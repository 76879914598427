import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl, InputLabel, Select, MenuItem,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import LocalityService from "../../services/LocalityService";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { useRefresh } from "react-admin";
import LocalityResponse from "../../interfaces/Locality/LocalityResponse";
import {AvailableTrialType} from "../../enums/AvailableTrialType";

interface InputProps {
  data: LocalityResponse;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const localityService = new LocalityService();

const SelectTrialAvailabilityTypes = (props: InputProps) => {
  const [selectedService, setSelectedService] = useState<any>(null);
  const refresh = useRefresh();


  const handleSubmit = () => {
    if (!selectedService) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Fill the data to continue",
        type: CustomSnackbarTypes.ERROR,
      });
      return;
    }
    let payload = {
      type: selectedService,
    };
    localityService.updateTrialLocalityAvailabilityType(props?.data?.id, payload).then(() => {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Successful",
        type: CustomSnackbarTypes.SUCCESS,
      });
    });
    refresh();
    props.setOpen(false);
  };


  return (
    <Dialog open={props.open} scroll={"paper"}>
      <DialogTitle>Set Trial Availability Type</DialogTitle>
      <DialogContent>
        <FormControl fullWidth >
          <InputLabel id="for_slot">Select Type</InputLabel>
          <Select
              labelId="for_slot"
              id="Select Type"
              label="Select Service"
              value={selectedService}
              onChange={(event) => setSelectedService(event.target.value)}
          >
            {Object.keys(AvailableTrialType).map((e: any) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={"success"}
          onClick={() => {
            handleSubmit();
          }}
          size={"large"}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            props.setOpen(false);
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectTrialAvailabilityTypes;
