import React from 'react';
import {Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

const CookReferralList = (props: any) => {

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Cook Id</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Onboarding Status</TableCell>
                        <TableCell>Payout Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((row: any) => {
                        return (
                            <TableRow
                                key={row.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell>
                                    <Link href={`/#/Cooks/${row.referredCookId}/show`}>
                                        {row?.referredCookId}
                                    </Link>
                                </TableCell>
                                <TableCell>{row?.referredCook?.firstName + ' ' + row?.referredCook?.lastName}</TableCell>
                                <TableCell>{row?.referredCook?.onboardingStatus}</TableCell>
                                <TableCell>{row?.referalAmountProcessedAt ? 'PROCESSED' : 'NOT-PROCESSED'}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CookReferralList;
