import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow
  } from "@mui/material";
  import { useRefresh } from "react-admin";
  import { useEffect, useState } from 'react';
  import EventBus from "../../EventBus";
  import { CustomSnackbarTypes } from "./CustomSnackBar";
  import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
  import ChefForPartyService from "../../services/ChefForPartyService";
import { CouponProductType } from "../../enums/CouponTypeEnum";
  
  interface Props {
    data: any;
    open: boolean;
    callback: () => void;
  }

  const CouponTable = (props: any) => {
      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Header</TableCell>
              <TableCell align="center">Code</TableCell>
              <TableCell align="center">Value</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Product Type</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.data?.map((e: any) => (
              <TableRow key={e.id}>
                <TableCell align="center">{e.couponHeader}</TableCell>
                <TableCell align="center">{e.couponCode}</TableCell>
                <TableCell align="center">{e.couponValue}</TableCell>
                <TableCell align="center">{e.couponType}</TableCell>
                <TableCell align="center">{e.productType}</TableCell>
                <TableCell>
                  <Button variant={"contained"} color={props?.select?.id === e?.id ? "success" : "primary"} onClick={() => props.setSelect(e)}> 
                    Select
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
  }
  
  const ChangeCouponChefForPartyDialog = (props: Props) => {
    const [select, setSelect] = useState<any>(null)
    const [coupon, setCoupon] = useState([])
    const chefForPartyService = new ChefForPartyService();
    const refresh = useRefresh();
  
    let handleClose = () => {
      props.callback();
      setSelect(null)
    };
  
    useEffect(() => {
        if(props?.data?.houseId){
            chefForPartyService.getAllCoupon(props?.data?.houseId).then((d)=>{
                setCoupon(d)
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props?.data])
    return (
        
      <Dialog
        onClose={handleClose}
        open={props.open}
        scroll={"paper"}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>{"Select the coupon"}</DialogTitle>
        <DialogContent>
            {coupon.length > 0 ? <CouponTable data={coupon} select={select} setSelect={setSelect}/> : <h3>No Coupon Available</h3>}
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              if (!select) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Please select one coupon",
                  type: CustomSnackbarTypes.WARNING
                });
                return;
              }
              chefForPartyService.changeCoupon(props?.data?.id, {
                couponCode: select?.couponCode,
                productType: CouponProductType.CHEF_FOR_PARTY,
                houseId: props?.data?.houseId
              }).then(() => {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Coupon Change Successfully",
                  type: CustomSnackbarTypes.SUCCESS,
                });
                refresh();
                handleClose();
              });
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              handleClose();
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  export default ChangeCouponChefForPartyDialog;
  