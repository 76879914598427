import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CookResponse } from "../../interfaces/Cook/CookResponse";
import { CookSlot, } from "../../interfaces/PartnerSlot/PartnerslotFilterResponse";

interface FilterProps {
  id?: number;
  northIndianFinalRating?: string;
  southIndianFinalRating?: string;
  chineseDishFinalRating?: string;
  continentalDishFinalRating?: string;
}

type PartnerData = {
  cook: CookResponse;
  cookSlots: CookSlot;
};
interface PartnerProps {
  data: PartnerData[] | undefined;
  callback: (filterData: PartnerData[] | undefined, filters: number) => void;
}

const PartnerSlotLocalFilter = (props: PartnerProps) => {
  const [localFilter, setLocalFilter] = useState<FilterProps>({});
  let showSlotTableData: PartnerData[] | undefined = props.data
    ? props.data
    : [];

  useEffect(() => {
    (Object.keys(localFilter) as Array<keyof typeof localFilter>).forEach(
      (x) => {
        showSlotTableData =
        // eslint-disable-next-line react-hooks/exhaustive-deps
          showSlotTableData && showSlotTableData.length > 0
            ? showSlotTableData?.filter((y) => {
              if(x !== 'id' && y["cook"] && y["cook"][x]) {
                return Number(y["cook"][x]) >= Number(localFilter && localFilter[x])
              } 
              return y["cook"][x] === localFilter[x]
            })
            : [];
        return x;
      }
    );
    props.callback(showSlotTableData, Number(Object.keys(localFilter).length));
  }, [localFilter]);

  return (
    <div style={{ display: "flex", gap: 12 }}>
      <TextField
        id="cookId"
        value={localFilter?.id}
        type="number"
        label="CookId"
        onChange={(event) => {
          let x: FilterProps = {};
          if (localFilter && Object.keys(localFilter).length > 0) {
            x = JSON.parse(JSON.stringify(localFilter));
          }
          if (event.target.value.trim().length === 0) {
            delete x["id"];
          } else {
            x["id"] = Number(event.target.value);
          }
          setLocalFilter(x);
        }}
      />
      <TextField
        id="northIndianFinalRating"
        value={localFilter?.northIndianFinalRating}
        label="North India Dish Rating"
        onChange={(event) => {
          let x: FilterProps = {};
          if (localFilter && Object.keys(localFilter).length > 0) {
            x = JSON.parse(JSON.stringify(localFilter));
          }
          if (event.target.value.trim().length === 0) {
            delete x["northIndianFinalRating"];
          } else {
            x["northIndianFinalRating"] = event.target.value;
          }
          setLocalFilter(x);
        }}
      />
      <TextField
        id="southIndianFinalRating"
        value={localFilter?.southIndianFinalRating}
        label="South India Dish Rating"
        onChange={(event) => {
          let x: FilterProps = {};
          if (localFilter && Object.keys(localFilter).length > 0) {
            x = JSON.parse(JSON.stringify(localFilter));
          }
          if (event.target.value.trim().length === 0) {
            delete x["southIndianFinalRating"];
          } else {
            x["southIndianFinalRating"] = event.target.value;
          }
          setLocalFilter(x);
        }}
      />
      <TextField
        id="chineseDishFinalRating"
        value={localFilter?.chineseDishFinalRating}
        label="Chinese India Dish Rating"
        onChange={(event) => {
          let x: FilterProps = {};
          if (localFilter && Object.keys(localFilter).length > 0) {
            x = JSON.parse(JSON.stringify(localFilter));
          }
          if (event.target.value.trim().length === 0) {
            delete x["chineseDishFinalRating"];
          } else {
            x["chineseDishFinalRating"] = event.target.value;
          }
          setLocalFilter(x);
        }}
      />
      <TextField
        id="continentalDishFinalRating"
        value={localFilter?.continentalDishFinalRating}
        label="Continental India Dish Rating"
        onChange={(event) => {
          let x: FilterProps = {};
          if (localFilter && Object.keys(localFilter).length > 0) {
            x = JSON.parse(JSON.stringify(localFilter));
          }
          if (event.target.value.trim().length === 0) {
            delete x["continentalDishFinalRating"];
          } else {
            x["continentalDishFinalRating"] = event.target.value;
          }
          setLocalFilter(x);
        }}
      />
    </div>
  );
};

export default PartnerSlotLocalFilter;
