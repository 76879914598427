import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, {useEffect, useState} from 'react';
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";

export interface CustomConfirmationDialogAttributes {
    onClose: (confirmed: boolean) => void;
    title: string;
    message: string;
    successLabel: string;
    failureLabel: string;
}

export default function ConfirmationDialog() {

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [dialogData, setDialogData] = useState<CustomConfirmationDialogAttributes>({
        failureLabel: "No",
        message: "Are you sure ?",
        onClose: (confirmed: boolean) => {},
        successLabel: "Yes",
        title: "Confirmation"
    })

    useEffect(() => {
        const handleConfirmationDialogShow = (data: CustomConfirmationDialogAttributes) => {
            setDialogData((dialogData) => ({
                ...dialogData,
                ...data
            }))
            setIsOpen(true);
        }
        EventBus.getInstance().on(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, handleConfirmationDialogShow);
        return () => {
            EventBus.getInstance().removeListener(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, handleConfirmationDialogShow);
        }
    }, [])

    const resetDialogData = () => {
        setDialogData({
            failureLabel: "No",
            message: "Are you sure ?",
            onClose: (confirmed: boolean) => {},
            successLabel: "Yes",
            title: "Confirmation"
        })
    }

    const onCancel = () => {
        dialogData.onClose(false);
        setIsOpen(false);
        resetDialogData();
    }
    const onSuccess = () => {
        setIsOpen(false);
        dialogData.onClose(true);
        resetDialogData();
    }

    return (
        <Dialog
            open={isOpen}
        >
            <DialogTitle> {dialogData.title} </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {dialogData.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCancel}
                    variant="contained"
                    color="error"
                >
                    {dialogData.failureLabel}
                </Button>
                <Button
                    onClick={onSuccess}
                    variant="contained"
                    color="success"
                >
                    {dialogData.successLabel}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
