
import { useRecordContext } from "ra-core";
import { MetabaseDasboardId } from "../../enums/MetabsaeDashboardId";
import MetabaseTransactionButton from "../Common/MetabaseDashboardButton";
interface Props {
    label: string,
}

const MetabaseCustomerDashboard = (props: Props) => {
    const record = useRecordContext();
    return <MetabaseTransactionButton dashboardId={MetabaseDasboardId.CUSTOMER} houseId={record.houseId} label="View"/>
}
export default MetabaseCustomerDashboard
