import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";
import {
  Create,
  Identifier,
  NumberInput,
  RaRecord,
  SimpleForm,
  TextInput
} from "react-admin";
import { useAppDispatch } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import AreaList from "../Area/AreaList";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";

const CreateHub = () => {
  const [areaId, setAreaId] = useState<Identifier>();
  const [areaName, setAreaName] = useState<string>();
  const dispatch = useAppDispatch();

  const handleCityListDialogOpen = (open:boolean) => {
    dispatch(setCustomSelectOpen({open}));
  };

  const transform = (data: { name: string; lat: number; long: number }) => {
    if (areaId) {
      return {
        name: data.name,
        areaId: areaId,
        lat: JSON.stringify(data.lat),
        long: JSON.stringify(data.long),
      };
    }

    return {};
  };
  return (
    <Create redirect="/Hubs" transform={transform}>
      <SimpleForm>
        <ResourceSelectDialog
          name={"Areas"}
          list={AreaList}
          onClose={(record: RaRecord) => {
            if(record){
              setAreaName(record.name)
              setAreaId(record.id ?? undefined);
            }else{
              handleCityListDialogOpen(false);
            }
          }}
        />
        <Box sx={{display:"flex", alignItems:"center"}}>
          <Button variant="contained" onClick={()=>{handleCityListDialogOpen(true)}}>
            {areaName ? `Area Selected` : "Select Area"}
          </Button>
          <Typography variant="body1" sx={{ml:"1rem"}}>{areaName}</Typography>
        </Box>
        <TextInput source="name" label="Name" />
        <NumberInput source="lat" label="Latitude" />
        <NumberInput source="long" label="Longitude" />
      </SimpleForm>
    </Create>
  );
};

export default CreateHub;
