import { Box, Link, TableCell, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CheckInCheckoutInterface from "../../interfaces/Cook/CookCheckinCheckoutInterface";
import { CookService } from "../../services/CookServices";
import CkMuiTable from "../Common/CkMuiTable/CkMuiTable";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import ImagePreviewer from "../Common/ImagePreviewer";
import moment from "moment";

const cookService = new CookService();

interface InputProps {
  houseId: number;
  subscriptionId: number;
}

const CookCheckInCheckOut = (props: InputProps) => {
  const [checkInCheckoutList, setCheckInCheckoutList] = useState<
    CheckInCheckoutInterface[]
    >([]);
  useEffect(() => {
    cookService
      .getAllCheckInCheckOutForCook(props.subscriptionId, {
        houseId: props.houseId,
      })
      .then((data) => {
          let modifiedRes = data?.sort((a, b) => b?.checkInId - a?.checkInId)
        setCheckInCheckoutList(modifiedRes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props]);

  if (!Array.isArray(checkInCheckoutList) || checkInCheckoutList.length === 0) {
    return <EmptyListPlaceholder message={"No Checkin Checkout Data Found"} />;
  }
  return (
    <CkMuiTable
      sx={{ width: "100%", }}
      headers={[
        "Check In Id",
        "House Id",
        "Cook Id",
        "Pre Checkin Time",
        "Check in Date",
        "Check in Time",
        "Check out Date",
        "Check out Time",
        "Wallet Transaction Amount",
        "Wallet Transaction Id",
        "Check In Images",
        "Check Out Images",
      ]}
      rowData={[]}
      isTablePagination={false}
    >
      {checkInCheckoutList.map((data: CheckInCheckoutInterface) => (
        <TableRow>
          <TableCell>{data?.checkInId || "Not Available"}</TableCell>
          <TableCell>
            <Link href={`/#/houses/${data?.houseId}/show`}>
              <p style={{ margin: 0 }}>{data?.houseId || "Not Available"}</p>
            </Link>
          </TableCell>
          <TableCell>
            <Link href={`/#/cooks/${data?.cookId}/show`}>
              <p style={{ margin: 0 }}>{data?.cookId || "Not Available"}</p>
            </Link>
          </TableCell>
          <TableCell>{data?.preCheckedInAt?moment(data?.preCheckedInAt).format("DD/MM/YYYY hh:mm A"):"Not Available"}</TableCell>
          <TableCell>{data?.checkInDate || "Not Available"}</TableCell>
          <TableCell>{data?.checkInTime || "Not Available"}</TableCell>
          <TableCell>{data?.checkOutDate || "Not Available"}</TableCell>
          <TableCell>{data?.checkOutTime || "Not Available"}</TableCell>
          <TableCell>
            {data?.walletTransactionAmount || "Not Available"}
          </TableCell>
          <TableCell>{data?.walletTransactionId || "Not Available"}</TableCell>
          <TableCell>
            <Box display={'flex'} alignItems={'center'} width={'150px'} gap={'5px'}>
              <Typography fontWeight={500} fontFamily={'inherit'} fontSize={'inherit'}>Self - </Typography>
              <Box>
                {data?.checkInImages?.self?.length <= 0 &&  <p>No Image</p>}
                {data?.checkInImages?.self?.length >= 0 && data?.checkInImages?.self?.map((val, index) => {
                  return (
                    <Box sx={{cursor: 'pointer'}}>
                      <ImagePreviewer key={index} value={val} width={"50px"} height={"50px"}/>
                      {/* <Link component="button" sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          setOpen(true);
                          setImageSrc(val);
                        }}
                      >{`Image - ${index + 1}`}</Link> */}
                    </Box>
                  )
                })}
              </Box>
            </Box>

            <Box display={'flex'} alignItems={'center'} width={'150px'} gap={'5px'} mt={'10px'}>
              <Typography fontWeight={500} fontFamily={'inherit'} fontSize={'inherit'}>Kitchen - </Typography>
              <Box>
                {data?.checkInImages?.kitchen?.length <= 0 &&  <p>No Image</p>}
                {data?.checkInImages?.kitchen?.length > 0 && data?.checkInImages?.kitchen?.map((val, index) => {
                  return (
                    <Box sx={{cursor: 'pointer'}}>
                      <ImagePreviewer key={index} value={val} width={"50px"} height={"50px"}/>
                      {/* <Link component="button" sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          setOpen(true);
                          setImageSrc(val);
                        }}
                      >{`Image - ${index + 1}`}</Link> */}
                    </Box>
                  )
                })}
              </Box>
            </Box>
          </TableCell>
          <TableCell>
            <Box display={'flex'} alignItems={'center'} width={'150px'} gap={'21px'}>
              <Typography fontWeight={500} fontFamily={'inherit'} fontSize={'inherit'}>Food - </Typography>
              <Box>
                {data?.checkOutImages?.food?.length <= 0 && <p>No Image</p>}
                {data?.checkOutImages?.food?.length > 0 && data?.checkOutImages?.food?.map((val, index) => {
                  return (
                    <Box sx={{cursor: 'pointer'}}>
                      <ImagePreviewer key={index} value={val} width={"50px"} height={"50px"}/>
                      {/* <Link component="button" sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          setOpen(true);
                          setImageSrc(val);
                        }}
                      >{`Image - ${index + 1}`}</Link> */}
                    </Box>
                  )
                })}
              </Box>
            </Box>

            <Box display={'flex'} alignItems={'center'} width={'150px'} gap={'5px'} mt={'10px'}>
              <Typography fontWeight={500} fontFamily={'inherit'} fontSize={'inherit'}>Kitchen - </Typography>
              <Box>
                {data?.checkOutImages?.kitchen?.length <= 0 && <p>No Image</p>}
                {data?.checkOutImages?.kitchen?.length > 0 && data?.checkInImages?.kitchen?.map((val, index) => {
                  return (
                    <Box sx={{cursor: 'pointer'}}>
                      <ImagePreviewer key={index} value={val} width={"50px"} height={"50px"}/>
                      {/* <Link component="button" sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          setOpen(true);
                          setImageSrc(val);
                        }}
                      >{`Image - ${index + 1}`}</Link> */}
                    </Box>
                  )
                })}
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </CkMuiTable>
  );
};

export default CookCheckInCheckOut;
