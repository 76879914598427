import Kalend, { CalendarView } from "kalend"; // import component
import "kalend/dist/styles/index.css"; // import styles
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Link,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import { Authenticated } from "ra-core";
import { DatePicker } from "@mui/x-date-pickers";
import {CookService} from "../../services/CookServices";
import '../OmsTimeTable/index.css';
import {OneMealService} from "../../services/OneMealService";
import {TrialsService} from "../../services/TrialServices";
import UserBundleService from "../../services/BundleService";
import {OneMealServiceStatus} from "../../enums/OneMealServiceStatus";
import { CookReferenceModelName } from "../../enums/CookReferenceModelName";
import { useRecordContext } from "react-admin";
import { retrieveFromLocalStorage, saveOrUpdateLocalStorage } from "../../utils/utils";

const cookService = new CookService();
const oneMealService = new OneMealService();
const trialsService = new TrialsService();
const userBundleService = new UserBundleService();
// const currentDate = localStorage.getItem('omsTimeTableDateFilter') ? moment(localStorage.getItem('omsTimeTableDateFilter')).toDate() : moment().toDate();

const legends = [
  {color: 'darkgrey', label: 'Leaves/BLOCKED'},
  {color: 'green', label: 'SERVICES FULFILLED'},
  {color: 'blue', label: 'COOK_ALLOCATED, COOK_CONFIRMED before SERVICES Start time'},
  {color: 'red', label: 'COOK_ALLOCATED, COOK_CONFIRMED after SERVICES Start time'},
  {color: 'black', label: 'PENDING SERVICES'},
];
interface CookLeaveInterface {
    summary: string,
    id: any,
    startAt: string,
    endAt: string,
    color: any,
    cookId: any,
    referenceId: string,
    referenceModelName: string,
    isLeave: boolean,
    cookDetails: {},
};

interface CookLeaveDataInterface {
    [date: string]: CookLeaveInterface[];
}

const CookPartTimeCalendar = (props: any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [dialog, setDialog] = useState<boolean>(false);
    const [initialDate, setInitialDate] = useState<Date>(moment().toDate());
    const [finalDate, setFinalDate] = useState<Date>(moment(initialDate).add(7,'days').toDate());
    const [details, setDetails] = useState<any>();
    const [cookOmsMapping, setCookOmsMapping] = useState<any>({});
    const record = useRecordContext();
    function mergeContinuousTimeSlots(timeSlots: any[]) {
        // Sort the array based on the startAt time
        // @ts-ignore
        timeSlots.sort((a: { startAt: string | number | Date; }, b: { startAt: string | number | Date; }) => new Date(a.startAt) - new Date(b.startAt));

        let mergedSlots: CookLeaveInterface[] = [];
        let currentSlot = timeSlots[0];

        if(!currentSlot) return [];

        for (let i = 1; i < timeSlots.length; i++) {
        const currentEnd = new Date(currentSlot.endAt);
        const nextStart = new Date(timeSlots[i].startAt);
        const currentModelName = currentSlot.referenceModelName;
        const nextModelName = timeSlots[i].referenceModelName;
        const currentReferenceId = currentSlot.referenceId;
        const nextReferenceId = timeSlots[i].referenceId;

        if (currentEnd >= nextStart && currentModelName === nextModelName && currentReferenceId === nextReferenceId) {
            // Merge the current slot with the next slot
            currentSlot.endAt = timeSlots[i].endAt;
            currentSlot.referenceModelName = timeSlots[i].referenceModelName;
            currentSlot.referenceId = timeSlots[i].referenceId;
        } else {
            // Current slot is not continuous, push it to the merged slots
            currentSlot.startAt = moment(currentSlot.startAt)
                .add(0.001, "hours")
                .format();
            mergedSlots.push(currentSlot);
            currentSlot = timeSlots[i];
        }
        }
        if(currentSlot?.isLeave === true) {
        currentSlot.endAt = moment(currentSlot.endAt)
            .subtract(0.001, "hours")
            .format();
        }
        // Push the last slot to the merged slots
        mergedSlots.push(currentSlot);
        return mergedSlots;
    }

    const getColor = (eachSlot: any) => {
        if (eachSlot?.referenceModelName === CookReferenceModelName.ONE_MEAL_SERVICE ||
        eachSlot?.referenceModelName === CookReferenceModelName.COOK_SUBSCRIPTION_V3 ||
        eachSlot?.referenceModelName === CookReferenceModelName.TRIALS_V3) {
            const currentDate = moment(new Date(), "YYYY-MM-DD HH:mm:ss");
            const slotDateTime = moment(
                moment(eachSlot.date).format('YYYY-MM-DD') + "T" + moment(eachSlot.timeSlot, "h:mm A").format("HH:mm"), "YYYY-MM-DD HH:mm:ss"
            ).format();
            if(eachSlot?.serviceStatus === OneMealServiceStatus.FULFILLED){
                return 'green';
            }
            if([OneMealServiceStatus.COOK_ALLOCATED, OneMealServiceStatus.COOK_CONFIRMED].includes(eachSlot?.serviceStatus) && !currentDate.isAfter(slotDateTime)){
                return 'blue'
            }
            if([OneMealServiceStatus.COOK_ALLOCATED, OneMealServiceStatus.COOK_CONFIRMED].includes(eachSlot?.serviceStatus) && currentDate.isAfter(slotDateTime) && eachSlot?.checkInTime === null){
                return 'red'
            }
            if([OneMealServiceStatus.ONGOING].includes(eachSlot?.serviceStatus) && eachSlot?.checkOutTime === null){
                return 'red'
            }
            return  "yellow";
        } else if(eachSlot?.leaveMarked !== null || eachSlot?.cookLeaveId !== null || ((eachSlot?.status === 'BLOCKED' && eachSlot?.referenceModelName === null))) {
        return  'darkgrey';
        } else if(OneMealServiceStatus.COOK_ALLOCATED) {
        return  'black';
        }
    };

    const getAllCooks = useCallback(() => {
        let startDate =  moment(moment(initialDate).format("YYYY-MM-DD")).format("YYYY-MM-DD");
        let endDate = moment(finalDate).format("YYYY-MM-DD");
        let query: any = { startDate: startDate, endDate: endDate, cookId: record?.id };
        
        cookService.getPartTimeCookCalender(query).then((res) => {
            let tempCookOmsMapping: CookLeaveDataInterface = {};
            for (const [key, val] of Object.entries(res)) {
                let tempDetails: any = {};
                const timeSlots: any[] = [];
                // eslint-disable-next-line
                (val as any[])?.map((eachSlot: any, ind: number) => {
                    let requiredDetails: CookLeaveInterface = {
                        summary: "",
                        id: NaN,
                        startAt: "",
                        endAt: "",
                        color: "",
                        cookId: NaN,
                        referenceId: "",
                        referenceModelName: "",
                        isLeave: false,
                        cookDetails: {},
                    };
                    requiredDetails.summary = eachSlot?.referenceModelName ? `${eachSlot?.referenceId}_${eachSlot?.referenceModelName}` : eachSlot?.status === 'BLOCKED' ? 'BLOCKED' : 'LEAVE';
                    requiredDetails.id = eachSlot.id;
                    requiredDetails.startAt = moment(eachSlot.date + "T" + moment(eachSlot.timeSlot, "h:mm A").format("HH:mm")).format();
                    requiredDetails.endAt = moment(requiredDetails.startAt).add(0.5, "hours").format();
                    requiredDetails.color = getColor(eachSlot);
                    requiredDetails.cookId = record?.id;
                    requiredDetails.referenceId = eachSlot?.referenceId;
                    requiredDetails.referenceModelName = eachSlot?.referenceModelName;
                    requiredDetails.cookDetails = record?.cookFullProfile;

                    tempDetails = { ...requiredDetails };
                    if(eachSlot?.leaveMarked !== null || eachSlot?.cookLeaveId !== null || (eachSlot?.status === 'BLOCKED' && eachSlot?.referenceModelName === null)){
                        requiredDetails.isLeave = true;
                    }
                    if (eachSlot?.referenceModelName !== null || eachSlot?.leaveMarked !== null || eachSlot?.cookLeaveId !== null || eachSlot?.status === 'BLOCKED') {
                        if(eachSlot?.referenceModelName !== ''){
                            timeSlots.push(requiredDetails);
                        }
                    }
                })
                let mergedSlots = mergeContinuousTimeSlots(timeSlots);
                if(mergedSlots.length === 0){
                    tempDetails.startAt = '';
                    tempDetails.endAt = '';
                    mergedSlots = [tempDetails];
                }
                if (!tempCookOmsMapping[key]) {
                    tempCookOmsMapping[key] = [...mergedSlots];
                } else {
                    tempCookOmsMapping[key].push(...mergedSlots);
                }
            }
            setCookOmsMapping(tempCookOmsMapping);
            setLoading(false);
        }).catch((err) => {setLoading(false)})
    }, [initialDate, finalDate, record]);
  
  useEffect(() => {
    const storedData = retrieveFromLocalStorage("cooksCalendar");
    if (storedData) {
      const cooksCalendarDates = JSON.parse(storedData);
      setInitialDate(cooksCalendarDates?.startDate);
      setFinalDate(cooksCalendarDates?.endDate);
    }
  }, [])
  const DataContainer = ({title = '', value = '---'}: {title: string, value: string}) => {
    return(
        <div>
          <b style={{ minWidth: "170px", display: "inline-block" }}>
            {title}
          </b>
          <span>{value}</span>
        </div>
    )
  };
  const DialogDetails = (props: any) => {
    let cook = props?.data?.cookDetails;
    const [data, setData] = useState<any>(null);
    const [redirectLink, setRedirectLink] = useState('')
    useEffect(() => {
      if(props?.data?.referenceModelName === 'ONE_MEAL_SERVICE'){
        setRedirectLink(`/#/OneMealService/${props?.data?.referenceId}/show`);
        oneMealService.getOneMealService(props?.data?.referenceId).then((res: any) => {
          setData(res?.data);
        })
      } else if(props?.data?.referenceModelName === 'TRIALS_V3'){
        setRedirectLink(`/#/Trials/${props?.data?.referenceId}/show`);
        trialsService.getTrialsById(props?.data?.referenceId).then((res: any) => {
          setData(res?.data);
        })
      } else if(props?.data?.referenceModelName === 'COOK_SUBSCRIPTION_V3') {
        cookService.getSubscriptionForACook(props?.data?.referenceId).then((data: any) => {
          setRedirectLink(`/#/UserBundles/${data?.userBundleMappingId}/show/7`);
          userBundleService.fetchOneUserBundleMapping(data?.userBundleMappingId).then((res: any) => {
            setData(res?.data);
          })
        })
      }
    }, [props?.data?.referenceId, props?.data?.referenceModelName]);
    return (
      <div>
        <Dialog
          onClose={props.handleClose}
          open={props.open}
          scroll={"paper"}
          maxWidth="xl"
        >
          <DialogTitle>
            <b>{props?.data?.referenceModelName}</b>
            <Button
                variant={'contained'}
                sx={{borderRadius: 30, fontSize: '10px', ml: 2}}
                size={'small'}
                component={Link}
                href={redirectLink}
            >
              {'View Full Details'}
            </Button>
          </DialogTitle>
          <DialogContent
            style={{ display: "flex", flexDirection: "column", width: "400px" }}
          >
            <DataContainer title={'Status:'} value={data?.status} />
            {cook && (
              <div>
                <DataContainer title={'Cook Id:'} value={cook?.cookId} />
                <DataContainer title={'Cook Name:'} value={cook?.firstName + " " + cook?.lastName} />
                <DataContainer title={'Cook PhoneNo:'} value={data?.cookPhoneNumber} />
                <DataContainer title={'Onboarding Status:'} value={cook?.onboardingStatus} />
              </div>
            )}
            <DataContainer title={'Customer Name:'} value={data?.customerName} />
            <DataContainer title={'Customer PhoneNo:'} value={data?.customerPhoneNumber} />
            <DataContainer title={'City:'} value={data?.cityName} />
            <DataContainer title={'Area:'} value={data?.areaName} />
            <DataContainer title={'Locality:'} value={data?.localityName} />
            <DataContainer title={'Customer Address:'} value={data?.house?.address + " " + data?.house?.locality + " " + data?.house?.city} />
            <DataContainer title={'Total People:'} value={data?.totalPeople} />
            <DataContainer title={'Allocator Name:'} value={data?.allocatorName} />
            <DataContainer title={'Date:'} value={moment(data?.date).format('DD-MM-YYYY')} />
            <DataContainer title={'Time:'} value={data?.time} />
            <DataContainer title={'Check In Time:'} value={data?.checkInTime} />
            <DataContainer title={'Customer Feedback:'} value={data?.customerFeedback} />
            <DataContainer title={'Check Out Time:'} value={data?.checkOutTime} />
            <DataContainer title={'Customer Comments:'} value={data?.customerComments?.[0]?.comment} />
            <DataContainer title={'Customer Rating:'} value={data?.customerRating} />
          </DialogContent>
          <DialogActions style={{ marginTop: "12px" }}>
            <Button
              variant="contained"
              color={"error"}
              onClick={() => {
                props.handleClose(false);
              }}
              size={"large"}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const onEventClick = (data: any) => {
    if(data?.referenceId) {
      setDialog(true);
    }
    setDetails(data);
  };

  useEffect(() => {
    setCookOmsMapping({});
    getAllCooks();
    const interval = setInterval(() => {
      getAllCooks();
    }, 1000 * 60 * 5);
  return () => {
    clearInterval(interval);
  }
  }, [getAllCooks, initialDate, finalDate]);
  return (
    <Authenticated>
      <>
        <DialogDetails
          open={dialog}
          handleClose={() => setDialog(false)}
          data={details}
        />
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "200%",
              minWidth: "0",
              overflowX: "scroll",
            }}
          >
            <Box display={'flex'} gap={'20px'}>    
                <DatePicker
                    mask=""
                    label="Select Start Date"
                    inputFormat="dd MMM yyyy"
                    value={initialDate}
                    onChange={(newValue: any) => {
                        setInitialDate(newValue);
                    }}
                    renderInput={(params) => {
                        return <TextField {...params} />;
                    }}
                    onAccept={
                      (newValue: any) => {
                        saveOrUpdateLocalStorage("cooksCalendar", {
                          startDate: moment(newValue),    
                        })
                      }
                    }
                />
                <DatePicker
                    mask=""
                    label="Select End Date"
                    inputFormat="dd MMM yyyy"
                    value={finalDate}
                    onChange={(newValue: any) => {
                        setFinalDate(newValue);
                    }}
                    renderInput={(params) => {
                        return <TextField {...params} />;
                    }}
                    onAccept={
                      (newValue: any) => {
                        saveOrUpdateLocalStorage("cooksCalendar", {
                          endDate: moment(newValue),
                        })
                      }
                    }
                />
            </Box>
            <div
              style={{ display: "flex", height: "70vh", overflow: "scroll"}}
            >
              {
                Object.keys(cookOmsMapping).length !== 0 &&
                Object.entries(cookOmsMapping).map(([key, value]: any) => (
                    <div style={{ textAlign: "center" }} key={key} className={'Calendar__wrapper wrapper'}>
                        
                        <Kalend
                            kalendRef={props.kalendRef}
                            initialView={CalendarView.DAY}
                            disabledViews={[
                                CalendarView.AGENDA,
                                CalendarView.THREE_DAYS,
                                CalendarView.MONTH,
                                CalendarView.WEEK,
                            ]}
                            timeFormat={'12'}
                            onEventClick={onEventClick}
                            events={value}
                            initialDate={moment(key).format("YYYY-MM-DD")}
                            hourHeight={60}
                            disabledDragging={true}
                            onStateChange={props.onStateChange}
                            selectedView={props.selectedView}
                            showTimeLine={true}
                            isDark={false}
                            autoScroll={false}
                        />
                    </div>
                ))}
            </div>
            <Box display={'flex'} flexDirection={'row'}>
              <Box m={2} display={'flex'} flexDirection={'column'}>
                {[legends[0], legends[1]]?.map((eachLegend) => (
                    <Box sx={{display: 'flex', alignItems: 'center'}} id={eachLegend?.label}>
                      <Box sx={{width: 20, height: 20, backgroundColor: eachLegend?.color, borderRadius: 1}} m={0.5} mr={1} />
                      <Typography fontSize={12}>
                        {eachLegend?.label}
                      </Typography>
                    </Box>
                ))}
              </Box>
              <Box m={2} display={'flex'} flexDirection={'column'}>
                {[legends[2], legends[3]]?.map((eachLegend) => (
                    <Box sx={{display: 'flex', alignItems: 'center'}} id={eachLegend?.label}>
                      <Box sx={{width: 20, height: 20, backgroundColor: eachLegend?.color, borderRadius: 1}} m={0.5} mr={1} />
                      <Typography fontSize={12}>
                        {eachLegend?.label}
                      </Typography>
                    </Box>
                ))}
              </Box>
              <Box m={2} display={'flex'} flexDirection={'column'}>
                {[legends[4]]?.map((eachLegend) => (
                    <Box sx={{display: 'flex', alignItems: 'center'}} id={eachLegend?.label}>
                      <Box sx={{width: 20, height: 20, backgroundColor: eachLegend?.color, borderRadius: 1}} m={0.5} mr={1} />
                      <Typography fontSize={12}>
                        {eachLegend?.label}
                      </Typography>
                    </Box>
                ))}
              </Box>
            </Box>
          </div>
        )}
      </>
    </Authenticated>
  );
};
export default CookPartTimeCalendar;
