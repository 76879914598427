import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useEffect, useState } from "react";
import CircularLoader from "./CircularLoader";
import moment from "moment";
import EmptyListPlaceholder from "./EmptyListPlaceholder";
import { CouponServiceV2 } from "../../services/CuponServiceV2";

const couponServiceV2=new CouponServiceV2();

const CouponsSelectDialog =(props:any)=>{
    const { houseId,setCoupon } = props;
    const [coupons,setCoupons]=useState<any>([]);
    const [loading,setLoading]=useState<boolean>(false);

    function getCoupons(houseId:number){
        couponServiceV2.getAllCouponForHouseV2(houseId).then((data)=>{
            setCoupons(data?.data);
            setLoading(false);
        }).catch((err)=>{
            setLoading(false);
        })
    }

    useEffect(()=>{
        setLoading(true);
        setCoupons([]);
        setCoupon(null)
        getCoupons(houseId);
    },[houseId,setCoupon])

    const handleClose = () => {
        props.callback(null);
    }

    return (
        <Dialog 
            open={props.open}
            onClose={handleClose}
            scroll={"paper"}
        >
            <DialogTitle>Select Coupons</DialogTitle>
            <DialogContent>
                {
                    loading
                    ?
                    <CircularLoader/>:
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth:"80%"}}aria-label="coupon table">
                            <TableHead>
                            <TableRow>
                                <TableCell>Select</TableCell>
                                <TableCell>Coupon Code</TableCell>
                                <TableCell>Coupon Type</TableCell>
                                <TableCell>Coupon Value</TableCell>
                                <TableCell>Is Active</TableCell>
                                <TableCell>Expiry Time</TableCell>
                                <TableCell>Total Time Used</TableCell>
                                <TableCell>Usage Limit</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {(coupons&&coupons.length>0)?coupons.map((row:any) => (
                                <TableRow
                                key={row?.couponCode}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>
                                        <Button 
                                            variant={"contained"}
                                            color={"primary"}
                                            onClick={() => {
                                                props.setCoupon(row);
                                                handleClose();
                                            }}
                                            size={"large"}
                                        >
                                            Select
                                        </Button>
                                    </TableCell>
                                    <TableCell scope="row">{row?.couponCode}</TableCell>
                                    <TableCell align="left">{row?.couponType}</TableCell>
                                    <TableCell align="left">{row?.couponValue}</TableCell>
                                    <TableCell align="left">{row?.isActive?"YES":"NO"}</TableCell>
                                    <TableCell align="left">{moment(row?.expiresAt).format('DD-MM-YYYY hh:mm A')}</TableCell>
                                    <TableCell align="left">{row?.totalTimesUsed}</TableCell>
                                    <TableCell align="left">{row?.maximumUsageLimit}</TableCell>
                               </TableRow>
                            )
                        ):
                        <EmptyListPlaceholder message="No Coupons to show....."/>
                        }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    }
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color={"error"}
                    onClick={() => {
                        handleClose();
                    }}
                    size={"large"}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )

}
export default CouponsSelectDialog