import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useRefresh } from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { RenewalResponse } from "../../interfaces/Renewals/RenewalsResponse";
import { RenewalsService } from "../../services/RenewalsService";
import { CustomSnackbarTypes } from "./CustomSnackBar";

interface InputProps {
  data: RenewalResponse;
  open: boolean;
  callback: () => void;
}

const renewalService = new RenewalsService();

const EscalationRenewal = (props: InputProps) => {
  let refresh = useRefresh();
  let handleClose = () => {
    props.callback();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      scroll={"paper"}
      maxWidth="xl"
    >
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>{props?.data?.hasEscalations === true ? "Resolved Escalation?": "Create Escalation?"}</DialogContent>
      <DialogActions>
        <Button
          size={"medium"}
          variant="contained"
          onClick={async () => {
            await renewalService
              .markEscalationForRenewal(props?.data?.id)
              .then(() => {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Success",
                  type: CustomSnackbarTypes.SUCCESS,
                });
                refresh();
                handleClose();
              });
          }}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            handleClose();
          }}
          size={"medium"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EscalationRenewal;
