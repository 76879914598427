import { memo } from "react";
import {
  AppBar,
  Layout as AdminLayout,
  Logout,
  UserMenu,
  useTheme,
} from "react-admin";
import PersonSharpIcon from "@mui/icons-material/PersonSharp";
import { useAppSelector } from "./store/hooks";
import { ListItemIcon, MenuItem, IconButton, Tooltip, Box } from "@mui/material";
import Language from "@mui/icons-material/Language";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeIcon from "@mui/icons-material/LightMode";
import { useNavigate } from "react-router-dom";
import { darkTheme, lightTheme } from "./theme";
import UpdateCentaurusButton from "./components/Common/UpdateCentaurusButton";
import ModuleSearch from "./components/Common/ModuleSearch";


const MyUserMenu = () => {
  const userDetails = useAppSelector((state) => state.userDetails.value);
  let navigate = useNavigate();
  const [theme, setTheme] = useTheme();
  return (
    <Box display={'flex'} alignItems={'center'}>
      <UpdateCentaurusButton />
      <Tooltip
        title={
          theme?.palette?.mode === "dark"
            ? "Switch to Light Theme"
            : "Switch to Dark Theme"
        }
      >
        <IconButton
          onClick={() =>
            setTheme(theme?.palette?.mode === "dark" ? lightTheme : darkTheme)
          }
        >
          {theme?.palette?.mode === "dark" ? (
            <LightModeIcon />
          ) : (
            <DarkModeOutlinedIcon sx={{ color: "#202021" }} />
          )}
        </IconButton>
      </Tooltip>
      <UserMenu>
        <div
          style={{
            padding: "6px 16px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <PersonSharpIcon
            style={{
              marginRight: "12px",
            }}
          />
          <p
            style={{
              margin: "0px",
            }}
          >
            {(userDetails?.adminUser as any)?.name}
          </p>
        </div>
        <MenuItem
          sx={{ color: "text.secondary" }}
          onClick={(e) => {
            navigate("/change-password");
          }}
        >
          <ListItemIcon sx={{ minWidth: 5 }}>
            <Language />
          </ListItemIcon>
          Change Password
        </MenuItem>
        <Logout />
      </UserMenu>
    </Box>
  );
};

const MyAppBar = memo((props) => {
  return <AppBar elevation={1} {...props} userMenu={<MyUserMenu />} sx={{
    '& .RaAppBar-toolbar': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    }
  }}>
    <Box flexBasis={'90%'} display={'flex'}>
      <ModuleSearch />
    </Box>
  </AppBar>;
});

const CustomLayout = (props: any) => {
  return <AdminLayout {...props} appBar={MyAppBar} />;
};
export default CustomLayout;
