import { ResolutionEnum } from "../enums/ResolutionEnum";
import { SlotsEnum } from "../enums/SlotsEnum";

export class MultipleCustomerLeavesDto {
  public id!: number;
  public userMobile!: number;
  public userBundleMappingId!: number;
  public slot!: SlotsEnum;
  public leaveDate!: string;
  public status!: string;
  public leaveType!: string;
  public resolution!: ResolutionEnum;
  public isSelected!: boolean;
}
