import { GetListResult, GetOneResult } from "react-admin";
import HttpClient from "../HttpClient";
import { ChangePasswordRequest } from "../interfaces/AdminUser/changePasswordRequest";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";

class AdminUserService {
    async getAllAdminUsers(limit: number, offset: number, filters: {}, sort: {}): Promise<GetListResult<any>> {
        filters = ConstructSequelizeFilters(filters, ['email', 'name'])
        let data = await HttpClient.get('/admin-user/admin/all', {
            params: {
                limit,
                offset,
                filters,
                sort
            }
        });
        return {
            data: (!!data && !!data.data) ? data.data.data : [],
            total: (!!data && !!data.data) ? data.data.count : 0
        }
    }

    async getAdminUserById(id: number): Promise<GetOneResult<any>> {
        let data = await HttpClient.get(`/admin-user/admin/${id}`);
        return {
            data: data?.data
        }
    }

    async getAllGroups(): Promise<GetListResult<any>> {
        let data = await HttpClient.get(`/admin-user/admin/all-groups`);
        return data?.data
    }

    async addAdminUserToGroup(adminId: number, groupId: number): Promise<any> {
        let data = await HttpClient.patch(`/admin-user/admin/${adminId}/add-group/${groupId}`);
        return data?.data
    }

    async removeAdminUserToGroup(adminId: number, groupId: number): Promise<any> {
        let data = await HttpClient.delete(`/admin-user/admin/${adminId}/remove-group/${groupId}`);
        return data?.data
    }

    async changePassword(adminId: number, payload: ChangePasswordRequest): Promise<any> {
        let data = await HttpClient.patch(`/admin-user/admin/change-password/${adminId}`, payload);
        if(data.status === 200){
            localStorage.clear();
            window.location.href = '#/login'
        }
        return data?.data
    }
}

export default AdminUserService;
