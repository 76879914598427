import {
    Box, Button,
    Dialog, Divider,
    FormControl, FormControlLabel,
    InputLabel,
    MenuItem, Radio, RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import {ReasonSubReasonResponse} from "../../interfaces/ReasonSubReason/ReasonSubReasonResponse";
import React from "react";
import {UserBundlesResponse} from "../../interfaces/UserBundleMapping/UserBundlesResponse";
import moment from "moment/moment";
import {SubscriptionResponse} from "../../interfaces/UserBundleMapping/SubscriptionForBundle";

type InputProps = {
    open: boolean;
    onClose: () => void;
    reasonSubReasonData: ReasonSubReasonResponse[];
    bundleDetails: UserBundlesResponse;
    subscriptionDetails?: SubscriptionResponse;
    refundDetail?: any;
    handleContinueClicked: (reason: string, subReason: string, comment: string, selectedOption: string) => void;
}
const CancelPRRequestDialog = (props: InputProps) => {
    const [reason, setReason] = React.useState<string>('');
    const [subReason, setSubReason] = React.useState<string>('');
    const [comment, setComment] = React.useState<string>('');
    const [selectedOption, setSelectedOption] = React.useState<string>('cancelSubscription');

    const handleReasonChange = (event: SelectChangeEvent) => {
        setReason(event?.target?.value);
    }
    const handleSubreasonChange = (event: SelectChangeEvent) => {
        setSubReason(event?.target?.value);
    }
    const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComment(event?.target?.value);
    }
    const handleOptionSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event?.target?.value);
    }
    return (
            <Dialog
                open={props?.open}
                onClose={props?.onClose}
                sx={{
                    '& .MuiPaper-root': {
                        py: '20px',
                        px: '20px',
                        minWidth: '450px',
                        maxWidth: '650px',
                    },
                }}
            >
                {/*End Dates */}
                <Box display={'flex'} gap={'50px'} mb={'10px'}>
                    {/*Bundle End Date */}
                    <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                        <Typography fontWeight={500}>Bundle End Date: </Typography>
                        <Typography fontWeight={400}>{moment(props?.bundleDetails?.endDate).format('DD MMM YYYY')}</Typography>
                    </Box>
                    {/*Subscription End Date */}
                    <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                        <Typography fontWeight={500}>Subscription End Date: </Typography>
                        <Typography fontWeight={400}>{moment(props?.subscriptionDetails?.endDate).format('DD MMM YYYY')}</Typography>
                    </Box>
                </Box>
                <Divider />

                <Box mt={'25px'}>
                    <Typography fontWeight={600} letterSpacing={'1px'} fontSize={'16px'} mb={'10px'}>Cancel PR Request</Typography>
                    {/*Cancel PR Request Form */}
                    <Box>
                        {/*Reason Dropdown */}
                        <FormControl fullWidth variant={'outlined'} size={'medium'}>
                            <InputLabel id="reason-label" >Select Reason For Cancelling Permanent Request</InputLabel>
                            <Select
                                labelId="reason-label"
                                id="reason-select"
                                value={reason}
                                label="Select Reason For Cancelling Permanent Request"
                                onChange={(event: SelectChangeEvent) => handleReasonChange(event)}
                            >
                                {Array.from(new Set(props?.reasonSubReasonData?.map((value: ReasonSubReasonResponse) => value.reason))).map((value: string) => {
                                    return (
                                        <MenuItem key={value} value={value}>{value}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                        {/*SubReason DropDown and a text field for sub reason if other is selected*/}
                        {reason && (
                            <Box>
                                <FormControl fullWidth variant={'outlined'} size={'medium'}>
                                    <InputLabel id="sub-reason-label">Select Sub Reason For Cancelling Permanent Request</InputLabel>
                                    <Select
                                        labelId="sub-reason-label"
                                        id="sub-reason-select"
                                        value={subReason}
                                        label="Select Sub Reason For Cancelling Permanent Request"
                                        onChange={(event: SelectChangeEvent) => handleSubreasonChange(event)}
                                    >
                                        {Array.from(new Set(props?.reasonSubReasonData?.filter((value: ReasonSubReasonResponse) => value?.reason === reason)?.map((value: ReasonSubReasonResponse) => value?.subReason))).map((value: string) => {
                                            return (
                                                <MenuItem key={value} value={value}>{value}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        )}

                        {/*Comments text field */}
                        <FormControl fullWidth>
                            <TextField
                                variant={'outlined'}
                                size={'medium'}
                                label={'Comments'}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCommentChange(event)}
                            />
                        </FormControl>

                        {/*Option Select */}
                        <Box mt={'20px'}>
                            <Typography fontWeight={500}>Please Select the option, which customer wants after cancellation</Typography>
                            <Box pl={'10px'}>
                                <FormControl>
                                    <RadioGroup
                                        value={selectedOption}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOptionSelected(event)}
                                    >
                                        <Box>
                                            <FormControlLabel value={'cancelSubscription'} control={<Radio />} label={'Cancel Subscription'} />
                                            {selectedOption === 'cancelSubscription' &&
                                                <Typography
                                                    color={'#085b08'}
                                                    fontWeight={500}
                                                    ml={'45px'}
                                                    mt={'-5px'}
                                                    pl={'-20px'}
                                                >
                                                    Refund Amount: INR {props?.refundDetail?.refund}
                                                </Typography>}
                                        </Box>
                                        <FormControlLabel value={'wantsOldCookBack'} control={<Radio />} label={'Customer wants their old Cook back'} />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Box>
                        {/*Continue Button */}
                        <Box display={'flex'} justifyContent={'center'} mt={'20px'}>
                            <Button
                                sx={{
                                    width: '100px',
                                    textTransform: 'none',
                                    fontSize: '16px',
                                    letterSpacing: '1px',
                                }}
                                variant={'contained'}
                                color={'primary'}
                                disabled = {!reason || !subReason || !selectedOption}
                                onClick={() => props?.handleContinueClicked(reason, subReason, comment, selectedOption)}
                            >
                                Continue
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        )

}

export default CancelPRRequestDialog;