import HttpClient from "../HttpClient";
import {GetListResult, GetOneResult} from "react-admin";
import { RosterResponse } from "../interfaces/StarChefVisit/StarChefRosterResponse";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";

export default class StarChefRosterService {
    private basePath = '/star-chef-roster/centaurus';
    
    async getDefaultCount(payload: {cityId : number}): Promise<GetListResult<RosterResponse>> {
        let filters = ConstructSequelizeFilters(payload, [], ['cityId'],[],[])
        let data = await HttpClient.get(`${this.basePath}`, {
          params: {
            filters,
          },
        });
        return {
            data: (!!data && !!data.data) ? data.data.data : [],
            total: (!!data && !!data.data) ? data.data.count : 0
        }
    }

    async updateRoster(id: number, count: number): Promise<GetOneResult<RosterResponse>> {
        let data = await HttpClient.patch(`${this.basePath}/roster/${id}`, {count});
        return data?.data;
    }

}
