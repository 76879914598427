import React, { useEffect, useState } from "react";
import VendorPayoutService from "../../services/VendorPayoutService";
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import VendorPayoutListModule from "../VenderPayout/VendorPayoutListModule";
import moment from "moment";

interface InputProps {
  cookId: number;
}

const vendorPayoutService = new VendorPayoutService();

const CookVendorPayoutTab = (props: InputProps) => {
  let [transactionData, setTransactionData] = useState<any>(null);
  let [isLoading, setIsLoading] = useState<boolean>();

  const getData = (cookId: any, year: string) => {
    setIsLoading(true);
    vendorPayoutService
      .cookModuleYearlyTransaction(cookId, year)
      .then((data) => {
        setTransactionData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData(props.cookId, moment().format("YYYY"));
  }, [props.cookId]);

  return (
    <div>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <div>
          {transactionData &&
            Array.isArray(transactionData) &&
            (transactionData.length > 0 ? (
              
              <VendorPayoutListModule data={transactionData} cookId={props.cookId}/>
            ) : (
              <EmptyListPlaceholder message="No Vendor Payout Found" />
            ))}
        </div>
      )}
    </div>
  );
};

export default CookVendorPayoutTab;
