import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button, Link,
} from "@mui/material";
import ApproveChefForParty from "../Common/ApproveChefForParty";
import React, { useState } from "react";
import ChefForPartStatusEnum from "../../enums/ChefForPartStatusEnum";
import ChefForPartyService from "../../services/ChefForPartyService";
import CallButton from "../Common/CallButton/CallButton";
import { UserType } from "../../enums/common";

  const ChefForPartyCooksList = (props: any) => {
    const [selectedData, setSelectedData] = useState<any>()
    const [approvalModel, setApprovalModel] = useState<boolean>(false);
    const chefForPartyService = new ChefForPartyService();
    const handleAccept = (data : any) => {
        setApprovalModel(true)
        setSelectedData(data);
    }
    const handleRemoveCook = (data: any) => {
      chefForPartyService.removeCook(props?.party?.id, data?.cookId).then(()=>{
        props.refresh();
      })
    }
    return (
        <>
        <ApproveChefForParty
            data={selectedData}
            open={approvalModel}
            refresh={props.refresh}
            callback={() => {
                setApprovalModel(false);
            }}
        />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Cook Id</TableCell>
              <TableCell>CookName</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>CooksNonVeg</TableCell>
              <TableCell>Mode of Travel</TableCell>
              <TableCell>Cook Acknowledge</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row: any) => (
              <TableRow
                key={row?.profile?.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell scope="row">
                    <Link href={`/#/Cooks/${row?.profile?.cookFullProfile?.cookId}/show`}>
                        {row?.profile?.cookFullProfile?.cookId}
                    </Link>
                </TableCell>
                <TableCell scope="row">{(row?.profile?.cookFullProfile?.firstName||"---") + " " + (row?.cookFullProfile?.lastName||"")}</TableCell>
                {/* <TableCell scope="row">{(row?.details?.cookPhone || "---")}</TableCell> */}
                <TableCell scope="row">{
                  (<CallButton
                    toPhoneNumber={row?.details?.cookPhone}
                    userType={UserType.COOK}
                />
                || "---")}</TableCell>
                <TableCell scope="row">{(row?.profile?.cookFullProfile?.gender||"---")}</TableCell>
                <TableCell scope="row">{(row?.profile?.cookFullProfile?.cooksNonVeg?"Yes":"No")}</TableCell>
                <TableCell scope="row">{(row?.profile?.cookFullProfile?.modeOfTravel||"---")}</TableCell>
                <TableCell scope="row">{(row?.details?.cookAcknowledge !== null ? row?.details?.cookAcknowledge ? "Accepted" : "Rejected" : "---" )}</TableCell>
                {row?.details?.cookAcknowledge === null && <TableCell>
                    <Button
                        variant={"contained"}
                        onClick={() => handleAccept(row?.profile?.cookFullProfile)}
                    >
                        Accept/Reject
                    </Button>
                </TableCell>}
                {![ChefForPartStatusEnum.CANCELLED, ChefForPartStatusEnum.ONGOING, ChefForPartStatusEnum.RESCHEDULED, ChefForPartStatusEnum.FULFILLED].includes(props?.party?.status) && <TableCell>
                    <Button
                        variant={"contained"}
                        color={'warning'}
                        onClick={() => handleRemoveCook(row?.profile?.cookFullProfile)}
                    >
                        Remove
                    </Button>
                </TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </>
    );
  };

  export default ChefForPartyCooksList;
