import React from 'react';
import {Button, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const CookSubscriptionListForModule = (props: any) => {
    let navigate = useNavigate();

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Cook</TableCell>
                        <TableCell>Cook Name</TableCell>
                        <TableCell>Zoho CookId</TableCell>
                        <TableCell>Cook Mobile</TableCell>
                        <TableCell>Subscription</TableCell>
                        <TableCell>User bundle Mapping</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Start Slot</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Leave Taken</TableCell>
                        <TableCell>Leave Allowed</TableCell>
                        <TableCell>Zoho</TableCell>
                        <TableCell>Show</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((row: any) => {
                        let fullAddress = [
                            row?.userBundleMapping?.house?.locality || '',
                            row?.userBundleMapping?.house?.sector || '',
                            row?.userBundleMapping?.house?.address || '',
                            row?.userBundleMapping?.house?.city || ''
                        ].filter((e) => e !== '').join(', ');
                        return (
                            <TableRow
                                key={row.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell scope="row">{row?.id}</TableCell>
                                <TableCell>
                                    <Link href={`/#/Cooks/${row.cookId}/show`}>
                                        {row?.cookId}
                                    </Link>
                                </TableCell>
                                <TableCell align="center">{row?.cook?.fname}</TableCell>
                                <TableCell align="center">{row?.cook?.zohoCookId}</TableCell>
                                <TableCell align="center">{row?.cook?.mobile}</TableCell>
                                <TableCell align="center">{row?.subscriptionId}</TableCell>
                                <TableCell>
                                    <Link href={`/#/UserBundles/${row.userBundleMappingId}/show`}>
                                        {row?.userBundleMappingId}
                                    </Link>
                                </TableCell>
                                <TableCell align="center">{fullAddress}</TableCell>
                                <TableCell align="center">{row?.startSlot}</TableCell>
                                <TableCell align="center">
                                    {
                                        !!row &&
                                        !!row.startDate &&
                                        moment(row?.startDate, 'YYYY-MM-DD').format('DD MMM YYYY')
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        !!row &&
                                        !!row.endDate &&
                                        moment(row?.endDate, 'YYYY-MM-DD').format('DD MMM YYYY')
                                    }
                                </TableCell>
                                <TableCell align="center">{row?.leaveTaken}</TableCell>
                                <TableCell align="center">{row?.leaveAllowed}</TableCell>
                                <TableCell align="center">{row?.zohoId}</TableCell>
                                <TableCell>
                                    <Button onClick={() => {
                                            navigate(
                                              `/cookSubscription/show?houseId=${row?.userBundleMapping.houseId}&subscriptionId=${row?.id}`
                                            );
                                        }}>
                                        Show
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CookSubscriptionListForModule;
