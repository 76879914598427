import {
    Button,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import moment from 'moment';
import { TrialResponse } from "../../interfaces/Trials/TrialsResponse";
import React from "react";
import CallButton from "../Common/CallButton/CallButton";
import { UserType } from "../../enums/common";

interface InputProps {
    data: Array<TrialResponse>;
}

const TrailsListForModule = (props: InputProps) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Cook Name</TableCell>
                        <TableCell>CX Name</TableCell>
                        <TableCell>CX Mobile</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Subscription Price</TableCell>
                        <TableCell>House</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Time</TableCell>
                        <TableCell>PR</TableCell>
                        <TableCell align="center">Meal Mapping</TableCell>
                        <TableCell>Trial Owner Name</TableCell>
                        <TableCell>Trial Owner Email</TableCell>
                        <TableCell>Customer Feedback</TableCell>
                        <TableCell>Cancellation Reason</TableCell>
                        <TableCell>Cancellation SubReason</TableCell>
                        <TableCell>Reschedule Reason</TableCell>
                        <TableCell>Reschedule SubReason</TableCell>
                        <TableCell>Cook Rejection Reason</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((row: any) => {
                        let fullAddress = [
                            row?.house?.locality || '',
                            row?.house?.sector || '',
                            row?.house?.address || '',
                            row?.house?.city || ''
                        ].filter((e) => e !== '').join(', ');                        return (
                            <TableRow
                                key={String(row.id) + 'Trial-list'}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell scope="row">{row?.id}</TableCell>
                                <TableCell align="left">{`${row?.cook?.cookFullProfile?.firstName} ${row?.cook?.cookFullProfile?.lastName}` || '---'}</TableCell>
                                <TableCell align="left">{row?.customerName}</TableCell>
                                {/* <TableCell align="left">{row?.customerPhoneNumber}</TableCell> */}
                                <TableCell align="left">{
                                    <CallButton toPhoneNumber={row?.customerPhoneNumber} userType={UserType.CUSTOMER}/>
                                }</TableCell>
                                <TableCell align="left">{row?.status}</TableCell>
                                <TableCell align="left">{row?.totalPrice}</TableCell>
                                <TableCell align="left">
                                    <Link href={`/#/Houses/${row?.houseId}/show`}>
                                        {row?.houseId}
                                    </Link>
                                </TableCell>
                                <TableCell align="left" sx={{minWidth:"200px"}}>{fullAddress}</TableCell>
                                <TableCell
                                    align="left">{row?.date && moment(row?.date).format("DD MMM YYYY")}</TableCell>
                                <TableCell align="left">{row?.time}</TableCell>
                                <TableCell align="center">
                                    {row.prId &&
                                      <Link href={`/#/PermanentReplacement/${row.prId}/show`}>
                                          {row.prId}
                                      </Link>
                                    }
                                </TableCell>
                                <TableCell align="left">
                                    {row.mealMapping?.length !== 0 ?
                                        <Table size="small" sx={{'&:last-child td, &:last-child th': {border: 1}}}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Slot</TableCell>
                                                    <TableCell>Time</TableCell>
                                                    <TableCell>Buffer</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {row.mealMapping.map((meal: { slot: string; time: string; bufferMinutes: number }) => (
                                                    <TableRow key={String(row.from)}>
                                                        <TableCell component="th" scope="row">
                                                            {meal?.slot}
                                                        </TableCell>
                                                        <TableCell sx={{minWidth: '70px'}}>{meal?.time}</TableCell>
                                                        <TableCell>{meal?.bufferMinutes}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table> : '---'
                                    }
                                </TableCell>
                                <TableCell align="left">{row?.trialOwnerName}</TableCell>
                                <TableCell align="left">{row?.trialOwnerEmail}</TableCell>
                                <TableCell align="left">{row?.customerFeedback || '---'}</TableCell>
                                <TableCell align="left">{row?.cancellationReason || '---'}</TableCell>
                                <TableCell align="left">{row?.cancellationSubReason || '---'}</TableCell>
                                <TableCell align="left">{row?.rescheduleReason || '---'}</TableCell>
                                <TableCell align="left">{row?.rescheduleSubReason || '---'}</TableCell>
                                <TableCell align="left">{row?.cookRejectionReason || '---'}</TableCell>
                                <TableCell align="center">
                                    <Link href={`/#/Trials/${row.id}/show`}>
                                        <Button variant={'contained'} color={'primary'}>
                                            Show
                                        </Button>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        )}
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TrailsListForModule;
