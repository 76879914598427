import {Box} from '@mui/material';
import React from 'react'
import { SimpleShowLayout, TextField, useRecordContext } from 'react-admin';
import ImagePreviewerDialog from './ImagePreviewerDialog';

const CheckInAndOutImages = () => {
    const record = useRecordContext();
    const [open, setOpen] = React.useState(false);
    const [src, setSrc] = React.useState("");

    return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
        <SimpleShowLayout
          sx={{ gap: "4px", marginRight: "1px" }}
          spacing={"10px"}
        >
          <TextField label={"CheckIn - Kitchen Images"} />
          <div style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
            {record?.checkInImages?.kitchenImages.map(
              (item: string, index: number) => {
                return (
                  <Box
                    onClick={()=>{
                      setOpen(true)
                      setSrc(item);
                    }}
                    component="img"
                    key={String(index) + "checkin-kitchen"}
                    sx={{
                      height: 200,
                      width: 250,
                      maxHeight: { xs: 200, md: 150 },
                      maxWidth: { xs: 250, md: 200 },
                      margin: "1px",
                    }}
                    alt="Image Not Available"
                    src={item}
                  />
                );
              }
            )}
          </div>

          <TextField label={"CheckIn - Selfies Images"} />
          <div style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
            {record?.checkInImages?.selfiesImages.map(
              (item: string, index: number) => {
                return (
                  <Box
                    onClick={()=>{
                      setOpen(true)
                      setSrc(item);
                    }}
                    component="img"
                    key={String(index) + "checkin-selfi"}
                    sx={{
                      height: 200,
                      width: 250,
                      maxHeight: { xs: 200, md: 150 },
                      maxWidth: { xs: 250, md: 200 },
                      margin: "1px",
                    }}
                    alt="Image Not Available"
                    src={item}
                  />
                );
              }
            )}
          </div>
        </SimpleShowLayout>
        <SimpleShowLayout
          sx={{
            gap: "4px",
            display: "flex",
            flexWrap: "wrap",
            marginRight: "1px",
          }}
          spacing={"10px"}
        >
          <TextField label={"CheckOut - Food Images"} />
          <div style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
            {record?.checkOutImages?.foodImages.map(
              (item: string, index: number) => {
                return (
                  <Box
                    onClick={()=>{
                      setOpen(true)
                      setSrc(item);
                    }}
                    component="img"
                    key={String(index) + "checkout-food"}
                    sx={{
                      height: 200,
                      width: 250,
                      maxHeight: { xs: 200, md: 150 },
                      maxWidth: { xs: 250, md: 200 },
                      margin: "1px",
                    }}
                    alt="Image Not Available"
                    src={item}
                  />
                );
              }
            )}
          </div>
          <TextField label={"CheckOut - Kitchen Images"} />
          <div style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
            {record?.checkOutImages?.kitchenImages.map(
              (item: string, index: number) => {
                return (
                  <Box
                    onClick={()=>{
                      setOpen(true)
                      setSrc(item);
                    }}
                    component="img"
                    key={String(index) + "checkout-kitched"}
                    sx={{
                      height: 200,
                      width: 250,
                      maxHeight: { xs: 200, md: 150 },
                      maxWidth: { xs: 250, md: 200 },
                      margin: "1px",
                    }}
                    alt="Image Not Available"
                    src={item}
                  />
                );
              }
            )}
          </div>
        </SimpleShowLayout>
        {open && (<ImagePreviewerDialog src={src} open={open} onClose={() => setOpen(false)} />)}
      </div>
  )
}

export default CheckInAndOutImages
