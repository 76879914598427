import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CookEscalationResolutions } from "../../enums/CookEscalationResolution.";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { CookService } from "../../services/CookServices";
import CkMuiDialog from "./CkMuiDialog/CkMuiDialog";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { TrialsService } from "../../services/TrialServices";
import { OneMealService } from "../../services/OneMealService";
import UserBundleService from "../../services/BundleService";
import ChefForPartyService from "../../services/ChefForPartyService";
import { EscalationService } from "../../services/EscalationServices";
import { getReasonCategory } from "./constants/constant";


const cookService = new CookService();
const trialsService = new TrialsService();
const oneMealService = new OneMealService();
const userBundleService = new UserBundleService();
const chefForPartyService= new ChefForPartyService();
const escalationService = new EscalationService();

interface InputProps {
  cookId: number;
  open: boolean;
  setOpen: (open: boolean) => void;
  refresh: () => void;
  onSubmit: () => void;
}

const CookEscalationDialog = ({
  open,
  setOpen,
  cookId,
  refresh,
  onSubmit,
}: InputProps) => {
  const [escalation, setEscalation] = useState<any>();
  const [module,setModule]=useState<string|null>(null);
  const [actiontype,setActiontype]=useState<string|null>(null);
  const [id,setId]=useState<number|null>(null);
  const [isVerified,setIsVerified]=useState<boolean>(false);
  const [moduleActiontypeArray,setModuleActiontypeArray]=useState<any>([]);
  const [actiontypeArray,setActiontypeArray]=useState<any>([]);
  const [reasonSubreasonArray,setReasonSubreasonArray]=useState<any>([]);
  const [subreasonArray,setSubreasonArray]=useState<any>([]);
  const [reasonCategory, setReasonCategory] = useState<string | null>(null);
  const [reasonSubCategory, setReasonSubCategory] = useState<string | null>(null);
    const handleLoad = async ()=>{
    try {
      const res = await escalationService.getModuleAndAction();
      setModuleActiontypeArray(res.data);

    }  catch (error) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "something went wrong",
        type: CustomSnackbarTypes.ERROR,
      });
    }

  }
  useEffect(()=>{
    handleLoad()
  },[])

  const handlemoduleChange = (e:any)=>{
    setModule(e.target.value);
    let actionstypesElement = moduleActiontypeArray.find((i:any)=>{
      return i.module === e.target.value;
    })
    setActiontypeArray(actionstypesElement.actionTypes);
    setActiontype(null);
    setId(null);
    setReasonCategory(null);
    setReasonSubCategory(null);
    setReasonSubreasonArray([]);
    setSubreasonArray([]);
    setIsVerified(false);
  }

  const handleActiontypeChange = async(e: any)=>{
    if(module!==null)
    {
      let sampleReasonSubReason:any = await getReasonCategory(module,e.target.value);
      setReasonSubreasonArray(sampleReasonSubReason);
    }
    setActiontype(e.target.value);
    setReasonCategory(null);
    setReasonSubCategory(null);
    setIsVerified(false);

  }

  const handleReasonCategoryChange = (event: any) => {
    const selectedCategory:any = reasonSubreasonArray.find(
      (option:any) => option.category === event.target.value
    );

    if (selectedCategory) {
      setSubreasonArray(selectedCategory?.subCategory);
      setReasonCategory(event.target.value);
      setReasonSubCategory(null);
    }
  }

  const handleReasonSubCategoryChange = (event: any) => {
    setReasonSubCategory(event.target.value)
  }

  const handleIdChange = (e:any)=>{
    setId(e.target.value);
    setIsVerified(false);
  }


  const handleChange = (e: any) => {
    if (e.target.name === "resolution") {
      if (e.target.value === CookEscalationResolutions.NONE) {
        setEscalation({
          ...escalation,
          [e.target.name]: null,
        });
        return;
      }
    }
    setEscalation({ ...escalation, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if(module===null || actiontype===null || !reasonCategory){
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Please fill the details to proceed",
        type: CustomSnackbarTypes.ERROR,
      });
      return;
    }
    if(!isVerified){
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Please verify to proceed",
        type: CustomSnackbarTypes.ERROR,
      });
      return;
    }
    if(subreasonArray.length!==0 && reasonSubCategory===null){
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "please select the sub-reason",
        type: CustomSnackbarTypes.ERROR,
      });
      return;
    }
    if (escalation.customerPhone) {
      var regExp = /[a-zA-Z]/g;
      const customerPhone = String(escalation.customerPhone);
      if (customerPhone.length !== 10 || regExp.test(customerPhone)) {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Invalid Customer Phone",
          type: CustomSnackbarTypes.ERROR,
        });
        return;
      }
    }
    let payload ={
      reason:reasonCategory,
      subReason:reasonSubCategory||"",
      referenceId: Number(id),
      referenceModelName:module,
      ...escalation
    }
    await cookService.createEscalation(cookId, payload);
    setOpen(false);
    refresh();
    onSubmit();
  };

  function showSubReason(reasonCategory: string) {
    if (reasonCategory?.toLowerCase() === "others" || reasonCategory?.toLowerCase() === 'other') {
      return (
        <FormControl style={{ width: 500 }}>
          <TextField value={reasonSubCategory} onChange={handleReasonSubCategoryChange} label="Write a reason" />
        </FormControl>
      )
    } else {
      return (
        <FormControl style={{ width: 500 }}>
          <InputLabel id="select-reason">Select a Subreason</InputLabel>
          <Select
            labelId="select-reason"
            id="select-reason"
            defaultValue="Select a reason"
            value={reasonSubCategory}
            onChange={handleReasonSubCategoryChange}
          >
            {subreasonArray?.map((val:any) => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )
    }
  }

  return (
    <CkMuiDialog
      fullWidth={true}
      isDialogOpen={open}
      setDialogOpen={setOpen}
      onDialogClose={() => {}}
      title={"Cook Escalation"}
      primaryLabel={"Create"}
      primaryAction={handleSubmit}
      secondaryLabel={"Close"}
      secondaryAction={() =>{
         setOpen(false);
         setIsVerified(false);
         setActiontype(null);
         setModule(null);
         setReasonCategory(null);
         setReasonSubCategory(null);
         setActiontypeArray([]);
         setReasonSubreasonArray([]);
         setSubreasonArray([]);
        }}
      isLoading={false}
    >
      <Grid justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-module-label" required>
              Module
            </InputLabel>
            <Select
              name="module"
              labelId="demo-simple-module-label"
              id="demo-simple-module-select"
              label="module"
              onChange={handlemoduleChange}
              defaultValue={""}
            >
              {moduleActiontypeArray.map((e:any) => (
                <MenuItem key={e.module} value={e.module}>
                  {e.module}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {
            module && <FormControl fullWidth>
            <InputLabel id="demo-simple-actiontype-label" required>
              ActionType
            </InputLabel>
            <Select
              name="module"
              labelId="demo-simple-actiontype-label"
              id="demo-simple-actiontype-select"
              label="ActionType"
              onChange={handleActiontypeChange}
              defaultValue={""}
            >
              {actiontypeArray.map((e:any) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          }
          {
            module &&<Box display={"flex"} gap={2}>
            <TextField
            fullWidth
            name="customerPhone"
            id="outlined-basic"
            label="id"
            variant="outlined"
            onChange={handleIdChange}
            defaultValue={""}
            value={id}
            ></TextField>
            <Button
            variant="contained"
            disabled = {isVerified}
            onClick={(e)=>{
              if(id===null || module===null || actiontype===null){
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Fill the details to proceed",
                  type: CustomSnackbarTypes.WARNING
                });
                return;
              }
              switch (module) {
                case "TRIAL_V3":
                  trialsService.getTrialsById(id).then((response)=>{
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                      message: "ID Verified",
                      type: CustomSnackbarTypes.SUCCESS,
                    });
                    setIsVerified(true);
                  }).catch((e)=>{
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                      message: "Please Enter the right ID to proceed",
                      type: CustomSnackbarTypes.ERROR
                    })
                  })
                  break;
                case "ONE_MEAL_SERVICE":
                  oneMealService.getOneMealService(id).then((response)=>{
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                      message: "ID Verified",
                      type: CustomSnackbarTypes.SUCCESS,
                    });
                    setIsVerified(true);
                  }).catch((e)=>{
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                      message: "Please Enter the right ID to proceed",
                      type: CustomSnackbarTypes.ERROR
                    })
                  })
                  break;
                case "SUBSCRIPTION":
                  userBundleService.fetchOneUserBundleMapping(id).then((response)=>{
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                      message: "ID Verified",
                      type: CustomSnackbarTypes.SUCCESS,
                    });
                    setIsVerified(true);
                  }).catch((e)=>{
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                      message: "Please Enter the right ID to proceed",
                      type: CustomSnackbarTypes.ERROR
                    })
                  })
                  break;
                case "CHEF_FOR_PARTY_ORDER":
                  chefForPartyService.fetchOneChefForParty(id).then((response)=>{
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                      message: "ID Verified",
                      type: CustomSnackbarTypes.SUCCESS,

                    });
                    setIsVerified(true);
                  }).catch((e)=>{
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                      message: "Please Enter the right ID to proceed",
                      type: CustomSnackbarTypes.ERROR
                    })
                  })
                  break;

              }
            }}
            >verify</Button>
          </Box>
          }
          {
            actiontype && <FormControl fullWidth>
            <InputLabel id="demo-simple-reason-label" required>
              reason
            </InputLabel>
            <Select
              name="reason"
              labelId="demo-simple-reason-label"
              id="demo-simple-reason-select"
              label="Reason"
              onChange={handleReasonCategoryChange}
              defaultValue={""}
            >
              {reasonSubreasonArray.map((e:any) => (
                <MenuItem key={e.category} value={e.category}>
                  {e.category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          }

          {
            reasonCategory && subreasonArray.length!==0 && showSubReason(reasonCategory)
          }



          {/* <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" required>
              Escalation Reason
            </InputLabel>
            <Select
              name="reason"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Escalation Reason"
              onChange={handleChange}
              defaultValue={""}
            >
              {Array.from(CookEscalationTypesArray).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" required>
              Escalation Resolution
            </InputLabel>
            <Select
              name="resolution"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Escalation Resolution"
              onChange={handleChange}
              defaultValue={""}
            >
              {Object.values(CookEscalationResolutions).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="customerPhone"
            id="outlined-basic"
            label="Customer Mobile"
            variant="outlined"
            onChange={handleChange}
            defaultValue={""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="comment"
            id="outlined-basic"
            label="Comment"
            variant="outlined"
            multiline
            rows={4}
            onChange={handleChange}
            defaultValue={""}
          />
        </Grid>
      </Grid>
    </CkMuiDialog>
  );
};

export default CookEscalationDialog;
