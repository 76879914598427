import {
    Datagrid,
    List, NumberField, ShowButton, TextField, TextInput, FunctionField
} from 'react-admin';
import { UserType } from '../../enums/common';
import { useAppSelector } from "../../store/hooks";
import CallButton from '../Common/CallButton/CallButton';
import ResourceSelectDialogButton from "../Common/ResourceSelectDialogButton";

const cookLeavesFilters = [
    <TextInput label="Search by name" source="name" alwaysOn />,
    <TextInput label="Search by Mobile" source="phoneNumber" alwaysOn />
];

const Vendors = (props: any) => {
    const isCustomSelectOpen = useAppSelector((state) => state.customSelect.value.open);
    return (
        <List {...props} filters={cookLeavesFilters}>
            <Datagrid bulkActionButtons={false}>
                {/*Will be active if custom popup is open*/}
                {
                    isCustomSelectOpen &&
                    <ResourceSelectDialogButton />
                }
                <NumberField source='id' sortable={false}/>
                <TextField source='name' sortable={false}/>
                <FunctionField label= "Mobile" render={(record: any) => <CallButton toPhoneNumber={record?.phoneNumber} userType={UserType.VENDOR}/>}/>
                <TextField source='category' sortable={false}/>
                <ShowButton />
            </Datagrid>
        </List>

    );
};

export default Vendors;