enum CancellationReasonsCategories {
    COOK_DID_NOT_REACH = "Cook did not reach",
    CUSTOMER_CANCELLED_THE_TRIAL = "Customer cancelled the trial",
    OPERATIONAL_REASON="Operational Reason",
    PRICING_MISMATCH = "Pricing Mismatch",
    ZERO_TOLERANCE = "Zero Tolerance",
    OTHERS = "Other"

}

export default CancellationReasonsCategories
