import React, {useEffect, useState} from 'react';
import {useRefresh} from "react-admin";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "./CustomSnackBar";
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { TrialsService } from '../../services/TrialServices';
import { MealMapping } from '../../interfaces/Trials/MealMapping';

const trialsService = new TrialsService()

interface InputProps {
    data: any;
    open: boolean;
    callback: () => void;
}

const PrCustomerAcceptDialog = (props: InputProps) => {
    const refresh = useRefresh();
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [startSlot, setStartSlot] = useState<string>('');

    let handleClose = () => {
        props.callback()
    }

    useEffect(() => {
        setStartDate(null);
        setStartSlot('')
    }, [props.open]);

    const SubmitHandller = () => {
      if (!startDate || !startSlot) {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Fill the details to proceed",
          type: CustomSnackbarTypes.WARNING,
        });
        return;
      }
      
      let trialSlotIndex = props?.data?.mealMapping.findIndex((e: MealMapping) => e.slot === props?.data?.slotName)
      let selectSlotIndex = props?.data?.mealMapping.findIndex((e: MealMapping) => e.slot === startSlot)
      if(moment(startDate).format('YYYY-MM-DD') === props?.data.date && selectSlotIndex <= trialSlotIndex){
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Trial Slot and Start Slot can't be same",
          type: CustomSnackbarTypes.WARNING,
        });
        return;
      }
      trialsService.customerAcknowledged(props?.data?.id, {
          startDate: moment(startDate).format("YYYY-MM-DD"),
          startSlot,
        })
        .then((res) => {
          refresh();
          handleClose();
        });
    }
 
    return (
      <Dialog open={props.open} scroll={"paper"} maxWidth="xl">
        <DialogTitle>Customer Accept</DialogTitle>
        <DialogContent>
          <Grid
            container
            columns={1}
            rowSpacing={0.9}
            direction="column"
            style={{ padding: "12px 12px 12px 12px" }}
          >
            <Grid item>
              <FormControl fullWidth>
                <DatePicker
                  label="Select Date"
                  inputFormat="dd MMM yyyy"
                  disableMaskedInput
                  minDate={moment(props?.data?.date).toDate()}
                  maxDate={moment(props?.data?.date).add(30, "d").toDate()}
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  renderInput={(params) => {
                    return <TextField {...params} />;
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <InputLabel id="for_startSlot">Start Slot</InputLabel>
                <Select
                  labelId="for_startSlot"
                  id="startSlot"
                  defaultValue={""}
                  label="StartSlot"
                  onChange={(event) => setStartSlot(event.target.value)}
                >
                  {props.data &&
                    props.data.mealMapping.map((e: any, i: number) => (
                      <MenuItem key={i} value={e.slot}>
                        {e.slot}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={SubmitHandller}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              handleClose();
              setStartDate(null);
              setStartSlot("");
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
};

export default PrCustomerAcceptDialog;
