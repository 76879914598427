import { Show, SimpleShowLayout, TextField, NumberField } from "react-admin";

const BundleShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source='name' />
            <NumberField source='paidDays' />
            <NumberField source='freeDays' />
            <NumberField source='graceDays' />
        </SimpleShowLayout>
    </Show>
);

export default BundleShow