import { useState } from "react";
import { Button, Stack, Button as MUIButton } from "@mui/material";
import {
  Show,
  Tab,
  TabbedShowLayout,
  useRecordContext,
  useRefresh,
} from "react-admin";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { PaymentStatusEnum } from "../../enums/PaymentStatusEnum";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import { PaymentLinkDailog } from "../Common/PaymentLinksDailogs";
import StarCleanerVisitMetaShow from "./StarCleanerVisitMetaShow";
import { StarCleanerVisitStatus } from "../../enums/StarCleanerVisitStatus";
import StarCleanerVisitService from "../../services/StarCleanerVisitService";
import StarCleanerRefundDialog from "../Common/StarCleanerRefundDialog";
import { AddNoteStarCleanerVisit } from "./components/AddNotesDialog";
import { RescheduleStarCleanerVisitDialog } from "./components/RescheduleStarCleanerVisitDialog";
import { CancelStarCleanerDialog } from "./components/CancelStarCleanerDialog";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import Vendors from "../Vendors/Vendor";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useLocation } from "react-router-dom";
import { UserGroupsEnum } from "../../enums/UserGroupsEnum";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";

const starCleanerService = new StarCleanerVisitService();

interface InputProps {
  name: any;
  callback: (data: any) => void;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
}

const CustomButton = (props: InputProps) => {
  let record = useRecordContext();
  return (
    <MUIButton
      onClick={() => {
        props.callback(record);
      }}
      size={"small"}
      color={props.color || "secondary"}
      variant={"contained"}
      style={{ width: "max-content", fontSize: "12px" }}
    >
      {props.name}
    </MUIButton>
  );
};

export const ActionButtons = (props: any) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const userDetails = useAppSelector((state) => state.userDetails.value);
  let groupsArray: string[] = userDetails.adminUser.accessGroups;

  const onClickSendPaymentLink = () => {
    EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
      message: "Are you sure you want to send the payment link?",
      onClose: (confirmed: boolean) => {
        if (confirmed) {
          starCleanerService
            .sendStarCleanerPaymentLink(parseInt((record as any)?.id))
            .then((res) => {
              props.setPaymentDialog(true);
              props.setPaymentLinks(res);
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Payment link sent",
                type: CustomSnackbarTypes.SUCCESS,
              });
            });
        }
      },
      title: "Confirmation",
    });
  };

  return (
    <>
      <Stack direction={"row"} gap={2}>
        {[PaymentStatusEnum.PAID].includes(record?.paymentStatus) &&
          [
            StarCleanerVisitStatus.PENDING,
            StarCleanerVisitStatus.ALLOCATED,
          ].includes(record?.status) && (
            <CustomButton
              name={
                record?.status === StarCleanerVisitStatus.PENDING
                  ? "Assign Cleaner"
                  : "Change Cleaner"
              }
              color={"primary"}
              callback={(data: any) => {
                props.setSelectedData(data);
                props.setAssignCleaner(true);
                props.handleCleanerOpenList();
              }}
            />
          )}
        {[
          StarCleanerVisitStatus.PENDING,
          StarCleanerVisitStatus.ALLOCATED,
        ].includes(record?.status) && (
          <>
            <CustomButton
              name={"Cancel"}
              color="error"
              callback={(data: any) => {
                props.setSelectedData(data);
                props.setCancelModel(true);
              }}
            />
            <CustomButton
              name={"Reschedule"}
              color="primary"
              callback={(data: any) => {
                props.setSelectedData(data);
                props.setRescheduleModel(true);
              }}
            />
            <CustomButton
              name={"Add Note"}
              color="primary"
              callback={(data: any) => {
                props.setSelectedData(data);
                props.setNoteModel(true);
              }}
            />
          </>
        )}

        {record?.status === StarCleanerVisitStatus.ALLOCATED && (
          <CustomButton
            name={"Mark as Fulfilled"}
            color="primary"
            callback={() => {
              EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
                message: "Are you sure you want to mark this Visit fulfilled?",
                onClose: (confirmed: boolean) => {
                  if (confirmed) {
                    starCleanerService
                      .markFulfilled(parseInt((record as any).id))
                      .then((res) => {
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                          message: "StarCleanerVisit marked fulfilled",
                          type: CustomSnackbarTypes.SUCCESS,
                        });
                        refresh();
                      });
                  }
                },
                title: "Confirmation",
              });
            }}
          />
        )}

        {GroupPermissionFunction(groupsArray, [
          UserGroupsEnum.ADMIN,
          UserGroupsEnum.MANAGER,
        ]) &&
          record?.paymentStatus === PaymentStatusEnum.PAID && (
            <CustomButton
              name={"Refund"}
              color="error"
              callback={(data: any) => {
                props.setSelectedData(data);
                props.setrefundStarCleanerDialog(true);
              }}
            />
          )}
        {![
          StarCleanerVisitStatus.CANCELLED,
          StarCleanerVisitStatus.FULFILLED,
        ].includes(record?.status) &&
          record?.paymentStatus === PaymentStatusEnum.NOT_PAID && (
            <Button
              color="primary"
              variant={"contained"}
              onClick={onClickSendPaymentLink}
            >
              {"Send Payment Link"}
            </Button>
          )}
      </Stack>
    </>
  );
};

const StarCleanerServiceTabs = () => {
  return (
    <TabbedShowLayout syncWithLocation={false}>
      <Tab label={"Details"} key={"Details"}>
        <StarCleanerVisitMetaShow />
      </Tab>
    </TabbedShowLayout>
  );
};

const StarCleanerServiceShow = (props: any) => {
  let [paymentDialog, setPaymentDialog] = useState<boolean>(false);
  let [paymentLinks, setPaymentLinks] = useState();
  let [selectedData, setSelectedData] = useState<any>(null);
  const [assignCleaner, setAssignCleaner] = useState<boolean>(false);
  const [cancelModel, setCancelModel] = useState<boolean>(false);
  const [noteModel, setNoteModel] = useState<boolean>(false);
  const [rescheduleModel, setRescheduleModel] = useState<boolean>(false);
  let [refundStarCleanerDialog, setrefundStarCleanerDialog] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();
  const refresh = useRefresh();
  const location = useLocation();
  const visitId = location.pathname.split("/")[2];

  const handleCleanerClose = (data: any) => {
    setAssignCleaner(false);
    starCleanerService
      .assignCleanerToVisit(Number(visitId), { cleanerId: data.id })
      .then(() => {
        refresh();
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: `Star cleaner Updated Successfully`,
          type: CustomSnackbarTypes.SUCCESS,
        });
      })
      .catch(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: `Star Cleaner was not updated `,
          type: CustomSnackbarTypes.ERROR,
        });
      });
  };

  const handleCleanerOpenList = () => {
    dispatch(
      setCustomSelectOpen({
        open: true,
      })
    );
  };

  return (
    <Show {...props} actions={false}>
      <div>
        <StarCleanerRefundDialog
          data={selectedData}
          open={refundStarCleanerDialog}
          callback={() => {
            setrefundStarCleanerDialog(false);
          }}
        />
        {assignCleaner && (
          <ResourceSelectDialog
            name={"Vendors"}
            list={Vendors}
            onClose={(data: any) => handleCleanerClose(data)}
          />
        )}
        {noteModel && (
          <AddNoteStarCleanerVisit
            data={selectedData}
            open={noteModel}
            callback={() => setNoteModel(false)}
          />
        )}
        {rescheduleModel && (
          <RescheduleStarCleanerVisitDialog
            data={selectedData}
            open={rescheduleModel}
            callback={() => setRescheduleModel(false)}
          />
        )}
        {cancelModel && (
          <CancelStarCleanerDialog
            data={selectedData}
            open={cancelModel}
            callback={() => {
              setCancelModel(false);
            }}
          />
        )}
        <PaymentLinkDailog
          data={paymentLinks}
          open={paymentDialog}
          callback={() => {
            setPaymentDialog(false);
          }}
        />
        <Show {...props} actions={false}>
          <ActionButtons
            handleCleanerOpenList={handleCleanerOpenList}
            setSelectedData={setSelectedData}
            setNoteModel={setNoteModel}
            setPaymentDialog={setPaymentDialog}
            setrefundStarCleanerDialog={setrefundStarCleanerDialog}
            setPaymentLinks={setPaymentLinks}
            setRescheduleModel={setRescheduleModel}
            setCancelModel={setCancelModel}
            setAssignCleaner={setAssignCleaner}
          />
          <StarCleanerServiceTabs />
        </Show>
      </div>
    </Show>
  );
};

export default StarCleanerServiceShow;
