export enum AllCookOnBoardingSubStatus {
  NO_CALL_DONE = "NO_CALL_DONE",
  FIRST_CALL_DONE = "1st_CALL_DONE",
  SECOND_CALL_DONE = "2nd_CALL_DONE",
  THIRD_CALL_DONE = "3rd_CALL_DONE",
  LOST_LEAD = "LOST_LEAD",
  JUNK_LEAD = "JUNK_LEAD",
  PENDING = "PENDING",
  KIT_DISTRIBUTION_PENDING = "KIT_DISTRIBUTION_PENDING",
  KIT_CONFIRMATION_PENDING = "KIT_CONFIRMATION_PENDING",
  PAYOUT_DISCUSSION_PENDING = "PAYOUT_DISCUSSION_PENDING",
  COMPLETE = "COMPLETE",
}
