import { Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import {
  Show,
  Tab,
  TabbedShowLayout,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { RenewalsService } from "../../services/RenewalsService";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import AdminUserList from "../AdminUser/AdminUserList";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import RenewalsMetaShow from "./RenewalsMetaShow";
import { useAppDispatch } from "../../store/hooks";
import { useLocation } from "react-router-dom";
import CookFollowUpDialog from "../Common/CookFollowUpDialog";
import CustomerFollowUpDialog from "../Common/CustomerFollowUpDialog";
import EscalationRenewal from "../Common/EscalationRenewal";
import { CancelBundleDialog } from "../Common/CancelBundleDialog";
import UserBundleService from "../../services/BundleService";
import { RenewalResponse } from "../../interfaces/Renewals/RenewalsResponse";
import RenewalBundleDetailsShow from "./RenewalBundleDetailsShow";
import CustomerFollowUpTab from "./CustomerFollowUpTab";
import CookFollowUpTab from "./CookFollowUpTab";
import UserBundlePrTab from "../UserBundleMapping/UserBundlePrTab";
import { PaymentLinkDailog } from "../Common/PaymentLinksDailogs";
import { UserBundlesResponse } from "../../interfaces/UserBundleMapping/UserBundlesResponse";
import BundleList from "../Bundle/BundleList";
import {useNavigate} from "react-router-dom";

const renewalService = new RenewalsService();
const userBundleService = new UserBundleService();

const CustomButton = (props: any) => {
  let record = useRecordContext();

  return (
    <Button
      onClick={() => {
        props.callback(record);
      }}
      size={"small"}
      color={props.color || "secondary"}
      variant={"contained"}
      style={{ width: "max-content", fontSize: "12px" }}
    >
      {props.name}
    </Button>
  );
};

export const ActionButtons = (props: any) => {
  let record = useRecordContext();
  const isTerminalState = props.renewalData
    ? props.renewalData?.paymentStatus?.toLowerCase() === "paid" ||
      props.renewalData?.status?.toLowerCase() === "closed" ||
      props.renewalData?.status?.toLowerCase() === "fulfilled"
    : true;

  return (
    <div>
      <Stack direction={"row"} gap={2}>
        {!isTerminalState && (
          <CustomButton
            name={"Renew Bundle"}
            color={"primary"}
            callback={() => {
              props.handleOpenBundleList();
              props.setActivateBundle(true);
            }}
          />
        )}
        {!isTerminalState && (
          <CustomButton
            name={"Close Bundle"}
            color={"primary"}
            callback={(data: any) => {
              props.setSelectedData(data);
              props.setCancelBundle(true);
            }}
          />
        )}
        {!isTerminalState && (
          <CustomButton
            name={props?.renewalData?.hasEscalations? "Resolve Esclation": "Create Escalation"}
            color={"primary"}
            callback={(data: RenewalResponse) => {
              props.setSelectedData(data);
              props.setEscalation(true);
            }}
          />
        )}
        {!isTerminalState && (
          <CustomButton
            name={"Change Assignee"}
            color={"primary"}
            callback={() => {
              props.handleAdminOpenList();
              props.setChangeOwner(true);
            }}
          />
        )}
        {
          <CustomButton
            name={"Cook Follow-up"}
            color={"primary"}
            callback={(data: RenewalResponse) => {
              props.setSelectedData(data);
              props.setCookFollowUp(true);
            }}
          />
        }
        {
          <CustomButton
            name={"Customer Follow-up"}
            color={"primary"}
            callback={(data: RenewalResponse) => {
              props.setSelectedData(data);
              props.setCustomerFollowUp(true);
            }}
          />
        }
        {!isTerminalState && (
          <CustomButton
            name={"Send Renewal Link"}
            color={"primary"}
            callback={async () => {
              await userBundleService
                .sendRenewalPaymentLink(Number(record?.userBundleMappingId))
                .then((res) => {
                  props.setPaymentDailog(true);
                  props.setPaymentLinks(res.data);
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Renewal link sent",
                    type: CustomSnackbarTypes.SUCCESS,
                  });
                });
            }}
          />
        )}
      </Stack>
    </div>
  );
};

const RenewalsShow = (props: any) => {
  const [changeOwner, setChangeOwner] = useState<boolean>(false);
  const [cookFollowUp, setCookFollowUp] = useState<boolean>(false);
  const [customerFollowUp, setCustomerFollowUp] = useState<boolean>(false);
  const [escalation, setEscalation] = useState<boolean>(false);
  const [activateBundle, setActivateBundle] = useState<boolean>(false);
  const [cancelBundle, setCancelBundle] = useState<boolean>(false);
  let [paymentLinks, setPaymentLinks] = useState<any>();
  let [selectedData, setSelectedData] = useState<any>(null);
  let [paymentDailog, setPaymentDailog] = useState<boolean>(false);
  const [renewalData, setRenewalData] = useState<any>();
  const [userBundleData, setUserBundleData] = useState<
    UserBundlesResponse | undefined
  >(undefined);
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const refresh = useRefresh();
  const location = useLocation();
  const renewalId = location.pathname.split("/")[2];
  const handleOwnerClose = (data: any) => {
    setChangeOwner(false);
    renewalService
      .changeAssigneForRenewal(Number(renewalId), data?.id)
      .then(() => {
        refresh();
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: `Trial Owner Updated Successfully`,
          type: CustomSnackbarTypes.SUCCESS,
        });
      });
  };

  const handleCloseRenew = (data: any) => {
    setActivateBundle(false);
    userBundleService
      .renewUserBundle(Number(renewalData?.userBundleMappingId), {
        bundleId: data?.id,
      })
      .then((response: any) => {
        refresh();
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: `Success`,
          type: CustomSnackbarTypes.SUCCESS,
        });
        navigate(`/UserBundles/${response?.data?.id}/show`);
      });
  };

  const handleOpenBundleList = () => {
    dispatch(
      setCustomSelectOpen({
        open: true,
      })
    );
  };

  const handleAdminOpenList = () => {
    dispatch(
      setCustomSelectOpen({
        open: true,
      })
    );
  };

  useEffect(() => {
    try {
      const fetchData = async () => {
        const renewalData = await renewalService.getOneRenewals(
          Number(renewalId)
        );
        const userBundleResponse =
          await userBundleService.fetchOneUserBundleMapping(
            Number(renewalData?.data.userBundleMappingId)
          );
        setUserBundleData(userBundleResponse?.data);
        setRenewalData(renewalData?.data);
      };
      fetchData();
    } catch (error) {
      console.log((error as Error)?.message);
    }
  }, [renewalId]);

  return (
    <Show>
      <div>
        {changeOwner && (
          <ResourceSelectDialog
            name={"AdminUsers"}
            list={AdminUserList}
            onClose={(data: any) => handleOwnerClose(data)}
          />
        )}
        {cookFollowUp && (
          <CookFollowUpDialog
            data={selectedData}
            open={cookFollowUp}
            callback={() => setCookFollowUp(false)}
          />
        )}
        {customerFollowUp && (
          <CustomerFollowUpDialog
            data={selectedData}
            open={customerFollowUp}
            callback={() => setCustomerFollowUp(false)}
          />
        )}
        {escalation && (
          <EscalationRenewal
            data={selectedData}
            open={escalation}
            callback={() => {
              refresh();
              setEscalation(false)
            }}
          />
        )}
        {activateBundle && (
          <ResourceSelectDialog
            name={"Bundles"}
            list={BundleList}
            onClose={(data: any) => handleCloseRenew(data)}
          />
        )}
        {cancelBundle && (
          <CancelBundleDialog
            data={userBundleData}
            open={cancelBundle}
            callback={() => {setCancelBundle(false)}}
          />
        )}
        {
          <PaymentLinkDailog
            data={paymentLinks}
            open={paymentDailog}
            callback={(data) => {
              setPaymentDailog(false);
            }}
          />
        }
        <Show {...props} actions={false}>
          <ActionButtons
            handleOpenBundleList={handleOpenBundleList}
            handleAdminOpenList={handleAdminOpenList}
            setSelectedData={setSelectedData}
            setChangeOwner={setChangeOwner}
            setCookFollowUp={setCookFollowUp}
            setCustomerFollowUp={setCustomerFollowUp}
            setEscalation={setEscalation}
            setCancelBundle={setCancelBundle}
            setActivateBundle={setActivateBundle}
            setPaymentDailog={setPaymentDailog}
            setPaymentLinks={setPaymentLinks}
            renewalData={renewalData}
          />
          <TabbedShowLayout>
            <Tab label={"Renewals Details"}>
              <RenewalsMetaShow />
            </Tab>
            <Tab label={"Bundle Details"}>
              <RenewalBundleDetailsShow />
            </Tab>
            <Tab label={"Permanent Replacement"}>
              <UserBundlePrTab
                userBundleMappingId={Number(renewalData?.userBundleMappingId)}
              />
            </Tab>
            <Tab label={"Customer Follow-up"}>
              <CustomerFollowUpTab />
            </Tab>
            <Tab label={"Cook Follow-Up"}>
              <CookFollowUpTab />
            </Tab>
          </TabbedShowLayout>
        </Show>
      </div>
    </Show>
  );
};

export default RenewalsShow;
