import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Dialog,
  DialogActions,
} from "@mui/material";
import { useEffect, useState } from "react";
import AssignAgentDialog from "../Common/AssignAgentDialog";
import ChangeCookStatusDialog from "../Common/ChangeCookStatusDialog";
import UpdateCookDetailsDialog from "../Common/UpdateCookDetailsDialog";
import CookProfileShow from "./CookProfileShow";
import FreshLeadInterface from "../../interfaces/Cook/FreshLeadInterface";
import { CookService } from "../../services/CookServices";
import AddCommentDialogCookProfile from "../Common/AddCommentDialogCookProfile";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import HubsList from "../Hubs/HubsList";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import { DataGrid, GridValueGetterParams } from "@mui/x-data-grid";
import SkillAssessmentTable from "./SkillAssessmentShow";
import { FreshLeadStatus } from "../../enums/FreshLeadStatus";
import { setCookProfileData } from "../../store/reducers/cookProfileData";
import { CookWorkStatus } from "../../enums/CookWorkStatus";
// import ChangeCookTypeDialog from "../Common/ChangeCookTypeDialog";
import ConfirmationButton from "../Common/ConfirmationButton";
import InventoryKit from "../../interfaces/Cook/InventoryKit";
import { InventoryKitEnum } from "../../enums/InventoryKitEnum";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";
import { UserGroupsEnum } from "../../enums/UserGroupsEnum";
import { CookVerificationStatus } from "../../enums/VerificationStatus";
import ChangeCookServiceTypeDialog from "../Common/CookServiceTypeDialog";
import RemoveCookServiceTypeDialog from "../Common/RemoveCookServiceTypeDialog";
import {AllCookOnBoardingSubStatus} from "../../enums/AllCookOnboardingSubStatus";
import ChangeCookVerificationAndSeverity from "../Common/ChangeCookVerificationAndSeverity";
const cookService = new CookService();
interface InputProps {
  cookId: number;
}

interface KitInterface {
  openRequestKitDialog: boolean;
  setOpenRequestKitDialog: (value: boolean) => void;
  cookId: number;
  refresh: () => void;
  inventoryDetails: InventoryKit[];
}

const alertUser = (message: string) => {
  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
    message: message,
    type: CustomSnackbarTypes.SUCCESS,
  });
};

interface CustomCookKitFormEntry {
  id?: number;
  inventoryName: string;
  inventoryCount?: number;
}

const CustomCookKitForm = (props: KitInterface) => {
  const [rows, setRows] = useState<CustomCookKitFormEntry[]>([]);

  useEffect(() => {
    const mappedInventories: Array<CustomCookKitFormEntry> = [];
    Object.values(InventoryKitEnum).forEach((value, index) => {
      mappedInventories.push({
        id: index,
        inventoryName: value,
        inventoryCount: 0,
      });
    });

    mappedInventories.forEach((inventory) => {
      props?.inventoryDetails?.forEach((inventoryDetail) => {
        if (inventory.inventoryName === inventoryDetail.inventoryName) {
          inventory.inventoryCount = inventoryDetail.inventoryCount;
        }
      });
    })

    setRows(mappedInventories);
  },[props?.cookId, props?.inventoryDetails])

  const submitHandler = async () => {
    const mappedInventories: Array<{
      inventoryName: string;
      inventoryCount: number;
    }> = [];
    rows.forEach((key) => {
      mappedInventories.push({
        inventoryName: key?.inventoryName,
        inventoryCount: key?.inventoryCount || 0,
      });
    });
    const payload = {
      inventories: mappedInventories,
    };
    const response = await cookService.updateKitDistribution(
      props.cookId,
      payload
    );
    if (response.status === 200) {
      alertUser("Successful");
      setRows([]);
      props.setOpenRequestKitDialog(false);
      props.refresh();
    }
  };

  const columns: any = [
    { field: "inventoryName", headerName: "Name", minWidth: 200, flex: 1 },
    {
      field: "inventoryCount",
      headerName: "Count",
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <TextField
            variant="outlined"
            type="number"
            value={
              params?.row?.inventoryCount
            }
            sx={{ mt: "0px", mb: "0px" }}
            fullWidth
            inputProps={{ min: 0 }}
            onChange={(e) => {
              setRows([...rows].map((row) => {
                if(row.id === params.row.id) {
                  return {
                    ...row,
                    inventoryCount: parseInt(e.target.value)
                  }
                }
                return row;
              }))
            }}
          />
        );
      },
    },
  ];

  if (rows.length === 0) return null;

  return (
    <Box>
      <Dialog
        open={props.openRequestKitDialog}
        onClose={() => {
          props.setOpenRequestKitDialog(false);
        }}
      >
        <Box p="1rem">
          <Box sx={{ height: 470, width: "500px" }}>
            <DataGrid
              sx={{
                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
              }}
              rows={rows}
              columns={columns}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              hideFooter={true}
            />
          </Box>
        </Box>
        <DialogActions>
          <Button
            onClick={() => {
              props.setOpenRequestKitDialog(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={submitHandler} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const CookProfile = (props: InputProps) => {
  const [assignAgentDialog, setAgentAssignDialog] = useState<boolean>(false);
  const [changeStatusDialog, setChangeStatusDialog] = useState<boolean>(false);
  const [updateCookProfileDialog, setUpdateCookProfileDialog] =
    useState<boolean>(false);
  const [addCommentDialog, setAddCommentDialog] = useState<boolean>(false);
  const [data, setData] = useState<FreshLeadInterface>();
  const [hubList, setHubList] = useState<boolean>(false);
  // const [changeCookTypeDialog, setChangeCookTypeDialog] =
  //   useState<boolean>(false);
  const [openRequestKitDialog, setOpenRequestKitDialog] = useState<boolean>(false);
  const [addCookServiceTypesDialog, setAddCookServiceTypesDialog] = useState<boolean>(false);
  const [removeCookServiceTypeDialog, setRemoveCookServiceTypeDialog] = useState<boolean>(false);
  const [removeCookVerificationAndSeverity, setRemoveCookVerificationAndSeverity] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const userDetails = useAppSelector((state) => state.userDetails.value);
  let groupsArray: string[] = userDetails.adminUser.accessGroups;
  const handleHubListDialogOpen = () => {
    setHubList(true);
    dispatch(
      setCustomSelectOpen({
        open: true,
      })
    );
  };

  const refresh = () => {
    cookService
      .getCookByLead(props?.cookId)
      .then((res) => {
        setData(res.data);
        dispatch(setCookProfileData(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCreateWebsiteUrl = async (cookId: number) => {
    try {
      await cookService.patchCookProfileSlug(cookId);
      refresh();
    } catch(err) {
      console.log(err);
    }
  }

  useEffect(() => {
    cookService
      .getCookByLead(props?.cookId)
      .then((res) => {
        setData(res.data);
        dispatch(setCookProfileData(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    dispatch,
    props?.cookId,
    assignAgentDialog,
    changeStatusDialog,
    addCommentDialog,
    updateCookProfileDialog,
  ]);

  if (!data) return <></>;

  return (
    <div>
      <Stack direction="row" spacing={2}>
        <Grid container spacing={2} justifyContent="flex-end">
          {
            GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER]) &&
            ([CookVerificationStatus.REPORT_GENERATED].includes(data?.verificationStatus)) &&
            (data?.verificationReportSeverity !== "Clear") &&
            (
              <Grid item>
                <ConfirmationButton
                  buttonLabel="Mark Authbridge Report Clear"
                  onSuccess={async () => {
                    const response = await cookService.overrideAuthBridge(
                      props.cookId
                    );
                    if (response.status === 200) {
                      alertUser("Successful");
                    }
                    refresh();
                  }}
                  title="Are you sure ?"
                  buttonProps={{color:"secondary"}}
                />
              </Grid>
            )
          }
          <Grid item>
            <Button
              onClick={() => {
                setAddCookServiceTypesDialog(true);
              }}
              color={"secondary"}
              style={{ width: "max-content", fontSize: "12px" }}
              size="small"
              variant="text"
            >
              Add Cook Service Types
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                setRemoveCookServiceTypeDialog(true);
              }}
              color={"secondary"}
              style={{ width: "max-content", fontSize: "12px" }}
              size="small"
              variant="text"
            >
              Remove Cook Service Type
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                setRemoveCookVerificationAndSeverity(true);
              }}
              color={"secondary"}
              style={{ width: "max-content", fontSize: "12px" }}
              size="small"
              variant="text"
            >
              Change Cook Verification And Severity
            </Button>
          </Grid>
          {/* <Grid item>
            <Button
              onClick={() => {
                setChangeCookTypeDialog(true);
              }}
              color={"secondary"}
              style={{ width: "max-content", fontSize: "12px" }}
              size="small"
              variant="text"
            >
              Change Cook Type
            </Button>
          </Grid> */}
          <Grid item>
            <Button
              onClick={() => {
                setAgentAssignDialog(true);
              }}
              color={"secondary"}
              style={{ width: "max-content", fontSize: "12px" }}
              size="small"
              variant="text"
            >
              Assign Agent
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                setChangeStatusDialog(true);
              }}
              color={"secondary"}
              style={{ width: "max-content", fontSize: "12px" }}
              size="small"
              variant="text"
            >
              Change Status
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                setAddCommentDialog(true);
              }}
              color={"secondary"}
              style={{ width: "max-content", fontSize: "12px" }}
              size="small"
              variant="text"
            >
              Add Comment
            </Button>
          </Grid>
          {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN]) &&
            <Grid item>
              <Button
                onClick={() => {
                  handleCreateWebsiteUrl(props?.cookId);
                }}
                disabled={[AllCookOnBoardingSubStatus.COMPLETE].includes(data?.onboardingSubStatus) && [CookWorkStatus.FREE_TO_WORK].includes(data?.workStatus)}
                size="small"
                variant="text"
                color={"secondary"}
                style={{ width: "max-content", fontSize: "12px" }}
              >
                Quick onboard
              </Button>
            </Grid>
          }
          <Grid item>
            <Button
              onClick={() => {
                handleHubListDialogOpen();
              }}
              size="small"
              variant="text"
              color={"secondary"}
              style={{ width: "max-content", fontSize: "12px" }}
            >
              Update Hub
            </Button>
          </Grid>
          {[FreshLeadStatus.SKILL_ASSESSMENT].includes(
            data?.onboardingStatus
          ) && (
            <Grid item>
              <ConfirmationButton
                buttonLabel="Assessment Complete"
                onSuccess={async () => {
                  const response = await cookService.markAssessmentComplete(
                    props.cookId
                  );
                  if (response.status === 200) {
                    alertUser("Successful");
                  }
                  refresh();
                }}
                title="Are you sure ?"
                buttonProps={{color:"secondary"}}
              />
            </Grid>
          )}
          {[FreshLeadStatus.APPROVAL_CHECKS].includes(
            data?.onboardingStatus
          ) && (
            <Grid item>
              <ConfirmationButton
                buttonLabel="Approval Done"
                onSuccess={async () => {
                  const response = await cookService.markApprovalVerification(
                    props.cookId
                  );
                  if (response.status === 200) {
                    alertUser("Successful");
                  }
                  refresh();
                }}
                title="Are you sure ?"
                buttonProps={{color:"secondary"}}
              />
            </Grid>
          )}
          {[FreshLeadStatus.TRAINING].includes(data?.onboardingStatus) &&
            (data?.trainingRating?.appTraining >= 0) &&
            (
              <Grid item>
                <ConfirmationButton
                  buttonLabel="Training Complete"
                  onSuccess={async () => {
                    const response = await cookService.markTrainingComplete(
                      props.cookId
                    );
                    if (response.status === 200) {
                      alertUser("Successful");
                    }
                    refresh();
                  }}
                  title="Are you sure ?"
                  buttonProps={{color:"secondary"}}
                />
              </Grid>
            )}
          <Grid item>
            <ConfirmationButton
                buttonLabel="Initiate Verification"
                onSuccess={async () => {
                  const response = await cookService.markAuthbridgeVerification(
                      props.cookId
                  );
                  if (response.status === 200) {
                    alertUser("Successful");
                  }
                  refresh();
                }}
                title="Are you sure ?"
                buttonProps={{color:"secondary"}}
            />
          </Grid>
          {[FreshLeadStatus.ONBOARDING].includes(data?.onboardingStatus) &&
            [CookWorkStatus.ONBOARDING_INCOMPLETE].includes(
              data?.workStatus
            ) && (
              <Grid item>
                <ConfirmationButton
                  buttonLabel="Onboarding Complete"
                  onSuccess={async () => {
                    const response = await cookService.markOnboardingComplete(
                      props.cookId
                    );
                    if (response.status === 200) {
                      alertUser("Successful");
                    }
                    refresh();
                  }}
                  title="Are you sure ?"
                  buttonProps={{color:"secondary"}}
                />
              </Grid>
            )}
            {
              [FreshLeadStatus.ONBOARDING].includes(data?.onboardingStatus) &&
              <Grid item>
                <Button
                    variant="text"
                    onClick={() => {
                      setOpenRequestKitDialog(true);
                    }}
                    size="small"
                    color={"secondary"}
                    style={{ width: "max-content", fontSize: "12px" }}
                  >
                  Request Kit
                </Button>
              </Grid>
          }
          <Grid item>
            {data?.onboardingStatus === FreshLeadStatus.FRESH_LEAD ? (
              <Button
                onClick={() => {
                  setUpdateCookProfileDialog(true);
                }}
                size="small"
                color="error"
                variant="contained"
              >
                Add Profile
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setUpdateCookProfileDialog(true);
                }}
                size="small"
                color="error"
                variant="contained"
              >
                Update Profile
              </Button>
            )}
          </Grid>
        </Grid>
      </Stack>
      <CustomCookKitForm
        cookId={props.cookId}
        refresh={refresh}
        inventoryDetails={data.kitDistributionCount as InventoryKit[]}
        openRequestKitDialog={openRequestKitDialog}
        setOpenRequestKitDialog={setOpenRequestKitDialog}
      />
      {/* <ChangeCookTypeDialog
        cookDetail={data as FreshLeadInterface}
        isOpen={changeCookTypeDialog}
        setIsOpen={setChangeCookTypeDialog}
        refresh={refresh}
      /> */}
      <AssignAgentDialog
        cookDetail={data as FreshLeadInterface}
        isOpen={assignAgentDialog}
        setIsOpen={setAgentAssignDialog}
        refresh={refresh}
      />
      <ChangeCookStatusDialog
        cookDetail={data as FreshLeadInterface}
        isOpen={changeStatusDialog}
        setIsOpen={setChangeStatusDialog}
        refresh={refresh}
      />
      <UpdateCookDetailsDialog
        cookDetail={data as FreshLeadInterface}
        isOpen={updateCookProfileDialog}
        setIsOpen={setUpdateCookProfileDialog}
        isFirstTimeRequest={
          data.onboardingStatus === FreshLeadStatus.FRESH_LEAD ? true : false
        }
        refresh={refresh}
      />
      <AddCommentDialogCookProfile
        cookDetail={data as FreshLeadInterface}
        isOpen={addCommentDialog}
        setIsOpen={setAddCommentDialog}
        refresh={refresh}
      />
      <ChangeCookServiceTypeDialog
        cookDetail={data as FreshLeadInterface}
        isOpen={addCookServiceTypesDialog}
        setIsOpen={setAddCookServiceTypesDialog}
        refresh={refresh}
      />
      <RemoveCookServiceTypeDialog
        cookDetail={data as FreshLeadInterface}
        isOpen={removeCookServiceTypeDialog}
        setIsOpen={setRemoveCookServiceTypeDialog}
        refresh={refresh}
      />
      <ChangeCookVerificationAndSeverity
      cookDetail={data as FreshLeadInterface}
      isOpen={removeCookVerificationAndSeverity}
      setIsOpen={setRemoveCookVerificationAndSeverity}
      refresh={refresh}
      />
      <CookProfileShow data={data as FreshLeadInterface} />
      {data.onboardingStatus && (
        <SkillAssessmentTable cookDetail={data as FreshLeadInterface} />
      )}
      {hubList && (
        <ResourceSelectDialog
          name="Hubs"
          list={HubsList}
          onClose={async (hub: { id: number }) => {
            if (hub) {
              const response = await cookService.updateHub(props.cookId, {
                hubId: hub.id,
              });
              if (response.status === 200) {
                if (response.status === 200) {
                  alertUser("Successful");
                }
              }
            }
            refresh();
            setHubList(false);
          }}
        />
      )}
    </div>
  );
};

export default CookProfile;
