import React from "react";
import {
  DateField,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { ZohoBaseUrl, ZohoModulesUrl } from "../../enums/zoho";
import LinkField from "../Common/LinkField";

const CustomerMetaShow = (props: any) => {
  return (
    <Show {...props} actions={false}>
      <SimpleShowLayout>
        <TextField label="First name" source="fname" />
        <TextField label="Last name" source="lname" />
        <TextField label="Middle name" source="mname" />
        <NumberField label="Mobile" source="mobile" />
        <NumberField label="Id" source="id" />
        <TextField label="Tag" source="tag" />
        <NumberField label="AccountId" source="accountId" />
        <NumberField label="Account Status" source="accountStatus" />
        <TextField label="Address" source="address" />
        <TextField label="ContactId" source="contactId" />
        <TextField label="Email" source="email" />
        <LinkField
          source="zohoId"
          href={`${ZohoBaseUrl}${ZohoModulesUrl.CUSTOMER}`}
          sourceRoute
          targetBlank
        />
        <DateField
          label="Last activity time"
          source="Last_Activity_Time"
          showTime
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default CustomerMetaShow;
