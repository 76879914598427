import HttpClient from "../HttpClient";
import { GetListResult, UpdateResult } from "react-admin";
import { ExtensionResponse } from "../interfaces/Extension/ExtensionResponse";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";

export class ExtensionService {
    async getAllExtension(limit: number, offset: number, filters: {}, sort: {}): Promise<GetListResult<ExtensionResponse>> {
        filters = ConstructSequelizeFilters(filters, [], ['status'])
        let data = await HttpClient.get('/centaurus/extension/all', {
            params: {
                limit, 
                offset,
                filters,
                sort
            }
        })
        return {
            data: data.data.data,
            total: data.data.count
        }
    }
    
    async cancelExtension(id:number, cancellationReason: string): Promise<UpdateResult<any>>{
        let updatedTr = await HttpClient.patch(`/centaurus/extension/${id}/cancel`, {
            cancellationReason
            }
        )
        return {
            data : updatedTr
        }
    }
}