import { Box, Chip, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import HouseService from '../../services/HouseService';
import {useLocation, useNavigate} from "react-router-dom";
import TransactionButton from '../Common/TransactionButton';
import ImagePreviewerDialog from '../Common/ImagePreviewerDialog';
import JsonViewer from '../Common/JsonViewer';


const houseService = new HouseService();

function HouseBanners() {
    const navigate = useNavigate();
    const location = useLocation();
    const [houseId, setHouseId] = React.useState<any>(null);
    const [loading, setLoading] = React.useState(false);
    const [open,setOpen] =useState<boolean>(false);
    const [lat,setLat]=useState<any>(null);
    const [long,setLong]=useState<any>(null);
    const [customerId,setCustomerId]=useState<any>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [bannerUrl,setBannerUrl]=useState<string>('');
    const [data,setData] = useState<any>(null);

    const [bannerData,setBannerData]=useState<any>([]);
    const [slcData,setSlcData]=useState<any>([]);



    useEffect(() => {
        if (!houseId && !lat && !long && !customerId) {
            setBannerData([]); // Clear the banner data when all filters are null
            setLoading(false);
            return;
        }
        setLoading(true);
        const params = { houseId, lat, long, customerId };
        houseService.getAllBannersForHouse(params).then((result) => {
            setBannerData(result?.bannerData || []);
            setData(result)
            setSlcData(result?.slcServiceMappings|| []);
            setLoading(false);
        });
    }, [houseId, lat, long, customerId]);

    const updateRouter = (key: string, value: any) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set(key, value);
        navigate({ search: `?${queryParams.toString()}` });
    };

    useEffect(() => {
        if(houseId === null) return;
        const queryParams = new URLSearchParams(location.search);
        if(queryParams.get('houseId') === houseId.toString()) return;
        let timer = setTimeout(() => {
            updateRouter('houseId', houseId);
        }, 800);
        return () => { clearTimeout(timer) };
        // eslint-disable-next-line
    }, [houseId]);

    useEffect(() => {
        if(customerId === null) return;
        const queryParams = new URLSearchParams(location.search);
        if(queryParams.get('customerId') === customerId.toString()) return;
        let timer = setTimeout(() => {
            updateRouter('customerId', customerId);
        }, 800);
        return () => { clearTimeout(timer) };
        // eslint-disable-next-line
    }, [customerId]);

    useEffect(() => {
        if(lat === null) return;
        const queryParams = new URLSearchParams(location.search);
        if(queryParams.get('lat') === lat.toString()) return;
        let timer = setTimeout(() => {
            updateRouter('lat', lat);
        }, 800);
        return () => { clearTimeout(timer) };
        // eslint-disable-next-line
    }, [lat]);


    useEffect(() => {
        if(long === null) return;
        const queryParams = new URLSearchParams(location.search);
        if(queryParams.get('long') === long.toString()) return;
        let timer = setTimeout(() => {
            updateRouter('long', long);
        }, 800);
        return () => { clearTimeout(timer) };
        // eslint-disable-next-line
    }, [long]);


    useEffect(() => {
        if(loaded) return;
        const queryParams = new URLSearchParams(location.search);
        let houseId = queryParams.get('houseId');
        let lat= queryParams.get('lat');
        let long= queryParams.get('long');
        let customerId= queryParams.get('customerId');

        setHouseId(houseId);
        setLat(lat);
        setLong(long);
        setCustomerId(customerId);
        setLoaded(true);
    }, [location?.search, loaded]);
  return (
    <>
      <Box sx={{ width: '100%', mt: 2 }}>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} my={2} alignItems={'center'}>
          <TextField
            value={houseId || ''}  // Convert null or undefined to an empty string
            type={'number'}
            size={'small'}
            sx={{ mr: 2}}
            variant={'filled'}
            label={'House Id'}
            InputProps={{ inputProps: { min: 0 } }}
            onChange={(e) => setHouseId(e.target.value)}
        />
        <TextField
            value={customerId || ''}  // Convert null to an empty string
            type={'number'}
            size={'small'}
            sx={{ mr: 2}}
            variant={'filled'}
            label={'Customer Id'}
            InputProps={{ inputProps: { min: 0 } }}
            onChange={(e) => setCustomerId(e.target.value)}
        />
        <TextField
            value={lat || ''}  // Convert null to an empty string
            type={'number'}
            size={'small'}
            sx={{ mr: 2}}
            variant={'filled'}
            label={'Lat'}
            InputProps={{ inputProps: { min: 0 } }}
            onChange={(e) => setLat(e.target.value)}
        />
        <TextField
            value={long || ''}  // Convert null to an empty string
            type={'number'}
            size={'small'}
            sx={{ mr: 2}}
            variant={'filled'}
            label={'Long'}
            InputProps={{ inputProps: { min: 0 } }}
            onChange={(e) => setLong(e.target.value)}
        />
              <TransactionButton
                name={'Clear Filter'}
                onClickHandler={() => {
                    // Clear the URL params completely
                    navigate({ search: '' });

                    // Reset all state values to null
                    setHouseId(null);
                    setLat(null);
                    setLong(null);
                    setCustomerId(null);

                    // Clear the banner data
                    setBannerData([]);
                    setSlcData([]);
                    setData(null);
                }}
            />
            </Box>
            <Box fontSize={'1.4rem'} fontWeight={600}>Available Banners</Box>
            <Box display={'flex'} gap={'1rem'}>
                {
                    !loading&&bannerData&&bannerData.length>0?bannerData.map((banner:any)=>(
                        <Box mt={2}> 
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
                            <Box fontSize={"0.8rem"} fontWeight={600}>{banner?.service}</Box>
                        </Box>
                        <img src={banner?.bannerImage?.data?.attributes?.url} alt={banner?.service} style={{maxWidth:"350px", cursor:"pointer"}} onClick={()=>{
                            setOpen(true);
                            setBannerUrl(banner?.bannerImage?.data?.attributes?.url);
                        }}/>
                        </Box>
                    )):<Box>No Banner to show</Box>
                }
            </Box>
            <Box fontSize={'1.4rem'} fontWeight={600} mt={2}>Slc Service Mapping</Box>
            <Box display={'flex'} gap={'1rem'} flexDirection={'column'} mt={2}>
                {
                    slcData&&slcData.length>0?slcData.map((slc:any,i:number)=>(
                        <Box display={'flex'} gap={'1rem'} key={i}>
                            {slc?.serviceName?<Chip label={slc?.serviceName} color='warning'/>:''}
                            {slc?.couponData?.couponStrip?<Chip label={slc?.couponData?.couponStrip} color='warning'/>:''}
                        </Box>
                    )):<Box>No Slc to show</Box>
                }
            </Box>
        </Box>
        {open && <ImagePreviewerDialog src={bannerUrl} open={open} onClose={() => setOpen(false)} />}
        <JsonViewer data={data} />
    </>
  )
}

export default HouseBanners