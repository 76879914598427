import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";
import { useState } from "react";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import FreshLeadInterface from "../../interfaces/Cook/FreshLeadInterface";
import { CookService } from "../../services/CookServices";
import CkMuiDialog from "./CkMuiDialog/CkMuiDialog";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { CookVerificationStatus } from "../../enums/CookVerificationStatus";
import { CookVerificationReportSeverity } from "../../enums/CookVerificationReportSeverity";

const cookService = new CookService();

interface InputProps {
  cookDetail: FreshLeadInterface;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  refresh: () => void;
}

const ChangeCookVerificationAndSeverity = (props: InputProps) => {
  const [cookVerificationStatus, setCookVerificationStatus] =
    useState<CookVerificationStatus>(CookVerificationStatus.REPORT_GENERATED);
  const [cookVerificationReportSeverity, setCookVerificationReportSeverity] =
    useState<CookVerificationReportSeverity>(
      CookVerificationReportSeverity.Clear
    );

  return (
    <CkMuiDialog
      isDialogOpen={props.isOpen}
      setDialogOpen={props.setIsOpen}
      onDialogClose={() => {}}
      title={"Change Cook Verication And Severity"}
      secondaryLabel={"Close"}
      secondaryAction={() => {
        props.setIsOpen(false);
      }}
      primaryAction={() => {
        if (
          cookVerificationStatus !== null ||
          cookVerificationReportSeverity !== null
        ) {
          cookService
            .changeCookVerificationStatusAndReportSeverity(
              props?.cookDetail?.cookId,
              {
                verificationStatus: cookVerificationStatus,
                verificationReportSeverity: cookVerificationReportSeverity,
              }
            )
            .then(() => {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Successfull",
                type: CustomSnackbarTypes.SUCCESS,
              });
              props.refresh();
              props.setIsOpen(false);
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (
          cookVerificationStatus == null &&
          cookVerificationReportSeverity == null
        ) {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Add Cook Service Type First",
            type: CustomSnackbarTypes.WARNING,
          });
        }
      }}
      primaryLabel={"Submit"}
      isLoading={false}
    >
      <FormControl fullWidth sx={{ minWidth: 200 }}>
        <InputLabel>Select Cook Verification Status</InputLabel>
        <Select
          value={cookVerificationStatus}
          onChange={(e) => {
            const selectedValue = e.target.value;
            setCookVerificationStatus(selectedValue as CookVerificationStatus);
          }}
        >
          {Object.values(CookVerificationStatus).map((e) => (
            <MenuItem key={e} value={e}>
              <ListItemText primary={e} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ minWidth: 200 }}>
        <InputLabel>Select Cook Verification Report Severity</InputLabel>
        <Select
          value={cookVerificationReportSeverity}
          onChange={(e) => {
            const selectedValue = e.target.value;
            setCookVerificationReportSeverity(
              selectedValue as CookVerificationReportSeverity
            );
          }}
        >
          {Object.values(CookVerificationReportSeverity).map((e) => (
            <MenuItem key={e} value={e}>
              <ListItemText primary={e} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </CkMuiDialog>
  );
};

export default ChangeCookVerificationAndSeverity;
