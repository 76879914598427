import { useRecordContext } from "react-admin";

function GoogleMapsField({source}: any) {
    const record = useRecordContext();
    let coords = record && record[source]
    coords = coords.coordinates
    return (
        <div><a href={`https://www.google.com/maps/search/?api=1&query=${coords[1]},${coords[0]}`} target="_blank" rel="noopener noreferrer"> Go to google maps</a></div>
    )
}

export default GoogleMapsField;