import {
   AccessTime, AdminPanelSettings, AutoAwesome,
   Book, BookmarkAdded, Bookmarks, CalendarMonth, CurrencyExchange,
   Discount, ExitToApp,
   ExtensionTwoTone, FindReplace, Group,
   House,
   Inventory, Message, Money, Place, PublishedWithChanges,
   Receipt,
   Restaurant,
   AssessmentRounded,
   RoomService, Storefront, Map
} from "@mui/icons-material";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import StarsIcon from '@mui/icons-material/Stars';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import NavigationIcon from '@mui/icons-material/Navigation';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect } from 'react';
import { Admin, CustomRoutes, EditGuesser, Resource } from "react-admin";
import { Route } from 'react-router-dom';
import authProvider from "./authProvider";
import AdminCreate from "./components/AdminUser/AdminCreate";
import AdminEdit from "./components/AdminUser/AdminEdit";
import AdminEditAccess from "./components/AdminUser/AdminEditAccess";
import AdminUserShow from "./components/AdminUser/AdminShow";
import AdminUserList from "./components/AdminUser/AdminUserList";
import AreaList from "./components/Area/AreaList";
import AreaShow from "./components/Area/AreaShow";
import CreateArea from "./components/Area/CreateArea";
import BundleCreate from "./components/Bundle/BundleCreate";
import BundleList from "./components/Bundle/BundleList";
import BundleShow from "./components/Bundle/BundleShow";
import { CityList } from "./components/City/CityList";
import CityShow from "./components/City/CityShow";
import CreateCity from "./components/City/CreateCity";
import { ChangePassword } from "./components/Common/ChangePassword";
import ConfirmationDialog from "./components/Common/ConfirmationDialog";
import CustomSnackBar from "./components/Common/CustomSnackBar";
import StagingTag from "./components/Common/StagingTag";
import UpdateAvailable from "./components/Common/UpdateAvailable";
import CookLeaves from "./components/CookLeaves/CookLeaves";
import CookLeavesCreate from "./components/CookLeaves/CookLeavesCreate";
import CookLeavesEdit from "./components/CookLeaves/CookLeavesEdit";
import CookLeavesShow from "./components/CookLeaves/CookLeavesShow";
import Cooks from "./components/Cooks/Cooks";
import CookShow from "./components/Cooks/CookShow";
import Customers from "./components/Customers/Customer";
import CustomerCreate from "./components/Customers/CustomerCreate";
import CustomerEdit from "./components/Customers/CustomerEdit";
import CustomerShow from "./components/Customers/CustomerShow";
import CustomerLeaves from './components/CustomersLeaves/CustomerLeaves';
import Extension from "./components/Extension/Extension";
import HouseReviewList from "./components/HouseReview/HouseReviewList";
import HouseCreate from "./components/Houses/HouseCreate";
import HouseList from "./components/Houses/HouseList";
import HouseShow from "./components/Houses/HouseShow";
import CreateLocality from "./components/Locality/CreateLocality";
import LocalityList from "./components/Locality/LocalityList";
import LocalityShow from "./components/Locality/LocalityShow";
import MetabaseDashboard from "./components/MetabaseDashboard/MetabaseDashboard";
import { PartnerSlotFilterForm } from "./components/PartnerSlotting/PartnerSlotFilterForm";
import PermanentReplacementList from "./components/PermanentReplacement/PermanentReplacementList";
import PermanentReplacementShow from "./components/PermanentReplacement/PermanentReplacementShow";
import { TemplateList } from "./components/SMS/TemplateList";
import TemplateVariantShow from "./components/SMS/TemplateVariantShow";
import TemporaryReplacement from './components/TemporaryReplacement/TemporaryReplacement';
import TransactionList from "./components/Transactions/TransactionV3";
import {TrialsCreate} from "./components/Trials/TrialsCreate";
import { TrialsEdit } from "./components/Trials/TrialsEdit";
import TrialsList from "./components/Trials/TrialsList";
import TrialsShow from "./components/Trials/TrialsShow";
import TrialConvertedShow from "./components/TrialsConverted/TrialConvertedShow";
import TrialsConvertedList from "./components/TrialsConverted/TrialsConvertedList";
import TrTimetable from "./components/TrTimeTable/TrTimeTable";
import UserBundleMappingCreate from "./components/UserBundleMapping/UserBundleMappingCreate";
import UserBundleMappingList from "./components/UserBundleMapping/UserBundleMappingList";
import UserBundleMappingShow from "./components/UserBundleMapping/UserBundleMappingShow";
import UserTransactionList from "./components/Vas/UserTransactionList";
import UserTransactionEdit from "./components/Vas/UserTransationEdit";
import VasCreate from "./components/Vas/VasCreate";
import VasList from "./components/Vas/VasList";
import VasShow from "./components/Vas/VasShow";
import VasUserMappingList from "./components/Vas/VasUserMappingList";
import VasUserMappingShow from "./components/Vas/VasUserMappingShow";
import VendorPayoutDetails from "./components/VenderPayout/VendorPayoutDetails";
import { VendorPayoutList } from "./components/VenderPayout/VendorPayoutList";
import VendorPayoutShowDetails from "./components/VenderPayout/VendorPayoutShowDetails";
import Vendors from "./components/Vendors/Vendor";
import { VendorShow } from "./components/Vendors/VendorShow";
import CustomLayout from "./CustomLayout";
import customDataProvider from "./dataProvider";
import { UserGroupsEnum } from "./enums/UserGroupsEnum";
import AdminUserService from "./services/AdminUser";
import { AuthService } from "./services/AuthService";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { setUserDetails } from "./store/reducers/userDetails";
import GroupPermissionFunction from "./utils/groupPermissionFunction";
import StarChefVisit from "./components/StarChefVisit/StarChefVisit";
import StarChefVisitCreate from "./components/StarChefVisit/StarChefVisitCreate";
import StarChefVisitShow from "./components/StarChefVisit/StarChefVisitShow";
import StarChefInventory from "./components/StarChefInventory/StarChefInventory";
import StarChefEdit from "./components/StarChefVisit/StarChefEdit";
import RenewalsList from "./components/Renewals/RenewalsList";
import RenewalsShow from "./components/Renewals/RenewalsShow";
import DynamicPriceCalaculator from "./components/DynamicPriceCalculator/DynamicPriceCalaculator";
import TemporaryReplacementShow from "./components/TemporaryReplacement/TemporaryReplacementShow";
import VendorPayoutShow from "./components/VenderPayout/VendorPayoutShow";
import RosterList from "./components/Roster/RosterList";
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import WeightageTable from "./components/Weightage Table/WeightageTable";
import CouponShowV2 from "./components/CouponV2/CouponShowV2";
import CouponCreateV2 from "./components/CouponV2/CouponCreateV2";
import CouponListV2 from "./components/CouponV2/CouponListV2";
import CouponEdit from "./components/CouponV2/CouponEdit";
import OneMealServiceList from "./components/OneMealService/OneMealServiceList";
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import OneMealServiceCreate from "./components/OneMealService/OneMealServiceCreate";
import { RefundListV2 } from "./components/Refund/RefundListV2";
import OneMealServiceEdit from "./components/OneMealService/OneMealServiceEdit";
import OneMealServiceShow from "./components/OneMealService/OneMealServiceShow";
import StarCleaner from "./components/StarCleaner/StarCleaner";
import StarCleanerVisitCreate from "./components/StarCleaner/StarCleanerVisitCreate";
import StarCleanerInventory from "./components/StarCleanerInventory/StarCleanerInventory";
import StarCleanerServiceShow from "./components/StarCleaner/StarCleanerShow";
import VendorCreate from "./components/Vendors/VendorCreate";
import OmsTimeTable from "./components/OmsTimeTable/OmsTimeTable";
import MemoryIcon from '@mui/icons-material/Memory';
import HubsList from "./components/Hubs/HubsList";
import ViewHub from "./components/Hubs/ViewHub";
import CreateHub from "./components/Hubs/CreateHub";
import MgAllocation from "./components/MapComponent/MapComponent"
import CelebrationIcon from '@mui/icons-material/Celebration';
import PlatformLeadList from "./components/Platform/PlatformLeadList";
import PlatformLeadShow from "./components/Platform/PlatformLeadShow";
import Login from "./components/Common/Login";
import { lightTheme } from "./theme";
import TraningAssessment from "./components/Common/TrainingAssessment";
import CookSubscriptionShow from "./components/Cooks/CookSubscriptionShow";
import ChefForPartList from "./components/ChefForParty/ChefForPartList";
import ChefForPartyShow from "./components/ChefForParty/ChefForPartyShow";
import ChefForPartyEdit from "./components/ChefForParty/ChefForPartyEdit";
import DailyCooksSlots from "./components/Cooks/DailyCooksSlots";
import CookActivityModule from "./components/Cooks/CookActivityModule";
import CookLocationModule from "./components/Cooks/CookLocationModule";
import CookServeCalendar from "./components/CooksServeCalendar/CooksServeCalendar";
import VisitTracker from "./components/VisitTracker/VisitTracker";
import SkillAssessment from "./components/Common/SkillAssessment";
import VendorPayoutEventTable from "./components/Common/VendorPayoutEventTable";
import FeedIcon from '@mui/icons-material/Feed';
import CreateFieldList from "./components/FieldView/CreateFieldList";
import UploadIcon from '@mui/icons-material/Upload';
import BulkUploadLeads from "./components/Bulk Upload/BulkUploadLeads";
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ComboList from "./components/Combo/ComboList";
import ComboShow from "./components/Combo/ComboShow";
import HiveIcon from '@mui/icons-material/Hive';
import CreateCombo from "./components/Combo/CreateCombo";
import AssessmentResources from "./components/AssessmentResources/AssessmentResources";
import ChefForPartyCreate from "./components/ChefForParty/ChefForPartyCreate";
import HouseBanners from "./components/Houses/HouseBanners";
import ShowCityBanners from "./components/Common/ShowCityBanners";
import PhotoLibraryRoundedIcon from '@mui/icons-material/PhotoLibraryRounded';

const authService = new AuthService();
const adminUserService = new AdminUserService();


function App() {
   const userDetails = useAppSelector((state) => state.userDetails.value)
   const dispatch = useAppDispatch()
   useEffect(() => {
      let authData = localStorage.getItem('auth');
      if (authData) {
         authService.refreshToken(false);
         let user = JSON.parse(authData as any)?.data?.user
         adminUserService.getAdminUserById(user?.id)
             .then(data => {
                if(data.data.adminGroups.length === 0){
                   localStorage.clear();
                   window.location.replace("#/login");
                   return;
                }
                dispatch(setUserDetails(data.data))
             })
         setInterval(() => {
            authService.refreshToken(false);
         }, 15 * 60 * 1000)
      }
   }, [dispatch]);

   let groupsArray: string[] = userDetails.adminUser.accessGroups;

   return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
         <CustomSnackBar/>
         <ConfirmationDialog/>
         <UpdateAvailable/>
         <StagingTag/>
         <Admin theme={lightTheme} loginPage={Login} dataProvider={customDataProvider} authProvider={authProvider} layout={CustomLayout}>
            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'AdminUsers') && (
               <Resource
                  name="AdminUsers"
                  options={{ label: "Admin Users" }}
                  list={AdminUserList}
                  show={AdminUserShow}
                  edit={AdminEdit}
                  create={AdminCreate}
                  icon={AdminPanelSettings}
               />
            )}
            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER], 'EmployeeRoster') && (
               <Resource
                  name="EmployeeRoster"
                  options={{ label: "Employee Roster" }}
                  list={RosterList}
                  icon={SupervisedUserCircleIcon}
               />
            )}
            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN], 'CreateField') && (
                <Resource
                    name="CreateField"
                    options={{ label: "Additional Field Filter" }}
                    list={CreateFieldList}
                    icon={FeedIcon}
                />
            )}
             {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER, UserGroupsEnum.EXECUTIVE], 'BulkUploadLeads') && (
                 <Resource
                     name="BulkUploadLeads"
                     options={{ label: "Bulk Upload Leads" }}
                     list={BulkUploadLeads}
                     icon={UploadIcon}
                 />
             )}
            {!GroupPermissionFunction(groupsArray, [UserGroupsEnum.PROMOTOR], 'EmployeeRoster') && (
               <Resource
                  name='Cooks'
                  icon={Restaurant}
                  list={Cooks}
                  show={CookShow}
               />
            )}
            {!GroupPermissionFunction(groupsArray, [UserGroupsEnum.PROMOTOR], 'EmployeeRoster') && (
               <Resource
                  name='AssessmentResources'
                  icon={AssessmentRounded}
                  options={{ label: 'Assessment Resources' }}
                  list={AssessmentResources}
               />
            )}
            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER, UserGroupsEnum.PROMOTOR], 'CooksServeCalendar') &&
               <Resource
                  name='CooksServeCalendar'
                  list={CookServeCalendar}
                  options={{ label: 'Cooks Serve Calendar' }}
                  icon={PermContactCalendarIcon}
               />
            }
            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER, UserGroupsEnum.PROMOTOR], 'VisitTracker') &&
               <Resource
                  name='VisitTracker'
                  list={VisitTracker}
                  options={{ label: 'Visit Tracker' }}
                  icon={ContentPasteSearchIcon}
               />
            }
            {!GroupPermissionFunction(groupsArray, [UserGroupsEnum.PROMOTOR], 'DailyCookSlot') && (
               <Resource
                  name='DailyCookSlot'
                  options={{ label: "Daily Cook Slots" }}
                  icon={ViewTimelineIcon}
                  list={DailyCooksSlots}
               />
            )}

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'Customers') &&
               <Resource
                  name='Customers'
                  icon={Group}
                  list={Customers}
                  show={CustomerShow}
                  edit={CustomerEdit}
                  create={CustomerCreate}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'Cities') &&
               <Resource
                  name="Cities"
                  list={CityList}
                  show={CityShow}
                  icon={Place}
                  options={{ label: 'Cities' }}
                  create={CreateCity}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'Areas') &&
               <Resource
                  name="Areas"
                  list={AreaList}
                  show={AreaShow}
                  icon={AddLocationAltIcon}
                  options={{ label: 'Areas' }}
                  create={CreateArea}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'Localities') &&
               <Resource
                  name="Localities"
                  list={LocalityList}
                  show={LocalityShow}
                  icon={NavigationIcon}
                  options={{ label: 'Localities' }}
                  create={CreateLocality}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'Houses') &&
               <Resource
                  name="Houses"
                  list={HouseList}
                  show={HouseShow}
                  icon={House}
                  options={{ label: 'Houses' }}
                  create={HouseCreate}
               />
            }
            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'Houses') &&
               <Resource
                  name="HouseBanners"
                  list={HouseBanners}
                  icon={PhotoLibraryRoundedIcon}
                  options={{ label: 'HouseBanners' }}
               />
            }
            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'HouseReviews') &&
               <Resource
                  name="HouseReviews"
                  list={HouseReviewList}
                  icon={ApartmentIcon}
                  options={{ label: 'HouseReviews' }}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'Vendors') &&
               <Resource
                  name='Vendors'
                  icon={Storefront}
                  list={Vendors}
                  show={VendorShow}
                  create={VendorCreate}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'Vas') &&
               <Resource
                  name='Vas'
                  icon={AutoAwesome}
                  options={{ label: 'Value Added Services' }}
                  list={VasList}
                  create={VasCreate}
                  show={VasShow}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'VasTransactions') &&
               <Resource
                  name='VasTransactions'
                  icon={Receipt}
                  options={{ label: 'Vas Transactions' }}
                  list={UserTransactionList}
                  edit={UserTransactionEdit}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'UserBundleServiceMappings') &&
               <Resource
                  name='UserBundleServiceMappings'
                  icon={RoomService}
                  options={{ label: 'User Vas Mappings' }}
                  list={VasUserMappingList}
                  show={VasUserMappingShow}
                  edit={EditGuesser}
               />
            }


            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'Bundles') &&
               <Resource
                  name='Bundles'
                  list={BundleList}
                  options={{ label: 'Plans' }}
                  icon={Book}
                  create={BundleCreate}
                  show={BundleShow}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'UserBundles') &&
               <Resource
                  name='UserBundles'
                  list={UserBundleMappingList}
                  options={{ label: 'User Orders' }}
                  icon={Inventory}
                  create={UserBundleMappingCreate}
                  show={UserBundleMappingShow}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'ChefForParty') &&
               <Resource
                  name='ChefForParty'
                  list={ChefForPartList}
                  options={{ label: 'Chef For party' }}
                  create={ChefForPartyCreate}
                  icon={CelebrationIcon}
                  show={ChefForPartyShow}
                  edit={ChefForPartyEdit}
               />
            }

            {/* {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'Refund') &&
               <Resource
                  name='Refund'
                  list={RefundList}
                  options={{ label: 'Refund' }}
                  icon={Money}
               />
            } */}
             {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'Refund') &&
               <Resource
                 name='RefundV2'
                 list={RefundListV2}
                 options={{ label: 'Refund V2' }}
                 icon={Money}
               />
             }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'CustomerLeaves') &&
               <Resource
                  name="CustomerLeaves"
                  list={CustomerLeaves}
                  icon={ExitToApp}
                  options={{ label: 'Customer Leaves' }}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'TemporaryReplacement') &&
               <Resource
                  name="TemporaryReplacement"
                  list={TemporaryReplacement}
                  icon={PublishedWithChanges}
                  options={{ label: 'Temporary Replacement'}}
                  show={TemporaryReplacementShow}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'Extension') &&
               <Resource
                  name="Extension"
                  list={Extension}
                  icon={ExtensionTwoTone}
                  options={{ label: 'Extension' }}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'CookLeaves') &&
               <Resource
                  name='CookLeaves'
                  list={CookLeaves}
                  options={{ label: 'Cook Leaves' }}
                  icon={CalendarMonth}
                  show={CookLeavesShow}
                  create={CookLeavesCreate}
                  edit={CookLeavesEdit}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'Transactions') &&
               <Resource
                  name='Transactions'
                  list={TransactionList}
                  options={{ label: 'All Transactions' }}
                  icon={CurrencyExchange}
               />
            }

             {/* {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'Coupons') &&
               <Resource
                  name='Coupons'
                  list={CouponList}
                  options={{label: 'All Coupons'}}
                  icon={Discount}
                  create={CouponCreate}
               />
             } */}

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'Coupons') &&
               <Resource
                  name='CouponsV2'
                  list={CouponListV2}
                  options={{label: 'CouponsV2'}}
                  icon={Discount}
                  create={CouponCreateV2}
                  show={CouponShowV2}
                  edit={CouponEdit}
               />
             }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER, UserGroupsEnum.PROMOTOR], 'Trials') &&
               <Resource
                  name='Trials'
                  list={TrialsList}
                  options={{ label: 'Trials' }}
                  icon={Bookmarks}
                  create={TrialsCreate}
                  show={TrialsShow}
                  edit={TrialsEdit}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER, UserGroupsEnum.PROMOTOR], 'Trials') &&
               <Resource
                  name='OneMealService'
                  list={OneMealServiceList}
                  options={{ label: 'One Meal Service' }}
                  icon={RestaurantIcon}
                  create={OneMealServiceCreate}
                  show={OneMealServiceShow}
                  edit={OneMealServiceEdit}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'TrialsConverted') &&
               <Resource
               name='TrialsConverted'
               list={TrialsConvertedList}
               options={{ label: 'Trials Converted' }}
               icon={BookmarkAdded}
               show={TrialConvertedShow}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'PriceCalculator') &&
               <Resource
                  name='PriceCalculator'
                  options={{ label: 'Price Calculator' }}
                  icon={PointOfSaleIcon}
                  list={DynamicPriceCalaculator}
               />
            }

             {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'PermanentReplacement') &&
               <Resource
                 name='PermanentReplacement'
                 list={PermanentReplacementList}
                 options={{ label: 'Permanent Replacement' }}
                 icon={FindReplace}
                 show={PermanentReplacementShow}
               />
             }
             {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'Combo') &&
               <Resource
                 name='Combo'
                 list={ComboList}
                 options={{ label: 'Combo' }}
                 icon={HiveIcon}
                 show={ComboShow}
                 create={CreateCombo}
               />
             }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'CookSlots') &&
               <Resource
                  name="CookSlots"
                  list={PartnerSlotFilterForm}
                  options={{ label: "Cook Slots" }}
                  icon={AccessTime}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'SMS') &&
               <Resource
                  name="SMS"
                  list={TemplateList}
                  options={{ label: "SMS" }}
                  show = {TemplateVariantShow}
                  icon={Message}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'VendorPayouts') &&
               <Resource
                  name="VendorPayouts"
                  list={VendorPayoutList}
                  show={VendorPayoutShow}
                  options={{ label: "Vendor Payouts" }}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER], "Hubs") &&
              <Resource
                name="Hubs"
                list={HubsList}
                show={ViewHub}
                create={CreateHub}
                options={{ label: "Hubs" }}
                icon={MemoryIcon}
              />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], "Platform") &&
              <Resource
                name="Platform"
                list={PlatformLeadList}
                show={PlatformLeadShow}
                options={{ label: "Platform" }}
                icon={CelebrationIcon}
              />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'StarChefVisit') &&
               <Resource
                  name="StarChefVisit"
                  list={StarChefVisit}
                  options={{ label: "StarChef Service" }}
                  show = {StarChefVisitShow}
                  create={StarChefVisitCreate}
                  edit={StarChefEdit}
                  icon={StarsIcon}
               />
            }
            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'StarChefInventory') &&
               <Resource
                  name="StarChef-Inventory"
                  list={StarChefInventory}
                  icon={Inventory2Icon}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'StarCleaner') &&
               <Resource
                  name="StarCleaner"
                  options={{ label: "StarCleaner Service" }}
                  list={StarCleaner}
                  create={StarCleanerVisitCreate}
                  show={StarCleanerServiceShow}
                  icon={CleaningServicesIcon}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'StarCleanerInventory') &&
               <Resource
                  name="StarCleaner-Inventory"
                  list={StarCleanerInventory}
                  icon={Inventory2Icon}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'Renewals') &&
               <Resource
               name='Renewals'
               list={RenewalsList}
               options={{ label: 'Renewals' }}
               icon={AutorenewIcon}
               show={RenewalsShow}
               />
            }

            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'PriceCalculator') &&
               <Resource
                  name='WeightageTable'
                  options={{ label: 'Weightage Table' }}
                  icon={MonitorWeightIcon}
                  list={WeightageTable}
               />
            }
            {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER], 'MgAllocation') &&
               <Resource
                  name='MgAllocation'
                  options={{ label: 'MG Allocation' }}
                  icon={Map}
                  list={MgAllocation}
               />
            }

             <CustomRoutes>
                 {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER]) &&
                   <Route path="/TimeTable" element={<TrTimetable />} />
                 }
                 {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER]) &&
                   <Route path="/OmsTimeTable" element={<OmsTimeTable />} />
                 }
                 {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER]) &&
                   <Route path="/cook-activity/:id" element={<CookActivityModule />} />
                 }
                 {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER]) &&
                   <Route path="/cook-location/:id" element={<CookLocationModule />} />
                 }
                 {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER]) &&
                   <Route path="/edit-access-groups/:id" element={<AdminEditAccess />} />
                 }
                 {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER, UserGroupsEnum.EXECUTIVE]) &&
                   <Route path="/change-password" element={<ChangePassword />} />
                 }
                 {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER, UserGroupsEnum.EXECUTIVE]) &&
                   <Route path="/vendor-payout-details/:cookId" element={<VendorPayoutDetails />} />
                 }
                 {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER, UserGroupsEnum.EXECUTIVE]) &&
                   <Route path="/vendor-payout-show-details/:cookId" element={<VendorPayoutShowDetails />} />
                 }
                 {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER]) &&
                   <Route path="/metabase-transaction/:dashboardId" element={<MetabaseDashboard/>} />
                 }
                 {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER]) &&
                   <Route path="/metabase-pr/:dashboardId" element={<MetabaseDashboard/>} />
                 }
                 {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER]) &&
                   <Route path="skill-assessment/*" element={<SkillAssessment/>} />
                 }
                  {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER]) &&
                   <Route path="training/*" element={<TraningAssessment/>} />
                 }
                 {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER]) &&
                   <Route path="cookSubscription/show/*" element={<CookSubscriptionShow />} />
                 }
                 {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER]) &&
                   <Route path="banners" element={<ShowCityBanners/>} />
                 }
               <Route path="vendorPayoutEventTable" element={<VendorPayoutEventTable />} />
             </CustomRoutes>
         </Admin>
      </LocalizationProvider>
   );
}

export default App;
