import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import VendorPayoutService from "../../services/VendorPayoutService";
import CircularLoader from "../Common/CircularLoader";
import moment from "moment";
import PayoutTypeEnum from "../../enums/PayoutTypeEnum";

const vendorPayoutService = new VendorPayoutService();

function TotalAmounts(data: any){
  return data
    .map((data: any) => Number(data.amount))
    .reduce((cur: any, acr: any) => cur + acr, 0);
}

function PayoutTables(props: any) {
  return (
    <Table
      className={"custom-table"}
      sx={{
        maxWidth: 750,
      }}
    >
      <TableHead>
        <TableRow className={"custom-table"}>
          <TableCell
            sx={{
              fontSize: "20px",
            }}
          >
            Comments
          </TableCell>
          <TableCell
            sx={{
              fontSize: "20px",
            }}
          >
            Date
          </TableCell>
          <TableCell
            sx={{
              fontSize: "20px",
            }}
          >
            Amount
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.data?.map((data: any) => {
          return (
            <TableRow key={data?.id}>
              <TableCell>{data?.comments}</TableCell>
              <TableCell>{moment(data?.createdAt).format("DD-MM-YYYY")}</TableCell>
              <TableCell>{data?.amount}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

const PayoutTypeComponent = (props: any) => {
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>();

  function TypeMaker(inputType: string) {
    let type;
    switch (inputType) {
      case PayoutTypeEnum.TRIAL:
        type = "trails";
        break;
      case PayoutTypeEnum.REFERRAL:
        type = "referrals";
        break;
      case PayoutTypeEnum.TR:
        type = "tr"; 
        break; 
      default:
        type = ""  
    }
    return type;

  }

  useEffect(() => {
    if ([PayoutTypeEnum.TRIAL, PayoutTypeEnum.REFERRAL, PayoutTypeEnum.TR].includes(props.type)) {
      setIsLoading(true)
      vendorPayoutService
        .getAllPayoutByCookId(
          Number(props.data[0]),
          TypeMaker(props.type),
          props.data[2],
          props.data[1]
        )
        .then((e) => {
          setData(e);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else if (props.type === PayoutTypeEnum.SUBSCRIPTION) {
      setIsLoading(true)
      vendorPayoutService
        .getSubscriptionAndLeaveByHouseId(
          Number(props.data[0]),
          props.data[3],
          props.data[2],
          props.data[1]
        )
        .then((e) => {
          setData(e);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(true)
      vendorPayoutService
        .getAllMiscellaneousPayouts(
          Number(props.data[0]),
          props.data[2],
          props.data[1],
          props.type.toUpperCase()
        )
        .then((e) => {
          setData(e);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [props.data, props.type]);

  return (
    <>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <div>
          {
          props.type === PayoutTypeEnum.TRIAL && (
            <div>
              <div
                style={{
                  fontSize: "35px",
                  textAlign: "center",
                  margin: "15px 0",
                }}
              >
                <strong>Trials Amount:</strong>
                {TotalAmounts(data)}
              </div>
              <Table className={"custom-table"} sx={{ maxWidth: 800 }}>
                <TableHead>
                  <TableRow className={"custom-table"}>
                    <TableCell sx={{ fontSize: "20px" }}>Address</TableCell>
                    <TableCell sx={{ fontSize: "20px" }}>Date</TableCell>
                    <TableCell sx={{ fontSize: "20px" }}>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((data: any) => {
                    return (
                      <TableRow key={data?.id} className={"custom-table"}>
                        <TableCell>{data?.house.address}</TableCell>
                        <TableCell>{data?.trial.date}</TableCell>
                        <TableCell>{data?.amount}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          )}
          {props.type === PayoutTypeEnum.REFERRAL && (
            <div>
              <div
                style={{
                  fontSize: "35px",
                  textAlign: "center",
                  margin: "15px 0",
                }}
              >
                <strong>Reffral Amount:</strong>{" "}
                {TotalAmounts(data)}
              </div>
              <Table className={"custom-table"} sx={{ maxWidth: 700 }}>
                <TableHead>
                  <TableRow className={"custom-table"}>
                    <TableCell sx={{ fontSize: "20px" }}>Name</TableCell>
                    <TableCell sx={{ fontSize: "20px" }}>Date</TableCell>
                    <TableCell sx={{ fontSize: "20px" }}>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((data: any) => {
                    return (
                      <TableRow key={data?.id}>
                        <TableCell>{data?.referral.referralCook.fname}</TableCell>
                        <TableCell>
                          {moment(data?.createdAt).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>{data?.amount}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          )}
          {props.type === PayoutTypeEnum.TR && (
            <div>
              <div
                style={{
                  fontSize: "35px",
                  textAlign: "center",
                  margin: "15px 0",
                }}
              >
                <strong>Tr Amount:</strong>
                {TotalAmounts(data)}
              </div>
              <Table className={"custom-table"} sx={{ maxWidth: 700 }}>
                <TableHead>
                  <TableRow className={"custom-table"}>
                    <TableCell sx={{ fontSize: "20px" }}>Comments</TableCell>
                    <TableCell sx={{ fontSize: "20px" }}>Date</TableCell>
                    <TableCell sx={{ fontSize: "20px" }}>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((data: any) => {
                    return (
                      <TableRow key={data?.id}>
                        <TableCell>{data?.comments}</TableCell>
                        <TableCell>
                          {moment(data?.TR.date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>{data?.amount}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          )}
          {props.type === PayoutTypeEnum.SUBSCRIPTION && (
            <>
              <div>
                <div
                  style={{
                    fontSize: "30px",
                    textAlign: "center",
                    margin: "15px 0",
                  }}
                >
                  <strong>Subscription Amount:</strong>
                  {data?.cookSubsPayout
                    ?.map((e: any) => Number(e.amount))
                    .reduce((cur: any, acr: any) => cur + acr, 0)}
                </div>
                <Table className={"custom-table"} sx={{ maxWidth: 700 }}>
                  <TableHead>
                    <TableRow className={"custom-table"}>
                      <TableCell sx={{ fontSize: "20px" }}>House</TableCell>
                      <TableCell sx={{ fontSize: "20px" }}>Comments</TableCell>
                      <TableCell sx={{ fontSize: "20px" }}>Date</TableCell>
                      <TableCell sx={{ fontSize: "20px" }}>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.cookSubsPayout?.map((data: any) => {
                      return (
                        <TableRow key={data?.cookSubsPayout?.id}>
                          <TableCell>{data?.house.address}</TableCell>
                          <TableCell>{data?.comments}</TableCell>
                          <TableCell>
                            {moment(data?.createdAt).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>{data?.amount}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <div>
                <div
                  style={{
                    fontSize: "30px",
                    textAlign: "center",
                    margin: "15px 0",
                  }}
                >
                  <strong>LeavePayout Amount:</strong>
                  {data?.leavePayout
                    ?.map((e: any) => Number(e.amount))
                    .reduce((cur: any, acr: any) => cur + acr, 0)}
                </div>
                <Table className={"custom-table"} sx={{ maxWidth: 700 }}>
                  <TableHead>
                    <TableRow className={"custom-table"}>
                      <TableCell sx={{ fontSize: "20px" }}>House</TableCell>
                      <TableCell sx={{ fontSize: "20px" }}>Comments</TableCell>
                      <TableCell sx={{ fontSize: "20px" }}>Date</TableCell>
                      <TableCell sx={{ fontSize: "20px" }}>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.leavePayout?.map((data: any) => {
                      return (
                        <TableRow key={data?.leavePayout?.id}>
                          <TableCell>{data?.house.address}</TableCell>
                          <TableCell>{data?.comments}</TableCell>
                          <TableCell>
                            {moment(data?.createdAt).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>{data?.amount}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </>
          )}
          {[PayoutTypeEnum.MISCELLANEOUS, PayoutTypeEnum.INCENTIVE, PayoutTypeEnum.ADVANCE, PayoutTypeEnum.LEAVE, PayoutTypeEnum.REIMBURSEMENT].includes(props.type) && (
            <div>
              <div
                style={{
                  fontSize: "30px",
                  textAlign: "center",
                  margin: "15px 0",
                }}
              >
                <strong>{props.type[0].toUpperCase() + props.type.slice(1)} Amount:</strong>
                {TotalAmounts(data)}
              </div>
              <PayoutTables data={data}></PayoutTables>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PayoutTypeComponent;


