import { Box, Button, DialogActions, DialogContent } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { Resource, useListController, useUnselectAll } from "ra-core";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setCustomSelectOpen } from "../../../store/reducers/customSelect";

interface InputProps {
  onClose: Function;
  name: string;
  list: React.FC<{ isMultiSelect?: boolean }>;
  isMultiSelect: boolean;
}

const ListWrapper: React.FC<{
  isMultiSelect: boolean;
  SelectedList: Array<number>;
  List: React.FC<{ isMultiSelect?: boolean }>;
}> = ({ isMultiSelect, SelectedList, List }) => {
  const { selectedIds } = useListController();
  useEffect(()=>{
    SelectedList.push(...selectedIds);
  },[selectedIds,SelectedList])
  const options = { isMultiSelect };
  return <List {...options} />;
};

const MultiResourceSelectDialog = (props: InputProps) => {
  const { onClose, name, list: List } = props;
  const unSelectAll = useUnselectAll(name);
  const isCustomSelectOpen = useAppSelector(
    (state) => state.customSelect.value.open
  );
  const dispatch = useAppDispatch();
  let SelectedList:number[] = [];
  const handleClose = () => {
    if (props.isMultiSelect) {
      onClose(SelectedList);
    } 
    unSelectAll();
    dispatch(setCustomSelectOpen({ open: false, record: null }));
  };

  return (
    <Dialog
      onClose={() => {
        unSelectAll();
        dispatch(setCustomSelectOpen({ open: false, record: null }));
      }}
      open={isCustomSelectOpen || false}
      scroll={"paper"}
      maxWidth="xl"
    >
      <DialogContent sx={{display:"flex", padding:"0px 1rem"}}>
        <Box flex="1" display="flex">
          <Resource
            name={name}
            list={
              <ListWrapper
                List={List}
                SelectedList={SelectedList}
                isMultiSelect={props.isMultiSelect}
              />
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {props.isMultiSelect && <Button onClick={handleClose}>Select</Button>}
        <Button
          onClick={() => {
            unSelectAll();
            dispatch(setCustomSelectOpen({ open: false, record: null }));
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MultiResourceSelectDialog;
