import {
    Show,
    Tab,
    TabbedShowLayout,
    useListController,
    useRecordContext,
    useRefresh,
} from "react-admin";
import VendorPayoutMetaShow from "./VendorPayoutMetaShow";
import {Stack} from "@mui/material";
import {VendorPayoutTransactionStatus} from "../../enums/vendorPayoutTransactionStatus";
import React, {useState} from "react";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";
import TransactionButton from "../Common/TransactionButton";
import RejectPayoutReasonDialog from "../Common/RejectPayoutReasonDialog";
import VendorPayoutService from "../../services/VendorPayoutService";
import {VendorPayoutResponse} from "../../interfaces/VendorPayout/VendorPayoutResponse";
import {PayoutTypeDailog} from "../Common/payoutTypeDialog";

const vendorPayoutService = new VendorPayoutService();

const VendorPayoutTabs = () => {
    return(
        <TabbedShowLayout>
            <Tab label={"Vendor Payout Details"}>
                <VendorPayoutMetaShow />
            </Tab>
        </TabbedShowLayout>
    );
};

interface ActionButtonsInputProps {
    setPayoutTypeDailog: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedData: React.Dispatch<
        React.SetStateAction<VendorPayoutResponse | null>
        >;
    recordType?: string;
    isBulkAction: boolean;
    setBulkAction: React.Dispatch<React.SetStateAction<boolean>>;
}


const ProcessPayout = (props: ActionButtonsInputProps) => {
    const record = useRecordContext();

    const handleClick = () => {
        props?.setBulkAction(props?.isBulkAction);
        props.setPayoutTypeDailog(true);
        props.setSelectedData(record as VendorPayoutResponse);
    };

    return (
        <TransactionButton onClickHandler={handleClick} name={"Process Payout"} />
    );
};

const ApprovePayout = () => {
    const record = useRecordContext();
    const refresh = useRefresh();

    const handleClick = async () => {
        try {
            const response = await vendorPayoutService.approvePayout(
                Number(record?.id)
            );
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: response.status,
                type: CustomSnackbarTypes.SUCCESS,
            });
            refresh();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <TransactionButton
            onClickHandler={handleClick}
            color="success"
            name={"Approve Payout"}
        />
    );
};

const RejectPayout = () => {
    const [open, setOpen] = useState(false);
    const { selectedIds } = useListController();
    return (
        <>
            <RejectPayoutReasonDialog
                open={open}
                setOpen={setOpen}
                isBulkAction={false}
                selectedVendorPayoutIds={selectedIds}
            />
            <TransactionButton
                onClickHandler={() => setOpen(true)}
                color="error"
                name={"Reject Payout"}
            />
        </>
    );
};

const ActionButtons = (props: any) => {
    const record = useRecordContext();
    return(
        <Stack direction={"row-reverse"} style={{marginBottom: "10px"}} gap={2}>
            {[VendorPayoutTransactionStatus.PENDING].includes(
                record?.status
            ) && (
                <>
                    <ApprovePayout />
                    <RejectPayout />
                </>
            )}
            {[
                VendorPayoutTransactionStatus.APPROVED,
                VendorPayoutTransactionStatus.PROCESSING_FAILED,
            ].includes(record?.status) && (
                <ProcessPayout
                    setPayoutTypeDailog={props?.setPayoutTypeDailog}
                    setSelectedData={props?.setSelectedData}
                    isBulkAction={false}
                    setBulkAction={props?.setBulkAction}
                />
            )}
        </Stack>
    )
};

function VendorPayoutShow(props: any) {
    const [payoutTypeDailog, setPayoutTypeDailog] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<VendorPayoutResponse | null>(
        null
    );

    console.log(payoutTypeDailog);

    return (
    <Show>
        <PayoutTypeDailog
            selectedVendorPayoutIds={[]}
            data={selectedData}
            open={payoutTypeDailog}
            isBulkAction={false}
            callback={() => {
                setPayoutTypeDailog(false);
            }}
        />
        <ActionButtons
            setPayoutTypeDailog={setPayoutTypeDailog}
            setSelectedData={setSelectedData}
            isBulkAction={false}
            setBulkAction={() => {}}
        />
        <VendorPayoutTabs />
    </Show>
  );
}

export default VendorPayoutShow;
