import {useCallback, useEffect, useState} from 'react';
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import { OneMealService } from '../../services/OneMealService';
import OneMealListForModule from './OneMealListForModule';

interface InputProps {
    houseId: number
}

const oneMealService = new OneMealService();

const OneMealHistoryTab = (props: InputProps) => {
    let [subData, setSubData] = useState<any>(null);
    let [isLoading, setIsLoading] = useState<boolean>();
    const [pageSize, setPageSize] = useState(10);

    const refreshData = useCallback(
      (pageNo: number) => {
      setIsLoading(true)
      oneMealService.getAllOneMealServiceForHouse(props.houseId, pageSize, pageSize * pageNo, {order: "DESC", field: "createdAt"})
          .then((data) => {
              setSubData(data);
              setIsLoading(false);
          })
          .catch((err) => {
              setIsLoading(false);
          })
    },[props.houseId, pageSize])

    useEffect(() => {
        refreshData(0);
    }, [refreshData])

    return (
      <div>
        {subData &&
          subData?.data &&
          Array.isArray(subData?.data) &&
          (subData?.data?.length > 0 ? (
            <OneMealListForModule
              isLoading={isLoading}
              count={subData?.count}
              data={subData?.data}
              refreshData={refreshData}
              setPageSize={setPageSize}
              pageSize={pageSize}
            />
          ) : (
            <EmptyListPlaceholder message="No One Meal Service found" />
          ))}
      </div>
    );
};

export default OneMealHistoryTab;

