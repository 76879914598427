import HttpClient from "../HttpClient";
import { GetListResult } from "react-admin";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";
import Op from "../interfaces/SequalizeFilters/SequalizeTypes";
import moment from "moment";
import { RazorpayOrderReferenceModelEnums } from "../enums/RazorpayOrderReferenceModelEnums";

export class TransactionServies {

    processDateRangeFilter(filters: any) {
        let updatedAt;
        if (filters.startDate) {
            updatedAt = {
                [Op.gte.toString()]: moment(filters.startDate)
            }
            delete filters.startDate
        }
        if (filters.endDate) {
            if (updatedAt) {
                updatedAt = {
                    ...updatedAt,
                    [Op.lte.toString()]: moment(filters.endDate).add(1, 'day')
                }
            } else {
                updatedAt = {
                    [Op.lte.toString()]: moment(filters.endDate).add(1, 'day')
                }
            }
            delete filters.endDate
        }
        return {updatedAt};
    }


    async getAllTransaction(limit: number, offset: number, filters: {}, sort: {}): Promise<GetListResult<any>> {
        let autoFilters = ConstructSequelizeFilters({...filters}, [], ['state'])
        let customFilters = this.processDateRangeFilter(filters);

        filters = {
            ...autoFilters,
            ...customFilters
        }

        let data = await HttpClient.get('/transactions/v3/all', {
            params: {
                limit,
                offset,
                filters,
                sort
            }
        })
        return {
            data: data.data.data,
            total: data.data.count
        }
    }
    async getTransactionForHouse(houseId: number,modelName: RazorpayOrderReferenceModelEnums,limit: number, offset: number): Promise<GetListResult<any>>{
        let data = await HttpClient.get(
          `/centaurus/houses/${houseId}/model-name/${modelName}`,
          {
            params: {
              limit,
              offset,
            },
          }
        );
        return {
            data: data.data.data,
            total: data.data.count
        }
    }
    async getTransactionForBundle(userBundleMappingId: number): Promise<GetListResult<any>>{
        let data = await HttpClient.get(`/centaurus/bundle/userbundles/${userBundleMappingId}/order`)
        return {
            data: [data.data],
            total: data.data.length
        }
    }
}
