import {
    Box,
    Button,
    Dialog,
    IconButton, Paper,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import React from "react";
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";
import FieldViewService from "../../services/FieldViewService";
import {Article} from "@mui/icons-material";
import sampleBulkUploadCSV from "../../constants/SampleBulkUploadCSV";

const fieldViewService = new FieldViewService();

type UploadedDataDialogProps = {
    open: boolean;
    onClose: () => void;
    uploadedData: any;
}

type CSVPreviewerProps = {
    preview: string[][] | null;
}
const UploadedDataDialog = (props: UploadedDataDialogProps) => {
    return (
        <Dialog
            open={props?.open}
            sx={{
                '& .MuiPaper-root': {
                    px: '35px',
                    py: '35px',
                    maxWidth: '70vw',
                    minHeight: '50vw',
                }
            }}
        >
            <Typography fontWeight={700} fontSize={'2vw'} textAlign={'center'}>Uploaded Data</Typography>
            <Box display={'flex'} gap={'3vw'} my={'2vw'}>
                <Typography>Created Count: {props?.uploadedData?.createdCount}</Typography>
                <Typography>Updated Count: {props?.uploadedData?.updatedCount}</Typography>
                <Typography>Failed Count: {props?.uploadedData?.failedCount}</Typography>
            </Box>
            {props?.uploadedData?.failedProfiles && props?.uploadedData?.failedProfiles?.length > 0 && (
                <Box>
                    <Typography fontWeight={600} fontSize={'1.5vw'} mb={'1vw'}>Failed Profiles</Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align={'center'}>First Name</TableCell>
                                    <TableCell align={'center'}>Last Name</TableCell>
                                    <TableCell align={'center'}>Phone</TableCell>
                                    <TableCell align={'center'}>Source</TableCell>
                                    <TableCell align={'center'}>Sub Source</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props?.uploadedData?.failedProfiles?.length > 0 && props?.uploadedData?.failedProfiles?.map((failedProfile: any, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell>{failedProfile?.firstName}</TableCell>
                                        <TableCell>{failedProfile?.lastName}</TableCell>
                                        <TableCell>{failedProfile?.phone}</TableCell>
                                        <TableCell>{failedProfile?.source}</TableCell>
                                        <TableCell>{failedProfile?.subSource}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
            <Box textAlign={'center'}>
                <Button
                    variant={'contained'}
                    color={'error'}
                    sx={{
                        mt: '1vw',
                        width: '30vw',
                        fontSize: '1.4vw',
                    }}
                    onClick={props?.onClose}
                >Close</Button>
            </Box>

        </Dialog>
    )
}
const CSVPreviewer = (props: CSVPreviewerProps) => {
    return (
        <Paper elevation={3} sx={{
            p: '2vw',
            my: 2,
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            backgroundColor: '#ffffff',
            borderRadius: '3vw',
        }}>
            <Typography fontWeight={600} textAlign={'center'} fontSize={'1.5vw'} mt={2}>
                CSV Preview
            </Typography>
            {props?.preview && (
                <Table sx={{my: 3}}>
                    <TableBody>
                    {props?.preview.map((row: string[], rowIndex: number) => (
                        <TableRow key={rowIndex}>
                            {row.map((cell: string, cellIndex: number) => (
                                <TableCell
                                    align={'center'}
                                    key={cellIndex}
                                     sx={{
                                         fontWeight: rowIndex === 0 ? 600 : 400,
                                         textTransform: 'capitalize',
                                     }}
                                >{cell}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            )}
        </Paper>
    )
}
const BulkUploadLeads = () => {
    const [isDragOver, setIsDragOver] = React.useState<boolean>(false);
    const [file, setFile] = React.useState<File | null>(null);
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [uploadedData, setUploadedData] = React.useState<any>();
    const [openuploadedDataDialog, setOpenUploadedDataDialog] = React.useState<boolean>(false);
    const [preview, setPreview] = React.useState<string[][] | null>(null);
    const [openPreviewer, setOpenPreviewer] = React.useState<boolean>(false);

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(true);
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(false);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const uploadedFile = e.target.files && e.target.files[0];
        console.log("ip", e.target.files);
        if (uploadedFile && uploadedFile.type === 'text/csv') {
            setFile(uploadedFile);
        } else {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Select CSV File Only",
                type: CustomSnackbarTypes.ERROR,
            });
        }
    };
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(false);
        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile && droppedFile.type === 'text/csv') {
            setFile(droppedFile);
        } else {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Select CSV File Only",
                type: CustomSnackbarTypes.ERROR,
            });
        }
    };
    const handleUpload = () => {
        if (file) {
            const formData = new FormData();
            formData.append('cookFreshLeadCsv', file, file.name);
            fieldViewService?.bulkUploadLeads(formData).then((res) => {
                if(res) {
                    console.log(res);
                    console.log(res?.body);
                    setUploadedData(res);
                    setOpenUploadedDataDialog(true);
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: "File Uploaded Successfully",
                        type: CustomSnackbarTypes.SUCCESS,
                    });
                    setFile(null);
                }

            })
        }
    };

    // const handleClickToUpload = () => {
    //     if (fileInputRef.current) {
    //         fileInputRef.current.click();
    //     }
    // };
    const handleDownload = () => {
        const csvContent = sampleBulkUploadCSV.map((row: string[]) => row.join(',')).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Sample-Bulk-Upload.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };
    const parseCSV = (data: string) => {
        const rows = data?.trim()?.split('\n');
        const parsedData = rows?.map((row) => row.split(','));
        setPreview(parsedData);
        setOpenPreviewer(true);
    };
    React.useEffect(() => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e?.target && e?.target.result) {
                    parseCSV(e?.target?.result?.toString());
                }
            };
            reader?.readAsText(file);
        }
    }, [file]);
    return (
        <Box display={'flex'} flexDirection={'column'} height={openPreviewer ? 'auto': '100vh'} justifyContent={'center'} alignItems={'center'} bgcolor={'#e9eaec'} position={'relative'}>
            <Box position={'absolute'} top={'1%'} right={'1%'} onClick={handleDownload}>
                <Tooltip title={"Sample CSV file"}>
                    <IconButton>
                        <Article fontSize={'large'}/>
                    </IconButton>
                </Tooltip>
            </Box>
            <Box position={'relative'}
                sx = {{
                    width: '50vw',
                    mt: openPreviewer ? '4vw' : '0vw',
                    mb: openPreviewer ? '2.5vw' : '0vw',
                    px: '4vw',
                    py: '3vw',
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                    backgroundColor: '#ffffff',
                    borderRadius: '3vw',
                }}
            >
                <Box>
                    <Typography textAlign={'left'} fontWeight={600} fontSize={'2vw'} color={'#010101'} fontFamily={'Monaco'}>Upload and attach files</Typography>
                    <Typography textAlign={'left'} fontWeight={600} fontSize={'1.4vw'} color={'#777777'} fontFamily={'Monaco'} mt={'10px'}>Upload and attach file below.</Typography>
                </Box>
                <Box
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    sx={{
                        mt: '3vw',
                        py: '4vw',
                        border: '2px dashed #272727',
                        borderRadius: '2vw',
                        position: 'relative',
                    }}
                >
                    <Box textAlign={'center'}

                    >
                        <UploadFileRoundedIcon sx={{
                            fontSize: '6vw',
                        }}/>
                    </Box>
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept=".csv"
                        onChange={handleFileChange}
                        style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0, cursor: 'pointer'}} // Make the input transparent and cover the entire area
                    />
                    <Box textAlign={'center'} mt={'1vw'}>
                        <label htmlFor="file-input">
                            <Typography component="span" fontFamily={'Monaco'} fontWeight={600} fontSize={'1.3vw'} sx={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                            }}>
                                {isDragOver ? 'Drop the file here' : 'Click to upload or drag and drop'}
                            </Typography>
                        </label>
                        <Typography fontFamily={'Monaco'} fontWeight={600} fontSize={'1vw'} mt={'1vw'}>Maximum file size 500KB</Typography>
                    </Box>

                </Box>
                <Box textAlign="center" mt={'2vw'}>
                    <Button variant="contained" onClick={handleUpload} disabled={!file} sx={{
                        backgroundColor: '#000000',
                        textTransform: 'none',
                        letterSpacing: '1px',
                        width: '12vw',
                        height: '3vw',
                        borderRadius: '1.2vw',
                        fontSize: '1.1vw',
                        fontWeight: 600,
                        fontFamily: 'Monaco',
                        '&:hover': {
                            backgroundColor: '#ffffff',
                            color: '#000000',
                            border: '2px solid #000000',
                            boxShadow: 'none',
                        }
                    }}>
                        Upload
                    </Button>
                </Box>
                {file && (
                    <Box sx={{
                        height: '7vw',
                        position: 'absolute',
                        left: '5.5vw',
                        top: '13vw',
                    }} >
                        <Typography fontFamily={'Monaco'} fontSize={'1.1vw'}>
                            File Name:
                            <b>{file.name}</b>
                        </Typography>
                        <Typography fontFamily={'Monaco'} fontSize={'1.1vw'}>
                            File Size:
                            <b>{(file.size / 1024).toFixed(2)} KB</b>
                        </Typography>
                    </Box>
                )}
            </Box>
            <UploadedDataDialog
                open={openuploadedDataDialog}
                onClose={() => setOpenUploadedDataDialog(false)}
                uploadedData={uploadedData}
            />
            {openPreviewer &&
                <Box sx={{
                    width: '50vw',
                }}
                >
                    <CSVPreviewer preview = {preview}/>
                </Box>
            }
        </Box>
    )
}
export default BulkUploadLeads;