import { GetListResult, GetOneResult } from "react-admin";
import CreateVasDto from "../dtos/vas/createVasDto";
import CreateVasUserTransactionsDto from "../dtos/vas/createVasUserTransactionDto";
import VasResponse from "../dtos/vas/vasResponseDto";
import VasTransactionResponseDto from "../dtos/vas/vasTransactionsResponseDto";
import { UserType } from "../enums/common";
import HttpClient from "../HttpClient";
import { UserBundlesResponse } from "../interfaces/UserBundleMapping/UserBundlesResponse";

export class AllVasService {

    async getManyVas(ids: Array<number>) {
        const data = []

        for (const id of ids) {
            const res = await HttpClient.get(`/centaurus/vas/${id}`);
            data.push(res.data)
        }

        return {
            data: data,
            total: data.length
        }
    }

    async fetchOneUserBundleServiceMapping(userBundleServiceMappingId: number): Promise<GetOneResult<UserBundlesResponse>> {
        let data = await HttpClient.get(`/centaurus/vas/service-bundle-mappings/${userBundleServiceMappingId}`);
        return data;
    }

    async getAllVas(limit: number, offset: number): Promise<GetListResult<VasResponse>> {
        let data = await HttpClient.get('/centaurus/vas/all', {
            params: {
                limit,
                offset
            }
        })
        return {
            data: data.data.data,
            total: data.data.count
        }
    }

    async createVas(data: any) {

        const body: CreateVasDto = {
            ...data
        }
        const res = await HttpClient.post('/centaurus/vas', body);
        return res
    }

    async getAllServiceTransactions(limit: number, offset: number, sort: {}): Promise<GetListResult<VasResponse>> { //TODO: Fix this
        let data = await HttpClient.get('/centaurus/vas/service-transaction', {
            params: {
                limit,
                offset,
                sort: sort,
            }
        })

        return {
            data: data.data.data,
            total: data.data.count
        }
    }

    async getAllVasUserTransactionsForServiceMapping(vasMappingId: number, limit: number, offset: number): Promise<GetListResult<VasTransactionResponseDto>> {
        let data = await HttpClient.get(`/centaurus/vas/service-bundle-mappings/${vasMappingId}/transactions`, {
            params: {
                limit,
                offset
            }
        })

        return {
            data: data.data.data,
            total: data.data.count
        }
    }

    async createVasUserTransactions(userServiceMappingId: string, data: any) {
        const body: CreateVasUserTransactionsDto = {
            ...data
        }
        const res = await HttpClient.post(`/centaurus/vas/service-bundle-mappings/${userServiceMappingId}/transaction`, body);
        return res.data
    }

    async getOneVasTransaction(transactionId: number): Promise<GetOneResult<VasTransactionResponseDto>>{
        const res = await HttpClient.get(`/centaurus/vas/service-transaction/${transactionId}`);
        return res
    }

    async PatchVasUserTransaction(transactionId: number, data: any) {
        const res = await HttpClient.patch(`/centaurus/vas/service-transaction/${transactionId}`, {
            date: data.date,
            time: data.time,
            commentFromAdmin: data.commentFromAdmin
        });
        return res
    }

    async assignVendorToTransaction(userId: number, userType: UserType, transactionId: number) {
        await HttpClient.patch(`/centaurus/vas/service-transaction/${transactionId}/assign-vendor`, {
            userId,
            userType
        })
    }

    async markTransactionAsComplete(transactionId: number) {
        await HttpClient.patch(`/centaurus/vas/service-transaction/${transactionId}/mark-complete`)
    }

    async cancelTransaction(transactionId: number) {
        await HttpClient.delete(`/centaurus/vas/service-transaction/${transactionId}/cancel`)
    }

    async getAllVasMapping(limit: number, offset: number): Promise<GetListResult<VasResponse>> {
        let data = await HttpClient.get('/centaurus/vas/service-bundle-mappings', {
            params: {
                limit,
                offset
            }
        })
        return {
            data: data.data.data,
            total: data.data.count
        }
    }

    async createVasMapping(data: any) {
        const body: CreateVasDto = {
            ...data
        }
        const res = await HttpClient.post('/centaurus/vas', body);
        return res
    }

    async getVasById(id: number): Promise<GetOneResult<VasResponse>> {
        const res = await HttpClient.get(`/centaurus/vas/${id}`);
        return res;
    }
}
