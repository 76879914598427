import HttpClient from "../HttpClient";
import FieldViewResponse from "../interfaces/FieldView/FieldViewResponse";
import CookAdditionalViewResponse from "../interfaces/FieldView/CookAdditionalViewResponse";
export default class FieldViewService {

    async createAdditionalDetailParameters(payload: any): Promise<FieldViewResponse> {
        const data = await HttpClient.post(`/centaurus/additional-detail-parameters`, payload);
        return data?.data;
    }
    async updateAdditionalDetailParameters(id: number, payload: any): Promise<FieldViewResponse> {
        const data = await HttpClient.patch(`/centaurus/additional-detail-parameters/${id}`, payload);
        return data?.data;
    }
    async getAllDetailParameters(limit: number, offset: number): Promise<
        {
            total: number,
            data: FieldViewResponse[]
        }>
    {
        const response = await HttpClient.get(`/centaurus/additional-detail-parameters/all?limit=${limit}&offset=${offset}`);
        return {
            data: response?.data?.data,
            total: response?.data?.count,
        }
    }

    async getAllCookAdditionalDetails(limit: number, offset:number, filters: any): Promise<
        {
            total: number,
            data: CookAdditionalViewResponse[]
        }> {
        const response = await HttpClient.get(`/centaurus/cook-additional-details/all?limit=${limit}&offset=${offset}&filters=${filters}`);
        return {
            data: response?.data?.data,
            total: response?.data?.count,
        }
    }
    async createCookAdditionalDetail(payload: any): Promise<CookAdditionalViewResponse> {
        const data = await HttpClient.post(`/centaurus/cook-additional-details`, payload);
        return data?.data;
    }
    async updateCookAdditionalDetail(cookAdditionalDetailId: number, payload: any): Promise<CookAdditionalViewResponse> {
        const data = await HttpClient.patch(`/centaurus/cook-additional-details/${cookAdditionalDetailId}`, payload);
        return data?.data;
    }
    async bulkUploadLeads(formData: FormData) {
        let authData = localStorage.getItem('auth');
        const { accessToken } = !!authData ? JSON.parse(authData as string).data : '';
        const headers = new Headers({
            'X-APP-CODE': 'ADMIN',
            'Authorization': `Bearer ${accessToken}`,
        })
        function removeTrailingSlash(url: any) {
            if (url.slice(-1) === '/') {
                return url.slice(0, -1);
            }
            return url;
        }
        const res = await fetch(removeTrailingSlash(process.env.REACT_APP_PUBLIC_URL) + '/centaurus/cook/bulk-upload-leads', {
            method: 'POST',
            headers: headers,
            body: formData,
        })
        return res?.json();
    }
}
