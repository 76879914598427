
import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import { ShortTimeEnum } from "../../enums/ShortTimeEnum";
import { useState } from "react";
import { TempReplacementService } from "../../services/TemporaryReplacement";
import { useRefresh } from "react-admin";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";

interface InputProps {
    handleClose: any
    open: boolean
    data: any
}
const tempReplacement = new TempReplacementService()
export const ChangeTrTimeDialog = (props: InputProps) => {
    const refresh = useRefresh();
    const [timeSelectValue, setTimeSelectValue] = useState<string>('');

    const handleClose = () => {
        props.handleClose(false);
        setTimeSelectValue('')
    }
  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      scroll={"paper"}
      maxWidth="xl"
    >
      <DialogTitle>Change TR Time</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          justifyContent={"space-evenly"}
          style={{ padding: "12px 12px 12px 12px" }}
        >
          <Grid item>
            <FormControl style={{width:"170px", marginTop:"10px"}}>
                <InputLabel id="timeSelect">Time</InputLabel>
                <Select
                    labelId="timeSelect"
                    id="timeSelect"
                    label="Time"
                    value={timeSelectValue}
                    onChange={(event: SelectChangeEvent) => {
                        setTimeSelectValue(event.target.value as string)
                    }}
                >
                    {
                        Object.values(ShortTimeEnum).map((value) => (
                            <MenuItem key={value} value={value}>{value}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginTop: "12px" }}>
        <Button
          variant="contained"
          size={"large"}
          onClick={() => {
            if (!timeSelectValue) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Select the Time to proceed",
                  type: CustomSnackbarTypes.WARNING,
                });
            }
            tempReplacement.changeTrTime(props.data.id, { time: timeSelectValue }).then(() => {
              refresh();
              handleClose();
            });
          }}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            handleClose()
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
