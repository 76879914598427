export default function numberToShirtSizeMapping(key: number) {
    let numberToShirtMap = new Map();

    numberToShirtMap.set(34, "EXTRA_SMALL");
    numberToShirtMap.set(36, "SMALL");
    numberToShirtMap.set(38, "MEDIUM");
    numberToShirtMap.set(40, "LARGE");
    numberToShirtMap.set(42, "EXTRA_LARGE");
    numberToShirtMap.set(44, "EXTRA_EXTRA_LARGE");

  
    return numberToShirtMap.get(key);
  }
  