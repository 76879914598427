import { GetListResult, GetOneResult } from "react-admin";
import HttpClient from "../HttpClient";
import { CustomerResponse } from "../interfaces/Customer/CustomerResponse";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";
import CleanFilters from "../utils/CleanFilters";
import { CallMaskingReferenceModelNameEnum } from "../enums/CallMaskingReferenceModelNameEnum";

export class CustomerService {
    async getAllCustomers(limit: number, offset: number, filter: any): Promise<GetListResult<CustomerResponse>> {
        function processData(data: any) {
            if (/[0-9]/.test(data.fname)) {
                delete data.fname;
            }

            return data;
        }
        filter = processData(filter)
        let filters = ConstructSequelizeFilters(filter, ["fname"], ["mobile"]);
        let data = await HttpClient.get('/centaurus/user/customer', {
            params: {
                filters,
                limit,
                offset
            }
        })
        return {
            data: data.data.data,
            total: data.data.count
        }
    }
    async getCustomerById(id: number): Promise<GetOneResult<CustomerResponse>> {
        let data = await HttpClient.get('/centaurus/user/customer/'+id);
        return data
    }

    async updateCustomerById(payload: any): Promise<GetOneResult<CustomerResponse>> {
        let { id } = payload
        let body = CleanFilters(payload, ["fname","mname","lname","address","email"])
        let data = await HttpClient.put('/centaurus/user/customer/'+id, body);
        return data
    }
    async getAllTransactionForCustomer(customerId:number,limit:number,offset:number):Promise<GetOneResult<CustomerResponse>>{
        let data=await HttpClient.get(`/centaurus/user/customer/${customerId}/razorpay-transactions`,
            {
                params:{
                    limit,
                    offset
                }
            }
        )
        return data;
    }
    async getAllHouseForCustomer(customerId: number){
        let data = await HttpClient.get(`/centaurus/user/customer/${customerId}/houses`);
        return data
    }

    async createCustomer(payload: any): Promise<GetOneResult<any>> {
        let data : any  = await HttpClient.post(`/centaurus/user/customer`, payload);
        return data
    }
    async getMaskedCallRecording(customerId: number,referenceModelName:CallMaskingReferenceModelNameEnum): Promise<GetOneResult<any>>{
        let data = await HttpClient.get(`/call-masking/${referenceModelName}/${customerId}/recording`)
        return data
    }

}
