import {
    List,
    TextField,
    ShowButton,
    EditButton,
    useRecordContext,
    TextInput,
    TopToolbar,
    ExportButton,
    CreateButton
} from "react-admin";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../store/hooks";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";
import {UserGroupsEnum} from "../../enums/UserGroupsEnum";
import ResourceSelectDialogButton from "../Common/ResourceSelectDialogButton";
import { ConfigurableDatagridColumn, DatagridConfigurable } from "../Common/ReactAdminWrapper/DatagridConfigurable";
import { SelectColumnsButton } from "../Common/ReactAdminWrapper/SelectColumnsButton";
interface Props {
    label: string;
}

const EditUserGroupButton = (props: Props) => {
    const navigate = useNavigate();
    const record = useRecordContext();
    return (
        <Button
            onClick={() => {
                navigate(`/edit-access-groups/${record.id}`)
            }}
            variant={"outlined"}
            size={"small"}
            style={{ width: "max-content", fontSize: "12px" }}
        >
            {props.label}
        </Button>
    )
}

 const PostListActions = () => (
     <TopToolbar>
           <SelectColumnsButton callback={(event: string[], columns: ConfigurableDatagridColumn) => {console.log('event', event, columns)}} />
           <CreateButton/>
           <ExportButton/>
      </TopToolbar>
    );
const adminSearchFilters = [
    <TextInput label="Search by Name" source="name" alwaysOn />,
    <TextInput label="Search by Email" source="email" alwaysOn />,
]

const AdminUserList = (props: any) => {
    const userDetails = useAppSelector((state) => state.userDetails.value);
    let groupsArray: string[] = userDetails.adminUser.accessGroups;
    const isCustomSelectOpen = useAppSelector((state) => state.customSelect.value.open);

    return (
        <List {...props} filters = {adminSearchFilters} actions={<PostListActions />}>
            <DatagridConfigurable bulkActionButtons={false}>
                {
                    isCustomSelectOpen &&
                    <ResourceSelectDialogButton />
                }
                <TextField source='id' />
                <TextField source='name' />
                <TextField source='email' />
                <ShowButton label="Show" />
                {
                    GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN]) &&
                    <EditButton label="Edit"/>
                }
                {
                    GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER]) &&
                    <EditUserGroupButton label="Edit Access"/>
                }
            </DatagridConfigurable>
        </List>
    )
}

export default AdminUserList;
