import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  FormControl,
} from "@mui/material";
import { useState } from "react";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import LocalityResponse from "../../interfaces/Locality/LocalityResponse";
import { CustomerService } from "../../services/CustomerService";
import HouseService from "../../services/HouseService";
import { useAppDispatch } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import LocalityList from "../Locality/LocalityList";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import ResourceSelectDialog from "./ResourceSelectDialog";

interface InputProps {
  open: boolean;
  setOpen: (data: any) => void;
  setHouse?: (data: any) => void;
  setAreaId?: (data: any) => void;
}

const customerService = new CustomerService();
const houseService = new HouseService();

const CommonDialogForUserAndHouse = (props: InputProps) => {
  const [customerRes, setCustomerRes] = useState<any>();
  const [fname, setFname] = useState();
  const [lname, setLname] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState();
  const [locality, setLocality] = useState();
  const [city, setCity] = useState();
  const [openDialog, setOpenDialog] = useState(false)
  const [data, setData] = useState<LocalityResponse>();
  const dispatch = useAppDispatch();

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSubmit = async () => {
    let mobileNumber;
    if(mobile.length >= 10){
      mobileNumber = mobile.slice(-10)
    }
    await customerService
      .createCustomer({ fname, lname, mobile: mobileNumber, email, address })
      .then((res: any) => {
        if (res.status === 200) {
          houseService
            .createHouse({ city, locality, address, customerId: res?.data?.id, localityId: Number(data?.id), areaId: Number(data?.areaId), cityId: Number(data?.cityId) })
            .then((res: any) => {
              props.setHouse && props.setHouse(res?.data?.id);
              props.setAreaId && props.setAreaId(res?.data?.id);
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Successfully Created",
                type: CustomSnackbarTypes.SUCCESS,
              });
            })
        }
      })
    props.setOpen(false);
  };

  const handleSearch = () => {

    customerService
      .getAllCustomers(10, 0, { mobile: mobile })
      .then((res) => {
        setCustomerRes(res);
      })
      .catch((err) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: err,
          type: CustomSnackbarTypes.INFO,
        });
      });
  }

  const handleLocalityClose = (recordData: LocalityResponse) => {
    setOpenDialog(false)
    setData(recordData)
  }

  const handleOpenLocality = () => {
    dispatch(setCustomSelectOpen({open: true}));
  }

  return (
    <div>
      { openDialog &&
      <ResourceSelectDialog
        name={"Localities"}
        list={LocalityList}
        onClose={handleLocalityClose}
      />}
      <Dialog open={props.open} fullWidth={true} maxWidth="md">
        <DialogContent>
          <>
            {customerRes?.total !== 0 ? (
              <div>
                <FormControl>
                  <TextField
                    label="Search Customer By Mobile"
                    style={{ margin: "10px" }}
                    type="number"
                    onChange={(e: any) => {
                      setMobile(e.target.value);
                    }}
                  />
                </FormControl>
                {customerRes && customerRes?.total !== 0 && (
                  <p>Customer Already Exsist</p>
                )}
              </div>
            ) : (
              <div>
                <p>Create Customer and House</p>
                <FormControl>
                  <TextField
                    variant="filled"
                    required
                    label="fname"
                    style={{ margin: "10px" }}
                    onChange={(e: any) => setFname(e.target.value)}
                  />
                  <TextField
                    variant="filled"
                    label="lname"
                    style={{ margin: "10px" }}
                    onChange={(e: any) => setLname(e.target.value)}
                  />
                  <TextField
                    variant="filled"
                    label="email"
                    style={{ margin: "10px" }}
                    onChange={(e: any) => setEmail(e.target.value)}
                  />
                  <TextField
                    defaultValue={mobile}
                    variant="filled"
                    required
                    label="mobile"
                    type="number"
                    style={{ margin: "10px" }}
                    onChange={(e: any) => setMobile(e.target.value)}
                  />
                  <TextField
                    variant="filled"
                    required
                    label="address"
                    style={{ margin: "10px" }}
                    onChange={(e: any) => setAddress(e.target.value)}
                  />
                  <TextField
                    variant="filled"
                    required
                    label="locality"
                    style={{ margin: "10px" }}
                    onChange={(e: any) => setLocality(e.target.value)}
                  />
                  <TextField
                    variant="filled"
                    required
                    label="city"
                    style={{ margin: "10px" }}
                    onChange={(e: any) => setCity(e.target.value)}
                  />
                  <Button
                   style={{ margin: "10px" }}
                   variant="contained"
                   size={"small"}
                   onClick={() => {
                    setOpenDialog(true)
                    handleOpenLocality();
                   }}
                  >
                   SLC
                  </Button>
                  { data &&
                  <div>
                    <p>locality Id: {data?.id}</p>
                    <p>Area Id: {data?.areaId}</p>
                    <p>City Id: {data?.cityId}</p>
                    </div>
                    }
                </FormControl>
              </div>
            )}
          </>
        </DialogContent>
        <DialogActions>
          {customerRes?.total !== 0 && (
            <Button
              style={{ margin: "10px" }}
              variant="contained"
              disabled={mobile.length < 10 || mobile.length > 12 }
              onClick={() => {
                handleSearch();
              }}
              size={"large"}
            >
              Search
            </Button>
          )}
          {customerRes && customerRes?.total === 0 && (
            <Button
              style={{ margin: "10px" }}
              variant="contained"
              onClick={() => {
                if(!fname || !mobile || !address || !city || !locality || !address || !Number(data?.id) || !Number(data?.areaId) || !Number(data?.cityId)){
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "please fill the required field",
                    type: CustomSnackbarTypes.INFO,
                  });
                  return
                }else {
                handleSubmit();
                }
              }}
              size={"large"}
            >
              Submit
            </Button>
          )}
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              handleClose();
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CommonDialogForUserAndHouse;
