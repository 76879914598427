import React, { useEffect, useState } from "react";
import { Create } from "react-admin";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { CouponType } from "../../enums/CouponType";
import { ProductType } from "../../enums/ProductType";
import { DateTimePicker } from "@mui/x-date-pickers";
import TransactionButton from "../Common/TransactionButton";
import { CouponServiceV2 } from "../../services/CuponServiceV2";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import { useNavigate } from "react-router-dom";
import { default as TextFieldMUI } from "@mui/material/TextField/TextField";
import { useLocation } from "react-router-dom";

const couponServiceV2 = new CouponServiceV2();

interface item {
  category: string;
  subCategory: string[];
}

const categoryItems: item[] = [
  {
    category: "Sales",
    subCategory: ["Default Value in case the coupon is generated by System"],
  },
  {
    category: "Marketing",
    subCategory: ["Offline", "Online"],
  },
  {
    category: "Retention",
    subCategory: ["Winback", "Renewal", "Previous Bundle Credit"],
  },
];

interface Coupon {
  couponCode: string;
  couponValue: string;
  couponType: string;
  productType: string;
  usageLimitPerCustomer: Number;
  maximumUsageLimit: Number;
  minimumOrderValue: string;
  maximumDiscountAmount: string;
  couponHeader: string;
  couponDescription: string;
  isActive: boolean;
  visibleOnApp: boolean;
  userSet: any;
  areaSet: any;
}

const CouponEdit = () => {
  const defaultValue: Coupon = {
    couponCode: '',
    couponValue: '',
    couponType: '',
    productType: '',
    usageLimitPerCustomer: 0,
    maximumUsageLimit:  0,
    minimumOrderValue: '',
    maximumDiscountAmount: '',
    couponHeader: '',
    couponDescription: '',
    isActive: false,
    visibleOnApp: false,
    userSet: [],
    areaSet: [],
  }
  const [couponState, setCouponState] = useState<Coupon>(defaultValue);
  const [expiryDate, setExpiryDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [reasonSubCategory, setReasonSubCategory] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [disableAreaSetButton, setDisableAreaSetButton] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const couponId = location.pathname.split("/")[2];

  const handleReasonCategoryChange = (event: any) => {
    setSelectedCategory(event.target.value);
  };

  const handleReasonSubCategoryChange = (event: any) => {
    setReasonSubCategory(event.target.value);
  };

  function showSubCategoryOrNot(reasonCategory: string) {
    if (reasonCategory === null) {
      return null;
    }
    let selectedCategoryItem = categoryItems?.filter(
      (e) => e.category === reasonCategory
    )[0];
    return (
      <FormControl style={{ width: "230px" }}>
        <InputLabel id="select-label">Select a reason</InputLabel>
        <Select
          defaultValue="Select a reason"
          value={reasonSubCategory}
          onChange={handleReasonSubCategoryChange}
        >
          {selectedCategoryItem?.subCategory.map((val) => {
            return (
              <MenuItem key={val} value={val}>
                {val}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  const validate = () => {
    const errors: any = {};
    if (Number(couponState?.couponValue) < 0) {
      errors.couponValue = "Must be greater than 0";
    }
    if (couponState?.couponType) {
      errors.couponType = "Required";
    }
    if (couponState?.productType) {
      errors.productType = "Required";
    }
    return errors;
  };

  useEffect(() => {
     couponServiceV2.getOneCupon(Number(couponId)).then((res) => {
      let data = res?.data;
       data.userSet = data?.userSet && data?.userSet.map((x: number) => JSON.stringify(x) + '\n').join().replaceAll(',', '');
       data.areaSet = data?.areaSet && data?.areaSet.map((x: number) => JSON.stringify(x) + '\n').join().replaceAll(',', '');
      let SelectedCategory = data?.reason && data?.reason.split(" ")[0]
      let text = data?.reason && data?.reason.indexOf(' ')
      const SelectedSubCategory = data?.reason.substring(text + 1);
      setCouponState(data)
      setSelectedCategory(data?.reason.split())
      setSelectedCategory(SelectedCategory)
      setReasonSubCategory(SelectedSubCategory)
      setExpiryDate(data?.expiresAt)
      setStartDate(data?.startsAt)
     })
}, [couponId])

  const handleSubmit = () => {
    if (validate()) {
      let payload = {
        couponCode: couponState?.couponCode,
        couponValue: couponState?.couponValue,
        couponType: couponState?.couponType,
        productType: couponState?.productType,
        usageLimitPerCustomer: Number(couponState?.usageLimitPerCustomer),
        maximumUsageLimit: Number(couponState?.maximumUsageLimit),
        minimumOrderValue: couponState?.minimumOrderValue,
        maximumDiscountAmount: couponState?.maximumDiscountAmount,
        couponHeader: couponState?.couponHeader,
        couponDescription: couponState?.couponDescription,
        reason: selectedCategory + " " + reasonSubCategory,
        isActive: couponState?.isActive.toString() === "true" ? true : false,
        visibleOnApp: couponState?.visibleOnApp.toString() === "true" ? true : false,
        expiresAt: expiryDate,
        startsAt: startDate,
        userSet: couponState?.userSet ? couponState?.userSet.trim().split('\n').map((x: any) => Number(x)).filter((x: any) => !isNaN(x)) : couponState?.userSet,
        areaSet: couponState?.areaSet ? couponState?.areaSet.trim().split('\n').map((x: any) => Number(x)).filter((x: any) => !isNaN(x)) : couponState?.areaSet,
      };
      couponServiceV2.updateCoupon(Number(couponId), payload).then((data) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Success",
          type: CustomSnackbarTypes.SUCCESS,
        });
        if (data?.data?.id) {
          navigate(`/CouponsV2/${data?.data?.id}/show`, { replace: true });
        }
      });
    }
  };

  return (
    <Create title={"Edit Coupon"}>
      <Grid container columns={1} rowSpacing={1} style={{ padding: "12px" }}>
        <Grid item xs={4}>
          <Box
            display={"flex"}
            alignItems={"center"}
            style={{ flexWrap: "wrap" }}
          >
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="Coupon Code"
                value={couponState?.couponCode}
                disabled={disabled}
                onChange={(event) => {
                  setCouponState((prevState: Coupon) => ({...prevState, couponCode: event.target.value}))
                }}
              />
            </FormControl>
            <TransactionButton
              name={"Generate From System"}
              onClickHandler={() => {
                setDisabled(true);
              }}
              style={{ marginLeft: "30px" }}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <TextField
              label="Coupon Value"
              value={couponState?.couponValue}
              disabled={disabled}
              type="number"
              onChange={(event: any) => {
                setCouponState((previous: any) => ({...previous, couponValue: event.target.value}))
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <InputLabel id="couponType">Coupon Type</InputLabel>
            <Select
              labelId="couponType"
              value={couponState?.couponType}
              disabled={disabled}
              label="Coupon Type"
              onChange={(event: any) => {
                setCouponState((previous: any) => ({...previous, couponType: event.target.value}))
              }}
            >
              {Object.values(CouponType).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <InputLabel id="productType">Product Type</InputLabel>
            <Select
              labelId="productType"
              value={couponState?.productType}
              disabled={disabled}
              label="Product Type"
              onChange={(event: any) => {
                setCouponState((previous: any) => ({...previous, productType: event.target.value}))
              }}
            >
              {Object.values(ProductType).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <TextField
              label="Usage Limit Per Customer"
              value={couponState?.usageLimitPerCustomer}
              type="number"
              onChange={(event: any) => {
                setCouponState((previous: any) => ({...previous, usageLimitPerCustomer: event.target.value}))
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <TextField
              label="Maximum Usage Limit"
              value={couponState?.maximumUsageLimit}
              type="number"
              onChange={(event: any) => {
                setCouponState((previous: any) => ({...previous, maximumUsageLimit: event.target.value}))
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <TextField
              label="Minimum Order Value"
              value={couponState?.minimumOrderValue}
              type="number"
              onChange={(event: any) => {
                setCouponState((previous: any) => ({...previous, minimumOrderValue: event.target.value}))
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <TextField
              label="Maximum Discount Amount"
              value={couponState?.maximumDiscountAmount}
              type="number"
              onChange={(event: any) => {
                setCouponState((previous: any) => ({...previous, maximumDiscountAmount: event.target.value}))
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <TextField
              label="Coupon Header"
              value={couponState?.couponHeader}
              onChange={(event: any) => {
                setCouponState((previous: any) => ({...previous, couponHeader: event.target.value}))
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <TextareaAutosize
              placeholder="Coupon Description"
              minRows={3}
              maxRows={3}
              value={couponState?.couponDescription}
              onChange={(event: any) => {
                setCouponState((previous: any) => ({...previous, couponDescription: event.target.value}))
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <InputLabel id="select-label">Reason</InputLabel>
            <Select
              value={selectedCategory}
              onChange={handleReasonCategoryChange}
            >
              {categoryItems.map((item: item) => {
                return (
                  <MenuItem key={item.category} value={item.category}>
                    {item.category}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {selectedCategory && (
          <Grid item xs={4}>
            {showSubCategoryOrNot(selectedCategory as string)}
          </Grid>
        )}
        <Grid item xs={4}>
          <DateTimePicker
            label="Coupon Start Date"
            inputFormat="dd MMM yyyy HH:mm:ss"
            value={startDate}
            disableMaskedInput
            onChange={(newValue) => {
             setStartDate(newValue)
            }}
            renderInput={(params) => {
              return <TextField {...params} />;
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <DateTimePicker
            label="Coupon Expiry Date"
            inputFormat="dd MMM yyyy HH:mm:ss"
            value={expiryDate}
            disableMaskedInput
            onChange={(newValue) => {
           setExpiryDate(newValue)
            }}
            renderInput={(params) => {
              return <TextFieldMUI {...params} />;
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Box
            display={"flex"}
            alignItems={"center"}
            style={{ flexWrap: "wrap" }}
          >
            <FormControl style={{ width: "230px" }}>
              <textarea
                placeholder="User Set"
                value={couponState?.userSet}
                disabled={disableButton}
                rows={3}
                onChange={(event: any) => {
                  setCouponState((previous: any) => ({...previous, userSet: event.target.value}))
                }}
              />
            </FormControl>
            <TransactionButton
              name={disableButton ? 'Enable User Set' : "Disable User Set"}
              onClickHandler={() => {
                setDisableButton(!disableButton);
                setCouponState((previous: any) => ({...previous, userSet: null}))
              }}
              style={{ marginLeft: "30px" }}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            display={"flex"}
            alignItems={"center"}
            style={{ flexWrap: "wrap" }}
          >
            <FormControl style={{ width: "230px" }}>
              <textarea
                placeholder="Area Set"
                value={couponState?.areaSet}
                disabled={disableAreaSetButton}
                rows={3}
                onChange={(event: any) => {
                  setCouponState((previous: any) => ({ ...previous, areaSet: event.target.value }));
                }}
              />
            </FormControl>
            <TransactionButton
              name={disableAreaSetButton ? 'Enable Area Set' : "Disable Area Set"}
              onClickHandler={() => {
                setDisableAreaSetButton(!disableAreaSetButton)
                setCouponState((previous: any) => ({ ...previous, areaSet: null }));
              }}
              style={{ marginLeft: "30px" }}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <FormControl>
            <FormLabel id="utensils">Is Active</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(event: any) => {
                setCouponState((previous: any) => ({...previous, isActive: event.target.value}))
              }}
              value={couponState?.isActive}
            >
              <FormControlLabel value={true} control={<Radio />} label="True" />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="False"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl>
            <FormLabel id="utensils">Visible On App</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(event: any) => {
                setCouponState((previous: any) => ({...previous, visibleOnApp: event.target.value}))
              }}
              value={couponState?.visibleOnApp}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
            size={"large"}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Create>
  );
};

export default CouponEdit;
