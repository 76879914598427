import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  DateField,
} from "react-admin";
import GoogleMapsField from "../Common/GoogleMapsField";

const LocalityMetaShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField source="areaId" link="show" reference="Areas">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="cityId" link="show" reference="Cities">
          <TextField source="name" />
        </ReferenceField>
        <GoogleMapsField source="coordinates" />
        <TextField source="rentPayingCapacity" />
        <TextField label={'Trial Auto Allocation Status'} source="hasTrialAutoAllocation" />
        <TextField source="availableTrialType" />
        <TextField label={'Oms Auto Allocation Status'} source="hasOmsAutoAllocation" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </SimpleShowLayout>
    </Show>
  );
};

export default LocalityMetaShow;
