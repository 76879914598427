import React, { useCallback, useEffect, useState } from "react";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import DeniedCookInterface from "../../interfaces/Cook/DeniedCookInterface";
import { TrialsService } from "../../services/TrialServices";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import DeniedCooks from "../Common/DeniedCooks";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";

const trialService = new TrialsService();

interface InputProps {
  trialId: number;
}

const TrialDeniedCooksList = (props: InputProps) => {
  const [deniedCooksInTrials, setDeniedCookSInTrials] = useState<
    DeniedCookInterface[]
  >([]);

  const revertDeniedCook = (trialId: number, cookId: number) => {
    trialService
      .revertDeniedCook(trialId, cookId)
      .then((res) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Cook Revereted Successfully",
          type: CustomSnackbarTypes.SUCCESS,
        });
        refreshData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const refreshData = useCallback(() => {
    trialService
    .getDeniedCooks(props.trialId)
    .then((res) => {
      setDeniedCookSInTrials(res);
    })
    .catch((err) => {
      console.log(err);
    });
  },[props.trialId])

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  return (
    <div>
      {deniedCooksInTrials.length === 0 ? (
        <EmptyListPlaceholder message="No Cooks Found" />
      ) : (
        <DeniedCooks cookData={deniedCooksInTrials} callBack={revertDeniedCook} id={props.trialId} refresh={refreshData}/>
      )}
    </div>
  );
};

export default TrialDeniedCooksList;
