import {
    Autocomplete,
    Box,
    FormControl,
    FormLabel,
    RadioGroup,
    TextField,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import FreshLeadInterface from "../../interfaces/Cook/FreshLeadInterface";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import CityResponse from "../../interfaces/City/CityResponse";
import CityService from "../../services/CityService";
import {HubsResponse} from "../../interfaces/Hubs/HubsInterfaces";
import HubServices from "../../services/HubServices";
import Op from "../../interfaces/SequalizeFilters/SequalizeTypes";
import { debounce } from "lodash";

const cityService = new CityService();
const hubServices = new HubServices();
interface InputProps {
    cookDetail: FreshLeadInterface;
    onCallback: (data: any) => void
}

const CurrentCitySelect = ({ cookDetail, onCallback }: InputProps) => {

    const [allCities, setAllCities] = useState<CityResponse[]>([]);
    const [fetchedCities,setFetchedCities] = useState<CityResponse[]>([])
    const [inputCity,setInputCity]=useState<any>("");
    const [allHubs, setAllHubs] = useState<HubsResponse[]>([]);
    const [selectedHub, setSelectedHub] = useState<any>();

    const [isOther, setOther] = useState<boolean>(false);
    const [text, setText] = useState<string>('');

    useEffect(() => {
        cityService.fetchAllCities(100, 0, {isCurrentlyServing:"YES"}, {})
            .then((res) => {
                setAllCities(res?.data);
            })
    }, []);

    useEffect(() => { // Create a debounced function to fetch cities
        const debouncedFetchCities = debounce(() => {
          if(!inputCity){
            setFetchedCities(allCities);
            return;
          }
            cityService.fetchAllCities(100, 0,{name:inputCity}, {})
              .then((res) => {
                setFetchedCities(res?.data || []);
              }).catch((err)=>{
                console.log(err);
              });
        }, 1000); // Debounce delay set to 1000ms
          debouncedFetchCities(); // Call the debounced function
        
          // Cleanup function to cancel the debounced function on unmount or re-run
          return () => {
            debouncedFetchCities.cancel(); // Cancel any pending debounced calls
          };
        }, [inputCity,allCities]);

    useEffect(() => {
        if(!cookDetail?.currentAddressCity) {
            return;
        }
        let filter = {"cityName":{[Op.like.toString()]: `%${cookDetail?.currentAddressCity?.toString()}%`}};
        setAllHubs([]);
        hubServices.getAllHubs(1000, 0, {}, {"field":"name","order":"ASC"}, filter)
            .then((res) => {
                setAllHubs(res?.data);
            })
    }, [cookDetail?.currentAddressCity])

    useEffect(() => {
        if(cookDetail?.chefHub) {
            setSelectedHub(cookDetail?.chefHub);
        }
        if(cookDetail?.otherChefHub && cookDetail?.otherChefHub !== '') {
            setOther(true);
            setText(cookDetail?.otherChefHub);
        }
    }, [cookDetail?.chefHub, cookDetail?.otherChefHub]);

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <FormControl>
                <Autocomplete
                  disablePortal
                  value={cookDetail?.currentAddressCity}
                  options={fetchedCities?.map((city: any) => city?.name)}
                  inputValue={inputCity}
                  onInputChange={(e,v)=>setInputCity(v)}
                  onChange={(e, value) => {
                    onCallback({currentAddressCity: value})
                  }}
                  renderInput={(params) => <TextField {...params} label="In which city do you currently live ?`" />}
                />
            </FormControl>
            {allHubs?.length ? (
                <FormControl>
                    <FormLabel>
                        {`In which area do you currently live?`}
                    </FormLabel>
                    <RadioGroup>
                        {allHubs?.map((eachHub) => {
                            return(
                                <FormControlLabel
                                    checked={selectedHub === eachHub?.id}
                                    onChange={() => {
                                        setOther(false);
                                        setText('');
                                        setSelectedHub(eachHub?.id);
                                        onCallback({otherChefHub: ''})
                                        onCallback({hubId: eachHub?.id})
                                    }}
                                    value={eachHub?.name}
                                    control={<Radio />}
                                    label={eachHub?.name}
                                />
                            )
                        })}
                        <FormControlLabel
                            checked={isOther}
                            onChange={() => {
                                setOther(true);
                                setText('');
                                setSelectedHub(null);
                                // onCallback('')
                            }}
                            value="Others"
                            control={<Radio />}
                            label="Others"
                        />
                    </RadioGroup>
                    {isOther && (
                        <TextField
                            label={'Enter the work place'}
                            onChange={(e: any) => {
                                setText(e.target.value);
                                onCallback({otherChefHub: e.target.value})
                            }}
                            value={text}
                        />
                    )}
                </FormControl>
            ) : ''}
        </Box>
    );
};

export default CurrentCitySelect;
