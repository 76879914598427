import {FeatureFlags} from "./FeatureFlags";

function GroupPermissionFunction(userAccess: string[], moduleAccess: string[], resourceName: string = '') {
  if (resourceName && !(FeatureFlags as any)[resourceName]) {
    return false;
  }
  const found = userAccess?.some((item) => moduleAccess?.indexOf(item) >= 0);
  return found;
}

export default GroupPermissionFunction;
