import {
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
  } from "@mui/material";
  
  interface TableCellElement {}
  interface InputProps {
    size?: "small" | "medium";
    sx: object;
    headers: string[];
    children?: React.ReactNode;
    alignment?: "inherit" | "left" | "center" | "right" | "justify";
    rowData: TableCellElement[];
    isTablePagination: boolean;
    paginationCount?: number;
    paginationRowsPerPage?: number;
    paginationPage?: number;
    paginationOnPageChange?: (event: any, newPage: number) => void;
    paginationoOnRowsPerPageChange?: (event: any) => void;
    paginationRowsPerPageOptions?: number[];
    paginationLabelRowsPerPage?: React.ReactNode;
    paginationLabelDisplayedRows?: ({ page }: any) => string;
    paginationBackIconButtonProps?: object;
    paginationNextIconButtonProps?: object;
    paginationSelectProps?: object;
    isStickyHeader?: boolean;
  }

  function CkMuiTable(props: InputProps) {
    return (
      <Table stickyHeader={props.isStickyHeader ?? false} size={props.size} sx={props.sx}>
        <TableHead>
          <TableRow>
            {props.headers.map((header) => (
              <TableCell align={props.alignment || "left"} key={header}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.children
            ? props.children
            : props.rowData.map((data) => (
                <TableRow>
                  {Object.values(data).map((value) => (
                    <TableCell align={props.alignment || "left"}>
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
        </TableBody>
        {props.isTablePagination && (
          <TableFooter>
            <TableRow>
              <TablePagination
                count={props.paginationCount || 0}
                rowsPerPage={props.paginationRowsPerPage || 0}
                page={props.paginationPage || 0}
                onPageChange={
                  props.paginationOnPageChange ||
                  function (event: any, page: number) {
                    return;
                  }
                }
                onRowsPerPageChange={props.paginationoOnRowsPerPageChange}
                rowsPerPageOptions={props.paginationRowsPerPageOptions}
                labelRowsPerPage={props.paginationLabelRowsPerPage}
                labelDisplayedRows={props.paginationLabelDisplayedRows}
                backIconButtonProps={props.paginationBackIconButtonProps}
                nextIconButtonProps={props.paginationNextIconButtonProps}
                SelectProps={props.paginationSelectProps}
                showFirstButton={true}
                showLastButton={true}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    );
  }
  
  export default CkMuiTable;