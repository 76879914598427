import { UserGroupsEnum } from "../enums/UserGroupsEnum";

 export const menuOptions = [
     {
         title: 'Admin Users',
         url: '/AdminUsers',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Employee Roster',
         url: '/EmployeeRoster',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Cooks',
         url: '/Cooks',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.PROMOTOR],
     },
     {
         title: 'Cooks Serve Calendar',
         url: '/CooksServeCalendar',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER, UserGroupsEnum.PROMOTOR],
     },
     {
         title: 'Visit Tracker',
         url: '/VisitTracker',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER, UserGroupsEnum.PROMOTOR],
     },
     {
         title: 'Daily CookSlot',
         url: '/DailyCookSlot',
         permission: [UserGroupsEnum.PROMOTOR],
     },
     {
         title: 'Customers',
         url: '/Customers',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Cities',
         url: '/Cities',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Areas',
         url: '/Areas',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Localities',
         url: '/Localities',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Houses',
         url: '/Houses',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'House Reviews',
         url: '/HouseReviews',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Vendors',
         url: '/Vendors',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Vas',
         url: '/Vas',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Vas Transactions',
         url: '/VasTransactions',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'User Bundle Service Mappings',
         url: '/UserBundleServiceMappings',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Bundles',
         url: '/Bundles',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'User Bundles',
         url: '/UserBundles',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Chef For Party',
         url: '/ChefForParty',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Refund V2',
         url: '/RefundV2',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Customer Leaves',
         url: '/CustomerLeaves',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Temporary Replacement',
         url: '/TemporaryReplacement',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Extension',
         url: '/Extension',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Cook Leaves',
         url: '/CookLeaves',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Transactions',
         url: '/Transactions',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Coupons V2',
         url: '/CouponsV2',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Trials',
         url: '/Trials',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER, UserGroupsEnum.PROMOTOR],
     },
     {
         title: 'One Meal Service',
         url: '/OneMealService',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER, UserGroupsEnum.PROMOTOR],
     },
     {
         title: 'Trials Converted',
         url: '/TrialsConverted',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Price Calculator',
         url: '/PriceCalculator',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Permanent Replacement',
         url: '/PermanentReplacement',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Cook Slots',
         url: '/CookSlots',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'SMS',
         url: '/SMS',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Vendor Payouts',
         url: '/VendorPayouts',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Hubs',
         url: '/Hubs',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Platform',
         url: '/Platform',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'StarChef Visit',
         url: '/StarChefVisit',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'StarChef Inventory',
         url: '/StarChef-Inventory',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Star Cleaner',
         url: '/StarCleaner',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'StarCleaner Inventory',
         url: '/StarCleaner-Inventory',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Renewals',
         url: '/Renewals',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
     {
         title: 'Weightage Table',
         url: '/WeightageTable',
         permission: [[UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],]
     },
     {
         title: 'Mg Allocation',
         url: '/MgAllocation',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
     },
 ]
  
export const recentMenuOptions = [
    {
         title: 'Cooks',
         url: '/Cooks',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.PROMOTOR],
    },
    {
         title: 'Cooks Serve Calendar',
         url: '/CooksServeCalendar',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER, UserGroupsEnum.PROMOTOR],
    },
    {
         title: 'Chef For Party',
         url: '/ChefForParty',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
    },
    {
         title: 'Trials',
         url: '/Trials',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER, UserGroupsEnum.PROMOTOR],
    },
    {
         title: 'One Meal Service',
         url: '/OneMealService',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER, UserGroupsEnum.PROMOTOR],
    },
    {
         title: 'Vendor Payouts',
         url: '/VendorPayouts',
         permission: [UserGroupsEnum.ADMIN, UserGroupsEnum.EXECUTIVE, UserGroupsEnum.MANAGER],
    },
     
 ]