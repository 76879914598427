import React, {useEffect, useState} from 'react';
import {CookService} from "../../services/CookServices";
import {Button, Box, TablePagination, Dialog,DialogTitle, DialogContent, DialogActions, TextField} from "@mui/material";
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import CookReferralList from "./CookReferralList";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";

interface InputProps {
    cookId: number
}

const cookService = new CookService();

const CookReferralTab = (props: InputProps) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    let [subData, setSubData] = useState<any>(null);
    let [isLoading, setIsLoading] = useState<boolean>();
    let [open, setOpen] = useState<boolean>(false);
    let [name, setName] = useState<string>('');
    let [lastName, setLastName] = useState<string>('');
    let [phone, setPhone] = useState<string>('');

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const refreshData = (cookId: number, limit: number, offset: number) => {
        setIsLoading(true)
        cookService.getCookReferrals(limit, offset, {order: "DESC", field: "id"}, {referralCookId: cookId})
            .then((data) => {
                setSubData(data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            })
    }

    const handleSubmit = () => {
        if(name.trim() === '' || phone.trim() === ''){
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Enter all details to continue",
                type: CustomSnackbarTypes.WARNING,
            });
            return;
        }
        if(phone.length < 10){
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Mobile number must be of 10 digits",
                type: CustomSnackbarTypes.WARNING,
            });
            return;
        }
        cookService.createCookReferral({
            "referralCookId": props?.cookId,
            fname: name,
            lname: lastName,
            "phoneNumber": phone,
        }).then(() => {
            refreshData(props.cookId, rowsPerPage, page*rowsPerPage);
            setOpen(false);
            setName('');
            setPhone('');
            setLastName('');
        });
    };

    // set trial availability type

    useEffect(() => {
        refreshData(props.cookId, rowsPerPage, page*rowsPerPage);
    }, [props.cookId, rowsPerPage, page])

    return (
        <div>
            <Box display={'flex'} flexDirection={'row-reverse'} my={2}>
                <Button size={'small'} variant={'contained'} onClick={() => {setOpen(true)}}>
                    {'Add new referred cook'}
                </Button>
            </Box>
            {
                isLoading ?
                    <CircularLoader/>
                    :
                    <div>
                        {
                            subData &&
                            subData.data &&
                            Array.isArray(subData.data) &&
                            (
                                subData.data.length>0 ?
                                    <CookReferralList data={subData?.data}/>
                                    :
                                    <EmptyListPlaceholder
                                        message='No Cook Referral'
                                    />
                            )
                        }
                        <TablePagination
                            component="div"
                            count={subData?.total || 0}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
            }
            {open && (
                <Dialog open={open} onClose={() => {setOpen(false)}}>
                    <DialogTitle>
                        Add Cook Referral
                    </DialogTitle>
                    <DialogContent>
                        <Box display={'flex'} flexDirection={'column'}>
                            <TextField
                                label={'First Name'}
                                margin={'dense'}
                                value={name}
                                onChange={(e: any) => setName(e.target.value)}
                            />
                            <TextField
                                label={'Last Name'}
                                margin={'dense'}
                                value={lastName}
                                required={false}
                                onChange={(e: any) => setLastName(e.target.value)}
                            />
                            <TextField
                                type={'number'}
                                label={'Mobile Number'}
                                margin={'dense'}
                                value={phone}
                                onChange={(e: any) => setPhone(e.target.value)}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button size={'small'} variant={'outlined'} onClick={() => {setOpen(false)}}>
                            close
                        </Button>
                        <Button size={'small'} variant={'contained'} color={'error'} onClick={()=> {handleSubmit()}}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};

export default CookReferralTab;
