import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { CookService } from "../../services/CookServices";
import {useRefresh } from "react-admin";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import HttpClient from "../../HttpClient";
import {ErrorDialog} from "./ErrorDialog";
import { OneMealService } from "../../services/OneMealService";


const cookService = new CookService();

const oneMealService=new OneMealService();


const AssignCookServeList = (props: any) => {
  const refresh = useRefresh();
  const [mealCookCut, setMealCookCut] = useState<string>("");
  const [isMgCook, setIsMgCook] = useState<boolean>(false);
  const [isConveyanceAllowed, setIsConveyanceAllowed] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorRes, setErrorRes] = useState<any>();

  useEffect(()=>{
    if(props?.cookId){
      oneMealService.checkConveynceEligibility(parseInt(props?.mealServiceId),props?.cookId).then((data)=>{
        setIsConveyanceAllowed(data?.eligibleForConveyance)
      })
    }
  },[props?.cookId,props?.data?.id,props?.mealServiceId])
  useEffect(()=>{
    if(props?.cookId){
        checkMgCook(props?.cookId)
    }
  },[props?.cookId])

  const checkMgCook = (cookId: number) => {
    cookService.getMgCookSalary(cookId).then((cookSalary) => {
      const isMgCook = cookSalary[cookSalary.length - 1]?.endDate === null;
      setIsMgCook(isMgCook);
      setMealCookCut(isMgCook ? "0" : "149");
    });
  };

  const handleSubmit = () => {
    if (
      props?.cookId &&
      Number(mealCookCut) >= 0
    ) {
      let payload = {
        cookId: props?.cookId,
        oneMealServiceCookCut: mealCookCut,
        allocationNotes: '',
        isConveyanceAllowed: isConveyanceAllowed,
      };
      HttpClient.post(`/centaurus/one-meal-service/${parseInt(props?.mealServiceId)}/assign`, payload)
        .then((res) => {
          if (res) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
              message: "Success",
              type: CustomSnackbarTypes.SUCCESS,
            });
          }
          setMealCookCut("0");
          setIsMgCook(false);
          setIsConveyanceAllowed(false);
          props.setAssignCookOpen(false);
          refresh();
        })
        .catch((err) => {
          setErrorRes(err?.response?.data?.data);
          if(err?.response?.data?.data){
            setOpenError(true);
          }
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: err?.response?.data?.data ? `` : err?.response?.data?.error,
            type: CustomSnackbarTypes.ERROR,
            duration:  err?.response?.data?.data ? 0 : 3000,
          });
        })
  } else {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: `Fill the details properly`,
        type: CustomSnackbarTypes.ERROR,
      });
    }
  };

  return (
    <div>
      <Dialog open={props.assignCookOpen} scroll={"paper"} maxWidth="xs" fullWidth>
        <DialogTitle>Assign cook</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {!!props?.cookId && (
              <p>Selected cook - {props?.cookId}</p>
            )}
            {!isMgCook && (
              <TextField
                value={mealCookCut}
                type={"number"}
                label={
                 "One Meal Cook Cut"
                }
                disabled={true}
                style={{
                  marginTop: "12px",
                }}
                onChange={(event) => {
                  setMealCookCut(event.target.value);
                }}
              />
            )}
            <FormGroup>
              <FormControlLabel label='Conveyance Allowed' control={
                <Checkbox checked={isConveyanceAllowed} onClick={(e)=>{
                  EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
                    message: "Are you sure you want to change Conveyance Allowed",
                    onClose: (confirmed: boolean) => {
                      if (confirmed) {
                        setIsConveyanceAllowed(!isConveyanceAllowed);
                      }
                    },
                    title: "Confirmation"
                  });
                }}/>
              }/>
            </FormGroup>
          </div>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              props.setAssignCookOpen(false);
              setMealCookCut("0");
              // setReason(undefined);
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorDialog
          open={openError}
          onClose={() => {setOpenError(false)}}
          data={errorRes}
      />
    </div>
  );
};

export default AssignCookServeList;
