import React from "react";
import {
  ArrayField,
  Datagrid,
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import CustomReferenceField from "../Common/CustomReferenceField";

const RenewalBundleDetailsShow = () => {
  return (
    <Show actions={false}>
      <div style={{ display: "grid", gridTemplateColumns: "30% 40% 30%" }}>
        <SimpleShowLayout>
          <TextField label="Id" source="id" />
          <DateField label="Start Date" source="startDate" />
          <DateField label="Created At" source="createdAt" />
          <DateField label="Updated At" source="updatedAt" />
          <TextField label="End Date" source="endDate" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <CustomReferenceField
            label="User Bundle Id"
            listName={"UserBundles"}
            source={"userBundleMappingId"}
          />
          <TextField label="Bundle Type" source="bundleType" />
          <TextField label="Status" source="status" />
          <TextField label="Price" source="price" />
          <TextField label="Payment Status" source="paymentStatus" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField label="Owner Id" source="ownerId" />
          <TextField label="Owner Name" source="ownerName" />
          <TextField label="Owner Email" source="ownerEmail" />
          <TextField source="closureReason" />
          <TextField label="Closure Sub Reason" source="closureSubReaason" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <ArrayField
            textAlign="center"
            label="Meal Mapping"
            source="mealMapping"
          >
            <Datagrid bulkActionButtons={false} style={{ width: "250px" }}>
              <TextField
                label="Slot"
                source="slot"
                style={{ width: "61px", display: "inlineBlock" }}
              />
              <TextField
                label="Time"
                source="time"
                style={{ width: "61px", display: "inlineBlock" }}
              />
              <TextField
                label="Buffer Minutes"
                source="bufferMinutes"
                style={{ width: "61px", display: "inlineBlock" }}
              />
            </Datagrid>
          </ArrayField>
        </SimpleShowLayout>
      </div>
    </Show>
  );
};

export default RenewalBundleDetailsShow;
