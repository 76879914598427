import React from 'react';
import {Edit, SimpleForm, TextInput, SaveButton, Toolbar } from 'react-admin'

const CustomerEditActions = (props: any) => {
    return (
        <Toolbar {...props}>
            <SaveButton/>
        </Toolbar>
    )
}

const CustomerEdit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm toolbar={<CustomerEditActions/>}>
                <TextInput label="First Name" source="fname"/>
                <TextInput label="Last name" source="lname"/>
                <TextInput label="Middle name" source="mname"/>
                <TextInput label="Address" source="address"/>
                <TextInput label="Email" source="email"/>
            </SimpleForm>
        </Edit>
    );
};

export default CustomerEdit;
