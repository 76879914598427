import moment from "moment";
import React from "react";
import { SimpleShowLayout, TextField, ArrayField, Datagrid, FunctionField, RaRecord } from "react-admin";
import CustomReferenceField from "../Common/CustomReferenceField";

const CustomerFollowUpTab = () => {
  return (
    <SimpleShowLayout>
      <CustomReferenceField
        label="Customer Id"
        listName={"customers"}
        source={"customerId"}
      />
      <TextField label="Customer Name" source="customerName" />
      <TextField label="Customer Mobile" source="customerPhoneNumber" />
      <TextField label="Customer Address" source="house.address" />
      <TextField
        label="Next Customer Follow Up"
        source="nextCustomerFollowUpDate"
      />
      <TextField
        label="No Of Customer Follow Up"
        source="noOfCustomerFollowups"
      />
      <ArrayField
        textAlign="center"
        label="Customer Follow-up"
        source="customerFollowUp"
      >
        <Datagrid bulkActionButtons={false}>
          <TextField
            label="Commenter Id"
            source="commenterId"
            
          />
          <TextField
            label="Commenter Name"
            source="commenterName"
            
          />
          <TextField
            label="Commenter Email"
            source="commenterEmail"
            
          />
          <FunctionField
            label="Created At"
            render={(record?: RaRecord) => {
                if (record?.timestamp) {
                  return moment(record.timestamp).format("LLL");
                }
                else {
                  return "---";
                }
              } 
            }          
            />
          <TextField
            label="Comment"
            source="comment"
          />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  );
};

export default CustomerFollowUpTab;
