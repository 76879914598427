import React from 'react'
import CkMuiDialog from './CkMuiDialog/CkMuiDialog';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { PaymentStatusEnum } from '../../enums/PaymentStatusEnum';
import { OneMealService } from '../../services/OneMealService';
import EventBus from '../../EventBus';
import { EventBusEventsEnum } from '../../enums/EventBusEventsEnum';
import { CustomSnackbarTypes } from './CustomSnackBar';
import { useRecordContext, useRefresh } from 'react-admin';

interface InputProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const oneMealService = new OneMealService();
const UpdateOMSPaymentStatusDialog = (props: InputProps) => {
    const record = useRecordContext();
    const refresh = useRefresh();
    const [selectedStatus, setSelectedStatus] = React.useState<PaymentStatusEnum>(PaymentStatusEnum.NOT_PAID);
    const handleUpdate = () => {
        if (selectedStatus && record?.id) {
            const payload = {
                paymentStatus: selectedStatus,
            }
            oneMealService?.updateOMSPaymentStatus(record?.id as number, payload).then((res) => {
                if (res) {
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: "Updated Successfully",
                        type: CustomSnackbarTypes.SUCCESS,
                    });
                    refresh();
                    props?.setOpen(false);
                }
            })
        }
    }
  return (
    <CkMuiDialog
        fullWidth
        maxWidth='xs'
        isDialogOpen={props?.open}
        setDialogOpen={props?.setOpen}
        onDialogClose={() => {}}
        title={"OMS Payment Status Update"}
        primaryLabel={"Submit"}
        primaryAction={handleUpdate}
        secondaryLabel={"Cancel"}
        secondaryAction={() => {
            props?.setOpen(false);
        }}
        isLoading={false}
    >
      <Box>
        <FormControl fullWidth>
            <InputLabel id="update_status">Update Payment Status</InputLabel>
            <Select
                labelId="for_slot"
                id="Select Service"
                label="Select Service"
                value={selectedStatus}
                onChange={(event) => setSelectedStatus(event?.target?.value as PaymentStatusEnum)}
            >
                {Object.keys(PaymentStatusEnum).map((status: any) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
            </Select>
        </FormControl>
      </Box>
    </CkMuiDialog>
  )
}

export default UpdateOMSPaymentStatusDialog