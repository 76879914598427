import {
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AdminUserService from "../../services/AdminUser";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { useAppSelector } from "../../store/hooks";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";

const adminUserService = new AdminUserService();

const AdminEditAccess = () => {
  const [adminUser, setAdminUser] = useState<any>();
  const [groups, setGroups] = useState<any>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const optionRef = useRef<any>(null)
  const [item,] = useState<any>();
  const location = useLocation();
  const adminId = location.pathname.split("/")[2];
  const userDetails = useAppSelector((state) => state.userDetails.value)

  const handleRemoveOrAdd = (group: any, action: string) => {
    const groupsArray: string[] = (userDetails.adminUser as any)?.adminGroups?.map((e: any) => e.name)
    let removeAccess ;
    if(groupsArray?.includes("Admin"))
    removeAccess = true;
    else if(groupsArray?.includes("Manager") && group.name === "Executive")
    removeAccess = true;
    else if(groupsArray?.includes("Manager") && group.name === "Manager")
    removeAccess = true;
    else
    removeAccess = false;
    if(removeAccess){
      if(action === "Remove"){
        adminUserService
        .removeAdminUserToGroup(Number(adminId), group.id)
        .then((e) => {
          setRefresh(true);
        });
      }
      else{
        adminUserService
        .addAdminUserToGroup(Number(adminId), group.id)
        .then((e) => {
          setRefresh(true)
        });
      }
    }
  };
  useEffect(() => {
    adminUserService
      .getAdminUserById(Number(adminId))
      .then((data) => {
        setAdminUser(data.data);
        setRefresh(false);
      })
      .catch((err) => console.log(err));
    adminUserService
      .getAllGroups()
      .then((data) => {
          setGroups(data)
        })
      .catch((err) => console.log(err));
  }, [refresh,adminId]);
  let remaining : any =  _.differenceBy(groups, adminUser?.adminGroups, "id")
  return (
    <div>
      {adminUser?.adminGroups.length === 0 ? (
        <h2>N0 Group Associated</h2>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adminUser?.adminGroups?.map((item: any) => (
                <TableRow
                  key={item.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{item.name}</TableCell>
                  <TableCell align="center">{item.description}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
                          message: "Are you sure you want to remove this access group?",
                          onClose: (confirmed: boolean) => {
                            if (confirmed) {
                              handleRemoveOrAdd(item, 'Remove')
                            }
                          },
                          title: "Confirmation"
                        });
                      }}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {remaining?.length !== 0 && (
        <div style={{paddingLeft:"20px"}}>
          <h3>Add To Groups</h3>
          <FormControl sx={{ width: "300px" }}>
            <InputLabel id="addGroup">Add</InputLabel>
            <Select labelId="addGroup" id="addGroup" label="Group" value={item}>
              {remaining.map((value: any, index: number) => (
                <MenuItem
                  key={value.id}
                  ref={optionRef}
                  onClick={(e) => {
                    handleRemoveOrAdd(value, 'Add')
                  }}
                  value={value.name}
                >
                  {value.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      <Link
        href={`/#/AdminUsers/${adminUser?.id}/show`}
        sx={{ textDecoration: "none"}}
      >
        <Button variant={"contained"} sx={{margin:"15px 0 0 20px"}}>Show</Button>
      </Link>
    </div>
  );
};

export default AdminEditAccess;
