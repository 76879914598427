import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";
import HttpClient from "../HttpClient";
import {CreateResult, GetListResult, GetOneResult} from "react-admin";
import { StarChefVisitResponse } from "../interfaces/StarChefVisit/StarChefVisitResponse";
import { CategoryResponse } from "../interfaces/StarChefVisit/CategoryResponse";
import Op from "../interfaces/SequalizeFilters/SequalizeTypes";
import moment from "moment";
import { StarChefCreateRequest } from "../interfaces/StarChefVisit/StarChefCreateRequest";
export default class StarChefVisitService {
    private basePath = '/starchef/centaurus';

    processDateRangeFilter(filters: any) {
        let visitDate;
        if (filters.date) {
            visitDate = {
                [Op.eq.toString()]: moment(filters.date).format("YYYY-MM-DD")
            }
            delete filters.date
        }
        return {visitDate};
    }
    
    async getAllStarChefVisit(limit: number, offset: number, filter: any, sort: any): Promise<GetListResult<StarChefVisitResponse>> {
        let filter1 = ConstructSequelizeFilters({...filter}, ['customerName', 'customerPhone'], ['status'],[],[])
        let filter2 = this.processDateRangeFilter(filter);
        let filters = {
            ...filter1,
            ...filter2
        }

        let data = await HttpClient.get(`${this.basePath}`, {
            params: {
                limit,
                offset,
                filters,
                sort
            }
        })
        return {
            data: (!!data && !!data.data) ? data.data.data : [],
            total: (!!data && !!data.data) ? data.data.count : 0
        }
    }

    async getStarChefVisitById(id: number): Promise<GetOneResult<StarChefVisitResponse>> {
        let data = await HttpClient.get(`${this.basePath}/${id}`);
        return data;
    }

    async getStarChefVisitForHouse(houseId: number): Promise<StarChefVisitResponse> {
        let data = await HttpClient.get(`${this.basePath}/house/${houseId}`);
        return data?.data;
    }

    async createStarChefVisit(payload: StarChefCreateRequest): Promise<CreateResult<StarChefVisitResponse>> {
        let houseId = payload.houseId
        let createPayload = {
            visitTime: payload.visitTime,
            visitDate: payload.visitDate,
            totalPeople: payload.totalPeople
        }
        const created = await HttpClient.post(`${this.basePath}/house/${houseId}`, createPayload);
        return {
            data: created?.data
        }
    }

    async assignCookToVisit(id: number, payload: { cookId: number }): Promise<StarChefVisitResponse> {
        let data = await HttpClient.patch(`${this.basePath}/${id}/assign-cook`, payload);
        return data?.data;
    }

    async cancelStarChefVisit(id: number, payload: { cancellationReason: string }): Promise<StarChefVisitResponse> {
        let data = await HttpClient.delete(`${this.basePath}/${id}/cancel-visit`, {data: payload});
        return data?.data;
    }

    async rescheduleStarChefVisit(id: number, payload: any): Promise<StarChefVisitResponse> {
        let data = await HttpClient.patch(`${this.basePath}/${id}/reschedule-visit`, payload);
        return data?.data;
    }

    async addNoteStarChefVisit(id: number, payload: { note: string }): Promise<StarChefVisitResponse> {
        let data = await HttpClient.patch(`${this.basePath}/${id}/add-note`, payload);
        return data?.data;
    }

    async markFulfilled(id: number): Promise<StarChefVisitResponse> {
        let data = await HttpClient.patch(`${this.basePath}/${id}/mark-fulfilled`);
        return data?.data;
    }

    async getAllCategory(): Promise<GetListResult<CategoryResponse>> {
        let data = await HttpClient.get('/cuisine/categories');
        return data?.data;
    }

    async getAllDishesForTypeAndCategory(categoryId: number, mealType: string): Promise<GetListResult<CategoryResponse>> {
        let data = await HttpClient.get(`/cuisine/categories/${categoryId}`, { params : { mealType } });
        return data?.data;
    }

    async editDishesStarChefVisit(id: number, payload: any): Promise<StarChefVisitResponse> {
        let data = await HttpClient.patch(`${this.basePath}/${id}/add-dishes`, payload);
        return data?.data;
    }

    async editStarChefVisit(id: number, payload: {totalPeople: number, extraPrice: string}): Promise<StarChefVisitResponse> {
        let data = await HttpClient.patch(`${this.basePath}/${id}/edit-visit`, payload);
        return data?.data;
    }
}
