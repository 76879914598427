import {
    Box, Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import React from "react";
import FieldViewService from "../../services/FieldViewService";
import CookAdditionalViewResponse from "../../interfaces/FieldView/CookAdditionalViewResponse";
import FieldViewResponse from "../../interfaces/FieldView/FieldViewResponse";
import AddCookAdditionalDetailValue from "./AddCookAdditionalDetailValue";
import {retrieveFromLocalStorage} from "../../utils/utils";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";

type CookAdditionalDetailsProps = {
    cookId: number
}
type DetailParameterListProps = {
    cookId: number
}
const fieldViewService = new FieldViewService();
// const CookAdditionalDetailList = (props: CookAdditionalDetailListProps) => {
//     const [loading, setLoading] = React.useState<boolean>(false);
//
//     return (
//         <Box>
//             {loading ?
//                 (
//                     <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
//                         <CircularProgress />
//                     </Box>
//                 ) :
//                 (
//                     <Box mt={'30px'}>
//                         <Table>
//                             <TableHead>
//                                 <TableRow>
//                                     <TableCell>Id</TableCell>
//                                     <TableCell>Cook Id</TableCell>
//                                     <TableCell>Key</TableCell>
//                                     <TableCell>Value</TableCell>
//                                     <TableCell>Created By Id</TableCell>
//                                     <TableCell>Created At</TableCell>
//                                     <TableCell>Updated At</TableCell>
//                                 </TableRow>
//                             </TableHead>
//                             <TableBody>
//                                 {fieldListData?.map((row: CookAdditionalViewResponse, index: number) => {
//                                     return (
//                                         <TableRow key={row?.id}>
//                                             <TableCell>{row?.id}</TableCell>
//                                             <TableCell>{row?.cookId}</TableCell>
//                                             <TableCell>{row?.key}</TableCell>
//                                             <TableCell>{row?.value}</TableCell>
//                                             <TableCell>{row?.createdById}</TableCell>
//                                             <TableCell>{moment(row?.createdAt).format('DD MMM YYYY hh:mm A')}</TableCell>
//                                             <TableCell>{moment(row?.updatedAt).format('DD MMM YYYY hh:mm A')}</TableCell>
//                                         </TableRow>
//                                     )
//                                 })}
//                             </TableBody>
//                         </Table>
//                     </Box>
//                 )
//             }
//         </Box>
//     )
// }

const DetailParameterList = (props: DetailParameterListProps) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [fieldListData, setFieldListData] = React.useState<FieldViewResponse[]>([]);
    const [label, setLabel] = React.useState<string>('');
    const [inputType, setInputType] = React.useState<string>('');
    const [parameterValues, setParamaterValues] = React.useState<string>('');
    const [openAddValueDialog, setOpenAddValueDialog] = React.useState<boolean>(false);
    const [openUpdateValueDialog, setOpenUpdateValueDialog] = React.useState<boolean>(false);
    const [cookAdditionalDetailsId, setCookAdditionalDetailsId] = React.useState<number>(0);
    const [cookAdditionalData, setCookAdditionalData] = React.useState<CookAdditionalViewResponse[]>([]);
    const [userId, setUserId] = React.useState<number>(0);
    const getAllCookAdditionalDetails = () => {
        setLoading(true);
        const filters = {
            cookId: props.cookId,
        }
        fieldViewService?.getAllCookAdditionalDetails(100, 0, JSON.stringify(filters)).then((res: {total: number, data: CookAdditionalViewResponse[]}) => {
            if(res) {
                setLoading(false);
                setCookAdditionalData(res?.data);
            }
        }).catch((err) => {
            setLoading(false);
        })
    }
    const getAllFieldList = () => {
        setLoading(true);
        fieldViewService?.getAllDetailParameters(100, 0).then((res: {total: number, data: FieldViewResponse[]}) => {
            if(res) {
                setLoading(false);
                console.log(res?.data);
                setFieldListData(res?.data);
            }
        }).catch((err) => {
            setLoading(false);
        })
    }
    const handleAddValueClicked = (key: string, inputType: string, parameterValues: string) => {
        setLabel(key);
        setInputType(inputType);
        setParamaterValues(parameterValues);
        setOpenAddValueDialog(true);
    }
    const handleUpdateValueClicked = (key: string, inputType: string, parameterValues: string) => {
        const cookAdditionalDetailId = cookAdditionalData?.find((item: CookAdditionalViewResponse) => item?.key === key)?.id
        if(cookAdditionalDetailId) {
            setLabel(key);
            setInputType(inputType);
            setParamaterValues(parameterValues);
            setCookAdditionalDetailsId(cookAdditionalDetailId);
            setOpenUpdateValueDialog(true);
        }
        else {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Add cook detail first.",
                type: CustomSnackbarTypes.ERROR,
            });
        }

    }
    const getUserId = () => {
        const authData = retrieveFromLocalStorage('auth');
        if(authData) {
            const parsedAuthData = JSON.parse(authData);
            if(parsedAuthData && parsedAuthData?.data?.user) {
                setUserId(parsedAuthData?.data?.user?.id);
            }
        }
    }
    const handleAddValuesSubmitClicked =(value: string | string[], key: string) => {
        if(value && key) {
            setLoading(true);
            const payload = {
                key: key,
                value: Array.isArray(value) ? JSON.stringify(value) : value,
                cookId: props.cookId,
                createdById: userId
            }
            fieldViewService?.createCookAdditionalDetail(payload).then((res: CookAdditionalViewResponse) => {
                setLoading(false);
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Created Successfully",
                    type: CustomSnackbarTypes.SUCCESS,
                });
                setOpenAddValueDialog(false);
                getAllFieldList();
                getAllCookAdditionalDetails();
            }).catch((err) => {
                setLoading(false);
            })
        }
        else {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Fill all fields",
                type: CustomSnackbarTypes.ERROR,
            });
        }
    }
    const handleUpdateValuesSubmitClicked =(value: string | string[], key: string) => {
        if(value && key && cookAdditionalDetailsId) {
            setLoading(true);
            const payload = {
                value: Array.isArray(value) ? JSON.stringify(value) : value,
            }
            fieldViewService?.updateCookAdditionalDetail(cookAdditionalDetailsId, payload).then((res: CookAdditionalViewResponse) => {
                setLoading(false);
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Updated Successfully",
                    type: CustomSnackbarTypes.SUCCESS,
                });
                setOpenUpdateValueDialog(false);
                getAllFieldList();
                getAllCookAdditionalDetails();
            }).catch((err) => {
                setLoading(false);
            })
        }
        else {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Fill all fields",
                type: CustomSnackbarTypes.ERROR,
            });
        }
    }
    React.useEffect(() => {
        getAllFieldList();
        getAllCookAdditionalDetails();
        getUserId();
        // eslint-disable-next-line
    }, [])
    return (
        <Box>
            {loading ?
                (
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
                        <CircularProgress />
                    </Box>
                ) :
                (
                    <Box mt={'30px'}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Label</TableCell>
                                    <TableCell>Value</TableCell>
                                    {/*<TableCell>Active</TableCell>*/}
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fieldListData?.filter((each: FieldViewResponse) => each?.isActive)?.map((field: FieldViewResponse, index: number) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{field?.id}</TableCell>
                                            <TableCell>{field?.label}</TableCell>
                                            <TableCell>
                                                {cookAdditionalData?.some((item: CookAdditionalViewResponse) => item?.key === field?.label) ?
                                                    // Key exists
                                                    (() => {
                                                        const item = cookAdditionalData.find((item: CookAdditionalViewResponse) => field?.label === item?.key);
                                                        // Value exists
                                                        if (item && item.value) {
                                                            return <span>{item.value}</span>;
                                                        }
                                                        // Value does not exist
                                                        else {
                                                            return <span>null</span>;
                                                        }
                                                    })() :
                                                    // Key does not exist
                                                    <span>null</span>
                                                }
                                            </TableCell>
                                            {/*<TableCell>{field?.isActive ? "Yes" : "No"}</TableCell>*/}
                                            <TableCell>
                                                {cookAdditionalData?.some((item: CookAdditionalViewResponse) => item?.key === field?.label) ?
                                                    // Key exists
                                                    (!cookAdditionalData?.find((item: CookAdditionalViewResponse) => field?.label === item?.key)?.value &&
                                                        // Value does not exists
                                                        (
                                                            <Button
                                                                variant={'contained'}
                                                                color={'primary'}
                                                                onClick={() => {
                                                                    handleAddValueClicked(field?.label, field?.inputType, field?.values)
                                                                }}
                                                            >
                                                                Add Value
                                                            </Button>
                                                        )
                                                    ) :
                                                    // Key does not exists
                                                    (
                                                        <Button
                                                            variant={'contained'}
                                                            color={'primary'}
                                                            onClick={() => {
                                                                handleAddValueClicked(field?.label, field?.inputType, field?.values)
                                                            }}
                                                        >
                                                            Add Value
                                                        </Button>
                                                    )
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {cookAdditionalData?.some((item: CookAdditionalViewResponse) => item?.key === field?.label) &&
                                                    // Key exists
                                                    (cookAdditionalData?.find((item: CookAdditionalViewResponse) => field?.label === item?.key)?.value &&
                                                        // Value exists
                                                        (
                                                            <Button
                                                                variant={'contained'}
                                                                color={'success'}
                                                                onClick={() => {
                                                                    handleUpdateValueClicked(field?.label, field?.inputType, field?.values)
                                                                }}
                                                            >
                                                                Update Value
                                                            </Button>
                                                        )
                                                    )
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                )
            }
            {/*Add Value Dialog Here */}
            {openAddValueDialog && (
                <AddCookAdditionalDetailValue
                    open={openAddValueDialog}
                    onClose={() => setOpenAddValueDialog(false)}
                    label={label}
                    inputType={inputType}
                    parameterValues={parameterValues}
                    submitClicked={handleAddValuesSubmitClicked}
                />
            )}
            {openUpdateValueDialog && (
                <AddCookAdditionalDetailValue
                    open={openUpdateValueDialog}
                    onClose={() => setOpenUpdateValueDialog(false)}
                    label={label}
                    inputType={inputType}
                    parameterValues={parameterValues}
                    submitClicked={handleUpdateValuesSubmitClicked}
                />
            )}
        </Box>
    )
}

const CookAdditionalDetails = (props: CookAdditionalDetailsProps) => {
    return (
        <Box width={'100%'}>
            <DetailParameterList  cookId={props?.cookId}/>
        </Box>
    )
}
export default CookAdditionalDetails;

