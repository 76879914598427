import { Close } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Portal,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import * as React from "react";
import { ResolutionEnum } from "../../enums/ResolutionEnum";
import UserBundleService from "../../services/BundleService";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { useState } from "react";
import { useShowContext } from "react-admin";
import { UserGroupsEnum } from "../../enums/UserGroupsEnum";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";
import { useAppSelector } from "../../store/hooks";
import { CookLeaveRequestedBy } from "../../enums/CookLeavesRequestBy";
import { UserCookLeaveLinkDisputeResolutionAbsenceTypeEnum } from "../../enums/UserCookLeaveLinkDisputeResolutionAbsenceTypeEnum";

interface InputProps {
  data: any;
  open: boolean;
  callback: (data: any) => void;
}

export const CreateCxLeavesDialog = (props: InputProps) => {
  let [resolution, setResolution] = React.useState<any>();
  let [slot, setSlot] = React.useState<any>();
  let [date, setDate] = React.useState<Date | null>(new Date());
  let [snackBarOpen, setSnackBarOpen] = React.useState(false);
  let [absenseType,setAbsenseType]=useState<any>(UserCookLeaveLinkDisputeResolutionAbsenceTypeEnum.COOK_NOT_AVAILABLE);
  const [comment, setComment] = useState<string>();
  const userBundleService = new UserBundleService();
  const { record } = useShowContext();
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(false);
  const userDetails = useAppSelector((state) => state.userDetails.value)
  let groupsArray: string[] = userDetails.adminUser.accessGroups;

  let handleClose = () => {
    props.callback({
      resolution,
      slot,
      date,
    });
    setDate(null);
    setResolution("");
    setSlot("");
  };
  let handleSnackbarClose = () => {
    setSnackBarOpen(false);
  };

  let leaveOptions = Object.values(ResolutionEnum);
  let UserCookLeaveLinkDisputeResolutionAbsenceType=Object.values(UserCookLeaveLinkDisputeResolutionAbsenceTypeEnum)
  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={props.open}
        scroll={"paper"}
        maxWidth="xl"
      >
        <DialogTitle>Create Customer Leaves</DialogTitle>
        <DialogContent>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 2, right: 5 }}
          >
            {" "}
            <Close />{" "}
          </IconButton>
          <Grid
            container
            columns={1}
            rowSpacing={0.9}
            style={{ padding: "12px 12px 12px 12px" }}
          >
            <FormControl fullWidth>
              <FormControl fullWidth style={{ marginTop: "20px" }}>
                <DatePicker
                  label="Date"
                  inputFormat="dd MMM yyyy"
                  disableMaskedInput
                  value={date}
                  minDate={GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER]) ? new Date(props.data?.startDate) : moment().subtract(3, "days").toDate()}
                  maxDate={new Date(props.data?.endDate)}
                  onChange={(value) => setDate(value)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginTop: "20px" }}>
                <InputLabel id="forResolution">Resolution</InputLabel>
                <Select
                  labelId="forResolution"
                  id="Resolution"
                  value={resolution}
                  label="Resolution"
                  onChange={(event) => setResolution(event.target.value)}
                >
                  {leaveOptions.map((e) => {
                    if (e === ResolutionEnum.TR) {
                      return record?.includeFreeTr ||
                        props?.data?.includeFreeTr ? (
                        <MenuItem key={e} value={e}>
                          {e}
                        </MenuItem>
                      ) : null;
                    }
                    return (
                      <MenuItem key={e} value={e}>
                        {e}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth style={{ marginTop: "20px" }}>
                <InputLabel id="forResolution">Absense Type</InputLabel>
                <Select
                  labelId="forResolution"
                  id="Resolution"
                  value={absenseType}
                  label="Resolution"
                  onChange={(event) => setAbsenseType(event.target.value)}
                >
                  {UserCookLeaveLinkDisputeResolutionAbsenceType.map((e) => {
                    return (
                      <MenuItem key={e} value={e}>
                        {e}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {resolution === "TR" && (
                <TextField
                  style={{ marginTop: "20px" }}
                  fullWidth
                  value={comment}
                  label="Comment"
                  multiline={true}
                  onChange={(event) => {
                    setComment(event.target.value);
                  }}
                />
              )}
              {!!props.data && props.data.userBundleMeta && (
                <FormControl fullWidth style={{ marginTop: "20px" }}>
                  <InputLabel id="forSlot">Slot</InputLabel>
                  <Select
                    labelId="forSlot"
                    id="Slot"
                    value={slot}
                    label="Slot"
                    onChange={(event) => setSlot(event.target.value)}
                  >
                    {!!props.data &&
                      !!props.data.userBundleMeta &&
                      props.data?.userBundleMeta?.mealMapping.map((e: any) => (
                        <MenuItem key={e} value={e}>
                          {e}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            disabled={submitButtonDisabled}
            variant="contained"
            onClick={() => {
              if (!resolution || !slot || !date) {
                setSnackBarOpen(true);
                return;
              }
              let payload = {
                slot: slot,
                resolution: resolution,
                comment: comment,
                date: moment(date).format("YYYY-MM-DD"),
                cookLeaveRequestedBy: CookLeaveRequestedBy.CUSTOMER,
                absenseType: absenseType,
              };
              let payload1 = {
                slot: slot,
                resolution: resolution,
                date: moment(date).format("YYYY-MM-DD"),
                absenseType: absenseType,
                cookLeaveRequestedBy: CookLeaveRequestedBy.CUSTOMER,
              };
              setSubmitButtonDisabled(true);
              userBundleService
                .createCxLeaves(
                  props.data.id,
                  resolution === "TR" ? payload : payload1
                )
                .then((res) => {
                  if (res) {
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                      message: res?.data?.resolution
                        ? `Leave resolution is ${res.data.resolution}`
                        : "Success",
                      type: CustomSnackbarTypes.SUCCESS,
                    });
                  }
                  handleClose();
                }).finally(() => {
                  setSubmitButtonDisabled(false);
                });
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              handleClose();
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Portal>
        <Snackbar
          open={snackBarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
        >
          <Alert severity="warning">Fill the details to proceed</Alert>
        </Snackbar>
      </Portal>
    </div>
  );
};
