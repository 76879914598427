import { GetListResult, GetOneResult } from "react-admin";
import HttpClient from "../HttpClient";
import { CouponResponse } from "../interfaces/Coupon/CouponResponse";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";

export class CouponService {
    async getAllCoupons(limit: number, offset: number, filters: {}, sort: {}): Promise<GetListResult<CouponResponse>> {
        filters = ConstructSequelizeFilters(filters, [], ['productType', 'couponType'])
        let data = await HttpClient.get('/centaurus/v3/coupon/all', {
            params: {
                limit,
                offset,
                filters,
                sort
            }
        })
        return {
            data: data.data.data,
            total: data.data.count
        }
    }

    async createCoupon(payload: any): Promise<GetOneResult<CouponResponse>> {
        let data : any  = await HttpClient.post(`/centaurus/v3/coupon`, payload);
        return data
    }

    async getAllCouponForHouse(houseId: number) {
        const data = await HttpClient.get(`/centaurus/v3/coupon/houses/${houseId}`);
        return {
            data: data.data,
            total: data.data.length,
          };
    }

    async sendCoupon(couponId: number) {
        let response = await HttpClient.post(`/centaurus/v3/coupon/${couponId}/send`)
        return response
    }
}
