import React from 'react';
import {
    DateField, Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';

const VasShow = (props: any) => {
    return (
        <Show {...props} actions={false}>
            <SimpleShowLayout>
                <TextField source="id" sortable={false}/>
                <TextField source="name" sortable={false}/>
                <TextField source="description" sortable={false}/>
                <DateField options={{day: 'numeric', month: 'short', year: 'numeric' }} source="createdAt" sortable={false}/>
                <DateField options={{day: 'numeric', month: 'short', year: 'numeric' }} source="updatedAt" sortable={false}/>
            </SimpleShowLayout>
        </Show>
    );
};

export default VasShow;