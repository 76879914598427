const ORG_ID = process.env.REACT_APP_ENV === "production" ? 'org60008027973' : 'org60009269319';

export const ZohoModulesUrl = {
    CUSTOMER: `/${ORG_ID}/tab/Leads`,
    COOKS: `/${ORG_ID}/tab/Vendors`,
    EXTENSION: `/${ORG_ID}/tab/CustomModule32`,
    TR: `/${ORG_ID}/tab/CustomModule4`,
    SUBSCRIPTION: `/${ORG_ID}/tab/CustomModule38`,
    COOK_SUBSCRIPTION: `/${ORG_ID}/tab/CustomModule39`,
    LEAVES: `/${ORG_ID}/tab/CustomModule1`,
    STAR_CLEANER: `/${ORG_ID}/tab/CustomModule20`,
    STAR_CHEF: `/${ORG_ID}/tab/CustomModule26`
}

export const ZohoBaseUrl = process.env.REACT_APP_ENV === "production" ? "https://crmplus.zoho.in/thechefkart/index.do/cxapp/crm" : "https://crmsandbox.zoho.in/crm";