import {DateField, ImageField, Show, SimpleShowLayout, TextField} from 'react-admin';

function CityMetaShow(props: any) {
    return (
        <Show {...props} actions={false}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="coordinates.lat" label={'Latitude'} />
                <TextField source="coordinates.long" label={'Longitude'} />
                <ImageField source="image" title="Image" />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
            </SimpleShowLayout>
        </Show>
    )
}

export default CityMetaShow;
