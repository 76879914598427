import Box from '@mui/material/Box';
import React from 'react'
import ImagePreviewerDialog from './ImagePreviewerDialog';

const ImagePreviewer = ({value, width, height}: {value: any, width?: any, height?: any}) => {
    const [openImagePreviewerDialog, setOpenImagePreviewerDialog] = React.useState<boolean>(false);
    return (
        <Box mr={2}>
            {value ?
                <Box>
                    <img
                        src={`${value}?${new Date().getTime()}`}
                        style={{
                            width: width ? width : "120px",
                            height: height ? height : "204px",
                        }}
                        alt="Cook"
                        onClick={() => setOpenImagePreviewerDialog(true)}
                    />
                    {openImagePreviewerDialog && <ImagePreviewerDialog src={value} open={openImagePreviewerDialog} onClose={() => setOpenImagePreviewerDialog(false)} />}
                </Box>
                :
                <Box>Picture Not available</Box>
            }
      </Box>
    )
}

export default ImagePreviewer