import {Button, Button as MUIButton, Link, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {
  EditButton,
  GetListResult,
  Show,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  TopToolbar,
  useRecordContext,
  useRefresh
} from "react-admin";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {TrialsV3Status} from "../../enums/TrialsV3Status";
import {UserGroupsEnum} from "../../enums/UserGroupsEnum";
import EventBus from "../../EventBus";
import { TrialsService } from "../../services/TrialServices";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";
import AdminUserList from "../AdminUser/AdminUserList";
import ApproveTrialsDialog from "../Common/ApproveCookToTrials";
import AssignCookOnTrialDialog from "../Common/AssignCookOnTrialDialog";
import CreateUserBundleModelFromTrial from "../Common/createUserBundleModelFromTrial";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import NotConvertedReasonDialog from "../Common/NotConvertedReasonDialog";
import RescheduleTrialDialog from "../Common/RescheduleTrialDailog";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import TrialsAdminNotesDialog from "../Common/TrialsAdminNotesDialog";
import TrialsCancelDialog from "../Common/TrialsCancelDialog";
import { TrialsCookMetaShow } from "./TrialsCookMetaShow";
import TrialsMetaShow from "./TrialsMetaShow";
import TrialAllocationTab from "./TrialAllocationTab";
import { useLocation } from "react-router-dom";
import LocalityList from "../Locality/LocalityList";
import PrCustomerAcceptDialog from "../Common/PrCustomerAcceptDailog";
import TrialsVendorPayoutTab from "./TrialsVendorPayout";
import SendTrainingContentDialog from "../Common/SendTrainingContentDialog";
import { TrialResponse } from "../../interfaces/Trials/TrialsResponse";
import TrialsRefundDialog from "../Common/TrialsRefundDialog";
import {PaymentLinkDailog} from "../Common/PaymentLinksDailogs";
import UpdateCommentInTrialDialog from "../Common/UpdateCommentInTrialDialog";
import {PaymentStatusEnum} from "../../enums/PaymentStatusEnum";
import FlagIcon from '@mui/icons-material/Flag';
import CookDenyDialog from "../Common/CookDenyDialog";
import { moduleType } from "../../enums/common";
import TrialDeniedCooksList from "./TrialDeniedCooksList";
import FindCookTabView from "./FindCookTabView";
import {AllocationType} from "../../enums/AllocationType";
import TrialAutoAllocationTab from "./TrialAutoAllocationTab";
import {TrialsCookAuditLogsShow} from "./TrialsCookAuditLogsShow";
import {ActionTypeEnum} from "../../enums/ActionTypeEnum";
import TrialShowStages from "./TrialShowStages";
import EditPriceOnTrialDialog from "../Common/EditPriceOnTrialDialog";
import TrialCancellationOptionsDialog from "./TrialCancellationOptionsDialog";
import UserBundleService from "../../services/BundleService";
import {SubscriptionResponse} from "../../interfaces/UserBundleMapping/SubscriptionForBundle";
import {UserBundlesResponse} from "../../interfaces/UserBundleMapping/UserBundlesResponse";
import {SlotsEnum} from "../../enums/SlotsEnum";
// import CreateTrForPrDialog from "../Common/CreateTrForPrDialog";
import PRSelectDateSlotDialog from "../PermanentReplacement/PRSelectDateSlotDialog";
import PermanentReplacementService from "../../services/PermanentReplacementService";
import {CookSubscriptionResponse} from "../../interfaces/UserBundleMapping/CookSubscriptionForBundle";
import ConstructSequelizeFilters from "../../utils/ConstructSequelizeFilters";
import PRTrialCreateSuccessDialog from "../PermanentReplacement/PRTrialCreateSuccessDialog";
import TrialGenderChangeDialog from "../Common/TrialGenderChangeDialog";
import CustomerCallRecordingsTab from "../Customers/CustomerCallRecordingsTab";
import { CallMaskingReferenceModelNameEnum } from "../../enums/CallMaskingReferenceModelNameEnum";
import CookServeList from "../Common/CookServeList";

const trialsService = new TrialsService();
const userBundleMappingService = new UserBundleService();
const permanentReplacementService = new PermanentReplacementService();

const TrialsTabs = () => {
  let location = useLocation();
  let trialId = location.pathname.split('/')[2]
  const userDetails = useAppSelector((state) => state.userDetails.value)
  const promotorFlag = (userDetails.adminUser.accessGroups as any)?.includes(UserGroupsEnum.PROMOTOR)
  const record = useRecordContext();
  console.log(record);
  

  return (
    <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" selectionFollowsFocus={true} />}>
      <Tab label={"Details"} key={"Details"}>
        <TrialsMetaShow />
      </Tab>
      {!promotorFlag && [
        <Tab label={"Cook Details"} key={"Cook Details"}>
          <TrialsCookMetaShow />
        </Tab>,
        <Tab label={"Cook Serve List"} key={"Cook Serve List"}>
         <CookServeList serviceId={2} referenceId={record?.areaId} time={record?.time} totalPeople={record?.totalPeople} date={record?.date} hasService={true} mealServiceId={trialId} houseId={record?.houseId} type={"TRIAL"}/>
        </Tab>,
        <Tab label={"Manual Allocation Tab"} key={"Manual Allocation Tab"}>
          <TrialAllocationTab trialId={Number(trialId)} />
        </Tab>,
        <Tab label={"Auto Allocation Tab"} key={"Auto Allocation Tab"}>
          <TrialAutoAllocationTab trialId={Number(trialId)} />
        </Tab>,
        <Tab label={"Auto Allocation Stages"} key={"Auto Allocation Tab"}>
          <TrialShowStages trialId={Number(trialId)} />
        </Tab>,
        <Tab label={"Audit Logs Tab"} key={"Audit Logs Tab"}>
          <TrialsCookAuditLogsShow />
        </Tab>,
        <Tab label={"Vendor Payout Tab"} key={"Vendor Payout Tab"}>
          <TrialsVendorPayoutTab trialsId={Number(trialId)} />
        </Tab>,
      ]}
      <Tab label={"Denied Cooks"} key={"Denied Cooks"}>
        <TrialDeniedCooksList trialId={Number(trialId)} />
      </Tab>
      {
        record?.findCookStatus &&
        <Tab
          label={"Find Cooks"}
          key={"Find Cooks"}
        >
          <FindCookTabView trialId={Number(trialId)} />
        </Tab>
      }
      <Tab label={"Call Recordings"} key={"Call Recordings"}>
        <CustomerCallRecordingsTab serviceId={Number(record?.id)} referenceModalName={CallMaskingReferenceModelNameEnum.TRIAL}/>
      </Tab>
    </TabbedShowLayout>
  );
};

const CustomEditButton = () => {
  const record = useRecordContext();
return(
  <TopToolbar>
      { !record?.prId  && <EditButton />}
  </TopToolbar>
);
}

export const ActionButtons = (props: any) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const userDetails = useAppSelector((state) => state.userDetails.value);
  let groupsArray: string[] = userDetails.adminUser.accessGroups;
  const dispatch = useAppDispatch();

  const WhatsappMsgHandler = () => {
    try{
      let message = `कस्टमर का नाम : ${record?.customerName}%0aफोन : ${record?.customerPhoneNumber}%0aपता: ${record?.house?.address}%0aट्रायल टाईम: ${record?.time}%0aसैलरी: ${record?.cookCut}`
      window.open(`https://api.whatsapp.com/send?phone=+91${record?.cook?.mobile}&text=${message}`)
    }catch(err){
      console.log(err)
    }
  }

  const handleFixSlcOpen = () => {
    dispatch(setCustomSelectOpen({
      open: true
  }));
  }

  const onClickSendPaymentLink = () => {
    EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
      message: "Are you sure you want to send the payment link?",
      onClose: (confirmed: boolean) => {
        if (confirmed) {
          trialsService.sendTrailPaymentLink(parseInt((record as any).id)).then((res) => {
            props.setPaymentDialog(true)
            props.setPaymentLinks(res)
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
              message: 'Payment link sent',
              type: CustomSnackbarTypes.SUCCESS
            })
          })
        }
      },
      title: "Confirmation"
    })
  };

  return (
    <div>
    <Stack direction={"row"} style={{marginBottom: "10px"}} gap={2}>
      {[TrialsV3Status.PENDING, TrialsV3Status.COOK_ALLOCATED, TrialsV3Status.COOK_CONFIRMED].includes(record?.status) && record?.paymentStatus === PaymentStatusEnum.PAID && (
        <CustomButton
          name={record?.cookId ? "Change Cook" : "Assign Cook"}
          color={'primary'}
          callback={(data) => {
              props.setSelectedData(data);
              props.setOpenAssignCookToTrialDialog(true);
          }}
        />
      )}
      {[
        TrialsV3Status.PENDING,
        TrialsV3Status.COOK_ALLOCATED,
        TrialsV3Status.COOK_CONFIRMED,
      ].includes(record?.status) && (
        <CustomButton
          name={"Reschedule Trial"}
          color="primary"
          callback={(data: any) => {
            props.setSelectedData(data);
            props.setRescheduleModel(true);
          }}
        />
      )}
      {[TrialsV3Status.COOK_ALLOCATED].includes(record?.status) && (
        <CustomButton
          name={"Action By Cook"}
          color="success"
          callback={(data: any) => {
            props.setSelectedData(data);
            props.setApprovalModel(true);
            props.setInitiatedBy(1)
          }}
        />
      )}
      {[TrialsV3Status.COOK_CONFIRMED, TrialsV3Status.ONGOING].includes(record?.status) && (
        <CustomButton
          name={"Mark as Fulfilled"}
          color="primary"
          callback={() => {
            EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
              message: "Are you sure you want to mark the trial fulfilled?",
              onClose: (confirmed: boolean) => {
                if (confirmed) {
                  trialsService
                    .markFulfilled(parseInt((record as any).id))
                    .then((res) => {
                      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: "Trial marked fulfilled",
                        type: CustomSnackbarTypes.SUCCESS,
                      });
                      refresh();
                    });
                }
              },
              title: "Confirmation",
            });
          }}
        />
      )}
        {
            [TrialsV3Status.FULFILLED].includes(record?.status) &&
            !record?.userBundleMappingId &&
            record?.cookAcknowledged &&
            !record?.prId && (
                <CustomButton
                    name={"Create User Bundle"}
                    color="primary"
                    callback={(data: any) => {
                        props.setSelectedData(data);
                        props.setCreateUserBundleModel(true);
                    }}
                />
            )
        }
      {[TrialsV3Status.FULFILLED].includes(record?.status) &&
       (record.cookAcknowledged !== null) && (
        <CustomButton
          name={"Not Converted"}
          color="error"
          callback={(data: any) => {
            props.setSelectedData(data);
            props.setNotConvertedModel(true);
          }}
        />
      )}
      {[TrialsV3Status.FULFILLED].includes(record?.status) &&
        record.cookAcknowledged === null && (
          <>
            <CustomButton
              name={"Cook Accepted"}
              color="success"
              callback={(data: any) => {
                EventBus.emitEvent(
                  EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG,
                  {
                    message: "Are you sure cook accepted to work?",
                    onClose: (confirmed: boolean) => {
                      if (confirmed) {
                        trialsService
                          .cookAcknowledge(parseInt((record as any).id), {accepted: true})
                          .then((res) => {
                            EventBus.emitEvent(
                              EventBusEventsEnum.SNACKBAR_SHOW,
                              {
                                message: "Success",
                                type: CustomSnackbarTypes.SUCCESS,
                              }
                            );
                            refresh();
                          });
                      }
                    },
                    title: "Confirmation",
                  }
                );
              }}
            />
            <CustomButton
              name={"Cook Rejected"}
              color="error"
              callback={(data: any) => {
                props.setSelectedData(data);
                props.setOpenCancelReasonDialog(true);
              }}
            />
          </>
        )}
      <CustomButton
          name={"Change Gender"}
          color={'primary'}
          callback={(data) => {
            props.setSelectedData(data);
            props.setOpenChangeGender(true);
          }}
      />
        {[TrialsV3Status.FULFILLED].includes(record?.status) &&
            record.prId &&
            record.cookAcknowledged && (
                <>
                  <CustomButton
                    name={"Customer Accept"}
                    color="success"
                    callback={(data: any) => {
                      props.setSelectedData(data);
                      props.setOpenCustomerAccept(true);
                    }}
                  />
                  <CustomButton
                      name={"Customer Rejected"}
                      color="error"
                      callback={(data: any) => {
                        EventBus.emitEvent(
                            EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG,
                            {
                              message: "Are you sure customer rejected?",
                              onClose: (confirmed: boolean) => {
                                if (confirmed) {
                                  trialsService
                                      .customerRejected(parseInt((record as any).id))
                                      .then((res) => {
                                        EventBus.emitEvent(
                                            EventBusEventsEnum.SNACKBAR_SHOW,
                                            {
                                              message: "Success",
                                              type: CustomSnackbarTypes.SUCCESS,
                                            }
                                        );
                                        refresh();
                                      });
                                }
                              },
                              title: "Confirmation",
                            }
                        );
                      }}
                  />
                </>
            )
        }
        <CustomButton
            name={"Add Admin Notes"}
            color="primary"
            callback={(data: any) => {
                props.setSelectedData(data);
                props.setOpenSetAdminNotesDialog(true);
            }}
        />
            <CustomButton
                name={"Update Trial Owner"}
                color="primary"
                callback={(data: any) => {
                    props.setOpenUpdateTrialOwner(true)
                    props.getData(record);
                    props.setIsForTrialOwner(true);
                    props.handleClickOpen();
                }}
            />
        {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER]) && (
            <CustomButton
                name={"Update Trial Allocator"}
                color="primary"
                callback={(data: any) => {
                    props.setOpenUpdateTrialAllocator(true);
                    props.getData(record);
                    props.setIsForTrialOwner(false);
                    props.handleClickOpen();
                }}
            />
        )}
        {record?.cookId !== null && (
          <Button
          size={'small'}
          variant={'contained'}
          color={'success'}
          onClick={() => {
            WhatsappMsgHandler()
          }}
      >
          <span style={{width: 'max-content'}}>
              Send WhatsApp
          </span>
      </Button>
        )}
         {
        <CustomButton
        name={"Send Training Content"}
        color="success"
        callback={(data: TrialResponse) => {
            props.setSelectedData(data);
            props.setSendTrainingContentDialog(true);
        }}
    />
      }
      {[TrialsV3Status.COOK_CONFIRMED].includes(record?.status) && (
        <CustomButton
          name={"Mark as Ongoing"}
          color="primary"
          callback={(data: any) => {
            trialsService
            .markTrialAsOngoing(parseInt((record as any).id))
            .then((res) => {
              EventBus.emitEvent(
                  EventBusEventsEnum.SNACKBAR_SHOW,
                  {
                    message: "Success",
                    type: CustomSnackbarTypes.SUCCESS,
                  }
              );
              refresh();
            })
          }}
        />
      )}
      { record?.areaId === null &&
        <CustomButton
        name={"Fix SLC"}
        color="primary"
        callback={(data: any) => {
            handleFixSlcOpen();
            props.setOpenFixSLC(true);
        }}
    />
      }
      {record?.paymentStatus === PaymentStatusEnum.PAID && (
          <CustomButton
              name={"Refund Trial Amount"}
              color="error"
              callback={(data: any) => {
                props.setInitiatedBy(1)
                props.setSelectedData(data);
                props.setRefundTrialModel(true);
              }}
          />
      )}
      {[TrialsV3Status.COOK_CONFIRMED].includes(record?.status) && (
          <CustomButton
              name={"Cook Deny"}
              color={'error'}
              callback={(data) => {
                props.setSelectedData(data);
                props.setOpenCookDenyDialog(true);
              }}
          />
      )}
      {[
        TrialsV3Status.PENDING,
        TrialsV3Status.COOK_ALLOCATED,
        TrialsV3Status.COOK_CONFIRMED,
        TrialsV3Status.ONGOING,
      ].includes(record?.status) && (
          <CustomButton
              name={"Cancel Trial"}
              color="error"
              callback={(data: any) => {
                props.setSelectedData(data);
                props.setCancelTrialModel(true);
                props.setInitiatedBy(1)
              }}
          />
      )}
      {![TrialsV3Status.CANCELLED].includes(record?.status) && record?.paymentStatus === PaymentStatusEnum.NOT_PAID && (
          <Button
              color="primary"
              variant={'contained'}
              onClick={onClickSendPaymentLink}
          >
            {"Send Payment Link"}
          </Button>
      )}
    </Stack>
    <Stack direction={"row"} gap={2}>
      <Link href={`#/VisitTracker?visitId=${record?.id}&date=${record?.date}&status=${JSON.stringify([record?.status])}`} target={'_blank'}>
        <CustomButton
            name={"View Tracker"}
            color="primary"
            callback={() => {
            }}
        />
      </Link>
    {[PaymentStatusEnum.PAID, PaymentStatusEnum.PAYMENT_IN_PROGRESS, PaymentStatusEnum.REFUND_IN_PROGRESS, PaymentStatusEnum.REFUNDED, PaymentStatusEnum.REFUND_FAILED].includes(record?.paymentStatus) &&
      <CustomButton
        name={"Support Comments"}
        color="primary"
        callback={() => {
          props.setUpdateCommentDialog(true)
          props.setInitiatedBy(1)

        }}
    />
      }
       {[PaymentStatusEnum.PAID, PaymentStatusEnum.PAYMENT_IN_PROGRESS, PaymentStatusEnum.REFUND_IN_PROGRESS, PaymentStatusEnum.REFUNDED, PaymentStatusEnum.REFUND_FAILED].includes(record?.paymentStatus) &&
        <CustomButton
        name={"CX Feedback"}
        color="primary"
        callback={() => {
          props.setUpdateCommentDialog(true)
          props.setInitiatedBy(2)
        }}
    />
      }
    {[PaymentStatusEnum.PAID, PaymentStatusEnum.PAYMENT_IN_PROGRESS, PaymentStatusEnum.REFUND_IN_PROGRESS, PaymentStatusEnum.REFUNDED, PaymentStatusEnum.REFUND_FAILED].includes(record?.paymentStatus) &&
      <CustomButton
        name={"Chef Feedback"}
        color="primary"
        callback={() => {
          props.setUpdateCommentDialog(true)
          props.setInitiatedBy(3)
        }}
    />
      }
       {[PaymentStatusEnum.PAID, PaymentStatusEnum.PAYMENT_IN_PROGRESS, PaymentStatusEnum.REFUND_IN_PROGRESS, PaymentStatusEnum.REFUNDED, PaymentStatusEnum.REFUND_FAILED].includes(record?.paymentStatus) &&
        <CustomButton
        name={"Allocation Comments"}
        color="primary"
        callback={() => {
          props.setUpdateCommentDialog(true)
          props.setInitiatedBy(4)
        }}
    />
      }
      {[TrialsV3Status.PENDING].includes(record?.status) &&
        !record?.findCookStatus &&
        (
        <CustomButton
          name={"Find Cook"}
          color="success"
          callback={(data: any) => {
            trialsService
            .startFindCookProcess(parseInt((record as any).id))
            .then((res) => {
              EventBus.emitEvent(
                  EventBusEventsEnum.SNACKBAR_SHOW,
                  {
                    message: "Success",
                    type: CustomSnackbarTypes.SUCCESS,
                  }
              );
              refresh();
            })
          }}
        />
      )}
      {
        <CustomButton
            name={"Reset Cook Acknowledgement"}
            color="primary"
            callback={(data: any) => {
              trialsService.resetCookAcknolwedgement(parseInt((record as any).id))
              .then((res) => {
                EventBus.emitEvent(
                  EventBusEventsEnum.SNACKBAR_SHOW,
                  {
                    message: "Success",
                    type: CustomSnackbarTypes.SUCCESS,
                  }
                );
                refresh();
              })
            }}
        />
      }
      {
        (record?.status!==TrialsV3Status.CANCELLED&&record?.status!==TrialsV3Status.FULFILLED&&!record?.preCheckedInAt)&&<CustomButton
        name={"Pre Checkin"}
        color="primary"
        callback={() => {
          EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
            message: "Confirm Pre Checkin",
            onClose: (confirmed: boolean) => {
              trialsService.preCheckIn(Number(record?.id),Number(record?.cookId)).then((data)=>{
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Pre Checkin Successful",
                  type: CustomSnackbarTypes.SUCCESS,
                });
                refresh();
              }).catch(error=>{
                console.log(error); 
              })
                
            },
        });
          
        }}
      />
      }
      {record?.allocationType === AllocationType.AUTO && [
        TrialsV3Status.PENDING,
        TrialsV3Status.COOK_ALLOCATED,
        TrialsV3Status.COOK_CONFIRMED,
      ].includes(record?.status) && (
          <CustomButton
              name={record?.allocationType === AllocationType.AUTO ? "Switch to Manual Allocation" : "Switch to Auto Allocation"}
              color="primary"
              callback={(data: any) => {
                const SwitchAllocation = () => {
                    trialsService.switchAllocationType(parseInt((record as any).id), {
                      allocationType: record?.allocationType === AllocationType.AUTO ? AllocationType.MANUAL : AllocationType.AUTO,
                    }).then(() => {
                      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: "Successful",
                        type: CustomSnackbarTypes.SUCCESS,
                      });
                      refresh();
                    })
                };
                EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
                  title: "Are you sure?",
                  message:
                      `Please confirm to switch trial allocation from ${record?.allocationType} to ${record?.allocationType === AllocationType.AUTO ? AllocationType.MANUAL : AllocationType.AUTO}`,
                  successLabel: "Confirm",
                  failureLabel: "Cancel",
                  onClose: (isConfirmed: boolean) =>
                      isConfirmed ? SwitchAllocation() : null,
                });
              }}
          />
      )}
      {GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN]) && ([TrialsV3Status.PENDING].includes(record?.status)) && (record?.paymentStatus === PaymentStatusEnum.NOT_PAID) &&(
        <CustomButton
          name={"Edit Price"}
          color="success"
          callback={(data: any) => {
            props.setSelectedData(data);
            props.setOpenEditPriceDialog(true);
          }}
        />
      )}
       {record?.prId !== null && record?.userBundleMappingDetails?.subscriptions?.some((e: any) => e.status === "ACTIVE" && e.type === "GRACE") && (
        <>
     <FlagIcon style={{color: "red", fontSize: 35}} />
     <span style={{color: "red",}} >Grace Period Active</span>
     </>
       )
     }
    </Stack>
    </div>
  );
};

interface InputProps {
  name: any;
  callback: (data: any) => void;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
}

const CustomButton = (props: InputProps) => {
  let record = useRecordContext();
  return (
    <MUIButton
      onClick={() => {
        props.callback(record);
      }}
      size={"small"}
      color={props.color || "secondary"}
      variant={"contained"}
      style={{ width: "max-content", fontSize: "12px" }}
    >
      {props.name}
    </MUIButton>
  );
};

const TrialsShow = (props: any) => {
  const refresh = useRefresh();
  const dispatch = useAppDispatch();

  let [selectedData, setSelectedData] = useState<any>(null);
  let [cancelTrialModel, setCancelTrialModel] = useState<boolean>(false);
  let [refundTrialModel, setRefundTrialModel] = useState<boolean>(false);
  let [notConvertedModel, setNotConvertedModel] = useState<boolean>(false);
  let [rescheduleModel, setRescheduleModel] = useState<boolean>(false);
  let [approvalModel, setApprovalModel] = useState<boolean>(false);
  let [isForTrialOwner, setIsForTrialOwner] = useState<boolean>(false);
  let [createUserBundleModel, setCreateUserBundleModel] = useState<boolean>(false);
  const [openCancelReasonDialog, setOpenCancelReasonDialog] = useState(false);
  const [openSetAdminNotesDialog, setOpenSetAdminNotesDialog] = useState(false);
  const [openAssignCookToTrialDialog, setOpenAssignCookToTrialDialog] = useState(false);
  const [openCustomerAccept, setOpenCustomerAccept] = useState(false);
  const [openFixSLC, setOpenFixSLC] = useState(false);
  const [openUpdateTrialAllocator, setOpenUpdateTrialAllocator] =  useState(false);
  const [openUpdateTrialOwner, setOpenUpdateTrialOwner] =  useState(false);
  const [sendTrainingContentDialog, setSendTrainingContentDialog] =  useState(false);
  const [updateCommentDialog, setUpdateCommentDialog] = useState(false);
  const [openCookDenyDialog, setOpenCookDenyDialog] = useState<boolean>(false);
  const [openEditPriceDialog, setOpenEditPriceDialog] = useState<boolean>(false);
  const [openChangeGender, setOpenChangeGender] = useState<boolean>(false);
  const [initiatedBy, setInitiatedBy] = useState<any>();
  const [trialCancellationReason, setTrialCancellationReason] = useState<string>('');
  const [trialCancellationSubReason, setTrialCancellationSubReason] = useState<string>('');
  const [openTrialCancellationOptionsDialog, setOpenTrialCancellationOptionsDialog] = useState<boolean>(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionResponse>();
  const [gracePeriodDetails,setGracePeriodDetails]=useState<any>({});
  const [allSubscriptionDetails, setAllSubscriptionDetails] = useState<SubscriptionResponse[]>();
  const [refundDetail, setRefundDetail] = useState<any>();
  const [createTrialDialog, setCreateTrialDialog] = useState<boolean>(false);
  const [cookSubscriptionDetails, setCookSubscriptionDetails] = useState<CookSubscriptionResponse>();
  const [prDetails, setPrDetails] = useState<any>(null);
  const [openPrTrialCreatedSuccessDialog, setOpenPrTrialCreatedSuccessDialog] = useState<boolean>(false);

  let location = useLocation();
  let trialId = location.pathname.split('/')[2]
  const userDetails = useAppSelector((state) => state.userDetails.value)
  const promotorFlag = (userDetails.adminUser.accessGroups as any).includes(UserGroupsEnum.PROMOTOR)

  const [trialsId, setTrialsId] = useState<any>();

  let [paymentDialog, setPaymentDialog] = useState<boolean>(false)
  let [paymentLinks, setPaymentLinks] = useState();
  const handleSelectCookOrAdminUser = () => {
    dispatch(setCustomSelectOpen({
      open: true
    }));
  }

  const getData = (data: any) => {
    setTrialsId(data);
  };

  const handleClickOpen = () => {
      handleSelectCookOrAdminUser();
  };

  const handleAdminUserAfterSelect = (data: any) => {
    setOpenUpdateTrialOwner(false)
    setOpenUpdateTrialAllocator(false);
    setOpenUpdateTrialOwner(false)
    if (isForTrialOwner) {
      handleSelectAdminUserForTrialOwner(data);
    }
    else if (!isForTrialOwner){
      handleSelectAdminUserForTrialAllocator(data);
    }
    return;
  }

  const handleSelectAdminUserForTrialOwner = (data: any) => {
    if (!data) {
      return;
    }
    trialsService.updateTrialOwner(trialsId.id, { trialOwnerId : data.id}).then(() => {
      refresh();
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: `Trial Owner Updated Successfully`,
        type: CustomSnackbarTypes.SUCCESS
      });
    }).catch(() => {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: `Trial Owner was not updated `,
        type: CustomSnackbarTypes.ERROR
      });
    })
  }

  const handleSelectAdminUserForTrialAllocator = (data: any) => {
    if (!data) {
      return;
    }
    trialsService.updateTrialAllocator(trialsId?.id, { trialAllocatorId : data?.id}).then(() => {
      refresh();
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: `Trial Allocator Updated Successfully`,
        type: CustomSnackbarTypes.SUCCESS
      });
    }).catch(() => {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: `Trial Allocator Was not Updated`,
        type: CustomSnackbarTypes.ERROR
      });
    })
  }

  const handleFixSLC = (recordData: any) => {
    setOpenFixSLC(false)
    trialsService.addLocalityToTrial(Number(trialId), {localityId: recordData?.id, cityId: recordData?.cityId, areaId: recordData?.areaId})
    .then((res) => {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "success",
        type: CustomSnackbarTypes.SUCCESS,
      })
      refresh();
    }).catch((err) => {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Error",
        type: CustomSnackbarTypes.ERROR,
      })
    })
  }
  const handleTrialCancelSubmitClicked = (reasonCategory: string, reasonSubCategory: string) => {
    if(reasonCategory && reasonSubCategory) {
      if(selectedData && selectedData?.prId) {
        setTrialCancellationReason(reasonCategory);
        setTrialCancellationSubReason(reasonSubCategory);
        setOpenTrialCancellationOptionsDialog(true);
      } else {
        trialsService.cancelTrials(parseInt(trialId), {
           reason:reasonCategory,
           cancellationSubReason: reasonSubCategory
        }).then((res) => {
          if(res) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
              message: "Trial Cancelled",
              type: CustomSnackbarTypes.SUCCESS
            });
            refresh();
          }
        })
      }
    }
  }
  const getRefundDetails = (userBundleMappingId: number, endDate: string, endSlot: SlotsEnum) => {
    userBundleMappingService?.getRefundDetails(userBundleMappingId, endDate, endSlot).then((res) => {
      if(res && res?.data?.length > 0) {
        setRefundDetail(res?.data[0]);
      }
    })
  }
  const handleTrialCancellationOptionsSubmit = (selectedOption: string) => {
    const payload = {
      "cancelSubscription": selectedOption === "cancelSubscription",
      "wantsOldCookBack": selectedOption === "wantsOldCookBack",
      "cancellationReason": trialCancellationReason,
      "cancellationSubReason": trialCancellationSubReason
    }
    trialsService.cancelTrial(parseInt(trialId), payload).then((res) => {
      if(res) {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Trial Cancelled",
          type: CustomSnackbarTypes.SUCCESS
        });
        refresh();
        setOpenTrialCancellationOptionsDialog(false);
        if(selectedOption === 'bookNewTrial') {
          setCreateTrialDialog(true);
        }
      }
    })
  }

  const handleDateSlotContinueClicked = (selectedDate: string, selectedSlot: string, selectedOption: string) => {
    if(selectedDate && selectedSlot && prDetails) {
      if(allSubscriptionDetails?.some((e: any) => e?.status === "ACTIVE" && e?.type !== "GRACE")) {
        if(!selectedOption) {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Select a visit choice to proceed",
            type: CustomSnackbarTypes.WARNING,
          });
          return;
        }
      }
      permanentReplacementService?.createPRTrial(prDetails?.id, selectedSlot, selectedDate, selectedOption, cookSubscriptionDetails?.id)
          .then((res) => {
            if(res) {
              setCreateTrialDialog(false);
              setOpenPrTrialCreatedSuccessDialog(true);
            }
          }).catch((err) => {
            setCreateTrialDialog(false);
         })
    }
  }
  useEffect(() => {
    if(selectedData?.prId) {
      permanentReplacementService.getPrDetailsById(selectedData?.prId)
          .then((res: any) => {
            setPrDetails(res);
          })
      userBundleMappingService?.fetchAllSubscriptionsForUserBundleMapping(selectedData?.userBundleMappingId, 100, 0, {order: "ASC", field: "startDate"})
          .then((res: GetListResult<SubscriptionResponse>) => {
            if(res?.data?.length > 0) {
              setSubscriptionDetails(res?.data[0]);
              setAllSubscriptionDetails(res?.data);
              setGracePeriodDetails(res?.data[res?.data.length-1]);
            }
          })
      let filters = ConstructSequelizeFilters({userBundleMappingId: selectedData?.userBundleMappingId}, [], [], ['endDate'], []);
      userBundleMappingService?.fetchAllCookSubscriptionsForUserBundleMapping(selectedData?.userBundleMappingId, 100, 0, {order: "DESC", field: "startDate"}, filters)
          .then((res: any) => {
            if(res?.data?.data?.length > 0) {
              setCookSubscriptionDetails(res?.data?.data[0]);
            }
          })
      getRefundDetails(selectedData?.userBundleMappingId, selectedData?.userBundleMappingDetails?.endDate, selectedData?.userBundleMappingDetails?.endSlot as SlotsEnum);
    }
  }, [selectedData])
  return (
    <Show actions={promotorFlag ? <></> : <CustomEditButton/>} title=" ">
    <div>
    {
          openUpdateTrialOwner &&
          <ResourceSelectDialog
            name={'AdminUsers'}
            list={AdminUserList}
            onClose={handleAdminUserAfterSelect}
          />
      }
      {
          openUpdateTrialOwner &&
          <ResourceSelectDialog
            name={'AdminUsers'}
            list={AdminUserList}
            onClose={handleAdminUserAfterSelect}
          />
      }
      {
          openUpdateTrialAllocator &&
          <ResourceSelectDialog
            name={'AdminUsers'}
            list={AdminUserList}
            onClose={handleAdminUserAfterSelect}
          />
      }
      {
      openFixSLC &&
       <ResourceSelectDialog
       name={'Localities'}
       list={LocalityList}
       onClose={handleFixSLC}
     />
     }
     {createUserBundleModel &&
      <CreateUserBundleModelFromTrial
        data={selectedData}
        open={createUserBundleModel}
        callback={() => {
          setCreateUserBundleModel(false);
        }}
      />}
      <TrialsAdminNotesDialog
          data={selectedData}
          open={openSetAdminNotesDialog}
          setOpen={setOpenSetAdminNotesDialog}
      />
      {cancelTrialModel && <TrialsCancelDialog
        intiatedBy={initiatedBy}
        data={selectedData}
        open={cancelTrialModel}
        callback={() => {
          setCancelTrialModel(false);
        }}
        handleTrialSubmit={handleTrialCancelSubmitClicked}
      />}
      {openTrialCancellationOptionsDialog && (
          <TrialCancellationOptionsDialog
              open={openTrialCancellationOptionsDialog}
              onClose={() => setOpenTrialCancellationOptionsDialog(false)}
              handleSubmit={handleTrialCancellationOptionsSubmit}
              bundleDetails={selectedData?.userBundleMappingDetails as UserBundlesResponse}
              subscriptionDetails={subscriptionDetails}
              refundDetail={refundDetail}
          />
      )}
      {createTrialDialog && (
          <PRSelectDateSlotDialog
              open={createTrialDialog}
              onClose={() => setCreateTrialDialog(false)}
              bundleDetails={selectedData?.userBundleMappingDetails as UserBundlesResponse}
              subscriptionDetails={subscriptionDetails}
              cookSubscriptionDetails={cookSubscriptionDetails}
              gracePeriodDetails={gracePeriodDetails}
              permanentReplacementDetails={prDetails}
              handleContinueClicked={handleDateSlotContinueClicked}
              allSubscriptions={allSubscriptionDetails}
          />
      )}
      {openPrTrialCreatedSuccessDialog && (
          <PRTrialCreateSuccessDialog
              open={openPrTrialCreatedSuccessDialog}
              onClose={() => {
                refresh();
                setOpenPrTrialCreatedSuccessDialog(false);
              }}
          />
      )}
      {/*<CreateTrForPrDialog*/}
      {/*    prId={selectedData?.prId}*/}
      {/*    mealMapping={selectedData?.userBundleMappingDetails?.userBundleMeta?.mealMapping}*/}
      {/*    open={createTrialDialog}*/}
      {/*    callback={() => {*/}
      {/*      setCreateTrialDialog(false);*/}
      {/*      refresh();*/}
      {/*    }}*/}
      {/*/>*/}
      {refundTrialModel && <TrialsRefundDialog
        initiatedBy={initiatedBy}
        data={selectedData}
        open={refundTrialModel}
        callback={() => {
          setRefundTrialModel(false);
        }}
      />}
      {openChangeGender && (
        <TrialGenderChangeDialog
          data={selectedData}
          open={openChangeGender}
          callback={() => {
            setOpenChangeGender(false);
            refresh();
          }}
        />
      )}
      <PaymentLinkDailog
          data={paymentLinks}
          open={paymentDialog}
          callback={(data) => {
            setPaymentDialog(false);
          }}
      />
      <PrCustomerAcceptDialog
        data={selectedData}
        open={openCustomerAccept}
        callback={() => {
          setOpenCustomerAccept(false);
        }}
      />
      {openCancelReasonDialog && (
          <NotConvertedReasonDialog
            data={selectedData}
            open={openCancelReasonDialog}
            cookSideCustomerSide={ActionTypeEnum.NOT_CONVERTED_COOK_ISSUES}
            callback={() => {
              setOpenCancelReasonDialog(false);
            }}
          />
      )}
      {notConvertedModel && <NotConvertedReasonDialog
      data={selectedData}
      open={notConvertedModel}
      cookSideCustomerSide={ActionTypeEnum.NOT_CONVERTED_CX_ISSUES}
      callback={() => {
        setNotConvertedModel(false);
      }}/>}
      {rescheduleModel && <RescheduleTrialDialog
        data={selectedData}
        open={rescheduleModel}
        callback={() => {
          setRescheduleModel(false);
        }}
      />}
      <ApproveTrialsDialog
       initiatedBy={initiatedBy}
        data={selectedData}
        open={approvalModel}
        callback={() => {
          setApprovalModel(false);
        }}
      />
      <AssignCookOnTrialDialog
          data={selectedData}
          open={openAssignCookToTrialDialog}
          setOpen={setOpenAssignCookToTrialDialog}
          trialId={trialId}
      />
      {
        openCookDenyDialog &&
        <CookDenyDialog
          open = {openCookDenyDialog}
          setOpen = {setOpenCookDenyDialog}
          data={selectedData}
          requestFrom={moduleType.TRIALS} />
      }
      {sendTrainingContentDialog &&
      <SendTrainingContentDialog
       data={selectedData}
       open={sendTrainingContentDialog}
       setOpen={setSendTrainingContentDialog}
       />}
       <UpdateCommentInTrialDialog
       initiatedBy={initiatedBy}
       open={updateCommentDialog}
       setOpen={setUpdateCommentDialog}
       />
      <EditPriceOnTrialDialog
          data={selectedData}
          open={openEditPriceDialog}
          setOpen={setOpenEditPriceDialog}
      />
      <Show {...props} actions={false}>
        {!promotorFlag && <ActionButtons
          handleClickOpen={handleClickOpen}
          setCancelTrialModel={setCancelTrialModel}
          setRefundTrialModel={setRefundTrialModel}
          setNotConvertedModel={setNotConvertedModel}
          setRescheduleModel={setRescheduleModel}
          setApprovalModel={setApprovalModel}
          setSelectedData={setSelectedData}
          setCreateUserBundleModel={setCreateUserBundleModel}
          getData={getData}
          setIsForTrialOwner={setIsForTrialOwner}
          setOpenCancelReasonDialog={setOpenCancelReasonDialog}
          setOpenSetAdminNotesDialog={setOpenSetAdminNotesDialog}
          setOpenAssignCookToTrialDialog={setOpenAssignCookToTrialDialog}
          setOpenFixSLC={setOpenFixSLC}
          setOpenUpdateTrialAllocator={setOpenUpdateTrialAllocator}
          setOpenUpdateTrialOwner={setOpenUpdateTrialOwner}
          setOpenCustomerAccept={setOpenCustomerAccept}
          setSendTrainingContentDialog={setSendTrainingContentDialog}
          setPaymentDialog={setPaymentDialog}
          setPaymentLinks={setPaymentLinks}
          setUpdateCommentDialog={setUpdateCommentDialog}
          setInitiatedBy={setInitiatedBy}
          setOpenCookDenyDialog={setOpenCookDenyDialog}
          setOpenEditPriceDialog={setOpenEditPriceDialog}
          setOpenChangeGender={setOpenChangeGender}
        />}
        <TrialsTabs/>
      </Show>
    </div>
    </Show>
  );
};

export default TrialsShow;
