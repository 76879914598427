import { CreateResult, GetListResult, GetOneResult } from "react-admin";
import HttpClient from "../HttpClient";
import { VendorCreateRequest } from "../interfaces/Vendor/VendorCreateRequest";
import { VendorResponse } from "../interfaces/Vendor/VendorResponse";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";

export class VendorService {
    async getAllVendors(limit: number, offset: number, filter: any): Promise<GetListResult<VendorResponse>> {
        let filters = ConstructSequelizeFilters(filter, ['name', 'phoneNumber'])
        let data = await HttpClient.get('/centaurus/user/vendor', {
            params: {
                filters,
                limit, 
                offset
            }
        })
        return {
            data: data.data.data,
            total: data.data.count
        }
    }

    async getOneVendor(vendorId: number): Promise<GetOneResult<VendorResponse>> {
        let res = await HttpClient.get(`/centaurus/user/vendor/${vendorId}`)
        return res
    }

    async getManyVendors(ids: number[]): Promise<GetListResult<VendorResponse>>{
        const data = []

        for(const id of ids){
            const res = await HttpClient.get(`/centaurus/user/vendor/${id}`);
            data.push(res.data)
        }
        
        return {
            data: data,
            total: data.length
        }
    }

    async createVendor(payload: VendorCreateRequest): Promise<CreateResult<VendorResponse>> {
        let res = await HttpClient.post(`/centaurus/user/vendor`, payload);
        return res.data;
    }


}