import QuestionInterface from "../../interfaces/Cook/SkillAssessmentInterface";

export const situationalQuestions: QuestionInterface[] = [
  {
    questionText:
      "Kisi baat ke liye customer aap pe gussa karta hai toh aap kya karenge?",
    answerOptions: [
      {
        answerText:
          "Shaant reh kar customer ki baat sunege",
        marks: 5,
      },
      {
        answerText: "Customer se behas karenge",
        marks: 0,
      },
      {
        answerText: "Customer ki complaint CK ki helpline par karenge",
        marks: 0,
      },
      {
        answerText: "Kaam chhod denge",
        marks: 0,
      },
    ],
  },
  {
    questionText:
      "Agar aap customer ke ghar pahuchne me late ho jayein toh aap kya karenge?",
    answerOptions: [
      {
        answerText:
          "Customer ko call karenge aur batayenge ki aap late ho gaye hain",
        marks: 5,
      },
      {
        answerText:
          "Ck ki helpline pe call karenge",
        marks: 0,
      },
      {
        answerText:
          "Bina bataye kaam pe chale jayenge",
        marks: 0,
      },
    ],
  },
  {
    questionText:
      "Khana banate samay khaana jal jaye to aap kya karenge?",
    answerOptions: [
      {
        answerText:
          "Upar se khana paros denge aur jala hua chhod denge",
        marks: 0,
      },
      {
        answerText: "Customer ko turant batake sorry bolenge",
        marks: 5,
      },
      {
        answerText: "Customer ko bolein ki agli baar sahi bana denge",
        marks: 0,
      },
      {
        answerText: "Usi samay wapis banane ke liye customer ko kahenge",
        marks: 5,
      },
      {
        answerText: "Is samasya ke liye Chefkart ko call karenge.",
        marks: 0,
      },
    ],
  },
  {
    questionText:
      "Customer aapko koi aisi dish banane ke liye kahe jo aapko thodi/bilkul nahi aati hai, to aap kya karenge?",
    answerOptions: [
      {
        answerText:
          "Aap unko koi or uske jaisi dish suggest karenge",
        marks: 5,
      },
      { answerText: "Aap customer ko bata denge aapko wo dish banani aati hai ya nahi", marks: 5 },
      {
        answerText:"Aap customer se recipe pooch lenge",
        marks: 5,
      },
      {
        answerText:"Aap apne friend se poochhenge.",
        marks: 0,
      },
      {
        answerText:"Chefkart ke Head chef se pooch lenge.",
        marks: 5,
      },
      {
        answerText:"Aap youtube pe dekh sakte hain",
        marks: 5,
      },
    ],
  },

  {
    questionText:
      "Customer ke ghar mein aapki jankari se zyada logon ka khana banana pade tab aap kya karenge?",
    answerOptions: [
      {
        answerText:
          "Aap customer ko mana kar denge.",
        marks: 0,
      },
      { answerText: "Ek time ke liye kar denge", marks: 5 },
      {
        answerText:
          "ChefKart ko turant phone karenge.",
        marks: 0,
      },
      { answerText: "1 hafte tak aap aisa kar sakte hain, uske baad ChefKart support mein phone karenge.", marks: 5 },
    ],
  },

  {
    questionText:
      "Customer aapko apne kisi personal kaam mein help karne ke liye kahein tab aap kya karenge?",
    answerOptions: [
      {
        answerText:
          "Mana kar denge",
        marks: 0,
      },
      { answerText: "Haan kar denge aur kaam karenge", marks: 0 },
      {
        answerText:
          "Unhe ChefKart se baat karne ke liye kahenge kyunki aapko kaam ke baare mein ChefKart soochit karta hai",
        marks: 5,
      },
      { answerText: "Customer ke baar baar bulane pe ChefKart ko soochit kar denge.", marks: 0 },
    ],
  },

  {
    questionText:
      "Agar koi ChefKart ke baare mein galat bolta hai toh aap kya karenge?",
    answerOptions: [
      {
        answerText:
          "Unse sehmat honge aur ChefKart ka kaam chhod denge",
        marks: 0,
      },
      { answerText: "Un logon ki samasya ChefKart ko batayenge", marks: 5 },
      {
        answerText:
          "Kuch nahin karenge",
        marks: 0,
      },
      { answerText: "Unko bolenge aisa nahin hai, aur unhe Chefkart ke baare me batayenge", marks: 5 },
    ],
  },

  {
    questionText:
      "Customer aapko bartan saaf karne ke liye kahe tab aap kya karenge?",
    answerOptions: [
      {
        answerText:
          "Customer ko turant mana kar denge",
        marks: 0,
      },
      { answerText: "Customer ko batayenge ki Chefkart ne bartan ke liye soochit nahi kiya hai, kripya Chefkart se iske liye baat karein", marks: 5 },
      {
        answerText:
          "Clean kar denge, ChefKart ko nahin batayenge",
        marks: 0,
      },
    ],
  },

  {
    questionText:
      "Customer aapko kaam ka samay change karne ke liye kahe tab aap kya karenge?",
    answerOptions: [
      {
        answerText:
          "Customer ko ChefKart se baat karne ke liye bolenge.",
        marks: 5,
      },
      { answerText: "Main apne hisab se kisi bhi time ja sakta hoon.", marks: 0 },
      {
        answerText:
          "Agar samay hai toh badal sakte hain, lekin ChefKart ko pehle soochit karenge.",
        marks: 5,
      },
    ],
  },

  {
    questionText:
      "Agar customer bole ki khana accha nahi bana hai tab aap kya karenge?",
    answerOptions: [
      {
        answerText:
          "Customer ke saath behes karenge",
        marks: 0,
      },
      { answerText: "Customer ki baat dhyaan se sunenge", marks: 5 },
      {
        answerText:
          "Customer ko doosra chef lene ke liye kahenge",
        marks: 0,
      },
      { answerText: "Khana theek karne ki koshish karenge/second chance ke liye kahenge", marks: 5 },
    ],
  },

  {
    questionText:
      "Agar aap ko kitchen me koi machine chalani nahi aati toh aap kya karenge?",
    answerOptions: [
      {
        answerText:
          "Khud us machine ke baare mein jaanenge aur uska upyog karne ka prayaas karenge",
        marks: 0,
      },
      { answerText: "Customer se poochna accha nahi lagta", marks: 0 },
      {
        answerText:
          "Cutomer se machine use karne ka tarika puchenge",
        marks: 5,
      },
      { answerText: "Uske bina hi khana bana lenge", marks: 0 },
    ],
  },
];
