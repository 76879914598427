enum ChefForPartyReScheduleReasons {
    CUSTOMER_NOT_AVAILABLE_AT_HOME = "Customer not available at home",
    PARTY_IS_POSTPONED_PREPONED = "Party is Postponed/Preponed",
    CUSTOMER_PERSONAL_EMERGENCIES = "Customer Personal emergencies",
    UNFORESEEN_CIRCUMSTANCES = "Unforeseen Circumstances",
    CUSTOMER_HEALTH_ISSUES = "Customer Health Issues",
    OTHERS = "Others"
}

export default ChefForPartyReScheduleReasons;
