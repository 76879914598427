import React, { useState } from "react";
import { Create } from "react-admin";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { CouponType } from "../../enums/CouponType";
import { ProductType } from "../../enums/ProductType";
import { DateTimePicker } from "@mui/x-date-pickers";
import TransactionButton from "../Common/TransactionButton";
import { CouponServiceV2 } from "../../services/CuponServiceV2";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import { useNavigate } from "react-router-dom";
import { default as TextFieldMUI } from "@mui/material/TextField/TextField";

const couponServiceV2 = new CouponServiceV2();

interface item {
  category: string;
  subCategory: string[];
}

const categoryItems: item[] = [
  {
    category: "Sales",
    subCategory: ["Sales"],
  },
  {
    category: "Marketing",
    subCategory: ["Offline", "Online"],
  },
  {
    category: "Retention",
    subCategory: ["Winback", "Renewal", "Previous Bundle Credit"],
  },
];

const CouponCreateV2 = () => {
  const [couponCode, setCouponCode] = useState<string>("");
  const [couponValue, setCouponValue] = useState<string>("");
  const [couponType, setCouponType] = useState<string>("");
  const [productType, setProductType] = useState<string>("");
  const [usageLimitPerCustomer, setUsageLimitPerCustomer] = useState<Number>(0);
  const [maximumUsageLimit, setMaximumUsageLimit] = useState<Number>(0);
  const [minimumOrderValue, setMinimumOrderValue] = useState<string>("");
  const [maximumDiscountAmount, setMaximumDiscountAmount] =
    useState<string>("");
  const [couponHeader, setCouponHeader] = useState<string>("");
  const [couponDescription, setCouponDescription] = useState<string>("");
  const [isActive, setIsActive] = useState<any>(true);
  const [visibleOnApp, setVisibleOnApp] = useState<boolean>(true);
  const [expiryDate, setExpiryDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [reasonSubCategory, setReasonSubCategory] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [userSet, setUserSet] = useState<any>();
  const [areaSet, setAreaSet] = useState<any>();
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [disableAreaSetButton, setDisableAreaSetButton] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleReasonCategoryChange = (event: any) => {
    setSelectedCategory(event.target.value);
  };

  const handleReasonSubCategoryChange = (event: any) => {
    setReasonSubCategory(event.target.value);
  };

  function showSubCategoryOrNot(reasonCategory: string) {
    if (reasonCategory === null) {
      return null;
    }
    let selectedCategoryItem = categoryItems?.filter(
      (e) => e.category === reasonCategory
    )[0];
    return (
      <FormControl style={{ width: "230px" }}>
        <InputLabel id="select-label">Select a reason</InputLabel>
        <Select
          defaultValue="Select a reason"
          value={reasonSubCategory}
          onChange={handleReasonSubCategoryChange}
        >
          {selectedCategoryItem?.subCategory.map((val) => {
            return (
              <MenuItem key={val} value={val}>
                {val}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  const validate = () => {
    const errors: any = {};
    if (Number(couponValue) < 0) {
      errors.couponValue = "Must be greater than 0";
    }
    if (couponType) {
      errors.couponType = "Required";
    }
    if (productType) {
      errors.productType = "Required";
    }
    if(Number(usageLimitPerCustomer) < 0){
      errors.productType = "Must be greater than 0";
    }
    if(Number(maximumUsageLimit) < 0){
      errors.productType = "Must be greater than 0";
    }
    if(Number(usageLimitPerCustomer) > 9007199254740992){
      errors.productType = "Too Much Customers";
    }
    if(Number(maximumUsageLimit) > 9007199254740992){
      errors.productType = "Too Much Usage Limit";
    }
    return errors;
  };

  const handleSubmit = () => {
    if (validate()) {
      let payload = {
        couponCode: couponCode.trim(),
        couponValue: couponValue,
        couponType: couponType,
        productType: productType,
        usageLimitPerCustomer: Number(usageLimitPerCustomer),
        maximumUsageLimit: Number(maximumUsageLimit),
        minimumOrderValue: minimumOrderValue,
        maximumDiscountAmount: maximumDiscountAmount,
        couponHeader: couponHeader,
        couponDescription: couponDescription,
        reason: selectedCategory + " " + reasonSubCategory,
        isActive: isActive === "false" ? false : true,
        visibleOnApp: visibleOnApp.toString() === "false" ? false : true,
        expiresAt: expiryDate,
        startsAt: startDate,
        userSet: userSet ? userSet.trim().split('\n').map((x: any) => Number(x)).filter((x: any) => !isNaN(x)) : userSet,
        areaSet: areaSet ? areaSet.trim().split('\n').map((x: any) => Number(x)).filter((x: any) => !isNaN(x)) : areaSet,
      };
      couponServiceV2.createCouponV2(payload).then((data) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Success",
          type: CustomSnackbarTypes.SUCCESS,
        });
        if (data?.data.id) {
          navigate(`/CouponsV2/${data?.data?.id}/show`, { replace: true });
        }
      });
    }
  };

  return (
    <Create title={"Create Coupon"}>
      <Grid container columns={1} rowSpacing={1} style={{ padding: "12px" }}>
        <Grid item xs={4}>
          <Box
            display={"flex"}
            alignItems={"center"}
            style={{ flexWrap: "wrap" }}
          >
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="Coupon Code"
                value={couponCode}
                disabled={disabled}
                onChange={(event: any) => {
                  setCouponCode(event.target.value);
                }}
              />
            </FormControl>
            <TransactionButton
              name={"Generate From System"}
              onClickHandler={() => {
                setDisabled(true);
              }}
              style={{ marginLeft: "30px" }}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <TextField
              label="Coupon Value"
              value={couponValue}
              type="number"
              onChange={(event: any) => {
                setCouponValue(event.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <InputLabel id="couponType">Coupon Type</InputLabel>
            <Select
              labelId="couponType"
              value={couponType}
              label="Coupon Type"
              onChange={(event: any) => {
                setCouponType(event.target.value);
              }}
            >
              {Object.values(CouponType).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <InputLabel id="productType">Product Type</InputLabel>
            <Select
              labelId="productType"
              value={productType}
              label="Product Type"
              onChange={(event: any) => {
                setProductType(event.target.value);
              }}
            >
              {Object.values(ProductType).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <TextField
              label="Usage Limit Per Customer"
              value={usageLimitPerCustomer}
              type="number"
              onChange={(event: any) => {
                setUsageLimitPerCustomer(event.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <TextField
              label="Maximum Usage Limit"
              value={maximumUsageLimit}
              type="number"
              onChange={(event: any) => {
                setMaximumUsageLimit(event.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <TextField
              label="Minimum Order Value"
              value={minimumOrderValue}
              type="number"
              onChange={(event: any) => {
                setMinimumOrderValue(event.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <TextField
              label="Maximum Discount Amount"
              value={maximumDiscountAmount}
              type="number"
              onChange={(event: any) => {
                setMaximumDiscountAmount(event.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <TextField
              label="Coupon Header"
              value={couponHeader}
              onChange={(event: any) => {
                setCouponHeader(event.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <TextareaAutosize
              placeholder="Coupon Description"
              value={couponDescription}
              minRows={3}
              maxRows={3}
              onChange={(event: any) => {
                setCouponDescription(event.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <InputLabel id="select-label">Reason</InputLabel>
            <Select
              value={selectedCategory}
              onChange={handleReasonCategoryChange}
            >
              {categoryItems.map((item: item) => {
                return (
                  <MenuItem key={item.category} value={item.category}>
                    {item.category}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {selectedCategory && (
          <Grid item xs={4}>
            {showSubCategoryOrNot(selectedCategory as string)}
          </Grid>
        )}
        <Grid item xs={4}>
          <DateTimePicker
            label="Coupon Start Date"
            inputFormat="dd MMM yyyy HH:mm:ss"
            value={startDate}
            disableMaskedInput
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
            renderInput={(params) => {
              return <TextField {...params} />;
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <DateTimePicker
            label="Coupon Expiry Date"
            inputFormat="dd MMM yyyy HH:mm:ss"
            value={expiryDate}
            disableMaskedInput
            onChange={(newValue) => {
              setExpiryDate(newValue);
            }}
            renderInput={(params) => {
              return <TextFieldMUI {...params} />;
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Box
            display={"flex"}
            alignItems={"center"}
            style={{ flexWrap: "wrap" }}
          >
            <FormControl style={{ width: "230px" }}>
              <TextareaAutosize
                placeholder="User Set"
                value={userSet}
                disabled={disableButton}
                minRows={3}
                maxRows={10}
                onChange={(event: any) => {
                  setUserSet(event.target.value);
                }}
              />
            </FormControl>
            <TransactionButton
              name={disableButton ? 'Enable User Set' : "Disable User Set"}
              onClickHandler={() => {
                setDisableButton(!disableButton);
              }}
              style={{ marginLeft: "30px" }}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            display={"flex"}
            alignItems={"center"}
            style={{ flexWrap: "wrap" }}
          >
            <FormControl style={{ width: "230px" }}>
              <TextareaAutosize
                placeholder="Area Set"
                value={areaSet}
                disabled={disableAreaSetButton}
                minRows={3}
                maxRows={10}
                onChange={(event: any) => {
                  setAreaSet(event.target.value);
                }}
              />
            </FormControl>
            <TransactionButton
              name={disableAreaSetButton ? 'Enable Area Set' : "Disable Area Set"}
              onClickHandler={() => {
                setDisableAreaSetButton(!disableAreaSetButton);
              }}
              style={{ marginLeft: "30px" }}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <FormControl>
            <FormLabel id="utensils">Is Active</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(event: any) => {
                setIsActive(event.target.value);
              }}
              value={isActive}
            >
              <FormControlLabel value={true} control={<Radio />} label="True" />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="False"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl>
            <FormLabel id="utensils">Visible On App</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(event: any) => {
                setVisibleOnApp(event.target.value);
              }}
              value={visibleOnApp}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
            size={"large"}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Create>
  );
};

export default CouponCreateV2;
