import { FormControl, Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useState } from "react";
import { Create, SimpleForm } from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { StarChefVisitSlots } from "../../enums/StarChefTimeSlots";
import EventBus from "../../EventBus";
import { useAppDispatch } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import CustomSelectInput from "../Common/CustomSelectInput";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import TransactionButton from "../Common/TransactionButton";
import HouseList from "../Houses/HouseList";

const StarCleanerVisitForm = (props: any) => {
  const dispatch = useAppDispatch();

  const handleOpen = () => {
    dispatch(
      setCustomSelectOpen({
        open: true,
      })
    );
  };

  const handleCloseHouse = (record: any) => {
    props.setHouseId(record?.id);
  };

  const validate = (data: any) => {
    const errors: any = {};
    if (!data.visitTime) {
      errors.visitTime = "Required";
    }
    if (!props.date) {
      errors.visitDate = "Required";
    }
    return errors;
  };

  return (
    <SimpleForm validate={validate}>
      <ResourceSelectDialog
        name={"Houses"}
        list={HouseList}
        onClose={handleCloseHouse}
      />
      <Stack gap={1}>
        <TransactionButton
          name={props.houseId ? `houseId: ${props.houseId}` : "Choose House"}
          onClickHandler={handleOpen}
        />
      </Stack>
      <FormControl style={{ margin: "20px 0", width: "190px" }}>
        <DatePicker
          label="Visit Date"
          inputFormat="dd MMM yyyy"
          disableMaskedInput
          value={props.date}
          minDate={new Date()}
          maxDate={moment().add(30, "d").toDate()}
          onChange={(value) => props.setDate(value)}
          renderInput={(params) => <TextField {...params} />}
        />
      </FormControl>
      <CustomSelectInput
        style={{ width: "190px" }}
        source="visitTime"
        emptyText="select Slots"
        data={StarChefVisitSlots}
      />
    </SimpleForm>
  );
};
const StarCleanerVisitCreate = (props: any) => {
  const [houseId, setHouseId] = useState<number | null>(null);
  const [date, setDate] = useState<Date | null>(null);
  const transform = (data: any) => {
    if (!houseId) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Fill the HouseId to proceed",
        type: CustomSnackbarTypes.WARNING,
      });
      return null;
    }
    let newData = {
      ...data,
      houseId: houseId,
      visitDate: moment(date).format("YYYY-MM-DD"),
    };
    return newData;
  };

  return (
    <Create redirect={"show"} {...props} transform={transform}>
      <StarCleanerVisitForm
        houseId={houseId}
        setHouseId={setHouseId}
        date={date}
        setDate={setDate}
      />
    </Create>
  );
};

export default StarCleanerVisitCreate;
