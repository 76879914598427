enum PayoutTypeEnum {
    TRIAL = 'trial',
    REFERRAL = 'referral',
    TR = 'tr',
    SUBSCRIPTION = 'subscription',
    MISCELLANEOUS = 'miscellaneous',
    INCENTIVE = 'incentive',
    REIMBURSEMENT = 'reimbursement',
    ADVANCE = 'advance',
    LEAVE = 'leave'
}

export default PayoutTypeEnum;