import { Button, Stack } from "@mui/material";
import { Show, Tab, TabbedShowLayout, useRecordContext, useRefresh } from "react-admin";
import { useAppDispatch } from "../../store/hooks";
import { StarChefVisitStatus } from "../../enums/StarChefVisitStatus";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import Cooks from "../Cooks/Cooks";
import { useState } from "react";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import StarChefVisitService from "../../services/StarChefVisitService";
import { useLocation } from "react-router-dom";
import StarChefVisitMetaShow from "./StarChefVisitMetaShow";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import { RescheduleStarChefVisit } from "../Common/RescheduleStarChefVisit";
import { AddNoteStarChefVisit } from "../Common/AddNoteStarChefVisit";
import { CancelStarChefVisit } from "../Common/CancelStarChefVisit";
import { EditDishStarChefVisit } from "../Common/EditDishesStarChef";

const starChefVisitService = new StarChefVisitService()

const CustomButton = (props: any) => {
    let record = useRecordContext();
    return (
      <Button
        onClick={() => {
          props.callback(record);
        }}
        size={"small"}
        color={props.color || "secondary"}
        variant={"contained"}
        style={{ width: "max-content", fontSize: "12px" }}
      >
        {props.name}
      </Button>
    );
  };


export const ActionButtons = (props: any) => {
    const record = useRecordContext();
    const refresh = useRefresh();
  
    return (
      <>
      <Stack direction={"row"} gap={2}>
        {[StarChefVisitStatus.PENDING, StarChefVisitStatus.COOK_ALLOCATED].includes(record?.status) && (
          <CustomButton
            name={record?.status === StarChefVisitStatus.PENDING ? "Assign Cook" : "Change Cook"}
            color={'primary'}
            callback={(data : any) => {
                props.setSelectedData(data);
                props.setAssignCook(true);
                props.handleCookOpenList();
            }}
          />
        )}
        {[
          StarChefVisitStatus.PENDING,
          StarChefVisitStatus.COOK_ALLOCATED,
        ].includes(record?.status) && (
            <>
          <CustomButton
            name={"Cancel"}
            color="error"
            callback={(data: any) => {
              props.setSelectedData(data);
              props.setCancelModel(true);
            }}
          />
          <CustomButton
          name={"Reschedule"}
          color="primary"
          callback={(data: any) => {
            props.setSelectedData(data);
            props.setRescheduleModel(true);
          }}
        />
         <CustomButton
            name={"Add Note"}
            color="primary"
            callback={(data: any) => {
              props.setSelectedData(data);
              props.setNoteModel(true);
            }}
          />
          <CustomButton
            name={"Edit Dishes"}
            color="primary"
            callback={(data: any) => {
              props.setSelectedData(data);
              props.setDishModel(true);
            }}
          />
        </>
        )}
         
        { record?.status === StarChefVisitStatus.COOK_ALLOCATED && (
          <CustomButton
          name={"Mark as Fulfilled"}
          color="primary"
          callback={() => {
            EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
              message: "Are you sure you want to mark this Visit fulfilled?",
              onClose: (confirmed: boolean) => {
                if (confirmed) {
                  starChefVisitService
                    .markFulfilled(parseInt((record as any).id))
                    .then((res) => {
                      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: "StarChefVisit marked fulfilled",
                        type: CustomSnackbarTypes.SUCCESS,
                      });
                      refresh();
                    });
                }
              },
              title: "Confirmation",
            });
          }}
        />
        )}
    
      </Stack>
      </>
    );
  };


  const StarChefVisitShow = (props: any) => {
    const refresh = useRefresh();
    const dispatch = useAppDispatch();
    let [selectedData, setSelectedData] = useState<any>(null);
    const [assignCook, setAssignCook] = useState<boolean>(false);
    const [cancelModel, setCancelModel] = useState<boolean>(false);
    const [rescheduleModel, setRescheduleModel] = useState<boolean>(false);
    const [noteModel, setNoteModel] = useState<boolean>(false);
    const [dishModel, setDishModel] = useState<boolean>(false);
    const location = useLocation();
    const visitId = location.pathname.split('/')[2]

    const handleCookClose = (data: any) => {
        setAssignCook(false);
        starChefVisitService.assignCookToVisit(Number(visitId), { cookId : data.id}).then(() => {
          refresh();
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: `Trial Owner Updated Successfully`,
            type: CustomSnackbarTypes.SUCCESS
          });
        }).catch(() => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: `Trial Owner was not updated `,
            type: CustomSnackbarTypes.ERROR
          });
        })
    }

    const handleCookOpenList = () => {
      dispatch(setCustomSelectOpen({
        open: true
      }));
    }
  return (
    <Show>
      <div>
        {assignCook && (
          <ResourceSelectDialog
            name={"StarChefCooks"}
            list={Cooks}
            onClose={(data: any) => handleCookClose(data)}
          />
        )}
        {cancelModel && (
          <CancelStarChefVisit
            data={selectedData}
            open={cancelModel}
            callback={() => {
              setCancelModel(false);
            }}
          />
        )}
        {rescheduleModel && (
          <RescheduleStarChefVisit
            data={selectedData}
            open={rescheduleModel}
            callback={() => setRescheduleModel(false)}
          />
        )}
        {noteModel && (
          <AddNoteStarChefVisit
            data={selectedData}
            open={noteModel}
            callback={() => setNoteModel(false)}
          />
        )}
        {dishModel && (
          <EditDishStarChefVisit
            data={selectedData}
            open={dishModel}
            callback={() => setDishModel(false)}
          />
        )}
        <Show {...props} actions={false}>
          <ActionButtons
            handleCookOpenList={handleCookOpenList}
            setSelectedData={setSelectedData}
            setAssignCook={setAssignCook}
            setCancelModel={setCancelModel}
            setRescheduleModel={setRescheduleModel}
            setNoteModel={setNoteModel}
            setDishModel={setDishModel}
          />
          <TabbedShowLayout>
            <Tab label={"Details"}>
              <StarChefVisitMetaShow />
            </Tab>
          </TabbedShowLayout>
        </Show>
      </div>
    </Show>
  );
};

export default StarChefVisitShow;