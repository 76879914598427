import TimeSlotDto from "../dtos/Partner/timeSlotDto";
import { ShortTimeEnum } from "../enums/ShortTimeEnum";
import HttpClient from "../HttpClient";
import { PatnerSlotResponse } from "../interfaces/PartnerSlot/partnerSlotResponse";

export default class PartnerSlotService {
  async getFilteredCooks(
    locationId: number,
    radius: number,
    gender: string,
    slotSize: number,
    category: string,
    visitOneTime: ShortTimeEnum[],
    visitTwoTime: ShortTimeEnum[],
    lastUpdatedByAdmin: Date,
    lastUpdatedByCook: Date,
  ): Promise<any> {
    let data = await HttpClient.get(
      "centauras/cook-slotting/cooks/filteredCooks",
      {
        params: {
          locationId: locationId,
          radius: radius,
          gender: gender,
          slotSize: slotSize,
          category: category,
          visitOneTime: JSON.stringify(visitOneTime),
          visitTwoTime: JSON.stringify(visitTwoTime),
          lastUpdatedByAdmin: lastUpdatedByAdmin,
          lastUpdatedByCook: lastUpdatedByCook,
        },
      }
    );
    return data?.data
  }

  async getCookSlots(cookId: number): Promise<PatnerSlotResponse[]> {
    let data = await HttpClient.get(`centauras/cook-slotting/cooks/${cookId}`);
    return data?.data
  }

  async postSlotsForCook(cookId: number, data: TimeSlotDto[]) {
    let response = await HttpClient.post(
      `centauras/cook-slotting/cooks/${cookId}`,
      {
        data,
      }
    );
    return response;
  }
}
