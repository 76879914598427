import { Datagrid, List, NumberField, TextField, ShowButton } from "react-admin";
import { useAppSelector } from "../../store/hooks";
import ResourceSelectDialogButton from "../Common/ResourceSelectDialogButton";

const BundleList = (props: any) => {
    const isCustomSelectOpen = useAppSelector((state) => state.customSelect.value.open);
    return (
        <List {...props}>
            <Datagrid bulkActionButtons={false}>
                {
                    isCustomSelectOpen &&
                    <ResourceSelectDialogButton />
                }
                <TextField source='name' />
                <NumberField source='paidDays' />
                <NumberField source='freeDays' />
                <NumberField source='graceDays' />
                {/*Fields which will be not shown when custom popup is open goes here*/}
                {
                    !isCustomSelectOpen &&
                    <ShowButton/>
                }
                {/*Will be active if custom popup is open*/}
            </Datagrid>
        </List>
    )
}

export default BundleList;