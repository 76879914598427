import { CreateResult, GetListResult, GetOneResult } from "react-admin";
import HttpClient from "../HttpClient";
import CreateLocality from "../interfaces/Locality/CreateLocality";
import LocalityResponse from "../interfaces/Locality/LocalityResponse";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";
import {ReferenceModelNameEnums} from "../enums/ReferenceModelNameEnums";
import ServiceResponse from "../interfaces/Service/ServiceResponse";

export default class LocalityService {
    async fetchAllLocalities(limit: number, offset: number, filters: {}, sort: {}): Promise<GetListResult<LocalityResponse>> {
        filters = ConstructSequelizeFilters(filters, ["name", "cityName", "areaName"]);
        let data = await HttpClient.get('/centaurus/houses/localities', {
            params: {
                limit,
                offset,
                filters,
                sort
            }
        })
        return {
            data: (!!data && !!data?.data) ? data?.data?.data : [],
            total: (!!data && !!data?.data) ? data?.data?.count : 0
        };
    }

    async fetchOne(localityId: number): Promise<GetOneResult<LocalityResponse>> {
        const locality = await HttpClient.get(`/centaurus/houses/localities/${localityId}`);
        return locality;
    }

    async getMany(ids: Array<number>): Promise<GetListResult<LocalityResponse>> {
        const data = []

        for (const id of ids) {
            const res = await this.fetchOne(id)
            data.push(res?.data)
        }

        return {
            data: data,
            total: data?.length
        }
    }

    async create(payload: CreateLocality): Promise<CreateResult<LocalityResponse>> {
        const res = await HttpClient.post(`/centaurus/houses/${payload.areaId}/locality`, {
            name: payload.name,
            coordinates: payload.coordinates,
            rentPayingCapacity: payload.rentPayingCapacity
        });
        return {
            data : res?.data
        }
    }

    async markLocalityAsServicable(id: number): Promise<LocalityResponse>{
        const data = await HttpClient.patch(`/centaurus/areas/localities/${id}/mark-as-serviceable`)
        return data?.data
    }

    async markLocalityAsNonServicable(id: number): Promise<LocalityResponse>{
        const data = await HttpClient.patch(`/centaurus/areas/localities/${id}/mark-as-unserviceable`)
        return data?.data
    }

    async editLocality(id: number, payload: any): Promise<LocalityResponse>{
        const data = await HttpClient.patch(`/centaurus/houses/locality/${id}`, payload);
        return data?.data
    }

    async editLocalityLatLong(id: number, payload: {coordinates: {lat: number, long: number}}){
        const data = await HttpClient.patch(`/centaurus/areas/localities/${id}/update-lat-log`, payload)
        return data?.data
    }

    async getAllServiceOfferings(payload: {
        referenceId: number;
        referenceModelName: ReferenceModelNameEnums;
    }): Promise<ServiceResponse[]> {
        const res = await HttpClient.get(`/houses/get-offering-by-slc`, {
            params: {
                ...payload,
            }
        });
        return res?.data;
    }

    async getAllServices(): Promise<ServiceResponse> {
        const res = await HttpClient.get(`/houses/get-service-offerings`);
        return res?.data;
    }

    async createServiceForSlc(payload: {
        referenceId: number;
        referenceModelName: ReferenceModelNameEnums;
        serviceId: number;
        isServing: boolean;
    }): Promise<ServiceResponse> {
        const res = await HttpClient.post(`/houses/create-slc-service-mapping`, payload);
        return res?.data;
    }

    async changeCookServeForSlc(payload: {
        referenceId: number;
        referenceModelName: ReferenceModelNameEnums;
        serviceId: number;
        isCookServed: boolean;
    }): Promise<ServiceResponse> {
        const res = await HttpClient.patch(`/centaurus/houses/locality/set-cook-served`, payload);
        return res?.data;
    }

    async updateServiceForSlc(payload: {
        referenceId: number;
        referenceModelName: ReferenceModelNameEnums;
        serviceId: number;
        isServing: boolean;
    }): Promise<ServiceResponse> {
        const res = await HttpClient.patch(`/houses/create-slc-service-mapping/${payload?.serviceId}`, payload);
        return res?.data;
    }

    async addCooksToSlc(payload: {
        referenceId: number;
        referenceModelName: string;
        serviceId: number;
        cookId: number;
    }): Promise<ServiceResponse> {
        const res = await HttpClient.post(`/centaurus/houses/add-cook-slc-mapping`, payload);
        return res?.data;
    }

    async getAllSclCooks(params: any): Promise<ServiceResponse> {
        const res = await HttpClient.get(`/centaurus/houses/get-cook-slc-mapping`, {
                params: params,
            }
        );
        return res?.data;
    }

    async removeSlcCooks(payload: any): Promise<ServiceResponse> {
        const res = await HttpClient.post(`/centaurus/houses/remove-cook-slc-mapping`, payload);
        return res?.data;
    }

    async updateTrialLocalityAvailabilityType(localityId: number, payload: any): Promise<ServiceResponse> {
        const res = await HttpClient.patch(`/centaurus/houses/trial-locality-availablity/${localityId}`, payload);
        return res?.data;
    }

    async updateCustomerTag(localityId: number, payload: any): Promise<ServiceResponse> {
        const res = await HttpClient.patch(`/centaurus/user/customer/changeTag/${localityId}`, payload);
        return res?.data;
    }
}
