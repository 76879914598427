import QuestionInterface from "../../interfaces/Cook/SkillAssessmentInterface";

export const hygieneTrainingQuestions: QuestionInterface[] = [
  {
    questionText:
      "Kitchen me jane ke baad aap sabse pehle kya karenge?",
    answerOptions: [
      {
        answerText:
          "Apron pehenenge",
        marks: 0,
      },
      {
        answerText: "Hand wash karenge",
        marks: 20,
      },
      {
        answerText: "Vegetables wash karenge",
        marks: 0,
      },
      {
        answerText: "Safai karenge",
        marks: 0,
      },
    ],
  },
  {
    questionText:
      "Aapke nails kyun chhote hone chahiye?",
    answerOptions: [
      {
        answerText:
          "Nails mai khana atak jata hai.",
        marks: 0,
      },
      {
        answerText:
          "Nails ki gandgi khaane mein jati hai",
        marks: 20,
      },
      {
        answerText:
          "Nails toot jaate hain",
        marks: 0,
      },
      {
        answerText:
          "Chhote nails se chot nahin lagti hai",
        marks: 0,
      },
      {
        answerText:
          "Nails ki size se fark nahi padta",
        marks: 0,
      },
    ],
  },
  {
    questionText:
      "Khaana banaate samay baal bandhe kyun hone chahiye?",
    answerOptions: [
      {
        answerText:
          "Khane mein koi baal na chala jaye",
        marks: 20,
      },
      {
        answerText: "Khane me dandruff na gire",
        marks: 0,
      },
      {
        answerText: "Pata nahi",
        marks: 0,
      },
      {
        answerText: "Baal gande na ho jayein",
        marks: 0,
      },
    ],
  },
  {
    questionText:
      "Khana banate samay khaansi/chheenk aane par kya karna chahiye ?",
    answerOptions: [
      {
        answerText:
          "Khane/Gas se door ho jana chahiye",
        marks: 0,
      },
      { answerText: "Peeche mudkar cheenkein aur phir kaam pe lag jayein", marks: 0 },
      {
        answerText:"Door hone ke baad chheenkein/khaansein aur phir haath dhoke kaam shuru karein",
        marks: 20,
      },
      { answerText: "Wahin khade rehke moonh pe haath rakh lein aur kaam karein", marks: 0 },
    ],
  },

  {
    questionText:
      "Khana banate samay paseena ya khujli aane par kya karna chahiye ?",
    answerOptions: [
      {
        answerText:
          "Kaam karte hue beech mein hi turant khujli kar lein",
        marks: 0,
      },
      { answerText: "Side me jake khujli karein/ roomal se paseena paunch lein, phir haath dhoke kaam karein.", marks: 20 },
      {
        answerText:
          "Kisi chammach se khujala lein.",
        marks: 0,
      },
      { answerText: "Deewaar ya slab ka sahara leke khuja lein.", marks: 0 },
    ],
  },
];
