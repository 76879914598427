import { GetListResult, GetOneResult } from "react-admin";
import httpClient from "../HttpClient";
import { TrialResponse } from "../interfaces/Trials/TrialsResponse";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";
import {BundleGenderPreference, SlotsEnum} from "../enums/common";
import Op from "../interfaces/SequalizeFilters/SequalizeTypes";
import { DynamicPriceCalculator } from "../interfaces/Trials/DynamicPriceResponse";
import {RefundTypeEnum} from "../enums/RefundTypeEnum";
import HttpClient from "../HttpClient";
import GetUserDetailsAction from "../store/actions/LoginAction/GetUserDetailsAction";
import { UserGroupsEnum } from "../enums/UserGroupsEnum";
import DeniedCookInterface from "../interfaces/Cook/DeniedCookInterface";

export class TrialsService {

    cookNumberFilter(filters: any) {
        let where : any= {};
        if (filters.numberOfCookAccepted){
            where.numberOfCookAccepted = {
                [Op.gte.toString()]: filters.numberOfCookAccepted
            }
        }
        if (filters.numberOfCookAllocated) {
            where.numberOfCookAllocated = {
                [Op.gte.toString()]: filters.numberOfCookAllocated
            }
        }
        return where;
    }

    async getAllTrials(limit: number, offset: number, filters: any, sort: any, otherFilters: any = {}): Promise<GetListResult<TrialResponse>> {

        //added filters for promotor
        const userDetails = GetUserDetailsAction()
        const promotorFlag = (userDetails.adminUser.accessGroups as any)?.includes(UserGroupsEnum.PROMOTOR)
        if(promotorFlag){
            filters.createdBy = userDetails.adminUser.id
            filters.createdByType = UserGroupsEnum.ADMIN
        }

        if(filters.cancelledBy){
         filters.cancelledBy = filters?.cancelledBy?.toUpperCase()
        }
        let nullFilters: string[] = [];
        let notNullFilters: string[] = [];
        if (filters.prId === 'Pr_Trial') {
            notNullFilters.push('prId');
        }
        if (filters.prId === 'Normal_Trial') {
            nullFilters.push('prId');
        }
        if(filters.preCheckedInAt==="YES"){
            notNullFilters.push('preCheckedInAt')
        }
        if(filters.preCheckedInAt==="NO"){
            nullFilters.push('preCheckedInAt')
        }
        if(filters?.cookAcknowledged){
            if(filters?.cookAcknowledged?.toLowerCase() === 'true'){
                filters.cookAcknowledged = true
            }
           else if(filters.cookAcknowledged.toLowerCase() === 'false') {
                filters.cookAcknowledged = false
            }
            else{
                delete filters['cookAcknowledged']
            }
        }
        if(filters?.localityId?.toLowerCase() === 'false'){
            notNullFilters.push('localityId')
        }
        if(filters?.localityId?.toLowerCase() === 'true'){
            nullFilters.push('localityId')
        }
       let newFilters = ConstructSequelizeFilters(
            filters,
            ['customerName', 'customerPhoneNumber', 'cookName', 'cookPhoneNumber', 'trialOwnerName', 'sector'],
            ['id', 'cookId', 'findCookStatus', 'status', 'date', 'time', 'cancelledBy', 'cookAcknowledged', 'numberOfCookAllocated', 'numberOfCookAccepted', 'paymentStatus', 'cityId', 'createdBy', 'createdByType', 'allocationType', 'isPartOfDiscovery','trialOwner'],
            nullFilters,
            notNullFilters
        )
       let customFilters = this.cookNumberFilter(filters)
        filters = {
            ...newFilters,
            ...customFilters,
            ...otherFilters,
        }
        let data = await httpClient.get('/centaurus/v3/trials', {
            params: {
                limit,
                offset,
                filters,
                sort
            }
        })
        return {
            data: (!!data && !!data.data) ? data.data.data : [],
            total: (!!data && !!data.data) ? data.data.count : 0
        }
    }

    async getTrialsForHouse(houseId: number,limit: number, offset: number, sort: {}): Promise<GetListResult<TrialResponse>> {
        let data = await httpClient.get(`/centaurus/v3/trials/houses/${houseId}`, {
            params: {
                limit,
                offset,
                sort
            }
        })
        return {
            data: data?.data?.data,
            total: data?.data?.count
        }
    }

    async getTrialsForCook(cookId: number,limit: number, offset: number, sort: {}): Promise<GetListResult<TrialResponse>> {
        let data = await httpClient.get(`/centaurus/v3/trials/cooks/${cookId}`, {
            params: {
                limit,
                offset,
                sort
            }
        })
        return {
            data: data?.data?.data,
            total: data?.data?.count
        }
    }
    async createTrials(payload : any): Promise<GetOneResult<TrialResponse>>{
        let houseId = payload.houseId
        delete payload.houseId
        let data = await httpClient.post(`/centaurus/v4/trials/houses/${houseId}`, payload)
        return data
    }

    async createDynamicPricingTrials(payload : any): Promise<GetOneResult<TrialResponse>>{
        let houseId = payload.houseId
        delete payload.houseId
        let data = await httpClient.post(`/centaurus/v4/trials/houses/${houseId}`, payload)
        return data?.data
    }

    async assignCookToTrials(trialsId: number, payload : any){
        let data = await httpClient.post(`/centaurus/v3/trials/${trialsId}/assign`, payload)
        return data
    }

    async cancelTrials(trialId : number, payload: any){
        let data = await httpClient.delete(`/centaurus/v3/trials/${trialId}`, {data: payload})
        return data
    }

    async rescheduleTrials(trialId : number, payload : any){
        let data = await httpClient.patch(`/centaurus/v3/trials/${trialId}/reschedule`, payload)
        return data
    }

    async approvalTrials(trialId : number, payload : any){
        let data = await httpClient.post(`/centaurus/v3/trials/${trialId}/approval`, payload)
        return data
    }

    async markFulfilled(trialId: number) {
        let data = await httpClient.post(`/centaurus/v3/trials/${trialId}/fulfilled`)
        return data?.data
    }

    async markNotConverted(trialId: number, payload: {reason: string | null,subReason:string | null}) {
        let data = await httpClient.post(`/centaurus/v3/trials/${trialId}/not-converted`, payload)
        return data?.data
    }

    async cookAcknowledge(trialId: number, payload: {accepted: boolean, cookRejectionReason?: string, cookRejectionSubReason?: any}) {
        let data = await httpClient.post(`/centaurus/v3/trials/${trialId}/acknowledge`, payload)
        return data?.data
    }

    async createUserBundle(trialId: number, bundleId: number, payload: {startDate: string, startSlot: SlotsEnum}) {
        let data = await httpClient.post(`/centaurus/v3/trials/${trialId}/bundle/${bundleId}/activate`, payload);
        return data?.data
    }

    async getTrialsById(trialId: number) {
        let data = await httpClient.get(`/centaurus/v3/trials/${trialId}`);
        return {
            data : data?.data
        }
    }

    async editTrialsById(trialId: number, payload: any) {
        let data = await httpClient.patch(`/centaurus/v4/trials/${trialId}`, payload);
        return {
            data : data?.data
        }
    }

    async editDynamicPricingTrialsById(trialId: number, payload: any) {
        let data = await httpClient.patch(`/centaurus/v4/trials/${trialId}`, payload);
        return data?.data;
    }

    async updateTrialOwner(trialId: number, payload: any) {
        let data = await httpClient.patch(`/centaurus/v3/trials/${trialId}/trial-owner`, payload)
        return {
            data: data?.data
        }
    }

    async updateTrialAllocator(trialId: number, payload: any) {
        let data = await httpClient.patch(`/centaurus/v3/trials/${trialId}/trial-allocator`, payload)
        return {
            data: data?.data
        }
    }

    async addOrUpdateTrialAdminNotes(trialId: number, payload: {adminNotes: string}) {
        let data = await httpClient.post(`/centaurus/v3/trials/${trialId}/admin-notes`, payload)
        return data?.data
    }

    async customerAcknowledged(trialId: number,payload: {startDate: string, startSlot: string}) {
        let data = await httpClient.patch(`/centaurus/v3/trials/${trialId}/accept`, payload);
        return data?.data
    }

    async customerRejected(trialId: number) {
        let data = await httpClient.patch(`/centaurus/v3/trials/${trialId}/reject`);
        return data?.data
    }

    async markTrialAsOngoing(trialId: number) {
        let data = await httpClient.patch(`/centaurus/v3/trials/${trialId}/ongoing`);
        return data?.data;
    }

    async getAcceptedLeadsForTrial(trialId: number, limit: number, offset: number, sort: any){
        let data = await httpClient.get(`/centaurus/v3/trials/${trialId}/accepted-leads`, {
            params: {
                limit,
                offset,
                sort,
                filters: {
                    hasValidStatus: true
                }
            }
        });
        return data
    }

    async getAllAutoAllocatedCooksForTrial(trialId: number, limit: number, offset: number, sort: any){
        let data = await httpClient.get(`trial/${trialId}/all-leads`, {
            params: {
                limit,
                offset,
                sort,
                // filters: {
                //     leadAcceptedAt: {
                //         [Op.not.toString()]: null,
                //     },
                //     // isPrefferedByCx: true,
                // }
            }
        });
        return data
    }

   async addManualLeadToTrial(trialId: number, body: any){
    let data = await httpClient.post(`/centaurus/v3/trials/${trialId}/lead`,body);
    return data?.data
   }

   async addLocalityToTrial(trialId: number, body: any){
    let data = await httpClient.patch(`/centaurus/v3/trials/${trialId}/locality`, body);
    return data
   }

    async calculateDynamicPrice(payload : any){
        let data = await httpClient.post('/trial/calculatePrice', payload)
        return data?.data
    }
    async calculateDynamicPriceByAreaId(payload : any){
        let data = await httpClient.post('/trial/calculate-price-by-area', payload)
        return data?.data
    }

    async calculateDynamicPriceByLocality(payload: DynamicPriceCalculator){
        let data = await httpClient.post(`/trial/calculatepricebylocality`, payload)
        return data?.data

    }

    async trialVendorPayout(id: number){
        let data = await httpClient.get(`centaurus/v3/trials/${id}/vendor-payout`);
        return data?.data;
    }

    async getAvailableTemplateForTrialCICO(userType: string){
        let data =  await httpClient.get(`/whatsapp/v3/available-templates`, {
            params: {
                userType
            }
        });
        return data;
    }

    async sendWhatsappForTrialCICO(payload: {userId: number, userType: string, templateName: string}){
        let data = await httpClient.post(`/whatsapp/v3/send-direct-template-message`, payload)
        return data;
    }

    async checkAutoAllocationCook(trialId: number, cookId: number){
        let data = await httpClient.post(`/centaurus/v3/trials/is-auto-allocated-cook`, {
            "trialId": trialId,
            "cookId": cookId
        })
        return data?.data
    }

    async refundTrial(trialId : number, payload: {refundType: RefundTypeEnum, reason: string, amount?: string}){
        let data = await httpClient.post(`/centaurus/v4/trials/${trialId}/refund`, payload)
        return data?.data
    }

    async sendTrailPaymentLink(trialId: number) {
        let data = await HttpClient.post(`centaurus/v4/trials/${trialId}/send-payment-link`)
        return data?.data;
    }

    async addSupportComments(id: number, payload: {commentOrFeedback: string}){
        let data = await httpClient.patch(`/centaurus/v4/trials/${id}/support-comments`, payload)
        return data?.data
    }

    async addAllocationNotes(id: number, payload: {commentOrFeedback: string} ){
        let data = await httpClient.patch(`/centaurus/v4/trials/${id}/allocation-comments`, payload)
        return data?.data
    }

    async addCxFeedback(id: number, payload: {commentOrFeedback: string}){
        let data = await httpClient.patch(`/centaurus/v4/trials/${id}/cx-feedback`, payload)
        return data?.data
    }

    async addHcFeedback(id: number, payload: {commentOrFeedback: string}){
        let data = await httpClient.patch(`/centaurus/v4/trials/${id}/hc-feedback`, payload)
        return data?.data
    }

    async denyCook(id: number,payload: {reason: string}) {
        let data = await httpClient.patch(`/centaurus/v3/trials/${id}/deny`, payload)
        return data?.data
    }

    async getDeniedCooks(id: number): Promise<DeniedCookInterface[]> {
        let data = await httpClient.get(`/centaurus/v4/trials/${id}/get-denied-cooks`);
        return data?.data;
    }

    async revertDeniedCook(trialId: number, cookId: number) {
        let data = await httpClient.delete(`/centaurus/v4/trials/${trialId}/cook/${cookId}/revert-denial`);
        return data?.data;
    }

    async startFindCookProcess(trialId: number): Promise<GetOneResult<TrialResponse>> {
        let data = await httpClient.post(`centaurus/v4/trials/${trialId}/start-find-cook`);
        return data?.data;
    }

    async initiateFindCookProcessBySupply(trialId: number): Promise<GetOneResult<TrialResponse>> {
        let data = await httpClient.post(`centaurus/v4/trials/${trialId}/init-find-cook`);
        return data?.data;
    }

    async setCookBySupply(trialId: number, payload: {cookId: number, isCookFound?: boolean}): Promise<GetOneResult<TrialResponse>> {
        let data = await httpClient.patch(`centaurus/v4/trials/${trialId}/set-found-cook`, payload);
        return data?.data;
    }

    async addSupplyComment(trialId: number, payload: {supplyComment: string}): Promise<GetOneResult<TrialResponse>> {
        let data = await httpClient.patch(`centaurus/v4/trials/${trialId}/add-supply-comment`, payload);
        return data?.data;
    }

    async changeFoundCookStatus(trialId: number, payload: {reason: string, status?: string}): Promise<GetOneResult<TrialResponse>> {
        let data = await httpClient.patch(`centaurus/v4/trials/${trialId}/set-find-cook-status`, payload);
        return data?.data;
    }

    async resetCookAcknolwedgement(trialId: number): Promise<GetOneResult<TrialResponse>> {
        let data = await httpClient.patch(`/centaurus/v4/trials/${trialId}/reset-cook-acknowledge`);
        return data?.data;
    }

    async setContactedAt(trialId: number, cookId: number, payload: any): Promise<GetOneResult<TrialResponse>> {
        let data = await httpClient.patch(`/centaurus/v4/trials/${trialId}/list-cook-contacted/${cookId}`, payload);
        return data?.data;
    }

    async switchAllocationType(trialId: number, payload: any): Promise<GetOneResult<TrialResponse>> {
        let data = await httpClient.post(`/centaurus/v4/trials/${trialId}/set-trial-allocation-type`, payload);
        return data?.data;
    }

    async acceptAutoAllocationCook(trialId: number, cookId: number): Promise<GetOneResult<TrialResponse>> {
        let data = await httpClient.post(`/trial/${trialId}/accept-lead/${cookId}`);
        return data?.data;
    }
    async getAuditLogsForTrial(trialId: number): Promise<GetOneResult<TrialResponse>> {
        let data = await httpClient.get(`/centaurus/v4/trials/${trialId}/allocation-audit-logs`);
        return data?.data;
    }

    async updatePreference(trialId: number, payload: any): Promise<GetOneResult<TrialResponse>> {
        let data = await httpClient.post(`/trial/${trialId}/mark-cx-preffered`, payload);
        return data?.data;
    }

    async getStages(trialId: number): Promise<any> {
        let data = await httpClient.get(`/centaurus/v4/trials/${trialId}/expected-allocation-time`);
        return data?.data;
    }

    async setTotalPrice(trialId: number, payload: any): Promise<any> {
        let data = await httpClient.patch(`centaurus/v4/trials/${trialId}/set-total-price`, payload);
        return data;
    }

    async getCustomerRatings(id: string, model: string): Promise<any> {
        let data = await httpClient.get(`/v1/centaurus/cook-customer-ratings/${model}/${id}`);
        return data;
    }

    async cancelTrial(trialId: number, payload: any): Promise<any> {
        let data = await httpClient.patch(`/centaurus/v4/trials/${trialId}/cancel`, payload);
        return data;
    }

    async changeGenderPreference(trialId: number, payload: {genderPreference: BundleGenderPreference}): Promise<any> {
        let data = await httpClient.patch(`/centaurus/v3/trials/${trialId}/change-gender-preference`, payload);
        return data?.data;
    }

    async preCheckIn(trialId:number,cookId:number){
        let data = await httpClient.patch(`/centaurus/v4/trials/${trialId}/cook/${cookId}/pre-checkin`)
        return data
    }
 }
