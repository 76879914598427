import CookCheckInCheckOut from "./CookCheckInCheckOut";
import CookSubscriptionMetaShow from "./CookSubscriptionMetaShow";
import { useSearchParams } from "react-router-dom";
import { Box, Tabs, Typography, Tab } from "@mui/material";
import { useState } from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CookSubscriptionShow = () => {
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [searchParams] = useSearchParams();
  const houseId = searchParams.get("houseId");
  const subscriptionId = searchParams.get("subscriptionId");

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Cook Subscription" {...a11yProps(0)} />
          <Tab label="Check in Check out" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <CookSubscriptionMetaShow subscriptionId={Number(subscriptionId)} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CookCheckInCheckOut
          houseId={Number(houseId)}
          subscriptionId={Number(subscriptionId)}
        />
      </TabPanel>
    </>
  );
};

export default CookSubscriptionShow;
