import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl, InputLabel, Select, MenuItem,
  } from "@mui/material";
  import React, { Dispatch, SetStateAction, useState } from "react";
  import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
  import EventBus from "../../EventBus";
  import LocalityService from "../../services/LocalityService";
  import { CustomSnackbarTypes } from "./CustomSnackBar";
  import { useRecordContext, useRefresh } from "react-admin";
  import {CustomerTags} from "../../enums/CustomerTags";
  
  interface InputProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
  }
  
  
  const UpdateTagDialog = (props: InputProps) => {
    const [selectedTag, setSelectedTag] = useState<any>(null);
    const record = useRecordContext();
    const userId = Number(record?.id);
    const refresh = useRefresh();
    const localityService = new LocalityService();
  
  
    const handleSubmit = () => {
      if (!selectedTag) {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Fill the data to continue",
          type: CustomSnackbarTypes.ERROR,
        });
        return;
      }
      let payload = {
        tag: selectedTag,
      };
      localityService.updateCustomerTag(userId,payload).then(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Successful",
          type: CustomSnackbarTypes.SUCCESS,
        });
        refresh();
      });
      
      props.setOpen(false);
    };
  
  
    return (
      <Dialog open={props.open} scroll={"paper"}>
        <DialogTitle>Set Tag</DialogTitle>
        <DialogContent>
          <FormControl fullWidth >
            <InputLabel id="Tag_slot">Select Type</InputLabel>
            <Select
                labelId="Tag_slot"
                id="Select Type"
                label="Select Service"
                value={selectedTag}
                onChange={(event) => setSelectedTag(event.target.value)}
            >
              
              {Object.keys(CustomerTags).map((e: any) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
            ))}
              
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color={"success"}
            onClick={() => {
              handleSubmit();
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              props.setOpen(false);
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default UpdateTagDialog;
  