import { ReponseAfterSMS } from "../dtos/sms/ResponseAfterSendSMSDto";
import HttpClient from "../HttpClient";

export default class SMSService {
  async sendMessage(jsonData: JSON, phoneNumberArray: string[], appId: number, templateVariant: number): Promise<ReponseAfterSMS> {
    let payLoad = {
      body: jsonData,
      app: appId,
      receiver: phoneNumberArray,
      templateVariant: templateVariant,
    };
    const res = await HttpClient.post("/sms/bulk", payLoad);
    return res.data;
  }

  async getTemplateVariantsAccToTemplate(templateId: number) {
    let data = await HttpClient.get(
      `/sms/template/${templateId}/template-variants`
    );
    return data.data;
  }

  async getAllTemplate(limit: number, offset: number, filter: any, sort: any): Promise<any> {
    let data = await HttpClient.get("/sms/template/paginate", {
      params: {
        limit,
        offset,
        filter,
        sort,
      },
    });
    return {
      data: data.data.data,
      total: data.data.count,
    };
  }

  async getTemplate(templateId: number) {
    let data = await HttpClient.get(`/sms/template/${templateId}`);
    return data.data;
  }
}
