import React, {useEffect} from "react";
import {
    Box, Button, Checkbox,
    Dialog,
    FormControl, FormControlLabel, FormGroup,
    // IconButton, TextField,
    Typography
} from "@mui/material";
import FieldViewResponse from "../../interfaces/FieldView/FieldViewResponse";
// import {FieldViewInputTypeEnums} from "../../enums/FieldViewInputTypeEnums";
// import CloseIcon from "@mui/icons-material/Close";
type InputProps = {
    field: FieldViewResponse;
    open: boolean;
    onClose: () => void;
    handleSubmitClicked:  (payload: any) => void;
}

const UpdateFieldDialog = (props: InputProps) => {
    const [activeChecked, setActiveChecked] = React.useState<boolean>(true);
    // const [options, setOptions] = React.useState<string[]>([]);
    const handleActiveChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        setActiveChecked(event?.target?.checked);
    }

    useEffect(() => {
        if(!props?.field) return;
        setActiveChecked(props?.field?.isActive);
        // if([FieldViewInputTypeEnums.SELECT, FieldViewInputTypeEnums.CHECKBOX, FieldViewInputTypeEnums.RADIO].includes(props?.field?.inputType) && props?.field?.values) {
        //     setOptions(JSON.parse(props?.field?.values.replace(/'/g, '"')) || []);
        // }
    }, [props?.field]);

    return (
        <Dialog open={props?.open} onClose={props?.onClose} sx={{
            '& .MuiPaper-root': {
                py: '20px',
                px: '20px',
                minWidth: '525px',
                maxWidth: '650px',
            },
        }}>
            <Typography fontWeight={600} letterSpacing={'1px'} fontSize={'16px'} mb={'10px'}>Update Additional Detail Parameter</Typography>
            <Box>
                {/*{*/}
                {/*    [*/}
                {/*        FieldViewInputTypeEnums.SELECT,*/}
                {/*        FieldViewInputTypeEnums.CHECKBOX,*/}
                {/*        FieldViewInputTypeEnums.RADIO*/}
                {/*    ].includes(props?.field?.inputType) &&*/}
                {/*    options?.map((eachOption: string, index: number) => {*/}
                {/*        return(*/}
                {/*            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} key={eachOption}>*/}
                {/*                <TextField*/}
                {/*                    label="Enter Value"*/}
                {/*                    fullWidth*/}
                {/*                    value={eachOption}*/}
                {/*                    onChange={(e) => {*/}
                {/*                        const val = e.target.value;*/}
                {/*                        const updatedOptions = [...options];*/}
                {/*                        updatedOptions[index] = val;*/}
                {/*                        setOptions(updatedOptions);*/}
                {/*                    }}*/}
                {/*                />*/}
                {/*                <IconButton*/}
                {/*                    disabled={options.length === 1}*/}
                {/*                    sx={{ml: 2}}*/}
                {/*                    onClick={() => {*/}
                {/*                        options.splice(index, 1);*/}
                {/*                        setOptions([...options]);*/}
                {/*                    }}*/}
                {/*                    color={'error'}*/}
                {/*                >*/}
                {/*                    <CloseIcon />*/}
                {/*                </IconButton>*/}
                {/*            </Box>*/}

                {/*        )*/}
                {/*    })*/}
                {/*}*/}
                <FormControl component="fieldset">
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={activeChecked} onChange={handleActiveChecked} />}
                            label="Active"
                        />
                    </FormGroup>
                </FormControl>
            </Box>
            <Box mt={'5px'} display={'flex'} justifyContent={'flex-end'}>
                <Button
                    variant="contained"
                    color="primary"
                    sx = {{
                        width: '150px',
                        height: '40px',
                    }}
                    onClick={() => {
                        let payload: any = {
                            isActive: activeChecked,
                        };
                        // if([FieldViewInputTypeEnums.SELECT, FieldViewInputTypeEnums.CHECKBOX, FieldViewInputTypeEnums.RADIO].includes(props?.field?.inputType)) {
                        //     payload = {
                        //         ...payload,
                        //         values: JSON.stringify(options),
                        //     }
                        // }
                        props.handleSubmitClicked(payload)
                    }}
                >
                    {'Update'}
                </Button>
            </Box>
        </Dialog>
    );
}
export default UpdateFieldDialog;
