import { TableContainer, TableHead, TableRow, TableCell, Table, TableBody, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, FormControl } from "@mui/material"
import { useState } from "react";
import { ReponseAfterSMS } from "../../dtos/sms/ResponseAfterSendSMSDto";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import SMSService from "../../services/SMSService";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";

const smsService = new SMSService();

const TemplateVariantTable = (props: any) => {
    const [sendMessageDialogboxOpen, setSendMessageDialogboxOpen] = useState<boolean>(false);
    const [phoneNumbers, setPhoneNumbers] = useState<string>('');
    const [templateVariant, setTemplateVariant] = useState<number>();
    const [jsonData, setJsonData] = useState<JSON>();
    const [templateText, setTemplateText] = useState<string>("");

    const handlePhoneNumberChange = (e: any) => {
        setPhoneNumbers(e.target.value);
    }

    const handleSubmit = async () => {
        let mobile = phoneNumbers.trim();
        mobile = mobile.replace(/ /g, "");
        let phoneNumberArray = mobile.split(",");
        let data: ReponseAfterSMS = await smsService.sendMessage(jsonData!, phoneNumberArray, props.appId, templateVariant!);
        if (data.failure.length > 0) {
            let numbersFailed: string = "";
            for (let i = 0; i < data.failure.length; i++) {
                if (i === data.failure.length - 1) {
                    numbersFailed += data.failure[i].receiver;
                }
                else {
                    numbersFailed += data.failure[i].receiver + " , ";
                }
            }
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: `Something Went Wrong With: ${numbersFailed}`,
                type: CustomSnackbarTypes.ERROR
            });
        }
        else {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Message Sent Successfully",
                type: CustomSnackbarTypes.SUCCESS
            });
        }
    }


    return (
        <div>
            <div>
                <Dialog
                    open={sendMessageDialogboxOpen}
                    scroll={"paper"}
                    maxWidth="xl"
                    onBackdropClick={() => { setSendMessageDialogboxOpen(false) }}
                >
                    <DialogTitle>Send Message</DialogTitle>
                    <DialogContent>
                        <Grid
                            container
                            spacing={2}
                            justifyContent={"space-evenly"}
                            style={{ padding: "12px 12px 12px 12px" }}
                            direction="column"
                            alignItems="center"
                        >
                            <Grid item>
                                <p>{templateText}</p>
                            </Grid>
                            {
                                !!jsonData &&
                                Object.keys(jsonData).map((key: string) => {
                                    return (
                                        <Grid key={key} item xs={12}>
                                            <FormControl style={{ minWidth: 120 }} fullWidth>
                                                <TextField
                                                    id="fullWidth"
                                                    fullWidth
                                                    label={key}
                                                    value={(jsonData as any)[key]}
                                                    onChange={(e: any) => {
                                                        setJsonData({ ...jsonData, [key]: e.target.value })
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    )
                                })
                            }
                            <Grid item>
                                <FormControl style={{ minWidth: 120 }} fullWidth>
                                    <TextField value={phoneNumbers} onChange={handlePhoneNumberChange} fullWidth label="Number (with ,)" id="fullWidth" />
                                </FormControl>
                            </Grid>
                        </Grid>


                    </DialogContent>
                    <DialogActions style={{ marginTop: "12px" }}>
                        <Button onClick={() => {
                            handleSubmit();
                            setSendMessageDialogboxOpen(false);
                        }} variant="contained">Send</Button>
                    </DialogActions>
                </Dialog>
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Id</TableCell>
                            <TableCell align="center">Language</TableCell>
                            <TableCell align="center">Flow Id</TableCell>
                            <TableCell align="center">Template</TableCell>
                            <TableCell align="center">Content</TableCell>
                            <TableCell align="center">Send Message</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            !!props.apiData &&
                            Array.isArray(props.apiData) &&
                            props.apiData.map((item: any) => (
                                <TableRow key={item.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <TableCell align="center">{item.id}</TableCell>
                                    <TableCell align="center">{item.language}</TableCell>
                                    <TableCell align="center">{item.flowId}</TableCell>
                                    <TableCell align="center">{item.template}</TableCell>
                                    <TableCell align="center">{JSON.stringify(item.content).substring(1, JSON.stringify(item.content).length - 1)}</TableCell>
                                    <TableCell align="center">
                                        <Button variant="contained" onClick={() => {
                                            setSendMessageDialogboxOpen(true);
                                            setTemplateVariant(item.id);
                                            setJsonData(item.content);
                                            setTemplateText(item.templateText);
                                        }}>Message</Button>
                                    </TableCell>

                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default TemplateVariantTable