import HttpClient from "../HttpClient";
import {GetOneResult} from "react-admin";

export default class reasonSubreasonService {
    async fetchReasonAndSubReason(
        module:string,
        actionType:string
      ): Promise<GetOneResult> {
        let data = await HttpClient.get(`/reasonSubreason?module=${module}&actionType=${actionType}`, {
        });
        // console.log(data)
        return {
          data
        };
    }
}
