import { Button, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import CallButton from "../Common/CallButton/CallButton";
import { UserType } from "../../enums/common";

const PermanentReplacementListForModule = (props: any) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>CX Name</TableCell>
                        <TableCell>CX Mobile</TableCell>
                        <TableCell>Owner Id</TableCell>
                        <TableCell>User Bundle Id</TableCell>
                        <TableCell>Owner Name</TableCell>
                        <TableCell>Owner Email</TableCell>
                        <TableCell>CreatedAt</TableCell>
                        <TableCell>UpdatedAt</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((row: any) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell scope="row">{row?.id}</TableCell>
                            <TableCell align="center">{row?.status}</TableCell>
                            <TableCell align="center">{row?.customerName}</TableCell>
                            {/* <TableCell align="center">{row?.customerPhone}</TableCell> */}
                            <TableCell align="center">{
                                <CallButton toPhoneNumber={row?.customerPhone} userType={UserType.CUSTOMER} />
                            }</TableCell>
                            <TableCell align="center">{row?.ownerId}</TableCell>
                            <TableCell align="center">{row?.userBundleMappingId}</TableCell>
                            <TableCell align="center">{row?.ownerName}</TableCell>
                            <TableCell align="center">{row?.ownerEmail}</TableCell>
                            <TableCell align="center">
                                {
                                    !!row &&
                                    !!row.createdAt &&
                                    moment(row?.createdAt).format('DD MMM YYYY hh:mm')
                                }
                            </TableCell>
                            <TableCell align="center">
                                {
                                    !!row &&
                                    !!row.updatedAt &&
                                    moment(row?.updatedAt).format('DD MMM YYYY hh:mm')
                                }
                            </TableCell>
                            <TableCell>
                                <Link href={`/#/PermanentReplacement/${row.id}/show`}>
                                    <Button variant={'contained'} color={'primary'}>
                                        Show
                                    </Button>
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PermanentReplacementListForModule;
