function cleanFilters(filters: {}, keys: string[]) {
    let filterObj = {};
    Object.keys(filters).forEach((key) => {
        if (keys.includes(key)) {
            // @ts-ignore
            filterObj[key] = filters[key]
        }
    })
    keys.forEach((key) => {
        // @ts-ignore
        if (!filterObj[key]) {
            // @ts-ignore
            filterObj[key] = ''
        }
    })
    return filterObj;
}

export default cleanFilters;