import QuestionInterface from "../../interfaces/Cook/SkillAssessmentInterface";

export const kitchenEtiquettesQuestions: QuestionInterface[] = [
  {
    questionText:
      "Kitchen me jane se pehle aap ko kya kya pehennna zaroori hai ?",
    answerOptions: [
      {
        answerText:
          "Apron",
        marks: 10,
      },
      {
        answerText: "Mask",
        marks: 10,
      },
      {
        answerText: "Hair Net",
        marks: 10,
      },
    ],
  },
  {
    questionText:
      "Customer ne agar rashan ka saaman mangwa kar slab pe rakha hua hai to aap kya karenge?",
    answerOptions: [
      {
        answerText:
          "Customer ko saaman hatane ke liye bolenge",
        marks: 0,
      },
      {
        answerText:
          "Saaman ko kahin bhi rakh denge",
        marks: 0,
      },
      {
        answerText:
          "Customer se poochke saaman jagah pe rakh denge",
        marks: 10,
      },
      {
        answerText:
          "Saaman jahaan rakha hai wahin rehne denge",
        marks: 0,
      },
    ],
  },
  {
    questionText:
      "Sabzi kaatne ke samay kuch tukde neeche gir jayein, to aap unko kab saaf karenge?",
    answerOptions: [
      {
        answerText:
          "Poora kaam karne ke baad saaf karenge",
        marks: 0,
      },
      {
        answerText: "Sabzi kaatne ke turant baad saaf karege aur phir hand wash karenge",
        marks: 10,
      },
      {
        answerText: "Time milne pe saaf karenge",
        marks: 0,
      },
      {
        answerText: "Bina saaf kiye bhi ja sakte hain, safayi wale baad me utha lenge",
        marks: 0,
      },
    ],
  },
  {
    questionText:
      "Masale aur baaki saaman istemaal karne ke baad unke boxes ka aap kya karenge?",
    answerOptions: [
      {
        answerText:
          "Boxes ko wahin chhod denge",
        marks: 0,
      },
      { answerText: "Boxes ka kaam hote hi saaf karke rakh denge", marks: 10 },
      {
        answerText:"Saara kaam hone ke baad boxes ko bina saaf kare rakh denge",
        marks: 0,
      },
    ],
  },

  {
    questionText:
      "Kaam pura hone ke baad kaunse bartan saaf karne hote hain aur kaun se bartan bhigo kar rakhne hote hain?",
    answerOptions: [
      {
        answerText:
          "Saare bartan saaf kar ke rakhne hain",
        marks: 0,
      },
      { answerText: "Kuch bhi saaf nahi karna hai", marks: 0 },
      {
        answerText:
          "Chopping board and Knife",
        marks: 10,
      },
      { answerText: "Chakla - Belan", marks: 10 },
      { answerText: "Mixer Grinder", marks: 10 },
      { answerText: "Jis bartan me khana bana hai use bhigo kar denge", marks: 10 },
    ],
  },
];
