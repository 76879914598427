import { Close } from "@mui/icons-material";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField,
} from "@mui/material";
import * as React from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneAllIcon from '@mui/icons-material/DoneAll';

interface InputProps {
    data: any;
    open: boolean;
    callback: (data: any) => void;
}
const LinkListItem = (props: any) => {
  const [copy, setCopy] = React.useState<boolean>(false)
  const handleCopyClick = () => {
    let text : any = document.getElementById('textField')
    navigator.clipboard.writeText(text.value)
    setCopy(true)
  }
  setTimeout(() => {
    setCopy(false);
  }, 10000)
  return(
    <div style={{display:"flex", alignItems: "center", alignContent: "space-between"}}> 
      <TextField id="textField" value={props?.text} variant="outlined" size="small" sx={{width:"330px"}}/>
      <div style={{marginLeft: "0.5rem"}}>
        {!copy ? <ContentCopyIcon onClick={()=>handleCopyClick()}/> : <DoneAllIcon />}
      </div>
    </div>
  )
}

export const PaymentLinkDailog = (props: InputProps) => {

    let handleClose = () => {
        props.callback({});
    };


    return (
        <div>
            <Dialog
                onClose={handleClose}
                open={props?.open}
                scroll={"paper"}
                maxWidth="xl"
            >
                <DialogTitle>Payment Links</DialogTitle>
                <DialogContent>
                    <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
                    <Grid
                        container
                        columns={1}
                        rowSpacing={0.9}
                        style={{ padding: "12px 12px 12px 12px", width:"400px" }}
                    >
                      <div style={{display:"flex",flexDirection:"column"}}>
                      {props?.data?.map((e:any)=>
                      <LinkListItem key={e} text={e}/>)}
                      </div>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ marginTop: "12px" }}>
                    <Button
                        variant="contained"
                        color={"error"}
                        onClick={() => {
                            handleClose();
                        }}
                        size={"large"}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
