import {
  Alert, Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Portal,
  Select,
  Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Typography,
  TextField,
} from "@mui/material";
import { useRefresh } from "react-admin";
import { SlotsEnum } from "../../enums/SlotsEnum";
import UserBundleService from "../../services/BundleService";
import React, { useCallback, useEffect, useState } from "react";
import CircularLoader from "./CircularLoader";
import { UserWantServiceInFuture } from "../../enums/UserWantServiceInFutureEnum";
import { CalculateRefundDialog } from "./CalculateRefundDialog";
import {getReasonCategory } from "./constants/constant";
import { ActionTypeEnum } from "../../enums/ActionTypeEnum";
import ReasonSubreasonModuleEnum from "../../enums/ReasonSubreasonModuleEnum";

interface InputProps {
  data: any;
  open: boolean;
  callback: (data: any) => void;
}

interface item {
  category: string;
  subCategory: string[];
}

const userBundleService = new UserBundleService();

export const CancelBundleDialog = (props: InputProps) => {

  const [endDate, setEndDate] = useState<any>(null);
  const [endSlot, setEndSlot] = useState<SlotsEnum | string>("");
  let [snackBarOpen, setSnackBarOpen] = useState(false);
  const [userBundleOptionMapping,setUserBundleOptionMapping]=useState<any>([]);
  const [reasonSubCategory, setReasonSubCategory] = useState<string | null>(null);
  const [reasonSubCategoryArray, setReasonSubCategoryArray] = useState<string[]>([]);
  const [reasonCategory, setReasonCategory] = useState<string | null>(null);  const [comment, setComment] = useState<SlotsEnum | string>("");
  let [isInterested, setIsInterested] = useState<Boolean>(false);
  let [refundDetailLoading, setRefundDetailLoading] = useState<Boolean>(false);
  let [refundDetails, setRefundDetails] = useState<any>(null);
  let [showSubmitButton, setShowSubmitButton] = useState<Boolean>(false);
  const [calculateRefundFlag, setCalculateRefundFlag] = useState<boolean>(true);
  const [openBreakUp, setOpenBreakUp] = useState<boolean>(false);
  const [selectedRefund, setSelectedRefund] = useState<any>();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const refresh = useRefresh();

  const loadData = useCallback(async () => {
    let sampleReasonSubReason = await getReasonCategory(ReasonSubreasonModuleEnum.SUBSCRIPTION,ActionTypeEnum.SUBSCRIPTION_CLOSURE);
    setUserBundleOptionMapping(sampleReasonSubReason);
  }, []);

  useEffect(()=>{
    loadData();
  },[loadData])


  const handleReasonCategoryChange = (event: any) => {
    const selectedCategory:any = userBundleOptionMapping.find(
      (option:any) => option.category === event.target.value
    );

    if (selectedCategory) {
      setReasonSubCategoryArray(selectedCategory?.subCategory);
      setReasonCategory(event.target.value);
    }
  }

  const handleReasonSubCategoryChange = (event: any) => {
    setReasonSubCategory(event.target.value);
  };
  function showSubCategoryOrNot(reasonCategory: string) {
    if (reasonCategory === null) {
      return null;
    }
    if (reasonCategory?.toLowerCase() === "others" || reasonCategory?.toLowerCase() === 'other') {
      return (
        <FormControl fullWidth>
          <TextField
            value={reasonSubCategory}
            onChange={handleReasonSubCategoryChange}
            id="outlined-basic"
            label="Write a reason"
            fullWidth
          />
        </FormControl>
      );
    } else {
      return (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select a Subreason</InputLabel>
          <Select
            autoWidth={false}
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            defaultValue="Select a reason"
            value={reasonSubCategory}
            onChange={handleReasonSubCategoryChange}
          >
            {reasonSubCategoryArray.map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    }
  }

  let handleClose = () => {
    setEndDate("");
    setEndSlot("");
    setRefundDetails(null);
    setCalculateRefundFlag(true)
    setShowSubmitButton(false);
    props.callback({
      endDate,
      endSlot,
    });
  };
  let handleSnackbarClose = () => {
    setSnackBarOpen(false);
  };

  useEffect(() => {
    if (endSlot && endDate) {
      setRefundDetailLoading(true);
      userBundleService
        .getRefundDetails(props.data.id, endDate, endSlot as SlotsEnum)
        .then((res) => {
          setRefundDetailLoading(false);
          setRefundDetails(res.data);
          setShowSubmitButton(true);
        })
        .catch((err) => {
          setRefundDetailLoading(false);
          setShowSubmitButton(true);
        });
        setCalculateRefundFlag(false)
    }
  }, [endDate, endSlot, props]);
  if(!props.open){
    return null;
  }

  return (
    <div>
      { calculateRefundFlag ?
         <CalculateRefundDialog
         open={calculateRefundFlag}
         onSubmit={(param) => {
          setCalculateRefundFlag(false)
          setEndDate(param.date);
          setEndSlot(param.slot);
         }}
         callback={() => handleClose()}
         onClose={handleClose}
         data={props.data} /> :  endDate && endSlot && <Dialog
         onClose={handleClose}
         open={props.open}
         scroll={"paper"}
         maxWidth="lg"
       >
         <DialogTitle>Cancel Bundle</DialogTitle>
         <DialogContent>
           {refundDetailLoading ? (
             <CircularLoader
               style={{
                 width: "100%",
               }}
             />
           ) : (
             <Grid
               container
               columns={1}
               rowSpacing={0.9}
               style={{ padding: "12px 12px 12px 12px" }}
             >
               <FormControl fullWidth>
                 <FormControl fullWidth style={{ marginTop: "20px" }}>
                   <InputLabel id="demo-simple-select-label">
                     Tell Us Why?
                   </InputLabel>
                   <Select
                     autoWidth={false}
                     labelId="demo-multiple-name-label"
                     id="cancel-dialog"
                     defaultValue="Tell us why?"
                     value={reasonCategory}
                     onChange={handleReasonCategoryChange}
                   >
                     {userBundleOptionMapping.map((item: item) => {

                       return (
                         <MenuItem key={item.category} value={item.category}>
                           {item.category}
                         </MenuItem>
                       );
                     })}
                   </Select>
                 </FormControl>
                 {reasonCategory && showSubCategoryOrNot(reasonCategory as string)}
                 <FormControl fullWidth>
                   <InputLabel id="FutureService">
                     User want chefkart service in future?
                   </InputLabel>
                   <Select
                     labelId="FutureService"
                     id="FutureService"
                     value={isInterested}
                     label="User want chefkart service in future?"
                     onChange={(event: any) => {
                       setIsInterested(event.target.value);
                     }}
                   >
                     {Object.values(UserWantServiceInFuture).map((val) => {
                       return (
                         <MenuItem key={val} value={val}>
                           {val}
                         </MenuItem>
                       );
                     })}
                   </Select>
                 </FormControl>
               </FormControl>
               <TextField
                   fullWidth
                   value={comment}
                   label="Comment"
                   multiline = {true}
                   onChange={(event) => {
                     setComment(event.target.value);
                   }}
                 />
               <Box sx={{width: "100%"}}>
                 {!!refundDetails &&
                   Array.isArray(refundDetails) &&
                   refundDetails?.length > 0 && (
                     <Table>
                       <TableHead>
                         <TableRow>
                           <TableCell>{'Bundle Id'}</TableCell>
                           <TableCell>{'Quoted Net Price'}</TableCell>
                           <TableCell>{'Refund Amount'}</TableCell>
                           <TableCell>{'Cook Allocation Status'}</TableCell>
                           <TableCell>{'Start Date'}</TableCell>
                           <TableCell>{'Comment'}</TableCell>
                           <TableCell />
                         </TableRow>
                       </TableHead>
                       <TableBody>
                         {refundDetails?.map((bundleDetails, index) => {
                           return (
                               <TableRow key={bundleDetails?.userBundleMapping?.id}>
                                 <TableCell>{bundleDetails?.userBundleMapping?.bundle?.id}</TableCell>
                                 <TableCell> {bundleDetails?.userBundleMapping?.quotedNetPrice}</TableCell>
                                 <TableCell> {Number(bundleDetails?.refund).toFixed(2)}</TableCell>
                                 <TableCell>
                                   {
                                     bundleDetails?.userBundleMapping
                                         ?.cookAllocationStatus
                                   }
                                 </TableCell>
                                 <TableCell> {bundleDetails?.userBundleMapping?.startDate}</TableCell>
                                 <TableCell> {bundleDetails?.comment || ''}</TableCell>
                                 <TableCell>
                                   <Button variant={'contained'} onClick={() => {
                                     setOpenBreakUp(true);
                                     setSelectedRefund({
                                       ...bundleDetails,
                                       refund: Number(bundleDetails?.refund).toFixed(2),
                                     });
                                     setSelectedIndex(index);
                                   }}>
                                     Show Break Up
                                   </Button>
                                 </TableCell>
                               </TableRow>
                           )}
                         )}
                       </TableBody>
                     </Table>
                   )}
               </Box>
             </Grid>
           )}
         </DialogContent>
         <DialogActions style={{ marginTop: "12px" }}>
           {showSubmitButton && (
             <Button
               variant="contained"
               onClick={() => {
                 if (!endDate || !endSlot || !isInterested || !reasonSubCategory || !comment || !reasonCategory) {
                   setSnackBarOpen(true);
                   return;
                 }
                 let refundData = refundDetails.map((bundleDetails: {refund: string, userBundleMapping: {id: number}, comment?: string}) => {
                   return {
                     reason: bundleDetails?.comment || '',
                     refundAmount: parseFloat(Number(bundleDetails?.refund).toFixed(2)),
                     userBundleMappingId: bundleDetails?.userBundleMapping?.id,
                   };
                 })
                 userBundleService
                   .cancelUserBundle(
                     props.data.id,
                     endDate,
                     endSlot as SlotsEnum,
                     reasonCategory as string,
                     reasonSubCategory as string,
                     comment,
                     true,
                       refundData,
                   )
                   .then(() => {
                     handleClose();
                     refresh();
                   });
               }}
               size={"large"}
             >
               Submit
             </Button>
           )}
           <Button
             variant="contained"
             color={"error"}
             onClick={() => {
               handleClose();
             }}
             size={"large"}
           >
             Close
           </Button>
         </DialogActions>
       </Dialog>
      }
      <Dialog
          onClose={() => {
            setOpenBreakUp(false);
          }}
          open={openBreakUp}
          scroll={"paper"}
          maxWidth="lg"
      >
        <DialogTitle>
          Price Break UP
        </DialogTitle>
        <DialogContent sx={{minWidth: 500}}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography>{'Amount Paid by Customer'}</Typography>
              <Typography>{Number(selectedRefund?.userBundleMapping?.quotedNetPrice) - Number(selectedRefund?.userBundleMapping?.discountAmount)}</Typography>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography>{'Bundle Start date'}</Typography>
              <Typography>{selectedRefund?.userBundleMapping?.startDate}</Typography>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography>{'Bundle End date'}</Typography>
              <Typography>{selectedRefund?.userBundleMapping?.endDate}</Typography>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography>{'Allowed Leaves'}</Typography>
              <Typography>{selectedRefund?.userBundleMapping?.userBundleMeta?.totalLeaves}</Typography>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography>{'Customer Leaves'}</Typography>
              <Typography>{selectedRefund?.userBundleMapping?.userBundleMeta?.takenLeaves}</Typography>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography>{'Number of Chef visits made'}</Typography>
              <Typography>{selectedRefund?.userBundleMapping?.userBundleMeta?.totalVisits}</Typography>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography>{'Amount to be refunded'}</Typography>
              <TextField
                  value={selectedRefund?.refund}
                  onChange={(event) => {
                    setSelectedRefund({
                      ...selectedRefund,
                      refund: event.target.value,
                    })
                  }}
                  type={'number'}
              />
            </Box>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography>{'Comment'}</Typography>
              <TextField
                multiline={true}
                rows={4}
                placeholder={'Add your comments here'}
                value={selectedRefund?.comment || ''}
                onChange={(event) => {
                  setSelectedRefund({
                    ...selectedRefund,
                    comment: event.target.value,
                  })
                }}
              />
            </Box>
        </DialogContent>
        <DialogActions>
          <Button variant={'contained'} color={'error'} onClick={() => setOpenBreakUp(false)}>
            {'Cancel'}
          </Button>
          <Button variant={'contained'} onClick={() => {
            let data = refundDetails;
            data[selectedIndex] = selectedRefund;
            setRefundDetails([...data]);
            setOpenBreakUp(false);

          }}>
            {'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
      <Portal>
        <Snackbar
          open={snackBarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
        >
          <Alert severity="warning">Enter all details</Alert>
        </Snackbar>
      </Portal>
    </div>
  );
};
