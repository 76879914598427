import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { useRefresh } from "react-admin";
import ChefForPartyService from "../../services/ChefForPartyService";

interface InputProps {
  initiatedBy: any;
  open: boolean;
  data: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const chefForPartyService = new ChefForPartyService();

const UpdateCommentInChefForParty = (props: InputProps) => {
  const refresh = useRefresh();
  const [comment, setComment] = useState<string>("");

  const handleClose = () => {
    refresh();
    setComment("");
    props.setOpen(false);
  };

  const handleSubmit = () => {
    let payload = {
      comment: comment,
    };
    if (props.initiatedBy === 1) {
      chefForPartyService.updateAllocationComment(props?.data?.id, payload).then(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Successful",
          type: CustomSnackbarTypes.SUCCESS,
        });
        handleClose();
      });
    } else if (props.initiatedBy === 2) {
      chefForPartyService.updateSupplyComment(props?.data?.id, payload).then(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Successful",
          type: CustomSnackbarTypes.SUCCESS,
        });
        handleClose();
      });
    } else if (props.initiatedBy === 3) {
      chefForPartyService.updateCustomerComment(props?.data?.id, payload).then(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Successful",
          type: CustomSnackbarTypes.SUCCESS,
        });
        handleClose();
      });
    }
  };

  return (
    <Dialog open={props.open} scroll={"paper"}>
      <DialogTitle>Update Comment</DialogTitle>
      <DialogContent>
        <TextField
          style={{ margin: "15px" }}
          label="Enter the Comment"
          value={comment}
          onChange={(event) => {
            setComment(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={"success"}
          onClick={() => {
            handleSubmit();
          }}
          size={"large"}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            props.setOpen(false);
            setComment("");
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateCommentInChefForParty;
