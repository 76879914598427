import { Close } from "@mui/icons-material";
import {
    Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, Portal,
    Snackbar, Stack, TextField
} from "@mui/material";
import * as React from "react";
import UserBundleService from "../../services/BundleService";
import { useAppDispatch } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import VasList from "../Vas/VasList";
import ResourceSelectDialog from "./ResourceSelectDialog";
import TransactionButton from "./TransactionButton";

interface InputProps {
    data: any;
    open: boolean;
    callback: (data: any) => void;
}

export const CreateVasDialog = (props: InputProps) => {
    const dispatch = useAppDispatch();
    let [vasId, setVasId] = React.useState<number | null>();
    let [totalVisits, setTotalVisits] = React.useState<number>(0);
    let [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const userBundleService = new UserBundleService();

    const handleSelectVas = () => {
        dispatch(setCustomSelectOpen({
            open: true
        }));
    }

    let handleClose = () => {
        props.callback({
            vasId,
            totalVisits
        });
        setVasId(null);
        setTotalVisits(0)
    };
    let handleSnackbarClose = () => {
        setSnackBarOpen(false);
    };

    const handleCloseVas = (record: any) => {
        if (record) {
            setVasId(record.id)
        }
    }
    return (
        <div>
            <Dialog
                onClose={handleClose}
                open={props.open}
                scroll={"paper"}
                maxWidth="xl"
            >
                <DialogTitle>Create Vas</DialogTitle>
                <DialogContent>
                    <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
                    <Grid
                        container
                        columns={1}
                        rowSpacing={0.9}
                        style={{ padding: "12px 12px 12px 12px" }}
                    >
                        <FormControl fullWidth>
                            <ResourceSelectDialog
                                name={'Vas'}
                                list={VasList}
                                onClose={handleCloseVas}
                            />
                            <Stack gap={1}>
                                <TransactionButton style={{width: "100%"}} name={vasId ? `Vas ID: ${vasId.toString()}` : "Choose Vas"} onClickHandler={() => handleSelectVas() } />
                            </Stack>
                            <TextField
                                label="Total Visits"
                                id="TotalVisits"
                                value={totalVisits}
                                defaultValue={0}
                                style={{ marginTop: "30px" }}
                                type="number"
                                onChange={(event) => {
                                    setTotalVisits(parseInt(event.target.value))
                                }}
                            />
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ marginTop: "12px" }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (!totalVisits) {
                                setSnackBarOpen(true);
                                return;
                            }
                            userBundleService
                                .createBundleVas(props.data.id, {
                                    vasId,
                                    totalVisits
                                })
                                .then(() => {
                                    handleClose();
                                });
                        }}
                        size={"large"}
                    >
                        Submit
                    </Button>
                    <Button
                        variant="contained"
                        color={"error"}
                        onClick={() => {
                            handleClose();
                        }}
                        size={"large"}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Portal>
                <Snackbar
                    open={snackBarOpen}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                >
                    <Alert severity="warning">Fill all the information to proceed</Alert>
                </Snackbar>
            </Portal>
        </div>
    );
};
