import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Link,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {CookService} from "../../services/CookServices";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";
import AreaService from "../../services/AreaService";
import {SlcServiceCookMappingEnum} from "../../enums/SlcServiceCookMappingEnum";
import {setCustomSelectOpen} from "../../store/reducers/customSelect";
import {useAppDispatch} from "../../store/hooks";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import AreaList from "../Area/AreaList";

type CookSLCProps = {
    cookId: number
}
const cookService = new CookService();
const areaService = new AreaService();

const CookSLC = (props: CookSLCProps) => {

    const dispatch = useAppDispatch();

    let [slots, setSlots] = React.useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);


    const [openMappingTypeDialog, setOpenMappingTypeDialog] = useState<boolean>(false);
    const [mappingType, setMappingType] = useState<SlcServiceCookMappingEnum | undefined>(undefined);

    let [allServices, setAllServices] = useState<any>([]);
    const [selectedService, setSelectedService] = useState<string>('');

    const [openAreaList, setOpenAreaList] = useState(false);

    const handleSelectArea = () => {
        setOpenAreaList(true);
        // setSelectedList(data);
        dispatch(
            setCustomSelectOpen({
                open: true,
            })
        );
    };

    const handleClose = (recordData: any) => {
        setOpenAreaList(false);

        if (!mappingType) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Select Slc mapping type",
                type: CustomSnackbarTypes.ERROR,
            });
        }
        if (recordData && mappingType) {
            areaService
                .addCooksToSlc({
                    referenceId: recordData?.id,
                    referenceModelName: 'AREA',
                    serviceId: Number(selectedService),
                    cookId: props?.cookId,
                    mappingType: mappingType,
                })
                .then(() => {
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: "Added Successfully",
                        type: CustomSnackbarTypes.SUCCESS,
                    });
                    setOpenMappingTypeDialog(false);
                    getCookSlots();
                });
        }
        else {
            setOpenMappingTypeDialog(false);
        }
    };

    const getCookSlots = useCallback(() => {
        setLoading(true);
        cookService.getAllDailyCooksSlc(1000, 0, {cookId: props?.cookId}).then((res: any) => {
            setLoading(false);
            setSlots([...res?.data]);
        })
    }, [props?.cookId]);

    useEffect(() => {
        if(!props?.cookId) return;
        getCookSlots();
    }, [getCookSlots, props?.cookId]);


    useEffect(() => {
        if(!openMappingTypeDialog) return;
        areaService.getAllServices().then((res) => {
            setAllServices(res);
        });
    }, [openMappingTypeDialog]);

    return (
        <Box width={'100%'} mt={4}>
            {openAreaList && (
                <ResourceSelectDialog
                    name={"Areas"}
                    list={AreaList}
                    onClose={handleClose}
                />
            )}
            <TableContainer>
                <Box display={'flex'} justifyContent={'flex-end'} mb={2}>
                    <Button
                        color={"primary"}
                        variant={"contained"}
                        onClick={() => {
                            setOpenMappingTypeDialog(true);
                            setMappingType(undefined);
                            setSelectedService('');
                        }}
                    >
                        {"Add SLC"}
                    </Button>
                </Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell align={'center'}>SLC Name</TableCell>
                            <TableCell align={'center'}>Reference Model Name</TableCell>
                            <TableCell align={'center'}>Service Name</TableCell>
                            <TableCell align={'center'}>Mapping Type</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    {slots?.length > 0 ?
                        slots?.map((each: any)=> {
                            return(
                                <TableBody key={each?.id}>
                                    <TableRow>
                                        <TableCell>
                                            {each?.id}
                                        </TableCell>
                                        <TableCell align={'center'}>
                                            {each?.referenceModelName === 'AREA' ? (
                                                <Link href={`/#/Areas/${each?.referenceId}/show`}>
                                                    <Typography>
                                                        {each?.slcName}
                                                    </Typography>
                                                </Link>
                                            ) : (
                                                <Typography>
                                                    {each?.slcName}
                                                </Typography>
                                            )}

                                        </TableCell>
                                        <TableCell align={'center'}>
                                            {each?.referenceModelName}
                                        </TableCell>
                                        <TableCell align={'center'}>
                                            {each?.serviceName}
                                        </TableCell>
                                        <TableCell align={'center'}>
                                            {each?.mappingType}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                size={"small"}
                                                color={"error"}
                                                variant={"contained"}
                                                onClick={() => {
                                                    EventBus.emitEvent(
                                                        EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG,
                                                        {
                                                            title: "Are you sure?",
                                                            message: "Please confirm to remove the cook",
                                                            successLabel: "Confirm",
                                                            failureLabel: "Cancel",
                                                            onClose: (confirmed: boolean) => {
                                                                if (confirmed) {
                                                                    areaService
                                                                        .removeSlcCooks({
                                                                            referenceId:
                                                                            each?.referenceId,
                                                                            referenceModelName:
                                                                            each?.referenceModelName,
                                                                            serviceId: each?.serviceId,
                                                                            cookId: each?.cookId,
                                                                        })
                                                                        .then(() => {
                                                                            EventBus.emitEvent(
                                                                                EventBusEventsEnum.SNACKBAR_SHOW,
                                                                                {
                                                                                    message: "Removed Successfully",
                                                                                    type: CustomSnackbarTypes.SUCCESS,
                                                                                }
                                                                            );
                                                                            getCookSlots();
                                                                        });
                                                                }
                                                            },
                                                        }
                                                    );
                                                }}
                                            >
                                                {"Remove"}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )
                        })
                        : (loading ?
                                ''
                                : <caption>No SLC found</caption>
                        )}
                </Table>
            </TableContainer>
            <Dialog open={openMappingTypeDialog} onClose={() => { setOpenMappingTypeDialog(false) }}>
                <DialogTitle>Select the Following</DialogTitle>
                <DialogContent sx={{width: 400}}>
                    <FormControl fullWidth>
                        <InputLabel id="mapping-type-label">Select Mapping Type</InputLabel>
                        <Select
                            labelId="mapping-type-label"
                            id="mapping-type"
                            value={mappingType}
                            label="Mapping Type"
                            onChange={(event: SelectChangeEvent) => {
                                setMappingType(event?.target?.value as SlcServiceCookMappingEnum)
                            }}
                        >
                            {Object.values(SlcServiceCookMappingEnum).map((value) => (
                                <MenuItem value={value}>{value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Select Service Type</InputLabel>
                        <Select
                            value={selectedService}
                            label="Select Service Type"
                            onChange={(event: SelectChangeEvent) => {
                                setSelectedService(event?.target?.value)
                            }}
                        >
                            {allServices?.map((eachService: {id: number; name: string}) => {
                                return(
                                    <MenuItem value={eachService?.id}>{eachService?.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={'contained'}
                        onClick={() => {
                            if(!mappingType) {
                                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                    message: "Select Mapping Type",
                                    type: CustomSnackbarTypes.ERROR,
                                });
                                return;
                            }
                            if(!selectedService) {
                                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                    message: "Select Service Type",
                                    type: CustomSnackbarTypes.ERROR,
                                });
                                return;
                            }
                            handleSelectArea();
                        }}
                    >
                        {'Continue'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
export default CookSLC;

