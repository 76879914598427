import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import * as React from "react";
import {useEffect} from "react";
import {CookService} from "../../services/CookServices";
interface InputProps {
  data?: {
    message: string;
    isAvailable: boolean;
    referenceModelName?: 'COOK_LEAVE' | 'TRIALS_V3' | 'COOK_SUBSCRIPTION_V3' | 'ONE_MEAL_SERVICE';
    referenceId?: number;
  };
  open: boolean;
  onClose: () => void;
}

const cookService = new CookService();

export const ErrorDialog = (props: InputProps) => {
  const [redirectUrl, setRedirectUrl] = React.useState('');
  useEffect(() => {
    if(!props?.data) return;
    setRedirectUrl('/#/vendorPayoutEventTable');

    if(props?.data?.referenceModelName === 'ONE_MEAL_SERVICE'){
      setRedirectUrl(`/#/OneMealService/${props?.data?.referenceId}/show`);
    } else if(props?.data?.referenceModelName === 'TRIALS_V3'){
      setRedirectUrl(`/#/Trials/${props?.data?.referenceId}/show`);
    } else if(props?.data?.referenceModelName === 'COOK_SUBSCRIPTION_V3' && props?.data?.referenceId) {
      cookService.getSubscriptionForACook(props?.data?.referenceId).then((data: any) => {
        setRedirectUrl(`/#/UserBundles/${data?.userBundleMappingId}/show/7`);
      })
    } else if(props?.data?.referenceModelName === 'COOK_LEAVE' && props?.data?.referenceId) {
      setRedirectUrl(`/#/CookLeaves/${props?.data?.referenceId}/show`);
    }
  }, [props?.data])
  return (
    <>
      <Dialog open={props?.open} onClose={props?.onClose}>
        <DialogContent>
          <p>{props?.data?.message}</p>
        </DialogContent>
        <DialogActions>
          {props?.data?.referenceId && props?.data?.referenceModelName && (
              <Button
                  variant="contained"
                  color={"primary"}
                  onClick={() => {
                    window.open(redirectUrl, '_blank');
                  }}
              >
                View
              </Button>
          )}
          <Button
              variant="contained"
              color={"error"}
              onClick={props?.onClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
