import React, { useState} from 'react';
import {
    ArrayField, Datagrid, DateField, FunctionField, List, NumberField, SelectInput, TextField, useRecordContext,
} from 'react-admin';
import { ResolutionEnum } from "../../enums/ResolutionEnum";
import { ZohoBaseUrl, ZohoModulesUrl } from '../../enums/zoho';
import LinkField from '../Common/LinkField';
import TransactionButton from '../Common/TransactionButton';
import CustomReferenceField from '../Common/CustomReferenceField';
import CancelCustomerLeave from '../Common/CancelCustomerLeave';
import { UserType } from '../../enums/common';
import CallButton from '../Common/CallButton/CallButton';

const CancelButtonWrapper = (props: any) => {
    const record: any = useRecordContext();
    return (
        <TransactionButton
            name={"Cancel"}
            color={'error'}
            onClickHandler={() => {
                props.setData(record);
                props.setOpenDialog(true);
            }}
        />
    )
}

const filters = [
    <SelectInput
        label="Resolution"
        source="resolution"
        alwaysOn
        emptyText="Clear Filter"
        choices={
            Object.values(ResolutionEnum)
                .map(key => {
                    return {
                        id: key,
                        name: key
                    }
                })
        }
    />,
    <SelectInput
        label="Cook Allotted"
        source="allotted"
        alwaysOn
        emptyText="Clear Filter"
        choices={[
            {
                id: 'Allotted',
                name: 'Allotted'
            },
            {
                id: 'Not Allotted',
                name: 'Not Allotted'
            }
        ]}
    />
];

const CustomerLeaves = (props: any) => {
    const [data, setData] = useState();
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    return (
        <div>
            <CancelCustomerLeave open={openDialog} setOpen={setOpenDialog} data={data}/>
            <List {...props} filters={filters}>
                <Datagrid bulkActionButtons={false}>
                    <NumberField source="id" />
                    <ArrayField
                        label="User Name"
                        source="userBundleMapping.house.customers"
                        sortable={false}
                    >
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="fname" label={''}/>
                        </Datagrid>
                    </ArrayField>
                    <ArrayField
                        label="User Mobile"
                        source="userBundleMapping.house.customers"
                        sortable={false}
                    >
                        <Datagrid bulkActionButtons={false}>
                            <FunctionField render={(record: any) => <CallButton toPhoneNumber={record?.mobile} userType={UserType.CUSTOMER}/>}/>
                        </Datagrid>
                    </ArrayField>
                    <CustomReferenceField label={"Bundle Id"} listName={"UserBundles"} source={"userBundleMapping.id"} sortable={false}/>

                    <DateField
                        options={{ day: 'numeric', month: 'short', year: 'numeric' }}
                        sortable={false}
                        label={"Bundle Start Date"}
                        source="userBundleMapping.startDate"
                    />
                    <DateField
                        options={{ day: 'numeric', month: 'short', year: 'numeric' }}
                        sortable={false}
                        label={"Bundle End Date"}
                        source="userBundleMapping.endDate"
                    />
                    <DateField
                        options={{ day: 'numeric', month: 'short', year: 'numeric' }}
                        label={"Leave Date"}
                        source="date"
                        sortable={false}
                    />
                    <TextField
                        label={"Leave slot"}
                        source="slot"
                        sortable={false}
                    />
                    <NumberField
                        sortable={false}
                        label={"Allowed Leaves"}
                        source="userBundleMapping.userBundleMeta.totalLeaves"
                    />
                    <NumberField
                        sortable={false}
                        label={"Leaves Taken"}
                        source="userBundleMapping.userBundleMeta.takenLeaves"
                    />
                    <TextField
                        label={"Status"}
                        source="userBundleMapping.status"
                        sortable={false}
                    />
                    <TextField
                        source="leaveType"
                        sortable={false}
                    />
                    <TextField
                        source="resolution"
                        sortable={false}
                    />
                    <LinkField source='zohoId' href={`${ZohoBaseUrl}${ZohoModulesUrl.LEAVES}`} sourceRoute targetBlank />
                    <CancelButtonWrapper
                        setData={setData}
                        setOpenDialog={setOpenDialog}
                        sortable={false}
                    />
                </Datagrid>
            </List>
        </div>
    );
};

export default CustomerLeaves;
