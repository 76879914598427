import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { ZohoBaseUrl, ZohoModulesUrl } from "../../enums/zoho";

const TemporaryReplacementAllocationMetaShow = (props: any) => {
  
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Id</TableCell>
              <TableCell align="center">Cook Id</TableCell>
              <TableCell align="center">Cook Name</TableCell>
              <TableCell align="center">Mobile Number</TableCell>
              <TableCell align="center">Cook Category</TableCell>
              <TableCell align="center">Travel</TableCell>
              <TableCell align="center">Zoho</TableCell>
              <TableCell align="center">Locality Id</TableCell>
              <TableCell align="center">Locality Name</TableCell>
              <TableCell align="center">Area Id</TableCell>
              <TableCell align="center">Area Id</TableCell>
              <TableCell align="center">City Id</TableCell>
              <TableCell align="center">City Id</TableCell>
              <TableCell align="center">Rent Paying Capacity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.data?.map((value: any) => (
              <TableRow
                key={value.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{value?.id}</TableCell>
                <TableCell align="center">
                    <Link href={`/#/Cooks/${value?.cookId}/show`}>
                    {value?.cookId}
                    </Link>
                  </TableCell>
                <TableCell align="center">
                  {value?.cookDetails?.fname}
                </TableCell>
                <TableCell align="center">
                  {value?.cookDetails?.mobile}
                </TableCell>
                <TableCell align="center">
                  {value?.cookDetails?.category}
                </TableCell>
                <TableCell align="center">
                  {value?.cookDetails?.vehicle}
                </TableCell>
                <TableCell align="center">
                   <Link href={`${ZohoBaseUrl}${ZohoModulesUrl.COOKS}/${value?.cookDetails?.zohoId}`} target="_blank">
                    {value?.cookDetails?.zohoCookId}
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    <Link href={`/#/localities/${value?.localityDetails?.id}/show`}>
                    {value?.localityDetails?.id}
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                  {value?.localityDetails?.name}
                </TableCell>
                  <TableCell align="center">
                    <Link href={`/#/Areas/${value?.localityDetails?.areaId}/show`}>
                    {value?.localityDetails?.areaId}
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                  {value?.localityDetails?.areaName}
                </TableCell>
                  <TableCell align="center">
                    <Link href={`/#/cities/${value?.localityDetails?.cityId}/show`}>
                    {value?.localityDetails?.cityId}
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                  {value?.localityDetails?.cityName}
                </TableCell>
                <TableCell align="center">
                  {value?.localityDetails?.rentPayingCapacity}
                </TableCell>
                
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TemporaryReplacementAllocationMetaShow;
