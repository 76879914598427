import {
  Box,
  Button, Checkbox,
  FormControl, FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useState } from "react";
import { Create} from "react-admin";
import { BundleGenderPreference } from "../../enums/common";
import { ShortTimeEnum } from "../../enums/ShortTimeEnum";
import { OneMealService } from "../../services/OneMealService";
import CommonDialogForUserAndHouse from "../Common/CommonDialogUserAndHouse";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import HouseList from "../Houses/HouseList";
import TransactionButton from "../Common/TransactionButton";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import { useAppDispatch } from "../../store/hooks";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import { useNavigate } from "react-router-dom";
import CouponsSelectDialog from "../Common/CouponsSelectDialog";
import CircularLoader from "../Common/CircularLoader";

const oneMealService = new OneMealService();

const OneMealServiceCreate = () => {
  const [time, setTime] = useState<string>("");
  const [totalPeople, setTotalPeople] = useState<number>(0);
  const [date, setDate] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null)
  const [open, setOpen] = useState(false);
  const [hasOptedPayAfterService, setHasOptedPayAfterService] = useState(false);
  const [houseId, setHouseId] = useState<any>(null);
  const [genderPreference, setGenderPreference] = useState<string>("");
  const [openHouseDialog, setOpenHouseDialog] = useState(false);
  const [openCouponDialog, setOpenCouponDialog] = useState(false);
  const [coupon, setCoupon] = useState<any>(null)
  const [loadingOnSubmit, setLoadingOnSubmit] = useState(false);
  const [days,setDays] = useState("single");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  const handleSelectHouse = () => {
    setOpenHouseDialog(true);
    dispatch(
      setCustomSelectOpen({
        open: true,
      })
    );
  };
  const handleSelectCoupon = () => {
    setOpenCouponDialog(true);
  }

  const handleOpen = (value:boolean) => {
    if(value){
     handleSelectHouse();
    }
    else{
      handleSelectCoupon();
    }
  };

  const handleCloseHouse = (record: any) => {
    setOpenHouseDialog(false);
    setHouseId(record?.id);
  };

  async function createOneMealService(){
    if(coupon){
      const value = await oneMealService.getOmsPrice({houseId,time,totalPeople,date: moment(date).format("YYYY-MM-DD")});
      if(value?.price>Number(coupon?.maximumDiscountAmount)){
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Coupon maximum discount amount exceeded",
          type: CustomSnackbarTypes.ERROR,
        });
        return;
      }
    }
    oneMealService
        .createOneMealService({
          houseId,
          genderPreference,
          totalPeople,
          time,
          date:moment(date).format("YYYY-MM-DD"),
          hasOptedPayAfterService,
        })
        .then((data) => {
          if(!coupon){
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
              message: "Success",
              type: CustomSnackbarTypes.SUCCESS,
            });
            if (data?.id) {
              navigate(`/OneMealService/${data?.id}/show`, { replace: true });
            }
          }
          else{
            oneMealService.createRazaorPayOrder(data?.id,coupon?.id).then((order)=>{
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Success",
                type: CustomSnackbarTypes.SUCCESS,
              });
              if (data?.id) {
                navigate(`/OneMealService/${data?.id}/show`, { replace: true });
              }
            }).catch((err)=>{
              console.log(err);
            })
          }
    });
  }
  const getDateDifference = () => {
    if (startDate && endDate) {
      const start = moment(startDate, "YYYY-MM-DD");
      const end = moment(endDate, "YYYY-MM-DD");
      return end.diff(start, 'days')+1; // Difference in days
    }
    return 0;
  };

  async function createMultipleOneMealService() {
    if(!coupon){
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: `Cannot Create Multiple Visits Without Coupons`,
        type: CustomSnackbarTypes.ERROR,
      });
      return;
    }
    setLoadingOnSubmit(true);
    const value = await oneMealService.getOmsPrice({houseId,time,totalPeople,date: moment(date).format("YYYY-MM-DD")});
    if(value?.price>Number(coupon?.maximumDiscountAmount)){
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Coupon maximum discount amount exceeded",
        type: CustomSnackbarTypes.ERROR,
      });
      setLoadingOnSubmit(false);
      return;
    }  
    const totalDays = getDateDifference();
    const dates = [];
    for (let i = 0; i < totalDays; i++) {
      dates.push(moment(startDate).add(i, 'days').format("YYYY-MM-DD"));
    }
    let countSuccess = 0;
    let countFailure = 0;

    for await (let date of dates){
      try {
        const value = await oneMealService.createOneMealService({
          houseId,
          genderPreference,
          totalPeople,
          time,
          date,
        });
        if(coupon){
          await oneMealService.createRazaorPayOrder(value?.id,coupon?.id);
          countSuccess++;
        }
        else countSuccess++;
      } catch (error) {
        countFailure++;
      }
    }
    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
      message: `Successfully created ${countSuccess} One Meal Service(s) and failed to create ${countFailure} One Meal Service(s)`,
      type: CustomSnackbarTypes.SUCCESS,
    });
    setLoadingOnSubmit(false);
    if (countSuccess > 0) {
      navigate(`/OneMealService`, { replace: true });
    }
  
  }

  const handleSubmit = async() => {
    if(days==="single"){
      createOneMealService();
    }
    if(days==="multiple"){
      if(!startDate||!endDate){
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Please Enter Start date and End date",
          type: CustomSnackbarTypes.ERROR,
        });
        return;
      }
      createMultipleOneMealService();
    }
  };

  return (
    <Create title={"Create One Meal Service"}>
      {
        !loadingOnSubmit?<Grid
        container
        columns={1}
        rowSpacing={2}
        style={{ padding: "12px 12px 12px 12px" }}
      >
        {
          houseId&&
          <CouponsSelectDialog open={openCouponDialog} houseId={houseId} callback={()=>setOpenCouponDialog(false)} setCoupon={setCoupon}/>
        }
        <Grid item xs={4}>
          <CommonDialogForUserAndHouse
            open={open}
            setOpen={setOpen}
            setHouse={setHouseId}
          />
          {openHouseDialog && (
            <ResourceSelectDialog
              name={"Houses"}
              list={HouseList}
              onClose={handleCloseHouse}
            />
          )}
          <TransactionButton
            name={"Create customer and House"}
            onClickHandler={() => setOpen(true)}
            style={{ marginLeft: isSmall ? "0" : "75%" }}
          />
        </Grid>
        <Grid item xs={4}>
          <Box display={"flex"} gap={"1rem"}>
            <TransactionButton
              name={houseId ? `house Id: ${houseId}` : "Choose House"}
              onClickHandler={() => handleOpen(true)}
            />
            {
              houseId&&
              <TransactionButton
                name={coupon ? `coupon Id: ${coupon?.couponCode}` : "Choose Coupon"}
                onClickHandler={() => handleOpen(false)}
              />
            }
          </Box>
        </Grid>
        <Grid item xs={4}>
          {
            !coupon?<Box>No Coupon Selected ...</Box>:
            <TableContainer component={Paper}>
              <Table aria-label="coupon table">
                  <TableHead>
                  <TableRow>
                      <TableCell>Coupon Code</TableCell>
                      <TableCell>Coupon Type</TableCell>
                      <TableCell>Coupon Value</TableCell>
                      <TableCell>Maximum Amount</TableCell>
                      <TableCell>Is Active</TableCell>
                      <TableCell>Expiry Time</TableCell>
                      <TableCell>Total Time Used</TableCell>
                      <TableCell>Usage Limit</TableCell>
                  </TableRow>
                  </TableHead>
                  <TableBody>
                      <TableRow
                      key={coupon?.couponCode}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                          <TableCell scope="row">{coupon?.couponCode}</TableCell>
                          <TableCell align="left">{coupon?.couponType}</TableCell>
                          <TableCell align="left">{coupon?.couponValue}</TableCell>
                          <TableCell align="left">{coupon?.maximumDiscountAmount}</TableCell>
                          <TableCell align="left">{coupon?.isActive?"YES":"NO"}</TableCell>
                          <TableCell align="left">{moment(coupon?.expiresAt).format('DD-MM-YYYY hh:mm A')}</TableCell>
                          <TableCell align="left">{coupon?.totalTimesUsed}</TableCell>
                          <TableCell align="left">{coupon?.maximumUsageLimit}</TableCell>
                      </TableRow>
                  </TableBody>
              </Table>
          </TableContainer>
        }
        </Grid>
        <Grid item xs={4}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">No. of Days</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            value={days}
            name="radio-buttons-group"
            onChange={(e)=>{
              setDays(e.target.value)
            }}
          >
            <FormControlLabel value={"single"} control={<Radio />} label="Single" />
            <FormControlLabel value={"multiple"} control={<Radio />} label="Multiple" />
          </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
        {
          days==="single"?
          <FormControl style={{ width: "230px" }}>
            <DatePicker
              label="Date"
              inputFormat="dd MMM yyyy"
              disableMaskedInput
              minDate={new Date()}
              value={date}
              onChange={(value: any) => {
                setDate(moment(value).format("YYYY-MM-DD"));
              }}
              renderInput={(params: any) => <TextField {...params} />}
            />
        </FormControl>:
        <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} gap={"1rem"}>
            <FormControl style={{ width: "230px" }}>
              <DatePicker
                label="Start Date"
                inputFormat="dd MMM yyyy"
                disableMaskedInput
                minDate={new Date()}
                value={startDate}
                onChange={(value: any) => {
                  setStartDate(moment(value).format("YYYY-MM-DD"));
                }}
                renderInput={(params: any) => <TextField {...params} />}
              />
          </FormControl>
          <FormControl style={{ width: "230px" }}>
              <DatePicker
                label="End Date"
                inputFormat="dd MMM yyyy"
                disableMaskedInput
                minDate={new Date()}
                value={endDate}
                onChange={(value: any) => {
                  setEndDate(moment(value).format("YYYY-MM-DD"));
                }}
                renderInput={(params: any) => <TextField {...params} />}
              />
          </FormControl>  
        </Box>
        }
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <InputLabel id="forGenderPreference">Gender Preference</InputLabel>
            <Select
              labelId="forGenderPreference"
              id="genderPreference"
              value={genderPreference}
              label="genderPreference"
              onChange={(event: any) => {
                setGenderPreference(event.target.value);
              }}
            >
              {Object.values(BundleGenderPreference).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <InputLabel id="forTotalPeople">Total People</InputLabel>
            <Select
              labelId="forTotalPeople"
              id="totalPeople"
              value={totalPeople}
              label="total People"
              onChange={(event: any) => {
                setTotalPeople(event.target.value);
              }}
            >
              {Object.values(Array.from({ length: 40 }, (_, i) => i + 1)).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <InputLabel id="forTime">One Meal Time</InputLabel>
            <Select
              labelId="forTime"
              id="time"
              value={time}
              label="One Meal Time"
              onChange={(event: any) => {
                setTime(event.target.value);
              }}
            >
              {Object.values(ShortTimeEnum).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {
          days!=='multiple'&&<Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={hasOptedPayAfterService}
                onChange={(e) => {
                  setHasOptedPayAfterService(e.target.checked);
                }}
                name="Pay After Service"
              />
            }
            label="Pay After Service"
          />
        </Grid>
        }
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
            size={"large"}
          >
            Submit
          </Button>
        </Grid>
      </Grid>:
      <CircularLoader/> 
      }
    </Create>
  );
};

export default OneMealServiceCreate;
