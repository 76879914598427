import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField
} from "@mui/material";
import { useCallback, useEffect, useState } from 'react';
import EventBus from "../../EventBus";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import CancellationReasonsCategories from "../../enums/CancellationReasonsCategories";
import {getReasonCategory } from "./constants/constant";
import ComboService from "../../services/ComboService";

interface Props {
  data: any;
  open: boolean;
  callback: () => void;
}

const comboService = new ComboService()

const ComboRefundDialog = (props: Props) => {
  const [cancelTrialReasonOptionMapping,setCancelTrialReasonOptionMapping]=useState<any>([]);
  const [reasonSubCategoryArray, setReasonSubCategoryArray] = useState<string[] | null>(null);
  const [reasonCategory, setReasonCategory] = useState<CancellationReasonsCategories | null>(null);
  const [reasonSubCategory, setReasonSubCategory] = useState<string | null>(null);

  const loadData = useCallback(async () => {
    let sampleReasonSubReason1:any = await getReasonCategory('DISCOUNT_COUPON_ORDER','DISCOUNT_COUPON_ORDER_REFUND');
    setCancelTrialReasonOptionMapping(sampleReasonSubReason1);
  }, []);

  useEffect(()=>{
    loadData();
  },[loadData])

  let handleClose = () => {
    setReasonSubCategoryArray(null)
    setReasonCategory(null)
    setReasonSubCategory(null)
    props.callback();
  };

  const handleReasonCategoryChange = (event: any) => {
    const selectedCategory:any = cancelTrialReasonOptionMapping.find(
      (option:any) => option.category === event.target.value
    );

    if (selectedCategory) {
      setReasonSubCategoryArray(selectedCategory?.subCategory);
      setReasonCategory(event.target.value);
    }
  }

  const handleReasonSubCategoryChange = (event: any) => {
    setReasonSubCategory(event.target.value)
  }

  function showSubCategoryOrNot(reasonCategory: string) {
    if (reasonCategory === CancellationReasonsCategories.OTHERS) {
      return (
        <FormControl style={{ width: 500 }}>
          <TextField value={reasonSubCategory} onChange={handleReasonSubCategoryChange} label="Write a reason" />
        </FormControl>
      )
    } else {
      return (
        <FormControl style={{ width: 500 }}>
          <InputLabel id="select-reason">Select a Subreason</InputLabel>
          <Select
            labelId="select-reason"
            id="select-reason"
            defaultValue="Select a reason"
            value={reasonSubCategory}
            onChange={handleReasonSubCategoryChange}
          >
            {reasonSubCategoryArray?.map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      scroll={"paper"}
      maxWidth="xl"
    >
      <DialogTitle>{"Refund Combo"}</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          justifyContent={"space-evenly"}
          style={{ padding: "12px 12px 12px 12px" }}
          direction="column"
          alignItems="center"
        >
          <Grid item xs={12}>
            <FormControl style={{ width: 500 }}>
              <InputLabel id="select-label">Select a reason</InputLabel>
              <Select
                labelId="select-label"
                id="select-label"
                defaultValue="Tell us Why?"
                value={reasonCategory}
                onChange={handleReasonCategoryChange}
              >
                {cancelTrialReasonOptionMapping.map((item:any) => {
                    return (
                      <MenuItem key={item.category} value={item.category}>
                        {item.category}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {
              reasonCategory && showSubCategoryOrNot(reasonCategory!)
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginTop: "12px" }}>
        <Button
          variant="contained"
          onClick={() => {
            if (!reasonSubCategory || !reasonCategory) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Fill the details to proceed",
                type: CustomSnackbarTypes.WARNING
              });
              return;
            }
            if (reasonCategory !== CancellationReasonsCategories.PRICING_MISMATCH) {
              if (!reasonSubCategory || !reasonCategory) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Fill the details to proceed",
                  type: CustomSnackbarTypes.WARNING
                });
                return;
              }
            }
            if (reasonCategory === CancellationReasonsCategories.PRICING_MISMATCH) {
              if (!reasonCategory) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Fill the details to proceed",
                  type: CustomSnackbarTypes.WARNING
                });
                return;
              }
            }
            comboService.refundCombo(props?.data?.id, {
              reason:reasonCategory,
              subReason: reasonSubCategory
            }).then(() => {
              handleClose();
            });
          }}
          size={"large"}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            handleClose();
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ComboRefundDialog;
