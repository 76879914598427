import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { TrialsService } from "../../services/TrialServices";
import { useLocation } from "react-router-dom";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { useRefresh } from "react-admin";
import { OneMealService } from "../../services/OneMealService";

interface InputProps {
  initiatedBy: any;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const trialsService = new TrialsService();
const oneMealService = new OneMealService();

const UpdateCommentInTrialDialog = (props: InputProps) => {
  const refresh = useRefresh();
  let location = useLocation();
  let trialId = location.pathname.split("/")[2];
  let oneMealServiceId = location.pathname.split("/")[2];
  const [comment, setComment] = useState<string>("");

  const handleSubmit = () => {
    let payload = {
      commentOrFeedback: comment,
    };
    if (props.initiatedBy === 1) {
      trialsService.addSupportComments(Number(trialId), payload).then(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Successful",
          type: CustomSnackbarTypes.SUCCESS,
        });
        refresh();
        setComment("");
        props.setOpen(false);
      });
    } 
    else if (props.initiatedBy === 4) {
      trialsService.addAllocationNotes(Number(trialId), payload).then(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Successful",
          type: CustomSnackbarTypes.SUCCESS,
        });
        refresh();
        setComment("");
        props.setOpen(false);
      });
    }
     else if (props.initiatedBy === 2) {
      trialsService.addCxFeedback(Number(trialId), payload).then(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Successful",
          type: CustomSnackbarTypes.SUCCESS,
        });
        refresh();
        setComment("");
        props.setOpen(false);
      });
    } else if (props.initiatedBy === 3) {
      trialsService.addHcFeedback(Number(trialId), payload).then(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Successful",
          type: CustomSnackbarTypes.SUCCESS,
        });
        refresh();
        setComment("");
        props.setOpen(false);
      });
    } else if (
      props.initiatedBy === 5
    ) {
      oneMealService
        .addSupportCommentsForOneMeal(Number(oneMealServiceId), payload)
        .then(() => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Successful",
            type: CustomSnackbarTypes.SUCCESS,
          });
          refresh();
          setComment("");
          props.setOpen(false);
        });
    } else if (
      props.initiatedBy === 8
    ) {
      oneMealService
        .addAllocationNotesForOneMeal(Number(oneMealServiceId), payload)
        .then(() => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Successful",
            type: CustomSnackbarTypes.SUCCESS,
          });
          refresh();
          setComment("");
          props.setOpen(false);
        });
    } else if (props.initiatedBy === 6) {
      oneMealService
        .addCxFeedbackForOneMeal(Number(oneMealServiceId), payload)
        .then(() => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Successful",
            type: CustomSnackbarTypes.SUCCESS,
          });
          refresh();
          setComment("");
          props.setOpen(false);
        });
    } else if (
      props.initiatedBy === 7
    ) {
      oneMealService
        .addHcFeedbackForOneMeal(Number(oneMealServiceId), payload)
        .then(() => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Successful",
            type: CustomSnackbarTypes.SUCCESS,
          });
          refresh();
          setComment("");
          props.setOpen(false);
        });
    }
  };

  return (
    <Dialog open={props.open} scroll={"paper"}>
      <DialogTitle>Update Comment</DialogTitle>
      <DialogContent>
        <TextField
          style={{ margin: "15px" }}
          label="Enter the Comment"
          value={comment}
          onChange={(event) => {
            setComment(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={"success"}
          onClick={() => {
            handleSubmit();
          }}
          size={"large"}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            props.setOpen(false);
            setComment("");
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateCommentInTrialDialog;
