import React from "react";
import { BooleanField, Datagrid, DateField, List, ReferenceField, ShowButton, TextField, TextInput } from "react-admin";
import { useAppSelector } from "../../store/hooks";
import GoogleMapsField from "../Common/GoogleMapsField";
import ResourceSelectDialogButton from "../Common/ResourceSelectDialogButton";

const filters =  [
    <TextInput label="Search by area Name" source="areaName" alwaysOn />,
    <TextInput label="Search by city name" source="cityName" alwaysOn />,
    <TextInput label="Search by locality name" source="name" alwaysOn />,
]
interface LocalityListProps{
  isMultiSelect?:boolean;
}
const LocalityList = (props:LocalityListProps) => {
    const isCustomSelectOpen = useAppSelector((state) => state.customSelect.value.open);
    const checkMultiSelect = ():React.ReactElement | false => {
      return props.isMultiSelect?<></>:false
    }
    return (
      <>
        <List disableSyncWithLocation={props?.isMultiSelect ?? false} filters={filters}>
            <Datagrid bulkActionButtons={checkMultiSelect()}>
                {
                    isCustomSelectOpen && (!props?.isMultiSelect ?? true) && 
                    <ResourceSelectDialogButton />
                }
                <TextField source="id" />
                <TextField source="name" />
                <ReferenceField source="areaId" link="show" reference="Areas">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="cityId" link="show" reference="Cities">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="rentPayingCapacity" />
                <GoogleMapsField source="coordinates" />
                <BooleanField source="isServiceable" />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
                <ShowButton />
            </Datagrid>
        </List>
        </>
    )
}

export default LocalityList;
