import RosterEmployeeResponse from "../interfaces/Roster/RosterEmployeeResponse";
import HttpClient from "../HttpClient";

export default class RosterService {
    async getAllEmployeesForRoster(rosterName: string): Promise<RosterEmployeeResponse[]> {
        const data = await HttpClient.get(`/centaurus/roster/${rosterName}`);
        return data?.data;
    }

    async addEmployeesToRoster(payload: {rosterName: string, adminId: number}): Promise<RosterEmployeeResponse> {
        const data = await HttpClient.post(`/centaurus/roster/add`, payload);
        return data?.data;
    }

    async deleteEmployeesForRoster(id: number): Promise<RosterEmployeeResponse> {
        const data = await HttpClient.delete(`/centaurus/roster/${id}`);
        return data?.data;
    }
}
