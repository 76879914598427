import React, { useState } from "react";
import { RenewalsService } from "../../services/RenewalsService";
import { useRefresh } from "react-admin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { CustomerFollowUpEnum } from "../../enums/CustomerFollowUp";
import { RenewalResponse } from "../../interfaces/Renewals/RenewalsResponse";
import { DatePicker } from "@mui/x-date-pickers";

interface InputProps {
  data: RenewalResponse;
  open: boolean;
  callback: () => void;
}

const renewalService = new RenewalsService();

const CustomerFollowUpDialog = (props: InputProps) => {
  const [comment, setComment] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [followUpDate, setFollowUpDate] = useState<any>("");
  let refresh = useRefresh();
  let handleClose = () => {
    props.callback();
    setComment("");
  };
  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      scroll={"paper"}
      maxWidth="xl"
    >
      <DialogTitle>Customer Follow Up</DialogTitle>
      <DialogContent>
        <Grid
          container
          columns={1}
          rowSpacing={0.9}
          style={{ padding: "12px 12px 12px 12px" }}
        >
          <FormControl style={{ width: 500 }} fullWidth>
            <DatePicker
              label="Next Followup Date"
              minDate={new Date()}
              inputFormat="dd MMM yyyy"
              value={followUpDate}
              disableMaskedInput
              onChange={(event) => {
                setFollowUpDate(event);
              }}
              
              renderInput={(params) => {
                return <TextField sx={{mr: "20px"}} {...params} />;
              }}
            />

            <FormControl style={{ marginTop: "20px", width: 500 }} fullWidth>
              <InputLabel id="select-label">Reason</InputLabel>
              <Select
                autoWidth={false}
                className="input_field"
                labelId="select-label"
                id="select-label"
                defaultValue="Reason"
                value={comment}
                onChange={(event) => setComment(event.target.value)}
              >
                {Object.values(CustomerFollowUpEnum).map((val) => {
                  return (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  );
                })}
              </Select>
              {comment === "Other" && (
                <TextField
                  value={reason}
                  label="Comment"
                  onChange={(event) => {
                    setReason(event.target.value);
                  }}
                />
              )}
            </FormControl>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size={"large"}
          variant="contained"
          onClick={async () => {
            if (!comment) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Fill the Comment",
                type: CustomSnackbarTypes.WARNING,
              });
              return;
            }
            let payload = {
              comment: comment + reason,
              nextFollowUpDate: followUpDate,
            };
            await renewalService
              .addCustomerFollowUpForRenewal(props?.data?.id, payload)
              .then(() => {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Success",
                  type: CustomSnackbarTypes.SUCCESS,
                });
                refresh();
                handleClose();
              });
          }}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            handleClose();
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerFollowUpDialog;
