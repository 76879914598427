import { useRecordContext } from "react-admin";
import LinkField from "./LinkField";

interface Props {
    listName: string,
    source: string,
    label?: string,
    sortable?: boolean
}

const CustomReferenceField = (props: Props) => {
    const record = useRecordContext()

    return (
        <LinkField source={props.source} href={`/#/${props.listName}/${record[props.source]}/show`} sourceRoute={false}/>
    )
}
export default CustomReferenceField