import React, {useCallback, useEffect, useState} from 'react';
import {CookService} from "../../services/CookServices";
import {Box, TextField} from "@mui/material";
import moment from "moment";
import {DateTimePicker} from "@mui/x-date-pickers";
import {GoogleMap, Marker, Polyline, useJsApiLoader} from "@react-google-maps/api";
import {useLocation} from "react-router-dom";


const containerStyle = {
    width: '100%',
    height: '80vh'
};

const options1 = {
    fillColor: "lightblue",
    fillOpacity: .5,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
}

const cookService = new CookService();

const CookActivityModule = () => {

    const location = useLocation();
    const cookId = location.pathname.split("/")[2];

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string
    })

    const [fromDate, setFromDate] = React.useState<Date>(new Date(moment().format('YYYY-MM-DD')));
    const [toDate, setToDate] = React.useState<Date>(new Date());

    let [center, setCenter] = useState<any>(null);
    let [coordinates, setCoordinates] = useState<any>(null);

    const loadData = useCallback((cookId: number, fromDate: any, toDate: any) => {
        cookService.getCookActivityCoordinates(cookId, fromDate, toDate)
            .then((data) => {
                setCenter({
                    ...data?.[0]?.lastActiveLocation,
                    lng: data?.[0]?.lastActiveLocation?.long,
                })
                setCoordinates(data);
            })
            .catch((err) => {
            })
    }, []);


    const onUnmount = React.useCallback(function callback() {}, [])

    const onClickHander = (e: any) => {
        // setArea(e)
        // setDailogOpen(true)
    }

    // set trial availability type

    useEffect(() => {
        loadData(Number(cookId), fromDate, toDate);
    }, [cookId, toDate, fromDate, loadData])
    return (
        <div>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} my={2}>
                <DateTimePicker
                    mask=""
                    label="From"
                    inputFormat="dd MMM yyyy hh:mm a"
                    value={fromDate}
                    maxDate={moment(toDate).toDate()}
                    maxTime={moment(toDate).toDate()}
                    onChange={(newValue: any) => {
                        setFromDate(newValue);
                    }}
                    renderInput={(params) => {
                        return <TextField {...params} />;
                    }}
                />
                <Box ml={2} />
                <DateTimePicker
                    mask=""
                    label="To"
                    inputFormat="dd MMM yyyy hh:mm a"
                    value={toDate}
                    onChange={(newValue: any) => {
                        setToDate(newValue);
                    }}
                    renderInput={(params) => {
                        return <TextField {...params} />;
                    }}
                />
            </Box>
            {isLoaded ? (
                <div>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={50}
                        onLoad={() => {
                            loadData(Number(cookId), fromDate, toDate);
                        }}
                        onUnmount={onUnmount}
                    >
                        {/*{allBoundarys.map((e: any) => (*/}
                        {/*  <div key={`${e.name} cityId-${e.cityId}`}>*/}
                        {/*    <Marker position={e.position} label={e.name} icon="." />*/}
                        {/*    <Polygon*/}
                        {/*      options={options1}*/}
                        {/*      paths={}*/}
                        {/*      onClick={() => onClickHander(e)}*/}
                        {/*    />*/}
                        {/*  </div>*/}
                        {/*))}*/}
                        {coordinates?.map((each: any) => <Marker position={{
                            ...each?.lastActiveLocation,
                            lng: each?.lastActiveLocation?.long
                        }} label={moment(each?.createdAt).format('MMM Do YYYY, h:mm a')} title={''}
                        />)}
                        <Polyline
                            options={options1}
                            path={coordinates?.map((each: any) => {
                                return {
                                    ...each?.lastActiveLocation,
                                    lng: each?.lastActiveLocation?.long
                                }
                            })}
                            onClick={() => onClickHander({})}
                        />
                    </GoogleMap>
                </div>
            ) : (
                <div />
            )}
        </div>
    );
};

export default CookActivityModule;
