import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody, Link,
} from "@mui/material";
import moment from "moment";
import React from "react";

const CouponListForModuleV2 = (props: any) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Coupon code</TableCell>
            <TableCell>Coupon value</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Product type</TableCell>
            <TableCell>Coupon type</TableCell>
            <TableCell>Maximum discount amount</TableCell>
            <TableCell>Maximum usage limit</TableCell>
            <TableCell>Minimum order value</TableCell>
            <TableCell>Coupon header</TableCell>
            <TableCell>Coupon description</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Expires at</TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row: any) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell scope="row">
                <Link href={`/#/CouponsV2/${row?.id}/show`}>
                  <p style={{ margin: 0 }}>{row?.id || "Not Available"}</p>
                </Link>
              </TableCell>
              <TableCell>{row?.couponCode}</TableCell>
              <TableCell>{row?.couponValue}</TableCell>
              <TableCell>{(!row?.isActive || moment(row?.expiresAt).isBefore(moment())) ? <b style={{color: "red"}}>Expired</b> : <b style={{color: "green"}}>Active</b>}</TableCell>
              <TableCell align="left">{row?.productType}</TableCell>
              <TableCell align="left">{row?.couponType}</TableCell>
              <TableCell align="left">{row?.maximumDiscountAmount}</TableCell>
              <TableCell align="left">{row?.maximumUsageLimit}</TableCell>
              <TableCell align="left">{row?.minimumOrderValue}</TableCell>
              <TableCell align="left">{row?.couponHeader}</TableCell>
              <TableCell align="left">{row?.couponDescription}</TableCell>
              <TableCell align="left">
                {row?.createdAt && moment(row?.createdAt).format("DD MMM YYYY")}
              </TableCell>
              <TableCell align="left">
                {row?.expiresAt && moment(row?.expiresAt).format("DD MMM YYYY")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CouponListForModuleV2;
