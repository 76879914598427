import { Box, TableCell, TableContainer, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import CookAuditInterface from "../../interfaces/Cook/CookAuditInterface";
import { CookService } from "../../services/CookServices";
import CkMuiTable from "../Common/CkMuiTable/CkMuiTable";
import DisplayNoDataFound from "../Common/DisplayNoDataFound";

const cookService = new CookService();

interface InputProps {
  cookId: number;
}

const CookAuditLogs = (props: InputProps) => {
  const [cookAuditLogs, setCookAuditLogs] = useState<CookAuditInterface[]>();

  useEffect(() => {
    cookService
      .getAuditLogs(props.cookId)
      .then((response) => {
        // console.log(response)
        setCookAuditLogs(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.cookId]);

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        {cookAuditLogs?.length !== 0 ? (
          <TableContainer sx={{ overflowX: "auto" }}>
            <CkMuiTable
              sx={{}}
              headers={[
                "Id",
                "Reference Model",
                "Action Type",
                "Changed By",
                "Changed By Type",
                "Previous Value",
                "Next Value",
                "Changes",
              ]}
              rowData={[]}
              isTablePagination={false}
            >
              {cookAuditLogs &&
                cookAuditLogs.map(
                  (audit: CookAuditInterface, index: number) => (
                    <TableRow key={"" + index}>
                      <TableCell>{audit.id}</TableCell>
                      <TableCell>{audit.referenceModelName}</TableCell>
                      <TableCell>{audit?.actionType}</TableCell>
                      <TableCell>{audit?.changedBy}</TableCell>
                      <TableCell>{audit?.changedByType}</TableCell>
                      <TableCell>
                        <CkMuiTable
                          sx={{}}
                          headers={["State", "Value"]}
                          rowData={[]}
                          isTablePagination={false}
                        >
                          {audit?.changes?.map((change: string) => {
                            return (
                              <TableRow>
                                <TableCell>{change}</TableCell>
                                <TableCell>
                                  {`${audit?.previousValue[change]}`}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </CkMuiTable>
                      </TableCell>
                      <TableCell>
                        <CkMuiTable
                          sx={{}}
                          headers={["State", "Value"]}
                          rowData={[]}
                          isTablePagination={false}
                        >
                          {audit?.changes?.map((change: string) => {
                            return (
                              <TableRow>
                                <TableCell>{change}</TableCell>
                                <TableCell>
                                  {`${audit?.nextValue[change]}`}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </CkMuiTable>
                      </TableCell>
                      <TableCell>
                        {audit?.changes?.map(
                          (change: string, index: number) => {
                            return `${change} ${
                              audit.changes.length - 1 === index ? "" : ","
                            }`;
                          }
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )}
            </CkMuiTable>
          </TableContainer>
        ) : (
          <DisplayNoDataFound text="No Data Found" />
        )}
      </Box>
    </div>
  );
};

export default CookAuditLogs;
