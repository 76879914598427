import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const CustomerCreate = () => {
    return (
      <Create redirect="show">
        <SimpleForm>
            <TextInput source="fname" />
            <TextInput source="lname" />
            <TextInput source="email" />
            <TextInput source="address" />
            <TextInput source="mobile" />
        </SimpleForm>
      </Create>
    );
};

export default CustomerCreate;