import React, {useCallback, useEffect, useState} from "react";
import { CookService } from "../../services/CookServices";
import CkMuiTable from "../Common/CkMuiTable/CkMuiTable";
import { CookEscalationInterface } from "../../interfaces/Cook/CookEscalationInterface";
import {
  Button, Dialog, DialogContent, DialogActions, DialogTitle, FormControl,
  Grid, InputLabel,
  Link, MenuItem, Select,
  Stack,
  TableCell,
  TableContainer,
  TableRow, TablePagination, TextField,
} from "@mui/material";
import moment from "moment";
import CookComment from "../../interfaces/Cook/CookComment";
import CookEscalationDialog from "../Common/CookEscalationDialog";
import { useRecordContext, useRefresh } from "ra-core";
import { CookWorkStatus } from "../../enums/CookWorkStatus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import {CookEscalationAction} from "../../enums/CookEscalationAction";
import {useAppSelector} from "../../store/hooks";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";
import {UserGroupsEnum} from "../../enums/UserGroupsEnum";
import {CookEscalationResolutions} from "../../enums/CookEscalationResolution.";
import Op from "../../interfaces/SequalizeFilters/SequalizeTypes";
import { CookEscalationResolutionsEnum } from "../../enums/CookEscalationResolutionsEnum";

const cookService = new CookService();

interface InputProps {
  cookId: number;
}

const CookEscalation = ({ cookId }: InputProps) => {
  const userDetails = useAppSelector((state) => state.userDetails.value);
  let groupsArray: string[] = userDetails.adminUser.accessGroups;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  let [subData, setSubData] = useState<any>(null);

  const [cookEscalationData, setCookEscalationData] =
    useState<CookEscalationInterface[]>();
  const [escalationDialogBox, setOpenEscalationDialogBox] =
    useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [escalationDialogOpen, setEscalationDialogOpen] = useState<boolean>(false);
  const [freeToWork, setFreeToWork] = useState<boolean>(false);
  const [addComment, setAddComment] = useState<boolean>(false);
  const [commentText, setCommentText] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [topicProgressId, setTopicProgressId] = useState<string>('');
  const [escalationId, setEscalationId] = useState<number>();
  const [escalationType, setEscalationType] = useState<string>('');
  const [escalationResolution, setEscalationResolution] = useState<string>('');
  const record = useRecordContext();
  const refresh = useRefresh();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSubmit = () => {
    cookService
        .markFreeToWork(record?.cookFullProfile?.cookId, {comment})
        .then((res) => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Successfully marked free to work",
            type: CustomSnackbarTypes.SUCCESS,
          });
          setFreeToWork(false);
          setComment('');
          loadData();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          refresh();
        });
  };

  const fetchDetails = useCallback((limit: number, offset: number, filters: any) => {
    if(!cookId) return;
    cookService
        .getEscalations(cookId, limit, offset, filters)
        .then((res) => {
          setSubData(res);
          setCookEscalationData(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [cookId]);

  const loadData = useCallback(() => {
    let filters: any = {};
    if(status !== ''){
      filters.actionByCook = status;
    }
    if(type !== ''){
      filters.resolution = type;
    }
    if(topicProgressId === 'MANUAL'){
      filters.topicProgressId = {
        [Op.eq.toString()]: null
      }
    }
    if(topicProgressId === 'AUTOMATED'){
      filters.topicProgressId = {
        [Op.not.toString()]: null
      }
    }
    fetchDetails(rowsPerPage, page * rowsPerPage, filters);
  }, [fetchDetails, page, rowsPerPage, status, type, topicProgressId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (!cookEscalationData) return <></>;
  return (
    <div>
      <Stack direction="row" spacing={2} alignItems={'baseline'}>
        <FormControl sx={{minWidth: 200}}>
          <InputLabel id="demo-simple-select-label">
            Status
          </InputLabel>
          <Select
              name="status"
              label="Status"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value as string);
              }}
              defaultValue={""}
          >
            <MenuItem value={''}>
              {'Clear Filter'}
            </MenuItem>
            {Object.keys(CookEscalationAction).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{minWidth: 200}}>
          <InputLabel id="demo-simple-select-label">
            Escalation Type
          </InputLabel>
          <Select
              name="status"
              label="Status"
              value={type}
              onChange={(e) => {
                setType(e.target.value as string);
              }}
              defaultValue={""}
          >
            <MenuItem value={''}>
              {'Clear Filter'}
            </MenuItem>
            {Object.keys(CookEscalationResolutions).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{minWidth: 200}}>
          <InputLabel id="demo-simple-select-label">
            MANUAL/AUTOMATED
          </InputLabel>
          <Select
              name="status"
              label="Status"
              value={topicProgressId}
              onChange={(e) => {
                setTopicProgressId(e.target.value as string);
              }}
              defaultValue={""}
          >
            <MenuItem value={''}>
              {'Clear Filter'}
            </MenuItem>
            <MenuItem value={'MANUAL'}>
              {'MANUAL'}
            </MenuItem>
            <MenuItem value={'AUTOMATED'}>
              {'AUTOMATED'}
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Grid container spacing={2} mb="0.5rem" justifyContent="flex-end">
          {[CookWorkStatus.BLACKLISTED].includes(record?.cookFullProfile?.workStatus) && (
              <Grid item>
                <Button
                    disabled={!GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER])}
                    onClick={() => {
                      setFreeToWork(true);
                    }}
                    color={"secondary"}
                    style={{ width: "max-content", fontSize: "12px" }}
                    size="small"
                    variant="contained"
                >
                  Mark Free To Work
                </Button>
              </Grid>
          )}
          {![CookWorkStatus.FREE_TO_WORK, CookWorkStatus.BLACKLISTED].includes(record?.cookFullProfile?.workStatus) && (
            <Grid item>
              <Button
                disabled={!GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER])}
                onClick={() => {
                  handleSubmit();
                }}
                color={"secondary"}
                style={{ width: "max-content", fontSize: "12px" }}
                size="small"
                variant="contained"
              >
                Mark Free To Work
              </Button>
            </Grid>
          )}
          {[CookWorkStatus.FREE_TO_WORK].includes(
            record?.cookFullProfile?.workStatus
          ) && (
            <Grid item>
              <Button
                onClick={() => {
                  cookService
                    .markNotFreeForWork(record?.cookFullProfile?.cookId)
                    .then((res) => {
                      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: "Successfully marked not free to work",
                        type: CustomSnackbarTypes.SUCCESS,
                      });
                    })
                    .catch((err) => {
                      console.log(err);
                    })
                    .finally(() => {
                      refresh();
                    });
                }}
                color={"secondary"}
                style={{ width: "max-content", fontSize: "12px" }}
                size="small"
                variant="contained"
              >
                Mark Not Free To Work
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              onClick={() => {
                setOpenEscalationDialogBox(true);
              }}
              color={"secondary"}
              style={{ width: "max-content", fontSize: "12px" }}
              size="small"
              variant="contained"
            >
              Create Escalation
            </Button>
          </Grid>
        </Grid>
      </Stack>
      <CookEscalationDialog
        cookId={cookId}
        open={escalationDialogBox}
        setOpen={setOpenEscalationDialogBox}
        refresh={refresh}
        onSubmit={() => {
          loadData();
        }}
      />
      {cookEscalationData &&
        Array.isArray(cookEscalationData) &&
        (cookEscalationData.length === 0 ? (
          <EmptyListPlaceholder message="No Escalations Found" />
        ) : (
          <TableContainer sx={{ overflowX: "auto" }}>
            <CkMuiTable
              sx={{}}
              headers={[
                "Id",
                "Product",
                "Trigger Source",
                "Reference Link",
                "Escalation Reason",
                "Sub Reason",
                "Resolution Type",
                "Created At",
                "MANUAL/AUTOMATED",
                "End Date",
                "Escalation Status",
                "Comments",
                "",
                "",
              ]}
              rowData={[]}
              isTablePagination={false}
            >
              {cookEscalationData.map(
                (escalation: CookEscalationInterface, index: number) => {
                  let link = '';
                    if(escalation?.referenceModelName === 'ONE_MEAL_SERVICE') {
                        link = `/#/OneMealService/${escalation?.referenceId}/show`;
                    } else if(escalation?.referenceModelName === 'TRIAL_V3' || escalation?.referenceModelName === 'TRIALS') {
                        link = `/#/Trials/${escalation?.referenceId}/show`;
                    } else if(escalation?.referenceModelName === 'COOK_SUBSCRIPTION_V3' || escalation?.referenceModelName === 'SUBSCRIPTION') {
                      link = `/#/UserBundles/${escalation?.referenceId}/show`;
                    } else if(escalation?.referenceModelName === 'CHEF_FOR_PARTY_ORDER') {
                      link = `/#/ChefForParty/${escalation?.referenceId}/show`;
                    }
                  return (
                      <TableRow key={"" + index}>
                        <TableCell>{escalation?.id}</TableCell>
                        <TableCell>{escalation?.referenceModelName}</TableCell>
                        <TableCell>
                          {!escalation?.createdBy ? 'Customer' : (
                              <Link href={`/#/AdminUsers/${escalation?.createdBy}/show`}>
                                {escalation?.createdBy}
                              </Link>
                          )}
                        </TableCell>
                        <TableCell>
                          {link !== '' ? (
                              <Link href={link}>
                                {escalation?.referenceId}
                              </Link>
                          ) : escalation?.referenceId}
                        </TableCell>
                        <TableCell>{escalation?.reason}</TableCell>
                        <TableCell>{escalation?.subReason}</TableCell>
                        <TableCell>{escalation.resolution}</TableCell>
                        <TableCell sx={{minWidth: '220px'}}>{moment(escalation?.createdAt).format('LLL')}</TableCell>
                        <TableCell align={'center'}>{escalation?.topicProgressId ? 'AUTOMATED' : 'MANUAL'}</TableCell>
                        <TableCell sx={{minWidth: '220px'}}>{escalation?.endDate ? moment(escalation?.endDate).format('LLL') : '---'}</TableCell>
                        <TableCell>{escalation?.actionByCook}</TableCell>
                        <TableCell>
                          <CkMuiTable
                              sx={{}}
                              headers={["Name", "Comment", "TimeStamp"]}
                              rowData={[]}
                              isTablePagination={false}
                          >
                            {escalation?.comments?.map(
                                (comment: CookComment, index: number) => (
                                    <TableRow key={"" + index}>
                                      <TableCell>{comment?.name}</TableCell>
                                      <TableCell>{comment?.comment}</TableCell>
                                      <TableCell>
                                        {moment(comment?.timestamp).format("DD/MM/YYYY")}
                                      </TableCell>
                                    </TableRow>
                                )
                            )}
                          </CkMuiTable>
                        </TableCell>
                        <TableCell>
                          <Button style={{width: '300px'}} variant={'contained'} onClick={() => {
                            setEscalationId(escalation?.id);
                            setEscalationDialogOpen(true);
                          }}>
                            {'Cook Escalation Resolution'}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button style={{width: '150px'}} variant={'contained'} onClick={() => {
                            setEscalationId(escalation?.id);
                            setOpen(true);
                          }}>
                            {'Update Status'}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button style={{width: '150px'}} variant={'contained'} onClick={() => {
                            setEscalationId(escalation?.id);
                            setAddComment(true);
                          }}>
                            {'Add Comment'}
                          </Button>
                        </TableCell>
                      </TableRow>
                  )
                }
              )}
            </CkMuiTable>
            <TablePagination
                component="div"
                count={subData?.count || 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        ))}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {'Update Escalation Status'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl style={{ width: 520 }} fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Status
                </InputLabel>
                <Select
                    autoWidth={false}
                    className="input_field"
                    defaultValue="Select Status"
                    value={escalationType}
                    onChange={(e) => setEscalationType(e.target.value)}
                >
                  {Object.keys(CookEscalationAction).map((item: string) => {
                    return (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color={'error'} variant={'contained'} onClick={() => {setOpen(false)}}>
            {'Cancel'}
          </Button>
          <Button  variant={'contained'} onClick={() => {
            if(escalationType === '' || !escalationId) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Select a type",
                type: CustomSnackbarTypes.ERROR,
              });
              return;
            }
            cookService.updateEscalationsStatus(cookId, {
              escalationId: escalationId,
              actionByCook: escalationType,
            }).then(() => {
              refresh();
              loadData();
              setOpen(false);
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Updated Successfully",
                type: CustomSnackbarTypes.SUCCESS,
              });
            });
          }}>
            {'Save'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={escalationDialogOpen} onClose={() => setEscalationDialogOpen(false)}>
        <DialogTitle>
          {'Update Cook Escalation Resolution Type'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl style={{ width: 520 }} fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Resolution Type
                </InputLabel>
                <Select
                    autoWidth={false}
                    className="input_field"
                    defaultValue="Select Resolution Type"
                    value={escalationResolution}
                    onChange={(e) => setEscalationResolution(e.target.value)}
                >
                  {Object.keys(CookEscalationResolutionsEnum).map((item: string) => {
                    return (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color={'error'} variant={'contained'} onClick={() => {setEscalationDialogOpen(false)}}>
            {'Cancel'}
          </Button>
          <Button  variant={'contained'} onClick={() => {
            if(escalationResolution === '' || !escalationId) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Select a type",
                type: CustomSnackbarTypes.ERROR,
              });
              return;
            }
            cookService.patchCookResolution(cookId, escalationId, {
              escalationId: escalationId,
              resolution: escalationResolution,
            }).then(() => {
              refresh();
              loadData();
              setEscalationDialogOpen(false);
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Updated Successfully",
                type: CustomSnackbarTypes.SUCCESS,
              });
            });
          }}>
            {'Save'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={freeToWork} onClose={() => setFreeToWork(false)}>
        <DialogTitle>
          {'Mark Free To Work'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={'Comment'}
                fullWidth
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color={'error'} variant={'contained'} onClick={() => {setFreeToWork(false)}}>
            {'Cancel'}
          </Button>
          <Button  variant={'contained'} onClick={() => {
            if(comment === '') {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Enter Details",
                type: CustomSnackbarTypes.ERROR,
              });
              return;
            }
            handleSubmit();
          }}>
            {'Save'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={addComment} onClose={() => setAddComment(false)}>
        <DialogTitle>
          {'Add comment'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={'Comment'}
                fullWidth
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color={'error'} variant={'contained'} onClick={() => {setAddComment(false)}}>
            {'Cancel'}
          </Button>
          <Button  variant={'contained'} onClick={() => {
            if(commentText === '' || !escalationId) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Enter Details to continue",
                type: CustomSnackbarTypes.ERROR,
              });
              return;
            }
            cookService
                .addComment(record?.cookFullProfile?.cookId, escalationId,{comment: commentText})
                .then(() => {
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Comment added Successfully",
                    type: CustomSnackbarTypes.SUCCESS,
                  });
                  setAddComment(false);
                  setCommentText('');
                  loadData();
                })
                .catch((err) => {
                  console.log(err);
                })
                .finally(() => {
                  refresh();
                });
          }}>
            {'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CookEscalation;
