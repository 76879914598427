const TypesOfChefRating = {
  ONBOARDING_SCORE: 1,
  RELATIONSHIP_SCORE: 5,
  TRIAL_SCORE: 10,
  CHEFIT_SCORE: 16,
  APP_ADAPTION_SCORE: 19,
  ESCALATION_SCORE: 22,
  TOLERANCE_SCORE: 26,
};

export default TypesOfChefRating;
