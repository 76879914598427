import HttpClient from "../HttpClient";
import { MetabaseDashboardRequest, Resource } from "../interfaces/MetabaseTransaction/MetabaseDashboardRequest";

export class MetabaseDashboardService {
    private readonly baseurl: string;
    constructor() {
        this.baseurl = '/admin-user/admin/metabase-embed-url'
    }

    async getMetabaseEmbedUrl(dashboard: string, queryParams: any = null): Promise<any> {
        const resource: Resource = {
            dashboard: Number(dashboard)
        }
        const exp = Math.round(Date.now() / 1000) + (10 * 60)
        const params = queryParams ? queryParams : {}
        const payload: MetabaseDashboardRequest = {
            resource,
            exp,
            params
        }
        let data = await HttpClient.post(this.baseurl, payload);
        return data?.data;
    }
}