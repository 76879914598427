import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl, FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem, Radio, RadioGroup,
  Select,
  TextField
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import * as React from "react";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "./CustomSnackBar";
import {TimeEnum} from "../../enums/TimeEnum";
import {CookService} from "../../services/CookServices";

interface InputProps {
  data: any;
  open: boolean;
  callback: () => void;
}

const cookService = new CookService();

export const MarkLeaveDialog = (props: InputProps) => {
  let [startTime, setStartTime] = React.useState<any>()
  let [startDate, setStartDate] = React.useState<Date | null>(new Date())
  let [endTime, setEndTime] = React.useState<any>()
  let [endDate, setEndDate] = React.useState<Date | null>(new Date())
  let [isLeaveMarked, setIsLeaveMarked] = React.useState<any>(true);

  let handleClose = () => {
    props.callback();
    setStartDate(null);
    setStartTime('')
  }
  return (
      <Dialog
        onClose={handleClose}
        open={props.open}
        scroll={"paper"}
        maxWidth="xl"
      >
        <DialogTitle>{`Cook SLCs for ${props?.data?.firstName} ${props?.data?.lastName}`}</DialogTitle>
        <DialogContent>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
          <Grid
            container
            columns={1}
            rowSpacing={0.9}
            style={{ padding: "12px 12px 12px 12px" }}
          >
            <FormControl fullWidth style={{ marginTop: "20px" }}>
              <DatePicker
                label="Stat Date"
                inputFormat="dd MMM yyyy"
                disableMaskedInput
                value={startDate}
                onChange={(value) => setStartDate(value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
            <FormControl fullWidth style={{ marginTop: "20px" }}>
              <InputLabel id="forSlot">Start Time</InputLabel>
              <Select
                labelId="startTime"
                id="startTime"
                value={startTime}
                label="Start Time"
                onChange={(event) => setStartTime(event.target.value)}
              >
                {Object.values(TimeEnum).map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginTop: "20px" }}>
              <DatePicker
                  label="End Date"
                  inputFormat="dd MMM yyyy"
                  disableMaskedInput
                  value={endDate}
                  onChange={(value) => setEndDate(value)}
                  renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
            <FormControl fullWidth style={{ marginTop: "20px" }}>
              <InputLabel id="forSlot">End Time</InputLabel>
              <Select
                  labelId="endTime"
                  id="endTime"
                  value={endTime}
                  label="End Time"
                  onChange={(event) => setEndTime(event.target.value)}
              >
                {Object.values(TimeEnum).map((e) => (
                    <MenuItem key={e} value={e}>
                      {e}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <FormLabel id="pr">Select Type of Leave</FormLabel>
              <RadioGroup
                  row
                  aria-labelledby="row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(event: any) => {
                    setIsLeaveMarked(event.target.value === "true");
                  }}
                  value={isLeaveMarked}
              >
                <FormControlLabel value={true} control={<Radio />} label="Mark Leave" />
                <FormControlLabel value={false} control={<Radio />} label="Un-Mark Leave" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              if (!startTime || !startDate || !endTime || !endDate) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Fill the details to proceed",
                    type: CustomSnackbarTypes.WARNING,
                });
                return;
              }
              cookService
                .markCookLeave({
                  "cookId": props?.data?.cookId,
                  "startDate": moment(startDate).format("YYYY-MM-DD"),
                  "endDate": moment(endDate).format("YYYY-MM-DD"),
                  "startTime": startTime,
                  "endTime": endTime,
                  isLeaveMarked,
                })
                .then(() => {
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Successful",
                    type: CustomSnackbarTypes.SUCCESS,
                  });
                  handleClose();
                });
            }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  );
};
