import {AuthProvider} from 'react-admin'
import AdminUserService from './services/AdminUser';
import {AuthService} from "./services/AuthService";
import SetUserDetailsAction from './store/actions/LoginAction/SetUserDetailsAction';

const authProvider: AuthProvider = {
    // send username and password to the auth server and get back credentials
    async login(params) {
        const adminUserService = new AdminUserService();
        const authService = new AuthService();
        let data = await authService.issueToken(params.username, params.password);
        if (!data || data.status !== 200) {
            return Promise.reject()
        }
        localStorage.setItem('auth', JSON.stringify(data))
        if(!data?.data?.user?.id){
            return Promise.reject()
        }
        const user = await adminUserService.getAdminUserById(Number(data?.data?.user.id))
        if(user.data.adminGroups.length === 0){
            localStorage.clear();
            return Promise.reject('No access group assigned');
        }
        SetUserDetailsAction(user.data)
        return Promise.resolve()
    },
    // when the dataProvider returns an error, check if this is an authentication error
    async checkError(error) {
        const status = error.status;
        if (status === 401 || status === 403) {
            const authService = new AuthService();
            await authService.refreshToken(true);
        }
        return Promise.resolve();
    },
    // chen the user navigates, make sure that their credentials are still valid
    async checkAuth(params) {
        return localStorage.getItem('auth') ? Promise.resolve() : Promise.reject();
    },
    // remove local credentials and notify the auth server that the user logged out
     async logout() {
        localStorage.clear();
        SetUserDetailsAction({})
        return Promise.resolve()
    },
    async getPermissions(params) {
        return Promise.resolve();
    },
};
export default authProvider
