import {
  DialogContent,
  Box,
  TableRow,
  TableCell,
  Button,
  Table,
  TableHead,
} from "@mui/material";
import { useEffect, useState } from "react";
import { EventBusEventsEnum } from "../enums/EventBusEventsEnum";
import EventBus from "../EventBus";
import FreshLeadInterface from "../interfaces/Cook/FreshLeadInterface";
import RosterEmployeeResponse from "../interfaces/Roster/RosterEmployeeResponse";
import { CookService } from "../services/CookServices";
import RosterService from "../services/RosterService";
import CkMuiDialog from "./Common/CkMuiDialog/CkMuiDialog";
import CkMuiTable from "./Common/CkMuiTable/CkMuiTable";
import { CustomSnackbarTypes } from "./Common/CustomSnackBar";

const cookService = new CookService();
const rosterService = new RosterService();
const employeType = "COOK_PROFILE_LEADS";

interface InputProps {
  cookDetails: FreshLeadInterface;
  open: boolean;
  setOpen: (open: boolean) => void;
  setAssingAgentDialogOpen: (open: boolean) => void;
  refresh: () => void;
}

const EmployeRosterForCookProfileDialog = ({
  cookDetails,
  open,
  setOpen,
  setAssingAgentDialogOpen,
  refresh,
}: InputProps) => {
  const [employees, setEmployees] = useState<RosterEmployeeResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  function handleSelectOwner(id: number) {
    cookService
      .updateOwner(cookDetails.cookId, { authUserId: id })
      .then((res) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Agent Assigned Successfully",
          type: CustomSnackbarTypes.SUCCESS,
        });
      })
      .catch(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Error Assigning Agent",
          type: CustomSnackbarTypes.ERROR,
        });
      });
  }

  useEffect(() => {
    if (employees.length === 0) {
      setLoading(true);
      rosterService
        .getAllEmployeesForRoster(employeType)
        .then((res) => {
          setEmployees(res);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [employees]);

  return (
    <CkMuiDialog
      isDialogOpen={open}
      setDialogOpen={setOpen}
      onDialogClose={() => {}}
      title={"Employee Roster for Cook Profile"}
      primaryLabel={"Close"}
      primaryAction={() => {
        setOpen(false);
      }}
      secondaryLabel={""}
      secondaryAction={() => {}}
      isLoading={false}
    >
      <DialogContent sx={{ minWidth: "400px" }}>
        <Box>
          <CkMuiTable
            sx={{}}
            headers={[]}
            rowData={[]}
            isTablePagination={false}
            alignment="left"
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {employees.length > 0 ? (
                employees.map((each, index) => (
                  <TableRow key={"" + index}>
                    <TableCell>{each?.adminDetails?.name || ""}</TableCell>
                    <TableCell>{each?.adminDetails?.email || ""}</TableCell>
                    <TableCell>
                      <Button
                        size={"small"}
                        color={"error"}
                        variant={"contained"}
                        onClick={() => {
                          handleSelectOwner(each?.adminId);
                          setOpen(false);
                          setAssingAgentDialogOpen(false);
                          refresh();
                        }}
                      >
                        {"Select"}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : loading ? (
                ""
              ) : (
                <caption>No Employees added</caption>
              )}
            </Table>
          </CkMuiTable>
        </Box>
      </DialogContent>
    </CkMuiDialog>
  );
};

export default EmployeRosterForCookProfileDialog;
