import {
  FreshLeadOnboardingSubStatus,
  FreshLeadStatus,
  FreshLeadSubStatusReasoningsForLostLead,
} from "../enums/FreshLeadStatus";

export function freshLeadSubStatusMapFunction(key: FreshLeadStatus) {
  let freshLeadMap = new Map();
  switch (key) {
    case FreshLeadStatus.FRESH_LEAD:
    case FreshLeadStatus.PROFILE:
    case FreshLeadStatus.SKILL_ASSESSMENT:
      freshLeadMap.set(key, [
        "NO_CALL_DONE",
        "1st_CALL_DONE",
        "2nd_CALL_DONE",
        "3rd_CALL_DONE",
        "LOST_LEAD",
        "JUNK_LEAD",
      ]);
      break;
    case FreshLeadStatus.TRAINING:
    case FreshLeadStatus.APPROVAL_CHECKS:
      freshLeadMap.set(key, [
        "NO_CALL_DONE",
        "1st_CALL_DONE",
        "2nd_CALL_DONE",
        "3rd_CALL_DONE",
        "LOST_LEAD",
        "JUNK_LEAD",
        "PENDING",
      ]);
      break;
    case FreshLeadStatus.ONBOARDING:
      freshLeadMap.set(key, [
        "KIT_DISTRIBUTION_PENDING",
        "KIT_CONFIRMATION_PENDING",
        "PAYOUT_DISCUSSION_PENDING",
        "LOST_LEAD",
        "COMPLETE",
      ]);
      break;
    default:
      return null;
  }
  return freshLeadMap.get(key);
}

export function freshLeadSubStatusReasoningMapFunction(
  key: FreshLeadOnboardingSubStatus
) {
  let freshLeadSubStatusReasoningMap = new Map();

  switch (key) {
    case FreshLeadOnboardingSubStatus.LOST_LEAD:
      freshLeadSubStatusReasoningMap.set(key, [
        "UNABLE_TO_CONNECT",
        "NON_RESPONSIVE",
        "NOT_INTERESTED",
        "NOT_FREE_TO_WORK",
        "NOT_ELIGIBLE_FOR_WORK",
        "OTHER",
      ]);
      break;
    case FreshLeadOnboardingSubStatus.JUNK_LEAD:
      freshLeadSubStatusReasoningMap.set(key, ["NOT_A_COOK", "INVALID_NUMBER"]);
      break;

    default:
      return null;
  }

  return freshLeadSubStatusReasoningMap.get(key);
}

export function freshLeadSubStatusSubReasoningMapFunction(
  subStatusReasonings: FreshLeadSubStatusReasoningsForLostLead
) {
  let freshLeadSubStatusSubReasoningMap = new Map();

  switch (subStatusReasonings) {
    case FreshLeadSubStatusReasoningsForLostLead.NON_RESPONSIVE:
      freshLeadSubStatusSubReasoningMap.set(subStatusReasonings, [
        "Not responding to calls",
        "Declining the calls",
      ]);
      break;
    case FreshLeadSubStatusReasoningsForLostLead.NOT_ELIGIBLE_FOR_WORK:
      freshLeadSubStatusSubReasoningMap.set(subStatusReasonings, [
        "Soft skill issue",
        "Lack of knowledge in cuisines",
        "Background Verification Failed"
      ]);
      break;
    case FreshLeadSubStatusReasoningsForLostLead.NOT_FREE_TO_WORK:
      freshLeadSubStatusSubReasoningMap.set(subStatusReasonings, [
        "Got another opportunity",
        "Already occupied",
        "Out of city",
      ]);
      break;
    case FreshLeadSubStatusReasoningsForLostLead.NOT_INTERESTED:
      freshLeadSubStatusSubReasoningMap.set(subStatusReasonings, [
        "High salary expectation",
        "Not comfortable working in house kitchen",
        "Only wants to work in single household",
        "Leave policy issue",
        "Spouse not allowing",
        "Lack of trust in CK",
        "Does not want to work with CK",
        "Did not complete Assessment Process",
        "Did not complete Training",
        "Relocation Issue",
        "Interested in MG only",
        "Interested in full time only",
        "Interested in Parties only"
      ]);
      break;
    case FreshLeadSubStatusReasoningsForLostLead.OTHER:
      freshLeadSubStatusSubReasoningMap.set(subStatusReasonings, [
        "Language issue",
        "Distance issue",
        "Delaying dates",
        "Document Pending",
        "No Smartphone",
        "Below Criteria Ratings"
      ]);
      break;
    case FreshLeadSubStatusReasoningsForLostLead.UNABLE_TO_CONNECT:
      freshLeadSubStatusSubReasoningMap.set(subStatusReasonings, [
        "Switched Off",
        "Incoming off",
        "DND",
        "Out of Network",
      ]);
      break;

    default:
      return null;
  }

  return freshLeadSubStatusSubReasoningMap.get(subStatusReasonings);
}
