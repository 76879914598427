import { Button } from "@mui/material";
import { useState } from "react";
import {
    ArrayField,
    Datagrid,
    DateField,
    List,
    NumberField,
    ReferenceField,
    ShowButton,
    TextField,
    useRecordContext,
    useRefresh,
    FunctionField
} from "react-admin";
import { UserType } from "../../enums/common";
import CallButton from "../Common/CallButton/CallButton";
import { CreateTxnDialog } from "../Common/CreateTxnDialog";
import CustomReferenceField from "../Common/CustomReferenceField";

const RenderCTAButtons = (props: any) => {
    const record = useRecordContext()

    const showCTAButtons: boolean = record.totalVisits > record.totalTaken

    return (
        showCTAButtons ?
            <CustomButton
                name={'Create Txn'}
                callback={(data) => {
                    props.setSelectedData(data);
                    props.setCreateTxn(true);
                }}
            /> :
            null
    )
}

const CustomButton = (props: { name: any, callback: (data: any) => void }) => {
    let record = useRecordContext();
    return (
        <Button
            onClick={() => {
                props.callback(record);
            }}
            size={"small"}
            color={"secondary"}
            style={{ width: "max-content", fontSize: "12px" }}
        >
            {props.name}
        </Button>
    )
}

const VasUserMappingList = () => {
    const refresh = useRefresh();
    let [selectedData, setSelectedData] = useState<any>(null);
    let [createTxn, setCreateTxn] = useState<boolean>(false)

    return (
        <>
            <CreateTxnDialog
                data={selectedData}
                open={createTxn}
                callback={() => {
                    setCreateTxn(false);
                    refresh()
                }}
            />

            <List>
                <Datagrid bulkActionButtons={false}>
                    <TextField source="id" sortable={false}/>
                    <CustomReferenceField label="User Bundle" listName={"UserBundles"} source={"userBundleMappingId"}/>
                    <ReferenceField label="Vas Name" source="vasId" reference="Vas" sortable={false} link="show">
                        <TextField source="name" />
                    </ReferenceField>
                    <NumberField source="totalVisits" sortable={false}/>
                    <DateField options={{day: 'numeric', month: 'short', year: 'numeric' }} source="startDate" sortable={false}/>
                    <DateField options={{day: 'numeric', month: 'short', year: 'numeric' }} source="endDate" sortable={false}/>
                    <NumberField source="totalTaken" sortable={false}/>
                    <DateField options={{day: 'numeric', month: 'short', year: 'numeric' }} source="createdAt" sortable={false}/>
                    <DateField options={{day: 'numeric', month: 'short', year: 'numeric' }} source="updatedAt" sortable={false}/>
                    <ArrayField sortable={false} label="Customer Name" source="userBundleMapping.house.customers">
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="fname" label={''}/>
                        </Datagrid>
                    </ArrayField>
                    <ArrayField sortable={false} label="Customer Address" source="userBundleMapping.house.customers">
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="address" label={''}/>
                        </Datagrid>
                    </ArrayField>
                    <ArrayField sortable={false} label="Customer Mobile" source="userBundleMapping.house.customers">
                        <Datagrid bulkActionButtons={false}>
                            <FunctionField render={(record: any) => <CallButton toPhoneNumber={record?.mobile} userType={UserType.CUSTOMER}/>}/>
                        </Datagrid>
                    </ArrayField>
                    <ShowButton />
                    <RenderCTAButtons
                        setSelectedData={setSelectedData}
                        setCreateTxn={setCreateTxn}
                    />
                </Datagrid>
            </List>
        </>
    )
};

export default VasUserMappingList
