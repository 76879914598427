import { TextField } from "@mui/material";
import { useState } from "react";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { TrialsService } from "../../services/TrialServices";
import CkMuiDialog from "./CkMuiDialog/CkMuiDialog";
import { CustomSnackbarTypes } from "./CustomSnackBar";

const trialService = new TrialsService();

interface InputProps {
  trialId: number;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  refresh: () => void;
}

const AddSupplyNotesDialog = ({
  isOpen,
  setIsOpen,
  trialId,
  refresh,
}: InputProps) => {
  const [comment, setComment] = useState<string | null>(null);

  const handleSubmit = () => {
    if (comment) {
      trialService
        .addSupplyComment(trialId, { supplyComment: comment })
        .then((res) => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Comment Added Successfully",
            type: CustomSnackbarTypes.SUCCESS,
          });
        })
        .finally(() => {
          refresh();
          setIsOpen(false);
        });
    }
  };

  const handleCommentChange = (e: any) => {
    setComment(e.target.value);
  };

  return (
    <CkMuiDialog
      isDialogOpen={isOpen}
      setDialogOpen={setIsOpen}
      onDialogClose={() => {}}
      title={"Add Comment"}
      primaryLabel={"Submit"}
      primaryAction={handleSubmit}
      secondaryLabel={"Close"}
      secondaryAction={() => {
        setIsOpen(false);
      }}
      isLoading={false}
      fullWidth={true}
    >
      <TextField
        id="outlined-textarea"
        label="Comment"
        value={comment || ""}
        rows={4}
        fullWidth
        multiline
        onChange={handleCommentChange}
      />
    </CkMuiDialog>
  );
};

export default AddSupplyNotesDialog;
