import { ArrayField, BooleanField, DateField, NumberField, SimpleShowLayout, TextField, useListContext, useRecordContext } from "react-admin";
import LinkField from "../Common/LinkField";
import { CookResponse } from "../../interfaces/Cook/CookResponse";
import { Link } from "@mui/material";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";


export const TrialsCookMetaShow = () => {
    const record = useRecordContext();
    const cook: CookResponse = record.cook;


    function aadharPhotos(label: string, url: any, input: any) {
        return (
            <div>
                <label style={{ color: "grey", fontSize: "12px" }}>{label}</label>
                <br />
                <Link href={url} target={"_blank"} style={{fontSize:"10px"}}> {input} </Link>
            </div>
        );
    }
    const Backlinks = () => {
        const { data } = useListContext();

        return (
            <div>
                {data?.map(backlink => (
                    <p key={backlink} style={{fontSize:"12px",lineHeight:"5px"}}>{backlink}</p>
                ))}
            </div>
        );
    };
    return (
        cook
            ?
            <div>
                <div style={{ display: "grid", gridTemplateColumns: "40% 30% 30%" }}>
                    <SimpleShowLayout>
                        <ArrayField label="cuisine" source="cook.cookFullProfile.cuisineKnown">
                            <Backlinks />
                        </ArrayField>
                        <TextField label={'Id'} source="cook.cookFullProfile.cookId" />
                        <TextField label={'Fname'} source="cook.cookFullProfile.firstName" />
                        <TextField label={'Lname'} source="cook.cookFullProfile.lastName" />
                        <TextField label={'Address'} source="cook.address" />
                    </SimpleShowLayout>
                    <SimpleShowLayout>
                        <TextField label={'Mobile'} source="cook.mobile" />
                        {
                            aadharPhotos("Aadhar Front Photo", cook.cookFullProfile?.profilePhoto, cook.cookFullProfile?.profilePhoto)
                        }
                        <TextField label={'Gender'} source="cook.cookFullProfile.gender" />
                        <TextField label={'Category'} source="cook.cookFullProfile.cookCategory" />
                        <TextField label={'AadharId'} source="cook.cookFullProfile.aadharNumber" />
                    </SimpleShowLayout>
                    <SimpleShowLayout>
                        <TextField label={'Vehicle'} source="cook.vehicle" />
                        <TextField label={'Religion'} source="cook.cookFullProfile.religion" />
                        <TextField label={'Cook Type'} source="cook.cookFullProfile.cookType" />
                        <NumberField label={'Experience'} source="cook.cookFullProfile.yearsOfExperienceHousehold" />
                        <TextField label={"Mobile Type"} source="cook.mobileType" />
                    </SimpleShowLayout>
                    <SimpleShowLayout>
                        <TextField label={"Working City"} source="cook.workingCity" />
                        <TextField label={'Working Locallity'} source="cook.workingLocality" />
                        {
                            aadharPhotos("Aadhar Front Photo", cook.cookFullProfile?.aadharFrontPhoto, cook.cookFullProfile?.aadharFrontPhoto)
                        }
                        {
                            aadharPhotos("Aadhar Back Photo", cook.cookFullProfile?.aadharBackPhoto, cook.cookFullProfile?.aadharBackPhoto)
                        }
                        <LinkField value={'AadharBackPhoto'} source="cook.aadharBackPhoto" sourceRoute gotoHref={true} targetBlank />
                    </SimpleShowLayout>
                    <SimpleShowLayout>
                        <TextField label={'Request To Open Account'} source="cook.requestToOpenAccount" />
                        <TextField label={'Emergency Contact Name'} source="cook.cookFullProfile.emergencyContactName" />
                        <TextField label={'Emergency Contact Number'} source="cook.cookFullProfile.emergencyContactNumber" />
                        <TextField label={'Emergency Contact Relation'} source="cook.emergencyContactRelation" />
                        <BooleanField label={'On Boarding Complete'} source="cook.onboardingComplete" />
                        <NumberField label={'Cook Referrals'} source="cook.cookReferrals" />
                    </SimpleShowLayout>
                    <SimpleShowLayout>
                        <BooleanField label={'Is Salaried'} source="cook.isSalaried" />
                        <NumberField label={'Salary'} source="cook.salary" />
                        <DateField
                            label={'Salary Start Date'}
                            options={{ day: "numeric", month: "short", year: "numeric" }}
                            source="cook.salaryStartDate"
                        />
                        <TextField label={'Salary End Date'} source="cook.salaryEndDate" />
                        <DateField
                            label={'Last Activity Time'}
                            options={{ day: "numeric", month: "short", year: "numeric" }}
                            source="cook.Last_Activity_Time"
                        />
                    </SimpleShowLayout>
                    <SimpleShowLayout>
                        <TextField label={'Whatsapp Number'} source="cook.whatsappNumber" />
                        <DateField
                            label={'DOB'}
                            options={{ day: "numeric", month: "short", year: "numeric" }}
                            source="cook.dob"
                        />
                        <TextField label={'Vaccine Status'} source="cook.vaccineStatus" />
                        <BooleanField label={'Cook Nonveg'} source="cook.cookNonveg" />
                        <TextField label={'Vaccine Certificate'} source="cook.vaccineCertificate" />
                    </SimpleShowLayout>
                    <SimpleShowLayout sx={{ width: "30%" }}>
                        <TextField label={'Verification Status'} source="cook.cookFullProfile.verificationStatus" />
                        <TextField label={'Aadhaar Address'} source="cook.aadhaarAddress" />
                        <TextField label={'Father Name'} source="cook.fatherName" />
                    </SimpleShowLayout>
                </div>
            </div>
            :
            <EmptyListPlaceholder
                message='No Cook Found'
            />
    )
};
