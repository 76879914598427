import React, {useEffect, useState} from 'react';
import UserBundleService from "../../services/BundleService";
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import {TablePagination} from "@mui/material";
import SubscriptionListForModule from "./SubscriptionListForModule";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";

interface InputProps {
    userBundleMappingId: number;
}

const userBundleService = new UserBundleService();

const UserBundleSubscriptionTab = (props: InputProps) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    let [subData, setSubData] = useState<any>(null);
    let [isLoading, setIsLoading] = useState<boolean>();

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const refreshData = (userBundleMappingId: number, limit: number, offset: number) => {
        setIsLoading(true)
        userBundleService.fetchAllSubscriptionsForUserBundleMapping(userBundleMappingId, limit, offset, {order: "DESC", field: "startDate"})
            .then((data) => {
                setSubData(data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            })
    }

    const refreshListener = () => {
        refreshData(props.userBundleMappingId, rowsPerPage, page*rowsPerPage);
    }

    useEffect(() => {
        const listener = EventBus.getInstance();
        listener.on(EventBusEventsEnum.REFRESH_CHILD_USER_BUNDLE_MAPPING_TABS, refreshListener)
        return () => {
            listener.removeListener(EventBusEventsEnum.REFRESH_CHILD_USER_BUNDLE_MAPPING_TABS, refreshListener)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        refreshData(props.userBundleMappingId, rowsPerPage, page*rowsPerPage);
    }, [props.userBundleMappingId, rowsPerPage, page])

    return (
        <div>
            {
                isLoading ?
                    <CircularLoader/>
                    :
                    <div>
                        {
                            subData &&
                            subData.data &&
                            Array.isArray(subData.data) &&
                            (
                                subData.data.length>0 ?
                                    <SubscriptionListForModule
                                        data={subData?.data}
                                        refresh={() => {
                                            refreshData(props.userBundleMappingId, rowsPerPage, page*rowsPerPage)
                                        }}
                                    />
                                    :
                                    <EmptyListPlaceholder
                                        message='No Subscription Found'
                                    />
                            )
                        }
                        <TablePagination
                            component="div"
                            count={subData?.total || 0}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
            }
        </div>
    );
};

export default UserBundleSubscriptionTab;
