import HttpClient from "../HttpClient";

export default class PartnerSectorService {
  async getCookSector(cookId: number) {
    let data = await HttpClient.get(`centaurus/user/cook/${cookId}/sector`);
    return data.data;
  }

  async postCookSector(cookId: number, sector: number[]) {
    const response = await HttpClient.post(
      `centaurus/user/cook/${cookId}/sector`,
      {
        sector: sector,
      }
    );
    return response;
  }
}
