import { FilterAlt, FilterAltOff } from "@mui/icons-material";
import { Box, Button, Card, CardContent, InputLabel } from "@mui/material";
import React from "react";
import {
  Datagrid,
  DateInput,
  ExportButton,
  FilterLiveSearch,
  FunctionField,
  List,
  NumberField,
  SelectInput,
  ShowButton,
  TextField,
  TopToolbar,
} from "react-admin";
import { UserType } from "../../enums/common";
import {
  RenewalBundleStatus,
  RenewalPaymentStatus,
} from "../../enums/RenewalsEnum";
import { RenewalResponse } from "../../interfaces/Renewals/RenewalsResponse";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setRenewalFilter } from "../../store/reducers/customSelect";
import CallButton from "../Common/CallButton/CallButton";
import CustomReferenceField from "../Common/CustomReferenceField";

const FilterSidebar = () => {
  return (
    <Box
      sx={{
        marginTop: "65px",
        display: {
          xs: "none",
          sm: "block",
        },
        order: -1,
        width: "17em",
        marginRight: "1em",
      }}
    >
      <Card>
        <CardContent>
          <InputLabel>Cook Follow Up</InputLabel>
          <FilterLiveSearch source="noOfCookFollowups" />
          <InputLabel>Customer Follow Up</InputLabel>
          <FilterLiveSearch source="noOfCustomerFollowups" />
          <InputLabel>Escalation(true/false)</InputLabel>
          <FilterLiveSearch source="hasEscalations" />
          <InputLabel>Pr Status(true/false)</InputLabel>
          <FilterLiveSearch source="hasActivePr" />
          <InputLabel>Assignee Name</InputLabel>
          <FilterLiveSearch source="ownerName" />
        </CardContent>
      </Card>
    </Box>
  );
};

const renewalFilters = [
  <SelectInput
    label="Payment Status"
    source="paymentStatus"
    alwaysOn
    emptyText="Clear Filter"
    choices={Object.values(RenewalPaymentStatus).map((key) => {
      return {
        id: key,
        name: key,
      };
    })}
  />,
  <DateInput label="Payment From Due Date" source="startDates" alwaysOn />,
  <DateInput label="Payment To Due Date" source="endDates" alwaysOn />,
  <SelectInput
    label="Bundle Status"
    source="status"
    alwaysOn
    emptyText="Clear Filter"
    choices={Object.values(RenewalBundleStatus).map((key) => {
      return {
        id: key,
        name: key,
      };
    })}
  />,
  <DateInput
    label="Customer Follow Up"
    source="nextCustomerFollowUpDate"
    alwaysOn
  />,
  <DateInput label="Cook Follow Up" source="nextCookFollowUpDate" alwaysOn />,
];

const ListActions = (props: any) => {
  const dispatch = useAppDispatch();
  return (
    <TopToolbar>
      <Button variant="text" 
        size={"small"}
        onClick={() => {
          dispatch(
            setRenewalFilter({
              renewalSideFilter: !props.openSideFilterShow,
            })
          );
        }}
        startIcon={props.openSideFilterShow ? <FilterAlt /> : <FilterAltOff />} color="primary"
      >
        Filter
      </Button>
      <ExportButton />
    </TopToolbar>
  );
};

const RenewalsList = (props: RenewalResponse) => {
  const showSideFilter = useAppSelector(
    (state) => state.customSelect.value.renewalSideFilter
  );
  return (
    <List
      {...props}
      filters={renewalFilters}
      actions={<ListActions openSideFilterShow={showSideFilter} />}
      aside={showSideFilter ? <FilterSidebar /> : (null as any)}
    >
      <Datagrid
        bulkActionButtons={false}
        rowStyle={(record, index) => {
          return {
            backgroundColor:
              record.status.toLowerCase() === "grace" ? "#ffdede" : "",
          };
        }}
      >
        <NumberField source="id" />
        <CustomReferenceField
          label="User Order Id"
          listName={"UserBundles"}
          source="userBundleMappingId"
        />
        <TextField label="Customer Name" source={"customerName"} />
        <FunctionField label= "Customer Mobile" render={(record: any) => <CallButton toPhoneNumber={record?.customerPhoneNumber} userType={UserType.CUSTOMER}/>}/>
        <TextField label="Payment Due Date" source={"endDate"} />
        <CustomReferenceField
          label="CookId"
          listName={"cooks"}
          source={"cookId"}
        />
        <TextField label="Cook Name" source={"cookName"} />
        <TextField label="Cook Mobile" source={"cookPhoneNumber"} />
        <TextField label="Escalation" source={"hasEscalations"} />
        <TextField label="PR status" source={"hasActivePr"} />
        <TextField
          label="Customer Follow-up"
          source="customerFollowUp[0].comment"
          sortable={false}
        />
        <TextField label="Cook Follow-up" source="cookFollowUp[0].comment" sortable={false}/>
        <TextField
          label="Next Customer Follow-up"
          source={"nextCustomerFollowUpDate"}
        />
        <TextField
          label="Next Cook Follow-up"
          source={"nextCookFollowUpDate"}
        />
        <TextField label="Payment Status" source={"paymentStatus"} />
        <TextField label="Bundle Status" source={"status"} />
        <TextField label="Subscription Plan Type" source={"bundleType"} />
        <TextField label="Subscription Price" source={"price"} />
        <TextField label="Assignee Name" source={"ownerName"} />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default RenewalsList;
