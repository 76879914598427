import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Create } from "react-admin";
import { WeightageTable } from "../../interfaces/ChefRating/weightageTable";

const WeightageTableShow = (props: any) => {
  return (
    <Create title={"Weightage Table"}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category Id</TableCell>
              <TableCell>Category Name</TableCell>
              <TableCell>Category Weightage</TableCell>
              <TableCell>Parameter Id</TableCell>
              <TableCell>Parameter Name</TableCell>
              <TableCell>Parameter Weightage</TableCell>
              <TableCell>SubCategory Id</TableCell>
              <TableCell>Subcategory Name</TableCell>
              <TableCell>SubCategory Weightage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.data?.map((rowData: WeightageTable, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell align="center">{rowData?.categoryId}</TableCell>
                  <TableCell align="center">{rowData?.categoryName}</TableCell>
                  <TableCell align="center">
                    {rowData?.categoryWeightage}
                  </TableCell>
                  <TableCell align="center">{rowData?.parameterId}</TableCell>
                  <TableCell align="left">{rowData?.parameterName}</TableCell>
                  <TableCell align="center">
                    {rowData?.parameterWeightage}
                  </TableCell>
                  <TableCell align="center">{rowData?.subCategoryId}</TableCell>
                  <TableCell align="center">
                    {rowData?.subCategoryName}
                  </TableCell>
                  <TableCell align="center">
                    {rowData?.subCategoryWeightage}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Create>
  );
};

export default WeightageTableShow;
