import { Close } from "@mui/icons-material";
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField
} from "@mui/material";
import  React from "react";
import { useRefresh } from "react-admin";
import { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import StarCleanerVisitService from "../../../services/StarCleanerVisitService";
import { StarChefVisitSlots } from "../../../enums/StarChefTimeSlots";
import { EventBusEventsEnum } from "../../../enums/EventBusEventsEnum";
import EventBus from "../../../EventBus";
import { CustomSnackbarTypes } from "../../Common/CustomSnackBar";

interface InputProps {
  data: any;
  open: boolean;
  callback: () => void;
}

export const RescheduleStarCleanerVisitDialog = (props: InputProps) => {
  const [rescheduleReason, setRescheduleReason] = useState<string>('')
  const [visitTime, setVisitTime] = useState<StarChefVisitSlots>();
  const [visitDate, setVisitDate] = useState<Date | null>(null);
  let refresh = useRefresh()
  const starChefVisitService = new StarCleanerVisitService()

  let handleClose = () => {
    props.callback()
  }
  return (
      <Dialog
        onClose={handleClose}
        open={props.open}
        scroll={"paper"}
        maxWidth="xl"
      >
        <DialogTitle>Reschedule StarCleaner Visit</DialogTitle>
        <DialogContent>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
          <Grid
            container
            spacing={2}
            direction="column"
            justifyContent={"space-evenly"}
            style={{ padding: "12px 12px 12px 12px" }}
          >
              <Grid item>
            <FormControl fullWidth>
            <TextField
                id="reason"
                onChange={(event) => {
                  setRescheduleReason(event.target.value);
                }}
                value={rescheduleReason}
                label="Enter Reschedule reason"
                variant="outlined"
              />
            </FormControl>
            </Grid>
            <Grid item>
            <DatePicker
              label="Select Date"
              inputFormat="dd MMM yyyy"
              disableMaskedInput
              minDate={new Date()}
              maxDate={moment().add(30, 'd').toDate()}
              value={visitDate}
              onChange={(newValue) => {
                setVisitDate(newValue);
              }}
              renderInput={(params) => {
                return <TextField {...params} />;
              }}
            />
            </Grid>
            <Grid item>
            <FormControl fullWidth >
                <InputLabel id="for_visitTime">Visit Time</InputLabel>
                <Select
                  labelId="for_visitTime"
                  id="visitTime"
                  defaultValue={''}
                  label="VisitTime"
                  onChange={(event) => setVisitTime(event.target.value as StarChefVisitSlots)}
                >
                  {Object.values(StarChefVisitSlots).map((e: any, i: number) => (
                    <MenuItem key={i} value={e}>
                      {e}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Grid>

          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              if (!rescheduleReason || !visitDate || !visitTime) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Fill the details to proceed",
                    type: CustomSnackbarTypes.WARNING,
                });
                return;
              }
              starChefVisitService
              .rescheduleStarCleanerVisit(props.data.id, {
                 rescheduleReason,
                 visitDate: moment(visitDate).format("YYYY-MM-DD"),
                 visitTime
                })
                .then((res) => {
                  refresh();
                  handleClose()
                });
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              handleClose();
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  );
};
