import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import LocalityList from "../Locality/LocalityList";
import ResourceSelectDialog from "./ResourceSelectDialog";

interface InputProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>
    callback: (localityId: number) => void;
}

function UpdateHouseSlcDialog(props: InputProps) {
    const [localityId, setLocalityId] = useState<number>(-1)
    const [localityName, setLocalityName] = useState();
    const [localityListOpen, setLocalityListOpen] = useState(false);
    const dispatch = useAppDispatch();

    const handleClose = (d: any) => {
        if(d?.id){
        setLocalityId(d.id)
        }
        if(d?.name) {
        setLocalityName(d.name)
        }
        setLocalityListOpen(false)
        dispatch(setCustomSelectOpen({
            open: false
        }))
    
    }

    return (
        <div>
            {localityListOpen &&
                <ResourceSelectDialog
                    name={"Localities"}
                    list={LocalityList}
                    onClose={handleClose}
                />
            }

            <Dialog
                open={props.open}
                scroll={"paper"}
                maxWidth="xl"
            >
                <DialogTitle>Update SLC of a house</DialogTitle>
                <DialogContent>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 10
                        }}
                    >
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => {
                                setLocalityListOpen(true)
                                dispatch(setCustomSelectOpen({
                                    open: true
                                }))
                            }}
                        >
                            {localityId !== -1 ? `Selected Locality: ${localityName}` : `Select Locality`}
                        </Button>

                    </div>
                </DialogContent>
                <DialogActions style={{ marginTop: "12px" }}>

                    <Button
                        variant="contained"
                        color={"primary"}
                        onClick={() => {
                            props.callback(localityId)
                            props.setOpen(false);
                        }}
                        size={"large"}
                    >
                        Submit
                    </Button>
                    <Button
                        variant="contained"
                        color={"error"}
                        onClick={() => {
                            props.setOpen(false);
                        }}
                        size={"large"}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default UpdateHouseSlcDialog;