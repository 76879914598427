import { GetListResult } from "react-admin";
import HttpClient from "../HttpClient";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";
import {RefundResponseV2} from "../interfaces/Refund/RefundResponseV2";
import Op from "../interfaces/SequalizeFilters/SequalizeTypes";
import moment from "moment";
import { RazorpayOrderReferenceModelEnums } from "../enums/RazorpayOrderReferenceModelEnums";

export class RefundServiceV2 {
  processDateRangeFilter(filters: any) {
    let createdAt;
    if (filters.createdAt) {
      createdAt = {
        [Op.gte.toString()]: moment(filters.createdAt)
      }
    }
    if (filters.updatedAt) {
      if (createdAt) {
        createdAt = {
          ...createdAt,
          [Op.lte.toString()]: moment(filters.updatedAt).add(1, 'd')
        }
      } else {
        createdAt = {
          [Op.lte.toString()]: moment(filters.updatedAt).add(1, 'd')
        }
      }
    }
    return {createdAt};
  }
  async getAllRefundsV2(
    limit: number,
    offset: number,
    filters: any,
    sort: any
  ): Promise<GetListResult<RefundResponseV2>> {
    let filter = ConstructSequelizeFilters(
      filters,
      [ 'status','customerPhone', 'processedBy']
    );
    let customFilters = this.processDateRangeFilter(filters);
    filters = {
      ...filter,
      ...customFilters,
    };
    let data = await HttpClient.get("/centaurus/bundle/refunds/v2", {
      params: {
        limit,
        offset,
        filters,
        sort,
      },
    });
    return {
      data: data?.data?.data,
      total: data?.data?.count,
    };
  }

  async getRefundsForHouse(houseId: number,modelName: RazorpayOrderReferenceModelEnums,limit: number, offset: number): Promise<GetListResult<any>>{
    let data = await HttpClient.get(
      `/centaurus/houses/${houseId}/refund-model/${modelName}`,
      {
        params: {
          limit,
          offset,
          sort: {"field":"id", "order":"DESC"},
        },
      }
    );
    return {
        data: data.data.data,
        total: data.data.count
    }
}

  async approveUserRefundV2(id: number): Promise<RefundResponseV2> {
    let data = await HttpClient.post(`/centaurus/bundle/refunds/v2/${id}/approve`);
    return data?.data
  }

  async rejectUserRefundV2(id: number): Promise<RefundResponseV2> {
    let data = await HttpClient.post(`/centaurus/bundle/refunds/v2/${id}/reject`);
    return data?.data
  }

  async getAllAccountsOfUserRefundV2(houseId: number) {
    let data = await HttpClient.get(`/centaurus/bundle/refunds/v2/house/${houseId}/accounts`);
    return data?.data
  }

  async initiatePayoutV2(id: number, payload: { masterAccountId: number }): Promise<RefundResponseV2> {
    let data = await HttpClient.post(`/centaurus/bundle/refunds/v2/${id}/initiate-payout`, payload);
    return data?.data
  }

  async retryPayoutV2(id: number, payload: { masterAccountId: number }): Promise<RefundResponseV2> {
    let data = await HttpClient.post(`/centaurus/bundle/refunds/v2/${id}/retry-payout`, payload);
    return data?.data
  }

  async initiateRefundV2(id: number): Promise<RefundResponseV2> {
    let data = await HttpClient.post(`/centaurus/bundle/refunds/v2/${id}/initiate-refund`);
    return data?.data
  }

  async retryRefundV2(id: number): Promise<RefundResponseV2> {
    let data = await HttpClient.post(`/centaurus/bundle/refunds/v2/${id}/retry-refund`);
    return data?.data
  }

  async createRefundForOrder(orderId: number, payload: any): Promise<RefundResponseV2> {
    let data = await HttpClient.post(`/centaurus/refunds/v2/create-refund-for-order/${orderId}`, payload);
    return data?.data
  }
}
