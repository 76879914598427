import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Link,
  } from "@mui/material";
  import moment from "moment";
  import React from "react";
  
  const ChefForPartyListForModule = (props: any) => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>No of Adults</TableCell>
              <TableCell>No of Children</TableCell>
              <TableCell>No of Dish</TableCell>
              <TableCell>Total Amount</TableCell>
              <TableCell>Token Amount Payment Status</TableCell>
              <TableCell>Remaining Amount Payment Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row: any) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell scope="row">{row?.id}</TableCell>
                <TableCell>{moment(row?.date).format('LLL')}</TableCell>
                <TableCell>{row?.time}</TableCell>
                <TableCell align="left">{row?.status}</TableCell>
                <TableCell align="left">{row?.noOfAdults}</TableCell>
                <TableCell align="left">{row?.noOfChildren}</TableCell>
                <TableCell align="left">{row?.noOfDishes}</TableCell>
                <TableCell align="left">{row?.totalAmount}</TableCell>
                <TableCell align="left">{row?.tokenAmountPaymentStatus}</TableCell>
                <TableCell align="left">{row?.remainingAmountPaymentStatus}</TableCell>
                <TableCell align="center">
                    <Link href={`/#/ChefForParty/${row.id}/show`}>
                        <Button variant={'contained'} color={'primary'}>
                            Show
                        </Button>
                    </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  
  export default ChefForPartyListForModule;
  