import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const CookLeavesTable=(props:any)=>{
    return(
        <TableContainer component={Paper}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>CookId</TableCell>
                    <TableCell>Leave Type</TableCell>
                    <TableCell>Start Date/Time</TableCell>
                    <TableCell>End Date/Time</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Requested By</TableCell>
                    <TableCell/>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.data.map((row: any) => (
                    <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell scope="row">{row?.id}</TableCell>
                        <TableCell align="left">{row?.leaveType}</TableCell>
                        <TableCell align="left">{row?.startDate+" "+row?.startTime}</TableCell>
                        <TableCell align="left">{row?.endDate+" "+row?.endTime}</TableCell>
                        <TableCell align="left">{row?.state}</TableCell>
                        <TableCell align="left">{row?.requestedBy}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
    )
}
export default CookLeavesTable;