import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import { useState } from "react";
import { Create } from "react-admin";
import { useNavigate } from "react-router";
import { vendorCategory } from "../../enums/common";
import { VendorService } from "../../services/VendorServices";

const vendorService = new VendorService();

const VendorCreate = (props: any) => {
  const [name, setFname] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const navigate = useNavigate();

  return (
    <Create {...props}>
      <Grid
        container
        direction="column"
        columns={1}
        rowSpacing={1}
        style={{ padding: "12px 12px 12px 12px" }}
      >
        <Grid item xs={4}>
          <TextField
            label="First Name"
            sx={{width: "300px"}}
            value={name}
            type="string"
            onChange={(e: any) => {
              setFname(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Phone Number"
            value={phone}
            type="string"
            sx={{width: "300px"}}
            onChange={(e: any) => {
              setPhone(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <InputLabel id="forCategory">Category</InputLabel>
            <Select
              labelId="forCategory"
              id="category"
              label="Choose Category"
              value={category}
              onChange={(e: any) => {
                setCategory(e.target.value);
              }}
              sx={{width: "300px"}}
            >
              {Object.values(vendorCategory).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => {
              vendorService
                .createVendor({
                  name: name,
                  phoneNumber: phone,
                  category: category,
                })
                .then((data) => {
                  if (data) {
                    navigate(`/Vendors`, {
                      replace: true,
                    });
                  }
                });
            }}
            size={"large"}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Create>
  );
};

export default VendorCreate;
