import HttpClient from "../HttpClient";

export class EscalationService {
    async getModuleAndAction(): Promise<any> {
        
        let data = await HttpClient.get('/reasonSubreason/module/action-types')
        return {
            data: data.data,
            
        }
    }
}