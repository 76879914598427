import {Button, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

const HouseStarCleanerVisitsTable = (props: any) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>status</TableCell>
                        <TableCell>visitDate</TableCell>
                        <TableCell>visitTime</TableCell>
                        <TableCell>houseId</TableCell>
                        <TableCell>createdByType</TableCell>
                        <TableCell>cancellationReason</TableCell>
                        <TableCell/>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((row: any) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell scope="row">{row?.id}</TableCell>
                            <TableCell align="left">{row?.status}</TableCell>
                            <TableCell align="left">{row?.visitDate}</TableCell>
                            <TableCell align="left">{row?.visitTime}</TableCell>
                            <TableCell align="left">{row?.houseId}</TableCell>
                            <TableCell align="left">{row?.createdByType}</TableCell>
                            <TableCell align="left">{row?.cancellationReason}</TableCell>
                            <TableCell align="left">
                                <Link
                                    href={`/#/StarCleaner/${row.id}/show`}
                                >
                                    <Button variant={'contained'} color={'primary'}>
                                        Show
                                    </Button>
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default HouseStarCleanerVisitsTable;
