import React, { useState, useEffect } from "react";
import { CustomerService } from "../../services/CustomerService";
import CircularLoader from "../Common/CircularLoader";
import TransactionListForCustomerModule from "../Transactions/TransactionListForCustomerModule";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box } from "@mui/material";
import Button from '@mui/material/Button';



interface InputProps {
  customerId: number;
}

let customerService = new CustomerService();

const CustomerTransaction = (props: InputProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [subData, setSubData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>();

  const handleChange = (event: SelectChangeEvent) => {
    setRowsPerPage(event.target.value);
  };


  const refreshData = (
    customerId: number,
    limit: number,
    offset: number,
  ) => {
    setIsLoading(true);
    customerService
      .getAllTransactionForCustomer(customerId, limit, offset)
      .then((data) => {
        setSubData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    refreshData(props.customerId, Number(rowsPerPage), page * Number(rowsPerPage));
  }, [props.customerId, rowsPerPage, page]);

  return (
    <div>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <div>
          {subData &&
            subData.data &&
            subData.data.data &&
            Array.isArray(subData.data.data) &&
            (subData.data.data.length > 0 ? (
              <TransactionListForCustomerModule data={subData?.data.data} />
            ) : (
              <EmptyListPlaceholder message="No Transactions Found" />
            ))}
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }} >
            <FormControl size="medium">
              <InputLabel id="demo-simple-select-label">Rows</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                label="No of Rows"
                onChange={handleChange}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
            {
              page === 0 ? <Button size="small" disabled>Previous</Button> : <Button size="small" onClick={() => setPage(page - 1)}>Previous</Button>
            }
            <Button size="small" onClick={() => setPage(page + 1)}>Next</Button>
          </Box>
        </div>
      )}
    </div>
  );
};

export default CustomerTransaction;

