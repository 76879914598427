import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Stack,
  Grid,
  Button,
  Dialog, DialogTitle, DialogContent, InputLabel, Select, MenuItem, FormControl, DialogActions,
} from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import {
  ArrayField,
  Datagrid,
  FunctionField,
  RaRecord,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { UserType } from "../../enums/common";
import { CookResponse } from "../../interfaces/Cook/CookResponse";
import { CookService } from "../../services/CookServices";
import CallButton from "../Common/CallButton/CallButton";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import Timer from "../Common/Timer";
import Cooks from "../Cooks/Cooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import { useAppDispatch } from "../../store/hooks";
import { TrialsService } from "../../services/TrialServices";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import { FindCookStatus } from "../../enums/FindCookStatusEnum";
import AllocateTrialDialog from "../Common/AllocateTrialDialog";
import AddSupplyNotesDialog from "../Common/AddSupplyNotesDialog";
import CustomReferenceField from "../Common/CustomReferenceField";
import ReasonSubreasonModuleEnum from "../../enums/ReasonSubreasonModuleEnum";
import {ActionTypeEnum} from "../../enums/ActionTypeEnum";
import {getReasonCategory} from "../Common/constants/constant";

const cookService = new CookService();
const trialService = new TrialsService();

interface CardComponentInputProps {
  commenterId: number;
  commenterName: string;
  commentDate: string;
  comment: string;
}

const CustomCardComponent = ({
  commenterId,
  commenterName,
  commentDate,
  comment,
}: CardComponentInputProps) => {
  return (
    <Card sx={{ maxWidth: 420, padding: 0, margin: 0 }} elevation={0}>
      <CardHeader
        title={`#${commenterId} ${commenterName}`}
        subheader={commentDate}
      />
      <CardContent sx={{ overflow: "auto" }}>
        <Typography
          sx={{ wordWrap: "break-word" }}
          variant="body2"
          color="text.secondary"
        >
          {String(comment)}
        </Typography>
      </CardContent>
    </Card>
  );
};

interface InputProps {
  trialId: number;
}

const FindCookTabView = (props: InputProps) => {
  const [cookDetails, setCookDetails] = useState<CookResponse | null>(null);
  const [selectCookDialog, setSelectCookDialog] = useState<boolean>(false);
  const [openCookNotFound, setOenCookNotFound] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [supplyNotesDialog, setSupplyNotesDialog] = useState<boolean>(false);
  const record = useRecordContext();
  const dispatch = useAppDispatch();
  const refreshReactAdmin = useRefresh();
  const [rescheduleReasonsOptionMapping,setRescheduleReasonsOptionMapping]=useState<any>([]);
  const [reasonCategory, setReasonCategory] = useState<string | null>(null);
  const refresh = useCallback(() => {
    if (!record?.foundCookId) {
      return;
    };
    cookService.getOneCook(record?.foundCookId).then((res) => {
      setCookDetails(res.data);
    });
  }, [record?.foundCookId]);

  const loadReasonSubReason = useCallback(async () => {
    let sampleReasonSubReason = await getReasonCategory(ReasonSubreasonModuleEnum.TRIAL, ActionTypeEnum.COULD_NOT_FIND_COOK);
    setRescheduleReasonsOptionMapping(sampleReasonSubReason);
  }, []);

  useEffect(() => {
    loadReasonSubReason();
  }, [loadReasonSubReason]);

  const handleReasonCategoryChange = (event: any) => {
    const selectedCategory:any = rescheduleReasonsOptionMapping.find(
        (option:any) => option.category === event.target.value
    );

    if (selectedCategory) {
      setReasonCategory(event.target.value);
    }
  }

  const handleSelect = (recordData: any) => {
    setSelectCookDialog(false);
    dispatch(
      setCustomSelectOpen({
        open: false,
      })
    );
    if (recordData?.id) {
      trialService
        .setCookBySupply(props.trialId, { cookId: recordData?.id })
        .then(() => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Cook Suggested Successfully",
            type: CustomSnackbarTypes.SUCCESS,
          });
        })
        .finally(() => {
          refreshReactAdmin();
        });
    }
  };

  const handleSelectCook = () => {
    dispatch(
      setCustomSelectOpen({
        open: true,
      })
    );
  };

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <div>
      {selectCookDialog && (
        <ResourceSelectDialog
          name={"Cooks"}
          list={Cooks}
          onClose={handleSelect}
        />
      )}
      <Stack direction="row" spacing={2}>
        <Grid container spacing={2} justifyContent="flex-end">
          {record?.findCookStatus === FindCookStatus.PENDING && (
            <Grid item>
              <Button
                onClick={() => {
                  trialService
                    .initiateFindCookProcessBySupply(props.trialId)
                    .then(() => {
                      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: "Find Cook Initiated Successfully",
                        type: CustomSnackbarTypes.SUCCESS,
                      });
                    })
                    .finally(() => {
                      refreshReactAdmin();
                    });
                }}
                color={"secondary"}
                style={{ width: "max-content", fontSize: "12px" }}
                size="small"
                variant="contained"
              >
                Initiate Find Cook
              </Button>
            </Grid>
          )}

          {
            ![FindCookStatus.PENDING, FindCookStatus.OTHER_ALLOCATED, FindCookStatus.SAME_ALLOCATED, FindCookStatus.COOK_NOT_FOUND].includes(record?.findCookStatus) &&
              <Grid item>
                <Button
                  onClick={() => {
                    setSelectCookDialog(true);
                    handleSelectCook();
                  }}
                  color={"secondary"}
                  style={{ width: "max-content", fontSize: "12px" }}
                  size="small"
                  variant="contained"
                >
                  {record?.foundCookId ? "Change Cook" : "Select Cook"}
                </Button>
              </Grid>
          }

          {
            [FindCookStatus.INITIATED].includes(record?.findCookStatus) &&
              <Grid item>
                <Button
                  onClick={() => {
                    setOenCookNotFound(true);
                  }}
                  color={"error"}
                  style={{ width: "max-content", fontSize: "12px" }}
                  size="small"
                  variant="contained"
                >
                  {"Mark Cook Not Found"}
                </Button>
              </Grid>
          }

          <Grid item>
            <Button
              onClick={() => {
                setSupplyNotesDialog(true);
              }}
              color={"secondary"}
              style={{ width: "max-content", fontSize: "12px" }}
              size="small"
              variant="contained"
            >
              Add Supply Notes
            </Button>
          </Grid>

          {![FindCookStatus.OTHER_ALLOCATED, FindCookStatus.SAME_ALLOCATED].includes(record?.findCookStatus) &&
            record?.foundCookId && (
            <Grid item>
              <Button
                onClick={() => {
                  setOpenDialog(true);
                }}
                color={"success"}
                style={{ width: "max-content", fontSize: "12px" }}
                size="small"
                variant="contained"
              >
                Allocate
              </Button>
            </Grid>
          )}

          {![FindCookStatus.OTHER_ALLOCATED, FindCookStatus.SAME_ALLOCATED].includes(record?.findCookStatus) &&
            record?.foundCookId && (
            <Grid item>
              <Button
                onClick={() => {
                  trialService
                    .startFindCookProcess(props.trialId)
                    .then((res) => {
                      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: "Cook Rejected Successfully",
                        type: CustomSnackbarTypes.SUCCESS,
                      });
                      setCookDetails(null);
                    })
                    .finally(() => {
                      refreshReactAdmin();
                    });
                }}
                color={"error"}
                style={{ width: "max-content", fontSize: "12px" }}
                size="small"
                variant="contained"
              >
                Reject
              </Button>
            </Grid>
          )}
        </Grid>
      </Stack>
      {openDialog && (
        <AllocateTrialDialog
          data={{ cookId: cookDetails?.id, trialId: props?.trialId }}
          open={openDialog}
          setOpen={setOpenDialog}
          cookCut={record?.cookCut}
        />
      )}
      {supplyNotesDialog && (
        <AddSupplyNotesDialog
          trialId={Number(props?.trialId)}
          isOpen={supplyNotesDialog}
          setIsOpen={setSupplyNotesDialog}
          refresh={refreshReactAdmin}
        />
      )}

      <Show actions={false} {...props}>
        <div style={{ display: "grid", gridTemplateColumns: "30% 30% 40%" }}>
          <SimpleShowLayout>
            <FunctionField
              label="Time Since Trial Created"
              render={(record: RaRecord) => {
                if (moment().isAfter(moment(`${record?.date}:${record?.time}`))) return <span>Expired</span>
                return (
                  <Timer
                    startTime={moment(record?.createdAt).toDate()}
                    endTime={moment(`${record?.date}:${record?.time}`).toDate()}
                  />
                );
              }}
            />
            <FunctionField
              label="Time Since FindCook"
              render={(record: RaRecord) => {
                if (!record?.findCookStartDateTime)
                  return <span>Not Started</span>;
                return (
                  <Timer
                    startTime={moment(record?.findCookStartDateTime).toDate()}
                    endTime={moment(`${record?.date}:${record?.time}`).toDate()}
                  />
                );
              }}
            />
            <CustomReferenceField label="Found Cook Id" listName={"Cooks"} source={"foundCookId"}/>
            <FunctionField
              label="Found Cook Name"
              render={() => {
                if (!cookDetails) return <span>Not Available</span>;
                return (
                  <span>{`${cookDetails?.cookFullProfile?.firstName} ${cookDetails?.cookFullProfile?.lastName}`}</span>
                );
              }}
            />
            <FunctionField
              label="Found Cook Phone Number"
              render={() => (
                <CallButton
                  toPhoneNumber={cookDetails?.mobile || ""}
                  userType={UserType.CUSTOMER}
                />
              )}
            />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField
              label="Found Cook Status"
              source="findCookStatus"
              emptyText="Not Available"
            />
            <TextField
              label="Could Not Find Cook Reason"
              source="couldNotFindCookReason"
              emptyText="Not Available"
            />
            <CustomReferenceField
              label="Found Cook Owner"
              source="findCookOwnerId"
              listName="AdminUsers"
            />
            <TextField label="Found Cook Owner Name" source="findCookOwnerName" emptyText="Not Available"/>
            <TextField
              label="Found Cook Times"
              source="findCookTimes"
              emptyText="Not Available"
            />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <Card
              style={{
                padding: 0,
                overflow: "auto",
              }}
              elevation={0}
            >
              <SimpleShowLayout sx={{ padding: 0 }}>
                <ArrayField source="supplyComments" label="Supply Comments">
                  <Datagrid sx={{ padding: 0 }} bulkActionButtons={false}>
                    <SimpleShowLayout sx={{ padding: 0 }}>
                      <FunctionField
                        render={(record: RaRecord) => (
                          <CustomCardComponent
                            commenterId={record?.commenterId}
                            commenterName={record?.commenterName}
                            commentDate={moment(record?.timestamp).format(
                              "LLLL"
                            )}
                            comment={record?.comment}
                          />
                        )}
                      />
                    </SimpleShowLayout>
                  </Datagrid>
                </ArrayField>
              </SimpleShowLayout>
            </Card>
          </SimpleShowLayout>
        </div>
      </Show>
      <Dialog open={openCookNotFound} onClose={() => {setOenCookNotFound(false)}}>
        <DialogTitle>
          Cook Not Found Reason
        </DialogTitle>
        <DialogContent>
          <FormControl style={{ width: 500 }}>
            <InputLabel id="select-label">Tell us Why?</InputLabel>
            <Select
                labelId="select-label"
                id="select-label"
                defaultValue="Tell us Why?"
                value={reasonCategory}
                onChange={handleReasonCategoryChange}
            >
              {rescheduleReasonsOptionMapping.map((item:any) => {
                return (
                    <MenuItem key={item.category} value={item.category}>
                      {item.category}
                    </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant={'outlined'} onClick={() => {
            setOenCookNotFound(false);
          }}>
            {'Cancel'}
          </Button>
          <Button variant={'contained'} onClick={() => {
            if (!reasonCategory) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Fill the details to proceed",
                type: CustomSnackbarTypes.WARNING
              });
              return;
            }
            trialService.changeFoundCookStatus(props.trialId, { reason: reasonCategory, status: FindCookStatus.COOK_NOT_FOUND })
                .then(() => {
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Successfully Marked Cook Not Found",
                    type: CustomSnackbarTypes.SUCCESS,
                  });
                  setOenCookNotFound(false);
                  refreshReactAdmin();
                })
          }}>
            {'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FindCookTabView;
