import { useEffect, useState } from "react";
import { ShortTimeEnum } from "../../enums/ShortTimeEnum";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import moment from "moment";

export const PartnerLineChart = (props: any) => {
  const [timeSlotData, setTimeSlotData] = useState<any[]>();
  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    const getTimeSlot = async () => {
      const apiData = props.data;
      const cookSlots = [];
      for (let i = 0; i < apiData.length; i++) {
        cookSlots.push(apiData[i]);
      }
      return cookSlots;
    };
    const getData = async () => {
      const data = await getTimeSlot();
      setTimeSlotData(data);
      setIsDataFetched(true);
    };
    getData();
  }, [props]);

  const getColor = (time: String): string => {
    let color = "yellow";
    if(timeSlotData!.length === 0){
      color = "red"
    }
    for (let i = 0; i < timeSlotData!.length; i++) {
      if (timeSlotData![i]!.timeSlot === time) {
        if (timeSlotData![i].status === "FREE") {
          color = "green";
        } else if (timeSlotData![i].status === "BLOCKED") {
          color = "red";
        } else if (timeSlotData![i].status === "CK_BLOCKED") {
          color = "yellow"
        }
      }
    } 
    return color;
  };

  return isDataFetched ? (
    <table>
      <thead>
        <tr>
          {Object.values(ShortTimeEnum).map((item: any) => {
            return <th key={item}>{moment(item, 'hh:mm A').format('HH:mm')}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        <tr>
          {Object.values(ShortTimeEnum).map((item: any) => {
            return (
              <td
                key={item}
                style={{
                  backgroundColor: getColor(item),
                  height: "5px",
                  width: "5px",
                  padding: "0px",
                }}
              ></td>
            );
          })}
        </tr>
      </tbody>
    </table>
  ) : (
    <Box sx={{ display: "flex" }}>
      <CircularProgress />
    </Box>
  );
};