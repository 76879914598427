import moment from "moment";
import { GetListResult, GetOneResult } from "react-admin";
import HttpClient from "../HttpClient";
import Op from "../interfaces/SequalizeFilters/SequalizeTypes";
import { TrialsConverted } from "../interfaces/TrialsConverted/TrialsConverted";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";

export class TrialsConvertedService {

  processDateRangeFilter(filters: any) {
    let createdAt;
    if (filters.date) {
        createdAt = {
            [Op.between.toString()]: [moment(filters.date), moment(filters.date).add(1,'d')]
        }
        delete filters.date
    }
    return {createdAt};
}

  async getAllTrialsConverted(
    limit: number,
    offset: number,
    filters: any,
    sort: any
  ): Promise<GetListResult<TrialsConverted>> {
      if(filters?.informedToChef === 'true'){
          filters.informedToChef = true
      }
      if(filters.informedToChef === 'false') {
          filters.informedToChef = false
      }
    let filter = ConstructSequelizeFilters(filters, ['adminNotes', 'createdByName', 'modifiedByName', 'startDate', 'endDate'], ['informedToChef', 'createdAt'])
    let customFilters = this.processDateRangeFilter(filters);
    filters = {
      ...filter,
      ...customFilters
  }
    let data = await HttpClient.get("/centaurus/trialConverted/", {
      params: {
        limit,
        offset,
        filters,
        sort
      },
    });
    return {
      data: data.data.data,
      total: data.data.count,
    };
  }

  async getOneTrialsConverted(id: number): Promise<GetOneResult<TrialsConverted>> {
    let res = await HttpClient.get(`/centaurus/trialConverted/${id}`);
    return res;
  }

  async addOrUpdateAdminNotes(id: number, payload: {adminNotes: string}){
    let res = await HttpClient.patch(`/centaurus/trialConverted/${id}/admin-notes`, payload);
    return res;
  }

  async informedChef(id: number, payload: {informedToCook: boolean}){
    let res = await HttpClient.patch(`/centaurus/trialConverted/${id}/informed-cook/`, payload);
    return res;
  }
}
