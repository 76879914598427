import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useRefresh } from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { TemporaryReplacementResponse } from "../../interfaces/TemporaryReplacement/TemporaryReplacementResponse";
import { TempReplacementService } from "../../services/TemporaryReplacement";
import { CustomSnackbarTypes } from "./CustomSnackBar";

interface InputProps {
  data: TemporaryReplacementResponse;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

interface item{
  category: string;
  subCategory: string[];
}

const categoryItems: item[] = [
  {
    category: "Cook",
    subCategory: ["Communication (Call not picked, Not reachable, Switched off, etc)", "Location (Not able to find the address)", "Occupied (Still working in the previous household)", "Sickness (Cook is sick)", "Travel (Not able to book the ride)", "Exceptional (Bad weather, Rain, Accident, etc.)"]
  },
  {
    category: "Chefkart",
    subCategory: ["Unavailability", "Wrong Allocation", "Incorrect Credentials (CX Address or number updated on the Centaurus was not correct)",]
  },
  {
    category: "Others",
    subCategory: []
  }
]

const tempService = new TempReplacementService();

const MarkFailedDialogForTR = (props: InputProps) => {
  const refresh = useRefresh();
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [reasonSubCategory, setReasonSubCategory] = useState<string | null>(null);

  const handleReasonCategoryChange = (event: any) => {
    setSelectedCategory(event.target.value)
  };

  const handleReasonSubCategoryChange = (event: any) => {
    setReasonSubCategory(event.target.value);
  };

  function showSubCategoryOrNot(reasonCategory: string) {
    if(reasonCategory === null) {
      return null
    }
    if (reasonCategory?.toLowerCase() === "others" || reasonCategory?.toLowerCase() === 'other') {
      return (
        <FormControl style={{ width: 500 }} fullWidth>
          <TextField
            value={reasonSubCategory}
            onChange={handleReasonSubCategoryChange}
            id="outlined-basic"
            label="Write a reason"
            variant="outlined"
          />
        </FormControl>
      );
    } else {
      let selectedCategoryItem = categoryItems?.filter(e => e.category === reasonCategory)[0]
      return (
        <FormControl style={{ width: 520 }} fullWidth>
          <InputLabel id="demo-simple-select-label">Select a reason</InputLabel>
          <Select
            autoWidth={false}
            className="input_field"
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            defaultValue="Select a reason"
            value={reasonSubCategory}
            onChange={handleReasonSubCategoryChange}
          >
            {selectedCategoryItem?.subCategory.map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    }
  }

  const handleClose = () => {
    props.setOpen(false);
    setSelectedCategory("");
    setReasonSubCategory("")
  };

  const handleSubmit = () => {
    if (selectedCategory && reasonSubCategory) {
      let payload = {
        reason: selectedCategory + "-" + reasonSubCategory,
      };
      tempService.markFailed(props?.data?.id, payload).then(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Successful",
          type: CustomSnackbarTypes.SUCCESS,
        });
        refresh();
        props.setOpen(false);
      });
    }else{
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Please Fill the Reason",
        type: CustomSnackbarTypes.ERROR,
      });
    }
  };

  return (
    <Dialog open={props.open} scroll={"paper"} >
      <DialogTitle>Mark Failed</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          justifyContent={"space-evenly"}
          style={{ padding: "12px 12px 12px 12px" }}
          alignItems="center">
            <Grid item xs={12}>
              <FormControl style={{ width: 520 }} fullWidth>
                <InputLabel id="select-label">Tell us Why?</InputLabel>
                <Select
                  autoWidth={false}
                  className="input_field"
                  labelId="select-label"
                  id="demo-multiple-name"
                  value={selectedCategory}
                  onChange={handleReasonCategoryChange}
                >
                  {categoryItems.map((item: item) => {
                    return (
                      <MenuItem key={item.category} value={item.category}>
                        {item.category}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {selectedCategory && (
              <Grid item xs={12}>
                {showSubCategoryOrNot(selectedCategory as string)}
              </Grid>
            )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size={"large"}
          variant="contained"
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            handleClose();
            setSelectedCategory("");
            setReasonSubCategory("")
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MarkFailedDialogForTR;
