import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useRecordContext } from "react-admin";
import { CookCategoryEnum } from "../../enums/CookCategoryEnum";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import VendorPayoutService from "../../services/VendorPayoutService";
import { useAppDispatch } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import Cooks from "../Cooks/Cooks";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import ResourceSelectDialog from "./ResourceSelectDialog";

interface InputProps {
  open: boolean;
  setOpen: (open: boolean) => any;
}

const vendorPayoutService = new VendorPayoutService();

const CookReferralDialog = (props: InputProps) => {
  const [cookCategory, setCookCategory] = useState<string>("");
  const [referralAmount, setReferralAmount] = useState<string>("");
  const [selectedCook, setSelectedCook] = useState<any>(null);
  const dispatch = useAppDispatch();
  const record: any = useRecordContext();

  const handleSelectCook = () => {
    dispatch(
      setCustomSelectOpen({
        open: true,
      })
    );
  };

  const handleClose = (recordData: any) => {
    setSelectedCook(recordData);  
    setCookCategory(recordData?.category)
    if (recordData?.category === "Popular") {
      setReferralAmount("250");
    }
    else if (recordData?.category === "Premium") {
      setReferralAmount("500");
    } 
    else {
      setReferralAmount("250");
    }
  };

  const handleCancel = () => {
    props.setOpen(false);
  };

  const handleSubmit = () => {
    vendorPayoutService
      .CreateReferralPayouts({
        referralCookId: Number(record?.id),
        referredCookId: Number(selectedCook?.id),
        cookCategory,
        amount: referralAmount,
      })
      .then((res) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "successful",
          type: CustomSnackbarTypes.SUCCESS,
        });
      })
    props.setOpen(false);
    setSelectedCook(null);
    setReferralAmount("");
    setCookCategory("");
  };

  return (
    <div>
      {!!props.open && (
        <ResourceSelectDialog
          name={"Cooks"}
          list={Cooks}
          onClose={handleClose}
        />
      )}
      <Dialog open={props.open}>
        <DialogTitle> {"Cook Referral"} </DialogTitle>
        <DialogContent>
          <FormControl>
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={handleSelectCook}
              style={{ marginBottom: "10px" }}
            >
              Select Cook
            </Button>
            {!!selectedCook && (
              <p>Selected cook - {selectedCook?.fname + selectedCook?.lname}</p>
            )}
            <FormControl fullWidth>
              <InputLabel id="simple-select-label">Cook Category</InputLabel>
              <Select
                labelId="simple-select-label"
                value={cookCategory}
                label="Cook Category"
                onChange={(event) => {
                  setCookCategory(event.target.value)
                }}
              >
               {Object.values(CookCategoryEnum).map((e) => (
                    <MenuItem key={e} value={e}>
                        {e}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Referral Amount"
              type="number"
              value={referralAmount}
              style={{ marginTop: "20px" }}
              onChange={(event) => {
                setReferralAmount(event.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCancel();
              setSelectedCook(null);
            }}
            variant="contained"
            color="error"
          >
            {"Cancel"}
          </Button>
          <Button
            onClick={() => {
              if (Number(referralAmount) >= 0) {
                handleSubmit();
              } else {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: `Fill the details properly`,
                  type: CustomSnackbarTypes.ERROR,
                });
              }
            }}
            variant="contained"
            color="success"
          >
            {"Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CookReferralDialog;
