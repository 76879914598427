import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useRecordContext, useRefresh } from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import NotConvertedReasonsCategories from "../../enums/NotConvertedReasonsCategories";
import EventBus from "../../EventBus";
import { TrialsService } from "../../services/TrialServices";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { getReasonCategory } from "./constants/constant";
import { TrialsV3Status } from "../../enums/TrialsV3Status";
import { ActionTypeEnum } from "../../enums/ActionTypeEnum";
import ReasonSubreasonModuleEnum from "../../enums/ReasonSubreasonModuleEnum";

interface Props {
  data: any;
  open: boolean;
  cookSideCustomerSide: string;
  callback: (data: any) => void;
}

const trialService = new TrialsService();

const NotConvertedReasonDialog = (props: Props) => {
  let record = useRecordContext();
  const [notConvertedReasonOptionMapping,setNotConvertedReasonOptionMapping] =useState([]);
  const [reason, setReason] = useState<string>("");
  const [reasonSubCategoryArray, setReasonSubCategoryArray] = useState<string[] | null>(null);
  const [reasonCategory, setReasonCategory] = useState<string | null>(null);
  const [reasonSubCategory, setReasonSubCategory] = useState<string | null>(null);
  const refresh = useRefresh();

  const loadData = useCallback(async () => {
    switch (record.status) {
      case TrialsV3Status.FULFILLED:
        let sampleReasonSubReason1:any = [];
        if(props?.cookSideCustomerSide === ActionTypeEnum.NOT_CONVERTED_COOK_ISSUES) {
          sampleReasonSubReason1= await getReasonCategory(ReasonSubreasonModuleEnum.TRIAL,ActionTypeEnum.NOT_CONVERTED_COOK_ISSUES);
        } else{
          sampleReasonSubReason1= await getReasonCategory(ReasonSubreasonModuleEnum.TRIAL,ActionTypeEnum.NOT_CONVERTED_CX_ISSUES);
        }
        setNotConvertedReasonOptionMapping(sampleReasonSubReason1);
        break;
    }
  }, [props?.cookSideCustomerSide, record?.status]);

  useEffect(()=>{
    loadData();
  },[loadData])

  let handleClose = () => {
    setReasonSubCategoryArray(null);
    setReasonCategory(null);
    setReasonSubCategory(null);
    props.callback(reason);
  };

  const handleReasonCategoryChange = (event: any) => {
    const selectedCategory:any = notConvertedReasonOptionMapping.find(
      (option:any) => option.category === event.target.value
    );

    if (selectedCategory) {
      setReasonSubCategoryArray(selectedCategory?.subCategory);
      setReasonCategory(event.target.value);
    }
  };

  const handleReasonSubCategoryChange = (event: any) => {
    setReasonSubCategory(event.target.value);
    setReason(`${reasonCategory} and ${event.target.value}`);
  };

  function showSubCategoryOrNot(reasonCategory: string) {
    if (reasonCategory === NotConvertedReasonsCategories.OTHERS) {
      return (
        <FormControl style={{ width: 500 }} fullWidth>
          <TextField
            value={reasonSubCategory}
            onChange={handleReasonSubCategoryChange}
            id="outlined-basic"
            label="Write a reason"
            variant="outlined"
          />
        </FormControl>
      );
    } else {
      return (
        <FormControl style={{ width: 500 }} fullWidth>
          <InputLabel id="demo-simple-select-label">Select a reason</InputLabel>
          <Select
            autoWidth={false}
            className="input_field"
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            defaultValue="Select a reason"
            value={reasonSubCategory}
            onChange={handleReasonSubCategoryChange}
          >
            {reasonSubCategoryArray?.map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      scroll={"paper"}
      maxWidth="xl"
    >
      <DialogTitle>{props?.cookSideCustomerSide  === ActionTypeEnum.NOT_CONVERTED_CX_ISSUES ? 'Not Converted' : 'Cook Resection Reason'}</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          justifyContent={"space-evenly"}
          style={{ padding: "12px 12px 12px 12px" }}
          direction="column"
          alignItems="center"
        >
          {
            props?.cookSideCustomerSide && <Grid item xs={12}>
            <FormControl style={{ width: 500 }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Tell us Why?
              </InputLabel>
              <Select
                autoWidth={false}
                className="input_field"
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                defaultValue="Tell us Why?"
                value={reasonCategory}
                onChange={handleReasonCategoryChange}
              >
                {notConvertedReasonOptionMapping.map((item:any) => {
                    return (
                      <MenuItem key={item.category} value={item.category}>
                        {item.category}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          }
          <Grid item xs={12}>
            {reasonCategory && showSubCategoryOrNot(reasonCategory!)}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginTop: "12px" }}>
        <Button variant="contained" onClick={() => {
            if (!reasonCategory) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Fill the details to proceed",
                    type: CustomSnackbarTypes.WARNING
                });
                return;
            }
            if(reasonSubCategoryArray?.length !== 0 && !reasonSubCategory) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Fill the details to proceed",
                    type: CustomSnackbarTypes.WARNING
                });
                return;
            }
            if(props?.cookSideCustomerSide === ActionTypeEnum.NOT_CONVERTED_COOK_ISSUES) {
              trialService
                  .cookAcknowledge(parseInt((props?.data as any).id), {accepted: false, cookRejectionReason: reasonCategory, cookRejectionSubReason: reasonSubCategory})
                  .then(() => {
                    EventBus.emitEvent(
                        EventBusEventsEnum.SNACKBAR_SHOW,
                        {
                          message: "Work rejected",
                          type: CustomSnackbarTypes.SUCCESS,
                        }
                    );
                    handleClose();
                    refresh();
                  });
            } else {
              trialService.markNotConverted(props.data.id, {reason:reasonCategory,subReason:reasonSubCategory} )
                  .then((res) => {
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                      message: "Trial marked fulfilled",
                      type: CustomSnackbarTypes.SUCCESS
                    });
                    refresh();
                    handleClose();
                  })
            }
        }}
        size={"large"}>
            Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            handleClose();
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotConvertedReasonDialog;
