import { FilterAlt, FilterAltOff } from "@mui/icons-material";
import { Box, Button, Card, CardContent, InputLabel } from "@mui/material";
import React from "react";
import { List, Datagrid, TextField, NumberField, DateField, ShowButton, DateInput, SelectInput, FilterLiveSearch, ExportButton, TopToolbar, FunctionField, RaRecord } from "react-admin";
import { UserType } from "../../enums/common";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setTrialConvertedSideFilter } from "../../store/reducers/customSelect";
import CallButton from "../Common/CallButton/CallButton";
import CustomReferenceField from "../Common/CustomReferenceField";


const FilterSidebar = () => {
  return (
    <Box
      sx={{
        marginTop: "65px",
        display: {
          xs: "none",
          sm: "block",
        },
        order: -1,
        width: "17em",
        marginRight: "1em",
      }}
    >
      <Card>
        <CardContent>
          <InputLabel>Admin Notes</InputLabel>
          <FilterLiveSearch source="adminNotes"/>
          <InputLabel>Created By</InputLabel>
          <FilterLiveSearch source="createdByName"/>
          <InputLabel>Modified By</InputLabel>
          <FilterLiveSearch source="modifiedByName"/>
        </CardContent>
      </Card>
    </Box>
  );
};

const convertedFilters = [
  <SelectInput
    label="Informed To Chef"
    source="informedToChef"
    alwaysOn
    emptyText="Clear Filter"
    choices={[
      { id: 'true', name: "TRUE" },
      { id: 'false', name: "FALSE" },
    ]}
  />,
  <DateInput label="Start Date" source="startDate" alwaysOn />,
  <DateInput label="End Date" source="endDate" alwaysOn />,
  <DateInput label="Created At" source="date" alwaysOn/>
];

const ListActions = (props: any) => {
  const dispatch = useAppDispatch();
  return (
      <TopToolbar>
          <Button variant="text" 
            size={"small"}
            onClick={() => {
              dispatch(setTrialConvertedSideFilter({
                openSideFilter: !props.openSideFilterShow
              }))
            }}
            startIcon={props.openSideFilterShow ? <FilterAlt /> : <FilterAltOff />} color="primary"
          >
            Filter
          </Button>
          <ExportButton />
      </TopToolbar>
  );
};

const TrialsConverted = (props: any) => {
  const showSideFilter = useAppSelector((s) => s.customSelect.value.openSideFilter)

  return (
    <List 
     {...props} 
     filters={convertedFilters}
     sort={{ field: 'id', order: 'DESC' }}
     actions={<ListActions openSideFilterShow={showSideFilter} />}
     aside={showSideFilter ? <FilterSidebar /> : null}
     >
      <Datagrid bulkActionButtons={false}>
        <NumberField source="id" />
        <CustomReferenceField label="Cook Id" listName={"cooks"} source={"cookId"} />
        <FunctionField label="Cook Name" render={(record: RaRecord) => {
            if (!record?.cookFullProfile?.firstName) {
              return `Not Available`
            }
            return `${record?.cookFullProfile?.firstName} ${record?.cookFullProfile?.lastName}`
          }} 
        />
        <FunctionField label= "Cook Mobile" render={(record: any) => <CallButton toPhoneNumber={record?.cookMobile} userType={UserType.COOK}/>}/>
        <CustomReferenceField label="User Bundle" listName={"UserBundles"} source={"userBundleMappingId"} />
        <CustomReferenceField label="Trial Id" listName={"trials"} source={"trialId"} />
        <DateField label="Start Date" source="startDate" sortable={false} />
        <DateField label="End Date" source="endDate" sortable={false} />
        <TextField label="Start Slot" source="startSlot" sortable={false} />
        <TextField label="Address" source="address" sortable={false} />
        <TextField label="Admin Notes" source="adminNotes" sortable={false} />
        <TextField label="Informed To Chef" source="informedToChef" sortable={false} />
        <TextField label="Created Name" source="createdByName" sortable={false} />
        <TextField label="Created Email" source="createdByEmail" sortable={false} />
        <TextField label="Modified Name" source="modifiedByName" sortable={false} />
        <TextField label="Modified Email" source="modifiedByEmail" sortable={false} />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime/>
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default TrialsConverted;
