import { useState, useEffect, useCallback } from "react";
import { CookService } from "../../services/CookServices";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import OneMealListForModule from "../OneMealService/OneMealListForModule";

const cookService = new CookService();

interface InputProps {
    cookId: number;
  }

const CookOmsDetailsTab = ({ cookId }: InputProps) => {
    let [subData, setSubData] = useState<any>(null);
    let [isLoading, setIsLoading] = useState<boolean>();
    const [pageSize, setPageSize] = useState(10);
  
  
    const refreshData = useCallback(
      (pageNo: number) => {
        setIsLoading(true);
        cookService
          .getOneMealsForCook(cookId, pageSize, pageSize * pageNo, {order: "DESC", field: "createdAt"})
          .then((data) => {
            setSubData(data);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
          });
      },
      [cookId, pageSize]
    );
  
    useEffect(() => {
      refreshData(0);
    }, [refreshData]);
  
    return (
      <div>
      {subData &&
        subData?.data &&
        Array.isArray(subData?.data) &&
        (subData?.data?.length > 0 ? (
          <OneMealListForModule
            isLoading={isLoading}
            count={subData?.count}
            data={subData?.data}
            refreshData={refreshData}
            setPageSize={setPageSize}
            pageSize={pageSize}
          />
        ) : (
          <EmptyListPlaceholder message="No One Meal Service found" />
        ))}
    </div>
    );
}

export default CookOmsDetailsTab