import {TopToolbar,ExportButton, Datagrid, List, TextField, SelectInput, DateInput, DateField} from "react-admin";
import {TransactionStatesEnum} from "../../enums/TransactionStatesEnum";
import CustomReferenceField from "../Common/CustomReferenceField";
import MetabaseTransactionButton from "../Common/MetabaseDashboardButton"
import { MetabaseDasboardId } from '../../enums/MetabsaeDashboardId';

const filters = [
    <SelectInput
        label="Status"
        source="state"
        alwaysOn
        emptyText="Clear Filter"
        choices={
            Object.values(TransactionStatesEnum)
                .map(value => {
                    return {
                        id: value,
                        name: value
                    }
                })
        }
    />,
    <DateInput label="Start Date" source="startDate" alwaysOn />,
    <DateInput label="End Date" source="endDate" alwaysOn />
];

const ListActions = () => (
    <TopToolbar>
        <MetabaseTransactionButton dashboardId={MetabaseDasboardId.PAYMENT} />
        <ExportButton />
    </TopToolbar>
);

const TransactionList = () => {
    return (
        <List filters={filters} actions={<ListActions />}>
            <Datagrid bulkActionButtons={false}>
                <TextField source="id"/>
                <TextField source="amount"/>
                <TextField source="currency" sortable={false}/>
                <TextField source="orderId" sortable={false}/>
                <TextField source="refundAmount" sortable={false}/>
                <DateField options={{day: 'numeric', month: 'short', year: 'numeric' }} label="Payment Date" source='updatedAt' sortable={false}/>
                <TextField source="state" sortable={false}/>
                <CustomReferenceField label="UserBundleMappingId" listName={"UserBundles"} source={"userBundleMappingId"}/>
                <TextField source="customerName"/>
                <TextField source="customerPhone"/>
                <CustomReferenceField label="HouseId" listName={"Houses"} source={"houseId"}/>
            </Datagrid>
        </List>
    )
};

export default TransactionList;
