import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from "@mui/material";
import CircularLoader from '../Common/CircularLoader';
import { TemporaryReplacementResponse } from '../../interfaces/TemporaryReplacement/TemporaryReplacementResponse';
import {BundlePlan} from "../../enums/common";

interface MultipleTrResponse extends TemporaryReplacementResponse {
    zohoId: string;
    cookName: string;
    plan: BundlePlan;
    trCookCut?: string | null;
    isSelected: boolean;
    cookType: string;
    whatsappNumber?: string;
}

interface whatsAppDialogInterface {
    sendMultipleWhatsAppTr: boolean;
    isLoadingForWhatsAppDialog: boolean;
    dataAfterMappingForWhatsappDialog: MultipleTrResponse[];
    onCookChange: (row: MultipleTrResponse) => void;
    onClickSendWhatsApp: (row: MultipleTrResponse) => void;
    onClose: () => void;
}

const MultipleTemporaryWhatsAppDialog = (props: whatsAppDialogInterface) => {
    return (
        <Dialog
            maxWidth={'lg'}
            fullWidth={true}
            open={props.sendMultipleWhatsAppTr}
        >
            <DialogTitle>
                Send Message
            </DialogTitle>
            {props.isLoadingForWhatsAppDialog ?
                <CircularLoader /> :
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{'ID'}</TableCell>
                                    <TableCell>{'Time'}</TableCell>
                                    <TableCell>{'Cook Id'}</TableCell>
                                    <TableCell>{'Cook Name'}</TableCell>
                                    <TableCell>{'Zoho ID'}</TableCell>
                                    <TableCell>{'Slot'}</TableCell>
                                    <TableCell>{'Status'}</TableCell>
                                    <TableCell>{'Date'}</TableCell>
                                    <TableCell>{'Created At'}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.dataAfterMappingForWhatsappDialog?.map((row: MultipleTrResponse) => {
                                    return(
                                        <TableRow key={row?.id}>
                                            <TableCell key={row?.id}>{row?.id}</TableCell>
                                            <TableCell>{row?.time}</TableCell>
                                            <TableCell>{row?.cookId}</TableCell>
                                            <TableCell>{row?.cookName}</TableCell>
                                            <TableCell>{row?.zohoId}</TableCell>
                                            <TableCell>{row?.slot}</TableCell>
                                            <TableCell>{row?.status}</TableCell>
                                            <TableCell>{row?.date}</TableCell>
                                            <TableCell>{String(row?.createdAt)}</TableCell>
                                            <TableCell>
                                                <Button size="small" onClick={() => {props.onCookChange(row)}} variant="contained">
                                                    {'Change Cook'}
                                                </Button>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button size="small" onClick={() => {props.onClickSendWhatsApp(row)}} variant="contained" color="success">
                                                    {'Send WhatsApp'}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )})}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            }
            <DialogActions>
                <Button
                    variant="contained"
                    color={"error"}
                    onClick={props.onClose}
                    size={"large"}
                >
                    {'Close'}
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export default MultipleTemporaryWhatsAppDialog;
