import React from "react";
import { ArrayField, Datagrid, Show, SimpleShowLayout, TextField } from "react-admin";

const CustomerDetailsTab = () => {
  return (
    <Show actions={false}>
      <div >
        <SimpleShowLayout>
          <ArrayField sortable={false} label="Customer Details" source="userBundleMapping.house.customers" >
                <Datagrid bulkActionButtons={false} style={{width:"400px"}}>
                    <TextField label={'First Name'} source="fname"/>
                    <TextField label={'Middle Name'} source="mname"/>
                    <TextField label={'Last Name'} source="lname"/>
                    <TextField label={'Mobile Number'} source="mobile"/>
                    <TextField label={'Account Id'} source="accountId"/>
                    <TextField label={'Account Status'} source="accountStatus"/>
                    <TextField label={'Address'} source="address"/>
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
        <SimpleShowLayout>
            <TextField source="customerAcknowledged" emptyText="Data Not Available"/>
            <TextField label="Customer FeedBack" source="customerFeedBack" emptyText="Data Not Available"/>
        </SimpleShowLayout>
      </div>
    </Show>
  );
};

export default CustomerDetailsTab;
