import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField
} from "@mui/material";
import { useRecordContext, useRefresh } from "react-admin";
import { useCallback, useEffect, useState } from 'react';
import EventBus from "../../EventBus";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import ChefForPartyCancellationReasons from "../../enums/ChefForPartyCancellationReasons";
import ChefForPartyService from "../../services/ChefForPartyService";
import {getReasonCategory } from "./constants/constant";
import { ActionTypeEnum } from "../../enums/ActionTypeEnum";
import CancellationReasonsCategories from "../../enums/CancellationReasonsCategories";
import ReasonSubreasonModuleEnum from "../../enums/ReasonSubreasonModuleEnum";
import ChefForPartStatusEnum from "../../enums/ChefForPartStatusEnum";

interface Props {
  data: any;
  open: boolean;
  callback: () => void;
}

const chefForPartyService = new ChefForPartyService();

const ChefForPartyCancelDialog = (props: Props) => {
  const [chefForPartyOptionMapping,setChefForPartyOptionMapping]=useState<any>([]);
  const [reasonSubCategory, setReasonSubCategory] = useState<string | null>(null)
  const [reasonSubCategoryArray,setReasonSubCategoryArray]=useState<string[]>([])
  const [reasonCategory, setReasonCategory] = useState<ChefForPartyCancellationReasons | null>(null);
  const refresh = useRefresh();
  let record = useRecordContext();

  const loadData = useCallback(async () => {
    const module = ReasonSubreasonModuleEnum.CHEF_FOR_PARTY;
    switch (record.status) {
      case ChefForPartStatusEnum.CHEF_ALLOTTED:
        let sampleReasonSubReason1 = await getReasonCategory(module,ActionTypeEnum.POST_ALLOCATION_CANCELLED);
        setChefForPartyOptionMapping(sampleReasonSubReason1);
        break;
      case ChefForPartStatusEnum.PENDING:
        let sampleReasonSubReason2 = await getReasonCategory(module,ActionTypeEnum.PRE_ALLOCATION_CANCELLED);
           setChefForPartyOptionMapping(sampleReasonSubReason2);
        break;
      case ChefForPartStatusEnum.CHEF_CONFIRMED:
        let sampleReasonSubReason3 = await getReasonCategory(module,ActionTypeEnum.POST_ALLOCATION_CANCELLED);
        setChefForPartyOptionMapping(sampleReasonSubReason3);
        break;
      case ChefForPartStatusEnum.ONGOING:
        let sampleReasonSubReason4 = await getReasonCategory(module,ActionTypeEnum.POST_ALLOCATION_CANCELLED);
        setChefForPartyOptionMapping(sampleReasonSubReason4);
        break;

    }
  }, [record?.status]);

  useEffect(()=>{
    loadData();
  },[loadData])

  let handleClose = () => {
    setReasonCategory(null)
    setReasonSubCategory(null)
    props.callback();
  };

  const handleReasonCategoryChange = (event: any) => {
    const selectedCategory:any = chefForPartyOptionMapping.find(
      (option:any) => option.category === event.target.value
    );
    setReasonSubCategory(null);
    if (selectedCategory) {
      setReasonSubCategoryArray(selectedCategory?.subCategory);
      setReasonCategory(event.target.value);
    }
  }

  const handleReasonSubCategoryChange = (event: any) => {
    setReasonSubCategory(event.target.value);
  }

  function showSubCategoryOrNot(reasonCategory: string) {
    if (reasonCategory === CancellationReasonsCategories.OTHERS ) {
      return (
          <FormControl style={{width: 500}}>
            <TextField value={reasonSubCategory} onChange={handleReasonSubCategoryChange} label="Write a reason"/>
          </FormControl>
      )
    } else if(reasonSubCategoryArray?.length !== 0) {
      return (
        <FormControl style={{ width: 500 }}>
          <InputLabel id="select-reason">Select a Subreason</InputLabel>
          <Select
            labelId="select-reason"
            id="select-reason"
            defaultValue="Select a reason"
            value={reasonSubCategory}
            onChange={handleReasonSubCategoryChange}
          >
            {reasonSubCategoryArray?.map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      scroll={"paper"}
      maxWidth="xl"
    >
      <DialogTitle>{"Cancel Chef for Party"}</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          justifyContent={"space-evenly"}
          style={{ padding: "12px 12px 12px 12px" }}
          direction="column"
          alignItems="center"
        >
          <Grid item xs={12}>
            <FormControl style={{ width: 500 }}>
              <InputLabel id="select-label">Tell us Why?</InputLabel>
              <Select
                labelId="select-label"
                id="select-label"
                defaultValue="Tell us Why?"
                value={reasonCategory}
                onChange={handleReasonCategoryChange}
              >
                {chefForPartyOptionMapping.map((item:any) => {
                  return (
                    <MenuItem key={item.category} value={item.category}>
                      {item.category}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {
              reasonCategory && showSubCategoryOrNot(reasonCategory!)
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginTop: "12px" }}>
        <Button
          variant="contained"
          onClick={() => {
            if (!reasonCategory) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Fill the details to proceed",
                type: CustomSnackbarTypes.WARNING
              });
              return;
            }
            if(reasonSubCategoryArray?.length !== 0 && !reasonSubCategory) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Fill the details to proceed",
                type: CustomSnackbarTypes.WARNING
              });
              return;
            }
            if (reasonCategory === ChefForPartyCancellationReasons.OTHERS && !reasonSubCategory) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Fill the reason to proceed",
                type: CustomSnackbarTypes.WARNING
              });
              return;
            }
            chefForPartyService.cancelChefForParty(props?.data?.id, {
              reason: reasonCategory,
              cancellationSubReason: reasonSubCategory
            }).then(() => {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Canceled Successfully",
                type: CustomSnackbarTypes.SUCCESS,
              });
              refresh();
              handleClose();
            });
          }}
          size={"large"}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            handleClose();
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ChefForPartyCancelDialog;
