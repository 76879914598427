import HttpClient from "../HttpClient";
import { GetListResult, CreateResult, UpdateResult } from "react-admin";
import { CustomerLeavesResponse } from "../interfaces/Customer/CustomerLeavesResponse";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";
import moment from "moment";
import Op from "../interfaces/SequalizeFilters/SequalizeTypes";

export class CustomerLeavesServies {
    private processDateRangeFilter(filters: any) {
        let where;
        if (filters.startDate) {
            where = {
                [Op.gte.toString()]: moment(filters.startDate).toDate()
            }
            delete filters.startDate
        }
        if (filters.endDate) {
            if (where) {
                where = {
                    ...where,
                    [Op.lte.toString()]: moment(filters.endDate).toDate()
                }
            } else {
                where = {
                    [Op.lte.toString()]: moment(filters.endDate).toDate()
                }
            }
            delete filters.endDate
        }
        return {date: where};
    }


    async getAllCustLeaves(limit: number, offset: number, filters: any, sort: {}): Promise<GetListResult<CustomerLeavesResponse>> {
        let nullFilters: string[] = [];
        let notNullFilters: string[] = [];
        if (filters.allotted === 'Allotted') {
            notNullFilters.push('cookId')
        }
        if (filters.allotted === 'Not Allotted') {
            nullFilters.push('cookId')
        }
        const customfilters = ConstructSequelizeFilters(filters, [], ['resolution','userBundleMappingId'],nullFilters, notNullFilters)
        const dateFilters = this.processDateRangeFilter(filters);

        filters = {
            ...customfilters,
            ...dateFilters
        }

        let data = await HttpClient.get('/centaurus/customer/leaves/all', {
            params: {
                limit,
                offset,
                filters,
                sort
            }
        })
        return {
            data: data.data.data,
            total: data.data.count
        }
    }
    async createCustomerLeaves(data: any): Promise<CreateResult<CustomerLeavesResponse>>{
        let createdTr = await HttpClient.post("/centaurus/customer/leaves",data )
        return {
            data : createdTr.data
        }
    }
    async cancelCustomerLeaves(id:number, payload: any): Promise<UpdateResult<any>>{
        let updatedTr = await HttpClient.delete(`/centaurus/customer/leaves/${id}/cancel`, payload)
        return {
            data : updatedTr
        }
    }


}