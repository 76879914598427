import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import LocalityService from "../../services/LocalityService";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { useRefresh } from "react-admin";
import AreaService from "../../services/AreaService";

interface InputProps {
  data: any;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  type?: 'AREA' | 'LOCALITY';
}

const localityService = new LocalityService();
const areaService = new AreaService();

const EditLatLongLocalityDialog = (props: InputProps) => {
  const [lat, setLat] = useState<any>();
  const [long, setLong] = useState<any>();
  const refresh = useRefresh();

  const validateCoordinates = (coordinates: any) => {
    if (
      Number(coordinates) > 90 ||
      Number(coordinates) < -90
    ) {
      return false;
    }
    return true;
  }

  const handleSubmit = () => {
    if (!validateCoordinates(lat) || !validateCoordinates(long)) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Coordinates should be between -90 and 90",
        type: CustomSnackbarTypes.ERROR,
      });
      return;
    }
    let payload = {
      coordinates: { lat: lat, long: long },
    };
    if(props?.type === 'AREA') {
      areaService.editAreaLatLong(props?.data?.id, payload).then(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Successful",
          type: CustomSnackbarTypes.SUCCESS,
        });
      });
    } else {
      localityService.editLocalityLatLong(props?.data?.id, payload).then(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Successful",
          type: CustomSnackbarTypes.SUCCESS,
        });
      });
    }

    refresh();
    props.setOpen(false);
  };

  useEffect(() => {
    setLat(props?.data?.coordinates?.coordinates[1]);
    setLong(props?.data?.coordinates?.coordinates[0]);
  }, [props?.data?.coordinates?.coordinates])


  return (
    <Dialog open={props.open} scroll={"paper"} onClose={() => {props.setOpen(false)}}>
      <DialogTitle>Update Latitude And Longitude</DialogTitle>
      <DialogContent>
        <FormControl style={{ width: 300 }} fullWidth>
          <TextField
            style={{ margin: "15px" }}
            type="number"
            label="Enter the longitude"
            value={long || ""}
            InputProps={{
              inputProps: {
                min: -180,
                max: 180,
              },
            }}
            onChange={(event) => {
              setLong(Number(event.target.value));
            }}
          />
          <TextField
            style={{ margin: "15px" }}
            type="number"
            label="Enter the latitude"
            value={lat || ""}
            InputProps={{
              inputProps: {
                min: -180,
                max: 180,
              },
            }}
            onChange={(event) => {
              setLat(Number(event.target.value));
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={"success"}
          onClick={() => {
            handleSubmit();
          }}
          size={"large"}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            props.setOpen(false);
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditLatLongLocalityDialog;
