import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { useRefresh } from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { ShortTimeEnum } from "../../enums/ShortTimeEnum";
import EventBus from "../../EventBus";
import { OneMealService } from "../../services/OneMealService";
import CkMuiDialog from "./CkMuiDialog/CkMuiDialog";
import { CustomSnackbarTypes } from "./CustomSnackBar";

interface InputProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  data: any;
}

const oneMealService = new OneMealService();

const ChangeOmsTimeDialog = (props: InputProps) => {
  const refresh = useRefresh();
  const [timeSelectValue, setTimeSelectValue] = useState<string>("");

  const handleClose = () => {
    props.setOpen(false);
    setTimeSelectValue("");
  };

  return (
    <CkMuiDialog
      isDialogOpen={props.open}
      setDialogOpen={props.setOpen}
      onDialogClose={handleClose}
      title={"Change OMS Time"}
      secondaryLabel={"Close"}
      secondaryAction={handleClose}
      isLoading={false}
      primaryLabel="Submit"
      primaryAction={() => {
        if (!timeSelectValue) {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Select the Time to proceed",
            type: CustomSnackbarTypes.WARNING,
          });
        }
        oneMealService
          .changeOneMealServiceTime(props?.data?.id, { time: timeSelectValue })
          .then(() => {
            refresh();
            handleClose();
          });
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent={"space-evenly"}
        style={{ padding: "12px 12px 12px 12px" }}
      >
        <Grid item>
          <FormControl style={{ width: "170px", marginTop: "10px" }}>
            <InputLabel id="timeSelect">Time</InputLabel>
            <Select
              labelId="timeSelect"
              id="timeSelect"
              label="Time"
              value={timeSelectValue}
              onChange={(event: SelectChangeEvent) => {
                setTimeSelectValue(event.target.value as string);
              }}
            >
              {Object.values(ShortTimeEnum).map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </CkMuiDialog>
  );
};

export default ChangeOmsTimeDialog;
