import { Box } from '@mui/material';
import React from 'react'
import { SimpleShowLayout, TextField, useRecordContext } from 'react-admin'
import ImagePreviewer from './ImagePreviewer';

type InputProps = {
    service: string;
}
const CustomerRatingsImages = (props: InputProps) => {
    const record = useRecordContext();
    const [customerRatings, setCustomerRatings] = React.useState<any>([{}]);

    React.useEffect(() => {
        if (props?.service === "OMS" || props?.service === "CHEF_FOR_PARTY") {
            setCustomerRatings(record?.customerRatings);
        }
        else if (props?.service === "TRIALS") {
            setCustomerRatings([record?.moduleRating]);
        }
    }, [props?.service, record])
    return (
        <div>
            <SimpleShowLayout>
                <TextField label={"Customer Rating Images"} />
                <Box>
                    {customerRatings?.map((item: any, index: number) => {
                        return (
                            <Box key={index} sx={{cursor: 'pointer', overflowX: 'scroll', overflowY: 'hidden'}} display={'flex'}>
                                {
                                    item?.photos?.map((imgSrc: any, ind: number) => {
                                        return (
                                            <ImagePreviewer key={ind} value={imgSrc} />
                                        )
                                    })
                                }
                            </Box>
                        )
                    }
                    )}
                </Box>
            </SimpleShowLayout>
        </div>
    )
}

export default CustomerRatingsImages