import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";
import HttpClient from "../HttpClient";
import {GetListResult} from "react-admin";
import moment from "moment";
import Op from "../interfaces/SequalizeFilters/SequalizeTypes";
import {ComboResponse} from "../interfaces/Combo/ComboResponse";

export default class ComboService {
    private basePath = '/centaurus/discount-coupon-order';

    private processDateRangeFilter(filters: any) {
        let where;
        if (filters.expireAt) {
            where = {
                [Op.lte.toString()]: moment(filters.expireAt).toDate()
            }
        }
        return {expireAt: where};
    }

    async getAllCombos(limit: number, offset: number, filters: any, sort: any): Promise<GetListResult<ComboResponse>> {
        let filter = ConstructSequelizeFilters({...filters}, [], ['paymentStatus', 'createdAt', 'customerPhoneNumber', 'productType'])
        let customFilters = this.processDateRangeFilter(filters);
        filters = {
            ...filter,
            ...customFilters
        }
        let data = await HttpClient.get(`${this.basePath}`, {
            params: {
                limit,
                offset,
                filters,
                sort
            }
        })
        return {
            data: (!!data && !!data.data) ? data.data.data : [],
            total: (!!data && !!data.data) ? data.data.count : 0
        }
    }

    async getDetailsById(id: number) {
        return await HttpClient.get(`${this.basePath}/${id}`);
    }

    async createCombo(id: number, payload: any) {
        return await HttpClient.post(`${this.basePath}/customer/${id}`, payload);
    }

    async refundCombo(id: number, payload: any) {
        return await HttpClient.post(`${this.basePath}/${id}/refund`, payload);
    }

    async sendPaymentLink(id: number) {
        let data = await HttpClient.post(`${this.basePath}/${id}/send-payment-link`)
        return data?.data;
    }
    async getAllDiscountCouponSku(
        limit: number,
        offset: number,
    ) {
        let data = await HttpClient.get(`/centaurus/v1/discount-coupon-sku/all`,
            {
                params: {
                    limit,
                    offset
                },
            })
        return data?.data;
    }
}
