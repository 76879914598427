export enum VendorPayoutTypesEnum {
    SUBSCRIPTION = "SUBSCRIPTION",
    SALARY = "SALARY",
    TRIAL = "TRIAL",
    TR = "TR",
    LEAVE = "LEAVE",
    REFERRAL = "REFERRAL",
    ADVANCE = "ADVANCE",
    INCENTIVE =  "INCENTIVE",
    REIMBURSEMENT =  "REIMBURSEMENT",
    MISCELLANEOUS = "MISCELLANEOUS",
    ONE_MEAL_SERVICE = "ONE_MEAL_SERVICE",
    CONVEYANCE = "CONVEYANCE",
    OTHERS = "OTHERS"
}
