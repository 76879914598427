export default function newVendorSubResource(key: string) {
  let newVendorSubResource = new Map();
  newVendorSubResource.set("OFFLINE", [
    "Society Visit",
    "Locality Visit",
    "Canopy Promotions",
    "Community Event",
    "Decentralised Trial",
    "Decentralised Migration",
    "Walk-In"
  ]);

  newVendorSubResource.set("ONLINE", [
    "Apna",
    "Job Hai",
    "Work India",
    "QuikrJobs",
    "Hirect",
    "Kaam24",
    "Rocket",
    "Work Ex",
    "Website",
    "Jobsgaar",
    "Facebook",
    "Instagram"
  ]);

  return newVendorSubResource.get(key);
}
