import React from 'react';
import {CircularProgress} from "@mui/material";

const CircularLoader = (props: any) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%'
            }}
        >
            <CircularProgress {...props}/>
        </div>
    );
};

export default CircularLoader;