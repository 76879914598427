import { initializeApp } from "firebase/app";
import { getRemoteConfig } from "firebase/remote-config";
import { getAnalytics } from "firebase/analytics";

const firebaseConfigProduction = {
    apiKey: "AIzaSyCss_01GY5iJfGkq2XyCJmZPJTE5_Mpyqc",
    authDomain: "centaurus-2f466.firebaseapp.com",
    projectId: "centaurus-2f466",
    storageBucket: "centaurus-2f466.appspot.com",
    messagingSenderId: "529363065171",
    appId: "1:529363065171:web:a24268cfffaa10d22ae9a6",
    measurementId: "G-QW6VPHRVCX"
}

const firebaseConfigDevelopment = {
    apiKey: "AIzaSyA9xxDAonZKlLWPtd0fRMUOtemNyN8orv0",
    authDomain: "centaurus-dev-38c0b.firebaseapp.com",
    projectId: "centaurus-dev-38c0b",
    storageBucket: "centaurus-dev-38c0b.appspot.com",
    messagingSenderId: "225955595795",
    appId: "1:225955595795:web:fa8837035350ba73c2a1e6",
    measurementId: "G-V8R9ZT06ZV"
  };

const firebaseConfigStaging = {
    apiKey: "AIzaSyB6EhnBXHnfheOm8SfdcrMAuLtt7Ei4QUE",
    authDomain: "centaurus-staging.firebaseapp.com",
    projectId: "centaurus-staging",
    storageBucket: "centaurus-staging.appspot.com",
    messagingSenderId: "517291006629",
    appId: "1:517291006629:web:eb6e7e3ff9871835208b4c",
    measurementId: "G-3FE4S7EVKD"
}

let firebaseConfig = {};
switch (process.env.REACT_APP_ENV) {
    case 'production':
        firebaseConfig = firebaseConfigProduction;
        break;
    case 'staging':
            firebaseConfig = firebaseConfigStaging;
            break;

    default:
        firebaseConfig = firebaseConfigDevelopment
        break;
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Remote Config and get a reference to the service
const remoteConfig = getRemoteConfig(app);
// eslint-disable-next-line
const analytics = getAnalytics(app);
//30 minutes
remoteConfig.settings.minimumFetchIntervalMillis = 1000*60*30;

export default remoteConfig;
