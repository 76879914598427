import { Close } from "@mui/icons-material";
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select,
} from "@mui/material";
import  React, { useEffect } from "react";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "./CustomSnackBar";
import { useRefresh } from "react-admin";
import StarChefVisitService from "../../services/StarChefVisitService";
import { useState } from "react";
import { MealType } from "../../enums/MealType";

const starChefVisitService = new StarChefVisitService()

interface InputProps {
  data: any;
  open: boolean;
  callback: () => void;
}
const DishSelectorComp = (props: any) => {
    const [selectCategory, setSelectCategory] = useState<number>(0)
    const [dishes, setDishes] = useState<any>([])

    useEffect(() => {
      if (selectCategory !== 0) {
        starChefVisitService
          .getAllDishesForTypeAndCategory(selectCategory, props.type)
          .then((data) => setDishes(data))
          .catch((err) => setDishes([]));
      }
    }, [selectCategory, props.type]);

    const handleDishSelect = (value: any) => {
        let index = props.index
        if(props.dishes && props.dishes.length > index + 1){
            props.dishes[index] = value
        }
        else{
            props.dishes.push(value)
        }

    }

    return (
      <div>
        <InputLabel id="type">{props.type}</InputLabel>
        <FormControl style={{ width: "180px", margin: "20px 20px" }}>
          <InputLabel id="type">{"Type"}</InputLabel>
          <Select
            labelId="category"
            id="category"
            label="Type"
            defaultValue={''}
            onChange={(event: any) => {
              setSelectCategory(event.target.value);
            }}
          >
            {props.category && Object.values(props.category).map((d: any) => (
              <MenuItem key={d.id} value={d.id}>
                {d.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {dishes && (
          <FormControl style={{ width: "180px", margin: "20px 20px" }}>
            <InputLabel id="Dish_Name">{"Dish Name"}</InputLabel>
            <Select
              labelId="dishes"
              id="dishes"
              label="Dish Name"
              defaultValue={''}
              onChange={(event: any) => {
                handleDishSelect(event.target.value);
              }}
            >
              {dishes?.map((d: any) => (
                <MenuItem key={d.id} value={d}>
                  {d.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
    );
}

export const EditDishStarChefVisit = (props: InputProps) => {
  const [dishes, setDishes] = useState([])
  const [category, setCategory] = useState([]);
  let refresh = useRefresh()

  let handleClose = () => {
    props.callback()
    setDishes([])
  }

  useEffect(() => {
    starChefVisitService
      .getAllCategory()
      .then((data: any) => setCategory(data))
      .catch((err) => setCategory([]));
  }, []);

  return (
      <Dialog
        onClose={handleClose}
        open={props.open}
        scroll={"paper"}
        maxWidth="xl"
      >
        <DialogTitle>Edit/Add Dishes</DialogTitle>
        <DialogContent>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
          {Object.values(MealType).map((e,i) => <DishSelectorComp key={e} index={i} category={category} type={e} setDishes={setDishes} dishes={dishes}/>)}
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              if (dishes.length !== 3) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Fill the All Dishes",
                    type: CustomSnackbarTypes.WARNING,
                });
                return;
              }
              starChefVisitService
              .editDishesStarChefVisit(props.data.id, { dishes })
                .then((res) => {
                  refresh();
                  handleClose()
                });
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              handleClose();
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  );
};