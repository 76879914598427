import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import UserBundleService from "../../services/BundleService";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { UserCookLeaveLinkDisputeResolution } from "../../enums/UserCookLeaveLinkDisputeResolution ";


const bundleService=new UserBundleService()
const UpdateLeaveDisputeResolutionDialog = (props:any) => {
    const [resolution,setResolution]=useState<any>();
    const handleClose = () => {
        props.callback();
        setResolution("");
    };
    const resolve =()=>{

        let payload = {
            resolution
        }
        bundleService.updateLeaveDisputeResolution(props?.data?.id,payload).then((res)=>{
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Dispute Resolution Updated"
            });
            props.callback({
                resolution
            });
            setResolution("");
            handleClose();
        })
    }
    return (
        <div>
            <Dialog
                onClose={handleClose}
                open={props.open}
                scroll={"paper"}
            >
                <DialogContent>
                    <IconButton
                        onClick={handleClose}
                        sx={{ position: "absolute", top: 2, right: 5 }}
                    >
                        <Close/>
                    </IconButton>
                    <Grid
                        container
                        columns={1}
                        rowSpacing={0.9}
                        style={{ padding: "12px 12px 12px 12px",}}
                    >
                        <Box fontWeight={"bold"}>Dispute Raised By {props?.data?.userCookLeaveLinks[0]?.disputeRaisedByType}</Box>
                        <FormControl fullWidth>
                            <FormControl fullWidth style={{ marginTop: "20px" }}>
                                <InputLabel id="forResolution">Mark Resolution </InputLabel>
                                {
                                    props?.data?.userCookLeaveLinks[0]?.disputeRaisedByType==="COOK"?
                                    <Select
                                        labelId="forResolution"
                                        id="Resolution"
                                        value={resolution}
                                        label="Resolution"
                                        onChange={(event) => setResolution(event.target.value)}
                                    >
                                        <MenuItem value={UserCookLeaveLinkDisputeResolution.RESOLVED}>Cook raised right dispute (Customer was absent)[Resolved] </MenuItem>
                                        <MenuItem value={UserCookLeaveLinkDisputeResolution.REJECTED}>Cook raised wrong dispute (Cook was absent)[Rejected]</MenuItem>
                                        <MenuItem disabled>Customer marked wrong leave (Customer as absent)</MenuItem>
                                        <MenuItem disabled>Cook did false CICO (Cook was absent)</MenuItem>
                                    </Select>:
                                    <Select
                                        labelId="forResolution"
                                        id="Resolution"
                                        value={resolution}
                                        label="Resolution"
                                        onChange={(event) => setResolution(event.target.value)}
                                    >
                                        <MenuItem value={UserCookLeaveLinkDisputeResolution.RESOLVED}>Cook did false CICO (Cook was absent)[Resolved]</MenuItem>
                                        <MenuItem value={UserCookLeaveLinkDisputeResolution.REJECTED}>Customer marked wrong leave (Customer as absent)[Rejected]</MenuItem>
                                        <MenuItem disabled >Cook raised right dispute (Customer was absent)</MenuItem>
                                        <MenuItem disabled >Cook raised wrong dispute (Cook was absent)</MenuItem>
                                    </Select>
                                }
                            </FormControl>
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ marginTop: "12px" }}>
                    <Button
                        variant="contained"
                        color={"primary"}
                        onClick={()=>{
                            resolve();
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleClose}
                        color={"secondary"}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default UpdateLeaveDisputeResolutionDialog


/*
Raised By Cook 

    Rejected → Cook raised wrong dispute  (Cook was absent)

    Resolved → Cook raised right dispute  (Customer was absent)

Raised by CX

    Rejected → Customer marked wrong leave (Customer as absent)

    Resolved → Cook did false CICO  (Cook was absent)
 */