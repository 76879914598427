import moment from "moment";
import { GetListResult, GetOneResult } from "react-admin";
import HttpClient from "../HttpClient";
import { RenewalResponse } from "../interfaces/Renewals/RenewalsResponse";
import Op from "../interfaces/SequalizeFilters/SequalizeTypes";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";

export class RenewalsService {

  processDateRangeFilter(filters: any) {
    let endDate;
    if (filters.startDates) {
      endDate = {
            [Op.gte.toString()]: moment(filters.startDates)
        }
        delete filters.startDates
    }
    if (filters.endDates) {
        if (endDate) {
          endDate = {
                ...endDate,
                [Op.lte.toString()]: moment(filters.endDates)
            }
        } else {
          endDate = {
                [Op.lte.toString()]: moment(filters.endDates)
            }
        }
        delete filters.endDates
    }
    return {endDate};
}

  async getAllRenewals(
    limit: number,
    offset: number,
    filters: any,
    sort: any
  ): Promise<GetListResult<RenewalResponse>> {
    // for esclation
    if (filters?.hasEscalations) {
      if (filters?.hasEscalations?.toLowerCase() === "true") {
        filters.hasEscalations = true;
      } else if (filters?.hasEscalations?.toLowerCase() === "false") {
        filters.hasEscalations = false;
      } else {
        delete filters["hasEscalations"];
      }
    }
    //for active Pr
    if (filters?.hasActivePr) {
      if (filters?.hasActivePr?.toLowerCase() === "true") {
        filters.hasActivePr = true;
      } else if (filters?.hasActivePr?.toLowerCase() === "false") {
        filters.hasActivePr = false;
      } else {
        delete filters["hasActivePr"];
      }
    }
    let filter = ConstructSequelizeFilters(
      filters,
      ["ownerName"],
      ["hasActivePr", "hasEscalations", 'paymentStatus', 'status', 'noOfCustomerFollowups', 'noOfCookFollowups', 'nextCookFollowUpDate', 'nextCustomerFollowUpDate']
    );
    let customFilters = this.processDateRangeFilter(filters);
    filters = {
      ...filter,
      ...customFilters
    };
    let data = await HttpClient.get("/centaurus/subscriptionrenewals", {
      params: {
        limit,
        offset,
        filters,
        sort,
      },
    });
    return {
      data: data?.data?.data,
      total: data?.data?.count,
    };
  }

  async getOneRenewals(id: number): Promise<GetOneResult<RenewalResponse>> {
    let data = await HttpClient.get(`/centaurus/subscriptionrenewals/${id}`);
    return data;
  };

  async markEscalationForRenewal(id: number): Promise<RenewalResponse> {
    let data = await HttpClient.patch(`/centaurus/subscriptionrenewals/${id}/markescalation`);
    return data?.data
  }

  async changeAssigneForRenewal(id: number, ownerId: number): Promise<RenewalResponse>{
    let data = await HttpClient.patch(`/centaurus/subscriptionrenewals/${id}/owner/${ownerId}/assign`);
    return data?.data
  }
  
  async addCookFollowUpForRenewal(id: number, payload: {comment: string, nextFollowUpDate: Date}): Promise<RenewalResponse>{
    let data = await HttpClient.post(`/centaurus/subscriptionrenewals/${id}/followup/cook`, payload)
    return data?.data
  }

  async addCustomerFollowUpForRenewal(id: number, payload: {comment: string, nextFollowUpDate: Date}): Promise<RenewalResponse>{
    let data = await HttpClient.post(`/centaurus/subscriptionrenewals/${id}/followup/customer`, payload)
    return data?.data
  }
}
