import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { useRefresh } from "ra-core";
import { useState } from "react";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { RefundTypeEnum } from "../../enums/RefundTypeEnum";
import EventBus from "../../EventBus";
import { StarCleanerVisitResponse } from "../../interfaces/StarCleanerVisit/StarCleanerVisitResponse";
import StarCleanerVisitService from "../../services/StarCleanerVisitService";
import { CustomSnackbarTypes } from "./CustomSnackBar";

interface Props {
  data: StarCleanerVisitResponse;
  open: boolean;
  callback: () => void;
}

const starCleanerService = new StarCleanerVisitService();

const StarCleanerRefundDialog = (props: Props) => {
  const [refundType, setRefundType] = useState<RefundTypeEnum | null>(null);
  const [reason, setReason] = useState<string>("");
  const refresh = useRefresh();
  const [amount, setAmount] = useState<string>("0");

  let handleClose = () => {
    setRefundType(null);
    props.callback();
  };

  const handleSubmit = async () => {
    if (refundType === null) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Please select refund type",
        type: CustomSnackbarTypes.WARNING,
      });
      return;
    }
    if (refundType !== RefundTypeEnum.FULL && Number(amount) === 0) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Enter the refund amount",
        type: CustomSnackbarTypes.WARNING,
      });
      return;
    }
    if (refundType === RefundTypeEnum.PARTIAL && Number(amount) < 0) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Refund cannot be negative",
        type: CustomSnackbarTypes.WARNING,
      });
      return;
    }
    let payload: {
      refundType: RefundTypeEnum;
      reason: string;
      amount?: string;
    } = { refundType, reason };
    if (Number(amount) !== 0) {
      payload = {
        ...payload,
        amount,
      };
    }
    await starCleanerService
      .refundStarCleanerService(props?.data?.id, payload)
      .then(() => {
        refresh();
        handleClose();
      });
  };

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      scroll={"paper"}
      maxWidth="xs"
    >
      <DialogTitle>Refund Trials</DialogTitle>
      <DialogContent sx={{ width: 300 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Select refund type
          </InputLabel>
          <Select
            autoWidth={false}
            value={refundType}
            onChange={(event: any) => {
              setRefundType(event.target.value);
              setAmount("0");
            }}
          >
            {Object.values(RefundTypeEnum).map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {RefundTypeEnum.FULL !== refundType && refundType !== null && (
          <TextField
            fullWidth
            value={amount}
            type={"number"}
            label={"Enter the refund Amount"}
            InputProps={{
              inputProps: { min: 0 }
            }}
            onChange={(event) => {
              setAmount(event.target.value);
            }}
          />
        )}
        <FormControl fullWidth>
          <TextField
            fullWidth
            value={reason}
            label={"Tell us Why?"}
            onChange={(event) => {
              setReason(event.target.value);
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSubmit} size={"large"}>
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            handleClose();
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StarCleanerRefundDialog;
