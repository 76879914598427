import { Button } from "@mui/material";
import { useState } from "react";
import { Show, Tab, TabbedShowLayout, TabbedShowLayoutTabs, useRefresh } from "react-admin";
import { useLocation, useNavigate } from 'react-router-dom';
import HouseService from "../../services/HouseService";
import UpdateHouseSlcDialog from "../Common/UpdateHouseSlcDialog";
import HouseChefForPartyTab from "./HouseChefForPartyTab";
import HouseMetaShow from "./HouseMetaShow";
import HouseOmsTableTab from "./HouseOmsTableTab";
import PermanentReplacementTab from "./HousePRTab";
import HousesRefundsTab from "./HouseRefundsTab";
import HousesCouponTab from "./HousesCouponTab";
import HousesCustomersTab from "./HousesCustomersTab";
import HouseStarCleanerVisitsTab from "./HousesStarCleanerVisitsTab";
import HousesStarChefVisitsTab from "./HouseStarChefVisitsTab";
import HousesTransactionTab from "./HousesTransactionTab";
import HousesUserBundleTab from "./HousesUserBundleTab";
import HouseTrialTab from "./HouseTrialsTab";
import Op from "../../interfaces/SequalizeFilters/SequalizeTypes";
import CookServeList from "../Common/CookServeList";

const houseService = new HouseService();

const HouseTabShow = () => {
    let location = useLocation();
    let houseId = location.pathname.split('/')[2]
    return (
        <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" selectionFollowsFocus={true}/>}>
            <Tab label={"Details"}>
                <HouseMetaShow />
            </Tab>
            <Tab label={"Bundle Mapping Details"} >
                <HousesUserBundleTab
                    houseId={parseInt(houseId)}
                />
            </Tab>
            <Tab label={"All Customer"} >
                <HousesCustomersTab
                    houseId={parseInt(houseId)}
                />
            </Tab>
            <Tab label={"Cook Serve List"}>
                <CookServeList hasService={false} houseId={parseInt(houseId)}/>
            </Tab>
            <Tab label={"Trials Details"} >
                <HouseTrialTab
                    houseId={parseInt(houseId)} />
            </Tab>
            <Tab label={"Transaction"} >
                <HousesTransactionTab
                    houseId={parseInt(houseId)}
                />
            </Tab>
            <Tab label={"Refunds"} >
                <HousesRefundsTab
                    houseId={parseInt(houseId)}
                />
            </Tab>
            <Tab label={"Coupons"} >
                <HousesCouponTab
                    houseId={parseInt(houseId)}
                />
            </Tab>
            <Tab label={"Permanent Replacement"} >
                <PermanentReplacementTab
                    houseId={parseInt(houseId)}/>
            </Tab>
            <Tab label={"Chef For Party"} >
                <HouseChefForPartyTab
                    houseId={parseInt(houseId)}/>
            </Tab>
            <Tab label={"Star Chef Visits"} >
                <HousesStarChefVisitsTab
                    houseId={parseInt(houseId)}
                />
            </Tab>
            <Tab label={"Star Cleaner Visits"} >
                <HouseStarCleanerVisitsTab
                    houseId={parseInt(houseId)}
                />
            </Tab>
            <Tab label={"CHEFIT"}>
                <HouseOmsTableTab houseId={parseInt(houseId)} filter={{"userLeaveMarkedId":{[Op.eq.toString()]:null}}}  />
            </Tab>
            <Tab label={"OMS TR"}>
                <HouseOmsTableTab houseId={parseInt(houseId)} filter={{"userLeaveMarkedId":{[Op.not.toString()]:null}}} />
            </Tab>
        </TabbedShowLayout>
    )
};

const HouseShow = (props: any) => {
    const [openUpdateSlcDialog, setOpenUpdateSlcDialog] = useState(false);

    let location = useLocation();
    let houseId = location.pathname.split('/')[2]

    const refresh = useRefresh();

    let navigate = useNavigate();
    const navigateToTrialsCreate = () => {
        let path = `/Trials/create`;
        navigate(path);
    }

    const ShowAction = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}
            >
                <Button
                    variant={'contained'}
                    onClick={navigateToTrialsCreate}
                    style={{
                        margin: '8px 8px 0px 0px'
                    }}
                    size="small"
                >
                    Create Trial
                </Button>

                <Button
                    variant="contained"
                    size="small"
                    style={{
                        margin: '8px 8px 0px 0px'
                    }}
                    onClick={() => setOpenUpdateSlcDialog(!openUpdateSlcDialog)}
                >
                    Update SLC
                </Button>
            </div>
        )
    }

    return (
        <div>
            <UpdateHouseSlcDialog
                open={openUpdateSlcDialog}
                setOpen={setOpenUpdateSlcDialog}
                callback={async localityId => {
                    await houseService.updateSlc(Number(houseId), localityId)
                    refresh()

                }}
            />
            <Show {...props} actions={<ShowAction /> } title=" ">

                <HouseTabShow />
            </Show>
        </div>
    )
}
export default HouseShow;
