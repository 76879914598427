import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { TrialsService } from "../../services/TrialServices";
import { useAppDispatch } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import CircularLoader from "../Common/CircularLoader";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import TrialAllocationMetaShow from "./TrialAllocationMetaShow";
import { useLocation } from "react-router-dom";
import { TrialsV3Status } from "../../enums/TrialsV3Status";
import { useRecordContext, useRefresh } from "react-admin";
import CookVerifyDialog from "../Cooks/CookVerifyDialog";
import Cooks from "../Cooks/Cooks";
import { CookService } from "../../services/CookServices";
import { CookResponse } from "../../interfaces/Cook/CookResponse";

const trialsService = new TrialsService();
const cookService = new CookService();

const TrialAllocationTab = (props: any) => {
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  let [subData, setSubData] = useState<any>(null);
  const [sort, setSort] = useState({});
  let [isLoading, setIsLoading] = useState<boolean>();
  let [cookList, setCookList] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  let location = useLocation();
  const record = useRecordContext();
  const refresh = useRefresh();
  let trialId = location.pathname.split("/")[2];
  const [cookVerifyDialog, setCookVerifyDialog] = useState(false);
  const [selectedCook, setSelectedCook] = useState<CookResponse>();
  const [cookAvailability, setCookAvailability] = useState({});
  const [loading, setLoading] = useState(false);

  const refreshData = (
    trialId: number,
    limit: number,
    offset: number,
    sort: any
  ) => {
    setIsLoading(true);
    trialsService
      .getAcceptedLeadsForTrial(trialId, limit, offset, sort)
      .then((data) => {
        setSubData(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    refreshData(props.trialId, rowsPerPage, page * rowsPerPage, sort);
  }, [props.trialId, rowsPerPage, page, sort]);

  const handleSelectCook = () => {
    setCookList(true);
    dispatch(
      setCustomSelectOpen({
        open: true,
      })
    );
  };

  const handleClose = (recordData: any) => {
    let trialId = location.pathname.split("/")[2];
    setCookList(false);
    dispatch(
      setCustomSelectOpen({
        open: false,
      })
    );
    if (!recordData) return;
    setSelectedCook(recordData);
    setLoading(true);
    setCookVerifyDialog(true);
    cookService
      .checkCookAvailability(recordData.id, Number(trialId))
      .then((res) => {
        setCookAvailability(res);
        setLoading(false);
      });
  };
  return (
    <div>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <div>
          {cookList && (
            <ResourceSelectDialog
              name={"Cooks"}
              list={Cooks}
              onClose={handleClose}
            />
          )}
          {[TrialsV3Status.PENDING, TrialsV3Status.COOK_ALLOCATED].includes(
            record?.status
          ) && (
            <Button
              style={{ margin: 10 }}
              variant="contained"
              onClick={() => {
                handleSelectCook();
              }}
              size="small"
            >
              Manually Shortlist Chef
            </Button>
          )}
          {subData &&
            subData?.data &&
            Array.isArray(subData?.data) &&
            (subData?.data?.length > 0 ? (
              <TrialAllocationMetaShow
                data={subData?.data}
                trail={record}
                setSort={setSort}
                sort={sort}
                refresh={() => {
                  // refreshData(props.trialId, rowsPerPage, page * rowsPerPage, sort);
                }}
              />
            ) : (
              <EmptyListPlaceholder message="No Trials Allocated Found" />
            ))}
        </div>
      )}
      <CookVerifyDialog
        open={cookVerifyDialog}
        handleClose={() => {
          setCookVerifyDialog(false);
          handleSelectCook();
        }}
        loading={loading}
        handleSubmit={() => {
          setCookList(false);
          trialsService
            .addManualLeadToTrial(Number(trialId), {
              cookId: selectedCook?.id,
              cookPreference: 1,
            })
            .then(() => {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "successful",
                type: CustomSnackbarTypes.SUCCESS,
              });
              refreshData(props.trialId, rowsPerPage, page * rowsPerPage, sort);
              refresh();
              setCookVerifyDialog(false);
            });
        }}
        selectedCook={selectedCook}
        cookAvailability={cookAvailability}
      />
    </div>
  );
};

export default TrialAllocationTab;
