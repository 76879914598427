import {
    InputLabel,
    Select,
    MenuItem,
    FormControl, DialogContent, Grid, DialogActions, Button, Dialog, TextField
} from '@mui/material';
import React, {useState} from 'react'
import {TrialsService} from "../../services/TrialServices";
import ContactedReasonsCategories from "../../enums/ContactedReasonsCategories";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "./CustomSnackBar";
import {OneMealService} from "../../services/OneMealService";

interface InputProps {
    trailId: any;
    row: any;
    open: boolean;
    setOpen: any;
    callback: (data: any) => void;
    type: number;
}

const trialService = new TrialsService();
const oneMealService = new OneMealService();

const AllocationContactedDialog = (props: InputProps) => {
    const [reasonCategory, setReasonCategory] = useState<ContactedReasonsCategories | null>(null);
    const [reasonSubCategory, setReasonSubCategory] = useState<string | null>(null);
    let handleClose = () => {
        setReasonCategory(null);
        setReasonSubCategory(null);
        props.setOpen(false);
    };

    const handleReasonCategoryChange = (event: any) => {
        setReasonCategory(event.target.value);
    }

    const handleReasonSubCategoryChange = (event: any) => {
        setReasonSubCategory(event.target.value);
    }

    function showSubCategoryOrNot(reasonCategory: string) {
        if (reasonCategory === ContactedReasonsCategories.OTHERS) {
            return (
                <FormControl style={{ width: 500 }}>
                    <TextField value={reasonSubCategory} onChange={handleReasonSubCategoryChange} label="Write a reason" />
                </FormControl>
            )
        }
        return null;
    }

  return (
      <Dialog
          onClose={handleClose}
          open={props.open}
          scroll={"paper"}
          maxWidth="xl"
      >
          <DialogContent>
              <Grid
                  container
                  spacing={2}
                  justifyContent={"space-evenly"}
                  style={{ padding: "12px 12px 12px 12px" }}
                  direction="column"
                  alignItems="center"
              >
                  <Grid item xs={12}>
                      <FormControl style={{ width: 500 }}>
                          <InputLabel id="select-label">Select One Reason</InputLabel>
                          <Select
                              labelId="select-label"
                              id="select-label"
                              defaultValue="Select a reason"
                              value={reasonCategory}
                              onChange={handleReasonCategoryChange}
                          >
                              {Object.values(ContactedReasonsCategories).map((val) => {
                                  return (
                                      <MenuItem key={val} value={val}>
                                          {val}
                                      </MenuItem>
                                  );
                              })}
                          </Select>
                      </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                      {
                          showSubCategoryOrNot(reasonCategory!)
                      }
                  </Grid>
              </Grid>
          </DialogContent>
          <DialogActions style={{ marginTop: "12px" }}>
              <Button
                  variant="contained"
                  onClick={() => {
                      if (!reasonCategory) {
                          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                              message: "Fill the details to proceed",
                              type: CustomSnackbarTypes.WARNING,
                          });
                          return;
                      }
                      if (reasonCategory === ContactedReasonsCategories.OTHERS) {
                          if (!reasonSubCategory) {
                              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                  message: "Fill the details to proceed",
                                  type: CustomSnackbarTypes.WARNING,
                              });
                              return;
                          }
                      }
                      let reason: any = '';
                      if(ContactedReasonsCategories.OTHERS === reasonCategory) {
                          reason = reasonSubCategory;
                      } else {
                          reason = reasonCategory;
                      }
                      if(props?.type === 1){
                          trialService?.setContactedAt(
                              props?.trailId,
                              props?.row?.original?.cookId,
                              {callSummary: reason}
                          ).then(() => {
                              handleClose();
                              props.callback(props?.row);
                          })
                      } else {
                          oneMealService
                              ?.setContactedAt(
                                  props?.trailId,
                                  props?.row?.original?.cookId,
                                  {callSummary: reason}
                              )
                          .then(() => {
                              handleClose();
                              props.callback(props?.row);
                          })
                      }
                  }}
                  size={"large"}
              >
                  Submit
              </Button>
              <Button
                  variant="contained"
                  color={"error"}
                  onClick={() => {
                      handleClose();
                  }}
                  size={"large"}
              >
                  Cancel
              </Button>
          </DialogActions>
      </Dialog>
  )
}

export default AllocationContactedDialog
