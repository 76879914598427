import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import CookSalaryList from "./CookSalaryList";
import {CookService} from "../../services/CookServices";
import Box from "@mui/material/Box";
import {CreateMgCookDialog} from "../Common/CreateMgCookDialog";
import {ConvertMgCookDialog} from "../Common/ConvertMgCookDialog";
import {MgCookResponse} from "../../interfaces/Cook/MgCookResponse";

interface InputProps {
  cookId: number;
}

const cookService = new CookService();

const CookSalaryTab = (props: InputProps) => {
  const [cookSalary, setCookSalary] = useState<MgCookResponse[]>([]);
  const isMgCook = Boolean(cookSalary[cookSalary.length - 1]?.endDate === null);
  const [isLoading, setIsLoading] = useState<boolean>();

  const [createMgCook, setCreateMgCook] = useState<boolean>(false);
  const [convertMgCook, setConvertMgCook] = useState<boolean>(false);

  const getData = (cookId: any) => {
    setIsLoading(true);
    cookService
      .getMgCookSalary(cookId)
      .then((data) => {
        setCookSalary(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData(props.cookId);
  }, [props.cookId]);

  return (
    <div>
      <Box display={'flex'} justifyContent={'flex-end'} mb="0.5rem">
        <Button variant={'contained'} size="small" onClick={() => { setCreateMgCook(true) }}>
          {!isMgCook ? 'Create MG Cook' : 'Update MG Cook Salary'}
        </Button>
          {isMgCook &&
            <Button variant={'contained'} size="small" color={'error'} onClick={()=>{setConvertMgCook(true)}} sx={{ml: 2}}>
                {'Convert MG Cook to Part-time'}
            </Button>
          }
      </Box>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <div>
          {cookSalary &&
            Array.isArray(cookSalary) &&
            (cookSalary.length > 0 ? (
              <CookSalaryList
                  data={cookSalary}
                  cookId={props.cookId}
                  callBack={()=>{
                      getData(props.cookId);
                  }}
              />
            ) : (
              <EmptyListPlaceholder message="No Previous details Found" />
            ))}
        </div>
      )}
      <CreateMgCookDialog
        callBack={()=>{
          getData(props.cookId);
        }}
        cookId={props.cookId}
        isCreate={!isMgCook}
        open={createMgCook}
        setOpen={setCreateMgCook}
      />
      <ConvertMgCookDialog
        callBack={()=>{
          getData(props.cookId);
        }}
        cookId={props.cookId}
        open={convertMgCook}
        setOpen={setConvertMgCook}
      />
    </div>
  );
};

export default CookSalaryTab;
