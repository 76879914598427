export enum FreshLeadStatus {
  FRESH_LEAD = "FRESH_LEAD",
  PROFILE = "PROFILE",
  SKILL_ASSESSMENT = "SKILL_ASSESSMENT",
  TRAINING = "TRAINING",
  APPROVAL_CHECKS = "APPROVAL_CHECKS",
  ONBOARDING = "ONBOARDING",
}

export enum FreshLeadOnboardingSubStatus {
  NO_CALL_DONE = "NO_CALL_DONE",
  FIRST_CALL_DONE = "1st_CALL_DONE",
  SECOND_CALL_DONE = "2nd_CALL_DONE",
  THIRD_CALL_DONE = "3rd_CALL_DONE",
  LOST_LEAD = "LOST_LEAD",
  JUNK_LEAD = "JUNK_LEAD",
}

export enum FreshLeadSubStatusReasoningsForLostLead {
  UNABLE_TO_CONNECT = "UNABLE_TO_CONNECT",
  NON_RESPONSIVE = "NON_RESPONSIVE",
  NOT_INTERESTED = "NOT_INTERESTED",
  NOT_FREE_TO_WORK = "NOT_FREE_TO_WORK",
  NOT_ELIGIBLE_FOR_WORK = "NOT_ELIGIBLE_FOR_WORK",
  OTHER = "OTHER",
}

export enum FreshLeadSubStatusReasoningForJunkLead {
  NOT_A_COOK = "NOT_A_COOK",
  INVALID_NUMBER = "INVALID_NUMBER",
}
