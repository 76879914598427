import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";
import { useState } from "react";
import { CookServiceTypeEnum } from "../../enums/CookServiceTypeEnum";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import FreshLeadInterface from "../../interfaces/Cook/FreshLeadInterface";
import { CookService } from "../../services/CookServices";
import CkMuiDialog from "./CkMuiDialog/CkMuiDialog";
import { CustomSnackbarTypes } from "./CustomSnackBar";

const cookService = new CookService();

interface InputProps {
  cookDetail: FreshLeadInterface;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  refresh: () => void;
}

const RemoveCookServiceTypeDialog = (props: InputProps) => {
  const [cookType, setCookType] = useState<CookServiceTypeEnum>();
  return (
    <CkMuiDialog
      isDialogOpen={props.isOpen}
      setDialogOpen={props.setIsOpen}
      onDialogClose={() => {}}
      title={"Remove Cook Type"}
      secondaryLabel={"Close"}
      secondaryAction={() => {
        props.setIsOpen(false);
      }}
      primaryAction={() => {
        if (props?.cookDetail?.cookTypeList !== null && props?.cookDetail?.cookTypeList.length !== 0 &&cookType ) {
          cookService
            .removeCookType(props?.cookDetail?.cookId, {
              cookType: cookType
            })
            .then(() => {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Successfull",
                type: CustomSnackbarTypes.SUCCESS,
              });
              props.refresh();
              props.setIsOpen(false);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        else if(props?.cookDetail?.cookTypeList == null || props?.cookDetail?.cookTypeList.length === 0){
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Add Cook Service Type First",
            type: CustomSnackbarTypes.WARNING,
          });
        }
      }}
      primaryLabel={"Submit"}
      isLoading={false}
    >
      <FormControl fullWidth sx={{minWidth: 200}}>
      <InputLabel>Select Cook Type to Remove</InputLabel>
      <Select
        value={cookType}
        onChange={(e) => {
          const selectedValue = e.target.value as CookServiceTypeEnum;
          if (selectedValue) {
            setCookType(selectedValue);
          }
        }}
        >
          {props?.cookDetail?.cookTypeList !== null && props?.cookDetail?.cookTypeList.length !== 0 &&
          (
            Object.values(props?.cookDetail?.cookTypeList).map((e) => (
              <MenuItem key={e} value={e}>
                <ListItemText primary={e} />
              </MenuItem>
            ))
          )}
      </Select>
    </FormControl>
    </CkMuiDialog>
  );
};

export default RemoveCookServiceTypeDialog;
