import { Button, Link, TableCell, TableRow } from "@mui/material";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import DeniedCookInterface from "../../interfaces/Cook/DeniedCookInterface";
import CkMuiTable from "./CkMuiTable/CkMuiTable";
import moment from "moment";

interface InputProps {
  cookData: DeniedCookInterface[];
  callBack: (trialId: number, cookId: number) => void;
  id: number;
  refresh: () => void;
}

const DeniedCooks = (props: InputProps) => {
  return (
    <div>
      <CkMuiTable
        sx={{}}
        headers={["Id", "TrialId", "Cook Id", "Cook Name", 'Reason', "Denied By", "Denied By Type", "Action", "Created At", "Updated At"]}
        rowData={[]}
        isTablePagination={false}
      >
        {props.cookData.map((cook, index) => {
          return (
            <TableRow key={index + ""}>
              <TableCell>{cook.id || "Not Available"}</TableCell>
              <TableCell>{props.id || "Not Available"}</TableCell>
              <TableCell>
                <Link href={`/#/cooks/${cook?.cookId}/show`}>
                  <p style={{ margin: 0 }}>{cook?.cookId || "Not Available"}</p>
                </Link>
              </TableCell>
              <TableCell>
                {`${cook.cookFullProfile.firstName} ${cook.cookFullProfile.lastName}` ||
                  "Not Available"}
              </TableCell>
              <TableCell>{cook?.cancellationReason || "---"}</TableCell>
              <TableCell>{cook?.deniedBy || "---"}</TableCell>
              <TableCell>{cook?.deniedByType || "---"}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={() => {
                    EventBus.emitEvent(
                      EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG,
                      {
                        title: "Are you sure?",
                        message: "Please confirm to revert the cook",
                        successLabel: "Confirm",
                        failureLabel: "Cancel",
                        onClose: (confirmed: boolean) => {
                          if (confirmed) {
                            props.callBack(props.id, cook.cookId);
                            props.refresh();
                          }
                        }
                      }
                    );
                  }}
                >
                  Revert
                </Button>
              </TableCell>
              <TableCell>
                {cook?.createdAt && moment(cook?.createdAt).format("DD-MMM-YYYY HH:mm:ss")}
              </TableCell>
              <TableCell>
                {cook?.updatedAt && moment(cook?.updatedAt).format("DD-MMM-YYYY HH:mm:ss")}
              </TableCell>
            </TableRow>
          );
        })}
      </CkMuiTable>
    </div>
  );
};

export default DeniedCooks;
