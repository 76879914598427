import React, {useEffect, useState} from 'react';
import {CookService} from "../../services/CookServices";
import {TablePagination} from "@mui/material";
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import CookSubscriptionListForModule from "./CookSubscriptionListForModule";

interface InputProps {
    cookId: number
}

const cookService = new CookService();

const CookSubscriptionTab = (props: InputProps) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    let [subData, setSubData] = useState<any>(null);
    let [isLoading, setIsLoading] = useState<boolean>();

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const refreshData = (cookId: number, limit: number, offset: number) => {
        setIsLoading(true)
        cookService.getAllCookSubscriptionForCook(cookId, limit, offset, {order: "DESC", field: "id"})
            .then((data) => {
                setSubData(data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        refreshData(props.cookId, rowsPerPage, page*rowsPerPage);
    }, [props.cookId, rowsPerPage, page])

    return (
        <div>
            {
                isLoading ?
                    <CircularLoader/>
                    :
                    <div>
                        {
                            subData &&
                            subData.data &&
                            Array.isArray(subData.data) &&
                            (
                                subData.data.length>0 ?
                                    <CookSubscriptionListForModule data={subData?.data}/>
                                    :
                                    <EmptyListPlaceholder
                                        message='No Cook Subscription Found'
                                    />
                            )
                        }
                        <TablePagination
                            component="div"
                            count={subData?.total || 0}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
            }
        </div>
    );
};

export default CookSubscriptionTab;
