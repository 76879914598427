import {
    BooleanField,
    DateField,
    ReferenceField,
    SimpleShowLayout,
    TextField,
} from 'react-admin';

function AreaMetaShow() {
    return (
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
            <ReferenceField source="cityId" link="show" reference="Cities">
                <TextField source="name" />
            </ReferenceField>
            <BooleanField source="isServiceable" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </SimpleShowLayout>
    )
}

export default AreaMetaShow;
