import {
  Grid,
  FormControl,
  Button,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Create } from "react-admin";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { ShortTimeEnum } from "../../enums/ShortTimeEnum";
import { BundleGenderPreference } from "../../enums/common";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import { useNavigate } from "react-router-dom";
import { OneMealService } from "../../services/OneMealService";
import { useLocation } from "react-router-dom";

const oneMealService = new OneMealService();

const OneMealServiceEdit = () => {
  const [time, setTime] = useState<string>("");
  const [totalPeople, setTotalPeople] = useState<number>(0);
  const [date, setDate] = useState<any>(null);
  const [houseId, setHouseId] = useState<any>(null);
  const [genderPreference, setGenderPreference] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();
  const oneMealServiceId = location.pathname.split("/")[2];

  useEffect(() => {
    oneMealService.getOneMealService(Number(oneMealServiceId)).then((res) => {
      setHouseId(res.data.houseId);
      setTime(res.data.time);
      setGenderPreference(res.data.genderPreference);
      setDate(res.data.date);
      setTotalPeople(res.data.totalPeople);
    });
  }, [oneMealServiceId]);

  const handleSubmit = () => {
    oneMealService
      .editOneMealService(Number(oneMealServiceId), {
        houseId,
        genderPreference,
        totalPeople,
        time,
        date: moment(date).format("YYYY-MM-DD"),
      })
      .then((data) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Success",
          type: CustomSnackbarTypes.SUCCESS,
        });
        if (data?.id) {
          navigate(`/OneMealService/${data?.id}/show`, { replace: true });
        }
      });
  };

  return (
    <Create title={"Create One Meal Service"}>
      <Grid
        container
        columns={1}
        rowSpacing={2}
        style={{ padding: "12px 12px 12px 12px" }}
      >
         <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <DatePicker
              label="Date"
              inputFormat="dd MMM yyyy"
              disableMaskedInput
              minDate={new Date()}
              value={date}
              onChange={(value: any) => {
                setDate(moment(value).format("YYYY-MM-DD"));
              }}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <InputLabel id="forGenderPreference">Gender Preference</InputLabel>
            <Select
              labelId="forGenderPreference"
              id="genderPreference"
              value={genderPreference}
              label="genderPreference"
              onChange={(event: any) => {
                setGenderPreference(event.target.value);
              }}
            >
              {Object.values(BundleGenderPreference).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <InputLabel id="forTotalPeople">Total People</InputLabel>
            <Select
              labelId="forTotalPeople"
              id="totalPeople"
              value={totalPeople}
              label="total People"
              onChange={(event: any) => {
                setTotalPeople(event.target.value);
              }}
            >
              {Object.values([1, 2, 3, 4, 5, 6]).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <InputLabel id="forTime">Trial Time</InputLabel>
            <Select
              labelId="forTime"
              id="time"
              value={time}
              label="trial time"
              onChange={(event: any) => {
                setTime(event.target.value);
              }}
            >
              {Object.values(ShortTimeEnum).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
            size={"large"}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Create>
  );
};

export default OneMealServiceEdit;
