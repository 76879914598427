import { Button, Stack } from "@mui/material";
import React from "react";
import {
  Show,
  Tab,
  TabbedShowLayout,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { CouponServiceV2 } from "../../services/CuponServiceV2";
import CouponMetaShowV2 from "./CouponMetaShowV2";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";

const couponServiceV2 = new CouponServiceV2();

const CustomButton = (props: any) => {
  let record = useRecordContext();

  return (
    <Button
      onClick={() => {
        props.callback(record);
      }}
      size={"small"}
      color={props.color || "secondary"}
      variant={"contained"}
      style={{ width: "max-content", fontSize: "12px" }}
    >
      {props.name}
    </Button>
  );
};

const RenderCTAButtons = (props: any) => {
  const refresh = useRefresh();
  const record = useRecordContext();

  return (
    <Stack direction={"row"} gap={2}>
      <CustomButton
        name={"Send Coupons"}
        color={"primary"}
        callback={(data: any) => {
          if(record?.userSet !== null){
            const SendCoupon = () => {
              couponServiceV2.sendCouponV2(Number(record?.id)).then((res) => {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Successful",
                  type: CustomSnackbarTypes.SUCCESS,
                });
                refresh();
              });
            };
            EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
              message: "Are you sure you want to Send this coupon?",
              successLabel: "Confirm",
              failureLabel: "Cancel",
              onClose: (isConfirmed: boolean) =>
                isConfirmed ? SendCoupon() : null,
            });
          }
          else{
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
              message: "User set is empty",
              type: CustomSnackbarTypes.ERROR,
            });
          } 
        }}
      />
    </Stack>
  );
};

const CouponShowV2 = (props: any) => {
  return (
    <Show {...props} actions={false}>
      <div >
        <Show {...props} actions={false}>
          <RenderCTAButtons />
          <TabbedShowLayout>
            <Tab label={"Details"}>
              <CouponMetaShowV2 />
            </Tab>
          </TabbedShowLayout>
        </Show>
      </div>
    </Show>
  );
};

export default CouponShowV2;
