import React from "react";
import {
  DateField,
  ImageField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";
import CheckInAndOutImages from "../Common/CheckInAndOutImages";
import CustomReferenceField from "../Common/CustomReferenceField";

const TemporaryReplacementMetaShow = () => {
  const record = useRecordContext();
  return (
    <Show actions={false} title=" ">
      <div style={{ display: "grid", gridTemplateColumns: "30% 40% 30%" }}>
        <SimpleShowLayout>
          <TextField label="Id" source="id" />
          <TextField label="Status" source="status" />
          <TextField label="Date" source="date" />
          <TextField label="Time" source="time" />
          <CustomReferenceField
            label="Created By"
            listName={record.createdByType === "ADMIN" ? "AdminUsers" : "Customers"}
            source={"createdBy"}
          />
          <TextField label="Created By Type" source="createdByType" />
          <DateField label="Created At" source="createdAt" showTime />
          <DateField label="Updated At" source="updatedAt" showTime />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <CustomReferenceField
            label="Locality Id"
            listName={"Localities"}
            source={"localityId"}
          />
          <TextField label="Locality Name" source="localityName" />
          <CustomReferenceField
            label="Area Id"
            listName={"Areas"}
            source={"areaId"}
          />
          <TextField label="Area Name" source="areaName" />
          <CustomReferenceField
            label="City Id"
            listName={"Cities"}
            source={"cityId"}
          />
          <TextField label="City Name" source="cityName" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <CustomReferenceField
            label="Bundle Id"
            listName={"UserBundles"}
            source={"userBundleMappingId"}
          />
          <TextField label="Slot" source="slot" />
          <TextField source="userSubscriptionLeaveId" />
          <TextField source="subscriptionId" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <CustomReferenceField
            label="Cook Id"
            listName={"Cooks"}
            source={"cookId"}
          />
          <TextField label="CookAllocated By" source="cookAllocatedBy" />
          <TextField
            label="CookAllocated By Email"
            source="cookAllocatedByEmail"
          />
          <DateField label="CheckIn Time" source="checkInTime" showTime />
          <DateField label="CheckOut Time" source="checkOutTime" showTime />
          <TextField label="Zoho Id" source="zohoId" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <CustomReferenceField
            label="Tr Failed By Id"
            listName={"AdminUsers"}
            source={"trFailedById"}
          />
          <TextField label="Cancelled By" source="cancelledByType"/>
          <TextField
            label="Tr Failed By Name"
            source="trFailedByDetails.name"
          />
          <TextField source="failureReason" />
          <TextField source="isFalseCheckin" />
        </SimpleShowLayout>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
      <SimpleShowLayout>
          <TextField label="conveyanceFormDetails- From Details" />
          <TextField
            label="Amount"
            source="conveyanceFormDetails.fromDetails.amount"
          />
          <TextField
            label="Location"
            source="conveyanceFormDetails.fromDetails.location"
          />
          <ImageField label="Image" source="conveyanceFormDetails.fromDetails.image"/>
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField label="conveyanceFormDetails- To Details" />
          <TextField
            label="Amount"
            source="conveyanceFormDetails.toDetails.amount"
          />
          <TextField
            label="Location"
            source="conveyanceFormDetails.toDetails.location"
          />
          <ImageField label="Image" source="conveyanceFormDetails.toDetails.image"/>
        </SimpleShowLayout>
      </div>
      <CheckInAndOutImages/>
    </Show>
  );
};

export default TemporaryReplacementMetaShow;
