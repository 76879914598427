import { Show, Tab, TabbedShowLayout } from "react-admin";
import CustomerMetaShow from "./CustomerMeta";
import { useLocation } from "react-router-dom";
import CustomerHouseTab from "./CustomerHouseTab";
import UserPayoutTab from "../Payout/UserPayoutTab";
import { useState } from "react";
import { Grid, Stack } from "@mui/material";
import TransactionButton from "../Common/TransactionButton";
import { AddBankAccountDialog } from "../Common/AddBankAccountDialog";
import { PayoutUserType } from "../../enums/PayoutEnums";
import UpdateTagDialog from "../Common/UpdateTagDialog";
import CustomerTransaction from "./CustomerTransaction";

const RenderCTAButtons = (props: any) => {
  return (
    <Stack p={2}>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item>
          <TransactionButton
            onClickHandler={() => {
              props.setOpenAddBankAccountDialog(true);
            }}
            name={"Add UPI/Bank Account"}
          />
        </Grid>
        <Grid item>
          <TransactionButton
            onClickHandler={() => {
              props.setOpenTagDialog(true);
            }}
            name={"Update Tag"}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

const CustomerTabShow = () => {
  let location = useLocation();
  let customerId = location.pathname.split("/")[2];

  return (
    <TabbedShowLayout>
      <Tab label={"Details"}>
        <CustomerMetaShow />
      </Tab>
      <Tab label={"House Details"}>
        <CustomerHouseTab customerId={parseInt(customerId)} />
      </Tab>
      <Tab label={"Bank Details"}>
        <UserPayoutTab
          userType={PayoutUserType.CUSTOMER}
          userId={parseInt(customerId)}
        />
      </Tab>
      <Tab label={"Transaction Details"}>
        <CustomerTransaction customerId={parseInt(customerId)} />
      </Tab>
    </TabbedShowLayout>
  );
};
const CustomerShow = (props: any) => {
  const [openAddBankAccountDialog, setOpenAddBankAccountDialog] =
    useState<boolean>(false);
  const [openTagDialog, setOpenTagDialog] = useState<boolean>(false);
  return (
    <div>
      <Show {...props} actions={false}>
        <RenderCTAButtons
          setOpenAddBankAccountDialog={setOpenAddBankAccountDialog}
          setOpenTagDialog={setOpenTagDialog}
        />
        <CustomerTabShow />
        <AddBankAccountDialog
          open={openAddBankAccountDialog}
          setOpen={setOpenAddBankAccountDialog}
          userType={PayoutUserType.CUSTOMER}
        />
        <UpdateTagDialog open={openTagDialog} setOpen={setOpenTagDialog} />
      </Show>
    </div>
  );
};
export default CustomerShow;
