import axios from "axios";
import {AuthService} from "./services/AuthService";
import EventBus from "./EventBus";
import {EventBusEventsEnum} from "./enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "./components/Common/CustomSnackBar";

let http = axios.create({
    baseURL: process.env.REACT_APP_PUBLIC_URL,
    headers: {
        'Content-Type': 'application/json',
        'X-APP-CODE': 'ADMIN',
    }
});

http.interceptors.request.use((req) => {
    let authData = localStorage.getItem('auth');
    const { accessToken } = !!authData ? JSON.parse(authData as string).data : '';
    req.headers = {
        ...req.headers,
        'Authorization': `Bearer ${accessToken}`,
    }
    return req
})

http.interceptors.response.use(
    (res) => res,
    async (err) => {
        if (err.request && err.request.status === 401) {
            const authService = new AuthService();
            await authService.refreshToken(true);
        } else {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: err?.response?.data?.error,
                type: CustomSnackbarTypes.ERROR
            });
        }
        throw err
    }
)

export default http;
