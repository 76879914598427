import { Typography } from "@mui/material";
import { get } from "lodash";
import {  FC, memo } from "react";
import { TextFieldProps, useRecordContext, sanitizeFieldRestProps} from "react-admin";

interface CkTextFieldProps extends TextFieldProps {
    objMap?: {[key: string]: string}
}

export const CkTextField: FC<CkTextFieldProps> = memo(props => {
    const { className, source, emptyText, objMap, ...rest } = props;
    const record = useRecordContext(props);
    let value;
    if(source){
         value = get(record, source);
    }

    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
            {...sanitizeFieldRestProps(rest)}
        >
            {
            objMap ? objMap[value] :
            value != null && typeof value !== 'string'
                ? JSON.stringify(value)
                : value || emptyText}
        </Typography>
    );
});