import * as React from 'react';
import Box from '@mui/material/Box';
import { styled, alpha } from '@mui/material/styles';
import AssessmentService from '../../services/AssementServices';
import CircularLoader from '../Common/CircularLoader';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { Chip } from '@mui/material';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
// Custom TreeItem with styling

const assessmentService =new AssessmentService();

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.grey[200],
    [`& .${treeItemClasses.content}`]: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
        [`& .${treeItemClasses.label}`]: {
    fontSize: '1.2rem',
    fontWeight: 700,
    },
    },
    [`& .${treeItemClasses.iconContainer}`]: {
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(0, 1.2),
        ...theme.applyStyles('light', {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
    }),
        ...theme.applyStyles('dark', {
    color: theme.palette.primary.contrastText,
    }),
    },
    [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
    ...theme.applyStyles('light', {
    color: theme.palette.grey[800],
    }),
}));


const renderTree = (nodes:any) => {
    function getItemId() {
        return Math.random().toString(36).substr(2, 9);
    }
    function getByLabelText(node:any) {
        switch (node?.type){
            case 'Module':
                return `Is Active: ${node?.isActive}  Sequence No. : ${node?.sequenceNo}`;
            case 'Topic':
                return `Is Active: ${node?.isActive},  Sequence No. : ${node?.sequenceNo},  Is Mandatory : ${node?.isMandatory},  Minimun Score Required : ${node?.minimunScoreRequired},  Topic Type : ${node?.topicType}`;
            case 'Material':
                return `Is Active: ${node?.isActive},  Duration : ${node?.duration} sec`;
            case 'Question':
                return `Is Active: ${node?.isActive},  Sequence No. : ${node?.sequenceNo},  Maximum Marks : ${node?.maximumMarks},  Question Type : ${node?.questionType}`;        
            default:
                return `Is Active: ${node?.isActive},  Is Correct. : ${node?.isCorrect},  Marks : ${node?.marks},  Option Type : ${node?.optionType}`;
        }
    }
    return nodes.map((node:any) => (
        <Box sx={{display:"flex",flexDirection:"column", gap:"0.5rem" , alignItems:"flex-start"}}>
            <CustomTreeItem
                key={getItemId()}
                itemId={getItemId()}
                label={node?.type!=="Material"?`${node?.type}_${node?.id}: ${node?.name} ( ${node?.nameHi} ) ( ${node?.nameBn} )`:`Video: ${node?.name}`}
            >
                {node?.type!=="Material"?Array.isArray(node?.children) && renderTree(node?.children):
                <Chip icon={<PlayArrowRoundedIcon color='error'/>} label={"Click Here to View!!!"} sx={{fontWeight:700,fontSize:"1.2rem"}} onClick={() => window.open(node?.materialLink, '_blank')} />}
            </CustomTreeItem>
            <Chip label={getByLabelText(node)} sx={{padding:"0px"}}/>
        </Box>
  ));
};


export default function AssessmentResources() {
    const[loading,setLoading]=React.useState<boolean>(false);
    const[data,setData]=React.useState<any>([]);

    React.useEffect(()=>{
        setLoading(true);
        assessmentService.fetchAllResouces().then((res)=>{
            setData(res);
            setLoading(false);
        })
        .catch((err)=>{
            setLoading(false);
            console.log(err);
        })
    },[])
  return (
    <>
    {
     !loading?
        <Box sx={{ minHeight: 352, minWidth: 250 }}>
            <SimpleTreeView defaultExpandedItems={['root']}>
                    {renderTree(data)}
            </SimpleTreeView> 
        </Box>
        :<CircularLoader/>
    }
    </>
  )
}