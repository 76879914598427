function convertMinutes(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  if (hours > 24) {
    const days = Math.floor(hours / 24);
    const hoursAfterDays = hours % 24;
    return `${days}d ${hoursAfterDays}h ${mins}m`;
  }
  return `${hours}h ${mins}m`;
}

export default convertMinutes;
