import React, { useState, useEffect } from "react";
import CircularLoader from "../Common/CircularLoader";
import { CookService } from "../../services/CookServices";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import TransactionChefpay from "../Transactions/TransactionChefpay";

interface InputProps {
    cookId: number;
}

let cookService = new CookService()

const QrChefpayTransactions=(props:InputProps)=> {
    const [subData, setSubData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>();

    const refreshData = (cookId: number) => {
        setIsLoading(true)
        cookService.getQrChefpayTransactions(cookId)
           .then((data) => {
                setSubData(data.data);
                console.log(data.data);

                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            })
    };

    useEffect(()=>{
        refreshData(props.cookId)
    },[props.cookId])
  return (
    <div>
        {isLoading ? (
        <CircularLoader />
      ) : (
        <div>
          {subData &&
            subData.data &&
            Array.isArray(subData.data) &&
            (subData.data.length > 0 ? (
             <TransactionChefpay data={subData?.data}/>
            ) : (
              <EmptyListPlaceholder message="No Transactions Found" />
            ))}
        </div>
      )}
    </div>
  )
}

export default QrChefpayTransactions
