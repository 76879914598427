import { TableRow, TableCell, Button } from "@mui/material";
import CkMuiTable from "./CkMuiTable/CkMuiTable";
import { useNavigate } from "react-router-dom";
import FreshLeadInterface from "../../interfaces/Cook/FreshLeadInterface";
import { FreshLeadStatus } from "../../enums/FreshLeadStatus";
import { AllCookOnBoardingSubStatus } from "../../enums/AllCookOnboardingSubStatus";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";
import {UserGroupsEnum} from "../../enums/UserGroupsEnum";
import {useAppSelector} from "../../store/hooks";

interface AssessmentDataInterface {
    moduleId: number;
    moduleName: string;
    moduleTopics: {
        attempted: boolean;
        completed: boolean;
        maximumScore: number;
        topicProgressId: number;
        percentAttempted: number;
        topicId: number;
        topicName: string;
        totalScore: number;
    }[];
}

interface InputProps {
  cookDetail: FreshLeadInterface;
  moduleData: AssessmentDataInterface;
}

const SkillAssessmentRatingTable = ({ cookDetail, moduleData }: InputProps) => {
  let navigate = useNavigate();

    const userDetails = useAppSelector((state) => state.userDetails.value);
    let groupsArray: string[] = userDetails.adminUser.accessGroups;
    const checkDisabled = () => {
        if(GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER, UserGroupsEnum.EXECUTIVE])){
            return false
        } else {
            return (cookDetail.onboardingStatus === FreshLeadStatus.SKILL_ASSESSMENT ? false : true) &&
                (cookDetail.onboardingStatus === FreshLeadStatus.ONBOARDING && cookDetail.onboardingSubStatus === AllCookOnBoardingSubStatus.COMPLETE ? false : true)
        }
    };
  return (
    <div style={{ marginBottom: "5rem" }}>
      <h3 style={{ marginLeft: "1rem" }}>{moduleData?.moduleName}</h3>
      <CkMuiTable
        sx={{}}
        headers={["Item", "Status", "Score", "Action"]}
        rowData={[]}
        isTablePagination={false}
        alignment="left"
      >
        {moduleData?.moduleTopics?.map(
          (eachTopic) => {
              return (
                <TableRow key={eachTopic?.topicId}>
                  <TableCell>{eachTopic?.topicName}</TableCell>
                  <TableCell>
                    {"Active"}
                  </TableCell>
                  <TableCell>
                    {eachTopic?.totalScore}
                  </TableCell>
                  <TableCell>
                    <Button
                      disabled={checkDisabled()}
                      onClick={() => {
                        navigate(
                          `/skill-assessment?cookId=${cookDetail.cookId}&topicId=${eachTopic?.topicProgressId}`
                        );
                      }}
                    >
                      {eachTopic?.attempted
                        ? "ReTake Assessment"
                        : "Take Assessment"}
                    </Button>
                  </TableCell>
                </TableRow>
              );
          }
        )}
      </CkMuiTable>
    </div>
  );
};

export default SkillAssessmentRatingTable;
