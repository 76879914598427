enum ReasonSubreasonModuleEnum {
    TRIAL = "TRIAL_V3",
    ONE_MEAL_SERVICE="ONE_MEAL_SERVICE",
    SUBSCRIPTION = "SUBSCRIPTION",
    CHEF_FOR_PARTY = "CHEF_FOR_PARTY_ORDER",
    USER_TO_COOK_PAYMENT = "USER_TO_COOK_PAYMENT",
    DUPLICATEPAYMENT = "DUPLICATEPAYMENT",
    USER_BUNDLE_PAYMENTS = "USER_BUNDLE_PAYMENTS",
    STAR_CLEANER = "STAR_CLEANER",
    DISCOUNT_COUPON_ORDER = "DISCOUNT_COUPON_ORDER",
    PLATFORM_LEAD = "PLATFORM_LEAD",
    MARKET_PLACE_ORDER = "MARKET_PLACE_ORDER",
    CX_LEAVES="CX_LEAVES",
    REVERSE_PAYOUT="REVERSE_PAYOUT"
}

export default ReasonSubreasonModuleEnum