import { FilterAlt, FilterAltOff } from "@mui/icons-material";
import { Box, Button, Card, CardContent, InputLabel } from "@mui/material";
import {
    Datagrid,
    DateField,
    DateInput,
    ExportButton,
    FilterLiveSearch,
    FunctionField,
    List,
    NumberField,
    SelectInput,
    ShowButton,
    TextField, TextInput, TopToolbar
} from "react-admin";
import { CityEnum, CityIdMapping } from "../../enums/CityEnum";
import { UserType } from "../../enums/common";
import { MetabaseDasboardId } from "../../enums/MetabsaeDashboardId";
import { PermanentReplacementStatus } from "../../enums/PermanentReplacementStatus";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setPermanentReplacementSideFilter } from "../../store/reducers/customSelect";
import CallButton from "../Common/CallButton/CallButton";
import CustomReferenceField from '../Common/CustomReferenceField';
import MetabaseTransactionButton from "../Common/MetabaseDashboardButton";

const FilterSidebar = () => {
    return (
      <Box
        sx={{
          marginTop: "65px",
          display: {
            xs: "none",
            sm: "block",
          },
          order: -1,
          width: "17em",
          marginRight: "1em",
        }}
      >
        <Card>
          <CardContent>
            <InputLabel>Customer Name</InputLabel>
            <FilterLiveSearch source="customerName"/>
            <InputLabel>Customer Phone</InputLabel>
            <FilterLiveSearch source="customerPhone"/>
          </CardContent>
        </Card>
      </Box>
    );
  };

const filters = [
    <SelectInput
        label="Filter by status"
        source="status"
        alwaysOn
        emptyText="Clear Filter"
        choices={
            Object.values(PermanentReplacementStatus)
                .map(key => {
                    return {
                        id: key,
                        name: key
                    }
                })
        }
    />,
    <TextInput label="Trial Owner Name" source="ownerName" alwaysOn />,
    <DateInput label="Start Date" source="startDate" alwaysOn />,
    <DateInput label="End Date" source="endDate" alwaysOn />,
    <SelectInput
    label="City"
    source="cityId"
    alwaysOn
    emptyText="Clear Filter"
    choices={
        Object.values(CityEnum)
            .map(key => {
                return {
                    id: CityIdMapping[key],
                    name: key
                }
            })
    }
/>,
    <SelectInput
        source={"wantsOldCookBack"}
        label={"Wants Old Cook Back"}
        alwaysOn
        emptyText="Clear Filter"
        choices={
            [
                {id: 'true', name: 'Yes'}
            ]
        }
    />
];

const ListActions = (props: any) => {
    const dispatch = useAppDispatch();
    return (
      <TopToolbar>
          <MetabaseTransactionButton dashboardId={MetabaseDasboardId.PERMENANT_REPLACEMENT} />
          <Button variant="text"
            size={"small"}
            onClick={() => {
              dispatch(setPermanentReplacementSideFilter({
                permanentReplacemetSideFilter: !props.openSideFilterShow
              }))
            }}
            startIcon={props.openSideFilterShow ? <FilterAlt /> : <FilterAltOff />} color="primary"
          >
            Filter
          </Button>
          <ExportButton />
        </TopToolbar>
    );
  };

const PermanentReplacementList = (props: any) => {
    const showSideFilter = useAppSelector((s) => s.customSelect.value.permanentReplacemetSideFilter)
    return (
        <List
         {...props}
         filters={filters}
         sort={{ field: 'id', order: 'DESC' }}
         actions={<ListActions openSideFilterShow={showSideFilter} />}
         aside={showSideFilter ? <FilterSidebar /> : null}
        >
            <Datagrid bulkActionButtons={false}>
                <NumberField source="id" />
                <TextField source="status" sortable={false} />
                <TextField label={'CX Name'} source="customerName" sortable={false} />
                <FunctionField label= "CX Mobile" render={(record: any) => <CallButton toPhoneNumber={record?.customerPhone} userType={UserType.CUSTOMER}/>}/>
                <TextField source="ownerId" sortable={false} />
                <CustomReferenceField label="User Bundle" listName={"UserBundles"} source={"userBundleMappingId"} />
                <TextField source="cityName" />
                <TextField source="ownerName" />
                <TextField source="ownerEmail" />
                <TextField source="noOfTrials" />
                <TextField source="recentTrialDate" />
                <TextField source="recentTrialStatus" />
                <DateField source="createdAt" showTime />
                <DateField source="updatedAt" showTime/>
                <ShowButton />
            </Datagrid>
        </List>
    );
};

export default PermanentReplacementList;
