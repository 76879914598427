import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useRefresh } from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { TempReplacementService } from "../../services/TemporaryReplacement";
import { useAppDispatch } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import Cooks from "../Cooks/Cooks";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import ResourceSelectDialog from "./ResourceSelectDialog";

const temporaryReplacement = new TempReplacementService();
interface InputProps {
  data: any;
  open: boolean;
  setOpen: (open: boolean) => any;
}

const AssignCookOnTempRep = (props: InputProps) => {
  const refresh = useRefresh();
  const [selectedCook, setSelectedCook] = useState<any>(null);
  const [trCookCut, setTrCookCut] = useState<string>("");
  const dispatch = useAppDispatch();
  const [openCookList, setOpenCookList] = useState(false);

  const handleClose = (recordData: any) => {
    setOpenCookList(false);
    setSelectedCook(recordData);
    setTrCookCut("150");
  };

  const handleSelectCook = () => {
    setOpenCookList(true)
    dispatch(
      setCustomSelectOpen({
        open: true,
      })
    );
  };

  const handleSubmit = () => {
    if(selectedCook && Number(trCookCut) >= 0){
      if(props?.data){
        let payload = {    
          cookId: selectedCook?.id,
          TRCookCut: trCookCut,
        };
        temporaryReplacement
          .assignCook(Number(props?.data?.id), payload)
          .then((res) => {
            if (res) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Success",
                type: CustomSnackbarTypes.SUCCESS,
              });
            }
            setTrCookCut("");
            setSelectedCook(null);
            props.setOpen(false);
            refresh();
          });
      }
    }else{
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: `Fill the details properly`,
        type: CustomSnackbarTypes.ERROR,
      });
    }
  }

  return (
    <div>
      { openCookList &&
        <ResourceSelectDialog
          name={"Cooks"}
          list={Cooks}
          onClose={handleClose}
        />
      }
      <Dialog open={props?.open} scroll={"paper"} maxWidth="xl">
        <DialogTitle>Assign cook to TR</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={handleSelectCook}
            >
              Select Cook
            </Button>
            {!!selectedCook && (
              <p>Selected cook - {selectedCook?.fname + selectedCook?.lname}</p>
            )}
             <TextField
              value={trCookCut}
              type={"number"}
              label={"TR Cook Cut"}
              style={{
                marginTop: "12px",
              }}
              onChange={(event) => {
                setTrCookCut(event.target.value);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            size={"large"}
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              props.setOpen(false);
              setTrCookCut("");
              setSelectedCook(null);
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AssignCookOnTempRep;
