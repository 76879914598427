import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import ResourceSelectDialog from "./ResourceSelectDialog";
import { useRefresh } from "react-admin";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import { useAppDispatch } from "../../store/hooks";
import Cooks from "../Cooks/Cooks";
import { CookDetailTypeEnumChefForParty } from "../../enums/CookDetailTypeEnumChefForParty";
import {ErrorDialog} from "./ErrorDialog";
import httpClient from "../../HttpClient";

interface InputProps {
  data: any;
  open: boolean;
  setOpen: (open: boolean) => any;
  trialId: string;
}


const AssignCookOnChefForParty = (props: InputProps) => {
  const refresh = useRefresh();
  const [selectedCook, setSelectedCook] = useState<any>(null);
  const [cookCut, setCookCut] = useState<string>("0");
  const [allocationNotes, setAllocationNotes] = useState<string>("");
  const [type, setType] = useState<string>("")
  const dispatch = useAppDispatch();
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorRes, setErrorRes] = useState<any>();

  const handleClose = async (recordData: any) => {
    setSelectedCook(recordData);
  };

  const handleSelectCook = () => {
    dispatch(
      setCustomSelectOpen({
        open: true,
      })
    );
  };

  const handleSubmit = () => {
    if(!selectedCook) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: `Select a Cook`,
        type: CustomSnackbarTypes.ERROR,
      });
      return;
    }
    if(cookCut === '') {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: `Enter cook cut`,
        type: CustomSnackbarTypes.ERROR,
      });
      return;
    }
    if(type === '') {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: `Enter cook type`,
        type: CustomSnackbarTypes.ERROR,
      });
      return;
    }
    let payload = {
      cookId: selectedCook.id,
      cookCut: cookCut,
      allocatorComments: allocationNotes,
      type,
    };
    httpClient.patch(`/centaurus/chef-for-party/${parseInt(props?.data?.id)}/assign-cook`, payload).then((res) => {
      if (res) {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Success",
          type: CustomSnackbarTypes.SUCCESS,
        });
      }
      setCookCut("0");
      setSelectedCook(null);
      props.setOpen(false);
      setAllocationNotes('');
      refresh();
    })
        .catch((err) => {
          setErrorRes(err?.response?.data?.data);
          if(err?.response?.data?.data){
            setOpenError(true);
          }
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: err?.response?.data?.data ? `` : err?.response?.data?.error,
            type: CustomSnackbarTypes.ERROR,
            duration:  err?.response?.data?.data ? 0 : 3000,
          });
        })
  };

  return (
    <div>
      {!!props.open && (
        <ResourceSelectDialog
          name={"Cooks"}
          list={Cooks}
          onClose={handleClose}
        />
      )}
      <Dialog open={props.open} scroll={"paper"} maxWidth="xs" fullWidth>
        <DialogTitle>Assign cook</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={handleSelectCook}
            >
              Select Cook
            </Button>
            {!!selectedCook && (
              <p>Selected cook - {selectedCook?.fname + selectedCook?.lname}</p>
            )}
            <TextField
              value={cookCut}
              type={"number"}
              label={ "Cook Cut"}
              style={{
                marginTop: "12px",
              }}
              onChange={(event) => {
                setCookCut(event.target.value);
              }}
            />
            <FormControl fullWidth>
              <InputLabel id="cookType">Cook Type</InputLabel>
              <Select
                labelId="cookType"
                id="cookType"
                value={type}
                label="Cook Type"
                onChange={(event: any) => {
                  setType(event.target.value);
                }}
              >
                {Object.values(CookDetailTypeEnumChefForParty).map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              value={allocationNotes}
              type={"string"}
              label={'Allocation Notes'}
              style={{
                marginTop: "12px",
              }}
              onChange={(event) => {
                setAllocationNotes(event.target.value);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              props.setOpen(false);
              setCookCut("0");
              setSelectedCook(null);
              setAllocationNotes('');
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorDialog
          open={openError}
          onClose={() => {setOpenError(false)}}
          data={errorRes}
      />
    </div>
  );
};

export default AssignCookOnChefForParty;
