import { Close } from "@mui/icons-material";
import {
  Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, Portal,
  Snackbar, TextField
} from "@mui/material";
import * as React from "react";
import UserBundleService from "../../services/BundleService";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "./CustomSnackBar";

interface InputProps {
  data: any;
  open: boolean;
  callback: (data: any) => void;
}

export const CreateExtensionDialog = (props: InputProps) => {
  let [visits, setVisits] = React.useState<any>();
  let [reason, setReason] = React.useState<any>();
  let [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const userBundleService = new UserBundleService();

  let handleClose = () => {
    props.callback({
      visits,
      reason
    });
    setVisits(null);
    setReason('');
  };
  let handleSnackbarClose = () => {
    setSnackBarOpen(false);
  };
  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={props.open}
        scroll={"paper"}
        maxWidth="xl"
      >
        <DialogTitle>Create Extension</DialogTitle>
        <DialogContent>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
          <Grid
            container
            columns={1}
            rowSpacing={0.9}
            style={{ padding: "12px 12px 12px 12px" }}
          >
            <FormControl fullWidth>
              <TextField
                label="Visits"
                id="Visits"
                value={visits}
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                onChange={(event) => setVisits(event.target.value)}
              />
              <TextField
                label="Reason"
                id="Reason"
                value={reason}
                style={{ marginTop: "30px" }}
                onChange={(event) => setReason(event.target.value)}
              />
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              if (!visits) {
                setSnackBarOpen(true);
                return;
              }
              userBundleService
                .createExtension(props.data.id, {
                  visits: Number(visits),
                  reason,
                })
                .then(() => {
                  handleClose();
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: 'Success',
                    type: CustomSnackbarTypes.SUCCESS
                  });
                });
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              handleClose();
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Portal>
        <Snackbar
          open={snackBarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
        >
          <Alert severity="warning">Fill number of Visits to proceed</Alert>
        </Snackbar>
      </Portal>
    </div>
  );
};
