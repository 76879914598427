import {Button, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import moment from "moment";
import {ZohoBaseUrl, ZohoModulesUrl} from "../../enums/zoho";

const TemporaryReplacementListForModule = (props: any) => {
    return (
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell>Id</TableCell>
                            <TableCell>User Name</TableCell>
                            <TableCell>User Mobile</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>CookId</TableCell>
                            <TableCell>TR Cook name</TableCell>
                            <TableCell>TR Cook mobile</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Plan</TableCell>
                            <TableCell>Zoho</TableCell>
                            <TableCell>CreatedAt</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.map((row: any) => (
                            <TableRow
                                key={row.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell>  
                                    <Link href={`/#/TemporaryReplacement/${row?.id}/show`}>
                                        <Button size="small" variant={'contained'} color={'primary'}>
                                            Show
                                        </Button>
                                    </Link>
                                </TableCell>
                                <TableCell scope="row">{row?.id}</TableCell>
                                <TableCell>
                                    {
                                        !!row?.userBundleMapping?.house?.customers &&
                                        Array.isArray(row.userBundleMapping.house.customers) &&
                                        row.userBundleMapping.house.customers.map((cust: any) => {
                                            return (
                                                <p key={cust.fname}>{cust.fname}</p>
                                            )
                                        })
                                    }
                                </TableCell>
                                <TableCell>
                                    {
                                        !!row?.userBundleMapping?.house?.customers &&
                                        Array.isArray(row.userBundleMapping.house.customers) &&
                                        row.userBundleMapping.house.customers.map((cust: any) => {
                                            return (
                                                <p key={cust.mobile}>{cust.mobile}</p>
                                            )
                                        })
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {row?.userBundleMapping?.house?.address}
                                </TableCell>
                                <TableCell align="center">
                                    <Link href={`/#/Cooks/${row?.cookId}/show`}>
                                        {row?.cookId}
                                    </Link>
                                </TableCell>
                                <TableCell align="center">
                                    {row?.cook?.fname}
                                </TableCell>
                                <TableCell align="center">
                                    {row?.cook?.mobile}
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        !!row?.date &&
                                        moment(row?.date, 'YYYY-MM-DD').format('DD MMM YYYY')
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {row?.time}
                                </TableCell>
                                <TableCell align="center">
                                    {row?.status}
                                </TableCell>
                                <TableCell align="center">
                                    {row?.userBundleMapping?.userBundleMeta?.plan}
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        !!row?.zohoId &&
                                      <Link
                                        href={`${ZohoBaseUrl}${ZohoModulesUrl.TR}/${row.zohoId}`}
                                        target="_blank"
                                        rel="noopener"
                                      >
                                          {row.zohoId}
                                      </Link>
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        !!row?.createdAt &&
                                        moment(row?.createdAt).format('DD MMM YYYY, hh:mm:ss a')
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default TemporaryReplacementListForModule;
