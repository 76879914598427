import React from "react";
import {Card,} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import '../OmsTimeTable/index.css';
import VisitTrackerTab from "./VisitTrackerTab";
import CookActivityTracker from "./CookActivityTracker";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
      <div role="tabpanel" hidden={value !== index} id={`visit-tracker-${index}`}{...other}>
        {value === index && (
            <Box>
                {children}
            </Box>
        )}
      </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const VisitTracker = (props: any) => {

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Card sx={{ width: '100%', p: 1 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="Visit tracker tabs">
            <Tab label="Visit Tracker" {...a11yProps(0)} />
            <Tab label="Cook Activity Tracker" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <VisitTrackerTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
         <CookActivityTracker />
        </CustomTabPanel>
      </Card>
    </React.Fragment>
  );
};
export default VisitTracker;
