import { Create, SimpleForm, TextInput } from "react-admin";

const VasCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="description" />
        </SimpleForm>
    </Create>
);

export default VasCreate;