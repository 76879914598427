import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from 'moment';

const VendorPayoutListModule = (props: any) => {

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Payout Type</TableCell>
            <TableCell>Year & Month</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Status Updated At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.data.map((element: any) => {
            return element?.data.map((data: any) => {
              return (
                <TableRow>
                  <TableCell>{data?.id}</TableCell>
                  <TableCell>{data?.comments}</TableCell>
                  <TableCell>{data?.type}</TableCell>
                  <TableCell>{data?.yearAndMonth}</TableCell>
                  <TableCell>{data?.amount}</TableCell>
                  <TableCell>{data?.status}</TableCell>
                  <TableCell>{moment(data?.updatedAt).format("YYYY/MM/DD, HH:MM")}</TableCell>
                </TableRow>
              );
            });
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VendorPayoutListModule;
