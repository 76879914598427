import {Box, CircularProgress, Table, TableHead, TableBody, TableCell, TableRow, Button} from "@mui/material";
import React from "react";
import FieldViewService from "../../services/FieldViewService";
import FieldViewResponse from "../../interfaces/FieldView/FieldViewResponse";
import moment from "moment";
import CreateFieldDialog from "./CreateFieldDialog";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";
import UpdateFieldDialog from "./UpdateFieldDialog";

const fieldViewService = new FieldViewService();

const CreateFieldList = () => {
    const [fieldListData, setFieldListData] = React.useState<FieldViewResponse[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [openUpdateDialog, setOpenUpdateDialog] = React.useState<boolean>(false);
    const [fieldToUpdate, setFieldToUpdate] = React.useState<FieldViewResponse>();
    const [openCreateFieldDialog, setOpenCreateFieldDialog] = React.useState<boolean>(false);

    const getAllFieldList = () => {
        setLoading(true);
        fieldViewService?.getAllDetailParameters(100, 0).then((res: {total: number, data: FieldViewResponse[]}) => {
            if(res) {
                setLoading(false);
                console.log(res?.data);
                setFieldListData(res?.data);
            }
        }).catch((err) => {
            setLoading(false);
        })
    }
    const handleUpdateClicked = (field: FieldViewResponse) => {
        setFieldToUpdate(field);
        setOpenUpdateDialog(true);
    }
    const handleCreateFieldClicked = () => {
        setOpenCreateFieldDialog(true);
    }
    const handleCreateFieldSubmitClicked = (inputType: string, values: string[], label: string, isActive: boolean) => {
        if(inputType && values && label) {
            setLoading(true);
            const payload = {
                inputType: inputType,
                values: values?.length >0 ? JSON.stringify(values) : "",
                label: label,
                isActive: isActive
            }
            fieldViewService?.createAdditionalDetailParameters(payload).then((res: FieldViewResponse) => {
                if(res) {
                    console.log(res);
                    setLoading(false);
                    setOpenCreateFieldDialog(false);
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: 'Field Created Successfully',
                        type: CustomSnackbarTypes.SUCCESS
                    });
                    getAllFieldList();
                }
            })
        } else {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: 'Please fill all the fields',
                type: CustomSnackbarTypes.ERROR
            });
        }
    }
    const handleUpdateSubmitClicked = (payload: any) => {
        setLoading(true);
        if(fieldToUpdate?.id) {
            fieldViewService?.updateAdditionalDetailParameters(fieldToUpdate?.id, payload).then((res: FieldViewResponse) => {
                if(res) {
                    console.log(res);
                    setLoading(false);
                    setOpenUpdateDialog(false);
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: 'Field Updated Successfully',
                        type: CustomSnackbarTypes.SUCCESS
                    });
                    getAllFieldList();
                }
            }).catch((err) => {
                setLoading(false);
            })
        }

    }
    React.useEffect(() => {
        getAllFieldList();
    }, [])
    return(
        <Box>
            {loading ?
                (
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
                        <CircularProgress />
                    </Box>
                ) :
                (
                    <Box>
                        <Box mt={3} mb={3} textAlign={'right'}>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                sx={{
                                    width: '10%',
                                }}
                                onClick = {handleCreateFieldClicked}
                            >
                                Create Field
                            </Button>
                        </Box>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Input Type</TableCell>
                                    <TableCell>Values</TableCell>
                                    <TableCell>Label</TableCell>
                                    <TableCell>Active</TableCell>
                                    <TableCell>Created At</TableCell>
                                    <TableCell>Updated At</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fieldListData.map((field: FieldViewResponse, index: number) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{field?.id}</TableCell>
                                            <TableCell>{field?.inputType}</TableCell>
                                            <TableCell>{field?.values}</TableCell>
                                            <TableCell>{field?.label}</TableCell>
                                            <TableCell>{field?.isActive ? "Yes" : "No"}</TableCell>
                                            <TableCell>{moment(field?.createdAt).format('DD MMM YYYY hh:mm A')}</TableCell>
                                            <TableCell>{moment(field?.updatedAt).format('DD MMM YYYY hh:mm A')}</TableCell>
                                            <TableCell>
                                                <Button
                                                    variant={'contained'}
                                                    color={'primary'}
                                                    onClick = {() => {handleUpdateClicked(field)}}
                                                >
                                                    Update
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                )
            }
            {/*Create Dialog Here */}
            {openCreateFieldDialog && (
                <CreateFieldDialog
                    open={openCreateFieldDialog}
                    onClose={() => setOpenCreateFieldDialog(false)}
                    handleSubmitClicked={handleCreateFieldSubmitClicked}
                />
            )}
            {/*Update Dialog Here */}
            {openUpdateDialog && fieldToUpdate && (
                <UpdateFieldDialog
                    field={fieldToUpdate}
                    open={openUpdateDialog}
                    onClose={() => setOpenUpdateDialog(false)}
                    handleSubmitClicked={handleUpdateSubmitClicked}
                />
            )}
        </Box>
    )
}

export default CreateFieldList;
