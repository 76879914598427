import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { TrialResponse } from "../../interfaces/Trials/TrialsResponse";
import { TrialsService } from "../../services/TrialServices";
import { CustomSnackbarTypes } from "./CustomSnackBar";

interface InputProps {
  data: TrialResponse;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const trialsService = new TrialsService();

const SendTrainingContentDialog = (props: InputProps) => {
  const [templateMessage, setTemplateMeassage] = useState<any>();
  const [selectedMessage, setSelectedMessage] = useState<string>("");

  const getTemplateData = (userType: string) => {
    trialsService
      .getAvailableTemplateForTrialCICO(userType)
      .then((data: any) => {
        setTemplateMeassage(data?.data);
      });
  };

  const handleSend = () => {
    let payload = {
      userId: props?.data?.cook?.id,
      userType: "COOK",
      templateName: selectedMessage,
    };
    trialsService.sendWhatsappForTrialCICO(payload).then(() => {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Successfully Sent",
        type: CustomSnackbarTypes.SUCCESS,
      });
      props.setOpen(false);
      setSelectedMessage("");
    });
  };
  const showLabel = (data: string) => {
    const modifyLabel = data.split("__")
    if(modifyLabel.length > 2){
     return modifyLabel[1].split("_").join(" ").toLowerCase();
    }
    return data;
  }

  return (
    <Dialog open={props.open} scroll={"paper"} fullWidth={true} maxWidth="sm">
      <DialogTitle>Send Message</DialogTitle>
      <DialogContent style={{ textAlign: "left" }}>
        <div>
          <p>
            Cook Name:- <b>{props?.data?.cook?.fname} {''} {props?.data?.cook?.lname}</b>
          </p>
          <p>
            Cook Mobile:- <b>{props?.data?.cook?.mobile}</b>
          </p>
          <p>
            CK ID:- <b>{props?.data?.cook?.zohoCookId}</b>
          </p>

          <Divider />
          <p>Choose Training Message</p>
          <FormControl fullWidth style={{ width: "230px" }}>
            <InputLabel id="trialCicoTraining">Trial CICO Training</InputLabel>
            <Select
              labelId="trialCicoTraining"
              id="trialCicoTraining"
              label="Trial CICO Training"
              value={selectedMessage}
              onFocus={() => getTemplateData("COOK")}
              onChange={(event) => {
                setSelectedMessage(event.target.value);
              }}
            >
              {templateMessage?.map((item: { templateName: string }) => {
                return (
                  <MenuItem key={item.templateName} value={item.templateName}>
                    {showLabel(item.templateName)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={"success"}
          onClick={() => {
            handleSend();
          }}
          size={"large"}
        >
          Send
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            props.setOpen(false);
            setSelectedMessage("");
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendTrainingContentDialog;
