import { DateField, NumberField, ReferenceField, Show, Tab, TabbedShowLayout, TextField } from "react-admin";
import {useLocation} from "react-router-dom";
import VasMappingTransactionsTab from "./VasMappingTransactionsTab";

const VasUserMappingShowTabs = () => {
    // const [, setUserBundleMappingId] = useStore<number | null>('vasMappingTabs_userBundleServiceMappingId', null)

    let location = useLocation();
    let vasMappingId = location.pathname.split('/')[2]
    // useEffect(() => setUserBundleMappingId(parseInt(userBundleId)))

    return (
        <TabbedShowLayout>
            <Tab label={"Details"}>
                <TextField source="id" />
                <ReferenceField source="vasId" reference="Vas"><TextField source="name" /></ReferenceField>
                <NumberField source="vas.id" />
                <NumberField source="totalVisits" />
                <DateField options={{day: 'numeric', month: 'short', year: 'numeric' }} source="startDate" />
                <DateField options={{day: 'numeric', month: 'short', year: 'numeric' }} source="endDate" />
                <NumberField source="totalTaken" />
                <DateField options={{day: 'numeric', month: 'short', year: 'numeric' }} source="createdAt" />
                <DateField options={{day: 'numeric', month: 'short', year: 'numeric' }} source="updatedAt" />
            </Tab>

            <Tab label={"Vas Transactions"}>
                <VasMappingTransactionsTab vasMappingId={parseInt(vasMappingId)}/>
                {/*<Resource*/}
                {/*    name="UserServiceTransactionsForBundleMappings"*/}
                {/*    list={UserTransactionList} />*/}
            </Tab>
        </TabbedShowLayout>
    )
}

const VasUserMappingShow = () => (
    <Show>
        <VasUserMappingShowTabs />
    </Show>
);

export default VasUserMappingShow;
