import React, { useEffect, useState } from "react";
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel, Link,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { ReferenceModelNameEnums } from "../../enums/ReferenceModelNameEnums";
import { useRecordContext } from "react-admin";
import ServiceResponse from "../../interfaces/Service/ServiceResponse";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import AreaService from "../../services/AreaService";
import AreaResponse from "../../interfaces/Area/AreaResponse";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";
import { UserGroupsEnum } from "../../enums/UserGroupsEnum";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import Cooks from "../Cooks/Cooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import { DataGrid } from "@mui/x-data-grid";
import { SlcServiceCookMappingEnum } from "../../enums/SlcServiceCookMappingEnum";

interface InputProps {}
const areaService = new AreaService();

const AreaServicesTab = (props: InputProps) => {
  const dispatch = useAppDispatch();

  const userDetails = useAppSelector((state) => state.userDetails.value);
  let groupsArray: string[] = userDetails.adminUser.accessGroups;
  let record: AreaResponse = useRecordContext();
  let [allServices, setAllServices] = useState<any>([]);
  let [selectedService, setSelectedService] = useState<any>(null);
  let [subData, setSubData] = useState<ServiceResponse[]>([]);
  let [isLoading, setIsLoading] = useState<boolean>();
  const [openShow, setOpenShow] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openCookList, setOpenCookList] = useState(false);
  const [openShowThreshold, setOpenShowThreshold] = useState<boolean>(false);
  const [openCreateThesholdDialog, setOpenCreateThresholdDialog] = useState<boolean>(false);
  const [openUpdateThesholdDialog, setOpenUpdateThresholdDialog] = useState<boolean>(false);
  const [dataForCreateThreshold, setDataForCreateThreshold] = useState<{
    serviceId: number,
    referenceId: number,
    referenceModelName: ReferenceModelNameEnums,
  }>();
  const [thresholdData, setThresholdData] = useState<{
    serviceResponse: any,
    data: any[],
  }>({ serviceResponse: {}, data: [] });
  const [selectedList, setSelectedList] = useState<any>(null);
  const [cooks, setCooks] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedModel, setSelectedModel] = useState<any>();
  const [selectedThresholdData, setSelectedThresholdData] = useState<any>();
  const [openMappingTypeDialog, setOpenMappingTypeDialog] = useState<boolean>(false);
  const [mappingType, setMappingType] = useState<SlcServiceCookMappingEnum | undefined>(undefined);
  const refreshData = (id: number) => {
    setIsLoading(true);
    areaService
      .getAllServiceOfferings({
        referenceId: id,
        referenceModelName: ReferenceModelNameEnums.AREA,
      })
      .then((res) => {
        setSubData(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const changeCookServeStatus = (
    serviceId: number,
    isCookServed: boolean
  ) => {
    const payload = {
        referenceId: record?.id,
        referenceModelName: ReferenceModelNameEnums.AREA,
        serviceId,
        isCookServed,
    };
    areaService.changeCookServeForSlc(payload).then(() => {
        refreshData(record?.id);
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Updated Successfully",
            type: CustomSnackbarTypes.SUCCESS,
        });
    });
  };

  const changeServiceStatus = (serviceId: number, isServing: boolean) => {
    const payload = {
      referenceId: record?.id,
      referenceModelName: ReferenceModelNameEnums.AREA,
      serviceId,
      isServing,
    };
    areaService.createServiceForSlc(payload).then(() => {
      refreshData(record?.id);
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Updated Successfully",
        type: CustomSnackbarTypes.SUCCESS,
      });
    });
  };

  const handleSelectCook = () => {
    setOpenCookList(true);
    // setSelectedList(data);
    dispatch(
      setCustomSelectOpen({
        open: true,
      })
    );
  };
  const handleClose = (recordData: any) => {
    setOpenCookList(false);

    if (!mappingType) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Select Slc mapping type",
        type: CustomSnackbarTypes.ERROR,
      });
    }
    if (recordData && mappingType) {
      areaService
        .addCooksToSlc({
          referenceId: selectedList?.referenceId,
          referenceModelName: selectedList?.referenceModelName,
          serviceId: selectedList?.serviceId,
          cookId: recordData?.id,
          mappingType: mappingType,
        })
        .then((res) => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Added Successfully",
            type: CustomSnackbarTypes.SUCCESS,
          });
          setOpenMappingTypeDialog(false);
        });
    }
    else {
      setOpenMappingTypeDialog(false);
    }
  };
  const getCooks = (row: any) => {
    areaService
      .getAllSclCooks({
        serviceId: row.serviceId,
        areaId: row.referenceId,
        filters: {
          limit: 500,
        },
      })
      .then((res: any) => {
        setCooks(res?.data);
        setLoading(false);
      });
  };
  const columns: any = [
  { field: 'referenceId', headerName: 'Reference Id', width: '150' },
  { field: 'isActive', headerName: 'Active', width: '100' },
  { field: 'serviceId', headerName: 'Service Id', width: '100' },
  { field: 'serviceName', headerName: 'Service Name', width: '150' },
  {field: 'slcName', headerName: 'Area Name', width: '150'},
  { field: 'timeSlot', headerName: 'Time Slot', width: '200' },
  { field: 'threshold', headerName: 'Threshold', width: '200' },
  ];
  const getBookingThreshold = (row: any) => {
    const filters = {
      "referenceId": row?.referenceId,
      "referenceModelName": row?.referenceModelName,
      "serviceId": row?.serviceId,
    }
    console.log(filters);
    areaService.getBookingSlotThreshold(JSON.stringify(filters), 100, 0).then((res) => {
      if (res && res?.data && res?.data?.length !== 0) {
        setThresholdData({
          serviceResponse: row,
          data: res?.data
        });
        setLoading(false);
      }
    })
  }
  const handleSelectionModelChange = (newSelection: any) => {
    setSelectedModel([...newSelection]);
    const selectedIndices = newSelection?.map((id: any) => {
      return thresholdData?.data.findIndex((row: any) => row.id === id);
    });

    // Filter out any IDs that couldn't be found
    const validIndices = selectedIndices?.filter((index: number) => index !== -1);

    // Use the valid indices to retrieve the selected rows' data
    const selectedRowsData = validIndices?.map((index: any) => thresholdData?.data[index]);
    setSelectedThresholdData(selectedRowsData);
  }
  const handleUpdateThresholdDialogClose = () => {
    setOpenUpdateThresholdDialog(false);
    setOpenShowThreshold(false);
    setThresholdData({
      serviceResponse: null,
      data: []
    });
    setSelectedModel([]);
    setSelectedThresholdData([]);
  }
  const handleCreateThresholdDialogClose = () => {
    setOpenCreateThresholdDialog(false);
    setOpenShowThreshold(false);
    setThresholdData({
      serviceResponse: null,
      data: []
    });
  }
  useEffect(() => {
    if (!record?.id) return;
    refreshData(record?.id);
  }, [record?.id]);

  useEffect(() => {
    areaService.getAllServices().then((res) => {
      setAllServices(res);
    });
  }, []);

  return (
    <div>
      {openCookList && (
        <ResourceSelectDialog
          name={"Cooks"}
          list={Cooks}
          onClose={handleClose}
        />
      )}
      <Box display={"flex"} justifyContent={"flex-end"} my={3}>
        <Button
          variant={"contained"}
          disabled={
            !GroupPermissionFunction(groupsArray, [
              UserGroupsEnum.ADMIN,
              UserGroupsEnum.MANAGER,
            ])
          }
          onClick={() => {
            setOpen(true);
          }}
        >
          {"Add New Service"}
        </Button>
      </Box>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <div>
          {subData && subData?.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Id</TableCell>
                    <TableCell>SLC Name</TableCell>
                    <TableCell>Service Name</TableCell>
                    <TableCell align={"center"}>Service Status</TableCell>
                    <TableCell align={"center"}>Cook Served Satus</TableCell>
                    <TableCell align={"center"}>Primary Cooks Mapped</TableCell>
                    <TableCell align={"center"}>Secondary Cooks Mapped</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subData?.map((row: ServiceResponse, index) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell scope="row" width={80} align="center">{index + 1}</TableCell>
                      <TableCell>{row?.slcName}</TableCell>
                      <TableCell>{row?.serviceName}</TableCell>
                      <TableCell
                        align={"center"}
                        sx={{
                          backgroundColor: row?.isServing
                            ? "#90EE90"
                            : "#e53935",
                        }}
                      >
                        {row?.isServing ? "YES" : "No"}
                      </TableCell>
                      <TableCell
                        align={"center"}
                        sx={{
                          backgroundColor: row?.isCookServed
                            ? "#90EE90"
                            : "#e53935",
                        }}
                      >
                        {row?.isCookServed ? "YES" : "No"}
                      </TableCell>
                      <TableCell scope="row" width={100} align="center">
                        {row?.noOfPrimaryCookMapped}
                      </TableCell>
                      <TableCell scope="row" width={100} align="center">
                        {row?.noOfSecondaryCookMapped}
                      </TableCell>
                      <TableCell align={"center"}>
                        <Button
                          color={row?.isCookServed ? "error" : "success"}
                          variant={"contained"}
                          disabled={
                            !GroupPermissionFunction(groupsArray, [
                              UserGroupsEnum.ADMIN,
                              UserGroupsEnum.MANAGER,
                            ])
                          }
                          onClick={() => {
                            EventBus.emitEvent(
                              EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG,
                              {
                                title: "Are you sure?",
                                message:
                                  "Please confirm to change the cook service status",
                                successLabel: "Confirm",
                                failureLabel: "Cancel",
                                onClose: (confirmed: boolean) => {
                                  if (confirmed) {
                                    changeCookServeStatus(
                                      row?.serviceId,
                                      !row?.isCookServed
                                    );
                                  }
                                },
                              }
                            );
                          }}
                        >
                          {row?.isCookServed ? "No Cook Serve" : "Cook Serve"}
                        </Button>
                      </TableCell>
                      <TableCell align={"center"}>
                        <Button
                          color={row?.isServing ? "error" : "success"}
                          disabled={
                            !GroupPermissionFunction(groupsArray, [
                              UserGroupsEnum.ADMIN,
                              UserGroupsEnum.MANAGER,
                            ])
                          }
                          variant={"contained"}
                          onClick={() => {
                            EventBus.emitEvent(
                              EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG,
                              {
                                title: "Are you sure?",
                                message:
                                  "Please confirm to change the service status",
                                successLabel: "Confirm",
                                failureLabel: "Cancel",
                                onClose: (confirmed: boolean) => {
                                  if (confirmed) {
                                    changeServiceStatus(
                                      row?.serviceId,
                                      !row?.isServing
                                    );
                                  }
                                },
                              }
                            );
                          }}
                        >
                          {row?.isServing
                            ? "Mark Not Serviceable"
                            : "Mark Serviceable"}
                        </Button>
                      </TableCell>
                      <TableCell align={"center"} width={150}>
                        <Button
                          color={"primary"}
                          variant={"contained"}
                          onClick={() => {
                            setSelectedList(row);
                            setOpenMappingTypeDialog(true);
                            setMappingType(undefined);
                            // handleSelectCook(row);
                          }}
                        >
                          {"Add Cook"}
                        </Button>
                      </TableCell>
                      <TableCell align={"center"} width={180}>
                        <Button
                          color={"primary"}
                          variant={"contained"}
                          onClick={() => {
                            setOpenShow(true);
                            setSelectedList(row);
                            setLoading(true);
                            getCooks(row);
                          }}
                        >
                          {"Show Cooks"}
                        </Button>
                      </TableCell>
                      <TableCell align={"center"} width={200}>
                        <Button
                          color={"primary"}
                          variant={"contained"}
                          onClick={() => {
                            setLoading(true);
                            getBookingThreshold(row);
                            setDataForCreateThreshold({
                              serviceId: row?.serviceId,
                              referenceId: row?.referenceId,
                              referenceModelName: row?.referenceModelName,
                            })
                            setOpenShowThreshold(true);
                          }}
                        >
                          {"Show Threshold"}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <EmptyListPlaceholder message="No Services found" />
          )}
        </div>
      )}
      <Dialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        scroll={"paper"}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Add New Service</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            direction="column"
            justifyContent={"space-evenly"}
            style={{ padding: "12px 12px 12px 12px" }}
          >
            <Grid item>
              <FormControl fullWidth>
                <InputLabel id="for_slot">Select Service</InputLabel>
                <Select
                  labelId="for_slot"
                  id="Select Service"
                  label="Select Service"
                  value={selectedService}
                  onChange={(event) => setSelectedService(event.target.value)}
                >
                  {allServices.map((e: any) => (
                    <MenuItem key={e.name} value={e}>
                      {e.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              if (!selectedService) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Select to proceed",
                  type: CustomSnackbarTypes.WARNING,
                });
                return;
              }
              const payload = {
                referenceId: record?.id,
                referenceModelName: ReferenceModelNameEnums.AREA,
                serviceId: selectedService?.id,
                isServing: true,
              };
              areaService.createServiceForSlc(payload).then(() => {
                setOpen(false);
                refreshData(record?.id);
                setSelectedService(null);
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Added Successfully",
                  type: CustomSnackbarTypes.SUCCESS,
                });
              });
            }}
            size={"large"}
          >
            Add
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              setOpen(false);
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => {
          setOpenShow(false);
        }}
        open={openShow}
        scroll={"paper"}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>All SLC Cooks</DialogTitle>
        <DialogContent>
          <Box>
            <Table size={"small"}>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Name</TableCell>
                  {/*<TableCell>Cook Type</TableCell>*/}
                  <TableCell>Service Name</TableCell>
                  <TableCell>Area Name</TableCell>
                  <TableCell>Mapping Type</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {cooks.length > 0 ? (
                cooks.map((each: any) => {
                  return (
                    <TableBody key={each?.id}>
                      <TableRow>
                        <TableCell>
                          <Link href={`/#/Cooks/${each?.cookFullProfile?.cookId}/show/4`}>
                            {each?.cookFullProfile?.cookId}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {each?.cookFullProfile?.firstName +
                            " " +
                            each?.cookFullProfile?.lastName}
                        </TableCell>
                        {/*<TableCell>*/}
                        {/*  {each?.cookFullProfile?.isMgCook*/}
                        {/*    ? "MG_COOK"*/}
                        {/*    : "PART_TIME"}*/}
                        {/*</TableCell>*/}
                        <TableCell>{each?.serviceName}</TableCell>
                        <TableCell>{each?.slcName}</TableCell>
                        <TableCell>{each?.mappingType}</TableCell>
                        <TableCell>
                          <Button
                            size={"small"}
                            color={"error"}
                            variant={"contained"}
                            onClick={() => {
                              EventBus.emitEvent(
                                EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG,
                                {
                                  title: "Are you sure?",
                                  message: "Please confirm to remove the cook",
                                  successLabel: "Confirm",
                                  failureLabel: "Cancel",
                                  onClose: (confirmed: boolean) => {
                                    if (confirmed) {
                                      areaService
                                        .removeSlcCooks({
                                          referenceId:
                                            selectedList?.referenceId,
                                          referenceModelName:
                                            selectedList?.referenceModelName,
                                          serviceId: selectedList?.serviceId,
                                          cookId: each?.cookId,
                                        })
                                        .then(() => {
                                          EventBus.emitEvent(
                                            EventBusEventsEnum.SNACKBAR_SHOW,
                                            {
                                              message: "Removed Successfully",
                                              type: CustomSnackbarTypes.SUCCESS,
                                            }
                                          );
                                          getCooks(selectedList);
                                        });
                                    }
                                  },
                                }
                              );
                            }}
                          >
                            {"Remove"}
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : loading ? (
                ""
              ) : (
                <caption>No cooks found</caption>
              )}
            </Table>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        sx={{
          '& .MuiPaper-root': {
            height: '100vh'
          }
        }}
        onClose={() => {
          setOpenShowThreshold(false);
          setThresholdData({
            serviceResponse: null,
            data: []
          });
        }}
        open={openShowThreshold}
        scroll={"paper"}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography>Booking Slot Threshold</Typography>
            <Button
              sx={{display: thresholdData?.data?.length > 0 ? 'none' : 'block'}}
              color={'primary'}
              variant={'contained'}
              onClick={() => {
                setOpenCreateThresholdDialog(true);
              }}
            >Create Threshold</Button>
            <Button
              sx={{display: thresholdData?.data?.length > 0 ? 'block' : 'none'}}
              color={'success'}
              variant={'contained'}
              onClick={() => {
                if (!selectedThresholdData ||  selectedThresholdData?.length === 0) {
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Select any data first",
                    type: CustomSnackbarTypes.ERROR,
                  });
                  return;
                }
                setOpenUpdateThresholdDialog(true);
              }}
            >Update Threshold</Button>

          </Box>
        </DialogTitle>
        <DialogContent>
          <DataGrid
            sx={{
              "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                outline: "none",
              },
            }}
            rows={thresholdData?.data}
            columns={columns}
            experimentalFeatures={{ newEditingApi: true }}
            hideFooter={true}
            checkboxSelection
            selectionModel={selectedModel}
            onSelectionModelChange={handleSelectionModelChange}
            />
        </DialogContent>
      </Dialog>
      <Dialog open={openMappingTypeDialog} onClose={() => { setOpenMappingTypeDialog(false) }}>
        <DialogTitle>Step 1: Select Cook Slc Mapping Type</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="mapping-type-label">Mapping Type</InputLabel>
            <Select
              labelId="mapping-type-label"
              id="mapping-type"
              value={mappingType}
              label="Mapping Type"
              onChange={(event: SelectChangeEvent) => {
                setMappingType(event?.target?.value as SlcServiceCookMappingEnum)
                handleSelectCook();
              }}
            >
              {Object.values(SlcServiceCookMappingEnum).map((value) => (
                <MenuItem value={value}>{value}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
      </Dialog>
      <CreateThresholdDialog open={openCreateThesholdDialog} onClose={() => handleCreateThresholdDialogClose()} thresholdData={dataForCreateThreshold} />
      <UpdateThresholdDialog open={openUpdateThesholdDialog} onClose={() => handleUpdateThresholdDialogClose()} thresholdData={selectedThresholdData}/>
    </div>
  );
};

export default AreaServicesTab;

const CreateThresholdDialog = ({ open, onClose, thresholdData }: { open: boolean, onClose: () => void, thresholdData: any }) => {
  const [thresholdValue, setThresholdValue] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(true);
  return (
    <Dialog open={open} fullWidth={true} maxWidth="xs">
      <DialogTitle>Create Threshold Dialog</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
            <TextField
              label="Threshold"
              id="Threshold"
              value={thresholdValue}
              onChange={(event) => setThresholdValue(event?.target?.value)}
            />
            <Typography mt={'15px'}>Threshold Active</Typography>
            <RadioGroup
            name="isActive"
            value={isActive.toString()} // Convert boolean to string
            onChange={(event) => {setIsActive(event?.target?.value === 'true');}}
          >
            <FormControlLabel value="true" control={<Radio />} label="True" />
            <FormControlLabel value="false" control={<Radio />} label="False" />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            onClose();
          }}
          size={"large"}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color={"success"}
          onClick={() => {
            if (!isActive) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Fill the required Fields",
                type: CustomSnackbarTypes.ERROR,
              });
              return;
            }

            const payload = {
              referenceId: thresholdData?.referenceId,
              referenceModelName: thresholdData?.referenceModelName,
              serviceId: thresholdData?.serviceId,
              ...(thresholdValue && thresholdValue !== "" && { threshold: parseInt(thresholdValue) }),
              isActive: isActive,
            }
              areaService.createSlotThreshold(payload).then((res) => {
                if (res) {
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Successfully Created",
                    type: CustomSnackbarTypes.SUCCESS,
                  });
                  onClose();
                }
              })
            }}
          size={"large"}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const UpdateThresholdDialog = ({ open, onClose, thresholdData }: { open: boolean, onClose: () => void, thresholdData: any }) => {
  const [thresholdValue, setThresholdValue] = useState<any>();
  const [isActive, setIsActive] = useState<boolean>(true);
  return (
    <Dialog open={open} fullWidth={true} maxWidth="xs">
      <DialogTitle fontWeight={500}>Update Threshold Dialog</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            label="Threshold"
            id="Threshold"
            value={thresholdValue}
            onChange={(event) => setThresholdValue(event?.target?.value)}
          />
          <Typography mt={'10px'}>Threshold Active</Typography>
          <RadioGroup
            name="isActive"
            value={isActive.toString()}
            onChange={(event) => {setIsActive(event?.target?.value === 'true');}}
          >
            <FormControlLabel value="true" control={<Radio />} label="True" />
            <FormControlLabel value="false" control={<Radio />} label="False" />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            onClose();
          }}
          size={"large"}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color={"success"}
          onClick={() => {
            // if (!thresholdValue) {
            //   EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            //     message: "Fill the required Fields",
            //     type: CustomSnackbarTypes.ERROR,
            //   });
            //   return;
            // }
            if (thresholdData?.length === 0) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Select any data first",
                type: CustomSnackbarTypes.ERROR,
              });
              return;
            }
            if (thresholdData?.length !== 0) {
              // eslint-disable-next-line
              thresholdData?.map((each: any, index: number) => {
                const payload = {
                  referenceId: each?.referenceId,
                  referenceModelName: each?.referenceModelName,
                  serviceId: each?.serviceId,
                  ...(thresholdValue && thresholdValue !== "" && { threshold: parseInt(thresholdValue) }),
                  isActive: isActive,
                  timeSlot: each?.timeSlot,
                }
                  areaService.createSlotThreshold(payload).then((res) => {
                    if (res) {
                      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: "Successfully Updated",
                        type: CustomSnackbarTypes.SUCCESS,
                      });
                      onClose();
                    }
                  })
              })
            }}
          }
          size={"large"}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
