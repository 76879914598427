import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import {Breakpoint} from "@mui/system";

interface CkMuiDialogProps {
  isDialogOpen: boolean;
  setDialogOpen: (isDialogOpen: boolean) => any;
  onDialogClose: () => any;
  title: string;
  children: React.ReactNode;
  primaryLabel?: string;
  primaryAction?: () => any;
  secondaryLabel: string;
  secondaryAction: () => any;
  isLoading: boolean;
  fullWidth?: boolean;
  maxWidth?: Breakpoint;
}

function CkMuiDialog(props: CkMuiDialogProps) {
  return (
    <Dialog fullWidth={props.fullWidth} open={props.isDialogOpen} onClose={props.onDialogClose} maxWidth={props?.maxWidth}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        {props.secondaryLabel && (
          <Button onClick={props.secondaryAction}>
            {props.secondaryLabel}
          </Button>
        )}
        {props.primaryLabel && (
          <Button
            variant="contained"
            onClick={props.primaryAction}
            disabled={props.isLoading}
            startIcon={
              props.isLoading && (
                <CircularProgress size="1rem" color="inherit" />
              )
            }
          >
            {props.primaryLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default CkMuiDialog;
