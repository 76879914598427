import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';
import CreateMiscVendorPayoutDto from '../../dtos/vendorPayout/createMiscVendorPayoutDto';
import { EventBusEventsEnum } from '../../enums/EventBusEventsEnum';
// import { MiscellaneousPayoutTransactionType } from '../../enums/miscellaneousPayoutTransactionType';
import EventBus from '../../EventBus';
import VendorPayoutService from '../../services/VendorPayoutService';
import { CustomSnackbarTypes } from './CustomSnackBar';
import HttpClient from "../../HttpClient";
// import { MiscellaneousReferenceModelNameEnums } from '../../enums/MiscellaneousReferenceModelNameEnum';
import { TrialsService } from '../../services/TrialServices';
import { OneMealService } from '../../services/OneMealService';
import InfoIcon from '@mui/icons-material/Info';
import UserBundleService from '../../services/BundleService';

const trialsService = new TrialsService();
const oneMealService = new OneMealService();
const userBundle = new UserBundleService();

// const Selector = (props: { onSelect: any }) => {

//     return (
//         <FormControl fullWidth style={{ marginTop: "20px" }}>
//             <InputLabel id="for_Type">Select Type</InputLabel>
//             <Select
//                 labelId="for_Type"
//                 id="Type"
//                 label="Select Type"
//                 onChange={(event) => props.onSelect(event.target.value)}
//             >
//                 {Object.values(MiscellaneousPayoutTransactionType).map((e) => (
//                     <MenuItem key={e} value={e}>
//                         {e}
//                     </MenuItem>
//                 ))}
//             </Select>
//         </FormControl>
//     )
// }

const PayoutTypeSelector = (props: { onSelect: any, payoutTypeArray: any[]}) => {
    return (
        <FormControl fullWidth sx={{ mt: "20px" }}>
            <InputLabel id='payout_type'>Select Payout Type</InputLabel>
            <Select
                labelId='payout_type'
                id='payoutType'
                label="Select Payout Type"
                onChange={(event) => props?.onSelect(event.target.value)}
            >
                {props?.payoutTypeArray?.map((e) => (
                    <MenuItem key={e} value={e}>{e}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
const PayoutProductSelector = (props: { onSelect: any, payoutProductArray: any[]}) => {
    return (
        <FormControl fullWidth sx={{ mt: "20px" }}>
            <InputLabel id='payout_product'>Select Payout Product</InputLabel>
            <Select
                labelId='payout_product'
                id='payoutProduct'
                label="Select Payout Product"
                onChange={(event) => props?.onSelect(event.target.value)}
            >
                {props?.payoutProductArray?.map((e) => (
                    <MenuItem key={e} value={e}>{e}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
const PayoutEventSelector = (props: { onSelect: any, payoutEventArray: any[]}) => {
    return (
        <FormControl fullWidth sx={{ mt: "20px" }}>
            <InputLabel id='payout_event'>Select Payout Event</InputLabel>
            <Select
                labelId='payout_event'
                id='payoutEvent'
                label="Select Payout Event"
                onChange={(event) => props?.onSelect(event.target.value)}
            >
                {props?.payoutEventArray?.map((e) => (
                    <MenuItem key={e} value={e}>{e}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
// const ReferenceModelNameSelector = (props: { onSelect: any }) => {

//     return (
//         <FormControl fullWidth style={{ marginTop: "20px" }}>
//             <InputLabel id="for_Reference_Model_Name">Select Refernce Model Name</InputLabel>
//             <Select
//                 labelId="for_Reference_Model_Name"
//                 id="referenceModelName"
//                 label="Select Reference Model Name"
//                 onChange={(event) => props.onSelect(event.target.value)}
//             >
//                 {Object.values(MiscellaneousReferenceModelNameEnums).map((e) => (
//                     <MenuItem key={e} value={e}>
//                         {e}
//                     </MenuItem>
//                 ))}
//             </Select>
//         </FormControl>
//     )
// }
interface InputProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function VendorPayoutDialog(props: InputProps) {
    const record = useRecordContext();
    const vendorPayoutService = new VendorPayoutService();
    const [data, setData] = useState<CreateMiscVendorPayoutDto>({
        cookId: Number(record?.id),
        type: '',
        product: '',
        payoutEvent: '',
        referenceId: '',
        amount: '',
        comments: ''
    })
    const [payoutDataArray, setPayoutDataArray] = useState<any>();
    const [isVerified, setIsVerified] = useState<boolean>(false);
    const [eventReason, setEventReason] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleCancel = () => {
        props.setOpen(false)
        setData({
            ...data,
            type: '',
            product: '',
            payoutEvent: '',
        })
    }

    const handleSuccess = async () => {
        setLoading(true);
        if (!data || !data.payoutEvent || !data.product || !data.type || !data.amount || !data.comments) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Fields cannot be empty",
                type: CustomSnackbarTypes.ERROR
            });
            setLoading(false);
            return;
        }
        setData({...data})
        await vendorPayoutService.createMiscPayout({...data, cookId: Number(record?.id)})
        .then(() => {
            setLoading(false);
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "successful",
                type: CustomSnackbarTypes.SUCCESS
            });
        }).catch(() => {
            setLoading(false);
        })
        props.setOpen(false)
        setData({
            ...data,
            type: '',
            amount: '',
            comments: '',
            payoutEvent: '',
            product: '',
            referenceId: '',
        })
    }

    const getPayoutEvents = async () => {
        if (props?.open) {
            await HttpClient.get(`/vendor-payout/centaurus/payout/events`).then((res) => {
                if (res && res?.data) {
                    setPayoutDataArray(res?.data);
                }
            }).catch((err) => {})
        }
    }
    const infoClickHandler = () => {
        //Navigating to the vendor payout table.
        window.open(`/#/vendorPayoutEventTable`, '_blank');
    }
    useEffect(() => {
        getPayoutEvents();
        // eslint-disable-next-line
    }, [props?.open])

    useEffect(() => {
        setData({ ...data, amount: "" })
        setIsVerified(false);
        if (data?.type && data?.product && data?.payoutEvent) {

            const selectedPayoutData = payoutDataArray?.filter((item: { type: any; product: any, event: any }) =>
            item?.type === data?.type &&
            item?.product === data?.product &&
            item?.event === data?.payoutEvent
            )

            if (selectedPayoutData && selectedPayoutData?.length !== 0) {
                const filteredPayoutData = selectedPayoutData?.filter((item: { forMgCook: boolean }) =>
                    item?.forMgCook === record?.cookFullProfile?.isMgCook
                )
                if (filteredPayoutData && filteredPayoutData?.length !== 0) {
                    //GETTING AMOUNT
                    setData({ ...data, amount: filteredPayoutData[0]?.amount });
                    //GETTING REASONS
                    setEventReason(filteredPayoutData[0]?.reason);
                }
            }
        }
        //eslint-disable-next-line
    }, [data?.type, data?.product, data?.payoutEvent])
    return (
        <div>
            <Dialog
                open={props.open}
            >
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <DialogTitle> {"Misc. Vendor Payout"} </DialogTitle>
                    <Tooltip title="Vendor Payout Event Table">
                        <Box mr={'20px'} sx={{cursor: 'pointer'}} onClick={infoClickHandler}>
                            <InfoIcon />
                        </Box>
                    </Tooltip>
                </Box>

                <DialogContent sx={{width: '30vw'}}>
                    <Stack>
                        <Chip label={`Cook ${record?.id}`} />
                        {/* <Chip label={`TYPE: MISCELLANEOUS`} sx={{mt: '10px', fontWeight: 500}}/> */}
                        {/* <Selector onSelect={(v: MiscellaneousPayoutTransactionType) => setData({ ...data, type: v })} /> */}
                        <PayoutTypeSelector payoutTypeArray={Array.from(new Set(payoutDataArray?.map((item: { type: any; }) => item.type)))}
                            onSelect={(v: any) => {
                                if (v) {
                                    setData({...data, type: v})
                                }
                            }
                        } />
                        {data?.type &&
                            <PayoutProductSelector payoutProductArray={data?.type ? Array.from(new Set(payoutDataArray?.filter((item: { type: any; }) => item.type === data?.type).map((item: { product: any; }) => item.product))) : []}
                                onSelect={(v: any) => {
                                        if (v) {
                                            setData({...data, product: v})
                                        }
                                    }
                                }/>
                        }
                        {data?.type && data?.product &&
                            <PayoutEventSelector payoutEventArray={(data?.type && data?.product) ? Array.from(new Set(payoutDataArray?.filter((item: { type: any; product: string; }) => item?.type === data?.type && item?.product === data?.product).map((item: { event: any; }) => item?.event))) : []}
                                onSelect={(v: any) => {
                                    if (v) {
                                        setData({...data, payoutEvent: v})
                                    }
                                }
                            }/>
                        }
                        {data?.type && data?.product && data?.payoutEvent && eventReason &&
                            <Box mt={'20px'} display={'flex'} gap={'10px'}>
                                <Typography mb={'5px'} fontWeight={'600'} fontFamily={'inherit'}>Reason:</Typography>
                                <Typography textTransform={'uppercase'} fontSize={'14px'} color={'#033f35'} fontFamily={'inherit'} letterSpacing={'0.7px'}>{eventReason}</Typography>
                            </Box>
                        }
                        {/* <ReferenceModelNameSelector onSelect={(v: any) => {
                            if (v) {
                                setIsVerified(false);
                                setData({...data, referenceModelName: v})
                            }
                        }} /> */}
                        {data?.product &&
                            (data?.product === "TRIAL" ||
                                data?.product === "ONE_MEAL_SERVICE" ||
                                data?.product === "SUBSCRIPTION") &&
                            <Box display={'flex'} gap={'5px'} mt={'20px'} alignItems={'center'}>
                                <TextField
                                    type='number'
                                    label="Reference Model Id"
                                    id='referenceId'
                                    value={data?.referenceId}
                                    sx={{flexBasis: '80%'}}
                                    onChange={(event) => {
                                        // handleNumericInput(event?.target?.value)
                                        setIsVerified(false);
                                        setData({ ...data, referenceId: event?.target?.value || ''});
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    sx={{height: '43px'}}
                                    disabled = {isVerified}
                                    onClick={(e)=>{
                                    // if(id===null || module===null || actiontype===null){
                                    //     EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                    //     message: "Fill the details to proceed",
                                    //     type: CustomSnackbarTypes.WARNING
                                    //     });
                                    //     return;
                                    // }
                                        if (data?.product) {
                                            switch (data?.product) {
                                                case "TRIAL":
                                                    trialsService.getTrialsById(Number(data?.referenceId)).then((response) => {
                                                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                                            message: "ID Verified",
                                                            type: CustomSnackbarTypes.SUCCESS,
                                                        });
                                                        setIsVerified(true);
                                                    }).catch((e) => {
                                                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                                            message: "Please Enter the right ID to proceed",
                                                            type: CustomSnackbarTypes.ERROR
                                                        })
                                                    })
                                                break;
                                                case "ONE_MEAL_SERVICE":
                                                    oneMealService.getOneMealService(Number(data?.referenceId)).then((response) => {
                                                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                                            message: "ID Verified",
                                                            type: CustomSnackbarTypes.SUCCESS,
                                                        });
                                                        setIsVerified(true);
                                                    }).catch((e) => {
                                                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                                            message: "Please Enter the right ID to proceed",
                                                            type: CustomSnackbarTypes.ERROR
                                                        })
                                                    })
                                                break;
                                                case "SUBSCRIPTION":
                                                    userBundle.fetchOneUserBundleMapping(Number(data?.referenceId)).then((response) => {
                                                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                                            message: "ID Verified",
                                                            type: CustomSnackbarTypes.SUCCESS,
                                                        });
                                                        setIsVerified(true);
                                                    }).catch((e) => {
                                                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                                            message: "Please Enter the right ID to proceed",
                                                            type: CustomSnackbarTypes.ERROR
                                                        })
                                                    })
                                                break;
                                            }
                                        }
                                        else {
                                            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                                message: "Please Enter the ID to proceed",
                                                type: CustomSnackbarTypes.WARNING
                                            })
                                        }
                                    }}
                                >verify
                                </Button>
                            </Box>
                        }

                        <TextField
                            label="Comments"
                            id="Comments"
                            value={data?.comments}
                            placeholder='This is for internal reference only.'
                            style={{ marginTop: "30px" }}
                            onChange={(event) => setData({...data, comments: event.target.value})}
                        />
                        <TextField
                            label="Amount"
                            id="Amount"
                            value={data?.amount}
                            style={{ marginTop: "30px" }}
                            onChange={(event) => setData({...data, amount: event.target.value})}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCancel}
                        variant="contained"
                        color="error"
                        disabled={loading}
                    >
                        {"Cancel"}
                    </Button>
                    <Button
                        onClick={handleSuccess}
                        variant="contained"
                        color="success"
                        disabled={loading}
                    >
                        {loading ? (
                            <CircularProgress size={24} color={'inherit'} />
                        ) : 'Create'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
