import {Box, Divider, Link} from "@mui/material";
import React from "react";
import {
  DateField,
  FunctionField,
  RaRecord,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";
import CustomReferenceField from "../Common/CustomReferenceField";
import {ProductType} from "../../enums/ProductType";
import { OneMealService } from "../../services/OneMealService";
import { VendorPayoutTypesEnum } from "../../enums/VendorPayoutTypesEnum";
import ImagePreviewer from "../Common/ImagePreviewer";

const omsService = new OneMealService();

const VendorPayoutMetaShow = () => {
  const record = useRecordContext();
  const [conveyanceProofScreenShots, setConveyanceProofScreenShots] = React.useState<any>([]);

  React.useEffect(() => {
    if(!record) return;
    if (record?.type === VendorPayoutTypesEnum.CONVEYANCE && record?.referenceModel?.referenceModelName === ProductType.ONE_MEAL_SERVICE) {
      omsService.getOneMealService(parseInt(record?.referenceModel?.referenceId?.toString()))
      .then((res) => {
        if (res && res?.data) {
          setConveyanceProofScreenShots(res?.data?.conveyanceProofScreenShots)
        }
      }).catch((err) => {})
    }
    // eslint-disable-next-line
  },[record])
  return (
    <Show title="Vendor Payout Details">
      <div style={{ display: "grid", gridTemplateColumns: "30% 40% 30%" }}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="amount" />
          <TextField source="status" label="status" />
          <TextField label="Type" source="type" />
        {record?.referenceModel?.referenceModelName === ProductType.ONE_MEAL_SERVICE &&  <TextField label="OMS ID" />}
          {record?.referenceModel?.referenceModelName === ProductType.ONE_MEAL_SERVICE && (
            <Link href={`/#/OneMealService/${record?.referenceModel?.referenceId}/show`}>
              {record?.referenceModel?.referenceId}
            </Link>
          )}
        {record?.referenceModel?.trialId &&  <TextField label="Trial ID" />}
          {record?.referenceModel?.trialId && (
            <Link href={`/#/Trials/${record?.referenceModel?.trialId}/show`}>
              {record?.referenceModel?.trialId}
            </Link>
          )}
          {record?.referenceModel?.trId &&<TextField label="TR ID" />}
          {record?.referenceModel?.trId && (
            <Link
              href={`/#/TemporaryReplacement/${record?.referenceModel?.trId}/show`}
            >
              {record?.referenceModel?.trId}
            </Link>
          )}
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField label="Type Id" source="typeId" />
          <DateField source="createdAt" label="Created At" showTime />
          <DateField label="Processed At" source="processedAt" showTime />
          <DateField source="approvedAt" label="Approved At" showTime />
          <DateField source="rejectedAt" label="Rejected At" showTime />
          <DateField source="updatedAt" label="Updated At" showTime />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <CustomReferenceField
            label="Cook Id"
            listName={"cooks"}
            source={"cookId"}
          />
          <FunctionField label="Cook Name" render={(record: RaRecord) => (
              <div>{record?.cook?.cookFullProfile?.firstName} {record?.cook?.cookFullProfile?.lastName}</div>
            )} />
          <TextField source="cook.cookFullProfile.fname" label="Cook Name" />
          <TextField source="cook.mobile" label="Cook Mobile" />
          <TextField source="comments" label="comments" />
        </SimpleShowLayout>
      </div>
      <Divider />
      {record?.type === VendorPayoutTypesEnum.CONVEYANCE && conveyanceProofScreenShots?.length &&
        <Box display={'flex'} sx={{margin: '16px', flexWrap: 'wrap' }}>
          {conveyanceProofScreenShots?.map((value: any, index: number) => (
              <ImagePreviewer value={value} key={value} />
          ))}
        </Box>
      }
    </Show>
  );
};

export default VendorPayoutMetaShow;
