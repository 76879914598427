import { Box, Button, Divider, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@mui/material';
import React from 'react';
import HttpClient from "../../HttpClient";
import moment from 'moment';
import { ErrorOutlineOutlined, SystemUpdateAlt } from '@mui/icons-material';

const UpdateCentaurusButton = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [showUpdate, setShowUpdate] = React.useState<boolean>(false);
    const [updateDetails, setUpdateDetail] = React.useState<any>();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const getCentaurusUpdate = async () => {
        await HttpClient.get(`/app-constant/centaurus-last-update`).then((res) => {
            if (res) {
                const data = res?.data;
                if (data && data?.value) {
                    const date = moment(JSON.parse(data?.value)?.date).format('YYYY-MM-DD HH:mm:ss');
                    const updateData = JSON.parse(data?.value);
                    if(updateData) setUpdateDetail(updateData);
                    const hoursDifference = moment().diff(date, 'hours');
                    if (hoursDifference > 24) {
                        setShowUpdate(false);
                    }
                    else setShowUpdate(true);
                }
            }
        }).catch((err)=>{})
    }
    React.useEffect(() => {
        getCentaurusUpdate();
    },[])
    return (
        <Box>
            <Button
                id="update-button"
                onClick={handleClick}
                sx={{
                    backgroundColor: showUpdate ? '#00fee0' : '#e5e5e5',
                    fontWeight: 500,
                    borderRadius: '1vw',
                    width: '130px',
                    height: '30px',
                    color: '#242a32',
                    textTransform: 'none',
                    fontSize: '13px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    px: '15px',
                    mx: '10px',
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: showUpdate ? '#00fee0' : '#d7d7d7',
                    }
                }}
            >
                {showUpdate ? 'New Update' : ''}
                <ErrorOutlineOutlined sx={{ fontSize: '18px' }} />
            </Button>
            <Menu
                id="update-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuList>
                    <MenuItem sx={{pointerEvents: 'none'}}>
                        <Box>
                            <Typography fontFamily={'inherit'} fontWeight={'600'} color={showUpdate ? '#000000' : '#7d7d7d'}>Hard refresh on windows using:</Typography>
                            <List sx={{ listStyle: 'decimal', pl: 4 }}>
                                <ListItem sx={{display: 'list-item'}}>
                                    <Typography fontFamily={'inherit'} fontWeight={'500'} color={showUpdate ? '#000000' : '#7d7d7d'}>Hold the Ctrl key and press the F5 key.</Typography>
                                </ListItem>
                                <ListItem sx={{display: 'list-item'}}>
                                    <Typography fontFamily={'inherit'} fontWeight={'500'} color={showUpdate ? '#000000' : '#7d7d7d'}>Or, hold down Ctrl and ⇧ Shift and then press R.</Typography>
                                </ListItem>
                            </List>
                            <Typography fontFamily={'inherit'} fontWeight={'600'} color={showUpdate ? '#000000' : '#7d7d7d'}>Hard refresh on mac using:</Typography>
                            <List sx={{ listStyle: 'decimal', pl: 4 }}>
                                <ListItem sx={{display: 'list-item'}}>
                                    <Typography fontFamily={'inherit'} fontWeight={'500'} color={showUpdate ? '#000000' : '#7d7d7d'}>Hold down ⌘ Command and ⇧ Shift and then press R.</Typography>
                                </ListItem>
                                <ListItem sx={{display: 'list-item'}}>
                                    <Typography fontFamily={'inherit'} fontWeight={'500'} color={showUpdate ? '#000000' : '#7d7d7d'}>Or, hold down ⇧ Shift and click Reload button.</Typography>
                                </ListItem>
                            </List>
                        </Box>
                    </MenuItem>
                    <MenuItem>
                        <ListItemIcon>
                            <SystemUpdateAlt sx={{color: '#000'}}/>
                        </ListItemIcon>
                        <ListItemText sx={{ ml: '10px', fontWeight: 600 }}>
                            <Typography fontWeight={600}>Centaurus has been updated.</Typography>
                        </ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem sx={{pointerEvents: 'none'}}>
                        <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                            <Typography fontWeight={600}>Version :</Typography>
                            <Typography>{updateDetails?.version}</Typography>
                        </Box>
                    </MenuItem>
                    <Divider />
                    <MenuItem sx={{pointerEvents: 'none'}}>
                        <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                            <Typography fontWeight={600}>Update Date : </Typography>
                            <Typography>{moment(updateDetails?.date).format('DD MMM YYYY')}</Typography>
                        </Box>
                    </MenuItem>
                    <Divider />
                    <MenuItem sx={{pointerEvents: 'none'}}>
                        <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                            <Typography fontWeight={600}>Desciption : </Typography>
                            <Box maxWidth={'240px'}>
                                <Typography sx={{ whiteSpace: 'break-spaces' }}>{updateDetails?.description}</Typography>
                            </Box>
                        </Box>
                    </MenuItem>
                </MenuList>
                {/* <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem> */}
            </Menu>
        </Box>
    );
}

export default UpdateCentaurusButton