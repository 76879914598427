import { Close } from "@mui/icons-material";
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import * as React from "react";
import UserBundleService from "../../services/BundleService";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "./CustomSnackBar";
import { SlotsEnum } from "../../enums/SlotsEnum";
import { useRefresh } from "react-admin";

interface InputProps {
  data: any;
  open: boolean;
  callback: (data: any) => void;
}

export const ResuscitateDialog = (props: InputProps) => {
  let [slot, setSlot] = React.useState<any>()
  let [date, setDate] = React.useState<Date | null>(new Date())
  let refresh = useRefresh()
  const userBundleService = new UserBundleService()

  let handleClose = () => {
    props.callback({
      slot,
      date
    })
    setDate(null);
    setSlot('')
  }
  return (
      <Dialog
        onClose={handleClose}
        open={props.open}
        scroll={"paper"}
        maxWidth="xl"
      >
        <DialogTitle>Resuscitate</DialogTitle>
        <DialogContent>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
          <Grid
            container
            columns={1}
            rowSpacing={0.9}
            style={{ padding: "12px 12px 12px 12px" }}
          >
            <FormControl fullWidth>
              <FormControl fullWidth style={{ marginTop: "20px" }}>
                <DatePicker
                  label="Date"
                  inputFormat="dd MMM yyyy"
                  disableMaskedInput
                  value={date}
                  minDate={new Date()}
                  onChange={(value) => setDate(value)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginTop: "20px" }}>
                <InputLabel id="forSlot">Slot</InputLabel>
                <Select
                  labelId="forSlot"
                  id="Slot"
                  value={slot}
                  label="Slot"
                  onChange={(event) => setSlot(event.target.value)}
                >
                  {Object.values(SlotsEnum).map((e) => (
                    <MenuItem key={e} value={e}>
                      {e}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              if (!slot || !date) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Fill the details to proceed",
                    type: CustomSnackbarTypes.WARNING,
                });
                return;
              }
              userBundleService
                .resuscitateUserBundle(props.data.id, {
                  startSlot: slot,
                  startDate : moment(date).format("YYYY-MM-DD"),
                })
                .then((res) => {
                  refresh();
                  handleClose()
                });
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              handleClose();
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  );
};
