import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    Chip,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextareaAutosize, TextField,
    Typography
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { CookLeavesService } from "../../services/CookLeavesService";
import { Authenticated } from 'react-admin';
import { CookLeaveTypes } from "../../enums/CookLeaveTypes";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { CreateCookLongLeaveEditRequest } from "../../interfaces/CookLeaves/CreateCookLongLeaveEditRequest";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import { Delete } from "@mui/icons-material";
import ChangeLeaveDialog from '../Common/ChangeLeaveDialog';
import { CookSlotTimeEnum, CookSlotTimeEnumArray } from '../../enums/CookSlotTimeEnum';
import EmptyListPlaceholder from '../Common/EmptyListPlaceholder';
import { getReasonCategory } from '../Common/constants/constant';
import { ActionTypeEnum } from '../../enums/ActionTypeEnum';
import ReasonSubreasonModuleEnum from '../../enums/ReasonSubreasonModuleEnum';

const cookLeaveService = new CookLeavesService();

const CookLeavesEdit = () => {
    let location = useLocation();
    let leaveId = location.pathname.split('/')[2];
    let [loading, setLoading] = useState<boolean>(true);
    let [leaveData, setLeaveData] = useState<any>(null);

    let [reasons, setReasons] = useState<any>([]);
    let [leaveReason, setLeaveReason] = useState<any>(null);
    let [otherReasons, setOtherReasons] = useState<string>('');

    let [longLeaveStartDate, setLongLeaveStartDate] = useState<any>(null);
    let [longLeaveEndDate, setLongLeaveEndDate] = useState<any>(null);
    let [longLeaveStartTime, setLongLeaveStartTime] = useState<any>(null);
    let [longLeaveEndTime, setLongLeaveEndTime] = useState<any>(null);
    let [longLeaveData, setLongLeaveData] = useState<any>(null);

    let [shortLeaveDetails, setShortLeaveDetails] = useState<any>(null);
    let [shortLeaveLoading, setShortLeaveLoading] = useState<boolean>(false);


    let [shortLeaveStartDate, setShortLeaveStartDate] = useState<any>();
    let [shortLeaveEndDate, setShortLeaveEndDate] = useState<any>();
    let [shortLeaveStartTime, setShortLeaveStartTime] = useState<any>();
    let [shortLeaveEndTime, setShortLeaveEndTime] = useState<any>();

    const [open, setOpen] = useState(false);
    const [shortLeaveEditDetails, setShortLeaveEditDetails] = useState<any>(null);

    const legends = [
        { color: 'blue', label: 'Possible Leave Slot and Leave Marked' },
        { color: 'green', label: 'Added Slot' },
        { color: 'red', label: 'Deleted Slot' },
        { color: 'grey', label: 'Not a Possible Leave Slot' },
    ];


    const navigate = useNavigate();

   

    const resetState = () => {
        setLongLeaveStartDate(null);
        setLongLeaveEndDate(null);
        setLongLeaveData(null);
        setShortLeaveDetails(null);
        fetchAndSetLeaveData();
        setLeaveReason(null);
        setReasons(null)
    }
    const isDifferenceLessThanOneHour = useCallback((time1: CookSlotTimeEnum, time2: CookSlotTimeEnum): boolean => {
        const parseTimeString = (timeString: string): Date => {
            const [time, modifier] = timeString.split(' ');
            let [hours, minutes] = time.split(':').map(Number);

            if (modifier === 'PM' && hours < 12) {
                hours += 12;
            }
            if (modifier === 'AM' && hours === 12) {
                hours = 0;
            }

            const date = new Date();
            date.setHours(hours, minutes, 0, 0);
            return date;
        };

        const date1 = parseTimeString(time1);
        const date2 = parseTimeString(time2);

        const diffInMilliseconds = date2.getTime() - date1.getTime();
        const diffInMinutes = diffInMilliseconds / (1000 * 60);

        return (diffInMinutes < 60 || diffInMinutes < 0);
    }, []);

    const fetchAndSetShortLeaveSlots = useCallback(() => {
        setShortLeaveLoading(true);
        if (!leaveData) return;
        if (isDifferenceLessThanOneHour(shortLeaveStartTime, shortLeaveEndTime)) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: 'Cannot mark leave for this time slot',
                type: CustomSnackbarTypes.ERROR
            });
            setShortLeaveLoading(false)
            setShortLeaveStartTime(leaveData?.leaveStartTime);
            setShortLeaveEndTime(leaveData?.leaveEndTime);
            setShortLeaveDetails([]);
            return;
        }
        cookLeaveService.getShortLeaveSlotDetails(leaveData?.cookId, {
            startDate: shortLeaveStartDate,
            endDate: shortLeaveEndDate,
            startTime: shortLeaveStartTime,
            endTime: shortLeaveEndTime,
        }).then((res) => {
            setShortLeaveLoading(false);
            setShortLeaveDetails(res);
        });
    }, [shortLeaveStartTime, shortLeaveEndTime, shortLeaveStartDate, shortLeaveEndDate, leaveData, isDifferenceLessThanOneHour]);

    const handleShortLeaveSelect = async (value: any, key: boolean) => {
        key ? setShortLeaveStartDate(value) : setShortLeaveEndDate(value);
    }

    const fetchAndSetLeaveData = useCallback(() => {
        cookLeaveService.getCookLeaveDetailsById(parseInt(leaveId)).then((res) => {
            setLoading(false);
            setLeaveData(res.data);
        }).catch(() => {
            navigate("/CookLeaves");
        });
    }, [leaveId, navigate]);

    useEffect(() => {
        fetchAndSetLeaveData();
    }, [fetchAndSetLeaveData]);

    useEffect(() => {
        if (shortLeaveStartTime && shortLeaveEndTime) {
            fetchAndSetShortLeaveSlots();
        }
    }, [shortLeaveStartTime, shortLeaveEndTime, leaveId, fetchAndSetShortLeaveSlots]);

    useEffect(()=>{
        getReasonCategory(ReasonSubreasonModuleEnum.CX_LEAVES,ActionTypeEnum.CREATE_COOK_LEAVE).then((data)=>{
            setReasons(data);
        }).catch((err)=>{
            console.log(err);
        })
    },[])

    useEffect(()=>{
        if(leaveData&&leaveData?.leaveType === CookLeaveTypes.SHORT_LEAVE){
            setShortLeaveStartDate(leaveData?.leaveStartDate);
            setShortLeaveEndDate(leaveData?.leaveEndDate);
            setShortLeaveStartTime(leaveData?.startTime);
            setShortLeaveEndTime(leaveData?.endTime);
        }
        else{
            setLongLeaveStartDate(leaveData?.leaveStartDate);
            setLongLeaveEndDate(leaveData?.leaveEndDate);
            setLongLeaveStartTime(leaveData?.startTime);
            setLongLeaveEndTime(leaveData?.endTime);
        }
    },[leaveData])

    const getLongLeaveData = useCallback(async () => {
        if (!longLeaveStartDate || !longLeaveEndDate || !leaveData || !longLeaveStartTime || !longLeaveEndTime) {
            return;
        }
        let leaveStartDate = moment(longLeaveStartDate).format('YYYY-MM-DD');
        let leaveEndDate = moment(longLeaveEndDate).format('YYYY-MM-DD');
        if (leaveStartDate === leaveEndDate && isDifferenceLessThanOneHour(longLeaveStartTime, longLeaveEndTime)) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: 'Cannot mark leave for less than an hour',
                type: CustomSnackbarTypes.ERROR
            });
            setLongLeaveStartTime("");
            setLongLeaveEndTime("");
            setLongLeaveData(null);
            return;
        }
        let data = await cookLeaveService.getLongLeaveSlotDetails(leaveData.cookId, leaveStartDate, leaveEndDate, longLeaveStartTime, longLeaveEndTime);
        if (!data) return;
        setLongLeaveData(data);
    }, [longLeaveStartDate, longLeaveEndDate, longLeaveStartTime, longLeaveEndTime, leaveData, isDifferenceLessThanOneHour]);

    useEffect(() => {
        getLongLeaveData();
    }, [longLeaveStartDate, longLeaveEndDate, getLongLeaveData]);

    const handleShortLeaveProceed = () => {
        if (!shortLeaveStartDate || !shortLeaveEndDate || !shortLeaveStartTime || !shortLeaveEndTime || !leaveReason || (leaveReason==='Other'&&!otherReasons)) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: 'Please select Start and End  Date and Time or Reason',
                type: CustomSnackbarTypes.ERROR
            });
            return;
        }
        let leaveStartDate = moment(shortLeaveStartDate).format('YYYY-MM-DD');
        let leaveEndDate = moment(shortLeaveEndDate).format('YYYY-MM-DD');
        let newData = [];
        for (let index = 0; index < shortLeaveDetails?.length; index++) {
            let houseDetails: { houseId: number, deletedSlots: any[], addedSlots: any[] } = {
                houseId: shortLeaveDetails[index].houseId,
                deletedSlots: shortLeaveDetails[index]?.slots?.filter((slot: any) => !slot?.possibleLeaveSlot && slot?.isAlreadyMarked)?.map((slot: any) => slot?.slotName),
                addedSlots: shortLeaveDetails[index].slots.filter((slot: any) => slot?.possibleLeaveSlot && !slot?.isAlreadyMarked)?.map((slot: any) => slot?.slotName),
            };
            newData.push(houseDetails);
        }

        let payload: { startTime: CookSlotTimeEnum, endTime: CookSlotTimeEnum, startDate: string, endDate: string, leaveDetails: any[],reason:string } = { startTime: shortLeaveStartTime, endTime: shortLeaveEndTime, startDate: leaveStartDate, endDate: leaveEndDate, leaveDetails: newData,reason:leaveReason==="Other"?otherReasons:leaveReason};

        if (payload.leaveDetails.length === 0) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: 'No changes to edit',
                type: CustomSnackbarTypes.WARNING
            });
            return;
        }
        setShortLeaveEditDetails({ payload, leaveId: leaveId, cookId: leaveData?.cookId });
        setOpen(true);
    };

    const handleLongLeaveSelect = async (value: any, key: boolean) => {
        key ? setLongLeaveStartDate(value) : setLongLeaveEndDate(value);
    };

    const handleLongLeaveProceed = () => {
        if (!longLeaveStartDate || !longLeaveEndDate || !longLeaveStartTime || !longLeaveEndTime||!leaveReason||(leaveReason==='Other'&&!otherReasons)) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: 'Please select Start and End Date and Time and reason',
                type: CustomSnackbarTypes.ERROR
            });
            return;
        }
        let leaveStartDate = moment(longLeaveStartDate).format('YYYY-MM-DD');
        let leaveEndDate = moment(longLeaveEndDate).format('YYYY-MM-DD');
        let payload: CreateCookLongLeaveEditRequest = {
            requestedBy: leaveData.requestedBy,
            reason:leaveReason,
            startDate: leaveStartDate,
            endDate: leaveEndDate,
            startTime: longLeaveStartTime,
            endTime: longLeaveEndTime
        };
        EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
            message: "Are you sure you want to edit this leave?",
            onClose: (confirmed: boolean) => {
                if (confirmed) {
                    cookLeaveService.editLongLeave(parseInt(leaveId), payload).then(() => {
                        resetState();
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                            message: 'Leave Updated',
                            type: CustomSnackbarTypes.SUCCESS
                        });
                        navigate("/CookLeaves")
                    });
                }
            },
            title: "Confirmation"
        });
    };

    const handleCancelLeave = () => {
        EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
            message: "Are you sure you want to cancel this leave?",
            onClose: (confirmed: boolean) => {
                if (confirmed) {
                    cookLeaveService.cancelLeave(parseInt(leaveId)).then(() => {
                        navigate("/CookLeaves");
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                            message: 'Leave Cancelled',
                            type: CustomSnackbarTypes.SUCCESS
                        });
                    });
                }
            },
            title: "Confirmation"
        });
    };


    return (
        <Authenticated>
            <ChangeLeaveDialog data={shortLeaveEditDetails} open={open} setOpen={setOpen} />
            <Card
                style={{
                    padding: '12px',
                    height: '100%',
                    overflow: 'auto'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '12px',
                        alignItems: 'center'
                    }}
                >
                    <h3
                        style={{
                            margin: '0px'
                        }}
                    >
                        {`Edit ${leaveData?.leaveType === 'LONG_LEAVE' ? 'Long' : 'Short'} Leave`}
                    </h3>
                    <Button
                        variant={'contained'}
                        color={'error'}
                        size={"small"}
                        endIcon={<Delete />}
                        onClick={handleCancelLeave}
                    >
                        Cancel Leave
                    </Button>
                </div>
                {
                    (loading || shortLeaveLoading) &&
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress />
                    </div>
                }
                {
                    !loading &&
                    !!leaveData &&
                    leaveData?.leaveType === CookLeaveTypes.LONG_LEAVE &&
                    <div>
                        <div>
                            <h4>Please Select new date time</h4>
                            <div style={{ display: 'flex', gap: "1rem", width: "40%" }}  >
                                <DatePicker
                                    label="Select Leave Date"
                                    inputFormat="dd MMM yyyy"
                                    disableMaskedInput
                                    value={longLeaveStartDate}
                                    minDate={moment().subtract(7, "d").toDate()}
                                    onChange={(value: any) => handleLongLeaveSelect(value, true)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <DatePicker
                                    label="Select Leave Date"
                                    inputFormat="dd MMM yyyy"
                                    disableMaskedInput
                                    value={longLeaveEndDate}
                                    minDate={moment().subtract(7, "d").toDate()}
                                    onChange={(value: any) => handleLongLeaveSelect(value, false)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </div>
                            {leaveData?.leaveType === CookLeaveTypes.LONG_LEAVE
                                && longLeaveStartDate && longLeaveEndDate
                                && <div style={{ display: 'flex', gap: "1rem", width: "40%" }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Start Time</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={longLeaveStartTime}
                                            label="Start Time"
                                            onChange={(e) => setLongLeaveStartTime(e.target.value)}
                                        >
                                            {
                                                CookSlotTimeEnumArray.map((time) => (
                                                    <MenuItem key={time} value={time}>{time}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">End Time</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={longLeaveEndTime}
                                            label="End Time"
                                            onChange={(e) => setLongLeaveEndTime(e.target.value)}
                                        >
                                            {
                                                CookSlotTimeEnumArray.map((time) => (
                                                    <MenuItem key={time} value={time}>{time}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            }
                            <div style={{ marginTop: "15px" }}>
                                <Box sx={{maxWidth:"30%"}}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Leave Reason</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={leaveReason}
                                            label="Leave Reason"
                                            onChange={(e) => setLeaveReason(e.target.value)}
                                        >
                                            {
                                                reasons&&reasons.map((reason:any)=>(
                                                    <MenuItem key={reason?.category} value={reason?.category}>{reason?.category}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl> 
                                </Box>      
                                    {
                                        leaveReason === "Other" &&
                                        <div style={{ marginTop: "10px" }}>
                                            <InputLabel sx={{ color: "black" }} id="Notes">Other Reason</InputLabel>
                                            <TextareaAutosize
                                                minRows={4}
                                                placeholder="Enter Reason"
                                                onChange={e => setOtherReasons(e.target.value)}
                                                style={{ width: 300, paddingLeft: "10px", resize: "none" }}
                                            />
                                        </div>
                                    }
                                </div>

                        </div>
                        {
                            !!longLeaveData &&
                            Array.isArray(longLeaveData) &&
                            <div>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" width="40%">House Address</TableCell>
                                                <TableCell align="center" width="60%">Days</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                longLeaveData ? longLeaveData.map((house: any) => {
                                                    return (
                                                        <TableRow
                                                            key={house.houseId}
                                                        >
                                                            <TableCell align="center">{house?.address}</TableCell>
                                                            <TableCell align="center"> {
                                                                !!house?.slots &&
                                                                Array.isArray(house?.slots) &&
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'center'
                                                                    }}
                                                                >
                                                                    {
                                                                        house?.slots.map((ele: any) => {
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        ele?.possibleLeaveSlot ?
                                                                                            <Chip
                                                                                                key={ele.id}
                                                                                                sx={{
                                                                                                    padding: '6px 12px',
                                                                                                    border: '1px solid #0000002b',
                                                                                                    marginRight: '12px',
                                                                                                    borderRadius: '20px',
                                                                                                    cursor: 'pointer',
                                                                                                }}
                                                                                                color='success'
                                                                                                label={ele.time}
                                                                                            /> :
                                                                                            <Chip
                                                                                                key={ele.id}
                                                                                                sx={{
                                                                                                    padding: '6px 12px',
                                                                                                    border: '1px solid #0000002b',
                                                                                                    marginRight: '12px',
                                                                                                    borderRadius: '20px',
                                                                                                    cursor: 'not-allowed',
                                                                                                    backgroundColor: 'white'
                                                                                                }}
                                                                                                label={ele.time}
                                                                                            />
                                                                                    }
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }</TableCell>
                                                        </TableRow>
                                                    )
                                                }) :
                                                    <EmptyListPlaceholder message='No House to Show' />
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        }
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: '12px'
                            }}
                        >
                            {
                                !!longLeaveData &&
                                Array.isArray(longLeaveData) &&
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={() => handleLongLeaveProceed()}
                                >
                                    Proceed
                                </Button>
                            }
                        </div>
                    </div>
                }
                {
                    !loading &&
                    !shortLeaveLoading &&
                    !!leaveData &&
                    leaveData?.leaveType === CookLeaveTypes.SHORT_LEAVE &&
                    <div>
                        <div style={{ display: 'flex', gap: "1rem", width: "40%" }}  >
                            <DatePicker
                                label="Select Leave Date"
                                inputFormat="yyyy-MM-dd"
                                disableMaskedInput
                                value={shortLeaveStartDate}
                                minDate={moment().subtract(7, "d").toDate()}
                                onChange={(value: any) => handleShortLeaveSelect(value, true)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DatePicker
                                label="Select Leave Date"
                                inputFormat="yyyy-MM-dd"
                                disableMaskedInput
                                value={shortLeaveEndDate}
                                minDate={moment().subtract(7, "d").toDate()}
                                onChange={(value: any) => handleShortLeaveSelect(value, false)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </div>
                        <div style={{ display: 'flex', gap: "1rem", width: "40%" }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Start Time</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={shortLeaveStartTime}
                                    label="Start Time"
                                    onChange={(e) => setShortLeaveStartTime(e.target.value as CookSlotTimeEnum)}
                                >
                                    {
                                        CookSlotTimeEnumArray.map((time) => (
                                            <MenuItem key={time} value={time}>{time}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">End Time</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={shortLeaveEndTime}
                                    label="End Time"
                                    onChange={(e) => setShortLeaveEndTime(e.target.value as CookSlotTimeEnum)}
                                >
                                    {
                                        CookSlotTimeEnumArray.map((time) => (
                                            <MenuItem key={time} value={time}>{time}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                        <Box sx={{maxWidth:"30%"}}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Leave Reason</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={leaveReason}
                                            label="Leave Reason"
                                            onChange={(e) => setLeaveReason(e.target.value)}
                                        >
                                            {
                                                reasons&&reasons.map((reason:any)=>(
                                                    <MenuItem key={reason?.category} value={reason?.category}>{reason?.category}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl> 
                                </Box>      
                                    {
                                        leaveReason === "Other" &&
                                        <div style={{ marginTop: "10px" }}>
                                            <InputLabel sx={{ color: "black" }} id="Notes">Other Reason</InputLabel>
                                            <TextareaAutosize
                                                minRows={4}
                                                placeholder="Enter Reason"
                                                onChange={e => setLeaveReason(e.target.value)}
                                                style={{ width: 300, paddingLeft: "10px", resize: "none" }}
                                            />
                                        </div>
                                    }
                                </div>
                        {
                            !!shortLeaveDetails &&
                            Array.isArray(shortLeaveDetails) &&
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" width="40%">Customer Address</TableCell>
                                            <TableCell align="center" width="60%">Time Slots</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            shortLeaveDetails && Array.isArray(shortLeaveDetails) && shortLeaveDetails.length > 0 ?
                                                shortLeaveDetails.map((slot: any) => {
                                                    if (slot.slots.length === 0) {
                                                        return null;
                                                    }
                                                    return (
                                                        <TableRow
                                                            key={slot.houseId}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="center">{slot.address}</TableCell>
                                                            <TableCell align="center">
                                                                {
                                                                    !!slot?.slots &&
                                                                    Array.isArray(slot?.slots) &&
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'center'
                                                                        }}
                                                                    >
                                                                        {
                                                                            slot.slots.map((ele: any) => {
                                                                                return (
                                                                                    <>
                                                                                        {
                                                                                            !ele?.possibleLeaveSlot && !ele?.isAlreadyMarked &&
                                                                                            <Chip
                                                                                                key={ele.id}
                                                                                                sx={{
                                                                                                    padding: '6px 12px',
                                                                                                    border: '1px solid #0000002b',
                                                                                                    marginRight: '12px',
                                                                                                    borderRadius: '20px',
                                                                                                    cursor: 'pointer',
                                                                                                }}
                                                                                                label={ele?.time}
                                                                                            />
                                                                                        }
                                                                                        {
                                                                                            ele?.possibleLeaveSlot && !ele?.isAlreadyMarked &&
                                                                                            <Chip
                                                                                                key={ele.id}
                                                                                                sx={{
                                                                                                    padding: '6px 12px',
                                                                                                    border: '1px solid #0000002b',
                                                                                                    marginRight: '12px',
                                                                                                    borderRadius: '20px',
                                                                                                    cursor: 'pointer',
                                                                                                }}
                                                                                                label={ele?.time}
                                                                                                color='success'
                                                                                            />
                                                                                        }
                                                                                        {
                                                                                            !ele?.possibleLeaveSlot && ele?.isAlreadyMarked &&
                                                                                            <Chip
                                                                                                key={ele.id}
                                                                                                sx={{
                                                                                                    padding: '6px 12px',
                                                                                                    border: '1px solid #0000002b',
                                                                                                    marginRight: '12px',
                                                                                                    borderRadius: '20px',
                                                                                                    cursor: 'pointer',
                                                                                                }}
                                                                                                label={ele?.time}
                                                                                                color='error'
                                                                                            />
                                                                                        }
                                                                                        {
                                                                                            ele?.possibleLeaveSlot && ele?.isAlreadyMarked &&
                                                                                            <Chip
                                                                                                key={ele.id}
                                                                                                sx={{
                                                                                                    padding: '6px 12px',
                                                                                                    border: '1px solid #0000002b',
                                                                                                    marginRight: '12px',
                                                                                                    borderRadius: '20px',
                                                                                                    cursor: 'pointer',
                                                                                                }}
                                                                                                label={ele?.time}
                                                                                                color='primary'
                                                                                            />
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                                :
                                                <EmptyListPlaceholder message='No slot to Show' />
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: '12px'
                            }}
                        >
                            {
                                !!shortLeaveDetails &&
                                Array.isArray(shortLeaveDetails) &&
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={() => handleShortLeaveProceed()}
                                >
                                    Proceed
                                </Button>
                            }
                        </div>

                        <Box display={'flex'} flexDirection={'row'}>
                            <Box m={2} display={'flex'} flexDirection={'column'}>
                                {[legends[0], legends[1]]?.map((eachLegend) => (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }} id={eachLegend?.label}>
                                        <Box sx={{ width: 20, height: 20, borderRadius: 1, backgroundColor: eachLegend.color + ' !important' }} m={0.5} mr={1} />
                                        <Typography fontSize={12}>
                                            {eachLegend?.label}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                            <Box m={2} display={'flex'} flexDirection={'column'}>
                                {[legends[2], legends[3]]?.map((eachLegend) => (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }} id={eachLegend?.label}>
                                        <Box sx={{ width: 20, height: 20, borderRadius: 1, backgroundColor: eachLegend.color + ' !important' }} m={0.5} mr={1} />
                                        <Typography fontSize={12}>
                                            {eachLegend?.label}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </div>
                }
            </Card>
        </Authenticated>
    );
};

export default CookLeavesEdit;
