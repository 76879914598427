import { Create, minValue, number, SimpleForm, TextInput } from "react-admin";

const validateNumber = [number("Must be a number"), minValue(0)]

const BundleCreate = (props: any) => {
    const transform = (data: any) => {
        let newData = {
            name: data.name,
            paidDays: Number(data.paidDays),
            freeDays: Number(data.freeDays),
            graceDays: Number(data.graceDays),
        }
        return newData
    }
    return (
        <Create {...props} transform={transform}>
            <SimpleForm>
                <TextInput source='name' />
                <TextInput source='paidDays' validate={validateNumber} />
                <TextInput source='freeDays' validate={validateNumber} />
                <TextInput source='graceDays' validate={validateNumber} />
            </SimpleForm>
        </Create>
    )
}

export default BundleCreate;