import {Button} from '@mui/material';
import {Show, Tab, TabbedShowLayout, useRefresh, useShowContext} from 'react-admin';
// import AreaMetaShow from "./AreaMetaShow";
// import AreaServicesTab from "./AreaServicesTab";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
// import {useAppSelector} from "../../store/hooks";
import React, {useState} from "react";
import ComboMetaShow from "./ComboMetaShow";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";
import ComboService from "../../services/ComboService";
import {PaymentLinkDailog} from "../Common/PaymentLinksDailogs";
import {useNavigate} from "react-router-dom";
import ComboRefundDialog from "../Common/ComboRefundDialog";

const comboService = new ComboService();

const CTAButtons = (props: any) => {
    const navigate = useNavigate();
    // const userDetails = useAppSelector((state) => state.userDetails.value);
    // let groupsArray: string[] = userDetails.adminUser.accessGroups;
    const { record } = useShowContext();
    // const refresh = useRefresh();


    const onClickSendPaymentLink = () => {
        EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
            message: "Are you sure you want to send the payment link?",
            onClose: (confirmed: boolean) => {
                if (confirmed) {
                    comboService.sendPaymentLink(parseInt((record as any).id)).then((res) => {
                        props.setPaymentDialog(true)
                        props.setPaymentLinks(res)
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                            message: 'Payment link sent',
                            type: CustomSnackbarTypes.SUCCESS
                        })
                    })
                }
            },
            title: "Confirmation"
        })
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 10,
                margin: 6,
            }}
        >
            <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                    props.setSelectedData(record);
                    props.setRefundModel(true);
                }}
            >
                {'Refund Combo'}
            </Button>
            <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                    if(record?.paymentStatus !== 'PAID') {
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                            message: "Order is not PAID yet",
                            type: CustomSnackbarTypes.ERROR,
                        });
                    } else {
                        navigate(`/CouponsV2/${record?.orderDetailsData?.[record?.orderDetailsData?.length - 1]?.discountCouponId}/edit`)
                    }
                }}
            >
                {'Edit Coupon'}
            </Button>
            <Button
                variant={"contained"}
                color={"primary"}
                onClick={onClickSendPaymentLink}
            >
                {'Send Payment link'}
            </Button>
        </div>
    )
}

const AreaTab = () => {
    return(
        <TabbedShowLayout syncWithLocation={false}>
            <Tab label={"Details"} key={"Details"}>
                <ComboMetaShow />
            </Tab>
        </TabbedShowLayout>
    )
};

function ComboShow(props: any) {
    const refresh = useRefresh();
    const [selectedData, setSelectedData] = useState<any>(null);
    const [refundModel, setRefundModel] = useState<boolean>(false);


    let [paymentDialog, setPaymentDialog] = useState<boolean>(false)
    let [paymentLinks, setPaymentLinks] = useState();

    return (
        <>
            {paymentDialog && (
                <PaymentLinkDailog
                    data={paymentLinks}
                    open={paymentDialog}
                    callback={(data) => {
                        setPaymentDialog(false);
                    }}
                />
            )}
            {refundModel && <ComboRefundDialog
                data={selectedData}
                open={refundModel}
                callback={() => {
                    setRefundModel(false);
                    refresh();
                }}
            />}
            <Show actions={false}>
                <CTAButtons
                    setSelectedData={setSelectedData}
                    setPaymentDialog={setPaymentDialog}
                    setPaymentLinks={setPaymentLinks}
                    setRefundModel={setRefundModel}
                />
                <AreaTab />
            </Show>
        </>
    )
}

export default ComboShow;
