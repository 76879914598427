export enum TrialsV3Status {
    PENDING = 'PENDING',
    COOK_ALLOCATED = 'COOK_ALLOCATED',
    COOK_CONFIRMED = 'COOK_CONFIRMED',
    ONGOING = 'ONGOING',
    CANCELLED = 'CANCELLED',
    FULFILLED = 'FULFILLED',
    RESCHEDULED = 'RESCHEDULED',
    CONVERTED = 'CONVERTED',
    NOT_CONVERTED = 'NOT_CONVERTED'
}