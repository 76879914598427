import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import {  useRefresh } from "react-admin";
import {useEffect, useState} from "react";
import { TrialsService } from "../../services/TrialServices";
import EventBus from "../../EventBus";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { TrialResponse } from "../../interfaces/Trials/TrialsResponse";
import {BundleGenderPreference} from "../../enums/common";
interface Props {
  data: TrialResponse;
  open: boolean;
  callback: () => void;

}
const trialService = new TrialsService();

const TrialGenderChangeDialog = (props: Props) => {
  const [gender, setGender] = useState<any>(null);
  const refresh = useRefresh();


  let handleClose = () => {
    setGender(null);
    props.callback();
  };

  const handleSubmit = async () => {
    if (gender === null) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Please select gender",
        type: CustomSnackbarTypes.WARNING,
      });
      return;
    }
    if(props?.data?.id) {
      await trialService.changeGenderPreference(Number(props?.data?.id), {
        genderPreference: gender,
      }).then(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Changed Successfully",
          type: CustomSnackbarTypes.SUCCESS,
        });
        refresh();
        handleClose();
      });
    }
  };

  useEffect(() => {
    if(props?.data?.genderPreference) {
      setGender(props?.data?.genderPreference);
    }
  }, [props?.data?.genderPreference])

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      scroll={"paper"}
      maxWidth="xs"
    >
      <DialogTitle>Change Gender for Trials</DialogTitle>
      <DialogContent sx={{ width: 300 }}>
        <TextField
            select
            label="Select Gender"
            value={gender}
            fullWidth
            autoFocus={true}
            onChange={(event: any) => {
              setGender(event.target.value);
            }}
        >
          {Object.values(BundleGenderPreference).map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSubmit} size={"large"}>
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            handleClose();
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default TrialGenderChangeDialog;
