import { DateInput, Edit, SimpleForm, TextInput } from "react-admin";
import { ShortTimeEnum } from "../../enums/ShortTimeEnum";
import CustomSelectInput from "../Common/CustomSelectInput";

const UserTransactionEdit = () => {
    return (
        <Edit>
            <SimpleForm>
                <DateInput source="date" />
                <CustomSelectInput emptyText="Select Time Slot" source="time" data={ShortTimeEnum} />
                <TextInput source="commentFromAdmin" />
            </SimpleForm>
        </Edit>
    )
}

export default UserTransactionEdit;