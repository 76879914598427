import React, { useEffect, useState } from "react";
import { TrialsService } from "../../services/TrialServices";
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import { useRecordContext } from "react-admin";
import TrialAutoAllocationMetaShow from "./TrialAutoAllocationMetaShow";

const trialsService = new TrialsService();

const TrialAutoAllocationTab = (props: any) => {
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  let [subData, setSubData] = useState<any>(null);
  const [sort, setSort] = useState({});
  let [isLoading, setIsLoading] = useState<boolean>();
  const record = useRecordContext();

  const refreshData = (
    trialId: number,
    limit: number,
    offset: number,
    sort: any
  ) => {
    setIsLoading(true);
    trialsService
      .getAllAutoAllocatedCooksForTrial(trialId, limit, offset, sort)
      .then((data) => {
        setSubData(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    refreshData(props.trialId, rowsPerPage, page * rowsPerPage, sort);
  }, [props.trialId, rowsPerPage, page, sort]);

  return (
    <div>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <div>
          {subData &&
            subData?.data &&
            Array.isArray(subData?.data) &&
            (subData?.data?.length > 0 ? (
              <TrialAutoAllocationMetaShow
                data={subData?.data}
                trail={record}
                setSort={setSort}
                sort={sort}
                refresh={() => {
                  refreshData(props.trialId, rowsPerPage, page * rowsPerPage, sort);
                }}
              />
            ) : (
              <EmptyListPlaceholder message="No Trials Allocated Found" />
            ))}
        </div>
      )}
    </div>
  );
};

export default TrialAutoAllocationTab;
