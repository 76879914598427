import { Close } from "@mui/icons-material";
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, TextField
} from "@mui/material";
import  React from "react";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "./CustomSnackBar";
import { useRefresh } from "react-admin";
import StarChefVisitService from "../../services/StarChefVisitService";
import { useState } from "react";

interface InputProps {
  data: any;
  open: boolean;
  callback: () => void;
}

export const CancelStarChefVisit = (props: InputProps) => {
  const [cancellationReason, setCancellationReason] = useState<string>('')
  let refresh = useRefresh()
  const starChefVisitService = new StarChefVisitService()

  let handleClose = () => {
    props.callback()
    setCancellationReason('')
  }
  return (
      <Dialog
        onClose={handleClose}
        open={props.open}
        scroll={"paper"}
        maxWidth="xl"
      >
        <DialogTitle>Cancel</DialogTitle>
        <DialogContent>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
          <Grid
            container
            columns={1}
            rowSpacing={0.9}
            style={{ padding: "12px 12px 12px 12px" }}
          >
            <FormControl fullWidth>
            <TextField
                id="reason"
                onChange={(event) => {
                  setCancellationReason(event.target.value);
                }}
                value={cancellationReason}
                label="Enter Cancellation reason"
                variant="outlined"
              />
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              if (!cancellationReason) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Fill the reason",
                    type: CustomSnackbarTypes.WARNING,
                });
                return;
              }
              starChefVisitService
              .cancelStarChefVisit(props.data.id, {
                 cancellationReason
                })
                .then((res) => {
                  refresh();
                  handleClose()
                });
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              handleClose();
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  );
};