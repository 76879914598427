import CallServices from "../../../services/CallServices";
import { MakeCallDto } from "../../../dtos/Call/MakeCallDto";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { EventBusEventsEnum } from "../../../enums/EventBusEventsEnum";
import EventBus from "../../../EventBus";
import { CustomSnackbarTypes } from "../CustomSnackBar";
import CallIcon from "@mui/icons-material/Call";
import {Box, Button, Dialog, DialogActions, DialogContent, Typography} from "@mui/material";
import { useState } from "react";
import { setMakeCall } from "../../../store/reducers/callSlice";

const callServices = new CallServices();

interface InputProps {
  toPhoneNumber: string;
  userType: string;
  heading?: string;
}

function CallButton(props: InputProps) {
  const [openCallingDialog, setOpenCallingDialog] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>("");
  const [holdApiCall, setHoldApiCall] = useState(false);
  const userDetails = useAppSelector((state) => state.userDetails.value);
  const makeCallValue = useAppSelector((state) => state.makeCall.makeCall);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  function handleOnClick() {
    if (!userDetails?.adminUser?.mobile) {
      navigate(`/AdminUsers/${userDetails?.adminUser?.id}`);
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Phone No required",
        type: CustomSnackbarTypes.ERROR,
      });
    } else {
      let payload: MakeCallDto = {
        from: userDetails?.adminUser?.mobile,
        to: props.toPhoneNumber,
        userType: props.userType,
      };
      if (makeCallValue && !holdApiCall) {
        callServices
          .makeCall(payload)
          .then(() => {
            setDialogMessage("Please wait while we connect your call");
            setOpenCallingDialog(true);
            setHoldApiCall(true);
            dispatch(setMakeCall({ makeCall: false }));
            setTimeout(() => {
              setHoldApiCall(false);
              dispatch(setMakeCall({ makeCall: true }));
            }, 10000);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setDialogMessage("Already a call in progress, Please wait");
        setOpenCallingDialog(true);
      }
    }
  }

  if (!props) {
    return null;
  }

  return (
    <div>
      {props?.heading && (
          <Typography fontSize={'0.75em'} fontWeight={400} color={'#525252'} marginBottom={'0.2em'}>
            {props?.heading}
          </Typography>
      )}
      {!!props.toPhoneNumber && !!props.userType && (
        <div>
          <div className="displayFlex">
            <div>{props.toPhoneNumber}</div>
            <Box position="relative">
              <CallIcon
                color="success"
                className="cursorPointer"
                onClick={handleOnClick}
                sx={{position:"absolute"}}
              />
            </Box>
          </div>
          <Dialog open={openCallingDialog}>
            <DialogContent sx={{ textAlign: "center" }}>
              {dialogMessage}
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  setOpenCallingDialog(false);
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
}

export default CallButton;
