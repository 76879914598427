import React, { useEffect, useState } from "react";
import { CookService } from "../../services/CookServices";
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import ChefRatingMetaShow from "./ChefRatingMetaShow";

interface InputProps {
  cookId: number;
}

const cookService = new CookService();

const ChefRating = (props: InputProps) => {
  let [subData, setSubData] = useState<any>(null);
  let [isLoading, setIsLoading] = useState<boolean>();

  const refreshData = (cookId: number) => {
    setIsLoading(true);
    cookService
      .getCookRating(cookId)
      .then((data) => {
        setSubData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    refreshData(props.cookId);
  }, [props.cookId]);

  return (
    <div>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <div>
          { subData?.data &&
            (subData?.data?.overAllRating !== null ? (
              <ChefRatingMetaShow data={subData?.data}/>
            ) : (
              <EmptyListPlaceholder message="No Cook Rating Found" />
            ))}
        </div>
      )}
    </div>
  );
};

export default ChefRating;
