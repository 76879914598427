import { useState } from "react";
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import moment from "moment";
import { ZohoBaseUrl, ZohoModulesUrl } from "../../enums/zoho";
import EditClousreTextDialog from "../Common/EditClousreTextDialog";
import { useRecordContext } from "react-admin";
import { MealMapping } from "../../interfaces/Trials/MealMapping";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";
import { UserGroupsEnum } from "../../enums/UserGroupsEnum";
import { useAppSelector } from "../../store/hooks";
import { useNavigate } from "react-router-dom";

const CookSubscriptionListForModule = (props: any) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<any>();
    const record = useRecordContext();
    const userDetails = useAppSelector((state) => state.userDetails.value);
    let groupsArray: string[] = userDetails.adminUser.accessGroups;
    let navigate = useNavigate();

  return (
    <div>
      <div style={{marginBottom: "10px", display: "flex", alignItems: 'center', gap: '20px'}}>
        {record?.mealMapping?.map((item: MealMapping, index: number): any => <div style={{border: '1px solid #eaeaea', padding: '2px 6px', fontSize: '14px'}} key={String(index)}>{item.slot} {'=>'} {item.time}, <span>{'Buffer Time: '} {item.bufferMinutes} {'minutes'}</span></div>)}
      </div>
      {open && <EditClousreTextDialog open={open} onClose={setOpen} data={data} />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Cook</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Zoho CookId</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Subscription</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>Start Slot</TableCell>
              <TableCell>End Slot</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Leave Taken</TableCell>
              <TableCell>Leave Allowed</TableCell>
              <TableCell>Cook Cut</TableCell>
              <TableCell>Zoho</TableCell>
              <TableCell>Closure Category</TableCell>
              <TableCell>Closure Sub-Category</TableCell>
              <TableCell>Comments</TableCell>
              <TableCell>Closed By</TableCell>
              <TableCell>Closed By Email</TableCell>
              <TableCell>Support End Date</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell>Show</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.data.map((row: any) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell scope="row">{row?.id}</TableCell>
                <TableCell>
                  <Link href={`/#/Cooks/${row.cookId}/show`}>
                    {row?.cookId}
                  </Link>
                </TableCell>
                <TableCell scope="row">{row?.cook?.fname}</TableCell>
                <TableCell scope="row">{row?.cook?.zohoCookId}</TableCell>
                <TableCell scope="row">{row?.cook?.mobile}</TableCell>
                <TableCell scope="row">{row?.subscriptionId}</TableCell>
                <TableCell align="center">
                  {!!row &&
                    !!row.startDate &&
                    moment(row?.startDate, "YYYY-MM-DD").format("DD MMM YYYY")}
                </TableCell>
                <TableCell align="center">{row?.startSlot}</TableCell>
                <TableCell align="center">{row?.endSlot}</TableCell>
                <TableCell align="center">
                  {!!row &&
                    !!row.endDate &&
                    moment(row?.endDate, "YYYY-MM-DD").format("DD MMM YYYY")}
                </TableCell>
                <TableCell align="center">{row?.leaveTaken}</TableCell>
                <TableCell align="center">{row?.leaveAllowed}</TableCell>
                <TableCell align="center">{row?.cookCut}</TableCell>
                <TableCell align="center">
                  {!!row?.zohoId && (
                    <Link
                      href={`${ZohoBaseUrl}${ZohoModulesUrl.COOK_SUBSCRIPTION}/${row.zohoId}`}
                      target="_blank"
                      rel="noopener"
                    >
                      {row.zohoId}
                    </Link>
                  )}
                </TableCell>
                <TableCell scope="row">{row?.closureCategory}</TableCell>
                <TableCell scope="row">{row?.closureSubCategory}</TableCell>
                <TableCell scope="row">{row?.comments?.map((comment: any) => {
                    return(comment?.comment)
                })}</TableCell>
                <TableCell scope="row">
                  {row?.cancelledByDetails?.name}
                </TableCell>
                <TableCell scope="row">
                  {row?.cancelledByDetails?.email}
                </TableCell>
                <TableCell>{row?.supportEndDate ? moment(row?.supportEndDate).format("YYYY-MM-DD hh:mm a") : "---"}</TableCell>
                <TableCell>
                  {row?.endDate ? (
                    GroupPermissionFunction(groupsArray, [
                      UserGroupsEnum.ADMIN,
                      UserGroupsEnum.MANAGER
                    ]) && (
                    <Button
                      variant="contained"
                      size={"small"}
                      color={"primary"}
                      onClick={() => {
                        setOpen(true);
                        setData(row);
                      }}
                    >
                      Edit
                    </Button>
                    )
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell>
                  <Button onClick={() => {
                          navigate(
                          `/cookSubscription/show?houseId=${row?.userBundleMapping.houseId}&subscriptionId=${row?.id}`
                          );
                    }}>
                    Show
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CookSubscriptionListForModule;
