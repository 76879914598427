export enum RentPayingCapacityEnum {
  GT_0K_LTE_5K = "5000",
  GT_5K_LTE_10K = "10000",
  GT_10K_LTE_15K = "15000",
  GT_15K_LTE_20K = "20000",
  GT_20K_LTE_25K = "25000",
  GT_25K_LTE_30K = "30000",
  GT_30K_LTE_35K = "35000",
  GT_35K_LTE_40K = "40000",
  GT_40K_LTE_45K = "45000",
  GT_45K_LTE_50K = "50000",
  GT_50K_LTE_55K = "55000",
  GT_55K_LTE_60K = "60000",
  GT_60K_LTE_65K = "65000",
  GT_65K_LTE_70K = "70000",
  GT_70K_LTE_75K = "75000",
  GT_75K_LTE_80K = "80000",
  GT_80K_LTE_85K = "85000",
  GT_85K_LTE_90K = "90000",
  GT_90K_LTE_95K = "95000",
  GT_95K_LTE_100K = "100000",
  GT_100K_LTE_105K = "105000",
  GT_105K_LTE_110K = "110000",
  GT_110K_LTE_115K = "115000",
  GT_115K_LTE_120K = "120000",
  GT_120K_LTE_125K = "125000",
  GT_125K_LTE_130K = "130000",
  GT_130K_LTE_135K = "135000",
  GT_135K_LTE_140K = "140000",
  GT_140K_LTE_145K = "145000",
  GT_145K_LTE_150K = "150000",
  GTE_150K = ">150000",
}
