import { AnswerOptions } from "../../interfaces/Cook/SkillAssessmentInterface";

interface InputProps {
  question: string;
  options: AnswerOptions[];
  questionNo: number;
  marks: number;
  setMarks: (marks: number) => void;
}

const SkillAssessmentQuestion = ({
  question,
  options,
  questionNo,
  marks,
  setMarks,
}: InputProps) => {
  return (
    <div style={{ margin: "2rem" }}>
      <div
        style={{ fontWeight: "bold", fontSize: "1.1rem" }}
      >{`Q${questionNo}: ${question}`}</div>
      <div>
        {options.map((option: any, index: number) => (
          <div key={"" + index}>
            <label
              onClick={(event: any) => {
                if (event.target.checked !== undefined) {
                  if (event.target.checked === true) {
                    setMarks(marks + Number(option.marks));
                  } else {
                    setMarks(marks - Number(option.marks));
                  }
                }
              }}
              htmlFor={"" + index + questionNo}
            >
              <div style={{ display: "flex" }}>
                <input
                  style={{ marginRight: "0.5rem" }}
                  type="checkbox"
                  id={"" + index + questionNo}
                  value={option.answerText}
                />
                <div>{option.answerText} </div>
              </div>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkillAssessmentQuestion;
