import { useEffect, useState } from "react";
import { CustomerService } from "../../services/CustomerService";
import { CallMaskingReferenceModelNameEnum } from "../../enums/CallMaskingReferenceModelNameEnum";
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import CustomerCallRecordingsTable from "./CustomerCallRecordingsTable";

const customerService = new CustomerService();

interface InputProps {
    serviceId: number,
    referenceModalName: CallMaskingReferenceModelNameEnum
}
const CustomerCallRecordingsTab = (props: InputProps) => {

    let [callMaskData, setCallMaskData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>();
    const getMaskedCallData = (id: number, referenceModalName: CallMaskingReferenceModelNameEnum) => {
        setIsLoading(true);
        customerService.getMaskedCallRecording(id, referenceModalName).then((data) => {
            setCallMaskData(data);
            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
        })
    }
    useEffect(() => {
        if(!props.serviceId && !props.referenceModalName) {
            return;
        }
        getMaskedCallData(props.serviceId, props.referenceModalName)
    }, [props.serviceId, props.referenceModalName])
    return (
        <div>
            {isLoading? (
                <CircularLoader />
            ) : (
                <div>
                    {callMaskData && callMaskData?.data&&
                    Array.isArray(callMaskData?.data) &&
                    (
                        callMaskData?.data?.length > 0?
                            <CustomerCallRecordingsTable data={callMaskData?.data} />
                            :
                        <EmptyListPlaceholder message="No Recodings Found"/>
                    )
                    }
                </div>
            )}
        </div>

    )
}
export default CustomerCallRecordingsTab
