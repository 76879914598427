import {
    Box, Button,
    Checkbox,
    Dialog, FormControl,
    FormControlLabel,
    InputLabel, MenuItem, Radio, RadioGroup, Select,
    TextField,
    TextFieldProps,
    Typography
} from "@mui/material";
import {FieldViewInputTypeEnums} from "../../enums/FieldViewInputTypeEnums";
import {DatePicker, TimePicker} from "@mui/x-date-pickers";
import moment from "moment/moment";
import React from "react";

type InputProps = {
    open: boolean;
    onClose: () => void;
    label: string;
    inputType: string;
    parameterValues: string;
    submitClicked: (value: string | string[], key: string) => void;
}
const AddCookAdditionalDetailValue = (props: InputProps) => {
    const [value, setValue] = React.useState<string | string[]>('');
    const handleDateChange = (newValue: any) => {
        if (newValue) {
            setValue(moment(newValue).format('YYYY-MM-DD').toString());
        }
    }
    console.log(value);
    return (
        <Dialog open={props?.open} onClose={props?.onClose} sx={{
            '& .MuiPaper-root': {
                py: '20px',
                px: '20px',
                minWidth: '525px',
                maxWidth: '650px',
            },
        }}>
            <Typography fontWeight={600} letterSpacing={'1px'} fontSize={'16px'} mb={'15px'}>Add Cook Additional Detail</Typography>
            {/*Add Detail Value Form */}
            <Box>
                <Typography fontWeight={500} letterSpacing={'1px'}>Key: <Typography variant={"caption"} fontSize={'16px'} ml={'5px'}>{props?.label}</Typography></Typography>
            </Box>
            {/*Input Type */}
            <Box mt={'10px'}>
                {props?.inputType === FieldViewInputTypeEnums.TEXT && (
                    <Box>
                        <TextField
                            label="TEXT"
                            variant="outlined"
                            fullWidth
                            onChange = {(event: React.ChangeEvent<HTMLTextAreaElement>) => setValue(event?.target?.value)}
                        />
                    </Box>
                )}
                {props?.inputType === FieldViewInputTypeEnums.EMAIL && (<Box>
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        onChange = {(event: React.ChangeEvent<HTMLTextAreaElement>) => setValue(event?.target?.value)}
                    />
                </Box>)}
                {(props?.inputType === FieldViewInputTypeEnums.NUMBER || props?.inputType === FieldViewInputTypeEnums.TEL) && (<Box>
                    <TextField
                        label={props?.inputType}
                        variant="outlined"
                        fullWidth
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        onChange = {(event: React.ChangeEvent<HTMLTextAreaElement>) => setValue(event?.target?.value)}
                    />
                </Box>)}
                {props?.inputType === FieldViewInputTypeEnums.DATE && (<Box>
                    <DatePicker
                        label={"Select Date"}
                        inputFormat="dd MMM yyyy"
                        disableMaskedInput
                        minDate={moment().toDate()}
                        value={value}
                        onChange={(newValue: any) => handleDateChange(newValue)}
                        renderInput={(params: TextFieldProps) => <TextField variant={'outlined'} {...params} />}
                    />
                </Box>)}
                {props?.inputType === FieldViewInputTypeEnums.TIME && (<Box>
                    <TimePicker
                        onChange={(newValue: any) => setValue(newValue.toString())}
                        value={value}
                        renderInput={(params: TextFieldProps) => <TextField variant={'outlined'} {...params} />}
                    />
                </Box>)}
                {props?.inputType === FieldViewInputTypeEnums.CHECKBOX && (<Box>
                    {props?.parameterValues && Array.isArray(JSON.parse(props?.parameterValues?.replace(/'/g, '"'))) && JSON.parse(props?.parameterValues?.replace(/'/g, '"'))?.length > 0 && JSON.parse(props?.parameterValues?.replace(/'/g, '"'))?.map((value: string, index: number) => (
                        <FormControlLabel
                            key={value}
                            label={value}
                            control={
                                <Checkbox
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue((prevState) => {
                                        if (Array.isArray(prevState)) {
                                            if (event?.target?.checked) {
                                                return [...prevState, value];
                                            } else {
                                                return prevState?.filter((item: string) => item !== value);
                                            }
                                        } else {
                                            return [value];
                                        }
                                    })}
                                />
                            }
                        />
                    ))}
                </Box>)}
                {props?.inputType === FieldViewInputTypeEnums.RADIO && (<Box>
                    {props?.parameterValues && Array.isArray(JSON.parse(props?.parameterValues?.replace(/'/g, '"'))) && JSON.parse(props?.parameterValues?.replace(/'/g, '"')).length > 0 && (
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="radio-options"
                                name="radio-options"
                                value={value}
                                onChange={(event) => setValue(event.target.value)}
                            >
                                {JSON.parse(props?.parameterValues?.replace(/'/g, '"')).map((value: string) => (
                                    <FormControlLabel key={value} value={value} control={<Radio />} label={value} />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    )}
                </Box>)}
                {props?.inputType === FieldViewInputTypeEnums.SELECT && (<Box>
                    <FormControl fullWidth>
                        <InputLabel id="for_slot">Select Value</InputLabel>
                        <Select
                            labelId="select_value"
                            id="Select_Value"
                            label="Select Value"
                            value={value}
                            onChange={(event) => setValue(event?.target?.value)}
                        >
                            {props?.parameterValues && Array.isArray(JSON.parse(props?.parameterValues?.replace(/'/g, '"'))) &&  JSON.parse(props?.parameterValues?.replace(/'/g, '"'))?.length > 0 &&  JSON.parse(props?.parameterValues?.replace(/'/g, '"'))?.map((val: string, index: number) => (
                                <MenuItem key={val} value={val}>
                                    {val}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>)}
                {props?.inputType === FieldViewInputTypeEnums.TEXTAREA && (<Box>
                    <TextField
                        label="TEXTAREA"
                        variant="outlined"
                        fullWidth
                        onChange = {(event: React.ChangeEvent<HTMLTextAreaElement>) => setValue(event?.target?.value)}
                    />
                </Box>)}
            </Box>
            {/*Submit */}
            <Box textAlign={'right'} mt={'20px'}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    sx={{
                        letterSpacing: '1.5px',
                    }}
                    onClick={() => {props?.submitClicked(value, props?.label)}}
                >
                    Submit
                </Button>
            </Box>
        </Dialog>
    )
}
export default AddCookAdditionalDetailValue;
