import { Box, Card, Chip, IconButton } from "@mui/material";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { MealType } from "../../enums/MealType";

interface InputProps{
    dish:any,
    editDish:(dishId:number)=>void,
    removeDishes:(dishId:number,partyId:number)=>void
}

const ChefForPartyDish = (props:InputProps)  => {
    
    return (
        <>
            <Card sx={{ padding: "1rem 2rem",display:"flex", flexDirection:"row", justifyContent:"space-between"}} key={props?.dish?.dish?.id}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start",gap:"0.5rem"}}>
                        <div style={{fontSize:"1rem", fontWeight:"500"}}>{props?.dish?.dish?.name}</div>
                        <Box sx={{ display: "flex", alignItems: "flex-start",gap:"0.5rem" }}>
                            <Chip size="small" label={props?.dish?.dish?.type} sx={props?.dish?.dish?.type==="VEG"?{backgroundColor:"green",color:"white",fontWeight:"500",fontSize:"0.7rem"}:{backgroundColor:"red", color:"white",fontWeight:"500",fontSize:"0.7rem"}}/>
                            {
                                props?.dish?.dish?.mealType===MealType.STARTER&& <Chip size="small" label={props?.dish?.dish?.mealType} sx={{backgroundColor:"#f44336",color:"white",fontWeight:"500",fontSize:"0.7rem"}}/>
                            }
                            {
                                props?.dish?.dish?.mealType===MealType.MAIN_COURSE&& <Chip size="small" label={props?.dish?.dish?.mealType} sx={{backgroundColor:"orange",color:"white",fontWeight:"500",fontSize:"0.7rem"}}/>
                            }
                            {
                                props?.dish?.dish?.mealType===MealType.DESSERT&& <Chip size="small" label={props?.dish?.dish?.mealType} sx={{backgroundColor:"#2c387e",color:"white",fontWeight:"500",fontSize:"0.7rem"}}/>
                            }
                            {
                                props?.dish?.dish?.mealType===MealType.SIDE_DISH&& <Chip size="small" label={props?.dish?.dish?.mealType} sx={{backgroundColor:"#1cb1f5",color:"white",fontWeight:"500",fontSize:"0.7rem"}}/>
                            }
                        </Box>
                    </Box>
                    <Box>
                        <IconButton color="primary" onClick={()=>props.editDish(props?.dish?.dish?.id)}><EditRoundedIcon color="primary" /></IconButton>
                        <IconButton color="error"  onClick={()=>{
                            EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
                                message: "Are you sure you want to remove this Dish?",
                                onClose: (confirmed: boolean) => {
                                  if (confirmed) {
                                    props.removeDishes(props?.dish?.dish?.id,props?.dish?.chefForPartyId)
                                  }
                                },
                                title: "Confirmation"
                              });
                        }}><DeleteRoundedIcon color="error"/>
                        </IconButton>
                    </Box>
            </Card>
        </>
    )

}

export default ChefForPartyDish
