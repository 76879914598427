export default class Op {
    public static readonly adjacent: unique symbol = Symbol('adjacent');
    public static readonly all: unique symbol = Symbol('all');
    public static readonly and: unique symbol = Symbol('and');
    public static readonly any: unique symbol = Symbol('any');
    public static readonly between: unique symbol = Symbol('between');
    public static readonly col: unique symbol = Symbol('col');
    public static readonly contained: unique symbol = Symbol('contained');
    public static readonly contains: unique symbol = Symbol('contains');
    public static readonly endsWith: unique symbol = Symbol('endsWith');
    public static readonly eq: unique symbol = Symbol('eq');
    public static readonly gt: unique symbol = Symbol('gt');
    public static readonly gte: unique symbol = Symbol('gte');
    public static readonly iLike: unique symbol = Symbol('iLike');
    public static readonly in: unique symbol = Symbol('in');
    public static readonly iRegexp: unique symbol = Symbol('iRegexp');
    public static readonly is: unique symbol = Symbol('is');
    public static readonly like: unique symbol = Symbol('like');
    public static readonly lt: unique symbol = Symbol('lt');
    public static readonly lte: unique symbol = Symbol('lte');
    public static readonly match: unique symbol = Symbol('match');
    public static readonly ne: unique symbol = Symbol('ne');
    public static readonly noExtendLeft: unique symbol = Symbol('noExtendLeft');
    public static readonly noExtendRight: unique symbol = Symbol('noExtendRight');
    public static readonly not: unique symbol = Symbol('not');
    public static readonly notBetween: unique symbol = Symbol('notBetween');
    public static readonly notILike: unique symbol = Symbol('notILike');
    public static readonly notIn: unique symbol = Symbol('notIn');
    public static readonly notIRegexp: unique symbol = Symbol('notIRegexp');
    public static readonly notLike: unique symbol = Symbol('notLike');
    public static readonly notRegexp: unique symbol = Symbol('notRegexp');
    public static readonly or: unique symbol = Symbol('or');
    public static readonly overlap: unique symbol = Symbol('overlap');
    public static readonly placeholder: unique symbol = Symbol('placeholder');
    public static readonly regexp: unique symbol = Symbol('regexp');
    public static readonly startsWith: unique symbol = Symbol('startsWith');
    public static readonly strictLeft: unique symbol = Symbol('strictLeft');
    public static readonly strictRight: unique symbol = Symbol('strictRight');
    public static readonly substring: unique symbol = Symbol('substring');
    public static readonly values: unique symbol = Symbol('values');
}
