import QuestionInterface from "../../interfaces/Cook/SkillAssessmentInterface";

export const communicationTrainingQuestions: QuestionInterface[] = [
  {
    questionText:
      "Humari company ka naam kya hai?",
    answerOptions: [
      {
        answerText:
          "Chefkart",
        marks: 10,
      },
      {
        answerText: "Chipkart",
        marks: 0,
      },
      {
        answerText: "Flipkart",
        marks: 0,
      },
    ],
  },
  {
    questionText:
      "Company se milne wale kaam par kaise kapde pehen ke jana hoga?",
    answerOptions: [
      {
        answerText:
          "Kaise bhi kapde pehen sakte hain",
        marks: 0,
      },
      {
        answerText:
          "Chefkart ki uniform (Tshirt/Chefcoat/Hoodie) hi pehnna zaroori hai",
        marks: 20,
      },
      {
        answerText:
          "Kapde koi bhi pehen sakte hain, kaam sahi hona chahiye",
        marks: 0,
      },
    ],
  },
  {
    questionText:
      "Kaam par pahuchne ke baad aap apna bag/kit kahaan aur kisse poochke rakhenge?",
    answerOptions: [
      {
        answerText:
          "Apne sath kitchen mein le jayenge",
        marks: 0,
      },
      {
        answerText: "Customer se poochke main gate se andar aake uske pass hi rakhenge",
        marks: 10,
      },
      {
        answerText: "Main gate ke bahar rakhenge",
        marks: 0,
      },
    ],
  },
  {
    questionText:
      "Jab aap customer ke ghar trial ke liye jayenge, tab khana banane se pehle kaunsi 3 cheezein puchenge?",
    answerOptions: [
      {
        answerText:
          "Oil kaisa rakhna hai",
        marks: 10,
      },
      { answerText: "Masala kaisa khate hain", marks: 10 },
      {
        answerText:"Kya main khana taste kar ke dekh sakta/sakti hoon",
        marks: 10,
      },
      { answerText: "Bartan saaf hain ya nahi", marks: 0 },
    ],
  },

  {
    questionText:
      "Aap customer ko kya kehke bulayenge?",
    answerOptions: [
      {
        answerText:
          "Sir/ Maam",
        marks: 10,
      },
      { answerText: "Didi/Bhaiya", marks: 0 },
      {
        answerText:
          "Bhaiya/ Bhabhi",
        marks: 0,
      },
      { answerText: "Name se bulayenge", marks: 0 },
    ],
  },

  {
    questionText:
      "Aap customer se milte hi kaise greet karenge?",
    answerOptions: [
      {
        answerText:
          "Good morning / Good evening",
        marks: 10,
      },
      { answerText: "Hi/Hello", marks: 0 },
      {
        answerText:
          "Namaste sir/ma’am",
        marks: 10,
      },
      { answerText: "Kuch nahi bolenge, apna kaam karenge", marks: 0 },
    ],
  },
];
