import {Button, TableBody, TableCell, TableRow } from "@mui/material"
import { Link } from "react-admin"

const CookServeTable =(props:any)=>{
    return (
        <>
        <TableBody>
            {
                props?.data.map((cook:any)=>{
                    return (
                        <TableRow key={cook?.id} sx={cook?.notAvailable?{backgroundColor:"lightgrey !important"}:{}}>
                            <TableCell ><Link to={`/Cooks/${cook?.cookFullProfile?.cookId}/show`}>{cook?.cookFullProfile?.cookId}</Link></TableCell>
                            <TableCell>{cook?.cookFullProfile?.firstName+" "+cook?.cookFullProfile?.lastName+" "}
                                <span style={{color:"green"}}>{(!cook?.previousRatingByCx&&!cook?.overallRating)?<></>:cook?.previousRatingByCx?cook?.previousRatingByCx+" By Cx":cook?.overallRating+" Avg"}</span>
                            </TableCell>
                            <TableCell>{cook?.slcName}</TableCell>
                            <TableCell>{cook?.mappingType}</TableCell>
                            <TableCell>{(!cook?.notAvailable&&cook?.currentAvailableSlot)?cook?.currentAvailableSlot:"-"}</TableCell>
                            <TableCell>{(!cook?.notAvailable&&cook?.nextAvailableSlot)?cook?.nextAvailableSlot:"-"}</TableCell>
                            <TableCell><Button onClick={()=>{
                                props?.setDialogOpen(true);
                                props?.setCook(cook?.cookFullProfile?.cookId);
                            }} variant="contained">Show Slots</Button>
                            </TableCell>
                            {
                                props?.hasService?<TableCell><Button onClick={()=>{
                                    props?.setAssignCookOpen(true);
                                    props?.setCook(cook?.cookFullProfile?.cookId);
                                }} variant="contained">Assign</Button>
                                </TableCell>:<></>
                            }
                        </TableRow>
                    )
                })
            }    
        </TableBody>
        </>
    )
}
export default CookServeTable