import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import { useRefresh } from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { TrialsService } from "../../services/TrialServices";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { useLocation } from "react-router-dom";

interface InputProps {
  data: any
  open: boolean;
  setOpen: (open: boolean) => any;
}

const trialsService = new TrialsService();

const PreferenceAutoAllocateTrialDialog = (props: InputProps) => {
  const refresh = useRefresh();
  let location = useLocation();
  let trialId = location.pathname.split("/")[2];

  const [preference, setPreference] = useState<any>(null);

  return (
    <div>
      <Dialog open={props.open} scroll={"paper"} maxWidth="xl">
        <DialogTitle>Select the Preference of Cook</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormControl style={{ marginTop: "20px", width: 500 }} fullWidth>
              <InputLabel id="select-label">Select the preference</InputLabel>
              <Select
                  autoWidth={false}
                  className="input_field"
                  labelId="select-label"
                  id="select-label"
                  defaultValue="Select Preference"
                  value={preference}
                  onChange={(event) => setPreference(event.target.value)}
              >
                {Array.from(new Array(9), (_, index) => index + 1).map((val) => {
                  return (
                      <MenuItem key={val} value={val}>
                        {val}
                      </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              if (preference === null) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Please select the preference",
                  type: CustomSnackbarTypes.WARNING,
                });
                return;
              }
              trialsService
                  .updatePreference(Number(trialId), {
                      choices: [{
                          cookId: props?.data?.cookId,
                          preference: preference,
                      }]
                  })
                  .then((res) => {
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                      message: "Success",
                      type: CustomSnackbarTypes.SUCCESS,
                    });
                    props.setOpen(false);
                    refresh();
                  });
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              props.setOpen(false);
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PreferenceAutoAllocateTrialDialog;
