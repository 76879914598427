import { Grid, Button } from "@mui/material";
import { useState } from "react";
import FreshLeadInterface from "../../interfaces/Cook/FreshLeadInterface";
import EmployeRosterForCookProfileDialog from "../EmployeRosterForCookProfileDialog";
import CkMuiDialog from "./CkMuiDialog/CkMuiDialog";
interface InputProps {
  cookDetail: FreshLeadInterface;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  refresh: () => void;
}

const AssignAgentDialog = (props: InputProps) => {
  const [updateAgent, setUpdateAgent] = useState<boolean>(false);

  const handleAssignAgent = () => {
    setUpdateAgent(true);
  };

  const leadName = () => {
    if (props?.cookDetail?.firstName) {
      return props?.cookDetail?.firstName + " " + props?.cookDetail?.lastName;
    }
    return "N/A";
  };

  const valideName = () => {
    if (props?.cookDetail?.owner) {
      return props?.cookDetail?.owner;
    }
    return "N/A";
  };
  

  return (
    <CkMuiDialog
      isDialogOpen={props.isOpen}
      setDialogOpen={() => {}}
      onDialogClose={() => {
        props.refresh();
      }}
      title={"Update Agent"}
      primaryLabel={"Close"}
      primaryAction={() => {
        props.setIsOpen(false);
      }}
      secondaryLabel={""}
      secondaryAction={() => {}}
      isLoading={false}
      fullWidth={true}
    >
      <Grid container>
        <Grid item xs={12}>
          <h5 style={{ margin: 0 }}>{`Lead Name: ${leadName()} `} </h5>
        </Grid>
        <Grid item xs={12}>
          <h5>{`Current Agent: ${valideName()}`}</h5>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Button onClick={handleAssignAgent}>Select Agent</Button>
          <EmployeRosterForCookProfileDialog
            cookDetails={props.cookDetail}
            open={updateAgent}
            setOpen={setUpdateAgent}
            setAssingAgentDialogOpen={props.setIsOpen}
            refresh={props.refresh}
          />
        </Grid>
      </Grid>
    </CkMuiDialog>
  );
};

export default AssignAgentDialog;
