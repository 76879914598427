import React, { useEffect, useState } from "react";
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import PayoutList from "./PayoutList";
import {PayoutServices} from "../../services/PayoutServices";
import {BankDetailsResponse} from "../../interfaces/Payout/BankDetailsResponse";
import { useAppSelector } from "../../store/hooks";

interface InputProps {
  userId: number;
  userType: string;
}

const payoutService = new PayoutServices();
const UserPayoutTab = (props: InputProps) => {
  const [allBankDetails, setAllBankDetails] = useState<BankDetailsResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const bankDetail = useAppSelector((state) => state.bankDetails.value);

  const getData = (userId: number, userType: string) => {
    setIsLoading(true);
    payoutService
      .getAllPayoutsForUser(userId, userType)
      .then((data) => {
        setAllBankDetails(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if(!props.userId || !props.userType) return;
    getData(props.userId, props.userType);
  }, [props.userId, props.userType, bankDetail]);

  return (
    <div>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <div>
          {allBankDetails &&
            Array.isArray(allBankDetails) &&
            (allBankDetails.length > 0 ? (
              <PayoutList
                  data={allBankDetails}
                  cookId={props.userId}
                  callBack={()=>{
                      getData(props.userId, props.userType);
                  }}
              />
            ) : (
              <EmptyListPlaceholder message="No accounts Found" />
            ))}
        </div>
      )}
    </div>
  );
};

export default UserPayoutTab;
