export const KalendColors = [
  "yellow",
  "red",
  "blue",
  "pink",
  "brown",
  "silver",
  "maroon",
  "purple",
  "gray",
  "fuchsia",
  "lime",
  "olive",
  "navy",
  "teal",
  "aqua",
  "Alice blue",
  "aquamarine",
  "azure",
  "beige",
  "bisque",
  "blue violet",
  "blanched almond",
  "brown",
  "burlywood",
  "cadetblue",
  "chartreuse",
  "chocolate",
  "coral",
  "cornflower blue",
  "cornsilk",
  "crimson",
  "cyan",
  "dark blue",
  "darkcyan",
  "dark goldenrod",
  "darkgray",
  "dark green",
  "darkgrey",
  "dark khaki",
  "dark magenta",
  "dark olive green",
  "dark orange",
  "dark orchid",
  "darkred",
  "dark salmon",
  "dark sea green",
  "dark slate blue",
  "dark slate gray",
  "dark turquoise",
  "dark violet",
  "deep pink",
  "deep sky blue",
  "dim gray",
  "dodgerblue",
  "firebrick",
  "forest green",
  "gainsboro",
  "gold",
  "goldenrod",
  "gray",
  "green yellow",
  "honeydew",
  "hotpink",
  "indianred",
  "indigo",
  "ivory",
  "khaki",
  "lavender",
  "lavender blush",
  "lawngreen",
  "lemon chiffon",
  "lightblue",
  "lightcoral",
  "light cyan",
  "light golden rod yellow",
  "lightpink",
  "lightsteelblue",
  "lightyellow",
  "lime",
  "limegreen",
  "linen",
  "olive drab",
  "orange",
  "orange red",
  "pale golden rod",
  "palegreen",
  "pale turquoise",
  "palevioletred",
  "papayawhip",
  "peach puff",
  "Peru",
  "royal blue",
];
