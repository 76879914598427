import React from 'react'
import { useAppSelector } from '../../store/hooks';
import { useTheme } from 'react-admin';
import { useNavigate } from "react-router-dom";
import { Autocomplete, Box, Button, ClickAwayListener, Dialog, Paper, TextField, Typography, createFilterOptions } from '@mui/material';
import { menuOptions, recentMenuOptions } from '../../constants/MenuOptions';
import { DescriptionOutlined, History, Search } from '@mui/icons-material';

const CustomPaper =({ children }: any)  => {
  return (
    <Paper
      sx={{
        borderRadius: '0px 0px 10px 10px',
        borderColor: 'rgb(218, 226, 237)',
        "& .MuiAutocomplete-listbox": {
          paddingTop: '15px',
        },
      }}
    >
      {children}
    </Paper>
  );
}

const ModuleSearch = () => {
    const userDetails = useAppSelector((state) => state.userDetails.value);
    const [open, setOpen] = React.useState<boolean>(false);
    const [searchValue, setSearchValue] = React.useState({ title: '', url: '' });
    const [openOptions, setOpenOptions] = React.useState<boolean>(false);
    //eslint-disable-next-line
    const [theme, setTheme] = useTheme();
    let navigate = useNavigate();
    let groupsArray: string[] = userDetails.adminUser.accessGroups;

    const handleClick = () => {
        setOpen((prev) => !prev);
    };
    const handleClickAway = () => {
        setOpen(false);
    };

    const filterOptions = createFilterOptions({
        // matchFrom: 'start',
        stringify: (option: any) => option.title,
    });
    const filterMenuOptions = (userAccessGroups: any, menuOptions: any) => {
        return menuOptions?.filter((option: { permission: any[]; }) =>
        option?.permission?.some(permission => userAccessGroups?.includes(permission))
        );
    };
    const filteredMenuOption = filterMenuOptions(groupsArray, menuOptions);
    const filteredRecentMenuOptions = filterMenuOptions(groupsArray, recentMenuOptions);

    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
            event.preventDefault();
            setOpen(true);
        }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
        window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    React.useEffect(() => {
        if (searchValue?.url) {
        navigate(searchValue?.url);
        handleClickAway();
        }
        // eslint-disable-next-line
    }, [searchValue])
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
        <Box mx={'10px'}>
          <Button sx={{
            color: 'black',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#F3F6F9',
            width: '190px',
            textTransform: 'none',
            border: '1px solid #DAE2ED',
            borderRadius: '1vw',
            boxShadow: 'inset 0 1px 1px #E5EAF2,0 1px 0.5px rgba(229, 234, 242, 0.6)',
            px: '7px',
            py: '4px',
          }}
            onClick={handleClick}
          >
            <Box display={'flex'} alignItems={'center'} gap={'8px'}>
              <Search sx={{fontSize: '1.25rem', color: 'black'}}/>
              <Typography fontSize={'0.835rem'} color={'#434D5B'} fontWeight={500}>Search...</Typography>
            </Box>
            <Box p={'0px 4px'} border={'1px solid #DAE2ED'} borderRadius={'7px'} display={'flex'} alignItems={'center'}>
              <Typography display={{ xs: 'none', md: 'inline-block' }} fontSize={'0.7rem'} fontWeight={500}
              >{/Mac/.test(navigator?.userAgent.toString()) ? '⌘K' : 'ctrl+K'}</Typography>
            </Box>
          </Button>
          {open &&
            <Dialog open={open} onClose={handleClickAway}
              sx={{
                backdropFilter: 'blur(2px)',
                '.MuiPaper-root': {
                  maxWidth: '100%',
                  borderRadius: !openOptions ? '8px' : '8px 8px 0px 0px',
                  borderColor: 'rgb(218, 226, 237)',
                },
                '.MuiDialog-container': {
                  alignItems: 'flex-start',
                  paddingTop: '16vh',
                }
              }}
            >
              <Box>
                <Autocomplete
                  freeSolo
                  sx={{
                    width: '50vw',
                    '.MuiFilledInput-root': {
                      background: 'transparent',
                      '&:hover': {
                        background: 'transparent',
                      }
                    },
                  }}
                  open={openOptions}
                  onInputChange={(event, value) => {
                    setOpenOptions(value?.length > 0);
                  }}
                  renderInput={(params) => (
                    <Box width={'100%'} position={'relative'}>
                      <Box position={'absolute'} top={'37%'} left={'3%'}>
                        <Search sx={{fontSize: '22px'}}/>
                      </Box>
                      <TextField {...params} autoFocus variant="standard" placeholder="What are you looking for?"
                        sx={{
                          '& .MuiInput-root': {
                            py: '15px',
                            pr: '20px',
                            pl: '54px',
                            letterSpacing: '1px',
                            fontFamily: 'inherit',
                            fontWeight: '500',
                            '& .MuiAutocomplete-endAdornment': {
                              display: 'none',
                            }
                          }
                        }}
                      />
                      <Box sx={{
                        position: 'absolute',
                        top: '34%',
                        right: '2%',
                        border: '1px solid rgb(218, 226, 237)',
                        borderRadius: '6px',
                        backgroundColor: 'rgb(243, 246, 249)',
                        px: '5px', py: '2px',
                        cursor: 'pointer',
                      }}
                        onClick={handleClickAway}>
                        <Typography fontSize={'0.75rem'} fontWeight={700} fontFamily={'inherit'} color={'rgb(67, 77, 91)'}>esc</Typography>
                      </Box>
                    </Box>
                  )}
                  renderOption={(props, option) => (
                    <>
                      {openOptions && <Box
                        onClick={() => {
                          setSearchValue(option);
                          setOpenOptions(false);
                        }}
                        sx={{
                          mx: '20px', mb: '7px',
                          px: '20px', py: '7px',
                          cursor: 'pointer',
                          backgroundColor: theme?.palette?.mode === "dark" ? '#fff' : 'rgba(243, 246, 249, 0.4)',
                          color: '#494747',
                          border: '1px solid rgba(229, 234, 242, 0.5)',
                          '&:hover': {
                            backgroundColor: theme?.palette?.mode === "dark" ? '#e3f1ff'  :'#0072e517',
                            border: '1px solid rgb(102, 178, 255)',
                            color: '#0072E5',
                          },
                          borderRadius: '12px',
                        }}
                      >
                        <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                          <Box>
                            <DescriptionOutlined />
                          </Box>
                          <Typography {...props} fontFamily={'inherit'} fontWeight={500}>{option?.title}</Typography>
                        </Box>
                      </Box>}
                    </>
                  )}
                  onChange={(event, value) => {
                    if (value && value?.title && value?.url) {
                      setSearchValue(value);
                    }
                  }}
                  options={filteredMenuOption?.slice()?.sort((a:any, b: any) => a?.title.localeCompare(b?.title))}
                  filterOptions={filterOptions}
                  getOptionLabel={(option) => option?.title}
                  PaperComponent={CustomPaper}
                />
                {!openOptions && 
                  <>
                  <Typography mx={'40px'} mt={'10px'} fontFamily={'inherit'} fontWeight={700} color={'rgb(107, 122, 144)'} fontSize={'0.685rem'}
                  >TOP SEARCHES</Typography>
                    <Box py={'10px'}>
                      {filteredRecentMenuOptions?.slice()?.sort((a: any, b: any) => a?.title.localeCompare(b?.title))?.map((value: any, index: number) => {
                        return (
                          <Box key={index}
                            onClick={() => {
                              setSearchValue(value);
                            }}
                            sx={{
                              mx: '20px', mb: '7px',
                              px: '20px', py: '15px',
                              cursor: 'pointer',
                              backgroundColor: theme?.palette?.mode === "dark" ? '#fff' :'rgba(243, 246, 249, 0.4)',
                              border: '1px solid rgba(229, 234, 242, 0.5)',
                              color: '#494747',
                              '&:hover': {
                                backgroundColor: theme?.palette?.mode === "dark" ? '#e3f1ff'  :'#0072e517',
                                border: '1px solid rgb(102, 178, 255)',
                                color: '#0072E5',
                              },
                              borderRadius: '12px',
                            }}
                          >
                            <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                              <Box display={'flex'} alignItems={'center'}>
                                <History />
                              </Box>
                              <Typography fontFamily={'inherit'} fontWeight={500}>{value?.title}</Typography>
                            </Box>
                          </Box>
                        )
                      })}
                    </Box>
                  </>
                }
              </Box>
            </Dialog>
          }
        </Box>
      </ClickAwayListener>
  )
}

export default ModuleSearch