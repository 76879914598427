import { useCallback, useEffect, useState } from "react";
import { OneMealService } from "../../services/OneMealService";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import OneMealListForModule from "../OneMealService/OneMealListForModule";

const oneMealService = new OneMealService();

interface InputProps {
  userBundleMappingId: number;
}

const UserBundleOmsTab = ({ userBundleMappingId }: InputProps) => {
  let [subData, setSubData] = useState<any>(null);
  let [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(10);

  const refreshData = useCallback((
    pageNo: number
  ) => {
    setIsLoading(true);
    oneMealService
      .getAllOneMealServiceForUserBundle(userBundleMappingId, pageSize, pageSize * pageNo, {order: "DESC", field: "createdAt"})
      .then((data) => {
        setSubData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  },[userBundleMappingId, pageSize])

  useEffect(() => {
    refreshData(0);
  }, [refreshData]);

  return (
    <div>
    {subData &&
      subData?.data &&
      Array.isArray(subData?.data) &&
      (subData?.data?.length > 0 ? (
        <OneMealListForModule
          isLoading={isLoading}
          count={subData?.count}
          data={subData?.data}
          refreshData={refreshData}
          setPageSize={setPageSize}
          pageSize={pageSize}
        />
      ) : (
        <EmptyListPlaceholder message="No One Meal Service found" />
      ))}
  </div>
  );
};

export default UserBundleOmsTab;
