import React, {useEffect, useState} from 'react';
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import {
    Box,
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel,
    MenuItem,
    Paper, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import CityService from "../../services/CityService";
import {ReferenceModelNameEnums} from "../../enums/ReferenceModelNameEnums";
import {useRecordContext} from "react-admin";
import CityResponse from "../../interfaces/City/CityResponse";
import ServiceResponse from "../../interfaces/Service/ServiceResponse";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";
import {UserGroupsEnum} from "../../enums/UserGroupsEnum";
import {useAppSelector} from "../../store/hooks";

interface InputProps {}
const cityService = new CityService();

const CityServicesTab = (props: InputProps) => {
    const userDetails = useAppSelector((state) => state.userDetails.value);
    let groupsArray: string[] = userDetails.adminUser.accessGroups;
    let record: CityResponse = useRecordContext();
    let [allServices, setAllServices] = useState<any>([]);
    let [selectedService, setSelectedService] = useState<any>(null);
    let [subData, setSubData] = useState<ServiceResponse[]>([]);
    let [isLoading, setIsLoading] = useState<boolean>();
    const [open, setOpen] = useState<boolean>(false);

    const refreshData = (id: number) => {
        setIsLoading(true)
        cityService.getAllServiceOfferings({
            referenceId: id,
            referenceModelName: ReferenceModelNameEnums.CITY,
        }).then((res) => {
            console.log(res);
            setSubData(res);
            setIsLoading(false);
        }).catch((err) => {
                setIsLoading(false);
        })
    }

    const changeServiceStatus = (serviceId: number, isServing: boolean) => {
        const payload = {
            referenceId: record?.id,
            referenceModelName: ReferenceModelNameEnums.CITY,
            serviceId,
            isServing,
        };
        cityService.createServiceForSlc(payload).then(() => {
            refreshData(record?.id);
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Updated Successfully",
                type: CustomSnackbarTypes.SUCCESS,
            });
        });
    };

    useEffect(() => {
        if(!record?.id) return;
        refreshData(record?.id);
    }, [record?.id]);

    useEffect(() => {
        cityService.getAllServices().then((res) => {
            setAllServices(res);
        });
    }, []);

    return (
        <div>
            <Box display={'flex'} justifyContent={'flex-end'} my={3}>
                <Button variant={'contained'} disabled={!GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN])} onClick={() => {
                    setOpen(true);
                }}>
                    {'Add New Service'}
                </Button>
            </Box>
            {isLoading ?
            <CircularLoader/>
            :
            <div>
                {subData && subData?.length > 0 ? (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell>SLC Name</TableCell>
                                    <TableCell>Service Name</TableCell>
                                    <TableCell align={'center'}>Service Status</TableCell>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subData?.map((row: ServiceResponse, index) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell scope="row">{index + 1}</TableCell>
                                        <TableCell>{row?.slcName}</TableCell>
                                        <TableCell>{row?.serviceName}</TableCell>
                                        <TableCell align={'center'} sx={{
                                            backgroundColor: row?.isServing ? '#90EE90' : '#e53935',
                                        }}>{row?.isServing ? 'YES' : 'No'}</TableCell>
                                        <TableCell align={'center'}>
                                            <Button
                                                color={row?.isServing ? 'error' : 'success'}
                                                variant={'contained'}
                                                disabled={!GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN])}
                                                onClick={() => {
                                                    EventBus.emitEvent(
                                                        EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG,
                                                        {
                                                            title: "Are you sure?",
                                                            message: "Please confirm to change the service status",
                                                            successLabel: "Confirm",
                                                            failureLabel: "Cancel",
                                                            onClose: (confirmed: boolean) => {
                                                                if (confirmed) {
                                                                    changeServiceStatus(row?.serviceId, !row?.isServing);
                                                                }
                                                            }
                                                        }
                                                    );
                                                }}
                                            >
                                                {row?.isServing ? 'Mark Not Serviceable' : 'Mark Serviceable'}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                   ) : (
                    <EmptyListPlaceholder
                        message='No Services found'
                    />
                )}
            </div>}
            <Dialog
                onClose={() => {setOpen(false)}}
                open={open}
                scroll={"paper"}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Add New Service</DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        spacing={2}
                        direction="column"
                        justifyContent={"space-evenly"}
                        style={{ padding: "12px 12px 12px 12px" }}
                    >
                        <Grid item>
                            <FormControl fullWidth >
                                <InputLabel id="for_slot">Select Service</InputLabel>
                                <Select
                                    labelId="for_slot"
                                    id="Select Service"
                                    label="Select Service"
                                    value={selectedService}
                                    onChange={(event) => setSelectedService(event.target.value)}
                                >
                                    {allServices.map((e: any) => (
                                        <MenuItem key={e.name} value={e}>
                                            {e.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ marginTop: "12px" }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            if(!selectedService){
                                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                    message: "Select to proceed",
                                    type: CustomSnackbarTypes.WARNING,
                                });
                                return;
                            }
                            const payload = {
                                referenceId: record?.id,
                                referenceModelName: ReferenceModelNameEnums.CITY,
                                serviceId: selectedService?.id,
                                isServing: true,
                            };
                            cityService.createServiceForSlc(payload).then(() => {
                                setOpen(false);
                                refreshData(record?.id);
                                setSelectedService(null);
                                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                    message: "Added Successfully",
                                    type: CustomSnackbarTypes.SUCCESS,
                                });
                            });
                        }}
                        size={"large"}
                    >
                        Add
                    </Button>
                    <Button
                        variant="contained"
                        color={"error"}
                        onClick={() => {
                            setOpen(false);
                        }}
                        size={"large"}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CityServicesTab;
