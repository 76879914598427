import React, { useEffect, useState } from "react";
import { useRefresh } from "react-admin";
import { TrialsService } from "../../services/TrialServices";
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import TrialsVendorPayoutMetaShow from "./TrialsVendorPayoutMetaShow";

const trialService = new TrialsService();

interface InputProps {
  trialsId: number;
}

const TrialsVendorPayoutTab = (props: InputProps) => {
  let [subData, setSubData] = useState<any>(null);
  let [isLoading, setIsLoading] = useState<boolean>();
  const refresh = useRefresh();

  const refreshData = (trialId: number) => {
    setIsLoading(true);
    trialService
      .trialVendorPayout(trialId)
      .then((data) => {
        setSubData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    refreshData(props.trialsId);
  }, [props.trialsId]);

  return (
    <div>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <div>
          {subData &&
            Array.isArray(subData) &&
            (subData.length > 0 ? (
              <TrialsVendorPayoutMetaShow data={subData} updateParentData={refresh}/>
            ) : (
              <EmptyListPlaceholder message="No VendorPayout Data Found" />
            ))}
        </div>
      )}
    </div>
  );
};

export default TrialsVendorPayoutTab;
