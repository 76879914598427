import React from 'react'
import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useRefresh } from 'react-admin';
import ModeOfTravel from '../../enums/ModeOfTravelEnum';
import { OneMealService } from '../../services/OneMealService';

interface InputProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    data: any;
}

const RequestCookConveyanceDialog = (props: InputProps) => {
    const [conveyanceAmount, setConveyanceAmount] = React.useState<number>();
    const [modeOfTravel, setModeOfTravel] = React.useState<string>("");
    const refresh = useRefresh();
    const oneMealService = new OneMealService();

    const handleSubmit = () => {
        const payload = {
            conveyanceAmount: conveyanceAmount,
            modeOfTravel: modeOfTravel,
            conveyanceProofScreenShots: props?.data?.conveyanceProofScreenShots,
        }
        oneMealService.requestCookConveyance(parseInt(props?.data?.id), payload)
            .then((res) => {
                refresh();
                props?.setOpen(false);
            }).catch((err) => {
            })
    }
    return (
        <Dialog open={props?.open} onClose={() => props?.setOpen(false)} scroll={"paper"} maxWidth="xs" fullWidth>
            <DialogTitle>Request Cook Conveyance</DialogTitle>
            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <TextField label={'Enter Conveyance Amount'} fullWidth type='number'
                    onChange={(event) => {setConveyanceAmount(parseInt(event.target.value))}}
                />
                <FormControl variant='filled' fullWidth>
                    <InputLabel id="mode-of-travel">Mode of Travel</InputLabel>
                    <Select
                        labelId='mode-of-travel'
                        id='mode-of-travel-select'
                        label={'Mode of Travel'}
                        defaultValue={''}
                        onChange={(event: any) => {
                            setModeOfTravel(event.target.value);
                        }}
                        fullWidth
                    >
                        {Object.values(ModeOfTravel)?.map(key => (
                            <MenuItem key={key} value={key}>
                                {key}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} gap={'15px'}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            handleSubmit();
                        }}
                        size={"large"}
                    >
                        Submit
                    </Button>
                    <Button
                        variant="contained"
                        color={"error"}
                        onClick={() => {
                            props?.setOpen(false);
                        }}
                        size={"large"}
                    >
                        Cancel
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default RequestCookConveyanceDialog