import {FormControl, InputLabel, MenuItem, Grid, Select, TextField, Button, Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import moment from "moment";
import {useEffect, useState} from "react";
import {
    BundleGenderPreference,
    BundlePlan, DynamicPricingSlotEnum,
} from "../../enums/common";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {ShortTimeEnum} from "../../enums/ShortTimeEnum";
import EventBus from "../../EventBus";
import {MealMappingInput} from "../Common/MealMappingInput";
import {DatePicker} from "@mui/x-date-pickers";
import {useLocation} from "react-router-dom";
import {TrialsService} from "../../services/TrialServices";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";
import {Edit} from "react-admin";
import Box from "@mui/material/Box";
import TransactionButton from "../Common/TransactionButton";
import { TrialsV3Status } from "../../enums/TrialsV3Status";

const trialsService = new TrialsService();

export const TrialsEdit = () => {
    const [slotName, setSlotName] = useState<string>("")
    const [houseId, setHouseId] = useState<any>(null)
    const [areaId, setAreaId] = useState<any>(null)
    const [time, setTime] = useState<string>("")
    const [genderPreference, setGenderPreference] = useState<string>("")
    const [date, setDate] = useState<any>(null)
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [totalPeople, setTotalPeople] = useState<number>(0)
    const [bundlePlan, setBundlePlan] = useState<string>("")
    const [numberOfVisit, setNumberOfVisit] = useState<number>(1)
    const [mealMapping, setMealMapping] = useState<any>([])
    const [includeFreeTr, setIncludeFreeTr] = useState(true);
    const [status, setStatus] = useState<TrialsV3Status | null>(null);
    const location = useLocation();
    const trialId = location.pathname.split('/')[2]

    const changePriceToDefaultVal = (defaultPrice: number = 0) => {
        if(totalPrice === 0) return;
        setTotalPrice(defaultPrice)
    };

    let setVisitTime = (myIndex: number, data: any) => {
        setMealMapping(mealMapping.map((ele: any, index: number) => {
            if (index === myIndex) {
                return {...ele, time: data}
            }
            return ele;
        }));
        changePriceToDefaultVal();
    }

    let setVisitSlot = (myIndex: number, data: any) => {
        setMealMapping(mealMapping.map((ele: any, index: number) => {
            if (index === myIndex) {
                return {...ele, slot: data}
            }
            return ele;
        }));
        changePriceToDefaultVal();
    }

    let setBufferTime = (myIndex: number, data: any) => {
        setMealMapping(mealMapping.map((ele: any, index: number) => {
            if (index === myIndex) {
                return {...ele, bufferMinutes: Number(data)}
            }
            return ele;
        }));
        changePriceToDefaultVal();
    }
    const MealMappingValidation = (mealMapping: any, numberOfVisit: number) => {
        if (mealMapping.length === numberOfVisit) {
            for (const obj of mealMapping) {
                if (Object.keys(obj).length !== 3) {
                    return true
                }
            }
        }
        return false
    }

    const validate = () => {
        if(!houseId){
            showError('Please select a house');
            return false;
        } else if(bundlePlan === ''){
            showError('Please select a plan');
            return false;
        } else if(slotName === ''){
            showError('Please select a slot');
            return false;
        } else if(!date){
            showError('Please select a date');
            return false;
        } else if(time === ''){
            showError('Please select a time');
            return false;
        } else if(genderPreference === ''){
            showError('Please select a gender preference');
            return false;
        } else if(totalPeople === 0){
            showError('Please select total people');
            return false;
        } else if(numberOfVisit === 0){
            showError('Please select the total number Of Visit');
            return false;
        } else if (MealMappingValidation(mealMapping, numberOfVisit)) {
            showError('Please fill the meal mapping details');
            return false;
        }
        return true;
    };

    const calculatePrice = () => {
        if(
            areaId &&
            bundlePlan !== '' &&
            slotName !== '' &&
            date &&
            time !== '' &&
            genderPreference !== '' &&
            totalPeople !== 0 &&
            numberOfVisit !== 0 &&
            !MealMappingValidation(mealMapping, numberOfVisit)
        ) {
            trialsService.calculateDynamicPriceByAreaId({
                areaId,
                totalPeople,
                plan: bundlePlan,
                visitsPerDay: numberOfVisit,
                "utensils": false,
                mealMapping,
                includeFreeTr
            }).then((res) => {
                if(res?.price){
                    setTotalPrice(res.price);
                }
            })
        }
    };

    const showError = (title: string) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: title || "Fill the form to continue",
            type: CustomSnackbarTypes.ERROR,
        });
    }

    useEffect(() => {
        trialsService.getTrialsById(Number(trialId)).then((res) => {
            setHouseId(res.data.houseId)
            setAreaId(res.data.areaId)
            setSlotName(res.data.slotName);
            setTime(res.data.time)
            setGenderPreference(res.data.genderPreference)
            setDate(res.data.date);
            setTotalPrice(res.data.totalPrice);
            setTotalPeople(res.data.totalPeople);
            setNumberOfVisit(res.data.mealMapping.length);
            setBundlePlan(res.data.bundlePlan);
            setMealMapping(res.data.mealMapping);
            setStatus(res.data.status);
        })
    }, [trialId])
    return (
        <Edit>
            <Grid
                container
                columns={1}
                rowSpacing={3.5}
                style={{padding: "12px 12px 12px 12px"}}
            >
                <Grid item xs={4}>
                    <FormControl>
                        <DatePicker
                            disabled={status === TrialsV3Status.FULFILLED}
                            label="Date"
                            inputFormat="dd MMM yyyy"
                            disableMaskedInput
                            minDate={new Date()}
                            value={date}
                            onChange={(value: any) => {
                                setDate(moment(value).format("YYYY-MM-DD"));
                                changePriceToDefaultVal();
                            }}
                            renderInput={(params: any) => <TextField {...params} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{width: "230px"}}>
                        <InputLabel id="forGenderPreference">Gender Preference</InputLabel>
                        <Select
                            disabled={status === TrialsV3Status.FULFILLED}
                            labelId="forGenderPreference"
                            id="genderPreference"
                            value={genderPreference}
                            label="genderPreference"
                            onChange={(event: any) => {
                                setGenderPreference(event.target.value);
                                changePriceToDefaultVal();
                            }}
                        >
                            {Object.values(BundleGenderPreference).map((e) => (
                                <MenuItem key={e} value={e}>
                                    {e}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{width: "230px"}}>
                        <InputLabel id="forTotalPeople">Total People</InputLabel>
                        <Select
                            labelId="forTotalPeople"
                            id="totalPeople"
                            value={totalPeople}
                            label="totalPeople"
                            onChange={(event: any) => {
                                setTotalPeople(event.target.value);
                                changePriceToDefaultVal();
                            }}
                        >
                            {Object.values([1, 2, 3, 4, 5, 6]).map((e) => (
                                <MenuItem key={e} value={e}>
                                    {e}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{width: "230px"}}>
                        <InputLabel id="forBundlePlan">Plan</InputLabel>
                        <Select
                            labelId="forBundlePlan"
                            id="bundlePlan"
                            value={bundlePlan}
                            label="Bundle Plan"
                            onChange={(event: any) => {
                                setBundlePlan(event.target.value);
                                changePriceToDefaultVal();
                            }}
                        >
                            {Object.values(BundlePlan).map((e) => (
                                <MenuItem key={e} value={e}>
                                    {e}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{width: "230px"}}>
                        <InputLabel id="NumberOfVisit">Number Of Visits</InputLabel>
                        <Select
                            labelId="NumberOfVisit"
                            id="numberOfVisit"
                            label="NumberOfVisits"
                            value={numberOfVisit}
                            onChange={(event: any) => {
                                let numberOfVisit = event.target.value;
                                let mealMapping = [];
                                for (let i = 0; i < numberOfVisit; i++) {
                                    mealMapping.push({ bufferMinutes: 30 });
                                }
                                setMealMapping(mealMapping);
                                setNumberOfVisit(event.target.value);
                                changePriceToDefaultVal();
                            }}
                        >
                            {Object.values([1, 2]).map((value) => (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <div style={{width: "600px"}}>
                        {
                            !!mealMapping &&
                            Array.isArray(mealMapping) &&
                            mealMapping.length > 0 &&
                            mealMapping.map((value, index) => (
                                <MealMappingInput
                                    key={index}
                                    data={value}
                                    setVisitTime={setVisitTime}
                                    setVisitSlot={setVisitSlot}
                                    setBufferTime={setBufferTime}
                                    index={index}
                                    isDynamicPricing={true}
                                    disabled={{
                                        bufferTime: true,
                                    }}
                                />
                            ))
                        }
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{width: "230px"}}>
                        <InputLabel id="forSlot">Select start Slot</InputLabel>
                        <Select
                            labelId="forSlot"
                            id="slot"
                            value={slotName}
                            label="Slot"
                            onChange={(event: any) => {
                                setSlotName(event.target.value);
                                changePriceToDefaultVal();
                            }}
                        >
                            {Object.values(DynamicPricingSlotEnum).map((e) => (
                                <MenuItem key={e} value={e}>
                                    {e}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{width: "230px"}}>
                        <InputLabel id="forTime">Time</InputLabel>
                        <Select
                            disabled={status === TrialsV3Status.FULFILLED}
                            labelId="forTime"
                            id="time"
                            value={time}
                            label="time"
                            onChange={(event: any) => {
                                setTime(event.target.value);
                                changePriceToDefaultVal();
                            }}
                        >
                            {Object.values(ShortTimeEnum).map((e) => (
                                <MenuItem key={e} value={e}>
                                    {e}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormGroup>
                        <FormControlLabel
                            onChange={(event: any, checked: boolean) => {
                            setIncludeFreeTr(checked);
                            }}
                            disabled
                            control={<Checkbox checked={includeFreeTr} />}
                            label="Include Free TR?"
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={4}>
                    <Box display={'flex'} alignItems={'center'}>
                        <FormControl style={{width: "230px"}}>
                            <TextField
                                label="Total Price"
                                id="totalPrice"
                                value={totalPrice}
                                disabled={true}
                                type="number"
                                InputProps={{inputProps: {min: 0}}}
                                onChange={(event: any) => setTotalPrice(event.target.value)}
                            />
                        </FormControl>
                        <TransactionButton
                            name={"Calculate Price"}
                            onClickHandler={() => {
                                if(validate()){
                                    calculatePrice();
                                }
                            }}
                            style={{marginLeft: '30px'}}
                        />
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (validate()) {
                                if(totalPrice === 0){
                                    showError('Please calculate the price');
                                    return;
                                }
                                trialsService
                                    .editDynamicPricingTrialsById(Number(trialId), {
                                        houseId,
                                        genderPreference,
                                        totalPrice: Number(totalPrice),
                                        totalPeople,
                                        slotName,
                                        time,
                                        bundlePlan,
                                        mealMapping,
                                        date: moment(date).format("YYYY-MM-DD")
                                    })
                                    .then(() => {
                                        window.location.href = `#/Trials/${trialId}/show`
                                    });
                            }
                        }}
                        size={"large"}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Edit>
    )

}
