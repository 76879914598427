const productionFlags = {
    "AdminUsers": true,
    "Cooks": true,
    "Customers": true,
    "Cities": true,
    "Areas": true,
    "Localities": true,
    "Houses": true,
    "HouseReviews": true,
    "Vendors": true,
    "Vas": false,
    "VasTransactions": false,
    "UserBundleServiceMappings": true,
    "Bundles": true,
    "UserBundles": true,
    "ChefForParty": true,
    "CustomerLeaves": true,
    "TemporaryReplacement": true,
    "Extension": true,
    "CookLeaves": true,
    "Transactions": false,
    "Coupons": true,
    "Trials": true,
    "CookSlots": true,
    "PermanentReplacement": true,
    "Combo": true,
    "SMS": false,
    "VendorPayouts": true,
    "TrialsConverted": true,
    "StarChefInventory": false,
    "StarChefVisit": false,
    "Renewals": true,
    "PriceCalculator": true,
    "Refund": true,
    "EmployeeRoster": true,
    "DailyCookSlot": true,
    "VisitTracker": true,
    "CouponsV2": true,
    "OneMealService": true,
    "StarCleaner": true,
    "StarCleanerInventory": true,
    "Hubs":true,
    "MgAllocation":true,
    "Platform": true,
    "CooksServeCalendar": true,
    "CreateField": true,
    "BulkUploadLeads": true,
}

const stagingFlags = {
    "AdminUsers": true,
    "Cooks": true,
    "Customers": true,
    "Cities": true,
    "Areas": true,
    "Localities": true,
    "Houses": true,
    "HouseReviews": true,
    "Vendors": true,
    "Vas": false,
    "VasTransactions": false,
    "UserBundleServiceMappings": true,
    "Bundles": true,
    "UserBundles": true,
    "ChefForParty": true,
    "CustomerLeaves": true,
    "TemporaryReplacement": true,
    "Extension": true,
    "CookLeaves": true,
    "Transactions": false,
    "Coupons": true,
    "Trials": true,
    "CookSlots": true,
    "PermanentReplacement": true,
    "Combo": true,
    "SMS": false,
    "VendorPayouts": true,
    "TrialsConverted": true,
    "StarChefInventory": false,
    "StarChefVisit": false,
    "Renewals": true,
    "PriceCalculator": true,
    "Refund": true,
    "EmployeeRoster": true,
    "DailyCookSlot": true,
    "VisitTracker": true,
    "CouponsV2": true,
    "OneMealService": true,
    "StarCleaner": true,
    "StarCleanerInventory": true,
    "Hubs":true,
    "MgAllocation":true,
    "Platform": true,
    "CooksServeCalendar": true,
    "CreateField": true,
    "BulkUploadLeads": true,
}

export const FeatureFlags = process.env.REACT_APP_ENV === 'production' ? productionFlags : stagingFlags;
