import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CookService } from "../../services/CookServices";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import Radio from '@mui/material/Radio';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import { AssessmentLanguages } from "../../enums/AssessmentLanguages";

const cookService = new CookService();

const SkillAssessment = () => {
  const [selectedData, setSelectedData] = useState<any>([]);
  const [questions, setQuestions] = useState<any>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [language, setLanguage] = useState<AssessmentLanguages>(AssessmentLanguages.ENGLISH);

  const handleSubmit = () => {
    const cookId = searchParams.get("cookId");
    const topicId = searchParams.get("topicId");

    let payload: any = {
      topicProgressId: Number(topicId),
      questionProgress: selectedData,
    };
    cookService
      .updateAssessment(payload)
      .then(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Assignment Submitted Successfully",
          type: CustomSnackbarTypes.SUCCESS,
        });
        navigate(`/Cooks/${cookId}/show`);
      });
  };

  const handleCheckboxChange = (
    optionId: number,
    questionProgressId: number,
    checked: boolean
  ) => {
    // Find the index of the current question progress in selectedData
    const questionIndex = selectedData.findIndex(
      (data: any) => data.questionProgressId === questionProgressId
    );
    // If the question progress is not present in selectedData, create a new entry
    if (questionIndex === -1) {
      const newQuestionData = {
        questionProgressId,
        optionIds: checked ? [optionId] : [],
      };
      setSelectedData((prevSelectedData: any) => [...prevSelectedData, newQuestionData]);
    } else {
      // If the question progress is already present, update the optionIds
      setSelectedData((prevSelectedData: any) => {
        const updatedData = [...prevSelectedData];
        const questionData = updatedData[questionIndex];
        if (checked) {
          // If the checkbox is checked, add the optionId to the array
          questionData.optionIds = [...questionData.optionIds, optionId];
        } else {
          // If the checkbox is unchecked, remove the optionId from the array
          questionData.optionIds = questionData.optionIds.filter((id: number) => id !== optionId);
        }
        return updatedData;
      });
    }
  };

  const handleRadioChange = (
    optionId: number,
    questionProgressId: number,
    checked: boolean
  ) => {
    // Find the index of the current question progress in selectedData
    const questionIndex = selectedData.findIndex(
      (data: any) => data.questionProgressId === questionProgressId
    );
    // If the question progress is not present in selectedData, create a new entry
    if (questionIndex === -1) {
      const newQuestionData = {
        questionProgressId,
        optionIds: [optionId],
      };
      setSelectedData((prevSelectedData: any) => [...prevSelectedData, newQuestionData]);
    } else {
      // If the question progress is already present, update the optionIds
      setSelectedData((prevSelectedData: any) => {
        const updatedData = [...prevSelectedData];
        const questionData = updatedData[questionIndex];
        // Update the optionIds with the selected radio option
        questionData.optionIds = [optionId];
        return updatedData;
      });
    }
  };

  useEffect(() => {
    const topicId = searchParams.get("topicId");
    cookService.getQuestionOfTopicProgressId(Number(topicId))
      .then((res: any) => {
        console.log('-->', res)
        setQuestions(res);
      })
  }, [searchParams]);

  if (!questions || questions.length <= 0) {
    return null;
  }

  return (
    <>
      <Grid
        sx={{ margin: "2rem" }}
        justifyContent="space-around"
        alignItems="stretch"
      >
        <Box sx={{ minWidth: 120,maxWidth:"20%" }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Language</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            label="Age"
            onChange={(e) => setLanguage(e.target.value as AssessmentLanguages)}
          >
            {
              Object.keys(AssessmentLanguages).map(lang => (
                <MenuItem key={lang} value={lang}>{lang}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Box>
        {questions?.questionProgress?.map((questionData: any, questionIndex: number) => (
          <Grid key={questionData?.id} item xs={12} sm={6}>
            <div style={{ margin: "2rem" }}>
              {
                language === AssessmentLanguages.ENGLISH && <div
                  style={{ fontWeight: "bold", fontSize: "1.1rem" }}
                >{`Q${questionIndex + 1}: ${questionData?.question?.localEn}`}</div>
              }
              {
                language === AssessmentLanguages.HINDI && <div
                style={{ fontWeight: "bold", fontSize: "1.1rem" }}
              >{`Q${questionIndex + 1}: ${questionData?.question?.localHi}`}</div>
              }
              {
              language === AssessmentLanguages.BENGALI&& <div
                  style={{ fontWeight: "bold", fontSize: "1.1rem" }}
                >{`Q${questionIndex + 1}: ${questionData?.question?.localBn}`}</div>

              }
              <div>
              </div>
              {questionData?.question?.type === "MULTIPLE-CHOICES" ? (
                // Render for MULTIPLE-CHOICES
                <FormGroup>
                  {questionData?.question?.options.map((option: any) => (
                    <FormControlLabel
                      key={option.id}
                      control={
                        <Checkbox
                          disabled={false}
                          checked={selectedData?.some(
                            (data: any) =>
                              data?.questionProgressId === questionData?.id &&
                              data?.optionIds?.includes(option?.id)
                          )}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const optionId = option.id;
                            const questionProgressId = questionData?.id;

                            handleCheckboxChange(optionId, questionProgressId, event.target.checked);
                          }}
                          name={`question_${option?.id}`}
                          value={option.id}
                        />
                      }
                      label={language===AssessmentLanguages.ENGLISH?option?.localEn:language===AssessmentLanguages.BENGALI?option?.localBn:option?.localHi}
                    />
                  ))}
                </FormGroup>
              ) : (
                // Render for SINGLE-CHOICE
                <RadioGroup>
                  {questionData?.question?.options.map((option: any) => (
                    <FormControlLabel
                      key={option.id}
                      control={
                        <Radio
                          disabled={false}
                          checked={selectedData?.some(
                            (data: any) =>
                              data?.questionProgressId === questionData?.id &&
                              data?.optionIds.includes(option?.id)
                          )}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const optionId = option.id;
                            const questionProgressId = questionData?.id;

                            handleRadioChange(optionId, questionProgressId, event.target.checked);
                          }}
                          name={`question_${option?.id}`}
                          value={option.id}
                        />
                      }
                      label={language===AssessmentLanguages.ENGLISH?option.localEn:language===AssessmentLanguages.BENGALI?option.localBn:option.localHi}
                    />
                  ))}
                </RadioGroup>
              )}
            </div>
          </Grid>
        ))}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{ textAlign: "end" }}
            onClick={handleSubmit}
            variant="contained"
          >
            Submit
          </Button>
        </div>
      </Grid>
    </>
  );
};

export default SkillAssessment;
