import Kalend, { CalendarView } from "kalend"; // import component
import "kalend/dist/styles/index.css"; // import styles
import React, { useCallback, useEffect, useState } from "react";
import { TempReplacementService } from "../../services/TemporaryReplacement";
import moment from "moment";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Authenticated } from "ra-core";
import { KalendColors } from "../../constants/KalendColors";
import { TimeTableItem } from "../../interfaces/TrTimeTable/TrTimeTable";
import { TemporaryReplacementResponse } from "../../interfaces/TemporaryReplacement/TemporaryReplacementResponse";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { DatePicker } from "@mui/x-date-pickers";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { UserBundleMappingStatus } from "../../enums/UserBundleMappingStatus";
import MultipleTemporaryReplacement from "../TemporaryReplacement/MultipleTemporaryReplacement";
import { CityEnum, CityIdMapping } from "../../enums/CityEnum";

const temporaryReplacement = new TempReplacementService();
const currentDate = localStorage.getItem('trTimeTableDateFilter') ? moment(localStorage.getItem('trTimeTableDateFilter')).toDate() : moment().toDate()

const TrTimeTable = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(currentDate);
  const [dialog, setDialog] = useState<boolean>(false);
  const [details, setDetails] = useState<any>();
  const [cookTrMapping, setCookTrMapping] = useState<any>({});
  const [multipleTrAllocateDialog, setMultipleTrAllocateDialog] = useState<boolean>(false);
  const [selectCityId, setSelectCityId] = useState<number | null>(null);

  const getAllCook = useCallback(
    (cityId?: number | null, start?: Date) => {
      setCookTrMapping({});
      setLoading(true);
      let startDate = start
        ? moment(start).format("YYYY-MM-DD")
        : moment(date).format("YYYY-MM-DD");
      let filters : any = {startDate, endDate: startDate}
      if(cityId){
        filters.cityId = cityId
      }
      temporaryReplacement
        .getAllTempReplacement(1000, 0, filters, {})
        .then((res) => {
          setLoading(true);
          const uniqueCook = new Set();
          let tempCookTrMapping: any = {};
          res.data.forEach((obj: TemporaryReplacementResponse) => {
            if (obj.status !== "CANCELLED" && obj.status !== "FAILED") {
              let requiredDetails: TimeTableItem = {
                summary: "",
                id: NaN,
                startAt: "",
                endAt: "",
                color: "",
                trDetails: {},
              };
              requiredDetails.trDetails = obj;
              let eventSummary = obj.cook
                ? `${obj?.cook?.fname} ${obj.cook?.lname}`
                : "Pending";
              requiredDetails.summary = `${eventSummary} ${
                obj.userBundleMapping.house.address
                  ? obj.userBundleMapping.house.address
                  : ""
              }`;
              uniqueCook.add(obj.cook && obj?.cook.id);
              requiredDetails.id = obj?.id;
              requiredDetails.startAt = moment(
                obj.date + "T" + moment(obj.time, "h:mm A").format("HH:mm")
              ).format();
              requiredDetails.endAt = moment(requiredDetails.startAt)
                .add(1.5, "hours")
                .format();
              let index = obj.cook
                ? Array.from(uniqueCook).indexOf(obj?.cook.id) %
                  Array.from(uniqueCook).length
                : -1;
              requiredDetails.color =
                index === -1 ? "black" : KalendColors[index];
              if (obj.cook !== null) {
                if (tempCookTrMapping[obj.cookId]) {
                  tempCookTrMapping[obj.cookId].push(requiredDetails);
                } else {
                  tempCookTrMapping[obj.cookId] = [requiredDetails];
                }
              } else {
                if (tempCookTrMapping[0]) {
                  tempCookTrMapping[0].push(requiredDetails);
                } else {
                  tempCookTrMapping[0] = [requiredDetails];
                }
              }
            }
          });
          setCookTrMapping({ ...tempCookTrMapping });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    },
    [date]
  );

  const DialogDetails = (props: any) => {
    let details = props?.data?.trDetails;
    return (
      <div>
        <Dialog
          onClose={props.handleClose}
          open={props.open}
          scroll={"paper"}
          maxWidth="xl"
        >
          <DialogTitle>
            <b>TR Details</b>
          </DialogTitle>
          <DialogContent
            style={{ display: "flex", flexDirection: "column", width: "400px" }}
          >
            {details && details.cook && (
              <div>
                <div>
                  <b style={{ minWidth: "170px", display: "inline-block" }}>
                    CookId:
                  </b>
                  <span>{details?.cook.id}</span>
                </div>
                <div>
                  <b style={{ minWidth: "170px", display: "inline-block" }}>
                    Cook Name:
                  </b>
                  <span>{details?.cook.fname + " " + details?.cook.lname}</span>
                </div>
                <div>
                  <b style={{ minWidth: "170px", display: "inline-block" }}>
                    Cook CK_ID:
                  </b>
                  <span>{details?.cook.zohoCookId}</span>
                </div>
                <div>
                  <b style={{ minWidth: "170px", display: "inline-block" }}>
                    Cook PhoneNo:
                  </b>
                  <span>{details?.cook.mobile}</span>
                </div>
              </div>
            )}
            <div>
              <b style={{ minWidth: "170px", display: "inline-block" }}>
                Customer Name:
              </b>
              <span>
                {details?.userBundleMapping?.house?.customers[0].fname +
                  " " +
                  details?.userBundleMapping?.house?.customers[0].lname}
              </span>
            </div>
            <div>
              <b style={{ minWidth: "170px", display: "inline-block" }}>
                Customer PhoneNo:
              </b>
              <span>
                {details?.userBundleMapping?.house?.customers[0].mobile}
              </span>
            </div>
            <div>
              <b style={{ minWidth: "170px", display: "inline-block" }}>
                TR Date:
              </b>
              <span>{moment(details?.date).format("DD MMM YYYY")}</span>
            </div>
            <div>
              <b style={{ minWidth: "170px", display: "inline-block" }}>
                TR Time:
              </b>
              <span>
                {moment(details?.time, "h:mm A").format("hh:mm A") +
                  "-" +
                  moment(details?.time, "h:mm A")
                    .add(1.5, "hours")
                    .format("hh:mm A")}
              </span>
            </div>
            <div>
              <b style={{ minWidth: "170px", display: "inline-block" }}>
                Address:
              </b>
              <span>{details?.userBundleMapping?.house.address+" "}</span>
              <span>{details?.userBundleMapping?.house.city+" "}</span>
              <span>{details?.userBundleMapping?.house.locality}</span>
            </div>
          </DialogContent>
          <DialogActions style={{ marginTop: "12px" }}>
            {
              details?.cookId &&
              details?.status === UserBundleMappingStatus.PENDING &&
              <Button
                variant="contained"
                onClick={() => {
                  setMultipleTrAllocateDialog(true);
                  props.handleClose(false);
                }}
                size={"large"}
              >
                Assign Multiple TR
              </Button>
            }
            <Button
              variant="contained"
              color={"error"}
              onClick={() => {
                props.handleClose(false);
              }}
              size={"large"}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const onEventClick = (data: any) => {
    setDialog(true);
    setDetails(data);
  };

  useEffect(() => {
    getAllCook(selectCityId, date);
  }, [date, getAllCook, selectCityId]);

  return (
    <Authenticated>
      <>
        <MultipleTemporaryReplacement
          data={details?.trDetails}
          multipleTrAllocateDialog={multipleTrAllocateDialog}
          setMultipleTrAllocateDialog={setMultipleTrAllocateDialog}
        />
        <DialogDetails
          open={dialog}
          handleClose={() => setDialog(false)}
          data={details}
        />
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "200%",
              minWidth: "0",
              overflowX: "scroll",
            }}
          >
            <div style={{ marginTop: "15px", margin: "15px" }}>
              <form>
              <FormControl sx={{ minWidth: 140 }}>
                  <InputLabel id="for_Type">City</InputLabel>
                  <Select
                    labelId="for_Type"
                    id="Type"
                    label="City"
                    value={selectCityId ? Object.values(CityEnum)[selectCityId - 1] : "ALL CITY"}
                    onChange={(event) => {
                      let value: any = event.target.value
                      let cityId: number = CityIdMapping[value]
                      setSelectCityId(cityId || null)
                    }}
                  >
                   <MenuItem key={"ALL CITY"} value={"ALL CITY"}>
                        {"ALL CITY"}
                    </MenuItem>
                    {Object.values(CityEnum).map((e) => (
                      <MenuItem key={e} value={e}>
                        {e}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <ArrowBackIosIcon
                  style={{ padding: "15px" }}
                  onClick={() =>
                    setDate((e) => moment(e).subtract(1, "d").toDate())
                  }
                />
                <DatePicker
                  mask=""
                  label="Select Date"
                  inputFormat="dd MMM yyyy"
                  value={date}
                  onChange={(newValue: any) => {
                    setDate(newValue);
                  }}
                  renderInput={(params) => {
                    return <TextField {...params} />;
                  }}
                  onAccept={
                    (newValue: any) => {
                      localStorage.setItem("trTimeTableDateFilter", moment(newValue).toString());
                    }
                  }
                />
                <ArrowForwardIosIcon
                  style={{ padding: "15px" }}
                  onClick={() => setDate((e) => moment(e).add(1, "d").toDate())}
                />
                <Button sx={{marginLeft: "0.5rem"}} size="small" variant="outlined" onClick={() => {
                  setSelectCityId(0);
                  setDate(new Date());
                }}
              >
                Reset
              </Button>
              </form>
            </div>
            <div
              style={{ display: "flex", height: "100%", overflow: "scroll" }}
            >
              {Object.keys(cookTrMapping).length !== 0 &&
                Object.entries(cookTrMapping).map(([key, value]: any) => (
                  <div style={{ textAlign: "center" }} key={key}>
                    <h5>
                      {key === "0"
                        ? "Pending TR"
                        : `${value[0].trDetails.cook.fname} ${value[0].trDetails.cook.zohoCookId}`}
                    </h5>
                    <Kalend
                      kalendRef={props.kalendRef}
                      initialView={CalendarView.DAY}
                      disabledViews={[
                        CalendarView.AGENDA,
                        CalendarView.THREE_DAYS,
                        CalendarView.MONTH,
                        CalendarView.WEEK,
                      ]}
                      onEventClick={onEventClick}
                      events={value}
                      initialDate={moment(date).format("YYYY-MM-DD")}
                      hourHeight={100}
                      disabledDragging={true}
                      onStateChange={props.onStateChange}
                      selectedView={props.selectedView}
                      showTimeLine={true}
                      isDark={false}
                      autoScroll={false}
                    />
                  </div>
                ))}
              {Object.keys(cookTrMapping).length === 0 && (
                <Kalend
                  kalendRef={props.kalendRef}
                  initialView={CalendarView.DAY}
                  initialDate={moment(date).format("YYYY-MM-DD")}
                  disabledViews={[
                    CalendarView.AGENDA,
                    CalendarView.THREE_DAYS,
                    CalendarView.MONTH,
                    CalendarView.WEEK,
                  ]}
                  onEventClick={onEventClick}
                  hourHeight={60}
                  disabledDragging={true}
                  onStateChange={props.onStateChange}
                  selectedView={props.selectedView}
                  showTimeLine={true}
                  isDark={false}
                  autoScroll={false}
                />
              )}
            </div>
          </div>
        )}
      </>
    </Authenticated>
  );
};
export default TrTimeTable;
