import { PartnerLineChart } from "./PartnerLineChart";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import { TableData } from "../../enums/TableData";
import { ZohoBaseUrl, ZohoModulesUrl } from "../../enums/zoho";
import { Card, Link as MUILink} from "@mui/material";
import CallButton from "../Common/CallButton/CallButton";
import { UserType } from "../../enums/common";

export const PartnerTimeSlotTable = (props: any) => {
  return (
    <div>
      <Card style={{overflow: 'auto', marginTop: "10px"}}>
      <TableContainer sx={{ width: "100vw" }}>
        <Table sx={{ width: "max-content" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {
                Object.values(TableData).map((val) => {
                  return (
                    <TableCell key={val}>{val}</TableCell>
                  );
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {!!props?.data
              &&
              props?.data.map((item: any) => {
                return(
                <>
                  <TableRow
                    key={item.cook.id + "1"}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Link to={{ pathname: `/Cooks/${item.cook.id}/show` }}>
                        {item.cook.id}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <MUILink href={`${ZohoBaseUrl}${ZohoModulesUrl.COOKS}/${item?.cook?.zohoCookId}`} target="_blank">
                    {item?.cook?.zohoCookId}
                    </MUILink>
                    </TableCell>
                    <TableCell>
                      {item?.cook?.fname}
                    </TableCell>
                    <TableCell>
                      {item?.cook?.lname}
                    </TableCell>
                    <TableCell>
                      <CallButton toPhoneNumber={item?.cook?.mobile} userType={UserType.COOK} />
                    </TableCell>
                    <TableCell>
                      {item?.cook?.address}
                    </TableCell>
                    <TableCell>
                      {item?.cook?.category}
                    </TableCell>
                    <TableCell>
                      {item?.cook?.vehicle}
                    </TableCell>
                    <TableCell align="center">
                      {item?.cook?.cooktype}
                    </TableCell>
                    <TableCell align="center">
                      {item?.cook?.experience}
                    </TableCell>
                    <TableCell>
                      {item?.cook?.sector}
                    </TableCell>
                    <TableCell>
                      {item?.cook?.northIndianFinalRating}
                    </TableCell>
                    <TableCell>
                      {item?.cook?.southIndianFinalRating}
                    </TableCell>
                    <TableCell>
                      {item?.cook?.chineseDishFinalRating}
                    </TableCell>
                    <TableCell>
                      {item?.cook?.continentalDishFinalRating}
                    </TableCell>
                    <TableCell>
                      {item?.cook?.lastSlotsUpdatedByAdmin}
                    </TableCell>
                    <TableCell>
                      {item?.cook?.lastSlotsUpdatedByAdminAt}
                    </TableCell>
                    <TableCell>
                      {item?.cook?.lastSlotsUpdatedByAppAt}
                    </TableCell>
                    <TableCell>
                      {item?.cook?.lastSlotsUpdatedBySystemAt}
                    </TableCell>
                    <TableCell>
                      {item?.cook?.workingCity}
                    </TableCell>
                    <TableCell>
                      {item?.cook?.isSalaried ? "true" : "false"}
                    </TableCell>
                  </TableRow>

                  <TableRow key={item.cook.id + "2"}>
                    <TableCell colSpan={17}>
                      {<PartnerLineChart data={item.cookSlots} id={item.id} />}
                    </TableCell>
                  </TableRow>
                </>
              )})}
          </TableBody>
        </Table>
      </TableContainer>
      </Card>
    </div >
  );
};
