import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Rating } from "../../interfaces/ChefRating/chefRatingInterface";
import TypesOfChefRating from "../../utils/TypeOfChefRating";

interface TableInterface {
  label: string;
  dataSet: string;
}

const tableCellData = [
  {
    label: "Id",
    dataSet: "id",
  },
  {
    label: "Category Name",
    dataSet: "categoryName",
  },
  {
    label: "Category Score",
    dataSet: "categoryScore",
  },
  {
    label: "Parameter Name",
    dataSet: "parameterName",
  },
  {
    label: "Parameter Score",
    dataSet: "parameterScore",
  },
  {
    label: "SubCategory Name",
    dataSet: "subCategoryName",
  },
  {
    label: "SubCategory Score",
    dataSet: "subCategoryScore",
  },
];

const TableData = (props: any) => {
  return (
    <TableContainer style={{ margin: "20px 0px" }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {tableCellData.map((x: TableInterface) => (
              <TableCell>{x.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.data?.map((rowData: any, index: number) => {
            return (
              <TableRow key={index}>
                {tableCellData.map((x: any) => (
                  <TableCell>{rowData[`${x.dataSet}`]} </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ChefRatingMetaShow = (props: any) => {
  return (
    <div>
      {
        <p>
          <b>Overall Rating: </b>
          {Number(props?.data?.overAllRating).toFixed(2)}
        </p>
      }
      {Object.values(TypesOfChefRating).map((value) => {
        return (
          <TableData
            data={props?.data?.ratings.filter(
              (e: Rating) => e.categoryId === value
            )}
          />
        );
      })}
    </div>
  );
};

export default ChefRatingMetaShow;
