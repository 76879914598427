import { GetListResult, GetOneResult } from "ra-core";
import HttpClient from "../HttpClient";
import { InventoryResponse } from "../interfaces/StarChefVisit/InventoryResponse";
import { StarCleanerVisitSlots } from "../enums/StarCleanerTimeSlots";

export default class StarCleanerInventoryService {
    private basePath = "/centaurus/star-cleaner-inventory";
    
    async getAllStarCleanerInventory(cityId: number): Promise<GetListResult<InventoryResponse>> {
        let data = await HttpClient.get(`${this.basePath}/city/${cityId}`)

        return {
            data: !!data ? data.data : [],
            total: !!data ? data.data.length : 0
        }
    }

    async upsertCleanerInventory(date: string, timeSlot: StarCleanerVisitSlots, availableCount: number, cityId: number): Promise<GetOneResult<InventoryResponse>> {
        let data = await HttpClient.post(`${this.basePath}/`,{date, timeSlot, availableCount, cityId});
        return data?.data;
    }
}