import {Box, Button, Dialog, Typography} from "@mui/material";
import React from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type InputProps = {
    open: boolean;
    onClose: () => void;
}
const PRTrialCreateSuccessDialog = (props: InputProps) => {
    return (
        <Dialog
            open={props?.open}
            sx={{
                '& .MuiPaper-root': {
                    py: '40px',
                    px: '20px',
                    minWidth: '350px',
                    maxWidth: '650px',
                },
            }}
        >
            <Box m={'auto'} mb={'20px'}>
                <CheckCircleIcon sx={{color: '#055005', fontSize: '50px'}}/>
            </Box>
            <Typography m={'auto'} fontWeight={500} letterSpacing={'0.7px'}>PR Trial Successfully Created</Typography>
            <Box display={'flex'} justifyContent={'center'} mt={'30px'}>
                <Button
                    sx={{
                        width: '100px',
                        textTransform: 'none',
                        fontSize: '16px',
                        letterSpacing: '1px',
                    }}
                    variant={'contained'}
                    color={'primary'}
                    onClick={props?.onClose}
                >
                    OK
                </Button>
            </Box>
        </Dialog>
    )
}
export default PRTrialCreateSuccessDialog;