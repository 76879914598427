import { useState } from "react";
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  RaRecord,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { Button as MUIButton, Stack } from "@mui/material";
import CustomReferenceField from "../Common/CustomReferenceField";
import TrialsAdminNotesDialog from "../Common/TrialsAdminNotesDialog";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { TrialsConvertedService } from "../../services/TrialsConvertedService";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";

const TrialsConverted = new TrialsConvertedService();
interface InputProps {
  name: any;
  callback: (data: any) => void;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
}

const CustomButton = (props: InputProps) => {
  let record = useRecordContext();
  return (
    <MUIButton
      onClick={() => {
        props.callback(record);
      }}
      size={"small"}
      color={props.color || "secondary"}
      variant={"contained"}
      style={{ width: "max-content", fontSize: "12px" }}
    >
      {props.name}
    </MUIButton>
  );
};

export const ActionButton = (props: any) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  if(!record) return null;
  return (
    <Stack direction={"row"} gap={2}>
      <CustomButton
        name={"Add Admin Notes"}
        color="primary"
        callback={(data: any) => {
          props.setSelectedData(data);
          props.setOpenSetAdminNotesDialog(true);
        }}
      />
      {!record?.informedToChef &&
      <CustomButton
        name={"Informed To Cook"}
        color="primary"
        callback={(data: any) => {
          EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
            message: "Informed to cook true?",
            onClose: (confirmed: boolean) => {
              if (confirmed) {

                TrialsConverted.informedChef(parseInt((record as any).id), {informedToCook: true}).then(
                  (res) => {
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                      message: "Success",
                      type: CustomSnackbarTypes.SUCCESS,
                    });
                    refresh();
                  }
                );
              }
            },
            title: "Confirmation",
          });
        }}
      />}
    </Stack>
  );
};

const TrialConvertedShow = (props: any) => {
  let [selectedData, setSelectedData] = useState<any>(null);
  const [openSetAdminNotesDialog, setOpenSetAdminNotesDialog] = useState(false);

  return (
    <Show>
      <div>
        <TrialsAdminNotesDialog
          method={"post"}
          data={selectedData}
          open={openSetAdminNotesDialog}
          setOpen={setOpenSetAdminNotesDialog}
        />
        <Show {...props} actions={false} title=" ">
          <ActionButton
            setSelectedData={setSelectedData}
            setOpenSetAdminNotesDialog={setOpenSetAdminNotesDialog}
          />
          <div style={{ display: "grid", gridTemplateColumns: "30% 40% 30%" }}>
            <SimpleShowLayout>
              <NumberField source="id" />
              <CustomReferenceField
                label="Cook Id"
                listName={"cooks"}
                source={"cookId"}
              />
              <FunctionField label="Cook Name" render={(record: RaRecord) => {
                if (!record?.cookFullProfile?.firstName) {
                  return `Not Available`
                }
                  return `${record?.cookFullProfile?.firstName} ${record?.cookFullProfile?.lastName}`
                }} 
              />
              <TextField
                label="Cook Mobile"
                source="cookMobile"
                sortable={false}
              />
              <CustomReferenceField
                label="User Bundle"
                listName={"UserBundles"}
                source={"userBundleMappingId"}
              />
            </SimpleShowLayout>
            <SimpleShowLayout>
              <TextField
                label="Subscription Id"
                source="subscriptionId"
                sortable={false}
              />
                <CustomReferenceField
                label="Trial Id"
                listName={"trials"}
                source={"trialId"}
              />
              <DateField
                label="Start Date"
                source="startDate"
                sortable={false}
              />
              <DateField label="End Date" source="endDate" sortable={false} />
              <TextField
                label="Start Slot"
                source="startSlot"
                sortable={false}
              />
            </SimpleShowLayout>
            <SimpleShowLayout>
              <ArrayField label="Meal Mapping" source="mealMapping">
                <Datagrid bulkActionButtons={false} style={{ minWidth: "250px" }}>
                  <TextField source="slot" />
                  <TextField
                    source="time"
                    style={{ width: "61px", display: "inlineBlock" }}
                  />
                  <TextField label="Buffer" source="bufferMinutes" />
                </Datagrid>
              </ArrayField>
              <TextField label="Address" source="address" sortable={false} />
              <TextField
                label="Informed To Chef"
                source="informedToChef"
                sortable={false}
              />
              <TextField
                label="Admin Notes"
                source="adminNotes"
                sortable={false}
              />
            </SimpleShowLayout>
            <SimpleShowLayout>
              <TextField
                label="Created By Id"
                source="createdById"
                sortable={false}
              />
              <TextField
                label="Created By Name"
                source="createdByName"
                sortable={false}
              />
              <TextField
                label="Created By Email"
                source="createdByEmail"
                sortable={false}
              />
              <TextField
                label="Created At"
                source="createdAt"
                sortable={false}
              />
            </SimpleShowLayout>
            <SimpleShowLayout>
              <TextField
                label="Modified By Id"
                source="modifiedById"
                sortable={false}
              />
              <TextField
                label="Modified By Name"
                source="modifiedByName"
                sortable={false}
              />
              <TextField
                label="Modified By Email"
                source="modifiedByEmail"
                sortable={false}
              />
              <TextField
                label="Updated At"
                source="updatedAt"
                sortable={false}
              />
            </SimpleShowLayout>
          </div>
        </Show>
      </div>
    </Show>
  );
};

export default TrialConvertedShow;
