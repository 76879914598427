import { Close } from "@mui/icons-material";
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, TextField
} from "@mui/material";
import  React from "react";
import { useRefresh } from "react-admin";
import { useState } from "react";
import StarCleanerVisitService from "../../../services/StarCleanerVisitService";
import { EventBusEventsEnum } from "../../../enums/EventBusEventsEnum";
import EventBus from "../../../EventBus";
import { CustomSnackbarTypes } from "../../Common/CustomSnackBar";

interface InputProps {
  data: any;
  open: boolean;
  callback: () => void;
}

export const AddNoteStarCleanerVisit = (props: InputProps) => {
  const [note, setNote] = useState<string>(props?.data?.note || "")
  let refresh = useRefresh()
  const starCleanerVisitService = new StarCleanerVisitService()

  let handleClose = () => {
    props.callback()
    setNote('')
  }
  return (
      <Dialog
        onClose={handleClose}
        open={props.open}
        scroll={"paper"}
        maxWidth="xl"
      >
        <DialogTitle>Add Note</DialogTitle>
        <DialogContent>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
          <Grid
            container
            columns={1}
            rowSpacing={0.9}
            style={{ padding: "12px 12px 12px 12px" }}
          >
            <FormControl fullWidth>
            <TextField
                id="reason"
                onChange={(event) => {
                  setNote(event.target.value);
                }}
                value={note}
                multiline
                rows={4}
                label="Enter the Text"
                variant="outlined"
              />
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              if (!note) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Fill the reason",
                    type: CustomSnackbarTypes.WARNING,
                });
                return;
              }
              starCleanerVisitService
              .addNote(props.data.id, {
                 note
                })
                .then((res) => {
                  refresh();
                  handleClose()
                });
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              handleClose();
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  );
};
