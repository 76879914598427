import {useEffect, useState} from "react";
import { useRecordContext } from "react-admin";
import {Box, Grid} from "@mui/material";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import {TrialsService} from "../../services/TrialServices";

const trialServices = new TrialsService();

const TextView = ({title, value}: {title: string; value: any}) => {
    function convertToTitleCase(str: string) {
        const words = str.split(/(?=[A-Z])/);
        const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
        return capitalizedWords.join(' ');
    }
    return(
        <Box mb={1} mt={2} display={'flex'}>
            <Box fontSize={'0.875rem'} color={'#525252'}><b>{convertToTitleCase(title)}: </b></Box>
            <Box fontSize={'0.875rem'} ml={1}>{typeof value === 'object' ? JSON.stringify(value) : value}</Box>
        </Box>
    )
};

export const TrialsCookAuditLogsShow = () => {
    const record = useRecordContext();
    const [data, setData] = useState<any>([]);

    useEffect(() => {
        if(!record?.id) {
            return;
        }
        trialServices.getAuditLogsForTrial(Number(record?.id)).then(async (res: any) => {
            res.map((eachLog: any) => {
                if(eachLog?.deepObjectDiff) {
                    if(typeof eachLog?.deepObjectDiff?.modified === 'object') {
                        setData((preData: any) => [...preData, eachLog?.deepObjectDiff?.modified])
                    }
                }
                return eachLog;
            })
        })
    }, [record?.id])

    return (
            <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                    {data?.length > 0 ? (
                        data.map((eachObj: any, index: number) => {
                            return (
                                <Box sx={{
                                    backgroundColor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.04)': '#ffffff',
                                }} p={2} px={3}>
                                    {Object.keys(eachObj).map((eachKey: string) => (
                                        <TextView title={eachKey} value={eachObj[eachKey]}/>
                                    ))}
                                </Box>
                            )
                        })
                    ) : <EmptyListPlaceholder
                        message='No Audit Logs'
                    />}
                </Grid>
            </Grid>
    )
};
