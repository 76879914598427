enum ContactedReasonsCategories {
    COOK_CALLED_NOT_RESPONDING = "Cook called - not responding",
    COOK_TIME_SLOT_WRONGLY_UPDATED = "Cook time slot wrongly updated",
    COOK_IS_WRONGLY_MAPPED_IN_THIS_SECTOR="Cook is wrongly mapped in this sector",
    COOK_DOES_NOT_WANT_TO_WORK_WITH_CHEFKART = "Cook does not want to work with Chefkart",
    COOK_STATUS_IS_NOT_ALLOCATABLE = "Cook status is not allocatable",
    DISTANCE_TOO_FAR = "Distance too far",
    BLOCKED_SLOT = "Blocked Slot",
    PRICE_LOW = "Price Low",
    LONG_LEAVE_SCHEDULED = "Long leave Scheduled",
    NOT_WELL = "Not well",
    OUT_OF_STATION = "Out of Station",
    SHIFTED_TO_LOCALITY = "Shifted to different Locality",
    OTHERS = "Others"
}

export default ContactedReasonsCategories
