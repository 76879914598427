import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { default as TextFieldMUI } from "@mui/material/TextField/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { PatchCookOnBundleMapping } from "../../dtos/bundle/PatchCookOnBundleMapping";
import { CookSubscriptionService } from "../../services/CookSubscriptionService";
import "../../styles/AssignCookOnBundleDialogCss.css";
import Cooks from "../Cooks/Cooks";
import CircularLoader from "./CircularLoader";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import ResourceSelectDialog from "./ResourceSelectDialog";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import { UserGroupsEnum } from "../../enums/UserGroupsEnum";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";
import EditClousreTextDialog from "./EditClousreTextDialog";
import { Close } from "@mui/icons-material";
import { SchedulerData } from "../../interfaces/UserBundleMapping/SchedulerDetails";
import ErrorIcon from "@mui/icons-material/Error";
import { getReasonCategory } from "./constants/constant";
import { ActionTypeEnum } from "../../enums/ActionTypeEnum";
import ReasonSubreasonModuleEnum from "../../enums/ReasonSubreasonModuleEnum";


const cookSubscriptionService = new CookSubscriptionService();

interface CookSubscription {
  callback: () => void;
  data: any;
  open: boolean;
}
interface item {
  category: string;
  subCategory: string[];
}

const AssignCookOnBundleDialog = (props: CookSubscription) => {

  const [activeClousreRequest, setActiveClousreRequest] =
    useState<SchedulerData>();
  const [createPr, setCreatePr] = useState<string>("true");
  let [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  let [assignCookFormModal, setAssignCookFormModal] = useState(false);
  let [selectedCookDetails, setSelectedCookDetails] = useState<any>(null);
  let [datePickerValue, setDatePickerValue] = useState<Date | null>(null);
  let [selectStartSlot, setSelectStartSlot] = useState<string>("");
  let [selectCookCut, setSelectCookCut] = useState<string>("0.00");
  let [showStartDateEdit, setShowStartDateEdit] = useState<boolean>(false);
  let [startDateEdit, setStartDateEdit] = useState<any>();
  let [slotMappingIdForEdit, setSlotMappingIdForEdit] = useState<any>(null);
  let [showEditDialog, setShowEditDialog] = useState(false);
  let [cookCutForEdit, setCookCutForEdit] = useState<any>(null);
  let [showCancelDialog, setShowCancelDialog] = useState(false);
  let [changeTimeSlotDialog, setChangeTimeSlotDialog] = useState(false);
  let [selectEndSlot, setSelectEndSlot] = useState<string>("");
  let [selectedBundleData, setSelectedBundleData] = useState<any>(null);
  const userDetails = useAppSelector((state) => state.userDetails.value);
  let groupsArray: string[] = userDetails.adminUser.accessGroups;
  const dispatch = useAppDispatch();
  let [activeCookSubscription, setActiveCookSubscription] = useState<any>();
  let [inactiveCookSubscription, setInactIveCookSubscription] =
    useState<any[]>();
  const [comments, setComments] = useState<any>(null);
  const [categoryItems,setCategoryItems]=useState<item[]>([])
  const [reasonSubCategory, setReasonSubCategory] = useState<string | null>(
    null
  );
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any>();

  const fetchData = () => {
    cookSubscriptionService
      .getAssignedCooksForUserBundleMapping(props.data.id)
      .then((response) => {
        const data = response?.data?.data;
        let activeSubscription: any[] = [];
        let inActiveSubscription: any[] = [];
        setIsDataLoading(false);
        data?.forEach((cookSubscription: any) => {
          if (!cookSubscription?.endDate) {
            activeSubscription.push(cookSubscription);
          } else {
            inActiveSubscription.push(cookSubscription);
          }
        });
        setActiveCookSubscription(activeSubscription[0]);
        setInactIveCookSubscription(inActiveSubscription);
      })
      .catch((err) => {
        handleClose();
      });
    cookSubscriptionService
      .getActiveClousreRequests(props?.data?.id)
      .then((data) => {
        setActiveClousreRequest(data);
      });
  };

  const loadData = useCallback(async () => {
    const sampleReasonSubReason = await getReasonCategory(ReasonSubreasonModuleEnum.SUBSCRIPTION,ActionTypeEnum.REASON_FOR_PR_TRIAL);
    setCategoryItems(sampleReasonSubReason);

  }, []);

  useEffect(()=>{
    loadData();
  },[loadData])

  useEffect(() => {
    setSelectCookCut(props?.data?.cookCut);
    if (props?.data?.id) {
      fetchData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let refresh = () => {
    setIsDataLoading(true);
    fetchData();
  };

  const handleClose = () => {
    props.callback();
  };

  const handleSelectCook = () => {
    dispatch(
      setCustomSelectOpen({
        open: true,
      })
    );
  };

  const onClose = () => {
    setOpen(false);
    refresh();
  };

  const handleReasonCategoryChange = (event: any) => {
    setSelectedCategory(event.target.value);
  };

  const handleReasonSubCategoryChange = (event: any) => {
    setReasonSubCategory(event.target.value);
  };

  function showSubCategoryOrNot(reasonCategory: string) {
    if (reasonCategory === null) {
      return null;
    }
    if (reasonCategory?.toLowerCase() === "others" || reasonCategory?.toLowerCase() === 'other') {
      return (
        <FormControl style={{ width: 500 }} fullWidth>
          <TextField
            value={reasonSubCategory}
            onChange={handleReasonSubCategoryChange}
            id="outlined-basic"
            label="Write a reason"
            variant="outlined"
          />
        </FormControl>
      );
    } else {
      let selectedCategoryItem = categoryItems?.filter(
        (e) => e.category === reasonCategory
      )[0];
      return (
        <FormControl style={{ width: 520 }} fullWidth>
          <InputLabel id="demo-simple-select-label">Select a Subreason</InputLabel>
          <Select
            autoWidth={false}
            className="input_field"
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            defaultValue="Select a reason"
            value={reasonSubCategory}
            onChange={handleReasonSubCategoryChange}
          >
            {selectedCategoryItem?.subCategory.map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    }
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={props.open}
        maxWidth={"xl"}
        fullWidth={true}
      >
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          {" "}
          <Close />{" "}
        </IconButton>
        {isDataLoading ? (
          <CircularLoader />
        ) : (
          <div>
            {activeClousreRequest && (
              <div style={{ margin: "20px", marginTop: "40px" }}>
                <b>Cancelled By Id:- </b>
                <Link
                  href={`/#/AdminUsers/${activeClousreRequest?.cancelledById}/show`}
                >
                  {activeClousreRequest?.cancelledById}
                </Link>
                <br />
                <b>Support End Date:- </b>{" "}
                {moment(activeClousreRequest?.supportEndDate).format(
                  "YYYY/MM/DD"
                )}
                <br />
                <b>Support End Slot:- </b>
                {activeClousreRequest?.supportEndSlot}
                <br />
                <b>Scheduled Time:- </b>
                {moment(
                  activeClousreRequest?.schedulerDetails?.scheduledTime
                ).format("YYYY/MM/DD hh:mm a")}
                <br />
                <b>Closure Category:- </b>
                {activeClousreRequest?.closureCategory} <br />
                <b>Closure Sub-Category:- </b>
                {activeClousreRequest?.closureSubCategory} <br />
                <b>Comments:- </b>
                {activeClousreRequest?.comments?.map((commentData: any) => {
                  return commentData?.comment;
                })}
                <br />
                {activeClousreRequest && (
                  <Button
                    style={{ margin: "10px" }}
                    variant="contained"
                    size={"small"}
                    color={"error"}
                    onClick={() => {
                      const removeSchedulers = () => {
                        cookSubscriptionService
                          .removeScheduler(activeClousreRequest?.id)
                          .then((res) => {
                            EventBus.emitEvent(
                              EventBusEventsEnum.SNACKBAR_SHOW,
                              {
                                message: "Successful",
                                type: CustomSnackbarTypes.SUCCESS,
                              }
                            );
                            refresh();
                          });
                      };
                      EventBus.emitEvent(
                        EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG,
                        {
                          title: "Are you sure?",
                          message: "Please confirm to remove the scheduler!!",
                          successLabel: "Confirm",
                          failureLabel: "Cancel",
                          onClose: (isConfirmed: boolean) =>
                            isConfirmed ? removeSchedulers() : null,
                        }
                      );
                    }}
                  >
                    Remove scheduler
                  </Button>
                )}
                {GroupPermissionFunction(groupsArray, [
                  UserGroupsEnum.ADMIN,
                  UserGroupsEnum.MANAGER,
                ]) &&
                  activeClousreRequest && (
                    <Button
                      style={{ margin: "10px" }}
                      variant="contained"
                      size={"small"}
                      color={"success"}
                      onClick={() => {
                        const ExecuteSchedulers = () => {
                          cookSubscriptionService
                            .executeSchedulers(activeClousreRequest?.id)
                            .then((res) => {
                              EventBus.emitEvent(
                                EventBusEventsEnum.SNACKBAR_SHOW,
                                {
                                  message: "Successful",
                                  type: CustomSnackbarTypes.SUCCESS,
                                }
                              );
                              refresh();
                            });
                        };
                        EventBus.emitEvent(
                          EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG,
                          {
                            title: "Are you sure?",
                            message:
                              "Please confirm to Execute the scheduler!!",
                            successLabel: "Confirm",
                            failureLabel: "Cancel",
                            onClose: (isConfirmed: boolean) =>
                              isConfirmed ? ExecuteSchedulers() : null,
                          }
                        );
                      }}
                    >
                      Execute scheduler
                    </Button>
                  )}
              </div>
            )}
            <div
              style={{
                margin: "40px",
              }}
            >
              <h4
                style={{
                  textAlign: "center",
                }}
              >
                Active Subscription
              </h4>
              {
                <Table className={"custom-table"}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Start Slot</TableCell>
                      <TableCell>Cook Subscription Id</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>Cook Name</TableCell>
                      <TableCell>CookId</TableCell>
                      <TableCell>CookCut</TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={activeCookSubscription?.id}>
                      <TableCell>{activeCookSubscription?.startSlot}</TableCell>
                      <TableCell>{activeCookSubscription?.id}</TableCell>
                      <TableCell>{activeCookSubscription?.startDate}</TableCell>
                      <TableCell>
                        {activeCookSubscription?.cook?.fname}
                      </TableCell>
                      <TableCell>{activeCookSubscription?.cookId}</TableCell>
                      <TableCell>{activeCookSubscription?.cookCut}</TableCell>
                      <TableCell>
                        <div>
                          {activeCookSubscription?.id ? (
                            <div>
                              {moment(
                                activeCookSubscription?.startDate
                              ).isAfter(moment()) && (
                                <Button
                                  style={{ marginRight: "10px" }}
                                  variant="contained"
                                  size={"small"}
                                  color={"primary"}
                                  onClick={() => {
                                    setShowStartDateEdit(true);
                                    setSelectStartSlot("");
                                    setSlotMappingIdForEdit(
                                      activeCookSubscription?.id
                                    );
                                  }}
                                >
                                  Edit Date
                                </Button>
                              )}
                              <Button
                                style={{ marginRight: "10px" }}
                                variant="contained"
                                size={"small"}
                                color={"primary"}
                                disabled={!GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER, UserGroupsEnum.EXECUTIVE])}
                                onClick={() => {
                                  setShowEditDialog(true);
                                  setSlotMappingIdForEdit(
                                    activeCookSubscription?.id
                                  );
                                  setCookCutForEdit(
                                    activeCookSubscription?.cookCut
                                  );
                                }}
                              >
                                Edit
                              </Button>
                              {GroupPermissionFunction(groupsArray, [
                                UserGroupsEnum.ADMIN,
                              ]) && (
                                  <Button
                                      style={{ marginRight: "10px" }}
                                      variant="contained"
                                      size={"small"}
                                      color={"warning"}
                                      onClick={() => {
                                        setChangeTimeSlotDialog(true);
                                        setSelectEndSlot("");
                                        setSelectedBundleData(
                                            activeCookSubscription?.id
                                        );
                                      }}
                                  >
                                    Change Time Slot
                                  </Button>
                              )}
                              {!activeClousreRequest && (
                                <Button
                                  style={{ marginRight: "10px" }}
                                  variant="contained"
                                  size={"small"}
                                  color={"error"}
                                  onClick={() => {
                                    setShowCancelDialog(true);
                                    setSelectEndSlot("");
                                    setComments("");
                                    setSelectedCategory("");
                                    setReasonSubCategory("");
                                    setSelectedBundleData(
                                      activeCookSubscription?.id
                                    );
                                  }}
                                >
                                  Cancel schedule cook
                                </Button>
                              )}
                              {GroupPermissionFunction(groupsArray, [
                                UserGroupsEnum.ADMIN,
                                UserGroupsEnum.MANAGER,
                              ]) &&
                                !activeClousreRequest && (
                                  <Button
                                    variant="contained"
                                    size={"small"}
                                    color={"error"}
                                    onClick={() => {
                                      const deleteCook = () => {
                                        cookSubscriptionService
                                          .hardDeleteCookSubscription(
                                            activeCookSubscription?.id
                                          )
                                          .then((res) => {
                                            EventBus.emitEvent(
                                              EventBusEventsEnum.SNACKBAR_SHOW,
                                              {
                                                message: "Successful",
                                                type: CustomSnackbarTypes.SUCCESS,
                                              }
                                            );
                                            refresh();
                                          });
                                      };
                                      EventBus.emitEvent(
                                        EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG,
                                        {
                                          title: "Are you sure?",
                                          message:
                                            "Please confirm the deletion of cook subscription",
                                          successLabel: "Confirm",
                                          failureLabel: "Cancel",
                                          onClose: (isConfirmed: boolean) =>
                                            isConfirmed ? deleteCook() : null,
                                        }
                                      );
                                    }}
                                  >
                                    Delete
                                  </Button>
                                )}
                            </div>
                          ) : (
                            <Button
                              variant="contained"
                              size={"small"}
                              color={"primary"}
                              onClick={() => {
                                setAssignCookFormModal(true);
                                setSelectStartSlot("");
                                setSelectedBundleData(
                                  props?.data?.userBundleMeta
                                    ?.userBundleMappingId
                                );
                              }}
                            >
                              Assign Cook
                            </Button>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              }
            </div>
            <div
              style={{
                height: "250px",
                overflow: "scroll",
                padding: "20px",
                margin: "20px",
              }}
            >
              <h4
                style={{
                  textAlign: "center",
                }}
              >
                Subscription History
              </h4>
              {!!inactiveCookSubscription &&
                inactiveCookSubscription.length === 0 && (
                  <p>No Mapping History</p>
                )}
              {!!inactiveCookSubscription &&
                inactiveCookSubscription?.length > 0 && (
                  <Table className={"custom-table"}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Start Slot</TableCell>
                        <TableCell>End Slot</TableCell>
                        <TableCell>Cook Subscription Id</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Cook Name</TableCell>
                        <TableCell>CookId</TableCell>
                        <TableCell>CookCut</TableCell>
                        <TableCell>Closure Category</TableCell>
                        <TableCell>Closure Sub-Category</TableCell>
                        <TableCell>Admin Notes</TableCell>
                        <TableCell>Closed By</TableCell>
                        <TableCell>Closed At</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    {inactiveCookSubscription?.map((slot: any) => {
                      return (
                        <TableBody>
                          <TableRow key={slot?.id}>
                            <TableCell>{slot?.startSlot}</TableCell>
                            <TableCell>{slot?.endSlot}</TableCell>
                            <TableCell>{slot?.id}</TableCell>
                            <TableCell>{slot?.startDate}</TableCell>
                            <TableCell>{slot?.endDate}</TableCell>
                            <TableCell>{slot?.cook?.fname}</TableCell>
                            <TableCell>{slot?.cookId}</TableCell>
                            <TableCell>{slot?.cookCut}</TableCell>
                            <TableCell>{slot?.closureCategory}</TableCell>
                            <TableCell>{slot?.closureSubCategory}</TableCell>
                            <TableCell>
                              {slot?.comments?.map((commment: any) => {
                                return commment?.comment;
                              })}
                            </TableCell>
                            <TableCell>
                              {slot?.cancelledByDetails?.name}
                            </TableCell>
                            <TableCell>
                              {slot?.cancelledAt
                                ? moment(slot?.cancelledAt).format(
                                    "YYYY-MM-DD HH:MM"
                                  )
                                : ""}
                            </TableCell>
                            <TableCell>
                              {
                                <div>
                                  {GroupPermissionFunction(groupsArray, [
                                    UserGroupsEnum.ADMIN,
                                    UserGroupsEnum.MANAGER,
                                  ]) && (
                                    <Button
                                      variant="contained"
                                      size={"small"}
                                      color={"primary"}
                                      onClick={() => {
                                        setData(slot);
                                        setOpen(true);
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  )}
                                </div>
                              }
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })}
                  </Table>
                )}
            </div>
          </div>
        )}
        <DialogActions>
          <Button
            variant="contained"
            color={"error"}
            onClick={handleClose}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* for assigning a cook */}
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={assignCookFormModal}
        onClose={() => {
          setSelectedCookDetails(null);
          setDatePickerValue(null);
          setAssignCookFormModal(false);
        }}
      >
        <DialogTitle>Assign Cook</DialogTitle>
        <Grid
          container
          spacing={2}
          justifyContent={"space-evenly"}
          style={{ padding: "0px 12px 0px 12px" }}
        >
          <Grid item xs={4}>
            <DatePicker
              label="Select Date"
              inputFormat="dd MMM yyyy"
              value={datePickerValue}
              disableMaskedInput
              minDate={new Date(props.data?.startDate)}
              maxDate={new Date(props.data?.endDate)}
              onChange={(newValue) => {
                setDatePickerValue(newValue);
              }}
              renderInput={(params) => {
                return <TextFieldMUI {...params} />;
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControl sx={{ minWidth: 140 }}>
              <InputLabel id="select-label">Start Slot</InputLabel>
              <Select
                labelId="select-label"
                id="simple-select"
                value={selectStartSlot}
                label="Start Slot"
                onChange={(event) => setSelectStartSlot(event.target.value)}
              >
                {props?.data?.userBundleMeta?.mealMapping?.map((e: any) => {
                  return (
                    <MenuItem key={e} value={e}>
                      {e}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <DialogContent>
          {!!selectedCookDetails && (
            <div>
              <h3>Selected Cook Details</h3>
              <Table
                style={{ border: "1px solid black", marginBottom: "40px" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>address</TableCell>
                    <TableCell>category</TableCell>
                    <TableCell>vehicle</TableCell>
                    <TableCell>workingCity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={selectedCookDetails?.id}>
                    <TableCell>
                      {selectedCookDetails?.fname +
                        " " +
                        selectedCookDetails?.lname}
                    </TableCell>
                    <TableCell>{selectedCookDetails?.mobile}</TableCell>
                    <TableCell>{selectedCookDetails?.address}</TableCell>
                    <TableCell>{selectedCookDetails?.category}</TableCell>
                    <TableCell>{selectedCookDetails?.vehicle}</TableCell>
                    <TableCell>{selectedCookDetails?.workingCity}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <FormControl>
                <TextField
                  label="Cook Cut(Monthly Cut)"
                  id="CookCut"
                  value={selectCookCut}
                  disabled
                  onChange={(event) => setSelectCookCut(event.target.value)}
                />
              </FormControl>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size={"large"}
            onClick={() => {
              if (!datePickerValue && selectStartSlot) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Please Provide the start date and Start Slot",
                  type: CustomSnackbarTypes.ERROR,
                });
                return;
              }
              if (!selectedCookDetails) {
                handleSelectCook();
                return;
              }
              if (
                selectedCookDetails &&
                (!selectCookCut ||
                  !Number(selectCookCut) ||
                  Number(selectCookCut) < 0)
              ) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Please Provide the cookCut",
                  type: CustomSnackbarTypes.ERROR,
                });
                return;
              }
              cookSubscriptionService
                .assignCookOnBundleMappingId(selectedBundleData, {
                  cookId: selectedCookDetails.id,
                  startSlot: selectStartSlot as any,
                  startDate: moment(datePickerValue).format("YYYY-MM-DD"),
                  cookCut: selectCookCut,
                })
                .then((res) => {
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Successful",
                    type: CustomSnackbarTypes.SUCCESS,
                  });
                  setSelectedCookDetails(null);
                  setDatePickerValue(null);
                  setAssignCookFormModal(false);
                  refresh();
                });
            }}
          >
            {!!selectedCookDetails ? "Save" : "Select Cook"}
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              setSelectedCookDetails(null);
              setDatePickerValue(null);
              setAssignCookFormModal(false);
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>


      {/*  for changing time and slot   */}
      {
        <Dialog
            fullWidth={true}
            open={changeTimeSlotDialog}
            onClose={() => {
              setDatePickerValue(null);
              setChangeTimeSlotDialog(false);
            }}
        >
          <DialogTitle>Change time and slot</DialogTitle>
          <DialogContent>
            <Grid
                container
                spacing={2}
                justifyContent={"space-evenly"}
                style={{ padding: "12px 12px 12px 12px" }}
                alignItems="center"
            >
              <Grid item xs={4}>
                <DatePicker
                    label="Select Start Date"
                    inputFormat="dd MMM yyyy"
                    disableMaskedInput
                    value={datePickerValue}
                    onChange={(newValue) => {
                      setDatePickerValue(newValue);
                    }}
                    renderInput={(params) => {
                      return <TextFieldMUI {...params} />;
                    }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl style={{ width: 150 }}>
                  <InputLabel id="select-label">Start Slot</InputLabel>
                  <Select
                      labelId="select-label"
                      id="simple-select1"
                      value={selectEndSlot}
                      label="End Slot"
                      onChange={(event) => setSelectEndSlot(event.target.value)}
                  >
                    {props?.data?.userBundleMeta?.mealMapping?.map((e: any) => {
                      return (
                          <MenuItem key={e} value={e}>
                            {e}
                          </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ marginTop: "12px" }}>
            <Button
                variant="contained"
                color={"primary"}
                size={"large"}
                onClick={() => {
                  if (
                      !datePickerValue ||
                      !selectEndSlot
                  ) {
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                      message: "Please Fill the details properly",
                      type: CustomSnackbarTypes.ERROR,
                    });
                    return;
                  }
                  cookSubscriptionService
                      .changeTimeAndSlotCookSubscription(selectedBundleData, {
                        startDate: moment(datePickerValue).format("YYYY-MM-DD"),
                        startSlot: selectEndSlot,
                      })
                      .then(() => {
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                          message: "Successful",
                          type: CustomSnackbarTypes.SUCCESS,
                        });
                        setDatePickerValue(null);
                        setChangeTimeSlotDialog(false);
                        setSelectEndSlot("");
                        refresh();
                      });
                }}
            >
              Save
            </Button>
            <Button
                variant="contained"
                color={"error"}
                onClick={() => {
                  setDatePickerValue(null);
                  setChangeTimeSlotDialog(false);
                  setSelectEndSlot("");
                }}
                size={"large"}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      }

      {/*  for remove the Schedule cook from slot   */}
      {
        <Dialog
          fullWidth={true}
          open={showCancelDialog}
          onClose={() => {
            setDatePickerValue(null);
            setShowCancelDialog(false);
          }}
        >
          <DialogTitle>Remove Schedule Cook From slot</DialogTitle>
          <DialogContent>
            <Grid
              container
              spacing={2}
              justifyContent={"space-evenly"}
              style={{ padding: "12px 12px 12px 12px" }}
              alignItems="center"
            >
              <Link
                href={`https://chefkart.atlassian.net/wiki/spaces/SOPS/pages/240877828/Cook+Subscription`}
                target="_blank"
              >
                <Tooltip title="Training Doc">
                  <IconButton sx={{ position: "absolute", top: 8, right: 8 }}>
                    <ErrorIcon />
                  </IconButton>
                </Tooltip>
              </Link>
              <Grid item xs={4}>
                <DatePicker
                  label="Select End Date"
                  inputFormat="dd MMM yyyy"
                  disableMaskedInput
                  value={datePickerValue}
                  minDate={new Date(props.data?.startDate)}
                  onChange={(newValue) => {
                    setDatePickerValue(newValue);
                  }}
                  renderInput={(params) => {
                    return <TextFieldMUI {...params} />;
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl style={{ width: 150 }}>
                  <InputLabel id="select-label">End Slot</InputLabel>
                  <Select
                    labelId="select-label"
                    id="simple-select1"
                    value={selectEndSlot}
                    label="End Slot"
                    onChange={(event) => setSelectEndSlot(event.target.value)}
                  >
                    {props?.data?.userBundleMeta?.mealMapping?.map((e: any) => {
                      return (
                        <MenuItem key={e} value={e}>
                          {e}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl style={{ width: 500 }} fullWidth>
                  <TextField
                    label="Comments"
                    value={comments}
                    onChange={(event) => {
                      setComments(event.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl style={{ width: 520 }} fullWidth>
                  <InputLabel id="select-label">Tell us Why?</InputLabel>
                  <Select
                    autoWidth={false}
                    className="input_field"
                    labelId="select-label"
                    id="demo-multiple-name"
                    value={selectedCategory}
                    onChange={handleReasonCategoryChange}
                  >
                    {categoryItems.map((item: item) => {
                      return (
                        <MenuItem key={item.category} value={item.category}>
                          {item.category}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {selectedCategory && (
                <Grid item xs={12}>
                  {selectedCategory && showSubCategoryOrNot(selectedCategory as string)}
                </Grid>
              )}
              {
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel id="pr">Create PR</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(event: any) => {
                        setCreatePr(event.target.value);
                      }}
                      value={createPr}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="True"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="False"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              }
            </Grid>
          </DialogContent>
          <DialogActions style={{ marginTop: "12px" }}>
            <Button
              variant="contained"
              color={"primary"}
              size={"large"}
              onClick={() => {
                if (
                  !datePickerValue ||
                  !selectEndSlot ||
                  !reasonSubCategory ||
                  !comments
                ) {
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Please Fill the details properly",
                    type: CustomSnackbarTypes.ERROR,
                  });
                  return;
                }
                cookSubscriptionService
                  .scheduleCancelCookSubscription(selectedBundleData, {
                    endDate: moment(datePickerValue).format("YYYY-MM-DD"),
                    endSlot: selectEndSlot,
                    reason: selectedCategory as string,
                    subReason: reasonSubCategory as string,
                    comments: comments,
                    createPr: createPr === "true" ? true : false,
                  })
                  .then(() => {
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                      message: "Successful",
                      type: CustomSnackbarTypes.SUCCESS,
                    });
                    setDatePickerValue(null);
                    setShowCancelDialog(false);
                    refresh();
                  });
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color={"error"}
              onClick={() => {
                setDatePickerValue(null);
                setShowCancelDialog(false);
                setReasonSubCategory(null);
                setSelectedCategory("");
                setComments("");
              }}
              size={"large"}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      }

      {/* for edit the cook cut */}
      <Dialog
        open={showEditDialog}
        fullWidth={true}
        onBackdropClick={() => {
          setShowEditDialog(false);
        }}
      >
        <DialogTitle>Edit Cook Details</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            justifyContent={"space-evenly"}
            style={{ padding: "10px 12px 0px 12px" }}
          >
            <Grid item xs={4}>
              <FormControl>
                <TextField
                  label="Cook Cut(Monthly Cut)"
                  id="CookCut"
                  value={cookCutForEdit}
                  onChange={(event) => setCookCutForEdit(event.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              if (
                !cookCutForEdit ||
                !Number(cookCutForEdit) ||
                Number(cookCutForEdit) < 0
              ) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Please Provide the cookCut",
                  type: CustomSnackbarTypes.ERROR,
                });
                return;
              }
              let payload: PatchCookOnBundleMapping = {
                cookCut: cookCutForEdit,
              };
              cookSubscriptionService
                .patchCookMappingOnBundleMappingId(
                  slotMappingIdForEdit,
                  payload
                )
                .then((res) => {
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Successful",
                    type: CustomSnackbarTypes.SUCCESS,
                  });
                  setSelectedCookDetails(null);
                  setShowEditDialog(false);
                  refresh();
                });
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* for update the start date of the slot mapping */}
      <Dialog
        open={showStartDateEdit}
        fullWidth={true}
        onBackdropClick={() => {
          setShowStartDateEdit(false);
        }}
      >
        <DialogTitle>Update Start Date of slot mapping</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            justifyContent={"space-evenly"}
            style={{ padding: "10px 12px 0px 12px" }}
          >
            <Grid item xs={4}>
              <FormControl fullWidth>
                <DatePicker
                  label="Select Date"
                  inputFormat="dd MMM yyyy"
                  value={startDateEdit}
                  minDate={new Date(props.data?.startDate)}
                  onChange={(newValue) => {
                    setStartDateEdit(newValue);
                  }}
                  renderInput={(params) => {
                    return <TextFieldMUI {...params} />;
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ minWidth: 140 }}>
                <InputLabel id="select-label">Start Slot</InputLabel>
                <Select
                  labelId="select-label"
                  id="simple-select"
                  value={selectStartSlot}
                  label="Start Slot"
                  onChange={(event) => setSelectStartSlot(event.target.value)}
                >
                  {props?.data?.userBundleMeta?.mealMapping?.map((e: any) => {
                    return (
                      <MenuItem key={e} value={e}>
                        {e}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={async () => {
              if (!startDateEdit) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Please Provide the start date and Start Slot",
                  type: CustomSnackbarTypes.ERROR,
                });
                return;
              }
              let payload = {
                startDate: startDateEdit,
                startSlot: selectStartSlot,
              };
              await cookSubscriptionService.updateStartDateOfCookSubscriptionSlotMapping(
                slotMappingIdForEdit,
                payload
              );
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Successful",
                type: CustomSnackbarTypes.SUCCESS,
              });
              setShowStartDateEdit(false);
              refresh();
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* {To Update the clousre text} */}
      {open && <EditClousreTextDialog open={open} onClose={onClose} data={data} />}

      <ResourceSelectDialog
        name={"Cooks"}
        list={Cooks}
        onClose={(data: any) => {
          setSelectedCookDetails(data);
        }}
      />
    </div>
  );
};

export default AssignCookOnBundleDialog;
