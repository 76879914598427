import {
  ExportButton,
  FilterLiveSearch,
  FunctionField,
  List,
  RaRecord,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
  SelectArrayInput, DateInput, useListContext,
  AutocompleteArrayInput,
} from "react-admin";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import ResourceSelectDialogButton from "../Common/ResourceSelectDialogButton";
import { Box, Card, CardContent, InputLabel, Button, Link } from "@mui/material";
import { FilterAlt, FilterAltOff } from "@mui/icons-material";
import { setCookSideFilter } from "../../store/reducers/customSelect";
import { SelectColumnsButton } from "../Common/ReactAdminWrapper/SelectColumnsButton";
import { DatagridConfigurable } from "../Common/ReactAdminWrapper/DatagridConfigurable";
import CallButton from "../Common/CallButton/CallButton";
import { UserType } from "../../enums/common";
import CookCreate from "./CookCreate";
import { FreshLeadStatus } from "../../enums/FreshLeadStatus";
import { AllCookOnBoardingSubStatus } from "../../enums/AllCookOnboardingSubStatus";
import CooksProfileLink from "../Common/CooksProfileLink";
import { CookWorkStatus } from "../../enums/CookWorkStatus";
import React, { useEffect, useState } from "react";
import FilterListIcon from '@mui/icons-material/FilterList';
import AdvancedCookFilterDialog from "./AdvancedCookFilterDialog";
import { NewLeadCategoryEnum } from "../../enums/CookCategoryEnum";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import { NewVendorSource } from "../../enums/NewVendorSource";
import CityService from "../../services/CityService";
import HubServices from "../../services/HubServices";
import RosterService from "../../services/RosterService";
// import {CookType} from "../../enums/CookType";

const cityService = new CityService();
const hubService = new HubServices();
const rosterService=new RosterService();

const CustomRefrenceField = (props: { source: string, label: string, reference: string }) => {
  const record = useRecordContext();
  if (!record.cookFullProfile) return <></>;
  return (
    <Link href={`/#/${props.reference}/${record?.cookFullProfile[props.source]}/show`}>{record?.cookFullProfile[props.source]}</Link>
  )
}

const FilterSidebar = () => {
  return (
    <Box
      sx={{
        marginTop: "65px",
        display: {
          xs: "none",
          sm: "block",
        },
        order: -1,
        width: "20em",
        marginRight: "1em",
      }}
    >
      <Card>
        <CardContent>
          <InputLabel>Gender(Male/Female)</InputLabel>
          <FilterLiveSearch source="gender" />
        </CardContent>
      </Card>
    </Box>
  );
};

const ListActions = (props: any) => {
  const dispatch = useAppDispatch();
  const { setFilters } = useListContext();
  const resetFilter = () => {
    setFilters({}, [], false);
    props?.setFilter([]);
  };

  return (
    <TopToolbar>
      <Button
        variant="text"
        size={"small"}
        onClick={resetFilter}
        startIcon={<FilterListOffIcon />}
      >
        {'Clear Filters'}
      </Button>
      <Button
        variant="text"
        size={"small"}
        onClick={() => {
          props?.setOpenAdvancedFilter(true);
        }}
        startIcon={<FilterListIcon />}
        color="primary"
      >
        {'Advanced filter'}
      </Button>
      <SelectColumnsButton callback={() => { }} />
      <Button variant="text"
        size={"small"}
        onClick={() => {
          dispatch(
            setCookSideFilter({
              cookSideFilter: !props.openSideFilterShow,
            })
          );
        }}
        startIcon={props.openSideFilterShow ? <FilterAlt /> : <FilterAltOff />} color="primary"
      >
        Filter
      </Button>
      <ExportButton />
      <CookCreate />
    </TopToolbar>
  );
};

const Cooks = (props: any) => {
  const isCustomSelectOpen = useAppSelector(
    (state) => state?.customSelect?.value.open
  );
  const showSideFilter = useAppSelector(
    (state) => state.customSelect.value.cookSideFilter
  );

  const [openAdvancedFilter, setOpenAdvancedFilter] = useState<boolean>(false);

  const [allCities, setAllCities] = useState<any>([]);

  const [choices, setChoices] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetched, setFetched] = useState<boolean>(false);
  const [filter,setFilter] = useState<any>([]);
  const[owners,setOwners]=useState<any>([])

  const fetchOptions = async () => {
    setLoading(true);
    hubService.getAllHubs(130, 0, {}, {}, {}).then((result:any) => {
      setChoices(result.data);
      setLoading(false);
      setFetched(true);
    })
  };
  const handleOpen = () => {
    if (!fetched) {
      fetchOptions();
    }
  };

    useEffect(() => {
        rosterService
          .getAllEmployeesForRoster("COOK_PROFILE_LEADS")
          .then((res) => {
            setOwners(res);
          })
         fetchOptions(); 
    }, [])


  const cookFilters = [
    <TextInput sx={{ width: "150px" }} label="First Name" source="firstName" alwaysOn />,
    <TextInput sx={{ width: "150px" }} label="Last Name" source="lastName" alwaysOn />,
    <TextInput sx={{ width: "150px" }} label="Phone Number" source="mobile" alwaysOn />,
    <TextInput sx={{ width: "150px" }} label="Cook Id" source="cookId" alwaysOn />,
    <AutocompleteArrayInput
      label="Preff. Working Cities"
      source="preferredCities"
      alwaysOn
      sx={{ width: "180px" }}
      choices={allCities? allCities.map((eachCity:any) => {
        return {
          id: eachCity?.name,
          name: eachCity?.name,
        };
      }):[]}
    />,
    <SelectArrayInput
      label="Onboarding Status"
      source="onboardingStatus"
      alwaysOn
      sx={{ width: "180px" }}
      choices={Object.values(FreshLeadStatus).map((key) => {
        return {
          id: key,
          name: key,
        };
      })}
    />,
    <SelectInput
      label="Cook Type"
      source="isMgCook"
      alwaysOn
      sx={{ width: "180px" }}
      emptyText="Clear Filter"
      choices={[
        { id: 'MG_COOK', name: 'MG_COOK' },
        { id: 'PART_TIME', name: 'PART_TIME' },
      ]}
    />,
    <SelectArrayInput
      label="Onboarding SubStatus"
      source="onboardingSubStatus"
      alwaysOn
      choices={Object.values(AllCookOnBoardingSubStatus).map((key) => {
        return {
          id: key,
          name: key,
        };
      })}
    />,
    <SelectArrayInput
      label="Work Status"
      source="workStatus"
      alwaysOn
      sx={{ width: "180px" }}
      choices={Object.values(CookWorkStatus).map((key) => {
        return {
          id: key,
          name: key,
        }
      })}
    />,
    <SelectArrayInput
      label="Category"
      source="cookCategory"
      alwaysOn
      sx={{ width: "180px" }}
      choices={Object.values(NewLeadCategoryEnum).map((key) => {
        return {
          id: key,
          name: key,
        }
      })}
    />,
    <SelectArrayInput
      label="Source"
      source="source"
      alwaysOn
      sx={{ width: "180px" }}
      choices={Object.values(NewVendorSource).map((key) => {
        return {
          id: key,
          name: key,
        }
      })}
    />,
    <SelectInput
      source="chefHub"
      label="Chef Hub Name"
      emptyText="Clear Filter"
      choices={!loading && choices.map((hub:any)=>{
        return {
          id: hub?.id,
          name: hub?.name,
        }
      })}
      onFocus={handleOpen}
      alwaysOn
    />,
    <TextInput label="Sub Source" sx={{ width: "150px" }} source="subSource" alwaysOn />,
    <TextInput label="Owner" sx={{ width: "150px" }} source="ownerEmail" alwaysOn />,
    <SelectInput
          label="Owner Name"
          source="ownerEmail"
          alwaysOn
          sx={{ width: "180px" }}
          emptyText="Clear Filter"
          choices={owners ? [...owners.map((owner: any) => ({
            id: owner?.adminDetails?.email,
            name: owner?.adminDetails?.name,
          })),{id: "none", name: "NONE"}] : []}
        />,
    <TextInput label="Chef Hub Id" sx={{ width: "150px" }} source="chefHub" alwaysOn />,
    <DateInput label="From Created At Date" source="startDates" alwaysOn />,
    <DateInput label="To Created At Date" source="endDates" alwaysOn />,
  ];

  useEffect(() => {
    cityService.fetchAllCities(100, 0, {isCurrentlyServing:"YES"}, {}).then((res) => {
      setAllCities(res?.data || []);
    });
  }, []);

  return (
    <>
      <List
        {...props}
        className="toolbar-custom-css"
        filters={cookFilters}
        actions={<ListActions openSideFilterShow={showSideFilter} setOpenAdvancedFilter={setOpenAdvancedFilter} setFilter={setFilter}/>}
        aside={showSideFilter ? <FilterSidebar /> : null}
      >
        <DatagridConfigurable bulkActionButtons={false}>
          {/*Will be active if custom popup is open*/}
          {!isCustomSelectOpen ? [<ShowButton key={"6"} label="Show" />]:[<ResourceSelectDialogButton />]}
          <TextField label="Cook Id" source="cookFullProfile.cookId" sortable={false} />
          <TextField label="First Name" source="cookFullProfile.firstName" sortable={false} />
          <TextField label="Last Name" source="cookFullProfile.lastName" sortable={false} />
          <FunctionField
            label="Mobile"
            render={(record: any) => (
              <CallButton
                toPhoneNumber={record?.mobile}
                userType={UserType.COOK}
              />
            )}
          />
          <FunctionField
            label="Address"
            render={(record: any) => (
              <Box minWidth={200}>{record?.cookFullProfile?.permanentAddressLine1}</Box>
            )}
          />
          <TextField label="Gender" source="cookFullProfile.gender" sortable={false} />
          <TextField label="Chef Hub Id" source="cookFullProfile.hubDetails.id" sortable={true} />
          <TextField label="Chef Hub Name" source="cookFullProfile.hubDetails.name" sortable={false} />
          <TextField label="Cook Category" source="cookFullProfile.cookCategory" sortable={false} />
          <TextField label="Preferred City" source="cookFullProfile.preferredCity" sortable={false} />,
          {!isCustomSelectOpen && [
            <TextField
              key={"7"}
              source="cookFullProfile.aadharNumber"
              label="Aadhar"
              sortable={false}
            />,
            <TextField key={"8"} label="Mode of Travel" source="cookFullProfile.modeOfTravel" sortable={false} />,
            <TextField key={"9"} label="Religion" source="cookFullProfile.religion" sortable={false} />,
          ]}

          <FunctionField
            label="Rating"
            render={(record: RaRecord) => (
              <CooksProfileLink href={record?.slug} />
            )}
          />
          <TextField source="cookFullProfile.onboardingStatus" label="Cook Onboarding Status" sortable={false} />
          <TextField source="cookFullProfile.onboardingSubStatus" label="Cook Onboarding Sub Status" sortable={false} />
          <CustomRefrenceField reference="AdminUsers" source="owner" label="Owner" />
          <CustomRefrenceField reference="Hubs" label="ChefHub" source="chefHub" />,
          <TextField source="cookFullProfile.verificationStatus" label="Verification Status" />
          <TextField source="cookFullProfile.dateOfBirth" label="Date of Birth" />
          {!isCustomSelectOpen && [
            <TextField key={"1"} label="Cooking Since" source="cookFullProfile.cookingSince" sortable={false} />,
            <TextField key={"2"} label="Work Status" source="cookFullProfile.workStatus" sortable={false} />,
          ]}
          {/*Fields which will be not shown when custom popup is open goes here*/}
        </DatagridConfigurable>
        <AdvancedCookFilterDialog setOpenAdvancedFilter={setOpenAdvancedFilter} openAdvancedFilter={openAdvancedFilter} filter={filter} setFilter={setFilter}/>
      </List>
    </>
  );
};

export default Cooks;