import React, {useState} from 'react';
import {TrialsService} from "../../services/TrialServices";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    Grid, InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {useRefresh} from "react-admin";
import {DatePicker} from "@mui/x-date-pickers";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "./CustomSnackBar";
import {SlotsEnum} from "../../enums/SlotsEnum";
import moment from "moment";
import TransactionButton from './TransactionButton';
import { setCustomSelectOpen } from '../../store/reducers/customSelect';
import { useAppDispatch } from '../../store/hooks';
import ResourceSelectDialog from './ResourceSelectDialog';
import BundleList from '../Bundle/BundleList';

const trialService = new TrialsService()

interface InputProps {
    data: any;
    open: boolean;
    callback: (data: any) => void;
}


const CreateUserBundleModelFromTrial = (props: InputProps) => {
    const dispatch = useAppDispatch();
    const [date, setDate] = useState<any>(null);
    const [startSlot, setStartSlot] = useState('');
    const [bundleId, setBundleId] = useState<number>(NaN);
    const refresh = useRefresh();

    const handleCloseBundle = (data: any) => {
        if(data) {
            setBundleId(data?.id);
        }
    };

   

    let handleClose = () => {
        props.callback({date});
        setDate(null);
        setStartSlot('');
        setBundleId(NaN)
    };

    const handleSelectHouseOrBundle = () => {
        dispatch(setCustomSelectOpen({
            open: true
        }));
    }

    const handleOpen = (val: number) => {
        handleSelectHouseOrBundle();
    }

    return (
        <div>
            <ResourceSelectDialog
                name={"Bundles"}
                list={BundleList}
                onClose={handleCloseBundle}
            />
            <Dialog
            onClose={handleClose}
            open={props.open}
            scroll={"paper"}
            maxWidth="xl"
        >
            <DialogTitle>Create User Bundle from trial</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    columns={1}
                    direction={'column'}
                >
                    <FormControl fullWidth>
                        <DatePicker
                            label="Start Date"
                            value={date}
                            minDate={new Date()}
                            onChange={(value: any) => setDate(value)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: "20px" }}>
                        <InputLabel id="Start_Slot">Start Slot</InputLabel>
                        <Select
                            labelId="Start Slot"
                            id="Start Slot"
                            label="Start Slot"
                            value={startSlot}
                            onChange={(event) => {
                                setStartSlot(event.target.value)
                            }}
                        >
                            {
                                !!props?.data?.mealMapping &&
                                Array.isArray(props.data.mealMapping) &&
                                props.data.mealMapping.map((ele: any) => (
                                    <MenuItem key={ele.slot} value={ele.slot}>
                                        {ele.slot} - {ele.time}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <TransactionButton style={{ marginTop: "20px" }}
                        name={
                            bundleId
                                ? `bundleId: ${bundleId}`
                                : "Choose Bundle"
                        }
                        onClickHandler={() => handleOpen(1)}
                    />
                </Grid>
            </DialogContent>
            <DialogActions style={{ marginTop: "12px" }}>
                <Button
                    variant="contained"
                    onClick={() => {
                        if (!startSlot || !date || !bundleId) {
                            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                message: "Fill the details to proceed",
                                type: CustomSnackbarTypes.WARNING
                            });
                            return;
                        }
                        trialService.createUserBundle(props.data.id, bundleId, {
                            startSlot: startSlot as SlotsEnum,
                            startDate: moment(date).format('YYYY-MM-DD'),
                        }).then(() => {
                            refresh();
                            handleClose();
                        });
                    }}
                    size={"large"}
                >
                    Submit
                </Button>
                <Button
                    variant="contained"
                    color={"error"}
                    onClick={() => {
                        handleClose();
                    }}
                    size={"large"}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
        </div>
        
    );
};

export default CreateUserBundleModelFromTrial;
