import { GetListResult, Identifier } from "react-admin";
import HttpClient from "../HttpClient";
import { HubsResponse } from "../interfaces/Hubs/HubsInterfaces";
import LocalityResponse from "../interfaces/Locality/LocalityResponse";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";

interface CreateHubPayload {
  name: string;
  areaId: Identifier;
  lat: string;
  long: string;
}

interface AddLocalitiesPayload{
  hubId?: Identifier,
  localityIds: number[],
}
class HubServices {
  async getAllHubs(
    limit: number,
    offset: number,
    filters: any,
    sort: {},
    otherFilter?: any,
  ): Promise<GetListResult<HubsResponse>> {
    const sequalizeFilters = ConstructSequelizeFilters(filters, [
      "name",
      "areaName",
      "cityName",
    ]);
    let data = await HttpClient.get("/centaurus/hubs", {
      params: {
        limit,
        offset,
        filters: {...sequalizeFilters, ...otherFilter},
        sort,
      },
    });
    return {
      data: !!data?.data?.data ? data.data.data : [],
      total: !!data?.data?.count ? data.data.count : 0,
    };
  }

  async getAllLocalitiesForHub(
    id: string,
    limit?: number,
    offset?: number,
    filters?: any,
    sort?: {}
  ): Promise<GetListResult<LocalityResponse>> {
    let data = await HttpClient.get(`/centaurus/hubs/${id ?? 1}/locality`, {
      params: {
        limit,
        offset,
        filters,
        sort,
      },
    });
    return {
      data: !!data?.data ? data?.data : [],
      total: !!data?.data?.count ? data.data.count : 0,
    };
  }
  async createHub(payload: CreateHubPayload) {
    let areaId = payload.areaId;
    let lat = payload.lat;
    let long = payload.long
    if (!areaId) {
      throw new Error("Area must be Selected");
    }
    if(parseFloat(lat)>90||parseFloat(lat)<-90){
      throw new Error("Latitude should be in range of -90 to 90");
    }
    if(parseFloat(long)>180||parseFloat(long)<-180){
      throw new Error("Longitude should be in range of -180 to 180");
    }
    const res = await HttpClient.post(`/centaurus/hubs`, payload);
    return res;
  }

  async addLocalitiesToHub(payload: AddLocalitiesPayload) {
    let hubId = payload.hubId;
    if (!hubId) {
      throw new Error("Hub ID not Found");
    }
    delete payload.hubId;
    const res = await HttpClient.post(
      `/centaurus/hubs/${hubId}/locality`,
      payload
    );
    return res;
  }

  async deleteLocalityFromHub(id: number) {
    const res = await HttpClient.delete(`/centaurus/hubs/mapping/${id}`);
    return res;
  }
}

export default HubServices;
