import { Close } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Portal,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useRefresh } from "react-admin";
import { PaymentMethod } from "../../enums/PaymentMethod";
import UserBundleService from "../../services/BundleService";

interface InputProps {
  data: any;
  open: boolean;
  callback: (data: any) => void;
}

export const ActivateBundleDialog = (props: InputProps) => {
  const [amountPaid, setAmountPaid] = useState<string>("0");
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [paymentDetails, setPaymentDetails] = useState<string>("");
  let [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const userBundleService = new UserBundleService();

  const refresh = useRefresh();

  let handleClose = () => {
    props.callback({
      amountPaid,
    });
  };
  let handleSnackbarClose = () => {
    setSnackBarOpen(false);
  };
  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={props.open}
        scroll={"paper"}
        maxWidth="xl"
      >
        <DialogTitle>Activate Bundle</DialogTitle>
        <DialogContent>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 2, right: 5 }}
          >
            <Close />
          </IconButton>
          <Grid
            container
            columns={1}
            rowSpacing={0.9}
            style={{ padding: "12px 12px 12px 12px" }}
          >
            <FormControl style={{ width: 300 }} fullWidth>
              <TextField
                label="Amount Paid"
                id="AmountPaid"
                value={amountPaid}
                style={{ marginTop: "20px" }}
                onChange={(event) => setAmountPaid(event.target.value)}
              />
              <FormControl style={{ marginTop: "20px"}}>
                <InputLabel id="select-label">Payment Method</InputLabel>
                <Select
                  autoWidth={false}
                  className="input_field"
                  labelId="select-label"
                  id="select-label"
                  defaultValue="Payment Method"
                  value={paymentMethod}
                  onChange={(event) => setPaymentMethod(event.target.value)}
                >
                  {Object.values(PaymentMethod).map((val) => {
                    return (
                      <MenuItem key={val} value={val}>
                        {val}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextField
                label="Payment Details"
                id="paymentDetails"
                value={paymentDetails}
                style={{ marginTop: "20px" }}
                onChange={(event) => setPaymentDetails(event.target.value)}
              />
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              if (
                !amountPaid ||
                Number(amountPaid) < Number(props.data?.quotedNetPrice)
              ) {
                setSnackBarOpen(true);
                return;
              }
              let payload = {
                paidByUser: amountPaid,
                paymentMethod: paymentMethod,
                paymentDetails: paymentDetails
              }
              userBundleService
                .activateBundle(props.data.id, payload)
                .then(() => {
                  handleClose();
                  refresh();
                });
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              handleClose();
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Portal>
        <Snackbar
          open={snackBarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
        >
          <Alert severity="warning">
            Enter amount greater than Quoted Net Price
          </Alert>
        </Snackbar>
      </Portal>
    </div>
  );
};
