import {
  Grid,
  Button,
  TextField,
  // FormControl,
  // InputLabel,
  // Select,
  // MenuItem,
} from "@mui/material";
import { useState } from "react";
import CkMuiDialog from "../Common/CkMuiDialog/CkMuiDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { CookService } from "../../services/CookServices";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import { useNavigate } from "react-router-dom";
// import newVendorSubResource from "../../utils/NewVendorSubResource";
// import { NewVendorSource } from "../../enums/NewVendorSource";

const cookService = new CookService();

const CookCreate = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [newLeadPhoneNumber, setNewLeadPhoneNumber] = useState<string>();
  // const [source, setSource] = useState<string>("");
  // const [subSource, setSubSource] = useState<string>("");

  const navigate = useNavigate();

  const closeDialog = () => {
    setDialogOpen(false);
    setNewLeadPhoneNumber("");
    // setSource("");
    // setSubSource("");
  };

  const handleSubmit = () => {
    if (
      newLeadPhoneNumber &&
      newLeadPhoneNumber.match("[0-9]{10}") &&
      newLeadPhoneNumber.trim().length === 10
      // && source && subSource
    ) {
      cookService
        .createCook({ mobile: newLeadPhoneNumber })
        .then((res) => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "New Lead Created Successfully",
            type: CustomSnackbarTypes.SUCCESS,
          });
          navigate(`/Cooks/${res?.id}/show`);
        })
        .catch((err) => {
          console.log(err);
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: err?.response?.data?.error,
            type: CustomSnackbarTypes.ERROR,
          });
        })
        .finally(() => {
          closeDialog();
        });
    } else {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Invalid Phone Number",
        type: CustomSnackbarTypes.ERROR,
      });
    }
  };

  return (
    <div>
      <CkMuiDialog
        isDialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        onDialogClose={() => {
          setNewLeadPhoneNumber("");
        }}
        title={"Create New Lead"}
        primaryLabel={"Submit"}
        primaryAction={handleSubmit}
        secondaryLabel={"Close"}
        secondaryAction={closeDialog}
        isLoading={false}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              sx={{ minWidth: 210 }}
              id="outlined-basic"
              label="Phone Number"
              type={"number"}
              inputProps={{ maxLength: 10 }}
              variant="outlined"
              value={newLeadPhoneNumber || ""}
              onChange={(e) => setNewLeadPhoneNumber(e.target.value)}
              name="newLeadPhoneNumber"
            />
            {/* <FormControl sx={{ minWidth: 210 }}>
              <InputLabel id="demo-simple-select-label">
                Source of lead
              </InputLabel>
              <Select
                name="subSource"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={source}
                label="Source of Lead"
                onChange={(e) => setSource(e.target.value)}
              >
                {
                  Object.values(NewVendorSource).map((source) => (
                    <MenuItem key={source} value={source}>{source}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            {
              source && (source === NewVendorSource.OFFLINE || source === NewVendorSource.ONLINE) &&
              <FormControl sx={{ minWidth: 210 }}>
                <InputLabel id="demo-simple-select-label">
                  Subsource of Lead
                </InputLabel>
                <Select
                  name="subSource"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subSource}
                  label="Subsource of Lead"
                  onChange={(e)=>setSubSource(e.target.value)}
                >
                  {newVendorSubResource(source).map((e: any) => (
                    <MenuItem key={e} value={e}>
                      {e}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            } */}
          </Grid>
        </Grid>
      </CkMuiDialog>
      <Button
        size="small"
        variant="contained"
        onClick={() => {
          setDialogOpen(true);
        }}
        startIcon={<AddCircleOutlineIcon />}
      >
        Create
      </Button>
    </div>
  );
};

export default CookCreate;
