import React, { useEffect, useState } from "react";
import { TempReplacementService } from "../../services/TemporaryReplacement";
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import TemporaryReplacementAllocationMetaShow from "./TemporaryReplacementAllocationMetaShow";

const temporaryReplacement = new TempReplacementService();

interface InputProps {
  trId: number;
}

const TemporaryReplacementAllocationTab = (props: InputProps) => {
  let [subData, setSubData] = useState<any>(null);
  let [isLoading, setIsLoading] = useState<boolean>();

  const refreshData = (trId: number) => {
    setIsLoading(true);
    temporaryReplacement
      .trAllocationCook(trId)
      .then((data) => {
        setSubData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    refreshData(props.trId);
  }, [props.trId]);

  return (
    <div>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <div>
          {subData &&
            Array.isArray(subData) &&
            (subData.length > 0 ? (
              <TemporaryReplacementAllocationMetaShow data={subData}/>
            ) : (
              <EmptyListPlaceholder message="No Allocation Data Found" />
            ))}
        </div>
      )}
    </div>
  );
};

export default TemporaryReplacementAllocationTab;
