import {Stack} from '@mui/material';
import {Datagrid, DateField, List, SelectInput, TextField, DateInput, TextInput, useRecordContext, useRefresh} from 'react-admin'
import CustomReferenceField from '../Common/CustomReferenceField'
import TransactionButton from '../Common/TransactionButton';
import React, {useState} from "react";
import {RefundServiceV2} from "../../services/RefundServiceV2";
import {RefundResponseV2} from "../../interfaces/Refund/RefundResponseV2";
import {UserBundleRefundsStatus} from "../../enums/UserBundleRefundsStatus";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {PayoutDialogV2} from "./PayoutDialogV2";

const refundServiceV2 = new RefundServiceV2();

const InitiateRefund = (props: {id: number}) => {
  const refresh = useRefresh();
  const handleClick = () => {
      EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
          message: "Are you sure to approve this refund!",
          onClose: (confirmed: boolean) => {
              if (confirmed) {
                  refundServiceV2.initiateRefundV2(props.id).then(refresh);
              }
          },
          title: "Are you sure?"
      });

  };
  return (
    <TransactionButton
      onClickHandler={handleClick}
      name={"Initiate Refund"}
    />
  );
};

const RetryRefund = (props: {id: number}) => {
  const refresh = useRefresh();
  const handleClick = () => {
      EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
          message: "Are you sure to retry this refund!",
          onClose: (confirmed: boolean) => {
              if (confirmed) {
                  refundServiceV2.retryRefundV2(props.id).then(refresh)
              }
          },
          title: "Are you sure?"
      });

  };
  return (
    <TransactionButton
      onClickHandler={handleClick}
      name={"Retry Refund"}
    />
  );
};

const MarkAsApproved = (props: {id: number}) => {
  const refresh = useRefresh();
  const handleClick = () => {
      EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
          message: "Are you sure to approve this refund!",
          onClose: (confirmed: boolean) => {
              if (confirmed) {
                  refundServiceV2.approveUserRefundV2(props?.id).then(refresh);
              }
          },
          title: "Are you sure?"
      });
  };
  return (
    <TransactionButton
      onClickHandler={handleClick}
      color="success"
      name={"Mark Approved"}
    />
  );
};

const MarkRejected = (props: {id: number}) => {
    const refresh = useRefresh();
    const handleClick = () => {
        EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
            message: "Are you sure to reject this refund!",
            onClose: (confirmed: boolean) => {
                if (confirmed) {
                    refundServiceV2.rejectUserRefundV2(props?.id).then(refresh);
                }
            },
            title: "Are you sure?"
        });
    };
    return (
        <TransactionButton
            onClickHandler={handleClick}
            color="error"
            name={"Mark Rejected"}
        />
    );
};


const RenderCTAButton = (props: any) => {
  const record: RefundResponseV2 = useRecordContext();
  return (
    <Stack direction={"row"} gap={2}>
      {[UserBundleRefundsStatus.PENDING].includes(record.status) && (
          <>
              <MarkAsApproved id={record.id}  />
              <MarkRejected id={record.id}  />
          </>
      )}
      {[UserBundleRefundsStatus.APPROVED].includes(record.status) && (
        <>
            {record?.razorpayOrderId && (
                <InitiateRefund id={record.id} />
            )}
            <TransactionButton
                onClickHandler={() => {
                    props.setPayOutDialog(true);
                    props.setSelectedData(record);
                    props.setIsRetry(false);
                }}
                name={"Initiate Payout"}
            />
        </>
      )}
      {[UserBundleRefundsStatus.PROCESSING_FAILED].includes(record.status) && (
        <>
            {record?.razorpayOrderId && (
                <RetryRefund id={record.id} />
            )}
            <TransactionButton
                onClickHandler={() => {
                    props.setPayOutDialog(true);
                    props.setSelectedData(record);
                    props.setIsRetry(true);
                }}
                name={"Retry Payout"}
            />
        </>
      )}
    </Stack>
  );
};
const filters = [
    <DateInput label="Start Date" source="createdAt" alwaysOn />,
    <DateInput label="End Date" source="updatedAt" alwaysOn />,
    <TextInput label="Customer Phone" source="customerPhone" alwaysOn />,
    <TextInput label="Refunded By" source="processedBy" alwaysOn />,
    <SelectInput
        label="Filter by status"
        source="status"
        alwaysOn
        emptyText="Clear Filter"
        choices={
            Object.values(UserBundleRefundsStatus)
                .map(key => {
                    return {
                        id: key,
                        name: key
                    }
                })
        }
    />,
];
export const RefundListV2 = (props: RefundResponseV2) => {
    const [selectedData, setSelectedData] = useState<RefundResponseV2>();
    const [payOutDialog, setPayOutDialog] = useState<boolean>(false);
    const [isRetry, setIsRetry] = useState<boolean>(false);
  return (
      <>
          <List  {...props} filters={filters}>
              <Datagrid bulkActionButtons={false}>
                  <TextField source="id" />
                  <CustomReferenceField label="House" listName={"Houses"} source={"houseId"} />
                  <TextField source="customerPhone" />
                  <TextField source="amount" />
                  <TextField source="razorpayOrderId" />
                  <CustomReferenceField label="User Bundle" listName={"UserBundles"} source={"userBundleMappingId"}/>
                  <CustomReferenceField label="Refunded By" listName={"AdminUsers"} source={"processedBy"}/>
                  <TextField source="processedByEmail" />
                  <TextField source="status" />
                  <TextField source="reason" />
                  <DateField source="createdAt" />
                  <DateField source="updatedAt" />
                  <RenderCTAButton
                      setSelectedData={setSelectedData}
                      setPayOutDialog={setPayOutDialog}
                      setIsRetry={setIsRetry}
                  />
              </Datagrid>
          </List>
          {selectedData && (
              <PayoutDialogV2
                  data={selectedData}
                  open={payOutDialog}
                  isRetry={isRetry}
                  callback={() => {
                      setPayOutDialog(false);
                      setSelectedData(undefined);
                  }}
              />
          )}
      </>
  )
}
