import React, { useEffect } from 'react';
import {
    Box,
    Button,
    Dialog, Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import {CookSubscriptionResponse} from "../../interfaces/UserBundleMapping/CookSubscriptionForBundle";
import {ReasonSubReasonResponse} from "../../interfaces/ReasonSubReason/ReasonSubReasonResponse";

type InputProps = {
    open: boolean;
    onClose: () => void;
    subscriptionDetails?: CookSubscriptionResponse;
    reasonSubReasonData: ReasonSubReasonResponse[];
    handleContinueClicked: (reason: string, subReason: string, comment: string) => void;
}
const InitiatePRRequestDialog = (props: InputProps) => {
    const [reason, setReason] = React.useState<string>('');
    const [subReason, setSubReason] = React.useState<string>('');
    const [comment, setComment] = React.useState<string>('');

    useEffect(()=>{
        setReason('');
        setSubReason('');
        setComment('');
    },[])

    const handleReasonChange = (event: SelectChangeEvent) => {
        setReason(event?.target?.value);
    }
    const handleSubreasonChange = (event: SelectChangeEvent) => {
        setSubReason(event?.target?.value);
    }
    const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComment(event?.target?.value);
    }
    return (
        <Dialog open={props?.open} onClose={props?.onClose} sx={{
            '& .MuiPaper-root': {
                py: '20px',
                px: '20px',
                minWidth: '450px',
                maxWidth: '650px',
            },
        }}>
            {/*Current Cook Details */}
            <Box mb={'10px'}>
                {props?.subscriptionDetails ? (
                    // if current cook is available
                    <Box>
                        <Typography fontWeight={600} letterSpacing={'1px'} fontSize={'16px'} mb={'10px'}>Current
                            Cook</Typography>
                        <Box display={'flex'} gap={'50px'}>
                            <Box>
                                <Typography fontWeight={500} mb={'10px'}>Cook Id</Typography>
                                <Typography>{props?.subscriptionDetails?.cookId}</Typography>
                            </Box>
                            <Box>
                                <Typography fontWeight={500} mb={'10px'}>Name</Typography>
                                <Typography>{`${props?.subscriptionDetails?.cook?.fname} ${props?.subscriptionDetails?.cook?.lname}`}</Typography>
                            </Box>
                            <Box>
                                <Typography fontWeight={500} mb={'10px'}>Phone Number</Typography>
                                <Typography>{props?.subscriptionDetails?.cook?.mobile}</Typography>
                            </Box>
                            <Box>
                                <Typography fontWeight={500} mb={'10px'}>Start Date</Typography>
                                <Typography>{props?.subscriptionDetails?.startDate}</Typography>
                            </Box>
                            <Box>
                                <Typography fontWeight={500} mb={'10px'}>Start Slot</Typography>
                                <Typography>{props?.subscriptionDetails?.startSlot}</Typography>
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    // if no current cook available
                    <Box>
                        <Typography fontWeight={500} mb={'10px'}>No Cook Available</Typography>
                    </Box>
                )}
            </Box>
            <Divider sx={{mb: '30px'}}/>
            {/*PR Request Form */}
            <Box>
                {/*Reason Dropdown */}
                <FormControl fullWidth variant={'outlined'} size={'medium'}>
                    <InputLabel id="reason-label" >Select Reason For Changing Cook</InputLabel>
                    <Select
                        labelId="reason-label"
                        id="reason-select"
                        value={reason}
                        label="Select Reason For Changing Cook"
                        onChange={(event: SelectChangeEvent) => handleReasonChange(event)}
                    >
                        {Array.from(new Set(props?.reasonSubReasonData?.map((value: ReasonSubReasonResponse) => value.reason))).map((value: string) => {
                            return (
                                <MenuItem key={value} value={value}>{value}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>

                {/*SubReason DropDown and a text field for sub reason if other is selected*/}
                {reason && reason!=="Other"&&(
                    <Box>
                        <FormControl fullWidth variant={'outlined'} size={'medium'}>
                            <InputLabel id="sub-reason-label">Select Sub Reason For Changing Cook</InputLabel>
                            <Select
                                labelId="sub-reason-label"
                                id="sub-reason-select"
                                value={subReason}
                                label="Select Sub Reason For Changing Cook"
                                onChange={(event: SelectChangeEvent) => handleSubreasonChange(event)}
                            >
                                {Array.from(new Set(props?.reasonSubReasonData?.filter((value: ReasonSubReasonResponse) => value?.reason === reason)?.map((value: ReasonSubReasonResponse) => value?.subReason))).map((value: string) => {
                                    return (
                                        <MenuItem key={value} value={value}>{value}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                )}

                {/*Comments text field */}
                    <FormControl fullWidth>
                    <TextField
                        variant={'outlined'}
                        size={'medium'}
                        label={'Comments'}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCommentChange(event)}
                    />
                </FormControl>
                {/*Continue Button */}
                <Box display={'flex'} justifyContent={'center'} mt={'20px'}>
                    <Button
                        sx={{
                            width: '100px',
                            textTransform: 'none',
                            fontSize: '16px',
                            letterSpacing: '1px',
                        }}
                        variant={'contained'}
                        color={'primary'}
                        disabled = {!reason || (reason!=="Other" && !subReason) || !comment}
                        onClick={() => props?.handleContinueClicked(reason, subReason, comment)}
                    >
                        Continue
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
}
export default InitiatePRRequestDialog;