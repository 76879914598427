import { Grid, Button } from "@mui/material";
import { useState } from "react";
import UserBundleService from "../../services/BundleService";
import BundleList from "../Bundle/BundleList";
import CkMuiDialog from "./CkMuiDialog/CkMuiDialog";
import ResourceSelectDialog from "./ResourceSelectDialog";
import { useAppDispatch } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { CustomSnackbarTypes } from "./CustomSnackBar";

const userBundleService = new UserBundleService();

interface InputProps {
  data: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  refresh: any;
}

interface RenewBundleInterface {
  bundleId: number | null,
  includeFreeTr: boolean,
  bundleName: string | null
}

const initalState: RenewBundleInterface = {
  bundleId: null,
  includeFreeTr: true,
  bundleName: null,
};

const RenewBundleDialog = (props: InputProps) => {
  const [renewBundleData, setRenewBundleData] = useState<RenewBundleInterface>(initalState);
  const [renewResourceSelect, setRenewResourceSelect] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleSelectBundle = (toOpen: boolean) => {
    dispatch(
      setCustomSelectOpen({
        open: toOpen,
      })
    );
  };

  const handleCloseRenew = (recordData: any) => {
    setRenewResourceSelect(false);
    setRenewBundleData({
      ...renewBundleData,
      bundleId: recordData?.id,
      bundleName: recordData?.name,
    });
  };

  return (
    <CkMuiDialog
      isDialogOpen={props.open}
      setDialogOpen={props.setOpen}
      onDialogClose={() => {
        props.refresh();
      }}
      title={"Renew Bundle"}
      primaryLabel={"Submit"}
      primaryAction={() => {
        if (!renewBundleData?.bundleId) {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Bundle Not Selected",
            type: CustomSnackbarTypes.ERROR,
          });
          return;
        }
        let payload: { [key: string]: any } = {
          bundleId: renewBundleData?.bundleId,
          includeFreeTr: renewBundleData?.includeFreeTr
        };
        if (props?.data?.includeFreeTr === true) {
          payload.includeFreeTr = true;
        }
        userBundleService
          .renewUserBundle(props?.data?.id, payload)
          .then(() => {
            props.refresh();
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
              message: "Successful",
              type: CustomSnackbarTypes.SUCCESS,
            });
          })
          .finally(() => {
            setRenewBundleData(initalState);
            handleSelectBundle(false);
            props.setOpen(false);
          });
      }}
      secondaryLabel={"Close"}
      secondaryAction={() => {
        props.setOpen(false);
        setRenewBundleData(initalState);
        handleSelectBundle(false);
      }}
      isLoading={false}
    >
      {renewResourceSelect && (
        <ResourceSelectDialog
          name={"Bundles"}
          list={BundleList}
          onClose={handleCloseRenew}
        />
      )}
      <Grid container>
        {props?.data?.includeFreeTr === false && (
          <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ marginRight: "1rem" }}>Choose New Pricing?: </div>
              <label
                htmlFor="yes"
              >
                <div style={{ display: "flex", marginRight: "0.5rem" }}>
                  <input
                    onChange={() => {
                      setRenewBundleData({
                        ...renewBundleData,
                        includeFreeTr: true,
                      });
                    }}
                    checked={renewBundleData.includeFreeTr}
                    id="yes"
                    style={{ marginRight: "0.2rem" }}
                    type="checkbox"
                  />
                  <div>YES(Includes Free TR)</div>
                </div>
              </label>
              <label
                htmlFor="no"
              >
                <div style={{ display: "flex" }}>
                  <input
                    onChange={() => {
                      setRenewBundleData({
                        ...renewBundleData,
                        includeFreeTr: false,
                      });
                    }}
                    checked={!renewBundleData.includeFreeTr}
                    id="no"
                    style={{ marginRight: "0.2rem" }}
                    type="checkbox"
                  />
                  <div>NO</div>
                </div>
              </label>
            </div>
          </Grid>
        )}
        {renewBundleData.bundleName && (
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <p
              style={{ fontSize: "1.2rem", marginTop: 0, marginBottom: "2rem" }}
            >{`Selected Bundle: ${renewBundleData?.bundleName}`}</p>
          </Grid>
        )}
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Button
            variant="outlined"
            onClick={() => {
              setRenewResourceSelect(true);
              handleSelectBundle(true);
            }}
          >
            {renewBundleData?.bundleId ? "Change Bundle" : "Select Bundle"}
          </Button>
        </Grid>
      </Grid>
    </CkMuiDialog>
  );
};

export default RenewBundleDialog;
