import { SlotsEnum } from "../../enums/SlotsEnum";

export default class UpdateDateAndSlotsDto {
    public startDate!: string;
    public startSlot!: SlotsEnum;

    constructor(startDate: string, startSlot: SlotsEnum){
        this.startDate = startDate;
        this.startSlot = startSlot;
    }
}