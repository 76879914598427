import React from "react";
import { FormControl, InputLabel, MenuItem, Select, useMediaQuery } from "@mui/material";
import { ShortTimeEnum } from "../../enums/ShortTimeEnum";
import { SlotsEnum } from "../../enums/SlotsEnum";
import BufferTime from "../../enums/BufferTime";
import {DynamicPricingSlotEnum} from "../../enums/common";

export const MealMappingInput = (props: any) => {
  const [buffer, setBuffer] = React.useState<any>();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  
    return (
      <div style={{display:"flex", margin:"20px 0",justifyContent:"space-between", flexDirection: isSmall ? "column" : "row"}}>
        <FormControl style={{ width: "180px" }}>
          <InputLabel id="VisitTime">Visit Time</InputLabel>
          <Select
            disabled={props?.disabled?.visitTime || false}
            labelId="VisitTime"
            id="visitTime"
            label="VisitTime"
            value={props.data?.time}
            onChange={(event: any) => {
              props.setVisitTime(props.index, event.target.value);
            }}
          >
            {Object.values(ShortTimeEnum).map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ width: "180px"}}>
          <InputLabel id="VisitSlot">Visit Slot</InputLabel>
          <Select
            disabled={props?.disabled?.visitSlot || false}
            labelId="VisitSlot"
            id="visitSlot"
            label="VisitSlot"
            value={props.data?.slot}
            onChange={(event: any) => {
              props.setVisitSlot(props.index, event.target.value);
            }}
          >
            {Object.values(props?.isDynamicPricing ? DynamicPricingSlotEnum : SlotsEnum).map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ width: "180px" }}>
          <InputLabel id="BufferTime">Buffer Time</InputLabel>
          <Select
            disabled={props?.disabled?.bufferTime || false}
            labelId="BufferTime"
            id="bufferTime"
            label="BufferTime"
            value={props.data?.bufferMinutes || buffer || ''}
            onChange={(event: any) => {
              setBuffer(event.target.value)
              props.setBufferTime(props.index, event.target.value);
            }}
          >
            {Object.values(BufferTime).map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
}
