import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select, Table, TableBody, TableCell, TableHead, TableRow,
  TextField, Typography,
} from "@mui/material";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import { useRefresh } from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import PermanentReplacementService from "../../services/PermanentReplacementService";
import { useAppDispatch } from "../../store/hooks";
import { markFailedPR } from "../../store/reducers/prMarkFailed";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { UserWantServiceInFuture } from "../../enums/UserWantServiceInFutureEnum";
import UserBundleService from "../../services/BundleService";
import {UserBundlesResponse} from "../../interfaces/UserBundleMapping/UserBundlesResponse";
import {SlotsEnum} from "../../enums/SlotsEnum";
import moment from 'moment';
import {DatePicker} from "@mui/x-date-pickers";

interface InputProps {
  prId: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  userBundleMapping: UserBundlesResponse;
}

interface item {
  category: string;
  subCategory: string[];
}

const categoryItems: item[] = [
  {
    category: "Account closed due to support",
    subCategory: [
      "No proper resolution within SLA",
      "VAS Service not provided",
      "Wrong PR created",
      "No follow-up on feedback",
      "No TR after several requests",
      "PR not created",
      "Update not given to the customer",
    ],
  },
  {
    category: "Account closed due to Cook",
    subCategory: [
      "Cook Behaviour",
      "Taste Issue after multiple trials",
      "Food Quality Issue",
      "Leaves and Lateness after multiple trials",
      "Cook did not reach in several trials",
      "Cook Hygiene Issue",
      "Cook Salary issue",
      "Cook Travelling",
      "Uninformed leaves; Not ready to continue",
      "Cook did not reach when the customer is already angry",
    ],
  },
  {
    category: "Account closed due to Supply Gap",
    subCategory: [
      "Find Cook failed 1st Trial",
      "Find Cook failed 2nd Trial",
      "Out of Service area",
      "Gender preference Issue",
      "Time Preference Issues",
    ],
  },
  {
    category: "The account closed due to the customer",
    subCategory: [
      "Religion Issue",
      "Hired Full-time cook",
      "Travelling",
      "Shifting to a non-serviceable area",
      "The customer didn't like the cook after giving multiple trials",
      "Price Issues",
      "The customer never responded to our calls at renewals",
      "Customer-specific issue",
    ],
  },
  {
    category: "Dis-intermediation",
    subCategory: [
      "The customer promised to renew, but not responding",
      "The customer hired the cook directly.",
    ],
  },
  {
    category: "Others",
    subCategory: [],
  },
];

const permanentReplacementService = new PermanentReplacementService();
const userBundleService = new UserBundleService();

const MarkFailedPRDialog = (props: InputProps) => {
  let refresh = useRefresh();
  const dispatch = useAppDispatch();
  const [closeUserBundle, setCloseUserBundle] = useState<any>();
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [reasonSubCategory, setReasonSubCategory] = useState<string>();
  const [comment, setComment] = useState<string>();
  let [isInterested, setIsInterested] = useState<string>();
  let [refundDetails, setRefundDetails] = useState<any>(null);
  const [openBreakUp, setOpenBreakUp] = useState<boolean>(false);
  const [selectedRefund, setSelectedRefund] = useState<any>();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [endDate, setEndDate] = useState<any>(null);
  const [endSlot, setEndSlot] = useState<any>(null);
  const handleReasonCategoryChange = (event: any) => {
    setSelectedCategory(event.target.value);
  };

  const handleReasonSubCategoryChange = (event: any) => {
    setReasonSubCategory(event.target.value);
  };

  function showSubCategoryOrNot(reasonCategory: string) {
    if (reasonCategory === null) {
      return null;
    }
    if (reasonCategory?.toLowerCase() === "others" || reasonCategory?.toLowerCase() === 'other') {
      return (
        <FormControl fullWidth>
          <TextField
            value={reasonSubCategory}
            onChange={handleReasonSubCategoryChange}
            label="Write a reason"
            fullWidth
          />
        </FormControl>
      );
    } else {
      let selectedCategoryItem = categoryItems?.filter(
        (e) => e.category === reasonCategory
      )[0];
      return (
        <FormControl fullWidth>
          <InputLabel id="reason-label">Select a reason</InputLabel>
          <Select
            autoWidth={false}
            labelId="reason-label"
            id="reason-label"
            defaultValue="Select a reason"
            value={reasonSubCategory}
            onChange={handleReasonSubCategoryChange}
          >
            {selectedCategoryItem?.subCategory.map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    }
  }

  const handleSubmit = () => {
    let refundData = refundDetails?.map((bundleDetails: {refund: string, userBundleMapping: {id: number}, comment?: string}) => {
      return {
        reason: bundleDetails?.comment || '',
        refundAmount: parseFloat(Number(bundleDetails?.refund).toFixed(2)),
        userBundleMappingId: bundleDetails?.userBundleMapping?.id,
      };
    })
    let payload = {
      wantsOldCookBack: false,
      closeUserBundle: closeUserBundle === "true" ? true : false,
      closureReason: selectedCategory,
      closureSubReason: reasonSubCategory,
      notes: comment,
      isInterested: isInterested === "Yes" ? true : false,
      refundData,
    };
    let payload1 = {
      wantsOldCookBack: false,
      closeUserBundle: closeUserBundle === "true" ? true : false,
    };
    permanentReplacementService
      .markPrFailure(props.prId, closeUserBundle === "true" ? payload : payload1)
      .then((res) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Success",
          type: CustomSnackbarTypes.SUCCESS,
        });
        dispatch(markFailedPR(res));
        refresh();
        setComment("");
        setReasonSubCategory("");
        setSelectedCategory("");
        props.setOpen(false);
      });
  };

  useEffect(() => {
    if (props?.userBundleMapping && endDate && endSlot) {
      userBundleService
          .getRefundDetails(props?.userBundleMapping.id, endDate, endSlot as SlotsEnum)
          .then((res) => {
            setRefundDetails(res.data);
          });
    }
  },[props?.userBundleMapping, endDate, endSlot]);

  return (
    <>
    <Dialog open={props.open} maxWidth="lg" scroll={"paper"}>
      <DialogTitle>
        Permanent Replacement Failure?
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel id="pr">You want to close the bundle?</FormLabel>
            <RadioGroup
              row
              aria-labelledby="row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(event: any) => {
                setCloseUserBundle(event.target.value);
              }}
              value={closeUserBundle}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {closeUserBundle === "true" && (
          <>
            <FormControl fullWidth style={{ marginTop: "20px" }}>
              <InputLabel id="reson-label">
                Tell us why?
              </InputLabel>
              <Select
                labelId="reson-label"
                id="reson-label"
                defaultValue="Tell us Why?"
                value={selectedCategory}
                onChange={handleReasonCategoryChange}
              >
                {categoryItems.map((item: item) => {
                  return (
                    <MenuItem key={item.category} value={item.category}>
                      {item.category}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {showSubCategoryOrNot(selectedCategory as string)}
            <FormControl fullWidth>
              <InputLabel id="FutureService">
                User want chefkart service in future?
              </InputLabel>
              <Select
                labelId="FutureService"
                id="FutureService"
                value={isInterested}
                label="User want chefkart service in future?"
                onChange={(event: any) => {
                  setIsInterested(event.target.value);
                }}
              >
                {Object.values(UserWantServiceInFuture).map((val) => {
                  return (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              value={comment}
              label="Comment"
              multiline={true}
              onChange={(event) => {
                setComment(event.target.value);
              }}
            />
            <FormControl fullWidth>
              <DatePicker
                  label="Date"
                  inputFormat="dd MMM yyyy"
                  disableMaskedInput
                  minDate={new Date()}
                  maxDate={props?.userBundleMapping?.endDate ? new Date(props.userBundleMapping?.endDate): undefined}
                  value={endDate}
                  onChange={(value: any) => {
                    setEndDate(moment(value).format("YYYY-MM-DD"));
                  }}
                  renderInput={(params) => <TextField {...params} />}
              />
              { props?.userBundleMapping && props?.userBundleMapping?.userBundleMeta?.mealMapping.length > 0 &&  <FormControl fullWidth style={{ marginTop: "20px" }}>
                <InputLabel id="forEndSlot">End Slot</InputLabel>
                <Select
                    labelId="forEndSlot"
                    id="endSlot"
                    value={endSlot}
                    label="End Slot"
                    onChange={(event: any) => {
                      setEndSlot(event.target.value);
                    }}
                >
                  {props?.userBundleMapping && Object.values(props?.userBundleMapping?.userBundleMeta?.mealMapping).map((e: any, index) => {
                    return (
                        <MenuItem key={e} value={e}>
                          {e}
                        </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>}
            </FormControl>
            <Box sx={{width: "100%"}}>
              {!!refundDetails &&
                  Array.isArray(refundDetails) &&
                  refundDetails?.length > 0 ? (
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{'Bundle Id'}</TableCell>
                            <TableCell>{'Quoted Net Price'}</TableCell>
                            <TableCell>{'Refund Amount'}</TableCell>
                            <TableCell>{'Cook Allocation Status'}</TableCell>
                            <TableCell>{'Start Date'}</TableCell>
                            <TableCell>{'Comment'}</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {refundDetails?.map((bundleDetails, index) => {
                            return (
                                <TableRow key={bundleDetails?.userBundleMapping?.id}>
                                  <TableCell>{bundleDetails?.userBundleMapping?.bundle?.id}</TableCell>
                                  <TableCell> {bundleDetails?.userBundleMapping?.quotedNetPrice}</TableCell>
                                  <TableCell> {Number(bundleDetails?.refund).toFixed(2)}</TableCell>
                                  <TableCell>
                                    {
                                      bundleDetails?.userBundleMapping
                                          ?.cookAllocationStatus
                                    }
                                  </TableCell>
                                  <TableCell> {bundleDetails?.userBundleMapping?.startDate}</TableCell>
                                  <TableCell> {bundleDetails?.comment || ''}</TableCell>
                                  <TableCell>
                                    <Button variant={'contained'} onClick={() => {
                                      setOpenBreakUp(true);
                                      setSelectedRefund({
                                        ...bundleDetails,
                                        refund: Number(bundleDetails?.refund).toFixed(2),
                                      });
                                      setSelectedIndex(index);
                                    }}>
                                      Show Break Up
                                    </Button>
                                  </TableCell>
                                </TableRow>
                            )}
                          )}
                        </TableBody>
                      </Table>
                  ) : (
                      <Box pt={2}>
                        <Typography variant={'body1'} sx={{fontSize: 13}}>
                          {'Select the Date and slot to show price break up'}
                        </Typography>
                      </Box>
              )}
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={!refundDetails && closeUserBundle === "true"}
          onClick={() => {
            handleSubmit();
          }}
          size={"large"}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            props.setOpen(false);
            setComment("");
            setReasonSubCategory("");
            setSelectedCategory("");
          }}
          size={"large"}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog
        onClose={() => {
          setOpenBreakUp(false);
        }}
        open={openBreakUp}
        scroll={"paper"}
        maxWidth="lg"
    >
      <DialogTitle>
        Price Break UP
      </DialogTitle>
      <DialogContent sx={{minWidth: 500}}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography>{'Amount Paid by Customer'}</Typography>
          <Typography>{Number(selectedRefund?.userBundleMapping?.quotedNetPrice) - Number(selectedRefund?.userBundleMapping?.quotedDiscount)}</Typography>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography>{'Bundle Start date'}</Typography>
          <Typography>{selectedRefund?.userBundleMapping?.startDate}</Typography>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography>{'Bundle End date'}</Typography>
          <Typography>{selectedRefund?.userBundleMapping?.endDate}</Typography>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography>{'Allowed Leaves'}</Typography>
          <Typography>{selectedRefund?.userBundleMapping?.userBundleMeta?.totalLeaves}</Typography>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography>{'Customer Leaves'}</Typography>
          <Typography>{selectedRefund?.userBundleMapping?.userBundleMeta?.takenLeaves}</Typography>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography>{'Number of Chef visits made'}</Typography>
          <Typography>{selectedRefund?.userBundleMapping?.userBundleMeta?.totalVisits}</Typography>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography>{'Amount to be refunded'}</Typography>
          <TextField
              value={Number(selectedRefund?.refund)}
              onChange={(event) => {
                setSelectedRefund({
                  ...selectedRefund,
                  refund: event.target.value,
                })
              }}
              type={'number'}
          />
        </Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography>{'Comment'}</Typography>
          <TextField
              multiline={true}
              rows={4}
              placeholder={'Add your comments here'}
              value={selectedRefund?.comment || ''}
              onChange={(event) => {
                setSelectedRefund({
                  ...selectedRefund,
                  comment: event.target.value,
                })
              }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant={'contained'} color={'error'} onClick={() => setOpenBreakUp(false)}>
          {'Cancel'}
        </Button>
        <Button variant={'contained'} onClick={() => {
          let data = refundDetails;
          data[selectedIndex] = selectedRefund;
          setRefundDetails([...data]);
          setOpenBreakUp(false);

        }}>
          {'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
    </>
  );
};

export default MarkFailedPRDialog;
