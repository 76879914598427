import { Button as MUIButton, Stack } from "@mui/material";
import moment from "moment";
import { useRecordContext } from "ra-core";
import { useEffect, useState } from "react";
import {
    ArrayField,
    Button,
    Datagrid,
    DateField,
    DeleteButton,
    EditButton,
    FunctionField,
    List,
    NumberField,
    ReferenceField,
    SelectInput,
    ShowButton,
    TextField,
    TextInput,
    useRefresh,
    useStore
} from "react-admin";
import { CookAllocationStatus } from "../../enums/cookAllocationEnums";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { UserBundleMappingStatus } from "../../enums/UserBundleMappingStatus";
import EventBus from "../../EventBus";
import UserBundleService from "../../services/BundleService";
import { useAppDispatch } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import BundleList from "../Bundle/BundleList";
import { ActivateBundleDialog } from "../Common/ActivateBundleDialog";
import AssignCookOnBundleDialog from "../Common/AssignCookOnBundleDialog";
import { CancelBundleDialog } from "../Common/CancelBundleDialog";
import { CreateCxLeavesDialog } from "../Common/CreateCxLeaves";
import { CreateExtensionDialog } from "../Common/CreateExtensionDialog";
import CreateMultipleCXLeavesDialog from "../Common/CreateMultipleCXLeavesDialog";
import { CreateTrDialog } from "../Common/CreateTR";
import { CreateVasDialog } from "../Common/CreateVasDialog";
import CustomReferenceField from "../Common/CustomReferenceField";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import { PaymentLinkDailog } from "../Common/PaymentLinksDailogs";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import { ResuscitateDialog } from "../Common/ResuscitateDialog";
import { UpdateDateAndSlotDialog } from "../Common/UpdateDateAndTime";
import {ChangeBundleTimeDialog} from "../Common/ChangeBundleTimeDialog";
import CallButton from "../Common/CallButton/CallButton";
import { UserType } from "../../enums/common";

const userBundleService = new UserBundleService()

const UserBundleEditButton = () => {
    const record = useRecordContext()
    const isEditable = [UserBundleMappingStatus.CANCELLED, UserBundleMappingStatus.CLOSED, UserBundleMappingStatus.FULFILLED].includes(record.status as UserBundleMappingStatus)
    return !isEditable ? <EditButton /> : <></>
}

const SendPaymentLinkButton = (props: any) => {
    const record = useRecordContext();
    return (
        <Button
            color="secondary"
            label="Send Payment Link"
            onClick={() => {
                EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
                    message: "Are you sure you want to send the payment link?",
                    onClose: (confirmed: boolean) => {
                        if (confirmed) {
                            if (!props.active) {
                                userBundleService.sendPaymentLink(parseInt((record as any).id)).then((res) => {
                                    props.setPaymentDailog(true)
                                    props.setPaymentLinks(res.data)
                                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                        message: 'Payment link sent',
                                        type: CustomSnackbarTypes.SUCCESS
                                    })
                                })
                            }
                            else {
                                userBundleService.sendRenewalPaymentLink(parseInt((record as any).id)).then((res) => {
                                    props.setPaymentDailog(true)
                                    props.setPaymentLinks(res.data)
                                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                        message: 'Payment link sent',
                                        type: CustomSnackbarTypes.SUCCESS
                                    })
                                })
                            }
                        }
                    },
                    title: "Confirmation"
                })
            }}
        />
    )
}


export const RenderCTAButtons = (props: any) => {
    const record = useRecordContext()
    const userBundleStatus = record.status;

    const AssignCookButton = () => {

        switch (record.cookAllocationStatus) {
            case CookAllocationStatus.UNALLOCATED:
                return <CustomButton
                    name={'Assign Cook'}
                    color="error"
                    callback={(data) => {
                        props.setSelectedData(data);
                        props.setAssignCookModel(true);
                    }}
                />

            case CookAllocationStatus.ALLOCATED:
                return <CustomButton
                    name={'Change Cook'}
                    color="success"
                    callback={(data) => {
                        props.setSelectedData(data);
                        props.setAssignCookModel(true);
                    }}
                />
            case CookAllocationStatus.PARTIALLY_ALLOCATED:
                return <CustomButton
                    name={'Assign Cook'}
                    color="info"
                    callback={(data) => {
                        props.setSelectedData(data);
                        props.setAssignCookModel(true);
                    }}
                />

            default:
                return <CustomButton
                    name={'Assign Cook'}
                    callback={(data) => {
                        props.setSelectedData(data);
                        props.setAssignCookModel(true);
                    }}
                />
        }
    }

    return (
        <Stack direction={"row"} gap={2}>
            {!props.disableShow && <ShowButton />}
            {
                userBundleStatus === UserBundleMappingStatus.PENDING
                &&
                <>
                    <CustomButton
                        name={'Activate Bundle'}
                        callback={(data) => {
                            props.setSelectedData(data);
                            props.setActivateBundle(true);
                        }}
                    />
                    <SendPaymentLinkButton
                        setPaymentDailog={props.setPaymentDailog}
                        setPaymentLinks={props.setPaymentLinks} />
                    <DeleteButton />
                    {!props.disableEdit && <UserBundleEditButton />}
                </>

            }
            {
                [UserBundleMappingStatus.ACTIVE, UserBundleMappingStatus.UPCOMING].includes(userBundleStatus) &&
                <>
                    <CustomButton
                        name={'Create Cx Leaves'}
                        callback={(data) => {
                            props.setSelectedData(data);
                            props.setCxLeaveModel(true);
                        }}
                    />
                    <CustomButton
                        name={'Create Multiple Cx Leaves'}
                        callback={(data) => {
                            props.setSelectedData(data);
                            props.setIsMultipleCXLeaveModelOpen(true);
                        }}
                    />
                </>
            }
            {
                userBundleStatus === UserBundleMappingStatus.ACTIVE
                &&
                <>
                    <AssignCookButton />
                    <CustomButton
                        name={'Give Vas'}
                        callback={(data) => {
                            props.setSelectedData(data);
                            props.setCreateVas(true);
                        }}
                    />
                    <CustomButton
                        name={'Cancel'}
                        callback={(data) => {
                            props.setSelectedData(data);
                            props.setCancelBundle(true);
                        }}
                    />
                    <CustomButton
                        name={"Renew"}
                        callback={(data) => {
                            props.setSelectedData(data);
                            props.handleClickRenewOpen();
                        }}
                    />
                    <CustomButton
                        name={"Update Meal Mapping"}
                        callback={(data) => {
                            props.setSelectedData(data);
                            props.setUpdateMealMappingDialog(true);
                        }}
                    />
                    <SendPaymentLinkButton
                        setPaymentDailog={props.setPaymentDailog}
                        setPaymentLinks={props.setPaymentLinks}
                        active={true} />
                    {(!props.disableEdit && userBundleStatus !== UserBundleMappingStatus.ACTIVE) &&
                        <UserBundleEditButton />}
                </>
            }
            {
                userBundleStatus === UserBundleMappingStatus.UPCOMING
                &&
                <>
                    {!props.disableEdit && <UserBundleEditButton />}
                    <CustomButton
                        name={'Create Vas'}
                        callback={(data) => {
                            props.setSelectedData(data);
                            props.setCreateVas(true);
                        }}
                    />
                </>
            }
            {
                [UserBundleMappingStatus.FULFILLED, UserBundleMappingStatus.CLOSED, UserBundleMappingStatus.CANCELLED].includes(userBundleStatus) &&
                <CustomButton
                    name={'Resuscitate'}
                    callback={(data) => {
                        props.setSelectedData(data);
                        props.setResuscitateDailog(true);
                    }}
                />
            }
            {
                userBundleStatus === UserBundleMappingStatus.ACTIVE &&
                moment(record.startDate).isSameOrAfter(moment()) &&
                <CustomButton
                    name={'Update date and slot'}
                    callback={(data) => {
                        props.setSelectedData(data);
                        props.setUpdateDateAndSlot(true);
                    }}
                />
            }

        </Stack>
    )
}

const filters = [
    <SelectInput
        label="Filter by status"
        source="status"
        alwaysOn
        emptyText="Clear Filter"
        choices={
            Object.values(UserBundleMappingStatus)
                .map(key => {
                    return {
                        id: key,
                        name: key
                    }
                })
        }
    />,
    <SelectInput
        label="Filter by Cook Allocation"
        source="cookAllocationStatus"
        alwaysOn
        emptyText="Clear Filter"
        choices={
            Object.values(CookAllocationStatus)
                .map(key => {
                    return {
                        id: key,
                        name: key
                    }
                })
        }
    />,
    <TextInput label="Created By" source="createdBy" alwaysOn/>
];

interface InputProps {
    name: any,
    callback: (data: any) => void,
    color?: "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined
}

const CustomButton = (props: InputProps) => {
    let record = useRecordContext();
    return (
        <MUIButton
            onClick={() => {
                props.callback(record);
            }}
            size={"small"}
            color={props.color || "secondary"}
            style={{ width: "max-content", fontSize: "12px" }}
        >
            {props.name}
        </MUIButton>
    )
}

const CheckIfOldIdExist = (props: any) => {
    let record = useRecordContext();
    if (!record.oldId) {
        return null
    }
    return (
        <div>
            <p>Old</p>
        </div>
    )
}

const UserBundleMappingList = (props: any) => {
    const refresh = useRefresh()
    let [selectedData, setSelectedData] = useState<any>(null);

    let [createTrModel, setCreateTrModel] = useState<boolean>(false);
    let [customExtensionModel, setCustomExtensionModel] = useState<boolean>(false);
    let [cxLeaveModel, setCxLeaveModel] = useState<boolean>(false);
    let [assignCookModel, setAssignCookModel] = useState<boolean>(false)
    let [createVas, setCreateVas] = useState<boolean>(false)
    let [activateBundle, setActivateBundle] = useState<boolean>(false)
    let [cancelBundle, setCancelBundle] = useState<boolean>(false)
    let [paymentDailog, setPaymentDailog] = useState<boolean>(false)
    let [resuscitateDailog, setResuscitateDailog] = useState<boolean>(false)
    let [paymentLinks, setPaymentLinks] = useState<any>()
    let [updateDateAndSlot, setUpdateDateAndSlot] = useState<boolean>(false);
    let [renew, setRenew] = useState<boolean>(false)
    const [updateMealMappingDialog, setUpdateMealMappingDialog] = useState(false)
    const dispatch = useAppDispatch();

    const [, setUserBundlesForHouse] = useStore<boolean>('houseTabs_userBundlesForHouse', false)
    let [isMultipleCXLeaveModelOpen, setIsMultipleCXLeaveModelOpen] = useState<boolean>(false)
    useEffect(() => setUserBundlesForHouse(false))

    const handleSelectBundle = () => {
        dispatch(setCustomSelectOpen({
            open: true
        }));
    }

    const handleClickRenewOpen = () => {
        handleSelectBundle();
        setRenew(true);
    };


    const handleCloseRenew = (recordData: any) => {
        if (!recordData || !selectedData) {
            return;
        }
        setRenew(false)
        userBundleService
            .renewUserBundle(selectedData.id, { bundleId: recordData.id })
            .then(() => refresh())
    };

    return (
        <div>
            <PaymentLinkDailog
                data={paymentLinks}
                open={paymentDailog}
                callback={(data) => {
                    setPaymentDailog(false);
                }}
            />
            <ResuscitateDialog
                data={selectedData}
                open={resuscitateDailog}
                callback={(data) => {
                    setResuscitateDailog(false);
                }}
            />
            <ChangeBundleTimeDialog
                data={selectedData}
                open={updateMealMappingDialog}
                callback={() => {
                    setUpdateMealMappingDialog(false);
                }}
            />
            <CreateTrDialog
                data={selectedData}
                open={createTrModel}
                callback={(data) => {
                    setCreateTrModel(false);
                }}
            />
            <CreateCxLeavesDialog
                data={selectedData}
                open={cxLeaveModel}
                callback={(data) => {
                    setCxLeaveModel(false);
                }}
            />
            <CreateMultipleCXLeavesDialog
                data={selectedData}
                open={isMultipleCXLeaveModelOpen}
                callback={() => {
                    setIsMultipleCXLeaveModelOpen(false);
                }}
            />

            <CreateExtensionDialog
                data={selectedData}
                open={customExtensionModel}
                callback={() => {
                    setCustomExtensionModel(false);
                }}
            />

            <CreateVasDialog
                data={selectedData}
                open={createVas}
                callback={() => {
                    setCreateVas(false);
                }}
            />

            <ActivateBundleDialog
                data={selectedData}
                open={activateBundle}
                callback={() => {
                    setActivateBundle(false);
                }}
            />

            {cancelBundle && <CancelBundleDialog
                data={selectedData}
                open={cancelBundle}
                callback={() => {
                    setCancelBundle(false);
                }}
            />}

            {
                assignCookModel &&
                <AssignCookOnBundleDialog
                    data={selectedData}
                    open={assignCookModel}
                    callback={() => {
                        setAssignCookModel(false);
                    }}
                />
            }
            {
                renew &&
                <ResourceSelectDialog
                    name={'Bundles'}
                    list={BundleList}
                    onClose={handleCloseRenew}
                />
            }
            {
                updateDateAndSlot &&
                <UpdateDateAndSlotDialog
                    data={selectedData}
                    open={updateDateAndSlot}
                    callback={() => {
                        setUpdateDateAndSlot(false);
                    }}
                />
            }
            <List {...props} filters={filters}>
                <Datagrid
                    bulkActionButtons={false}
                >
                    <TextField source='id' />
                    <CustomReferenceField label="House" listName={"Houses"} source={"houseId"} />
                    <ReferenceField label="Bundle" source="bundleId" reference="Bundles" link="show">
                        <NumberField source='name' />
                    </ReferenceField>
                    <ArrayField sortable={false} label="User Mobile" source="house.customers">
                        <Datagrid bulkActionButtons={false}>
                            <FunctionField render={(record: any) => <CallButton toPhoneNumber={record?.mobile} userType={UserType.CUSTOMER}/>}/>
                        </Datagrid>
                    </ArrayField>
                    <DateField options={{ day: 'numeric', month: 'short', year: 'numeric' }} source='startDate' />
                    <TextField source='startSlot' />
                    <DateField options={{ day: 'numeric', month: 'short', year: 'numeric' }} source='endDate' />
                    <TextField source='endSlot' />
                    <TextField source='status' />
                    <TextField source='quotedNetPrice' />
                    <TextField label='Meal Mapping' source='userBundleMeta.mealMapping' />
                    <TextField source='cookCut' />
                    <TextField label="Created By" source="createdBy"/>
                    <DateField showTime source='createdAt' />
                    <CheckIfOldIdExist />
                    <RenderCTAButtons
                        setSelectedData={setSelectedData}
                        setCreateTrModel={setCreateTrModel}
                        setCustomExtensionModel={setCustomExtensionModel}
                        setCxLeaveModel={setCxLeaveModel}
                        setAssignCookModel={setAssignCookModel}
                        setCreateVas={setCreateVas}
                        setActivateBundle={setActivateBundle}
                        setCancelBundle={setCancelBundle}
                        handleClickRenewOpen={handleClickRenewOpen}
                        setPaymentDailog={setPaymentDailog}
                        setPaymentLinks={setPaymentLinks}
                        setResuscitateDailog={setResuscitateDailog}
                        setUpdateDateAndSlot={setUpdateDateAndSlot}
                        setIsMultipleCXLeaveModelOpen={setIsMultipleCXLeaveModelOpen}
                        setUpdateMealMappingDialog={setUpdateMealMappingDialog}
                    />
                </Datagrid>
            </List>
        </div>
    )
}

export default UserBundleMappingList;
