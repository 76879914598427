import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { UpdateStatusCookProfileDto } from "../../dtos/UpdateStatusCookProfileDto";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import {
  FreshLeadOnboardingSubStatus,
  FreshLeadStatus,
  FreshLeadSubStatusReasoningsForLostLead,
} from "../../enums/FreshLeadStatus";
import EventBus from "../../EventBus";
import FreshLeadInterface from "../../interfaces/Cook/FreshLeadInterface";
import { CookService } from "../../services/CookServices";
import {
  freshLeadSubStatusMapFunction,
  freshLeadSubStatusReasoningMapFunction,
  freshLeadSubStatusSubReasoningMapFunction,
} from "../../utils/FreshLeadStatusMapFunction";
import CkMuiDialog from "./CkMuiDialog/CkMuiDialog";
import { CustomSnackbarTypes } from "./CustomSnackBar";

const cookService = new CookService();

interface InputProps {
  cookDetail: FreshLeadInterface;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  refresh: () => void;
}

const ChangeCookStatusDialog = ({
  cookDetail,
  isOpen,
  setIsOpen,
  refresh,
}: InputProps) => {
  const [leadStatus, setLeadStatus] = useState<UpdateStatusCookProfileDto>({
    onboardingSubStatus: "",
    subStatusReasoning: "",
    subStatusSubReasoning: "",
    comment: "",
  });

  const handleDetailsChange = (e: any) => {
    if (!leadStatus) {
      return;
    }
    const updatedStatus: UpdateStatusCookProfileDto = {
      ...leadStatus,
      [e.target.name]: e.target.value,
    };
    setLeadStatus(updatedStatus);
  };

  const handleSubmit = () => {
    if (leadStatus) {
      cookService
        .editCookProfileStatus(cookDetail.cookId, leadStatus)
        .then((res) => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Cook Status Updated Successfully",
            type: CustomSnackbarTypes.SUCCESS,
          });
        })
        .catch((err) => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Something Went Wrong",
            type: CustomSnackbarTypes.ERROR,
          });
        })
        .finally(() => {
          refresh();
          setIsOpen(false);
        });
    }
  };

  const getSubStatus = (status: FreshLeadStatus) => {
    return (
      <FormControl sx={{ width: 400 }} fullWidth>
        <InputLabel id="demo-simple-select-label">Sub Status</InputLabel>
        <Select
          name="onboardingSubStatus"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={leadStatus?.onboardingSubStatus || ""}
          label="Sub Status"
          onChange={handleDetailsChange}
        >
          {freshLeadSubStatusMapFunction(status)?.map((e: any) => (
            <MenuItem key={e} value={e}>
              {e}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const getSubStatusReasonings = (subStatus: FreshLeadOnboardingSubStatus) => {
    switch (subStatus) {
      case FreshLeadOnboardingSubStatus.LOST_LEAD:
      case FreshLeadOnboardingSubStatus.JUNK_LEAD:
        return (
          <FormControl sx={{ width: 400 }} fullWidth>
            <InputLabel id="demo-simple-select-label">
              Sub Status Reasoning
            </InputLabel>
            <Select
              name="subStatusReasoning"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={leadStatus?.subStatusReasoning || ""}
              label="Sub Status Reasoning"
              onChange={handleDetailsChange}
            >
              {freshLeadSubStatusReasoningMapFunction(subStatus)?.map(
                (e: any) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        );

      default:
        return null;
    }
  };

  const getSubStatusSubReasonings = (
    subStatusReasoning: FreshLeadSubStatusReasoningsForLostLead
  ) => {
    switch (subStatusReasoning) {
      case FreshLeadSubStatusReasoningsForLostLead.UNABLE_TO_CONNECT:
      case FreshLeadSubStatusReasoningsForLostLead.NON_RESPONSIVE:
      case FreshLeadSubStatusReasoningsForLostLead.NOT_INTERESTED:
      case FreshLeadSubStatusReasoningsForLostLead.NOT_FREE_TO_WORK:
      case FreshLeadSubStatusReasoningsForLostLead.NOT_ELIGIBLE_FOR_WORK:
      case FreshLeadSubStatusReasoningsForLostLead.OTHER:
        return (
          <FormControl sx={{ width: 400 }} fullWidth>
            <InputLabel id="demo-simple-select-label">
              Sub Status Sub Reasoning
            </InputLabel>
            <Select
              name="subStatusSubReasoning"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={leadStatus?.subStatusSubReasoning || ""}
              label="Sub Status Sub Reasoning"
              onChange={handleDetailsChange}
            >
              {freshLeadSubStatusSubReasoningMapFunction(
                subStatusReasoning
              )?.map((e: any) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      default:
        return null;
    }
  };

  const leadName = () => {
    if (cookDetail?.firstName) {
      return cookDetail?.firstName + " " + cookDetail?.lastName;
    }
    return "N/A";
  };

  return (
    <CkMuiDialog
      isDialogOpen={isOpen}
      setDialogOpen={() => {}}
      onDialogClose={() => {}}
      title={"Update Lead Status"}
      primaryLabel={"Submit"}
      primaryAction={handleSubmit}
      secondaryLabel={"Close"}
      secondaryAction={() => {
        setIsOpen(false);
        setLeadStatus({
          onboardingSubStatus: "",
          subStatusReasoning: "",
          subStatusSubReasoning: "",
          comment: "",
        });
      }}
      isLoading={false}
      fullWidth={true}
    >
      <Stack spacing={2}>
        <h5 style={{ margin: 0 }}>{`Lead Name: ${leadName()}`}</h5>
        <h5>{`Status: ${cookDetail?.onboardingStatus}`}</h5>
      </Stack>
      <Grid
        sx={{ marginTop: "1rem" }}
        container
        spacing={1}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          {getSubStatus(cookDetail?.onboardingStatus as FreshLeadStatus)}
        </Grid>

        <Grid item xs={12}>
          {leadStatus?.onboardingSubStatus &&
            getSubStatusReasonings(
              leadStatus?.onboardingSubStatus as FreshLeadOnboardingSubStatus
            )}
        </Grid>
        <Grid item xs={12}>
          {leadStatus?.subStatusReasoning &&
            getSubStatusSubReasonings(
              leadStatus?.subStatusReasoning as FreshLeadSubStatusReasoningsForLostLead
            )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            sx={{ width: 400 }}
            id="outlined-textarea"
            name="comment"
            label="Comment"
            value={leadStatus?.comment || ""}
            rows={4}
            fullWidth
            multiline
            onChange={handleDetailsChange}
          />
        </Grid>
      </Grid>
    </CkMuiDialog>
  );
};

export default ChangeCookStatusDialog;
