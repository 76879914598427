import {
    Datagrid, DateField, List, ShowButton, TextField} from 'react-admin';
import { useAppSelector } from '../../store/hooks';
import ResourceSelectDialogButton from '../Common/ResourceSelectDialogButton';


const VasList = () => {
    const isCustomSelectOpen = useAppSelector((state) => state.customSelect.value.open);
    return (
        <List>
            <Datagrid bulkActionButtons={false}>
                {/*Will be active if custom popup is open*/}
                {
                    isCustomSelectOpen &&
                    <ResourceSelectDialogButton />
                }
                <TextField source="id" sortable={false}/>
                <TextField source="name" sortable={false}/>
                <TextField source="description" sortable={false}/>
                <DateField options={{day: 'numeric', month: 'short', year: 'numeric' }} source="createdAt" sortable={false}/>
                <DateField options={{day: 'numeric', month: 'short', year: 'numeric' }} source="updatedAt" sortable={false}/>
                <ShowButton/>
            </Datagrid>
        </List>
    )
}

export default VasList;