import { useRecordContext } from "ra-core";
import TransactionButton from "./TransactionButton";
import {Link} from "@mui/material";
interface Props {
    label: string,
}

const TrackerBtn = ({label}: Props) => {
    const record = useRecordContext();
    return <Link href={`#/VisitTracker?visitId=${record?.id}&date=${record?.date}&status=${JSON.stringify([record?.status])}`} target={'_self'} underline="none">
        <TransactionButton
            name={label}
            onClickHandler={() => {}}
        />
    </Link>
}
export default TrackerBtn
