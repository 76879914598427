import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import {
    TextField,
    Select,
    FormControl,
    InputLabel,
    MenuItem, Grid,
} from "@mui/material";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {OneMealService} from "../../services/OneMealService";
import {TrialsService} from "../../services/TrialServices";
import { TrialsV3Status } from "../../enums/TrialsV3Status";
import {TrialResponse} from "../../interfaces/Trials/TrialsResponse";
import CookLocationTracker from "./CookLocationTracker";
import TransactionButton from "../Common/TransactionButton";
import Op from "../../interfaces/SequalizeFilters/SequalizeTypes";
import {CookService} from "../../services/CookServices";
import VisitTrackerCard from "./VisitTrackerCard";
import {DatePicker} from "@mui/x-date-pickers";
import Skeleton from '@mui/material/Skeleton';
import {useLocation, useNavigate} from "react-router-dom";

const oneMealService = new OneMealService();
const trialsService = new TrialsService();
const cooksService = new CookService();

const TextRegular = ({title, description}: {title: string; description?: string}) => {
    return(<Typography fontSize={'0.8rem'}><b>{title}: </b>{description}</Typography>)
};

const VisitTrackerTab = (props: any) => {

    const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [trialLoading, setTrialLoading] = useState<boolean>(false);

  const [date, setDate] = useState<any>(new Date());
  const [sortedData, setSortedData] = useState<TrialResponse[]>([]);
  const [data, setData] = useState<TrialResponse[]>([])
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [status, setStatus] = useState<any>([TrialsV3Status.COOK_ALLOCATED, TrialsV3Status.COOK_CONFIRMED]);
  const [visitType, setVisitType] = useState<any>(null);
  const [visitId, setVisitId] = useState<any>('');
  const [cookId, setCookId] = useState<any>('');
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    const handleOpen = () => {
        setMenuOpen(true);
    };
    const handleClose = () => {
        setMenuOpen(false);
    };

    const updateRouter = (key: string, value: any) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set(key, value);
        navigate({ search: `?${queryParams.toString()}` });
    };

    useEffect(() => {
        if(visitId === null) return;
        const queryParams = new URLSearchParams(location.search);
        if(queryParams.get('visitId') === visitId.toString()) return;
        let timer = setTimeout(() => {
            updateRouter('visitId', visitId);
            setActiveIndex(0);
            setData([]);
        }, 800);
        return () => { clearTimeout(timer) };
        // eslint-disable-next-line
    }, [visitId]);

    useEffect(() => {
        if(cookId === null) return;
        const queryParams = new URLSearchParams(location.search);
        if(queryParams.get('cookId') === cookId.toString()) return;
        let timer = setTimeout(() => {
            updateRouter('cookId', cookId);
            setActiveIndex(0);
            setData([]);
        }, 800);
        return () => { clearTimeout(timer) };
        // eslint-disable-next-line
    }, [cookId]);


    const getAllOms = useCallback(
      () => {
        setLoading(true);
        const queryParams = new URLSearchParams(location.search);
        let selectedDate =  queryParams.get('date') || moment().format("YYYY-MM-DD");
        let status =  queryParams.get('status') || JSON.stringify([TrialsV3Status.COOK_ALLOCATED, TrialsV3Status.COOK_CONFIRMED]);
        let visitType =  queryParams.get('visitType');
        let visitId =  queryParams.get('visitId');
        let cookId =  queryParams.get('cookId');
        let filters : any = {date: selectedDate, paymentStatus: 'PAID'};
        let otherFilters: any = {};
        if(status){
            otherFilters = {
                ...otherFilters,
                status: {
                    [Op.in.toString()]: JSON.parse(status),
                },
            }
        }
        if(visitType === 'NEW_TRIAL' || visitType === 'PR_TRIAL'){
            setLoading(false);
            return;
        }
        if(visitType === 'FREE_TR'){
            otherFilters = {
                ...otherFilters,
                userLeaveMarkedId: {
                    [Op.not.toString()]: null,
                },
            }
        }
        if(visitId) {
            filters = {
                ...filters,
                id: visitId,
            }
        }
        if(cookId) {
            filters = {
                ...filters,
                cookId: cookId,
            }
        }
        oneMealService
            .getAllOneMealService(1000, 0, filters, {"field":"time","order":"ASC"}, otherFilters)
            .then(async (res) => {
                const cookId: any = [];
                await res?.data?.map((eachData: TrialResponse) => {
                    if(eachData?.cookId){
                        cookId.push(eachData?.cookId);
                    }
                    return eachData;
                });
                let cookDetails = new Map();
                if(cookId?.length) {
                    await cooksService.getCookLocation({cookId: cookId}).then(async (res: any) => {
                        await res.map((eachDetail: any) => {
                            cookDetails.set(eachDetail?.cookId, eachDetail);
                            return eachDetail;
                        })
                    });
                }
                const data = await res?.data?.map((eachData: TrialResponse) => {
                    return {...eachData, entityType: 'ONE_MEAL_SERVICE', ...cookDetails?.get(eachData?.cookId)}
                });
                await setData((prevState) => [...prevState, ...data]);
                setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
            });
      },
      [location.search]
  );

  const getAllTrials = useCallback(
      () => {
          setTrialLoading(true);
          const queryParams = new URLSearchParams(location.search);
          let selectedDate =  queryParams.get('date') || moment().format("YYYY-MM-DD");
          let status =  queryParams.get('status') || JSON.stringify([TrialsV3Status.COOK_ALLOCATED, TrialsV3Status.COOK_CONFIRMED]);
          let visitType =  queryParams.get('visitType');
          let visitId =  queryParams.get('visitId');
          let cookId =  queryParams.get('cookId');
        let filters : any = {date: selectedDate, paymentStatus: 'PAID'};
          let otherFilters: any = {};
          if(status){
              otherFilters = {
                  ...otherFilters,
                  status: {
                      [Op.in.toString()]: JSON.parse(status),
                  },
              }
          }
          if(visitType === 'OMS' || visitType === 'FREE_TR'){
              setTrialLoading(false);
              return;
          }
          if(visitType === 'NEW_TRIAL'){
              otherFilters = {
                  ...otherFilters,
                  prId: {
                      [Op.eq.toString()]: null,
                  },
              }
          }
          if(visitType === 'PR_TRIAL'){
              otherFilters = {
                  ...otherFilters,
                  prId: {
                      [Op.not.toString()]: null,
                  },
              }
          }
          if(visitId){
              filters = {
                  ...filters,
                  id: visitId,
              }
          }
          if(cookId) {
              filters = {
                  ...filters,
                  cookId: cookId,
              }
          }
         trialsService
            .getAllTrials(1000, 0, filters, {"field":"time","order":"ASC"}, otherFilters)
            .then(async (res) => {
                const cookId: any = [];
                await res?.data?.map((eachData: TrialResponse) => {
                    if(eachData?.cookId){
                        cookId.push(eachData?.cookId);
                    }
                    return eachData;
                });
                let cookDetails = new Map();
                if(cookId?.length) {
                    await cooksService.getCookLocation({cookId: cookId}).then( async (res: any) => {
                        await res.map((eachDetail: any) => {
                            cookDetails.set(eachDetail?.cookId, eachDetail);
                            return eachDetail;
                        })
                    });
                }
                const data = await res?.data?.map((eachData) => {
                    return {...eachData, entityType: 'TRIALS_V3', ...cookDetails?.get(eachData?.cookId)}
                })
                await setData((prevState) => [...prevState, ...data]);
                setTrialLoading(false);
            })
            .catch((err) => {
                setTrialLoading(false);
            });
      },
      [location?.search]
  );

    useEffect(() => {
        if(!data?.length) return;
        setSortedData([]);
        let _data = data;
        _data.sort((a: any, b: any) => {
            const momentA = moment(a?.time, 'hh:mm A');
            const momentB = moment(b?.time, 'hh:mm A');
            return momentA.isBefore(momentB) ? -1 : 1;
        });
        setSortedData([..._data]);
    }, [data]);

  useEffect(() => {
      getAllOms();
      getAllTrials();
  }, [getAllTrials, getAllOms]);

  useEffect(() => {
      if(loaded) return;
      const queryParams = new URLSearchParams(location.search);
      let selectedDate =  queryParams.get('date') || moment().format("YYYY-MM-DD");
      let status =  queryParams.get('status') || JSON.stringify([TrialsV3Status.COOK_ALLOCATED, TrialsV3Status.COOK_CONFIRMED]);
      let visitType =  queryParams.get('visitType');
      let visitId =  queryParams.get('visitId');
      let cookId =  queryParams.get('cookId');
      setDate(new Date(selectedDate));
      setStatus(JSON.parse(status));
      setVisitType(visitType);
      setVisitId(visitId);
      setCookId(cookId);
      setLoaded(true);
  }, [location?.search, loaded]);

  return (
    <React.Fragment>
      <Box sx={{ width: '100%', mt: 2 }}>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} my={2} alignItems={'center'}>
              <TextField
                  value={cookId}
                  type={'number'}
                  size={'small'}
                  disabled={loading}
                  onChange={(event) => {
                      let val = event.target.value;
                      if(val === '') {
                          setCookId('');
                      } else {
                          if (/^-?\d*\.?\d*$/.test(val) && parseFloat(val) >= 0) {
                              setCookId(Number(event.target.value));
                          }
                      }
                  }}
                  sx={{ mr: 2}}
                  variant={'filled'}
                  label={'Cook Id'}
                  InputProps={{ inputProps: { min: 0 } }}
              />
              <FormControl sx={{mr: 2, mt: '4px'}}>
                  <DatePicker
                      label="Date"
                      inputFormat="dd MMM yyyy"
                      disableMaskedInput
                      value={date}
                      disabled={loading}
                      onChange={(value) => {
                          setDate(value);
                          setActiveIndex(0);
                          setData([]);
                          updateRouter('date', moment(value).format("YYYY-MM-DD"));
                      }}
                      renderInput={(params) => <TextField {...params} />}
                  />
              </FormControl>
              <FormControl sx={{marginRight: 2}}>
                  <InputLabel id="demo-simple-select-label">
                      Status
                  </InputLabel>
                  <Select
                      name="Service"
                      label="Service"
                      size={'small'}
                      multiple={true}
                      value={status}
                      disabled={loading}
                      open={menuOpen}
                      onClose={handleClose}
                      onOpen={handleOpen}
                      onChange={(e) => {
                          handleClose();
                          setStatus(e.target.value);
                          setActiveIndex(0);
                          setData([]);
                          updateRouter('status', JSON.stringify(e.target.value));
                      }}
                      sx={{width: 150}}
                  >
                      {Object.keys(TrialsV3Status).map((key,index) => (
                          <MenuItem key={key} value={key}>
                              {key}
                          </MenuItem>
                      ))}
                  </Select>
              </FormControl>
              <FormControl sx={{marginRight: 2}}>
                  <InputLabel id="visit-type-select-label">
                      Visit Type
                  </InputLabel>
                  <Select
                      name="Visit Type"
                      label="Visit Type"
                      size={'small'}
                      value={visitType}
                      disabled={loading}
                      onChange={(e) => {
                          setVisitType(e.target.value);
                          setActiveIndex(0);
                          setData([]);
                          updateRouter('visitType', e.target.value);
                      }}
                      sx={{width: 150}}
                  >
                      <MenuItem key={'NULL'} value={''}>
                          {'CLEAR_FILTER'}
                      </MenuItem>
                      <MenuItem key={'NEW_Trial'} value={'NEW_TRIAL'}>
                          {'NEW_TRIAL'}
                      </MenuItem>
                      <MenuItem key={'OMS'} value={'OMS'}>
                          {'One Meal Service'}
                      </MenuItem>
                      <MenuItem key={'Free_TR'} value={'FREE_TR'}>
                          {'FREE TR'}
                      </MenuItem>
                      <MenuItem key={'PR_TRIAL'} value={'PR_TRIAL'}>
                          {'PR TRIAL'}
                      </MenuItem>
                  </Select>
              </FormControl>
              <TextField
                  value={visitId}
                  type={'number'}
                  size={'small'}
                  disabled={loading}
                  onChange={(event) => {
                      let val = event.target.value;
                      if(val === '') {
                          setVisitId('');
                          // updateRouter('visitId', event.target.value);
                      } else {
                          if (/^-?\d*\.?\d*$/.test(val) && parseFloat(val) >= 0) {
                              setVisitId(Number(event.target.value));
                              // updateRouter('visitId', event.target.value);
                          }
                      }
                      // setActiveIndex(0);
                      // setData([]);
                  }}
                  sx={{ mr: 2}}
                  variant={'filled'}
                  label={'Visit Id'}
                  InputProps={{ inputProps: { min: 0 } }}
              />
              <TransactionButton
                  name={'Clear Filter'}
                  onClickHandler={() => {
                      setDate([]);
                      setActiveIndex(0);
                      const emptyParams = new URLSearchParams();
                      navigate({ search: `?${emptyParams.toString()}` });
                      setDate(new Date(moment().format("YYYY-MM-DD")));
                      setStatus([TrialsV3Status.COOK_ALLOCATED, TrialsV3Status.COOK_CONFIRMED]);
                      setVisitType(null);
                      setVisitId('');
                      setCookId('');
                  }}
              />
          </Box>
        <Grid container spacing={1}>
            <Grid sm={3} md={3} xs={12} item>
                {(!loading && !trialLoading && data?.length) ? (
                    <Box sx={{border: '1px solid #d4d4d4', height: '75vh', overflowY: 'scroll', borderRadius: '6px'}}>
                        {sortedData?.map((eachData, index: number) => {
                            return (
                                <Box key={`${eachData?.id} - ${eachData?.entityType}`}
                                    sx={{
                                        p: 1,
                                        border: '1px solid #e8e8e8',
                                        cursor: 'pointer',
                                        position: 'relative',
                                        paddingTop: '35px',
                                        margin: '6px',
                                        borderRadius: '6px',
                                        ...(activeIndex === index ?
                                            {backgroundColor: '#cacaca'} :
                                            {}
                                        )
                                    }}
                                     onClick={() => { setActiveIndex(index) }}
                                >
                                    <VisitTrackerCard eachData={eachData} />
                                </Box>
                            )
                        })}
                    </Box>
                ) : ((loading || trialLoading) ? (
                    <Box>
                        {[1,2,3,4,5].map((d: number, i: number)=>
                            <Skeleton key={d} variant="rectangular" width={'100%'} height={120} sx={{mb: 1}} />
                        )}
                    </Box>
                        ) :
                    <Box sx={{p: 2, justifyContent: 'center'}}>
                        <TextRegular title={'NO SERVICES FOUND'} />
                    </Box>
                )}
            </Grid>
            <Grid sm={9} md={9} xs={12} item>
                <Box sx={{border: '1px solid #d4d4d4', height: '75vh', overflowY: 'scroll', p: 1, borderRadius: '6px'}}>
                    {(!loading && !trialLoading && data?.length) ? (
                        <CookLocationTracker cookId={data?.[activeIndex]?.cookId} visitData={data?.[activeIndex]} />
                    ) : (
                        (loading || trialLoading) ? (
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid md={4} sm={4} xs={12} item>
                                        <Skeleton variant="text" width={'50%'} sx={{ fontSize: '1.5rem', mt: 1}} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" width={'50%'} sx={{ fontSize: '1.5rem', mt: 1 }} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                    </Grid>
                                    <Grid md={4} sm={4} xs={12} item>
                                        <Skeleton variant="text" width={'50%'} sx={{ fontSize: '1.5rem', mt: 1 }} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" width={'50%'} sx={{ fontSize: '1.5rem', mt: 1 }} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                    </Grid>
                                    <Grid md={4} sm={4} xs={12} item>
                                        <Skeleton variant="text" width={'50%'} sx={{ fontSize: '1.5rem' }} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" sx={{ fontSize: '2rem', mt: 3 }} />
                                        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                                        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                                    </Grid>
                                    <Grid md={12} sm={12} xs={12} item>
                                        <Skeleton variant="rectangular" height={'300px'} sx={{mt: 3}} />
                                    </Grid>
                                </Grid>
                            </Box>
                        ) : ''
                    )}
                </Box>
            </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};
export default VisitTrackerTab;
