import { FC, useEffect, useMemo, useState } from "react";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import moment from "moment";
import "./AllocationTab.css";
import {
  Link,
  Button,
  Box,
} from "@mui/material";
import { useRecordContext, useRefresh } from "react-admin";
import AllocateTrialDialog from "../Common/AllocateTrialDialog";
import CallButton from "../Common/CallButton/CallButton";
import { UserType } from "../../enums/common";
import PartnerSlotService from "../../services/PartnerTimeSlotService";
import ShowSlotsDialog from "../Common/ShowSlotsDialog";
import AllocationContactedDialog from "../Common/AllocationContactedDialog";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {TrialsService} from "../../services/TrialServices";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";
import PreferenceAutoAllocateTrialDialog from "../Common/PreferenceAutoAllocateTrialDialog";
const MetaBaseUrl = `https://metabase.thechefkart.com/dashboard/125-chef-history-geography?id=`;
const partnerSlotService = new PartnerSlotService();
const trialsService = new TrialsService();
const TrialAutoAllocationMetaShow: FC<any> = (props: any) => {
  const [selectedData, setSelectedData] = useState<any>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openPreferenceDialog, setOpenPreferenceDialog] = useState<boolean>(false);
  const [contacted, setContacted] = useState<boolean>(false);
  const [showSlots, setShowSlots] = useState<boolean>(false);
  const record = useRecordContext(props);
  const refresh = useRefresh();
  let contactedArr:any = [];
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "cookId",
        id: "cookId",
        header: "Cook Id",
        enableHiding: false,
        maxSize: 64,
        Cell: ({ row }) => (
          <Link
            href={`/#/Cooks/${row.original.cookId}/show/`}
            target={"_blank"}
          >
            {" "}
            {row.original.cookId}{" "}
          </Link>
        ),
      },
      {
        accessorKey: "cookName",
        id: "cookName",
        header: "Cook Name",
        Cell: ({ row }) => (
            <Box display={'flex'} alignItems={'center'}>
                <p>{`${row?.original?.cookFullProfile?.firstName} ${row?.original?.cookFullProfile?.lastName}`}</p>
                {row?.original?.cookFullProfile?.onboardedAt && moment(new Date()).diff(moment(new Date(row?.original?.cookFullProfile?.onboardedAt)), 'days') < 31 && (
                    <Box ml={1} sx={{backgroundColor: '#006645', padding: '4px 10px', borderRadius: '6px', color: '#ffffff' }}>New</Box>
                )}
            </Box>
        ),
      },
      {
        accessorKey: "cookPhoneNumber",
        id: "cookPhoneNumber",
        header: "Mobile Number",
        maxSize: 72,
        Cell: ({ row }) => (
          <div className="displayFlex">
            <CallButton
              toPhoneNumber={row?.original?.cookPhoneNumber}
              userType={UserType.COOK}
            />
          </div>
        ),
      },
      // {
      //   accessorKey: "cookCategory",
      //   id: "cookCategory",
      //   header: "Category",
      //   maxSize: 72,
      //   filterVariant: "select",
      //   filterFn: "equalsString",
      //   filterSelectOptions: [
      //     { text: "Popular", value: "POPULAR" },
      //     { text: "Premium", value: "PREMIUM" },
      //   ],
      //   Cell: ({ row }) => (
      //     <div
      //       className="ck-chip"
      //       style={{
      //         backgroundColor:
      //           row.original?.cookFullProfile?.cookCategory ===
      //           props?.trail?.bundlePlan
      //             ? "#90EE90"
      //             : "",
      //       }}
      //     >
      //       {row.original?.cookFullProfile?.cookCategory}
      //     </div>
      //   ),
      // },
      // {
      //   accessorKey: "gender",
      //   id: "gender",
      //   header: "Gender Preference /Category/ ",
      //   maxSize: 220,
      //   filterVariant: "select",
      //   filterFn: "equalsString",
      //   filterSelectOptions: [
      //     { text: "Male", value: "male" },
      //     { text: "Female", value: "female" },
      //   ],
      //   Cell: ({ cell, row }) => (
      //     <div>
      //       <div
      //         className="ck-chip"
      //         style={{
      //           backgroundColor:
      //             row.original?.gender?.toLowerCase() ===
      //               props?.trail?.genderPreference?.toLowerCase() ||
      //             props?.trail?.genderPreference?.toLowerCase() === "any"
      //               ? "#90EE90"
      //               : "",
      //         }}
      //       >
      //         {row.original?.gender}
      //       </div>
      //     </div>
      //   ),
      // },
      // {
      //   accessorKey: "availability",
      //   id: "availability",
      //   header: "Availability",
      //   maxSize: 340,
      //   Filter: () => <div></div>,
      //   Cell: ({ row }) => <MealTable data={row.original.availability} />,
      // },
      // {
      //   accessorKey: "cookOnBoardingStatus",
      //   id: "cookOnBoardingStatus",
      //   header: "Cook OnBoarding Status",
      //   filterVariant: "multi-select",
      //   filterSelectOptions: CookOnBoardingTypes,
      //   maxSize: 64,
      //   Cell: ({ row }) => (
      //     <div>
      //       <div>
      //         {row?.original?.cookFullProfile?.onboardingStatus
      //           ? row?.original?.cookFullProfile?.onboardingStatus
      //           : "---"}
      //       </div>
      //     </div>
      //   ),
      // },
      // {
      //   accessorKey: "cookStatus",
      //   id: "cookStatus",
      //   header: "Cook Status",
      //   maxSize: 64,
      //   Cell: ({ row }) => (
      //     <div>
      //       <div>
      //         {row?.original?.cookFullProfile?.workStatus
      //           ? row?.original?.cookFullProfile?.workStatus
      //           : "-"}
      //       </div>
      //     </div>
      //   ),
      // },
      // {
      //   accessorKey: "localityType",
      //   header: "Locality Type",
      //   filterVariant: "select",
      //   filterFn: (row, id, filterValue) => {
      //     if (
      //       row?.original?.localityType &&
      //       Array.isArray(row?.original?.localityType) &&
      //       row?.original?.localityType.includes(filterValue)
      //     ) {
      //       return true;
      //     }
      //     return false;
      //   },
      //   filterSelectOptions: [
      //     { text: "PAST", value: "PAST" },
      //     { text: "PRIMARY", value: "PRIMARY" },
      //     { text: "WORKING", value: "WORKING" },
      //   ],
      //   Cell: ({ row }) =>
      //     row?.original?.localityType &&
      //     Array.isArray(row?.original?.localityType) ? (
      //       row?.original?.localityType.map((data: string, index: number) => {
      //         return (
      //           <p key={data + index} style={{ fontWeight: "bold" }}>
      //             {`${
      //               index === Array(row?.original?.localityType).length - 1
      //                 ? data
      //                 : data + ","
      //             }`}
      //           </p>
      //         );
      //       })
      //     ) : (
      //       <p key={"empty"}>{"--"}</p>
      //     ),
      // },
      // {
      //   accessorKey: "localityName",
      //   header: "Locality Name",
      //   Cell: ({ row }) => (<p>{`${row?.original?.localityName}`}</p>)
      // },
      {
        accessorKey: "isPrefferedByCx",
        header: "Preffered By Cx",
        Cell: ({ row }) => (<p>{`${row?.original?.isPrefferedByCx || '---'}`}</p>)
      },
      {
        accessorKey: "leadReferenceLocModelName",
        header: "Lead Reference Model Name",
        Cell: ({ row }) => (<p>{`${row?.original?.leadReferenceLocModelName || '---'}`}</p>)
      },
      {
        accessorKey: "cxPreferredSeqNo",
        header: "Customer Preferred Seq No",
        Cell: ({ row }) => (<p>{`${row?.original?.cxPreferredSeqNo || '---'}`}</p>)
      },
      // {
      //   accessorKey: "lastSlotUpdatedBy",
      //   header: "lastSlotUpdatedAt/ lastSlotUpdatedBy",
      //   filterVariant: "select",
      //   id: "lastSlotUpdatedBy",
      //   size: 100,
      //   filterFn: "equalsString",
      //   filterSelectOptions: [
      //     { text: "Admin", value: "ADMIN" },
      //     { text: "App", value: "APP" },
      //   ],
      //   Cell: ({ row }) => (
      //     <div>
      //       <div
      //         style={{
      //           border: "1px solid",
      //           padding: "2px 6px",
      //           borderRadius: "4px",
      //           textAlign: "center",
      //           fontSize: "13px",
      //         }}
      //       >
      //         {row.original?.lastSlotUpdatedAt
      //           ? moment(row.original?.lastSlotUpdatedAt).format("LLL")
      //           : "Not Updated"}
      //       </div>
      //       <div style={{ textAlign: "center" }}>
      //         {row.original.lastSlotUpdatedBy
      //           ? row.original.lastSlotUpdatedBy
      //           : "---"}
      //       </div>
      //     </div>
      //   ),
      // },
      {
        accessorKey: "metabase",
        id: "metabase",
        header: "History/ Geography",
        maxSize: 72,
        Cell: ({ row }) => (
          <Link href={MetaBaseUrl + row.original.cookId} target={"_blank"}>
            Dashboard
          </Link>
        ),
        Filter: () => <div></div>,
      },
      // {
      //   accessorKey: "isMgCook",
      //   header: "Mg Cook",
      //   id: "isMgCook",
      //   filterVariant: "checkbox",
      //   accessorFn: (originalRow) =>
      //     originalRow?.cookFullProfile?.isMgCook ? "true" : "false",
      //   Cell: ({ cell }) =>
      //     cell.getValue() === "true" ? (
      //       <span
      //         style={{
      //           textAlign: "center",
      //           border: "1px solid #ff9800",
      //           borderRadius: "10px",
      //           color: "black",
      //           backgroundColor: "#ff9800",
      //           padding: "2px 6px",
      //         }}
      //       >
      //         {"MG Chef"}
      //       </span>
      //     ) : (
      //       "Part time"
      //     ),
      // },
      // {
      //   accessorKey: "workRequestedAt",
      //   header: "Work Requested",
      //   id: "workRequestedAt",
      //   filterVariant: "checkbox",
      //   accessorFn: (originalRow) =>
      //     originalRow.workRequestedAt ? "true" : "false",
      //   Cell: ({ row, cell }) =>
      //     cell.getValue() === "true" ? (
      //       <span
      //         style={{
      //           backgroundColor: "#90ee8f",
      //           color: "#006645",
      //           padding: "4px 10px",
      //           borderRadius: "10px",
      //         }}
      //       >
      //         {"Requested for Work"}
      //       </span>
      //     ) : (
      //       "---"
      //     ),
      // },
      // {
      //   accessorKey: "overallRating",
      //   header: "Internal Rating",
      //   id: "overallRating",
      //   filterVariant: "range",
      //   filterFn: (row, id, filterValue) => {
      //     if (
      //       (filterValue[0] === "" || !filterValue[0]) &&
      //       (filterValue[1] === "" || !filterValue[1])
      //     ) {
      //       return true;
      //     }
      //     if (filterValue[0] && filterValue[1]) {
      //       if (
      //         row?.original?.chefInternalRating?.overallRating >=
      //           filterValue[0] &&
      //         row?.original?.chefInternalRating?.overallRating <= filterValue[1]
      //       ) {
      //         return true;
      //       }
      //     }
      //     if (filterValue[0] && !filterValue[1]) {
      //       if (
      //         row?.original?.chefInternalRating?.overallRating >= filterValue[0]
      //       ) {
      //         return true;
      //       }
      //     }
      //     if (!filterValue[0] && filterValue[1]) {
      //       if (
      //         row?.original?.chefInternalRating?.overallRating <= filterValue[1]
      //       ) {
      //         return true;
      //       }
      //     }
      //     return false;
      //   },
      //   Cell: ({ row }) =>
      //     row?.original?.chefInternalRating?.overallRating
      //       ? Number(row?.original?.chefInternalRating?.overallRating).toFixed(
      //           2
      //         )
      //       : "--",
      // },
      // {
      //   accessorKey: "externalRating",
      //   header: "External Rating",
      //   id: "externalRating",
      //   filterVariant: "range",
      //   filterFn: (row, id, filterValue) => {
      //     if (
      //       (filterValue[0] === "" || !filterValue[0]) &&
      //       (filterValue[1] === "" || !filterValue[1])
      //     ) {
      //       return true;
      //     }
      //     if (filterValue[0] && filterValue[1]) {
      //       if (
      //         row?.original?.cookCustomerRating?.overallRating >=
      //           filterValue[0] &&
      //         row?.original?.cookCustomerRating?.overallRating <= filterValue[1]
      //       ) {
      //         return true;
      //       }
      //     }
      //     if (filterValue[0] && !filterValue[1]) {
      //       if (
      //         row?.original?.cookCustomerRating?.overallRating >= filterValue[0]
      //       ) {
      //         return true;
      //       }
      //     }
      //     if (!filterValue[0] && filterValue[1]) {
      //       if (
      //         row?.original?.cookCustomerRating?.overallRating <= filterValue[1]
      //       ) {
      //         return true;
      //       }
      //     }
      //     return false;
      //   },
      //   Cell: ({ row }) =>
      //     row?.original?.cookCustomerRating?.overallRating
      //       ? Number(row?.original?.cookCustomerRating?.overallRating).toFixed(
      //           2
      //         )
      //       : "--",
      // },
      // {
      //   accessorKey: "categoryName",
      //   header: "Category Rating",
      //   id: "categoryName",
      //   Filter: () => <div></div>,
      //   Cell: ({ row }) =>
      //     row?.original?.chefInternalRating?.overallRating ? (
      //       <Table>
      //         <TableHead>
      //           <TableRow>
      //             <TableCell>Category Name</TableCell>
      //             <TableCell>Category Rating</TableCell>
      //           </TableRow>
      //         </TableHead>
      //         <TableBody>
      //           {row?.original?.chefInternalRating?.categoryScores?.map(
      //             (data: any, index: number) => {
      //               return (
      //                 <TableRow>
      //                   <TableCell>{data?.categoryName}</TableCell>
      //                   <TableCell>
      //                     {data?.categoryScore
      //                       ? Number(data?.categoryScore).toFixed(2)
      //                       : "--"}
      //                   </TableCell>
      //                 </TableRow>
      //               );
      //             }
      //           )}
      //         </TableBody>
      //       </Table>
      //     ) : (
      //       <div>{"--"}</div>
      //     ),
      // },
      {
        accessorKey: "leadAcceptedAt-work",
        header: "Acceptance status",
        id: "leadAcceptedAt",
        filterVariant: "checkbox",
        accessorFn: (originalRow) =>
          originalRow.leadAcceptedAt ? "true" : "false",
        maxSize: 72,
        Cell: ({ row, cell }) => (
          <div>
            {row.original.leadAcceptedAt ? (
              <span
                style={{
                  backgroundColor: "#90ee8f",
                  padding: 6,
                  borderRadius: 6,
                }}
              >
                {moment(row?.original?.leadAcceptedAt).format("LLL")}
              </span>
            ) : row.original.leadRejectedAt ? (
              <span
                style={{
                  backgroundColor: "#e53935",
                  padding: 6,
                  borderRadius: 6,
                }}
              >
                {moment(row?.original?.leadRejectedAt).format("LLL")}
              </span>
            ) : (
              <span>{"--"}</span>
            )}
          </div>
        ),
      },
      {
        accessorKey: "isInterested",
        id: "isInterested",
        header: "Is Interested",
        maxSize: 72,
        Filter: () => <div></div>,
        Cell: ({ row }) => (
          <div>
              <Button
                  style={{ margin: 10 }}
                  variant="contained"
                  disabled={row?.original?.leadAcceptedAt !== null || row?.original?.leadRejectedAt !== null}
                  color="success"
                  onClick={() => {
                      EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
                          message: "Are you sure to mark the cook as interested?",
                          onClose: (confirmed: boolean) => {
                              if (confirmed) {
                                trialsService.acceptAutoAllocationCook(Number(props?.trail?.id), Number(row?.original?.cookId)).then(() => {
                                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                        message: "Success",
                                        type: CustomSnackbarTypes.SUCCESS,
                                    });
                                })
                              }
                          },
                          title: "Confirmation"
                      });
                  }}
                  size="small"
              >
                  Is Interested
              </Button>
          </div>
        ),
      },
      {
        accessorKey: "preference",
        id: "preference",
        header: "Preference",
        maxSize: 72,
        Filter: () => <div></div>,
        Cell: ({ row }) => (
          <div>
              <Button
                  style={{ margin: 10 }}
                  variant="contained"
                  disabled={row?.original?.cxPreferredSeqNo !== null}
                  onClick={() => {
                      setOpenPreferenceDialog(true);
                      setSelectedData(row.original);
                  }}
                  size="small"
              >
                  Set Preference
              </Button>
          </div>
        ),
      },
      // {
      //   accessorKey: "contactedAt",
      //   id: "contactedAt",
      //   header: "ContactedAt",
      //   Filter: () => <div></div>,
      //   maxSize: 64,
      //   Cell: ({ row }) => (
      //     <div>
      //       <div>
      //         {row?.original?.contactedAt || contactedArr.includes(row?.original?.id)
      //             ? <Box display={'flex'} flexDirection={'column'}>
      //                 <b>Already Contacted</b>
      //                 {`(${row?.original?.callSummary})`}
      //               </Box>
      //           : <Button
      //           style={{ margin: 10 }}
      //           variant="contained"
      //           disabled={props?.data?.filter((e:any) => e?.id === row?.original?.id)[0].contactedAt}
      //           onClick={async () => {
      //             setSelectedData(row);
      //             setContacted(true);
      //           }}
      //           size="small"
      //         >
      //           Contacted
      //         </Button>}
      //       </div>
      //     </div>
      //   ),
      // },
      {
        accessorKey: "cook-slot",
        id: "cook-slot",
        header: "Cook Slot",
        maxSize: 72,
        Filter: () => <div></div>,
        Cell: ({ row }) => (
          <Button
            style={{ margin: 10 }}
            variant="contained"
            onClick={async () => {
              setShowSlots(true);
              const res = await partnerSlotService?.getCookSlots(
                row?.original?.cookId
              );
              setData(res);
            }}
            size="small"
          >
            Cook Slot
          </Button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props?.trail?.bundlePlan, props?.trail?.genderPreference, record?.status]
  );

  const [columnOrder, setColumnOrder] = useState<any>(
    columns.map((c) => c.accessorKey)
  );
  const [columnVisibility, setColumnVisibility] = useState<any>({});
  const [loaded, setLoaded] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    if (!loaded) return;
    localStorage.setItem(
      "trial-allocationTab",
      JSON.stringify({ columnOrder, columnVisibility })
    );
  }, [columnVisibility, columnOrder, loaded]);
  useEffect(() => {
    let tableData = localStorage.getItem("trial-auto-allocationTab");
    if (tableData) {
      const data = JSON.parse(tableData);
      setColumnVisibility(data.columnVisibility);
      setColumnOrder(data.columnOrder);
      setLoaded(true);
    } else {
      localStorage.setItem(
        "trial-auto-allocationTab",
        JSON.stringify({ columnOrder, columnVisibility })
      );
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
        {openDialog && (
            <AllocateTrialDialog
                data={selectedData}
                open={openDialog}
                setOpen={setOpenDialog}
                cookCut={record?.cookCut}
            />
        )}
        {openPreferenceDialog && (
            <PreferenceAutoAllocateTrialDialog
                data={selectedData}
                open={openPreferenceDialog}
                setOpen={setOpenPreferenceDialog}
            />
        )}
      <ShowSlotsDialog showSlots={showSlots} setShowSlots={setShowSlots} data={data} />
        {contacted && (
            <AllocationContactedDialog type={1} trailId={props?.trail?.id} row={selectedData} open={contacted} setOpen={setContacted} callback={(row: any) => {
                contactedArr.push(row?.original?.id);
                let modifiedData:any = props?.data?.filter((e:any) => e?.id === row?.original?.id)
                modifiedData[0].contactedAt = moment().format("LLL");
                refresh();
                props.refresh();
                setSelectedData(null);
                setContacted(false);
            }} />
        )}
      {loaded && (
        <MaterialReactTable
          columns={columns}
          // enableColumnFilters={false}
          columnFilterModeOptions={null}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          data={props.data}
          enableColumnActions={false}
          enableHiding={true}
          enableColumnOrdering
          initialState={{
            density: "compact",
            showColumnFilters: true,
          }}
          enablePagination={false}
          onColumnOrderChange={setColumnOrder}
          onColumnVisibilityChange={setColumnVisibility}
          state={{ columnOrder, columnVisibility }}
        />
      )}
    </>
  );
};
export default TrialAutoAllocationMetaShow;
