import {
    DateField,
    FunctionField,
    SimpleShowLayout,
    TextField,
} from 'react-admin';
import {ComboResponse} from "../../interfaces/Combo/ComboResponse";
import {Link} from "@mui/material";
import React from "react";
import moment from "moment";

function ComboMetaShow() {
    return (
        <SimpleShowLayout>
            <div style={{ display: "grid", gridTemplateColumns: "33% 33% 33%"}}>
                <SimpleShowLayout>
                    <TextField source="id" />
                    <FunctionField
                        emptyText="---"
                        label="Discount coupon Id"
                        sortable={false}
                        render={(record: ComboResponse) =>
                            <Link href={`/#/CouponsV2/${record?.orderDetailsData?.[record?.orderDetailsData?.length - 1]?.discountCouponId}/show`}>
                                {record?.orderDetailsData?.[record?.orderDetailsData?.length - 1]?.discountCouponId}
                            </Link>
                        }
                    />
                    <FunctionField
                        emptyText="---"
                        label="Purchased Combo Name"
                        sortable={false}
                        render={(record: ComboResponse) =>
                            record?.orderDetailsData?.[record?.orderDetailsData?.length - 1]?.discountCouponCode
                        }
                    />
                    <FunctionField
                        emptyText="---"
                        label="Coupon Status"
                        sortable={false}
                        render={(record: ComboResponse) =>
                            record?.orderDetailsData?.[record?.orderDetailsData?.length - 1]?.isActive ? 'Active' : 'InActive'
                        }
                    />
                    <TextField source="paymentStatus" />
                    <TextField source="createdByType" />
                    <DateField source="createdAt" />
                    <DateField source="updatedAt" />
                </SimpleShowLayout>
                <SimpleShowLayout>
                    <FunctionField
                        emptyText="---"
                        label="Customer Name"
                        source="customerData.fname"
                        sortable={false}
                        render={(record: ComboResponse) =>
                            [record?.customerData?.fname, record?.customerData?.lname]?.filter((each) => each !== null).join(' ')
                        }
                    />
                    <FunctionField
                        emptyText="---"
                        label="Customer Mobile"
                        sortable={false}
                        render={(record: ComboResponse) =>
                            record?.customerData?.mobile
                        }
                    />
                    <FunctionField
                        emptyText="---"
                        label="Razorpay order Id"
                        sortable={false}
                        render={(record: ComboResponse) =>
                            record?.razorpayOrderId
                        }
                    />
                    <FunctionField
                        emptyText="---"
                        label="Amount Paid"
                        sortable={false}
                        render={(record: ComboResponse) =>
                            record?.razorpayOrder?.amount
                        }
                    />
                    <FunctionField
                        emptyText="---"
                        label="Coupons Used"
                        sortable={false}
                        render={(record: ComboResponse) =>
                            record?.orderDetailsData?.[record?.orderDetailsData?.length - 1]?.discountCouponData?.totalTimesUsed
                        }
                    />
                    <FunctionField
                        emptyText="---"
                        label="Coupons remaining"
                        sortable={false}
                        render={(record: ComboResponse) =>
                            Math.floor(record?.orderDetailsData?.[record?.orderDetailsData?.length - 1]?.discountCouponData?.maximumUsageLimit - record?.orderDetailsData?.[record?.orderDetailsData?.length - 1]?.discountCouponData?.totalTimesUsed)
                        }
                    />
                    <FunctionField
                        emptyText="---"
                        label="Coupon Expiry date"
                        sortable={false}
                        render={(record: ComboResponse) =>
                            record?.orderDetailsData?.[record?.orderDetailsData?.length - 1]?.discountCouponData?.expiresAt ? moment(record?.orderDetailsData?.[record?.orderDetailsData?.length - 1]?.discountCouponData?.expiresAt).format('DD/MM/YYYY hh:mm:A') : '---'
                        }
                    />
                    <FunctionField
                        emptyText="---"
                        label="Coupon Valid For"
                        sortable={false}
                        render={(record: ComboResponse) => {
                            function getRemainingDays(targetDate: any) {
                                let target = moment(targetDate);
                                let now = moment();
                                let remainingDays = target.diff(now, 'days');
                                if (remainingDays < 0) {
                                    return "N/A";
                                } else {
                                    return remainingDays + " days remaining";
                                }
                            }
                            return record?.orderDetailsData?.[record?.orderDetailsData?.length - 1]?.discountCouponData?.expiresAt ? getRemainingDays(record?.orderDetailsData?.[record?.orderDetailsData?.length - 1]?.discountCouponData?.expiresAt) : '---'
                        }}
                    />
                </SimpleShowLayout>
                <SimpleShowLayout>

                    <FunctionField
                        emptyText="---"
                        label="Refund Amount"
                        render={(record: ComboResponse) => {
                            return record?.refundData?.amount || '---'
                        }}
                    />
                    <FunctionField
                        emptyText="---"
                        label="Refunded By"
                        render={(record: ComboResponse) => {
                            return record?.refundData?.refundedBy || '---'
                        }}
                    />
                    <FunctionField
                        emptyText="---"
                        label="Refund At"
                        render={(record: ComboResponse) => {
                            return record?.refundData?.createdAt? moment(record?.refundData?.createdAt).format('DD/MM/YYYY hh:mm:A') : '---'
                        }}
                    />
                    <FunctionField
                        emptyText="---"
                        label="Refund Reason"
                        render={(record: ComboResponse) => {
                            return record?.refundReason?.reason || '---'
                        }}
                    />
                    <FunctionField
                        emptyText="---"
                        label="Refund Sub Reason"
                        render={(record: ComboResponse) => {
                            return record?.refundReason?.subReason || '---'
                        }}
                    />
                </SimpleShowLayout>
            </div>
        </SimpleShowLayout>
    )
}

export default ComboMetaShow;
