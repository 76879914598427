import { GetListResult, GetOneResult } from "react-admin";
import HttpClient from "../HttpClient";
import { PlatformLeadResponse } from "../interfaces/Party/PlatformLeadInterface";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";
import Op from "../interfaces/SequalizeFilters/SequalizeTypes";
import moment from "moment";

class PlatformLeadServices {
  private processDateRangeFilter(filters: any) {
    let where;
    if (filters.startDate) {
      where = {
        [Op.gte.toString()]: moment(filters.startDate).toDate()
      }
    }
    if (filters.endDate) {
      if (where) {
        where = {
          ...where,
          [Op.lte.toString()]: moment(filters.endDate).toDate()
        }
      } else {
        where = {
          [Op.lte.toString()]: moment(filters.endDate).toDate()
        }
      }
    }
    return {createdAt: where};
  }

  async getAllLeads(
    limit: number,
    offset: number,
    filters: any,
    sort: {}
  ): Promise<GetListResult<PlatformLeadResponse>> {
    let filter = ConstructSequelizeFilters(filters, ['mobile','clientId'], ['product','status','paymentStatus']);
    let customFilters = this.processDateRangeFilter(filters);
    filters = {
      ...filter,
      ...customFilters,
    }
    let data = await HttpClient.get("/centaurus/platform-lead", {
      params: {
        limit,
        offset,
        filters,
        sort,
      },
    });
    return {
      data: !!data?.data?.data ? data.data.data : [],
      total: !!data?.data?.count ? data.data.count : 0,
    };
  }

  async getPlatformLeadById(
    id: number
  ): Promise<GetOneResult<PlatformLeadResponse>> {
    let data = await HttpClient.get("/centaurus/platform-lead/" + id);
    return data;
  }

  async updateLead(id: number, payload: any) {
    return await HttpClient.patch(`/centaurus/platform-lead/${id}`, payload);
  }

  async processRefundPlatform(id: number){
    let data = await HttpClient.post(`/centaurus/platform-lead/${id}/refund`)
    return data
  }

  async sendPlatformPaymentLink(id: number){
    let data = await HttpClient.post(`/centaurus/platform-lead/${id}/send-payment-link`)
    return data
  }

}

export default PlatformLeadServices;
