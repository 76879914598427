import React, {useEffect, useState} from 'react';
import {Alert, IconButton} from "@mui/material";
import {fetchAndActivate, getAll} from "firebase/remote-config";
import remoteConfig from "../../FirebaseRemoteConfig";
import ReplayIcon from '@mui/icons-material/Replay';

const UpdateAvailable = () => {
    let [isVisible, setIsVisible] = useState<boolean>(false);
    useEffect(() => {
        console.log(`APP_VERSION ${process.env.REACT_APP_VERSION}`);
        setInterval(() => {
            fetchAndActivate(remoteConfig)
                .then((activated) => {
                    return getAll(remoteConfig);
                })
                .then((res) => {
                    let latest_version = !!res['latest_version'] ? (res['latest_version'] as any)?._value || '' : '';
                    let current_app_version = process.env.REACT_APP_VERSION || '';
                    let newVersionAvailable = current_app_version<latest_version;
                    if (newVersionAvailable) {
                        setIsVisible(true);
                    } else {
                        setIsVisible(false);
                    }
                })
        },1000*60*40);
    }, []);

    if (!isVisible) {
        return null
    }

    return (
        <div
            style={{
                position: 'fixed',
                zIndex: 20,
                bottom: 20,
                right: 20
            }}
        >
            <Alert
                severity="warning"
                action={
                    <IconButton
                        color="inherit"
                        style={{
                            height: '20px',
                            width: '20px',
                            padding: '0px',
                            margin: '0px',
                            alignSelf: 'center',
                        }}
                        onClick={() => window.location.reload()}
                    >
                        <ReplayIcon/>
                    </IconButton>
                }
            >
                New Version of the app is available please reload
            </Alert>
        </div>
    );
};

export default UpdateAvailable;
