import { Box, Typography } from "@mui/material";
import React from "react";

const StagingTag = () => {
  let tag = "development";
  if (process.env.REACT_APP_ENV === "production") {
    return null;
  }
  tag = String(process.env.REACT_APP_ENV);

  return (
      <Box
        sx={{
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
          top: "9px",
          backgroundColor: "#fff",
          borderRadius: "1rem",
          padding: "4px 8px",
          textTransform: "capitalize",
          zIndex: 100,
          boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
        }}
      >
        <Typography variant="body1" fontSize="14px" fontWeight={"500"}>{tag}</Typography>
      </Box>
  );
};

export default StagingTag;
