import React, {useState} from 'react';
import {Button, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import moment from "moment";
import {CreateTxnDialog} from "../Common/CreateTxnDialog";

const VasUserMappingListForModule = (props: any) => {
    let [selectedData, setSelectedData] = useState<any>(null);
    let [createTxn, setCreateTxn] = useState<boolean>(false)

    return (
        <div>
            <CreateTxnDialog
                data={selectedData}
                open={createTxn}
                callback={() => {
                    setCreateTxn(false);
                    props.refresh()
                }}
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>User Bundle</TableCell>
                            <TableCell>Vas Name</TableCell>
                            <TableCell>Total visits</TableCell>
                            <TableCell>Start date</TableCell>
                            <TableCell>End date</TableCell>
                            <TableCell>Total taken</TableCell>
                            <TableCell>CreatedAt</TableCell>
                            <TableCell>UpdatedAt</TableCell>
                            <TableCell>Customer Name</TableCell>
                            <TableCell>Customer Address</TableCell>
                            <TableCell>Customer Mobile</TableCell>
                            <TableCell/>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.map((row: any) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell scope="row">{row?.id}</TableCell>
                                <TableCell align="center">
                                    <Link href={`/#/UserBundles/${row?.userBundleMappingId}/show`}>
                                        {row?.userBundleMappingId}
                                    </Link>
                                </TableCell>
                                <TableCell align="center">
                                    <Link href={`/#/Vas/${row?.vasId}/show`}>
                                        {row?.vas?.name}
                                    </Link>
                                </TableCell>
                                <TableCell align="center">{row?.totalVisits}</TableCell>
                                <TableCell align="center">
                                    {
                                        !!row?.startDate &&
                                        moment(row?.startDate, 'YYYY-MM-DD').format('DD MMM YYYY')
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        !!row?.endDate &&
                                        moment(row?.endDate, 'YYYY-MM-DD').format('DD MMM YYYY')
                                    }
                                </TableCell>
                                <TableCell align="center">{row?.totalTaken}</TableCell>
                                <TableCell align="center">
                                    {
                                        !!row?.createdAt &&
                                        moment(row?.createdAt).format('DD MMM YYYY hh:mm:ss a')
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        !!row?.updatedAt &&
                                        moment(row?.updatedAt).format('DD MMM YYYY hh:mm:ss a')
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    <div
                                        style={{
                                            flexDirection: 'column'
                                        }}
                                    >
                                        {
                                            !!row?.userBundleMapping?.house?.customers &&
                                            Array.isArray(row.userBundleMapping.house.customers) &&
                                            row.userBundleMapping.house.customers.map((ele: any) => {
                                                return (
                                                    <p key={ele.fname}>{ele.fname}</p>
                                                )
                                            })
                                        }
                                    </div>
                                </TableCell>
                                <TableCell align="center">{row?.userBundleMapping?.house?.address}</TableCell>
                                <TableCell align="center">
                                    <div
                                        style={{
                                            flexDirection: 'column'
                                        }}
                                    >
                                        {
                                            !!row?.userBundleMapping?.house?.customers &&
                                            Array.isArray(row.userBundleMapping.house.customers) &&
                                            row.userBundleMapping.house.customers.map((ele: any) => {
                                                return (
                                                    <p key={ele.mobile}>{ele.mobile}</p>
                                                )
                                            })
                                        }
                                    </div>
                                </TableCell>
                                <TableCell align="center">
                                    <Link href={`/#/UserBundleServiceMappings/${row.id}/show`}>
                                        <Button variant={'contained'} color={'primary'}>
                                            Show
                                        </Button>
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    {
                                        row.totalVisits > row.totalTaken &&
                                        <Button
                                          variant={'contained'}
                                          color={'secondary'}
                                          size={'small'}
                                          onClick={() => {
                                              setSelectedData(row);
                                              setCreateTxn(true);
                                          }}
                                        >
                                          <span style={{width: 'max-content'}}>Create Txn</span>
                                        </Button>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default VasUserMappingListForModule;
