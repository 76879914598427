import React, { useEffect, useState } from "react";
import { CookService } from "../../services/CookServices";
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import WeightageTableShow from "./WeightageTableShow";

const cookService = new CookService();

const WeightageTable = () => {
  let [subData, setSubData] = useState<any>(null);
  let [isLoading, setIsLoading] = useState<boolean>();

  const refreshData = () => {
    setIsLoading(true);
    cookService
      .getWeightageTable()
      .then((data) => {
        setSubData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <div>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <div>
          {subData &&
            subData?.data &&
            Array.isArray(subData?.data) &&
            (subData.data.length > 0 ? (
              <WeightageTableShow data={subData?.data} />
            ) : (
              <EmptyListPlaceholder message="No Cook Subscription Found" />
            ))}
        </div>
      )}
    </div>
  );
};

export default WeightageTable;
