import {createSlice} from "@reduxjs/toolkit";

export const trailDetailsSlice = createSlice({
    name: 'trailDetails',
    initialState: {
        value: {
            trailId: '',
        }
    },
    reducers: {
        setTrailId: (state, action) => {
            state.value.trailId = action.payload?.trailId;
        }
    }
})

export const {setTrailId} = trailDetailsSlice.actions;

export default trailDetailsSlice.reducer;
