import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { useEffect, useState } from "react";
import DateSlotComparisonDto from "../../dtos/DateSlotComparisonDto";
import { MultipleCustomerLeavesDto } from "../../dtos/MultipleCustomerLeavesCancelDto";
import { UserSubscriptionLeaveType } from "../../enums/common";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { ResolutionEnum } from "../../enums/ResolutionEnum";
import EventBus from "../../EventBus";
import BundleService from "../../services/BundleService";
import { CustomerLeavesServies } from "../../services/CustomerLeaves";
import CircularLoader from "./CircularLoader";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import CancelCustomerLeave from "./CancelCustomerLeave";
import { useShowContext } from "react-admin";
import { UserGroupsEnum } from "../../enums/UserGroupsEnum";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";
import { useAppSelector } from "../../store/hooks";
import { CookLeaveRequestedBy } from "../../enums/CookLeavesRequestBy";
import { UserCookLeaveLinkDisputeResolutionAbsenceTypeEnum } from "../../enums/UserCookLeaveLinkDisputeResolutionAbsenceTypeEnum";

interface InputProps {
  data: any;
  open: boolean;
  callback: () => void;
}
const customerLeaves = new CustomerLeavesServies();
const bundleService = new BundleService();

const CreateMultipleCXLeavesDialog = (props: InputProps) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  let [resolution, setResolution] = useState<ResolutionEnum>(
    ResolutionEnum.NONE
  );
  let [absenseType,setAbsenseType]=useState<any>(UserCookLeaveLinkDisputeResolutionAbsenceTypeEnum.COOK_NOT_AVAILABLE);
  const [dataFromDatabase, setDataFromDatabase] = useState<any>(null);
  const [isLoadingForTableOne, setIsLoadingForTableOne] =
    useState<boolean>(false);
  const [dataAfterMappingForTableOne, setDataAfterMappingForTableOne] =
    useState<MultipleCustomerLeavesDto[]>([]);
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [isLoadingForTableTwo, setIsLoadingForTableTwo] =
    useState<boolean>(false);
  const [dataAfterMappingForTableTwo, setDataAfterMappingForTableTwo] =
    useState<MultipleCustomerLeavesDto[]>([]);
  const [selectionModelForTableTwo, setSelectionModelForTableTwo] =
    useState<GridSelectionModel>([]);
  const [openAfterSubmitDialog, setOpenSubmitDialog] = useState<boolean>(false);
  let [successfullDataAfterSubmit, setSuccessFulDataAfterSubmit] =
    useState<any>(null);
  let [unsuccessfullDataAfterSubmit, setUnsuccessFulDataAfterSubmit] =
    useState<any>(null);
  const [isLoadingOnSubmit, setIsLoadingOnSubmit] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [data, setData] = useState();
  const { record } = useShowContext();
  const userDetails = useAppSelector((state) => state.userDetails.value)
  let groupsArray: string[] = userDetails.adminUser.accessGroups;
  const columnsForTableOne: GridColDef[] = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "slot", headerName: "Slot", width: 150 },
    { field: "leaveDate", headerName: "Leave Date", width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    { field: "leaveType", headerName: "Leave Type", width: 150 },
    { field: "resolution", headerName: "Resolution", width: 150 },
    {
      field: "Action",
      renderCell: (cellValues) => {
        return (
          <Button
            variant="contained"
            color="error"
            onClick={(event) => {
              setOpenDialog(true);
              event.stopPropagation();
              setData(cellValues?.row);
            }}
          >
            {"Cancel Leave"}
          </Button>
        );
      },
      width: 150,
    },
  ];

  const columnsForTableTwo: GridColDef[] = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "slot", headerName: "Slot", width: 150 },
    { field: "leaveDate", headerName: "Leave Date", width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    { field: "leaveType", headerName: "Leave Type", width: 150 },
    { field: "resolution", headerName: "Resolution", width: 150 },
  ];

  const handleSubmit = async () => {
    setIsLoadingOnSubmit(true);
    const responseSuccessfulArray: any[] = [];
    const responseUnsuccessfulArray: any[] = [];
    let countSuccess = 0;
    let countfailure = 0;
    for await (const item of dataAfterMappingForTableTwo) {
      if (item.isSelected) {
        const payload = {
          date: item.leaveDate,
          resolution: item.resolution,
          slot: item.slot,
          cookLeaveRequestedBy : CookLeaveRequestedBy.CUSTOMER,
          absenseType: absenseType, // this will be updated with user selection when the functionality is available in the API.
        };
        try {
          const data = await bundleService.createCxLeaves(
            item.userBundleMappingId,
            payload
          );
          responseSuccessfulArray.push(data.data);
          countSuccess++;
        } catch (error) {
          countfailure++;
          responseUnsuccessfulArray.push(item);
        }
      }
    }
    handleAfterSubmit();
    setOpenSubmitDialog(true);
    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
      message: `${countSuccess} leaves created successfully and ${countfailure} leaves failed to create`,
      type: CustomSnackbarTypes.WARNING,
    });
    setIsLoadingOnSubmit(false);
    setUnsuccessFulDataAfterSubmit(responseUnsuccessfulArray);
    setSuccessFulDataAfterSubmit(responseSuccessfulArray);
  };

  const handleClose = () => {
    setResolution(ResolutionEnum.NONE);
    setSelectionModelForTableTwo([]);
    setStartDate(new Date());
    setEndDate(new Date());
    props.callback();
  };

  const handleResolutionChange = (event: any) => {
    setResolution(event.target.value as ResolutionEnum);
    const dataArray = dataAfterMappingForTableTwo.map((item) => {
      if (
        (event.target.value as ResolutionEnum) === ResolutionEnum.NONE &&
        item.isSelected
      ) {
        item.resolution = event.target.value;
      } else if (
        (event.target.value as ResolutionEnum) === ResolutionEnum.EXTENSION &&
        item.isSelected
      ) {
        item.resolution = event.target.value;
      } else if (
        (event.target.value as ResolutionEnum) === ResolutionEnum.TR &&
        item.isSelected
      ) {
        if (moment(item.leaveDate).isBefore(moment(), "days")) {
          item.resolution = ResolutionEnum.EXTENSION;
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: `Cannot Assign TR to Past Leaves`,
            type: CustomSnackbarTypes.WARNING,
          });
        } else {
          item.resolution = event.target.value;
        }
      }
      return item;
    });
    setDataAfterMappingForTableTwo(dataArray);
  };

  const handleAfterSubmit = () => {
    setResolution(ResolutionEnum.NONE);
    setSelectionModelForTableTwo([]);
    setStartDate(new Date());
    setEndDate(new Date());
    handleClose();
  };

  const handleCloseForDialogTwo = () => {
    setOpenSubmitDialog(false);
  };

  useEffect(() => {
    setSelectionModelForTableTwo(() =>
      dataAfterMappingForTableTwo
        .filter((item) => item.isSelected === true)
        .map((item) => item.id)
    );
  }, [dataAfterMappingForTableTwo]);

  useEffect(() => {
    if (!props.data) {
      return;
    }
    customerLeaves
      .getAllCustLeaves(
        1000,
        0,
        {
          startDate: startDate,
          endDate: endDate,
          userBundleMappingId: props.data.id,
        },
        {}
      )
      .then((data) => {
        setDataFromDatabase(data.data);
        setDataChanged(false);
      })
      .catch((err) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Something went wrong",
          type: CustomSnackbarTypes.ERROR,
        });
        return err;
      });
  }, [props, startDate, endDate, dataChanged]);

  useEffect(() => {
    if (!dataFromDatabase) {
      return;
    }

    const handleChangeInTableTwo = (
      dataAfterMappingForTableOne: MultipleCustomerLeavesDto[]
    ) => {
      setIsLoadingForTableTwo(true);
      const dataNotToShowInTableTwo: DateSlotComparisonDto[] = [];
      for (const singleRowDataFromTableOne of dataAfterMappingForTableOne) {
        const data = new DateSlotComparisonDto();
        data.leaveDate = singleRowDataFromTableOne.leaveDate;
        data.slot = singleRowDataFromTableOne.slot;
        dataNotToShowInTableTwo.push(data);
      }

      const mealMappingArray = props?.data?.userBundleMeta?.mealMapping;
      const datesArray: string[] = [];
      const startDateMoment = moment(startDate);
      const endDateMoment = moment(endDate);
      while (startDateMoment.isSameOrBefore(endDateMoment)) {
        datesArray.push(startDateMoment.format("YYYY-MM-DD"));
        startDateMoment.add(1, "days");
      }

      const dataAfterMappingForTableTwo: MultipleCustomerLeavesDto[] = [];
      let index = 1;
      for (const singleDate of datesArray) {
        for (const singleMealMapping of mealMappingArray) {
          let resolutionToSet = "";
          if (
            resolution === ResolutionEnum.TR &&
            moment(singleDate).isBefore(moment(), "days")
          ) {
            resolutionToSet = ResolutionEnum.EXTENSION;
          } else {
            resolutionToSet = resolution;
          }
          if (singleDate === props.data.startDate) {
            let index = mealMappingArray.indexOf(props.data.startSlot);
            let indexofSingleMeal = mealMappingArray.indexOf(singleMealMapping);
            if (indexofSingleMeal < index) {
              continue;
            }
          }
          if (singleDate === props.data.endDate) {
            let index = mealMappingArray.indexOf(props.data.endSlot);
            let indexofSingleMeal = mealMappingArray.indexOf(singleMealMapping);
            if (indexofSingleMeal > index) {
              continue;
            }
          }
          let isAlreadyPresent = dataNotToShowInTableTwo.find((item) => {
            return (
              item.leaveDate.toString() === singleDate &&
              item.slot === singleMealMapping
            );
          });
          if (!isAlreadyPresent) {
            let leaveType = "";
            if (moment(singleDate).isSame(moment(), "days")) {
              leaveType = UserSubscriptionLeaveType.PRESENT;
            } else if (moment(singleDate).isBefore(moment(), "days")) {
              leaveType = UserSubscriptionLeaveType.PAST;
            } else {
              leaveType = UserSubscriptionLeaveType.FUTURE;
            }
            const data = new MultipleCustomerLeavesDto();
            data.id = index;
            data.userMobile = props.data.house.customers[0].mobile;
            data.userBundleMappingId = props.data.id;
            data.slot = singleMealMapping;
            data.leaveDate = singleDate;
            data.leaveType = leaveType;
            data.status = props.data.status;
            data.resolution = resolutionToSet as ResolutionEnum;
            data.isSelected = true;
            dataAfterMappingForTableTwo.push(data);
            index++;
          }
        }
      }
      setDataAfterMappingForTableTwo(dataAfterMappingForTableTwo);
      setIsLoadingForTableTwo(false);
    };

    setIsLoadingForTableOne(true);
    const dataAfterMappingForTableOne: MultipleCustomerLeavesDto[] = [];
    dataFromDatabase.forEach((item: any) => {
      const data = new MultipleCustomerLeavesDto();
      data.id = item.id;
      data.userMobile = item.userBundleMapping.house.customers[0].mobile;
      data.userBundleMappingId = item.userBundleMappingId;
      data.slot = item.slot;
      data.leaveDate = item.date;
      data.status = item.userBundleMapping.status;
      data.leaveType = item.leaveType;
      data.resolution = item.resolution;
      dataAfterMappingForTableOne.push(data);
    });
    setDataAfterMappingForTableOne(dataAfterMappingForTableOne);
    setIsLoadingForTableOne(false);
    handleChangeInTableTwo(dataAfterMappingForTableOne);
  }, [dataFromDatabase, props, startDate, endDate, resolution]);

  let leaveOptions = Object.values(ResolutionEnum);
  let UserCookLeaveLinkDisputeResolutionAbsenceType=Object.values(UserCookLeaveLinkDisputeResolutionAbsenceTypeEnum)

  return (
    <div>
      <CancelCustomerLeave
        open={openDialog}
        setOpen={setOpenDialog}
        data={data}
      />
      <Dialog maxWidth={"lg"} fullWidth={true} open={props.open}>
        <DialogTitle>Create Multiple Customer Leaves</DialogTitle>
        <DialogContent>
          <div>
            <Grid
              container
              spacing={2}
              style={{ padding: "10px 10px 10px 10px" }}
            >
              <Grid item xs={3}>
                <FormControl>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      inputFormat="dd MMM yyyy"
                      minDate={GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER]) ? new Date(props.data?.startDate) : moment().subtract(3, "days").toDate()}
                      maxDate={new Date(props.data?.endDate)}
                      onChange={(newValue) => {
                        setStartDate(newValue as Date);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      disableMaskedInput
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      inputFormat="dd MMM yyyy"
                      minDate={new Date(props.data?.startDate)}
                      maxDate={new Date(props.data?.endDate)}
                      onChange={(newValue) => {
                        setEndDate(newValue as Date);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      disableMaskedInput
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="forResolution" sx={{ mt: "4px" }}>
                    Resolution
                  </InputLabel>
                  <Select
                    labelId="forResolution"
                    id="Resolution"
                    value={resolution}
                    label="Resolution"
                    onChange={handleResolutionChange}
                    sx={{ mt: "8px" }}
                  >
                    {leaveOptions.map((e) => {
                      if (e === ResolutionEnum.TR) {
                        return record?.includeFreeTr ||
                          props?.data?.includeFreeTr ? (
                          <MenuItem key={e} value={e}>
                            {e}
                          </MenuItem>
                        ) : null;
                      }
                      return (
                        <MenuItem key={e} value={e}>
                          {e}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="forResolution">Absense Type</InputLabel>
                <Select
                  labelId="forResolution"
                  id="Resolution"
                  value={absenseType}
                  label="Resolution"
                  onChange={(event) => setAbsenseType(event.target.value)}
                >
                  {UserCookLeaveLinkDisputeResolutionAbsenceType.map((e) => {
                    return (
                      <MenuItem key={e} value={e}>
                        {e}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              </Grid>
            </Grid>
          </div>
          <div>
            {isLoadingForTableTwo ? (
              <CircularLoader />
            ) : (
              <Grid
                container
                spacing={2}
                style={{ padding: "10px 10px 10px 10px" }}
              >
                <Grid item>
                  <p>
                    <strong>UserBundleId : </strong>
                    {props.data ? props.data.id : "ID"}
                  </p>
                </Grid>
                <Grid item>
                  <p>
                    <strong>UserBundleName : </strong>
                    {props.data ? props.data.bundle.name : "Name"}
                  </p>
                </Grid>
              </Grid>
            )}
          </div>
          <div>
            {isLoadingForTableTwo ? (
              <CircularLoader />
            ) : (
              <div>
                {!!dataAfterMappingForTableTwo &&
                  dataAfterMappingForTableTwo.length > 0 &&
                  Array.isArray(dataAfterMappingForTableTwo) && (
                    <div
                      style={{
                        height: 400,
                        width: "100%",
                        marginBottom: "40px",
                      }}
                    >
                      <h3>Assign Customer Leaves</h3>
                      <DataGrid
                        rows={dataAfterMappingForTableTwo}
                        columns={columnsForTableTwo}
                        pageSize={50}
                        rowsPerPageOptions={[5, 50]}
                        checkboxSelection
                        selectionModel={selectionModelForTableTwo}
                        onSelectionModelChange={(itm) => {
                          const newData = dataAfterMappingForTableTwo.map(
                            (item: MultipleCustomerLeavesDto) => {
                              if (itm.includes(item.id)) {
                                return { ...item, isSelected: true };
                              }
                              return { ...item, isSelected: false };
                            }
                          );
                          setSelectionModelForTableTwo(itm);
                          setDataAfterMappingForTableTwo(newData);
                        }}
                      />
                    </div>
                  )}
              </div>
            )}
          </div>
          <hr />
          <div>
            {isLoadingForTableOne ? (
              <CircularLoader />
            ) : (
              <div>
                {!!dataAfterMappingForTableOne &&
                  dataAfterMappingForTableOne.length > 0 &&
                  Array.isArray(dataAfterMappingForTableOne) && (
                    <div
                      style={{ height: 400, width: "100%", marginTop: "40px" }}
                    >
                      <h3>Cancel Customer Leaves</h3>
                      <DataGrid
                        rows={dataAfterMappingForTableOne}
                        columns={columnsForTableOne}
                        pageSize={50}
                        rowsPerPageOptions={[5]}
                      />
                    </div>
                  )}
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color={"error"}
            onClick={handleClose}
            size={"large"}
          >
            Close
          </Button>
          <Button variant="contained" onClick={handleSubmit} size={"large"}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog maxWidth={"lg"} fullWidth={true} open={openAfterSubmitDialog}>
        <DialogTitle>Successful Customer Leaves</DialogTitle>
        {isLoadingOnSubmit ? (
          <CircularLoader />
        ) : (
          <DialogContent>
            {!!successfullDataAfterSubmit &&
              successfullDataAfterSubmit.length > 0 &&
              Array.isArray(successfullDataAfterSubmit) && (
                <div>
                  <h3>Successful Assign Request</h3>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>User Bundle Id</TableCell>
                          <TableCell>Cook Id</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Leave Type</TableCell>
                          <TableCell>Marked By User Type</TableCell>
                          <TableCell>Resolution</TableCell>
                          <TableCell>Slot</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {successfullDataAfterSubmit.map(
                          (row: any, index: number) => (
                            <TableRow key={row.id}>
                              <TableCell key={row.id}>{row.id}</TableCell>
                              <TableCell>{row.userBundleMappingId}</TableCell>
                              <TableCell>{row.cookId}</TableCell>
                              <TableCell>{row.date}</TableCell>
                              <TableCell>{row.leaveType}</TableCell>
                              <TableCell>{row.markedByUserType}</TableCell>
                              <TableCell>{row.resolution}</TableCell>
                              <TableCell>{row.slot}</TableCell>
                              <TableCell>
                                <Button
                                  size="small"
                                  onClick={() => {
                                    setOpenDialog(true);
                                    setData(row);
                                    // handleCancelLeave(row, index, true);
                                  }}
                                  variant="contained"
                                >
                                  Cancel Leave
                                </Button>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            <hr />
            {!!unsuccessfullDataAfterSubmit &&
              unsuccessfullDataAfterSubmit.length > 0 &&
              Array.isArray(unsuccessfullDataAfterSubmit) && (
                <div>
                  <h3>Unsuccesfull Assign Requests</h3>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>User Bundle Id</TableCell>
                          <TableCell>Cook Id</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Leave Type</TableCell>
                          <TableCell>Marked By User Type</TableCell>
                          <TableCell>Resolution</TableCell>
                          <TableCell>Slot</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {unsuccessfullDataAfterSubmit.map(
                          (row: any, index: number) => (
                            <TableRow key={row.id}>
                              <TableCell key={row.id}>{row.id}</TableCell>
                              <TableCell>{row.userBundleMappingId}</TableCell>
                              <TableCell>{row.cookId}</TableCell>
                              <TableCell>{row.leaveDate}</TableCell>
                              <TableCell>{row.leaveType}</TableCell>
                              <TableCell>{row.markedByUserType}</TableCell>
                              <TableCell>{row.resolution}</TableCell>
                              <TableCell>{row.slot}</TableCell>
                              <TableCell>
                                <Button
                                  disabled
                                  size="small"
                                  onClick={() => {
                                    setOpenDialog(true);
                                    setData(row);
                                    // handleCancelLeave(row, index, true);
                                  }}
                                  variant="contained"
                                >
                                  Cancel Leave
                                </Button>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
          </DialogContent>
        )}
        <DialogActions>
          <Button
            variant="contained"
            color={"error"}
            onClick={handleCloseForDialogTwo}
            size={"large"}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateMultipleCXLeavesDialog;
