import { DateField, Show, SimpleShowLayout, TextField } from "react-admin";
import CustomReferenceField from "../Common/CustomReferenceField";

const RenewalsMetaShow = () => {
  return (
    <Show actions={false}>
      <div style={{ display: "grid", gridTemplateColumns: "30% 40% 30%" }}>
        <SimpleShowLayout>
          <TextField label="Id" source="id" />
          <DateField label="Created At" source="createdAt" />
          <DateField label="Updated At" source="updatedAt" />
          <CustomReferenceField
            label="House Id"
            listName={"Houses"}
            source={"houseId"}
          />
          <TextField label="End Date" source="endDate" />
          <TextField label="Active PR" source="hasActivePr" />
          <TextField label="Escalation" source="hasEscalations" />
        </SimpleShowLayout>
      </div>
    </Show>
  );
};

export default RenewalsMetaShow;
