import Op from "../interfaces/SequalizeFilters/SequalizeTypes";

const ConstructSequelizeFilters = (
    filters: any = {},
    likeFilters: string[] = [],
    matchFilters: string[] = [],
    nullFilters: string[] = [],
    notNullFilters: string[] = [],
    inFilters: string[] = []
) => {
    let where: any = {};
    Object.keys(filters).forEach((key) => {
        if (likeFilters.includes(key)) {
            where[key] = {
                [Op.like.toString()]: `%${filters?.[key]?.toString()?.trim()}%`
            }
        }
        if (matchFilters.includes(key)) {
            where[key] = {
                [Op.eq.toString()]: filters?.[key]?.toString()?.trim()
            }
        }
        if (inFilters.includes(key)) {
            where[key] = {
                [Op.in.toString()]: filters?.[key]
            }
        }
    })
    nullFilters.forEach((filter) => {
        where[filter] = {
            [Op.eq.toString()]: null
        }
    })
    notNullFilters.forEach((filters) => {
        where[filters] = {
            [Op.not.toString()]: null
        }
    })
    return where;
}

export default ConstructSequelizeFilters;
