import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import LocalityList from "../Locality/LocalityList";
import ResourceSelectDialog from "./ResourceSelectDialog";

interface InputProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>
    callback: (data: any) => void;
}

function ApproveHouseReviewDialog(props: InputProps) {
    const [data, setData] = useState<any>()
    const [cityName, setCityName] = useState();
    const [areaName, setAreaName] = useState();
    const [localityName, setLocalityName] = useState();
    const dispatch = useAppDispatch();

    const handleClose = (d: any) => {
        setData({
            localityId: d?.id,
            cityId: d?.cityId,
            areaId: d?.areaId
        })
        setCityName(d?.cityName);
        setAreaName(d?.areaName);
        setLocalityName(d?.name)
        dispatch(setCustomSelectOpen({
            open: false
        }))
    }

    return (
        <div>
            <ResourceSelectDialog
                name={"Localities"}
                list={LocalityList}
                onClose={handleClose}
            />

            <Dialog
                open={props.open}
                scroll={"paper"}
                maxWidth="xl"
            >
                <DialogTitle>Assign cook to trial</DialogTitle>
                <DialogContent>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 10
                        }}
                    >
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => {
                                dispatch(setCustomSelectOpen({
                                    open: true
                                }))
                            }}
                        >
                            {data?.localityId ? `Selected Locality: ${data.localityId}` : `Select Locality`}
                        </Button>

                        {data?.localityId &&
                            <Stack>
                                <Stack direction={"row"} justifyContent="space-between">
                                    <p>City</p>
                                    <p>{cityName}</p>
                                </Stack>
                                <Stack direction={"row"} justifyContent="space-between">
                                    <p>Area</p>
                                    <p>{areaName}</p>
                                </Stack>
                                <Stack direction={"row"} justifyContent="space-between">
                                    <p>Locality</p>
                                    <p>{localityName}</p>
                                </Stack>
                            </Stack>
                        }

                    </div>
                </DialogContent>
                <DialogActions style={{ marginTop: "12px" }}>

                    <Button
                        variant="contained"
                        color={"primary"}
                        onClick={() => {
                            props.callback(data)
                            props.setOpen(false);
                        }}
                        size={"large"}
                    >
                        Submit
                    </Button>
                    <Button
                        variant="contained"
                        color={"error"}
                        onClick={() => {
                            props.setOpen(false);
                        }}
                        size={"large"}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ApproveHouseReviewDialog