import {
    Box, Button,
    Dialog,
    Divider,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import {UserBundlesResponse} from "../../interfaces/UserBundleMapping/UserBundlesResponse";
import {SubscriptionResponse} from "../../interfaces/UserBundleMapping/SubscriptionForBundle";
import moment from "moment";
import {DatePicker} from "@mui/x-date-pickers";
import React, {useCallback, useEffect} from "react";
import {PermanentReplacementResponse} from "../../interfaces/PermanentReplacement/PermanentReplacementResponse";
import {MealMapping} from "../../interfaces/Trials/MealMapping";
import {CookSubscriptionResponse} from "../../interfaces/UserBundleMapping/CookSubscriptionForBundle";
import PermanentReplacementService from "../../services/PermanentReplacementService";

const permanentReplacementService = new PermanentReplacementService();
type InputProps = {
    open: boolean;
    onClose: () => void;
    permanentReplacementDetails?: PermanentReplacementResponse;
    bundleDetails?: UserBundlesResponse;
    subscriptionDetails?: SubscriptionResponse;
    cookSubscriptionDetails?: CookSubscriptionResponse;
    gracePeriodDetails?:any;
    allSubscriptions?:any;
    handleContinueClicked: (selectedDate: string, selectedSlot: string, selectedOption: string) => void;
}
const PRSelectDateSlotDialog = (props: InputProps) => {
    const [selectedDate, setSelectedDate] = React.useState<string>(moment().add(2, 'days').toDate() <= moment(props?.bundleDetails?.endDate).toDate() ? moment().add(2, 'days').format('YYYY-MM-DD').toString() : '');
    const [selectedSlot, setSelectedSlot] = React.useState<string>('BL');
    const [selectedOption, setSelectedOption] = React.useState<string>(props?.cookSubscriptionDetails ? 'SAME_COOK' : '');
    const [trDateAndTimeDetails, setTrDateAndTimeDetails] = React.useState<any>({});
    const [extensionDateAndTimeDetails, setExtensionDateAndTimeDetails] = React.useState<any>({});
    const [hasActiveSubscription,setHasActiveSubscription] = React.useState<boolean>(false);
    
    
    const handleDateChange = (newValue: any) => {
        if(newValue) {
            setSelectedDate(moment(newValue).format('YYYY-MM-DD'));
        }
    }
    const handleSlotSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedSlot(event?.target?.value);
    }
    const handleOptionSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event?.target?.value);
    }

    const findHasActivePaidSubscription = useCallback(() => {
        for(let i=0; i < props?.allSubscriptions.length; i++) {
            if(props?.allSubscriptions[i].status === 'ACTIVE' && props?.allSubscriptions[i].type === 'PAID') {
                return true;
            }
        }
        return false;
    }, [props.allSubscriptions]);

    const getTrAndExtensionDetails = useCallback(() => {
        if(hasActiveSubscription) {
            if(selectedDate && selectedSlot && props?.bundleDetails?.id) {
                permanentReplacementService.getTrExtensionDateDetails(props?.bundleDetails?.id, selectedDate, selectedSlot)
                    .then((res) => {
                        if(res) {
                            setTrDateAndTimeDetails(res?.data?.trDateAndTimeDetails);
                            setExtensionDateAndTimeDetails(res?.data?.extensionDateAndTimeDetails);
                        }
                    })
            }

        }
        // eslint-disable-next-line
    }, [selectedDate, selectedSlot, selectedOption]);

    React.useEffect(() =>{
        getTrAndExtensionDetails();
    }, [getTrAndExtensionDetails]);

    useEffect(()=>{
        if(findHasActivePaidSubscription()){
            setHasActiveSubscription(true);
        }
    },[findHasActivePaidSubscription])
    return (
        <Dialog
            open={props?.open}
            onClose={props?.onClose}
            sx={{
                '& .MuiPaper-root': {
                    py: '20px',
                    px: '20px',
                    maxWidth: '750px',
                },
            }}
        >
            {/*End Dates */}
            <Box display={'flex'} gap={'50px'} mb={'10px'}>
                {/*Bundle End Date */}
                <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                    <Typography fontWeight={500}>Bundle End Date: </Typography>
                    <Typography fontWeight={400}>{moment(props?.bundleDetails?.endDate).format('DD MMM YYYY')}</Typography>
                </Box>
                {/*Subscription End Date */}
                <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                    <Typography fontWeight={500}>Subscription End Date: </Typography>
                    <Typography fontWeight={400}>{moment(props?.subscriptionDetails?.endDate).format('DD MMM YYYY')}</Typography>
                </Box>
            </Box>

            <Divider />

            {/*Select Trial Date and Slot */}
            <Box mt={'25px'}>
                <Typography mb={'10px'} fontWeight={500}>Select Trial Date and Time</Typography>
                <Box>
                    {/*Select Date */}
                    <Box display={'flex'} gap={'60px'} alignItems={'center'}>
                        <Typography>Select Date:</Typography>
                        <Box>
                            <DatePicker
                                label={"Select Date"}
                                inputFormat="dd MMM yyyy"
                                disableMaskedInput
                                // minDate={moment().add(2, 'days').toDate() <= moment(props?.bundleDetails?.endDate).toDate() ? moment().add(2, 'days').toDate() : moment(props?.bundleDetails?.endDate).toDate()}
                                maxDate={moment(props?.bundleDetails?.endDate).toDate()}
                                value={selectedDate}
                                onChange={(newValue: any) => handleDateChange(newValue)}
                                renderInput={(params: any) => <TextField variant={'outlined'} {...params} />}
                            />
                        </Box>
                    </Box>

                    {/*Select Slot */}
                    <Box display={'flex'} gap={'60px'} alignItems={'center'} mt={'10px'}>
                        <Typography>Select Slot:</Typography>
                        <Box>
                            <FormControl>
                                <RadioGroup
                                    row
                                    sx={{
                                        gap: '30px',
                                    }}
                                    value={selectedSlot}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSlotSelected(event)}
                                >
                                    {props?.bundleDetails?.mealMapping?.map((item: MealMapping) => (
                                            <FormControlLabel key={item?.slot} value={item?.slot} control={<Radio />} label={`${item?.slot} (${item?.time})`}/>
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/*Customer cook choice */}
            <Box mt={'20px'}>
                <Typography fontWeight={500}>Please Select the option, which customer wants till the trial date</Typography>
                <Box>
                    <FormControl>
                        <RadioGroup
                            value={selectedOption}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOptionSelected(event)}
                        >
                            {props?.bundleDetails?.includeFreeTr && hasActiveSubscription&& (
                                <Box display={'flex'} alignItems={'center'} gap={'20px'}>
                                    <FormControlLabel
                                        value={'TR'}
                                        control={<Radio/>}
                                        label={`Temporary Replacement (${moment(trDateAndTimeDetails?.startDate).format('DD MMM')}  ${trDateAndTimeDetails?.startTime}- ${moment(trDateAndTimeDetails?.endDate).format('DD MMM YYYY')} ${trDateAndTimeDetails?.endTime})`}
                                    />
                                    {selectedOption === 'TR' && (<Typography fontSize={'13px'} color={'#690c0c'} fontWeight={500}>Current Cook will not go from next slot</Typography>)}
                                </Box>
                            )}
                            {hasActiveSubscription && (
                                <Box display={'flex'} alignItems={'center'} gap={'20px'}>
                                    <FormControlLabel value={'EXTENSION'} control={<Radio />} label={`Extension (${moment(extensionDateAndTimeDetails?.startDate).format('DD MMM')} - ${moment(extensionDateAndTimeDetails?.endDate).format('DD MMM YYYY')} ${extensionDateAndTimeDetails?.endTime})`} />
                                    {selectedOption === 'EXTENSION' && (<Typography fontSize={'13px'} color={'#690c0c'} fontWeight={500}>Current Cook will not go from next slot</Typography>)}
                                </Box>
                            )}
                            {props?.cookSubscriptionDetails && hasActiveSubscription && (
                                <FormControlLabel value={'SAME_COOK'} control={<Radio />} label={'Continue with Current Cook'} />
                            )}
                            {
                               !hasActiveSubscription&&
                                <Box display={'flex'} justifyContent={'center'} mt={'40px'}>
                                    <Typography color={'#690c0c'} fontWeight={500}>Subscription is in grace period.</Typography>
                                </Box>
                            }
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Box>
            {/*Continue Button */}
            <Box display={'flex'} justifyContent={'center'} mt={'40px'}>
                <Button
                    sx={{
                        width: '100px',
                        textTransform: 'none',
                        fontSize: '16px',
                        letterSpacing: '1px',
                    }}
                    variant={'contained'}
                    color={'primary'}
                    disabled = {!selectedDate || !selectedSlot|| !hasActiveSubscription}
                    onClick={() => props?.handleContinueClicked(selectedDate, selectedSlot, selectedOption)}
                >
                    Continue
                </Button>
            </Box>
        </Dialog>
    )
}

export default PRSelectDateSlotDialog;
