import { GetListResult, GetOneResult } from "react-admin";
import AssignCookOnUserBundleRequestDto from "../dtos/bundle/assignCookToUserBundleDto";
import { CreateBundleDto } from "../dtos/bundle/createBundleDto";
import CreateUserBundleMappingDto from "../dtos/bundle/createBundleMappingDto";
import PatchUserBundleMappingDto from "../dtos/bundle/patchBundleMappingDto";
import PatchUserBundleMetaDto from "../dtos/bundle/patchBundleMetaDto";
import UpdateDateAndSlotsDto from "../dtos/bundle/updateDateAndSlotDto";
import UpdateMealMappingDto from "../dtos/bundle/updateMealMappingDto";
import { SlotsEnum } from "../enums/SlotsEnum";
import HttpClient from "../HttpClient";
import { BundleResponse } from "../interfaces/Bundle/BundleResponse";
import { ExtensionResponse } from "../interfaces/Extension/ExtensionResponse";
import { CookSubscriptionResponse } from "../interfaces/UserBundleMapping/CookSubscriptionForBundle";
import { CustomerLeavesForBundle } from "../interfaces/UserBundleMapping/CustomerLeavesForBundle";
import { SubscriptionResponse } from "../interfaces/UserBundleMapping/SubscriptionForBundle";
import { UserBundlesResponse } from "../interfaces/UserBundleMapping/UserBundlesResponse";
import { VasForUserBundle } from "../interfaces/UserBundleMapping/VasForUserBundle";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";
// import { CookLeaveTypes } from "../enums/CookLeaveTypes";
// import { UserSubscriptionLeaveType } from "../enums/common";
// import { ResolutionEnum } from "../enums/ResolutionEnum";

class UserBundleService {

    async fetchOneUserBundleMapping(userBundleMappingId: number): Promise<GetOneResult<UserBundlesResponse>> {
        let data = await HttpClient.get(`/centaurus/bundle/userbundles/${userBundleMappingId}`);
        return data;
    }

    async getManyBundles(ids: Array<number>): Promise<GetListResult<BundleResponse>> {
        const data = []

        for (const id of ids) {
            const res = await HttpClient.get(`/centaurus/bundle/${id}`);
            data.push(res.data)
        }

        return {
            data: data,
            total: data.length
        }
    }

    async getManyUserBundles(ids: Array<number>): Promise<GetListResult<UserBundlesResponse>> {
        const data = []

        for (const id of ids) {
            try {
                const res = await HttpClient.get(`/centaurus/bundle/userbundles/${id}`);
                data.push(res.data)
            } catch (e) {
                data.push({})
            }
        }

        return {
            data: data,
            total: data.length
        }
    }

    async fetchAllBundles(limit: number, offset: number): Promise<GetListResult<BundleResponse>> {
        let data = await HttpClient.get('/centaurus/bundle', {
            params: {
                limit,
                offset
            }
        });
        return {
            data: (!!data && !!data.data) ? data.data.data : [],
            total: (!!data && !!data.data) ? data.data.count : 0
        }
    }

    async fetchAllBundleMappings(limit: number, offset: number, filters = {}, sort = {}): Promise<GetListResult<UserBundlesResponse>> {
        filters = ConstructSequelizeFilters(filters, [], ['status','cookAllocationStatus', 'createdBy'])
        let data = await HttpClient.get('/centaurus/bundle/userbundles', {
            params: {
                limit,
                offset,
                filters,
                sort
            }
        });
        return {
            data: (!!data && !!data.data) ? data.data.data : [],
            total: (!!data && !!data.data) ? data.data.count : 0
        }
    }

    async fetchAllVasForUserBundleMappings(userBundleMappingId: number, limit: number, offset: number): Promise<GetListResult<VasForUserBundle>> {
        let data = await HttpClient.get(`/centaurus/bundle/userbundles/${userBundleMappingId}/value-added-services`, {
            params: {
                limit,
                offset
            }
        });
        return {
            data: (!!data && !!data.data) ? data.data.data : [],
            total: (!!data && !!data.data) ? data.data.count : 0
        }
    }

    async fetchAllSubscriptionsForUserBundleMapping(userBundleMappingId: number, limit: number, offset: number, sort: {}): Promise<GetListResult<SubscriptionResponse>> {
        let data = await HttpClient.get(`/centaurus/bundle/userbundles/${userBundleMappingId}/subscriptions`, {
            params: {
                limit,
                offset,
                sort
            }
        });
        return {
            data: (!!data && !!data.data) ? data.data.data : [],
            total: (!!data && !!data.data) ? data.data.count : 0
        }
    }

    async fetchAllExtensionsForUserBundleMapping(userBundleMappingId: number, limit: number, offset: number, sort: {}): Promise<GetListResult<ExtensionResponse>> {
        let data = await HttpClient.get(`/centaurus/bundle/userbundles/${userBundleMappingId}/extensions`, {
            params: {
                limit,
                offset,
                sort
            }
        });
        return {
            data: (!!data && !!data.data) ? data.data.data : [],
            total: (!!data && !!data.data) ? data.data.count : 0
        }
    }

    async fetchAllCustomerLeavesForUserBundleMapping(userBundleMappingId: number, limit: number, offset: number, sort: {}): Promise<GetListResult<CustomerLeavesForBundle>> {
        let data = await HttpClient.get(`/centaurus/bundle/userbundles/${userBundleMappingId}/customer-leaves`, {
            params: {
                limit,
                offset,
                sort
            }
        });
        return {
            data: (!!data && !!data.data) ? data.data.data : [],
            total: (!!data && !!data.data) ? data.data.count : 0
        }
    }

    async fetchAllTRForUserBundleMapping(userBundleMappingId: number, limit: number, offset: number, sort = {}) {
        let data = await HttpClient.get(`/centaurus/bundle/userbundles/${userBundleMappingId}/temp-replacement`, {
            params: {
                limit,
                offset,
                sort,
            }
        });
        return {
            data: (!!data && !!data.data) ? data.data.data : [],
            total: (!!data && !!data.data) ? data.data.count : 0
        }
    }

    async fetchAllCookSubscriptionsForUserBundleMapping(userBundleMappingId: number, limit: number, offset: number, sort: {}, filters?: any) : Promise<GetListResult<CookSubscriptionResponse>> {
        let data = await HttpClient.get(`/centaurus/bundle/userbundles/${userBundleMappingId}/cooks`, {
            params: {
                limit,
                offset,
                sort,
                filters: filters ? filters : null,
            }
        });
        return {
            data: (!!data && !!data.data) ? data.data.data : [],
            total: (!!data && !!data.data) ? data.data.count : 0
        }
    }

    async createUserBundleMapping(data: any) {

        const houseId = data.houseId
        delete data.houseId

        const body: CreateUserBundleMappingDto = {
            ...data,
            quotedDiscount: "0.00"
        }

        let res = await HttpClient.post(`/centaurus/bundle/houses/${houseId}/userbundles`, body)
        return {
            data: res.data
        }
    }

    async createBundle(data: any) {
        const body: CreateBundleDto = {
            ...data
        }

        let res = await HttpClient.post(`/centaurus/bundle`, body)
        return {
            data: res.data
        }
    }

    async patchUserBundleMapping(data: any, houseId: number, userBundleMappingId: number) {
        let body: PatchUserBundleMappingDto = {
            cookCut : data.cookCut ? parseInt(data.cookCut).toFixed(2) : data.cookCut.toFixed(2),
            bundleId: data.bundleId,
            startDate: data.startDate,
            startSlot: data.startSlot,
            quotedPrice: data.quotedPrice,
            quotedDiscount: "0.00",
            plan: data.plan,
            genderPreference: data.genderPreference,
            totalPeople: data.totalPeople,
            slots: data.slots,
            visitsPerDay: data.visitsPerDay,
            utensils: data.utensils,
            mealMapping: data.mealMapping
        };

        let res = await HttpClient.patch(`/centaurus/bundle/userbundles/${userBundleMappingId}`, body);

        return res;
    }

    async patchUserBundleMeta(data: any, houseId: number, userBundleMappingId: number) {
        const body: any = {};

        for (const key of Object.keys(new PatchUserBundleMetaDto())) {
            if (data[key]) {
                body[key] = data[key]
            }
        }

        let res = await HttpClient.patch(`/centaurus/bundle/userbundles/${userBundleMappingId}/meta`, body as PatchUserBundleMetaDto);

        return res;
    }

    async deleteUserBundleMapping(userBundleMappingId: number) {
        let data = await HttpClient.delete(`/centaurus/bundle/userbundles/${userBundleMappingId}`);
        return data;
    }

    async activateBundle(userBundleMappingId: string | number, payload : {paidByUser: string, paymentMethod: string, paymentDetails: string}): Promise<void> {
        await HttpClient.patch(`/centaurus/bundle/userbundles/${userBundleMappingId}/activate`, payload);

    }

    async assignCook(data: AssignCookOnUserBundleRequestDto) {
        let res = await HttpClient.post(`/centaurus/cook-subscription/assign/cook`, data);
        return res;
    }

    async cancelUserBundle(userBundleMappingId: string | number, date: string, slot: SlotsEnum, closureReason: string, closureSubReason: string, notes: string, isInterested: boolean, refundData: {userBundleMappingId: number, refundAmount: number, reason: string}[]) {
        return await HttpClient.patch(`/centaurus/bundle/userbundles/${userBundleMappingId}/cancel`, {
            date,
            slot,
            closureReason,
            closureSubReason,
            notes,
            isInterested,
            refundData,
        });
    }

    async getRefundDetails(userBundleMappingId: string | number, endDate: string, endSlot: SlotsEnum) {
        return await HttpClient.get(`/centaurus/bundle/userbundles/${userBundleMappingId}/cancel/calculate-refund`, {
            params: {
                date: endDate,
                slot: endSlot
            }
        })
    }

    async createBundleVas(userBundleMappingId: number, payload: any) {
        let data = await HttpClient.post(`/centaurus/bundle/userbundles/${userBundleMappingId}/value-added-services`, payload);
        return data;
    }

    async createTr(userBundleMappingId: number, payload: any) {
        let data = await HttpClient.post(`/centaurus/bundle/userbundles/${userBundleMappingId}/temp-replacement`, payload);
        return data;
    }

    async createExtension(userBundleMappingId: number, payload: any) {
        let data = await HttpClient.post(`/centaurus/bundle/userbundles/${userBundleMappingId}/extension`, payload);
        return data;
    }

    async createCxLeaves(userBundleMappingId: number, payload: any) {
        let data = await HttpClient.post(`/centaurus/bundle/userbundles/${userBundleMappingId}/customer-leaves`, payload);
        return data;
    }

    async getBundlesById(id: number): Promise<GetOneResult<BundleResponse>> {
        const res = await HttpClient.get(`/centaurus/bundle/${id}`);
        return res
    }

    async getUserBundleMappingForHouse(houseId: number, limit: number, offset: number, sort: any = {}) {
        let data = await HttpClient.get(`/centaurus/bundle/houses/${houseId}/userbundles`, {
            params: {
                limit,
                offset,
                sort,
            }
        });
        return {
            data: (!!data && !!data.data) ? data.data.data : [],
            total: (!!data && !!data.data) ? data.data.count : 0
        }
    }

    async renewUserBundle(id: number, payload: any) {
        return await HttpClient.post(`/centaurus/bundle/userBundles/${id}/renew`, payload);
    }

    async sendPaymentLink(id: number) {
        let data = await HttpClient.get(`/centaurus/bundle/userBundles/${id}/send-payment-link`)
        return data
    }

    async sendRenewalPaymentLink(id: number) {
        let data = await HttpClient.get(`/centaurus/bundle/userBundles/${id}/send-renewal-payment-link`)
        return data
    }
    async resuscitateUserBundle(id: number, payload: any) {
        let data = await HttpClient.post(`/centaurus/bundle/userbundles/${id}/resuscitate`, payload)
        return data
    }

    async updateDatesAndSlots(userBundleId: number, payload: UpdateDateAndSlotsDto) {
        let data = await HttpClient.patch(`/centaurus/bundle/userbundles/${userBundleId}/date-and-slot`, payload);
        return data.data;
    }

    async updateMealMapping(userBundleId: number, payload: UpdateMealMappingDto): Promise<UserBundlesResponse> {
        let data = await HttpClient.put(`/centaurus/bundle/userbundles/${userBundleId}/update-meal-mapping-time`, payload);
        return data?.data;
    }

    async fetchAllActiveClouseRequest(userBundleMappingId: number) {
        let data = await HttpClient.get(`/centaurus/bundle/userbundles/${userBundleMappingId}/closure-requests`);
        return data;
    }

    async cancelSchedular(id: number) {
        let data = await HttpClient.get(`centaurus/cook-subscription/${id}/schedule-cancel`);
        return data;
    }

    async setTotalPrice(userBundleMappingId: number, payload: any) {
        let data = await HttpClient.patch(`/centaurus/bundle/userbundles/${userBundleMappingId}/total-price`, payload);
        return data;
    }
    async setResolution(userBundleMappingId: number, payload: any){
        let data =await HttpClient.patch(`/centaurus/bundle/userbundles/${userBundleMappingId}/update-leave-resolution`,payload)
        return data;
    }
    async updateLeaveDisputeResolution(leaveId:number,payload:any){
        let data =await HttpClient.patch(`/centaurus/bundle/leave/${leaveId}/update-leave-dispute-resolution/`,payload)
        return data;
    }
}

export default UserBundleService;
