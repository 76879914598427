import {
  Datagrid,
  DateInput,
  FunctionField,
  List,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
} from "ra-ui-materialui";
import { UserType } from "../../enums/common";
import { PaymentStatusEnum } from "../../enums/PaymentStatusEnum";
import { StarCleanerVisitStatus } from "../../enums/StarCleanerVisitStatus";
import CallButton from "../Common/CallButton/CallButton";
import CustomReferenceField from "../Common/CustomReferenceField";

const starCleanerFilter = [
  <SelectInput
    label="Filter by Visit Status"
    source="status"
    alwaysOn
    emptyText="Clear Filter"
    choices={Object.values(StarCleanerVisitStatus).map((key) => {
      return {
        id: key,
        name: key,
      };
    })}
  />,
  <SelectInput
    label="Payment Status"
    source="paymentStatus"
    alwaysOn
    emptyText="Clear Filter"
    choices={Object.values(PaymentStatusEnum).map((key) => {
      return {
        id: key,
        name: key,
      };
    })}
  />,
  <TextInput label="Customer Phone" source="customerPhone" alwaysOn />,
  <TextInput label="Customer Name" source="customerName" alwaysOn />,
  <TextInput label="Cleaner Name" source="cleanerName" alwaysOn />,
  <DateInput label="Created Date" source="createdAt" alwaysOn />,
  <DateInput label="Visited Date" source="visitDate" alwaysOn />,
];

const StarCleaner = (props: any) => {
  return (
    <List {...props} filters={starCleanerFilter} className="toolbar-custom-css">
      <Datagrid bulkActionButtons={false}>
        <TextField label="Id" source="id" sortBy="id" sortByOrder="DESC" />
        <TextField label="Visit Status" source="status" sortable={false} />
        <CustomReferenceField
          label="Cleaner Id"
          source="cleanerId"
          sortable={false}
          listName={"Vendors"}
        />
        <TextField label="Cleaner Name" source="cleanerName" sortable={false} />
        <TextField
          label="Customer Name"
          source="customerName"
          sortable={false}
        />
        <FunctionField label= "Customer Mobile" render={(record: any) => <CallButton toPhoneNumber={record?.customerPhone} userType={UserType.VENDOR}/>}/>
        <CustomReferenceField
          label="House"
          listName={"Houses"}
          source={"houseId"}
        />
        <TextField
          label="Payment Status"
          source="paymentStatus"
          sortable={false}
        />
        <TextField label="Visit Date" source="visitDate" sortable={false} />
        <TextField label="Visit Time" source="visitTime" sortable={false} />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default StarCleaner;
