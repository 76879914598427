import React, {useEffect, useState} from 'react';
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import {TransactionServies} from "../../services/TransactionsService";
import TransactionV3ListForModule from "../Transactions/TransactionV3ListForModule";
import { Box, Tab,  Tabs, TablePagination } from '@mui/material';
import { RazorpayOrderReferenceModelEnums } from '../../enums/RazorpayOrderReferenceModelEnums';

interface InputProps {
    houseId: number
}

const transactionService = new TransactionServies();

const HousesTransactionTab = (props: InputProps) => {

    let [subData, setSubData] = useState<any>(null);
    let [isLoading, setIsLoading] = useState<boolean>();
    let [modelName, setModelName] = useState<RazorpayOrderReferenceModelEnums>(RazorpayOrderReferenceModelEnums.USER_BUNDLE_PAYMENTS);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const refreshData = (houseId: number, limit: number, offset: number) => {
        setIsLoading(true)
        transactionService.getTransactionForHouse(houseId, modelName, limit, offset)
            .then((data) => {
                setSubData(data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        refreshData(props.houseId, rowsPerPage, page * rowsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.houseId, modelName, rowsPerPage, page])

    return (
        <>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={modelName}
            onChange={(e, v) => setModelName(v)}
          >
            <Tab label="Subscription" value={RazorpayOrderReferenceModelEnums.USER_BUNDLE_PAYMENTS} />
            <Tab label="One Meal" value={RazorpayOrderReferenceModelEnums.ONE_MEAL_SERVICE} />
            <Tab label="Trial" value={RazorpayOrderReferenceModelEnums.TRIAL_V3} />
            <Tab label="Coupon" value={RazorpayOrderReferenceModelEnums.DISCOUNT_COUPON_ORDER} />
            <Tab label="Chef for party" value={RazorpayOrderReferenceModelEnums.CHEF_FOR_PARTY} />
          </Tabs>
        </Box>
        <div>
            {
                isLoading ?
                    <CircularLoader/>
                    :
                    <div>
                        {
                            subData &&
                            subData.data &&
                            Array.isArray(subData.data) &&
                            (
                                subData.data.length>0 ?
                                    <TransactionV3ListForModule data={subData?.data} modelName={modelName}/>
                                    :
                                    <EmptyListPlaceholder
                                        message='No Transaction Found'
                                    />
                            )
                        }
                        <TablePagination
                            component="div"
                            count={subData?.total || 0}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
            }
        </div>
        </>
    );
};

export default HousesTransactionTab;
