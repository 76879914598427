import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import EventBus from "../../EventBus";
import { useRefresh } from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import UserBundleService from "../../services/BundleService";

interface InputProps {
    data: any;
    open: boolean;
    setOpen: (open: boolean) => any;
}
const userBundleService = new UserBundleService();

const EditPriceOnBundleDialog = (props: InputProps) => {
    const refresh = useRefresh();
    const [totalPrice, setTotalPrice] = useState<string>("");
    const handleClose = () => {
        setTotalPrice("");
        props.setOpen(false);
    }
    const handleSubmit = () => {
        if (totalPrice !== null && totalPrice !== "" && Number(totalPrice) >= 0) {
            userBundleService.setTotalPrice(parseInt(props?.data?.id), {
                price: parseInt(totalPrice),
            }).then((res) => {
                if (res) {
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Success",
                    type: CustomSnackbarTypes.SUCCESS,
                    });
                }
                refresh();
                handleClose();
            }).catch((err) => {
            })
        }
        else {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Please enter numeric value in Edit Price",
                type: CustomSnackbarTypes.ERROR,
            });
        }
    }
return (
    <div>
        <Dialog open={props.open} scroll={"paper"} maxWidth="xs" fullWidth>
            <DialogTitle>Edit Price</DialogTitle>
            <DialogContent>
                <div
                    style={{
                    display: "flex",
                    flexDirection: "column",
                    }}
                    
                >
                    <TextField 
                        label={'Enter Price'}
                        style={{
                            marginTop: "12px",
                        }}
                        type={"number"}
                        value={totalPrice}
                        onChange={(event) => {
                            setTotalPrice(event.target.value);
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ marginTop: "12px" }}>
            <Button 
                variant="contained"
                size={"large"}
                onClick={() => handleSubmit()}
            >
                Submit
            </Button>
            <Button
                variant="contained"
                color={"error"}
                onClick={() => {
                props.setOpen(false);
                }}
                size={"large"}
            >
                Cancel
            </Button>
            </DialogActions>
        </Dialog>
    </div>
  );
};

export default EditPriceOnBundleDialog;
