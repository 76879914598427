import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { CookSubscriptionService } from "../../services/CookSubscriptionService";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { getReasonCategory } from "./constants/constant";
import { ActionTypeEnum } from "../../enums/ActionTypeEnum";
import ReasonSubreasonModuleEnum from "../../enums/ReasonSubreasonModuleEnum";
const cookSubscriptionService = new CookSubscriptionService();

interface InputProps {
    open: boolean;
    onClose: (data: any) => void;
    data: any
}

interface item{
    category: string;
    subCategory: string[];
  }

const EditClousreTextDialog = (props: InputProps) => {
  const [categoryItems,setCategoryItems]= useState<item[]>([])
  const [comments, setComments] = useState<any>(null);
  const [reasonSubCategory, setReasonSubCategory] = useState<string | null>(
    null
  );
  const [selectedCategory, setSelectedCategory] = useState<string>();

  const loadData = useCallback(async () => {
    const sampleReasonSubReason = await getReasonCategory(ReasonSubreasonModuleEnum.SUBSCRIPTION,ActionTypeEnum.SUBSCRIPTION_CLOSURE);
    setCategoryItems(sampleReasonSubReason);

  }, []);

  useEffect(()=>{
    loadData();
  },[loadData])

  const handleReasonCategoryChange = (event: any) => {
    setSelectedCategory(event.target.value)
  };

  const handleReasonSubCategoryChange = (event: any) => {
    setReasonSubCategory(event.target.value);
  };

  function showSubCategoryOrNot(reasonCategory: string) {
    if (reasonCategory === null) {
      return null;
    }
    if (reasonCategory?.toLowerCase() === "others" || reasonCategory?.toLowerCase() === 'other') {
      return (
        <FormControl style={{ width: 500 }} fullWidth>
          <TextField
            value={reasonSubCategory}
            onChange={handleReasonSubCategoryChange}
            id="outlined-basic"
            label="Write a reason"
            variant="outlined"
          />
        </FormControl>
      );
    } else {
      let selectedCategoryItem = categoryItems?.filter(
        (e) => e.category === reasonCategory
      )[0];
      return (
        <FormControl style={{ width: 520 }} fullWidth>
          <InputLabel id="demo-simple-select-label">Select a Subreason</InputLabel>
          <Select
            autoWidth={false}
            className="input_field"
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            defaultValue="Select a reason"
            value={reasonSubCategory}
            onChange={handleReasonSubCategoryChange}
          >
            {selectedCategoryItem?.subCategory.map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    }
  }

  return (
    <div>
      <Dialog
        open={props.open}
        fullWidth={true}
        onClose={() => {
          props.onClose(false);
        }}
      >
        <DialogTitle>Edit Clousre Text</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            justifyContent={"space-evenly"}
            style={{ padding: "12px 12px 12px 12px" }}
            direction="column"
            alignItems="center"
          >
            <Grid item xs={12}>
              <FormControl style={{ width: 520 }} fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Tell us Why?
                </InputLabel>
                <Select
                  autoWidth={false}
                  className="input_field"
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  defaultValue="Tell us Why?"
                  value={selectedCategory}
                  onChange={handleReasonCategoryChange}
                >
                  {categoryItems.map((item: item) => {
                    return (
                      <MenuItem key={item.category} value={item.category}>
                        {item.category}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {selectedCategory && showSubCategoryOrNot(selectedCategory as string)}
            </Grid>
            <Grid alignSelf={"flex-start"} marginLeft={"25px"}>
              {props?.data?.comments &&
                Array.isArray(props?.data?.comments) &&
                props?.data?.comments?.map((comment: any) => {
                  return (
                    <div key={comment?.timestamp}>
                      [{comment?.name} {comment?.timestamp}] -{" "}
                      {comment?.comment}
                    </div>
                  );
                })}
            </Grid>
            <Grid item xs={10}>
              <FormControl
                style={{ width: 500, marginRight: "10px" }}
                fullWidth
              >
                <TextField
                  label="Comments"
                  onChange={(event) => {
                    setComments(event.target.value);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color={"primary"}
            size={"large"}
            onClick={() => {
              if (!reasonSubCategory || !comments) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Please Fill the details",
                  type: CustomSnackbarTypes.ERROR,
                });
                return;
              }
              let payload = {
                reason: selectedCategory as string,
                subReason: reasonSubCategory as string,
                comments: comments,
              };
              cookSubscriptionService
                .EditClosedSubscription(props?.data?.id, payload)
                .then(() => {
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Successful",
                    type: CustomSnackbarTypes.SUCCESS,
                  });
                });
              props.onClose(false);
              setSelectedCategory("");
              setReasonSubCategory(null);
              setComments("");
            }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              props.onClose(false);
              setSelectedCategory("");
              setReasonSubCategory(null);
              setComments("");
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditClousreTextDialog;
