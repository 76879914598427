import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";

const CustomerCallRecordingsTable = (props: any) => {

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Virtual Number</TableCell>
                        <TableCell>Initiated By</TableCell>
                        <TableCell>Call Date and Time</TableCell>
                        <TableCell>Duration (in Sec)</TableCell>
                        <TableCell>Recording</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props?.data?.map((recordings: any) => (
                            recordings && recordings?.recordingUrls?.map((recording: any,i:number) => (
                                <TableRow
                                    key={i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell scope="row">{i+1}</TableCell>
                                    <TableCell align="left">{recordings?.virtualNumber}</TableCell>
                                    <TableCell align="left">{recording?.initiatedBy}</TableCell>
                                    <TableCell align="left">{moment(recording?.callTime)?.format("DD/MM/YYYY HH:mm A")}</TableCell>
                                    <TableCell align="left">
                                        {
                                            Math.floor((recording?.onCallDuration) / 60) !== 0 ?
                                                Math.floor((recording?.onCallDuration) / 60) + " min " + ((recording?.onCallDuration) % 60)+" sec" :
                                                ((recording?.onCallDuration) % 60)+" sec"
                                        }
                                    </TableCell>
                                    <TableCell align="left">
                                        <audio src={recording.url} controls></audio>
                                    </TableCell>
                                </TableRow>

                            ))
                        ))
                    }


                </TableBody>
            </Table>
        </TableContainer>

    )
}
export default CustomerCallRecordingsTable;
