import { Close } from "@mui/icons-material";
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select,
} from "@mui/material";
import  React, { useEffect } from "react";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "./CustomSnackBar";
import { useRefresh } from "react-admin";
import { useState } from "react";
import ChefForPartyService from "../../services/ChefForPartyService";
import {MealType} from "../../enums/MealType";

const chefForPartyService = new ChefForPartyService();

interface InputProps {
  data: any;
  open: boolean;
  callback: () => void;
}

const DishSelectorComp = (props: any) => {
    const [selectCategory, setSelectCategory] = useState<number>(0)
    const [dishes, setDishes] = useState<any>([])

    useEffect(() => {
        if (selectCategory !== 0) {
            chefForPartyService
                .getAllDishesForTypeAndCategory(selectCategory, props.type)
                .then((data) => setDishes(data))
                .catch((err) => setDishes([]));
        }
    }, [selectCategory, props.type]);

    const handleDishSelect = (value: any) => {
        let index = props.index;
        props?.updateCallBack(value?.id , index);
    }

    return (
        <div>
            {props?.showTitle && (
                <InputLabel id="type">{props.type}</InputLabel>
            )}
            <FormControl style={{ width: "180px", marginBottom: "20px" }}>
                <InputLabel id="type">{"Cuisine"}</InputLabel>
                <Select
                    labelId="Cuisine"
                    id="Cuisine"
                    label="Cuisine"
                    defaultValue={''}
                    onChange={(event: any) => {
                        setSelectCategory(event.target.value);
                    }}
                >
                    {props.category && Object.values(props.category).map((d: any) => (
                        <MenuItem key={d.id} value={d.id}>
                            {d.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {dishes && (
                <FormControl style={{ width: "180px", marginBottom: "20px", marginLeft: '20px' }}>
                    <InputLabel id="Dish_Name">{"Dish Name"}</InputLabel>
                    <Select
                        labelId="dishes"
                        id="dishes"
                        label="Dish Name"
                        defaultValue={''}
                        onChange={(event: any) => {
                            handleDishSelect(event.target.value);
                        }}
                    >
                        {dishes?.map((d: any) => (
                            <MenuItem key={d.id} value={d}>
                                {d.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </div>
    );
}

export const EditDishesChefForParty = (props: InputProps) => {
    let refresh = useRefresh();

  const [category, setCategory] = useState([]);
  const [selectDishes, setSelectDishes] = useState<any>([]);

  let handleClose = () => {
    props.callback();
      setSelectDishes([]);
      // setSelectDish(null);
  }

  useEffect(() => {
    chefForPartyService
      .getAllCategory()
      .then((data: any) => setCategory(data))
      .catch((err) => setCategory([]));
  }, []);

    useEffect(() => {
        if(!props?.data){
            return;
        }
        let {noOfStarters, noOfMainCourse, noOfDesserts, noOfSideDishes } = props?.data;
        const starters = Array.from({ length: noOfStarters }, () => ({ type: MealType.STARTER, item: null }));
        const mainCourse = Array.from({ length: noOfMainCourse }, () => ({ type: MealType.MAIN_COURSE, item: null }));
        const desserts = Array.from({ length: noOfDesserts }, () => ({ type: MealType.DESSERT, item: null }));
        const sideDishes = Array.from({ length: noOfSideDishes }, () => ({ type: MealType.SIDE_DISH, item: null }));
       const allDishes = [...starters, ...mainCourse, ...desserts, ...sideDishes];
        setSelectDishes([...allDishes]);
    }, [props?.data]);

  return (
      <Dialog
        onClose={handleClose}
        open={props.open}
        scroll={"paper"}
        maxWidth="xl"
      >
        <DialogTitle>Edit/Add Dishes</DialogTitle>
        <DialogContent>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
            {selectDishes.map((e: any,i: number) =>
                <DishSelectorComp
                    key={`${e.type}-${i}`}
                    index={i}
                    showTitle={selectDishes?.[i - 1]?.type !== e?.type}
                    category={category}
                    type={e.type}
                    selectDishes={selectDishes}
                    setSelectDishes={setSelectDishes}
                    updateCallBack={(item: number, index: number)  => {
                        selectDishes[index].item = item;
                        setSelectDishes([...selectDishes]);
                    }}
                />
            )}
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              if (selectDishes.findIndex((e:any) => e.item === null) !== -1) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Fill the All Dishes",
                    type: CustomSnackbarTypes.WARNING,
                });
                return;
              }
              chefForPartyService
              .addDIshChefForParty(props.data.id, { dishIds: selectDishes.map((e: any) => e.item) })
                .then((res) => {
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: "Added Successfully",
                        type: CustomSnackbarTypes.SUCCESS,
                    });
                  refresh();
                  handleClose();
                });
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              handleClose();
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  );
};
