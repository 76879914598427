import { Box, Button, Link, Skeleton } from "@mui/material";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useCallback, useState } from "react";
import {DateField, FunctionField, ReferenceField, Show, SimpleShowLayout, TextField, useRecordContext} from "react-admin";

const containerStyle = {
  width: '100%',
  height: '50vh'
};
const HouseMetaShow = () => {
  const record = useRecordContext();
  const [view,setView] =useState(false);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey:process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
  });

  const center = {
    lat: record?.lat ||0, // replace defaultLat with a valid number
    lng: record?.long ||0, // replace defaultLng with a valid number
  };

  function getUrlParams(){
    const params = new URLSearchParams();
    if (record?.long !== null) params.append('long', String(record.long));
    if (record?.lat !== null) params.append('lat', String(record.lat));
    if (record?.id !== null) params.append('houseId', String(record.id));

    return params.toString();
  }

const onUnmount =useCallback(function callback() {}, [])
return (
    <Show>
      <SimpleShowLayout>
        {/*  */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Link href={`#/HouseBanners?${getUrlParams()}`} target={'_blank'}>
          <Button
              variant={'contained'}
              style={{
                  margin: '8px 8px 0px 0px'
              }}
              size="small"
          >
              See Banners
          </Button>
        </Link>
        </Box>
        <TextField source="id" />
        <FunctionField
          emptyText=""
          label="Address"
          render={(record: { address: any; cityName: any; localityName: any; areaName: any;
          }) =>
              [record?.address, record?.localityName, record?.areaName, record?.cityName]?.filter((each) => each !== null).join(', ')
          }
        />
        {/* <TextField source="address" />
        <TextField source="locality" /> */}
        <TextField label="Map Location" source="city" />
        <ReferenceField source="cityId" link="show" reference="Cities">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="areaId" link="show" reference="Areas">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="localityId" link="show" reference="Localities">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="sector" />
        <TextField source="lat" />
        <TextField source="long" />
        <TextField source="otherSlcName" />

        <DateField showTime source="createdAt" />
        <DateField showTime source="updatedAt" />
        <Button onClick={()=>{setView(!view)}}>{!view?"View on Map":"Hide House"}</Button>
      </SimpleShowLayout>
      <SimpleShowLayout>
        {
          isLoaded&&view&&record?.lat && record?.long?(
            <div>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={20} 
                    onUnmount={onUnmount}
                >
                    {record?.lat && record?.long && (
                        <Marker position={{
                            lat: record?.lat,
                            lng: record?.long
                        }}
                        icon={{
                          url: "/house.png", // Replace with your house icon URL
                          scaledSize: new window.google.maps.Size(80, 80), // Adjust the size as needed
                        }}
                        />
                    )}
                </GoogleMap>
            </div>
        ) : (
            <Box>
                <Skeleton variant="rectangular" height={'300px'} sx={{mt: 3}} />
            </Box>
        )
        }
      </SimpleShowLayout>

    </Show>
  );
};

export default HouseMetaShow;
