import React from 'react';
import {Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import moment from "moment";
import {ZohoBaseUrl, ZohoModulesUrl} from "../../enums/zoho";

const SubscriptionListForModule = (props: any) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>Start Slot</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>End Slot</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Discount</TableCell>
                        <TableCell>Net Price</TableCell>
                        <TableCell>Taken Leaves</TableCell>
                        <TableCell>Meal Mapping</TableCell>
                        <TableCell>Total Visits</TableCell>
                        <TableCell>Total Leaves</TableCell>
                        <TableCell>Total Meal</TableCell>
                        <TableCell>Zoho</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((row: any) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell scope="row">{row?.id}</TableCell>
                            <TableCell align="center">
                                {
                                    !!row &&
                                    !!row.startDate &&
                                    moment(row?.startDate).format('DD MMM YYYY')
                                }
                            </TableCell>
                            <TableCell align="center">{row?.startSlot}</TableCell>
                            <TableCell align="center">
                                {
                                    !!row &&
                                    !!row.endDate &&
                                    moment(row?.endDate).format('DD MMM YYYY')
                                }
                            </TableCell>
                            <TableCell align="center">{row?.endSlot}</TableCell>
                            <TableCell align="center">{row?.status}</TableCell>
                            <TableCell align="center">{row?.type}</TableCell>
                            <TableCell align="center">{row?.price}</TableCell>
                            <TableCell align="center">{row?.discount}</TableCell>
                            <TableCell align="center">{row?.netPrice}</TableCell>
                            <TableCell align="center">{row?.takenLeaves}</TableCell>
                            <TableCell align="center">{row?.mealMapping}</TableCell>
                            <TableCell align="center">{row?.totalVisits}</TableCell>
                            <TableCell align="center">{row?.totalLeaves}</TableCell>
                            <TableCell align="center">{row?.totalMeal}</TableCell>
                            <TableCell align="center">
                                {
                                    !!row?.zohoId &&
                                    <Link
                                      href={`${ZohoBaseUrl}${ZohoModulesUrl.SUBSCRIPTION}/${row.zohoId}`}
                                      target="_blank"
                                      rel="noopener"
                                    >
                                        {row.zohoId}
                                    </Link>
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SubscriptionListForModule;
