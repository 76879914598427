import { GetListResult } from "react-admin";
import HttpClient from "../HttpClient";
import ApproveHouseReview from "../interfaces/HouseReview/ApproveHouseReview";
import HouseReviewResponse from "../interfaces/HouseReview/HouseReviewResponse";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";

interface HouseReviewFilters {
    areaId?: string | null;
    cityId?: string | null;
    status?: string | null;
    // Add any other filters that might exist here
}

export default class HouseReviewService {
    async fetchAllHouseReviews(limit: number, offset: number, filters: HouseReviewFilters, sort: {}): Promise<GetListResult<HouseReviewResponse>> {  
        if(filters.areaId==="none"){
            filters = ConstructSequelizeFilters(filters, ['status'],['cityId'],['areaId'])
        }
        else{
            filters = ConstructSequelizeFilters(filters, ['status'],['cityId','areaId'])
        }
        let data = await HttpClient.get('/centaurus/houseReviews', {
            params: {
                limit,
                offset,
                filters,
                sort
            }
        })
        return {
            data: (!!data && !!data?.data) ? data?.data?.data : [],
            total: (!!data && !!data?.data) ? data?.data?.count : 0
        };
    }

    async approve(houseReviewId: number, payload: ApproveHouseReview): Promise<HouseReviewResponse> {
        let result = await HttpClient.patch(`/centaurus/houseReviews/${houseReviewId}/approve`, payload);
        return result?.data;
    }

    async reject(houseReviewId: number): Promise<HouseReviewResponse> {
        let result = await HttpClient.patch(`/centaurus/houseReviews/${houseReviewId}/reject`);
        return result?.data;
    }
}
