import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";
import HttpClient from "../HttpClient";
import {GetListResult} from "react-admin";
import {PermanentReplacementResponse} from "../interfaces/PermanentReplacement/PermanentReplacementResponse";
import {TrialResponse} from "../interfaces/Trials/TrialsResponse";
import {SlotsEnum} from "../enums/SlotsEnum";
import {TimeEnum} from "../enums/TimeEnum";
import moment from "moment";
import Op from "../interfaces/SequalizeFilters/SequalizeTypes";

export default class PermanentReplacementService {
    private basePath = '/centaurus/permanent-replacement';


    private processDateRangeFilter(filters: any) {
        let where;
        if (filters.startDate) {
            where = {
                [Op.gte.toString()]: moment(filters.startDate).toDate()
            }
        }
        if (filters.endDate) {
            if (where) {
                where = {
                    ...where,
                    [Op.lte.toString()]: moment(filters.endDate).toDate()
                }
            } else {
                where = {
                    [Op.lte.toString()]: moment(filters.endDate).toDate()
                }
            }
        }
        return {createdAt: where};
    }

    async getAllPermanentReplacement(limit: number, offset: number, filters: any, sort: any): Promise<GetListResult<PermanentReplacementResponse>> {
        let filter = ConstructSequelizeFilters({...filters}, ['customerName', 'customerPhone', 'ownerName'], ['status', 'createdAt', 'cityId', 'wantsOldCookBack'])
        let customFilters = this.processDateRangeFilter(filters);

        filters = {
            ...filter,
            ...customFilters
        }
        let data = await HttpClient.get(`${this.basePath}`, {
            params: {
                limit,
                offset,
                filters,
                sort
            }
        })
        return {
            data: (!!data && !!data.data) ? data.data.data : [],
            total: (!!data && !!data.data) ? data.data.count : 0
        }
    }

    async getPrDetailsById(prId: number): Promise<PermanentReplacementResponse> {
        let data = await HttpClient.get(`${this.basePath}/${prId}`);
        return data?.data;
    }

    async getAllTrialsForPermanentReplacement(prId: number): Promise<TrialResponse[]> {
        let data = await HttpClient.get(`${this.basePath}/${prId}/trials`);
        return data?.data;
    }

    async getAllPermanentReplacementForUserBundle(userBundleMappingId: number): Promise<PermanentReplacementResponse[]> {
        let data = await HttpClient.get(`${this.basePath}/user-bundle/${userBundleMappingId}`);
        return data?.data;
    }

    async getAllPermanentReplacementForHouse(houseId: number,limit: number, offset: number): Promise<GetListResult<PermanentReplacementResponse>> {
        let data = await HttpClient.get(`${this.basePath}/house/${houseId}`, {
            params: {
                limit,
                offset
            }
        })
        return {
            data: data?.data?.data,
            total: data?.data?.count
        }
    }

    async createPermanentReplacement(userBundleMappingId: number): Promise<PermanentReplacementResponse> {
        let data = await HttpClient.post(`${this.basePath}/create/${userBundleMappingId}`)
        return data?.data;
    }

    async createTrialForPermanentReplacement(prId: number, slotName: SlotsEnum, time: TimeEnum, date: string): Promise<TrialResponse> {
        let data = await HttpClient.post(`${this.basePath}/${prId}/trials`, {
            slotName,
            time,
            date
        });
        return data?.data
    }

    async markPrSuccess(prId: number): Promise<PermanentReplacementResponse> {
        let data = await HttpClient.patch(`${this.basePath}/${prId}/success`)
        return data?.data;
    }

    async markPrFailure(prId: number, payload: {wantsOldCookBack: boolean, closeUserBundle: boolean, closureReason?: string, closureSubReason?: string, notes?: string, isInterested?: boolean, refundData?: {userBundleMappingId: number, refundAmount: number, reason: string}[]}): Promise<PermanentReplacementResponse> {
        let data = await HttpClient.patch(`${this.basePath}/${prId}/failure`, payload)
        return data?.data;
    }

    async updatePrRequestowner(prId: number, ownerId: number){
        let data = await HttpClient.patch(`${this.basePath}/${prId}/update-owner/${ownerId}`)
        return data?.data
    }

    async initiatePRRequest(userBundleMappingId: number, reason: string, subReason: string, comments: string) {
        const data = await HttpClient.post(`/centaurus/permanent-replacement/create`, {
            userBundleId: userBundleMappingId,
            comments: comments,
            prInitationTags: {
                [reason] : [subReason],
            }
        });
        return data;
    }
    async createPRTrial(prId: number, slot: string, date: string, option?: string, cookSubscriptionId?: number) {

        const data = await HttpClient.patch(`/centaurus/permanent-replacement/${prId}/create-trial`, {
            date,
            slot,
            ...(option && { prCxRemainingVisitChoice: option }),
            ...(cookSubscriptionId && { cookSubscriptionId }),
        });
        return data;
    }

    async cancelPRRequest(prId: number, payload: any) {
        const data = await HttpClient.patch(`/centaurus/permanent-replacement/${prId}/cancel`, payload);
        return data;
    }

    async getTrExtensionDateDetails(userBundleId: number, date: string, slot: string) {
        const data = await HttpClient.get(`permanent-replacement/get-tr-extension-date-details`, {
            params: {
                userBundleId,
                date,
                slot
            }
        });
        return data;
    }
}
