import React from 'react';
import {
    Datagrid, DateField,
    DateInput, List, NumberField, ShowButton, TextField, EditButton, useRecordContext, useRefresh, SelectInput, FunctionField,
    TextInput,
    NumberInput,
} from 'react-admin';
import {Button} from "@mui/material";
import {Delete} from "@mui/icons-material";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";
import {CookLeavesService} from "../../services/CookLeavesService";
import { LeaveSourceType } from '../../enums/leaveSourceType';
import CallButton from '../Common/CallButton/CallButton';
import { UserType } from '../../enums/common';
import { CookLeaveTypes } from '../../enums/CookLeaveTypes';

const cookLeavesFilters = [
    <DateInput label="Start Date" source="startDate" alwaysOn />,
    <DateInput label="End Date" source="endDate" alwaysOn />,
    <SelectInput
        label="Leave Source"
        source="leaveSource"
        alwaysOn
        emptyText="Clear Filter"
        choices={
            Object.values(LeaveSourceType)
                .map(key => {
                    return {
                        id: key,
                        name: key
                    }
                })
        }
    />,
    <SelectInput
        label="Leave Type"
        source="leaveType"
        alwaysOn
        emptyText="Clear Filter"
        choices={
            Object.values(CookLeaveTypes)
                .map(key => {
                    return {
                        id: key,
                        name: key
                    }
                })
        }
    />,
    <TextInput sx={{width: "150px"}} label="Phone" source="cookPhoneNumber" alwaysOn />,
    <NumberInput sx={{width: "150px"}} label="Cook Id" source="cookId" alwaysOn/>
];

const cookLeaveService = new CookLeavesService();

const DeleteButton = (props: any) => {
    let record = useRecordContext();
    let refresh = useRefresh();
    
    return (
        <Button
            variant={'contained'}
            color={'error'}
            size={"small"}
            endIcon={<Delete/>}
            onClick={() => {
                EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
                    message: "Are you sure you want to cancel this leave?",
                    onClose: (confirmed: boolean) => {
                        if (confirmed) {
                            cookLeaveService.cancelLeave(record.id as number).then(() => {
                                refresh();
                                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                    message: 'Leave Cancelled',
                                    type: CustomSnackbarTypes.SUCCESS
                                });
                            });
                        }
                    },
                    title: "Confirmation"
                });
            }}
        >
            Cancel
        </Button>
    )
}

const CookLeaves = (props: any) => {
    return (
        <List {...props} filters={cookLeavesFilters} sort={{ field: 'id', order: 'DESC' }}>
            <Datagrid bulkActionButtons={false}>
                <NumberField source='id'/>
                <NumberField source='cookId' sortable={false}/>
                <TextField source='cookFirstName' sortable={false}/>
                <TextField source='cookLastName' sortable={false}/>
                <FunctionField label= "Cook Phone Number" render={(record: any) => <CallButton toPhoneNumber={record?.cookPhoneNumber} userType={UserType.COOK}/>}/>
                <TextField source='leaveType'sortable={false}/>
                <TextField source='leaveSource' sortable={false}/>
                <TextField source='isEmergencyLeave' emptyText="false"/>
                <DateField options={{day: 'numeric', month: 'short', year: 'numeric' }} source='leaveStartDate'/>
                <TextField source='startTime'/>
                <DateField options={{ day: 'numeric', month: 'short', year: 'numeric' }} source='leaveEndDate' />
                <TextField source='endTime'/>
                <DateField options={{ day: 'numeric', month: 'short', year: 'numeric' }} source='createdAt' />
                <DateField options={{day: 'numeric', month: 'short', year: 'numeric' }} source='updatedAt'/>
                <ShowButton/>
                <EditButton/>
                <DeleteButton/>
            </Datagrid>
        </List>
    );
};

export default CookLeaves;
