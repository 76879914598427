import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import {useRecordContext, useRefresh } from "react-admin";
import { CookService } from "../../services/CookServices";
import httpClient from "../../HttpClient";
import {ErrorDialog} from "./ErrorDialog";


const cookService = new CookService();

const AssignCookOnTrialServeList = (props: any) => {
  const refresh = useRefresh();
  const [cookCut, setCookCut] = useState<string>("0");
  const [trialCookCut, setTrialCookCut] = useState<string>("");
  const [isMgCook, setIsMgCook] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorRes, setErrorRes] = useState<any>();
  const record=useRecordContext();

  const checkMgCook = (cookId: number) => {
    cookService.getMgCookSalary(cookId).then((cookSalary) => {
      const isMgCook = cookSalary[cookSalary.length - 1]?.endDate === null;
      setIsMgCook(isMgCook);
      setTrialCookCut(isMgCook ? "0" : "250");
    });
  };

  useEffect(()=>{
    if(props?.cookId){
        checkMgCook(parseInt(props?.cookId));
    }
  },[props?.cookId])


  const handleSubmit = () => {
    if (
      props?.cookId &&
      Number(cookCut) >= 0 &&
      Number(trialCookCut) >= 0
    ) {
      let payload = {
        cookId: props?.cookId,
        cookCut: Number(cookCut),
        trialCookCut: trialCookCut,
        // allocationNotes: outsideCook ? `${reason?.label} - ${allocationNotes}`: ''
        allocationNotes: '',
      };
        httpClient.post(`/centaurus/v3/trials/${parseInt(props?.mealServiceId)}/assign`, payload).then((res) => {
            if (res) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Success",
                    type: CustomSnackbarTypes.SUCCESS,
                });
            }
            setCookCut("0");
            setTrialCookCut("0");
            props.setAssignCookOpen(false);
            // setReason(undefined);
            refresh();
        })
            .catch((err) => {
                setErrorRes(err?.response?.data?.data);
                if(err?.response?.data?.data){
                    setOpenError(true);
                }
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: err?.response?.data?.data ? `` : err?.response?.data?.error,
                    type: CustomSnackbarTypes.ERROR,
                    duration:  err?.response?.data?.data ? 0 : 3000,
                });
            })
  } else {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: `Fill the details properly`,
        type: CustomSnackbarTypes.ERROR,
      });
    }
  };

  return (
    <div>
      <Dialog open={props.assignCookOpen} scroll={"paper"} maxWidth="xs" fullWidth>
        <DialogTitle>Assign cook</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {!!props?.cookId && (
              <p>Selected cook - {props?.cookId}</p>
            )}
            {
            <TextField
              defaultValue={record?.cookCut}
              type={"number"}
              label={"Cook Cut"}
              style={{
                marginTop: "12px",
              }}
              disabled={true}
            />}
            {!isMgCook && (
              <TextField
                value={trialCookCut}
                type={"number"}
                label={ "Trial Cook Cut"}
                style={{
                  marginTop: "12px",
                }}
                disabled={true}
                onChange={(event) => {
                  setTrialCookCut(event.target.value);
                }}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              props.setAssignCookOpen(false);
              setCookCut("0");
              setTrialCookCut("0");
              // setReason(undefined);
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
        <ErrorDialog
            open={openError}
            onClose={() => {setOpenError(false)}}
            data={errorRes}
        />
    </div>
  );
};

export default AssignCookOnTrialServeList;
