import {Close} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {CookService} from "../../services/CookServices";

interface InputProps {
  data: any;
  open: boolean;
  callback: () => void;
}

const cookService = new CookService();

export const ShowCookSlcDialog = (props: InputProps) => {
  let [slots, setSlots] = React.useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getCookSlots = useCallback(() => {
    setLoading(true);
    cookService.getAllDailyCooksSlc(1000, 0, {cookId: props?.data?.cookId}).then((res: any) => {
      setLoading(false);
      setSlots([...res?.data]);
    })
  }, [props?.data?.cookId]);

  useEffect(() => {
    if(!props?.data) return;
    getCookSlots();
  }, [getCookSlots, props?.data])

  let handleClose = () => {
    props.callback();
    setSlots([])
  }
  return (
      <>
      <Dialog
        onClose={handleClose}
        open={props.open}
        scroll={"paper"}
        maxWidth="xl"
      >
        <DialogTitle>{`Cook SLCs for ${props?.data?.firstName} ${props?.data?.lastName}`}</DialogTitle>
        <DialogContent>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
          <Box>
            <Table size={'small'}>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell align={'center'}>Reference Model Name</TableCell>
                  <TableCell align={'center'}>Service Name</TableCell>
                  <TableCell>SLC Name</TableCell>
                </TableRow>
              </TableHead>
              {slots?.length > 0 ?
                  slots?.map((each: any)=> {
                    return(
                        <TableBody key={each?.id}>
                          <TableRow>
                            <TableCell>
                              {each?.id}
                            </TableCell>
                            <TableCell align={'center'}>
                              {each?.referenceModelName}
                            </TableCell>
                            <TableCell align={'center'}>
                              {each?.serviceName}
                            </TableCell>
                            <TableCell>
                              {each?.slcName}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                    )
                  })
                  : (loading ?
                          ''
                          : <caption>No SLC found</caption>
                  )}
            </Table>
          </Box>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              handleClose();
            }}
            size={"small"}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      </>
  );
};
