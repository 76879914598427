import {configureStore} from "@reduxjs/toolkit"
import customSelect from "./reducers/customSelect";
import userDetails from "./reducers/userDetails";
import trailDetails from "./reducers/trailDetails";
import bankDetails from "./reducers/bankDetails";
import prMarkFailed from "./reducers/prMarkFailed";
import makeCall from "./reducers/callSlice"
import cookProfileData from "./reducers/cookProfileData";

const store = configureStore({
  reducer: {
    customSelect: customSelect,
    userDetails: userDetails,
    trailDetails: trailDetails,
    bankDetails: bankDetails,
    prMarkFailed: prMarkFailed,
    makeCall: makeCall,
    cookProfileData: cookProfileData,
  },
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
