import { Show, SimpleShowLayout, TextField } from "react-admin";

export const VendorShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="phoneNumber" />
            <TextField source="category" />
        </SimpleShowLayout>
    </Show>
);