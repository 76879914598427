import { Create } from "react-admin";
import React, {useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
    TablePagination, TableRow,
    TextField,
    Typography
} from "@mui/material";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";
import {useNavigate} from "react-router-dom";
import TransactionButton from "../Common/TransactionButton";
import {setCustomSelectOpen} from "../../store/reducers/customSelect";
import {useAppDispatch} from "../../store/hooks";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import Customers from "../Customers/Customer";
import ComboService from "../../services/ComboService";

const comboService = new ComboService();

const CreateCombo = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [count, setCount] = useState<string>("1");
    const [customerId, setCustomerId] = useState<any>(null);
    const [openCustomerDialog, setOpenCustomerDialog] = useState(false);

    const [open, setOpen] = useState<boolean>(false);
    const [allCouponSku, setAllCouponSku] = useState<any>();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [couponId, setCouponId] = useState<any>(null);
    const [couponData, setCouponData] = useState<any>(null);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSelectCustomer = () => {
        setOpenCustomerDialog(true);
        dispatch(setCustomSelectOpen({
            open: true
        }));
    }

    const handleCloseCustomer = (record: any) => {
        setOpenCustomerDialog(false);
        setCustomerId(record?.id);
    }

    const showError = (title: string) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: title || "Fill the form to continue",
            type: CustomSnackbarTypes.ERROR,
        });
    }

    const handleSave = () => {
        if(!customerId) {
            showError('Select a customer to continue');
            return;
        }
        if(!couponId) {
            showError('Select a coupon to continue');
            return;
        }
        if(!count) {
            showError('Enter count to continue');
            return;
        }
        if(Number(count) <= 0) {
            showError('Count must be grater than or equal to 1');
            return;
        }
        comboService.createCombo(Number(customerId), {
            "coupons": [
                {
                    "couponSkuId": couponId,
                    "count": Number(count),
                }
            ]
        }).then((res) => {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Created Successfully",
                type: CustomSnackbarTypes.SUCCESS,
            });
            if(res?.data.id) {
                navigate(`/Combo/${res?.data.id}/show`, {replace: true})
            }
        })
    };

    useEffect(() => {
        comboService.getAllDiscountCouponSku(rowsPerPage, page*rowsPerPage).then((res: any) => {
            setAllCouponSku(res);
        })
    }, [rowsPerPage, page]);

    return (
        <Create title={'Create City'}>
            <Grid
                container
                columns={1}
                rowSpacing={2}
                style={{padding: "12px 12px 12px 12px"}}
            >
                <Grid item xs={4}>
                    {openCustomerDialog &&
                        <ResourceSelectDialog
                            name={'Customers'}
                            list={Customers}
                            onClose={handleCloseCustomer}
                        />}
                    <TransactionButton
                        name={customerId ? `Customer Id: ${customerId}` : "Choose customer"}
                        onClickHandler={() => handleSelectCustomer()}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TransactionButton
                        name={couponId ? `Coupon Id: ${couponId}` : "Choose coupon"}
                        onClickHandler={() => { setOpen(true) }}
                    />
                </Grid>
                {couponData && (
                    <Grid item xs={4}>
                        <Typography>
                            {`Coupon Value: ${couponData?.price} ${couponData?.couponType}`}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={4}>
                    <TextField
                        label="No of Combos"
                        id="count"
                        value={count}
                        type={'number'}
                        onChange={(event: any) => setCount(event.target.value)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button onClick={handleSave} variant="contained">
                        {'Save'}
                    </Button>
                </Grid>
                <Dialog open={open} onClose={() => setOpen(false)} maxWidth={'lg'}>
                    <DialogTitle>
                        Select from coupon SKU
                    </DialogTitle>
                    <DialogContent>
                        <TableContainer component={Paper}>
                            <Table size={"small"}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell/>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Coupon Type</TableCell>
                                        <TableCell>Is Active</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Value</TableCell>
                                        <TableCell>Product Type</TableCell>
                                        <TableCell>Maximum Discount Amount</TableCell>
                                        <TableCell>Maximum Usage Limit</TableCell>
                                        <TableCell>Minimum Order Value</TableCell>
                                        <TableCell>No Of People</TableCell>
                                        <TableCell>Number Of Days</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {allCouponSku?.data?.map((row: any) => {
                                        return (
                                            <TableRow
                                                key={row.id}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell>
                                                    <Button
                                                        onClick={() => {
                                                            setCouponData(row);
                                                            setCouponId(row?.id);
                                                            setOpen(false);
                                                        }}
                                                    >
                                                        {'Select'}
                                                    </Button>
                                                </TableCell>
                                                <TableCell scope="row">{row?.name}</TableCell>
                                                <TableCell scope="row">{row?.description}</TableCell>
                                                <TableCell scope="row">{row?.couponType}</TableCell>
                                                <TableCell scope="row">{row?.isActive ? 'YES': 'NO'}</TableCell>
                                                <TableCell scope="row">{row?.price}</TableCell>
                                                <TableCell scope="row">{row?.value}</TableCell>
                                                <TableCell scope="row">{row?.productType}</TableCell>
                                                <TableCell scope="row">{row?.maximumDiscountAmount}</TableCell>
                                                <TableCell scope="row">{row?.maximumUsageLimit}</TableCell>
                                                <TableCell scope="row">{row?.minimumOrderValue}</TableCell>
                                                <TableCell scope="row">{row?.noOfPeople}</TableCell>
                                                <TableCell scope="row">{row?.numberOfDays}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={allCouponSku?.count || 0}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant={'contained'} color={'error'} onClick={() => {setOpen(false)}}>
                            {'Close'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Create>
    )
}

export default CreateCombo;
