import HttpClient from "../HttpClient";
import {GetListResult, GetOneResult} from "react-admin";
import { CookLeavesResponse } from "../interfaces/Cook/CookLeavesResponse";
import {CreateCookShortLeaveRequest} from "../interfaces/CookLeaves/CreateCookShortLeaveRequest";
import {CreateCookLongLeaveRequest} from "../interfaces/CookLeaves/CreateCookLongLeaveRequest";
// import {PatchCookShortLeaveRequest} from "../interfaces/CookLeaves/PatchCookShortLeaveRequest";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";
import Op from "../interfaces/SequalizeFilters/SequalizeTypes";
import moment from "moment";
import { CreateCookLongLeaveEditRequest } from "../interfaces/CookLeaves/CreateCookLongLeaveEditRequest";
import { CookSlotTimeEnum } from "../enums/CookSlotTimeEnum";

export class CookLeavesService {
    private readonly baseurl: string;
    constructor() {
        this.baseurl = '/cook-leaves/v3/centaurus'
    }

    processDateRangeFilter(filters: any) {
        let where;
        if (filters.startDate) {
            where = {
                startDate: {
                    [Op.gte.toString()]: moment(filters.startDate).toDate()
                }
            }
            delete filters.startDate
        }
        if (filters.endDate) {
            if (where) {
                where = {
                    ...where,
                    endDate: {
                        [Op.lte.toString()]: moment(filters.endDate).toDate()
                    }
                }
            } else {
                where = {
                    endDate: {
                        [Op.lte.toString()]: moment(filters.endDate).toDate()
                    }
                }
            }
            delete filters.endDate
        }
        return where;
    }

    async getAllCookLeaves(limit: number, offset: number, filters={}, sort={}): Promise<GetListResult<CookLeavesResponse>> {
        filters = {...filters};
        let nullFilters: string[] = [];
        let notNullFilters: string[] = [];

        let autoFilters = ConstructSequelizeFilters(filters, [], ['status', 'leaveSource','cookPhoneNumber','cookId','leaveType'], nullFilters, notNullFilters)
        let customFilters = this.processDateRangeFilter(filters);

        filters = {
            ...autoFilters,
            ...customFilters
        }


        if ((sort as any).field === "leaveStartDate") {
            (sort as any).field = "startDate"
        }
        if ((sort as any).field === "leaveEndDate") {
            (sort as any).field = "endDate"
        }

        let data = await HttpClient.get(`${this.baseurl}`, {
            params: {
                filters,
                sort,
                limit,
                offset
            }

        });
        return {
            data: (!!data && !!data.data) ? data.data.data : [],
            total: (!!data && !!data.data) ? data.data.count : 0
        }
    }

    async getAllLeavesForCookById(cookId: number, limit: number, offset: number, sort: {}): Promise<GetListResult<any>> {
        let data = await HttpClient.get(`${this.baseurl}/cook/${cookId}`, {
            params: {
                limit,
                offset,
                sort
            }
        })
        return {
            data: (!!data && !!data.data) ? data.data.data : [],
            total: (!!data && !!data.data) ? data.data.count : 0
        }
    }

    async getCookLeaveDetailsById(id: number): Promise<GetOneResult<CookLeavesResponse>> {
        let data = await HttpClient.get(`${this.baseurl}/${id}`);
        return {
            data: data?.data
        }
    }

    async getShortLeaveSlotDetails(cookId: number,payload:any): Promise<any> {
        let data = await HttpClient.get(`cook-leaves/v4/centaurus/short/slots/cook/${cookId}`, {
            params: {
                ...payload,
            }
        })
        return data?.data;
    }

    async markShortLeave(cookId: number, payload: CreateCookShortLeaveRequest): Promise<any> {
        let data = await HttpClient.post(`/cook-leaves/v4/centaurus/short/cook/${cookId}`, payload);
        return data?.data;
    }

    async getShortLeaveDetailsById(leaveId: number): Promise<any> {
        let data = await HttpClient.get(`${this.baseurl}/short/${leaveId}`);
        return data?.data;
    }

    async editShortLeave(leaveId: number,cookId:number, payload: any): Promise<any> {
        let data = await HttpClient.patch(`/cook-leaves/v4/centaurus/short/${leaveId}/cook/${cookId}`, payload);
        return data?.data;
    }

    async getLongLeaveSlotDetails(cookId: number, startDate: string, endDate: string, startTime : CookSlotTimeEnum, endTime : CookSlotTimeEnum): Promise<any> {
        let data = await HttpClient.get(`/cook-leaves/v4/centaurus/long/cook/${cookId}`, {
            params: {
                startDate,
                endDate, 
                startTime,
                endTime
            }
        })
        return data?.data;
    }

    async getCookLeavesForUserModule(userBundleMappingId:number):Promise<any> {
        let data = await HttpClient.get(`/cook-leaves/v4/centaurus/bundle/${userBundleMappingId}`);
        return data?.data;
    }

    async markLongLeave(cookId: number, payload: CreateCookLongLeaveRequest): Promise<any> {
        let data = await HttpClient.post(`/cook-leaves/v4/centaurus/long/cook/${cookId}`, payload);
        return data?.data;
    }

    async editLongLeave(leaveId: number, payload: CreateCookLongLeaveEditRequest): Promise<any> {
        let data = await HttpClient.patch(`/cook-leaves/v4/centaurus/long/${leaveId}`, payload);
        return data?.data;
    }

    async cancelLeave(leaveId: number): Promise<any> {
        let data = await HttpClient.delete(`${this.baseurl}/${leaveId}/cancel`);
        return data?.data
    }
}
