import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, {useState} from "react";
import {BankDetailsResponse} from "../../interfaces/Payout/BankDetailsResponse";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {PayoutServices} from "../../services/PayoutServices";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";
import PayoutBankDetailDialog from "./PayoutBankDetailDialog";

interface PropsInterface {
  data: BankDetailsResponse[];
  cookId: number;
  callBack: () => void;
}

const payoutService = new PayoutServices();
const PayoutList = (props: PropsInterface) => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<BankDetailsResponse | null>(null);
  const handleDelete = async (id: number) => {
    await payoutService.deletePayoutAccountDetail(id)
        .then(()=>{
          props.callBack();
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Deleted Successfully",
            type: CustomSnackbarTypes.SUCCESS,
          });
        })
  };
  const handleMakeDefault = async (id: number) => {
    await payoutService.makePayoutAccountAsDefault(id)
        .then(()=>{
          props.callBack();
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Marked as default",
            type: CustomSnackbarTypes.SUCCESS,
          });
        })
  };
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{'Sl.No'}</TableCell>
            <TableCell>{'Type'}</TableCell>
            <TableCell>{'UPI ID'}</TableCell>
            <TableCell>{'Account number'}</TableCell>
            <TableCell>{'IFSC Code'}</TableCell>
            <TableCell>{'Verified'}</TableCell>
            <TableCell>{'State'}</TableCell>
            <TableCell/>
            <TableCell align="center">Action</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row: BankDetailsResponse, index: number) => {
            return (
              <TableRow key={row?.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row?.type === 'VPA' ? 'UPI ID' : 'BANK ACCOUNT'}</TableCell>
                <TableCell>{row?.childTable?.vpaId || '---'}</TableCell>
                <TableCell>{row?.childTable?.accountNumber || '---'}</TableCell>
                <TableCell>{row?.childTable?.ifscCode || '---'}</TableCell>
                <TableCell>{row?.verified ? "Success" : "Failed"}</TableCell>
                <TableCell>{row?.childTable?.fundAccountCreationStatus}</TableCell>
                  <TableCell align={'left'}>
                      <Button
                          variant={'contained'}
                          size="small"
                          onClick={()=>{
                              setOpen(true);
                              setSelectedData(row);
                          }}
                      >
                          {'Show'}
                      </Button>
                  </TableCell>
                <TableCell align={'left'}>
                  {row?.isDefault ? (
                      <Box sx={{ backgroundColor: '#0000001a', padding: '10px 18px', borderRadius: '8px', textAlign: 'center'}}>
                        {'Default Account'}
                      </Box>
                      ) :
                    <Button
                      size="small"
                      variant={'outlined'}
                      color={'warning'}
                      onClick={()=>{
                        EventBus.emitEvent(
                            EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG,
                            {
                              title: "Are you sure?",
                              message:
                                  "Please confirm to mark it as default",
                              successLabel: "Confirm",
                              failureLabel: "Cancel",
                              onClose: (isConfirmed: boolean) =>
                                  isConfirmed ? handleMakeDefault(row?.id) : null,
                            }
                        );
                      }}
                    >
                      {'Mark as Default'}
                    </Button>
                  }
                </TableCell>
                <TableCell align={'left'}>
                  <Button
                      variant={'contained'}
                      color={'error'}
                      size="small"
                      onClick={()=>{
                        EventBus.emitEvent(
                            EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG,
                            {
                              title: "Are you sure?",
                              message:
                                  "Please confirm the deletion of the bank detail",
                              successLabel: "Confirm",
                              failureLabel: "Cancel",
                              onClose: (isConfirmed: boolean) =>
                                  isConfirmed ? handleDelete(row?.id) : null,
                            }
                        );
                      }}
                  >
                    {'Delete'}
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <PayoutBankDetailDialog
        open={open}
        handleClose={()=>{
          setOpen(false);
        }}
        data={selectedData}
      />
    </TableContainer>
  );
};

export default PayoutList;
