import React, { FC, useEffect, useMemo, useState } from "react";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import moment from "moment";
import "./AllocationTab.css";
import {
  Link,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { TrialsV3Status } from "../../enums/TrialsV3Status";
import { useRecordContext, useRefresh } from "react-admin";
import AllocateTrialDialog from "../Common/AllocateTrialDialog";
import CallButton from "../Common/CallButton/CallButton";
import { UserType } from "../../enums/common";
import { FreshLeadStatus } from "../../enums/FreshLeadStatus";
import PartnerSlotService from "../../services/PartnerTimeSlotService";
import ShowSlotsDialog from "../Common/ShowSlotsDialog";
import AllocationContactedDialog from "../Common/AllocationContactedDialog";
import PermanentReplacementService from "../../services/PermanentReplacementService";
const MetaBaseUrl = `https://metabase.thechefkart.com/dashboard/125-chef-history-geography?id=`;
const CookOnBoardingTypes = Object.values(FreshLeadStatus).map((x) => {
  return {
    text: x,
    value: x,
  };
});
interface MealMappingInterface {
  from?: string;
  to?: string;
  activityStatus: string;
  mealMapping: {
    slot: string;
    time: string;
    bufferMinutes: number;
  };
}
const partnerSlotService = new PartnerSlotService();
const permanentReplacementService = new PermanentReplacementService();
const MealTable = (props: any) => {
  const { data } = props;
  return (
    <Table
      size="small"
      sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
    >
      <TableHead>
        <TableRow key="header">
          <TableCell>Slot</TableCell>
          <TableCell>Time</TableCell>
          <TableCell>Buffer</TableCell>
          <TableCell>Availability</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data &&
          data.map((row: MealMappingInterface, index: number) => (
            <TableRow key={row.from + "" + index}>
              <TableCell
                component="th"
                scope="row"
                key={row?.mealMapping?.slot + `${index}`}
              >
                {row?.mealMapping?.slot}
              </TableCell>
              <TableCell sx={{ minWidth: "70px" }} key={row?.mealMapping?.time}>
                {row?.mealMapping?.time}
              </TableCell>
              <TableCell key={row?.mealMapping?.time + `${index}`}>
                {row?.mealMapping?.bufferMinutes}
              </TableCell>
              <TableCell
                key={row?.mealMapping?.time + `${index + 300}`}
                sx={{
                  backgroundColor:
                    row?.activityStatus === "BLOCKED"
                      ? "#e53935"
                      : row?.activityStatus === "NOT-UPDATED"
                      ? "#fdd835"
                      : "#90EE90",
                  minWidth: "140px",
                }}
              >
                {row?.from ? (
                  <Box>
                    {row?.from} - {row?.to}
                  </Box>
                ) : (
                  <Box>{"BLOCKED"}</Box>
                )}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};
const TrialAllocationMetaShow: FC<any> = (props: any) => {
  const [selectedData, setSelectedData] = useState<any>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [contacted, setContacted] = useState<boolean>(false);
  const [showSlots, setShowSlots] = useState<boolean>(false);
  const record = useRecordContext(props);
  const refresh = useRefresh();
  let contactedArr:any = [];
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "cookId",
        id: "cookId",
        header: "Cook Id",
        enableHiding: false,
        maxSize: 64,
        Cell: ({ row }) => (
          <Link
            href={`/#/Cooks/${row.original.cookId}/show/`}
            target={"_blank"}
          >
            {" "}
            {row.original.cookId}{" "}
          </Link>
        ),
      },
      {
        accessorKey: "cookName",
        id: "cookName",
        header: "Cook Name",
        Cell: ({ row }) => (
            <Box display={'flex'} alignItems={'center'}>
                <p>{`${row?.original?.cookFullProfile?.firstName} ${row?.original?.cookFullProfile?.lastName}`}</p>
                {row?.original?.cookFullProfile?.onboardedAt && moment(new Date()).diff(moment(new Date(row?.original?.cookFullProfile?.onboardedAt)), 'days') < 31 && (
                    <Box ml={1} sx={{backgroundColor: '#006645', padding: '4px 10px', borderRadius: '6px', color: '#ffffff' }}>New</Box>
                )}
            </Box>
        ),
      },
      {
        accessorKey: "cookPhoneNumber",
        id: "cookPhoneNumber",
        header: "Mobile Number",
        maxSize: 72,
        Cell: ({ row }) => (
          <div className="displayFlex">
            <CallButton
              toPhoneNumber={row?.original?.cookPhoneNumber}
              userType={UserType.COOK}
            />
          </div>
        ),
      },
      {
        accessorKey: "leadReferenceLocModelName",
        header: "Lead Reference Model Name",
        Cell: ({ row }) => (<p>{`${row?.original?.leadReferenceLocModelName || '---'}`}</p>)
      },
      {
            accessorKey: "cookFullProfile.chefHub",
            id: "chefHubId",
            header: "Chef Hub Id",
            maxSize: 72,
            Cell: ({ row }) => (
                <div className="displayFlex">
                    {row?.original?.cookFullProfile?.chefHub}
                </div>
            ),
        },
        {
            accessorKey: "cookFullProfile.hubDetails.name",
            id: "chefHub",
            header: "Chef Hub Name",
            maxSize: 72,
            Cell: ({ row }) => (
                <div className="displayFlex">
                    {row?.original?.cookFullProfile?.hubDetails?.name}
                </div>
            ),
        },
      {
        accessorKey: "cookFullProfile.cookCategory",
        id: "cookCategory",
        header: "Category",
        maxSize: 72,
        filterVariant: "select",
        filterFn: "equalsString",
        filterSelectOptions: [
          { text: "Popular", value: "POPULAR" },
          { text: "Premium", value: "PREMIUM" },
        ],
        Cell: ({ row }) => (
          <div
            className="ck-chip"
            style={{
              backgroundColor:
                row.original?.cookFullProfile?.cookCategory ===
                props?.trail?.bundlePlan
                  ? "#90EE90"
                  : "",
            }}
          >
            {row.original?.cookFullProfile?.cookCategory}
          </div>
        ),
      },
      {
        accessorKey: "cookFullProfile.gender",
        id: "gender",
        header: "Gender Preference /Category/ ",
        maxSize: 220,
        filterVariant: "select",
        filterFn: "equalsString",
        filterSelectOptions: [
          { text: "Male", value: "male" },
          { text: "Female", value: "female" },
        ],
        Cell: ({ cell, row }) => (
          <div>
            <div
              className="ck-chip"
              style={{
                backgroundColor:
                  row.original?.cookFullProfile?.gender?.toLowerCase() ===
                    props?.trail?.genderPreference?.toLowerCase() ||
                  props?.trail?.genderPreference?.toLowerCase() === "any"
                    ? "#90EE90"
                    : "",
              }}
            >
              {row.original?.cookFullProfile?.gender}
            </div>
          </div>
        ),
      },
      {
        accessorKey: "availability",
        id: "availability",
        header: "Availability",
        maxSize: 340,
        Filter: () => <div></div>,
        Cell: ({ row }) => <MealTable data={row.original.availability} />,
      },
      {
        accessorKey: "cookFullProfile.onboardingStatus",
        id: "cookOnBoardingStatus",
        header: "Cook OnBoarding Status",
        filterVariant: "multi-select",
        filterSelectOptions: CookOnBoardingTypes,
        maxSize: 64,
        Cell: ({ row }) => (
          <div>
            <div>
              {row?.original?.cookFullProfile?.onboardingStatus
                ? row?.original?.cookFullProfile?.onboardingStatus
                : "---"}
            </div>
          </div>
        ),
      },
      {
        accessorKey: "cookFullProfile.onboardedAt",
        id: "onboardedAt",
        header: "Onboarded At",
        maxSize: 64,
        Cell: ({ row }) => (
          <div>
              {row?.original?.cookFullProfile?.onboardedAt}
          </div>
        ),
      },
      {
        accessorKey: "cookFullProfile.reactivatedAt",
        id: "reactivatedAt",
        header: "Reactivated At",
        maxSize: 64,
        Cell: ({ row }) => (
          <div>
              {row?.original?.cookFullProfile?.onboardedAt}
          </div>
        ),
      },
      {
        accessorKey: "cookFullProfile.workStatus",
        id: "cookStatus",
        header: "Cook Status",
        maxSize: 64,
        Cell: ({ row }) => (
          <div>
            <div>
              {row?.original?.cookFullProfile?.workStatus
                ? row?.original?.cookFullProfile?.workStatus
                : "-"}
            </div>
          </div>
        ),
      },
      {
        accessorKey: "localityType",
        header: "Locality Type",
        filterVariant: "select",
        filterFn: (row, id, filterValue) => {
          if (
            row?.original?.localityType &&
            Array.isArray(row?.original?.localityType) &&
            row?.original?.localityType.includes(filterValue)
          ) {
            return true;
          }
          return false;
        },
        filterSelectOptions: [
          { text: "PAST", value: "PAST" },
          { text: "PRIMARY", value: "PRIMARY" },
          { text: "WORKING", value: "WORKING" },
        ],
        Cell: ({ row }) =>
          row?.original?.localityType &&
          Array.isArray(row?.original?.localityType) ? (
            row?.original?.localityType.map((data: string, index: number) => {
              return (
                <p key={data + index} style={{ fontWeight: "bold" }}>
                  {`${
                    index === Array(row?.original?.localityType).length - 1
                      ? data
                      : data + ","
                  }`}
                </p>
              );
            })
          ) : (
            <p key={"empty"}>{"--"}</p>
          ),
      },
      {
        accessorKey: "localityName",
        header: "Locality Name",
        Cell: ({ row }) => (<p>{`${row?.original?.localityName}`}</p>)
      },
      {
        accessorKey: "lastSlotUpdatedBy",
        header: "lastSlotUpdatedAt/ lastSlotUpdatedBy",
        filterVariant: "select",
        id: "lastSlotUpdatedBy",
        size: 100,
        filterFn: "equalsString",
        filterSelectOptions: [
          { text: "Admin", value: "ADMIN" },
          { text: "App", value: "APP" },
        ],
        Cell: ({ row }) => (
          <div>
            <div
              style={{
                border: "1px solid",
                padding: "2px 6px",
                borderRadius: "4px",
                textAlign: "center",
                fontSize: "13px",
              }}
            >
              {row.original?.lastSlotUpdatedAt
                ? moment(row.original?.lastSlotUpdatedAt).format("LLL")
                : "Not Updated"}
            </div>
            <div style={{ textAlign: "center" }}>
              {row.original.lastSlotUpdatedBy
                ? row.original.lastSlotUpdatedBy
                : "---"}
            </div>
          </div>
        ),
      },
      {
        accessorKey: "metabase",
        id: "metabase",
        header: "History/ Geography",
        maxSize: 72,
        Cell: ({ row }) => (
          <Link href={MetaBaseUrl + row.original.cookId} target={"_blank"}>
            Dashboard
          </Link>
        ),
        Filter: () => <div></div>,
      },
      {
        accessorKey: "cookFullProfile.isMgCook",
        header: "Mg Cook",
        id: "isMgCook",
        filterVariant: "checkbox",
        accessorFn: (originalRow) =>
          originalRow?.cookFullProfile?.isMgCook ? "true" : "false",
        Cell: ({ cell }) =>
          cell.getValue() === "true" ? (
            <span
              style={{
                textAlign: "center",
                border: "1px solid #ff9800",
                borderRadius: "10px",
                color: "black",
                backgroundColor: "#ff9800",
                padding: "2px 6px",
              }}
            >
              {"MG Chef"}
            </span>
          ) : (
            "Part time"
          ),
      },
      {
        accessorKey: "workRequestedAt",
        header: "Work Requested",
        id: "workRequestedAt",
        filterVariant: "checkbox",
        accessorFn: (originalRow) =>
          originalRow.workRequestedAt ? "true" : "false",
        Cell: ({ row, cell }) =>
          cell.getValue() === "true" ? (
            <span
              style={{
                backgroundColor: "#90ee8f",
                color: "#006645",
                padding: "4px 10px",
                borderRadius: "10px",
              }}
            >
              {"Requested for Work"}
            </span>
          ) : (
            "---"
          ),
      },
      {
        accessorKey: "overallRating",
        header: "Internal Rating",
        id: "overallRating",
        filterVariant: "range",
        filterFn: (row, id, filterValue) => {
          if (
            (filterValue[0] === "" || !filterValue[0]) &&
            (filterValue[1] === "" || !filterValue[1])
          ) {
            return true;
          }
          if (filterValue[0] && filterValue[1]) {
            if (
              row?.original?.chefInternalRating?.overallRating >=
                filterValue[0] &&
              row?.original?.chefInternalRating?.overallRating <= filterValue[1]
            ) {
              return true;
            }
          }
          if (filterValue[0] && !filterValue[1]) {
            if (
              row?.original?.chefInternalRating?.overallRating >= filterValue[0]
            ) {
              return true;
            }
          }
          if (!filterValue[0] && filterValue[1]) {
            if (
              row?.original?.chefInternalRating?.overallRating <= filterValue[1]
            ) {
              return true;
            }
          }
          return false;
        },
        Cell: ({ row }) =>
          row?.original?.chefInternalRating?.overallRating
            ? Number(row?.original?.chefInternalRating?.overallRating).toFixed(
                2
              )
            : "--",
      },
      {
        accessorKey: "externalRating",
        header: "External Rating",
        id: "externalRating",
        filterVariant: "range",
        filterFn: (row, id, filterValue) => {
          if (
            (filterValue[0] === "" || !filterValue[0]) &&
            (filterValue[1] === "" || !filterValue[1])
          ) {
            return true;
          }
          if (filterValue[0] && filterValue[1]) {
            if (
              row?.original?.cookCustomerRating?.overallRating >=
                filterValue[0] &&
              row?.original?.cookCustomerRating?.overallRating <= filterValue[1]
            ) {
              return true;
            }
          }
          if (filterValue[0] && !filterValue[1]) {
            if (
              row?.original?.cookCustomerRating?.overallRating >= filterValue[0]
            ) {
              return true;
            }
          }
          if (!filterValue[0] && filterValue[1]) {
            if (
              row?.original?.cookCustomerRating?.overallRating <= filterValue[1]
            ) {
              return true;
            }
          }
          return false;
        },
        Cell: ({ row }) =>
          row?.original?.cookCustomerRating?.overallRating
            ? Number(row?.original?.cookCustomerRating?.overallRating).toFixed(
                2
              )
            : "--",
      },
      {
        accessorKey: "leadAcceptedAt-work",
        header: "Acceptance status",
        id: "leadAcceptedAt",
        filterVariant: "checkbox",
        accessorFn: (originalRow) =>
          originalRow.leadAcceptedAt ? "true" : "false",
        maxSize: 72,
        Cell: ({ row, cell }) => (
          <div>
            {row.original.leadAcceptedAt ? (
              <span
                style={{
                  backgroundColor: "#90ee8f",
                  padding: 6,
                  borderRadius: 6,
                }}
              >
                {moment(row?.original?.leadAcceptedAt).format("LLL")}
              </span>
            ) : row.original.leadRejectedAt ? (
              <span
                style={{
                  backgroundColor: "#e53935",
                  padding: 6,
                  borderRadius: 6,
                }}
              >
                {moment(row?.original?.leadRejectedAt).format("LLL")}
              </span>
            ) : (
              <span>{"--"}</span>
            )}
          </div>
        ),
      },
      {
        accessorKey: "leadAcceptedAt-allocate",
        id: "leadAcceptedAt-allocate",
        header: "Allocate",
        maxSize: 72,
        Filter: () => <div></div>,
        Cell: ({ row }) => (
          <div>
            {[TrialsV3Status.PENDING, TrialsV3Status.COOK_ALLOCATED].includes(
              record?.status
            ) && (
              <Button
                style={{ margin: 10 }}
                variant="contained"
                color="success"
                onClick={() => {
                  setSelectedData(row.original);
                  setOpenDialog(true);
                }}
                size="small"
              >
                Allocate
              </Button>
            )}
          </div>
        ),
      },
      {
        accessorKey: "contactedAt",
        id: "contactedAt",
        header: "ContactedAt",
        Filter: () => <div></div>,
        maxSize: 64,
        Cell: ({ row }) => (
          <div>
            <div>
              {row?.original?.contactedAt || contactedArr.includes(row?.original?.id)
                  ? <Box display={'flex'} flexDirection={'column'}>
                      <b>Already Contacted</b>
                      {`${row?.original?.callSummary || ''}`}
                    </Box>
                : <Button
                style={{ margin: 10 }}
                variant="contained"
                disabled={props?.data?.filter((e:any) => e?.id === row?.original?.id)[0].contactedAt}
                onClick={async () => {
                  setSelectedData(row);
                  setContacted(true);
                }}
                size="small"
              >
                Contacted
              </Button>}
            </div>
          </div>
        ),
      },
      {
        accessorKey: "cook-slot",
        id: "cook-slot",
        header: "Cook Slot",
        maxSize: 72,
        Filter: () => <div></div>,
        Cell: ({ row }) => (
          <Button
            style={{ margin: 10 }}
            variant="contained"
            onClick={async () => {
              setShowSlots(true);
              const res = await partnerSlotService?.getCookSlots(
                row?.original?.cookId
              );
              setData(res);
            }}
            size="small"
          >
            Cook Slot
          </Button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props?.trail?.bundlePlan, props?.trail?.genderPreference, record?.status]
  );

  const [columnOrder, setColumnOrder] = useState<any>(
    columns.map((c) => c.accessorKey)
  );
  const [columnVisibility, setColumnVisibility] = useState<any>({});
  const [loaded, setLoaded] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [prDetails, setPrDetails] = useState<any>();

    const getPRDetails = (prId: number) => {
      if(!prId) return;

      permanentReplacementService.getPrDetailsById(prId)
        .then((res) => {
            if(res) {
                setPrDetails(res);
            }
        }).catch((err)=>{
          console.error(err);
        })
    }
  useEffect(() => {
    if (!loaded) return;
    localStorage.setItem(
      "trial-allocationTab",
      JSON.stringify({ columnOrder, columnVisibility })
    );
    getPRDetails(record?.prId as number);
  }, [columnVisibility, columnOrder, loaded, record?.prId]);
  useEffect(() => {
    let tableData = localStorage.getItem("trial-allocationTab");
    if (tableData) {
      const data = JSON.parse(tableData);
      setColumnVisibility(data.columnVisibility);
      setColumnOrder(data.columnOrder);
      setLoaded(true);
    } else {
      localStorage.setItem(
        "trial-allocationTab",
        JSON.stringify({ columnOrder, columnVisibility })
      );
      setLoaded(true);
    }
    getPRDetails(record?.prId as number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
        <Box pl={'30px'}>
            {prDetails && prDetails?.prInitiationTags && Object?.entries(prDetails?.prInitiationTags)?.map(([reason, subReasons], index: number) => (
                <Box key={reason} display={'flex'} gap={'40px'} alignItems={'center'}>
                    <p><b>PR Reason {index + 1}: </b>{reason}</p>
                    {Array?.isArray(subReasons) && subReasons?.map((subReason: string, ind: number) => (
                        <p><b>PR Sub Reason {ind + 1}: </b>{subReason}</p>
                    ))}
                </Box>
            ))}
            {prDetails && prDetails?.comments && (
                <Box>
                    <p><b>PR Comments: </b>{prDetails?.comments}</p>
                </Box>
            )}
        </Box>
        {openDialog && (
            <AllocateTrialDialog
                data={selectedData}
                open={openDialog}
                setOpen={setOpenDialog}
                cookCut={record?.cookCut}
            />
        )}
      <ShowSlotsDialog showSlots={showSlots} setShowSlots={setShowSlots} data={data} />
        {contacted && (
            <AllocationContactedDialog type={1} trailId={props?.trail?.id} row={selectedData} open={contacted} setOpen={setContacted} callback={(row: any) => {
                contactedArr.push(row?.original?.id);
                let modifiedData:any = props?.data?.filter((e:any) => e?.id === row?.original?.id)
                modifiedData[0].contactedAt = moment().format("LLL");
                refresh();
                props.refresh();
                setSelectedData(null);
                setContacted(false);
            }} />
        )}
      {loaded && (
        <MaterialReactTable
          columns={columns}
          // enableColumnFilters={false}
          columnFilterModeOptions={null}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          data={props.data}
          enableColumnActions={false}
          enableHiding={true}
          enableColumnOrdering
          initialState={{
            density: "compact",
            showColumnFilters: true,
          }}
          enablePagination={false}
          onColumnOrderChange={setColumnOrder}
          onColumnVisibilityChange={setColumnVisibility}
          state={{ columnOrder, columnVisibility }}
        />
      )}
    </>
  );
};
export default TrialAllocationMetaShow;
