import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
  } from "@mui/material";
  import moment from "moment";
  import React, { useState } from "react";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import ChefForPartyService from "../../services/ChefForPartyService";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import { PaymentLinkDailog } from "../Common/PaymentLinksDailogs";
import { useLocation } from 'react-router-dom';
import ChefForPartyRefundDailog from "../Common/ChefForPartyRefundDailog";
import EditAmountChefForParty from "../Common/EditAmountChefForParty";
import {PaymentStatusEnum} from "../../enums/PaymentStatusEnum";
import ChefForPartStatusEnum from "../../enums/ChefForPartStatusEnum";

  const ChefForPartyOrderList = (props: any) => {
    const chefForPartyService = new ChefForPartyService();
    const [paymentDialog, setPaymentDialog] = useState(false)
    const [refundTrialModel, setRefundTrialModel] = useState<boolean>(false);
      const [editAmount, setEditAmount] = useState(false);
    const [paymentLinks, setPaymentLinks] = useState<any>();
    const [refundData, setRefundData] = useState<any>()
    const location = useLocation();
    const partyId = location.pathname.split('/')[2]
    const handleSendPayment = (data: any) => {
      EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
        message: "Are you sure you want to send the payment link?",
        onClose: (confirmed: boolean) => {
          if (confirmed) {
            chefForPartyService.sendPaymentLink(Number(partyId), data?.id).then((res) => {
              setPaymentDialog(true);
              setPaymentLinks(res);
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Payment link sent",
                type: CustomSnackbarTypes.SUCCESS,
              });
            });
          }
        },
        title: "Confirmation",
      });
    };
    const handleRefund = (data : any) => {
        setRefundTrialModel(true);
        setRefundData(data)
    }
    return (
        <>
        <PaymentLinkDailog
          data={paymentLinks}
          open={paymentDialog}
          callback={(data) => {
            setPaymentDialog(false);
          }}
        />
        <ChefForPartyRefundDailog
            data={refundData}
            open={refundTrialModel}
            refresh={props.refresh}
            callback={() => {
            setRefundTrialModel(false)
            }}
        />
        {editAmount && (
            <EditAmountChefForParty
                id={Number(partyId)}
                data={refundData}
                open={editAmount}
                refresh={props.refresh}
                setOpen={setEditAmount}
            />
        )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Order Summary</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>createdAt</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>RazorpayOrderId</TableCell>
              <TableCell>RefundId</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row: any) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell scope="row">{row?.id}</TableCell>
                <TableCell scope="row">{row?.orderSummary}</TableCell>
                <TableCell scope="row">{row?.amount}</TableCell>
                <TableCell scope="row">
                  {moment(row?.createdAt).format("LLL")}
                </TableCell>
                <TableCell scope="row">
                  {row?.payment?.orderPayment?.paymentStatus || "NOT_PAID"}
                </TableCell>
                <TableCell scope="row">
                  {row?.payment?.orderPayment?.razorpayOrderId || "---"}
                </TableCell>
                <TableCell scope="row">
                  {row?.payment?.orderPayment?.refundId || "---"}
                </TableCell>
                {(!row?.payment?.orderPayment || row?.payment?.orderPayment?.paymentStatus === "NOT_PAID") && (
                  <TableCell>
                    <Button
                      variant={"contained"}
                      onClick={() => handleSendPayment(row)}
                    >
                      Send Payment Link
                    </Button>
                  </TableCell>
                )}
                {row?.payment?.orderPayment?.paymentStatus === "PAID" && !row?.payment?.orderPayment?.paymentStatus?.refundId && (
                  <TableCell>
                    <Button
                      variant={"contained"}
                      onClick={() => handleRefund(row)}
                    >
                      Refund
                    </Button>
                  </TableCell>
                )}
                  <TableCell>
                    <Button
                        disabled={
                        row?.payment?.orderPayment?.paymentStatus === PaymentStatusEnum.PAID ||
                            row?.payment?.orderPayment?.paymentStatus === PaymentStatusEnum.REFUNDED ||
                            props?.party?.status === ChefForPartStatusEnum.CANCELLED
                    }
                      variant={"contained"}
                      color={'warning'}
                      onClick={() => {
                          setRefundData(row);
                          setEditAmount(true);
                      }}
                    >
                      Edit Amount
                    </Button>
                  </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </>
    );
  };

  export default ChefForPartyOrderList;
