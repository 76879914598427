import React, {useState} from 'react';
import {
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField as TextFieldMUI
} from "@mui/material";
import {ZohoBaseUrl, ZohoModulesUrl} from "../../enums/zoho";
import {ExtensionService} from "../../services/ExtensionServices";
import moment from "moment";
import {ExtensionStatusEnum} from "../../enums/ExtensionStatusEnum";

const extensionService = new ExtensionService()

const ExtensionListForModule = (props: any) => {

    const [id , setId] = useState<number>()
    const [text, setText] = useState("")
    const [cancelReasonModal, setCancelReasonModal] = useState<boolean>(false);

    return (
        <div>
            <Dialog
                open={cancelReasonModal}
                scroll={"paper"}
                maxWidth="xl"
            >
                <DialogTitle>Cancel extension</DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        spacing={2}
                        justifyContent={"space-evenly"}
                        style={{ padding: "12px 12px 12px 12px" }}
                    >
                        <Grid item>
                            <FormControl fullWidth>
                                <TextFieldMUI
                                    id="reason"
                                    onChange={(event) => {
                                        setText(event.target.value);
                                    }}
                                    value={text}
                                    label="Enter cancellation reason"
                                    variant="outlined"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ marginTop: "12px" }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            extensionService.cancelExtension(id!, text).then(() => {
                                props.refresh();
                                setCancelReasonModal(false);
                            });
                        }}
                        size={"large"}
                    >
                        Submit
                    </Button>
                    <Button
                        variant="contained"
                        color={"error"}
                        onClick={() => {
                            setCancelReasonModal(false);
                        }}
                        size={"large"}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Subscription</TableCell>
                            <TableCell>Visits</TableCell>
                            <TableCell>Cancellation reason</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>User Bundle</TableCell>
                            <TableCell>User subscription leave Id</TableCell>
                            <TableCell>Leave Date</TableCell>
                            <TableCell>Reason</TableCell>
                            <TableCell>Zoho</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell align='center'>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.map((row: any) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell scope="row">{row?.id}</TableCell>
                                <TableCell align="center">
                                    {row?.subscriptionId}
                                </TableCell>
                                <TableCell align="center">
                                    {row?.visits}
                                </TableCell>
                                <TableCell align="center">
                                    {row?.cancellationReason}
                                </TableCell>
                                <TableCell align="center">
                                    {row?.status}
                                </TableCell>
                                <TableCell>
                                    <Link href={`/#/UserBundles/${row?.userBundleMappingId}/show`}>
                                        {row?.userBundleMappingId}
                                    </Link>
                                </TableCell>
                                <TableCell align="center">
                                    {row?.userSubscriptionLeaveId}
                                </TableCell>
                                <TableCell align="center">
                                    {row?.userSubscriptionLeave?.date}
                                </TableCell>
                                <TableCell align="center">
                                    {row?.reason}
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        !!row?.zohoId &&
                                      <Link
                                        href={`${ZohoBaseUrl}${ZohoModulesUrl.EXTENSION}/${row.zohoId}`}
                                        target="_blank"
                                        rel="noopener"
                                      >
                                          {row.zohoId}
                                      </Link>
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        !!row?.createdAt &&
                                        moment(row?.createdAt).format('DD MMM YYYY, hh:mm:ss a')
                                    }
                                </TableCell>
                                <TableCell>
                                    {
                                        row.status !== ExtensionStatusEnum.CANCELLED &&
                                        <Button
                                          variant={'contained'}
                                          color={'error'}
                                          onClick={() => {
                                              setId(row.id);
                                              setCancelReasonModal(true);
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ExtensionListForModule;
