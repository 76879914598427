import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  useTheme,
} from "@mui/material";
import { default as TextFieldMUI } from "@mui/material/TextField/TextField";
import { useCallback, useState } from "react";
import { BundlePlan, SlotingGenderPreference, SlotSize } from "../../enums/common";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { ShortTimeEnum } from "../../enums/ShortTimeEnum";
import EventBus from "../../EventBus";
import PartnerSlotService from "../../services/PartnerTimeSlotService";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import { PartnerTimeSlotTable } from "./PartnerTimeSlotTable";
import "./styles.css";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import LocalityList from "../Locality/LocalityList";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import { useAppDispatch } from "../../store/hooks";
import { DatePicker } from "@mui/x-date-pickers";
import CancelIcon from '@mui/icons-material/Cancel';
import TransactionButton from "../Common/TransactionButton";
import PartnerSlotLocalFilter from "./PartnerSlotLocalFilter";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const PartnerSlotFilterForm = () => {
  const partnerSlotService: PartnerSlotService = new PartnerSlotService();
  const theme = useTheme();
  const [spinner, setSpinner] = useState(<p>Data not available</p>);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [data, setData] = useState<any[]>();
  const [locality, setLocality] = useState<any>();
  const [gender, setGender] = useState<string>("");
  const [slotSize, setSlotSize] = useState<number>(1.5);
  const [radius, setRadius] = useState<any>();
  const [category, setCategory] = useState<BundlePlan>(BundlePlan.POPULAR);
  const [visitOneTime, setVisitOneTime] = useState<ShortTimeEnum[]>([]);
  const [visitTwoTime, setVisitTwoTime] = useState<ShortTimeEnum[]>([]);
  const [dateUpdatedByAdmin, setDateUpdatedByAdmin] = useState<any>(null);
  const [dateUpdatedByCook, setDateUpdatedByCook] = useState<any>(null);
  const [originalData, setOriginalData] = useState();
  const dispatch = useAppDispatch();

  const handleOpenLocality = () => {
    dispatch(setCustomSelectOpen({open: true}));
  }

  const handleCloseLocality = (e: any) => {
    setLocality(e);
  };

  const handleGender = (e: any) => {
    setGender(e.target.value);
  };

  const handleRadius = (e: any) => {
    setRadius(e.target.value)
  }

  const handleSlotSize = (e: any) => {
    setSlotSize(e.target.value);
  };

  const handleCategory = (e: any) => {
    setCategory(e.target.value);
  };

  const handleVisitOneTime = (
    event: SelectChangeEvent<typeof visitOneTime>
  ) => {
    const {
      target: { value },
    } = event;
    setVisitOneTime(value as ShortTimeEnum[]);
  };

  const handleVisitOneTimeDelete = (e: any, value: any) => {
    e.preventDefault();
    setVisitOneTime(visitOneTime.filter((e) => e !== value))
  }

  const handleVisitTwoTimeDelete = (e: any, value: any) => {
    e.preventDefault();
    setVisitTwoTime(visitTwoTime.filter((e) => e !== value))
  }

  const handleVisitTwoTime = (
    event: SelectChangeEvent<typeof visitTwoTime>
  ) => {
    const {
      target: { value },
    } = event;
    setVisitTwoTime(value as ShortTimeEnum[]);
  };

  const handleSubmit = async (e: any) => {
    setSpinner(<Box sx={{ display: "flex" }}>
      <CircularProgress />
    </Box>);
    if (
      !locality
    ) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Data not valid",
        type: CustomSnackbarTypes.ERROR
      });
      return;
    }

    const response = await partnerSlotService.getFilteredCooks(
      locality?.id,
      radius,
      gender as string,
      slotSize,
      category,
      visitOneTime,
      visitTwoTime,
     dateUpdatedByAdmin,
     dateUpdatedByCook,
    );
    setData(response);
    setOriginalData(response);
    setIsDataFetched(true);
  };

  function getStyles(
    name: string,
    visitOneTime: readonly string[],
    theme: Theme
  ) {
    return {
      fontWeight:
        visitOneTime.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const updateDataFromFilter = useCallback((filterData: any, noOfFilter: number) => {
    if(noOfFilter > 0) {
      setData(filterData)
    } else {
      setData(originalData)
    }
  }, [originalData])
  
  return (
    <Card style={{overflow: 'auto'}}>
     <CardContent>
     <ResourceSelectDialog
        name={"Localities"}
        list={LocalityList}
        onClose={handleCloseLocality}
      />
      <div style={{ display: "flex", justifyContent: "center", gap: 25}}>
            <FormControl fullWidth className="cookSlotForm">
              <TextField
                id="outlined-number"
                value={radius}
                type="number"
                className="cookSlotForm"
                onChange={handleRadius}
                label="Radius In Meter"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
            <FormControl fullWidth className="cookSlotForm" style={{marginTop: "15px"}}>
            <InputLabel id="demo-simple-select-label">Slot Size</InputLabel>
              <Select
               className="cookSlotForm"
               labelId="demo-multiple-name-label"
               id="demo-multiple-name"
               value={slotSize}
               onChange={handleSlotSize}>
                  {Object.values(SlotSize).map((val) => {
                  return (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth className="cookSlotForm" style={{marginTop: "15px"}}>
              <InputLabel id="demo-simple-select-label">Gender</InputLabel>
              <Select
                className="cookSlotForm"
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={gender}
                onChange={handleGender}
              >
                {Object.values(SlotingGenderPreference).map((val) => {
                  return (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl className="cookSlotForm" fullWidth style={{marginTop: "15px"}}>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                className="cookSlotForm"
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                defaultValue="Category"
                value={category}
                onChange={handleCategory}
              >
                {Object.values(BundlePlan).map((val) => {
                  return (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
           </div>
           <div style={{ display: "flex", justifyContent: "center", gap: 25}}>
            <FormControl className="cookSlotForm" fullWidth style={{marginTop: "18px"}}>
              <InputLabel className="visitTime" id="demo-multiple-chip-label">
                Visit One Time
              </InputLabel>
              <Select
                autoWidth={true}
                className="cookSlotForm"
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={visitOneTime || ""}
                onChange={handleVisitOneTime}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        clickable
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(e) => {
                              e.stopPropagation();
                            }}
                          />
                        }
                        onDelete={(e) => {
                          handleVisitOneTimeDelete(e, value);
                        }}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {Object.values(ShortTimeEnum).map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, visitOneTime, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="cookSlotForm"  fullWidth style={{marginTop: "18px"}}>
              <InputLabel className="visitTime" id="demo-multiple-chip-label">
                Visit Two Time
              </InputLabel>
              <Select
                autoWidth={true}
                className="cookSlotForm"
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={visitTwoTime || ""}
                onChange={handleVisitTwoTime}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value}   clickable
                      deleteIcon={
                        <CancelIcon
                          onMouseDown={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      }
                      onDelete={(e) => {
                        handleVisitTwoTimeDelete(e, value);
                      }}/>
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {Object.values(ShortTimeEnum).map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, visitTwoTime, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="cookSlotForm"  fullWidth>
            <DatePicker
              label="Last Updated By Admin"
              inputFormat="dd MMM yyyy"
              className="cookSlotForm" 
              value={dateUpdatedByAdmin}
              disableMaskedInput
              onChange={(newValue) => {
                setDateUpdatedByAdmin(newValue);
              }}
              renderInput={(params) => {
                return <TextFieldMUI {...params} />;
              }}
            />
            </FormControl>
            <FormControl className="cookSlotForm"  fullWidth>
            <DatePicker
             className="cookSlotForm" 
              label="Last Updated By Cook"
              inputFormat="dd MMM yyyy"
              value={dateUpdatedByCook}
              disableMaskedInput
              onChange={(newValue) => {
                setDateUpdatedByCook(newValue);
              }}
              renderInput={(params) => {
                return <TextFieldMUI {...params} />;
              }}
            />
            </FormControl>
            </div>
            <div>
            <Grid container >
          <Grid>
          <TransactionButton
              style={{margin: "10px", marginRight: "30px", marginTop: "20px", padding: "8px"}}
              name={locality ? `LocalityId: ${locality?.id}` : "Choose Locality"}
              onClickHandler={() => handleOpenLocality()}
            />
          </Grid>
        <Grid>
        <Button
          className="cookSlotForm"
          style={{ marginTop: "20px"}}
          onClick={handleSubmit}
          variant="contained"
          type="submit"
        >
          Get Cooks
        </Button>
        </Grid>
        </Grid>
      </div>
      { isDataFetched ? 
        <PartnerSlotLocalFilter data={originalData} callback={(filterData, filters) => updateDataFromFilter(filterData, filters)}/>
        : <></>
      }
      {
      isDataFetched ? (
        <div className="partnerTimeSlotTable">
          <PartnerTimeSlotTable
            isFetched={isDataFetched}
            data={data}
          ></PartnerTimeSlotTable>
        </div>
      ) : (
        spinner
      )}
      <div className="cook-slot-footer">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  backgroundColor: "red",
                  height: "5px",
                  width: "50px",
                  padding: "0px",
                }}
              ></TableCell>
              <TableCell
                style={{
                  backgroundColor: "yellow",
                  height: "5px",
                  width: "50px",
                  padding: "0px",
                }}
              ></TableCell>
              <TableCell
                style={{
                  backgroundColor: "green",
                  height: "5px",
                  width: "50px",
                  padding: "0px",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Blocked</TableCell>
              <TableCell>CK_Blocked</TableCell>
              <TableCell>Free</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
     </CardContent>
    </Card>
  );
};