import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";

interface ConfirmationButtonProps {
  buttonProps?: ButtonProps;
  buttonLabel: string;
  onSuccess: () => void;
  title: string;
}

const ConfirmationButton: React.FC<ConfirmationButtonProps> = ({
  buttonProps,
  buttonLabel,
  onSuccess,
  title,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onSuccessHandler = () => {
    onSuccess();
    setIsOpen(false);
  }
  return (
    <>
      <Button
        variant="text"
        style={{ width: "max-content", fontSize: "12px" }}
        size="small"
        onClick={() => {
          setIsOpen(true);
        }}
        {...buttonProps}
      >
        {buttonLabel}
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <DialogTitle> {title} </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
            variant="contained"
            color="error"
          >
            No
          </Button>
          <Button onClick={onSuccessHandler} variant="contained" color="success">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationButton;
