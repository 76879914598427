import {createSlice} from "@reduxjs/toolkit";

export const userDetailslice = createSlice({
    name: 'userDetails',
    initialState: {
        value: {
            adminUser: {
                id: null,
                name: null,
                email: null,
                adminGroups: [],
                accessGroups: [],
                mobile: null,
            },
        }
    },
    reducers: {
        setUserDetails: (state, action) => {
            let payload = {
                ...action.payload,
                accessGroups: action.payload?.adminGroups?.map((group: any) => group.name)
            }
            state.value.adminUser = payload;
        }
    }
})

export const {setUserDetails} = userDetailslice.actions;

export default userDetailslice.reducer;
