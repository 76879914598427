import { Close } from "@mui/icons-material";
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography
} from "@mui/material";
import  React, {useEffect, useState} from "react";
import { CityEnum } from "../../enums/CityEnum";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import StarChefRosterService from "../../services/StarChefRosterService";
import { CustomSnackbarTypes } from "./CustomSnackBar";

interface InputProps {
  data: any;
  open: boolean;
  callback: () => void;
  refresh: (arg0: boolean) => void;
}

const starChefRosterService = new StarChefRosterService()

export const UpsertRosterDialog = (props: InputProps) => {
  const [city, setCity] = useState<number>(props.data)
  const [count, setCount] = useState<number>(-1)
  const [rosterData, setRosterData] = useState<any>([])
  const [innerRefresh, setInnerRefresh] = useState<boolean>(false)

  let handleClose = () => {
    props.callback()
  }
  useEffect(() => {
      starChefRosterService
        .getDefaultCount({ cityId: city })
        .then((e) => {
          setRosterData(e.data);
        })
        .catch((err) => {
          console.log(err);
          setRosterData([]);
        });
        setInnerRefresh(false);
    
  }, [city, innerRefresh]);
  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      scroll={"paper"}
      maxWidth="xl"
    >
      <DialogTitle>Update Roster</DialogTitle>
      <DialogContent>
        <IconButton
          onClick={() => {
            handleClose();
            props.refresh(true);
          }}
          sx={{ position: "absolute", top: 2, right: 5 }}
        >
          {" "}
          <Close />{" "}
        </IconButton>
        <FormControl sx={{ width: "150px", marginTop: "10px" }}>
          <InputLabel id="cityId">City</InputLabel>
          <Select
            labelId="cityId"
            id="cityId"
            label="cityId"
            value={Object.values(CityEnum)[city - 1]}
          >
            {Object.values(CityEnum).map((value, index: number) => (
              <MenuItem
                key={value}
                onClick={(e) => {
                  setCity(index + 1);
                }}
                value={value}
              >
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="subtitle2" color={'red'} mt={1.5}>
          Warning: The changes in inventories will be applied immediately to all the
          dates from today.
        </Typography>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Day</TableCell>
                <TableCell align="center">City</TableCell>
                <TableCell align="center">StarChef</TableCell>
                <TableCell align="center" />
              </TableRow>
            </TableHead>
            <TableBody>
              {rosterData &&
                rosterData.map((row: any) => (
                  <TableRow key={`${row.day}${row.cityId}`}>
                    <TableCell align="center">{row.day}</TableCell>
                    <TableCell align="center">
                      {Object.values(CityEnum)[city - 1]}
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        id="rowCount"
                        defaultValue={row.count}
                        sx={{ width: "100px" }}
                        variant="outlined"
                        onChange={(e) => setCount(Number(e.target.value))}
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        onClick={() => {
                          if (count > -1 && count !== row.count) {
                            starChefRosterService
                              .updateRoster(row.id, count)
                              .then(() => {
                                EventBus.emitEvent(
                                  EventBusEventsEnum.SNACKBAR_SHOW,
                                  {
                                    message: "Update Done",
                                    type: CustomSnackbarTypes.SUCCESS,
                                  }
                                );
                              });
                          }
                        }}
                      >
                        Save
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions style={{ marginTop: "12px" }}>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            handleClose();
            props.refresh(true);
          }}
          size={"large"}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};