export enum RenewalPaymentStatus {
    PAID = "PAID",
    NOT_PAID = "NOT-PAID"
}

export enum RenewalBundleStatus {
    ACTIVE = "ACTIVE",
    GRACE = "GRACE",
    FULFILLED = "FULFILLED",
    CLOSED = "CLOSED"
}