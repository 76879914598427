import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { useRefresh } from "react-admin";
import { useLocation } from "react-router-dom";
import { OneMealService } from "../../services/OneMealService";

interface InputProps {
  data: any;
  open: boolean;
  setOpen: (open: boolean) => any;
}

// interface IReason {
//   label: string;
//   noteRequired: boolean;
//   noteLabel?: string;
// }

const oneMealService = new OneMealService();
// const reasonsList: IReason[] = [
//   {
//     label: 'Data is wrong for cooks',
//     noteRequired: true,
//     noteLabel: 'What data is wrong for chef?',
//   },
//   {
//     label: 'I trust my personal list of cooks',
//     noteRequired: true,
//     noteLabel: 'Why?',
//   },
//   {
//     label: 'Quality cooks are not available in the list',
//     noteRequired: true,
//     noteLabel: 'What do you mean by quality cooks?',
//   },
//   {
//     label: 'List does not contain Cx preferred cooks',
//     noteRequired: true,
//     noteLabel: 'What preference did not match?',
//   },
//   {
//     label: 'Not available in allocation tab',
//     noteRequired: true,
//     noteLabel: 'Is data for SLC and slots updated for chef, if no what data is not updated?',
//   },
//   {
//     label: 'No cook in list accepted the job',
//     noteRequired: false,
//   },
//   {
//     label: 'Cook has not accepted but confirmed on call',
//     noteRequired: false,
//   },
//   {
//     label: 'Other',
//     noteRequired: true,
//     noteLabel: 'Reason?',
//   },
// ]

const AllocateOneMealDialog = (props: InputProps) => {
  const refresh = useRefresh();
  const [oneMealCookCut, setOneMealCookCut] = useState<string>("0");
  let location = useLocation();
  // const [outsideCook, setOutsideCook] = useState(false);
  // const [reason, setReason] = useState<IReason>();
  let OneMealId = location.pathname.split("/")[2];
  // const [allocationNotes, setAllocationNotes] = useState<string>("");

  useEffect(() => {
    // if (props?.data) {
    //   (async function () {
    //     const response = await oneMealService.checkCookFromAllocationListForOneMeal(
    //       Number(props?.data?.oneMealServiceId),
    //       Number(props?.data?.cookId),
    //     );
    //     if (!response.isInterestedInTrial) {
    //       setOutsideCook(true);
    //     } else {
    //       setOutsideCook(false);
    //       setReason(undefined);
    //       setAllocationNotes("");
    //     }
    //   })();
    // }
    if (props.data && !props.data.isMgCook) {
      setOneMealCookCut("149");
    } else if (props.data && props.data.isMgCook) {
      setOneMealCookCut("0");
    }
  }, [props.data]);

  return (
    <div>
      <Dialog open={props.open} scroll={"paper"} maxWidth="xl">
        <DialogTitle>One Meal Allocation</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {props && props.data && !props.data.isMgCook && (
              <TextField
                value={oneMealCookCut}
                disabled={true}
                type={"number"}
                label={"Meal Cook Cut"}
                style={{
                  marginTop: "12px",
                }}
                onChange={(event) => {
                  setOneMealCookCut(event.target.value);
                }}
              />
            )}
            {/*{outsideCook && (*/}
            {/*  <FormControl fullWidth>*/}
            {/*    <InputLabel id="addGroup">Reason</InputLabel>*/}
            {/*    <Select*/}
            {/*      labelId="addGroup"*/}
            {/*      id="addGroup"*/}
            {/*      label="Group"*/}
            {/*      value={reason}*/}
            {/*    >*/}
            {/*      {reasonsList.map((value: IReason) => (*/}
            {/*        <MenuItem*/}
            {/*          key={value.label}*/}
            {/*          onClick={(e) => {*/}
            {/*            setReason(value);*/}
            {/*          }}*/}
            {/*          value={value.label}*/}
            {/*        >*/}
            {/*          {value.label}*/}
            {/*        </MenuItem>*/}
            {/*      ))}*/}
            {/*    </Select>*/}
            {/*  </FormControl>*/}
            {/*)}*/}
            {/*{outsideCook && reason?.noteRequired && (*/}
            {/*  <TextField*/}
            {/*    value={allocationNotes}*/}
            {/*    type={"string"}*/}
            {/*    label={`Reason`}*/}
            {/*    style={{*/}
            {/*      marginTop: "12px",*/}
            {/*    }}*/}
            {/*    onChange={(event) => {*/}
            {/*      setAllocationNotes(event.target.value);*/}
            {/*    }}*/}
            {/*  />*/}
            {/*)}*/}
          </div>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              // if(outsideCook && !reason) {
              //   EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
              //     message: "Please select the reason",
              //     type: CustomSnackbarTypes.WARNING,
              //   });
              //   return;
              // }
              // if (reason?.noteRequired && allocationNotes.trim() === '') {
              //   EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
              //     message: "Please enter reason",
              //     type: CustomSnackbarTypes.WARNING,
              //   });
              //   return;
              // } else
              if (
                  props?.data?.cookId &&
                  Number(oneMealCookCut) >= 0
              ) {
              let payload = {
                cookId: props?.data?.cookId,
                oneMealServiceCookCut: oneMealCookCut,
                // allocationNotes: outsideCook ? `${reason?.label} - ${allocationNotes}`: ''
                allocationNotes: '',
              };
              oneMealService
                .assignCookToOneMealService(Number(OneMealId), payload)
                .then((res) => {
                  if (res.status === 200) {
                    oneMealService
                      .acceptOneMealService(Number(OneMealId), {
                        accepted: true,
                        reason: "Accepted",
                      })
                      .then(() => {
                        if (res) {
                          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                            message: "Success",
                            type: CustomSnackbarTypes.SUCCESS,
                          });
                        }
                        setOneMealCookCut("0");
                        // setReason(undefined);
                        props.setOpen(false);
                        refresh();
                      });
                  }
                });
              } else {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: `Fill the details properly`,
                  type: CustomSnackbarTypes.ERROR,
                });
              }
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              props.setOpen(false);
              setOneMealCookCut("0");
              // setReason(undefined);
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AllocateOneMealDialog;
