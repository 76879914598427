import React, {useEffect, useState} from 'react';
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import {TransactionServies} from "../../services/TransactionsService";
import TransactionV3ListForModule from "../Transactions/TransactionV3ListForModule";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";

interface InputProps {
    userBundleMappingId: number;
}

const transactionService = new TransactionServies();

const UserBundleTransactionsTab = (props: InputProps) => {
    let [subData, setSubData] = useState<any>(null);
    let [isLoading, setIsLoading] = useState<boolean>();

    const refreshData = (userBundleMappingId: number) => {
        setIsLoading(true)
        transactionService.getTransactionForBundle(userBundleMappingId)
            .then((data) => {
                setSubData(data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            })
    }

    const refreshListener = () => {
        refreshData(props.userBundleMappingId);
    }

    useEffect(() => {
        const listener = EventBus.getInstance();
        listener.on(EventBusEventsEnum.REFRESH_CHILD_USER_BUNDLE_MAPPING_TABS, refreshListener)
        return () => {
            listener.removeListener(EventBusEventsEnum.REFRESH_CHILD_USER_BUNDLE_MAPPING_TABS, refreshListener)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        refreshData(props.userBundleMappingId);
    }, [props.userBundleMappingId])
    
    return (
        <div>
            {
                isLoading ?
                    <CircularLoader/>
                    :
                    <div>
                        {
                            subData &&
                            subData?.data &&
                            Array.isArray(subData?.data) &&
                            (
                                subData?.data.length>0 ?
                                    <TransactionV3ListForModule
                                        data={subData?.data}
                                        refresh={() => {
                                            refreshData(props.userBundleMappingId)
                                        }}
                                    />
                                    :
                                    <EmptyListPlaceholder
                                        message='No Transaction Found'
                                    />
                            )
                        }
                    </div>
            }
        </div>
    );
};

export default UserBundleTransactionsTab;
