import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextareaAutosize,
    TextField
} from "@mui/material";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import Cooks from "../Cooks/Cooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import { useAppDispatch } from "../../store/hooks";
import { DatePicker } from "@mui/x-date-pickers";
import { CookLeavesService } from "../../services/CookLeavesService";
import { CreateCookShortLeaveRequest, ShortLeave } from "../../interfaces/CookLeaves/CreateCookShortLeaveRequest";
import moment from "moment";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import { CreateCookLongLeaveRequest } from "../../interfaces/CookLeaves/CreateCookLongLeaveRequest";
import { Authenticated } from 'react-admin';
import { useNavigate } from "react-router-dom";
import { CookLeaveRequestedBy } from '../../enums/CookLeavesRequestBy';
import { CookSlotTimeEnum, CookSlotTimeEnumArray } from '../../enums/CookSlotTimeEnum';
import { CookLeaveTypes } from '../../enums/CookLeaveTypes';
import EmptyListPlaceholder from '../Common/EmptyListPlaceholder';
import { getReasonCategory } from '../Common/constants/constant';
import ReasonSubreasonModuleEnum from '../../enums/ReasonSubreasonModuleEnum';
import { ActionTypeEnum } from '../../enums/ActionTypeEnum';

const cookLeaveService = new CookLeavesService();

const CookLeavesCreate = () => {

    let [selectedCook, setSelectedCook] = useState<any>(null);
    let [selectedLeaveType, setSelectedLeaveType] = useState<any>(null);
    let [requestedBy, setRequestedBy] = useState<any>(CookLeaveRequestedBy.COOK)
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    let [shortLeaveStartDate, setShortLeaveStartDate] = useState<any>(null);
    let [shortLeaveEndDate, setShortLeaveEndDate] = useState<any>(null);
    let [shortLeaveData, setShortLeaveData] = useState<any>(null);
    let [shortLeaveStartTime, setShortLeaveStartTime] = useState<any>(null);
    let [shortLeaveEndTime, setShortLeaveEndTime] = useState<any>(null);
    let [reasons, setReasons] = useState<any>([]);
    let [leaveReason, setLeaveReason] = useState<any>(null);
    let [otherReasons, setOtherReasons] = useState<string>('');

    let [longLeaveStartDate, setLongLeaveStartDate] = useState<any>(null);
    let [longLeaveEndDate, setLongLeaveEndDate] = useState<any>(null);
    let [longLeaveData, setLongLeaveData] = useState<any>(null);
    let [longLeaveStartTime, setLongLeaveStartTime] = useState<any>(null);
    let [longLeaveEndTime, setLongLeaveEndTime] = useState<any>(null);


    const resetState = (resetCook: boolean = true) => {
        if (resetCook) {
            setSelectedCook(null);
        }
        setSelectedLeaveType(null);
        setShortLeaveData(null);
        setShortLeaveStartDate(null);
        setShortLeaveEndDate(null);
        setShortLeaveStartTime(null)
        setShortLeaveEndTime(null);
        setLongLeaveStartDate(null);
        setLongLeaveEndDate(null);
        setLongLeaveData(null);
        setLongLeaveEndTime(null);
        setShortLeaveEndTime(null);
        setReasons([])
        setLeaveReason(null);
        setOtherReasons("");
    }

    const handleCookSelect = (data: any) => {
        setSelectedCook(data);
        resetState(false);
    }

    const handleSelectCook = () => {
        dispatch(setCustomSelectOpen({
            open: true
        }));
    }

    /* Checking If time entered is not less than one hour */

    function parseTimeString(timeString: string): Date {
        const [time, modifier] = timeString.split(' ');
        let [hours, minutes] = time.split(':').map(Number);

        if (modifier === 'PM' && hours < 12) {
            hours += 12;
        }
        if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }

        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
    }

    const isDifferenceLessThanOneHour = useCallback((time1: CookSlotTimeEnum, time2: CookSlotTimeEnum): boolean => {
        const date1 = parseTimeString(time1);
        const date2 = parseTimeString(time2);

        const diffInMilliseconds = date2.getTime() - date1.getTime();
        const diffInMinutes = diffInMilliseconds / (1000 * 60);

        return (diffInMinutes < 60 || diffInMinutes < 0);
    }, [])

    /* Short Leave  */

    const getShortLeaveData = useCallback(async () => {
        if (!shortLeaveStartDate || !shortLeaveEndDate || !shortLeaveStartTime || !shortLeaveEndTime || !selectedCook?.id) {
            return;
        }
        let leaveStartDate = moment(shortLeaveStartDate).format('YYYY-MM-DD');
        let leaveEndDate = moment(shortLeaveEndDate).format('YYYY-MM-DD');
        if (isDifferenceLessThanOneHour(shortLeaveStartTime, shortLeaveEndTime)) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: 'Cannot mark leave for less time slot',
                type: CustomSnackbarTypes.ERROR
            });
            setShortLeaveStartTime("");
            setShortLeaveEndTime("");
            setShortLeaveData(null);
            return;
        }
        let payload = {
            startDate: leaveStartDate,
            endDate: leaveEndDate,
            startTime: shortLeaveStartTime,
            endTime: shortLeaveEndTime,
        }
        let data = await cookLeaveService.getShortLeaveSlotDetails(selectedCook.id, payload);
        if (!data) return;
        setShortLeaveData(data);
    }, [isDifferenceLessThanOneHour, selectedCook?.id, shortLeaveEndDate, shortLeaveEndTime, shortLeaveStartDate, shortLeaveStartTime]);


    const handleShortLeaveSelect = async (value: any, key: boolean) => {
        key ? setShortLeaveStartDate(value) : setShortLeaveEndDate(value);
    }

    const handleShortLeaveProceed = () => {
        if (!shortLeaveStartDate || !shortLeaveEndDate || !shortLeaveStartTime || !shortLeaveEndTime || !leaveReason || (leaveReason === 'Other' && !otherReasons)) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: 'Please select Start and End  Date and Time or Reason',
                type: CustomSnackbarTypes.ERROR
            });
            return;
        }
        let leaveStartDate = moment(shortLeaveStartDate).format('YYYY-MM-DD');
        let leaveEndDate = moment(shortLeaveEndDate).format('YYYY-MM-DD');

        if (!leaveStartDate || !leaveEndDate || !shortLeaveStartTime || !shortLeaveEndTime || !leaveReason || (leaveReason === 'Other' && !otherReasons)) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: 'Please select Start and End  Date and Time or Reason',
                type: CustomSnackbarTypes.ERROR
            });
            return;
        }
        let payload: CreateCookShortLeaveRequest = {
            startDate: leaveStartDate,
            endDate: leaveEndDate,
            startTime: shortLeaveStartTime,
            endTime: shortLeaveEndTime,
            requestedBy: requestedBy,
            reason: leaveReason === "Other" ? otherReasons : leaveReason,
            leaveDetails: []
        }
        shortLeaveData.forEach((house: any) => {
            let houseLeaveData: ShortLeave = {
                houseId: house.houseId,
                slots: []
            }
            house.slots.forEach((slot: any) => {
                if (slot?.possibleLeaveSlot) {
                    houseLeaveData.slots.push(slot.slotName);
                }
            })
            if (houseLeaveData.slots.length > 0) {
                payload.leaveDetails.push(houseLeaveData);
            }
        })
        EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
            message: " Are you sure you want to mark this leave?",
            onClose: (confirmed: boolean) => {
                if (confirmed) {
                    cookLeaveService.markShortLeave(selectedCook.id, payload).then((res) => {
                        if (res) {
                            resetState();
                            navigate("/CookLeaves")
                            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                message: 'Leave Marked',
                                type: CustomSnackbarTypes.SUCCESS
                            });
                        }
                    });
                }
            },
            title: "Confirmation"
        });
    }


    /* Long Leave  */

    const handleLongLeaveSelect = async (value: any, key: boolean) => {
        key ? setLongLeaveStartDate(value) : setLongLeaveEndDate(value);
    }

    const getLongLeaveData = useCallback(async () => {
        if (!longLeaveStartDate || !longLeaveEndDate || !longLeaveStartTime || !longLeaveEndTime || !selectedCook?.id) {
            return;
        }
        let leaveStartDate = moment(longLeaveStartDate).format('YYYY-MM-DD');
        let leaveEndDate = moment(longLeaveEndDate).format('YYYY-MM-DD');
        if (leaveStartDate === leaveEndDate && isDifferenceLessThanOneHour(longLeaveStartTime, longLeaveEndTime)) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: 'Cannot mark leave for this time slot',
                type: CustomSnackbarTypes.ERROR
            });
            setLongLeaveStartTime("");
            setLongLeaveEndTime("");
            setLongLeaveData(null);
            return;
        }
        let data = await cookLeaveService.getLongLeaveSlotDetails(selectedCook.id, leaveStartDate, leaveEndDate, longLeaveStartTime, longLeaveEndTime);
        if (!data) return;
        setLongLeaveData(data)
    }, [isDifferenceLessThanOneHour, longLeaveEndDate, longLeaveEndTime, longLeaveStartDate, longLeaveStartTime, selectedCook?.id])

    const handleLongLeaveProceed = () => {
        if (!longLeaveStartDate || !longLeaveEndDate || !longLeaveStartTime || !longLeaveEndTime || !leaveReason || (leaveReason === 'Other' && !otherReasons)) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: 'Please select Start and End  Date and Time or Reason',
                type: CustomSnackbarTypes.ERROR
            });
            return;
        }
        let leaveStartDate = moment(longLeaveStartDate).format('YYYY-MM-DD');
        let leaveEndDate = moment(longLeaveEndDate).format('YYYY-MM-DD');
        let payload: CreateCookLongLeaveRequest = {
            requestedBy,
            reason: leaveReason === "Other" ? otherReasons : leaveReason,
            startDate: leaveStartDate,
            endDate: leaveEndDate,
            startTime: longLeaveStartTime,
            endTime: longLeaveEndTime
        }
        EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
            message: " Are you sure you want to mark this leave?",
            onClose: (confirmed: boolean) => {
                if (confirmed) {
                    cookLeaveService.markLongLeave(selectedCook.id, payload).then((res) => {
                        if (res) {
                            resetState();
                            navigate("/CookLeaves")
                            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                message: 'Leave Marked',
                                type: CustomSnackbarTypes.SUCCESS
                            });
                        }
                    });
                }
            },
            title: "Confirmation"
        });

    }

    useEffect(() => {
        getShortLeaveData().then(() => { });
    }, [getShortLeaveData])

    useEffect(() => {
        getLongLeaveData().then(() => { });
    }, [getLongLeaveData])

    useEffect(() => {
        getReasonCategory(ReasonSubreasonModuleEnum.CX_LEAVES, ActionTypeEnum.CREATE_COOK_LEAVE).then((data) => {
            setReasons(data);
        }).catch((err) => {
            console.log(err);
        })
    }, [selectedCook])

    return (
        <Authenticated>
            <Card
                style={{
                    padding: '12px',
                    height: '100%',
                    overflow: 'auto'
                }}
            >
                <ResourceSelectDialog
                    onClose={handleCookSelect}
                    name={'Cooks'}
                    list={Cooks}
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <h3 style={{ margin: '0px' }}>
                        Create New Leaves
                    </h3>
                    <Button
                        variant={'outlined'}
                        color={'secondary'}
                        onClick={() => resetState(true)}
                    >
                        Reset Form
                    </Button>
                </div>
                <div>
                    <p>Please Select a Cook</p>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            onClick={handleSelectCook}
                            style={{
                                marginRight: '12px'
                            }}
                        >
                            Select Cook
                        </Button>
                        {
                            selectedCook &&
                            <p
                                style={{
                                    margin: '0px'
                                }}
                            >
                                Create leaves for <b>{selectedCook?.fname} {selectedCook?.lname}</b>
                            </p>
                        }
                    </div>
                </div>
                <FormControl style={{ width: "180px", margin: "30px 0 10px" }}>
                    <InputLabel id="RequestedBy">Requested By</InputLabel>
                    <Select
                        labelId="requestedBy"
                        id="requestedBy"
                        label="Requested By"
                        value={requestedBy}
                        onChange={(event: any) => {
                            setRequestedBy(event.target.value);
                        }}
                    >
                        {Object.values(CookLeaveRequestedBy).map((value) => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div>
                    <p>Please Select a Leave Type</p>
                    <div>
                        <Button
                            variant={selectedLeaveType === 'SHORT_LEAVE' ? 'contained' : 'outlined'}
                            color={'primary'}
                            style={{ marginRight: '12px' }}
                            onClick={() => {
                                setSelectedLeaveType('SHORT_LEAVE')
                            }}
                            disabled={!selectedCook}
                        >
                            Create Short Leave
                        </Button>
                        <Button
                            variant={selectedLeaveType === 'LONG_LEAVE' ? 'contained' : 'outlined'}
                            color={'primary'}
                            onClick={() => {
                                setSelectedLeaveType('LONG_LEAVE')
                            }}
                            disabled={!selectedCook}
                        >
                            Create Long Leave
                        </Button>
                    </div>
                </div>
                {
                    !!selectedCook &&
                    <div>
                        {
                            selectedLeaveType === 'SHORT_LEAVE' &&
                            <div>
                                <p>Create Short leave</p>
                                <div style={{ display: 'flex', gap: "1rem", width: "40%" }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Start Time</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={shortLeaveStartTime}
                                                label="Start Time"
                                                onChange={(e) => setShortLeaveStartTime(e.target.value)}
                                            >
                                                {
                                                    CookSlotTimeEnumArray.map((time) => (
                                                        <MenuItem key={time} value={time}>{time}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>

                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">End Time</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={shortLeaveEndTime}
                                                label="End Time"
                                                onChange={(e) => setShortLeaveEndTime(e.target.value)}
                                            >
                                                {
                                                    CookSlotTimeEnumArray.map((time) => (
                                                        <MenuItem key={time} value={time}>{time}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                        </div>

                                {selectedLeaveType === CookLeaveTypes.SHORT_LEAVE
                                    && shortLeaveStartTime && shortLeaveEndTime
                                    && <div style={{ display: 'flex', gap: "1rem", width: "40%" }}  >
                                                <DatePicker
                                                    label="Select Leave Date"
                                                    inputFormat="dd MMM yyyy"
                                                    disableMaskedInput
                                                    value={shortLeaveStartDate}
                                                    minDate={moment().subtract(7, "d").toDate()}
                                                    onChange={(value: any) => handleShortLeaveSelect(value, true)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                                <DatePicker
                                                    label="Select Leave Date"
                                                    inputFormat="dd MMM yyyy"
                                                    disableMaskedInput
                                                    value={shortLeaveEndDate}
                                                    minDate={moment().subtract(7, "d").toDate()}
                                                    onChange={(value: any) => handleShortLeaveSelect(value, false)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                        </div>
                                }
                                <div style={{ marginTop: "15px" }}>
                                    <Box sx={{ maxWidth: "30%" }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Leave Reason</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={leaveReason}
                                                label="Leave Reason"
                                                onChange={(e) => setLeaveReason(e.target.value)}
                                            >
                                                {
                                                    reasons && reasons.map((reason: any) => (
                                                        <MenuItem key={reason?.category} value={reason?.category}>{reason?.category}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    {
                                        leaveReason === "Other" &&
                                        <div style={{ marginTop: "10px" }}>
                                            <InputLabel sx={{ color: "black" }} id="Notes">Other Reason</InputLabel>
                                            <TextareaAutosize
                                                minRows={4}
                                                placeholder="Enter Reason"
                                                onChange={e => setOtherReasons(e.target.value)}
                                                style={{ width: 300, paddingLeft: "10px", resize: "none" }}
                                            />
                                        </div>
                                    }
                                </div>
                                {
                                    !!shortLeaveData &&
                                    Array.isArray(shortLeaveData) &&
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center" width="40%">Customer Address</TableCell>
                                                    <TableCell align="center" width="60%">Time Slots</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    shortLeaveData ? shortLeaveData.map((slot: any) => {
                                                        if (slot.slots.length === 0) {
                                                            return null;
                                                        }
                                                        return (
                                                            <TableRow
                                                                key={slot?.houseId}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell align="center">{slot?.address}</TableCell>
                                                                <TableCell align="center">
                                                                    {
                                                                        !!slot?.slots &&
                                                                        Array.isArray(slot?.slots) &&
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'center'
                                                                            }}
                                                                        >
                                                                            {
                                                                                slot.slots.map((ele: any) => {
                                                                                    return (
                                                                                        <>
                                                                                            {
                                                                                                ele?.possibleLeaveSlot ?
                                                                                                    <Chip
                                                                                                        key={ele.id}
                                                                                                        sx={{
                                                                                                            padding: '6px 12px',
                                                                                                            border: '1px solid #0000002b',
                                                                                                            marginRight: '12px',
                                                                                                            borderRadius: '20px',
                                                                                                            cursor: 'pointer',
                                                                                                        }}
                                                                                                        color='success'
                                                                                                        label={ele.time}
                                                                                                    /> :
                                                                                                    <Chip
                                                                                                        key={ele.id}
                                                                                                        sx={{
                                                                                                            padding: '6px 12px',
                                                                                                            border: '1px solid #0000002b',
                                                                                                            marginRight: '12px',
                                                                                                            borderRadius: '20px',
                                                                                                            cursor: 'not-allowed',
                                                                                                            backgroundColor: 'white'
                                                                                                        }}
                                                                                                        label={ele.time}
                                                                                                    />
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }) :
                                                        <EmptyListPlaceholder message='No Slot to Show' />
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginTop: '12px'
                                    }}
                                >
                                    {
                                        !!shortLeaveData &&
                                        Array.isArray(shortLeaveData) &&
                                        <Button
                                            variant={'contained'}
                                            color={'primary'}
                                            onClick={() => handleShortLeaveProceed()}
                                        >
                                            Proceed
                                        </Button>
                                    }
                                </div>
                            </div>
                        }
                        {
                            selectedLeaveType === 'LONG_LEAVE' &&
                            <div>
                                <p>Create Long leave</p>
                                <div style={{ display: 'flex', gap: "1rem", width: "30%" }}  >
                                    <DatePicker
                                        label="Start Date"
                                        inputFormat="dd/MM/yy"
                                        disableMaskedInput
                                        value={longLeaveStartDate}
                                        minDate={moment().subtract(7, "d").toDate()}
                                        onChange={(value: any) => handleLongLeaveSelect(value, true)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Start Time</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={longLeaveStartTime}
                                                label="Start Time"
                                                onChange={(e) => setLongLeaveStartTime(e.target.value)}
                                            >
                                                {
                                                    CookSlotTimeEnumArray.map((time) => (
                                                        <MenuItem key={time} value={time}>{time}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                </div>
                                {selectedLeaveType === CookLeaveTypes.LONG_LEAVE
                                    && longLeaveStartDate && longLeaveStartTime
                                    && <div style={{ display: 'flex', gap: "1rem", width: "30%" }}>
                                        <DatePicker
                                        label=" End Date"
                                        inputFormat="dd/MM/yy"
                                        disableMaskedInput
                                        value={longLeaveEndDate}
                                        minDate={moment().subtract(7, "d").toDate()}
                                        onChange={(value: any) => handleLongLeaveSelect(value, false)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">End Time</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={longLeaveEndTime}
                                                label="End Time"
                                                onChange={(e) => setLongLeaveEndTime(e.target.value)}
                                            >
                                                {
                                                    CookSlotTimeEnumArray.map((time) => (
                                                        <MenuItem key={time} value={time}>{time}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                }
                                <div style={{ marginTop: "15px" }}>
                                    <Box sx={{ maxWidth: "30%" }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Leave Reason</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={leaveReason}
                                                label="Leave Reason"
                                                onChange={(e) => setLeaveReason(e.target.value)}
                                            >
                                                {
                                                    reasons && reasons.map((reason: any) => (
                                                        <MenuItem key={reason?.category} value={reason?.category}>{reason?.category}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    </div>
                                    {
                                        leaveReason === "Other" &&
                                        <div style={{ marginTop: "10px" }}>
                                            <InputLabel sx={{ color: "black" }} id="Notes">Other Reason</InputLabel>
                                            <TextareaAutosize
                                                minRows={4}
                                                placeholder="Enter Reason"
                                                onChange={e => setOtherReasons(e.target.value)}
                                                style={{ width: 300, paddingLeft: "10px", resize: "none" }}
                                            />
                                        </div>
                                    }
                                {
                                    !!longLeaveData &&
                                    Array.isArray(longLeaveData) &&
                                    <div>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center" width="40%">House Address</TableCell>
                                                        <TableCell align="center" width="60%">Slots</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        longLeaveData ? longLeaveData.map((house: any) => {
                                                            return (
                                                                <TableRow
                                                                    key={house.houseId}
                                                                >
                                                                    <TableCell align="center">{house?.address}</TableCell>
                                                                    <TableCell align="center"> {
                                                                        !!house?.slots &&
                                                                        Array.isArray(house?.slots) &&
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'center'
                                                                            }}
                                                                        >
                                                                            {
                                                                                house?.slots.map((ele: any) => {
                                                                                    return (
                                                                                        <>
                                                                                            {
                                                                                                ele?.possibleLeaveSlot ?
                                                                                                    <Chip
                                                                                                        key={ele.id}
                                                                                                        sx={{
                                                                                                            padding: '6px 12px',
                                                                                                            border: '1px solid #0000002b',
                                                                                                            marginRight: '12px',
                                                                                                            borderRadius: '20px',
                                                                                                            cursor: 'pointer',
                                                                                                        }}
                                                                                                        color='success'
                                                                                                        label={ele.time}
                                                                                                    /> :
                                                                                                    <Chip
                                                                                                        key={ele.id}
                                                                                                        sx={{
                                                                                                            padding: '6px 12px',
                                                                                                            border: '1px solid #0000002b',
                                                                                                            marginRight: '12px',
                                                                                                            borderRadius: '20px',
                                                                                                            cursor: 'not-allowed',
                                                                                                            backgroundColor: 'white'
                                                                                                        }}
                                                                                                        label={ele.time}
                                                                                                    />
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    }</TableCell>
                                                                </TableRow>
                                                            )
                                                        }) :
                                                            <EmptyListPlaceholder message='No House to Show' />
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                }
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginTop: '12px'
                                    }}
                                >
                                    {
                                        !!longLeaveData &&
                                        Array.isArray(longLeaveData) &&
                                        <Button
                                            variant={'contained'}
                                            color={'primary'}
                                            onClick={() => handleLongLeaveProceed()}
                                        >
                                            Proceed
                                        </Button>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
            </Card>
        </Authenticated>
    );
};

export default CookLeavesCreate;
