import {Show, Tab, TabbedShowLayout} from 'react-admin';
import CityMetaShow from "./CityMetaShow";
import CityServicesTab from "./CityServicesTab";

const CityTabs = () => {
    return(
        <TabbedShowLayout syncWithLocation={false}>
            <Tab label={"Details"} key={"Details"}>
                <CityMetaShow />
            </Tab>
            <Tab label={"Services"} key={"Services"}>
                <CityServicesTab />
            </Tab>
        </TabbedShowLayout>
    )
};

function CityShow(props: any) {
    return (
        <Show {...props} actions={false}>
            <CityTabs />
        </Show>
    )
}

export default CityShow;
