import HttpClient from "../HttpClient";
import {GetListResult, GetOneResult} from "react-admin";
import { StarChefVisitSlots } from "../enums/StarChefTimeSlots";
import { InventoryResponse } from "../interfaces/StarChefVisit/InventoryResponse";

export default class StarChefInventoryService {
    private basePath = '/star-chef-inventory/centaurus';
    
    async getAllStarChefInventory(cityId: number): Promise<GetListResult<InventoryResponse>> {

        let data = await HttpClient.get(`${this.basePath}/city/${cityId}`)
        return {
            data: !!data ? data.data : [],
            total: !!data ? data.data.length : 0
        }
    }

    async upsertInventory(date: string, timeSlot: StarChefVisitSlots, availableCount: number, cityId: number): Promise<GetOneResult<InventoryResponse>> {
        let data = await HttpClient.post(`${this.basePath}/`, {date, timeSlot, availableCount, cityId});
        return data?.data;
    }
}
