import React from 'react';
import {useRecordContext} from "react-admin";
import {Button} from "@mui/material";
import {setCustomSelectOpen} from "../../store/reducers/customSelect";
import {useAppDispatch} from "../../store/hooks";

const ResourceSelectDialogButton = () => {
    const dispatch = useAppDispatch();
    let record = useRecordContext();
    return (
        <Button
            onClick={() => {
                dispatch(setCustomSelectOpen({
                    open: false,
                    record
                }));
            }}
        >
            Select
        </Button>
    )
};

export default ResourceSelectDialogButton;
