import { useEffect, useState } from "react";
import moment from "moment";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { Authenticated } from "ra-core";
import StarChefInventoryService from "../../services/StarChefInventoryService";
import { Button, Loading } from "ra-ui-materialui";
import { StarChefVisitSlots } from "../../enums/StarChefTimeSlots";
import { InventoryResponse } from "../../interfaces/StarChefVisit/InventoryResponse";
import { DatePicker } from "@mui/x-date-pickers";
import { UpsertInvertoryDialog } from "../Common/UpsertInvertoryDialog";
import StarChefRosterService from "../../services/StarChefRosterService";
import { CityEnum } from "../../enums/CityEnum";
import { RosterResponse } from "../../interfaces/StarChefVisit/StarChefRosterResponse";
import { UpsertRosterDialog } from "../Common/StarChefRosterDailog";
import CkMuiTable from "../Common/CkMuiTable/CkMuiTable";

const starChefInventoryService = new StarChefInventoryService()
const starChefRosterService = new StarChefRosterService()

const StarChefInventory = () => {
    const [allDate, setAllDate] = useState<any>({});
    const [allDateCopy, setAllDateCopy] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true)
    const [page,setPage] = useState(0)
    const [rowperPage,setrowperPage] = useState(10)
    const [searchDate, setSearchDate] = useState<Date | null>(null);
    const [upsertData, setUpsertData] = useState<any>(null);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [city, setCity] = useState<number>(1)
    const [roster, setRoster] = useState<boolean>(false)
    const handleChangePage = (event: any, newPage: number) => {
      setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event: any) => {
      setrowperPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const DefaultCount = (day: string, data: RosterResponse[]): RosterResponse  => {
      let selectedDay = data.filter((e: RosterResponse) =>  e.day === day)
      return selectedDay[0]
    }

    const DataCreator = (rosterDates: RosterResponse[]) => {
      let data: any = {};
      const endDate = moment().add(6, "months");
      for (let i = moment(); endDate.diff(i, "d") >= 0; i.add(1, "d")) {
        Object.values(StarChefVisitSlots).forEach((e) => {
          data[`${i.format("YYYY-MM-DD")}${e}`] = {
            date: i.format("YYYY-MM-DD"),
            availableCount: DefaultCount(i.format('dddd'), rosterDates).count,
            bookingCount: 0,
            timeSlot: e,
            city: Object.values(CityEnum)[city-1],
          };
        });
      }
      return data;
    };

    const GetData = async (cityId: number) => {
      let defaultData: any = {};
      let rosterData = (await starChefRosterService.getDefaultCount({ cityId })).data
      defaultData = { ...DataCreator(rosterData) };
      let inventoies = (await starChefInventoryService.getAllStarChefInventory(cityId)).data
      inventoies.forEach((d: InventoryResponse) => {
        defaultData[`${d.date}${d.timeSlot}`] = {
          date: d.date,
          availableCount: d.availableCount,
          bookingCount: d.bookingCount,
          timeSlot: d.timeSlot,
          city: Object.values(CityEnum)[d.cityId - 1],
        };
      }); 
      return defaultData
    }
    
    useEffect(() => {
        setLoading(true)
        GetData(city)
        .then((data: any) => {
          setAllDateCopy(data);
          if (searchDate) {
            let afterSearch: any = {};
            Object.values(StarChefVisitSlots).forEach((e) => {
              afterSearch[`${moment(searchDate).format("YYYY-MM-DD")}${e}`] =
                allDateCopy[`${moment(searchDate).format("YYYY-MM-DD")}${e}`];
            });
            setAllDate(afterSearch)
            setPage(0);
          }
          else{
            setAllDate(data)
          }
        })
        .catch( err => {
          console.log(err)
        })
        .finally(()=>{
          setRefresh(false)
        })
        setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [city, refresh, searchDate]);

  return (
    <Authenticated>
      {loading ? (
        <Loading />
      ) : (
        <Paper>
          <UpsertInvertoryDialog
            data={{upsertData, cityId: city}}
            open={upsertData ? true : false}
            callback={() => setUpsertData(null)}
            refresh={() => setRefresh(true)}
          />
          <UpsertRosterDialog
            data={city}
            open={roster}
            callback={() => setRoster(false)}
            refresh={() => setRefresh(true)}
          />
          <Container sx={{ marginY: "20px" }}>
            <DatePicker
              label="Search By Date"
              inputFormat="dd MMM yyyy"
              value={searchDate}
              disableMaskedInput
              minDate={new Date()}
              maxDate={moment().add(6, "months").toDate()}
              onChange={(newValue) => {
                setSearchDate(moment(newValue).toDate());
              }}
              clearable={true}
              clearText={<span 
                onClick={(e) => {
                  e.stopPropagation();
                  setSearchDate(null);
                }}
              >Clear</span>}
              renderInput={(params) => {
                return <TextField {...params} />;
              }}
            />
            <FormControl sx={{ width: "150px", marginLeft: '20px' }}>
            <InputLabel id="cityId">City</InputLabel>
            <Select labelId="cityId" id="cityId" label="cityId" value={Object.values(CityEnum)[city-1]}>
              {Object.values(CityEnum).map((value, index : number) => (
                <MenuItem
                  key={value}
                  onClick={(e) => {
                    setCity(index+1)
                    setPage(0)
                  }}
                  value={value}
                >
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
            <Button
              label={"Roster"}
              variant={"contained"}
              sx={{ marginLeft: "15px" , float: 'right'}}
              onClick={() => setRoster(true)}
            />
          </Container>
          <TableContainer>
            <CkMuiTable
                sx={{}}
                headers={["Date", "Time Slot", "City", "Booking Account", "Available Count", "Action"]}
                rowData={[]}
                paginationCount={Object.values(allDate).length}
                paginationRowsPerPage={rowperPage}
                paginationPage={page}
                paginationOnPageChange={handleChangePage}
                paginationoOnRowsPerPageChange={handleChangeRowsPerPage}
                paginationRowsPerPageOptions={[5, 10, 25]}
                paginationLabelRowsPerPage={<span>Rows:</span>}
                paginationLabelDisplayedRows={({ page }) => {
                  return `Page: ${page + 1}`;
                } }
                paginationBackIconButtonProps={{
                  color: "secondary",
                }}
                paginationNextIconButtonProps={{
                  color: "secondary",
                }}
                paginationSelectProps={{
                  inputProps: {
                    "aria-label": "page number",
                  },
                }} 
                isTablePagination={true}
              >
                {Object.values(allDate)
                  .slice(page * rowperPage, page * rowperPage + rowperPage)
                  .map((row: any) => (
                    <TableRow key={`${row.date}${row.timeSlot}`}>
                      <TableCell align="center">{moment(row.date).format('DD MMM YYYY')}</TableCell>
                      <TableCell align="center">{row.timeSlot}</TableCell>
                      <TableCell align="center">{row.city}</TableCell>
                      <TableCell align="center">{row.bookingCount}</TableCell>
                      <TableCell align="center">{row.availableCount}</TableCell>
                      <TableCell>
                        <Button
                          label={"Update"}
                          variant="text"
                          onClick={() => setUpsertData(row)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </CkMuiTable>
          </TableContainer>
        </Paper>
      )}
    </Authenticated>
  );
};
export default StarChefInventory;
