import moment from "moment";
import { FunctionField, Show, SimpleShowLayout, TextField, useRecordContext } from "react-admin";
import CustomReferenceField from "../Common/CustomReferenceField";

function StarCleanerVisitMetaShow() {
  const record = useRecordContext();
  return (
    <Show actions={false}>
      <div style={{ display: "grid", gridTemplateColumns: "30% 40% 30%" }}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="status" />
          <TextField source="visitDate" />
          <TextField source="visitTime" />
          <TextField source="customerName" />
          <TextField source="customerPhone" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <CustomReferenceField
            label="User Id"
            listName={"Customers"}
            source={"userId"}
          />
          <CustomReferenceField
            label="Cleaner Id"
            listName={"Cleaners"}
            source={"cleanerId"}
          />
          <TextField source="cleanerName" />
          <TextField source="cleanerPhone" />
          <FunctionField
            label="CleanerAllocated At"
            render={() => {
              if (
                moment(record?.cleanerAllocatedAt).format(
                  "dddd, MMMM Do YYYY, h:mm:ss a"
                ) === "Invalid date"
              ) {
                return null;
              } else {
                return moment(record?.cleanerAllocatedAt).format(
                  "dddd, MMMM Do YYYY, h:mm:ss a"
                );
              }
            }}
          />
          <CustomReferenceField
            label="CleanerAllocated By"
            listName={"AdminUsers"}
            source={"cleanerAllocatedBy"}
          />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField label="Payment Status" source="paymentStatus" />
          <CustomReferenceField
            label="House Id"
            listName={"Houses"}
            source={"houseId"}
          />
          <CustomReferenceField
            label="City Id"
            listName={"Cities"}
            source={"cityId"}
          />
          <TextField source="cityName" />
          <CustomReferenceField
            label="Locality Id"
            listName={"Localities"}
            source={"localityId"}
          />
          <TextField source="localityName" />
          <CustomReferenceField
            label="Area Id"
            listName={"Areas"}
            source={"areaId"}
          />
          <TextField source="areaName" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField source="cancellationReason" />
          <TextField source="customerFeedback" />
          <TextField source="customerRating" />
          <TextField source="questions" />
          <TextField source="cancelledBy" />
          <TextField source="cancelledByType" />
          <TextField source="note" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField source="amount" />
          <TextField source="discount" />
          <TextField source="refundReason" />
          <TextField source="razorpayOrder.refundAmount" />          
          <TextField source="numberOfRescheduleReminder" />
          <TextField source="rescheduleReason" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField source="createdBy" />
          <TextField source="createdByType" />
          <TextField source="updatedBy" />
          <TextField source="updatedByType" />
        </SimpleShowLayout>
      </div>
    </Show>
  );
}

export default StarCleanerVisitMetaShow;
