import reasonSubreasonService from "../../../services/ReasonSubreasonServices";

interface item {
  id: number,
  reason: string,
  subReason: string,
  clientId: number,
  module: string,
  isActive: boolean,
  shouldCreateEscalation: boolean,
  actionType: string,
  isVisible: boolean,
  internalRatingParamId?:null,
  createdAt: string,
  "updatedAt": string
}
const rservice = new reasonSubreasonService();

export const getReasonCategory = async (module: string,actionType:string) => {
  let filteredItems: Array<item> = []
  await rservice.fetchReasonAndSubReason(module,actionType).then((res)=>{
    filteredItems = res.data.data
  })
  let categoryItemsArray:any=[];
  await filteredItems.forEach((item:any)=> {
    let index = categoryItemsArray.findIndex((it:any)=>{
      return it.category===item.reason;
    });
    if(index===-1){
      categoryItemsArray.push({
        category:item.reason,
        subCategory:(!item?.subReason || item?.subReason === '') ?  [] : [item.subReason],
      })
    }
    else{
      if(item.subReason !== ''){
        categoryItemsArray[index].subCategory.push(item.subReason);
      }
    }
  })
  return categoryItemsArray;
};
