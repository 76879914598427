import { FormControl, Grid, TextField, Button } from '@mui/material';
import React, { useState } from 'react';
import { Edit } from 'react-admin'
import { EventBusEventsEnum } from '../../enums/EventBusEventsEnum';
import EventBus from '../../EventBus';
import StarChefVisitService from '../../services/StarChefVisitService';
import { CustomSnackbarTypes } from '../Common/CustomSnackBar';
import { useLocation } from "react-router-dom";

const starChefVisitService = new StarChefVisitService()


const StarChefEdit = (props: any) => {
  const location = useLocation();
  const starChefId = location.pathname.split("/")[2];
  const [totalPeople, setTotalPeople] = useState<number>(0)
  const [extraPrice, setExtraPrice] = useState<string>('')
    return (
      <Edit {...props}>
        <Grid
          container
          columns={1}
          rowSpacing={3.5}
          style={{ padding: "12px 12px 12px 12px" }}
        >
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="Total People"
                id="totalPeople"
                value={totalPeople}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(event: any) => setTotalPeople(event.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="Extra Price"
                id="extraPrice"
                value={extraPrice}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(event: any) => setExtraPrice(event.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => {
              if (!extraPrice || !totalPeople) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Fill the details to proceed",
                  type: CustomSnackbarTypes.WARNING,
                });
                return;
              }
              starChefVisitService.editStarChefVisit(Number(starChefId), {totalPeople: Number(totalPeople), extraPrice})
                .then(() => {
                  window.location.href = `#/StarChefVisit/${starChefId}/show`;
                });
            }}
            size={"large"}
          >
            Submit
          </Button>
        </Grid>
        </Grid>
      </Edit>
    );
};

export default StarChefEdit;
