import { createSlice } from "@reduxjs/toolkit";

const makeCallSlice = createSlice({
    name: "makeCall",
    initialState: { makeCall: true },
    reducers: {
        setMakeCall: (state, action) => {
            const {makeCall} = action.payload;
            state.makeCall = makeCall;
        },
    }
})


export const {setMakeCall} = makeCallSlice.actions

export default makeCallSlice.reducer;