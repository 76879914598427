import React from 'react';
import {Button, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import moment from "moment";
import CallButton from '../Common/CallButton/CallButton';
import { UserType } from '../../enums/common';

const UserBundleMappingListForModule = (props: any) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <Link
                href={`/#/UserBundles/create`}
                style={{
                    alignSelf: 'flex-end',
                    marginBottom: '10px'
                }}
                underline="none"
            >
                <Button
                    variant={'contained'}
                    color={'secondary'}
                    size="small"
                >
                    Create User Bundle
                </Button>
            </Link>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>House</TableCell>
                            <TableCell>Bundle</TableCell>
                            <TableCell>User Mobile</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>Start Slot</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>End Slot</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Quoted net price</TableCell>
                            <TableCell>Meal Mapping</TableCell>
                            <TableCell>Cook Cut</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.map((row: any) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell scope="row">{row?.id}</TableCell>
                                <TableCell align="center">
                                    <Link href={`/#/Houses/${row?.houseId}/show`}>
                                        {row?.houseId}
                                    </Link>
                                </TableCell>
                                <TableCell align="center">
                                    <Link href={`/#/Bundles/${row?.bundleId}/show`}>
                                        {row?.bundleId}
                                    </Link>
                                </TableCell>
                                <TableCell align="center">
                                    <div
                                        style={{
                                            flexDirection: 'column'
                                        }}
                                    >
                                        {
                                            !!row &&
                                            !!row.house &&
                                            !!row.house.customers &&
                                            Array.isArray(row.house.customers) &&
                                            row.house.customers.map((ele: any) => {
                                                return (
                                                    <CallButton toPhoneNumber={ele?.mobile} userType={UserType.CUSTOMER}/>
                                                    // <p key={ele.mobile}>{ele.mobile}</p>
                                                )
                                            })
                                        }
                                    </div>
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        !!row &&
                                        !!row.startDate &&
                                        moment(row?.startDate, 'YYYY-MM-DD').format('DD MMM YYYY')
                                    }
                                </TableCell>
                                <TableCell align="center">{row?.startSlot}</TableCell>
                                <TableCell align="center">
                                    {
                                        !!row &&
                                        !!row.endDate &&
                                        moment(row?.endDate, 'YYYY-MM-DD').format('DD MMM YYYY')
                                    }
                                </TableCell>
                                <TableCell align="center">{row?.endSlot}</TableCell>
                                <TableCell align="center">{row?.status}</TableCell>
                                <TableCell align="center">{row?.quotedNetPrice}</TableCell>
                                <TableCell align="center">
                                    {
                                        row?.userBundleMeta?.mealMapping &&
                                        JSON.stringify(row.userBundleMeta.mealMapping)
                                    }
                                </TableCell>
                                <TableCell align="center">{row?.cookCut}</TableCell>
                                <TableCell align="center">
                                    <Link href={`/#/UserBundles/${row.id}/show`}>
                                        <Button variant={'contained'} color={'primary'}>
                                            Show
                                        </Button>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default UserBundleMappingListForModule;
