import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import ResourceSelectDialog from "./ResourceSelectDialog";
import Cooks from "../Cooks/Cooks";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  // FormControl,
  // InputLabel,
  // MenuItem,
  // Select,
  TextField,
} from "@mui/material";
import { CookService } from "../../services/CookServices";
import {useRefresh } from "react-admin";
import { useAppDispatch } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import HttpClient from "../../HttpClient";
import {ErrorDialog} from "./ErrorDialog";
import { OneMealService } from "../../services/OneMealService";

interface InputProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  id: string;
}

// interface IReason {
//   label: string;
//   noteRequired: boolean;
//   noteLabel?: string;
// }

const cookService = new CookService();

const oneMealService=new OneMealService();

// const reasonsList: IReason[] = [
//   {
//     label: 'Data is wrong for cooks',
//     noteRequired: true,
//     noteLabel: 'What data is wrong for chef?',
//   },
//   {
//     label: 'I trust my personal list of cooks',
//     noteRequired: true,
//     noteLabel: 'Why?',
//   },
//   {
//     label: 'Quality cooks are not available in the list',
//     noteRequired: true,
//     noteLabel: 'What do you mean by quality cooks?',
//   },
//   {
//     label: 'List does not contain Cx preferred cooks',
//     noteRequired: true,
//     noteLabel: 'What preference did not match?',
//   },
//   {
//     label: 'Not available in allocation tab',
//     noteRequired: true,
//     noteLabel: 'Is data for SLC and slots updated for chef, if no what data is not updated?',
//   },
//   {
//     label: 'No cook in list accepted the job',
//     noteRequired: false,
//   },
//   {
//     label: 'Cook has not accepted but confirmed on call',
//     noteRequired: false,
//   },
//   {
//     label: 'Other',
//     noteRequired: true,
//     noteLabel: 'Reason?',
//   },
// ]

const AssignCookOnOneMealDialog = (props: InputProps) => {
  const refresh = useRefresh(); 
  const [selectedCook, setSelectedCook] = useState<any>(null);
  const [mealCookCut, setMealCookCut] = useState<string>("");
  const [isMgCook, setIsMgCook] = useState<boolean>(false);
  const [isConveyanceAllowed, setIsConveyanceAllowed] = useState<boolean>(false);
  // const [outsideCook, setOutsideCook] = useState(false);
  // const [reason, setReason] = useState<IReason>();
  // const [allocationNotes, setAllocationNotes] = useState<string>("");
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorRes, setErrorRes] = useState<any>();
  const dispatch = useAppDispatch();

  const handleClose = async (recordData: any) => {
    setSelectedCook(recordData);
    checkMgCook(recordData?.id);
  };

  useEffect(()=>{
    if(selectedCook){
      oneMealService.checkConveynceEligibility(parseInt(props?.data?.id),selectedCook?.id).then((data)=>{
        setIsConveyanceAllowed(data?.eligibleForConveyance)
      })
    }
  },[selectedCook,props?.data?.id])

  // useEffect(() => {
  //   try {
  //     if (selectedCook) {
  //       (async function () {
  //         const response = await oneMealService.checkCookFromAllocationListForOneMeal(
  //           parseInt(props?.data?.id),
  //           selectedCook?.id
  //         );
  //         if (!response.isAutoAllocatedLead) {
  //           setOutsideCook(true);
  //         } else {
  //           setOutsideCook(false);
  //           setReason(undefined);
  //           setAllocationNotes("");
  //         }
  //       })();
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   return () => {};
  // }, [props?.data?.id, selectedCook]);

  const checkMgCook = (cookId: number) => {
    cookService.getMgCookSalary(cookId).then((cookSalary) => {
      const isMgCook = cookSalary[cookSalary.length - 1]?.endDate === null;
      setIsMgCook(isMgCook);
      setMealCookCut(isMgCook ? "0" : "149");
    });
  };

  const handleSelectCook = () => {
    setIsConveyanceAllowed(false);
    dispatch(
      setCustomSelectOpen({
        open: true,
      })
    );
  };

  const handleSubmit = () => {
    // if(outsideCook && !reason) {
    //   EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
    //     message: "Please select the reason",
    //     type: CustomSnackbarTypes.WARNING,
    //   });
    //   return;
    // }
    // if (reason?.noteRequired && allocationNotes.trim() === '') {
    //   EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
    //     message: "Please enter reason",
    //     type: CustomSnackbarTypes.WARNING,
    //   });
    //   return;
    // } else
    if (
      selectedCook &&
      Number(mealCookCut) >= 0
    ) {
      let payload = {
        cookId: selectedCook.id,
        oneMealServiceCookCut: mealCookCut,
        // allocationNotes: outsideCook ? `${reason?.label} - ${allocationNotes}`: ''
        allocationNotes: '',
        isConveyanceAllowed: isConveyanceAllowed,
      };
      HttpClient.post(`/centaurus/one-meal-service/${parseInt(props?.data?.id)}/assign`, payload)
        .then((res) => {
          if (res) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
              message: "Success",
              type: CustomSnackbarTypes.SUCCESS,
            });
          }
          setMealCookCut("0");
          setSelectedCook(null);
          setIsMgCook(false);
          setIsConveyanceAllowed(false);
          props.setOpen(false);
          refresh();
        })
        .catch((err) => {
          setErrorRes(err?.response?.data?.data);
          if(err?.response?.data?.data){
            setOpenError(true);
          }
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: err?.response?.data?.data ? `` : err?.response?.data?.error,
            type: CustomSnackbarTypes.ERROR,
            duration:  err?.response?.data?.data ? 0 : 3000,
          });
        })
  } else {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: `Fill the details properly`,
        type: CustomSnackbarTypes.ERROR,
      });
    }
  };

  return (
    <div>
      {!!props.open && (
        <ResourceSelectDialog
          name={"Cooks"}
          list={Cooks}
          onClose={handleClose}
        />
      )}
      <Dialog open={props.open} scroll={"paper"} maxWidth="xs" fullWidth>
        <DialogTitle>Assign cook</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={handleSelectCook}
            >
              Select Cook
            </Button>
            {!!selectedCook && (
              <p>Selected cook - {selectedCook?.fname + selectedCook?.lname}</p>
            )}
            {!isMgCook && (
              <TextField
                value={mealCookCut}
                type={"number"}
                label={
                 "One Meal Cook Cut"
                }
                disabled={true}
                style={{
                  marginTop: "12px",
                }}
                onChange={(event) => {
                  setMealCookCut(event.target.value);
                }}
              />
            )}
            <FormGroup>
              <FormControlLabel label='Conveyance Allowed' control={
                <Checkbox checked={isConveyanceAllowed} onClick={(e)=>{
                  EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
                    message: "Are you sure you want to change Conveyance Allowed",
                    onClose: (confirmed: boolean) => {
                      if (confirmed) {
                        setIsConveyanceAllowed(!isConveyanceAllowed);
                      }
                    },
                    title: "Confirmation"
                  });
                }}/>
              }/>
            </FormGroup>
            {/*{outsideCook && (*/}
            {/*  <FormControl fullWidth>*/}
            {/*    <InputLabel id="addGroup">Reason</InputLabel>*/}
            {/*    <Select*/}
            {/*      labelId="addGroup"*/}
            {/*      id="addGroup"*/}
            {/*      label="Group"*/}
            {/*      value={reason}*/}
            {/*    >*/}
            {/*      {reasonsList.map((value: IReason) => (*/}
            {/*        <MenuItem*/}
            {/*          key={value.label}*/}
            {/*          onClick={(e) => {*/}
            {/*            setReason(value);*/}
            {/*          }}*/}
            {/*          value={value.label}*/}
            {/*        >*/}
            {/*          {value.label}*/}
            {/*        </MenuItem>*/}
            {/*      ))}*/}
            {/*    </Select>*/}
            {/*  </FormControl>*/}
            {/*)}*/}
            {/*{outsideCook && reason?.noteRequired && (*/}
            {/*  <TextField*/}
            {/*    value={allocationNotes}*/}
            {/*    type={"string"}*/}
            {/*    label={reason?.noteLabel || 'Why?'}*/}
            {/*    style={{*/}
            {/*      marginTop: "12px",*/}
            {/*    }}*/}
            {/*    onChange={(event) => {*/}
            {/*      setAllocationNotes(event.target.value);*/}
            {/*    }}*/}
            {/*  />*/}
            {/*)}*/}
          </div>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              props.setOpen(false);
              setMealCookCut("0");
              setSelectedCook(null);
              setIsMgCook(false);
              setIsConveyanceAllowed(false);
              // setReason(undefined);
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorDialog
          open={openError}
          onClose={() => {setOpenError(false)}}
          data={errorRes}
      />
    </div>
  );
};

export default AssignCookOnOneMealDialog;
