import React from 'react';
import {Box, Grid} from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CircularLoader from '@mui/material/CircularProgress';
import {CookResponse} from "../../interfaces/Cook/CookResponse";

interface InputProps {
    open: boolean;
    handleClose: () => void;
    handleSubmit: () => void;
    selectedCook?: CookResponse;
    loading: boolean;
    cookAvailability: {
        hubMatching?: string;
        availability?: {
            to?: string;
            from?: string;
            mealMapping: {
                slot: string;
                time: string;
                bufferMinutes: number
            }
        }[];
        genderMatching?: string;
        categoryMatching?: string;
    }
}

const CookVerifyDialog = (props: InputProps) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            maxWidth={'md'}
        >
            <DialogTitle>
                {"Check and Verify Cook"}
                {props.handleClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={props.handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {
                        props.loading ?
                            <Box display={'flex'} justifyContent={'center'} p={2} alignItems={'center'}>
                                <CircularLoader sx={{width: '40px'}} />
                            </Box>:
                            <Grid container spacing={2}>
                                <Grid md={4} sm={4} xs={4} item>
                                    {'Name'}
                                </Grid>
                                <Grid md={8} sm={8} xs={8} item>
                                    <Box px={2} py={1}>
                                        {props?.selectedCook?.fname + ' ' + props?.selectedCook?.lname}
                                    </Box>
                                </Grid>
                                <Grid md={4} sm={4} xs={4} item>
                                    {'Mobile'}
                                </Grid>
                                <Grid md={8} sm={8} xs={8} item>
                                    <Box px={2} py={1}>
                                        {props?.selectedCook?.mobile}
                                    </Box>
                                </Grid>
                                <Grid md={4} sm={4} xs={4} item>
                                    {'Category'}
                                </Grid>
                                <Grid md={8} sm={8} xs={8} item>
                                    <Box px={2} py={1} sx={{...(props?.cookAvailability?.categoryMatching === 'MATCHING' && {bgcolor: '#90EE90'})}}>
                                        {props?.selectedCook?.category}
                                    </Box>
                                </Grid>
                                <Grid md={4} sm={4} xs={4} item>
                                    {'Gender'}
                                </Grid>
                                <Grid md={8} sm={8} xs={8} item>
                                    <Box px={2} py={1} sx={{...(props?.cookAvailability?.genderMatching === 'MATCHING' && {bgcolor: '#90EE90'})}}>
                                        {props?.selectedCook?.gender}
                                    </Box>
                                </Grid>
                                <Grid md={4} sm={4} xs={4} item>
                                    {'Hub Matching'}
                                </Grid>
                                <Grid md={8} sm={8} xs={8} item>
                                    <Box px={2} py={1} sx={{...(props?.cookAvailability?.hubMatching === 'MATCHING' && {bgcolor: '#90EE90'})}}>
                                        {props?.cookAvailability?.hubMatching}
                                    </Box>
                                </Grid>
                                <Grid md={4} sm={4} xs={4} item>
                                    {'Meal Mapping'}
                                </Grid>
                                <Grid md={8} sm={8} xs={8} item>
                                    <Box px={2} py={1}>
                                        {
                                            props?.cookAvailability?.availability?.length !== 0 ?
                                                <Table size="small" sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                                                    <TableHead>
                                                        <TableRow >
                                                            <TableCell>Slot</TableCell>
                                                            <TableCell>Time</TableCell>
                                                            <TableCell>Buffer</TableCell>
                                                            <TableCell>Availability</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {props?.cookAvailability?.availability?.map((row) => (
                                                            <TableRow key={row.from}>
                                                                <TableCell component="th" scope="row">
                                                                    {row?.mealMapping?.slot}
                                                                </TableCell>
                                                                <TableCell sx={{minWidth: '70px'}}>{row?.mealMapping?.time}</TableCell>
                                                                <TableCell>{row?.mealMapping?.bufferMinutes}</TableCell>
                                                                <TableCell sx={{backgroundColor: row?.from ? '#90EE90' : '#FFCCCB', minWidth: '140px'}}>
                                                                    {row?.from ?
                                                                        <Box>{row?.from} - {row?.to}</Box> :
                                                                        <Box>{'NOTAVAILABLE'}</Box>}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table> : '---'
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{mr: 1, mb: 1}}>
                <Button onClick={props.handleClose} color={'warning'}>Cancel</Button>
                <Button onClick={props.handleSubmit} autoFocus variant={'contained'}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CookVerifyDialog;
