import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import ChefForPartyService from "../../services/ChefForPartyService";

interface InputProps {
  id: number;
  open: boolean;
  data: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refresh: any;
}

const chefForPartyService = new ChefForPartyService();

const EditAmountChefForParty = (props: InputProps) => {
  const [amount, setAmount] = useState<number>(0);

  const handleClose = () => {
    props.refresh();
    setAmount(0);
    props.setOpen(false);
  };

  const handleSubmit = () => {
    let payload = {
      amount: amount,
    };
    chefForPartyService.updateChefForPartyOrderAmount(props?.id, props?.data?.id, payload).then(() => {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Successful",
        type: CustomSnackbarTypes.SUCCESS,
      });
      handleClose();
    });
  };

  useEffect(() => {
    if(!props?.data) {
      return;
    }
    setAmount(Number(props?.data?.amount));
  }, [props?.data]);

  return (
    <Dialog open={props.open} scroll={"paper"}>
      <DialogTitle>Update Order Amount</DialogTitle>
      <DialogContent>
        <TextField
            fullWidth
          label="Enter the Amount"
          value={amount}
            type={'number'}
          onChange={(event) => {
            if(Number(event.target.value) >= 0){
              setAmount(Number(event.target.value));
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={"success"}
          onClick={() => {
            handleSubmit();
          }}
          size={"large"}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            props.setOpen(false);
            setAmount(0);
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAmountChefForParty;
