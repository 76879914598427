import { MakeCallDto } from "../dtos/Call/MakeCallDto";
import HttpClient from "../HttpClient";

export default class CallServices {
    async makeCall(makeCallDto: MakeCallDto) {
        const res = await HttpClient.post("/call", makeCallDto);
        return res.data;
    }

    async patchAdminPhoneNumber(adminId: number | null, mobileNumber: string) {
        const req = {
            mobile: mobileNumber
        }
        const res = await HttpClient.patch(`/admin-user/admin/${adminId}`, req);
        return res.data;
    }
}