import React, {useCallback, useEffect, useState} from "react";
import CkMuiTable from "../Common/CkMuiTable/CkMuiTable";
import {
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import {TrialsService} from "../../services/TrialServices";
import moment from "moment";

const trialsService = new TrialsService();

interface InputProps {
  trialId: number;
}

const TrialShowStages = ({ trialId }: InputProps) => {
  let [subData, setSubData] = useState<any>(null);
  const fetchDetails = useCallback((trialId: number) => {
    trialsService.getStages(trialId)
        .then((res) => {
          setSubData(res);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  useEffect(() => {
    if(!trialId) return;
    fetchDetails(Number(trialId));
  }, [fetchDetails, trialId]);

  return (
    <div>
      {subData &&
        Array.isArray(subData) &&
        (subData.length === 0 ? (
          <EmptyListPlaceholder message="No Records Found" />
        ) : (
          <TableContainer sx={{ overflowX: "auto" }}>
            <CkMuiTable
              sx={{}}
              headers={[
                "Action",
                "Time",
              ]}
              rowData={[]}
              isTablePagination={false}
            >
              {subData.map(
                (data: any, index: number) => {
                  return (
                      <TableRow key={"" + index}>
                        <TableCell>{data?.stag}</TableCell>
                        <TableCell>{moment(data?.expectedTime).format('LLL')}</TableCell>
                      </TableRow>
                  )
                }
              )}
            </CkMuiTable>
          </TableContainer>
        ))}
    </div>
  );
};

export default TrialShowStages;
