import * as React from 'react';
import { useResourceContext, usePreference, useStore } from 'ra-core';
import { DatagridEditor } from './DataGridEditor';
import { Configurable } from './Configurable';
import { Datagrid, DatagridProps } from 'react-admin';

export const DatagridConfigurable = ({
    preferenceKey,
    omit,
    ...props
}: DatagridConfigurableProps) => {
    
    if (props.optimized) {
        throw new Error(
            'DatagridConfigurable does not support the optimized prop'
        );
    }
    const resource = useResourceContext(props);
    const finalPreferenceKey = preferenceKey || `${resource}.datagrid`;

    const [availableColumns, setAvailableColumns] = useStore<
        ConfigurableDatagridColumn[]
    >(`preferences.${finalPreferenceKey}.availableColumns`, []);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setOmit] = useStore<string[]>(
        `preferences.${finalPreferenceKey}.omit`,
        omit
    );

    React.useEffect(() => {
        // first render, or the preference have been cleared
        const columns = React.Children.map(props.children, (child: any, index: number) => {
            
            if(React.isValidElement(child)){
                const data: any = child.props;
                if(data.source || data.label) {
                    return {
                        index: String(index),
                        source: data.source,
                        label: data.label,
                    }
                } else {
                    return null
                }
            }
        })
        
        if (JSON.stringify(columns) !== JSON.stringify(availableColumns)) {
            if(columns) {
                setAvailableColumns(columns);
            }
            setOmit(omit);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableColumns]);
    
    return (
        <Configurable
            editor={<DatagridEditor />}
            preferenceKey={finalPreferenceKey}
            sx={{
                display: 'block',
                '& .MuiBadge-root': { display: 'flex' },
                '& .RaDatagrid-root': { flex: 1 },
                '& .MuiBadge-badge': { zIndex: 2 },
                minHeight: 2,
            }}
        >
            <DatagridWithPreferences {...props} />
        </Configurable>
    );
};

export interface DatagridConfigurableProps extends DatagridProps {
    preferenceKey?: string;
    omit?: string[] | any;
}

export interface ConfigurableDatagridColumn {
    index: string;
    source: string;
    label?: string;
}

DatagridConfigurable.propTypes = Datagrid.propTypes;

const DatagridWithPreferences = ({ children, ...props }: DatagridProps) => {
    const [availableColumns] = usePreference<ConfigurableDatagridColumn[] | any>('availableColumns', []);
    const [omit] = usePreference<any>('omit', []);
    const [columns] = usePreference<ConfigurableDatagridColumn[]>(
        'columns',
        availableColumns
            .filter((column: ConfigurableDatagridColumn) => !omit?.includes(column.source))
            .map((column: ConfigurableDatagridColumn) => column.index)
    );
    
    
    const childrenArray = React.Children.toArray(children);
    return (
        <Datagrid {...props}>
            {columns === undefined
                ? children
                : columns.map((index: any) => {
                    return childrenArray[Number(index) -1]
                })}
        </Datagrid>
    );
};
