import React from 'react';
import VendorPayoutService from '../../services/VendorPayoutService';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
const vendorPayoutService = new VendorPayoutService();
const VendorPayoutEventTable = () => {
    const [payoutEventData, setPayoutEventData] = React.useState([]);
    const [keys, setKeys] = React.useState<string[]>([]);
    const [sortBy, setSortBy] = React.useState<string | null>(null);
    const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('asc');

    React.useEffect(() => {
        vendorPayoutService.getPayoutEvents().then((res) => {
            if (res && res.length !== 0) {
                setPayoutEventData(res);
                // setKeys(Object.keys(res[0] || {}));
                setKeys(Object?.keys(res[0] || {}).filter(key => (
                    key !== 'tags' &&
                    key !== 'hasAutoApprove' && 
                    key !== 'hasAutoProcess' && 
                    key !== 'legend' &&
                    key !== 'forMgCook' && 
                    key !== 'icon' && 
                    key !== 'createdAt' && 
                    key !== 'updatedAt'
                )));
            }
        });
    }, []);
    const handleSort = (column: string) => {
        if (sortBy === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortDirection('asc');
        }
    };

    const sortedData = React.useMemo(() => {
        if (!sortBy) return payoutEventData;
        return [...payoutEventData].sort((a, b) => {
            const aValue: any = a[sortBy];
            const bValue: any = b[sortBy];
            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return sortDirection === 'asc' ? aValue?.localeCompare(bValue) : bValue?.localeCompare(aValue);
            }
            return sortDirection === 'asc' ? (aValue - bValue) : (bValue - aValue);
        });
    }, [sortBy, sortDirection, payoutEventData]);
    return (
        <>
            <Typography textAlign={'center'} fontSize={20} fontWeight={700} my={3}>Vendor Payout Event Table</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {keys && keys?.length !== 0 && keys?.map((value, index) => (
                                <TableCell sx={{ textTransform: 'capitalize' }} key={index}>
                                    <TableSortLabel
                                        active={sortBy === value}
                                        direction={sortDirection}
                                        onClick={() => handleSort(value)}
                                    >
                                        {value}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData?.map((row, index) => (
                            <TableRow key={index}>
                                {keys?.map((key, idx) => (
                                    <TableCell key={idx}>{row?.[key]|| ''}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
export default VendorPayoutEventTable;