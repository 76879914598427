import React, {useState} from 'react';
import {
    Button,
    Dialog, DialogActions, DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField
} from "@mui/material";
import moment from 'moment';
import {RefundServiceV2} from "../../services/RefundServiceV2";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";
import {useAppSelector} from "../../store/hooks";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";
import {UserGroupsEnum} from "../../enums/UserGroupsEnum";

const refundServiceV2 = new RefundServiceV2();

const RefundListForModule = (props: any) => {
    const userDetails = useAppSelector((state) => state.userDetails.value);
    let groupsArray: string[] = userDetails.adminUser.accessGroups;
    const [open, setOpen] = useState<boolean>(false);
    const [amount, setAmount] = useState<string>('0');
    const [data, setData] = useState<any>();
    const [loading,setLoading]= useState<boolean>(false);

    const handleSubmit = () => {
        setLoading(true);
        if(Number(amount) < 0) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Enter a valid amount",
                type: CustomSnackbarTypes.ERROR,
            });
            setLoading(false);
            return;
        }
        if(!GroupPermissionFunction(groupsArray, [UserGroupsEnum.MANAGER])) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Only managers have the access to initiate refund",
                type: CustomSnackbarTypes.ERROR,
            });
            setLoading(false);
            return;
        }
        if(Number(data?.refundableAmount) === 0) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Total amount already refunded",
                type: CustomSnackbarTypes.ERROR,
            });
            setLoading(false);
            return;
        }
        if(Number(data?.refundableAmount) < Number(amount)) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: `Total amount is more than the refundable amount (Current refundable amount is ${data?.refundableAmount})`,
                type: CustomSnackbarTypes.ERROR,
            });
            setLoading(false);
            return;
        }
        refundServiceV2.createRefundForOrder(data?.razorpayOrderId, {refundAmount: amount}).then(() => {
            setLoading(false)
            props.onCallback();
            setOpen(false);
            setData(null);
        }).catch((err)=>{
            setLoading(false);
        })
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>ReferenceId</TableCell>
                        <TableCell>ReferenceModelName</TableCell>
                        <TableCell>PaymentId</TableCell>
                        <TableCell>RefundId</TableCell>
                        <TableCell>Razorpay Id</TableCell>
                        <TableCell>RRN</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Refund Type</TableCell>
                        <TableCell>Refundable Amount</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>CreatedAt</TableCell>
                        <TableCell>UpdatedAt</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((row: any) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell scope="row">{row?.id}</TableCell>
                            <TableCell align="left">{row?.referenceId}</TableCell>
                            <TableCell align="left">{row?.referenceModelName}</TableCell>
                            <TableCell align="left">{row?.paymentId}</TableCell>
                            <TableCell align="left">{row?.refundId}</TableCell>
                            <TableCell align="left">{row?.razorpayOrderId}</TableCell>
                            <TableCell align="left">{row?.vendorRefundResponse?.acquirer_data?.rrn||row?.vendorRefundResponse?.acquirer_data?.arn}</TableCell>
                            <TableCell align="left">{row?.amount}</TableCell>
                            <TableCell align="left">{row?.refundType}</TableCell>
                            <TableCell align="left">{row?.refundableAmount}</TableCell>
                            <TableCell align="left">{row?.status}</TableCell>
                            <TableCell align="left">{moment(row?.createdAt).format("YYYY/MM/DD HH:MM")}</TableCell>
                            <TableCell align="left">{moment(row?.updatedAt).format("YYYY/MM/DD HH:MM")}</TableCell>
                            <TableCell align="left">
                                <Button
                                    disabled={row?.refundType === 'FULL'}
                                    variant={'contained'} color={'error'} onClick={() => {
                                    setOpen(true);
                                    setData(row);
                                    }}>
                                    {'Refund'}
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Dialog open={open} onClose={() => {setOpen(false)}}>
                <DialogTitle>
                    Refund Dialog
                </DialogTitle>
                <DialogContent>
                    <TextField
                        value={amount}
                        onChange={(e: any) => setAmount(e.target.value)}
                        label={'Enter refund amount'}
                        type={'number'}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color={'error'}>
                        Close
                    </Button>
                    <Button disabled={loading} onClick={handleSubmit} variant={'contained'}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </TableContainer>
    );
};

export default RefundListForModule;
