import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { CustomerLeavesServies } from "../../services/CustomerLeaves";
import { useRefresh } from "react-admin";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { CustomerLeavesResponse } from "../../interfaces/Customer/CustomerLeavesResponse";

interface InputProps {
  data: CustomerLeavesResponse | undefined;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refresh?: any;
}

const customerLeavesService = new CustomerLeavesServies();

const CancelCustomerLeave = (props: InputProps) => {
  const [reason, setReason] = useState<string>("");
  const refresh = useRefresh();

  const handleSubmit = () => {
    customerLeavesService
      .cancelCustomerLeaves(Number(props?.data?.id), reason)
      .then(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Success",
          type: CustomSnackbarTypes.SUCCESS,
        });
        props?.setOpen(false);
        setReason("");
        refresh();
      }).finally(() => {
        if (props.refresh) {
          props.refresh();
        }
      });
  };

  return (
    <Dialog open={props.open} scroll={"paper"} maxWidth="xl">
      <DialogTitle>Customer Leave</DialogTitle>
      <DialogContent>
        <TextField
          label="Reason"
          id="Reason"
          value={reason}
          onChange={(event) => setReason(event.target.value)}
        />
      </DialogContent>
      <DialogActions style={{ marginTop: "12px" }}>
        <Button
          variant="contained"
          onClick={() => {
            handleSubmit();
          }}
          size={"large"}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            props.setOpen(false);
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelCustomerLeave;
