import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useRefresh} from "react-admin";
import UserBundleService from "../../services/BundleService";
import { MealMappingInput } from "./MealMappingInput";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { UserBundlesResponse } from "../../interfaces/UserBundleMapping/UserBundlesResponse";

interface InputProps {
  data: UserBundlesResponse;
  open: boolean;
  callback: () => void;
}

export const ChangeBundleTimeDialog = (props: InputProps) => {
  const userBundleService = new UserBundleService();
  const [mealMapping, setMealMapping] = useState<any>([]);

  const refresh = useRefresh();

  const handleClose = () => {
    props.callback();
  };

  let setVisitTime = (myIndex: number, data: any) => {
    setMealMapping(
        mealMapping.map((ele: any, index: number) => {
          if (index === myIndex) {
            return { ...ele, time: data };
          }
          return ele;
        })
    );
  };

  let setVisitSlot = (myIndex: number, data: any) => {
    setMealMapping(
        mealMapping.map((ele: any, index: number) => {
          if (index === myIndex) {
            return { ...ele, slot: data };
          }
          return ele;
        })
    );
  };

  let setBufferTime = (myIndex: number, data: any) => {
    setMealMapping(
        mealMapping.map((ele: any, index: number) => {
          if (index === myIndex) {
            return { ...ele, bufferMinutes: Number(data) };
          }
          return ele;
        })
    );
  };

  useEffect(() => {
    if (!props) return;
    if (props?.data?.mealMapping) {
      setMealMapping(props?.data?.mealMapping);
    } else {
      let mealMappingArray: any[] = [];
      props?.data?.userBundleMeta?.mealMapping.forEach((slot) => {
        let mealMapping = {
          time: null,
          slot: slot,
          bufferTime: null,
        };
        mealMappingArray.push(mealMapping);
      });
      setMealMapping(mealMappingArray);
    }
  }, [props]);

  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={props.open}
        scroll={"paper"}
        maxWidth="xl"
      >
        <DialogTitle>Update Time of Meal Mapping</DialogTitle>
        <DialogContent>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 2, right: 5 }}
          >
            <Close />
          </IconButton>
          <Grid
            container
            columns={1}
            rowSpacing={0.9}
            style={{ padding: "12px 12px 12px 12px" }}
          >
            <div style={{ width: "600px" }}>
              {!!mealMapping &&
                  Array.isArray(mealMapping) &&
                  mealMapping.length > 0 &&
                  mealMapping.map((value, index) => (
                      <MealMappingInput
                          key={index}
                          data={value}
                          setVisitTime={setVisitTime}
                          setVisitSlot={setVisitSlot}
                          setBufferTime={setBufferTime}
                          index={index}
                          isDynamicPricing={true}
                          disabled={{
                              visitSlot: true,
                              bufferTime: true,
                          }}
                      />
                  ))}
            </div>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              userBundleService
                .updateMealMapping(props.data.id, {meals: mealMapping})
                .then(() => {
                  handleClose();
                  refresh();
                }).then(()=>{
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                      message: "Update successfully",
                      type: CustomSnackbarTypes.SUCCESS
                  })
              })
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              handleClose();
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
