import React from "react";
import { Box, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { BankDetailsResponse } from "../../interfaces/Payout/BankDetailsResponse";
import { PayoutTypes } from "../../enums/PayoutEnums";

interface InputProps {
  open: boolean;
  handleClose: () => void;
  data: BankDetailsResponse | null;
}

const ListItem = ({
  title,
  description,
}: {
  title: string;
  description: any;
}) => {
  if (description === undefined) return <div />;
  return (
    <Box minWidth={"200px"}>
      <Typography variant={"subtitle1"} sx={{ fontSize: "12px" }}>
        {title}
      </Typography>
      <Typography sx={{ color: "#000", fontSize: "15px" }}>
        {description !== undefined ? String(description) : "---"}
      </Typography>
    </Box>
  );
};

const PayoutBankDetailDialog = (props: InputProps) => {
  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth={"md"}>
      <DialogTitle>
        {"UPI/Bank Details"}
        {props.handleClose ? (
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"Type"}
                description={
                  props?.data?.type === PayoutTypes.VPA ? "UPI" : "BANK ACCOUNT"
                }
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"User Type"}
                description={props?.data?.userType}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"Verified"}
                description={props?.data?.verified}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem title={"User Id"} description={props?.data?.userId} />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"Is Default"}
                description={props?.data?.isDefault}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"Fund Account Id"}
                description={props?.data?.fundAccountId}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"Fund Account Creation Status"}
                description={props?.data?.childTable?.fundAccountCreationStatus}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"UPI Id"}
                description={props?.data?.childTable?.vpaId}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"Name"}
                description={props?.data?.childTable?.name}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"Account Number"}
                description={props?.data?.childTable?.accountNumber}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"Address"}
                description={props?.data?.childTable?.address}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"Bank"}
                description={props?.data?.childTable?.bank}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"Bank Code"}
                description={props?.data?.childTable?.bankCode}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"Branch"}
                description={props?.data?.childTable?.branch}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"Centre"}
                description={props?.data?.childTable?.centre}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"City"}
                description={props?.data?.childTable?.city}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"Contact"}
                description={props?.data?.childTable?.contact}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"District"}
                description={props?.data?.childTable?.district}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"IFSC Code"}
                description={props?.data?.childTable?.ifscCode}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"IMPS"}
                description={props?.data?.childTable?.imps}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"MICR"}
                description={props?.data?.childTable?.micr}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"NEFT"}
                description={props?.data?.childTable?.neft}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"RTGS"}
                description={props?.data?.childTable?.rtgs}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"State"}
                description={props?.data?.childTable?.state}
              />
            </Grid>
            <Grid md={4} sm={6} xs={12} item>
              <ListItem
                title={"Swift"}
                description={props?.data?.childTable?.swift}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default PayoutBankDetailDialog;
