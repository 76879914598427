import {
    Datagrid,
    DateField, DateInput,
    FunctionField,
    List,
    NumberField,
    SelectInput,
    ShowButton,
    TextField, TextInput
} from "react-admin";
import {PaymentStatusEnum} from "../../enums/PaymentStatusEnum";
import {ComboResponse} from "../../interfaces/Combo/ComboResponse";

const filters = [
    <TextInput label="Search by Phone Number" source="customerPhoneNumber" alwaysOn />,
    <DateInput label="Expire At" source="expireAt" alwaysOn />,
    <SelectInput
        label="Product Type"
        source="productType"
        alwaysOn
        emptyText="Clear Filter"
        choices={[
            { id: 'TRAIL', name: 'TRAIL' },
            { id: 'ONE_MEAL_SERVICE', name: 'ONE_MEAL_SERVICE' }
        ]}
    />,
    <SelectInput
        label="Filter by Payment Status"
        source="paymentStatus"
        alwaysOn
        emptyText="Clear Filter"
        choices={
            Object.values(PaymentStatusEnum)
                .map(key => {
                    return {
                        id: key,
                        name: key
                    }
                })
        }
    />,
];

const ComboList = (props: any) => {
    return (
        <List
         {...props}
         filters={filters}
         sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid bulkActionButtons={false}>
                <NumberField source="id" />
                <FunctionField
                    emptyText="---"
                    label="Customer Name"
                    source="customerData.fname"
                    sortable={false}
                    render={(record: ComboResponse) =>
                        [record?.customerData?.fname, record?.customerData?.lname]?.filter((each) => each !== null).join(' ')
                    }
                />
                <FunctionField
                    emptyText="---"
                    label="Customer Mobile"
                    source="customerData.mobile"
                    sortable={false}
                    render={(record: ComboResponse) =>
                        record?.customerData?.mobile
                    }
                />
                <FunctionField
                    emptyText="---"
                    label="Purchased Combo Name"
                    sortable={false}
                    render={(record: ComboResponse) =>
                        record?.orderDetailsData?.[record?.orderDetailsData?.length - 1]?.discountCouponCode
                    }
                />
                <FunctionField
                    emptyText="---"
                    label="Coupon Status"
                    sortable={false}
                    render={(record: ComboResponse) =>
                        record?.orderDetailsData?.[record?.orderDetailsData?.length - 1]?.isActive ? 'Active' : 'InActive'
                    }
                />
                <TextField source="paymentStatus" />
                <TextField source="createdByType" />
                <DateField source="createdAt" showTime />
                <DateField source="updatedAt" showTime/>
                <ShowButton />
            </Datagrid>
        </List>
    );
};

export default ComboList;
