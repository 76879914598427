import React, {useEffect, useState} from 'react';
import CircularLoader from "../Common/CircularLoader";
import HouseListForModule from "../Houses/HouseListForModule";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import {CustomerService} from "../../services/CustomerService";

interface InputProps {
    customerId: number
}

let customerService = new CustomerService();

const CustomerHouseTab = (props: InputProps) => {
    let [subData, setSubData] = useState<any>(null);
    let [isLoading, setIsLoading] = useState<boolean>();

    const refreshData = (customerId: number) => {
        setIsLoading(true)
        customerService.getAllHouseForCustomer(customerId)
            .then((data) => {
                setSubData(data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        refreshData(props.customerId)
    }, [props.customerId])

    return (
        <div>
            {
                isLoading ?
                    <CircularLoader/>
                    :
                    <div>
                        {
                            subData &&
                            subData.data &&
                            Array.isArray(subData.data) &&
                            (
                                subData.data.length>0 ?
                                    <HouseListForModule data={subData?.data}/>
                                    :
                                    <EmptyListPlaceholder
                                        message='No House Found'
                                        link='/#/Houses/create'
                                    />
                            )
                        }
                    </div>
            }
        </div>
    );
};

export default CustomerHouseTab;
