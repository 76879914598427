export enum ActionTypeEnum {
    POST_ALLOCATION_CANCELLED = "POST_ALLOCATION_CANCELLED",
    NOT_CONVERTED_CX_ISSUES = "NOT_CONVERTED_CX_ISSUES",
    RESCHEDULED_PRE_ALLOCATION = "RESCHEDULED_PRE_ALLOCATION",
    RATING = "RATING",
    CANCELLED_PRE_ALLOCATION = "CANCELLED_PRE_ALLOCATION",
    RESCHEDULED_POST_ALLOCATION = "RESCHEDULED_POST_ALLOCATION",
    POST_ALLOCATION_RESCHEDULED = "POST_ALLOCATION_RESCHEDULED",
    PRE_ALLOCATION_RESCHEDULED = "PRE_ALLOCATION_RESCHEDULED",
    CANCELLED_POST_ALLOCATION = "CANCELLED_POST_ALLOCATION",
    NOT_CONVERTED_COOK_ISSUES = "NOT_CONVERTED_COOK_ISSUES",
    PRE_ALLOCATION_CANCELLED = "PRE_ALLOCATION_CANCELLED",
    CX_FEEDBACK = "CX_FEEDBACK",
    SUBSCRIPTION_CLOSURE = "SUBSCRIPTION_CLOSURE",
    REASON_FOR_PR_TRIAL = "REASON_FOR_PR_TRIAL",
    COULD_NOT_FIND_COOK = 'COULD_NOT_FIND_COOK',
    CREATE_COOK_LEAVE='CREATE_COOK_LEAVE',
    PENALTY_REVOKE="PENALTY_REVOKE"
  }

