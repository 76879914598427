import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextareaAutosize,
} from "@mui/material";
import React, { useState } from "react";
import { useRecordContext, useRefresh, useUnselectAll } from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import VendorPayoutService from "../../services/VendorPayoutService";
import { CustomSnackbarTypes } from "./CustomSnackBar";

interface InputProps {
  open: boolean;
  setOpen: (data: any) => void;
  id?: number;
  isBulkAction?: boolean;
  selectedVendorPayoutIds?: number[];
}

const vendorPayoutService = new VendorPayoutService();

const RejectPayoutReasonDialog: React.FC<InputProps> = ({
  open,
  setOpen,
  id,
  isBulkAction = false,
  selectedVendorPayoutIds,
}) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const [rejectionReason, setRejectionReason] = useState<string>("");
  const unselectAll = useUnselectAll("VendorPayouts");

  const handleClose = () => {
    setOpen(false);
  };

  const bulkRejectHandler = async() => {
    if (rejectionReason && selectedVendorPayoutIds) {
      const payload = {
        transactionId: selectedVendorPayoutIds,
        comments: rejectionReason,
      }
      await vendorPayoutService.bulkRejectPayout(payload).then((res) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Success",
          type: CustomSnackbarTypes.SUCCESS,
        })
        refresh();
      }).catch((err) => {})
      // selectedVendorPayoutIds?.forEach(async (vendorPayoutID) => {
      //   await vendorPayoutService
      //     .rejectPayout(Number(vendorPayoutID), { comments: rejectionReason })
      //     .then(() => {
      //       EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
      //         message: "Success",
      //         type: CustomSnackbarTypes.SUCCESS,
      //       });
      //       refresh();
      //     });
      // });
      unselectAll();
    } else {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Write the rejection reason",
        type: CustomSnackbarTypes.INFO,
      });
    }
  };

  const handleConfirm = async () => {
    if (rejectionReason) {
      let vendorPayoutID = id ? id : record?.id;
      await vendorPayoutService
        .rejectPayout(Number(vendorPayoutID), { comments: rejectionReason })
        .then(() => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Success",
            type: CustomSnackbarTypes.SUCCESS,
          });
          refresh();
        });
    } else {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Write the rejection reason",
        type: CustomSnackbarTypes.INFO,
      });
    }
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm">
      <DialogTitle>Please confirm the rejection of the payout.</DialogTitle>
      <DialogContent>
        <FormControl style={{ width: 500 }} fullWidth>
          <TextareaAutosize
            value={rejectionReason}
            minRows={5}
            onChange={(event) => {
              setRejectionReason(event.target.value);
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            handleClose();
          }}
          size={"large"}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color={"success"}
          onClick={() => {
            if (isBulkAction) {
              bulkRejectHandler();
              return;
            }
            handleConfirm();
          }}
          size={"large"}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RejectPayoutReasonDialog;
