import { Close } from "@mui/icons-material";
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, TextField
} from "@mui/material";
import  React, {useState} from "react";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "./CustomSnackBar";
import StarChefInventoryService from "../../services/StarChefInventoryService";

interface InputProps {
  data: any;
  open: boolean;
  callback: () => void;
  refresh: (arg0: boolean) => void;
}

export const UpsertInvertoryDialog = (props: InputProps) => {
  const [availableCount, setAvailableCount] = useState<number>(-1)
  const starChefInventoryService = new StarChefInventoryService()

  let handleClose = () => {
    props.callback()
    setAvailableCount(0)
  }
  return (
      <Dialog
        onClose={handleClose}
        open={props.open}
        scroll={"paper"}
        maxWidth="xl"
      >
        <DialogTitle>Upsert Invertory</DialogTitle>
        <DialogContent>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
          <Grid
            container
            columns={1}
            rowSpacing={0.9}
            style={{ padding: "12px 12px 12px 12px" }}
          >
            <FormControl fullWidth>
            <TextField
              defaultValue={props?.data?.availableCount}
              type={"number"}
              label={"Available Count"}
              style={{width: '230px'}}
              InputProps={{ inputProps: { min: 1 } }}
              onChange={(event) => {
                setAvailableCount(Number(event.target.value));
              }}
            />
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              if (availableCount <= -1) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Fill the Available Count",
                    type: CustomSnackbarTypes.WARNING,
                });
                return;
              }
              starChefInventoryService
              .upsertInventory(props.data.upsertData.date, props.data.upsertData.timeSlot, Number(availableCount), props.data.cityId)
                .then((res) => {
                  handleClose()
                  props.refresh(true)
                });
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              handleClose();
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  );
};