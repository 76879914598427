import moment from "moment";
import { useEffect, useState } from "react";
import convertMinutes from "../../utils/TimeConversion";

interface InputProps {
  startTime: Date;
  endTime: Date;
}

function getDifference(startTime: Date, endTime: Date) {
  const start = moment(startTime);
  const end = moment(endTime);
  const diff = end.diff(start, "minutes");
  return convertMinutes(diff);
}

function getColor(
  startTime: Date,
  endTime: Date
): { color: string; backgroundColor: string } {
  const start = moment(startTime);
  const end = moment(endTime);
  const diffWithEnd = end.diff(start, "minutes");
  const diffWithCurrent = moment().diff(start, "minutes");
  if (diffWithCurrent < 0) {
    return { color: "white", backgroundColor: "red" };
  }
  const percent = (diffWithCurrent / diffWithEnd) * 100;
  if (percent <= 50) {
    return { color: "white", backgroundColor: "green" };
  } else if (percent > 50 && percent <= 80) {
    return { color: "black", backgroundColor: "yellow" };
  } else {
    return { color: "white", backgroundColor: "red" };
  }
}

const Timer = (props: InputProps) => {
  const [time, setTime] = useState<any | null>(null);

  useEffect(() => {
    if (!time) {
      setTime(getDifference(props.startTime, moment().toDate()));
      setInterval(() => {
        setTime(getDifference(props.startTime, moment().toDate()));
      }, 60000);
    }
  }, [props.startTime, time]);

  return (
    <div style={{ display: "inline-block" }}>
      <div style={getColor(props.startTime, props.endTime)}>{time}</div>
    </div>
  );
};

export default Timer;
