import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { ResolutionEnum } from "../../enums/ResolutionEnum";
import UserBundleService from "../../services/BundleService";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";

const bundleService=new UserBundleService()
const SetResolution = (props:any) => {
    const [resolution,setResolution]=useState<any>();

    const handleClose = () => {
        props.callback({
            resolution
        });
        setResolution("");
    };
    const resolve =()=>{
        bundleService.setResolution(props?.data?.userBundleMeta?.id,{
            resolution
        }).then((res)=>{
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Resolution Updated"
            });
            props.callback({
                resolution
            });
            setResolution("");
            handleClose();
        })
    }

    return (
        <div>
            <Dialog
                onClose={handleClose}
                open={props.open}
                scroll={"paper"}
                maxWidth="xl"
            >
                <DialogTitle sx={{marginTop:"20px"}}>Set Leave Resolution</DialogTitle>
                <DialogContent>
                    <IconButton
                        onClick={handleClose}
                        sx={{ position: "absolute", top: 2, right: 5 }}
                    >
                        <Close/>
                    </IconButton>
                    <Grid
                        container
                        columns={1}
                        rowSpacing={0.9}
                        style={{ padding: "12px 12px 12px 12px",}}
                    >
                        <FormControl fullWidth>
                            <FormControl fullWidth style={{ marginTop: "20px" }}>
                                <InputLabel id="forResolution">Resolution</InputLabel>
                                <Select
                                    labelId="forResolution"
                                    id="Resolution"
                                    value={resolution}
                                    label="Resolution"
                                    onChange={(event) => setResolution(event.target.value)}
                                >
                                    {
                                        Object.values(ResolutionEnum).map((res)=>(
                                            <MenuItem value={res}>{res}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ marginTop: "12px" }}>
                    <Button
                        variant="contained"
                        color={"primary"}
                        onClick={()=>{
                            resolve();
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleClose}
                        color={"secondary"}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default SetResolution;