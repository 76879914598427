import React, {useEffect, useState} from 'react'
import { Show } from 'react-admin'
import { FreshLeadStatus } from '../../enums/FreshLeadStatus'
import FreshLeadInterface from '../../interfaces/Cook/FreshLeadInterface'
import SkillAssessmentRatingTable from '../Common/SkillAssessmentRatingTable'
import {CookService} from "../../services/CookServices";

interface InputProps {
    cookDetail: FreshLeadInterface
}

interface AssessmentDataInterface {
    moduleId: number;
    moduleName: string;
    moduleTopics: {
        attempted: boolean;
        completed: boolean;
        maximumScore: number;
        topicProgressId: number;
        percentAttempted: number;
        topicId: number;
        topicName: string;
        totalScore: number;
    }[];
}

const cookService = new CookService();
function groupTopicsByModule(assessmentData: any): AssessmentDataInterface[] {
    const moduleMap = new Map();
    assessmentData.assessmentTopicProgess.forEach((topicProgress: any) => {
        const { id, topic, completed, attempted, totalScore, maximumScore, percentAttempted } = topicProgress;
        const { module } = topic;
        if (!moduleMap.has(module.id)) {
            moduleMap.set(module.id, {
                moduleId: module.id,
                moduleName: module.localEn,
                moduleTopics: [],
            });
        }
        const moduleInfo = moduleMap.get(module.id);
        moduleInfo.moduleTopics.push({
            topicId: topic.id,
            topicName: topic.localEn,
            topicProgressId: id,
            completed,
            attempted,
            totalScore,
            maximumScore,
            percentAttempted,
        });
        moduleMap.set(module.id, moduleInfo);
    });

    return Array.from(moduleMap.values());
}

const SkillAssessmentTable = ({cookDetail}: InputProps) => {
    const [assessmentData, setAssessmentData] = useState<AssessmentDataInterface[]>([])
    useEffect(() => {
        if(!cookDetail?.cookId) return;
        cookService.getTopicProgress(cookDetail?.cookId, {type: 'SKILLASSESSMENT'}).then((res) => {
            setAssessmentData(groupTopicsByModule(res));
        })
    }, [cookDetail?.cookId]);

  if (cookDetail.onboardingStatus === FreshLeadStatus.PROFILE || cookDetail.onboardingStatus === FreshLeadStatus.FRESH_LEAD) {
    return null
  }
  return (
    <Show title={"Skill Assessment"}>
        {assessmentData?.length ? (
           assessmentData?.map((eachModule: AssessmentDataInterface) => {
               return(
                   <div style={{ marginBottom: "1rem" }} key={eachModule?.moduleId}>
                       <SkillAssessmentRatingTable cookDetail={cookDetail} moduleData={eachModule} />
                   </div>
               )
           })
        ) : ''}
    </Show>
  );
}

export default SkillAssessmentTable
