import React, {useCallback, useEffect, useState} from "react";
import moment from "moment";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useLocation} from "react-router-dom";

const TextRegular = ({title, description, sx = {}}: {title: string; description?: string; sx?: any}) => {
    return(<Typography fontSize={'0.8rem'} sx={sx}><b>{title}: </b>{description}</Typography>)
};

const isAfterTime = (time: string): boolean => {
    const currentTime = moment();
    const specificTime = moment(time, 'hh:mm A');
    const differenceInMinutes = specificTime.diff(currentTime, 'minutes');
    return differenceInMinutes <= 0;
};

const calculateDifference = (time: string): string => {
    const currentTime = moment();
    const specificTime = moment(time, 'hh:mm A');
    const differenceInMinutes = specificTime.diff(currentTime, 'minutes');
    const differenceInHours = specificTime.diff(currentTime, 'hours');
    if(differenceInHours === 0) {
        return `${differenceInMinutes%60} min`;
    }
    return `${differenceInHours} hr ${differenceInMinutes % 60} min`;
};
const calculateDifferenceInDates = (date1: any): string => {
    const momentDate1 = moment(date1);
    const momentDate2 = moment();
    const differenceInMilliseconds = momentDate2.diff(momentDate1);
    const duration = moment.duration(differenceInMilliseconds);
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    let result = '';
    if (days > 0) {
        result += `${days} days, `;
    }
    if (hours > 0) {
        result += `${hours} hr, `;
    }
    if (minutes > 0) {
        result += `${minutes} min `;
    }
    result = result.replace(/,\s*$/, '');
    return result;
};

function haversine(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 6371; // Earth's radius in kilometers
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    // Distance in Meters
    return R * c * 1000;
}

function toRadians(degrees: number) {
    return degrees * (Math.PI / 180);
}

const VisitTrackerCard = ({eachData}: {eachData: any;}) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    let selectedDate =  queryParams.get('date') || moment().format("YYYY-MM-DD");
    const [distanceInLastTenMin, setDistanceInLastTenMin] = useState<string>('---');

    const getDistanceInLastTenMin = useCallback(async (
        cookCoordinatesBeforeTenMinutes: {lat: number; long: number},
        cookCurrentCoordinates: {lat: number; long: number},
        cookUpcomingVisit: {lat: number; long: number}
    ) => {
        if(!cookUpcomingVisit?.lat || !cookUpcomingVisit?.long) {
            setDistanceInLastTenMin('---');
            return;
        }
        if(!cookCurrentCoordinates?.lat || !cookCurrentCoordinates?.long) {
            setDistanceInLastTenMin('---');
            return;
        }
        if(!cookCoordinatesBeforeTenMinutes?.lat || !cookCoordinatesBeforeTenMinutes?.long) {
            setDistanceInLastTenMin('---');
            return;
        }
        let dist1 = haversine(cookCurrentCoordinates?.lat, cookCurrentCoordinates?.long, cookUpcomingVisit?.lat, cookUpcomingVisit?.long);
        let dist2 = haversine(cookCoordinatesBeforeTenMinutes?.lat, cookCoordinatesBeforeTenMinutes?.long, cookUpcomingVisit?.lat, cookUpcomingVisit?.long);
        if(!dist1 || !dist2) {
            setDistanceInLastTenMin('---');
            return;
        }
        let final = dist2 - dist1;
        let distance = !isNaN(final) ? `${Number(final/1000).toFixed(1)} km` : '---';
        setDistanceInLastTenMin(distance);
    }, []);

    useEffect(() => {
        getDistanceInLastTenMin(eachData?.cookPast10MinLocation?.coordinates, eachData?.cookLastLocation?.coordinates, eachData?.house);
    }, [eachData, getDistanceInLastTenMin])

  return (
    <React.Fragment>
        <Box sx={{
            backgroundColor: eachData?.entityType === 'ONE_MEAL_SERVICE' ? '#f58a54' : '#5491f5',
            position: 'absolute',
            top: '6px',
            right: '0px',
            borderRadius: '20px 0px 0px 20px',
            padding: '3px 10px',
            fontSize: 12,
            fontWeight: 600,
            color: '#ffffff',
        }}>
            {`${eachData?.id} - ${eachData?.entityType === 'ONE_MEAL_SERVICE' ? 'OMS' : 'TRIALS'}`}
        </Box>
        <Box sx={{
            backgroundColor: '#c987d8',
            position: 'absolute',
            top: '6px',
            left: '0px',
            borderRadius: '0px 20px 20px 00px',
            padding: '3px 10px',
            fontSize: 12,
            fontWeight: 600,
            color: '#ffffff',
        }}>
            {`${eachData?.status}`}
        </Box>
        <TextRegular title={'Visit Time'} description={eachData?.time} />
        <TextRegular
            title={'Time Left for Visit'}
            description={selectedDate === moment().format("YYYY-MM-DD") ? calculateDifference(eachData?.time) : '---'}
            sx={{...((selectedDate === moment().format("YYYY-MM-DD")) && isAfterTime(eachData?.time) ? {color: 'red'} : {})}}
        />
        <TextRegular title={'Location Last Updated At'} description={eachData?.cookLastLocation?.updatedAt ? calculateDifferenceInDates(eachData?.cookLastLocation?.updatedAt) : '---'} />
        <TextRegular title={'Distance In last 10 mins'} description={distanceInLastTenMin} />
        <TextRegular title={'Pre Checkin At'} description={eachData?.preCheckedInAt?moment(eachData?.preCheckedInAt).format("DD/MM/YYYY hh:mm A"):"Not Done Yet"} />
    </React.Fragment>
  );
};
export default VisitTrackerCard;
