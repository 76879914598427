import moment from "moment";
import React from "react";
import {
  SimpleShowLayout,
  TextField,
  ArrayField,
  Datagrid,
  FunctionField,
  RaRecord,
} from "react-admin";
import CustomReferenceField from "../Common/CustomReferenceField";

const CookFollowUpTab = () => {
  return (
    <SimpleShowLayout>
      <CustomReferenceField
        label="CookId"
        listName={"cooks"}
        source={"cookId"}
      />
      <FunctionField label="Cook Name" render={(record: RaRecord) => {
          if (!record?.cookFullProfile?.firstName) {
            return `Not Available`
          }
          return `${record?.cookFullProfile?.firstName} ${record?.cookFullProfile?.lastName}`
        }} 
      />
      <TextField label="Cook Mobile" source="cookPhoneNumber" />
      <TextField label="No Of Cook Follow Up" source="noOfCookFollowups" />
      <TextField
        label="Next Cook Follow Up Date"
        source="nextCookFollowUpDate"
      />
      <ArrayField
        textAlign="center"
        label="Cook Follow-up"
        source="cookFollowUp"
      >
        <Datagrid bulkActionButtons={false}>
          <TextField
            label="Commenter Id"
            source="commenterId"
            
          />
          <TextField
            label="Commenter Name"
            source="commenterName"
            
          />
          <TextField
            label="Commenter Email"
            source="commenterEmail"
            
          />
          <FunctionField
            label="Created At"
            render={(record?: RaRecord) => {
                if (record?.timestamp) {
                  return moment(record.timestamp).format("LLL");
                }
                else {
                  return "---";
                }
              } 
            }          
            />
          <TextField
            label="Comment"
            source="comment"
          />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  );
};

export default CookFollowUpTab;
