import { ArrayField, Datagrid, DateField, FunctionField, Show, SimpleShowLayout, TextField } from 'react-admin';
import CustomReferenceField from '../Common/CustomReferenceField';

function StarChefVisitMetaShow() {

  return (
    <Show actions={false}>
      <div style={{ display: "grid", gridTemplateColumns: "30% 40% 30%" }}>
        <SimpleShowLayout>
          <TextField source="id" />
          <CustomReferenceField
            label="UserBundleMapping Id"
            listName={"UserBundles"}
            source={"userBundleMappingId"}
          />
          <CustomReferenceField
            label="UserBundleService Id"
            listName={"UserBundleServiceMappings"}
            source={"userServiceBundleMappingId"}
          />
          <TextField source="subscriptionId" label="Subscription Id"/>
          <TextField source="status" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField source="visitDate" />
          <TextField source="visitTime" />
          <TextField source="cookAllocatedAt" />
          <CustomReferenceField
            label="CookAllocated By"
            listName={"AdminUsers"}
            source={"cookAllocatedBy"}
          />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField source="cookAllocatorName" />
          <CustomReferenceField
            label="Cook Id"
            listName={"Cooks"}
            source={"cookId"}
          />
          <FunctionField
            label="Cook Name"
            render={(record: any) => {
                if (!record?.cookFullProfile?.firstName) {
                  return `Not Available`
                }
                return `${record?.cookFullProfile?.firstName} ${record?.cookFullProfile?.lastName}`
              }
            }
          />
          <TextField source="cookPhone" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <CustomReferenceField
            label="House Id"
            listName={"Houses"}
            source={"houseId"}
          />
          <CustomReferenceField
            label="City Id"
            listName={"Cities"}
            source={"cityId"}
          />
          <TextField source="cityName" />
          <CustomReferenceField
            label="Locality Id"
            listName={"Localities"}
            source={"localityId"}
          />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField source="localityName" />
          <CustomReferenceField
            label="Area Id"
            listName={"Areas"}
            source={"areaId"}
          />
          <TextField source="areaName" />
          <CustomReferenceField
            label="User Id"
            listName={"Customers"}
            source={"userId"}
          />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField source="customerName" />
          <TextField source="customerPhone" />
          <TextField source="cancellationReason" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField source="cookCategory" />
          <TextField source="customerFeedback" />
          <TextField source="customerRating" />
          <TextField source="questions" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField source="numberOfRescheduleReminder" />
          <TextField source="rescheduleReason" />
          <TextField source="extraPrice" />
          <TextField source="totalPeople" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField source="createdBy" />
          <TextField source="createdByType" />
          <TextField source="updatedBy" />
          <TextField source="updatedByType" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField source="cancelledBy" />
          <TextField source="cancelledByType" />
          <TextField source="note" />
          <TextField source="finalConfirmationDate" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField source="finalConfirmationText" />
          <DateField source="lastVisitDate" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <ArrayField source="dishes">
            <Datagrid bulkActionButtons={false} style={{ width: "300px" }}>
              <TextField source="dish.mealType" label={"Type"} />
              <TextField source="dish.name" label={"Dishes"} />
            </Datagrid>
          </ArrayField>
        </SimpleShowLayout>
      </div>
    </Show>
  );
}

export default StarChefVisitMetaShow;