import HttpClient from "../HttpClient";
import { GetListResult, CreateResult, UpdateResult, GetOneResult } from "react-admin";
import { TemporaryReplacementResponse } from "../interfaces/TemporaryReplacement/TemporaryReplacementResponse";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";
import Op from "../interfaces/SequalizeFilters/SequalizeTypes";
import moment from "moment";

export class TempReplacementService {

    processDateRangeFilter(filters: any) {
        let where;
        if (filters.startDate) {
            where = {
                [Op.gte.toString()]: moment(filters.startDate).toDate()
            }
            delete filters.startDate
        }
        if (filters.endDate) {
            if (where) {
                where = {
                    ...where,
                    [Op.lte.toString()]: moment(filters.endDate).toDate()
                }
            } else {
                where = {
                    [Op.lte.toString()]: moment(filters.endDate).toDate()
                }
            }
            delete filters.endDate
        }
        return {date: where};
    }

    async getAllTempReplacement(limit: number, offset: number, filters: any, sort: {}): Promise<GetListResult<TemporaryReplacementResponse>> {
        filters = {...filters};
        let nullFilters: string[] = [];
        let notNullFilters: string[] = [];
        if (filters.allotted === 'Allotted') {
            notNullFilters.push('cookId')
        }
        if (filters.allotted === 'Not Allotted') {
            nullFilters.push('cookId')
        }
        delete filters.allotted;
        if(filters?.customerAcknowledged){
            if(filters?.customerAcknowledged?.toLowerCase() === 'true'){
                filters.customerAcknowledged = true
            }
           else if(filters.customerAcknowledged.toLowerCase() === 'false') {
                filters.customerAcknowledged = false
            }
            else{
                delete filters['customerAcknowledged']
            }
        }
       let autoFilters = ConstructSequelizeFilters(filters, ['cookAllocatedByEmail'], ['status', 'userBundleMappingId', 'cityId', 'customerAcknowledged'], nullFilters, notNullFilters)
       let customFilters = this.processDateRangeFilter(filters);

        filters = {
            ...autoFilters,
            ...customFilters
        }
       let data = await HttpClient.get('/centaurus/temp-replacement/all', {
           params: {
               limit,
               offset,
               sort: sort,
               filters
           }
       })
       return {
           data: data.data.data,
           total: data.data.count
       }
    }

    async getOneTempReplacement(id: number): Promise<GetOneResult<TemporaryReplacementResponse>>{
        let data = await HttpClient.get(`/centaurus/temp-replacement/${id}`);
        return data
    }

    async createTempReplacement(data: any): Promise<CreateResult<TemporaryReplacementResponse>>{
        let createdTr = await HttpClient.post("/centaurus/tr",data )
        return {
            data : createdTr.data
        }
    }
    async assignCook(trId:number, data: any): Promise<UpdateResult<any>>{
        let updatedTr = await HttpClient.patch(`/centaurus/temp-replacement/${trId}/cook`,data )
        return {
            data : updatedTr
        }
    }
    async markedCancel(id:number): Promise<UpdateResult<any>>{
        let updatedTr = await HttpClient.patch(`/centaurus/temp-replacement/${id}/mark-cancelled`)
        return {
            data : updatedTr
        }
    }
    async markedFulfilled(id:number): Promise<UpdateResult<any>>{
        let updatedTr = await HttpClient.patch(`/centaurus/temp-replacement/${id}/mark-fulfilled`)
        return {
            data : updatedTr
        }
    }

    async getWhatsAppMsg(id:number){
        let data = await HttpClient.get(`/centaurus/temp-replacement/${id}/whatsapp-message`)
        return data
    }

    async changeTrTime(id:number, payload: any): Promise<TemporaryReplacementResponse>{
        let data = await HttpClient.patch(`/centaurus/temp-replacement/${id}/change-time`, payload)
        return data?.data
    }

    async markOngoing(id:number): Promise<TemporaryReplacementResponse> {
        let data = await HttpClient.patch(`/centaurus/temp-replacement/${id}/mark-ongoing`)
        return data?.data
    }

    async markFailed(id: number, payload: {reason: string}): Promise<TemporaryReplacementResponse>{
        let data = await HttpClient.patch(`/centaurus/temp-replacement/${id}/mark-failed`, payload)
        return data?.data
    }

    async trAllocationCook(id: number){
        let data = await HttpClient.get(`/centaurus/temp-replacement/${id}/allocation`)
        return data?.data
    }

}
