import { Box, Grid } from "@mui/material";
import moment from "moment";
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  RaRecord,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

const CookLeavesShow = (props: any) => { 
  return (
    <Show {...props} actions={false}>
      <Box sx={{display:"flex",flexDirection:"column"}}>
      <Grid container spacing={2}>
        <Grid item>
          <SimpleShowLayout>
            <NumberField label="Cook Id" source="cookId" />
            <TextField label="Cook First Name" source="cookFirstName" />
            <TextField label="Cook Last Name" source="cookLastName" />
            <TextField label="Cook Phone Number" source="cookPhoneNumber" />
          </SimpleShowLayout>
        </Grid>
        <Grid item>
          <SimpleShowLayout>
            <TextField label="Cook Zoho Id" source="cookZohoId" />
            <TextField label="Category" source="category" />
            <NumberField label="Leave Id" source="id" />
            <TextField label="Leave Type" source="leaveType" />
          </SimpleShowLayout>
        </Grid>
        <Grid item>
          <SimpleShowLayout>
            <TextField label="Leave Source" source="leaveSource" />
            <TextField label="Notes" source="notes" />
            <TextField label="Requested By" source="requestedBy" />
            <TextField label="Reason" source="notes"/>
            <TextField label="Emergency Leave" source="isEmergencyLeave"/>
            <FunctionField label="Leave Duration" render={(record: RaRecord) => {
            if (record?.leaveType ==="LONG_LEAVE") {
                return (
                <div style={{display:"flex",fontWeight:"600",gap:8}}>
                  <div>{moment.utc(record?.leaveStartDate).format('DD MMM YYYY')+" "+record?.startTime+""}</div>
                  <div>{" - "+moment.utc(record?.leaveEndDate).format('DD MMM YYYY')+" "+record?.endTime+""}</div>
                </div>
              )
            }
            else{
              if(record?.leaveType ==="SHORT_LEAVE"&&(record?.leaveStartDate===record?.leaveEndDate)){
                return(
                <div style={{display:"flex",fontWeight:"600",gap:8}}>
                  <div>{record?.startTime+" - "+record?.endTime}</div>
                  <div>{"( "+moment.utc(record?.leaveEndDate).format('DD MMM YYYY')+" )"}</div>
                </div>
                )
              }
              else{
                return(
                <div style={{display:"flex",fontWeight:"600",gap:8}}>
                  <div>{record?.startTime+" - "+record?.endTime}</div>
                  <div>{"( "+moment.utc(record?.leaveStartDate).format('DD MMM YYYY')+" - "+moment.utc(record?.leaveEndDate).format('DD MMM YYYY')+" )"}</div>
                </div>
                )
              }
            }
          }} />
          </SimpleShowLayout>
        </Grid>
        <Grid item>
          <SimpleShowLayout>
            <FunctionField label="Created At" render={(record: RaRecord) => {
              if (record?.createdAt) {
                return (
                  <>
                    <div>Date - {moment.utc(record?.createdAt).add(5, 'hours').add(30, 'minutes').format('DD MMM YYYY')}</div>
                    <div>Time - {moment.utc(record?.createdAt).add(5, 'hours').add(30, 'minutes').format('HH:mm:ss')}</div>
                  </>
                )
              }
            }} />
            <FunctionField label="Updated At" render={(record: RaRecord) => {
              if (record?.updatedAt) {
                return (
                  <>
                    <div>Date -  {moment.utc(record?.updatedAt).add(5, 'hours').add(30, 'minutes').format('DD MMM YYYY')}</div>
                    <div>Time - {moment.utc(record?.updatedAt).add(5, 'hours').add(30, 'minutes').format('HH:mm:ss')}</div>
                  </>
                )
              }
            }} />
          </SimpleShowLayout>
        </Grid>
      </Grid>
      <Box mt="1rem">
        <ArrayField label="CUSTOMER DETAILS" source="customerDetails">
          <Datagrid bulkActionButtons={false}>
            <TextField source="customerFirstName" />
            <TextField source="customerLastName" />
            <TextField source="customerMobile" />
            <TextField source="houseId" />
            <TextField source="locality" />
            <TextField source="address" />
            <TextField source="city" />
            <TextField source="slot" />
            <DateField
              options={{ day: "numeric", month: "short", year: "numeric" }}
              source="absentDate"
            />
          </Datagrid>
        </ArrayField>
        </Box>
      </Box>
    </Show>
  );
};

export default CookLeavesShow;
