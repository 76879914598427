export enum TableData {
    id = 'id',
    zohId = 'zohId',
    Fname = "Fname",
    Lname = "Lname",
    Mobile = "Mobile",
    Address = "Address",
    Category = "Category",
    Vehicle = "Vehicle",
    Cooktype = "Cooktype",
    Experience = "Experience",
    Sector = "Sector",
    North_Indian_Final_Rating = "North Indian Final Rating",
    South_Indian_Final_Rating = "South Indian Final Rating",
    Chinese_Dish_Final_Rating = "Chinese Dish Final Rating",
    Continental_Dish_Final_Rating = "Continental Dish Final Rating",
    Last_Slots_Updated_By_Admin = "Last Slots Updated By Admin",
    Last_Slots_Updated_By_Admin_At = "Last Slots Updated By Admin At",
    Last_Slots_Updated_By_App_At = "Last Slots Updated By App At",
    Last_Slots_Updated_By_System_At = "Last Slots Updated By System At",
    Working_City = "Working City",
    Is_Salaried = "Is Salaried",
}