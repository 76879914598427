import { SelectInput } from "react-admin";

const CustomSelectInput = (props: { data: object, emptyText?: string, source: string, style?: object, default?: string }) => {
    return (
        <SelectInput
            emptyText={props.emptyText || ''}
            label={props.emptyText || ""}
            source={props.source}
            style={props.style}
            defaultValue={props.default}
            choices={
                Object.values(props.data)
                    .map(val => {
                        return {
                            id: val,
                            name: val
                        }
                    })
            }
        />
    )
}

export default CustomSelectInput;