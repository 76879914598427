import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MetabaseDashboardService } from "../../services/MetabaseDashboardService";
import IFrame from "../Common/IFrame";

const metabaseTransactionService = new MetabaseDashboardService();

const MetabaseDashboard = () => {
  const location = useLocation();
  const dashboardId = location.pathname.split("/")[2];
  const [metabaseUrl, setMetabaseUrl] = useState("");

  useEffect(() => {
    metabaseTransactionService
      .getMetabaseEmbedUrl(dashboardId)
      .then((data) => {
        setMetabaseUrl(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dashboardId]);

  return <IFrame url={metabaseUrl} width="100%" height="100%" />;
};

export default MetabaseDashboard;
