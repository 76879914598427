import { Stack, Grid } from "@mui/material";
import { useState } from "react";
import {
  Identifier,
  Show,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { PaymentStatusEnum } from "../../enums/PaymentStatusEnum";
import EventBus from "../../EventBus";
import { PlatformLeadResponse } from "../../interfaces/Party/PlatformLeadInterface";
import PlatformLeadServices from "../../services/PlatformServices";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import TransactionButton from "../Common/TransactionButton";
import UpdatePlatformLeadStatusDialog from "../Common/UpdatePlatfromLeadStatusDialog";
import UpdatePlatformLeadNotesDialog from "../Common/UpdatePlatLeadNoteDialog";
import PlatformLeadMetaShow from "./PlatformLeadMetaShow";

const platformLeadServices = new PlatformLeadServices();

const RenderCTAButtons = (props: any) => {
  let record = useRecordContext();
  const refresh = useRefresh();
  const refundAmountHandler = async (id:Identifier) => {
    try{
      const response = await platformLeadServices.processRefundPlatform(id as number);
      if(response.status === 200){
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Refund Successful",
          type: CustomSnackbarTypes.SUCCESS,
        });
      }
      refresh();
    }catch(error){
      console.error(error);
    }
  }
  const sendPaymentLinkHandler = async (id:Identifier) => {
    try{
      const response = await platformLeadServices.sendPlatformPaymentLink(id as number);
      if(response.status === 200){
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Payment link Sent",
          type: CustomSnackbarTypes.SUCCESS,
        });
      }
      refresh();
    }catch(error){
      console.error(error);
    }
  }
  return (
    <Stack p={2}>
      <Grid container spacing={2} justifyContent="flex-end">
        {record?.paymentStatus === PaymentStatusEnum.PAID && <Grid item>
          <TransactionButton
            onClickHandler={() => {refundAmountHandler(record?.id)}}
            color="error"
            name={"Refund Amount"}
          />
        </Grid>}
        {record?.paymentStatus === PaymentStatusEnum.NOT_PAID && <Grid item>
          <TransactionButton
            onClickHandler={() => {sendPaymentLinkHandler(record?.id)}}
            name={"Send Payment Link"}
          />
        </Grid>}
        <Grid item>
          <TransactionButton
            onClickHandler={() => {
              props.setSelectedData(record);
              props.setPlatFormLeadStatusDialogOpen(true);
            }}
            name={"Update Status"}
          />
        </Grid>
        <Grid item>
          <TransactionButton
            onClickHandler={() => {
              props.setSelectedData(record);
              props.setPlatFormLeadNoteDialogOpen(true);
            }}
            name={"Add Note"}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

const PlatformLeadTabShow = (props: any) => {
  return (
    <TabbedShowLayout
      tabs={
        <TabbedShowLayoutTabs
          variant="scrollable"
          scrollButtons="auto"
          selectionFollowsFocus={true}
          {...props}
        />
      }
    >
      <Tab label={"Details"}>
        <PlatformLeadMetaShow />
      </Tab>
    </TabbedShowLayout>
  );
};

const PlatformLeadShow = (props: any) => {
  let [selectedData, setSelectedData] = useState<any>(null);
  const [platFormLeadStatusDialogOpen, setPlatFormLeadStatusDialogOpen] =
    useState<boolean>(false);
  const [platFormLeadNoteDialogOpen, setPlatFormLeadNoteDialogOpen] =
    useState<boolean>(false);
  const refresh = useRefresh();

  return (
    <div>
      <Show {...props} actions={false}>
        <RenderCTAButtons
          setSelectedData={setSelectedData}
          setPlatFormLeadStatusDialogOpen={setPlatFormLeadStatusDialogOpen}
          setPlatFormLeadNoteDialogOpen={setPlatFormLeadNoteDialogOpen}
        />
        <PlatformLeadTabShow />
        <UpdatePlatformLeadStatusDialog
          platformLeadData={selectedData as PlatformLeadResponse}
          open={platFormLeadStatusDialogOpen}
          setOpen={setPlatFormLeadStatusDialogOpen}
          refresh={refresh}
        />
        <UpdatePlatformLeadNotesDialog
          platformLeadData={selectedData as PlatformLeadResponse}
          open={platFormLeadNoteDialogOpen}
          setOpen={setPlatFormLeadNoteDialogOpen}
          refresh={refresh}
        />
      </Show>
    </div>
  );
};

export default PlatformLeadShow;
