import { Close } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel,
    FormLabel,
    IconButton, Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useRecordContext, useRefresh } from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import {PayoutServices} from "../../services/PayoutServices";
import { useAppDispatch } from "../../store/hooks";
import { createBankAccount } from "../../store/reducers/bankDetails";

interface InputProps {
    setOpen: Dispatch<SetStateAction<boolean>>;
    open: boolean;
    userType: string;
}
const payoutServices = new PayoutServices();
export const AddBankAccountDialog = (props: InputProps) => {
    const record = useRecordContext();
    const refresh = useRefresh();
    const userId = Number(record?.id);
    const [name, setName] = useState<string>('');
    const [isBank, setIsBank] = useState<boolean>(true);
    const [ifscCode, setIfscCode] = useState<string>('');
    const [accountNumber, setAccountNumber] = useState<string>('');
    const [reEnterAccountNumber, setReEnterAccountNumber] = useState<string>('');
    const [vpaId, setVpaId] = useState<string>('');
    const dispatch = useAppDispatch();
    const handleClose = () => {
        props.setOpen(false);
        setName("");
        setIfscCode('');
        setAccountNumber('');
        setReEnterAccountNumber('');
        setVpaId('');
    };

    const validate = () => {
        if(isBank){
            if(
                name === '' ||
                ifscCode === '' ||
                accountNumber === '' ||
                reEnterAccountNumber === ''
            ) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Enter all the details",
                    type: CustomSnackbarTypes.ERROR
                });
                return false;
            }
            if(accountNumber !== reEnterAccountNumber) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Re-verify the Account numbers",
                    type: CustomSnackbarTypes.ERROR
                });
                return false;
            }
        } else {
            if(
                name === '' ||
                vpaId === ''
            ) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Enter all the details",
                    type: CustomSnackbarTypes.ERROR
                });
                return false;
            }
        }
        return true;
    };

    const handleSubmit = async () => {
        if(validate()) {
            if(isBank) {
                await payoutServices
                    .addBankDetails({
                        userId,
                        userType: props?.userType,
                        name,
                        ifscCode,
                        accountNumber
                    })
                    .then((res) => {
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                            message: "Added successfully",
                            type: CustomSnackbarTypes.SUCCESS
                        });
                        handleClose();
                        dispatch(createBankAccount(res))
                        refresh();
                    });
            } else {
                await payoutServices
                    .addUpiDetails({
                        userId,
                        userType: props?.userType,
                        name,
                        vpaId,
                    })
                    .then((res) => {
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                            message: "Added successfully",
                            type: CustomSnackbarTypes.SUCCESS
                        });
                        handleClose();
                        dispatch(createBankAccount(res))
                        refresh();
                    });
            }
        }
    };

    return (
        <Dialog onClose={handleClose} open={props.open} scroll={"paper"} maxWidth="xl">
            <DialogTitle>Add UPI/Bank Account</DialogTitle>
            <DialogContent sx={{minWidth: '340px'}}>
              <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8 }}> <Close /> </IconButton>
              <FormControl fullWidth>
                  <FormLabel id="utensils">Select Type</FormLabel>
                  <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(event: any) => {
                          setIsBank(event.target.value !== 'false');
                      }}
                      value={isBank}
                  >
                      <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Bank"
                      />
                      <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="UPI"
                      />
                  </RadioGroup>
                  <TextField
                      label="Enter your Name"
                      key={"name"}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                      value={name}
                      margin={"dense"}
                  />
                  {isBank ? (
                      <>
                          <TextField
                              label="Enter IFSC Code"
                              key={"ifscCode"}
                              onChange={(event) => {
                                  setIfscCode(event.target.value);
                              }}
                              value={ifscCode}
                              margin={"dense"}
                          />
                          <TextField
                              label="Enter Account Number"
                              key={"accountNumber"}
                              onChange={(event) => {
                                  setAccountNumber(event.target.value);
                              }}
                              value={accountNumber}
                              margin={"dense"}
                              type={"number"}
                          />
                          <TextField
                              label="Re-Enter Account Number"
                              key={"Account Number"}
                              onChange={(event) => {
                                  setReEnterAccountNumber(event.target.value);
                              }}
                              value={reEnterAccountNumber}
                              margin={"dense"}
                              type={"number"}
                          />
                      </>
                  ) : (
                      <TextField
                          label="Enter UPI Id"
                          onChange={(event) => {
                              setVpaId(event.target.value);
                          }}
                          placeholder={'Enter the full UPI Id'}
                          key={'upi'}
                          value={vpaId}
                          margin={"dense"}
                      />
                  )}
              </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    size={"large"}>
                    Submit
                </Button>
                <Button
                    variant="contained"
                    color={"error"}
                    onClick={() => { handleClose(); }}
                    size={"large"} >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};
