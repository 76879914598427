import { useCallback, useEffect, useState } from "react";
import AreaService from "../../services/AreaService";
import { ReferenceModelNameEnums } from "../../enums/ReferenceModelNameEnums";
import {Box, Button, FormControl, InputLabel, MenuItem, Select, Table, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import CircularLoader from "./CircularLoader";
import CookServeTable from "./CookServeTable";
import EmptyListPlaceholder from "./EmptyListPlaceholder";
import { DatePicker } from "@mui/x-date-pickers";
import { CookSlotTimeEnumArray } from "../../enums/CookSlotTimeEnum";
import moment from "moment";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import ServeListSlotsDialog from "./ServeListSlotsDialog";
import { CookService } from "../../services/CookServices";
import { useRecordContext } from "react-admin";
import AssignCookServeList from "./AssingnCookServeList";
import AssignCookOnTrialServeList from "./AssignCookOnTrailServeList";

const areaService =new AreaService();
const cookService =new CookService();

const CookServeList=(props:any)=>{
    const record = useRecordContext()
    const [serviceId,setServiceId]=useState<any>(props?.hasService?props?.serviceId:1);
    const [loading,setLoading]=useState<boolean>(false);
    const [cookLoading,setCookLoading]=useState<boolean>(false);
    const [subData,setSubData]=useState<any[]>([]);
    const [cookData,setCookData]=useState<any>({});
    const [dialogOpen,setDialogOpen]=useState<boolean>(false);
    //if we are checking serivice List in house Tab
    const [cook,setCook]=useState<any>(null)
    const [slots,setSlots]=useState<any[]>([])

    const [date, setDate] = useState<Date>(new Date());
    const [time,setTime] = useState<any>(null);
    const [totalPeople,setTotalPeople] = useState<number>(1);
    const [clicked,setClicked]=useState<boolean>(false)
    const [assignCookOpen,setAssignCookOpen]=useState<boolean>(false)


    const handleReset=()=>{
        setDate(new Date());
        setTime(null);
        setTotalPeople(1);
        setCookData({})
        setClicked(false)
    }
    useEffect(() => {
        setLoading(true);
        areaService
            .getAllServices()
            .then((res) => {
                setSubData(res);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    }, []);

      useEffect(()=>{
        if(cook){
            cookService.getCookServeSlots(props?.hasService?props?.date:date,cook,props?.hasService?props?.totalPeople:totalPeople).then((res)=>{
                setSlots(res);
            })
        }
      },[cook,date,props?.date,props?.hasService,props?.totalPeople,totalPeople])
    


    const refreshData = useCallback(() => {
        setCookLoading(true);
        const payload = {
            serviceId: serviceId,
            referenceId: props?.referenceId,
            houseId:props?.houseId,
            referenceModelName: ReferenceModelNameEnums.AREA,
            time: props?.time,
            totalPeople: props?.totalPeople,
            date: props?.date,
        };
        areaService.getSlcCookServiceMappingForVisit(payload)
            .then((response) => {
                setCookData(response?.data);
                setCookLoading(false);
            })
            .catch((err) => {
                setCookLoading(false);
            });
    }, [serviceId, props?.referenceId, props?.time, props?.totalPeople, props?.date,props?.houseId]);


    const fetchData =useCallback(()=>{
        setCookLoading(true);
        const payload = {
            serviceId: serviceId,
            referenceId: record?.areaId,
            houseId:props?.houseId,
            referenceModelName: ReferenceModelNameEnums.AREA,
            time: time,
            totalPeople: totalPeople,
            date: moment(date).format("YYYY-MM-DD"),
        };
        areaService.getSlcCookServiceMappingForVisit(payload)
            .then((response) => {
                setCookData(response?.data);
                setCookLoading(false);
            })
            .catch((err) => {
                setCookLoading(false);
        });
    },[date,serviceId,time,totalPeople, record?.areaId,props?.houseId])
    const handleClick=()=>{
        if(serviceId&&time&&totalPeople&&date){
            fetchData();
            setClicked(true);
        }
        else{
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Missing Required Data",
                type: CustomSnackbarTypes.ERROR,
            });

        }
    }

    useEffect(()=>{
        if(clicked){
            fetchData();
        }
    },[serviceId,time,totalPeople,date,clicked,props?.hasService,fetchData])
        
    useEffect(() => {
        if(props?.hasService){
        refreshData();
        }
    }, [serviceId, refreshData,props?.hasService]);


    return (
        <>
        {!loading&&
        <Box sx={{display:"flex",gap:"1rem",flexWrap:"wrap"}}>
            <FormControl style={{ width: "230px" }}>
                <InputLabel id="demo-simple-select-label">Service</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={serviceId}
                label="service"
                onChange={(e)=>setServiceId(e.target.value)}
                >
                {
                    subData&&subData.length>0&&subData.map((service)=>(
                        <MenuItem value={service?.id}>{service?.name}</MenuItem>
                    ))
                }
                </Select>
            </FormControl>
            {
                !props?.hasService&&(
                    <>
                        <DatePicker
                            mask=""
                            label="Select Date"
                            inputFormat="dd MMM yyyy"
                            value={date}
                            disabled={loading}
                            onChange={(newValue: any) => {
                                setDate(newValue);
                            }}
                            renderInput={(params) => {
                                return <TextField {...params} />;
                            }}
                        />
                            <FormControl style={{ width: "230px" }}>
                                <InputLabel id="demo-simple-select-label">Time</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={time}
                                    label="Start Time"
                                    onChange={(e) => setTime(e.target.value)}
                                >
                                    {
                                        CookSlotTimeEnumArray.map((time) => (
                                            <MenuItem key={time} value={time}>{time}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl> 
                            <FormControl style={{ width: "230px" }}>
                                <InputLabel id="forTotalPeople">Total People</InputLabel>
                                <Select
                                labelId="forTotalPeople"
                                id="totalPeople"
                                value={totalPeople}
                                label="total People"
                                onChange={(event: any) => {
                                    setTotalPeople(event.target.value);
                                }}
                                >
                                {Object.values(Array.from({ length: 40 }, (_, i) => i + 1)).map((e) => (
                                    <MenuItem key={e} value={e}>
                                    {e}
                                    </MenuItem>
                                ))}
                                </Select>
                        </FormControl>
                        <Box width={"300px"} display={"flex"} justifyContent={"flex-start"} alignItems={"center"} gap={"0.5rem"}>
                            <Button variant="contained" size="medium" onClick={handleClick}>Fetch</Button>
                            <Button variant="contained" size="medium" onClick={handleReset}>Reset</Button>
                        </Box> 
                    </>
                )
            }
       </Box>
       }
       <Table>
          <TableHead>
              <TableRow>
                  <TableCell>Cook Id</TableCell>
                  <TableCell>Cook Name</TableCell>
                  <TableCell>Slc Name</TableCell>
                  <TableCell>Mapping Type</TableCell>
                  <TableCell>Next Available</TableCell>
                  <TableCell>Current Available</TableCell>
                  <TableCell>Slots</TableCell>
                  <TableCell>Assign Cook</TableCell>
              </TableRow>
          </TableHead>
          {
            cookLoading?
            <CircularLoader/>:(
                (cookData&&cookData?.primary&&cookData?.secondary&&(cookData?.primary.length>0||cookData?.secondary.length))
                ?<CookServeTable data={[...cookData?.primary,...cookData?.secondary]} setDialogOpen={setDialogOpen} setCook={setCook} setAssignCookOpen={setAssignCookOpen} hasService={props?.hasService}/>
                :<EmptyListPlaceholder message="No data Found"/>
            )
          }
        </Table>
        <ServeListSlotsDialog open={dialogOpen} setOpen={setDialogOpen} slots={slots} cookId={cook} date={props?.hasService?props?.date:moment(date).format("DD/MM/YYYY")} noOfPeople={props?.hasService?props?.totalPeople:totalPeople}  />
        {
            props?.type&&
            (props?.type==="OMS"?
                <AssignCookServeList assignCookOpen={assignCookOpen} setAssignCookOpen={setAssignCookOpen} cookId={cook} mealServiceId={props?.mealServiceId}/>:
                <AssignCookOnTrialServeList assignCookOpen={assignCookOpen} setAssignCookOpen={setAssignCookOpen} cookId={cook} mealServiceId={props?.mealServiceId}/>
            )
        }
    </>
    )
}
export default CookServeList;


