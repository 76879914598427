import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField
} from "@mui/material";
import { useRecordContext, useRefresh } from "react-admin";
import { useCallback, useEffect, useState } from 'react';
// import { TrialsService } from "../../services/TrialServices";
import EventBus from "../../EventBus";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import CancellationReasonsCategories from "../../enums/CancellationReasonsCategories";
import { OneMealService } from "../../services/OneMealService";
import {getReasonCategory } from "./constants/constant";
import {ActionTypeEnum} from "../../enums/ActionTypeEnum";
import {TrialsV3Status} from "../../enums/TrialsV3Status";
import ReasonSubreasonModuleEnum from "../../enums/ReasonSubreasonModuleEnum";

interface Props {
  intiatedBy: any
  data: any;
  open: boolean;
  callback: () => void;
  isTrial?:boolean
  handleTrialSubmit?: (cancellationReason: string, cancellationSubReason: string) => void;
}
const TrialsCancelDialog = (props: Props) => {
  let record = useRecordContext();
  const [cancelTrialReasonOptionMapping,setCancelTrialReasonOptionMapping]=useState<any>([]);
  const [reasonSubCategoryArray, setReasonSubCategoryArray] = useState<string[] | null>(null);
  const [reasonCategory, setReasonCategory] = useState<CancellationReasonsCategories | null>(null);
  const [reasonSubCategory, setReasonSubCategory] = useState<string | null>(null);
  const refresh = useRefresh();
  // const trialService = new TrialsService()
  const oneMealService = new OneMealService()


  const loadData = useCallback(async () => {
    // if(!record?.status){ return;}
    const module = props?.intiatedBy === 1 ? ReasonSubreasonModuleEnum.TRIAL : ReasonSubreasonModuleEnum.ONE_MEAL_SERVICE;
    switch (record?.status) {
      case TrialsV3Status.COOK_ALLOCATED:

        let sampleReasonSubReason1:any = await getReasonCategory(module,ActionTypeEnum.POST_ALLOCATION_CANCELLED);
        setCancelTrialReasonOptionMapping(sampleReasonSubReason1);
        break;
      case TrialsV3Status.PENDING:
        let sampleReasonSubReason2:any = await getReasonCategory(module,ActionTypeEnum.PRE_ALLOCATION_CANCELLED);
        setCancelTrialReasonOptionMapping(sampleReasonSubReason2);
        break;
      case TrialsV3Status.COOK_CONFIRMED:
        let sampleReasonSubReason3:any = await getReasonCategory(module,ActionTypeEnum.POST_ALLOCATION_CANCELLED);
        setCancelTrialReasonOptionMapping(sampleReasonSubReason3);
        break;
      case TrialsV3Status.ONGOING:
        let sampleReasonSubReason4:any = await getReasonCategory(module,ActionTypeEnum.POST_ALLOCATION_CANCELLED);
        setCancelTrialReasonOptionMapping(sampleReasonSubReason4);
        break;

    }
  }, [props?.intiatedBy, record?.status]);

  useEffect(()=>{
    loadData();
  },[loadData])

  let handleClose = () => {
    setReasonSubCategoryArray(null)
    setReasonCategory(null)
    setReasonSubCategory(null)
    props.callback();
  };

  const handleReasonCategoryChange = (event: any) => {
    const selectedCategory:any = cancelTrialReasonOptionMapping.find(
      (option:any) => option.category === event.target.value
    );

    if (selectedCategory) {
      setReasonSubCategoryArray(selectedCategory?.subCategory);
      setReasonCategory(event.target.value);
    }
  }

  const handleReasonSubCategoryChange = (event: any) => {
    setReasonSubCategory(event.target.value)
  }

  function showSubCategoryOrNot(reasonCategory: string) {
    if (reasonCategory === CancellationReasonsCategories.OTHERS) {
      return (
        <FormControl style={{ width: 500 }}>
          <TextField value={reasonSubCategory} onChange={handleReasonSubCategoryChange} label="Write a reason" />
        </FormControl>
      )
    } else {
      return (
        <FormControl style={{ width: 500 }}>
          <InputLabel id="select-reason">Select a Subreason</InputLabel>
          <Select
            labelId="select-reason"
            id="select-reason"
            defaultValue="Select a reason"
            value={reasonSubCategory}
            onChange={handleReasonSubCategoryChange}
          >
            {reasonSubCategoryArray?.map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      scroll={"paper"}
      maxWidth="xl"
    >
      <DialogTitle>{props?.intiatedBy === 1 ? "Cancel Trials" : "Cancel One Meal Service"}</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          justifyContent={"space-evenly"}
          style={{ padding: "12px 12px 12px 12px" }}
          direction="column"
          alignItems="center"
        >
          <Grid item xs={12}>
            <FormControl style={{ width: 500 }}>
              <InputLabel id="select-label">Tell us Why?</InputLabel>
              <Select
                labelId="select-label"
                id="select-label"
                defaultValue="Tell us Why?"
                value={reasonCategory}
                onChange={handleReasonCategoryChange}
              >
                {cancelTrialReasonOptionMapping.map((item:any) => {
                    return (
                      <MenuItem key={item.category} value={item.category}>
                        {item.category}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {
              reasonCategory && showSubCategoryOrNot(reasonCategory!)
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginTop: "12px" }}>
        <Button
          variant="contained"
          onClick={() => {
            if (!reasonSubCategory || !reasonCategory) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Fill the details to proceed",
                type: CustomSnackbarTypes.WARNING
              });
              return;
            }
            if (reasonCategory !== CancellationReasonsCategories.PRICING_MISMATCH) {
              if (!reasonSubCategory || !reasonCategory) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Fill the details to proceed",
                  type: CustomSnackbarTypes.WARNING
                });
                return;
              }
            }
            if (reasonCategory === CancellationReasonsCategories.PRICING_MISMATCH) {
              if (!reasonCategory) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Fill the details to proceed",
                  type: CustomSnackbarTypes.WARNING
                });
                return;
              }
            }
            if(props.intiatedBy === 1){
              if(props?.handleTrialSubmit) {
                props?.handleTrialSubmit(reasonCategory, reasonSubCategory);
                handleClose();
              }

            // trialService.cancelTrials(props?.data?.id, {
            //    reason:reasonCategory,
            //   cancellationSubReason: reasonSubCategory
            // }).then(() => {
            //   refresh();
            //   handleClose();
            // });

            }
            else if(props.intiatedBy === 2){
              oneMealService.cancelOneMealService(props?.data?.id, {
                reason:reasonCategory,
                cancellationSubReason: reasonSubCategory
              }).then(() => {
                refresh();
                handleClose();
              });}
          }}
          size={"large"}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            handleClose();
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default TrialsCancelDialog;
