import { Button, Link } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useLocation } from "react-router-dom";
import CallButton from "../Common/CallButton/CallButton";
import { UserType } from "../../enums/common";

const OneMealListForModule = (props: any) => {
  let location = useLocation();
  let oneMealServiceId = location.pathname.split("/")[2];

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1, minWidth: 64 },
    {
      field: "customerName",
      headerName: "Customer Name",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "customerPhoneNumber",
      headerName: "Customer Phone",
      renderCell: (cellValues) => {
        return <CallButton toPhoneNumber={cellValues?.row?.customerPhoneNumber} userType={UserType.CUSTOMER} />
      },
      minWidth: 130,
      flex: 1,
    },
    {
      field: "customerAddress",
      headerName: "Customer Address",
      minWidth: 150,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <div>
            {`${cellValues?.row?.house?.address} ${cellValues?.row?.house?.locality} ${cellValues?.row?.house?.city}`}
          </div>
        );
      },
    },
    {
      field: "cookId",
      headerName: "Cook Id",
      renderCell: (cellValues) => {
        if (!cellValues?.row?.cook?.id) return "---";
        return <div>{`${cellValues?.row?.cook?.id}`}</div>;
      },
    },
    {
      field: "cookName",
      headerName: "Cook Name",
      renderCell: (cellValues) => {
        if (!cellValues?.row?.cook?.cookFullProfile) return "---";
        return (
          <div>
            {`${cellValues?.row?.cook?.cookFullProfile?.firstName} ${cellValues?.row?.cook?.cookFullProfile?.lastName}`}
          </div>
        );
      },
    },
    {
      field: "cookMobile",
      headerName: "Cook Mobile",
      renderCell: (cellValues) => {
        if (!cellValues?.row?.cook?.mobile) return "---";
        return <CallButton toPhoneNumber={cellValues?.row?.cook?.mobile} userType={UserType.COOK} />
        // <div>{`${cellValues?.row?.cook?.mobile}`}</div>;
      },
      minWidth: 120,
    },
    { field: "date", headerName: "Date", sortable: true },
    { field: "time", headerName: "Time" },
    { field: "status", headerName: "Status" },
    {
      field: "paymentStatus",
      headerName: "Payment Status",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: (cellValues) => {
        if (Number(cellValues?.id) !== Number(oneMealServiceId)) {
          return (
            <Link href={`/#/OneMealService/${cellValues?.id}/show`}>
              <Button variant={"contained"} color={"primary"}>
                Show
              </Button>
            </Link>
          );
        }
        return null;
      },
    },
  ];
  return (
    <div style={{ height: 500, width: "100%", marginBottom: "40px" }}>
      <DataGrid
        columns={columns}
        rows={props?.data ?? []}
        pageSize={props?.pageSize}
        onPageSizeChange={(pageSize) => {
          props.setPageSize(pageSize);
        }}
        loading={props?.isLoading}
        rowsPerPageOptions={[5, 10, 25, 50]}
        rowCount={props?.count ?? 0}
        paginationMode="server"
        onPageChange={(page) => {
          props.refreshData(page);
        }}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        experimentalFeatures={{ newEditingApi: true }}
      />
    </div>
  );
};

export default OneMealListForModule;
