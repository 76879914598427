import {
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import moment from "moment";
import {
  Show,
  TextField,
  DateField,
  NumberField,
  SimpleShowLayout,
  ArrayField,
  Datagrid,
  FunctionField,
  RaRecord,
} from "react-admin";

interface CardComponentInputProps {
  commenterId: number;
  commenterName: string;
  commentDate: string;
  comment: string;
}

const CustomCardComponent = ({
  commenterId,
  commenterName,
  commentDate,
  comment,
}: CardComponentInputProps) => {
  return (
    <Card sx={{ maxWidth: 420, padding: 0, margin: 0 }} elevation={0}>
      <CardHeader
        title={`#${commenterId} ${commenterName}`}
        subheader={commentDate}
      />
      <CardContent sx={{ overflow: "auto" }}>
        <Typography sx={{wordWrap: "break-word"}} variant="body2" color="text.secondary">
          {String(comment)}
        </Typography>
      </CardContent>
    </Card>
  );
};

const PlatformLeadMetaShow = (props: any) => {
  return (
    <Show {...props} actions={false}>
      <div style={{ display: "grid", gridTemplateColumns: "30% 30% 40%" }}>
        <SimpleShowLayout>
          <TextField label="ID" source="id" emptyText="---" />
          <TextField label="Name" source="name" emptyText="---" />
          <NumberField
            label="Mobile"
            source="mobile"
            emptyText="---"
          />
          <TextField label="Email" source="email" emptyText="---" />
          <TextField
            label="Customer Id"
            source="customerId"
            emptyText="---"
          />
          <TextField
            label="Product"
            source="product"
            emptyText="---"
          />
          <TextField label="Status" source="status" emptyText="---" />
          <TextField
            label="Total People"
            source="totalPeople"
            emptyText="---"
          />
          <DateField label="Date" source="date" emptyText="---" />
          <TextField label="Amount" source="amount" emptyText="---"/>
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField
            label="Area ID"
            source="areaId"
            emptyText="---"
          />
          <TextField
            label="Area Name"
            source="areaName"
            emptyText="---"
          />
          <TextField
            label="City ID"
            source="cityId"
            emptyText="---"
          />
          <TextField
            label="City Name"
            source="cityName"
            emptyText="---"
          />
          <TextField
            label="Locality Id"
            source="localityId"
            emptyText="---"
          />
          <TextField
            label="Locality Name"
            source="localityName"
            emptyText="---"
          />
          <TextField
            label="Allocator Id"
            source="allocatorId"
            emptyText="---"
          />
          <TextField
            label="Allocator Email"
            source="allocatorEmail"
            emptyText="---"
          />
          <TextField
            label="Payment Status"
            source="paymentStatus"
            emptyText="---"
          />
          <TextField
            label="ClientId"
            source="clientId"
            emptyText="---"
          />
          <TextField
            label="Customer Message"
            source="customerMessage"
            emptyText="---"
          />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <Card
            style={{
              padding: 0,
              overflow: "auto",
            }}
            elevation={0}
          >
            <SimpleShowLayout sx={{padding: 0}}>
              <ArrayField source="notes" label="NOTES">
                <Datagrid sx={{padding: 0}} bulkActionButtons={false}>
                  <SimpleShowLayout sx={{padding: 0}}>
                    <FunctionField
                      render={(record: RaRecord) => (
                        <CustomCardComponent
                          commenterId={record?.commenterId}
                          commenterName={record?.commenterName}
                          commentDate={moment(record?.timestamp).format("LLLL")}
                          comment={record?.comment}
                        />
                      )}
                    />
                  </SimpleShowLayout>
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </Card>
        </SimpleShowLayout>
      </div>
    </Show>
  );
};

export default PlatformLeadMetaShow;