import {
    Datagrid,
    DateInput,
    EditButton,
    FunctionField,
    List,
    SelectInput,
    ShowButton,
    TextField,
    TextInput,
    useRecordContext
} from 'react-admin';
import { UserType } from '../../enums/common';
import { StarChefVisitStatus } from '../../enums/StarChefVisitStatus';
import CallButton from '../Common/CallButton/CallButton';
import CustomReferenceField from '../Common/CustomReferenceField';

const starChefFilter = [
    <SelectInput
        label="Filter by status"
        source="status"
        alwaysOn
        emptyText="Clear Filter"
        choices={
            Object.values(StarChefVisitStatus)
                .map(key => {
                    return {
                        id: key,
                        name: key
                    }
                })
        }
    />,
    <DateInput label="VisitDate" source="date" alwaysOn />,
    <TextInput label="Customer Name" source="customerName" alwaysOn/>,
    <TextInput label="Customer Phone" source="customerPhone" alwaysOn/>
];

const EditButtonView = () => {
    let record = useRecordContext();
    if ([StarChefVisitStatus.PENDING, StarChefVisitStatus.COOK_ALLOCATED].includes(record.status)) {
        return (
            <EditButton />
        )
    }
    return null
}
const StarChefVisit = (props: any) => {
    return (
      <List {...props} filters={starChefFilter}>
        <Datagrid bulkActionButtons={false}>
          <TextField label="Id" source="id" sortable={false} />
          <TextField source="status" sortable={false} />
          <TextField source="visitDate" sortable={false} />
          <TextField source="visitTime" sortable={false} />
          <TextField source="totalPeople" sortable={false} />
          <CustomReferenceField
            label="House"
            listName={"Houses"}
            source={"houseId"}
          />
          <TextField source="customerName" sortable={false} />
          <FunctionField label= "Customer Phone" render={(record: any) => <CallButton toPhoneNumber={record?.customerPhone} userType={UserType.CUSTOMER}/>}/>
          <TextField source="createdByType" sortable={false} />
          <ShowButton />
          <EditButtonView />
        </Datagrid>
      </List>
    );
};

export default StarChefVisit;
