import { Button } from "@mui/material";
import { useState } from "react";
import { Create, NumberInput, SelectInput, SimpleForm, TextInput } from "react-admin";
import { RentPayingCapacityEnum } from "../../enums/RentPayingCapacityEnum";
import CreateLocalityInterface from "../../interfaces/Locality/CreateLocality";
import { useAppDispatch } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import AreaList from "../Area/AreaList";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";

const CreateLocality = () => {
    const [areaId, setAreaId] = useState<number>();
    const dispatch = useAppDispatch();

    const handleCityListDialogOpen = () => {
        dispatch(setCustomSelectOpen({
            open: true
        }))
    }

    const transform = (data: any): CreateLocalityInterface | null => {
        if (areaId) {
            return {
                name: data.name,
                areaId: areaId,
                coordinates: data.coordinates,
                rentPayingCapacity: data.rentPayingCapacity
            }
        }

        return null;
    };

    return (
        <Create transform={transform}>
            <SimpleForm>
                <ResourceSelectDialog
                    name={"Areas"}
                    list={AreaList}
                    onClose={(rec: any) => setAreaId(rec.id)}
                />

                <Button variant={"contained"} onClick={handleCityListDialogOpen} disableElevation> {areaId ? `Area: ${areaId}` : `Select Area`} </Button>
                <TextInput source="name" />
                <NumberInput source="coordinates.lat" />
                <NumberInput source="coordinates.long" />
                <SelectInput
                    label="Rent Paying Capacity"
                    source="rentPayingCapacity"
                    alwaysOn
                    choices={
                        Object.values(RentPayingCapacityEnum)
                            .map(value => {
                                return {
                                    id: value,
                                    name: value, 
                                }
                            })
                    }
                />, 
            </SimpleForm>
        </Create>
    )
}

export default CreateLocality