import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
} from "@mui/material";
import { useState } from "react";
import { useRefresh } from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { VendorPayoutTransactionStatus } from "../../enums/vendorPayoutTransactionStatus";
import EventBus from "../../EventBus";
import { VendorPayoutResponse } from "../../interfaces/VendorPayout/VendorPayoutResponse";
import VendorPayoutService from "../../services/VendorPayoutService";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import { PayoutTypeDailog } from "../Common/payoutTypeDialog";
import RejectPayoutReasonDialog from "../Common/RejectPayoutReasonDialog";
import TransactionButton from "../Common/TransactionButton";
const vendorPayoutService = new VendorPayoutService();
interface Props {
  data: any;
  updateParentData: () => void;
}

interface ActionButtonsInputProps {
  id: number;
  setPayoutTypeDailog: React.Dispatch<React.SetStateAction<boolean>>;
  data?: VendorPayoutResponse;
  setSelectedData: React.Dispatch<
    React.SetStateAction<VendorPayoutResponse | null>
  >;
  status?: VendorPayoutTransactionStatus;
  updateParentData: () => void;
}

const ApprovePayout = (props: any) => {
  const handleClick = async() => {
    try {
      const response = await vendorPayoutService.approvePayout(Number(props?.id));
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: response.status,
        type: CustomSnackbarTypes.SUCCESS
    });
      props.updateParentData();
    } catch (error) {
      console.error(error)
    }
    
  };

  return (
    <TransactionButton
      onClickHandler={handleClick}
      color="success"
      name={"Approve Payout"}
    />
  );
};
const ProcessPayout = (props: ActionButtonsInputProps) => {
  
  const handleClick = () => {
    props.setPayoutTypeDailog(true);
    props.setSelectedData(props.data as VendorPayoutResponse);
    props.updateParentData();
  };

  return (
    <TransactionButton onClickHandler={handleClick} name={"Process Payout"} />
  );
};

const RejectPayout = (props: any) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <RejectPayoutReasonDialog open={open} setOpen={setOpen} id={props.id} />
      <TransactionButton
        onClickHandler={() => setOpen(true)}
        color="error"
        name={"Reject Payout"}
      />
    </>
  );
};

const RendorCTAButtons = (props: ActionButtonsInputProps) => {
  return (
    <div>
      {props?.status &&
        [VendorPayoutTransactionStatus.PENDING].includes(props.status) && (
          <>
            <ApprovePayout id={props.id} key={`approve-${props.id}`} updateParentData={props.updateParentData} />
            <RejectPayout id={props.id} key={`reject-${props.id}`} updateParentData={props.updateParentData}/>
          </>
        )}
      {props?.status &&
        [
          VendorPayoutTransactionStatus.APPROVED,
          VendorPayoutTransactionStatus.PROCESSING_FAILED,
        ].includes(props.status) && (
          <ProcessPayout
            id={props.id}
            data={props.data}
            setPayoutTypeDailog={props.setPayoutTypeDailog}
            setSelectedData={props.setSelectedData}
            updateParentData={props.updateParentData}
          />
        )}
    </div>
  );
};
function TrialsVendorPayoutMetaShow(props: Props) {
  const [payoutTypeDailog, setPayoutTypeDailog] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<VendorPayoutResponse | null>(
    null
  );
  const refresh = useRefresh();
  const updateParentData = () => {
    refresh()
  }
  return (
    <div>
      <PayoutTypeDailog
        data={selectedData}
        open={payoutTypeDailog}
        callback={() => {
          setPayoutTypeDailog(false);
        }}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell >Id</TableCell>
              <TableCell>{"Cook Name"}</TableCell>
              <TableCell>{"Amount"}</TableCell>
              <TableCell>{"Description"}</TableCell>
              <TableCell>{"Last Update"}</TableCell>
              <TableCell>{"Status"}</TableCell>
              <TableCell>{"Action"}</TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.data?.map((value: any) => (
              <TableRow
                key={value.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Link href={`/#/VendorPayouts/${value.id}/show`}>
                      {value?.id}
                  </Link>
                </TableCell>
                <TableCell>
                  {value?.cook["fname"] + " " + value?.cook["lname"]}
                </TableCell>
                <TableCell>{value?.amount}</TableCell>
                <TableCell>{value?.id}</TableCell>
                <TableCell>{value?.updatedAt}</TableCell>
                <TableCell>{value?.status}</TableCell>
                <TableCell align={"left"}>
                  <RendorCTAButtons
                    id={value.id}
                    status={value.status}
                    data={value}
                    setPayoutTypeDailog={setPayoutTypeDailog}
                    setSelectedData={setSelectedData}
                    updateParentData={() => updateParentData()}
                  />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default TrialsVendorPayoutMetaShow;
