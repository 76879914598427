import {Grid, Link, Stack} from "@mui/material";
import { useState } from "react";
import {Show, Tab, TabbedShowLayout, TabbedShowLayoutTabs, useRecordContext} from "react-admin";
import { useLocation } from "react-router-dom";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { CookService } from "../../services/CookServices";
import { useAppDispatch } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import CookReferralDialog from "../Common/CookReferralDialog";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import TransactionButton from "../Common/TransactionButton";
import { UpdateCookPhoneNumberDialog } from "../Common/UpdateCookPhoneNumberDialog";
import VendorPayoutDialog from "../Common/VendorPayoutDialog";
import LocalityList from "../Locality/LocalityList";
import CookLeavesTab from "./CookLeavesTab";
import CookSubscriptionTab from "./CookSubscriptionTab";
import CookTimeSlotList from "./CookTimeSlotList";
import CookTrialTab from "./CookTrialTab";
import CookVendorPayoutTab from "./CookVendorPayoutTab";
import CookSalaryTab from "./CookSalaryTab";
import { AddBankAccountDialog } from "../Common/AddBankAccountDialog";
import UserPayoutTab from "../Payout/UserPayoutTab";
import { PayoutUserType } from "../../enums/PayoutEnums";
import CookLocalityTab from "./CookLocality";
import LocalityTypeDialog from "../Common/LocalityTypeDialog";
import LocalityTypeInterface from "../../interfaces/Locality/LocalityType";
import { LocalityType } from "../../enums/LocalityEnum";
import ChefRating from "./ChefRating";
import SendTrainingContentDialog from "../Common/SendTrainingContentDialog";
import CookProfile from "./CookProfile";
import CookWalletTab from "./CookWalletTab";
import CookEscalation from "./CookEscalation";
import CookOmsDetailsTab from "./CookOmsDetailsTab";
import CookAuditLogs from "./CookAuditLogs";
import CookReferralTab from "./CookReferralTab";
import CooksPartTimeCalendar from "./CooksPartTimeCalendar";
import CookAdditionalDetails from "./CookAdditionalDetails";
import CookSLC from "./CookSLC";
import CookRecommendedDishes from "./CookRecommendedDishes";
import QrChefpayTransactions from "./Qr-ChefpayTransactions";
import {UpdateTierDialog} from "../Common/UpdateTierDialog";

const cookService = new CookService();

const RenderCTAButtons = (props: any) => {
  const dispatch = useAppDispatch();
    let record = useRecordContext();

  const handleOpenLocality = () => {
    dispatch(setCustomSelectOpen({ open: true }));
  };

  return (
    <Stack p={2}>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item>
            <Link href={`#/VisitTracker?cookId=${record?.id}`} target={'_self'} underline="none">
                <TransactionButton
                    name={"View Tracker"}
                    onClickHandler={() => {}}
                />
            </Link>
        </Grid>
        <Grid item>
            <TransactionButton
                name={"Update Tier"}
                onClickHandler={() => {
                    props.setSelectedData({cook: record});
                    props.setTierDialog(true);
                }}
            />
        </Grid>
        <Grid item>
            <Link href={`#/cook-activity/${record?.id}`} target={'_self'} underline="none">
                <TransactionButton
                    name={"View Cook Activity"}
                    onClickHandler={() => {}}
                />
            </Link>
        </Grid>
        <Grid item>
            <TransactionButton
                name={"Send Training Content"}
                color="success"
                onClickHandler={() => {
                    props.setSelectedData({cook: record});
                    props.setSendTrainingContentDialog(true);
                }}
            />
        </Grid>
        <Grid item>
          <TransactionButton
            onClickHandler={() => {
              props.setOpenAddBankAccountDialog(true);
            }}
            name={"Add UPI/Bank Account"}
          />
        </Grid>
        <Grid item>
          <TransactionButton
            onClickHandler={() => {
              handleOpenLocality();
              props.setOpenLocalityDialog(true);
            }}
            name={"Assign Locality"}
          />
        </Grid>
        <Grid item>
          <TransactionButton
            onClickHandler={() => props.setOpenCookReferralDialog(true)}
            name={"Create Referral"}
          />
        </Grid>
        <Grid item>
          <TransactionButton
            onClickHandler={() => props.setOpenVendorPayoutDialog(true)}
            name={"Create Misc. Vendor Payout"}
          />
        </Grid>
        <Grid item>
          <TransactionButton
            onClickHandler={() =>
              props.setOpenUpdateCookPhoneNumberDialog(true)
            }
            name={"Update Mobile Number"}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

const CookTabShow = (props: any) => {
  let location = useLocation();
  let cookId = location.pathname.split("/")[2];

  return (
    <TabbedShowLayout  tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" selectionFollowsFocus={true} {...props} />}>
      <Tab label={"Details"}>
        <CookProfile cookId={Number(cookId)}></CookProfile>
      </Tab>
      <Tab label={"Cook Additional Details"}>
        <CookAdditionalDetails cookId={Number(cookId)}/>
      </Tab>
      <Tab label={"Cook SLCs"}>
        <CookSLC cookId={Number(cookId)}/>
      </Tab>
      <Tab label={"Cooks Subscription"}>
        <CookSubscriptionTab cookId={Number(cookId)} />
      </Tab>
      <Tab label={"Cooks Calendar"}>
        <CooksPartTimeCalendar />
      </Tab>
      <Tab label={"Cook Slots"}>
        <CookTimeSlotList cookId={Number(cookId)} />
      </Tab>
      <Tab label={"Cook Leaves"}>
        <CookLeavesTab cookId={Number(cookId)} />
      </Tab>
      <Tab label={"Trials Details"}>
        <CookTrialTab cookId={parseInt(cookId)} />
      </Tab>
      <Tab label={"OMS Detail"}>
        <CookOmsDetailsTab cookId={parseInt(cookId)} />
      </Tab>
      <Tab label={"Payout"}>
        <CookVendorPayoutTab cookId={parseInt(cookId)} />
      </Tab>
      <Tab label={"MG Cook Salary"}>
        <CookSalaryTab cookId={parseInt(cookId)} />
      </Tab>
      <Tab label={"Cook Locality"}>
        <CookLocalityTab cookId={parseInt(cookId)} />
      </Tab>
      <Tab label={"Bank Details"}>
        <UserPayoutTab
          userType={PayoutUserType.COOK}
          userId={parseInt(cookId)}
        />
      </Tab>
      <Tab label={"Chef Rating"}>
        <ChefRating cookId={Number(cookId)} />
      </Tab>
      <Tab label={"Escalations"}>
        <CookEscalation cookId={Number(cookId)}/>
      </Tab>
      <Tab label={"Wallet"}>
        <CookWalletTab cookId={Number(cookId)}/>
      </Tab>
      <Tab label={"Audit Logs"}>
        <CookAuditLogs cookId={Number(cookId)}/>
      </Tab>
      <Tab label={"Cook Referral"}>
        <CookReferralTab cookId={Number(cookId)}/>
      </Tab>
      <Tab label={"Cook Recommended Dish"}>
        <CookRecommendedDishes cookId={Number(cookId)}/>
      </Tab>
      <Tab label={"QR/Chef Pay Transactions"}>
        <QrChefpayTransactions cookId={Number(cookId)}/>
      </Tab>
    </TabbedShowLayout>
  );
};

const CookShow = (props: any) => {
    let [selectedData, setSelectedData] = useState<any>(null);
  const [openCookReferralDialog, setOpenCookReferralDialog] = useState(false);
  const [openVendorPayoutDialog, setOpenVendorPayoutDialog] = useState(false);
  const [openUpdateCookPhoneNumberDialog, setOpenUpdateCookPhoneNumberDialog] =
    useState(false);
  const [openAddBankAccountDialog, setOpenAddBankAccountDialog] =
    useState<boolean>(false);
  const [openLocalityDialog, setOpenLocalityDialog] = useState(false);
  const [openLocalityTypeDialog, setOpenLocalityTypeDialog] = useState(false);
  const [localityData, setLocalityData] = useState<LocalityTypeInterface>();
  let location = useLocation();
  let cookId = location.pathname.split("/")[2];
const [sendTrainingContentDialog, setSendTrainingContentDialog] =  useState<boolean>(false);
const [tierDialog, setTierDialog] =  useState<boolean>(false);

  const handleCloseLocality = (recordData: LocalityTypeInterface) => {
    setOpenLocalityDialog(false);
    setOpenLocalityTypeDialog(true);
    setLocalityData(recordData);
  };

  const localityDialogClose = () => {
    setOpenLocalityTypeDialog(false);
  };
  const handleCloseLocalityTypeDialog = (recordData: any) => {
    localityDialogClose();
    const params: LocalityTypeInterface = {
      localityId: recordData.id,
      areaId: recordData.areaId,
      cityId: recordData.cityId,
      preference: 1,
      localityType: recordData.localityType,
    };

    if (localityData) {
      cookService.addLocalityMapping(Number(cookId), params).then(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "success",
          type: CustomSnackbarTypes.SUCCESS,
        });
      });
    }
  };

  return (
    <div>
      {openLocalityDialog && (
        <ResourceSelectDialog
          name={"Localities"}
          list={LocalityList}
          onClose={handleCloseLocality}
        />
      )}
      {openLocalityTypeDialog && localityData && (
        <LocalityTypeDialog
          open={openLocalityTypeDialog}
          onClose={localityDialogClose}
          data={localityData}
          cook={props.data}
          submit={(type: LocalityType) => {
            setLocalityData({ ...localityData, localityType: [type] });
            handleCloseLocalityTypeDialog({
              ...localityData,
              localityType: [type],
            });
          }}
        />
      )}
      <Show {...props} actions={false}>
        {!openLocalityDialog && (
          <CookReferralDialog
            open={openCookReferralDialog}
            setOpen={setOpenCookReferralDialog}
          />
        )}
        <VendorPayoutDialog
          open={openVendorPayoutDialog}
          setOpen={setOpenVendorPayoutDialog}
        />
        <UpdateCookPhoneNumberDialog
          open={openUpdateCookPhoneNumberDialog}
          setOpen={setOpenUpdateCookPhoneNumberDialog}
        />
        <AddBankAccountDialog
          open={openAddBankAccountDialog}
          setOpen={setOpenAddBankAccountDialog}
          userType={PayoutUserType.COOK}
        />
        {tierDialog && (
          <UpdateTierDialog
              data={selectedData}
              open={tierDialog}
              setOpen={setTierDialog}
          />
        )}
        {sendTrainingContentDialog &&(
            <SendTrainingContentDialog
            data={selectedData}
            open={sendTrainingContentDialog}
            setOpen={setSendTrainingContentDialog}
            />
        )}
        <RenderCTAButtons
          setOpenCookReferralDialog={setOpenCookReferralDialog}
          setOpenVendorPayoutDialog={setOpenVendorPayoutDialog}
          setOpenUpdateCookPhoneNumberDialog={
            setOpenUpdateCookPhoneNumberDialog
          }
          setOpenLocalityDialog={setOpenLocalityDialog}
          setOpenAddBankAccountDialog={setOpenAddBankAccountDialog}
          setSelectedData={setSelectedData}
          setSendTrainingContentDialog={setSendTrainingContentDialog}
          setTierDialog={setTierDialog}
        />
        <CookTabShow />
      </Show>
    </div>
  );
};
export default CookShow;
