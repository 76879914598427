import { useEffect, useState } from "react";
import { CookLeavesService } from "../../services/CookLeavesService";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import CircularLoader from "../Common/CircularLoader";
import CookLeavesTable from "../CookLeaves/CookLeavesTable";

interface InputProps {
    userBundleMappingId: number;
}

const cookLeavesService = new CookLeavesService()
const UserBundleCookLeaves = (props: InputProps) => {
    const [subData, setSubData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>();

    const refreshData = async (
        userBundleMappingId: number,
    ) => {
        setIsLoading(true);
        cookLeavesService.getCookLeavesForUserModule(userBundleMappingId).then((data) => {
            setSubData(data);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
        })
    };

    useEffect(() => {
        refreshData(props?.userBundleMappingId);
    }, [props?.userBundleMappingId]);

    return (
        <div>
            {isLoading ?
                <CircularLoader />
                :
                subData && Array.isArray(subData) && subData.length > 0 ?
                    <CookLeavesTable data={subData}/>
                    :
                    <EmptyListPlaceholder message="No cook leaves found for this user bundle." />
            }
        </div>
    )

}
export default UserBundleCookLeaves