import {
  Box,
    Button,
    Card, Chip, Dialog, DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    IconButton, InputLabel, MenuItem, Paper, Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import { ArrayField, Datagrid, DateField, FunctionField, RaRecord, ReferenceField, SimpleShowLayout, TextField, useListContext, useRefresh, useShowContext } from "react-admin";
import ChefForPartyService from "../../services/ChefForPartyService";
import CustomReferenceField from "../Common/CustomReferenceField";
import { useLocation } from 'react-router-dom';
import CallButton from "../Common/CallButton/CallButton";
import { UserType } from "../../enums/common";
import React, {useEffect, useState} from "react";
import {Close} from "@mui/icons-material";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";
import JsonViewer from "../Common/JsonViewer";
import CustomerRatingsImages from "../Common/CustomerRatingsImages";
import ImagePreviewer from "../Common/ImagePreviewer";
import ChefForPartyOrderListEdit from "./ChefForPartyOrderListEdit";
import moment from "moment";

const chefForPartyService = new ChefForPartyService();

const DishList = () => {
  const record : any= useListContext();
  
  let location = useLocation();
  let partyId = location.pathname.split('/')[2]
  const refresh = useRefresh();

    const [selectData, setSelectData] = useState<any>(null);
    const [previousDish, setPreviousDish] = useState([]);

    const [open, setOpen] = useState<boolean>(false);
    const [category, setCategory] = useState([]);
    const [selectCategory, setSelectCategory] = useState<any>(null);

    const [dishes, setDishes] = useState<any>([]);
    const [selectedDish, setSelectedDish] = useState<any>(null);

    const handleChange = (data: any) => {
        let dishes = record?.data?.filter((eachDish: any) => eachDish?.dish?.id !== data?.id).map((eachDish: any) => eachDish?.dish?.id);
        setPreviousDish(dishes);
        setSelectData(data);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setSelectData(null);
        setSelectedDish(null);
        setPreviousDish([]);
    };

    useEffect(() => {
        if (selectCategory) {
            chefForPartyService
                .getAllDishesForTypeAndCategory(selectCategory, selectData?.mealType)
                .then((data) => setDishes(data))
                .catch((err) => setDishes([]));
        }
    }, [selectCategory, selectData?.mealType]);

    useEffect(() => {
        if(!open) return;
        chefForPartyService
            .getAllCategory()
            .then((data: any) => setCategory(data))
            .catch((err) => setCategory([]));
    }, [open]);

    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Meal Type</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        {record?.data?.sort((a: any, b: any) => a.dish.mealType.localeCompare(b.dish.mealType))?.map((e: any) => (
          <TableRow>
            <TableCell>{e.dish.name}</TableCell>
            <TableCell>{e.dish.type}</TableCell>
            <TableCell>{e.dish.mealType}</TableCell>
            <TableCell>
              <Button
                variant={"contained"}
                color={"warning"}
                onClick={() => handleChange(e.dish)}
              >
                Change Dish
              </Button>
            </TableCell>
          </TableRow>
        ))}
          <Dialog
              onClose={handleClose}
              open={open}
              scroll={"paper"}
              maxWidth="xl"
          >
              <DialogTitle>Edit Dish</DialogTitle>
              <DialogContent>
                  <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
                  <FormControl style={{ width: "180px", marginBottom: "20px" }}>
                      <InputLabel id="type">{"Cuisine"}</InputLabel>
                      <Select
                          labelId="Cuisine"
                          id="Cuisine"
                          label="Cuisine"
                          defaultValue={''}
                          onChange={(event: any) => {
                              setSelectCategory(event.target.value);
                              setSelectedDish(null);
                          }}
                      >
                          {category?.map((d: any) => (
                              <MenuItem key={d.id} value={d.id}>
                                  {d.name}
                              </MenuItem>
                          ))}
                      </Select>
                  </FormControl>
                  <FormControl style={{ width: "180px", marginBottom: "20px", marginLeft: '20px' }}>
                      <InputLabel id="Dish_Name">{"Dish Name"}</InputLabel>
                      <Select
                          labelId="dishes"
                          id="dishes"
                          label="Dish Name"
                          defaultValue={''}
                          onChange={(event: any) => {
                              setSelectedDish(event.target.value);
                          }}
                      >
                          {dishes?.map((d: any) => (
                              <MenuItem key={d.id} value={d}>
                                  {d.name}
                              </MenuItem>
                          ))}
                      </Select>
                  </FormControl>
              </DialogContent>
              <DialogActions style={{ marginTop: "12px" }}>
                  <Button
                      variant="contained"
                      onClick={() => {
                          if (!selectedDish) {
                              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                  message: "Select a dish to continue",
                                  type: CustomSnackbarTypes.WARNING,
                              });
                              return;
                          }
                          chefForPartyService
                              .addDIshChefForParty(Number(partyId), { dishIds: [...previousDish, selectedDish?.id] })
                              .then((res) => {
                                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                      message: "Added Successfully",
                                      type: CustomSnackbarTypes.SUCCESS,
                                  });
                                  refresh();
                                  handleClose();
                              });
                      }}
                      size={"large"}
                  >
                      Submit
                  </Button>
                  <Button
                      variant="contained"
                      color={"error"}
                      onClick={handleClose}
                      size={"large"}
                  >
                      Cancel
                  </Button>
              </DialogActions>
          </Dialog>
      </>
    );
}

const CountList = () => {
    const { record } = useShowContext();
    let bartender = 0;
    let waiter = 0;
    record?.orders?.map((eachOrder: any) => {
        if(eachOrder?.orderItems?.length !== 0){
            eachOrder?.orderItems.map((eachOrderItem: any) => {
                if(eachOrderItem?.serviceAddons?.id === 1){
                    bartender += Number(eachOrderItem?.quantity);
                }
                if(eachOrderItem?.serviceAddons?.id === 2){
                    waiter += Number(eachOrderItem?.quantity);
                }
                return eachOrderItem;
            })
        }
        return eachOrder;
    })
  const data = [
      {name: 'No of adults', value: record?.noOfAdults},
      {name: 'No of children', value: record?.noOfChildren},
      {name: 'No of bartender', value: bartender},
      {name: 'No of waiter', value: waiter},
      {name: 'No of starters', value: record?.noOfStarters},
      {name: 'No of main-course', value: record?.noOfMainCourse},
      {name: 'No of desserts', value: record?.noOfDesserts},
      {name: 'No of side dishes', value: record?.noOfSideDishes},
  ];
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell>Entity</TableCell>
            <TableCell>Count</TableCell>
          </TableRow>
        </TableHead>
          {data?.map((each) => (
              <TableRow key={each.name}>
                  <TableCell>{each.name}</TableCell>
                  <TableCell>{each?.value}</TableCell>
              </TableRow>
          ))}
          <TableRow>
              <TableCell sx={{fontWeight: 600}}>{'No of Dishes'}</TableCell>
              <TableCell sx={{fontWeight: 600}}>{record?.noOfDishes}</TableCell>
          </TableRow>
      </>
    );
}

const ChefForPartyMetaShow = () => {
    const { record } = useShowContext();


    const copyAllDishesToClipboard = () => {
      const allDishesInfo = record?.dishes.sort((a: any, b: any) => a.dish.mealType.localeCompare(b.dish.mealType))?.map((item:any) => 
          `Name: ${item.dish.name}, Type: ${item.dish.type}, Meal Type: ${item.dish.mealType}`
      ).join('\n');

      navigator.clipboard.writeText(allDishesInfo)
          .then(() => {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "All dish information copied to clipboard!",
            type: CustomSnackbarTypes.SUCCESS,
          });
          })
          .catch(err => {
              console.error('Failed to copy text: ', err);
          });
  };
    
    
    return (
      <div>
        <Stack gap={1} direction={"row"}>
          <SimpleShowLayout>
            <SimpleShowLayout record={record?.house}>
              <CustomReferenceField
                label="House Id"
                listName={"Houses"}
                source={"id"}
              />
              <FunctionField
                label="Address"
                render={(record: {
                  address: any; cityName: any; localityName: any; areaName: any;
                }) =>
                    [record?.address, record?.localityName, record?.areaName, record?.cityName]?.filter((each) => each !== null).join(', ')
                }
              />
              <ReferenceField source="cityId" link="show" reference="Cities">
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField source="areaId" link="show" reference="Areas">
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField source="localityId" link="show" reference="Localities">
                <TextField source="name" />
              </ReferenceField>
              {/* <TextField label="Address" source="address" /> */}
              {/* <TextField label="Locality" source="locality" /> */}
              {/* <NumberField label="City" source="city" /> */}
                <CallButton
                heading={'Customer phone number'}
                toPhoneNumber={record?.customerPhone}
                userType={UserType.CUSTOMER}
            />
            </SimpleShowLayout>
          <SimpleShowLayout>
              <TextField source="totalAmount" />
              <TextField source="amountPaid" />
              <TextField label={'Discount'} source="totalDiscount" />
              <DateField emptyText="---" source="date" />
              <TextField source="time" />
              <TextField source="tokenAmount" />
              <TextField source="tokenAmountPaymentStatus" />
              <TextField source="remainingAmountPaymentStatus" />
              <TextField source="status" />
              {record?.clientId && <FunctionField label="Source of booking" render={(record: RaRecord) => {
                  if (record?.clientId === 2) {
                    return (
                      <div>CENTAURUS</div>
                    )
                  }
                  else if (record?.clientId === 4) {
                    return (
                      <div>CUSTOMER APP</div>
                    )
                  }
                  else if (record?.clientId === 9) {
                    return (
                      <div>WEBSITE</div>
                    )
                  }
                }} />
              }
              <FunctionField
                label="Created At"
                render={(record: RaRecord) =>
                  `${record?.createdAt? moment(record?.createdAt).format('DD/MM/YYYY hh:mm A') : 'Not Available'}`
              }
          />
              <ArrayField sortable={false} label="Cook Phone Number" source="cooks">
                <Datagrid bulkActionButtons={false}>
                  <FunctionField render={(record: any) => <CallButton toPhoneNumber={record?.profile?.mobile} userType={UserType.COOK}/>}/>
                  </Datagrid>
              </ArrayField>
          </SimpleShowLayout>
          </SimpleShowLayout>
          {/*<FunctionField*/}
          {/*label="Payment Status Last Updated At"*/}
          {/*render={() => {*/}
          {/*    if (record?.paymentStatusLastUpdatedAt) {*/}
          {/*        return moment(record?.paymentStatusLastUpdatedAt).format("LLL");*/}
          {/*    }*/}
          {/*}}*/}
          {/*/>*/}
          {/*<TextField*/}
          {/*label="Razor Pay Order Id"*/}
          {/*source="razorpayOrder.razorpayOrderId"*/}
          {/*/>*/}
          {/*<FunctionField label="Pricing Regime" render={(record: RaRecord) => (record?.includeFreeTr === true ? "New Regime (Free TR Included)" : "Old Regime (No Free TR)")}/>*/}
          <SimpleShowLayout>
              <Table>
                  <CountList />
              </Table>
          </SimpleShowLayout>
          <SimpleShowLayout sx={{width:"450px"}}>
              <TextField source={"cancellationReason"} />
              <TextField source={"cancellationSubReason"} />
              <TextField source={"rescheduleReasons"} />
              <TextField source={"rescheduleSubReason"} />
            <Card
              style={{
                overflow: "auto",
                borderRadius: "4px",
                height: "12rem",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 3px",
              }}
              elevation={0}
            >

              <SimpleShowLayout>

                <ArrayField
                  emptyText="Not Available"
                  source="allocatorComments"
                >
                  <Datagrid bulkActionButtons={false}>
                    <TextField emptyText="Not Available" source="comment" />
                    <TextField
                      emptyText="Not Available"
                      source="commenterEmail"
                    />
                    <DateField
                      emptyText="Not Available"
                      source="timestamp"
                      showTime
                    />
                  </Datagrid>
                </ArrayField>
              </SimpleShowLayout>
            </Card>
            <Card
              style={{
                overflow: "auto",
                borderRadius: "4px",
                height: "12rem",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 3px",
              }}
              elevation={0}
            >
              <SimpleShowLayout>
                <ArrayField emptyText="Not Available" source="supplyComments">
                  <Datagrid bulkActionButtons={false}>
                    <TextField emptyText="Not Available" source="comment" />
                    <TextField
                      emptyText="Not Available"
                      source="commenterEmail"
                    />
                    <DateField
                      emptyText="Not Available"
                      source="timestamp"
                      showTime
                    />
                  </Datagrid>
                </ArrayField>
              </SimpleShowLayout>
            </Card>
            <Card
              style={{
                overflow: "auto",
                borderRadius: "4px",
                height: "12rem",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 3px",
              }}
              elevation={0}
            >
              <SimpleShowLayout>
                <ArrayField emptyText="Not Available" source="customerComments">
                  <Datagrid bulkActionButtons={false}>
                    <TextField emptyText="Not Available" source="comment" />
                    <TextField
                      emptyText="Not Available"
                      source="commenterEmail"
                    />
                    <DateField
                      emptyText="Not Available"
                      source="timestamp"
                      showTime
                    />
                  </Datagrid>
                </ArrayField>
              </SimpleShowLayout>
            </Card>
            {/* <Card
              style={{
                overflow: "auto",
                borderRadius: "4px",
                height: "12rem",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 3px",
              }}
              elevation={0}
            >
              <SimpleShowLayout>
                <ArrayField label="Customer Ratings" source="customerRatings">
                  <Datagrid bulkActionButtons={false}>
                    <TextField source="overallRating" />
                    <TextField source="punctualityRating" />
                    <TextField source="cleaninessRating" />
                    <TextField source="foodQualityRating" />
                    <TextField source="behaviourRating" />
                    <TextField source="comments" />
                  </Datagrid>
                </ArrayField>
              </SimpleShowLayout>
            </Card> */}
          </SimpleShowLayout>
        </Stack>
        <Stack>
        <Card
              style={{
                overflow: "auto",
                borderRadius: "4px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 3px",
              }}
              elevation={0}
            >
              <SimpleShowLayout>
                <Box display={'flex'} justifyContent={'flex-end'}>
                 <Button onClick={copyAllDishesToClipboard}>Copy</Button>
                </Box>
                <ArrayField emptyText="Not Available" source="dishes">
                  <Table>
                    <DishList />
                  </Table>
                </ArrayField>
              </SimpleShowLayout>
            </Card>
            <ChefForPartyOrderListEdit/>
        <SimpleShowLayout>
        <Box>
            <Typography fontFamily={'inherit'} fontWeight={500} my={'16px'}>Customer Ratings</Typography>
            {record?.customerRatings && record?.customerRatings?.length !== 0 &&
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Overall Ratings</TableCell>
                      <TableCell align="center">Behaviour Rating</TableCell>
                      <TableCell align="center">Cleaniness Rating</TableCell>
                      <TableCell align="center">Food Quality Rating</TableCell>
                      <TableCell align="center">Comments</TableCell>
                      <TableCell align="center">Photos</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {record?.customerRatings?.map((row: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell align="center" sx={{ fontWeight: 500 }}>
                          <Chip label={row?.overallRating}/>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            {row?.behaviourRating && <Chip sx={{mb: '4px'}} label={row?.behaviourRating} />}
                            {row?.ratingTags && row?.ratingTags["Behavior related"]?.length !== 0 &&
                            row?.ratingTags["Behavior related"]?.map((val: any, ind: number) => {
                              return (
                                <Box key={ind} display={'flex'} flexDirection={'column'} mb={'4px'}>
                                  <Chip label={val} />
                                </Box>
                              )
                            })}
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            {row?.cleaninessRating && <Chip sx={{mb: '4px'}} label={row?.cleaninessRating} />}
                            {row?.ratingTags && row?.ratingTags["Hygiene Related"]?.length !== 0 &&
                            row?.ratingTags["Hygiene Related"]?.map((val: any, ind: number) => {
                              return (
                                <Box key={ind} display={'flex'} flexDirection={'column'} mb={'4px'}>
                                  <Chip label={val} />
                                </Box>
                              )
                            })}
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            {row?.foodQualityRating && <Chip sx={{mb: '4px'}} label={row?.foodQualityRating} />}
                            {row?.ratingTags && row?.ratingTags["Food related"]?.length !== 0 &&
                            row?.ratingTags["Food related"]?.map((val: any, ind: number) => {
                              return (
                                <Box key={ind} display={'flex'} flexDirection={'column'} mb={'4px'}>
                                  <Chip label={val} />
                                </Box>
                              )
                            })}
                          </Box>
                        </TableCell>
                        <TableCell align="center">{row?.comments}</TableCell>
                        <TableCell align="center">
                          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={'5px'}>
                            {row?.photos && row?.photos?.length !== 0 &&
                            row?.photos?.map((photo: any, ind: number) => (
                              <Box sx={{cursor: 'pointer'}}>
                                <ImagePreviewer key={ind} value={photo} width={"70px"} height={"70px"}/>
                              </Box>
                            ))}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
        </Box>
      </SimpleShowLayout>
        <CustomerRatingsImages service="CHEF_FOR_PARTY" />
        </Stack>
        <JsonViewer data={record} />
      </div>
    );
}

export default ChefForPartyMetaShow;
