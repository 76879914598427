import * as React from "react";
import {
  Button,
  Card,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useAppSelector } from "../../store/hooks";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import EventBus from "../../EventBus";
import AdminUserService from "../../services/AdminUser";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const adminUserService = new AdminUserService();
interface Password {
  show: boolean;
  value: string;
}
export const ChangePassword = () => {
  const user: any = useAppSelector((state) => state.userDetails.value);

  const [oldPassword, setOldPassword] = React.useState<Password>({
    show: false,
    value: "",
  });
  const [newPassword, setNewPassword] = React.useState<Password>({
    show: false,
    value: "",
  });
  const [confirmNewPassword, setConfirmNewPassword] = React.useState<Password>({
    show: false,
    value: "",
  });

  const handleClickShowPassword = (setFunction: any) => {
    setFunction((prev: Password) => ({ ...prev, show: !prev.show }));
  };

  const handleChangePassword = (data: any, setFunction: any) => {
    setFunction((prev: Password) => ({ ...prev, value: data }));
  };

  const handleClose = () => {
    const initialSet: Password = { show: false, value: "" };
    setOldPassword(initialSet);
    setConfirmNewPassword(initialSet);
    setNewPassword(initialSet);
  };

  return (
    <Card
      style={{
        flex:"1",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container
        style={{
          maxWidth: "450px",
          width:"85%",
          padding: "50px 70px",
          boxShadow: "10px 16px 30px 10px lightblue, 10px 16px 30px 10px lightblue",
        }}
      >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "400px",
            }}
          >
            <FormControl fullWidth style={{ marginTop: "30px" }}>
              <InputLabel htmlFor="old-password">Old Password</InputLabel>
              <OutlinedInput
                id="old-password"
                type={oldPassword.show ? "text" : "password"}
                value={oldPassword.value}
                onChange={(e) =>
                  handleChangePassword(e.target.value, setOldPassword)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword(setOldPassword)}
                      edge="end"
                    >
                      {oldPassword.show ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <FormControl fullWidth style={{ marginTop: "30px" }}>
              <InputLabel htmlFor="new-password">New Password</InputLabel>
              <OutlinedInput
                id="new-password"
                type={newPassword.show ? "text" : "password"}
                value={newPassword.value}
                onChange={(e) =>
                  handleChangePassword(e.target.value, setNewPassword)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword(setNewPassword)}
                      edge="end"
                    >
                      {newPassword.show ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <FormControl fullWidth style={{ marginTop: "30px" }}>
              <InputLabel htmlFor="confirm-new-password">
                Confirm New Password
              </InputLabel>
              <OutlinedInput
                id="confirm-new-password"
                type={confirmNewPassword.show ? "text" : "password"}
                value={confirmNewPassword.value}
                onChange={(e) =>
                  handleChangePassword(e.target.value, setConfirmNewPassword)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleClickShowPassword(setConfirmNewPassword)
                      }
                      edge="end"
                    >
                      {confirmNewPassword.show ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              sx={{ maxWidth: "300px", width:"70%" }}
              onClick={() => {
                if (!newPassword.value || !oldPassword.value || !confirmNewPassword.value) {
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Fill the details to proceed",
                    type: CustomSnackbarTypes.WARNING,
                  });
                  return;
                }
                if (newPassword.value !== confirmNewPassword.value) {
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "New Password And Confirm Password Not Match",
                    type: CustomSnackbarTypes.WARNING,
                  });
                  return;
                }
                adminUserService
                  .changePassword(Number(user.adminUser.id), {
                    newPassword: newPassword.value,
                    oldPassword: oldPassword.value,
                    confirmNewPassword: confirmNewPassword.value,
                  })
                  .then((e) => handleClose());
              }}
            >
              Submit
            </Button>
          </div>
      </Container>
    </Card>
  );
};
