import {
    Box,
    Button,
    Dialog,
    Divider,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";
import moment from "moment";
import React from "react";
import {UserBundlesResponse} from "../../interfaces/UserBundleMapping/UserBundlesResponse";
import {SubscriptionResponse} from "../../interfaces/UserBundleMapping/SubscriptionForBundle";

type InputProps = {
    open: boolean,
    onClose: () => void,
    handleSubmit: (selectedOption: string) => void,
    bundleDetails: UserBundlesResponse,
    subscriptionDetails?: SubscriptionResponse,
    refundDetail?: any;
}
const TrialCancellationOptionsDialog = (props: InputProps) => {
    const [selectedOption, setSelectedOption] = React.useState<string>('');

    const handleOptionSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event?.target?.value)
    }
    return (
        <Dialog
            open={props?.open}
            onClose={props?.onClose}
            sx={{
                '& .MuiPaper-root': {
                    py: '20px',
                    px: '20px',
                    minWidth: '450px',
                    maxWidth: '650px',
                },
            }}
        >
            {/*End Dates */}
            <Box display={'flex'} gap={'50px'} mb={'10px'}>
                {/*Bundle End Date */}
                <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                    <Typography fontWeight={500}>Bundle End Date: </Typography>
                    <Typography fontWeight={400}>{moment(props?.bundleDetails?.endDate).format('DD MMM YYYY')}</Typography>
                </Box>
                {/*Subscription End Date*/}
                <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                    <Typography fontWeight={500}>Subscription End Date: </Typography>
                    <Typography fontWeight={400}>{moment(props?.subscriptionDetails?.endDate).format('DD MMM YYYY')}</Typography>
                </Box>
            </Box>

            <Divider />

            {/*Options */}
            <Box mt={'20px'}>
                <Typography fontWeight={500}>Please select the option, which customer wants after cancellation</Typography>
                <Box pl={'10px'}>
                    <FormControl>
                        <RadioGroup
                            value={selectedOption}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOptionSelected(event)}
                        >
                            <FormControlLabel value={'bookNewTrial'} control={<Radio />} label={'Book New Trial'} />
                            <Box>
                                <FormControlLabel value={'cancelSubscription'} control={<Radio />} label={'Cancel Subscription'} />
                                {selectedOption === 'cancelSubscription' &&
                                    <Typography
                                        color={'#085b08'}
                                        fontWeight={500}
                                        ml={'45px'}
                                        mt={'-5px'}
                                        pl={'-20px'}
                                    >
                                        Refund Amount: INR {props?.refundDetail?.refund}
                                    </Typography>}
                            </Box>
                            {props?.bundleDetails?.cookSubscription?.length !== 0 && ( <FormControlLabel value={'wantsOldCookBack'} control={<Radio />} label={'Customer wants their old Cook back'} /> )}
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Box>

            {/*Submit Button */}
            <Box display={'flex'} justifyContent={'center'} mt={'20px'}>
                <Button
                    sx={{
                        width: '100px',
                        textTransform: 'none',
                        fontSize: '16px',
                        letterSpacing: '1px',
                    }}
                    variant={'contained'}
                    color={'primary'}
                    disabled = {!selectedOption}
                    onClick={() => {
                        console.log(selectedOption);
                        props?.handleSubmit(selectedOption)
                    }}
                >
                    Submit
                </Button>
            </Box>
        </Dialog>
    )
}

export default TrialCancellationOptionsDialog;