import {Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {
  Datagrid,
  DateField,
  DateInput,
  List,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
  useUnselectAll,
  useListController,
  useRecordContext,
  useRefresh,
  FunctionField, TopToolbar, CreateButton,
  Button, useListContext
} from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { VendorPayoutTransactionStatus } from "../../enums/vendorPayoutTransactionStatus";
import EventBus from "../../EventBus";
import { VendorPayoutResponse } from "../../interfaces/VendorPayout/VendorPayoutResponse";
import VendorPayoutService from "../../services/VendorPayoutService";
import CustomReferenceField from "../Common/CustomReferenceField";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import { PayoutTypeDailog } from "../Common/payoutTypeDialog";
import RejectPayoutReasonDialog from "../Common/RejectPayoutReasonDialog";
import TransactionButton from "../Common/TransactionButton";
import FilterListOffIcon from '@mui/icons-material/FilterListOff';

const vendorPayoutService = new VendorPayoutService();

interface ActionButtonsInputProps {
  setPayoutTypeDailog: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<
    React.SetStateAction<VendorPayoutResponse | null>
  >;
  recordType?: string;
  isBulkAction: boolean;
  setBulkAction: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProcessPayout = (props: ActionButtonsInputProps) => {
  const record = useRecordContext();

  const handleClick = () => {
    props?.setBulkAction(props?.isBulkAction);
    props.setPayoutTypeDailog(true);
    props.setSelectedData(record as VendorPayoutResponse);
  };

  return (
    <TransactionButton onClickHandler={handleClick} name={"Process Payout"} />
  );
};

const ApprovePayout: React.FC<{ isBulkAction: boolean }> = ({
  isBulkAction,
}) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const { selectedIds } = useListController();
  const unselectAll = useUnselectAll("VendorPayouts");
  const bulkApproveHandler = async() => {
    if (selectedIds) {
      try {
        const payload = {
          transactionId: selectedIds,
          comments: '',
        }
        const response = await vendorPayoutService.bulkApprovePayout(payload);
        if (response) {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: response?.status,
            type: CustomSnackbarTypes.SUCCESS,
          });
          refresh();
        }
      } catch (error) {
      }
    }
    unselectAll();
  }
  // const bulkApproveHandler = () => {
  //   selectedIds.forEach(async (vendorPayoutID) => {
  //     try {
  //       const response = await vendorPayoutService.approvePayout(
  //         Number(vendorPayoutID)
  //       );
  //       EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
  //         message: response.status,
  //         type: CustomSnackbarTypes.SUCCESS,
  //       });
  //       refresh();
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   });
  //   unselectAll();
  // };

  const handleClick = async () => {
    try {
      const response = await vendorPayoutService.approvePayout(
        Number(record?.id)
      );
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: response.status,
        type: CustomSnackbarTypes.SUCCESS,
      });
      refresh();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <TransactionButton
      onClickHandler={isBulkAction ? bulkApproveHandler : handleClick}
      color="success"
      name={"Approve Payout"}
    />
  );
};

const RejectPayout: React.FC<{ isBulkAction: boolean }> = ({
  isBulkAction,
}) => {
  const [open, setOpen] = useState(false);
  const { selectedIds } = useListController();
  return (
    <>
      <RejectPayoutReasonDialog
        open={open}
        setOpen={setOpen}
        isBulkAction={isBulkAction}
        selectedVendorPayoutIds={selectedIds}
      />
      <TransactionButton
        onClickHandler={() => setOpen(true)}
        color="error"
        name={"Reject Payout"}
      />
    </>
  );
};

const RendorCTAButtons: React.FC<ActionButtonsInputProps> = ({
  setPayoutTypeDailog,
  setSelectedData,
  recordType,
  isBulkAction = false,
  setBulkAction,
}) => {
  const record = useRecordContext();
  return (
    <Stack direction={"row"} gap={2}>
      {[VendorPayoutTransactionStatus.PENDING].includes(
        record?.status ?? recordType
      ) && (
        <>
          <ApprovePayout isBulkAction={isBulkAction} />
          <RejectPayout isBulkAction={isBulkAction} />
        </>
      )}
      {[
        VendorPayoutTransactionStatus.APPROVED,
        VendorPayoutTransactionStatus.PROCESSING_FAILED,
      ].includes(record?.status ?? recordType) && (
        <ProcessPayout
          setPayoutTypeDailog={setPayoutTypeDailog}
          setSelectedData={setSelectedData}
          isBulkAction={isBulkAction}
          setBulkAction={setBulkAction}
        />
      )}
    </Stack>
  );
};

const ListActions = (props: any) => {
  const {setFilters} = useListContext();
  // const list = useListContext();
  // console.log('list::', list)

  const resetFilter = () => {
    setFilters({}, [], false);
  };

  return (
      <TopToolbar>
        <Button onClick={resetFilter} label="Clear Filters">
          <FilterListOffIcon />
        </Button>

        <CreateButton />
      </TopToolbar>
  )
};

export const VendorPayoutList = (props: any) => {

  const {filterValues} = useListContext();
  console.log('filterValues',filterValues)
  const [eventList, setEventList] = useState<any>([]);
  const [productList, setProductList] = useState<any>([]);
  const [typeList, setTypeList] = useState<any>([]);
  const [payoutTypeDailog, setPayoutTypeDailog] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<VendorPayoutResponse | null>(
    null
  );

  const payoutFilters = [
    <TextInput label="Cook Id" source="cookId" alwaysOn />,
    <SelectInput
        label="Payout status"
        source="status"
        alwaysOn
        emptyText="Clear Filter"
        choices={Object.values(VendorPayoutTransactionStatus).map((key) => {
          return {
            id: key,
            name: key,
          };
        })}
    />,
    <SelectInput
        label="Payout Type"
        source="type"
        alwaysOn
        emptyText="Clear Filter"
        choices={typeList?.map((key: string) => {
          return {
            id: key,
            name: key,
          };
        })}
    />,
    <SelectInput
        label="Payout Event"
        source="event"
        alwaysOn
        emptyText="Clear Filter"
        choices={eventList?.map((key: string) => {
          return {
            id: key,
            name: key,
          };
        })}
    />,
    <SelectInput
        label="Payout Product"
        source="product"
        alwaysOn
        emptyText="Clear Filter"
        choices={productList?.map((key: string) => {
          return {
            id: key,
            name: key,
          };
        })}
    />,
    <DateInput label="From Created At Date" source="startDates" alwaysOn />,
    <DateInput label="To Created At Date" source="endDates" alwaysOn />,
  ];

  const [bulkAction, setBulkAction] = useState<boolean>(false);
  const { selectedIds, data } = useListController();
  function checkActionType() {
    let selectedPayouts = data?.filter((row) => selectedIds.includes(row.id));
    let statusType = selectedPayouts?.[0]?.status ?? "";
    if (statusType === "") return <></>;
    if (
      selectedPayouts?.filter((row) => row.status === statusType)?.length ===
      selectedPayouts?.length
    ) {
      return (
        <RendorCTAButtons
          setPayoutTypeDailog={setPayoutTypeDailog}
          setSelectedData={setSelectedData}
          recordType={statusType}
          isBulkAction={true}
          setBulkAction={setBulkAction}
        />
      );
    }
    return <></>;
  }

  useEffect(() => {
    vendorPayoutService.getPayoutEvents().then(async (res) => {
      const typesSet = new Set();
      const eventsSet = new Set();
      const productsSet = new Set();
      await res?.forEach((each: any) => {
        typesSet.add(each?.type);
        eventsSet.add(each?.event);
        productsSet.add(each?.product);
      });
      setEventList(Array.from(eventsSet));
      setProductList(Array.from(productsSet));
      setTypeList(Array.from(typesSet));
    })
  }, [])
  return (
    <>
      <PayoutTypeDailog
        selectedVendorPayoutIds={selectedIds}
        data={selectedData}
        open={payoutTypeDailog}
        isBulkAction={bulkAction}
        callback={() => {
          setPayoutTypeDailog(false);
        }}
      />
      <List
          filters={payoutFilters}
          actions={<ListActions {...props} />}
      >
        <Datagrid
          rowClick="edit"
          sx={selectedIds?.length !== 0 ? { mt: "3rem" } : {}}
          bulkActionButtons={checkActionType()}
        >
          <TextField source="id" />
          <CustomReferenceField listName={"Cooks"} source={"cookId"} />
          <FunctionField label="Cook Name" render={(record: any) => (
            <div>{`${record?.cook?.cookFullProfile?.firstName} ${record?.cook?.cookFullProfile?.lastName}`}</div>
          )} />
          {/* <TextField label="Cook Name" source="cook.cookFullProfile.firstName" sortable={false} /> */}
          {/*<TextField source="type" sortable={false} />*/}
          <TextField source="amount" />
          <TextField label="Description" source="comments" />
          <FunctionField
              label="Event"
              render={(record: any) => (
                  <div>{`${record?.vendorPayoutEvent?.event || ''}`}</div>
              )}
          />
          <FunctionField
              label="Product"
              render={(record: any) => (
                  <div>{`${record?.vendorPayoutEvent?.product || ''}`}</div>
              )}
          />
          <FunctionField
              label="Type"
              render={(record: any) => (
                  <div>{`${record?.vendorPayoutEvent?.type || ''}`}</div>
              )}
          />
          <TextField source="status" sortable={false} />
          <DateField label="last Update" source="updatedAt" />
          <RendorCTAButtons
            setPayoutTypeDailog={setPayoutTypeDailog}
            setSelectedData={setSelectedData}
            isBulkAction={false}
            setBulkAction={setBulkAction}
          />
          <ShowButton />
        </Datagrid>
      </List>
    </>
  );
};
