import React, { useCallback, useEffect, useState } from "react";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import DeniedCookInterface from "../../interfaces/Cook/DeniedCookInterface";
import { OneMealService } from "../../services/OneMealService";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import DeniedCooks from "../Common/DeniedCooks";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";

const omsService = new OneMealService();

interface InputProps {
  serviceId: number;
}

const OmsDeniedCookList = (props: InputProps) => {
  const [deniedCooksInOms, setDeniedCookSInOms] = useState<
    DeniedCookInterface[]
  >([]);

  const revertDeniedCook = (trialId: number, cookId: number) => {
    omsService
      .revertDeniedCook(trialId, cookId)
      .then((res) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Cook Revereted Successfully",
          type: CustomSnackbarTypes.SUCCESS,
        });
        refreshData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const refreshData = useCallback(() => {
    omsService
      .getDeniedCooksForOneMeal(props.serviceId)
      .then((res) => {
        setDeniedCookSInOms(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.serviceId]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  return (
    <div>
      {deniedCooksInOms.length === 0 ? (
        <EmptyListPlaceholder message="No Cooks Found" />
      ) : (
        <DeniedCooks
          cookData={deniedCooksInOms}
          callBack={revertDeniedCook}
          id={props.serviceId}
          refresh={refreshData}
        />
      )}
    </div>
  );
};

export default OmsDeniedCookList;
