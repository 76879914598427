import { Box, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import React from 'react'
import { PartnerLineChart } from '../PartnerSlotting/PartnerLineChart';
import CkMuiDialog from './CkMuiDialog/CkMuiDialog';

interface InputProps {
    showSlots: boolean;
    setShowSlots: (showSlots: boolean) => void;
    data: any[]
}

const ShowSlotsDialog = ({showSlots, setShowSlots, data}: InputProps) => {
  return (
    <CkMuiDialog
    maxWidth={"lg"}
    isDialogOpen={showSlots}
    setDialogOpen={setShowSlots}
    onDialogClose={() => {
      setShowSlots(false);
    }}
    title={""}
    secondaryLabel={"Close"}
    secondaryAction={() => {
      setShowSlots(false);
    }}
    isLoading={false}
  >
    <Box m={2}>
      <PartnerLineChart data={data} id={"cook-slot"} />
      <Box mt={10} className="cook-slot-footer">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  backgroundColor: "red",
                  height: "5px",
                  width: "50px",
                  padding: "0px",
                }}
              ></TableCell>
              <TableCell
                style={{
                  backgroundColor: "yellow",
                  height: "5px",
                  width: "50px",
                  padding: "0px",
                }}
              ></TableCell>
              <TableCell
                style={{
                  backgroundColor: "green",
                  height: "5px",
                  width: "50px",
                  padding: "0px",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Blocked</TableCell>
              <TableCell>CK_Blocked</TableCell>
              <TableCell>Free</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  </CkMuiDialog>
  )
}

export default ShowSlotsDialog