import React from 'react';
import {Button, Link} from "@mui/material";

interface InputProps {
    message: string;
    link?:string
}

const EmptyListPlaceholder = (props: InputProps) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <h4 style={{textAlign: 'center'}}>{props.message}</h4>
            {
                props.link &&
                <Link href={props.link}>
                  <Button variant={'contained'} color={'primary'} >
                    Create
                  </Button>
                </Link>
            }
        </div>
    );
};

export default EmptyListPlaceholder;
