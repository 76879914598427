export enum PayoutTypes {
    BANK_DETAILS = "BANK DETAILS",
    VPA = "VPA"
}

export enum PayoutUserType {
    CUSTOMER = "CUSTOMER",
    COOK = "COOK",
    VENDOR = "VENDOR",
    ADMIN = "ADMIN"
}
