import {Close} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import moment from "moment";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "./CustomSnackBar";
import {CookService} from "../../services/CookServices";
import {PartnerTimeSlotStatusEnums} from "../../enums/partnerTimeSlotStatusEnums";
import {DatePicker} from "@mui/x-date-pickers";

interface InputProps {
  data: any;
  open: boolean;
  callback: () => void;
}

const cookService = new CookService();

export const ShowCookSlotsDialog = (props: InputProps) => {
  let [slots, setSlots] = React.useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  let [startDate, setStartDate] = React.useState<Date | null>(new Date())

  const [commentDialog, setCommentDialog] = useState(false);
  const [selectedCook,setSelectedCook] = useState<any>(null);
  const [comment,setComment] = useState<string>('');

  const getCookSlots = useCallback(() => {
    setLoading(true);
    cookService.getCookSlots({
      cookId: props?.data?.cookId,
      date: moment(startDate).format("YYYY-MM-DD"),
    }).then((res: any) => {
      setLoading(false);
      setSlots([...res]);
    })
  }, [props?.data?.cookId, startDate]);

  useEffect(() => {
    if(!props?.data) return;
    getCookSlots();
  }, [getCookSlots, props?.data, startDate])

  let handleClose = () => {
    props.callback();
    setSlots([])
  }
  return (
      <>
      <Dialog
        onClose={handleClose}
        open={props.open}
        scroll={"paper"}
        maxWidth="xl"
      >
        <DialogTitle>{`Cook Slot Status of ${props?.data?.firstName} ${props?.data?.lastName}`}</DialogTitle>
        <DialogContent>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
          <Box>
            <FormControl fullWidth style={{ marginBottom: "20px" }}>
              <DatePicker
                  label="Select Date"
                  inputFormat="dd MMM yyyy"
                  disableMaskedInput
                  value={startDate}
                  onChange={(value) => setStartDate(value)}
                  renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
            <Table size={'small'}>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Time Slot</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Leave Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {slots?.length > 0 ?
                  slots?.map((each: any)=> {
                    return(
                        <TableBody key={each?.id}>
                          <TableRow>
                            <TableCell>
                              {each?.date}
                            </TableCell>
                            <TableCell>
                              {each?.timeSlot}
                            </TableCell>
                            <TableCell>
                              {each?.status}
                            </TableCell>
                            <TableCell>
                              {each?.leaveMarked ? 'ON_LEAVE' : ''}
                            </TableCell>
                            <TableCell>
                              {each?.status === PartnerTimeSlotStatusEnums.FREE && (
                                  <Button
                                      size={'small'}
                                      color={'error'}
                                      variant={'contained'}
                                      onClick={() => {
                                        setCommentDialog(true);
                                        setSelectedCook(each);
                                      }}
                                  >
                                    {'Mark Blocked'}
                                  </Button>
                              )}
                              {each?.status === PartnerTimeSlotStatusEnums.CK_BLOCKED && (
                                  <Button
                                      size={'small'}
                                      variant={'contained'}
                                      color={'warning'}
                                      onClick={() => {
                                        setCommentDialog(true);
                                        setSelectedCook(each);
                                      }}
                                  >
                                    {'Mark Free'}
                                  </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                    )
                  })
                  : (loading ?
                          ''
                          : <caption>No cooks found</caption>
                  )}
            </Table>
          </Box>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              handleClose();
            }}
            size={"small"}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
        <Dialog open={commentDialog} onClose={() => {setCommentDialog(false)}}>
          <DialogTitle>
            {'Add Reason To Mark Leave'}
          </DialogTitle>
          <DialogContent>
            <TextField
                label={'Reason'}
                onChange={(e: any) => {setComment(e.target.value)}}
                value={comment}
                fullWidth
                placeholder={'Enter the reason'}
            />
          </DialogContent>
          <DialogActions>
            <Button variant={'contained'} onClick={() =>  {
              cookService.changeSlotStatus({
                status: selectedCook?.status === PartnerTimeSlotStatusEnums.CK_BLOCKED ?
                    PartnerTimeSlotStatusEnums.FREE :
                    PartnerTimeSlotStatusEnums.CK_BLOCKED,
                timeSlot: selectedCook?.timeSlot,
                cookId: props?.data?.cookId,
                date: selectedCook?.date,
                comment: comment,
              }).then(() => {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Successful",
                  type: CustomSnackbarTypes.SUCCESS,
                });
                setComment('');
                setCommentDialog(false);
                getCookSlots();
              })
            }}>{'Submit'}</Button>
          </DialogActions>
        </Dialog>
      </>
  );
};
