import EventEmitter from "events";
import {EventBusEventsEnum} from "./enums/EventBusEventsEnum";

export default class EventBus {
    private static emitter: EventEmitter;

    public static emitEvent(eventName: EventBusEventsEnum, payload: any) {
        if (!this.emitter) {
            this.emitter = new EventEmitter()
        }
        this.emitter.emit(eventName, payload);
    }

    public static getInstance() {
        if (!this.emitter) {
            this.emitter = new EventEmitter()
        }
        return this.emitter;
    }
}