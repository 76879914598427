import {Button, Datagrid, DateField,ExportButton,List, SelectInput, ShowButton, TextField, TextInput, TopToolbar, useListContext } from "react-admin";
import { useAppSelector } from "../../store/hooks";
import ResourceSelectDialogButton from "../Common/ResourceSelectDialogButton";
import {useNavigate} from "react-router-dom";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import PhotoLibraryRoundedIcon from '@mui/icons-material/PhotoLibraryRounded';


const ListActions = (props: any) => {
    const { setFilters } = useListContext();
    const resetFilter = () => {
      setFilters({}, [], false);
    };
    const navigate = useNavigate();
  
    return (
      <TopToolbar>
        <Button
          variant="text"
          size={"small"}
          onClick={resetFilter}
          startIcon={<FilterListOffIcon />}
          label="Clear filters"
        />
        <Button
          variant="text"
          size={"small"}
          onClick={()=>{
            navigate("/Cities/create")
          }}
          startIcon={<ControlPointIcon />}
          label="Create"
        />
        <Button
          variant="text"
          size={"small"}
          onClick={()=>{
            navigate("/banners")
          }}
          startIcon={<PhotoLibraryRoundedIcon/>}
          label="Show Banners"
        />
        <ExportButton/>
      </TopToolbar>
    );
};


export const CityList = () => {
    const isCustomSelectOpen = useAppSelector((state) => state.customSelect.value.open);

    const cityFilters = [
        <TextInput sx={{ width: "150px" }} label="City Name" source="name" alwaysOn />,
        <SelectInput
            label="Serviceable"
            source="isCurrentlyServing"
            emptyText="Clear Filter"
            alwaysOn
            sx={{ width: "180px" }}
            choices={['YES','NO'].map((key) => {
                return {
                id: key,
                name: key,
                };
            })}
        />,
    ];

    return (
      <>
        <List filters={cityFilters} actions={<ListActions/>}>
            <Datagrid bulkActionButtons={false}>
                {isCustomSelectOpen && <ResourceSelectDialogButton />}
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="isCurrentlyServing" />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
                <ShowButton />
            </Datagrid>
        </List>
        </>
    );
};
