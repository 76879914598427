import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { MetabaseDashboardService } from "../../services/MetabaseDashboardService";
import { MetabaseDasboardId } from "../../enums/MetabsaeDashboardId";
import IFrame from "./IFrame";

interface DataType {
  id: number;
  name: string;
  points: any;
  cityId: number;
  position: { lat: number; lng: number };
}
interface InputProps {
  data: DataType;
  open: boolean;
  callback: () => void;
}

const metabaseTransactionService = new MetabaseDashboardService();

const MgAllocationDailog = (props: InputProps) => {
  const [metabaseUrl, setMetabaseUrl] = useState("");

  useEffect(() => {
    metabaseTransactionService
      .getMetabaseEmbedUrl(String(MetabaseDasboardId.AREA_DETAILS), {
        area_id: props.data.id,
      })
      .then((data) => {
        setMetabaseUrl(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.data]);

  let handleClose = () => {
    props.callback();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      scroll={"paper"}
      fullWidth={true}
    >
      <DialogTitle>{`${props.data.name} Details`}</DialogTitle>
      <DialogContent>
        <Grid
          container
          columns={1}
          rowSpacing={0.9}
          style={{ padding: "12px 12px 12px 12px", height: "50vh" }}
        >
          <IFrame url={metabaseUrl} width="100%" height="100%" />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            handleClose();
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MgAllocationDailog;
