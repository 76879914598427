import {
  Show,
  Tab,
  TabbedShowLayout,
  useRecordContext,
  useRefresh,
} from "react-admin";

import LocalityMetaShow from "./LocalityMetaShow";
import LocalityService from "../../services/LocalityService";
import { Button, Stack } from "@mui/material";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import { useState } from "react";
import EditLatLongLocalityDialog from "../Common/EditLatLongLocalityDialog";
import LocalityServicesTab from "./LocalityServicesTab";
import {useAppSelector} from "../../store/hooks";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";
import {UserGroupsEnum} from "../../enums/UserGroupsEnum";
import SelectTrialAvailabilityTypes from "../Common/SelectTrialAvailabilityTypes";

const localityService = new LocalityService();

const CustomButton = (props: any) => {
    let record = useRecordContext();

    return (
      <Button
        onClick={() => {
          props.callback(record);
        }}
        size={"small"}
        color={props.color || "secondary"}
        variant={"contained"}
        style={{ width: "max-content", fontSize: "12px" }}
      >
        {props.name}
      </Button>
    );
  };


const RenderCTAButtons = (props: any) => {

    const userDetails = useAppSelector((state) => state.userDetails.value);
    let groupsArray: string[] = userDetails.adminUser.accessGroups;
  const refresh = useRefresh();
  const record = useRecordContext();

  return (
    <Stack direction={"row-reverse"} gap={2}>
      {record?.isServiceable === true && (
        <CustomButton
          name={"Mark As Non Serviceable"}
          color={"error"}
          disabled={!GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER])}
          callback={() => {
            const MarkAsNonServiceable = () => {
              localityService
                .markLocalityAsNonServicable(Number(record?.id))
                .then((res) => {
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Successful",
                    type: CustomSnackbarTypes.SUCCESS,
                  });
                  refresh();
                });
            };
            EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
              title: "Are you sure?",
              message:
                "Please confirm to mark this locality as non serviceable",
              successLabel: "Confirm",
              failureLabel: "Cancel",
              onClose: (isConfirmed: boolean) =>
                isConfirmed ? MarkAsNonServiceable() : null,
            });
          }}
        />
      )}
      {record?.isServiceable === false && (
        <CustomButton
          name={"Mark As Serviceable"}
          color={"primary"}
          disabled={!GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER])}
          callback={() => {
            const MarkAsServiceable = () => {
              localityService
                .markLocalityAsServicable(Number(record?.id))
                .then((res) => {
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Successful",
                    type: CustomSnackbarTypes.SUCCESS,
                  });
                  refresh();
                });
            };
            EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
              title: "Are you sure?",
              message:
                "Please confirm to mark this locality as non serviceable",
              successLabel: "Confirm",
              failureLabel: "Cancel",
              onClose: (isConfirmed: boolean) =>
                isConfirmed ? MarkAsServiceable() : null,
            });
          }}
        />
      )}
      {
        <CustomButton
          name={"Edit Coordinates"}
          color={"primary"}
          callback={() => {
            props.setSelectedData(record);
            props.setOpenDialog(true);
          }}
        />
      }
      <CustomButton
        name={record?.hasTrialAutoAllocation ? 'Disable trial auto-allocation' : 'Enable trial auto-allocation'}
        color={record?.hasTrialAutoAllocation ? "error" : 'warning'}
        callback={() => {
            const UpdateAutoAllocation = () => {
                localityService
                    .editLocality(Number(record?.id), {
                        hasTrialAutoAllocation: !record?.hasTrialAutoAllocation,
                    })
                    .then((res) => {
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                            message: "Successful",
                            type: CustomSnackbarTypes.SUCCESS,
                        });
                        refresh();
                    });
            };
            EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
                title: "Are you sure?",
                message:
                    `Please confirm to ${record?.hasTrialAutoAllocation ? 'disable' : 'enable'} this locality for trial auto allocation`,
                successLabel: "Confirm",
                failureLabel: "Cancel",
                onClose: (isConfirmed: boolean) =>
                    isConfirmed ? UpdateAutoAllocation() : null,
            });
        }}
      />
      <CustomButton
        name={record?.hasOmsAutoAllocation ? 'Disable oms auto-allocation' : 'Enable oms auto-allocation'}
        color={record?.hasOmsAutoAllocation ? "error" : 'warning'}
        callback={() => {
            const UpdateAutoAllocation = () => {
                localityService
                    .editLocality(Number(record?.id), {
                        hasOmsAutoAllocation: !record?.hasOmsAutoAllocation,
                    })
                    .then((res) => {
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                            message: "Successful",
                            type: CustomSnackbarTypes.SUCCESS,
                        });
                        refresh();
                    });
            };
            EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
                title: "Are you sure?",
                message:
                    `Please confirm to ${record?.hasOmsAutoAllocation ? 'disable' : 'enable'} this locality for oms auto allocation`,
                successLabel: "Confirm",
                failureLabel: "Cancel",
                onClose: (isConfirmed: boolean) =>
                    isConfirmed ? UpdateAutoAllocation() : null,
            });
        }}
      />
      <CustomButton
        name={'Set trial availability type'}
        callback={() => {
            props.setSelectedData(record);
            props.setSelectTrialType(true);
        }}
      />
    </Stack>
  );
};

const LocalityTabs = () => {
  return(
    <TabbedShowLayout>
      <Tab label={"Details"} key={"Details"}>
        <LocalityMetaShow />
      </Tab>
      <Tab label={"Services"} key={"Services"}>
        <LocalityServicesTab />
      </Tab>
    </TabbedShowLayout>
  )
};

function LocalityShow(props: any) {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectTrialType, setSelectTrialType] = useState<boolean>(false);
  let [selectedData, setSelectedData] = useState<any>(null);

  return (
    <Show>
      <div>
        <EditLatLongLocalityDialog
          open={openDialog}
          setOpen={setOpenDialog}
          data={selectedData}
        />
        <SelectTrialAvailabilityTypes
          open={selectTrialType}
          setOpen={setSelectTrialType}
          data={selectedData}
        />
        <Show {...props} actions={false}>
          <RenderCTAButtons
            setSelectedData={setSelectedData}
            setOpenDialog={setOpenDialog}
            setSelectTrialType={setSelectTrialType}
          />
          <LocalityTabs />
        </Show>
      </div>
    </Show>
  );
}

export default LocalityShow;
