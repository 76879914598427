import { GetListResult, GetOneResult } from "react-admin";
import HttpClient from "../HttpClient";
import { CouponResponse } from "../interfaces/Coupon/CouponResponse";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";

export class CouponServiceV2 {
    async getAllCouponsV2(limit: number, offset: number, filters: {}, sort: {}): Promise<GetListResult<CouponResponse>> {
        filters = ConstructSequelizeFilters(filters, ["couponCode"], ['productType', 'couponType'])
        let data = await HttpClient.get('/centaurus/v4/coupon/all', {
            params: {
                limit,
                offset,
                filters,
                sort
            }
        })
        return {
            data: data.data.data,
            total: data.data.count
        }
    }

    async createCouponV2(payload: any): Promise<GetOneResult<CouponResponse>> {
        let data : any  = await HttpClient.post(`/centaurus/v4/coupon`, payload);
        return data
    }

    async getAllCouponForHouseV2(houseId: number) {
        const data = await HttpClient.get(`/centaurus/v4/coupon/houses/${houseId}`);
        return {
            data: data.data,
            total: data.data.length,
          };
    }

    async sendCouponV2(couponId: number) {
        let response = await HttpClient.post(`/centaurus/v4/coupon/${couponId}/send`)
        return response
    }

    async getOneCupon(couponId: number){
        let data = HttpClient.get(`/centaurus/v4/coupon/${couponId}`)
        return data
    }

    async updateCoupon(couponId: number, payload: any){
        let data = HttpClient.put(`/centaurus/v4/coupon/${couponId}`, payload)
        return data
    }

    async updateAreaSet(couponId: number, payload: any) {
        let data = HttpClient.patch(`/centaurus/v4/coupon/${couponId}/area-set`, payload);
        return data;
    }
}
