import React, {useCallback, useState} from 'react';
import {CookService} from "../../services/CookServices";
import {Box, Button, Grid, Typography} from "@mui/material";
import {
    GoogleMap,
    useJsApiLoader,
    DirectionsService,
    DirectionsRenderer,
    InfoWindow,
    Marker
} from "@react-google-maps/api";
import {Link, useLocation} from "react-router-dom";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";
import moment from "moment";


const containerStyle = {
    width: '100%',
    height: '70vh'
};

const cookService = new CookService();

const CookLocationModule = () => {

    const location = useLocation();
    const cookId = location.pathname.split("/")[2];

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
    })

    const [directionsOptions, setDirectionsOptions] = useState<any>(null);
    const [data, setData] = useState<any>(null);
    const [response, setResponse] = useState<any>(null);
    const [showDirections, setShowDirections] = useState(false);
    const [showInfoWindow, setShowInfoWindow] = useState(true);

    const loadData = useCallback((cookId: number, ) => {
        cookService.getCookActivity(cookId)
            .then((data: any) => {
                setData(data);
                setDirectionsOptions({
                    origin: {
                        lat: data?.cookCurrentCoordinates?.lat,
                        lng: data?.cookCurrentCoordinates?.long,
                    },
                    destination: {
                        lat: data?.cookUpcomingVisit?.lat,
                        lng: data?.cookUpcomingVisit?.long,
                    },
                    travelMode: 'DRIVING',
                });
                if(data?.cookCurrentCoordinates?.lat && data?.cookCurrentCoordinates?.long && data?.cookUpcomingVisit?.lat && data?.cookUpcomingVisit?.long) {
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: "Latest Location Updated",
                        type: CustomSnackbarTypes.SUCCESS,
                    });
                } else {
                    if(!data?.cookCurrentCoordinates?.lat) {
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                            message: "Current Location of Cook Not Available",
                            type: CustomSnackbarTypes.ERROR,
                        });
                    } else {
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                            message: "No Data To Show",
                            type: CustomSnackbarTypes.ERROR,
                        });
                    }
                }

            })
            .catch(() => {})
    }, []);

    const handleRefresh = () => {
        setShowDirections(false);
        loadData(Number(cookId));
    };

    const onUnmount = React.useCallback(function callback() {}, [])

    const directionsCallback = useCallback((result: any, status: any) => {
        if (status === 'OK') {
            console.log(result);
            setResponse(result);
            setShowDirections(true); // Show directions once response is received
        } else {
            console.error('Directions request failed with status:', status);
        }
    }, []);

    const calculateETA = () => {
        if (response && response.routes && response.routes.length > 0) {
            const route = response.routes[0];
            if (route.legs && route.legs.length > 0) {
                const leg = route.legs[0];
                if (leg.duration && leg.duration.text) {
                    return leg.duration.text;
                }
            }
        }
        return 'ETA not available';
    };

    return (
        <div>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} my={2}>
                <Grid container spacing={2}>
                    <Grid md={4} sm={4} xs={12} item>
                        <Box>
                            <Typography fontWeight={700} fontSize={16}>
                                Cook Details
                            </Typography>
                            <Typography>Id - {cookId ? (
                                <Link to={`/Cooks/${cookId}/show`}>
                                    {cookId}
                                </Link>
                            ) : 'N/A'}</Typography>
                            <Typography>Cook Name - {data?.cookPastVisit?.cookName || data?.cookUpcomingVisit?.cookName || 'N/A'}</Typography>
                            <Typography>Cook Phone Number - {data?.cookPastVisit?.cookPhoneNumber || data?.cookUpcomingVisit?.cookPhoneNumber || 'N/A'}</Typography>
                        </Box>
                        <Box>
                            <Typography fontWeight={700} fontSize={16}>
                                Cook Current Location
                            </Typography>
                            <Typography>Updated At - {moment(data?.cookCurrentCoordinates?.createdAt, "YYYY-MM-DD HH:mm:ss").format("MMM Do YYYY, h:mm a")}</Typography>
                        </Box>
                    </Grid>
                    <Grid md={4} sm={4} xs={12} item>
                        <Box>
                            <Typography fontWeight={700} fontSize={16}>
                                Cook Past Visit
                            </Typography>
                            <Typography>Id - {data?.cookPastVisit?.id ? (
                                <Link to={`/OneMealService/${data?.cookPastVisit?.id}/show`}>
                                    {data?.cookPastVisit?.id}
                                </Link>
                            ) : 'N/A'}</Typography>
                            <Typography>Customer Name - {data?.cookPastVisit?.customerName || 'N/A'}</Typography>
                            <Typography>Customer Phone Number - {data?.cookPastVisit?.customerPhoneNumber || 'N/A'}</Typography>
                            <Typography>Date - {data?.cookPastVisit?.date || ''} {data?.cookPastVisit?.time || '---'}</Typography>
                        </Box>
                        <Box>
                            <Typography fontWeight={700} fontSize={16}>
                                Cook Upcoming Visit
                            </Typography>
                            <Typography>Id - {data?.cookUpcomingVisit?.id ? (
                                <Link to={`/OneMealService/${data?.cookUpcomingVisit?.id}/show`}>
                                    {data?.cookUpcomingVisit?.id}
                                </Link>
                            ) : 'N/A'}</Typography>
                            <Typography>Customer Name - {data?.cookUpcomingVisit?.customerName || 'N/A'}</Typography>
                            <Typography>Customer Phone Number - {data?.cookUpcomingVisit?.customerPhoneNumber || 'N/A'}</Typography>
                            <Typography>Date - {data?.cookUpcomingVisit?.date || 'N/A'} {data?.cookUpcomingVisit?.time || 'N/A'}</Typography>
                        </Box>
                    </Grid>
                    <Grid md={4} sm={4} xs={12} item>
                        <Box display={'flex'} flexDirection={'column'} justifyContent={'end'}>
                            <div>Estimated Time of Arrival: </div>
                            <Typography fontWeight={700} fontSize={16}>
                                {calculateETA()}
                            </Typography>
                            <Button variant={'contained'} sx={{mt: 2}} onClick={handleRefresh}>
                                GET CURRENT LOCATION
                            </Button>
                        </Box>
                    </Grid>
                    <Grid md={12} sm={12} xs={12} item>
                        <Box display={'flex'} justifyContent={'flex-end'}>
                            <Box display={'flex'} alignItems={'center'} mr={2}>
                                <img src={'/map_a.png'} alt={'map'} style={{width: '20px'}} />
                                <Typography sx={{ml: 1}}>
                                    {'Cook Current Location'}
                                </Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'} mr={2}>
                                <img src={'/map_b.png'} alt={'mapB'} style={{width: '20px'}} />
                                <Typography sx={{ml: 1}}>
                                    {'Cook Destination'}
                                </Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'}>
                                <img src={'/map_c.png'} alt={'mapC'} style={{width: '20px'}} />
                                <Typography sx={{ml: 1}}>
                                    {'Cook Past Location'}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {isLoaded ? (
                <div>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        // zoom={7}
                        onLoad={() => {
                            loadData(Number(cookId));
                        }}
                        onUnmount={onUnmount}
                    >
                        {data?.cookPastVisit?.lat && data?.cookPastVisit?.long && (
                            <Marker position={{
                                    lat: data?.cookPastVisit?.lat,
                                    lng: data?.cookPastVisit?.long
                                }}
                                label={'C'}
                            />
                        )}
                        {!showDirections && directionsOptions?.origin?.lat && directionsOptions?.destination?.lat && (
                            <DirectionsService
                                // @ts-ignore
                                options={directionsOptions}
                                callback={directionsCallback}
                            />
                        )}
                        {showDirections && directionsOptions?.origin?.lat && directionsOptions?.destination?.lat && response &&
                          <DirectionsRenderer
                            directions={response}
                          />
                        }
                        {showInfoWindow && directionsOptions?.origin?.lat && directionsOptions?.destination?.lat && (
                            <InfoWindow
                                position={directionsOptions?.destination}
                                onCloseClick={() => setShowInfoWindow(false)}
                            >
                                <div>Estimated Time of Arrival: {calculateETA()}</div>
                            </InfoWindow>
                        )}
                    </GoogleMap>
                </div>
            ) : (
                <div />
            )}
        </div>
    );
};

export default CookLocationModule;
