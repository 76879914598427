import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import VendorPayoutService from "../../services/VendorPayoutService";
import CircularLoader from "../Common/CircularLoader";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const vendorPayoutService = new VendorPayoutService();

const VendorPayoutDetails = (props: any) => {
  let creditAmount = 0;
  let debitAmount = 0;
  let subscriptionAmount = 0;
  const [transactionData, setTransactionData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>();
  const location = useLocation();
  const cookId = location.pathname.split("/")[2];
  const otherDetails = Object.fromEntries(new URLSearchParams(location.search))
  const navigate = useNavigate();

  
  const getData = useCallback(() => {
    setIsLoading(true);
    vendorPayoutService
      .getTransationsByMonth(Number(cookId), otherDetails.year, otherDetails.month)
      .then((data) => {
        setTransactionData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  },[cookId, otherDetails.year, otherDetails.month]);

  useEffect(() => {
    getData();
  }, [location.pathname, getData]);

  if (transactionData) {
    // total credit amount
    for (let x: number = 0; x < transactionData?.credit?.length; x++) {
      creditAmount += parseInt(transactionData?.credit[x]?.totalAmount);
    }
    // total debit amount
    for (let x: number = 0; x < transactionData?.debit?.length; x++) {
      debitAmount += parseInt(transactionData?.debit[x]?.totalAmount);
    }
    // total subscription amount
    if (transactionData?.isMgCook !== true) {
      for (
        let x: number = 0;
        x < transactionData?.subscriptionDetails?.length;
        x++
      ) {
        subscriptionAmount += parseInt(
          transactionData?.subscriptionDetails[x]?.totalAmount
        );
      }
    }
  }
  let houseId =  !transactionData?.isMgCook ? transactionData?.subscriptionDetails[0]?.houseId : "mgCook";

  return (
    <>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <div>
          {!transactionData?.isMgCook && (
            <div
              style={{
                fontSize: "35px",
                textAlign: "center",
                margin: "15px 0",
              }}
            >
              <strong>Total Amount:</strong> {Math.abs(subscriptionAmount)}
            </div>
          )}
          <div
            style={{ fontSize: "30px", textAlign: "center", margin: "15px 0" }}
          >
            <strong>Credit Amount:</strong> {creditAmount}
          </div>
          <Table className={"custom-table"} sx={{ maxWidth: 550 }}>
            <TableHead>
              <TableRow className={"custom-table"}>
                <TableCell sx={{ fontSize: "20px" }}>Type</TableCell>
                <TableCell sx={{ fontSize: "20px" }}>Amount</TableCell>
                <TableCell sx={{ fontSize: "20px" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionData?.credit?.map((row: any) => {
                return (
                  <TableRow key={row.type}>
                    <TableCell>{row?.type}</TableCell>
                    <TableCell>{row?.totalAmount}</TableCell>
                    <TableCell>
                    {
                    !(houseId === "mgCook" && row.type === "SUBSCRIPTION") && 
                    <Button
                        onClick={() => {
                          navigate(
                            `/vendor-payout-show-details/${cookId}/?houseId=${houseId}&type=${row.type.toLowerCase()}&year=${otherDetails.year}&month=${otherDetails.month}`
                          );
                        }}
                      >
                        Show
                      </Button>}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <div
            style={{ fontSize: "30px", textAlign: "center", margin: "15px 0" }}
          >
            <strong>Debit Amount:</strong> {debitAmount}
          </div>
          <Table className={"custom-table"} sx={{ maxWidth: 550 }}>
            <TableHead>
              <TableRow className={"custom-table"}>
                <TableCell sx={{ fontSize: "20px" }}>Type</TableCell>
                <TableCell sx={{ fontSize: "20px" }}>Amount</TableCell>
                <TableCell sx={{ fontSize: "20px" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionData?.debit?.map((row: any) => {
                return (
                  <TableRow key={row.type}>
                    <TableCell>{row?.type}</TableCell>
                    <TableCell>{row?.totalAmount}</TableCell>
                    <TableCell>
                    <Button
                      onClick={() => {
                        navigate(
                          `/vendor-payout-show-details/${cookId}/?houseId=${houseId}&type=${row.type.toLowerCase()}&year=${otherDetails.year}&month=${otherDetails.month}`
                        );
                      }}
                    >
                      Show
                    </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      )}
    </>
  );
};

export default VendorPayoutDetails;


