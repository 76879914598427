import * as React from 'react';
import { Close } from "@mui/icons-material";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { CookService } from "../../services/CookServices";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import moment from 'moment';

interface InputProps {
    setOpen: Dispatch<SetStateAction<boolean>>;
    open: boolean;
    cookId: number;
    callBack: () => void
}
const cookService = new CookService();
export const ConvertMgCookDialog = (props: InputProps) => {
    const now = new Date();
    const [startDate, setStartDate] = useState('');

    const handleSubmit = () => {
        if(startDate === ''){
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "End date must be provided",
                type: CustomSnackbarTypes.ERROR
            });
            return;
        }
        cookService
            .convertMgCookToPartTime(props.cookId, {startDate})
            .then(() => {
                props.callBack();
                handleClose();
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: 'Converted Successfully',
                    type: CustomSnackbarTypes.SUCCESS
                });
            })
    };
    const handleClose = () => {
        setStartDate('');
        props.setOpen(false);
    }
    return (
        <Dialog onClose={handleClose} open={props.open} scroll={"paper"} maxWidth="xl">
            <DialogTitle>{'Convert Mg Cook to Part-time'}</DialogTitle>
            <DialogContent>
              <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 10, right: 10 }}> <Close /> </IconButton>
              <Grid container columns={1} spacing={1} sx={{ padding: "10px", width: 300 }}>
                  <Grid sm={12} md={12} xs={12} item>
                      <TextField
                          id="end-date"
                          label={'End Date'}
                          type="date"
                          inputProps={{ min: moment(new Date(now.getFullYear(), now.getMonth() - 1)).format('YYYY-MM-DD') }}
                          fullWidth
                          variant={'outlined'}
                          value={startDate}
                          onChange={(e: any)=>{setStartDate(e.target.value)}}
                          InputLabelProps={{
                              shrink: true,
                          }}
                      />
                  </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    size={"large"}>
                    Submit
                </Button>
                <Button
                    variant="contained"
                    color={"error"}
                    onClick={handleClose}
                    size={"large"} >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};
