import { Stack } from "@mui/material";
import { useState } from "react";
import { Create, SimpleForm, TextInput, NumberInput } from "react-admin";
import { useAppDispatch } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import TransactionButton from "../Common/TransactionButton";
import Customers from "../Customers/Customer";
import LocalityList from "../Locality/LocalityList";

const RESOURCES_ID = ["customerId", "localityId"];
const RESOURCES = ["Customers", "Localities"];
const RESOURCES_LIST = [Customers, LocalityList];

export const CreateHouseForm = (props: any) => {
  const [selectedResource, setSelectedResource] = useState<number>(0);
  const dispatch = useAppDispatch();

  const [cityName, setCityName] = useState();
  const [areaName, setAreaName] = useState();
  const [localityName, setLocalityName] = useState();

  const handleSelectCustomer = () => {
    dispatch(
      setCustomSelectOpen({
        open: true,
      })
    );
  };

  const handleOpen = (resource: number) => {
    setSelectedResource(resource);
    handleSelectCustomer();
  };

  const handleCloseCustomer = (record: any) => {
    if (record) {
      props.setData({
        ...props.data,
        [RESOURCES_ID[selectedResource]]: record.id,
      });

      if (selectedResource === 1) {
        props.setData({
          ...props.data,
          localityId: record.id,
          cityId: record.cityId,
          areaId: record.areaId,
        });
        setCityName(record.cityName);
        setAreaName(record.areaName);
        setLocalityName(record.name);
      }
    }
  };

  const validate = (data: any) => {
    const errors: any = {};
    if (!data.address) {
      errors.address = "Required";
    }
    if (!data.locality) {
      errors.locality = "Required";
    }
    if (!data.city) {
      errors.city = "Required";
    }
    if (!data.lat) {
      errors.lat = "Required";
    }
    if (!data.long) {
      errors.long = "Required";
    }

    return errors;
  };

  return (
    <SimpleForm validate={validate}>
      <ResourceSelectDialog
        name={RESOURCES[selectedResource]}
        list={RESOURCES_LIST[selectedResource]}
        onClose={handleCloseCustomer}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <TransactionButton
          name={
            props.data.customerId
              ? `customerId: ${props.data.customerId}`
              : "Choose Customer"
          }
          onClickHandler={() => handleOpen(0)}
        />
        <TransactionButton
          name={
            props.data.localityId
              ? `localityId: ${props.data.localityId}`
              : "Choose Locality"
          }
          onClickHandler={() => handleOpen(1)}
        />
        {props.data.localityId && (
          <Stack width={"150%"} border={"solid 1px"} p={1}>
            <Stack direction={"row"} justifyContent="space-between">
              <p>City</p>
              <p>{cityName}</p>
            </Stack>
            <Stack direction={"row"} justifyContent="space-between">
              <p>Area</p>
              <p>{areaName}</p>
            </Stack>
            <Stack direction={"row"} justifyContent="space-between">
              <p>Locality</p>
              <p>{localityName}</p>
            </Stack>
          </Stack>
        )}
      </div>

      <TextInput source="address" />
      <TextInput source="locality" />
      <TextInput source="city" />
      <NumberInput source="lat" />
      <NumberInput source="long" />
    </SimpleForm>
  );
};

const HouseCreate = (props: any) => {
  const [data, setData] = useState({});

  const transform = (d: any) => {
    let newData = {
      ...d,
      ...data,
    };

    return newData;
  };

  return (
    <Create redirect={"list"} {...props} transform={transform}>
      <CreateHouseForm data={data} setData={setData} />
    </Create>
  );
};

export default HouseCreate;
