import React, { useCallback, useEffect, useState } from "react";
import { OneMealService } from "../../services/OneMealService";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextField as TextFieldMUI,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { ShortTimeEnum } from "../../enums/ShortTimeEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { useRefresh, useShowContext } from "react-admin";
import { TrialsV3Status } from "../../enums/TrialsV3Status";
import { getReasonCategory } from "./constants/constant";
import { ActionTypeEnum } from "../../enums/ActionTypeEnum";
import ReasonSubreasonModuleEnum from "../../enums/ReasonSubreasonModuleEnum";

interface Props {
  data: any;
  open: boolean;
  callback: (data: any) => void;
}

const oneMealService = new OneMealService();

const RescheduleOneMealDialog = (props: Props) => {
  const [rescheduleReasonsOptionMapping,setRescheduleReasonsOptionMapping]=useState([]);
  const [date, setDate] = useState<Date | null>(null);
  const [time, setTime] = useState<ShortTimeEnum>();
  const [reason, setReason] = useState<string | null>("");
  const [subReason, setSubReason] = useState<string | null>("");
  const [reasonSubCategoryArray, setReasonSubCategoryArray] = useState<string[] | null>(null);
  const [otherReasonInput, setOtherReasonInput] = useState<boolean>(false);
  const refresh = useRefresh();
  const { record } = useShowContext();


  const loadData = useCallback(async () => {
    switch (record.status) {
      case TrialsV3Status.COOK_ALLOCATED:
        let sampleReasonSubReason1 = await getReasonCategory(ReasonSubreasonModuleEnum.ONE_MEAL_SERVICE,ActionTypeEnum.RESCHEDULED_POST_ALLOCATION);
        console.log(sampleReasonSubReason1)
        setRescheduleReasonsOptionMapping(sampleReasonSubReason1);
        break;
      case TrialsV3Status.PENDING:
        let sampleReasonSubReason2 = await getReasonCategory(ReasonSubreasonModuleEnum.ONE_MEAL_SERVICE,ActionTypeEnum.RESCHEDULED_PRE_ALLOCATION);
        setRescheduleReasonsOptionMapping(sampleReasonSubReason2);
        break;
      case TrialsV3Status.COOK_CONFIRMED:
        let sampleReasonSubReason3 = await getReasonCategory(ReasonSubreasonModuleEnum.ONE_MEAL_SERVICE,ActionTypeEnum.RESCHEDULED_POST_ALLOCATION);
        setRescheduleReasonsOptionMapping(sampleReasonSubReason3);
        break;
      case TrialsV3Status.ONGOING:
        let sampleReasonSubReason4 = await getReasonCategory(ReasonSubreasonModuleEnum.ONE_MEAL_SERVICE,ActionTypeEnum.RESCHEDULED_POST_ALLOCATION);
        setRescheduleReasonsOptionMapping(sampleReasonSubReason4);
        break;
    }
  }, [record?.status]);

  useEffect(()=>{
    loadData();
  },[loadData])

  let handleClose = () => {
    props.callback({ date, reason });
    setDate(null);
    setReason("");
    setOtherReasonInput(false);
  };

  const handleReasonCategoryChange = (event: any) => {
    const selectedCategory:any = rescheduleReasonsOptionMapping.find(
      (option:any) => option.category === event.target.value
    );

    if (selectedCategory) {
      setReasonSubCategoryArray(selectedCategory?.subCategory);
      setReason(event.target.value);
    }
  }

  function ShowSubCategoryOrNot(reasonCategory: string) {
    if (reasonCategory?.toLowerCase() === "others" || reasonCategory?.toLowerCase() === 'other') {
      return (
        <FormControl style={{ width: 500 }}>
          <TextField value={subReason} onChange={(e)=>setSubReason(e.target.value)} label="Write a reason" />
        </FormControl>
      )
    }
    else  {
      return (
        <Grid item>
          <FormControl fullWidth>
          <InputLabel id="select-reason">Select a Subreason</InputLabel>
          <Select
            labelId="select-reason"
            id="select-reason"
            defaultValue="Select a reason"
            value={subReason}
            onChange={(e)=>setSubReason(e.target.value)}
          >
            {reasonSubCategoryArray?.map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        </Grid>
      )
    }

  }

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      scroll={"paper"}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>Reschedule Trials</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent={"space-evenly"}
          style={{ padding: "12px 12px 12px 12px" }}
        >
          <Grid item>
            <DatePicker
              label="Select Date"
              inputFormat="dd MMM yyyy"
              disableMaskedInput
              minDate={new Date()}
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => {
                return <TextFieldMUI style={{ width: "100%" }} {...params} />;
              }}
            />
          </Grid>
          {
            <Grid item>
              <FormControl fullWidth>
                <InputLabel id="for_time">Time</InputLabel>
                <Select
                  labelId="for_time"
                  id="Time"
                  label="Time"
                  value={time}
                  onChange={(event) =>
                    setTime(event.target.value as ShortTimeEnum)
                  }
                >
                  {Object.values(ShortTimeEnum).map((e: any, i: number) => (
                    <MenuItem key={i} value={e}>
                      {e}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          }
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="for_reschedule_reason">
                Reschedule Reason
              </InputLabel>
              <Select
                labelId="for_reschedule_reason"
                id="rescheduleReason"
                label="Reschedule Reason"
                value={reason}
                onChange={(event) => {
                  handleReasonCategoryChange(event)
                    setOtherReasonInput(true);
                }}
              >
                {rescheduleReasonsOptionMapping.map((item:any) => {
                    return (
                      <MenuItem key={item.category} value={item.category}>
                        {item.category}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          {otherReasonInput && (
            ShowSubCategoryOrNot(reason!)
          )}
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginTop: "12px" }}>
        <Button
          variant="contained"
          onClick={() => {
            if (!date || !reason || !subReason) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Fill the details to proceed",
                type: CustomSnackbarTypes.WARNING,
              });
              return;
            }
            const payload = {
              date: moment(date).format("YYYY-MM-DD"),
              time: time,
              reason: reason,
              rescheduleSubReason:subReason
            };
            oneMealService
              .rescheduleOneMealService(props?.data?.id, payload)
              .then(() => {
                refresh();
                handleClose();
              });
          }}
          size={"large"}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            handleClose();
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RescheduleOneMealDialog;
