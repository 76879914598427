import {createSlice} from "@reduxjs/toolkit";

export const prMarkFailedSlice = createSlice({
    name: 'prMarkFailed',
    initialState: {
        value: {
            data: {},
        }
    },
    reducers: {
        markFailedPR: (state, action) => {
            state.value.data = action.payload;
        }
    }
})

export const {markFailedPR} = prMarkFailedSlice.actions;

export default prMarkFailedSlice.reducer;