import { Show, SimpleShowLayout, TextField, NumberField, ArrayField, Datagrid } from "react-admin";

const AdminUserShow = () => (
    <Show>
        <SimpleShowLayout>
            <NumberField source='id' />
            <TextField source='name' />
            <TextField source='email' />
            <ArrayField sortable={false} label="Admin Groups" source="adminGroups" >
                <Datagrid bulkActionButtons={false} style={{width:"400px"}}>
                    <TextField label={'Name'} source="name"/>
                    <TextField label={'Description'} source="description"/>
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);

export default AdminUserShow