import React, {useEffect, useState} from 'react';
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import PermanentReplacementService from '../../services/PermanentReplacementService';
import PermanentReplacementListForModule from '../PermanentReplacement/PermanentReplacementListForModule';
import { TablePagination } from '@mui/material';

interface InputProps {
    houseId: number
}

const permanentReplacementService = new PermanentReplacementService();

const PermanentReplacementTab = (props: InputProps) => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    let [subData, setSubData] = useState<any>(null);
    let [isLoading, setIsLoading] = useState<boolean>();

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(Number(event.target.value));
        setPage(0);
    };

    const refreshData = (houseId: number, limit: number, offset: number) => {
        setIsLoading(true)
        permanentReplacementService.getAllPermanentReplacementForHouse(houseId, limit, offset)
            .then((data) => {
                setSubData(data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        refreshData(props.houseId, rowsPerPage, page * rowsPerPage);
    }, [props.houseId, rowsPerPage, page])

    return (
        <div>
            {
                isLoading ?
                    <CircularLoader/>
                    :
                    <div>
                        {
                            subData &&
                            subData.data &&
                            Array.isArray(subData.data) &&
                            (
                                subData.data.length > 0 ?
                                    <PermanentReplacementListForModule data={subData?.data} />
                                    :
                                    <EmptyListPlaceholder
                                        message='No Permanent Replacement Found'
                                    />
                            )
                        }
                        <TablePagination
                            component="div"
                            count={subData?.total || 0}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
            }
        </div>
    );
};

export default PermanentReplacementTab;
