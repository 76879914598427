import {Box, MenuItem, TextField} from "@mui/material";
import React, {useState} from "react";
import { useRefresh } from "react-admin";
import { moduleType } from "../../enums/common";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { OneMealService } from "../../services/OneMealService";
import { TrialsService } from "../../services/TrialServices";
import CkMuiDialog from "./CkMuiDialog/CkMuiDialog";
import { CustomSnackbarTypes } from "./CustomSnackBar";

const trialService = new TrialsService();
const omsService = new OneMealService();

interface InputProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  data: any;
  requestFrom: moduleType;
}

const CookDenyDialog = ({ open, setOpen, data, requestFrom }: InputProps) => {
  const refresh = useRefresh();

  const [option, setOption] = useState<string>('');
  const [text, setText] = useState<string>('');
  const handleDeny = () => {
    if(!option) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Select a reason to continue",
        type: CustomSnackbarTypes.ERROR,
      });
      return;
    }
    if(option === 'Others') {
      if(text === ''){
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Enter a reason to continue",
          type: CustomSnackbarTypes.ERROR,
        });
        return;
      }
    }
    if (requestFrom === moduleType.TRIALS) {
      trialService
        .denyCook(data.id, {reason: option === 'Others' ? `${option} - ${text}` : option})
        .then((res) => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Cook Denied Successfully",
            type: CustomSnackbarTypes.SUCCESS,
          });
        })
        .catch((err) => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Cook Denied Failed",
            type: CustomSnackbarTypes.SUCCESS,
          });
        })
        .finally(() => {
          setOpen(false);
          refresh();
        });
    } else {
      omsService
        .denyCook(data.id, {reason: option === 'Others' ? `${option} - ${text}` : option})
        .then(() => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Cook Denied Successfully",
            type: CustomSnackbarTypes.SUCCESS,
          });
        })
        .catch(() => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Cook Denied Failed",
            type: CustomSnackbarTypes.ERROR,
          });
        })
        .finally(() => {
          setOpen(false);
          refresh();
        });
    }
  };
  return (
    <CkMuiDialog
      isDialogOpen={open}
      setDialogOpen={setOpen}
      onDialogClose={() => {}}
      title={"Cook Deny"}
      primaryLabel={"Yes"}
      primaryAction={handleDeny}
      secondaryLabel={"No"}
      secondaryAction={() => {
        setOpen(false);
      }}
      isLoading={false}
    >
      <Box>
        <p>The cook you are denying for will be penalised, if it is not cooks fault kindly use change cook button to change cook on the visit</p>

        <TextField
          id="select-reason"
          select
          fullWidth
          label="Select Deny Reason"
          onChange={(event) => {
            setOption(event.target.value);
          }}
        >
          <MenuItem value={'Current job delayed'}>
            {'Current job delayed'}
          </MenuItem>
          <MenuItem value={'I am on leave'}>
            {'I am on leave'}
          </MenuItem>
          <MenuItem value={'Need a break'}>
            {'Need a break'}
          </MenuItem>
          <MenuItem value={'Long distance job'}>
            {'Long distance job'}
          </MenuItem>
          <MenuItem value={'Have an emergency'}>
            {'Have an emergency'}
          </MenuItem>
          <MenuItem value={'Others'}>
            {'Others'}
          </MenuItem>
        </TextField>
        {option === 'Others' && (
            <TextField
                value={text}
                fullWidth
                label={'Enter the reason'}
                onChange={(event) => {
                  setText(event.target.value);
                }}
            />
        )}
      </Box>
    </CkMuiDialog>
  );
};

export default CookDenyDialog;
