import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  DialogActions,
  Button,
} from "@mui/material";
import { useState } from "react";
import { LocalityType } from "../../enums/LocalityEnum";

interface Props {
  open: boolean;
  onClose: () => void;
  data: any;
  cook: any;
  submit: Function;
}
const LocalityTypeDialog = (props: Props) => {
  const [type, setType] = useState("PRIMARY");
  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value);
  };
  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
      scroll={"paper"}
      maxWidth="xl"
    >
      <DialogTitle>Select locality type</DialogTitle>
      <DialogContent>
        <p>Locality: {props.data.name}</p>
        <div>
          <FormControl size="small" fullWidth>
            <InputLabel id="select-dialog">Locality Type</InputLabel>
            <Select
              labelId="select label"
              id="select-locality"
              value={type}
              label="Age"
              onChange={handleChange}
              disabled={true}
            >
              {/*{["PRIMARY", "SECONDARY", "RESIDENCE"].map((row, index) => (*/}
              {["PRIMARY"].map((row, index) => (
                <MenuItem value={row} key={String(index)}>
                  {row}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => props.submit(type as LocalityType)}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LocalityTypeDialog;
