
interface  IFrameProps {
    width: string,
    height: string,
    url: string
}

const IFrame = (props: IFrameProps) => {
    const {width, height, url} = props
    return  <iframe
    title="iframe"
    src={url}
    width={width}
    height={height} />
}

export default IFrame