import { GetListResult, GetOneResult } from "react-admin";
import HttpClient from "../HttpClient";
import { HouseResponse } from "../interfaces/House/HouseResponse";
import CleanFilters from "../utils/CleanFilters";

class HouseService {
    async getManyHouse(ids: Array<number>): Promise<GetListResult<HouseResponse>> {
        const data = []

        for(const id of ids){
            const res = await HttpClient.get(`/centaurus/houses/${id}`);
            data.push(res.data)
        }

        return {
            data: data,
            total: data.length
        }
    }

    async fetchAllHouse(limit: number, offset: number, filters: any, sort: {}): Promise<GetListResult<HouseResponse>> {
        let customFilters = CleanFilters(filters, ['mobile', 'cityId']);
        if (!parseInt((filters as any)?.mobile)) {
            (customFilters as any).mobile = '';
        };
        let data = await HttpClient.get('/centaurus/houses', {
            params: {
                limit,
                offset,
                filters: customFilters,
                sort
            }
        })
        return {
            data: !!data?.data?.data ? data.data.data : [],
            total: !!data?.data?.count ? data.data.count : 0
        };
    }

    async getHouseById(id: number): Promise<GetOneResult<HouseResponse>> {
        const res = await HttpClient.get(`/centaurus/houses/${id}`);
        return res
    }

    async createHouse(payload: any) {
        let userId = payload.customerId
        if(!userId){
            throw new Error("Customer must be Selected")
        }
        delete payload.customerId
        const res = await HttpClient.post(`/centaurus/houses/users/${userId}`, payload);
        return res
    }
    async getAllCustomerForHouse(houseId: number) {
        const data = await HttpClient.get(`/centaurus/houses/customers/${houseId}`);
        return {
            data: data.data,
            total: data.data.length,
          };
    }

    async updateSlc(houseId: number, localityId: number): Promise<HouseResponse>  {
        const data = await HttpClient.patch(`/centaurus/houses/${houseId}/locality/${localityId}`);
        return data?.data;
    }

    async getHouseOmsCoupons(houseId:number){
        const data= await HttpClient.get(`/customers/v4/coupon/ONE_MEAL_SERVICE/houses/${houseId}`);
        return data?.data
    }

    async getAllBannersForHouse(payload:any):Promise<any>{
        console.log(payload);
        
        const data = await HttpClient.get(`/houses/v2/get-available-slc-service-mappings`,{
            params:payload
        });
        return data?.data
    }
}

export default HouseService;
