import React, { useEffect, useState } from 'react';
import {
    Button, Dialog, DialogActions, DialogContent,
    DialogTitle, FormControl, Box, Typography
} from "@mui/material";
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TempReplacementService } from '../../services/TemporaryReplacement';
import CircularLoader from '../Common/CircularLoader';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import Cooks from '../Cooks/Cooks';
import { TemporaryReplacementResponse } from '../../interfaces/TemporaryReplacement/TemporaryReplacementResponse';
import EventBus from '../../EventBus';
import { CustomSnackbarTypes } from '../Common/CustomSnackBar';
import { EventBusEventsEnum } from '../../enums/EventBusEventsEnum';
import ResourceSelectDialog from '../Common/ResourceSelectDialog';
import { useAppDispatch } from '../../store/hooks';
import { setCustomSelectOpen } from '../../store/reducers/customSelect';
import {CookService} from "../../services/CookServices";
import {CookResponse} from "../../interfaces/Cook/CookResponse";
import {BundlePlan} from "../../enums/common";
import MultipleTemporaryWhatsAppDialog from "./MultipleTemporaryWhatsAppDialog";

const temporaryReplacement = new TempReplacementService();
const cookService = new CookService();

interface MultipleTrResponse extends TemporaryReplacementResponse {
    zohoId: string;
    cookName: string;
    plan: BundlePlan;
    trCookCut?: string | null;
    isSelected: boolean;
    cookType: string;
    whatsappNumber?: string;
}

const MultipleTemporaryReplacement = (props: any) => {
    const dispatch = useAppDispatch();
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [dataAfterMapping, setDataAfterMapping] = useState<MultipleTrResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [singleRowData, setSingleRowData] = useState<any>(null);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [dataAfterMappingForWhatsappDialog, setDataAfterMappingForWhatsAppDialog] = useState<MultipleTrResponse[]>([]);
    const [currentTr, setCurrentTr] = useState<any>(null);
    const [isLoadingForWhatsAppDialog, setIsLoadingForWhatsAppDialog] = useState(false);
    const [selectCookChoice, setSelectCookChoice] = useState<number | null>(null);
    const [trCookCutValue, setTrCookCutValue] = useState<"0" | "300" | null>();

    const isMgCook = (cookSalary: any) => {
        return cookSalary[cookSalary?.length - 1]?.endDate === null;
    }

    const getCookSalary = async(cookId: any) => {
        return await cookService.getMgCookSalary(cookId);
    };

    const handleSubmit = async () => {
        for (const item of dataAfterMapping) {
            if (!item?.cookId && item?.isSelected) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: `Cook Not Found For ${item?.id}`,
                    type: CustomSnackbarTypes.ERROR
                });
                return;
            }
        }
        const responseArray: any[] = [];
        let countSelected = 0;
        Promise.allSettled(dataAfterMapping.map((item: MultipleTrResponse) => {
            if (item?.isSelected) {
                countSelected++;
                return temporaryReplacement.assignCook(item?.id, { cookId: item?.cookId, TRCookCut: item.trCookCut})
                    .then((data) => {
                        responseArray.push(data?.data?.data);
                        return data;
                    })
                    .catch((err) => {
                        throw err;
                    })
            }
            return null;
        })).then(() => {
            if (countSelected <= 0) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "No Cook Selected",
                    type: CustomSnackbarTypes.ERROR
                });
                return;
            }
            handleAfterSubmitForWhatsAppDialog(responseArray);
            props.setMultipleTrAllocateDialog(false);
            props.setSendMultipleWhatsAppTr(true);
            setSelectionModel([]);
            setStartDate(props?.data?.date || new Date());
            setEndDate(null);
            // setTrCookCut([])
        }).catch((err) => {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: err.response.data.message,
                type: CustomSnackbarTypes.ERROR
            });
        })

    }

    const handleSelectCook = () => {
        dispatch(setCustomSelectOpen({
            open: true
        }));
    }

    const handleCookSelect = async (data: any) => {
        if (selectCookChoice === 0) {
            // choice 0 for multiple
            const mgCook = isMgCook(await getCookSalary(data?.id));
            setTrCookCutValue(mgCook ? "0" : "300");
            handleChangeCookForMultiple(data, mgCook);
        }
        else if (selectCookChoice === 1) {
            // choice 1 for single
            const mgCook = isMgCook(await getCookSalary(data?.id));
            handleChangeCookSingleRow(data, mgCook);
            setTrCookCutValue(null);
        }
        else {
            handleApiCallForWhatsAppDialog(data);
        }
    }

    const handleAfterSubmitForWhatsAppDialog = (dataAfterSubmit: any) => {
        setIsLoadingForWhatsAppDialog(true);
        const data: MultipleTrResponse[] = [];
        dataAfterSubmit.forEach((item: any) => {
            let tempObj: MultipleTrResponse = {
                ...item,
                zohoId: item?.cook?.zohoId,
                cookName: item?.cook?.fname,
                plan: item?.userBundleMapping?.userBundleMeta?.plan,
                isSelected: false,
                whatsappNumber:  item?.cook?.whatsappNumber,
            };
            data.push(tempObj);
        });
        setDataAfterMappingForWhatsAppDialog(data);
        setIsLoadingForWhatsAppDialog(false);
    }

    const handleChangeCookForWhatsAppDialog = (cookData: CookResponse) => {
        let newMappingData = dataAfterMappingForWhatsappDialog.map((trData) => {
            if (trData.id === currentTr.id) {
                return {
                    ...trData,
                    cookId: cookData?.id,
                    cookName: cookData?.fname,
                    zohoId: cookData?.zohoCookId,
                    whatsappNumber: cookData?.whatsappNumber
                }
            }
            return trData;
        })
        setDataAfterMappingForWhatsAppDialog(newMappingData);
    }

    const handleApiCallForWhatsAppDialog = async (data: any) => {
        handleChangeCookForWhatsAppDialog(data);
        const mgCook = isMgCook(await getCookSalary(data?.id));
        await temporaryReplacement.assignCook(currentTr?.id, { cookId: data?.id, TRCookCut: mgCook ? "0" : "300" })
            .then((data) => {
                return data;
            })
    }

    const handleChangeCookSingleRow = (cook: CookResponse, mgCook: boolean) => {
        if (!cook) {
            return;
        }
        const dataArray: MultipleTrResponse[] = [];
        dataAfterMapping.forEach(async (item: MultipleTrResponse) => {
            if (item?.id === singleRowData?.id) {
                item.cookId = cook?.id;
                item.isSelected = true;
                item.cookName = cook?.fname;
                item.zohoId = cook?.zohoCookId;
                item.trCookCut = mgCook ? "0" : "300";
                item.cookType = mgCook ? "Mg Cook" : "Part Time cook";
            }
            dataArray.push(item);
        })
        setDataAfterMapping(dataArray);
    }

    const handleChangeCookForMultiple = (data: CookResponse, mgCook: boolean) => {
        if (!data) {
            return;
        }
        const dataArray: MultipleTrResponse[] = [];
        dataAfterMapping.forEach(async (item: MultipleTrResponse) => {
            if (item.isSelected) {
                item.cookId = data?.id;
                item.isSelected = true;
                item.cookName = data?.fname;
                item.zohoId = data?.zohoCookId;
                item.trCookCut = mgCook ? "0" : "300";
                item.cookType = mgCook ? "Mg Cook" : "Part Time cook";
            }
            dataArray.push(item);
        });
        setDataAfterMapping(dataArray);
    }

    const whatsAppHandler = async (data: any) => {
        try {
            let msgData: any = await temporaryReplacement.getWhatsAppMsg(data.id)
            window.open(`https://api.whatsapp.com/send?phone=+91${data?.whatsappNumber}&text=${msgData?.data}`)
        } catch (err) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: `Error in sending whasapp message`,
                type: CustomSnackbarTypes.ERROR
            });
        }
    }

    useEffect(() => {
        setSelectionModel(() => dataAfterMapping.filter((item) => item?.isSelected === true).map((item) => item?.id))
    }, [dataAfterMapping])


    useEffect(() => {
        if (!props?.data || !props?.data?.status || !props?.data?.userBundleMappingId) {
            return;
        }
        setIsLoading(true);
        temporaryReplacement.getAllTempReplacement(1000, 0, { status: props?.data?.status, userBundleMappingId: props?.data?.userBundleMappingId, startDate: startDate, endDate: endDate }, {})
            .then(async (data) => {
                const multiTr: MultipleTrResponse[] = [];
                await Promise.all(data?.data.map(async (item: TemporaryReplacementResponse) => {
                    let trCookCut = null;
                    let cookType = '';
                    if(item?.cookId){
                        const mgCook = isMgCook(await getCookSalary(item?.cookId));
                        trCookCut = mgCook ? "0" : "300"
                        cookType = mgCook ? "Mg Cook" : "Part Time cook"
                    }
                    let tempObj: MultipleTrResponse = {
                        ...item,
                        zohoId: item?.cook?.zohoId,
                        cookName: item?.cook?.fname,
                        plan: item?.userBundleMapping?.userBundleMeta?.plan,
                        isSelected: false,
                        trCookCut,
                        cookType,
                    };
                    multiTr.push(tempObj);
                }));
                setDataAfterMapping(multiTr);
                setIsLoading(false);
            }).catch(() => {
            setIsLoading(false);
        })
    }, [startDate, endDate, props]);

    useEffect(() => {
        (async function () {
            if (props?.data?.cookId) {
                await getCookSalary(props?.data?.cookId);
            }
            if (!props?.data?.date) return;
            setStartDate(props?.data?.date);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.data]);

    const columns: GridColDef[] = [
        {
            field: "Action",
            renderCell: (cellValues) => {
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(event) => {
                            event.stopPropagation();
                            setSingleRowData(cellValues?.row);
                            handleSelectCook();
                            setSelectCookChoice(1);
                        }}
                    >
                        {cellValues.row.cookId ? "Change Cook" : "Assign Cook"}
                    </Button>
                );
            },
            width: 150
        },
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'time', headerName: 'Time', width: 90 },
        { field: 'cookId', headerName: 'Cook ID', width: 100 },
        { field: 'cookName', headerName: 'Cook Name', width: 100 },
        { field: 'zohoId', headerName: 'Zoho ID', width: 100 },
        { field: 'trCookCut', headerName: 'Cook Cut', width: 100 },
        { field: 'cookType', headerName: "CookType", width: 100 },
        { field: 'slot', headerName: 'Slot', width: 100 },
        { field: 'status', headerName: 'Status', width: 130 },
        { field: 'plan', headerName: 'Plan', width: 130 },
        { field: 'date', headerName: 'Date', width: 130 },
        { field: 'createdAt', headerName: 'Created At', width: 200 },
    ];

    return (
        props?.data
        &&
        <div>
          <ResourceSelectDialog
            name={'Cooks'}
            list={Cooks}
            onClose={handleCookSelect}
          />
          <Dialog
            open={props.multipleTrAllocateDialog}
            maxWidth={'lg'}
            fullWidth={true}
          >
            <DialogTitle>Assign Multiple TR's</DialogTitle>
            <DialogContent>
              <Box display={'flex'}>
                <FormControl>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      inputFormat="dd MMM yyyy"
                      mask=''
                      minDate={new Date(props?.data?.userBundleMapping?.startDate)}
                      maxDate={new Date(props?.data?.userBundleMapping?.endDate)}
                      onChange={(newValue) => {
                          setStartDate(newValue as Date);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{marginLeft: 2}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="End Date"
                        value={endDate}
                        inputFormat="dd MMM yyyy"
                        mask=''
                        minDate={new Date(props?.data?.userBundleMapping?.startDate)}
                        maxDate={new Date(props?.data?.userBundleMapping?.endDate)}
                        onChange={(newValue) => {
                            setEndDate(newValue as Date);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                </FormControl>
                <FormControl sx={{marginLeft: 2}}>
                  <TextField
                    value={trCookCutValue || ""}
                    type={"number"}
                    disabled={true}
                    label={"TR Cook Cut"}
                  />
                </FormControl>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} ml={2}>
                  <Button variant="contained" onClick={() => {
                      handleSelectCook();
                      setSelectCookChoice(0);
                  }}>
                      {'Select Cook For All'}
                  </Button>
                </Box>
              </Box>
                {isLoading ?
                    <CircularLoader /> :
                    <>
                        <Box display={'flex'} my={2}>
                            <Typography>
                                <b>{'Name: '}</b>{props?.data?.userBundleMapping?.house?.customers[0]?.fname}
                            </Typography>
                            <Typography sx={{ml: 2}}>
                                <b>{'Mobile: '}</b>{props?.data?.userBundleMapping?.house?.customers[0]?.mobile}
                            </Typography>
                            <Typography sx={{ml: 2}}>
                                <b>{'Address: '}</b>{props?.data?.userBundleMapping?.house?.customers[0]?.address}
                            </Typography>
                        </Box>
                        <Box sx={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={dataAfterMapping}
                                columns={columns}
                                pageSize={50}
                                checkboxSelection
                                selectionModel={selectionModel}
                                onSelectionModelChange={(itm) => {
                                    const newData = dataAfterMapping?.map((item: MultipleTrResponse) => {
                                        if (itm.includes(item.id)) {
                                            return { ...item, isSelected: true };
                                        }
                                        return { ...item, isSelected: false };
                                    })
                                    setSelectionModel(itm);
                                    setDataAfterMapping(newData);
                                }}
                            />
                        </Box>
                    </>
                }
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color={"error"}
                onClick={() => {
                    props.setMultipleTrAllocateDialog(false)
                    setSelectionModel([]);
                    setDataAfterMapping([]);
                    setStartDate(props?.data?.date || new Date());
                    setEndDate(null);
                }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
          <MultipleTemporaryWhatsAppDialog
            sendMultipleWhatsAppTr={props.sendMultipleWhatsAppTr}
            isLoadingForWhatsAppDialog={isLoadingForWhatsAppDialog}
            dataAfterMappingForWhatsappDialog={dataAfterMappingForWhatsappDialog}
            onClose={() => {
                props.setSendMultipleWhatsAppTr(false)
            }}
            onCookChange={(row: MultipleTrResponse) => {
                setSelectCookChoice(2);
                setCurrentTr(row);
                handleSelectCook();
            }}
            onClickSendWhatsApp={(row: MultipleTrResponse) => {
                whatsAppHandler(row);
            }}
          />
        </div>
    )
}

export default MultipleTemporaryReplacement;
