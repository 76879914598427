enum ChefForPartStatusEnum {
    PENDING = "PENDING",
    CHEF_ALLOTTED = "CHEF_ALLOTTED",
    CHEF_CONFIRMED = "CHEF_CONFIRMED",
    ONGOING = "ONGOING",
    FULFILLED = "FULFILLED",
    CANCELLED = "CANCELLED",
    RESCHEDULED = "RESCHEDULED"
}

export default ChefForPartStatusEnum;
