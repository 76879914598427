import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField as TextFieldMUI,
  TextField,
  Typography
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useRefresh, useShowContext } from "react-admin";
import { SlotsEnum } from "../../enums/common";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { ShortTimeEnum } from "../../enums/ShortTimeEnum";
import EventBus from "../../EventBus";
import { TrialsService } from "../../services/TrialServices";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { TrialsV3Status } from "../../enums/TrialsV3Status";
import {getReasonCategory } from "./constants/constant";
import { ActionTypeEnum } from "../../enums/ActionTypeEnum";
import ReasonSubreasonModuleEnum from "../../enums/ReasonSubreasonModuleEnum";
import PermanentReplacementService from "../../services/PermanentReplacementService";
interface Props {
  data: any;
  open: boolean;
  callback: (data: any) => void;
}

const permanentReplacementService = new PermanentReplacementService();

const RescheduleTrialDialog = (props: Props) => {
  const [rescheduleReasonsOptionMapping,setRescheduleReasonsOptionMapping]=useState([]);
  const [date, setDate] = useState<Date | null>(null)
  const [slotTimeName, setSlotTimeName] = useState<any>({})

  const [slot, setSlot] = useState<SlotsEnum>();
  const [time, setTime] = useState<ShortTimeEnum>();

  const [reason, setReason] = useState<string>("")
  const [reasonSubCategoryArray, setReasonSubCategoryArray] = useState<string[] | null>(null);
  const [subReason, setSubReason] = useState<string>("")
  const [otherReasonInput, setOtherReasonInput] = useState<boolean>(false)
  const [visitChoice, setVisitChoice] = useState<string>("");

  const [trDateAndTimeDetails, setTrDateAndTimeDetails] = React.useState<any>();
  const [extensionDateAndTimeDetails, setExtensionDateAndTimeDetails] = React.useState<any>();


  const refresh = useRefresh();
  const { record } = useShowContext();
  console.log(record);
  const loadData = useCallback(async () => {
    switch (record.status) {
      case TrialsV3Status.COOK_ALLOCATED:
        await getReasonCategory(ReasonSubreasonModuleEnum.TRIAL,ActionTypeEnum.RESCHEDULED_POST_ALLOCATION).then((res) => {
          setRescheduleReasonsOptionMapping(res);
        })
        break;
      case TrialsV3Status.PENDING:
        let sampleReasonSubReason2 = await getReasonCategory(ReasonSubreasonModuleEnum.TRIAL,ActionTypeEnum.RESCHEDULED_PRE_ALLOCATION);
        setRescheduleReasonsOptionMapping(sampleReasonSubReason2);
        break;
      case TrialsV3Status.COOK_CONFIRMED:
        let sampleReasonSubReason3 = await getReasonCategory(ReasonSubreasonModuleEnum.TRIAL,ActionTypeEnum.RESCHEDULED_POST_ALLOCATION);
        setRescheduleReasonsOptionMapping(sampleReasonSubReason3);
        break;
      case TrialsV3Status.ONGOING:
        let sampleReasonSubReason4 = await getReasonCategory(ReasonSubreasonModuleEnum.TRIAL,ActionTypeEnum.RESCHEDULED_POST_ALLOCATION);
        setRescheduleReasonsOptionMapping(sampleReasonSubReason4);
        break;
    }
  }, [record?.status]);

  useEffect(()=>{
    loadData();
  },[loadData])

  const trialService = new TrialsService()
  let handleClose = () => {
    props.callback({ date, slotTimeName, reason });
    setDate(null);
    setSlotTimeName({});
    setReason("")
    setOtherReasonInput(false);
  };

  const handleReasonCategoryChange = (event: any) => {
    const selectedCategory:any = rescheduleReasonsOptionMapping.find(
      (option:any) => option.category === event.target.value
    );

    if (selectedCategory) {
      setReasonSubCategoryArray(selectedCategory?.subCategory);
      setReason(event.target.value);
    }
  }
  const handleVisitChoiceSelected = (event: ChangeEvent<HTMLInputElement>) => {
    setVisitChoice(event?.target?.value);
  }

  useEffect(() => {
    if(date && slot && record?.userBundleMappingDetails?.id) {
      permanentReplacementService.getTrExtensionDateDetails(record?.userBundleMappingDetails?.id, moment(date).format('YYYY-MM-DD'), slot)
          .then((res) => {
            if(res) {
              setTrDateAndTimeDetails(res?.data?.trDateAndTimeDetails);
              setExtensionDateAndTimeDetails(res?.data?.extensionDateAndTimeDetails);
            }
          })
    }
  }, [record?.userBundleMappingDetails?.id, date, slot])

  function ShowSubCategoryOrNot(reasonCategory: string) {
    if (reasonCategory?.toLowerCase() === "others" || reasonCategory?.toLowerCase() === 'other') {
      return (
        <FormControl style={{ width: 500 }}>
          <TextField value={subReason} onChange={(e)=>setSubReason(e.target.value)} label="Write a reason" />
        </FormControl>
      )
    }
    else  {
      return (
        <Grid item>
          <FormControl fullWidth>
          <InputLabel id="select-reason">Select a Subreason</InputLabel>
          <Select
            labelId="select-reason"
            id="select-reason"
            defaultValue="Select a reason"
            value={subReason}
            onChange={(e)=>setSubReason(e.target.value)}
          >
            {reasonSubCategoryArray?.map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        </Grid>
      )
    }

  }

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      scroll={"paper"}
      maxWidth="xs"
      sx={{
        '& .MuiPaper-root': {
          py: '20px',
          px: '20px',
          maxWidth: '750px',
        },
      }}
      fullWidth
    >
      <DialogTitle>Reschedule Trials</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent={"space-evenly"}
          style={{ padding: "12px 12px 12px 12px" }}
        >
          <Grid item>
            <DatePicker
              label="Select Date"
              inputFormat="dd MMM yyyy"
              disableMaskedInput
              minDate={record?.prId && new Date()}
              maxDate={record?.prId && moment(record?.userBundleMappingDetails?.endDate).toDate()}
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => {
                return <TextFieldMUI style={{width: '100%'}} {...params} />;
              }}
            />
          </Grid>
          <Grid item>
            {(record && record.prId) ?
              <FormControl fullWidth >
                <InputLabel id="for_slot">Slot</InputLabel>
                <Select
                  labelId="for_slot"
                  id="Slot"
                  label="Slot"
                  value={slot}
                  onChange={(event) => setSlot(event.target.value as SlotsEnum)}
                >
                  {props.data?.mealMapping.map((e: any) => (
                    <MenuItem key={e.slot} value={e.slot}>
                      {e.slot}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> :
              <FormControl fullWidth >
                <InputLabel id="for_slotTime">Slot and Time</InputLabel>
                <Select
                  labelId="for_slotTime"
                  id="SlotTime"
                  label="Slot and Time"
                  value={slotTimeName}
                  onChange={(event) => setSlotTimeName(event.target.value)}
                >
                  {props.data?.mealMapping.map((e: any) => (
                    <MenuItem key={e.slot} value={e}>
                      {`Slot:${e.slot}   Time:${e.time}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>}
          </Grid>
          {
            record && record.prId &&
            <Grid item>
              <FormControl fullWidth >
                <InputLabel id="for_time">Time</InputLabel>
                <Select
                  labelId="for_time"
                  id="Time"
                  label="Time"
                  value={time}
                  onChange={(event) => setTime(event.target.value as ShortTimeEnum)}
                >
                  {Object.values(ShortTimeEnum).map((e: any, i: number) => (
                    <MenuItem key={i} value={e}>
                      {e}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          }
          <Grid item>
            <FormControl fullWidth >
                <InputLabel id="for_reschedule_reason">Reschedule Reason</InputLabel>
                <Select
                  labelId="for_reschedule_reason"
                  id="rescheduleReason"
                  label="Reschedule Reason"
                  value={reason}
                  onChange={(event) => {
                    handleReasonCategoryChange(event)
                    setOtherReasonInput(true);
                  }}
                >
                  {rescheduleReasonsOptionMapping.map((item:any) => {
                    return (
                      <MenuItem key={item.category} value={item.category}>
                        {item.category}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
          </Grid>
          {
            otherReasonInput && ShowSubCategoryOrNot(reason)
          }
        </Grid>
        {/* Visit Choice */}
        {record && record?.prId && trDateAndTimeDetails && extensionDateAndTimeDetails && (
          <Grid>
            <FormControl>
              <RadioGroup
                  value={visitChoice}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleVisitChoiceSelected(event)}
              >
                {record?.userBundleMappingDetails?.subscriptions?.some((e: any) => e.status === "ACTIVE" && e.type !== "GRACE") && (
                  <Box display={'flex'} alignItems={'center'} gap={'20px'}>
                    <FormControlLabel
                        value={'TR'}
                        control={<Radio />}
                        label={`Temporary Replacement (${moment(trDateAndTimeDetails?.startDate).format('DD MMM')}  ${trDateAndTimeDetails?.startTime}- ${moment(trDateAndTimeDetails?.endDate).format('DD MMM YYYY')} ${trDateAndTimeDetails?.endTime})`}
                    />
                    {visitChoice === 'TR' && (<Typography fontSize={'13px'} color={'#690c0c'} fontWeight={500}>Current Cook will not go from next slot</Typography>)}
                  </Box>
                )}
                {record?.userBundleMappingDetails?.subscriptions?.some((e: any) => e.status === "ACTIVE" && e.type !== "GRACE") && (
                  <Box display={'flex'} alignItems={'center'} gap={'20px'}>
                    <FormControlLabel value={'EXTENSION'} control={<Radio />} label={`Extension (${moment(extensionDateAndTimeDetails?.startDate).format('DD MMM')} - ${moment(extensionDateAndTimeDetails?.endDate).format('DD MMM YYYY')} ${extensionDateAndTimeDetails?.endTime})`} />
                    {visitChoice === 'EXTENSION' && (<Typography fontSize={'13px'} color={'#690c0c'} fontWeight={500}>Current Cook will not go from next slot</Typography>)}
                  </Box>
                )}
                {record?.userBundleMappingDetails?.cookSubscription && (
                  <FormControlLabel value={'SAME_COOK'} control={<Radio />} label={'Continue with Current Cook'} />
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
      </DialogContent>
      <DialogActions style={{ marginTop: "12px" }}>
        <Button
          variant="contained"
          onClick={() => {
            if (!date || !slotTimeName || !reason || !subReason) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Fill the details to proceed",
                type: CustomSnackbarTypes.WARNING,
              });
              return;
            }
            if(record?.userBundleMappingDetails?.subscriptions?.some((e: any) => e.status === "ACTIVE" && e.type !== "GRACE")) {
              if(!visitChoice) {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Select a visit choice to proceed",
                  type: CustomSnackbarTypes.WARNING,
                });
                return;
              }
            }
            const payload = record?.prId ? {
              date: moment(date).format("YYYY-MM-DD"),
              slotName: slot,
              time: time,
              reason: reason,
              rescheduleSubReason: subReason,
              ...(visitChoice && { prCxRemainingVisitChoice: visitChoice }),
            } :
              {
                date: moment(date).format("YYYY-MM-DD"),
                slotName: slotTimeName.slot,
                time: slotTimeName.time,
                reason: reason,
                rescheduleSubReason: subReason
              }
            trialService.rescheduleTrials(props.data.id, payload).then(() => {
              refresh();
              handleClose();
            });
          }}
          size={"large"}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            handleClose();
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RescheduleTrialDialog;
