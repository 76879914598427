import React from 'react';
import {Button, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import moment from "moment";
import { UserType } from '../../enums/common';


const CookLeavesListForModule = (props: any) => {

     function getLink(id:number,type:UserType):string{
        if(type===UserType.ADMIN){
            return `/#/AdminUsers/${id}/show`
        }
        else if(type===UserType.COOK){
            return `/#/Cooks/${id}/show`
        }
        else{
            return `/#/Customers/${id}/show`
        }
    }
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Cook Id</TableCell>
                        <TableCell>Cook Zoho</TableCell>
                        <TableCell>Cook Fname</TableCell>
                        <TableCell>Cook Lname</TableCell>
                        <TableCell>Cook Mobile</TableCell>
                        <TableCell>Leave Type</TableCell>
                        <TableCell>Leave Start Date</TableCell>
                        <TableCell>Leave End Date</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell>Updated At</TableCell>
                        <TableCell>Deleted At</TableCell>
                        <TableCell/>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((row: any) => (
                        <TableRow
                            key={row.id}
                            sx={!row?.deletedById?{ '&:last-child td, &:last-child th': { border: 0 } }:{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor:"#F79797 !important" }}
                        >
                            <TableCell scope="row">{row?.id}</TableCell>
                            <TableCell>
                                <Link href={`/#/Cooks/${row.cookId}/show`}>
                                    {row?.cookId}
                                </Link>
                            </TableCell>
                            <TableCell align="center">{row?.cookZohoId}</TableCell>
                            <TableCell align="center">{row?.cookFirstName}</TableCell>
                            <TableCell align="center">{row?.cookLastName}</TableCell>
                            <TableCell align="center">{row?.cookPhoneNumber}</TableCell>
                            <TableCell align="center">{row?.leaveType}</TableCell>
                            <TableCell align="center">
                                {
                                    !!row &&
                                    !!row.leaveStartDate &&
                                    moment(row?.leaveStartDate, 'YYYY-MM-DD').format('DD MMM YYYY')+" "+(row?.startTime?row?.startTime:"")
                                }
                            </TableCell>
                            <TableCell align="center">
                                {
                                    !!row &&
                                    !!row.leaveEndDate &&
                                    moment(row?.leaveEndDate, 'YYYY-MM-DD').format('DD MMM YYYY')+" "+(row?.endTime?row?.endTime:"")
                                }
                            </TableCell>
                            <TableCell align="center">
                                {
                                    row &&
                                    row?.createdAt &&
                                    moment(row?.createdAt, 'YYYY-MM-DD').format('DD MMM YYYY')
                                }
                            </TableCell>
                            <TableCell align="center">
                                {
                                    row &&
                                    row?.updatedAt &&
                                    moment(row?.updatedAt, 'YYYY-MM-DD').format('DD MMM YYYY')
                                }
                            </TableCell>
                            <TableCell align="center">
                                {
                                    (row &&
                                    row?.deletedAt)?
                                    moment(row?.deletedAt).format('DD MMM YYYY hh:mm A'):"-"
                                }
                            </TableCell>
                            <TableCell align="center">
                                {
                                    !row?.deletedById?
                                    <Link
                                      href={`/#/CookLeaves/${row?.id}/show`}
                                    >
                                        <Button variant={'contained'} color={'primary'}>
                                            Show
                                        </Button>
                                    </Link>:
                                    <Link href={getLink(row?.deletedById,row?.deletedByType)}>{row?.deletedById}</Link>
                                }
                            </TableCell>
                            <TableCell align="center">
                                {
                                 !row?.deletedById?
                                 <Link
                                    href={`/#/CookLeaves/${row.id}`}
                                >
                                    <Button variant={'contained'} color={'primary'}>
                                        Edit
                                    </Button>
                                 </Link>:row?.deletedByType   
                                } 
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CookLeavesListForModule;
