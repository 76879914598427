import { Close } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel,
    FormLabel,
    IconButton, MenuItem, Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import { useRefresh } from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import {CookService} from "../../services/CookServices";
import {MgCookTier} from "../../enums/MgCookTierEnum";

interface InputProps {
    data: {cook: any};
    setOpen: Dispatch<SetStateAction<boolean>>;
    open: boolean;
}
const cookService = new CookService();
export const UpdateTierDialog = (props: InputProps) => {;
    const refresh = useRefresh();
    const [tier, setTier] = useState<string>('');
    const [hasFixedTier, setHasFixedTier] = useState<boolean>(false);
    const handleClose = () => {
        props.setOpen(false);
    };

    const handleSubmit = async () => {
        await cookService
            .updateCookTier(props?.data?.cook?.id, {tier, hasFixedTier})
            .then((res) => {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Updated successfully",
                    type: CustomSnackbarTypes.SUCCESS
                });
                handleClose();
                refresh();
            });
    };

    useEffect(() => {
        if(props?.data?.cook?.cookFullProfile?.hasFixedTier) {
            setHasFixedTier(props?.data?.cook?.cookFullProfile?.hasFixedTier);
        }
        if(props?.data?.cook?.cookFullProfile?.tier) {
            setTier(props?.data?.cook?.cookFullProfile?.tier);
        }
    }, [props?.data?.cook])

    return (
        <Dialog onClose={handleClose} open={props.open} scroll={"paper"} maxWidth="xl">
            <DialogTitle>Update Cook Tier</DialogTitle>
            <DialogContent sx={{minWidth: '340px'}}>
              <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8 }}> <Close /> </IconButton>
              <FormControl fullWidth>
                  <FormLabel id="utensils">Select Type</FormLabel>
                  <RadioGroup
                      row
                      onChange={(event: any) => {
                          setHasFixedTier(event.target.value !== 'false');
                      }}
                      value={hasFixedTier === true ? 'true' : hasFixedTier === false ? 'false' : null}
                  >
                      <FormControlLabel
                          value={'true'}
                          control={<Radio />}
                          label="Fixed"
                      />
                      <FormControlLabel
                          value={'false'}
                          control={<Radio />}
                          label="Not Fixed"
                      />
                  </RadioGroup>
                  <TextField
                      label="Select the Tier"
                      key={"tier"}
                      onChange={(event) => {
                        setTier(event.target.value);
                      }}
                      select
                      value={tier}
                      margin={"dense"}
                  >
                      {Object.values(MgCookTier).map((each: string) => {
                          return(
                              <MenuItem key={each} value={each}>
                                  {each}
                              </MenuItem>
                          )
                      })}
                  </TextField>
              </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    size={"large"}>
                    Submit
                </Button>
                <Button
                    variant="contained"
                    color={"error"}
                    onClick={() => { handleClose(); }}
                    size={"large"} >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};
