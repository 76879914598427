export const saveToLocalStorage = (key: string, value: any, isJson: boolean = false) => {
    if (isJson === true) {
        JSON.stringify(value)
    }
    localStorage.setItem(key, value);
}

export const retrieveFromLocalStorage = (key: string) => {
    return localStorage.getItem(key);
}

export const saveOrUpdateLocalStorage = (key: string, value: any) => {
  const existingValue = localStorage.getItem(key);

  if (existingValue) {
      localStorage.setItem(key, JSON.stringify({ ...JSON.parse(existingValue), ...value }));
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
}