import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRefresh } from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { TrialsService } from "../../services/TrialServices";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { useLocation } from "react-router-dom";
import { CookService } from "../../services/CookServices";
import {ErrorDialog} from "./ErrorDialog";

interface InputProps {
  data: any
  open: boolean;
  setOpen: (open: boolean) => any;
  cookCut: number;
}

// interface IReason {
//   label: string;
//   noteRequired: boolean;
//   noteLabel?: string;
// }

const trialsService = new TrialsService();
const cookService = new CookService();

// const reasonsList: IReason[] = [
//   {
//     label: 'Data is wrong for cooks',
//     noteRequired: true,
//     noteLabel: 'What data is wrong for chef?',
//   },
//   {
//     label: 'I trust my personal list of cooks',
//     noteRequired: true,
//     noteLabel: 'Why?',
//   },
//   {
//     label: 'Quality cooks are not available in the list',
//     noteRequired: true,
//     noteLabel: 'What do you mean by quality cooks?',
//   },
//   {
//     label: 'List does not contain Cx preferred cooks',
//     noteRequired: true,
//     noteLabel: 'What preference did not match?',
//   },
//   {
//     label: 'Not available in allocation tab',
//     noteRequired: true,
//     noteLabel: 'Is data for SLC and slots updated for chef, if no what data is not updated?',
//   },
//   {
//     label: 'No cook in list accepted the job',
//     noteRequired: false,
//   },
//   {
//     label: 'Cook has not accepted but confirmed on call',
//     noteRequired: false,
//   },
//   {
//     label: 'Cook suggested by supply team',
//     noteRequired: false,
//   },
//   {
//     label: 'Other',
//     noteRequired: true,
//     noteLabel: 'Reason?',
//   },
// ]

const AllocateTrialDialog = (props: InputProps) => {
  const refresh = useRefresh();
  const [cookCut, setCookCut] = useState<string>("0");
  const [trialCookCut, setTrialCookCut] = useState<string>("250");
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorRes, setErrorRes] = useState<any>();
  let location = useLocation();
  // const [outsideCook, setOutsideCook] = useState(false);
  // const [reason, setReason] = useState<IReason>();
  let trialId = location.pathname.split("/")[2];
  // const [allocationNotes, setAllocationNotes] = useState<string>("");

  useEffect(() => {
    // if(props?.data){
    //   (async function () {
    //     const response = await trialsService.checkAutoAllocationCook(
    //       parseInt(props?.data?.trialId),
    //       props?.data?.cookId
    //     );
    //     if (!response.isInterestedInTrial) {
    //       setOutsideCook(true);
    //     } else {
    //       setOutsideCook(false);
    //       setReason(undefined);
    //       setAllocationNotes('');
    //     }
    //   })();
    // }
    cookService.getMgCookSalary(props?.data?.cookId).then((cookSalary) => {
      if (cookSalary && cookSalary.length > 0) {
        const isMgCook = cookSalary[cookSalary.length - 1]?.endDate === null;
        if (props.data && isMgCook) {
          setTrialCookCut("0");
        }
      }
    });
  }, [props.data])


  return (
    <div>
      <Dialog open={props.open} scroll={"paper"} maxWidth="xl">
        <DialogTitle>Trial Allocation</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
              <TextField
                defaultValue={props?.cookCut}
                disabled={true}
                type={"number"}
                label={"Cook Cut"}
                style={{
                  marginTop: "12px",
                }}
              />
             {props && props.data &&
             <TextField
              value={trialCookCut}
              type={"number"}
              disabled={true}
              label={"Trial Cook Cut"}
              style={{
                marginTop: "12px",
              }}
            />}
            {/*{outsideCook && (*/}
            {/*  <FormControl fullWidth>*/}
            {/*  <InputLabel id="addGroup">Reason</InputLabel>*/}
            {/*  <Select labelId="addGroup" id="addGroup" label="Group" value={reason}>*/}
            {/*    {reasonsList.map((value: IReason) => (*/}
            {/*      <MenuItem*/}
            {/*        key={value.label}*/}
            {/*        onClick={(e) => {*/}
            {/*          setReason(value);*/}
            {/*        }}*/}
            {/*        value={value.label}*/}
            {/*      >*/}
            {/*        {value.label}*/}
            {/*      </MenuItem>*/}
            {/*    ))}*/}
            {/*  </Select>*/}
            {/*</FormControl>*/}
            {/*)}*/}
            {/*{outsideCook && reason?.noteRequired && (*/}
            {/*<TextField*/}
            {/*    value={allocationNotes}*/}
            {/*    type={"string"}*/}
            {/*    label={`Reason`}*/}
            {/*    style={{*/}
            {/*      marginTop: "12px",*/}
            {/*    }}*/}
            {/*    onChange={(event) => {*/}
            {/*      setAllocationNotes(event.target.value);*/}
            {/*    }}*/}
            {/*  />*/}
            {/*)}*/}
          </div>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              // if(outsideCook && !reason) {
              //   EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
              //     message: "Please select the reason",
              //     type: CustomSnackbarTypes.WARNING,
              //   });
              //   return;
              // }
              // if (reason?.noteRequired && allocationNotes.trim() === '') {
              //   EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
              //     message: "Please enter reason",
              //     type: CustomSnackbarTypes.WARNING,
              //   });
              //   return;
              // } else
              if (
                  props?.data?.cookId &&
                  Number(cookCut) >= 0 &&
                  Number(trialCookCut) >= 0
              ) {
              let payload = {
                cookId: props?.data?.cookId,
                cookCut: Number(cookCut),
                trialCookCut: trialCookCut,
                // allocationNotes: outsideCook ? `${reason?.label} - ${allocationNotes}`: ''
                allocationNotes: '',
              };
              trialsService
                .assignCookToTrials(Number(trialId), payload)
                .then((res) => {
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Success",
                    type: CustomSnackbarTypes.SUCCESS,
                  });
                  setCookCut("0");
                  setTrialCookCut("250");
                  // setReason(undefined);
                  props.setOpen(false);
                  refresh();
                }).catch((err) => {
                      setErrorRes(err?.response?.data?.data);
                      if(err?.response?.data?.data){
                          setOpenError(true);
                      }
                      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                          message: err?.response?.data?.data ? `` : err?.response?.data?.error,
                          type: CustomSnackbarTypes.ERROR,
                          duration:  err?.response?.data?.data ? 0 : 3000,
                      });
                  })
              } else {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: `Fill the details properly`,
                  type: CustomSnackbarTypes.ERROR,
                });
              }
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              props.setOpen(false);
              setCookCut("0");
              setTrialCookCut("250")
              // setReason(undefined);
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
        <ErrorDialog
            open={openError}
            onClose={() => {setOpenError(false)}}
            data={errorRes}
        />
    </div>
  );
};

export default AllocateTrialDialog;
