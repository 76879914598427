import { Chip, Stack, Typography } from "@mui/material";
import moment from "moment";
import { ArrayField, BooleanField, Datagrid, DateField, FunctionField, NumberField, RaRecord, Show, SimpleShowLayout, TextField, useShowContext } from "react-admin";
import CustomReferenceField from "../../Common/CustomReferenceField";
import JsonViewer from "../../Common/JsonViewer";

const UserBundleMetaShow = (props: any) => {
    const { record } = useShowContext();
    return (
        <div>

            <Stack>
                <Chip label={`Wallet amount: ${record?.house?.wallet?.amount || 0}`} />
            </Stack>

            <Show actions={false}>
                <Stack gap={1} direction={"row"}>
                    {/* TODO: figure out a way to display customers */}

                    <SimpleShowLayout record={record?.house}>
                        <Typography> House </Typography>
                        <CustomReferenceField
                            label="House Id"
                            listName={"Houses"}
                            source={"id"}
                        />
                        <TextField label="Address" source='address' />
                        <TextField label="Locality" source='locality' />
                        <NumberField label="City" source='city' />
                    </SimpleShowLayout>

                    <SimpleShowLayout>
                        <Typography> Bundle </Typography>
                        <TextField label="Bundle Name" source='bundle.name' />
                        <TextField label="Paid Days" source='bundle.paidDays' />
                        <NumberField label="Free Days" source='bundle.freeDays' />
                        <TextField label="Grace Days" source='bundle.graceDays' />
                        <TextField label="Payment Source" source='paymentMethod' />
                        <TextField label="Closure Reason" source="closureReason"/>
                        <TextField label="Closure Sub Reason" source="closureSubReason"/>
                        <TextField label="Comment" source="closureNotes"/>
                        <TextField label="On Board Again" source="isInterested"/>
                        <TextField label="Payment Status" source="paymentStatus" />
                        <FunctionField
                        label="Payment Status Last Updated At"
                        render={() => {
                            if (record?.paymentStatusLastUpdatedAt) {
                                return moment(record?.paymentStatusLastUpdatedAt).format("LLL");
                            }
                        }}
                        />
                        <TextField
                        label="Razor Pay Order Id"
                        source="razorpayOrder.razorpayOrderId"
                        />
                        <FunctionField label="Pricing Regime" render={(record: RaRecord) => (record?.includeFreeTr === true ? "New Regime (Free TR Included)" : "Old Regime (No Free TR)")}/>
                    </SimpleShowLayout>

                    <SimpleShowLayout>
                        <Typography> Mapping </Typography>
                        <TextField label="Status" source='status' />
                        <TextField label="Quoted Price" source='quotedPrice' />
                        <TextField label="Quoted Net Price" source='quotedNetPrice' />
                        <TextField label="Discount Amount" source='discountAmount' />
                        <ArrayField label="Meal Mapping" source="mealMapping">
                            <Datagrid bulkActionButtons={false} style={{ minWidth: "250px" }}>
                                <TextField source="slot" />
                                    <TextField
                                        source="time"
                                        style={{ width: "61px", display: "inlineBlock" }}
                                    />
                                <TextField label="Buffer" source="bufferMinutes" />
                            </Datagrid>
                        </ArrayField>
                        <TextField label="Cook Cut" source="cookCut"/>
                    </SimpleShowLayout>

                    <SimpleShowLayout record={record?.userBundleMeta}>
                        <Typography> Meta </Typography>
                        <TextField label="Plan" source='plan' />
                        <TextField label="Gender Preference" source='genderPreference' />
                        <NumberField label="Total People" source='totalPeople' />
                        <TextField label="Meals Chosen" source='mealMapping' />
                        <NumberField label="Total Visits" source='totalVisits' />
                        <NumberField label="Total Leaves" source='totalLeaves' />
                        <NumberField label="Taken Leaves" source='takenLeaves' />
                        <BooleanField label="Utensils Taken" source='utensils' />
                        <TextField label="Leave Default Option" source='leaveDefaultOption'/>
                    </SimpleShowLayout>

                    <SimpleShowLayout>
                        <Typography> Tenure </Typography>
                        <DateField options={{day: 'numeric', month: 'short', year: 'numeric' }} label="Start Date" source='startDate' />
                        <TextField label="Start Slot" source='startSlot' />
                        <DateField options={{day: 'numeric', month: 'short', year: 'numeric' }} label="End Date" source='endDate' />
                        <TextField label="End Slot" source='endSlot' />
                    </SimpleShowLayout>

                </Stack>
                <JsonViewer data={record} />
            </Show>
        </div>
    )
}

export default UserBundleMetaShow;
