import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Create } from "react-admin";
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment";
import TransactionButton from "../Common/TransactionButton";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import { useAppDispatch } from "../../store/hooks";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import BundleList from "../Bundle/BundleList";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import HouseList from "../Houses/HouseList";
import {useNavigate} from "react-router-dom"
import ChefForPartyService from "../../services/ChefForPartyService";
import {ChefForPartyResponse} from "../../interfaces/ChefForParty/ChefForPartyResponse";
import { ChefPartyTimeEnum } from "../../enums/ChefForPartyTimeEnums";

const chefForPartyService = new ChefForPartyService();

const ChefForPartyCreate = (props: any) => {
    const dispatch = useAppDispatch();
    const [houseId, setHouseId] = useState<any>(null);
    const [date, setDate] = useState<any>(null);
    const [time, setTime] = useState<any>(null);
    const [noOfAdults, setNoOfAdults] = useState(0);
    const [noOfChildren, setNoOfChildren] = useState(0);
    const [noOfDishes, setNoOfDishes] = useState(0);
    const [noOfStarters, setNoOfStarters] = useState(0);
    const [noOfMainCourse, setNoOfMainCourse] = useState(0);
    const [noOfDesserts, setNoOfDesserts] = useState(0);
    const [noOfSideDishes, setNoOfSideDishes] = useState(0);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [bartender, setBartender] = useState<number>(0);
    const [waiter, setWaiter] = useState<number>(0)
    const [fullPayment,setFullPayment ] = useState<boolean>(false);

    const navigate = useNavigate();

    const [selected, setSelected] = useState(NaN);

    const handleSelectHouseOrBundle = () => {
        dispatch(setCustomSelectOpen({
            open: true
        }));
    }

    const handleOpen = (val: number) => {
        setSelected(val)
        handleSelectHouseOrBundle();
    }


    const handleCloseBundle = (data: any) => {
        // setBundleId(data?.id);
    };

    const handleCloseHouse = (record: any) => {
        setHouseId(record?.id);
    }



    const validate = () => {
        if(!houseId){
            showError('Please select a house');
            return false;
        } else if(!date){
            showError('Please select a date');
            return false;
        }else if(!time){
            showError('Please select a time');
            return false;
        }
        return true;
    };

    const showError = (title: string) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: title || "Fill the form to continue",
            type: CustomSnackbarTypes.ERROR,
        });
    }

    const calculatePrice = () => {
        if(!houseId){
            showError('Please select a house');
            return;
        }
        let addOns = [];
        if(bartender > 0) {
            addOns.push({"addOnId": 1,"quantity": bartender,"duration": 0})
        }
        if(waiter > 0) {
            addOns.push({"addOnId": 2,"quantity": waiter,"duration": 0})
        }
        let dishesCountObj: any = {};
        dishesCountObj["noOfStarters"] = noOfStarters
        dishesCountObj["noOfMainCourse"] = noOfMainCourse
        dishesCountObj["noOfDesserts"] = noOfDesserts
        dishesCountObj["noOfSideDishes"] = noOfSideDishes
        if (noOfDishes !== 0) {
          chefForPartyService
            .calculateDynamicPrice({
              houseId: houseId,
              date: date,
              noOfAdults: Number(noOfAdults),
              noOfChildren: Number(noOfChildren),
              noOfDishes: Number(noOfDishes),
              addOns,
              dishesCountObj,
            })
            .then((res: any) => {
              if (res?.totalAmount) {
                setTotalPrice(res.totalAmount);
              }
            });
        }
    };


    return (
      <Create title={"Create Chef for party"}>
        <Grid
          container
          columns={1}
          rowSpacing={1}
          style={{ padding: "12px 12px 12px 12px" }}
        >
          <Grid item xs={4}>
            <ResourceSelectDialog
              name={selected === 0 ? "Houses" : "Bundles"}
              list={selected === 0 ? HouseList : BundleList}
              onClose={selected === 0 ? handleCloseHouse : handleCloseBundle}
            />
            <Stack gap={1}>
              <Stack gap={1}>
                <TransactionButton
                  name={houseId ? `houseId: ${houseId}` : "Choose House"}
                  onClickHandler={() => handleOpen(0)}
                />
              </Stack>
              {/*<Stack gap={1}>*/}
              {/*    <TransactionButton*/}
              {/*        name={*/}
              {/*            bundleId*/}
              {/*                ? `bundleId: ${bundleId}`*/}
              {/*                : "Choose Bundle"*/}
              {/*        }*/}
              {/*        onClickHandler={() => handleOpen(1)}*/}
              {/*    />*/}
              {/*</Stack>*/}
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <DatePicker
                label="Date"
                inputFormat="dd MMM yyyy"
                disableMaskedInput
                value={date}
                minDate={new Date()}
                onChange={(value: any) => {
                  setDate(moment(value).format("YYYY-MM-DD"));
                }}
                renderInput={(params: any) => <TextField {...params} />}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <InputLabel id="forTime">Party Time</InputLabel>
              <Select
                labelId="forTime"
                id="time"
                value={time}
                label="Party Time"
                onChange={(event: any) => {
                  setTime(event.target.value);
                }}
              >
                {Object.values(ChefPartyTimeEnum).map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="No of Adults"
                id="noOfAdults"
                value={noOfAdults}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e: any) => {
                  setNoOfAdults(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="No of Children"
                id="noOfChildren"
                value={noOfChildren}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e: any) => {
                  setNoOfChildren(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="No of Bartender"
                id="noOfBartender"
                value={bartender}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e: any) => {
                  setBartender(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="No of Waiter"
                id="noOfWaiter"
                value={waiter}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e: any) => {
                  setWaiter(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="No of Dishes"
                id="noOfDishes"
                disabled={true}
                value={noOfDishes}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e: any) => {
                  setNoOfDishes(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="No of Starter"
                id="noOfStarters"
                value={noOfStarters}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e: any) => {
                  setNoOfDishes(
                    Number(noOfSideDishes) +
                      Number(noOfDesserts) +
                      Number(noOfMainCourse) +
                      Number(e.target.value)
                  );
                  setNoOfStarters(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="No of Main Course"
                id="noOfMainCourse"
                value={noOfMainCourse}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e: any) => {
                  setNoOfDishes(
                    Number(noOfSideDishes) +
                      Number(noOfDesserts) +
                      Number(e.target.value) +
                      Number(noOfStarters)
                  );
                  setNoOfMainCourse(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="No of Desserts"
                id="noOfDesserts"
                value={noOfDesserts}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e: any) => {
                  setNoOfDishes(
                    Number(noOfSideDishes) +
                      Number(e.target.value) +
                      Number(noOfMainCourse) +
                      Number(noOfStarters)
                  );
                  setNoOfDesserts(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="No of SideDishes"
                id="noOfSideDishes"
                value={noOfSideDishes}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e: any) => {
                  setNoOfDishes(
                    Number(e.target.value) +
                      Number(noOfDesserts) +
                      Number(noOfMainCourse) +
                      Number(noOfStarters)
                  );
                  setNoOfSideDishes(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
                <Box display={'flex'} alignItems={'center'} style={{flexWrap: 'wrap'}}>
                    <FormControl style={{width: "230px"}}>
                        <TextField
                            label="Total Price"
                            id="totalPrice"
                            value={totalPrice}
                            disabled={true}
                            type="number"
                            InputProps={{inputProps: {min: 0}}}
                        />
                    </FormControl>
                    <TransactionButton
                        name={"Calculate Price"}
                        onClickHandler={() => {
                            calculatePrice();
                        }}
                        style={{marginLeft: '30px'}}
                    />
                </Box>
            </Grid>
            <Grid item xs={4}>
              <FormControl>
                  <FormLabel id="fullPayments">Take Full Payment</FormLabel>
                  <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(event: any) => {
                          setFullPayment(event.target.value !== 'false');
                      }}
                      value={fullPayment}
                  >
                      <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes"
                      />
                      <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                      />
                  </RadioGroup>
              </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              onClick={() => {
                if (validate()) {
                  let addOns = [];
                  if(bartender > 0) {
                      addOns.push({"addOnId": 1,"quantity": bartender,"duration": 0})
                  }
                  if(waiter > 0) {
                      addOns.push({"addOnId": 2,"quantity": waiter,"duration": 0})
                  }
                  chefForPartyService
                    .createChefForPartyService({
                      time: time,
                      date: date,
                      noOfAdults: Number(noOfAdults),
                      noOfChildren: Number(noOfChildren),
                      noOfDishes: Number(noOfDishes),
                      noOfStarters: Number(noOfStarters),
                      noOfMainCourse: Number(noOfMainCourse),
                      noOfDesserts: Number(noOfDesserts),
                      addOns,
                      houseId: houseId,
                      fullPayment: fullPayment,
                      noOfSideDishes: Number(noOfSideDishes),
                    })
                    .then((data: ChefForPartyResponse) => {
                      if (data?.id) {
                        navigate(`/ChefForParty/${data?.id}/show`, {
                          replace: true,
                        });
                      }
                    });
                }
              }}
              size={"large"}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Create>
    );
};

export default ChefForPartyCreate;
