import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { useRefresh } from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import HouseReviewResponse from "../../interfaces/HouseReview/HouseReviewResponse";
import HouseReviewService from "../../services/HouseReviewService";
import { CustomSnackbarTypes } from "./CustomSnackBar";

const houseReviewService = new HouseReviewService();

interface InputProps {
  data: HouseReviewResponse
  open: boolean;
  setOpen: (data: any) => void;
}

const RejectHouseReviewDialog = (props: InputProps) => {
  const refresh = useRefresh();

  const RejectHouse = () => {
    houseReviewService.reject(Number(props?.data?.id)).then(() => {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Address Rejected",
        type: CustomSnackbarTypes.SUCCESS,
      });
      refresh();
      props.setOpen(false)
    });
  };

  return (
    <Dialog open={props.open} scroll={"paper"} maxWidth="xl">
      <DialogTitle>conformation</DialogTitle>
      <DialogContent>
        Are you sure you want to reject this address?
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            props.setOpen(false);
          }}
        >
          No
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            RejectHouse();
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RejectHouseReviewDialog;
