import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useRefresh, useUnselectAll } from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { VendorPaymentType } from "../../enums/vendorPaymentType";
import { VendorPayoutTransactionStatus } from "../../enums/vendorPayoutTransactionStatus";
import EventBus from "../../EventBus";
import { VendorPayoutResponse } from "../../interfaces/VendorPayout/VendorPayoutResponse";
import VendorPayoutService from "../../services/VendorPayoutService";
import { CustomSnackbarTypes } from "./CustomSnackBar";

interface InputProps {
  data: VendorPayoutResponse | null;
  open: boolean;
  callback: () => void;
  isBulkAction?: boolean;
  selectedVendorPayoutIds?: number[];
}

export const PayoutTypeDailog: React.FC<InputProps> = ({
  data,
  open,
  callback,
  isBulkAction,
  selectedVendorPayoutIds,
}) => {
  let refresh = useRefresh();
  const vendorPayoutService = new VendorPayoutService();
  // const payoutServices = new PayoutServices();
  const [paymentType, setPaymentType] = useState();
  const unselectAll = useUnselectAll("VendorPayouts");

  let handleClose = useCallback(() => {
    callback();
  }, [callback]);

  const bulkPayoutHandler = async() => {
    if (!paymentType) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Select the payout Type to proceed",
        type: CustomSnackbarTypes.WARNING,
      });
      return;
    } else {
      if (selectedVendorPayoutIds) {
        try {
          let payload = {
            transactionId: selectedVendorPayoutIds,
            comments: '',
            hardProcess: false,
          }

          if (paymentType === VendorPaymentType.SOFTPAYOUT) {
            await vendorPayoutService.bulkProcessPayout(payload).then((res: VendorPayoutResponse[]) => {
              if (res) {
                // eslint-disable-next-line
                res?.map((v: VendorPayoutResponse) => {
                  if (v && v?.status) {
                    if (v?.status !== VendorPayoutTransactionStatus.PROCESSED) {
                      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: "Payout was not processed",
                        type: CustomSnackbarTypes.ERROR,
                      });
                      return null;
                    }
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                      message: "Payout Processed Successfully",
                      type: CustomSnackbarTypes.SUCCESS,
                    });
                  }
                })
              }
              callback();
              unselectAll();
              refresh();
            }).catch((err) => {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: err?.response?.data?.error,
                type: CustomSnackbarTypes.ERROR,
              });
              callback();
              refresh();
            })
          }
          else {
            if (data?.status === VendorPayoutTransactionStatus.PROCESSING_FAILED) {
              selectedVendorPayoutIds?.forEach(async (vendorPayoutID) => {
                await vendorPayoutService.retryPayout(Number(vendorPayoutID)).then((res: VendorPayoutResponse) => {
                  if (res && res?.status) {
                    if (res?.status !== VendorPayoutTransactionStatus.PROCESSED) {
                      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: "Payout was not processed",
                        type: CustomSnackbarTypes.ERROR,
                      });
                      return null;
                    }
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                      message: "Payout Processed Successfully",
                      type: CustomSnackbarTypes.SUCCESS,
                    });
                  }
                  callback();
                  unselectAll();
                  refresh();
                }).catch((err) => {
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: err?.response?.data?.error,
                    type: CustomSnackbarTypes.ERROR,
                  });
                  callback();
                  refresh();
                })
              })
            }
            else {
              payload.hardProcess = true;
              await vendorPayoutService.bulkProcessPayout(payload).then((res: VendorPayoutResponse[]) => {
                if (res) {
                  // eslint-disable-next-line
                  res?.map((v: VendorPayoutResponse) => {
                    if (v && v?.status) {
                      if (v?.status !== VendorPayoutTransactionStatus.PROCESSED) {
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                          message: "Payout was not processed",
                          type: CustomSnackbarTypes.ERROR,
                        });
                        return null;
                      }
                      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: "Payout Processed Successfully",
                        type: CustomSnackbarTypes.SUCCESS,
                      });
                    }
                  })
              }
              callback();
              unselectAll();
              refresh();
            }).catch((err) => {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: err?.response?.data?.error,
                type: CustomSnackbarTypes.ERROR,
              });
              callback();
              refresh();
            })
          }
          }
        } catch (err) { }
      }
      // selectedVendorPayoutIds?.forEach(async (vendorPayoutID) => {
      //   try {
      //     let res: VendorPayoutResponse;
      //     if (paymentType === VendorPaymentType.SOFTPAYOUT) {
      //       res = await vendorPayoutService.processPayout(
      //         Number(vendorPayoutID),
      //         {
      //           hardProcess: false,
      //         }
      //       );
      //     } else {
      //       if (
      //         data?.status === VendorPayoutTransactionStatus.PROCESSING_FAILED
      //       ) {
              // res = await vendorPayoutService.retryPayout(
      //           Number(vendorPayoutID)
      //         );
      //       } else {
      //         res = await vendorPayoutService.processPayout(
      //           Number(vendorPayoutID),
      //           {
      //             hardProcess: true,
      //           }
      //         );
      //       }
      //     }
      //     if (
      //       res.status &&
      //       res?.status === VendorPayoutTransactionStatus.PROCESSED
      //     ) {
      //       EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
      //         message: "Payout Processed Successfully",
      //         type: CustomSnackbarTypes.SUCCESS,
      //       });
      //     } else {
      //       EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
      //         message: "Payout was not processed",
      //         type: CustomSnackbarTypes.ERROR,
      //       });
      //     }
      //   } catch (err) {
      //     console.log(err);
      //   } finally {
      //     callback();
      //     refresh();
      //     unselectAll();
      //   }
      // });
    }
  };

  const SubmitHandler = async () => {
    if (!paymentType) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Select the payout Type to proceed",
        type: CustomSnackbarTypes.WARNING,
      });
      return;
    } else {
      try {
        let res: VendorPayoutResponse;
        if (paymentType === VendorPaymentType.SOFTPAYOUT) {
          res = await vendorPayoutService.processPayout(Number(data?.id), {
            hardProcess: false,
          });
        } else {
          if (
            data?.status === VendorPayoutTransactionStatus.PROCESSING_FAILED
          ) {
            res = await vendorPayoutService.retryPayout(Number(data?.id));
          } else {
            res = await vendorPayoutService.processPayout(Number(data?.id), {
              hardProcess: true,
            });
          }
        }
        if (
          res.status &&
          res?.status === VendorPayoutTransactionStatus.PROCESSED
        ) {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Payout Processed Successfully",
            type: CustomSnackbarTypes.SUCCESS,
          });
        } else {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Payout was not processed",
            type: CustomSnackbarTypes.ERROR,
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        callback();
        refresh();
      }
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} scroll={"paper"} maxWidth="xl">
      <DialogTitle>Payout Type</DialogTitle>
      <DialogContent>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 2, right: 5 }}
        >
          {" "}
          <Close />{" "}
        </IconButton>
        <Grid
          container
          columns={1}
          rowSpacing={0.9}
          style={{ padding: "12px 12px 12px 12px" }}
        >
          <FormControl fullWidth style={{ width: "230px" }}>
            <InputLabel id="paymentType">Payment Type</InputLabel>
            <Select
              labelId="paymentType"
              id="paymentType"
              label="Payment Type"
              defaultValue={""}
              onChange={(event: any) => {
                setPaymentType(event.target.value);
              }}
            >
              <MenuItem
                key={VendorPaymentType.SOFTPAYOUT}
                value={VendorPaymentType.SOFTPAYOUT}
              >
                {`${VendorPaymentType.SOFTPAYOUT}`}
              </MenuItem>
              {
                <MenuItem
                  key={VendorPaymentType.HARDPAYOUT}
                  value={VendorPaymentType.HARDPAYOUT}
                >
                  {data?.status ===
                  VendorPayoutTransactionStatus.PROCESSING_FAILED
                    ? `Retry ${VendorPaymentType.HARDPAYOUT}`
                    : VendorPaymentType.HARDPAYOUT}
                </MenuItem>
              }
            </Select>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={isBulkAction ? bulkPayoutHandler : SubmitHandler}
          size={"large"}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            handleClose();
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
