export enum CouponTypeEnum {
    FLAT = 'FLAT',
    PERCENT = 'PERCENT'
}

export enum CouponProductType {
    TRAIL = 'TRAIL',
    SUBSCRIPTION = 'SUBSCRIPTION',
    ONE_MEAL_SERVICE = 'ONE_MEAL_SERVICE',
    STAR_CLEANER = 'STAR_CLEANER',
    CHEF_FOR_PARTY = 'CHEF_FOR_PARTY'
}
