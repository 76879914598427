import {Box, Button, Typography} from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useRecordContext, useRefresh, useUnselectAll } from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import HubServices from "../../services/HubServices";
import { useAppDispatch } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import MultiResourceSelectDialog from "../Common/CommonDialog/MultiResourceSelectDialog";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import DisplayNoDataFound from "../Common/DisplayNoDataFound";
import LocalityList from "../Locality/LocalityList";
const hubsService = new HubServices();
const LocalitiesListOfHub = () => {
  const record = useRecordContext();
  const dispatch = useAppDispatch();
  const refresh = useRefresh();
  const unSelectAll = useUnselectAll("Localities");
  const [pageSize, setPageSize] = useState(10);
  const [loadingData, setLoadingData] = useState(false);
  const handleCityListDialogOpen = () => {
    dispatch(
      setCustomSelectOpen({
        open: true,
      })
    );
  };

  const postLocalities = async (localities: number[]) => {
    if (localities && localities.length !== 0) {
      try {
        setLoadingData(true);
        const response = await hubsService.addLocalitiesToHub({
          hubId: record.id,
          localityIds: localities,
        });
        if(response.status===200){
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Successfully imported selected list",
            type: CustomSnackbarTypes.SUCCESS,
          });
        }
        unSelectAll();
        refresh();
      } catch (err) {
        console.error(err);
      }finally{
        setLoadingData(false);
      }
    }
  };

  const deleteSingleLocalityfromHub = useCallback(async (id: number) => {
    try {
      const resp = await hubsService.deleteLocalityFromHub(id);
      if(resp.status===200){
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Removed Successfully",
          type: CustomSnackbarTypes.SUCCESS
        });
      }
      refresh();
    } catch (error) {
      console.error(error);
    }
  },[refresh]);
  const columns: GridColDef[] = useMemo(()=>[
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "localityDetailsname",
      headerName: "Name",
      minWidth: 250,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.localityDetails.name}`,
    },
    {
      field: "localityId",
      headerName: "Locality ID",
      width: 100,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.localityId}`,
    },
    {
      field: "localityDetailsareaId",
      headerName: "Area ID",
      width: 100,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.localityDetails.areaId}`,
    },
    {
      field: "localityDetailsareaName",
      headerName: "Area Name",
      minWidth: 150,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.localityDetails.areaName}`,
    },
    {
      field: "localityDetailscityName",
      headerName: "City Name",
      width: 140,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.localityDetails.cityName}`,
    },
    {
      field: "localityDetailsrentPayingCapacity",
      headerName: "Rent Paying Capacity",
      width: 180,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.localityDetails.rentPayingCapacity}`,
    },
    {
      field: "localityDetailsisServiceable",
      headerName: "Serviceable",
      width: 100,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.localityDetails.isServiceable}`,
    },
    {
      field: "remove",
      headerName: "Remove",
      sortable: false,
      width: 140,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridValueGetterParams) => {
        return (
          <Button
            color="error"
            onClick={() => {
              deleteSingleLocalityfromHub(params.id as number);
            }}
            size="small"
          >
            Remove
          </Button>
        );
      },
    },
  ],[deleteSingleLocalityfromHub]);

  if (!record) {
    return null;
  }
  return (
    <>
      <MultiResourceSelectDialog
        onClose={(localities: number[]) => {
          postLocalities(localities);
        }}
        isMultiSelect={true}
        name={"Localities"}
        list={LocalityList}
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "1rem" }}>
        <Button variant="contained" disabled={loadingData} onClick={handleCityListDialogOpen}>
          Add Locality
        </Button>
      </Box>
      <Box sx={{ display: "flex", mb: "1rem" }} m={1}>
        <Box mr={2}>
          <Typography fontWeight={400} fontSize={'0.75em'} color={'#525252'}>
            {'Hub Name: '}
          </Typography>
          <Typography fontWeight={400} fontSize={'1rem'}>
            {record?.data?.hubDetails?.name}
          </Typography>
        </Box>
        <Box mr={2}>
          <Typography fontWeight={400} fontSize={'0.75em'} color={'#525252'}>
            {'Hub City Name: '}
          </Typography>
          <Typography fontWeight={400} fontSize={'1rem'}>
            {record?.data?.hubDetails?.cityName}
          </Typography>
        </Box>
        <Box mr={2}>
          <Typography fontWeight={400} fontSize={'0.75em'} color={'#525252'}>
            {'Hub Area Name: '}
          </Typography>
          <Typography fontWeight={400} fontSize={'1rem'}>
            {record?.data?.hubDetails?.areaName}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ height: "83vh", width: "100%" }}>
        {record?.data?.localities?.length !== 0 ? (
          <DataGrid
            rows={record?.data?.localities ?? []}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(pageSize) => {
              setPageSize(pageSize);
            }}
            rowsPerPageOptions={[5, 10, 25, 50]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        ):<DisplayNoDataFound text="No Localities Found"/>}
      </Box>
    </>
  );
};

export default LocalitiesListOfHub;
