import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material';
import ImagePreviewerDialog from './ImagePreviewerDialog';
import JsonViewer from './JsonViewer';

function ShowCityBanners(props:any) {
  const [bannerData,setBannerData]= useState<any>([])
  const [open,setOpen] =useState<boolean>(false);
  const [bannerUrl,setBannerUrl]=useState<string>('');

  const getBannerData = async() =>{
    const data = await fetch('https://yildun.thechefkart.com/api/customer-app-city?populate=deep');
    const dataBanner = await data.json();
    setBannerData(dataBanner?.data?.attributes);   
  }
    useEffect(()=>{
      getBannerData();
    },[])

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
      <Box fontSize={'1.4rem'} fontWeight={600} mt={2}>Banner Details for All Cities</Box>
        {
          bannerData&&bannerData?.cities&&bannerData?.cities.length>0&&bannerData?.cities.map((city:any)=>(
            <>
              <Box fontSize={'1rem'} fontWeight={600}>{city?.name}</Box>
              <Box display={'flex'} gap={'1rem'}>
                {
                  city?.banners&&city?.banners.length>0&&city?.banners.map((banner:any)=>(
                    <Box> 
                      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
                        <Box fontSize={"0.8rem"} fontWeight={600}>{banner?.service}</Box>
                      </Box>
                      <img src={banner?.bannerImage?.data?.attributes?.url} alt={banner?.service} style={{maxWidth:"350px", cursor:"pointer"}} onClick={()=>{
                        setOpen(true);
                        setBannerUrl(banner?.bannerImage?.data?.attributes?.url);
                      }}/>
                    </Box>
                  ))
                }
              </Box>
            </>
          ))
        }
        <Box fontSize={'1.4rem'} fontWeight={600} mt={2}>Default Banners</Box>
        <Box display={'flex'} gap={'1rem'}>
          {
            bannerData&&bannerData?.default_banners&&bannerData?.default_banners.map((banner:any)=>(
              <Box> 
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
                  <Box fontSize={"0.8rem"} fontWeight={600}>{banner?.service}</Box>
                </Box>
                <img src={banner?.bannerImage?.data?.attributes?.url} alt={banner?.service} style={{maxWidth:"250px", cursor:"pointer"}} onClick={()=>{
                  setOpen(true);
                  setBannerUrl(banner?.bannerImage?.data?.attributes?.url);
                }}/>
              </Box>
            ))
          }   
        </Box> 
        {open && <ImagePreviewerDialog src={bannerUrl} open={open} onClose={() => setOpen(false)} />} 
        <JsonViewer data={bannerData} />  
    </Box>
  )
}

export default ShowCityBanners