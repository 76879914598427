import React, {useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import {RosterEnums} from "../../enums/RosterEnums";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";
import RosterService from "../../services/RosterService";
import RosterEmployeeResponse from "../../interfaces/Roster/RosterEmployeeResponse";
import AdminUserList from "../AdminUser/AdminUserList";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import {setCustomSelectOpen} from "../../store/reducers/customSelect";
import {useAppDispatch} from "../../store/hooks";

const rosterService = new RosterService();

const RosterList = () => {
    const dispatch = useAppDispatch();
    const [type, setType] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [openAddEmployee, setOpenAddEmployee] = useState<boolean>(false);
    const [employees, setEmployees] = useState<RosterEmployeeResponse[]>([]);
    const handleClose = () => {
        setShow(false);
    };

    const showEmployees = (type: RosterEnums) => {
        setEmployees([]);
        setType(type);
        setShow(true);
        getAllEmployees(type);
    };

    const getAllEmployees = async (type: string) => {
        setLoading(true);
        await rosterService.getAllEmployeesForRoster(type)
            .then((res) => {
                setEmployees(res);
                setLoading(false);
            })
    }

    const handleOpenAddEmployees = () => {
        setOpenAddEmployee(true);
        dispatch(setCustomSelectOpen({open: true, record: null}));
    };

    const handleAfterSelect = async (res: any) => {
        await rosterService.addEmployeesToRoster({rosterName: type, adminId: res?.id})
            .then(() => {
                getAllEmployees(type);
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Added successfully",
                    type: CustomSnackbarTypes.SUCCESS,
                });
            })
    };

    const handleRemove = (adminId: number) => {
        EventBus.emitEvent(
            EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG,
            {
                message: "Do you confirm to remove this employee?",
                onClose: (confirmed: boolean) => {
                    if (confirmed) {
                        rosterService.deleteEmployeesForRoster(adminId)
                            .then(() => {
                                getAllEmployees(type);
                                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                    message: "Deleted successfully",
                                    type: CustomSnackbarTypes.SUCCESS,
                                });
                            })
                    }
                },
                title: "Are you sure?",
            }
        );
    };

    return (
        <Box mt={6}>
            <Table size={'small'}>
                <TableHead>
                    <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(RosterEnums).map((eachRoster: RosterEnums) => {
                        return(
                            <TableRow key={eachRoster}>
                                <TableCell>
                                    {eachRoster}
                                </TableCell>
                                <TableCell>
                                    <Button variant={'contained'} size="small" onClick={()=>{showEmployees(eachRoster)}}>
                                        {'Show Employees'}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            <Dialog
                onClose={handleClose}
                open={show}
                scroll={"paper"}
                maxWidth="xl"
            >
                <DialogTitle>
                    <Typography variant={'h5'}>
                        {`Employee List`}
                    </Typography>
                    <Typography variant={'body2'}>
                        Total Employees - {employees.length}
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{minWidth: '400px'}}>
                    <Box>
                        <Table size={'small'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            {employees.length > 0 ?
                                employees.map((each)=> {
                                    return(
                                        <TableBody key={each?.id}>
                                            <TableRow>
                                                <TableCell>
                                                    {each?.adminDetails?.name || ''}
                                                </TableCell>
                                                <TableCell>
                                                    {each?.adminDetails?.email || ''}
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        size={'small'}
                                                        color={'error'}
                                                        variant={'contained'}
                                                        onClick={() => {
                                                            handleRemove(each?.id)
                                                        }}
                                                    >
                                                        {'Remove'}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )
                                })
                            : (loading ?
                               ''
                                : <caption>No Employees added</caption>
                            )}
                        </Table>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button size={"small"} variant="contained" onClick={handleOpenAddEmployees}>
                        {'Add Employees'}
                    </Button>
                    <Button
                        variant="contained"
                        color={"error"}
                        onClick={() => {
                            handleClose();
                        }}
                        size={"small"}
                    >
                        {'Close'}
                    </Button>
                </DialogActions>
            </Dialog>
            {openAddEmployee && (
                <ResourceSelectDialog
                    name={'AdminUsers'}
                    list={AdminUserList}
                    onClose={handleAfterSelect}
                />
            )}
        </Box>
    );
};

export default RosterList;
