import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField
} from "@mui/material";
import { useRefresh, useShowContext } from "react-admin";
import React, { useCallback, useEffect, useState } from 'react';
import EventBus from "../../EventBus";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import ChefForPartyService from "../../services/ChefForPartyService";
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment";
import ChefForPartyReScheduleReasons from "../../enums/ChefForPartyReScheduleReasons";
import { ChefPartyTimeEnum } from "../../enums/ChefForPartyTimeEnums";
import { getReasonCategory } from "./constants/constant";
import { ActionTypeEnum } from "../../enums/ActionTypeEnum";
import ReasonSubreasonModuleEnum from "../../enums/ReasonSubreasonModuleEnum";
import ChefForPartStatusEnum from "../../enums/ChefForPartStatusEnum";

interface Props {
  data: any;
  open: boolean;
  callback: () => void;
}

const chefForPartyService = new ChefForPartyService();

const ChefForPartyReScheduleDialog = (props: Props) => {
  const refresh = useRefresh();
  const [rescheduleReasonsOptionMapping,setRescheduleReasonsOptionMapping]=useState<any>([]);
  const [reasonSubCategoryArray, setReasonSubCategoryArray] = useState<string[] | null>(null);
  const [subReason, setSubReason] = useState<string | null>(null)
  const [date, setDate] = useState<any>(null);
  const [time, setTime] = useState<any>(null);
  const [reasonCategory, setReasonCategory] = useState<string | null>(null);
  const { record } = useShowContext();


  const loadData = useCallback(async () => {
    const module = ReasonSubreasonModuleEnum.CHEF_FOR_PARTY;
    switch (record.status) {
      case ChefForPartStatusEnum.CHEF_ALLOTTED:
        let sampleReasonSubReason1 = await getReasonCategory(module ,ActionTypeEnum.POST_ALLOCATION_RESCHEDULED);
        setRescheduleReasonsOptionMapping(sampleReasonSubReason1);
        break;
      case ChefForPartStatusEnum.PENDING:
        let sampleReasonSubReason2 = await getReasonCategory(module ,ActionTypeEnum.PRE_ALLOCATION_RESCHEDULED);
        setRescheduleReasonsOptionMapping(sampleReasonSubReason2);
        break;
      case ChefForPartStatusEnum.CHEF_CONFIRMED:
        let sampleReasonSubReason3 = await getReasonCategory(module ,ActionTypeEnum.POST_ALLOCATION_RESCHEDULED);
        setRescheduleReasonsOptionMapping(sampleReasonSubReason3);
        break;
      case ChefForPartStatusEnum.ONGOING:
        let sampleReasonSubReason4 = await getReasonCategory(module ,ActionTypeEnum.POST_ALLOCATION_RESCHEDULED);
        setRescheduleReasonsOptionMapping(sampleReasonSubReason4);
        break;
    }
  }, [record?.status]);

  useEffect(()=>{
    loadData();
  },[loadData])

  let handleClose = () => {
    setReasonCategory(null)
    setSubReason(null)
    props.callback();
  };

  const handleReasonCategoryChange = (event: any) => {
    setSubReason(null);
    const selectedCategory:any = rescheduleReasonsOptionMapping.find(
      (option:any) => option.category === event.target.value
    );

    if (selectedCategory) {
      setReasonSubCategoryArray(selectedCategory?.subCategory);
      setReasonCategory(event.target.value);
    }
  }

  function showSubCategoryOrNot(reasonCategory: string) {
    if (reasonCategory?.toLowerCase() === "others" || reasonCategory?.toLowerCase() === 'other') {
      return (
        <FormControl style={{ width: 500 }}>
          <TextField value={subReason} onChange={(e)=>setSubReason(e.target.value)} label="Write a reason" />
        </FormControl>
      )
    }
    else if(reasonSubCategoryArray?.length !== 0) {
      return (
          <FormControl style={{width:500}}>
          <InputLabel id="select-reason">Select a Subreason</InputLabel>
          <Select
            labelId="select-reason"
            id="select-reason"
            defaultValue="Select a reason"
            value={subReason}
            onChange={(e)=>setSubReason(e.target.value)}
          >
            {reasonSubCategoryArray?.map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

      )
    } else {
      return <></>;
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      scroll={"paper"}
      maxWidth="xl"
    >
      <DialogTitle>{"Reschedule Chef for Party"}</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          justifyContent={"space-evenly"}
          style={{ padding: "12px 12px 12px 12px" }}
          direction="column"
          alignItems="center"
        >
          <Grid item xs={12}>
            <FormControl style={{ width: 500 }}>
              <DatePicker
                label="Reschedule Date"
                inputFormat="dd MMM yyyy"
                disableMaskedInput
                value={date}
                minDate={new Date()}
                onChange={(value: any) => {
                  setDate(moment(value).format("YYYY-MM-DD"));
                }}
                renderInput={(params: any) => <TextField {...params} style={{marginRight: '12px'}}/>}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl style={{ width: 500 }}>
              <InputLabel id="forTime">Reschedule Time</InputLabel>
              <Select
                labelId="forTime"
                id="time"
                value={time}
                label="Reschedule Time"
                onChange={(event: any) => {
                  setTime(event.target.value);
                }}
              >
                {Object.values(ChefPartyTimeEnum).map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl style={{ width: 500 }}>
              <InputLabel id="select-label">Tell us Why?</InputLabel>
              <Select
                labelId="select-label"
                id="select-label"
                defaultValue="Tell us Why?"
                value={reasonCategory}
                onChange={handleReasonCategoryChange}
              >
                {rescheduleReasonsOptionMapping.map((item:any) => {
                  return (
                    <MenuItem key={item.category} value={item.category}>
                      {item.category}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {
              reasonCategory && showSubCategoryOrNot(reasonCategory!)
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginTop: "12px" }}>
        <Button
          variant="contained"
          onClick={() => {
            if (!reasonCategory || !date || !time) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Fill the details to proceed",
                type: CustomSnackbarTypes.WARNING
              });
              return;
            }
            if(reasonSubCategoryArray?.length !== 0 && !subReason) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Fill the details to proceed",
                type: CustomSnackbarTypes.WARNING
              });
              return;
            }
            if (reasonCategory === ChefForPartyReScheduleReasons.OTHERS && !subReason) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Fill the reason to proceed",
                type: CustomSnackbarTypes.WARNING
              });
              return;
            }
            chefForPartyService.reScheduleChefForParty(props?.data?.id, {
              date,
              time,
              rescheduleReason: reasonCategory,
              rescheduleSubReason:subReason
            }).then(() => {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Reschedule Successfully",
                type: CustomSnackbarTypes.SUCCESS,
              });
              refresh();
              handleClose();
            });
          }}
          size={"large"}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            handleClose();
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ChefForPartyReScheduleDialog;
