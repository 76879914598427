import {
    Box,
    Button,
    FormControl,
    Grid,
    TextField,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Create} from "react-admin";
import TransactionButton from "../Common/TransactionButton";
import {useLocation, useNavigate} from "react-router-dom"
import ChefForPartyService from "../../services/ChefForPartyService";
import {ChefForPartyResponse} from "../../interfaces/ChefForParty/ChefForPartyResponse";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";

const chefForPartyService = new ChefForPartyService();

const ChefForPartyEdit = (props: any) => {
    const location = useLocation();
    const chefForPartyId = location.pathname.split('/')[2]
    const [houseId, setHouseId] = useState<any>(null);
    const [date, setDate] = useState<any>(null);
    const [noOfAdults, setNoOfAdults] = useState(0);
    const [noOfChildren, setNoOfChildren] = useState(0);
    const [noOfDishes, setNoOfDishes] = useState(0);
    const [noOfStarters, setNoOfStarters] = useState(0);
    const [noOfMainCourse, setNoOfMainCourse] = useState(0);
    const [noOfDesserts, setNoOfDesserts] = useState(0);
    const [noOfSideDishes, setNoOfSideDishes] = useState(0);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [bartender, setBartender] = useState<number>(0);
    const [waiter, setWaiter] = useState<number>(0);

    const navigate = useNavigate();

    const calculatePrice = () => {
        let addOns = [];
        if(bartender > 0) {
            addOns.push({"addOnId": 1,"quantity": bartender,"duration": 0})
        }
        if(waiter > 0) {
            addOns.push({"addOnId": 2,"quantity": waiter,"duration": 0})
        }
        let dishesCountObj: any = {};
        dishesCountObj["noOfStarters"] = noOfStarters
        dishesCountObj["noOfMainCourse"] = noOfMainCourse
        dishesCountObj["noOfDesserts"] = noOfDesserts
        dishesCountObj["noOfSideDishes"] = noOfSideDishes
        if (noOfDishes !== 0) {
          chefForPartyService
            .calculateDynamicPrice({
              date,
              houseId,
              noOfAdults: Number(noOfAdults),
              noOfChildren: Number(noOfChildren),
              noOfDishes: Number(noOfDishes),
              addOns,
              dishesCountObj,
            })
            .then((res: any) => {
              if (res?.totalAmount) {
                setTotalPrice(Number(res.totalAmount));
              }
            });
        }
    };

    useEffect(() => {
        if(!chefForPartyId) return;
        chefForPartyService.fetchOneChefForParty(Number(chefForPartyId)).then((res) => {
            const {
                houseId,
                date,
                noOfAdults,
                noOfChildren,
                noOfDesserts,
                noOfDishes,
                noOfMainCourse,
                noOfSideDishes,
                noOfStarters,
                orders,
            } = res?.data;
            console.log(res?.data);
            setNoOfAdults(noOfAdults);
            setHouseId(houseId);
            setDate(date);
            setNoOfChildren(noOfChildren);
            setNoOfDishes(noOfDishes);
            setNoOfStarters(noOfStarters);
            setNoOfMainCourse(noOfMainCourse);
            setNoOfDesserts(noOfDesserts);
            setNoOfSideDishes(noOfSideDishes);
            orders.map((eachOrder: any) => {
                if(eachOrder?.orderItems?.length !== 0){
                    eachOrder?.orderItems.map((eachOrderItem: any) => {
                        if(eachOrderItem?.serviceAddons?.id === 1){
                            setBartender(prevState => prevState + Number(eachOrderItem?.quantity));
                        }
                        if(eachOrderItem?.serviceAddons?.id === 2){
                            setWaiter(prevState => prevState + Number(eachOrderItem?.quantity));
                        }
                        return eachOrderItem;
                    })
                }
                return eachOrder;
            })
        })
    }, [chefForPartyId]);


    return (
      <Create title={"Edit Chef for party"}>
        <Grid
          container
          columns={1}
          rowSpacing={1}
          style={{ padding: "12px 12px 12px 12px" }}
        >
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="No of Adults"
                id="noOfAdults"
                value={noOfAdults}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e: any) => {
                  setNoOfAdults(e.target.value);
                  setTotalPrice(0);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="No of Children"
                id="noOfChildren"
                value={noOfChildren}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e: any) => {
                  setNoOfChildren(e.target.value);
                    setTotalPrice(0);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="No of Bartender"
                id="noOfBartender"
                value={bartender}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e: any) => {
                  setBartender(e.target.value);
                    setTotalPrice(0);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="No of Waiter"
                id="noOfWaiter"
                value={waiter}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e: any) => {
                  setWaiter(e.target.value);
                    setTotalPrice(0);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="No of Dishes"
                id="noOfDishes"
                disabled={true}
                value={noOfDishes}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e: any) => {
                  setNoOfDishes(e.target.value);
                    setTotalPrice(0);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="No of Starter"
                id="noOfStarters"
                value={noOfStarters}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e: any) => {
                  setNoOfDishes(
                    Number(noOfSideDishes) +
                      Number(noOfDesserts) +
                      Number(noOfMainCourse) +
                      Number(e.target.value)
                  );
                  setNoOfStarters(e.target.value);
                    setTotalPrice(0);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="No of Main Course"
                id="noOfMainCourse"
                value={noOfMainCourse}
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                onChange={(e: any) => {
                  setNoOfDishes(
                    Number(noOfSideDishes) +
                      Number(noOfDesserts) +
                      Number(e.target.value) +
                      Number(noOfStarters)
                  );
                  setNoOfMainCourse(e.target.value);
                    setTotalPrice(0);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="No of Desserts"
                id="noOfDesserts"
                value={noOfDesserts}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e: any) => {
                  setNoOfDishes(
                    Number(noOfSideDishes) +
                      Number(e.target.value) +
                      Number(noOfMainCourse) +
                      Number(noOfStarters)
                  );
                  setNoOfDesserts(e.target.value);
                    setTotalPrice(0);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: "230px" }}>
              <TextField
                label="No of SideDishes"
                id="noOfSideDishes"
                value={noOfSideDishes}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e: any) => {
                  setNoOfDishes(
                    Number(e.target.value) +
                      Number(noOfDesserts) +
                      Number(noOfMainCourse) +
                      Number(noOfStarters)
                  );
                  setNoOfSideDishes(e.target.value);
                    setTotalPrice(0);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
                <Box display={'flex'} alignItems={'center'} style={{flexWrap: 'wrap'}}>
                    <FormControl style={{width: "230px"}}>
                        <TextField
                            label="Total Price"
                            id="totalPrice"
                            value={totalPrice}
                            disabled={true}
                            type="number"
                            InputProps={{inputProps: {min: 0}}}
                        />
                    </FormControl>
                    <TransactionButton
                        name={"Calculate Price"}
                        onClickHandler={() => {
                            calculatePrice();
                        }}
                        style={{marginLeft: '30px'}}
                    />
                </Box>
            </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              disabled={totalPrice === 0}
              onClick={() => {
                  let addOns = [];
                  if(bartender > 0) {
                      addOns.push({"addOnId": 1,"quantity": bartender,"duration": 0})
                  }
                  if(waiter > 0) {
                      addOns.push({"addOnId": 2,"quantity": waiter,"duration": 0})
                  }
                  chefForPartyService
                    .editChefForPartyService(Number(chefForPartyId), {
                      noOfAdults: Number(noOfAdults),
                      noOfChildren: Number(noOfChildren),
                      noOfDishes: Number(noOfDishes),
                      noOfStarters: Number(noOfStarters),
                      noOfMainCourse: Number(noOfMainCourse),
                      noOfDesserts: Number(noOfDesserts),
                      addOns,
                      noOfSideDishes: Number(noOfSideDishes),
                    })
                    .then((data: ChefForPartyResponse) => {
                      if (data?.id) {
                        navigate(`/ChefForParty/${data?.id}/show`, {
                          replace: true,
                        });
                          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                              message: "Edited Successfully",
                              type: CustomSnackbarTypes.SUCCESS,
                          });
                      }
                    });
                }
              }
              size={"large"}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Create>
    );
};

export default ChefForPartyEdit;
