import { Box, Button, Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import CookSubscriptionInterface from "../../interfaces/Cook/CookSubscriptionInterface";
import { CookService } from "../../services/CookServices";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import {useRefresh } from "react-admin";
import UserBundleService from "../../services/BundleService";

const cookService = new CookService();
const userBundleService=new UserBundleService()

interface InputProps {
  subscriptionId: number;
}

function CustomLinkField(props: any) {
  return (
    <Box sx={{ width: "100px", margin: "0.5rem" }}>
      <p style={{ margin: 0, color: "grey", fontSize: "0.7rem" }}>
        {props?.label}
      </p>
      <Link href={`/#/${props.type}/${props?.data}/show`}>
        <p style={{ margin: 0 }}>{props?.data || "Not Available"}</p>
      </Link>
    </Box>
  );
}

function CustomTextField(props: any) {
  return (
    <Box sx={{ width: "100px", margin: "0.5rem" }}>
      <p style={{ margin: 0, color: "grey", fontSize: "0.7rem" }}>
        {props?.label}
      </p>
      <p style={{ margin: 0 }}>{props?.data || "Not Available"}</p>
    </Box>
  );
}

const CookSubscriptionMetaShow = (props: InputProps) => {
  const [cookSubscriptionData, setCookSubscriptionData] =
    useState<CookSubscriptionInterface | null>(null);
  const refresh = useRefresh();  
  const[bundle,setBundle]=useState<any>();
  

    const handleChange = () => {
      EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
        message: "Confirm Pre Checkin",
        onClose: (confirmed: boolean) => {
          cookService.preCheckIn(Number(cookSubscriptionData?.cookId),Number(bundle?.houseId)).then((data)=>{
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
              message: "Pre Checkin Successful",
              type: CustomSnackbarTypes.SUCCESS,
            });
            refresh();
          }).catch(error=>{
            console.log(error); 
          })
        }
    })
  }

  const fetchBundle=(bundleId:number)=>{
    userBundleService.fetchOneUserBundleMapping(bundleId).then((data)=>{
      setBundle(data?.data);
    }).catch((err)=>{
      console.log(err)
    }
    )
  }

  useEffect(() => {
    cookService
      .getSubscriptionForACook(props.subscriptionId)
      .then((data) => {
        setCookSubscriptionData(data);
        fetchBundle(data.userBundleMappingId);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props]);

  if (!cookSubscriptionData) return <></>;

  return (
    <div style={{ display: "flex" }}>
      <div style={{ margin: "1rem 10rem 0 0" }}>
        {bundle&&bundle?.houseId&&<Button variant="contained" onClick={handleChange}>Pre CheckIn</Button>}
        <CustomTextField label="Id" data={cookSubscriptionData?.id} />
        <CustomLinkField
          label="Cook Id"
          data={cookSubscriptionData?.cookId}
          type="cooks"
        />
        <CustomTextField
          label="Subscription Id"
          data={cookSubscriptionData?.subscriptionId}
        />
        <CustomLinkField
          label="User Bundle Mapping Id"
          data={cookSubscriptionData?.userBundleMappingId}
          type="UserBundles"
        />
        <CustomTextField
          label="Start Date"
          data={cookSubscriptionData?.startDate}
        />
        <CustomTextField
          label="Start Slot"
          data={cookSubscriptionData?.startSlot}
        />
        <CustomTextField
          label="End Date"
          data={cookSubscriptionData?.endDate}
        />
        <CustomTextField
          label="End Slot"
          data={cookSubscriptionData?.endSlot}
        />
      </div>
      <div style={{ margin: "1rem 10rem 0 0" }}>
        <CustomTextField
          label="Support End Date"
          data={cookSubscriptionData?.supportEndDate}
        />
        <CustomTextField
          label="Support End Slot"
          data={cookSubscriptionData?.supportEndSlot}
        />
        <CustomTextField
          label="Leave Taken"
          data={cookSubscriptionData?.leaveTaken}
        />
        <CustomTextField
          label="Leave Allowed"
          data={cookSubscriptionData?.leaveAllowed}
        />
        <CustomTextField
          label="Cancelled At"
          data={cookSubscriptionData?.cancelledAt}
        />
        <CustomTextField
          label="Cancelled By Id"
          data={cookSubscriptionData?.cancelledById}
        />
        <CustomTextField
          label="Cook Cut"
          data={cookSubscriptionData?.cookCut}
        />
        <CustomTextField
          label="Closure Category"
          data={cookSubscriptionData?.closureCategory}
        />
      </div>
      <div style={{ margin: "1rem 10rem 0 0" }}>
        <CustomTextField
          label="Closure Sub Category"
          data={cookSubscriptionData?.closureSubCategory}
        />
      </div>
    </div>
  );
};

export default CookSubscriptionMetaShow;
