import { Box, Chip, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { Button } from "react-admin";

const ServeListSlotsDialog = (props:any)=>{
    const handleCancel = () => {
        props.setOpen(false);
    };

    return (
        <div>
        <Dialog open={props.open}>
          <DialogTitle> {"Available Slots"} </DialogTitle>
          <DialogContent>
            <p>Cook Id: {props?.cookId}</p>
            <p>Date: {props?.date}</p>
            <p>No Of People: {props?.noOfPeople}</p>
            <Box display={"flex"} gap={"0.5rem"} flexWrap={"wrap"}>
                {
                    props?.slots&&props?.slots.length>0?props?.slots.map((slot:any,i:number)=>(
                        i>11?
                        slot?.status==="FREE"?<Chip variant="outlined" label={slot?.timeSlot}/>
                        :<Chip sx={{backgroundColor:"grey"}} label={slot?.timeSlot}/>:<></>
                    )):"NO Slots Available"
                }
            </Box>
          </DialogContent>
          <DialogActions>
          <Button
            onClick={() => {
              handleCancel();
            }}
            variant="contained"
            color="primary"
            label="Close"
          />
          </DialogActions>
        </Dialog>
      </div>
    )

}

export default ServeListSlotsDialog