import { Close } from "@mui/icons-material";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem,
    Select, TextField
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import * as React from "react";
import { useRefresh } from "react-admin";
import UpdateDateAndSlotsDto from "../../dtos/bundle/updateDateAndSlotDto";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import UserBundleService from "../../services/BundleService";
import { CustomSnackbarTypes } from "./CustomSnackBar";

interface InputProps {
    data: any;
    open: boolean;
    callback: (data: any) => void;
}

export const UpdateDateAndSlotDialog = (props: InputProps) => {
    const userBundleService = new UserBundleService();
    let [date, setDate] = React.useState<Date | null>(new Date())
    let [slot, setSlot] = React.useState<any>();
    
    const refresh = useRefresh();
    const handleClose = () => {
        props.callback({
            slot,
            date
          })
          setDate(null);
          setSlot('')
    }
    return (
        <Dialog onClose={handleClose} open={props.open} scroll={"paper"} maxWidth="xl">
            <DialogTitle>Update Date And Slot</DialogTitle>
            <DialogContent>
              <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
              <Grid container columns={1} rowSpacing={0.9} style={{ padding: "12px 12px 12px 12px" }}>
                <FormControl fullWidth>
                  <FormControl fullWidth style={{ marginTop: "20px" }}>
                    <DatePicker
                        label="Date"
                        inputFormat="dd MMM yyyy"
                        disableMaskedInput
                        value={date}
                        minDate={moment().toDate()}
                        onChange={(value) => setDate(value)}
                        renderInput={(params) => <TextField {...params} />}
                    />
                  </FormControl>
                  {!!props.data && props.data.userBundleMeta && (
                    <FormControl fullWidth style={{ marginTop: "20px" }}>
                      <InputLabel id="forSlot">Slot</InputLabel>
                      <Select
                        labelId="forSlot"
                        id="Slot"
                        value={slot}
                        label="Slot"
                        onChange={(event) => setSlot(event.target.value)}
                      >
                        {!!props.data &&
                          !!props.data.userBundleMeta &&
                          props.data?.userBundleMeta?.mealMapping.map((e: any) => (
                            <MenuItem key={e} value={e}>
                              {e}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}
                </FormControl>
              </Grid>
            </DialogContent>
            <DialogActions style={{ marginTop: "12px" }}>
                <Button
                    variant="contained"
                    onClick={() => {
                        if(!date || !slot) {
                            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                message: "Please Provide all fields",
                                type: CustomSnackbarTypes.ERROR
                            })
                            return;
                        }
                        userBundleService
                            .updateDatesAndSlots(props.data.id, new UpdateDateAndSlotsDto(moment(date).format("YYYY-MM-DD"), slot))
                            .then((res) => {
                                handleClose();
                                refresh();
                            });
                         }}
                    size={"large"}> 
                    Submit
                </Button>
                <Button
                    variant="contained"
                    color={"error"}
                    onClick={() => {
                        handleClose();
                    }}
                    size={"large"} >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};
