import React from 'react'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';

type InputProps = {
    src: string;
    open: boolean;
    onClose: () => void;
}
const ImagePreviewerDialog = ({ src, open, onClose }: InputProps) => {
    const [rotation, setRotation] = React.useState(0);
    const [width, setWidth] = React.useState('auto');
    const rotateImageLeft = () => {
        setRotation(rotation - 90);
        if (rotation % 180 !== 0) setWidth('auto');
        else setWidth('600px')
    }
    const rotateImageRight = () => {
        setRotation(rotation + 90);
        if (rotation % 180 !== 0) setWidth('auto');
        else setWidth('655px')
    }
    return (
        <>
            <Dialog open={open} onClose={() => {onClose()}} maxWidth={'lg'}>
                <Box p={2}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography>
                            {'Image Preview'}
                        </Typography>
                        <IconButton onClick={() => onClose()}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <div
                        className={`previewer-image-container`}
                        style={{ width: `${width}`}}
                    >
                        
                        <img
                            src={src}
                            alt={'alt'}
                            className="previewer-image"
                            style={{ transform: `rotate(${rotation}deg)`, objectFit: 'contain' }}
                        />
                        <div style={{
                            height: '40px',
                            background: '#fff',
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingTop: '10px',
                            paddingLeft: '10px',
                            paddingRight: '10px'
                        }}>
                            <IconButton onClick={() => rotateImageLeft()}>
                                <RotateLeftIcon/>
                            </IconButton>
                            <IconButton>
                                <RotateRightIcon onClick={() => rotateImageRight()} />
                            </IconButton>
                        </div>
                    </div>
                </Box>
            </Dialog>
        </>
    )
}

export default ImagePreviewerDialog