export enum CookSlotTimeEnum {
    AM_05_00 = '05:00 AM',
    AM_05_30 = '05:30 AM',
    AM_06_00 = '06:00 AM',
    AM_06_30 = '06:30 AM',
    AM_07_00 = '07:00 AM',
    AM_07_30 = '07:30 AM',
    AM_08_00 = '08:00 AM',
    AM_08_30 = '08:30 AM',
    AM_09_00 = '09:00 AM',
    AM_09_30 = '09:30 AM',
    AM_10_00 = '10:00 AM',
    AM_10_30 = '10:30 AM',
    AM_11_00 = '11:00 AM',
    AM_11_30 = '11:30 AM',
    PM_12_00 = '12:00 PM',
    PM_12_30 = '12:30 PM',
    PM_01_00 = '01:00 PM',
    PM_01_30 = '01:30 PM',
    PM_02_00 = '02:00 PM',
    PM_02_30 = '02:30 PM',
    PM_03_00 = '03:00 PM',
    PM_03_30 = '03:30 PM',
    PM_04_00 = '04:00 PM',
    PM_04_30 = '04:30 PM',
    PM_05_00 = '05:00 PM',
    PM_05_30 = '05:30 PM',
    PM_06_00 = '06:00 PM',
    PM_06_30 = '06:30 PM',
    PM_07_00 = '07:00 PM',
    PM_07_30 = '07:30 PM',
    PM_08_00 = '08:00 PM',
    PM_08_30 = '08:30 PM',
    PM_09_00 = '09:00 PM',
    PM_09_30 = '09:30 PM',
    PM_10_00 = '10:00 PM',
    PM_10_30 = '10:30 PM',
    PM_11_00 = '11:00 PM',
}

export const CookSlotTimeEnumArray = [
    CookSlotTimeEnum.AM_05_00,
    CookSlotTimeEnum.AM_05_30,
    CookSlotTimeEnum.AM_06_00,
    CookSlotTimeEnum.AM_06_30,
    CookSlotTimeEnum.AM_07_00,
    CookSlotTimeEnum.AM_07_30,
    CookSlotTimeEnum.AM_08_00,
    CookSlotTimeEnum.AM_08_30,
    CookSlotTimeEnum.AM_09_00,
    CookSlotTimeEnum.AM_09_30,
    CookSlotTimeEnum.AM_10_00,
    CookSlotTimeEnum.AM_10_30,
    CookSlotTimeEnum.AM_11_00,
    CookSlotTimeEnum.AM_11_30,
    CookSlotTimeEnum.PM_12_00,
    CookSlotTimeEnum.PM_12_30,
    CookSlotTimeEnum.PM_01_00,
    CookSlotTimeEnum.PM_01_30,
    CookSlotTimeEnum.PM_02_00,
    CookSlotTimeEnum.PM_02_30,
    CookSlotTimeEnum.PM_03_00,
    CookSlotTimeEnum.PM_03_30,
    CookSlotTimeEnum.PM_04_00,
    CookSlotTimeEnum.PM_04_30,
    CookSlotTimeEnum.PM_05_00,
    CookSlotTimeEnum.PM_05_30,
    CookSlotTimeEnum.PM_06_00,
    CookSlotTimeEnum.PM_06_30,
    CookSlotTimeEnum.PM_07_00,
    CookSlotTimeEnum.PM_07_30,
    CookSlotTimeEnum.PM_08_00,
    CookSlotTimeEnum.PM_08_30,
    CookSlotTimeEnum.PM_09_00,
    CookSlotTimeEnum.PM_09_30,
    CookSlotTimeEnum.PM_10_00,
    CookSlotTimeEnum.PM_10_30,
    CookSlotTimeEnum.PM_11_00,
]