export enum CityEnum {
    GURGAON = "GURGAON",
    NOIDA = "NOIDA",
    BENGALURU = "BENGALURU",
    DELHI = "DELHI",
    MUMBAI = "MUMBAI",
    FARIDABAD = "FARIDABAD",
    GHAZIABAD = "GHAZIABAD",
}

export enum UpdateCookDetailsCityEnum {
  GURGAON = "Gurgaon",
  NOIDA = "Noida",
  MUMBAI = "Mumbai",
  HYDERABAD = "Hyderabad",
  DELHI = "Delhi",
  CHENNAI = "Chennai",
  BANGALORE = "Bangalore",
  PUNE = "Pune",
  KOLKATA = "Kolkata",
  JAIPUR = "Jaipur",
  AHMEDABAD = "Ahmedabad",
  SURAT = "Surat",
  LUCKNOW = "Lucknow",
  INDORE = "Indore",
  PATNA = "Patna",
  BHOPAL = "Bhopal",
  COIMBATORE = "Coimbatore",
  CHANDIGARH = "Chandigarh",
  VADODARA = "Vadodara",
  VARANASI = "Varanasi",
  LUDHIANA = "Ludhiana",
  KANPUR = "Kanpur",
  NAGPUR = "Nagpur",
  AURANGABAD = "Aurangabad",
}

export const CityIdMapping : Record<string, number> = {
    GURGAON: 1,
    NOIDA: 2,
    BENGALURU: 3,
    DELHI: 4,
    MUMBAI: 5,
    FARIDABAD: 6,
    GHAZIABAD: 7,
}
