import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField as TextFieldMUI
} from "@mui/material";
import React, { useState } from 'react';
import {
    Datagrid, DateField,
    List, NumberField, SelectInput, TextField, useRecordContext, useRefresh
} from 'react-admin';
import { ExtensionStatusEnum } from '../../enums/ExtensionStatusEnum';
import { ZohoBaseUrl, ZohoModulesUrl } from "../../enums/zoho";
import { ExtensionService } from '../../services/ExtensionServices';
import CustomReferenceField from "../Common/CustomReferenceField";
import LinkField from "../Common/LinkField";
import TransactionButton from '../Common/TransactionButton';

const CancelButtonWrapper = (props: any) => {
    const record: any = useRecordContext();
    if(record.status === ExtensionStatusEnum.CANCELLED) {
        return null;
    }
    return (
        <TransactionButton name={"Cancel"} onClickHandler={()=> props.canceldHandler(record)}/>
    )
}

const filters = [
    <SelectInput
        label="Status"
        source="status"
        alwaysOn
        emptyText="Clear Filter"
        choices={
            Object.values(ExtensionStatusEnum)
                .map(value => {
                    return {
                        id: value,
                        name: value
                    }
                })
        }
    />
    //<TextInput label="Search by phone" source="mobile" alwaysOn />,
];


const Extension = (props: any) => {
    const refresh = useRefresh();
    const extensionService = new ExtensionService()
    const [id , setId] = useState<number>()
    const [text, setText] = useState("")
    const canceldHandler = (data: any) => {
        setCancelReasonModal(true);
        setId(data.id)
    }
    const [cancelReasonModal, setCancelReasonModal] = useState<boolean>(false);
    const handleClose = () => {}
    return (
      <div>
        <Dialog
          onClose={handleClose}
          open={cancelReasonModal}
          scroll={"paper"}
          maxWidth="xl"
        >
          <DialogTitle>Cancel extension</DialogTitle>
          <DialogContent>
            <Grid
              container
              spacing={2}
              justifyContent={"space-evenly"}
              style={{ padding: "12px 12px 12px 12px" }}
            >
              <Grid item>
                <FormControl fullWidth>
                  <TextFieldMUI
                    id="reason"
                    onChange={(event) => {
                      setText(event.target.value);
                    }}
                    value={text}
                    label="Enter cancellation reason"
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ marginTop: "12px" }}>
            <Button
              variant="contained"
              onClick={() => {
                extensionService.cancelExtension(id!, text).then(() => {
                  refresh();
                  setCancelReasonModal(false);
                });
              }}
              size={"large"}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color={"error"}
              onClick={() => {
                setCancelReasonModal(false);
              }}
              size={"large"}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <List {...props} filters={filters} sort={{ field: 'id', order: 'DESC' }}>
          <Datagrid bulkActionButtons={false}>
            <NumberField source="id"/>
            <NumberField source="subscriptionId" sortable={false}/>
            <TextField source="cancellationReason" sortable={false}/>
            <TextField source="status" sortable={false}/>
            <CustomReferenceField label="User Bundle" listName={"UserBundles"} source={"userBundleMappingId"} sortable={false}/>
            <NumberField source="userSubscriptionLeaveId" sortable={false}/>
            <TextField source="userSubscriptionLeave.date" sortable={false} label="Leave Date"/>
            <TextField source="reason" sortable={false}/>
            <LinkField source='zohoId' href={`${ZohoBaseUrl}${ZohoModulesUrl.EXTENSION}`} sourceRoute targetBlank/>
            <TextField source="visits" sortable={false} />
            <DateField options={{day: 'numeric', month: 'short', year: 'numeric', hour: "numeric", minute: 'numeric', hourCycle: 'h12' }} source='createdAt' />
            <CancelButtonWrapper canceldHandler={canceldHandler} sortable={false}/>
          </Datagrid>
        </List>
      </div>
    );
};

export default Extension;
