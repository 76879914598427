import {FormControl, InputLabel, MenuItem, Grid, Select, TextField, Button, useMediaQuery, FormControlLabel, FormLabel, RadioGroup, Radio} from "@mui/material";
import moment from "moment";
import {useState} from "react";
import {
    BundleGenderPreference,
    BundlePlan, DynamicPricingSlotEnum,
} from "../../enums/common";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {ShortTimeEnum} from "../../enums/ShortTimeEnum";
import EventBus from "../../EventBus";
import {MealMappingInput} from "../Common/MealMappingInput";
import {DatePicker} from "@mui/x-date-pickers";
import {TrialsService} from "../../services/TrialServices";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";
import {Create} from "react-admin";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import HouseList from "../Houses/HouseList";
import TransactionButton from "../Common/TransactionButton";
import CommonDialogForUserAndHouse from "../Common/CommonDialogUserAndHouse";
import {setCustomSelectOpen} from "../../store/reducers/customSelect";
import {useAppDispatch} from "../../store/hooks";
import Box from '@mui/material/Box';
import {useNavigate} from "react-router-dom"

const trialsService = new TrialsService()

export const TrialsCreate = () => {
    const [slotName, setSlotName] = useState<string>("");
    const [houseId, setHouseId] = useState<any>(null);
    const [areaId, setAreaId] = useState<any>(null);
    const [time, setTime] = useState<string>("");
    const [genderPreference, setGenderPreference] = useState<string>("");
    const [date, setDate] = useState<any>(null);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [totalPeople, setTotalPeople] = useState<number>(0);
    const [bundlePlan, setBundlePlan] = useState<string>("");
    const [numberOfVisit, setNumberOfVisit] = useState<number>(0);
    const [mealMapping, setMealMapping] = useState<any>([]);
    const [open, setOpen] = useState(false);
    const [openHouseDialog, setOpenHouseDialog] = useState(false);
    // const [includeFreeTr, setIncludeFreeTr] = useState(true);
    // const [isPartOfDiscovery, setIsPartOfDiscovery] = useState<boolean>(true);
    const [utensils, setUtensils] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));


    const handleSelectHouse = () => {
        setOpenHouseDialog(true)
        dispatch(setCustomSelectOpen({
            open: true
        }));
    }

    const handleOpen = () => {
        handleSelectHouse();
    }

    const changePriceToDefaultVal = (defaultPrice: number = 0) => {
        if(totalPrice === 0) return;
        setTotalPrice(defaultPrice)
    };

    const handleCloseHouse = (record: any) => {
        setOpenHouseDialog(false);
        setHouseId(record?.id);
        setAreaId(record?.areaId);
        changePriceToDefaultVal();
    }

    let setVisitTime = (myIndex: number, data: any) => {
        setMealMapping(mealMapping.map((ele: any, index: number) => {
            if (index === myIndex) {
                return {...ele, time: data}
            }
            return ele;
        }));
        changePriceToDefaultVal();
    }

    let setVisitSlot = (myIndex: number, data: any) => {
        setMealMapping(mealMapping.map((ele: any, index: number) => {
            if (index === myIndex) {
                return {...ele, slot: data}
            }
            return ele;
        }));
        changePriceToDefaultVal();
    }

    let setBufferTime = (myIndex: number, data: any) => {
        setMealMapping(mealMapping.map((ele: any, index: number) => {
            if (index === myIndex) {
                return {...ele, bufferMinutes: Number(data)}
            }
            return ele;
        }));
        changePriceToDefaultVal();
    }
    const MealMappingValidation = (mealMapping: any, numberOfVisit: number) => {
        if (mealMapping.length === numberOfVisit) {
            for (const obj of mealMapping) {
                if (Object.keys(obj).length !== 3) {
                    return true
                }
            }
        }
        return false
    }

    const validate = () => {
        if(!houseId){
            showError('Please select a house');
            return false;
        } else if(bundlePlan === ''){
            showError('Please select a plan');
            return false;
        } else if(slotName === ''){
            showError('Please select a slot');
            return false;
        } else if(!date){
            showError('Please select a date');
            return false;
        } else if(time === ''){
            showError('Please select a time');
            return false;
        } else if(genderPreference === ''){
            showError('Please select a gender preference');
            return false;
        } else if(totalPeople === 0){
            showError('Please select total people');
            return false;
        } else if(numberOfVisit === 0){
            showError('Please select the total number Of Visit');
            return false;
        } else if (MealMappingValidation(mealMapping, numberOfVisit)) {
            showError('Please fill the meal mapping details');
            return false;
        }
        return true;
    };

    const calculatePrice = () => {
        if(
            areaId &&
            bundlePlan !== '' &&
            slotName !== '' &&
            date &&
            time !== '' &&
            genderPreference !== '' &&
            totalPeople !== 0 &&
            numberOfVisit !== 0 &&
            !MealMappingValidation(mealMapping, numberOfVisit)
        ) {
            trialsService.calculateDynamicPriceByAreaId({
                areaId,
                totalPeople,
                plan: bundlePlan,
                visitsPerDay: numberOfVisit,
                // "utensils": false,
                utensils,
                mealMapping,
                // includeFreeTr
            }).then((res) => {
                if(res?.price){
                    setTotalPrice(res.price);
                }
            })
        }
    };

    const showError = (title: string) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: title || "Fill the form to continue",
            type: CustomSnackbarTypes.ERROR,
        });
    }

    return (
        <Create title={'Create Trail'}>
            <Grid
                container
                columns={1}
                rowSpacing={2}
                style={{padding: "12px 12px 12px 12px"}}
            >
                <Grid item xs={4}>
                    <CommonDialogForUserAndHouse open={open} setOpen={setOpen} setHouse={setHouseId} setAreaId={setAreaId}/>
                    {openHouseDialog &&
                      <ResourceSelectDialog
                        name={'Houses'}
                        list={HouseList}
                        onClose={handleCloseHouse}
                      />}
                    <TransactionButton
                        name={"Create customer and House"}
                        onClickHandler={() => setOpen(true)}
                        style={{marginLeft: isSmall ? "0" : "75%"}}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TransactionButton
                        name={houseId ? `houseId: ${houseId}` : "Choose House"}
                        onClickHandler={() => handleOpen()}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{width: "230px"}}>
                        <InputLabel id="forBundlePlan">Bundle Plan</InputLabel>
                        <Select
                            labelId="forBundlePlan"
                            id="bundlePlan"
                            value={bundlePlan}
                            label="Bundle Plan"
                            onChange={(event: any) => {
                                setBundlePlan(event.target.value);
                                changePriceToDefaultVal();
                            }}
                        >
                            {Object.values(BundlePlan).map((e) => (
                                <MenuItem key={e} value={e}>
                                    {e}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{width: "230px"}}>
                        <DatePicker
                            label="Date"
                            inputFormat="dd MMM yyyy"
                            disableMaskedInput
                            minDate={new Date()}
                            value={date}
                            onChange={(value: any) => {
                                setDate(moment(value).format("YYYY-MM-DD"));
                                changePriceToDefaultVal();
                            }}
                            renderInput={(params: any) => <TextField {...params} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{width: "230px"}}>
                        <InputLabel id="forGenderPreference">Gender Preference</InputLabel>
                        <Select
                            labelId="forGenderPreference"
                            id="genderPreference"
                            value={genderPreference}
                            label="genderPreference"
                            onChange={(event: any) => {
                                setGenderPreference(event.target.value);
                                changePriceToDefaultVal();
                            }}
                        >
                            {Object.values(BundleGenderPreference).map((e) => (
                                <MenuItem key={e} value={e}>
                                    {e}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{width: "230px"}}>
                        <InputLabel id="forTotalPeople">Total People</InputLabel>
                        <Select
                            labelId="forTotalPeople"
                            id="totalPeople"
                            value={totalPeople}
                            label="total People"
                            onChange={(event: any) => {
                                setTotalPeople(event.target.value);
                                changePriceToDefaultVal();
                            }}
                        >
                            {Object.values([2, 3, 4, 5, 6, 7, 8]).map((e) => (
                                <MenuItem key={e} value={e}>
                                    {e}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{width: "230px"}}>
                        <InputLabel id="NumberOfVisit">Number Of Visits</InputLabel>
                        <Select
                            labelId="NumberOfVisit"
                            id="numberOfVisit"
                            label="NumberOfVisits"
                            value={numberOfVisit}
                            onChange={(event: any) => {
                                let numberOfVisit = event.target.value;
                                let mealMapping = [];
                                for (let i = 0; i < numberOfVisit; i++) {
                                    mealMapping.push({ bufferMinutes: 30 });
                                }
                                setMealMapping(mealMapping);
                                setNumberOfVisit(event.target.value);
                                changePriceToDefaultVal();
                            }}
                        >
                            {Object.values([1, 2]).map((value) => (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <div style={{width: "600px"}}>
                        {
                            !!mealMapping &&
                            Array.isArray(mealMapping) &&
                            mealMapping.length > 0 &&
                            mealMapping.map((value, index) => (
                                <MealMappingInput
                                    key={index}
                                    data={value}
                                    setVisitTime={setVisitTime}
                                    setVisitSlot={setVisitSlot}
                                    setBufferTime={setBufferTime}
                                    index={index}
                                    isDynamicPricing={true}
                                    disabled={{
                                        bufferTime: true,
                                    }}
                                />
                            ))
                        }
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{width: "230px"}}>
                        <InputLabel id="forSlot">Select start Slot</InputLabel>
                        <Select
                            labelId="forSlot"
                            id="slot"
                            value={slotName}
                            label="Select start Slot"
                            onChange={(event: any) => {
                                setSlotName(event.target.value);
                                changePriceToDefaultVal();
                            }}
                        >
                            {Object.values(DynamicPricingSlotEnum).map((e) => (
                                <MenuItem key={e} value={e}>
                                    {e}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{width: "230px"}}>
                        <InputLabel id="forTime">Trial Time</InputLabel>
                        <Select
                            labelId="forTime"
                            id="time"
                            value={time}
                            label="trial time"
                            onChange={(event: any) => {
                                setTime(event.target.value);
                                changePriceToDefaultVal();
                            }}
                        >
                            {Object.values(ShortTimeEnum).map((e) => (
                                <MenuItem key={e} value={e}>
                                    {e}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {/* <Grid item xs={4}>
                    <FormGroup>
                        <FormControlLabel
                            onChange={(event: any, checked: boolean) => {
                                changePriceToDefaultVal()
                                setIncludeFreeTr(checked);
                            }}
                            disabled
                            control={<Checkbox checked={includeFreeTr} />}
                            label="Include Free TR?"
                        />
                    </FormGroup>
                </Grid> */}
                {/* <Grid item xs={4}>
                    <FormGroup>
                        <FormControlLabel
                            onChange={(event: any, checked: boolean) => {
                                setIsPartOfDiscovery(checked);
                            }}
                            control={<Checkbox checked={isPartOfDiscovery} />}
                            label="Is Discovery Trial"
                        />
                    </FormGroup>
                </Grid> */}
                <Grid item xs={4}>
                    <Box display={'flex'} alignItems={'center'} style={{flexWrap: 'wrap'}}>
                        <FormControl style={{width: "230px"}}>
                            <TextField
                                label="Total Price"
                                id="totalPrice"
                                value={totalPrice}
                                disabled={true}
                                type="number"
                                InputProps={{inputProps: {min: 0}}}
                                onChange={(event: any) => setTotalPrice(event.target.value)}
                            />
                        </FormControl>
                        <TransactionButton
                            name={"Calculate Price"}
                            onClickHandler={() => {
                                if(validate()){
                                    calculatePrice();
                                }
                            }}
                            style={{marginLeft: '30px'}}
                        />
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <FormControl>
                        <FormLabel id="utensils">Utensils</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(event: any) => {
                                setUtensils(event.target.value !== 'false');
                                changePriceToDefaultVal();
                            }}
                            value={utensils}
                        >
                            <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Required"
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="Not-Required"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            if(validate()){
                                if(totalPrice === 0){
                                    showError('Please calculate the price');
                                    return;
                                }
                                trialsService
                                    .createTrials({
                                        houseId,
                                        genderPreference,
                                        totalPrice: Number(totalPrice),
                                        totalPeople,
                                        slotName,
                                        time,
                                        utensils,
                                        bundlePlan,
                                        mealMapping,
                                        date: moment(date).format("YYYY-MM-DD"),
                                        // isPartOfDiscovery,
                                    })
                                    .then((data) => {
                                        if(data?.data.id) {
                                            navigate(`/Trials/${data?.data.id}/show`, {replace: true})
                                        }
                                    });
                            }
                        }}
                        size={"large"}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Create>
    )
}
