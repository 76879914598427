import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CookLeavesService } from "../../services/CookLeavesService";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { useNavigate } from "react-router-dom";


interface InputProps {
  data: any;
  open: boolean;
  setOpen: (data: any) => void;
}

const cookLeaveService = new CookLeavesService();

const ChangeLeaveDialog = (props: InputProps) => {
  const handleClose = () => {
    props.setOpen(false);
  }; 
  let navigate = useNavigate();
  const onConfirm = () => {
    cookLeaveService
      .editShortLeave(parseInt(props?.data?.leaveId),parseInt(props?.data?.cookId), props?.data?.payload)
      .then(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Leave Edited",
          type: CustomSnackbarTypes.SUCCESS,
        }); 
        navigate('/CookLeaves')   
      });
    handleClose();
  };
  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>
        Are you sure you want to proceed with the following changes?
      </DialogTitle>
      <DialogContent>
        <div>
          {props.data?.payload?.leaveDetails &&
            Array.isArray(props.data?.payload?.leaveDetails) &&
            props.data?.payload?.leaveDetails.map((leave: any, index: number) => {
              return (
                <div key={leave.houseId + index}>
                  <div>
                    <div>
                      <strong>House Id:</strong> {leave.houseId}
                    </div>
                    <div>
                      <strong>Added Time: </strong> 
                      {
                        leave?.addedSlots&&Array.isArray(leave?.addedSlots)&&leave?.addedSlots.length > 0?leave?.addedSlots.map((slotName:string)=>(
                          <span key={slotName}>{slotName+" ,"}</span>
                        )):<span> No slots to show</span>
                      }
                    </div>
                    <div>
                      <strong>Deleted Time: </strong>
                      {
                        leave?.deletedSlots&&Array.isArray(leave?.deletedSlots)&&leave?.deletedSlots.length > 0?leave?.deletedSlots.map((slotName:string)=>(
                          <span key={slotName}>{slotName+" ,"}</span>
                        )):<span> No slots to show</span>
                      }
                    </div>
                  </div>
                  <hr />
                </div>
              );
            })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} variant="contained" size={"large"}>
          confirm
        </Button>
        <Button
          onClick={handleClose}
          variant="contained"
          color={"error"}
          size={"large"}
        >
          cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeLeaveDialog;
