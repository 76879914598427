import React, { useEffect, useState } from "react";
import { OneMealService } from "../../services/OneMealService";
import { useRefresh } from "react-admin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextareaAutosize,
} from "@mui/material";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";

const oneMealService = new OneMealService();

interface InputProps {
  data: any;
  open: boolean;
  setOpen: (open: boolean) => any;
}

const AdminNotesForOneMeal = (props: InputProps) => {
  const refresh = useRefresh();
  const [notes, setNotes] = useState<string>(props?.data?.adminNotes);

  const handleChange = (event: any) => {
    setNotes(event.target.value);
  };

  useEffect(() => {
    setNotes(props?.data?.adminNotes);
  }, [props?.open, props?.data?.adminNotes]);

  return (
    <Dialog open={props.open} scroll={"paper"} maxWidth="xl">
      <DialogTitle>Edit Notes</DialogTitle>
      <DialogContent>
        <Grid
          container
          columns={1}
          rowSpacing={0.9}
          style={{ padding: "12px 12px 12px 12px" }}
        >
          <FormControl style={{ width: 500 }} fullWidth>
            <TextareaAutosize
              value={notes}
              onChange={handleChange}
              id="outlined-basic"
              minRows={10}
            />
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginTop: "12px" }}>
        <Button
          variant="contained"
          onClick={() => {
            oneMealService
              .adminNotesForOneMeal(Number(props?.data?.id), {
                adminNotes: notes,
              })
              .then((res) => {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: "Success",
                  type: CustomSnackbarTypes.SUCCESS,
                });
                props.setOpen(false);
                refresh();
              });
          }}
          size={"large"}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            props.setOpen(false);
            setNotes("");
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminNotesForOneMeal;
