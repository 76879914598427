import React from 'react';
import {Button, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

const HouseListForModule = (props: any) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Locality</TableCell>
                        <TableCell>Mobile</TableCell>
                        <TableCell>City</TableCell>
                        <TableCell>lat</TableCell>
                        <TableCell>long</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((row: any) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell scope="row">{row?.id}</TableCell>
                            <TableCell align="left">{row?.address}</TableCell>
                            <TableCell align="left">{row?.locality}</TableCell>
                            <TableCell align="left">{row?.mobile}</TableCell>
                            <TableCell align="left">{row?.city}</TableCell>
                            <TableCell align="left">{row?.lat}</TableCell>
                            <TableCell align="left">{row?.long}</TableCell>
                            <TableCell align="left">
                                <Link
                                    href={`/#/Houses/${row.id}/show`}
                                >
                                    <Button variant={'contained'} color={'primary'}>
                                        Show
                                    </Button>
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default HouseListForModule;
