import HttpClient from "../HttpClient";
import {BankDetailsResponse} from "../interfaces/Payout/BankDetailsResponse";


export class PayoutServices {
    async getAllPayoutsForUser(userId: number, userType: string): Promise<BankDetailsResponse[]> {
        const data = await HttpClient.get(`/centaurus/payout/${userType}/${userId}/all`);
        return data?.data;
    }

    async addBankDetails(payload: {
        userId: number,
        userType: string,
        name: string,
        ifscCode: string,
        accountNumber: string
    }): Promise<BankDetailsResponse> {
        const data = await HttpClient.post(`/centaurus/payout/bank-details`, payload);
        return data?.data;
    }

    async checkValidIfscCode(ifscCode: string): Promise<BankDetailsResponse> {
        const data = await HttpClient.post(`/validate-account/ifsc/:${ifscCode}`);
        return data?.data;
    }

    async addUpiDetails(payload: {
        userId: number,
        userType: string,
        name: string,
        vpaId: string,
    }): Promise<BankDetailsResponse> {
        const data = await HttpClient.post(`/centaurus/payout/vpa`, payload);
        return data?.data;
    }

    async checkValidVpaId(vpaId: string): Promise<BankDetailsResponse> {
        const data = await HttpClient.get(`/validate-account/vpa/${vpaId}`);
        return data?.data;
    }

    async makePayoutAccountAsDefault(id: number): Promise<BankDetailsResponse> {
        const data = await HttpClient.patch(`/centaurus/payout/${id}/mark-default`);
        return data?.data;
    }

    async deletePayoutAccountDetail(id: number): Promise<BankDetailsResponse> {
        const data = await HttpClient.delete(`/centaurus/payout/${id}`);
        return data?.data;
    }
}
