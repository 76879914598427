import { Show } from "react-admin";
import LocalitiesListOfHub from "./LocalitiesListOfHub";

const ViewHub = () => {
  return (
    <Show>
      <LocalitiesListOfHub />
    </Show>
  );
};

export default ViewHub;
