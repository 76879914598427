import moment from "moment";
import CreateMiscVendorPayoutDto from "../dtos/vendorPayout/createMiscVendorPayoutDto";
import HttpClient from "../HttpClient";
import { Referralpayout } from "../interfaces/VendorPayout/ReferralPayouts";
import Op from "../interfaces/SequalizeFilters/SequalizeTypes";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";
import { MonthPayout } from "../interfaces/VendorPayout/YearMonthPayout";
import { GetOneResult } from "react-admin";


const processEventFilter = async (filters: any) => {
    if(!filters?.type && !filters?.event && !filters?.product) {
        return {}
    }
    const res = await HttpClient.get(`/vendor-payout/centaurus/payout/events`)
    return res?.data?.filter((item: any) =>
        (filters?.type === undefined || item.type === filters?.type) &&
        (filters?.event === undefined || item.event === filters?.event) &&
        (filters?.product === undefined || item.product === filters?.product)
    ).map((item: any) => item.id);
}

export default class VendorPayoutService {

    processDateRangeFilter(filters: any) {
        let createdAt;
        if (filters.startDates) {
            createdAt = {
                [Op.gte.toString()]: moment(filters.startDates)
            }
        }
        if (filters.endDates) {
            if (createdAt) {
                createdAt = {
                    ...createdAt,
                    [Op.lte.toString()]: moment(filters.endDates).add(1, 'd')
                }
            } else {
                createdAt = {
                    [Op.lte.toString()]: moment(filters.endDates).add(1, 'd')
                }
            }
        }
        return {createdAt};
    }

    async getAllVendorPayouts(limit: number, offset: number, filters: any, sort: any) {
        let filter = ConstructSequelizeFilters(filters, [], ['cookId', 'status'] )
        let customFilters = this.processDateRangeFilter(filters);
        let eventFilters = await processEventFilter(filters);
        filters = {
            ...filter,
            ...customFilters,
            ...(Array.isArray(eventFilters) ? {
                eventId: {
                    [Op.in.toString()]: eventFilters,
                }
            } : {})
        }
        let data = await HttpClient.get('/vendor-payout/centaurus/all', {
            params: {
                limit,
                offset,
                filters,
                sort,
            }
        })
        return {
            data: data.data.data,
            total: data.data.count
        }
    }

    async createMiscPayout(createMiscVendorPayoutDto: CreateMiscVendorPayoutDto) {
        const response = await HttpClient.post("/vendor-payout/centaurus/miscellaneous", createMiscVendorPayoutDto)
        return response.data;
    }

    async processPayout(payoutId: number, payload:{ hardProcess: boolean }) {
        const response = await HttpClient.post(`/vendor-payout/centaurus/payouts/${payoutId}/process`, payload)
        return response.data;
    }

    async retryPayout(payoutId: number) {
        const response = await HttpClient.post(`/vendor-payout/centaurus/payouts/${payoutId}/retry`, { hardProcess: true })
        return response.data;
    }

    async approvePayout(payoutId: number) {
        const response = await HttpClient.post(`/vendor-payout/centaurus/payouts/${payoutId}/approve`)
        return response.data;
    }

    async rejectPayout(payoutId: number, payload: {comments: string}) {
        const response = await HttpClient.post(`/vendor-payout/centaurus/payouts/${payoutId}/reject`, payload)
        return response.data;
    }

   async getVendorPayoutById(id: number): Promise<GetOneResult<any>> {
    let data = await HttpClient.get(`/vendor-payout/centaurus/${id}/details`);
    return data
}

    async getTransationsByMonth(cookId: number, year: any, month: any){
        let data = await HttpClient.get(`/vendor-payout/centaurus/cooks/${cookId}/types`, {
            params: {
                year,
                month
            }
        })
        return data?.data
    }

    async getAllMiscellaneousPayouts(cookId: number, year: any, month: any, type: any){
        let data = await HttpClient.get(`/vendor-payout/centaurus/cooks/${cookId}/miscellaneous/type`,{
        params:{
            year,
            month,
            type
        }
        })
        return data?.data
    }

    async getSubscriptionAndLeaveByHouseId(cookId: number, houseId: any, year: any, month: any){
        let data = await HttpClient.get(`/vendor-payout/centaurus/cooks/${cookId}/subscription-leave/house/${houseId}`,{
            params:{
                year,
                month
            }
        })
        return data?.data
    }

    async getAllPayoutByCookId(cookId: number, type: string, year: any, month: any){
        let data = await HttpClient.get(`/vendor-payout/centaurus/cooks/${cookId}/${type}`, {
            params: {
                year,
                month
            }
        })
        return data?.data
    }

    async CreateReferralPayouts(payload: any): Promise<Referralpayout>{
        let res = await HttpClient.post(`/centaurus/v3/cook-referral`, payload)
        return res?.data
    }

    async cookModuleYearlyTransaction(cookId: number, year: string): Promise<MonthPayout>{
        let res = await HttpClient.get(`/vendor-payout/centaurus/${cookId}/getMonthlyPayoutData`, {
            params: {
                year
            }
        })
        return res?.data
    }

    async bulkApprovePayout(payload: any): Promise<any>{
        const res = await HttpClient.post(`/vendor-payout/centaurus/bulk-update/approve`, payload);
        return res?.data;
    }

    async bulkRejectPayout(payload: any): Promise<any>{
        const res = await HttpClient.post(`/vendor-payout/centaurus/bulk-update/reject`, payload);
        return res?.data;
    }

    async bulkProcessPayout(payload: any): Promise<any>{
        const res = await HttpClient.post(`/vendor-payout/centaurus/bulk-update/process`, payload);
        return res?.data;
    }

    async revokeVendorPayout(payoutId: number, payload: any): Promise<any> {
        const res = await HttpClient.post(`/vendor-payout/centaurus/payouts/${payoutId}/revoke`, payload);
        return res?.data;
    }

    async getPayoutEvents(): Promise<any> {
        const res = await HttpClient.get(`/vendor-payout/centaurus/payout/events`)
        return res?.data;
    }
}
