import {useState} from 'react';
import {Button, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import moment from "moment";
import {ZohoBaseUrl, ZohoModulesUrl} from "../../enums/zoho";
import {AllVasService} from "../../services/AllVasServices";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import Cooks from "../Cooks/Cooks";
import Vendors from "../Vendors/Vendor";
import {UserType} from "../../enums/common";
import { useAppDispatch } from '../../store/hooks';
import { setCustomSelectOpen } from '../../store/reducers/customSelect';
import ResourceSelectDialog from '../Common/ResourceSelectDialog';

const vasService = new AllVasService()

const UserTransactionListForModule = (props: any) => {

    const [selected, setSelected] = useState<any>(null);
    const [selectedRecord, setSelectedRecord] = useState<any>()
    const dispatch = useAppDispatch();

    const handleSelectCookOrVendor = () => {
        dispatch(setCustomSelectOpen({
            open: true
        }));
    }

    const assignCook = (data: any) => {
        if (data) {
            vasService.assignVendorToTransaction(data.id, UserType.COOK, selectedRecord?.id as number).then(() => props.refresh())
        }
    }

    const assignVendor = (data: any) => {
        if (data) {
            vasService.assignVendorToTransaction(data.id, UserType.VENDOR, selectedRecord?.id as number).then(() => props.refresh())
        }
    }

    const markAsComplete = (data: any) => {
        EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
            message: "Are you sure you want to Mark as complete?",
            onClose: (confirmed: boolean) => {
                if (confirmed) {
                    vasService.markTransactionAsComplete(data.id as number).then(() => props.refresh())
                }
            },
            title: "Confirmation"
        });
    }

    const cancel = (data: any) => {
        EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
            message: "Are you sure you want to cancel?",
            onClose: (confirmed: boolean) => {
                if (confirmed) {
                    vasService.cancelTransaction(data.id as number).then(() => props.refresh())
                }
            },
            title: "Confirmation"
        });
    }

    return (
        <div>
            <ResourceSelectDialog
                name={selected === 0 ? "Cooks" : "Vendors"}
                list={selected === 0 ? Cooks : Vendors}
                onClose={selected === 0 ? assignCook : assignVendor}
            />

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Service Name</TableCell>
                            <TableCell>State</TableCell>
                            <TableCell>Chef/Cleaner</TableCell>
                            <TableCell>Mobile</TableCell>
                            <TableCell>User Type</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Comment from admin</TableCell>
                            <TableCell>Zoho</TableCell>
                            <TableCell>Customer Name</TableCell>
                            <TableCell>Customer Address</TableCell>
                            <TableCell>Customer Mobile</TableCell>
                            <TableCell/>
                            <TableCell/>
                            <TableCell/>
                            <TableCell/>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.map((row: any) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell scope="row">{row?.id}</TableCell>
                                <TableCell scope="row">{row?.userServiceBundleMapping?.vas?.name}</TableCell>
                                <TableCell align="center">{row?.state}</TableCell>
                                <TableCell align="center">{row?.userId}</TableCell>
                                <TableCell align="center">{row?.user?.phoneNumber}</TableCell>
                                <TableCell align="center">{row?.userType}</TableCell>
                                <TableCell align="center">
                                    {
                                        !!row &&
                                        !!row.date &&
                                        moment(row?.date, 'YYYY-MM-DD').format('DD MMM YYYY')
                                    }
                                </TableCell>
                                <TableCell align="center">{row?.time}</TableCell>
                                <TableCell align="center">{row?.commentFromAdmin}</TableCell>
                                <TableCell align="center">
                                    {
                                        !!row?.zohoId &&
                                        !!row?.userServiceBundleMapping?.vas?.name &&
                                      <Link
                                        href={`${ZohoBaseUrl}${row.userServiceBundleMapping.vas.name.toLowerCase().includes("chef") ? ZohoModulesUrl.STAR_CHEF: ZohoModulesUrl.STAR_CLEANER}/${row.zohoId}`}
                                        target="_blank"
                                        rel="noopener"
                                      >
                                          {row.zohoId}
                                      </Link>
                                    }
                                </TableCell>
                                <TableCell>
                                    {
                                        !!row?.userServiceBundleMapping?.userBundleMapping?.house?.customers &&
                                        Array.isArray(row.userServiceBundleMapping.userBundleMapping.house.customers) &&
                                        row.userServiceBundleMapping.userBundleMapping.house.customers.map((cust: any) => {
                                            return (
                                                <p>{cust.fname}</p>
                                            )
                                        })
                                    }
                                </TableCell>
                                <TableCell>
                                    {row?.userServiceBundleMapping?.userBundleMapping?.house?.address}
                                </TableCell>
                                <TableCell>
                                    {
                                        !!row?.userServiceBundleMapping?.userBundleMapping?.house?.customers &&
                                        Array.isArray(row.userServiceBundleMapping.userBundleMapping.house.customers) &&
                                        row.userServiceBundleMapping.userBundleMapping.house.customers.map((cust: any) => {
                                            return (
                                                <p>{cust.mobile}</p>
                                            )
                                        })
                                    }
                                </TableCell>
                                {
                                    row.state === 'PENDING' &&
                                    <>
                                      <TableCell>
                                        <Button
                                          variant={'outlined'}
                                          color={'primary'}
                                          onClick={() => {
                                              setSelected(0);
                                              setSelectedRecord(row);
                                              handleSelectCookOrVendor();
                                          }}
                                        >
                                            {
                                                (!!row?.userId && row?.userType === UserType.COOK) ?
                                                    'Change Cook'
                                                    :
                                                    'Assign Cook'
                                            }
                                        </Button>
                                      </TableCell>
                                      <TableCell>
                                        <Button
                                          variant={'outlined'}
                                          color={'primary'}
                                          onClick={() => {
                                              setSelected(1);
                                              setSelectedRecord(row);
                                              handleSelectCookOrVendor();
                                          }}
                                        >
                                            {
                                                (!!row?.userId && row?.userType === UserType.VENDOR) ?
                                                    'Change Vendor'
                                                    :
                                                    'Assign Vendor'
                                            }
                                        </Button>
                                      </TableCell>
                                      <TableCell>
                                        <Button
                                          variant={'contained'}
                                          color={'success'}
                                          onClick={() => {
                                              markAsComplete(row)
                                          }}
                                        >
                                          Mark Complete
                                        </Button>
                                      </TableCell>
                                      <TableCell>
                                        <Button
                                          variant={'contained'}
                                          color={'error'}
                                          onClick={() => {
                                              cancel(row)
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      </TableCell>
                                      <TableCell>
                                        <Link
                                          href={`/#/VasTransactions/${row.id}`}
                                        >
                                          <Button variant={'contained'} color={'primary'}>
                                            Edit
                                          </Button>
                                        </Link>
                                      </TableCell>
                                    </>
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default UserTransactionListForModule;
