import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import AnalyticsIcon from '@mui/icons-material/Analytics';
interface MetabaseTransactionProp {
    dashboardId: number,
    label?: string;
    houseId?: number;
}

const MetabaseDashboardButton = (props: MetabaseTransactionProp) => {
    let navigate = useNavigate();

     const onShowClick = () => {
        if (props.houseId) {
            window.open(`https://metabase.thechefkart.com/dashboard/${props.dashboardId}-customer-history?houseid=${props.houseId}`, "_blank");
        }
        else {
            navigate(`/metabase-transaction/${props.dashboardId}`);
        }
    };
    return (
        <Button variant="text" size={"small"} {...(!props?.label && {startIcon:<AnalyticsIcon/>})} onClick={onShowClick}> {props.label ? props.label : 'Analytics'}</Button>
    )
}

export default MetabaseDashboardButton