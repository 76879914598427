import { Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useState } from "react";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { PlatformLeadStatus } from "../../enums/PlatformLeadEnums";
import EventBus from "../../EventBus";
import { PlatformLeadResponse } from "../../interfaces/Party/PlatformLeadInterface";
import PlatformLeadServices from "../../services/PlatformServices";
import CkMuiDialog from "./CkMuiDialog/CkMuiDialog";
import { CustomSnackbarTypes } from "./CustomSnackBar";

const platformLeadServices = new PlatformLeadServices();

interface InputProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  platformLeadData: PlatformLeadResponse;
  refresh: () => void;
}

const UpdatePlatformLeadStatusDialog = ({
  open,
  setOpen,
  platformLeadData,
  refresh,
}: InputProps) => {
  const [platFormLeadStatus, setPlatFormLeadStatus] = useState<any>();

  const handleChange = (e: any) => {
    setPlatFormLeadStatus({
      ...platFormLeadStatus,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    platformLeadServices
      .updateLead(platformLeadData.id, platFormLeadStatus)
      .then(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Status Updated Successfully",
          type: CustomSnackbarTypes.SUCCESS,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setOpen(false);
        refresh();
      });
  };

  return (
    <CkMuiDialog
      fullWidth={true}
      isDialogOpen={open}
      setDialogOpen={setOpen}
      onDialogClose={() => {}}
      title={"Change Status"}
      primaryLabel={"Submit"}
      primaryAction={handleSubmit}
      secondaryLabel={"Close"}
      secondaryAction={() => {
        setOpen(false);
      }}
      isLoading={false}
    >
      <Grid justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" required>
              Status
            </InputLabel>
            <Select
              name="status"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Status"
              onChange={handleChange}
              defaultValue={""}
            >
              {Object.keys(PlatformLeadStatus).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </CkMuiDialog>
  );
};
export default UpdatePlatformLeadStatusDialog;
