import { GetListResult } from "react-admin";
import HttpClient from "../HttpClient";
import { RefundResponse } from "../interfaces/Refund/RefundResponse";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";

export class ReFundService {

  async getAllRefunds(
    limit: number,
    offset: number,
    filters: any,
    sort: any
  ): Promise<GetListResult<RefundResponse>> {
    let filter = ConstructSequelizeFilters(
      filters,
      [ 'status']
    );
    filters = {
      ...filter,
    };
    let data = await HttpClient.get("/duplicateRefund/centaurus/all", {
      params: {
        limit,
        offset,
        filters,
        sort,
      },
    });
    return {
      data: data?.data?.data,
      total: data?.data?.count,
    };
  }

  async processedTransaction(duplicatePaymentId: number): Promise<any> {
    let data = await HttpClient.post(`/duplicateRefund/centaurus/${duplicatePaymentId}/process`);
    
    return {
      data: data?.data
    }
    
  }

  async retryRefund(duplicatePaymentId: number) : Promise<any>{
    let data = await HttpClient.post(`/duplicateRefund/centaurus/${duplicatePaymentId}/retry`);
    return {
      data: data?.data
    }
  }

}
