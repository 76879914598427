import { Typography, Button } from "@mui/material";
import { get } from "lodash";
import {
  Datagrid,
  DateField,
  FunctionField,
  Identifier,
  List,
  RaRecord,
  sanitizeFieldRestProps,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
  DateInput,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { UserType } from "../../enums/common";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { PaymentStatusEnum } from "../../enums/PaymentStatusEnum";
import {
  PlatformLeadProductType,
  PlatformLeadStatus,
} from "../../enums/PlatformLeadEnums";
import EventBus from "../../EventBus";
import PlatformLeadServices from "../../services/PlatformServices";
import CallButton from "../Common/CallButton/CallButton";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";

const platformLeadServices = new PlatformLeadServices();

const filters = [
  <SelectInput
    label="Product"
    source="product"
    alwaysOn
    emptyText="Clear Filter"
    choices={Object.values(PlatformLeadProductType).map((key) => {
      return {
        id: key,
        name: key,
      };
    })}
  />,
  <TextInput source="mobile" alwaysOn={true} label="Customer Mobile" />,
  <TextInput source="clientId" alwaysOn={true} label="ClientId" />,
  <DateInput label="Start Date" source="startDate" alwaysOn />,
  <DateInput label="End Date" source="endDate" alwaysOn />,
  <SelectInput
    label="Status"
    source="status"
    alwaysOn
    emptyText="Clear Filter"
    choices={Object.values(PlatformLeadStatus).map((key) => {
      return {
        id: key,
        name: key,
      };
    })}
  />,
  <SelectInput
    label="Payment Status"
    source="paymentStatus"
    alwaysOn
    emptyText="Clear Filter"
    choices={Object.values(PaymentStatusEnum).map((key) => {
      return {
        id: key,
        name: key,
      };
    })}
  />,
];

const CustomArrayField = (props: any) => {
  const { className, source, emptyText, objMap, ...rest } = props;
  const record = useRecordContext(props);
  let value;
  if (source) {
    value = get(record, source);
    if (value && value.length > 0) {
      return (
        <div style={{ display: "flex", gap: "4px" }}>
          {value.map((x: any, index: number) => (
            <Typography
              component="span"
              variant="body2"
              key={x + index}
              className={className}
              {...sanitizeFieldRestProps(rest)}
            >
              {objMap
                ? objMap[x]
                : x != null && typeof x !== "string"
                ? JSON.stringify(x)
                : x + "," || emptyText}
            </Typography>
          ))}
        </div>
      );
    } else {
      return <></>;
    }
  }
  return <></>;
};
const PlatformLeadList = () => {
  const refresh = useRefresh();
  const sendPaymentLinkHandler = async (id:Identifier) => {
    try{
      const response = await platformLeadServices.sendPlatformPaymentLink(id as number);
      if(response.status === 200){
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Payment link Sent",
          type: CustomSnackbarTypes.SUCCESS,
        });
      }
      refresh();
    }catch(error){
      console.error(error);
    }
  }
  return (
    <List filters={filters}>
      <Datagrid bulkActionButtons={false} size="medium">
        <TextField label="ID" source="id" />
        <TextField label="Name" source="name" />
        <FunctionField
          label="Mobile"
          render={(record: any) => (
            <CallButton
              toPhoneNumber={record?.mobile}
              userType={UserType.COOK}
            />
          )}
        />
        <TextField label="Email" source="email" />
        <TextField label="Customer Id" source="customerId" />
        <TextField label="Client ID" source="clientId" />
        <TextField label="Product" source="product" />
        <TextField label="Status" source="status" />
        <CustomArrayField
          textAlign="center"
          label="Requirement"
          source="requirements"
        ></CustomArrayField>
        <TextField label="Total People" source="totalPeople" />
        <DateField label="Date" source="date" />
        <TextField label="Area ID" source="areaId" />
        <TextField label="Area Name" source="areaName" />
        <TextField label="City ID" source="cityId" />
        <TextField label="City Name" source="cityName" />
        <TextField label="Locality Id" source="localityId" />
        <TextField label="Localoty Name" source="localityName" />
        <TextField label="Allocator Id" source="allocatorId" />
        <TextField label="Allocator Email" source="allocatorEmail" />
        <TextField source="createdAt" />
        <FunctionField label="Show" render={() => <ShowButton />} />
        <FunctionField
          render={(record: RaRecord) => {
            return (
              <>
                {record?.paymentStatus === PaymentStatusEnum.NOT_PAID ? (
                  <Button onClick={async ()=>{sendPaymentLinkHandler(record.id)}} variant="text" size="small" color="primary">
                    Send Payment Link
                  </Button>
                ) : null}
              </>
            );
          }}
        />
      </Datagrid>
    </List>
  );
};

export default PlatformLeadList;
