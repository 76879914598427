import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {  useRefresh } from "react-admin";
import { useState } from "react";
import { TrialsService } from "../../services/TrialServices";
import EventBus from "../../EventBus";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { RefundTypeEnum } from "../../enums/RefundTypeEnum";
import { TrialResponse } from "../../interfaces/Trials/TrialsResponse";
import { OneMealService } from "../../services/OneMealService";
interface Props {
  initiatedBy: any;
  data: TrialResponse;
  open: boolean;
  callback: () => void;

}
const oneMealService = new OneMealService();

const TrialsRefundDialog = (props: Props) => {
  const [refundType, setRefundType] = useState<RefundTypeEnum | null>(null);
  const refresh = useRefresh();

  const trialService = new TrialsService();
  const [amount, setAmount] = useState<string>("0");

  let handleClose = () => {
    setAmount('0');
    setRefundType(null);
    props.callback();
  };

  const handleSubmit = async () => {
    if (refundType === null) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Please select refund type",
        type: CustomSnackbarTypes.WARNING,
      });
      return;
    }
    if (refundType !== RefundTypeEnum.FULL && Number(amount) === 0) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Enter the refund amount",
        type: CustomSnackbarTypes.WARNING,
      });
      return;
    }

    let payload: {
      refundType: RefundTypeEnum;
      reason: string;
      amount?: string;
    } = { refundType, reason: '' };
    if (Number(amount) !== 0) {
      payload = {
        ...payload,
        amount,
      };
    }
    if (props?.initiatedBy === 1) {
      await trialService.refundTrial(props?.data?.id, payload).then(() => {
        refresh();
        handleClose();
      });
    } else if (props?.initiatedBy === 2) {
      await oneMealService
        .processRefundOneMealService(props?.data?.id, payload)
        .then(() => {
          refresh();
          handleClose();
        });
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      scroll={"paper"}
      maxWidth="xs"
    >
      <DialogTitle>Refund Trials</DialogTitle>
      <DialogContent sx={{ width: 300 }}>
        {props?.data?.rescheduledFrom ? (
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            Rescheduled From: {props?.data?.rescheduledFrom}
          </div>
        ) : (
          <></>
        )}
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Select refund type
          </InputLabel>
          <Select
            autoWidth={false}
            value={refundType}
            onChange={(event: any) => {
              setRefundType(event.target.value);
              setAmount("0");
            }}
          >
            {Object.values(RefundTypeEnum).map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {RefundTypeEnum.FULL !== refundType && refundType !== null && (
          <TextField
            fullWidth
            value={amount}
            type={"number"}
            label={"Enter the refund Amount"}
            onChange={(event) => {
              setAmount(event.target.value);
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSubmit} size={"large"}>
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            handleClose();
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default TrialsRefundDialog;
