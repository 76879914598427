import React, {useEffect, useState} from 'react';
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import HouseService from "../../services/HouseService";
import CustomerListForModule from "../Customers/CustomerListForModule";

interface InputProps {
    houseId: number
}

const houseService = new HouseService();

const HousesCustomersTab = (props: InputProps) => {

    let [subData, setSubData] = useState<any>(null);
    let [isLoading, setIsLoading] = useState<boolean>();

    const refreshData = (houseId: number) => {
        setIsLoading(true)
        houseService.getAllCustomerForHouse(houseId)
            .then((data) => {
                setSubData(data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        refreshData(props.houseId);
    }, [props.houseId])

    return (
        <div>
            {
                isLoading ?
                    <CircularLoader/>
                    :
                    <div>
                        {
                            subData &&
                            subData.data &&
                            Array.isArray(subData.data) &&
                            (
                                subData.data.length>0 ?
                                    <CustomerListForModule data={subData?.data}/>
                                    :
                                    <EmptyListPlaceholder
                                        message='No Customer Found'
                                    />
                            )
                        }
                    </div>
            }
        </div>
    );
};

export default HousesCustomersTab;
