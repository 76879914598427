import { Datagrid, List, NumberField, SelectInput, ShowButton, TextField } from "react-admin"
import { ApplicationEnums } from "../../enums/ApplicationEnums";

const templateFilter = [
    <SelectInput
        label="Filter by Apps"
        source="app"
        alwaysOn
        choices={
            Object.values(ApplicationEnums)
                .map(key => {
                    const index = Object.values(ApplicationEnums).indexOf(key as unknown as ApplicationEnums);
                    const name = Object.keys(ApplicationEnums)[index];
                    return {
                        id: key,
                        name: name
                    }
                })
        }
    />,
]
export const TemplateList = (props: any) => {
    return (
        <List {...props} filters={templateFilter}>
            <Datagrid bulkActionButtons={false}>
                <NumberField label="Id" source='id' sortable={false} />
                <TextField label="Name" source='name' sortable={false} />
                <TextField label="App" source='app.name' sortable={false} />
                <TextField label="Sms Type" source='SMSType' sortable={false} />
                <TextField label="Sms Priority" source='SMSPriority' sortable={false} />
                <ShowButton />
            </Datagrid>
        </List>
    );
}