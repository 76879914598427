import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const JsonViewer = (data: any) => {
    return (
        <>
            <Accordion TransitionProps={{ unmountOnExit: true }} sx={{
                
            }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography fontWeight={600}>JSON Viewer</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{
                    overflow: "auto",
                    height: "60vw"
                }}>
                    <div>
                        {data ? (
                            <pre>{JSON.stringify(data, null, 2)}</pre>
                        ) : (
                            <Typography>Loading data...</Typography>
                        )}
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default JsonViewer