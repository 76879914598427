import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Stack,
  Chip,
} from "@mui/material";
import { useState } from "react";
import { CookServiceTypeEnum } from "../../enums/CookServiceTypeEnum";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import FreshLeadInterface from "../../interfaces/Cook/FreshLeadInterface";
import { CookService } from "../../services/CookServices";
import CkMuiDialog from "./CkMuiDialog/CkMuiDialog";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import CancelIcon from "@mui/icons-material/Cancel";

const cookService = new CookService();

interface InputProps {
  cookDetail: FreshLeadInterface;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  refresh: () => void;
}

const ChangeCookServiceTypeDialog = (props: InputProps) => {
  const [cookTypes, setCookTypes] = useState<CookServiceTypeEnum[]>([]);
  return (
    <CkMuiDialog
      isDialogOpen={props.isOpen}
      setDialogOpen={props.setIsOpen}
      onDialogClose={() => {}}
      title={"Add Cook Types"}
      secondaryLabel={"Close"}
      secondaryAction={() => {
        props.setIsOpen(false);
      }}
      primaryAction={() => {
        if (cookTypes) {
          cookService
            .addCookType(props?.cookDetail?.cookId, {
              cookTypeList: cookTypes
            })
            .then(() => {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Successfull",
                type: CustomSnackbarTypes.SUCCESS,
              });
              props.refresh();
              props.setIsOpen(false);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }}
      primaryLabel={"Submit"}
      isLoading={false}
    >
      <FormControl fullWidth sx={{minWidth: 200}}>
      <InputLabel>Select Cook Types</InputLabel>
      <Select
        multiple
        value={cookTypes}
        onChange={(e) => {
          const selectedValue = e.target.value as CookServiceTypeEnum[];
          if (selectedValue) {
            setCookTypes(selectedValue);
          }
        }}
        renderValue={(selected) => (
          <Stack gap={1} direction="row" flexWrap="wrap" sx={{paddingY: '5px'}}>
            {selected.map((value) => (
            <Chip
              key={value}
              label={value}
              onDelete={() =>
                setCookTypes(
                  cookTypes.filter((item) => item !== value)
                )}
              deleteIcon={
                <CancelIcon onMouseDown={(event) => event.stopPropagation()}/>
              }
            />
            ))}
          </Stack>
          )}
        >
        {Object.values(CookServiceTypeEnum).filter((cookType)=>cookType!==CookServiceTypeEnum.HOME_CHEF).map((e) => (
          <MenuItem key={e} value={e}>
            <ListItemIcon>
              <Checkbox checked={cookTypes.indexOf(e) > -1} />
            </ListItemIcon>
            <ListItemText primary={e} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    </CkMuiDialog>
  );
};

export default ChangeCookServiceTypeDialog;
