import { Close } from "@mui/icons-material";
import {
  Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Portal, Select, Snackbar, TextField
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import * as React from "react";
import { ShortTimeEnum } from "../../enums/ShortTimeEnum";
import UserBundleService from "../../services/BundleService";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "./CustomSnackBar";

interface InputProps {
  data: any;
  open: boolean;
  callback: (data: any) => void;
}

export const CreateTrDialog = (props: InputProps) => {
  let [time, setTime] = React.useState<any>();
  let [slot, setSlot] = React.useState<any>();
  let [date, setDate] = React.useState<Date | null>(new Date());
  let [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const userBundleService = new UserBundleService();

  let handleClose = () => {
    props.callback({
      time,
      slot,
      date
    });
    setTime(null);
    setSlot('');
    setDate(new Date());
  };
  let handleSnackbarClose = () => {
    setSnackBarOpen(false);
  };
  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={props.open}
        scroll={"paper"}
        maxWidth="xl"
      >
        <DialogTitle>Create TR</DialogTitle>
        <DialogContent>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
          <Grid
            container
            columns={1}
            rowSpacing={0.9}
            style={{ padding: "12px 12px 12px 12px" }}
          >
            <FormControl fullWidth>
              <DatePicker
                label="Date"
                inputFormat="dd MMM yyyy"
                disableMaskedInput
                value={date}
                minDate={new Date(props.data?.startDate)}
                maxDate={new Date(props.data?.endDate)}
                onChange={(value) => setDate(value)}
                renderInput={(params) => <TextField {...params} />}
              />
              <FormControl fullWidth style={{ marginTop: "20px" }}>
                <InputLabel id="for_Time">Time</InputLabel>
                <Select
                  labelId="for_Time"
                  id="Time"
                  label="Time"
                  value={time}
                  onChange={(event) => setTime(event.target.value)}
                >
                  {Object.values(ShortTimeEnum).map((e) => (
                    <MenuItem key={e} value={e}>
                      {e}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {props.data &&
                props.data.userBundleMeta &&
                props.data?.userBundleMeta?.mealMapping && (
                  <FormControl fullWidth style={{ marginTop: "20px" }}>
                    <InputLabel id="for_slot">Slot</InputLabel>
                    <Select
                      labelId="for_slot"
                      id="Slot"
                      label="Slot"
                      value={slot}
                      onChange={(event) => setSlot(event.target.value)}
                    >
                      {props.data?.userBundleMeta?.mealMapping.map((e: any) => (
                        <MenuItem key={e} value={e}>
                          {e}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
            variant="contained"
            onClick={() => {
              if (!date || !time || !slot) {
                setSnackBarOpen(true);
                return;
              }
              userBundleService
                .createTr(props.data.id, {
                  slot,
                  time,
                  date: moment(date).format("YYYY-MM-DD"),
                })
                .then(() => {
                  handleClose();
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: 'Success',
                    type: CustomSnackbarTypes.SUCCESS
                  });
                });
            }}
            size={"large"}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              handleClose();
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Portal>
        <Snackbar
          open={snackBarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
        >
          <Alert severity="warning">Fill the details to proceed</Alert>
        </Snackbar>
      </Portal>
    </div>
  );
};
