import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
  } from "@mui/material";
  import { useRefresh } from "react-admin";
  import { useState } from "react";
  import EventBus from "../../EventBus";
  import { CustomSnackbarTypes } from "./CustomSnackBar";
  import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
  import { RefundTypeEnum } from "../../enums/RefundTypeEnum";
import ChefForPartyService from "../../services/ChefForPartyService";
import { useLocation } from 'react-router-dom';
  
  interface Props {
    data: any;
    open: boolean;
    callback: () => void;
    refresh?: any;
  }
  const chefForPartyService = new ChefForPartyService();
  const ChefForPartyRefundDailog = (props: Props) => {
    const [reason, setReason] = useState<string>("");
    const [refundType, setRefundType] = useState<string>("");
    const [refundPercentage, setRefundPercentage] = useState(0);
    const location = useLocation();
    const partyId = location.pathname.split('/')[2]
    const refresh = useRefresh();
  
    let handleClose = () => {
      setReason("");
      setRefundPercentage(0);
      setRefundType("");
      props.callback();
    };
  
    const handleSubmit = async () => {
      if (refundType === null || refundType.length === 0) {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Please select refund type",
          type: CustomSnackbarTypes.WARNING,
        });
        return;
      }
      if (reason.length === 0) {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Enter the Reason",
          type: CustomSnackbarTypes.WARNING,
        });
        return;
      }
      if (refundType === RefundTypeEnum.PARTIAL && (Number(refundPercentage) > 100 || Number(refundPercentage) < 0)) {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Enter the correct refund Percentage",
          type: CustomSnackbarTypes.WARNING,
        });
        return;
      }

      let payload: any = {
        refundType,
        reason
      }
      if(refundType === RefundTypeEnum.PARTIAL){
        payload["refundPercentage"] = Number(refundPercentage)
      }
      
      await chefForPartyService.initiateRefund(Number(partyId),props?.data?.id, payload)
        .then(() => {
          refresh();
          handleClose();
        }).finally(() => {
          if (props.refresh) {
            props.refresh();
          }
        });
    };
  
    return (
      <Dialog
        onClose={handleClose}
        open={props.open}
        scroll={"paper"}
        maxWidth="xs"
      >
        <DialogTitle>Refund Trials</DialogTitle>
        <DialogContent sx={{ width: 300 }}>
          {props?.data?.rescheduledFrom ? (
            <div style={{ marginTop: "8px", marginBottom: "12px" }}>
              Rescheduled From: {props?.data?.rescheduledFrom}
            </div>
          ) : (
            <></>
          )}
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select refund type
            </InputLabel>
            <Select
              autoWidth={false}
              value={refundType}
              onChange={(event: any) => {
                setRefundType(event.target.value);
              }}
            >
              {Object.values(RefundTypeEnum).map((val) => {
                return (
                  <MenuItem key={val} value={val}>
                    {val}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {refundType.length !== 0 && RefundTypeEnum.FULL !== refundType && (
            <TextField
              fullWidth
              value={refundPercentage}
              type={"number"}
              label={"Enter the refund Percentage"}
              InputProps={{ inputProps: { min: 0 } }}
              onChange={(event: any) => {
                  setRefundPercentage(event.target.value);
              }}
            />
          )}
          <TextField
              fullWidth
              value={reason}
              type={"text"}
              label={"Enter the refund Reason"}
              onChange={(event: any) => {
                setReason(event.target.value);
              }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSubmit} size={"large"}>
            Submit
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              handleClose();
            }}
            size={"large"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  export default ChefForPartyRefundDailog;
  