import { CreateResult, GetListResult, GetOneResult } from "ra-core";
import { StarCleanerVisitResponse } from "../interfaces/StarCleanerVisit/StarCleanerVisitResponse";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";
import HttpClient from "../HttpClient";
import moment from "moment";
import Op from "../interfaces/SequalizeFilters/SequalizeTypes";
import { StarCleanerCreateRequest } from "../components/StarCleaner/StarCleanerCreateRequest";
import { RefundTypeEnum } from "../enums/RefundTypeEnum";

export default class StarCleanerVisitService {
    private basePath = "/centaurus/starcleaner";

    processDateRangeFilter(filters: any) {
        let visitDate;
        if (filters.date) {
            visitDate = {
                [Op.eq.toString()]: moment(filters.date).format("YYYY-MM-DD")
            }
            delete filters.date
        }
        return {visitDate};
    }

    async getAllStarCleanerVisit(limit: number, offset: number, filter: any, sort: any): Promise<GetListResult<StarCleanerVisitResponse>> {
        let filter1 = ConstructSequelizeFilters({...filter}, ['cleanerName','customerPhone','customerName'], ['paymentStatus', 'status','createdAt','visitDate'],[],[])
        let filter2 = this.processDateRangeFilter(filter);
        let filters = {
            ...filter1,
            ...filter2
        }

        let data = await HttpClient.get(`${this.basePath}`, {
            params: {
                limit,
                offset,
                filters,
                sort
            }
        })
        return {
            data: (!!data && !!data.data) ? data.data.data : [],
            total: (!!data && !!data.data) ? data.data.count : 0
        }
    }

    async createStarCleanerVisit(payload: StarCleanerCreateRequest): Promise<CreateResult<StarCleanerVisitResponse>> {
        let houseId = payload.houseId
        let createPayload = {
            visitTime: payload.visitTime,
            visitDate: payload.visitDate,
        }
        const created = await HttpClient.post(`${this.basePath}/house/${houseId}`, createPayload);
        return {
            data: created?.data
        }
    }

    async getStarCleanerVisit(serviceId: number): Promise<GetOneResult<StarCleanerVisitResponse>> {
        let data = await HttpClient.get(`${this.basePath}/${serviceId}`);
        return data;
    }

    async sendStarCleanerPaymentLink(id: number) {
        let data = await HttpClient.get(`${this.basePath}/${id}/send-payment-link`)
        return data?.data;
    }

    async refundStarCleanerService(id: number, payload: {reason: string, refundType: RefundTypeEnum, amount?: string}) {
        let data = await HttpClient.post(`${this.basePath}/${id}/initiate-refund`, payload)
        return data?.data
    }

    async markFulfilled(id: number) {
        let data = await HttpClient.patch(`/centaurus/starcleaner/${id}/mark-fulfilled`)
        return data?.data
    }

    async cancelStarCleanerVisit(id: number, payload: { cancellationReason: string }): Promise<GetOneResult<StarCleanerVisitResponse>> {
        let data = await HttpClient.delete(`/centaurus/starcleaner/${id}/cancel-visit`, {data: payload});
        return data?.data;
    }

    async addNote(id: number, params: {note: string}) {
        let data = await HttpClient.patch(`/centaurus/starcleaner/${id}/add-note`, params);
        return data?.data
    }

    async rescheduleStarCleanerVisit(id: number, payload: any): Promise<GetOneResult<StarCleanerVisitResponse>> {
        let data = await HttpClient.patch(`/centaurus/starcleaner/${id}/reschedule-visit`, payload);
        return data?.data;
    }

    async assignCleanerToVisit(id: number, payload: { cleanerId: number }): Promise<GetOneResult<StarCleanerVisitResponse>> {
        let data = await HttpClient.patch(`centaurus/starcleaner/${id}/assign-cleaner`, payload);
        return data?.data;
    }

    async getStarCleanerForHouse(id: number): Promise<GetOneResult<StarCleanerVisitResponse>> {
        let res = await HttpClient.get(`${this.basePath}/house/${id}`);
        return res?.data;
    }
    
}