import { Close } from "@mui/icons-material";
import {
  Box, Button, Dialog, DialogContent, DialogTitle, IconButton,
  TableCell, TableHead, TableRow, Table, TableBody, Typography
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useRefresh } from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";
import {RefundResponseV2} from "../../interfaces/Refund/RefundResponseV2";
import {RefundServiceV2} from "../../services/RefundServiceV2";

interface InputProps {
  data: RefundResponseV2;
  open: boolean;
  isRetry: boolean;
  callback: () => void;
}

const refundServiceV2 = new RefundServiceV2();

export const PayoutDialogV2 = (props: InputProps) => {
  let refresh = useRefresh();
  const [loading, setLoading] = useState(true)
  const [allUserAccounts, setAllUserAccountsType] = useState([])

  let handleClose = useCallback(() => {
    props.callback()
  },[props])

  useEffect(() => {
    if(props.data){
      setLoading(true);
      refundServiceV2.getAllAccountsOfUserRefundV2(props?.data?.houseId).then((res) => {
        setAllUserAccountsType(res);
        setLoading(false);
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.data])

  const handleInitiatePayout = (account: number) => {
    refundServiceV2.initiatePayoutV2(props?.data?.id, {masterAccountId: account}).then(() => {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Success",
        type: CustomSnackbarTypes.SUCCESS,
      });
      refresh();
      props.callback();
    })
  }

  const handleRetryPayout = (account: number) => {
    refundServiceV2.retryPayoutV2(props?.data?.id, {masterAccountId: account}).then(() => {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Success",
        type: CustomSnackbarTypes.SUCCESS,
      });
      refresh();
      props.callback();
    })
  }

  const handleSubmit = async (account: number) => {
    EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
      message: "Are you sure to proceed with the account!",
      onClose: (confirmed: boolean) => {
        if (confirmed) {
          if(props?.isRetry){
            handleRetryPayout(account);
          } else {
            handleInitiatePayout(account);
          }
        }
      },
      title: "Are you sure?"
    });

  }

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      scroll={"paper"}
      maxWidth="lg"
    >
      <DialogTitle>Handle Payout</DialogTitle>
      <DialogContent>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 8, right: 5 }}
        >
          <Close />
        </IconButton>
        <Box sx={{minWidth: 500, minHeight: 100}}>
          {allUserAccounts.length > 0 ? (
              allUserAccounts?.map((eachItem: any) => {
                return(
                    <Box key={eachItem?.customerDetails?.id}>
                      <Box display={'flex'}>
                        <Typography><b>{`Name: `}</b>{eachItem?.customerDetails?.fname} {eachItem?.customerDetails?.lname}</Typography>
                        <Typography sx={{ml: 2}}><b>{`Phone: `}</b>{eachItem?.customerDetails?.mobile}</Typography>
                        <Typography sx={{ml: 2}}><b>{`Email: `}</b>{eachItem?.customerDetails?.email}</Typography>
                      </Box>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{"Id"}</TableCell>
                            <TableCell>{"Name"}</TableCell>
                            <TableCell>{"Type"}</TableCell>
                            <TableCell>{"UPI"}</TableCell>
                            <TableCell>{"Account Number"}</TableCell>
                            <TableCell>{"Verified"}</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {eachItem?.accounts?.length > 0 && (
                            eachItem?.accounts?.map((eachAccount: any) => {
                              return(
                                  <TableRow key={eachAccount?.id}>
                                    <TableCell>{eachAccount?.id}</TableCell>
                                    <TableCell>{eachAccount?.childTable?.name}</TableCell>
                                    <TableCell>{eachAccount?.type}</TableCell>
                                    <TableCell>{eachAccount?.type === 'VPA' ? eachAccount?.childTable?.vpaId : '---'}</TableCell>
                                    <TableCell>{eachAccount?.type === 'VPA' ? '---' : eachAccount?.childTable?.accountNumber}</TableCell>
                                    <TableCell>{eachAccount?.verified ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>
                                      <Button onClick={() => {handleSubmit(eachAccount?.id)}} size={'small'} variant={'contained'}>
                                        {'Proceed and Payout'}
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                              )
                            })
                          )}
                        </TableBody>
                        {eachItem?.accounts?.length <= 0 && <caption>No Account Found</caption>}
                      </Table>
                    </Box>
                )
              })
          ) : (!loading &&
              <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Typography>
                  No Details Found
                </Typography>
              </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
