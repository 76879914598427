import React from "react";
import { useLocation } from "react-router-dom";
import PayoutTypeComponent from "./PayoutTypeComponent";

const VendorPayoutShowDetails = () => {
  const location = useLocation();
  const cookId = location.pathname.split("/")[2];
  const otherDetails = Object.fromEntries(new URLSearchParams(location.search));

  return (
    <div>
      <PayoutTypeComponent
        type={otherDetails.type}
        data={[
          cookId,
          otherDetails.month,
          otherDetails.year,
          otherDetails.houseId,
        ]}
      />
    </div>
  );
};

export default VendorPayoutShowDetails;
