import { Grid, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { TrainingAssessmentEnum } from "../../enums/common";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import QuestionInterface from "../../interfaces/Cook/SkillAssessmentInterface";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import SkillAssessmentQuestion from "./SkillAssessmentQuestion";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CookService } from "../../services/CookServices";
import { appTrainingQuestions } from "../../utils/TraningQuestions/AppTrainingQuestions";
import { hygieneTrainingQuestions } from "../../utils/TraningQuestions/HygieneTrainingQuestions";
import { communicationTrainingQuestions } from "../../utils/TraningQuestions/CommunicationTrainingQuestions";
import { kitchenEtiquettesQuestions } from "../../utils/TraningQuestions/KitchenEtiquettesQuestions";
import { situationalQuestions } from "../../utils/TraningQuestions/SituationalQuestions";

const cookService = new CookService();

const TraningAssessment = () => {
  const [marks, setMarks] = useState<number>(0);
  const [questions, setQuestions] = useState<QuestionInterface[] | null>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleSubmit = () => {
    const cookId = Number(searchParams.get("cookId"));
    const ratingFor = searchParams.get("assessmentName");
    if (!cookId || !ratingFor) {
      return;
    }

    let payload: { [key: string]: number } = {};
    payload[ratingFor] = marks;

    // API CALL FOR TRAINING

    cookService
      .updateTrainingRatings(cookId, payload) 
      .then(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Assignment Submitted Successfully",
          type: CustomSnackbarTypes.SUCCESS,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        navigate(`/Cooks/${cookId}/show/11`);
      });
  };

  useEffect(() => {
    const assessmentName = searchParams.get("assessmentName");
    switch (assessmentName as TrainingAssessmentEnum) {
      case TrainingAssessmentEnum.APP_TRAINING:
        setQuestions(appTrainingQuestions);
        break;
      case TrainingAssessmentEnum.COMMUNICATION_TRAINING:
        setQuestions(communicationTrainingQuestions);
        break;
      case TrainingAssessmentEnum.HYGIENE_TRAINING:
        setQuestions(hygieneTrainingQuestions);
        break;
      case TrainingAssessmentEnum.KITCHEN_ETIQUETTES:
        setQuestions(kitchenEtiquettesQuestions);
        break;
      case TrainingAssessmentEnum.SITUATIONAL:
        setQuestions(situationalQuestions);
        break;
      default:
        return;
    }
  }, [searchParams]);

  if (!questions || questions.length <= 0) {
    return null;
  }

  return (
    <Grid
      sx={{ margin: "2rem" }}
      justifyContent="space-around"
      alignItems="stretch"
    >
      {questions.map((questionData: QuestionInterface, index: number) => (
        <Grid key={"trainingQuestion" + index} item xs={12} sm={6}>
          <SkillAssessmentQuestion
            question={questionData.questionText}
            options={questionData.answerOptions}
            questionNo={index + 1}
            marks={marks}
            setMarks={setMarks}
          />
        </Grid>
      ))}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          sx={{ textAlign: "end" }}
          onClick={handleSubmit}
          variant="contained"
        >
          Submit
        </Button>
      </div>
    </Grid>
  );
}

export default TraningAssessment