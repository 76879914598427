import {
    Box, Button, Checkbox, Chip,
    Dialog,
    FormControl, FormControlLabel, FormGroup,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import {FieldViewInputTypeEnums} from "../../enums/FieldViewInputTypeEnums";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";

type InputProps = {
    open: boolean;
    onClose: () => void;
    handleSubmitClicked: (inputType: string, values: string[], label: string, isActive: boolean) => void;
}
const CreateFieldDialog = (props: InputProps) => {
    const [inputTypeSelected, setInputTypeSelected] = React.useState<FieldViewInputTypeEnums>();
    const [singleValue, setSingleValue] = React.useState<string>('');
    const [valuesSelected, setValuesSelected] = React.useState<string[]>([]);
    const [label, setLabel] = React.useState<string>('');
    const [activeChecked, setActiveChecked] = React.useState<boolean>(true);
    const handleSetInputType = (event: any) => {
        setInputTypeSelected(event?.target?.value);
    }
    const handleSetValues = () => {
        if(singleValue) {
            if(!valuesSelected?.includes(singleValue)) {
                setValuesSelected((prevValues: string[]) => {
                    return [...prevValues, singleValue];
                });
            }

        }
    }
    const handleDeleteValue = (value: string) => {
        if(value) {
            const valuesSelectedAfterDelete = valuesSelected?.filter((item: string) => item !== value);
            setValuesSelected(valuesSelectedAfterDelete);
        }
    }
    const handleSetLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLabel(event?.target?.value);
    }
    const handleActiveChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        setActiveChecked(event?.target?.checked);
    }
    return (
        <Dialog open={props?.open} onClose={props?.onClose} sx={{
            '& .MuiPaper-root': {
                py: '20px',
                px: '20px',
                minWidth: '525px',
                maxWidth: '650px',
            },
        }}>
            <Typography fontWeight={600} letterSpacing={'1px'} fontSize={'16px'} mb={'10px'}>Create Additional Detail Parameter</Typography>
            {/*Create Detail Form */}
            <Box>
                {/*Input Type */}
                <FormControl fullWidth variant={'outlined'} size={'medium'}>
                    <InputLabel id="input-type-label" >Select Input Type</InputLabel>
                    <Select
                        labelId="input-type-label"
                        id="input-type-select"
                        value={inputTypeSelected}
                        label="Select Reason For Changing Cook"
                        onChange={(event: SelectChangeEvent) => handleSetInputType(event)}
                    >
                        {Object.values(FieldViewInputTypeEnums)?.map((field: string, index: number) => (
                            <MenuItem key={field} value={field}>{field}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/*Values */}
                <FormControl fullWidth>
                    <Box>
                        <TextField
                            variant={'outlined'}
                            size={'medium'}
                            label={'Values (Press Enter or Add Button to add values)'}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSingleValue(event?.target?.value?.toUpperCase())}
                            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                                if(event?.key === 'Enter') {
                                    if(singleValue) {
                                        handleSetValues();
                                    }
                                }
                            }}
                            sx={{
                                width: '100%',
                            }}
                        />
                        <Button
                            variant={'contained'}
                            sx ={{
                                position: 'absolute',
                                right: '2%',
                                top: valuesSelected?.length !== 0 ? '17%' : '27%',
                                backgroundColor: '#1a751a',
                                letterSpacing: '1px'
                            }}
                            onClick={() => {
                                if(singleValue) {
                                    handleSetValues();
                            }}}
                        >
                            Add
                        </Button>
                    </Box>

                    <Box>
                        {valuesSelected && valuesSelected?.length !== 0 && valuesSelected?.map((value: string, index: number) => (
                            <Chip
                                key={value}
                                label={value}
                                sx={{
                                    ml: '10px',
                                    mt: '8px',
                                }}
                                onDelete={() => handleDeleteValue(value)}
                            />
                        ))}
                    </Box>
                </FormControl>

                {/*Label */}
                <FormControl fullWidth>
                    <TextField
                        variant={'outlined'}
                        size={'medium'}
                        label={'Label'}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSetLabel(event)}
                    />
                </FormControl>

                {/*Active */}
                <Box>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox defaultChecked color={'success'} onChange={handleActiveChecked}/>} label={'Active'} />
                    </FormGroup>
                </Box>

                {/*Submit */}
                <Box mt={'15px'} textAlign={'center'}>
                    <Button
                        variant = 'contained'
                        color = 'primary'
                        sx = {{
                            width: '150px',
                            height: '50px',
                            letterSpacing: '1.5px',
                        }}
                        onClick = {() => {
                            if(inputTypeSelected && [FieldViewInputTypeEnums.SELECT, FieldViewInputTypeEnums.CHECKBOX, FieldViewInputTypeEnums.RADIO].includes(inputTypeSelected)) {
                                if(!valuesSelected?.length) {
                                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                        message: 'Please add values',
                                        type: CustomSnackbarTypes.ERROR
                                    });
                                    return;
                                }
                            }
                            // if()valuesSelected
                            if(inputTypeSelected) {
                                props?.handleSubmitClicked(inputTypeSelected, valuesSelected, label, activeChecked);
                            }
                        }}
                    >
                        Submit
                    </Button>
                </Box>
            </Box>
        </Dialog>
    )
}
export default CreateFieldDialog;
