export enum FieldViewInputTypeEnums {
    TEXT = 'TEXT',
    EMAIL = 'EMAIL',
    NUMBER = 'NUMBER',
    TEL = 'TEL',
    DATE = 'DATE',
    TIME = 'TIME',
    CHECKBOX = 'CHECKBOX',
    RADIO = 'RADIO',
    // To be handled when required
    // PASSWORD = 'PASSWORD',
    // FILE = 'FILE',
    // SUBMIT = 'SUBMIT',
    // BUTTON = 'BUTTON',
    // IMAGE = 'IMAGE',
    // RANGE = 'RANGE',
    // MONTH = 'MONTH',
    // WEEK = 'WEEK',
    // DATETIME_LOCAL = 'DATETIME-LOCAL',
    // DATALIST = 'DATALIST',
    SELECT = 'SELECT',
    TEXTAREA = 'TEXTAREA',

}