import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { useRecordContext, useRefresh } from "react-admin";
import { CookResponse } from "../../interfaces/Cook/CookResponse";
import { useLocation } from "react-router-dom";
import { OneMealService } from "../../services/OneMealService";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import CircularLoader from "../Common/CircularLoader";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import Cooks from "../Cooks/Cooks";
import { Button } from "@mui/material";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import CookVerifyDialog from "../Cooks/CookVerifyDialog";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import OneMealServiceAllocationMetaShow from "./OneMealServiceAllocationMetaShow";
import { OneMealServiceStatus } from "../../enums/OneMealServiceStatus";

const oneMealService = new OneMealService();

const OneMealServiceAllocationTab = (props: any) => {
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  let [subData, setSubData] = useState<any>(null);
  const [sort, setSort] = useState({});
  let [isLoading, setIsLoading] = useState<boolean>();
  let [cookList, setCookList] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  let location = useLocation();
  const record = useRecordContext();
  const refresh = useRefresh();
  let oneMealServiceId = location.pathname.split("/")[2];
  const [cookVerifyDialog, setCookVerifyDialog] = useState(false);
  const [selectedCook, setSelectedCook] = useState<CookResponse>();
  const [cookAvailability, setCookAvailability] = useState({});
  const [loading, setLoading] = useState(false);

  const refreshData = (
    oneMealServiceId: number,
    limit: number,
    offset: number,
    sort: any
  ) => {
    setIsLoading(true);
    oneMealService
      .getAcceptedLeadForOneMeal(oneMealServiceId, limit, offset, sort)
      .then((data) => {
        setSubData(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    refreshData(props.oneMealServiceId, rowsPerPage, page * rowsPerPage, sort);
  }, [props.oneMealServiceId, rowsPerPage, page, sort]);

  const handleSelectCook = () => {
    setCookList(true);
    dispatch(
      setCustomSelectOpen({
        open: true,
      })
    );
  };

  const handleClose = (recordData: any) => {
    let oneMealServiceId = location.pathname.split("/")[2];
    setCookList(false);
    dispatch(
      setCustomSelectOpen({
        open: false,
      })
    );
    if (!recordData) return;
    setSelectedCook(recordData);
    setLoading(true);
    setCookVerifyDialog(true);
    oneMealService
      .checkCookAvaibilityForOneMeal(recordData?.id, Number(oneMealServiceId))
      .then((res) => {
        setCookAvailability(res);
        setLoading(false);
      });
  };

  return (
    <div>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <div>
          {cookList && (
            <ResourceSelectDialog
              name={"Cooks"}
              list={Cooks}
              onClose={handleClose}
            />
          )}
          {[
            OneMealServiceStatus.PENDING,
            OneMealServiceStatus.COOK_ALLOCATED,
          ].includes(record?.status) && (
            <Button
              style={{ margin: 10 }}
              variant="contained"
              onClick={() => {
                handleSelectCook();
              }}
              size="small"
            >
              Manually Shortlist Chef
            </Button>
          )}
          {subData &&
            subData?.data &&
            Array.isArray(subData?.data) &&
            (subData?.data?.length > 0 ? (
              <OneMealServiceAllocationMetaShow
                data={subData?.data?.sort((a: any,b: any) => b?.isMgCook - a?.isMgCook)}
                trail={record}
                setSort={setSort}
                sort={sort}
                refresh={() => {
                  // refreshData(props?.oneMealServiceId, rowsPerPage, page * rowsPerPage, sort);
                }}
              />
            ) : (
              <EmptyListPlaceholder message="No Trials Allocated Found" />
            ))}
        </div>
      )}
      <CookVerifyDialog
        open={cookVerifyDialog}
        handleClose={() => {
          setCookVerifyDialog(false);
          handleSelectCook();
        }}
        loading={loading}
        handleSubmit={() => {
          setCookList(false);
          oneMealService
            .addManualLeadToOneMealService(Number(oneMealServiceId), {
              cookId: Number(selectedCook?.id),
              cookPreference: 1,
            })
            .then(() => {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "successful",
                type: CustomSnackbarTypes.SUCCESS,
              });
              refreshData(props?.oneMealServiceId, rowsPerPage, page * rowsPerPage, sort)
              refresh();
              setCookVerifyDialog(false);
            });
        }}
        selectedCook={selectedCook}
        cookAvailability={cookAvailability}
      />
    </div>
  );
};

export default OneMealServiceAllocationTab;
