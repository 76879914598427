import React, { useEffect, useState } from "react";
import { OneMealService } from "../../services/OneMealService";
import { useRefresh } from "react-admin";
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import OneMealServiceVendorMetaShow from "./OneMealServiceVendorMetaShow";

interface InputProps {
  oneMealServiceId: number;
}

const oneMealService = new OneMealService();

const OneMealVendorPayoutTab = (props: InputProps) => {
  let [subData, setSubData] = useState<any>(null);
  let [isLoading, setIsLoading] = useState<boolean>();
  const refresh = useRefresh();

  const refreshData = (oneMealServiceId: number) => {
    setIsLoading(true);
    oneMealService
      .getVendorPayoutForOneMeal(oneMealServiceId)
      .then((data) => {
        setSubData(data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    refreshData(props?.oneMealServiceId);
  }, [props?.oneMealServiceId]);

  return (
    <div>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <div>
          {subData &&
            Array.isArray(subData) &&
            (subData?.length > 0 ? (
              <OneMealServiceVendorMetaShow
                data={subData}
                updateParentData={refresh}
              />
            ) : (
              <EmptyListPlaceholder message="No VendorPayout Data Found" />
            ))}
        </div>
      )}
    </div>
  );
};

export default OneMealVendorPayoutTab;
