import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'
import moment from 'moment';

const TransactionChefpay = (props: any) => {
    return (
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Currency</TableCell>
                            <TableCell>Order Id</TableCell>
                            <TableCell>Customer Name</TableCell>
                            <TableCell>Payment Initiation Date</TableCell>
                            <TableCell>Payment Success Date</TableCell>
                            <TableCell>User Payment Status</TableCell>
                            <TableCell>Payout Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.map((row: any) => (
                            <TableRow
                                key={row.id}
                                sx={row?.userPaymentState!=="PAID"?{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: "grey !important" }
                                :row?.userPaymentState==="PAID"&&row?.payoutStatus === 'SUCCESS'?{ '&:last-child td, &:last-child th': { border: 0 }}
                                :row?.userPaymentState==="PAID"&&row?.payoutStatus=== 'IN-PROGRESS'?{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: "yellow !important" }
                                :{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: "red !important" }}
                            >
                                <TableCell scope="row">{row?.id}</TableCell>
                                <TableCell align="left">{row?.amount}</TableCell>
                                <TableCell align="left">{row?.currency}</TableCell>
                                <TableCell align="left">{row?.razorpayOrder?.razorpayOrderId}</TableCell>
                                <TableCell align="left">{row?.customer?.fname + " " + row?.customer?.lname}</TableCell>
                                <TableCell align="left">{moment(row?.paymentInitatedDateTime).format("YYYY/MM/DD HH:MM")}</TableCell>
                                <TableCell align="left">{moment(row?.paymentSuccessDateTime).format("YYYY/MM/DD HH:MM")}</TableCell>
                                <TableCell align="left">{row?.userPaymentState}</TableCell>
                                <TableCell align="left">{row?.payoutStatus}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default TransactionChefpay