import { Close } from "@mui/icons-material";
import {
    Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Portal,
    Select,
    Snackbar, TextField
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import * as React from "react";
import { ShortTimeEnum } from "../../enums/ShortTimeEnum";
import { AllVasService } from "../../services/AllVasServices";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "./CustomSnackBar";

interface InputProps {
    data: any;
    open: boolean;
    callback: (data: any) => void;
}

export const CreateTxnDialog = (props: InputProps) => {
    let [date, setDate] = React.useState<Date>();
    const [time, setTime] = React.useState("")
    const [commentFromAdmin, setCommentFromAdmin] = React.useState<string>("")
    let [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const vasService = new AllVasService();

    let handleClose = () => {
        props.callback({
            date,
            time,
            commentFromAdmin
        });
    };
    let handleSnackbarClose = () => {
        setSnackBarOpen(false);
    };

    return (
        <div>
            <Dialog
                onClose={handleClose}
                open={props.open}
                scroll={"paper"}
                maxWidth="xl"
            >
                <DialogTitle>Create Vas</DialogTitle>
                <DialogContent>
                    <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
                    <Grid
                        container
                        columns={1}
                        rowSpacing={0.9}
                        style={{ padding: "12px 12px 12px 12px" }}
                    >
                        <FormControl fullWidth>
                            <DatePicker
                                label="Date"
                                inputFormat="dd MMM yyyy"
                                disableMaskedInput
                                value={date || null}
                                maxDate={new Date(props.data?.endDate)}
                                minDate={new Date(props.data?.startDate)}
                                onChange={(value: any) => setDate(value)}
                                renderInput={(params) => <TextField {...params} />}
                            />

                            <FormControl fullWidth style={{ marginTop: "20px" }}>
                                <InputLabel id="for_Time">Time</InputLabel>
                                <Select
                                    labelId="for_Time"
                                    id="Time"
                                    label="Time"
                                    value={time}
                                    onChange={(event) => setTime(event.target.value)}
                                >
                                    {Object.values(ShortTimeEnum).map((e) => (
                                        <MenuItem key={e} value={e}>
                                            {e}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                label="Comment"
                                id="Comment from admin"
                                value={commentFromAdmin}
                                style={{ marginTop: "30px" }}
                                onChange={(event) => setCommentFromAdmin(event.target.value)}
                            />
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ marginTop: "12px" }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (!date || !time || !commentFromAdmin) {
                                setSnackBarOpen(true);
                                return;
                            }
                            vasService
                                .createVasUserTransactions(props.data.id, {
                                    date : moment(date).format("YYYY-MM-DD"),
                                    time,
                                    commentFromAdmin
                                })
                                .then(() => {
                                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                        message: 'Created',
                                        type: CustomSnackbarTypes.SUCCESS
                                    })
                                    handleClose();
                                });
                        }}
                        size={"large"}
                    >
                        Submit
                    </Button>
                    <Button
                        variant="contained"
                        color={"error"}
                        onClick={() => {
                            handleClose();
                        }}
                        size={"large"}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Portal>
                <Snackbar
                    open={snackBarOpen}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                >
                    <Alert severity="warning">Fill all the information to proceed</Alert>
                </Snackbar>
            </Portal>
        </div>
    );
};
