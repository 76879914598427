import React from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  List,
  NumberField,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
} from "react-admin";
import { CouponProductType, CouponTypeEnum } from "../../enums/CouponTypeEnum";
import {useAppSelector} from "../../store/hooks";
import ResourceSelectDialogButton from "../Common/ResourceSelectDialogButton";

const filters = [
  <SelectInput
    label="Coupon Type"
    source="couponType"
    alwaysOn
    emptyText="Clear Filter"
    choices={Object.values(CouponTypeEnum).map((value) => {
      return {
        id: value,
        name: value,
      };
    })}
  />,
  <SelectInput
    label="Product Type"
    source="productType"
    alwaysOn
    emptyText="Clear Filter"
    choices={Object.values(CouponProductType).map((value) => {
      return {
        id: value,
        name: value,
      };
    })}
  />,
  <TextInput sx={{width: "150px"}} label="Coupon Code" source="couponCode" alwaysOn />,
];

const CouponListV2 = (props: any) => {
  const isCustomSelectOpen = useAppSelector(
      (state) => state.customSelect.value.open
  );
  return (
    <List {...props} filters={filters}>
      <Datagrid bulkActionButtons={false}>
        {isCustomSelectOpen && <ResourceSelectDialogButton />}
        <NumberField source="id" />
        <TextField source="couponCode" sortable={false} />
        <TextField source="couponValue" sortable={false} />
        <TextField source="productType" sortable={false} />
        <TextField source="couponType" sortable={false} />
        <BooleanField label="Coupon Status" source="isActive" />
        <TextField source="maximumDiscountAmount" sortable={false} />
        <TextField source="maximumUsageLimit" sortable={false} />
        <TextField source="minimumOrderValue" sortable={false} />
        <TextField source="reason" sortable={false} />
        <TextField source="usageLimitPerCustomer" sortable={false} />
        <TextField source="uniqueCustomerUsed" sortable={false} />
        <TextField source="totalTimesUsed" />
        <TextField source="couponHeader" sortable={false} />
        <TextField source="couponDescription" sortable={false} />
        <TextField source="createdByEmail" sortable={false} />
        <DateField source="expiresAt" showTime />
        <ShowButton />
        <EditButton/>
      </Datagrid>
    </List>
  );
};

export default CouponListV2;
