import {
  Box,
  Button, Chip,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead, TablePagination,
  TableRow,
} from "@mui/material";
import Stack from '@mui/material/Stack';
import React, {useEffect, useState} from "react";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {LocalityType} from "../../enums/LocalityEnum";
import EventBus from "../../EventBus";
import {CookLocalityMapping} from "../../interfaces/Cook/CookLocalityMapping";
import {CookService} from "../../services/CookServices";
import CkMuiDialog from "../Common/CkMuiDialog/CkMuiDialog";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";

const cookService = new CookService();

interface Props {
  cookId: number;
}
function CookLocalityTab(props: Props) {
  const { cookId } = props;
  const [localityData, setLocalityData] =
    useState<Array<CookLocalityMapping>>();
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] =
    useState<boolean>(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState<boolean>(false);
  // const [selectedSlc, setSelectedSlc] = useState<any>();
  const [selectedSlc] = useState<any>();
  const [selectedSlcType, setSelectedSlcType] = useState<LocalityType[]>([]);


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  let [subData, setSubData] = useState<any>(null);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [localityId, setLocalityId] = useState<number>(NaN);

  const fetchDetails = (cookId: number, limit: number, offset: number) => {
      cookService.getLocalityMapping(cookId, limit, offset).then((res) => {
        setSubData(res?.data);
        setLocalityData(res?.data?.data);
      });
  }

  const handleDeleteLocality = () => {
    cookService
      .deleteLocality(cookId, localityId)
      .then(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Locality Deleted Successfully",
          type: CustomSnackbarTypes.SUCCESS,
        });
        fetchDetails(cookId, rowsPerPage, page * rowsPerPage);
      })
      .catch(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Error Deleting Locality",
          type: CustomSnackbarTypes.ERROR,
        });
      })
      .finally(() => {
        setDeleteConfirmDialogOpen(false);
      });
  };

  const handleDeleteLocalityDialog = (localityId: number) => {
    setDeleteConfirmDialogOpen(true);
    setLocalityId(localityId);
  };

  const handleUpdateLocality = () => {
    const params = {
      "localityId": selectedSlc?.localityId,
      "areaId": selectedSlc?.localityDetails?.areaId,
      "cityId": selectedSlc?.localityDetails?.cityId,
      "preference": selectedSlc?.preference,
      "localityType": selectedSlcType,
    }
    cookService.addLocalityMapping(props.cookId, params).then(() => {
      setUpdateDialogOpen(false);
      fetchDetails(cookId, rowsPerPage, page * rowsPerPage);
    })
  };

  useEffect(() => {
      fetchDetails(cookId, rowsPerPage, page * rowsPerPage);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookId, rowsPerPage, page]);


  return (
    <Box>
      <CkMuiDialog
        isDialogOpen={deleteConfirmDialogOpen}
        setDialogOpen={setDeleteConfirmDialogOpen}
        onDialogClose={() => {}}
        title={""}
        primaryLabel={"Yes"}
        primaryAction={() => {
          handleDeleteLocality();
        }}
        secondaryLabel={"No"}
        secondaryAction={() => {
          setDeleteConfirmDialogOpen(false);
        }}
        isLoading={false}
      >
        <Grid container>
          <Grid item xs={12}>
            Are you sure you want to delete the locality
          </Grid>
        </Grid>
      </CkMuiDialog>
      <CkMuiDialog
        isDialogOpen={updateDialogOpen}
        setDialogOpen={setUpdateDialogOpen}
        onDialogClose={() => {
          setUpdateDialogOpen(false);
        }}
        title={"Update Locality Type"}
        primaryLabel={"Confirm"}
        primaryAction={() => {
          handleUpdateLocality();
        }}
        secondaryLabel={"Cancel"}
        secondaryAction={() => {
          setUpdateDialogOpen(false);
        }}
        isLoading={false}
      >
        <Box pr={2} py={2}>
          <Stack direction="row" spacing={1}>
            {Object?.values(LocalityType).map((each: LocalityType) => {
              let isExists = selectedSlcType?.includes(each);
              return(
                  <Chip
                      key={each}
                      label={each}
                      variant={isExists ? 'filled' : 'outlined'}
                      onClick={() => {
                        if(isExists){
                          setSelectedSlcType((prevState) => prevState.filter((e) => e !== each));
                        } else {
                          setSelectedSlcType((prevState) => [...prevState, each]);
                        }
                      }}
                      onDelete={isExists ? () => {
                        setSelectedSlcType((prevState) => prevState.filter((e) => e !== each));
                      } : undefined}
                  />
              )
            })}
          </Stack>
        </Box>
      </CkMuiDialog>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Locality Id</TableCell>
            <TableCell>Locality Name</TableCell>
            <TableCell>Locality Type</TableCell>
            <TableCell>Area Name</TableCell>
            {/*<TableCell>Update Locality Type</TableCell>*/}
            <TableCell>Delete Locality</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {localityData?.map((row: CookLocalityMapping, index: number) => (
            <TableRow key={String(row.id)}>
              <TableCell key={`id-${row.id}`}>{row.id}</TableCell>
              <TableCell>
                <Link href={`/#/Localities/${row?.localityId}/show`}>
                  {row?.localityId}
                </Link>
              </TableCell>
              <TableCell key={row.localityId}>
                {row?.localityDetails?.name}
              </TableCell>
              <TableCell>{row?.slcTypeList?.join(', ')}</TableCell>
              <TableCell>{row?.localityDetails?.areaName}</TableCell>
              {/*<TableCell key={row.localityId + 'menu'}>*/}
              {/*  {!(row?.slcTypeList?.includes(LocalityType.PAST) || row?.slcTypeList?.includes(LocalityType.WORKING)) && (*/}
              {/*      <Button id="basic-button" onClick={() => {*/}
              {/*        setUpdateDialogOpen(true);*/}
              {/*        setSelectedSlcType(row?.slcTypeList || []);*/}
              {/*        setSelectedSlc(row);*/}
              {/*      }}>*/}
              {/*        Update*/}
              {/*      </Button>*/}
              {/*  )}*/}
              {/*</TableCell>*/}
              <TableCell key={row.localityId + "delete"}>
                {
                  // !(row.localityType.toLocaleLowerCase() === 'past' || row.localityType.toLocaleLowerCase() === 'working')
                  // &&
                  <Button
                    onClick={() => {
                      handleDeleteLocalityDialog(row.localityId);
                    }}
                    color="error"
                    variant="outlined"
                  >
                    Delete
                  </Button>
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
          component="div"
          count={subData?.count || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default CookLocalityTab;
