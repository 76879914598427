import React, {useCallback, useEffect, useState} from 'react';
import {CookService} from "../../services/CookServices";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid, Table, TableCell, TableHead, TableRow,
    TextField,
    Typography
} from "@mui/material";
import {
    GoogleMap,
    useJsApiLoader,
    DirectionsService,
    DirectionsRenderer,
    InfoWindow,
    Marker
} from "@react-google-maps/api";
import {Link} from "react-router-dom";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";
import moment from "moment";
import {UserType} from "../../enums/common";
import CallButton from "../Common/CallButton/CallButton";
import {TrialsService} from "../../services/TrialServices";
import {OneMealService} from "../../services/OneMealService";
import Skeleton from "@mui/material/Skeleton";

const containerStyle = {
    width: '100%',
    height: '40vh'
};

const cookService = new CookService();
const oneMealService = new OneMealService();
const trialsService = new TrialsService();

const CookLocationTracker = ({cookId, visitData}: {cookId: number, visitData: any}) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
    });
    console.log('vissit data::--', visitData);


    const [directionsOptions, setDirectionsOptions] = useState<any>(null);
    const [data, setData] = useState<any>(null);
    const [response, setResponse] = useState<any>(null);
    const [showDirections, setShowDirections] = useState(false);
    const [showInfoWindow, setShowInfoWindow] = useState(true);

    const [distanceInLastTenMin, setDistanceInLastTenMin] = useState<string>('---');
    const [openAllocationNote, setOpenAllocationNote] = useState<boolean>(false);
    const [openChefFeedback, setOpenChefFeedback] = useState<boolean>(false);
    const [note, setNote] = useState<string>('');
    const [service, setService] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleClose = () => {
        setOpenAllocationNote(false);
        setOpenChefFeedback(false);
        setNote('');
        setService(null);
    };
    console.log('data::---',data);
    const haversine = useCallback((lat1: number, lon1: number, lat2: number, lon2: number) => {
        function toRadians(degrees: number) {
            return degrees * (Math.PI / 180);
        }
        const R = 6371; // Earth's radius in kilometers
        const dLat = toRadians(lat2 - lat1);
        const dLon = toRadians(lon2 - lon1);
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        // Distance in Meters
        return R * c * 1000;
    }, [])

    const distanceBetweenLastVisit = (
        cookPastVisit: {lat: number; long: number},
        cookUpcomingVisit: {lat: number; long: number}
    ) => {
        if(!cookUpcomingVisit?.lat || !cookUpcomingVisit?.long) {
            return '---';
        }
        if(!cookPastVisit?.lat || !cookPastVisit?.long) {
            return '---';
        }
        let dist = haversine(cookPastVisit?.lat, cookPastVisit?.long, cookUpcomingVisit?.lat, cookUpcomingVisit?.long);
        return !isNaN(dist) ? `${Number(dist/1000).toFixed(1)} km` : '---'
    };


    const getDistanceInLastTenMin = useCallback(async (
        cookCoordinatesBeforeTenMinutes: {lat: number; long: number},
        cookCurrentCoordinates: {lat: number; long: number},
        cookUpcomingVisit: {lat: number; long: number}
    ) => {
        if(!cookUpcomingVisit?.lat || !cookUpcomingVisit?.long) {
            setDistanceInLastTenMin('---');
            return;
        }
        if(!cookCurrentCoordinates?.lat || !cookCurrentCoordinates?.long) {
            setDistanceInLastTenMin('---');
            return;
        }
        if(!cookCoordinatesBeforeTenMinutes?.lat || !cookCoordinatesBeforeTenMinutes?.long) {
            setDistanceInLastTenMin('---');
            return;
        }
        let dist1 = haversine(cookCurrentCoordinates?.lat, cookCurrentCoordinates?.long, cookUpcomingVisit?.lat, cookUpcomingVisit?.long);
        let dist2 = haversine(cookCoordinatesBeforeTenMinutes?.lat, cookCoordinatesBeforeTenMinutes?.long, cookUpcomingVisit?.lat, cookUpcomingVisit?.long);
        if(!dist1 || !dist2) {
            setDistanceInLastTenMin('---');
            return;
        }
        let final = dist2 - dist1;
        let distance = !isNaN(final) ? `${Number(final/1000).toFixed(1)} km` : '---';
        setDistanceInLastTenMin(distance);
    }, [haversine]);

    const loadData = useCallback((enableLoading: boolean = true) => {
        if(!cookId) return;
        if(enableLoading) {
            setLoading(true);
        }
        setShowDirections(false);
        cookService.getCookActivity(cookId, {referenceModelName: visitData?.entityType, referenceId: visitData?.id, date: visitData?.data})
            .then((data: any) => {
                getDistanceInLastTenMin(data?.cookCoordinatesBeforeTenMinutes, data?.cookCurrentCoordinates, data?.cookUpcomingVisit);
                setData(data);
                setDirectionsOptions({
                    origin: {
                        lat: data?.cookCurrentCoordinates?.lat,
                        lng: data?.cookCurrentCoordinates?.long,
                    },
                    destination: {
                        lat: data?.cookUpcomingVisit?.lat,
                        lng: data?.cookUpcomingVisit?.long,
                    },
                    travelMode: 'DRIVING',
                });
                if(data?.cookCurrentCoordinates?.lat && data?.cookCurrentCoordinates?.long && data?.cookUpcomingVisit?.lat && data?.cookUpcomingVisit?.long) {
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: "Latest Location Updated",
                        type: CustomSnackbarTypes.SUCCESS,
                    });
                } else {
                    if(!data?.cookCurrentCoordinates?.lat) {
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                            message: "Current Location of Cook Not Available",
                            type: CustomSnackbarTypes.ERROR,
                        });
                    } else if(!data?.cookUpcomingVisit?.lat) {
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                            message: "Upcoming Visit Location in not available",
                            type: CustomSnackbarTypes.ERROR,
                        });
                    }
                }
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }, [cookId, getDistanceInLastTenMin, visitData?.data, visitData?.entityType, visitData?.id]);

    const handleRefresh = () => {
        setShowDirections(false);
        loadData(true);
    };

    const onUnmount = React.useCallback(function callback() {}, [])

    const directionsCallback = useCallback((result: any, status: any) => {
        if (status === 'OK') {
            setResponse(result);
            setShowDirections(true); // Show directions once response is received
        } else {
            console.error('Directions request failed with status:', status);
        }
    }, []);

    const calculateETA = () => {
        if (response && response.routes && response.routes.length > 0) {
            const route = response.routes[0];
            if (route.legs && route.legs.length > 0) {
                const leg = route.legs[0];
                if (leg.duration && leg.duration.text) {
                    // setDistance(leg?.distance?.value);
                    // setDuration(leg?.duration?.value);
                    return `${leg?.distance?.text} - ${leg?.duration?.text}`;
                }
            }
        }
        return 'ETA not available';
    };

    const getDistanceDetails = () => {
        if (response && response.routes && response.routes.length > 0) {
            const route = response.routes[0];
            if (route.legs && route.legs.length > 0) {
                const leg = route.legs[0];
                if (leg.duration && leg.duration.text) {
                    return {
                        distance:leg?.distance?.value,
                        duration: leg?.duration?.value,
                    };
                }
            }
        }
        return {
            distance: 0,
            duration: 0,
        };
    };

    const sendMessage = () => {
        let googleEta = getDistanceDetails();
        EventBus.emitEvent(
            EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG,
            {
                title: "Are you sure?",
                message: "Please confirm to send the message",
                successLabel: "Confirm",
                failureLabel: "Cancel",
                onClose: (confirmed: boolean) => {
                    if (confirmed) {
                        if(!googleEta?.duration) {
                            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                message: "There is no ETA available or it is Zero",
                                type: CustomSnackbarTypes.ERROR
                            });
                            return;
                        }
                        if(!googleEta?.distance) {
                            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                message: "There is no Distance available or it is Zero",
                                type: CustomSnackbarTypes.ERROR
                            });
                            return;
                        }
                        cookService.sendWhatsApp({
                            customerPhone: data?.cookUpcomingVisit?.customerPhoneNumber,
                            cookName: data?.cookUpcomingVisit?.cookName,
                            referenceModelName: data?.cookUpcomingVisit?.visitType,
                            date: data?.cookUpcomingVisit?.date,
                            time: data?.cookUpcomingVisit?.time,
                            distance: Math.abs(googleEta?.distance/1000).toFixed(1),
                            eta: Math.abs(googleEta?.duration/60).toString(),
                        }).then(() => {
                            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                message: "Message Sent Successfully",
                                type: CustomSnackbarTypes.SUCCESS
                            });
                        })
                    }
                }
            }
        );
    }

    useEffect(() => {
        setTimeout(() => {
            setShowDirections(false);
            setResponse(null);
            loadData(false);
        }, 2500);
    }, [cookId, loadData]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        if(!data?.cookUpcomingVisit?.id) return;
        if(openAllocationNote || openChefFeedback){
            if(data?.cookUpcomingVisit?.visitType === 'ONE_MEAL_SERVICE'){
                oneMealService.getOneMealService(data?.cookUpcomingVisit?.id).then((res: any) => {
                    setService(res?.data);
                })
            } else {
                trialsService.getTrialsById(data?.cookUpcomingVisit?.id).then((res: any) => {
                    setService(res?.data);
                })
            }
        }
    }, [data?.cookUpcomingVisit?.id, data?.cookUpcomingVisit?.visitType, openAllocationNote, openChefFeedback]);

    return (
        <div>
            {loading ? (
                <Box mb={6}>
                    <Grid container spacing={2}>
                        <Grid md={4} sm={4} xs={12} item>
                            <Skeleton variant="text" width={'50%'} sx={{ fontSize: '1.5rem', mt: 1}} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" width={'50%'} sx={{ fontSize: '1.5rem', mt: 1 }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </Grid>
                        <Grid md={4} sm={4} xs={12} item>
                            <Skeleton variant="text" width={'50%'} sx={{ fontSize: '1.5rem', mt: 1 }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" width={'50%'} sx={{ fontSize: '1.5rem', mt: 1 }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </Grid>
                        <Grid md={4} sm={4} xs={12} item>
                            <Skeleton variant="text" width={'50%'} sx={{ fontSize: '1.5rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '2rem', mt: 3 }} />
                            <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                        </Grid>
                        {/*<Grid md={12} sm={12} xs={12} item>*/}
                        {/*    <Skeleton variant="rectangular" height={'300px'} sx={{mt: 3}} />*/}
                        {/*</Grid>*/}
                    </Grid>
                </Box>
            ) : (
                <Box>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} my={2}>
                        <Grid container spacing={2}>
                            <Grid md={4} sm={4} xs={12} item>
                                <Box>
                                    <Typography fontWeight={700} fontSize={16}>
                                        Cook Details
                                    </Typography>
                                    <Typography fontSize={14}>Cook Id: {cookId ? (
                                        <Link to={`/Cooks/${cookId}/show`}>
                                            {cookId}
                                        </Link>
                                    ) : '---'}</Typography>
                                    <Typography fontSize={14}>Cook Name: {data?.cookPastVisit?.cookName || data?.cookUpcomingVisit?.cookName || '---'}</Typography>
                                    <Typography fontSize={14} display={'flex'}>{'Mobile Number: '}{(data?.cookPastVisit?.cookPhoneNumber || data?.cookUpcomingVisit?.cookPhoneNumber) ? <CallButton
                                        toPhoneNumber={data?.cookPastVisit?.cookPhoneNumber || data?.cookUpcomingVisit?.cookPhoneNumber}
                                        userType={UserType.COOK}
                                    /> : '---'}</Typography>
                                </Box>
                                <Box>
                                    <Typography fontWeight={700} fontSize={16}>
                                        Cook Current Location
                                    </Typography>
                                    <Typography fontSize={14}>Updated At: {data?.cookCurrentCoordinates?.createdAt ? moment(data?.cookCurrentCoordinates?.createdAt, "YYYY-MM-DD HH:mm:ss").format("MMM Do YYYY, h:mm a") : '---'}</Typography>
                                    <Typography fontSize={14}>Distance in last 10 mins: {distanceInLastTenMin}</Typography>
                                    <Typography fontSize={14}>Distance form last Visit: {distanceBetweenLastVisit(data?.cookPastVisit, data?.cookUpcomingVisit)}</Typography>
                                    <Typography fontSize={14}>Cook Work Status: {data?.cookWorkStatus || '---'}</Typography>
                                    <Typography fontSize={14}>Mode Of Transport: {data?.modeOfTransport || '---'}</Typography>
                                </Box>
                            </Grid>
                            <Grid md={4} sm={4} xs={12} item>
                                {data?.cookPastVisit && (
                                    <Box>
                                        <Typography fontWeight={700} fontSize={16}>
                                            Cook Last Visit
                                        </Typography>
                                        <Typography fontSize={14}>Id: {data?.cookPastVisit?.id ? (
                                            <Link to={`/${data?.cookPastVisit?.visitType === 'ONE_MEAL_SERVICE' ? 'OneMealService' : data?.cookPastVisit?.visitType === 'COOK_SUBSCRIPTION_V3' ? 'UserBundles' : 'Trials'}/${data?.cookPastVisit?.id}/show`}>
                                                {data?.cookPastVisit?.id}
                                            </Link>
                                        ) : '---'}  {data?.cookPastVisit?.visitType ? `(${data?.cookPastVisit?.visitType})` : ''}</Typography>
                                        <Typography fontSize={14}>Customer Name: {data?.cookPastVisit?.customerName || '---'}</Typography>
                                        <Typography fontSize={14} display={'flex'}>Mobile Number: {data?.cookPastVisit?.customerPhoneNumber ? <CallButton
                                            toPhoneNumber={data?.cookPastVisit?.customerPhoneNumber}
                                            userType={UserType.CUSTOMER}
                                        /> : '---'}</Typography>
                                        <Typography fontSize={14}>Date: {data?.cookPastVisit?.date || ''} {data?.cookPastVisit?.time || '---'}</Typography>
                                        <Typography fontSize={14}>Check In: {data?.cookPastVisit?.checkInTime ? moment(data?.cookPastVisit?.checkInTime).format('hh:mm A') : 'N/A'}</Typography>
                                        <Typography fontSize={14}>Check Out: {data?.cookPastVisit?.checkOutTime ? moment(data?.cookPastVisit?.checkOutTime).format('hh:mm A') : 'N/A'}</Typography>
                                    </Box>
                                )}
                                {data?.cookUpcomingVisit && (
                                    <Box>
                                        <Typography fontWeight={700} fontSize={16}>
                                            Cook Upcoming Visit
                                        </Typography>
                                        <Typography fontSize={14}>Id: {data?.cookUpcomingVisit?.id ? (
                                            <Link to={`/${data?.cookUpcomingVisit?.visitType === 'ONE_MEAL_SERVICE' ? 'OneMealService' : data?.cookUpcomingVisit?.visitType === 'COOK_SUBSCRIPTION_V3' ? 'UserBundles' : 'Trials'}/${data?.cookUpcomingVisit?.id}/show`}>
                                                {data?.cookUpcomingVisit?.id}
                                            </Link>
                                        ) : '---'}  {data?.cookUpcomingVisit?.visitType ? `(${data?.cookUpcomingVisit?.visitType})` : ''}</Typography>
                                        <Typography fontSize={14}>Customer Name: {data?.cookUpcomingVisit?.customerName || '---'}</Typography>
                                        <Typography fontSize={14} display={'flex'}>Mobile Number: {data?.cookUpcomingVisit?.customerPhoneNumber ? <CallButton
                                            toPhoneNumber={data?.cookUpcomingVisit?.customerPhoneNumber}
                                            userType={UserType.CUSTOMER}
                                        /> : '---'}</Typography>
                                        <Typography fontSize={14}>Date: {data?.cookUpcomingVisit?.date || ''} {data?.cookUpcomingVisit?.time || '---'}</Typography>
                                        {data?.cookUpcomingVisit?.checkInTime ? <Typography fontSize={14}>Check In Time: {moment(data?.cookUpcomingVisit?.checkInTime).format('hh:mm A')}</Typography> : ''}
                                        {data?.cookUpcomingVisit?.checkOutTime ? <Typography fontSize={14}>Check Out Time: {moment(data?.cookUpcomingVisit?.checkOutTime).format('hh:mm A')}</Typography> : ''}
                                        {visitData?.preCheckedInAt?<Typography fontSize={14}>Pre Check In Time: {moment(visitData?.preCheckedInAt).format('DD/MM/YYYY hh:mm A')}</Typography> : ''}
                                    </Box>
                                )}
                            </Grid>
                            <Grid md={4} sm={4} xs={12} item>
                                <Box display={'flex'} flexDirection={'column'} justifyContent={'end'}>
                                    <div>Estimated Time of Arrival: </div>
                                    <Typography fontWeight={700} fontSize={16}>
                                        {calculateETA()}
                                    </Typography>
                                    <Button size={'small'} variant={'contained'} sx={{mt: 2}} onClick={handleRefresh}>
                                        GET CURRENT LOCATION
                                    </Button>
                                    <Button size={'small'} variant={'contained'} sx={{mt: 2}} onClick={() => {setOpenAllocationNote(true)}} disabled={!data?.cookUpcomingVisit?.id} color={'success'}>
                                        Tracker Note
                                    </Button>
                                    <Button size={'small'} variant={'contained'} sx={{mt: 2}} onClick={() => {setOpenChefFeedback(true)}} disabled={!data?.cookUpcomingVisit?.id} color={'success'}>
                                        Chef Feedback
                                    </Button>
                                    <Button
                                        size={'small'}
                                        variant={'contained'}
                                        sx={{mt: 2}}
                                        onClick={sendMessage}
                                        disabled={!data?.cookUpcomingVisit?.id || getDistanceDetails().distance >= 15000 || getDistanceDetails().duration > 3600}
                                        color={'success'}
                                    >
                                        Send Details via Whatsapp
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid md={12} sm={12} xs={12} item>
                                <Box display={'flex'} justifyContent={'flex-end'}>
                                    <Box display={'flex'} alignItems={'center'} mr={2}>
                                        <img src={'/map_a.png'} alt={'map'} style={{width: '20px'}} />
                                        <Typography sx={{ml: 1}}>
                                            {'Current Location'}
                                        </Typography>
                                    </Box>
                                    <Box display={'flex'} alignItems={'center'} mr={2}>
                                        <img src={'/map_b.png'} alt={'mapB'} style={{width: '20px'}} />
                                        <Typography sx={{ml: 1}}>
                                            {'Destination'}
                                        </Typography>
                                    </Box>
                                    <Box display={'flex'} alignItems={'center'} mr={2}>
                                        <img src={'/map_c.png'} alt={'mapC'} style={{width: '20px'}} />
                                        <Typography sx={{ml: 1}}>
                                            {'Last Visit'}
                                        </Typography>
                                    </Box>
                                    {
                                        visitData?.preCheckedInAt?<Box display={'flex'} alignItems={'center'}>
                                        <img src={'/map_D.png'} alt={'mapD'} style={{width: '20px'}} />
                                        <Typography sx={{ml: 1}}>
                                            {'Pre Checkin'}
                                        </Typography>
                                    </Box>:<></>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            )}
            {isLoaded ? (
                <div>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        // zoom={7}
                        onLoad={() => {
                            loadData();
                        }}
                        onUnmount={onUnmount}
                    >
                        {data?.cookPastVisit?.lat && data?.cookPastVisit?.long && (
                            <Marker position={{
                                lat: data?.cookPastVisit?.lat,
                                lng: data?.cookPastVisit?.long
                            }}
                                    label={'C'}
                            />
                        )}
                        {visitData?.coordinatesAtPreCheckin?.coordinates?.[1] && visitData?.coordinatesAtPreCheckin?.coordinates?.[0] && (
                            <Marker
                                position={{
                                    lat: visitData?.coordinatesAtPreCheckin?.coordinates[1],
                                    lng: visitData?.coordinatesAtPreCheckin?.coordinates[0]
                                }}
                                label={'D'}
                            />
                        )}
                        {!showDirections && directionsOptions?.origin?.lat && directionsOptions?.destination?.lat && (
                            <DirectionsService
                                // @ts-ignore
                                options={{...directionsOptions}}
                                callback={directionsCallback}
                            />
                        )}
                        {/*{!showDirections && directionsOptions?.origin?.lat && directionsOptions?.destination?.lat && (*/}
                        {/*    <DistanceMatrixService*/}
                        {/*        // @ts-ignore*/}
                        {/*        options={{...directionsOptions}}*/}
                        {/*        callback={distanceCallback}*/}
                        {/*    />*/}
                        {/*)}*/}
                        {showDirections && directionsOptions?.origin?.lat && directionsOptions?.destination?.lat && response &&
                            <DirectionsRenderer
                                directions={response}
                            />
                        }
                        {showInfoWindow && directionsOptions?.origin?.lat && directionsOptions?.destination?.lat && (
                            <InfoWindow
                                position={directionsOptions?.destination}
                                onCloseClick={() => setShowInfoWindow(false)}
                            >
                                <div>Estimated Time of Arrival: {calculateETA()}</div>
                            </InfoWindow>
                        )}
                    </GoogleMap>
                </div>
            ) : (
                <Box>
                    <Skeleton variant="rectangular" height={'300px'} sx={{mt: 3}} />
                </Box>
            )}
            {(openChefFeedback || openAllocationNote) && (
                <Dialog open={openAllocationNote || openChefFeedback} onClose={handleClose}>
                    <DialogTitle>{openChefFeedback ? 'Chef Feedback' : `Tracker Notes`}</DialogTitle>
                    <DialogContent>
                        <Table size={'small'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Comment</TableCell>
                                    <TableCell>Commenter email</TableCell>
                                    <TableCell>Time</TableCell>
                                </TableRow>
                            </TableHead>
                            {(service && openChefFeedback && service?.chefFeedback?.length) ? (
                                service?.chefFeedback?.map((comment: any) => (
                                    <TableRow>
                                        <TableCell>{comment?.comment}</TableCell>
                                        <TableCell>{comment?.commenterEmail}</TableCell>
                                        <TableCell>{moment(comment?.timestamp).format('LLL')}</TableCell>
                                    </TableRow>
                                ))
                            ) : (openChefFeedback ? <caption>No Comments</caption> : '')}
                            {(service && openAllocationNote && service?.allocationComments?.length) ? (
                                service?.allocationComments?.map((comment: any) => (
                                    <TableRow>
                                        <TableCell>{comment?.comment}</TableCell>
                                        <TableCell>{comment?.commenterEmail}</TableCell>
                                        <TableCell>{moment(comment?.timestamp).format('LLL')}</TableCell>
                                    </TableRow>
                                ))
                            ) : (openAllocationNote ? <caption>No Comments</caption> : '')}
                        </Table>
                        <TextField
                            sx={{mt: 3}}
                            fullWidth
                            label="Enter the Comment"
                            value={note}
                            variant={'filled'}
                            onChange={(event) => {
                                setNote(event.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color={"success"}
                            onClick={() => {
                                console.log(data)
                                if(openChefFeedback) {
                                    if(data?.cookUpcomingVisit?.visitType === 'ONE_MEAL_SERVICE') {
                                        oneMealService.addHcFeedbackForOneMeal(data?.cookUpcomingVisit?.id, {
                                            commentOrFeedback: note,
                                        }).then(() => {
                                            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                                message: "Successful",
                                                type: CustomSnackbarTypes.SUCCESS,
                                            });
                                            handleClose();
                                        });
                                    } else {
                                        trialsService.addHcFeedback(data?.cookUpcomingVisit?.id, {
                                            commentOrFeedback: note,
                                        }).then(() => {
                                            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                                message: "Successful",
                                                type: CustomSnackbarTypes.SUCCESS,
                                            });
                                            handleClose();
                                        });
                                    }
                                } else {
                                    if(data?.cookUpcomingVisit?.visitType === 'ONE_MEAL_SERVICE') {
                                        oneMealService.addAllocationNotesForOneMeal(data?.cookUpcomingVisit?.id, {
                                            commentOrFeedback: note,
                                        }).then(() => {
                                            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                                message: "Successful",
                                                type: CustomSnackbarTypes.SUCCESS,
                                            });
                                            handleClose();
                                        });
                                    } else {
                                        trialsService.addAllocationNotes(data?.cookUpcomingVisit?.id, {
                                            commentOrFeedback: note,
                                        }).then(() => {
                                            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                                message: "Successful",
                                                type: CustomSnackbarTypes.SUCCESS,
                                            });
                                            handleClose();
                                        });
                                    }
                                }
                            }}
                            size={"large"}
                        >
                            Submit
                        </Button>
                        <Button
                            variant="contained"
                            color={"error"}
                            onClick={handleClose}
                            size={"large"}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};

export default CookLocationTracker;
