import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import TimeSlotDto from "../../dtos/Partner/timeSlotDto";
import {
  CookSlotTimeEnum,
  CookSlotTimeEnumArray,
} from "../../enums/CookSlotTimeEnum";
import { PartnerTimeSlotStatusEnums } from "../../enums/partnerTimeSlotStatusEnums";
import PartnerSectorService from "../../services/PartnerSectorService";
import PartnerSlotService from "../../services/PartnerTimeSlotService";
import CircularLoader from "../Common/CircularLoader";
import moment from "moment";
import { useLocation } from "react-router-dom";
import "./styles.css";
import { CookService } from "../../services/CookServices";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import { useRecordContext, useRefresh } from "react-admin";
import {useAppSelector} from "../../store/hooks";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";
import {UserGroupsEnum} from "../../enums/UserGroupsEnum";

const partnerSlotService = new PartnerSlotService();
const partnerSectorService = new PartnerSectorService();
const cookService = new CookService();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const constructStaticSlotMap = (): Map<
  CookSlotTimeEnum,
  {
    slotName: CookSlotTimeEnum;
    status: PartnerTimeSlotStatusEnums;
    color: string;
    trialBlockedAt: Date | null;
  }
> => {
  const slotMap = new Map<CookSlotTimeEnum, any>();

  for (const timeEnumkey of CookSlotTimeEnumArray) {
    slotMap.set(timeEnumkey, {
      slotName: timeEnumkey,
      status: PartnerTimeSlotStatusEnums.BLOCKED,
      color: "#ffdede",
      trialBlockedAt: null,
    });
  }
  return slotMap;
};

const CookTimeSlotList = (props: any) => {
  const userDetails = useAppSelector((state) => state.userDetails.value);
  let groupsArray: string[] = userDetails.adminUser.accessGroups;
  const [updateSectorModal, setUpdateSectorModal] = useState<boolean>(false);
  const [serviceSector, setServiceSector] = useState<number[]>([0]);
  const [slots, setSlots] = useState<
    Map<
      CookSlotTimeEnum,
      {
        slotName: CookSlotTimeEnum;
        status: PartnerTimeSlotStatusEnums;
        color: string;
        trialBlockedAt: Date | null;
      }
    >
  >(constructStaticSlotMap());
  const [isDataAvailable, setDataAvailable] = useState<boolean>(false);
  const [dataChange, setDataChange] = useState<boolean>(false);
  const location = useLocation();
  const cookId = location.pathname.split("/")[2];

  let record = useRecordContext();
  const refresh = useRefresh();
  const getSlots = useCallback(async (cookId: number) => {
    setDataChange(false);
    const response = await partnerSlotService.getCookSlots(cookId);
    let slotMap = constructStaticSlotMap();
    response.forEach((element) => {
      let val = slotMap.get(element.timeSlot as any as CookSlotTimeEnum);
      if (val) {
        val.slotName = element.timeSlot as any as CookSlotTimeEnum;
        val.trialBlockedAt = element.trialBlockedAt;
        val.status = element.status;
        val.color = getColor(element.status);
      }
    });
    setSlots(slotMap);
  }, []);

  useEffect(() => {
    getSlots(props.cookId);
    setDataAvailable(true);
  }, [props.cookId, dataChange, getSlots]);

  const handleSectorChange = (e: any) => {
    setServiceSector(e.target.value);
  };

  const handleCloseSectorModal = () => {
    setDataChange(true);
    setUpdateSectorModal(false);
  };

  const getColor = (status: String): string => {
    if (status === "FREE") {
      return "#e6ffe6";
    } else if (status === "BLOCKED") {
      return "#ffdede";
    } else {
      return "#fffff2";
    }
  };

  const requestForWork = async () => {
    try {
      await cookService.requestedForWorkCook(Number(props.cookId));
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Success",
        type: CustomSnackbarTypes.SUCCESS,
      });
      refresh();
    } catch (error) {
      console.error(error);
    }
    return;
  };
  const recalculateV1 = async () => {
    try {
      await cookService.recalculateV1(Number(props.cookId));
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Success",
        type: CustomSnackbarTypes.SUCCESS,
      });
      refresh();
    } catch (error) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Error",
        type: CustomSnackbarTypes.ERROR,
      });
    }
  }
  const recalculateV2 = async () => {
    try {
      await cookService.recalculateV2(Number(props.cookId));
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Success",
        type: CustomSnackbarTypes.SUCCESS,
      });
      refresh();
    } catch (error) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Error",
        type: CustomSnackbarTypes.ERROR,
      });
    }
  }
  return (
    <div>
      {!isDataAvailable ? (
        <div>
          <CircularLoader />
        </div>
      ) : (
        <div>
          <Dialog
            open={updateSectorModal}
            scroll={"paper"}
            maxWidth="xl"
            onBackdropClick={handleCloseSectorModal}
          >
            <DialogTitle>Update Sector</DialogTitle>
            <DialogContent>
              <Grid
                container
                spacing={2}
                justifyContent={"space-evenly"}
                style={{ padding: "12px 12px 12px 12px" }}
              >
                <Grid item>
                  <FormControl style={{ minWidth: 120 }} fullWidth>
                    <InputLabel id="demo-multiple-name-label">
                      Sectors
                    </InputLabel>
                    <Select
                      autoWidth={true}
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple
                      value={serviceSector}
                      onChange={handleSectorChange}
                      input={<OutlinedInput label="Sectors" />}
                      MenuProps={MenuProps}
                    >
                      {[...Array(200)].map((x, i) => (
                        <MenuItem key={i + 1} value={i + 1}>
                          {i + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ marginTop: "12px" }}>
              <Button
                variant="contained"
                onClick={() => {
                  partnerSectorService
                    .postCookSector(props.cookId, serviceSector)
                    .then(() => {
                      setDataChange(true);
                      setUpdateSectorModal(false);
                    });
                }}
                size={"large"}
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>

          <div className="serviceSector">
            <Button
                variant="contained"
                size="small"
                style={{marginLeft: '15px', marginRight: '15px'}}
                onClick={() => recalculateV1()}
                disabled={!GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN])}
            >
              Recalculate V1 slot
            </Button>
            <Button
                variant="contained"
                size="small"
                style={{marginLeft: '15px', marginRight: '15px'}}
                onClick={() => recalculateV2()}
                disabled={!GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN])}
            >
              Recalculate V2 slot
            </Button>
            {record.workRequestedAt ? 'Already requested for work' : <Button variant="contained" size="small" onClick={requestForWork}>
              Requested for Work
            </Button>}
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Time Slot</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Trial Blocked At</TableCell>
                  <TableCell align="center">Update Cook Slot</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(slots.entries()).map((entry) => {
                  const [key, value] = entry;
                  return (
                    <TableRow
                      key={key}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{value?.slotName}</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          height: "30px",
                          backgroundColor: value?.color,
                          color: "#263238"
                        }}
                      >
                        <div>{value?.status}</div>
                      </TableCell>
                      <TableCell align="center">
                        {String(
                          value?.trialBlockedAt
                            ? moment(value.trialBlockedAt).format(
                                "YYYY/MM/DD, HH:MM"
                              )
                            : ""
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {value?.status ===
                        PartnerTimeSlotStatusEnums.CK_BLOCKED ? (
                          ""
                        ) : (
                          <>
                            <Button
                              variant="contained"
                              style={{ marginRight: "10px" }}
                              onClick={() => {
                                const timeSlotDto: TimeSlotDto = {
                                  timeSlot: value?.slotName,
                                  status: PartnerTimeSlotStatusEnums.FREE,
                                };
                                const data: TimeSlotDto[] = [];
                                data.push(timeSlotDto);
                                partnerSlotService
                                  .postSlotsForCook(Number(cookId), data)
                                  .then(() => {
                                    setDataChange(true);
                                  });
                              }}
                              size={"medium"}
                            >
                              Free
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() => {
                                const timeSlotDto: TimeSlotDto = {
                                  timeSlot: value?.slotName,
                                  status: PartnerTimeSlotStatusEnums.BLOCKED,
                                };
                                const data: TimeSlotDto[] = [];
                                data.push(timeSlotDto);
                                partnerSlotService
                                  .postSlotsForCook(Number(cookId), data)
                                  .then(() => {
                                    setDataChange(true);
                                  });
                              }}
                              size={"medium"}
                            >
                              Blocked
                            </Button>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default CookTimeSlotList;
