import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import moment from 'moment';


export default function HorizontalTimeline(props:any) {
    const[times,setTimes]=React.useState<any>([]);
    React.useEffect(()=>{
        let timeSteps=[];
            if(props?.record?.createdAt){
                timeSteps.push(["createdAt",moment(props?.record?.createdAt).format("DD/MM/YYYY hh:mm A")],)
            }
            if(props?.record?.date&&props?.record?.time){
                timeSteps.push(["Visit Time",moment(props?.record?.date).format("DD/MM/YYYY")+" "+props?.record?.time])
            }
            if(props?.record?.checkInTime){
                timeSteps.push(["Check-In",moment(props?.record?.checkInTime).format("DD/MM/YYYY hh:mm A")])
            }
            if(props?.record?.preCheckedInAt){
                timeSteps.push(["Pre-Check In",moment(props?.record?.preCheckedInAt).format("DD/MM/YYYY hh:mm A")])
            }
            if(props?.record?.checkOutTime){
                timeSteps.push(["Check-out",moment(props?.record?.checkOutTime).format("DD/MM/YYYY hh:mm A")])
            }
            if(props?.record?.cancelledAt){
                timeSteps.push(["Cancelled At",moment(props?.record?.cancelledAt).format("DD/MM/YYYY hh:mm A")])
            }

        timeSteps.sort((a:any, b:any) => {
            const dateA = moment(a[1], "DD/MM/YYYY hh:mm A").valueOf(); // Convert to timestamp
            const dateB = moment(b[1], "DD/MM/YYYY hh:mm A").valueOf(); // Convert to timestamp
            return dateA - dateB;  // ascending order
        });
        setTimes(timeSteps);
    },[props])
  return (
    <Box sx={{ width: '100%' }}>
        {
            times&&times.length>0?
            <Stepper alternativeLabel activeStep={times.length}>
                {times.map((label:any) => (
                <Step key={label}>
                    <StepLabel>
                        <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                            <Box fontWeight="bold">{label[0]}</Box>
                            <Box sx={label[0]==="Visit Time"?{color:"green"}:{}}>{label[1]}</Box>
                        </Box>    
                    </StepLabel>
                </Step>
                ))}
            </Stepper>:
          <></>
        } 
    </Box>
  );
}
