import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useRefresh } from "react-admin";
import React, { useState } from "react";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import ChefForPartyService from "../../services/ChefForPartyService";
import { useLocation } from 'react-router-dom';

interface Props {
  data: any;
  open: boolean;
  callback: (data: any) => void;
  refresh: any
}

const chefForPartyService = new ChefForPartyService();

const ApproveChefForParty = (props: Props) => {

  const [accepted, setAccepted] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const refresh = useRefresh();
  const location = useLocation();
  const partyId = location.pathname.split('/')[2]
  let handleClose = () => {
    props.callback({ accepted, reason });
    setReason("");
  };
  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      scroll={"paper"}
      maxWidth="xl"
    >
      <DialogTitle>Accept/Reject Chef for Party</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent={"space-evenly"}
          style={{ padding: "12px 12px 12px 12px", width:"270px"}}
        >
          <Grid item>
            <FormControl fullWidth style={{ marginTop: "20px" }}>
              <InputLabel id="for_Accept">Action</InputLabel>
              <Select
                labelId="for_Accept"
                id="Accept"
                label="Accept"
                value={accepted}
                onChange={(event) => setAccepted(event.target.value)}
              >
                {Object.values(["Accept", "Reject"]).map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {(accepted && accepted !== "Accept") &&(
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  id="reason"
                  onChange={(event) => {
                    setReason(event.target.value);
                  }}
                  value={reason}
                  label="Enter Reason"
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginTop: "12px" }}>
        <Button
          variant="contained"
          onClick={() => {
            if (!accepted || (accepted!=="Accept" && !reason)) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Fill the details to proceed",
                type: CustomSnackbarTypes.WARNING,
              });
              return;
            }
            chefForPartyService
            .approvalChefForParty(Number(partyId), {
              accepted: accepted === "Accept" ? true : false,
              reason,
              cookId: Number(props?.data?.cookId)
            })
            .then(() => {
              refresh();
              handleClose();
            })
            .finally(() => {
              if (props.refresh) {
                props.refresh();
              }
            });
          }}
          size={"large"}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            handleClose();
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveChefForParty;
