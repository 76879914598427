import { Button, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Show,
  Tab,
  TabbedShowLayout,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { TemporaryReplacementStatusEnum } from "../../enums/TemporaryReplacementStatusEnum";
import EventBus from "../../EventBus";
import { TempReplacementService } from "../../services/TemporaryReplacement";
import AssignCookOnTempRep from "../Common/AssignCookOnTempRep";
import { ChangeTrTimeDialog } from "../Common/ChangeTrTimeDailog";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import MarkFailedDialogForTR from "../Common/MarkFailedDialogForTR";
import UserBundleTemporaryReplacementTab from "../UserBundleMapping/UserBundleTemporaryReplacementTab";
import CustomerDetailsTab from "./CustomerDetailsTab";
import MultipleTemporaryReplacement from "./MultipleTemporaryReplacement";
import { useLocation } from "react-router-dom";
import TemporaryReplacementMetaShow from "./TemporaryReplacementMetaShow";
import TemporaryReplacementAllocationTab from "./TemporaryReplacementAllocationTab";

const temporaryReplacement = new TempReplacementService();

const CustomButton = (props: any) => {
  let record = useRecordContext();

  return (
    <Button
      onClick={() => {
        props.callback(record);
      }}
      size={"small"}
      color={props.color || "secondary"}
      variant={"contained"}
      style={{ width: "max-content", fontSize: "12px" }}
    >
      {props.name}
    </Button>
  );
};

export const ActionButtons = (props: any) => {
  let record = useRecordContext();
  const refresh = useRefresh();

  const fullfilledHandler = (data: any) => {
    temporaryReplacement.markedFulfilled(data?.id).then(() => refresh());
  };

  const whatsAppHandler = async (data: any) => {
    try {
      let msgData: any = await temporaryReplacement.getWhatsAppMsg(data.id);
      window.open(
        `https://api.whatsapp.com/send?phone=+91${data?.cook?.whatsappNumber}&text=${msgData?.data}`
      );
    } catch (err) {
      console.log(err);
    }
  };

  const HandleOngoing = (data: any) => {
    temporaryReplacement.markOngoing(data?.id).then(() => {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Successful",
        type: CustomSnackbarTypes.SUCCESS,
      });
      refresh();
    });
  };

  return (
    <div>
      <Stack direction={"row"} gap={2}>
        { [ TemporaryReplacementStatusEnum.PENDING,
          TemporaryReplacementStatusEnum.COOK_ALLOCATED,
        ].includes(record?.status) && (
          <>
            <CustomButton
              color={"primary"}
              name={record?.status === "PENDING" ? "Assign Cook" : "Change Cook"}
              callback={() => {
                props.setData(record);
                props.setMultipleTrAllocateDialog(true);
              }}
            />
            <CustomButton
                color={"primary"}
                name={"Change TR Time"}
                callback={() => {
                  props.setChangeTimeDailog(true);
                  props.setData(record);
                }}
            />
          </>
        )}
        {[
          TemporaryReplacementStatusEnum.PENDING,
          TemporaryReplacementStatusEnum.ONGOING,
          TemporaryReplacementStatusEnum.COOK_ALLOCATED,
        ].includes(record?.status) && (
          <CustomButton
            color={"primary"}
            name={"Cancel"}
            callback={() => {
              props.setConfirmDialog(true);
              props.setData(record);
            }}
          />
        )}
        {[TemporaryReplacementStatusEnum.COOK_ALLOCATED].includes(
          record?.status
        ) && (
          <CustomButton
            color={"primary"}
            name={"Mark Ongoing"}
            callback={() => {
              EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
                title: "Are you sure?",
                message: "Are you sure you want to mark ongoing this TR?",
                successLabel: "Confirm",
                failureLabel: "Cancel",
                onClose: (isConfirmed: boolean) =>
                  isConfirmed ? HandleOngoing(record) : null,
              });
            }}
          />
        )}
        {[
          TemporaryReplacementStatusEnum.COOK_ALLOCATED,
          TemporaryReplacementStatusEnum.ONGOING,
        ].includes(record?.status) && (
          <CustomButton
            color={"primary"}
            name={"Mark Fulfilled"}
            callback={() => fullfilledHandler(record)}
          />
        )}
        {[
          TemporaryReplacementStatusEnum.COOK_ALLOCATED,
          TemporaryReplacementStatusEnum.ONGOING,
          TemporaryReplacementStatusEnum.FULFILLED,
          TemporaryReplacementStatusEnum.PENDING,
        ].includes(record?.status) && (
          <CustomButton
            color={"primary"}
            name={"Mark Failed"}
            callback={() => {
              props.setMarkFailedDialog(true);
              props.setData(record);
            }}
          />
        )}
        {[
          TemporaryReplacementStatusEnum.COOK_ALLOCATED,
          TemporaryReplacementStatusEnum.ONGOING,
          TemporaryReplacementStatusEnum.FULFILLED,
          TemporaryReplacementStatusEnum.PENDING,
          TemporaryReplacementStatusEnum.CANCELLED,
          TemporaryReplacementStatusEnum.FAILED,
        ].includes(record?.status) && record.cook && (
          <CustomButton
            color={"primary"}
            name={"Send WhatsApp"}
            callback={() => whatsAppHandler(record)}
          />
        )}
      </Stack>
    </div>
  );
};

const TemporaryReplacementShow = (props: any) => {
  const refresh = useRefresh();
  const [data, setData] = useState<any>(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [multipleTrAllocateDialog, setMultipleTrAllocateDialog] =
    useState(false);
  const [changeTimeDailog, setChangeTimeDailog] = useState<boolean>(false);
  const [openAssignCookToTrDialog, setOpenAssignCookToTrDialog] =
    useState(false);
  const [sendMultipleWhatsAppTr, setSendMultipleWhatsAppTr] = useState(false);
  const [markFailedDialog, setMarkFailedDialog] = useState(false);
  const [trData, setTrData] = useState<any>();
  const location = useLocation();
  const trId = location.pathname.split("/")[2];

  useEffect(() => {
    const fetchData = async () => {
      const trData = await temporaryReplacement.getOneTempReplacement(Number(trId));
      setTrData(trData?.data)
    }
    fetchData();
    const cancelHandler = (data: any) => {
      temporaryReplacement.markedCancel(data?.id).then(() => {
        refresh();
        setConfirmDialog(false);
      });
    };

    if (confirmDialog) {
      EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
        message: " Are you sure you want to cancel this TR?",
        onClose: (confirmed: boolean) => {
          setConfirmDialog(false);
          if (confirmed) {
            cancelHandler(data);
          }
        },
        title: "Confirmation",
      });
    }
  }, [trId, confirmDialog, data, refresh]);

  return (
    <div>
      {
        <MarkFailedDialogForTR
          data={data}
          open={markFailedDialog}
          setOpen={setMarkFailedDialog}
        />
      }
      {!multipleTrAllocateDialog && !sendMultipleWhatsAppTr && (
        <AssignCookOnTempRep
          data={data}
          open={openAssignCookToTrDialog}
          setOpen={setOpenAssignCookToTrDialog}
        />
      )}
      <ChangeTrTimeDialog
        open={changeTimeDailog}
        handleClose={setChangeTimeDailog}
        data={data}
      />
      {!openAssignCookToTrDialog && (
        <MultipleTemporaryReplacement
          data={data}
          multipleTrAllocateDialog={multipleTrAllocateDialog}
          setMultipleTrAllocateDialog={setMultipleTrAllocateDialog}
          sendMultipleWhatsAppTr={sendMultipleWhatsAppTr}
          setSendMultipleWhatsAppTr={setSendMultipleWhatsAppTr}
        />
      )}
      <Show>
        <div>
          <Show {...props} actions={false} title=" ">
            <ActionButtons
              setData={setData}
              setMarkFailedDialog={setMarkFailedDialog}
              setConfirmDialog={setConfirmDialog}
              setMultipleTrAllocateDialog={setMultipleTrAllocateDialog}
              setChangeTimeDailog={setChangeTimeDailog}
              setOpenAssignCookToTrDialog={setOpenAssignCookToTrDialog}
            />
            <TabbedShowLayout>
              <Tab label={"TR Details"}>
                <TemporaryReplacementMetaShow />
              </Tab>
              <Tab label={"Customer Details"}>
                <CustomerDetailsTab />
              </Tab>
              <Tab label={"Customer TR"}>
              <UserBundleTemporaryReplacementTab
                    userBundleMappingId={trData?.userBundleMappingId}
                />
              </Tab>
              <Tab label={"Allocation Tab"}>
                <TemporaryReplacementAllocationTab trId={Number(trId)}/>
              </Tab>
            </TabbedShowLayout>
          </Show>
        </div>
      </Show>
    </div>
  );
};

export default TemporaryReplacementShow;
