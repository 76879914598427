import { createSlice } from "@reduxjs/toolkit";

export const cookProfileDataSlice = createSlice({
  name: "cookProfileData",
  initialState: {
    value: {
      data: {},
    },
  },
  reducers: {
    setCookProfileData: (state, action) => {
      state.value.data = action.payload;
    },
  },
});

export const { setCookProfileData } = cookProfileDataSlice.actions;

export default cookProfileDataSlice.reducer;
