export enum SlotsEnum {
  B = "B",
  L = "L",
  D = "D",
  BL = "BL",
  BD = "BD",
  LD = "LD",
  BLD = "BLD",
}

export enum DynamicPricingSlotEnum {
  BL = "BL",
  D = "D",
}

export enum UserType {
  ADMIN = "ADMIN",
  CUSTOMER = "CUSTOMER",
  COOK = "COOK",
  INTERNAL_SERVICES = "INTERNAL_SERVICES",
  VENDOR = "VENDOR",
}

export enum BundlePlan {
  PREMIUM = "Premium",
  POPULAR = "Popular",
}

export enum UserBundleMappingState {
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
}

export enum UserBundleMappingStatus {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  UPCOMING = "UPCOMING",
  FULFILLED = "FULFILLED",
  CLOSED = "CLOSED",
  CANCELLED = "CANCELLED",
}

export enum BundleGenderPreference {
  MALE = "MALE",
  FEMALE = "FEMALE",
  ANY = "ANY",
}

export enum CookStatusEnum {
  FREE = "FREE",
  BLOCKED = "BLOCKED",
}

export enum UserSubscriptionLeaveType {
  PAST = "PAST",
  FUTURE = "FUTURE",
  PRESENT = "PRESENT",
}

export enum SlotingGenderPreference {
  MALE = "Male",
  FEMALE = "Female",
}

export enum CookProfileGender {
  MALE = "Male",
  FEMALE = "Female",
  OTHER = "Other"
}

export enum CovidVaccinationStatus {
  NO= "No",
  ONE_DOSE = "1 Dose",
  TWO_DOSE = "2 Dose"
}

export enum SlotSize {
  TIME_0_5 = "0.5",
  TIME_1_0 = "1.0",
  TIME_1_5 = "1.5",
  TIME_2_0 = "2.0",
  TIME_2_5 = "2.5",
  TIME_3_0 = "3.0",
  TIME_3_5 = "3.5",
  TIME_4_0 = "4.0",
  TIME_4_5 = "4.5",
  TIME_5_0 = "5.0",
  TIME_5_5 = "5.5",
  TIME_6_0 = "6.0",
  TIME_6_5 = "6.5",
  TIME_7_0 = "7.0",
  TIME_7_5 = "7.5",
  TIME_8_0 = "8.0",
  TIME_8_5 = "8.5",
  TIME_9_0 = "9.0",
  TIME_9_5 = "9.5",
  TIME_10_0 = "10"
}

export enum moduleType {
  ADMIN_USERS= "AdminUser",
  EMPLOYEE_ROSTER="EmployeeRoster",
  COOKS="Cooks",
  CUSTOMERS="Customers",
  CITIES="Cities",
  AREAS= "Areas",
  LOCALITIES="Localities",
  HOUSES="Houses",
  HOUSE_REVIEWS="HouseReviews",
  VENDORS="Vendors",
  VAS="Vas",
  VAS_TRANSACTIONS= "VasTransactions",
  USER_BUNDLE_SERVICE_MAPPINGS="UserBundleServiceMappings",
  BUNDLES="Bundles",
  USER_BUNDLES="UserBundles",
  REFUND="Refund",
  REFUND_V2="RefundV2",
  CUSTOMER_LEAVES="CustomerLeaves",
  TEMPORARY_REPLACEMENT="TemporaryReplacement",
  EXTENSION="Extension",
  COOK_LEAVES="CookLeaves",
  TRANSACTIONS="Transactions",
  COUPONS="Coupons",
  COUPONSV2="CouponsV2",
  TRIALS="Trials",
  ONE_MEAL_SERVICE="OneMealService",
  TRIALS_CONVERTED="TrialsConverted",
  PRICE_CALCULATOR="PriceCalculator",
  PERMANENT_REPLACEMENT="PermanentReplacement",
  COOK_SLOTS="CookSlots",
  SMS="SMS",
  VENDOR_PAYOUTS="VendorPayouts",
  STAR_CHEF_VISIT="StarChefVisit",
  STAR_CHEF_INVENTORY= "StarChef-Inventory",
  RENEWALS="Renewals",
  WEIGHTAGE_TABLE="WeightageTable",
}

export enum vendorCategory {
  STAR_CLEANER = "STAR_CLEANER"
}

export enum Languages {
  HINDI = "Hindi",
  ENGLISH = "English",
  BENGALI = "Bengali",
  KANNADA = "Kannada",
  TELUGU = "Telugu"
}

export enum Religion {
  HINDU = "Hindu",
  MUSLIN = "Muslim",
  CHRISTIAN = "Christian", 
  JAIN = "Jain",
  SIKH = "Sikh",
}


export enum YEARS_OF_EXPERIENCE {
  BETWEEN_10_15 = "Between 10 to 15 Years", 
  BETWEEN_5_10 = "Between 5 to 10 Years",
  GREATER_THAN_15 = "Greater than 15 Years",
  LESS_THAN_5 = "Less than 5 Years",
  NO_EXPERIENCE = "No Experience"
}

export enum Cuisine_Known {
  NORTH_INDIAN = "North Indian",
  SOUTH_INDIAN = "South Indian",
  CHINESE = "Chinese",
  CONTINENTAL = "Continental",
  MEXICAN = "Mexican",
  THAI = "Thai"
}

export enum CuisineSkillsEnum {
  NORTH_INDIAN_CUISINE = "northIndianFinalRating",
  SOUTH_INDIAN_CUISINE = "southIndianFinalRating",
  CHINESE_CUISINE = "chineseDishFinalRating",
  CONTINENTAL_CUISINE = "continentalDishFinalRating",
  MEXICAN_CUISINE = "mexicanDishFinalRating",
  THAI_CUISINE = "thaiDishFinalRating",
}

export enum SoftSkillsEnum {
  COMMUNICATION = "communication",
  HYGIENE = "hygiene",
  ATTITUDE = "attitude",
  REGULARITY_AND_PUNCTUALITY = "regularityAndPunctuality",
}

export enum TrainingAssessmentEnum {
  APP_TRAINING= "appTraining",
  COMMUNICATION_TRAINING= "communications",
  HYGIENE_TRAINING = "hygiene",
  KITCHEN_ETIQUETTES="kitchenEtiquette",
  SITUATIONAL="situational",
}

export enum YesNoEnum {
  YES = "Yes",
  NO = "No",
}

export enum TshirtSizeEnum {
  EXTRA_SMALL = "Extra Small",
  SMALL = "Small",
  MEDIUM = "Medium",
  LARGE = "Large",
  EXTRA_LARGE = "Extra Large", 
  EXTRA_EXTRA_LARGE = "Extra Extra Large",
}

export enum TshirtNumberMapping {
    EXTRA_SMALL = 34,
    SMALL = 36,
    MEDIUM = 38,
    LARGE = 40,
    EXTRA_LARGE = 42, 
    EXTRA_EXTRA_LARGE = 44,
}


