import { Box, Typography } from '@mui/material'
import StorageIcon from '@mui/icons-material/Storage';
import React from 'react'
interface DisplayNoDataFoundProps{
  text?: string
}
const DisplayNoDataFound = (props:DisplayNoDataFoundProps) => {
  return (
    <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems: "center", width:"100%", mt:"5rem"}}>
      <StorageIcon sx={{fontSize:"min(15vw,8rem)", color:"#737373"}}/>
      <Typography color="#737373" fontSize="min(5vw,3rem)" variant="h4">{props?.text ? props.text : "No Data Found"}</Typography>
    </Box>
  )
}

export default DisplayNoDataFound