import { Button } from "@mui/material";
import { useState } from "react";
import { Create, RadioButtonGroupInput, SimpleForm, TextInput } from "react-admin";
import CreateAreaInterface from "../../interfaces/Area/CreateArea";
import { useAppDispatch } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import { CityList } from "../City/CityList";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";

const CreateArea = () => {
    const [cityId, setCityId] = useState<number>();
    const dispatch = useAppDispatch();

    const handleCityListDialogOpen = () => {
        dispatch(setCustomSelectOpen({
            open: true
        }))
    }

    const transform = (data: any): CreateAreaInterface => ({
        ...data,
        cityId: cityId
    });

    return (
        <Create transform={transform}>
            <SimpleForm>
                <ResourceSelectDialog
                    name={"Cities"}
                    list={CityList}
                    onClose={(rec: any) => {
                      if(!rec){
                        dispatch(setCustomSelectOpen({open: false}))
                        return
                      }
                      setCityId(rec.id)
                    }}
                />

                <Button variant={"contained"} onClick={handleCityListDialogOpen} disableElevation> {cityId ? `City: ${cityId}` : `Select City`} </Button>
                <TextInput source="name" />
                <RadioButtonGroupInput
                    source="isServiceable"
                    choices={[
                        { id: "true", name: "True" },
                        { id: "false", name: "False" },
                    ]}
                />
            </SimpleForm>
        </Create>
    )
}

export default CreateArea;