import { Create } from "react-admin";
import CityService from "../../services/CityService";
import {useState} from "react";
import {Button, FormControl, Grid, styled, TextField} from "@mui/material";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";
import {CookService} from "../../services/CookServices";
import {useNavigate} from "react-router-dom";

const Input = styled("input")({
    display: "none",
});

const cityService = new CityService();
const cookService = new CookService();

const CreateCity = () => {
    const navigate = useNavigate();
    const [name, setName] = useState<string>("");
    const [lat, setLat] = useState<string>("");
    const [long, setLong] = useState<string>("");

    const [fileNames, setFileNames] = useState<string>('');
    const [fileUri, setFileUri] = useState<string>('');

    const onImageSelect = (event: any) => {
        const image = event.target.files[0];
        cookService
            .getAWSLinks('cityImages', `${image?.name}`)
            .then((res) => {
                setFileNames(image?.name);
                setFileUri(res?.getUrl);
                cookService
                    .uploadImageToAWS(res?.putUrl, image)
                    .then((res) => {
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                            message: "Image uploaded successfully",
                            type: CustomSnackbarTypes.SUCCESS,
                        });
                    })
                    .catch((err) => {
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                            message: "Something Went Wrong",
                            type: CustomSnackbarTypes.ERROR,
                        });
                    });
            })
            .catch((err) => {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Something Went Wrong",
                    type: CustomSnackbarTypes.ERROR,
                });
            });
    };

    const showError = (title: string) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: title || "Fill the form to continue",
            type: CustomSnackbarTypes.ERROR,
        });
    }

    const handleSave = () => {
        if(!name) {
            showError('Enter name to continue');
            return;
        }
        if(!lat) {
            showError('Enter latitude to continue');
            return;
        }
        if(!long) {
            showError('Enter longitude to continue');
            return;
        }
        if(!fileUri) {
            showError('Select a image to continue');
            return;
        }
        cityService.create({
            name: name,
            coordinates: {
                lat: Number(lat),
                long: Number(long),
            },
            image: fileUri,
        }).then((res) => {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Created Successfully",
                type: CustomSnackbarTypes.SUCCESS,
            });
            if(res?.data.id) {
                navigate(`/Cities/${res?.data.id}/show`, {replace: true})
            }
        })
    };

    return (
        <Create title={'Create City'}>
            <Grid
                container
                columns={1}
                rowSpacing={2}
                style={{padding: "12px 12px 12px 12px"}}
            >
                <Grid item xs={4}>
                    <TextField
                        label="Name"
                        id="name"
                        value={name}
                        onChange={(event: any) => setName(event.target.value)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Latitude"
                        id="latitude"
                        value={lat}
                        onChange={(event: any) => setLat(event.target.value)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Longitude"
                        id="longitude"
                        value={long}
                        onChange={(event: any) => setLong(event.target.value)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl sx={{ minWidth: 210 }}>
                        <label htmlFor="profilePhoto">
                            <Button component="span" variant="outlined">
                                {fileNames
                                    ? `Selected File: ${fileNames}`
                                    : "Choose the Image"}
                            </Button>
                            <Input
                                accept="image/*"
                                onChange={onImageSelect}
                                name="profilePhoto"
                                id="profilePhoto"
                                type="file"
                            />
                        </label>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <Button onClick={handleSave} variant="contained">
                        {'Save'}
                    </Button>
                </Grid>

            </Grid>
        </Create>
    )
}

export default CreateCity;
