import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useRecordContext, useRefresh } from "react-admin";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { CookService } from "../../services/CookServices";
import { CustomSnackbarTypes } from "./CustomSnackBar";

interface InputProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}
const cookService = new CookService();
export const UpdateCookPhoneNumberDialog = (props: InputProps) => {
  const record = useRecordContext();
  const cookId = Number(record?.id);
  let [mobile, setMobile] = useState<string>();
  const refresh = useRefresh();
  const handleClose = () => {
    props.setOpen(false);
    setMobile("");
  };
  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      scroll={"paper"}
      maxWidth="xl"
    >
      <DialogTitle>Update Cook Phone Number</DialogTitle>
      <DialogContent>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 2, right: 5 }}
        >
          {" "}
          <Close />{" "}
        </IconButton>
        <Grid
          container
          columns={1}
          rowSpacing={0.9}
          style={{ padding: "12px 12px 12px 12px" }}
        >
          <FormControl fullWidth>
            <FormControl fullWidth style={{ marginTop: "20px" }}>
              <TextField
                label="Mobile"
                onChange={(event) => {
                  setMobile(event.target.value);
                }}
                required
              />
            </FormControl>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginTop: "12px" }}>
        <Button
          variant="contained"
          onClick={() => {
            const regex = /^[6-9][0-9]{9}$/;
            if (!mobile) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Phone Number must be provided",
                type: CustomSnackbarTypes.ERROR,
              });
              return;
            }

            if (mobile && !regex.test(mobile)) {
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Phone Number provided is wrong",
                type: CustomSnackbarTypes.ERROR,
              });
              return;
            }

            cookService
              .updateMobileNumber(cookId, {
                phone: mobile,
              })
              .then((res) => {
                handleClose();
                refresh();
              })
              .catch((err) => {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                  message: err.error.data,
                  type: CustomSnackbarTypes.ERROR,
                });
                return;
              });
          }}
          size={"large"}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            handleClose();
          }}
          size={"large"}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
