import {
  Datagrid,
  FunctionField,
  List,
  ShowButton,
  TextField,
  TextInput,
} from "react-admin";
import { useAppSelector } from "../../store/hooks";
import ResourceSelectDialogButton from "../Common/ResourceSelectDialogButton";

const HubListFilters = [
  <TextInput source="name" label="Search By Name" alwaysOn />,
  <TextInput label="Area Name" source="areaName" alwaysOn />,
  <TextInput label="City Name" source="cityName" alwaysOn />,
];

const HubsList = () => {
  const isCustomSelectOpen = useAppSelector(
    (state) => state.customSelect.value.open
  );
  return (
    <>
      <List filters={HubListFilters} actions={isCustomSelectOpen?false:undefined}>
        <Datagrid bulkActionButtons={false} size="medium">
          {isCustomSelectOpen && <ResourceSelectDialogButton />}
          <TextField label="ID" source="id" />
          <TextField label="Name" source="name" />
          <TextField label="Area ID" source="areaId" />
          <TextField label="Area Name" source="areaName" />
          <TextField label="City Name" source="cityName" />
          <TextField label="City ID" source="cityId" />
          <TextField label="Latitude" source="coordinates.coordinates[1]" />
          <TextField label="Longitude" source="coordinates.coordinates[0]" />
          <FunctionField label="View" render={() => <ShowButton />} />
        </Datagrid>
      </List>
    </>
  );
};

export default HubsList;
