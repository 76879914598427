export enum PlatformLeadStatus {
  NEW = "NEW",
  WIP = "WIP",
  ONGOING = "ONGOING",
  FULFILLED = "FULFILLED",
  NOT_CONVERTED = "NOT_CONVERTED",
}
export enum PlatformLeadProductType {
  ROYALE = "ROYALE",
  PARTY = "PARTY",
  SUBSCRIPTION = "SUBSCRIPTION",
  ONE_MEAL = "ONE_MEAL",
  CONTACT_US = "CONTACT_US",
  REQUEST_FOR_JOB = "REQUEST_FOR_JOB"
}

export enum PlatformLeadRequirementEnum {
  HOMECHEF = "HOMECHEF",
  COOKED_MEAL = "COOKED_MEAL",
  BARTENDER = "BARTENDER",
}
