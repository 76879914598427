import jsonServerProvider from 'ra-data-json-server';
import {
    CreateParams,
    CreateResult,
    DataProvider, DeleteResult, fetchUtils,
    GetListParams,
    GetListResult, GetManyResult, GetOneParams,
    GetOneResult, UpdateParams, UpdateResult
} from 'react-admin';
import { UserBundleMappingStatus } from './enums/common';
import { AdminUserInterface } from './interfaces/Admin/AdminUserInterface';
import { CreateAdminUserInterface } from './interfaces/Admin/CreateAdminuserinterface';
import { AdminServices } from './services/AdminServices';
import AdminUserService from './services/AdminUser';
import { AllVasService } from './services/AllVasServices';
import AreaService from './services/AreaService';
import UserBundleService from './services/BundleService';
import CityService from './services/CityService';
import { CookLeavesService } from "./services/CookLeavesService";
import { CookService } from "./services/CookServices";
import { CouponService } from './services/CouponService';
import { CustomerLeavesServies } from './services/CustomerLeaves';
import { CustomerService } from "./services/CustomerService";
import { ExtensionService } from "./services/ExtensionServices";
import HouseReviewService from './services/HouseReviewService';
import HouseService from './services/HouseService';
import LocalityService from './services/LocalityService';
import PermanentReplacementService from "./services/PermanentReplacementService";
import { ReFundService } from './services/RefundService';
import { RenewalsService } from './services/RenewalsService';
import SMSService from './services/SMSService';
import StarChefVisitService from './services/StarChefVisitService';
import { TempReplacementService } from './services/TemporaryReplacement';
import { TransactionServies } from './services/TransactionsService';
import { TrialsConvertedService } from './services/TrialsConvertedService';
import { TrialsService } from './services/TrialServices';
import VendorPayoutService from './services/VendorPayoutService';
import { VendorService } from './services/VendorServices';
import { CouponServiceV2 } from './services/CuponServiceV2';
import {RefundServiceV2} from "./services/RefundServiceV2";
import { OneMealService } from './services/OneMealService';
import StarCleanerVisitService from './services/StarCleanerVisitService';
import HubServices from './services/HubServices';
import PlatformLeadServices from "./services/PlatformServices";
import ChefForPartyService from "./services/ChefForPartyService";
import ComboService from "./services/ComboService";

const cookLeavesService = new CookLeavesService();
const cookService = new CookService();
const customerService = new CustomerService();
const vendorService = new VendorService();
const allVasService = new AllVasService();
const temporaryReplacement = new TempReplacementService();
const customerLeaves = new CustomerLeavesServies();
const userBundleService = new UserBundleService();
const chefForPartyService = new ChefForPartyService();
const houseService = new HouseService();
const extensionSevices = new ExtensionService();
const transactionServies = new TransactionServies();
const couponService = new CouponService();
const couponServiceV2 = new CouponServiceV2();
const trialsServices = new TrialsService();
const smsService = new SMSService();
const vendorPayoutService = new VendorPayoutService();
const adminServices = new AdminServices();
const adminUserService = new AdminUserService();
const permanentReplacementService = new PermanentReplacementService();
const comboService = new ComboService();
const trialsConvertedService = new TrialsConvertedService();
const cityService = new CityService();
const areaService = new AreaService();
const localityService = new LocalityService();
const houseReviewService = new HouseReviewService();
const starChefVisitService = new StarChefVisitService();
const renewalsService = new RenewalsService();
const refundService = new ReFundService();
const refundServiceV2 = new RefundServiceV2();
const oneMealService = new OneMealService();
const starCleanerService = new StarCleanerVisitService();
const hubsServices = new HubServices();
const platformLeadServices = new PlatformLeadServices();
const apiUrl = process.env.REACT_APP_PUBLIC_URL;

const httpClient = (url: any, options: fetchUtils.Options = {}) => {
    const requestHeaders: Headers = (options.headers ||
        new Headers({
            Accept: 'application/json',
        })) as Headers
    options.headers = requestHeaders
    let authData = localStorage.getItem('auth');
    const { accessToken } = !!authData ? JSON.parse(authData as string).data : '';
    options.headers.set('Authorization', `Bearer ${accessToken}`)
    options.headers.set('X-APP-CODE', 'COOK')

    return fetchUtils.fetchJson(url, options)
}

const dataProvider = jsonServerProvider(
    apiUrl,
    httpClient,
)

const customDataProvider: DataProvider = {
    ...dataProvider,
    getList: async (resource, params: GetListParams): Promise<GetListResult<any>> => {
        let limit = params.pagination.perPage;
        let offset = (params.pagination.page - 1) * limit;
        let sort = params.sort;
        let filters = params.filter;

        switch (resource) {
            case 'AdminUsers':
                return await adminUserService.getAllAdminUsers(limit, offset, filters, sort);
            case 'CookLeaves':
                return await cookLeavesService.getAllCookLeaves(limit, offset, filters, sort);
            case 'Cooks':
                return await cookService.getAllCooks(limit, offset, filters);
            case 'Customers':
                return await customerService.getAllCustomers(limit, offset, filters);
            case 'Vendors':
                return await vendorService.getAllVendors(limit, offset, filters);
            case 'Vas':
                return await allVasService.getAllVas(limit, offset);
            case 'VasTransactions':
                return await allVasService.getAllServiceTransactions(limit, offset, sort);
            case 'UserBundleServiceMappings':
                return await allVasService.getAllVasMapping(limit, offset);
            case 'TemporaryReplacement':
                return await temporaryReplacement.getAllTempReplacement(limit, offset, filters, sort);
            case 'CustomerLeaves':
                return await customerLeaves.getAllCustLeaves(limit, offset, filters, sort);
            case 'Cities':
                return await cityService.fetchAllCities(limit, offset, filters, sort);
            case 'Areas':
                return await areaService.fetchAllAreas(limit, offset, filters, sort);
            case 'Localities':
                return await localityService.fetchAllLocalities(limit, offset, filters, sort);
            case 'Houses':
                return await houseService.fetchAllHouse(limit, offset, filters, sort);
            case 'HouseReviews':
                return await houseReviewService.fetchAllHouseReviews(limit, offset, filters, sort);
            case 'Bundles':
                return await userBundleService.fetchAllBundles(limit, offset);
            case 'UserBundles':
                return await userBundleService.fetchAllBundleMappings(limit, offset, filters, sort);
            case 'ChefForParty':
                return await chefForPartyService.fetchAllChefForPart(limit, offset, filters, sort);
            case 'Extension':
                return await extensionSevices.getAllExtension(limit, offset, filters, sort)
            case 'Transactions':
                return await transactionServies.getAllTransaction(limit, offset, filters, sort)
            case 'Coupons':
                return await couponService.getAllCoupons(limit, offset, filters, sort)
            case 'CouponsV2':
                return await couponServiceV2.getAllCouponsV2(limit, offset, filters, sort)
            case 'Trials':
                return await trialsServices.getAllTrials(limit, offset, filters, sort)
            case 'PermanentReplacement':
                return await permanentReplacementService.getAllPermanentReplacement(limit, offset, filters, sort);
            case 'Combo':
                return await comboService.getAllCombos(limit, offset, filters, sort);
            case 'SMS':
                return await smsService.getAllTemplate(limit, offset, filters, sort)
            case 'VendorPayouts':
                return await vendorPayoutService.getAllVendorPayouts(limit, offset, filters, sort)
            case 'Hubs':
                return await hubsServices.getAllHubs(limit, offset, filters, sort)
            case 'TrialsConverted':
                return await trialsConvertedService.getAllTrialsConverted(limit, offset, filters, sort)
            case 'StarChefVisit':
                return await starChefVisitService.getAllStarChefVisit(limit, offset, filters, sort)
            case 'StarChefCooks':
                return await cookService.getAllStarChefCooks(limit, offset, filters)
            case 'Renewals':
                return await renewalsService.getAllRenewals(limit, offset, filters, sort)
            case 'Refund':
                return await refundService.getAllRefunds(limit, offset, filters, sort)
            case 'RefundV2':
                return await refundServiceV2.getAllRefundsV2(limit, offset, filters, sort)
            case 'OneMealService':
                return await oneMealService.getAllOneMealService(limit, offset, filters, sort)
            case 'StarCleaner':
                return await starCleanerService.getAllStarCleanerVisit(limit, offset, filters, sort)
            case 'Platform':
                return await platformLeadServices.getAllLeads(limit, offset, filters, sort)
            default:
            let defaultRes: GetListResult = { data: [], total: 0 };
            return Promise.resolve(defaultRes);
        }
    },
    getOne: async (resource, params: GetOneParams<any>) => {
        switch (resource) {
            case 'AdminUsers':
                return await adminUserService.getAdminUserById(params.id);
            case 'CookLeaves':
                return await cookLeavesService.getCookLeaveDetailsById(params.id);
            case 'Cooks':
                return await cookService.getOneCook(params.id);
            case 'Vendors':
                return await vendorService.getOneVendor(params.id);
            case 'UserBundleServiceMappings':
                return await allVasService.fetchOneUserBundleServiceMapping(params.id);
            case 'UserBundles':
                return await userBundleService.fetchOneUserBundleMapping(params.id);
            case 'ChefForParty':
                return await chefForPartyService.fetchOneChefForParty(params.id);
            case 'Customers':
                return await customerService.getCustomerById(params.id);
            case 'Cities':
                return await cityService.fetchOne(params.id);
            case 'Areas':
                return await areaService.fetchOne(params.id);
            case 'Localities':
                return await localityService.fetchOne(params.id);
            case 'Houses':
                return await houseService.getHouseById(params.id);
            case 'Vas':
                return await allVasService.getVasById(params.id);
            case 'VasTransactions':
                return await allVasService.getOneVasTransaction(params.id);
            case 'Bundles':
                return await userBundleService.getBundlesById(params.id);
            case 'Trials':
                return await trialsServices.getTrialsById(params.id);
            case 'VendorPayouts':
                    return await vendorPayoutService.getVendorPayoutById(params.id);
            case 'Combo':
                return await comboService.getDetailsById(params.id);
            case 'Hubs':
                const response = await hubsServices.getAllLocalitiesForHub(params.id)
                return {data:{id:params.id, data:response?.data}};
            case 'TrialsConverted':
                return await trialsConvertedService.getOneTrialsConverted(params.id);
            case 'StarChefVisit':
                return await starChefVisitService.getStarChefVisitById(params.id);
            case 'Renewals':
                return await renewalsService.getOneRenewals(params.id);
            case 'TemporaryReplacement':
                return await temporaryReplacement.getOneTempReplacement(params.id);
            case 'CouponsV2':
                return await couponServiceV2.getOneCupon(params.id);
            case 'OneMealService':
                return await oneMealService.getOneMealService(params?.id);
            case 'StarCleaner':
                return await starCleanerService.getStarCleanerVisit(params?.id)
            case 'FreshLead':
                return await cookService.getCookByLead(params?.id);
            case 'Platform':
                return await platformLeadServices.getPlatformLeadById(params?.id);
            default:
                let defaultRes: GetOneResult = { data: [] };
                return defaultRes;
        }
    },
    update: async (resource, params: UpdateParams) => {
        const previousData = params.previousData;
        const changedData = params.data;
        switch (resource) {
            case 'UserBundles':
                return previousData.status === UserBundleMappingStatus.PENDING ?
                    await userBundleService.patchUserBundleMapping(changedData, previousData.houseId, previousData.id) :
                    await userBundleService.patchUserBundleMeta(changedData, previousData.houseId, previousData.id)
            case 'Customers':
                return await customerService.updateCustomerById(params.data)
            case 'VasTransactions':
                return await allVasService.PatchVasUserTransaction(params.id as number, params.data)
            case 'Trials':
                return await allVasService.PatchVasUserTransaction(params.id as number, params.data)
            case 'AdminUsers':
                return await adminServices.updateById(params.data as AdminUserInterface)
            default:
                let defaultRes: UpdateResult = { data: {} };
                return defaultRes;
        }
    },
    create: async (resource: any, params: CreateParams) => {
        switch (resource) {
            case "TemporaryReplacement":
                return await temporaryReplacement.createTempReplacement(params.data)
            case "Vas":
                return await allVasService.createVas(params.data)
            case 'Bundles':
                return await userBundleService.createBundle(params.data)
            case 'UserBundles':
                return await userBundleService.createUserBundleMapping(params.data)
            case 'Customers':
                return await customerService.createCustomer(params.data)
            case 'Cities':
                return await cityService.create(params.data);
            case 'Areas':
                return await areaService.create(params.data);
            case 'Localities':
                return await localityService.create(params.data);
            case 'Houses':
                return await houseService.createHouse(params.data)
            case 'Trials':
                return await trialsServices.createTrials(params.data)
            case "CustomerLeaves":
                return await customerLeaves.createCustomerLeaves(params.data)
            case "Coupons":
                return await couponService.createCoupon(params.data)
            case 'AdminUsers':
                return await adminServices.create(params.data as CreateAdminUserInterface)
            case 'StarChefVisit':
                return await starChefVisitService.createStarChefVisit(params.data)
            case 'StarCleaner':
                return await starCleanerService.createStarCleanerVisit(params.data)
            case 'Vendors':
                return await vendorService.createVendor(params.data)
            case 'Hubs':
                return await hubsServices.createHub(params.data)
            default:
                let defaultRes: CreateResult = { data: {} };
                return defaultRes;
        }
    },
    delete: async (resource, params) => {
        let res: DeleteResult = { data: params.previousData };
        switch (resource) {
            case 'UserBundles':
                await userBundleService.deleteUserBundleMapping(res.data.id);
                return res;
            case 'AdminUsers':
                await adminServices.deleteById(res.data.id);
                return res;
            default:
                let defaultRes: DeleteResult = { data: {} };
                return defaultRes;
        }
    },
    deleteMany: async (resource, params) => {
        switch (resource) {
            default:
                let defaultRes: DeleteResult = { data: {} };
                return defaultRes;
        }
    },
    getMany: async (resource, params) => {
        const ids = params.ids;
        switch (resource) {
            case "Vas":
                return await allVasService.getManyVas(ids as number[])
            case "Bundles":
                return await userBundleService.getManyBundles(ids as number[])
            case 'Cities':
                return await cityService.getMany(ids as number[]);
            case 'Areas':
                return await areaService.getMany(ids as number[]);
            case 'Localities':
                return await localityService.getMany(ids as number[]);
            case 'Houses':
                return await houseService.getManyHouse(ids as number[])
            default:
                let defaultRes: GetManyResult = { data: [] };
                return defaultRes;
        }
    }
}

export default customDataProvider;
