import { createSlice } from "@reduxjs/toolkit";

export const customSelectSlice = createSlice({
    name: 'customSelect',
    initialState: {
        value: {
            open: false,
            record: null,
            sideFilterShow: false,
            starchefSideFilter: false,
            openSideFilter: false,
            permanentReplacemetSideFilter: false,
            cookSideFilter: false,
            renewalSideFilter: false,
            temporaryReplacementSideFilter: false,
            omsSideFilter: false,
        }
    },
    reducers: {
        setCustomSelectOpen: (state, action) => {
            state.value.open = action.payload.open;
            state.value.record = action.payload.record;
        },
        setTrialSideFilter: (state, action) => {
            state.value.sideFilterShow = action.payload.sideFilterShow
        },
        setTrialConvertedSideFilter: (state, action) => {
            state.value.openSideFilter = action.payload.openSideFilter
        },
        setStarChefSideFilter: (state, action) => {
            state.value.starchefSideFilter = action.payload.starchefSideFilter
        },
        setPermanentReplacementSideFilter: (state, action) => {
            state.value.permanentReplacemetSideFilter = action.payload.permanentReplacemetSideFilter
        },
        setCookSideFilter: (state, action) => {
            state.value.cookSideFilter = action.payload.cookSideFilter
        },
        setRenewalFilter: (state, action) => {
            state.value.renewalSideFilter = action.payload.renewalSideFilter
        },
        setTemporaryReplacementFilter: (state, action) => {
            state.value.temporaryReplacementSideFilter = action.payload.temporaryReplacementSideFilter
        },
        setOmsSideFilter: (state, action) => {
            state.value.omsSideFilter = action.payload.omsSideFilter
        }
    }
})

export const {setCustomSelectOpen, setTrialSideFilter, setTrialConvertedSideFilter, setPermanentReplacementSideFilter, setStarChefSideFilter, setCookSideFilter, setRenewalFilter, setTemporaryReplacementFilter, setOmsSideFilter} = customSelectSlice.actions;

export default customSelectSlice.reducer;
