import { Link } from "@mui/material";
import { useRecordContext } from "react-admin";

interface InputProps { 
    href?: string | undefined; 
    source: string | number;
    sourceRoute: boolean;
    targetBlank?: boolean;
    value?: string;
    gotoHref? : boolean;
}

const LinkField = (props: InputProps) => {
    const record = useRecordContext();
    let href;
    href = props.sourceRoute? `${props.href}/${record && record[props.source]}`: props.href;
    if(props.gotoHref)
    href = record[props.source]
    return (
        <Link href={href} target={props.targetBlank? '_blank': '_self'} > {record && (props.value ? record[props.value] : record[props.source])} </Link>
    )
}

export default LinkField;