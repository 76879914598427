import {createSlice} from "@reduxjs/toolkit";

export const bankDetailsSlice = createSlice({
    name: 'bankDetails',
    initialState: {
        value: {
            data: {},
        }
    },
    reducers: {
        createBankAccount: (state, action) => {
            state.value.data = action.payload;
        }
    }
})

export const {createBankAccount} = bankDetailsSlice.actions;

export default bankDetailsSlice.reducer;