import React, {useEffect, useState} from 'react';
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import ChefForPartyService from '../../services/ChefForPartyService';
import ChefForPartyCooksList from './ChefForPartyCookList';

interface InputProps {
    partyId: number
}

const chefForPartyService = new ChefForPartyService();

const ChefForPartCooksTab = (props: InputProps) => {

    let [subData, setSubData] = useState<any>(null);
    let [isLoading, setIsLoading] = useState<boolean>();
    const [partyData, setPartyData] = useState<any>(null);

    const refreshData = (partyId: number) => {
        setIsLoading(true)
        chefForPartyService.fetchOneChefForParty(partyId)
            .then((data: any) => {
                setSubData(data?.data?.cooks);
                setPartyData(data?.data)
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        refreshData(props.partyId);
    }, [props.partyId])

    return (
        <div>
            {
                isLoading ?
                    <CircularLoader/>
                    :
                    <div>
                        {subData &&
                            Array.isArray(subData) &&
                            (
                                subData.length>0 ?
                                    <ChefForPartyCooksList data={subData} party={partyData} refresh={() => {refreshData(Number(props.partyId))}} />
                                    :
                                    <EmptyListPlaceholder
                                        message='No Cooks Assgin Yet'
                                    />
                            )
                        }
                    </div>
            }
        </div>
    );
};

export default ChefForPartCooksTab;
