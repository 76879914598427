import { Dialog, DialogTitle, DialogContent, Grid, FormControl, Select, InputLabel, MenuItem, TextField, DialogActions, Button } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useState } from "react";
import { SlotsEnum } from "../../enums/SlotsEnum";
import { UserBundlesResponse } from "../../interfaces/UserBundleMapping/UserBundlesResponse";

interface PropsCalculateRefund {
    open: boolean;
    callback: () => void;
    data: UserBundlesResponse | undefined;
    onClose: () => void;
    onSubmit: (param: {date: Date, slot: SlotsEnum}) => void;
}

export const CalculateRefundDialog = (props: PropsCalculateRefund) => {
    const [endDate, setEndDate] = useState<any>(null);
    const [endSlot, setEndSlot] = useState<any>(null);
    const handleClose = () => {
      setEndDate("");
      setEndSlot("");
      props.callback();
    }
    return  (
        <Dialog
            onClose={handleClose}
            open={props.open}
            scroll={"paper"}
            maxWidth="lg"
            >
            <DialogTitle>Cancel Bundle</DialogTitle>
            <DialogContent>
            <Grid
              container
              columns={1}
              rowSpacing={0.9}
              style={{ padding: "12px 12px 12px 12px" }}
            >
              <FormControl fullWidth>
                <DatePicker
                  label="Date"
                  inputFormat="dd MMM yyyy"
                  disableMaskedInput
                  minDate={new Date()}
                  maxDate={props.data?.endDate ? new Date(props.data?.endDate): undefined}
                  value={endDate}
                  onChange={(value: any) => {
                    setEndDate(moment(value).format("YYYY-MM-DD"));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                { props.data && props.data?.userBundleMeta?.mealMapping.length > 0 &&  <FormControl fullWidth style={{ marginTop: "20px" }}>
                  <InputLabel id="forEndSlot">End Slot</InputLabel>
                  <Select
                    labelId="forEndSlot"
                    id="endSlot"
                    value={endSlot}
                    label="End Slot"
                    onChange={(event: any) => {
                      setEndSlot(event.target.value);
                    }}
                  >
                    {props.data && Object.values(props.data?.userBundleMeta?.mealMapping).map((e: any, index) => {
                      return (
                        <MenuItem key={e} value={e}>
                          {e}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>}
               
                </FormControl>
           </Grid>
            </DialogContent>
            <DialogActions style={{ marginTop: "12px" }}>
            <Button
              variant="contained"
              onClick={() => props.onSubmit({date: endDate, slot: endSlot})}
              size={"medium"}
            >
              Calculate Refund
            </Button>
            </DialogActions>
        </Dialog>
    )
  }