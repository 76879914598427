import QuestionInterface from "../../interfaces/Cook/SkillAssessmentInterface";

export const appTrainingQuestions: QuestionInterface[] = [
  {
    questionText:
      "CK Partner Application pe Check in/out roz karna kyun zaruri hai ?",
    answerOptions: [
      {
        answerText:
          "Isse humari attendance lagti hai aur Chefkart ko pata rehta hai",
        marks: 10,
      },
      {
        answerText: "Koi zaroorat nahi hai",
        marks: 0,
      },
    ],
  },
  {
    questionText:
      "Check in karte samay application me kaunsi photo daalni hoti hai?",
    answerOptions: [
      {
        answerText:
          "Selfie",
        marks: 10,
      },
      {
        answerText:
          "Kitchen ki photo",
        marks: 0,
      },
    ],
  },
  {
    questionText:
      "Check out karte samay application me kaunsi photo daalni hoti hai?",
    answerOptions: [
      {
        answerText:
          "Koi photo nahi daalni",
        marks: 10,
      },
      {
        answerText: "Kitchen ki photo",
        marks: 0,
      },
    ],
  },
  {
    questionText:
      "Agar aapka koi samay khaali hai toh application mein kya karenge ?",
    answerOptions: [
      {
        answerText:
          "CK partner app me jayenge> khali samay pe click karenge > jo samay khali hain wo bhar denge",
        marks: 10,
      },
      { answerText: "Helpline pe call karenge", marks: 0 },
    ],
  },

  {
    questionText:
      "Application mein chhutti lagane ke kya fayde hain?",
    answerOptions: [
      {
        answerText:
          "Aap yaad rakh sakte hain ki mahine me kitni chhutti li hain and kitni bachi hain",
        marks: 10,
      },
      { answerText: "Aapke paise galat nahi katenge", marks: 0 },
    ],
  },

  {
    questionText:
      "Do type ki Chutti kaunsi hain?",
    answerOptions: [
      {
        answerText:
          "Chhoti chhutti, lambi chhutti",
        marks: 10,
      },
      { answerText: "Aadhe din ki chhutti, poore din ki chhutti", marks: 0 },
    ],
  },

  {
    questionText:
      "Chhoti chhutti aur lambi chhutti dono me kya antar hai?",
    answerOptions: [
      {
        answerText:
          "Chhoti chhutti matlab half day, lambi chutti matlab full day",
        marks: 10,
      },
      { answerText: "Chhoti chhutti matlab kisi kaam se chhutti aur baaki kaam pe jayenge ya poore ek din ki chutti Lambi chhutti matlab 2 din ya usse zyada din ki chhutti", marks: 0 },
    ],
  },

  {
    questionText:
      "Aap ko naye kaam application me kahaan dikhte hain?",
    answerOptions: [
      {
        answerText:
          "Naya kaam tab pe (yellow tab me)",
        marks: 10,
      },
      { answerText: "WhatsApp pe", marks: 0 },
    ],
  },

  {
    questionText:
      "Aapke jo ghar chal rahe hain wo application me kahaan dikhte hain?",
    answerOptions: [
      {
        answerText:
          "Mere ghar me jake (blue tab me)",
        marks: 10,
      },
      { answerText: "Dikhta hi nahi hain ", marks: 0 },
    ],
  },
];
