import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    InputLabel, MenuItem,
    Select, TextField
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useState } from 'react';
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { SlotsEnum } from "../../enums/SlotsEnum";
import { TimeEnum } from "../../enums/TimeEnum";
import EventBus from "../../EventBus";
import PermanentReplacementService from "../../services/PermanentReplacementService";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import moment from "moment";

interface InputProps {
    prId: number;
    open: boolean;
    mealMapping?: SlotsEnum[];
    callback: (data: any) => void;
}

const permanentReplacementService = new PermanentReplacementService();

const CreateTrForPrDialog = (props: InputProps) => {

    const [slotName, setSlotName] = useState<SlotsEnum | null>(null);
    const [time, setTime] = useState<TimeEnum | null>(null);
    const [date, setDate] = useState<string | null>(null);

    const handleClose = () => {
        setSlotName(null);
        setTime(null);
        setDate(null);
        props.callback(null);
    }

    const handleCreate = () => {
        if (!slotName || !time || !date) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: 'Fill all the details',
                type: CustomSnackbarTypes.INFO
            });
            return;
        }
        permanentReplacementService.createTrialForPermanentReplacement(props.prId, slotName!, time!, date!)
            .then(() => {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: 'Trial created',
                    type: CustomSnackbarTypes.SUCCESS
                });
                handleClose();
            })
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            scroll={"paper"}
            maxWidth="xl"
        >
            <DialogTitle>Create Trial for Replacement</DialogTitle>
            <DialogContent>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <FormControl style={{ width: 250, marginTop: '6px' }} fullWidth>
                        <InputLabel>Select Slot</InputLabel>
                        <Select
                            autoWidth={false}
                            value={slotName}
                            onChange={(event) => setSlotName(event.target.value as SlotsEnum)}
                        >
                            {Object.values(props.mealMapping || []).map((val) => {
                                return (
                                    <MenuItem key={val} value={val}>
                                        {val}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl style={{ width: 250, marginTop: '12px' }} fullWidth>
                        <InputLabel>Select Time</InputLabel>
                        <Select
                            autoWidth={false}
                            value={time}
                            onChange={(event) => setTime(event.target.value as TimeEnum)}
                        >
                            {Object.values(TimeEnum).map((val) => {
                                return (
                                    <MenuItem key={val} value={val}>
                                        {val}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl style={{ width: 250, marginTop: '12px' }} fullWidth>
                        <DatePicker
                            label="Select Date"
                            inputFormat="dd MMM yyyy"
                            disableMaskedInput
                            value={date}
                            minDate={new Date()}
                            onChange={(value) => {
                                let data = moment(value);
                                setDate(data.format('YYYY-MM-DD'));
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={handleCreate}
                    size={"large"}
                >
                    Create
                </Button>
                <Button
                    variant="contained"
                    color={"error"}
                    onClick={() => {
                        handleClose();
                    }}
                    size={"large"}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateTrForPrDialog;
