import { Button, ButtonGroup, Stack } from "@mui/material";
import { useState } from "react";
import {
    ArrayField,
    Datagrid,
    DateField,
    EditButton,
    List,
    NumberField,
    RaRecord,
    TextField,
    useRecordContext,
    useRefresh, 
    FunctionField} from "react-admin";
import { UserType } from "../../enums/common";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { ZohoBaseUrl, ZohoModulesUrl } from "../../enums/zoho";
import EventBus from "../../EventBus";
import { AllVasService } from "../../services/AllVasServices";
import { useAppDispatch } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import CallButton from "../Common/CallButton/CallButton";
import LinkField from "../Common/LinkField";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import Cooks from "../Cooks/Cooks";
import Vendors from "../Vendors/Vendor";

const vasService = new AllVasService()

interface InputProps {
    handleOpen: (n: number, record: any) => void
}

const AssignButton = (props: InputProps) => {
    const record = useRecordContext()
    return (
        <ButtonGroup size="small" aria-label="outlined primary button group" disableElevation>
            <Button onClick={() => props.handleOpen(0, record)}>
                {
                   (record.userId && record.userType === UserType.COOK) ?
                        'Change Cook'
                        :
                        'Assign Cook'
                }
            </Button>
            <Button onClick={() => props.handleOpen(1, record)}>
                {
                    (record.userId && record.userType === UserType.VENDOR) ?
                        'Change Vendor'
                        :
                        'Assign Vendor'
                }
            </Button>
        </ButtonGroup>
    )
}

const StatusButton = () => {
    const record = useRecordContext();
    const refresh = useRefresh();
    return (
        <>
            <ButtonGroup size="small" aria-label="outlined primary button group" disableElevation>
                <Button
                    color={"success"}
                    onClick={() => {
                        EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
                            message: "Are you sure you want to Mark as complete?",
                            onClose: (confirmed: boolean) => {
                                if (confirmed) {
                                    vasService.markTransactionAsComplete(record.id as number).then(() => refresh())
                                }
                            },
                            title: "Confirmation"
                        });
                    }}
                >
                    Mark as complete
                </Button>
                <Button
                    color={"error"}
                    onClick={() => {
                        EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
                            message: "Are you sure you want to cancel?",
                            onClose: (confirmed: boolean) => {
                                if (confirmed) {
                                    vasService.cancelTransaction(record.id as number).then(() => refresh())
                                }
                            },
                            title: "Confirmation"
                        });
                    }}
                >
                    Cancel
                </Button>
            </ButtonGroup>
        </>
    )
}

const RenderCTAButtons = (props: { handleOpen: (n: number, record: any) => void; }) => {
    const record = useRecordContext()
    if (record.state === 'PENDING') {
        return (
            <Stack
                direction={"row"}
                gap={1}
            >
                <AssignButton
                    handleOpen={props.handleOpen}
                />
                <StatusButton />
                <EditButton />
            </Stack>
        )
    }

    return null;
}

const UserRefereceField = (props: { source: string; label: string }) => {
    const record = useRecordContext()
    let referece = '';
    if (record.userType === 'COOK') {
        referece = "Cooks"
    }

    if (record.userType === 'VENDOR') {
        referece = "Vendors"
    }

    return (
        <LinkField source={props.source} href={`/#/${referece}/${record[props.source]}/show`} sourceRoute={false}/>
    )
}

const UserTransactionList = () => {
    const dispatch = useAppDispatch();

    const [selectedRecord, setSelectedRecord] = useState<RaRecord>()
    const [selected, setSelected] = useState(NaN)
    const refresh = useRefresh();

    const handleSelectCookOrVendor = () => {
        dispatch(setCustomSelectOpen({
            open: true
        }));
    }

    const handleOpen = (val: number, record: any) => {
        setSelected(val)
        setSelectedRecord(record)
        handleSelectCookOrVendor();
    }

    const handleCloseCook = (record: any) => {
        if (record) {
            vasService.assignVendorToTransaction(record.id, UserType.COOK, selectedRecord?.id as number).then(() => refresh())
        }
    }

    const handleCloseVendor = (record: any) => {
        if (record) {
            vasService.assignVendorToTransaction(record.id, UserType.VENDOR, selectedRecord?.id as number).then(() => refresh())
        }
    }

    const ZohoField = (props: {source: string}) => {
        const record = useRecordContext();
        const serviceType: string = record.userServiceBundleMapping.vas.name;
        return(
            <LinkField source={props.source} href={`${ZohoBaseUrl}${serviceType.toLowerCase().includes("chef") ? ZohoModulesUrl.STAR_CHEF: ZohoModulesUrl.STAR_CLEANER}`} sourceRoute targetBlank />
        )
    }

    return (
        <div>
            <ResourceSelectDialog
                name={selected === 0 ? "Cooks" : "Vendors"}
                list={selected === 0 ? Cooks : Vendors}
                onClose={selected === 0 ? handleCloseCook : handleCloseVendor}
            />
            <List>
                <Datagrid bulkActionButtons={false}>
                    <NumberField source="id" />
                    <TextField label={"Service Name"} source="userServiceBundleMapping.vas.name" />
                    <TextField source="state" />
                    <UserRefereceField label={"Chef/Cleaner"} source="userId" />
                    <FunctionField label= "Chef/Cleaner phone number" render={(record: any) => <CallButton toPhoneNumber={record?.user?.phoneNumber} userType={UserType.COOK}/>}/>
                    <TextField source="userType" />
                    <DateField options={{ day: 'numeric', month: 'short', year: 'numeric' }} source="date" />
                    <TextField source="time" />
                    <TextField source="commentFromAdmin" sortable={false}/>
                    <ZohoField source="zohoId" />
                    <ArrayField sortable={false} label="Customer Name" source="userServiceBundleMapping.userBundleMapping.house.customers">
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="fname" label={''}/>
                        </Datagrid>
                    </ArrayField>
                    <TextField label={'Address'} source="userServiceBundleMapping.userBundleMapping.house.address" sortable={false}/>
                    <ArrayField sortable={false} label="Customer Mobile" source="userServiceBundleMapping.userBundleMapping.house.customers">
                        <Datagrid bulkActionButtons={false}>
                            <FunctionField render={(record: any) => <CallButton toPhoneNumber={record?.mobile} userType={UserType.CUSTOMER}/>}/>
                        </Datagrid>
                    </ArrayField>
                    <RenderCTAButtons handleOpen={handleOpen} />
                </Datagrid>
            </List>
        </div>
    )
};

export default UserTransactionList;
