import React, {useEffect, useRef} from 'react';
import {Box, Button, DialogActions, DialogContent} from "@mui/material";
import {Resource} from "ra-core";
import Dialog from "@mui/material/Dialog";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {setCustomSelectOpen} from "../../store/reducers/customSelect";

interface InputProps {
    onClose: Function;
    name: string;
    list: React.FC;
}

const ResourceSelectDialog = (props: InputProps) => {
    const { onClose, name, list } = props;
    const isMounted = useRef(false);

    const isCustomSelectOpen = useAppSelector((state) => state.customSelect.value.open);
    const record = useAppSelector((state) => state.customSelect.value.record)

    const dispatch = useAppDispatch();

    let handleClose = () => {
        onClose(record);
        dispatch(setCustomSelectOpen({open: false, record: null}));
    };

    useEffect(() => {
        let handleClose = () => {
            onClose(record);
            dispatch(setCustomSelectOpen({open: false, record: null}));
        };
        if (isMounted.current) {
            if (!isCustomSelectOpen && !!record) {
                handleClose();
            }
        } else {
            isMounted.current = true;
        }
    }, [isCustomSelectOpen, record, onClose, dispatch]);

    return (
        <Dialog
            onClose={handleClose}
            open={isCustomSelectOpen || false}
            scroll={'paper'}
            maxWidth="xl"
        >
            <DialogContent sx={{display:"flex", padding:"0px 1rem"}}>
              <Box flex="1" display="flex">
                <Resource name={name} list={list} />
              </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ResourceSelectDialog;
