enum NotConvertedReasonsCategories {
    POOR_HYGIENE = "Poor Hygiene",
    REQUIREMENT_MISMATCH = "Requirement Mismatch",
    FOOD_QUALITY_POOR = "Food quality poor",
    UNRESONABLE_CUSTOMER = "Unresonable Customer",
    PRICING_MISMATCH = "Pricing Mismatch",
    ZERO_TOLERANCE = "Zero Tolerance",
    OTHERS = "Others"
}

export default NotConvertedReasonsCategories
