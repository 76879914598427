import { Button as MUIButton, Stack } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import {
  Button,
  DeleteButton,
  EditButton,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  useRecordContext,
  useRefresh,
  useShowContext,
} from "react-admin";
import { useLocation } from "react-router-dom";
import Colors from "../../enums/colorEnums";
import { CookAllocationStatus } from "../../enums/cookAllocationEnums";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { UserBundleMappingStatus } from "../../enums/UserBundleMappingStatus";
import EventBus from "../../EventBus";
import UserBundleService from "../../services/BundleService";
import { ActivateBundleDialog } from "../Common/ActivateBundleDialog";
import AssignCookOnBundleDialog from "../Common/AssignCookOnBundleDialog";
import { CancelBundleDialog } from "../Common/CancelBundleDialog";
import { CreateCxLeavesDialog } from "../Common/CreateCxLeaves";
import { CreateExtensionDialog } from "../Common/CreateExtensionDialog";
import CreateMultipleCXLeavesDialog from "../Common/CreateMultipleCXLeavesDialog";
import { CreateTrDialog } from "../Common/CreateTR";
import { CreateVasDialog } from "../Common/CreateVasDialog";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import { PaymentLinkDailog } from "../Common/PaymentLinksDailogs";
import { ResuscitateDialog } from "../Common/ResuscitateDialog";
import { UpdateDateAndSlotDialog } from "../Common/UpdateDateAndTime";
import UserBundleCookSubscriptionTab from "./UserBundleCookSubscriptionTab";
import UserBundleCustomerLeaveTab from "./UserBundleCustomerLeaveTab";
import UserBundleExtensionsTab from "./UserBundleExtensionsTab";
import UserBundleMetaShow from "./UserBundleMeta/UserBundleMetaShow";
import UserBundleUserServiceTab from "./UserBundleMeta/UserBundleUserServiceTab";
import UserBundlePrTab from "./UserBundlePrTab";
import UserBundleUserStarChefServiceTab from "./UserBundleStarChefServiceTab";
import UserBundleSubscriptionTab from "./UserBundleSubscriptionTab";
import UserBundleTemporaryReplacementTab from "./UserBundleTemporaryReplacementTab";
import UserBundleTransactionsTab from "./UserBundleTransactionsTab";
import { ChangeBundleTimeDialog } from "../Common/ChangeBundleTimeDialog";
import RenewBundleDialog from "../Common/RenewBundleDialog";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import BundleList from "../Bundle/BundleList";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import UserBundleOmsTab from "./UserBundleOmsTab";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";
import { UserGroupsEnum } from "../../enums/UserGroupsEnum";
import EditPriceOnBundleDialog from "../Common/EditPriceOnUserBundleDialog";
import { PaymentStatusEnum } from "../../enums/PaymentStatusEnum";
import CustomerCallRecordingsTab from "../Customers/CustomerCallRecordingsTab";
import { CallMaskingReferenceModelNameEnum } from "../../enums/CallMaskingReferenceModelNameEnum";
import UserBundleCookLeaves from "./UserBundleCookLeaves";
import SetResolution from "../Common/SetResolution";

const userBundleService = new UserBundleService();

const UserBundleTabs = () => {
  let location = useLocation();
  let userBundleId = location.pathname.split("/")[2];
  let record = useRecordContext();


  return (
    <TabbedShowLayout
      tabs={
        <TabbedShowLayoutTabs
          variant="scrollable"
          scrollButtons="auto"
          selectionFollowsFocus={true}
        />
      }
    >
      <Tab label={"Details"}>
        <UserBundleMetaShow />
      </Tab>

      <Tab label={"Customer Leaves Marked"}>
        <UserBundleCustomerLeaveTab
          userBundleMappingId={parseInt(userBundleId)}
        />
      </Tab>

      <Tab label={"Extensions Granted"}>
        <UserBundleExtensionsTab userBundleMappingId={parseInt(userBundleId)} />
      </Tab>

      <Tab label={"One Meal (TR)"}>
        <UserBundleOmsTab userBundleMappingId={parseInt(userBundleId)} />
      </Tab>

      <Tab label={"Temporary Replacements"}>
        <UserBundleTemporaryReplacementTab
          userBundleMappingId={parseInt(userBundleId)}
        />
      </Tab>

      <Tab label={"User Services"}>
        <UserBundleUserServiceTab
          userBundleMappingId={parseInt(userBundleId)}
        />
      </Tab>

      <Tab label={"Subscriptions"}>
        <UserBundleSubscriptionTab
          userBundleMappingId={parseInt(userBundleId)}
        />
      </Tab>

      <Tab label={"Cook Subscriptions"}>
        <UserBundleCookSubscriptionTab
          userBundleMappingId={parseInt(userBundleId)}
        />
      </Tab>
      <Tab label={"Transaction"}>
        <UserBundleTransactionsTab
          userBundleMappingId={parseInt(userBundleId)}
        />
      </Tab>
      <Tab label={"Permanent Replacement"}>
        <UserBundlePrTab userBundleMappingId={parseInt(userBundleId)} />
      </Tab>
      <Tab label={"Star Chef Service"}>
        <UserBundleUserStarChefServiceTab
          userBundleMappingId={parseInt(userBundleId)}
        />
      </Tab>
      <Tab label={"Call Recordings"} key={"Call Recordings"}>
        <CustomerCallRecordingsTab serviceId={Number(record?.id)} referenceModalName={CallMaskingReferenceModelNameEnum.SUBSCRIPTION} />
      </Tab>
      <Tab label={"Cook Leaves"} key={"Cook Leaves"}>
        <UserBundleCookLeaves userBundleMappingId={parseInt(userBundleId)} />
      </Tab>
    </TabbedShowLayout>
  );
};

const UserBundleEditButton = () => {
  const record = useRecordContext();
  
  const isEditable = [
    UserBundleMappingStatus.CANCELLED,
    UserBundleMappingStatus.CLOSED,
    UserBundleMappingStatus.FULFILLED,
  ].includes(record.status as UserBundleMappingStatus);
  return !isEditable ? <EditButton /> : <></>;
};

const SendPaymentLinkButton = (props: any) => {
  const record = useRecordContext();
  return (
    <Button
      color="secondary"
      label={!props.active ? "Send Payment Link" : "Send renewal Link"}
      onClick={() => {
        EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
          message: "Are you sure you want to send the payment link?",
          onClose: (confirmed: boolean) => {
            if (confirmed) {
              if (!props.active) {
                userBundleService
                  .sendPaymentLink(parseInt((record as any).id))
                  .then((res) => {
                    props.setPaymentDailog(true);
                    props.setPaymentLinks(res.data);
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                      message: "Payment link sent",
                      type: CustomSnackbarTypes.SUCCESS,
                    });
                  });
              } else {
                userBundleService
                  .sendRenewalPaymentLink(parseInt((record as any).id))
                  .then((res) => {
                    props.setPaymentDailog(true);
                    props.setPaymentLinks(res.data);
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                      message: "Renewal link sent",
                      type: CustomSnackbarTypes.SUCCESS,
                    });
                  });
              }
            }
          },
          title: "Confirmation",
        });
      }}
    />
  );
};

const getAssignCookButtonColor = (
  cookSubscriptions: any[],
  visitPerDay: number
) => {
  if (!!cookSubscriptions && cookSubscriptions.length > 0) {
    let slotMappingCount = 0;
    for (const cookSubscription of cookSubscriptions) {
      const cookSubscriptionEndDateMoment = moment(cookSubscription.endDate);

      if (cookSubscriptionEndDateMoment.isAfter(moment())) {
        //TODO: add indian timezone
        slotMappingCount =
          slotMappingCount +
          (!!cookSubscription.slotMapping &&
            !!cookSubscription.slotMapping.length
            ? cookSubscription.slotMapping.length
            : 0);
      }
    }

    if (slotMappingCount === visitPerDay) {
      return Colors.GREEN;
    }

    return Colors.BLUE;
  }

  return Colors.RED;
};

export const RenderCTAButtons = (props: any) => {
  const { record } = useShowContext();
  const cookAllocationStatus = record?.cookAllocationStatus;
  const userBundleStatus = record?.status;
  const userDetails = useAppSelector((state) => state.userDetails.value);
  let groupsArray: string[] = userDetails.adminUser.accessGroups;
  const AssignCookButton = () => {
    const color: Colors = getAssignCookButtonColor(
      record.cookSubscription,
      record.userBundleMeta?.mealMapping?.length || 0
    );

    switch (color) {
      case Colors.RED:
        return (
          <CustomButton
            name={
              cookAllocationStatus === CookAllocationStatus.ALLOCATED
                ? "Change Cook"
                : "Assign Cook"
            }
            color="error"
            callback={(data) => {
              props.setSelectedData(data);
              props.setAssignCookModel(true);
            }}
          />
        );

      case Colors.GREEN:
        return (
          <CustomButton
            name={
              cookAllocationStatus === CookAllocationStatus.ALLOCATED
                ? "Change Cook"
                : "Assign Cook"
            }
            color="success"
            callback={(data) => {
              props.setSelectedData(data);
              props.setAssignCookModel(true);
            }}
          />
        );
      case Colors.BLUE:
        return (
          <CustomButton
            name={
              cookAllocationStatus === CookAllocationStatus.ALLOCATED
                ? "Change Cook"
                : "Assign Cook"
            }
            color="info"
            callback={(data) => {
              props.setSelectedData(data);
              props.setAssignCookModel(true);
            }}
          />
        );

      default:
        return (
          <CustomButton
            name={
              cookAllocationStatus === CookAllocationStatus.ALLOCATED
                ? "Change Cook"
                : "Assign Cook"
            }
            callback={(data) => {
              props.setSelectedData(data);
              props.setAssignCookModel(true);
            }}
          />
        );
    }
  };

  return (
    <Stack direction={"row"} p={1} gap={2}>
      {!props.disableShow && <ShowButton />}
      {userBundleStatus === UserBundleMappingStatus.PENDING && (
        <>
          <CustomButton
            name={"Activate Bundle"}
            callback={(data) => {
              props.setSelectedData(data);
              props.setActivateBundle(true);
            }}
          />
          <CustomButton
            name={"Change Meal Mapping Time"}
            callback={(data) => {
              props.setSelectedData(data);
              props.setEditTime(true);
            }}
          />
          <SendPaymentLinkButton
            setPaymentDailog={props.setPaymentDailog}
            setPaymentLinks={props.setPaymentLinks}
          />
          <DeleteButton />
          {!props.disableEdit && <UserBundleEditButton />}
        </>
      )}
      {[
        UserBundleMappingStatus.PENDING,
        UserBundleMappingStatus.ACTIVE,
        UserBundleMappingStatus.UPCOMING,
      ].includes(userBundleStatus) && (
          <CustomButton
            name={"Change Meal Mapping Time"}
            callback={(data) => {
              props.setSelectedData(data);
              props.setEditTime(true);
            }}
          />
        )}
      {[
        UserBundleMappingStatus.ACTIVE,
        UserBundleMappingStatus.UPCOMING,
      ].includes(userBundleStatus) && (
          <>
            <CustomButton
              name={"Create Cx Leaves"}
              callback={(data) => {
                props.setSelectedData(data);
                props.setCxLeaveModel(true);
              }}
            />
            <CustomButton
              name={"Create Multiple Cx Leaves"}
              callback={(data) => {
                props.setSelectedData(data);
                props.setIsMultipleCXLeaveModelOpen(true);
              }}
            />
            <CustomButton
              name={"Create Extension"}
              callback={(data) => {
                props.setSelectedData(data);
                props.setCustomExtensionModel(true);
              }}
            />
          </>
        )}
      {userBundleStatus === UserBundleMappingStatus.ACTIVE && (
        <>
          <AssignCookButton />
          <CustomButton
            name={"Give Vas"}
            callback={(data) => {
              props.setSelectedData(data);
              props.setCreateVas(true);
            }}
          />
          <CustomButton
            name={"Cancel"}
            callback={(data) => {
              props.setSelectedData(data);
              props.setCancelBundle(true);
            }}
          />
          <CustomButton
            name={"Renew Bundle"}
            callback={(data) => {
              props.setSelectedData(data);
              props.handleClickRenewDialogBoxOpen();
            }}
          />
          <SendPaymentLinkButton
            setPaymentDailog={props.setPaymentDailog}
            setPaymentLinks={props.setPaymentLinks}
            active={true}
          />
          {!props.disableEdit && <UserBundleEditButton />}
        </>
      )}
      {
          record?.includeFreeTr&&
            <CustomButton
            name={"Set Resolution"}
            callback={(data) => {
              props.setSelectedData(data);
              props.setResolution(true);
            }}
          />
      }
      {userBundleStatus === UserBundleMappingStatus.UPCOMING && (
        <>
          {!props.disableEdit && <UserBundleEditButton />}
          <CustomButton
            name={"Give Vas"}
            callback={(data) => {
              props.setSelectedData(data);
              props.setCreateVas(true);
            }}
          />
        </>
      )}
      {[UserBundleMappingStatus.FULFILLED].includes(userBundleStatus) && (
        <CustomButton
          name={"Renew Bundle"}
          callback={(data) => {
            props.setSelectedData(data);
            props.handleClickRenewOpen();
          }}
        />
      )}
      {[
        UserBundleMappingStatus.FULFILLED,
        UserBundleMappingStatus.CLOSED,
        UserBundleMappingStatus.CANCELLED,
      ].includes(userBundleStatus) && (
          <CustomButton
            name={"Resuscitate"}
            callback={(data) => {
              props.setSelectedData(data);
              props.setResuscitateDailog(true);
            }}
          />
        )}
      {userBundleStatus === UserBundleMappingStatus.ACTIVE &&
        moment(record.startDate).isSameOrAfter(moment()) && (
          <CustomButton
            name={"Update start date and slot"}
            callback={(data) => {
              props.setSelectedData(data);
              props.setUpdateDateAndSlot(true);
            }}
          />
        )}
      {(GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN])) && ([UserBundleMappingStatus.PENDING].includes(userBundleStatus)) && (record?.paymentStatus === PaymentStatusEnum.NOT_PAID) && (
        <CustomButton
          name={"Edit price"}
          callback={(data) => {
            props.setSelectedData(data);
            props.setOpenEditPriceDialog(true);
          }}
        />
      )}
    </Stack>
  );
};

interface InputProps {
  name: any;
  callback: (data: any) => void;
  color?:
  | "inherit"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning"
  | undefined;
}

const CustomButton = (props: InputProps) => {
  let record = useRecordContext();
  return (
    <MUIButton
      onClick={() => {
        props.callback(record);
      }}
      size={"small"}
      color={props.color || "secondary"}
      style={{ width: "max-content", fontSize: "12px" }}
    >
      {props.name}
    </MUIButton>
  );
};

const UserBundleMappingShow = (props: any) => {
  let [selectedData, setSelectedData] = useState<any>(null);

  let [createTrModel, setCreateTrModel] = useState<boolean>(false);
  let [customExtensionModel, setCustomExtensionModel] =
    useState<boolean>(false);
  let [cxLeaveModel, setCxLeaveModel] = useState<boolean>(false);
  let [assignCookModel, setAssignCookModel] = useState<boolean>(false);
  let [createVas, setCreateVas] = useState<boolean>(false);
  let [activateBundle, setActivateBundle] = useState<boolean>(false);
  let [editTime, setEditTime] = useState<boolean>(false);
  let [cancelBundle, setCancelBundle] = useState<boolean>(false);
  let [paymentDailog, setPaymentDailog] = useState<boolean>(false);
  let [resuscitateDailog, setResuscitateDailog] = useState<boolean>(false);
  let [paymentLinks, setPaymentLinks] = useState<any>();
  let [updateDateAndSlot, setUpdateDateAndSlot] = useState<boolean>(false);
  let [renewDialog, setRenewDialog] = useState<boolean>(false);
  let [resolution, setResolution] = useState<boolean>(false);
  let [renew, setRenew] = useState<boolean>(false);
  let [isMultipleCXLeaveModelOpen, setIsMultipleCXLeaveModelOpen] =
    useState<boolean>(false);
  const [openEditPriceDialog, setOpenEditPriceDialog] = useState<boolean>(false)
  const dispatch = useAppDispatch();

  const refresh = useRefresh();

  const handleSelectBundle = (toOpen: boolean) => {
    dispatch(
      setCustomSelectOpen({
        open: toOpen,
      })
    );
  };

  const handleClickRenewOpen = () => {
    setRenew(true);
    handleSelectBundle(true);
  };

  const handleCloseRenew = (recordData: any) => {
    setRenew(false);
    userBundleService
      .renewUserBundle(selectedData.id, {
        bundleId: recordData.id,
        includeFreeTr: true,
      })
      .then(() => refresh())
      .finally(() => {
        handleSelectBundle(false);
      });
  };

  const handleClickRenewDialogBoxOpen = () => {
    setRenewDialog(true);
  };

  const childRefresh = () => {
    EventBus.getInstance().emit(
      EventBusEventsEnum.REFRESH_CHILD_USER_BUNDLE_MAPPING_TABS
    );
  };

  return (
    <div>
      <Show {...props} actions={false} title=" ">
        {renew && (
          <ResourceSelectDialog
            name={"Bundles"}
            list={BundleList}
            onClose={handleCloseRenew}
          />
        )}
        <RenewBundleDialog
          data={selectedData}
          open={renewDialog}
          setOpen={setRenewDialog}
          refresh={refresh}
        />
        <PaymentLinkDailog
          data={paymentLinks}
          open={paymentDailog}
          callback={(data) => {
            setPaymentDailog(false);
          }}
        />
        <ResuscitateDialog
          data={selectedData}
          open={resuscitateDailog}
          callback={(data) => {
            setResuscitateDailog(false);
          }}
        />
        <CreateTrDialog
          data={selectedData}
          open={createTrModel}
          callback={(data) => {
            setCreateTrModel(false);
            refresh();
            childRefresh();
          }}
        />
        <CreateCxLeavesDialog
          data={selectedData}
          open={cxLeaveModel}
          callback={(data) => {
            setCxLeaveModel(false);
            refresh();
            childRefresh();
          }}
        />
        <CreateMultipleCXLeavesDialog
          data={selectedData}
          open={isMultipleCXLeaveModelOpen}
          callback={() => {
            setIsMultipleCXLeaveModelOpen(false);
          }}
        />

        <CreateExtensionDialog
          data={selectedData}
          open={customExtensionModel}
          callback={() => {
            setCustomExtensionModel(false);
            refresh();
            childRefresh();
          }}
        />
        <SetResolution
          data={selectedData}
          open={resolution}
          callback={()=>{
            setResolution(false);
            refresh();
            childRefresh();
          }}
        />
        <CreateVasDialog
          data={selectedData}
          open={createVas}
          callback={() => {
            setCreateVas(false);
            refresh();
            childRefresh();
          }}
        />

        <ActivateBundleDialog
          data={selectedData}
          open={activateBundle}
          callback={() => {
            setActivateBundle(false);
            refresh();
            childRefresh();
          }}
        />
        <ChangeBundleTimeDialog
          data={selectedData}
          open={editTime}
          callback={() => {
            setEditTime(false);
            refresh();
            childRefresh();
          }}
        />

        <CancelBundleDialog
          data={selectedData}
          open={cancelBundle}
          callback={() => {
            setCancelBundle(false);
            refresh();
            childRefresh();
          }}
        />

        {assignCookModel && (
          <AssignCookOnBundleDialog
            data={selectedData}
            open={assignCookModel}
            callback={() => {
              setAssignCookModel(false);
              refresh();
              childRefresh();
            }}
          />
        )}
        {updateDateAndSlot && (
          <UpdateDateAndSlotDialog
            data={selectedData}
            open={updateDateAndSlot}
            callback={() => {
              setUpdateDateAndSlot(false);
            }}
          />
        )}
        <EditPriceOnBundleDialog
          data={selectedData}
          open={openEditPriceDialog}
          setOpen={setOpenEditPriceDialog}
        />
        <RenderCTAButtons
          setSelectedData={setSelectedData}
          setCreateTrModel={setCreateTrModel}
          setCustomExtensionModel={setCustomExtensionModel}
          setCxLeaveModel={setCxLeaveModel}
          setAssignCookModel={setAssignCookModel}
          setCreateVas={setCreateVas}
          setActivateBundle={setActivateBundle}
          setEditTime={setEditTime}
          setCancelBundle={setCancelBundle}
          setResolution={setResolution}
          handleClickRenewDialogBoxOpen={handleClickRenewDialogBoxOpen}
          handleClickRenewOpen={handleClickRenewOpen}
          setPaymentDailog={setPaymentDailog}
          setPaymentLinks={setPaymentLinks}
          setResuscitateDailog={setResuscitateDailog}
          setUpdateDateAndSlot={setUpdateDateAndSlot}
          setIsMultipleCXLeaveModelOpen={setIsMultipleCXLeaveModelOpen}
          setOpenEditPriceDialog={setOpenEditPriceDialog}
          disableShow
        />
        <UserBundleTabs />
      </Show>
    </div>
  );
};

export default UserBundleMappingShow;
