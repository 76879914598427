import React from "react";
import { Edit, PasswordInput, SimpleForm, TextInput } from "react-admin";
import {useAppSelector} from "../../store/hooks";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";
import {UserGroupsEnum} from "../../enums/UserGroupsEnum";

const AdminEdit = (props: any) => {
    const userDetails = useAppSelector((state) => state.userDetails.value);
    let groupsArray: string[] = userDetails.adminUser.accessGroups;

    if (!GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN])) {
        return (
            <div>
                <p>Contact admin to edit users</p>
            </div>
        )
    }
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput sx={{width: "300px"}} source="name" />
        <TextInput sx={{width: "300px"}} source="email" />
        <PasswordInput sx={{width: "300px"}} source="password" />
        <TextInput sx={{width: "300px"}} source="mobile"/>
      </SimpleForm>
    </Edit>
  );
};

export default AdminEdit;
