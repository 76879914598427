import { useLocation } from 'react-router-dom';
import TemplateVariantTable from "./TemplateVairantTable";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import CircularLoader from "../Common/CircularLoader";
import { useEffect, useState } from "react";
import SMSService from "../../services/SMSService";

const smsService = new SMSService();
const TemplateVariantShow = (props: any) => {
  let location = useLocation();
  let templateId = location.pathname.split("/")[2];
  let templateIdNum = Number(templateId);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiData, setApiData] = useState<any>(null);
  const [appId, setAppId] = useState<number>(0);
  const getTemplateVariants = async (templateId: number) => {
    smsService.getTemplateVariantsAccToTemplate(templateId).then((data) => {
      setApiData(data);
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
    })
  }

  const getAppId = async (templateId: number) => {
    smsService.getTemplate(templateId).then((data) => {
      setAppId(data.app.id);
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
    })
  }

  useEffect(() => {
    getTemplateVariants(templateIdNum);
    getAppId(templateIdNum);
  }, [templateIdNum]);

  return (
    <div>
      {
        isLoading
          ?
          <CircularLoader />
          :
          (
            !!apiData &&
            Array.isArray(apiData) &&
            (
              apiData.length > 0
                ?
                <TemplateVariantTable appId={appId} apiData={apiData}></TemplateVariantTable>
                :
                <EmptyListPlaceholder
                  message='No Template Variant Found'
                />
            )
          )
      }
    </div>
  );
};
export default TemplateVariantShow;
