import {Button} from '@mui/material';

interface ActionButtonProp {
    name: string;
    color? : "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined;
    onClickHandler?: () => void
    style?: any;
}

const TransactionButton = (props: ActionButtonProp) => {
  return (
    <Button
      onClick={!!props.onClickHandler ? props.onClickHandler : () =>{} }
      variant="contained"
      size={"small"}
      color={props.color || "primary"}
      style={{ width: "max-content", fontSize: "12px", ...props.style }}
    >
      {props.name}
    </Button>
  );
};
export default TransactionButton;