import { Box } from "@mui/material";
import {
  Datagrid,
  EditButton,
  List,
  NumberField,
  ShowButton,
  TextField,
  TextInput,
  FunctionField,
} from "react-admin";
import { UserType } from "../../enums/common";
import { ZohoBaseUrl, ZohoModulesUrl } from "../../enums/zoho";
import { useAppSelector } from "../../store/hooks";
import CallButton from "../Common/CallButton/CallButton";
import LinkField from "../Common/LinkField";
import ResourceSelectDialogButton from "../Common/ResourceSelectDialogButton";

const cookLeavesFilters = [
  <TextInput label="Search by Name" source="fname" alwaysOn />,
  <TextInput label="Search by Number" source="mobile" alwaysOn />,
];

const Customers = (props: any) => {
  const isCustomSelectOpen = useAppSelector(
    (state) => state.customSelect.value.open
  );
  return (
    <List {...props} filters={cookLeavesFilters}>
      <Datagrid bulkActionButtons={false} size="small" stickyHeader>
        {isCustomSelectOpen && <ResourceSelectDialogButton />}
        <NumberField source="id" sortable={false} />
        <TextField source="fname" sortable={false} />
        <TextField source="lname" sortable={false} />
        <TextField source="email" sortable={false} />
        <FunctionField
          label="address"
          render={(record: any) => (
            <Box minWidth="200px">{record?.address}</Box>
          )}
        />
        <FunctionField
          label="Mobile"
          render={(record: any) => (
            <CallButton
              toPhoneNumber={record?.mobile}
              userType={UserType.CUSTOMER}
            />
          )}
        />
        <TextField source="accountStatus" sortable={false} />
        <TextField source="houseId" sortable={false} />
        <LinkField
          source="contactId"
          href={`${ZohoBaseUrl}${ZohoModulesUrl.CUSTOMER}`}
          sourceRoute
          targetBlank
        />
        <TextField source="accountId" sortable={false} />
        {!isCustomSelectOpen && (
          <FunctionField
            label="Action"
            render={() => {
              return (
                <Box sx={{ display: "flex" }}>
                  <ShowButton />
                  <EditButton />
                </Box>
              );
            }}
          />
        )}
      </Datagrid>
    </List>
  );
};
export default Customers;
