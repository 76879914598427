import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { PlatformLeadResponse } from "../../interfaces/Party/PlatformLeadInterface";
import PlatformLeadServices from "../../services/PlatformServices";
import CkMuiDialog from "./CkMuiDialog/CkMuiDialog";
import { CustomSnackbarTypes } from "./CustomSnackBar";

const platformLeadServices = new PlatformLeadServices();

interface InputProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  platformLeadData: PlatformLeadResponse;
  refresh: () => void;
}

const UpdatePlatformLeadNotesDialog = ({
  open,
  setOpen,
  platformLeadData,
  refresh,
}: InputProps) => {
  const [platFormLeadNotes, setPlatFormLeadNotes] = useState<any>();

  const handleChange = (e: any) => {
    setPlatFormLeadNotes({
      ...platFormLeadNotes,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    platformLeadServices
      .updateLead(platformLeadData.id, platFormLeadNotes)
      .then(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Successfull",
          type: CustomSnackbarTypes.SUCCESS,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setOpen(false);
        refresh();
      });
  };

  return (
    <CkMuiDialog
      fullWidth={true}
      isDialogOpen={open}
      setDialogOpen={setOpen}
      onDialogClose={() => {}}
      title={"Add Note"}
      primaryLabel={"Submit"}
      primaryAction={handleSubmit}
      secondaryLabel={"Close"}
      secondaryAction={() => {
        setOpen(false);
      }}
      isLoading={false}
    >
      <Grid justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="notes"
            id="outlined-basic"
            label="Notes"
            variant="outlined"
            multiline
            rows={5}
            onChange={handleChange}
            defaultValue={""}
          />
        </Grid>
      </Grid>
    </CkMuiDialog>
  );
};
export default UpdatePlatformLeadNotesDialog;
