
import {
    FormControl,
    FormLabel,
    RadioGroup,
    TextField,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import FreshLeadInterface from "../../interfaces/Cook/FreshLeadInterface";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

interface InputProps {
    cookDetail: FreshLeadInterface;
    onCallback: (data: string) => void
}

const PreviousWorkSelect = ({ cookDetail, onCallback }: InputProps) => {

    const [isOther, setOther] = useState<boolean>(false);
    const [text, setText] = useState<string>('');

    useEffect(() => {
        if(!cookDetail?.previousWorkType){
            return;
        }
        if(!['Hotels','Hostels','Canteens','Houses']?.includes(cookDetail?.previousWorkType)){
            setOther(true);
            setText(cookDetail?.previousWorkType);
        }
    }, [cookDetail])

    return (
        <div>
            <FormControl>
                <FormLabel>
                    {`Where did you worked before as a cook?`}
                </FormLabel>
                <RadioGroup>
                    <FormControlLabel
                        checked={cookDetail?.previousWorkType === 'Hotels'}
                        onChange={() => {
                            setOther(false);
                            setText('');
                            onCallback('Hotels')
                        }}
                        value="Hotels"
                        control={<Radio />}
                        label="Hotels"
                    />
                    <FormControlLabel
                        checked={cookDetail?.previousWorkType === 'Hostels'}
                        onChange={() => {
                            setOther(false);
                            setText('');
                            onCallback('Hostels')
                        }}
                        value="Hostels"
                        control={<Radio />}
                        label="Hostels"
                    />
                    <FormControlLabel
                        checked={cookDetail?.previousWorkType === 'Canteens'}
                        onChange={() => {
                            setOther(false);
                            setText('');
                            onCallback('Canteens')
                        }}
                        value="Canteens"
                        control={<Radio />}
                        label="Canteens"
                    />
                    <FormControlLabel
                        checked={cookDetail?.previousWorkType === 'Houses'}
                        onChange={() => {
                            setOther(false);
                            setText('');
                            onCallback('Houses')
                        }}
                        value="Houses"
                        control={<Radio />}
                        label="Houses"
                    />
                    <FormControlLabel
                        checked={isOther}
                        onChange={() => {
                            setOther(true);
                            setText('');
                            onCallback('')
                        }}
                        value="Others"
                        control={<Radio />}
                        label="Others"
                    />
                </RadioGroup>
                {isOther && (
                    <TextField
                        label={'Enter the work place'}
                        onChange={(e: any) => {
                            setText(e.target.value);
                            onCallback(e.target.value)
                        }}
                        value={text}
                    />
                )}
            </FormControl>
        </div>
    );
};

export default PreviousWorkSelect;
