import { GetOneResult} from "react-admin";
import HttpClient from "../HttpClient";
import { AssignCookOnBundleMappingRequest } from "../interfaces/UserBundleMapping/AssignCookOnBundleMappingRequest";
import { CancelCookMappingOnBundleRequest } from "../interfaces/UserBundleMapping/CancelCookMappingOnBundleRequest";
import { PatchCookOnBundleMapping } from "../dtos/bundle/PatchCookOnBundleMapping";
import { EditClosedSubscriptionResponse } from "../interfaces/UserBundleMapping/EditClosedSubsscriptionResponse";

export class CookSubscriptionService {
    private baseUrl = '/centaurus/bundle/userbundles/';

    async getAssignedCooksForUserBundleMapping(bundleMappingId: number): Promise<GetOneResult<any>> {
        let data = await HttpClient.get(`${this.baseUrl}${bundleMappingId}/cooks`);
        return data?.data;
    }

    async assignCookOnBundleMappingId(userBundleMappingId: number, payload: AssignCookOnBundleMappingRequest): Promise<any> {
        let data = await HttpClient.post(this.baseUrl+userBundleMappingId+'/assign-cook', payload);
        return data;
    }

    async cancelCookMappingOnBundleMappingId(id: number, details: CancelCookMappingOnBundleRequest): Promise<any> {
        let data = await HttpClient.delete(`centaurus/cook-subscription/${id}/cancel`, {data: details});
        return data;
    }

    async patchCookMappingOnBundleMappingId(cookSubscriptionId: number, payload: PatchCookOnBundleMapping): Promise<any> {
        let data = await HttpClient.patch(`${this.baseUrl}cook-subscription/${cookSubscriptionId}/cookcut`, payload);
        return data;
    }

    async updateStartDateOfCookSubscriptionSlotMapping(cookSubscriptionId: number, payload: {startDate: string, startSlot: string}){
        let data = await HttpClient.patch(`centaurus/cook-subscription/${cookSubscriptionId}/start-date`, payload)
        return data?.data;
    }

    async hardDeleteCookSubscription(cookSubscriptionId: number){
        let data = await HttpClient.delete(`/centaurus/cook-subscription/${cookSubscriptionId}/hard-delete`);
        return data?.data;
    }

    async EditClosedSubscription(cookSubscriptionId: number, payload: {reason: string, subReason: string, comments: string}): Promise<EditClosedSubscriptionResponse>{
        let data = await HttpClient.patch(`/centaurus/cook-subscription/${cookSubscriptionId}/edit-after-closure`, payload);
        return data?.data;
    }

    async getActiveClousreRequests(bundleMappingId: number) {
        let data = await HttpClient.get(`/centaurus/bundle/userbundles/${bundleMappingId}/closure-requests`)
        return data?.data;
    }

    async removeScheduler(id: number){
        let data = await HttpClient.delete(`/centaurus/cook-subscription/scheduler/${id}`)
        return data?.data;
    }

    async scheduleCancelCookSubscription(id: number, payload: {  endDate: string; endSlot: string; reason: string; subReason: string; comments: string; createPr?: boolean}) {
        let data = await HttpClient.post(`/centaurus/cook-subscription/${id}/schedule-cancel`, payload)
        return data?.data;
    }

    async changeTimeAndSlotCookSubscription(id: number, payload: {startDate: string; startSlot: string;}) {
        let data = await HttpClient.patch(`/centaurus/cook-subscription/${id}/update-start-date-slot`, payload)
        return data?.data;
    }

    async executeSchedulers(id: number){
        let data = await HttpClient.post(`/centaurus/cook-subscription/scheduler/${id}/execute`)
        return data?.data
    }
}
